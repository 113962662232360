<div class="col-sm-12 margin-bottom-lg">
	<input id="agm-autocomplete" placeholder="Search Box" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control" #search [formControl]="searchControl">
	<agm-map
		class="map"
		[latitude]="mapCenter.lat"
		[longitude]="mapCenter.lng"
		[zoom]="zoom"
		[panControl]="panControl"
		[zoomControl]="zoomControl"
		[streetViewControl]="streetViewControl"
		(mapClick)="setCurrentPosition($event.coords)"
		(zoomChange)="zoom = $event">
		<agm-marker 
			*ngFor="let m of markers"
			[latitude]="m.lat"
			[longitude]="m.lng"
			[label]="m.label">
		</agm-marker>
	</agm-map>
</div>