<app-modal #modal title="Update Firmware Requests History" [hasConfirmButton]="false" size="xl">
	<div class='col-md-12 padding-8'>
		<div *ngIf="modalSiteId != battviewMobileSiteID" class="col-md-3">
			<div class='col-md-10 col-md-offset-1 tcenter body-bg-color boxs3'><strong>{{'g.chargers' | translate}}</strong></div>
			<table class='table table-striped'>
				<thead>
					<tr>
						<th>{{'g.version' | translate}}</th>
						<th>{{'g.user' | translate}}</th>
						<th>{{'g.date' | translate}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let charger of requestsLog.charger">
						<td>{{charger.firmwareversion}}</td>
						<td>{{usersInfo[charger.userid] || '-'}}</td>
						<td>{{charger.daterequested | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div [ngClass]="{'col-md-3': modalSiteId != battviewMobileSiteID, 'col-md-6': modalSiteId == battviewMobileSiteID}">
			<div class='col-md-10 col-md-offset-1 tcenter body-bg-color boxs3'><strong>{{'g.battviews' | translate}}</strong></div>
			<table class='table table-striped'>
				<thead>
					<tr>
						<th>{{'g.version' | translate}}</th>
						<th>{{'g.user' | translate}}</th>
						<th>{{'g.date' | translate}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let battview of requestsLog.battview">
						<td>{{battview.firmwareversion}}</td>
						<td>{{usersInfo[battview.userid] || '-'}}</td>
						<td>{{battview.daterequested | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div [ngClass]="{'col-md-3': modalSiteId != battviewMobileSiteID, 'col-md-6': modalSiteId == battviewMobileSiteID}">
			<div class='col-md-10 col-md-offset-1 tcenter body-bg-color boxs3'><strong>{{'g.wifi' | translate}}</strong></div>
			<table class='table table-striped'>
				<thead>
					<tr>
						<th>{{'g.version' | translate}}</th>
						<th>{{'g.user' | translate}}</th>
						<th>{{'g.date' | translate}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let wifi of requestsLog.wifi">
						<td>{{wifi.firmwareversion}}</td>
						<td>{{usersInfo[wifi.userid] || '-'}}</td>
						<td>{{wifi.daterequested | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="modalSiteId != battviewMobileSiteID" class="col-md-3">
			<div class='col-md-10 col-md-offset-1 tcenter body-bg-color boxs3'><strong>{{'g.bms' | translate}}</strong></div>
			<table class='table table-striped'>
				<thead>
					<tr>
						<th>{{'g.id' | translate}}</th>
						<th>{{'g.version' | translate}}</th>
						<th>{{'g.user' | translate}}</th>
						<th>{{'g.date' | translate}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let bms of requestsLog.bms">
						<td>{{bmsTypesObj[bms.firmwaretype].text}}</td>
						<td>{{bms.firmwareversion}}</td>
						<td>{{usersInfo[bms.userid] || '-'}}</td>
						<td>{{bms.daterequested | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</app-modal> 
<div class="row">
	<div class="panel panel-default">
		<div class="panel-body">
			<div class="col-md-12 mb20">
				<fieldset>
					<legend style="font-size:18px">{{'site.push_firmware_for_all_sites' | translate}}</legend>
					<div class="col-md-3">
						<span>{{'g.chargers' | translate}}:</span>
						<select class="form-control inline-form-control" [(ngModel)]="allChargersFirmwareVersion">
							<option *ngFor="let version of firmwareVersions.charger" [value]="version">{{version}}</option>
						</select>
						<button class="btn btn-default" (click)="pushFirmwareUpdate('charger', {id: 0, 'chargerFirmwareVersion': allChargersFirmwareVersion})" [disabled]="!allChargersFirmwareVersion">{{'site.push_firmware' | translate}}</button>
					</div>
					<div class="col-md-3">
						<span>{{'g.battviews' | translate}}:</span>
						<select class="form-control inline-form-control" [(ngModel)]="allBattviewsFirmwareVersion">
							<option *ngFor="let version of firmwareVersions.battview" [value]="version">{{version}}</option>
						</select>
						<button class="btn btn-default" (click)="pushFirmwareUpdate('battview', {id: 0, 'battviewFirmwareVersion': allBattviewsFirmwareVersion})" [disabled]="!allBattviewsFirmwareVersion">{{'site.push_firmware' | translate}}</button>
					</div>
					<div class="col-md-3">
						<span>{{'g.wifi' | translate}}:</span>
						<select class="form-control inline-form-control" [(ngModel)]="allWifiFirmwareVersion">
							<option *ngFor="let version of firmwareVersions.wifi" [value]="version">{{version}}</option>
						</select>
						<button class="btn btn-info" (click)="pushFirmwareUpdate('wifi', {id: 0, 'wifiFirmwareVersion': allWifiFirmwareVersion})" [disabled]="!allWifiFirmwareVersion">{{'site.push_wifi_firmware' | translate}}</button>
					</div>
					<div class="col-md-3" *ngIf="firmwareVersions.bms">
						<span>{{'g.bms' | translate}}:</span>
						<select class="form-control inline-form-control" [(ngModel)]="allBmsFirmwareType" (ngModelChange)="allBmsFirmwareVersion = null">
							<option *ngFor="let type of bmsTypes" [ngValue]="type.id">{{type.text}}</option>
						</select>
						<select class="form-control inline-form-control margin-top-xs" [(ngModel)]="allBmsFirmwareVersion">
							<option *ngFor="let version of firmwareVersions.bms[allBmsFirmwareType]" [value]="version">{{version}}</option>
						</select>
						<button class="btn btn-warning" (click)="pushFirmwareUpdate('bms', {id: 0, 'bmsFirmwareVersion': allBmsFirmwareVersion, 'bmsFirmwareType': allBmsFirmwareType})" [disabled]="!allBmsFirmwareVersion || !allBmsFirmwareType">{{'site.push_bms_firmware' | translate}}</button>
					</div>
				</fieldset>
			</div>
		</div>
	</div>
	<div class="panel panel-default">
		<div class="panel-body">
			<input [(ngModel)]="customerNameFilter" placeholder="{{'site.search_filter_customers' | translate}}" class="form-control"/>
			<br/>
			<ul class="insm sidebar-nav">
				<li *ngFor="let customer of connectivity | filter:customerNameFilter: 'name'">
					<a href="javascript:" (click)="toggleExpanded(customer);">
						<span class="glyphicon" [ngClass]="{'glyphicon-plus-sign': !customer.expanded, 'glyphicon-minus-sign': customer.expanded}"></span>
						{{customer.name}}
					</a>
					<ul *ngIf="customer.expanded" class="sidebar-nav">
						<li>
							<table class="table table-striped">
								<thead>
									<tr>
										<th>{{'g.site' | translate}}</th>
										<th>{{'site.last_connect_time' | translate}}</th>
										<th [innerHTML]="'site.devices_got_the_last_update_request' | translate | safe: 'html'"></th>
										<th><div class="col-md-6 no-padding">{{'device.update_chargers' | translate}}</div><div class="col-md-6 no-padding">{{'device.update_battviews' | translate}}</div></th>
										<th [innerHTML]="'site.show_requests_history' | translate | safe: 'html'"></th>
										<th *ngIf="userService.hasAccessFunction('control_site_live_socket')" [innerHTML]="'device.chargers_live_socket_enable' | translate | safe: 'html'"></th>
										<th *ngIf="userService.hasAccessFunction('control_site_live_socket')" [innerHTML]="'device.battviews_live_socket_enable' | translate | safe: 'html'"></th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let site of customer.sites | values">
										<td>{{site.name}}</td>
										<td>{{site.lastconnecttime | date:'MM/dd/yyyy hh:mm:ss a'}}<br/>&nbsp;<b>{{site.timeDiff.diff}}</b> {{site.timeDiff.unit}}s ago</td>
										<td class="text-underline pointer" routerLink="/devices-connectivity" [queryParams]="{'customerID': customer.id, 'siteID': site.id}">
											<span *ngIf="site.id != battviewMobileSiteID">{{site.firmwareUpdateCounts.charger.updated}}/{{site.firmwareUpdateCounts.charger.total}} {{'g.chargers' | translate}} - </span>{{site.firmwareUpdateCounts.battview.updated}}/{{site.firmwareUpdateCounts.battview.total}} {{'g.battviews' | translate}}<br/><br/>
											<strong class="text-underline">{{'g.wifi' | translate}}:</strong><br/>
											<span *ngIf="site.id != battviewMobileSiteID">{{site.wifiFirmwareUpdate.charger}}/{{site.firmwareUpdateCounts.charger.total}} {{'g.chargers' | translate}} - </span>{{site.wifiFirmwareUpdate.battview}}/{{site.firmwareUpdateCounts.battview.total}} {{'g.battviews' | translate}}<br/><br/>
											<strong class="text-underline">{{'g.bms' | translate}}:</strong><br/>
											<span *ngIf="site.id != battviewMobileSiteID">{{site.bmsFirmwareUpdates}}/{{site.firmwareUpdateCounts.charger.total}} {{'g.chargers' | translate}}</span>
										</td>
										<td>
											<div class="row">
												<div class="col-md-6 no-padding" *ngIf="site.id != battviewMobileSiteID">
													<select class="form-control inline-form-control" [(ngModel)]="site.chargerFirmwareVersion">
														<option *ngFor="let version of filterVersions(firmwareVersions.charger, site.chargersfirmwareversion)" [value]="version">{{version}}</option>
													</select>
													<button class="btn btn-default" (click)="pushFirmwareUpdate('charger', site)" [disabled]="!site.chargerFirmwareVersion">{{'device.push_fw' | translate}}</button> <br/>
													<select class="form-control inline-form-control" [(ngModel)]="site.bmsFirmwareType" (ngModelChange)="site.bmsFirmwareVersion = null">
														<option *ngFor="let type of bmsTypes" [ngValue]="type.id">{{type.text}}</option>
													</select>
													<select class="form-control inline-form-control margin-top-xs" [(ngModel)]="site.bmsFirmwareVersion">
														<option *ngFor="let version of filterVersions(firmwareVersions.bms, site, 'bms')" [value]="version">{{version}}</option>
													</select>
													<button class="btn btn-warning" (click)="pushFirmwareUpdate('bms', site)" [disabled]="!site.bmsFirmwareType || !site.bmsFirmwareVersion">{{'device.push_bms_fw' | translate}}</button>
												</div>
												<div class="col-md-6" *ngIf="site.id == battviewMobileSiteID">
												</div>
												<div class="col-md-6 no-padding">
													<select class="form-control inline-form-control" [(ngModel)]="site.battviewFirmwareVersion">
														<option *ngFor="let version of filterVersions(firmwareVersions.battview, site.battviewsfirmwareversion)" [value]="version">{{version}}</option>
													</select>
													<button class="btn btn-default" (click)="pushFirmwareUpdate('battview', site)" [disabled]="!site.battviewFirmwareVersion">{{'device.push_fw' | translate}}</button>
												</div>
											</div>
											<div class="row margin-top-sm">
												<div class="col-md-3 no-padding col-md-offset-2">
													<select class="form-control" [(ngModel)]="site.wifiFirmwareVersion">
														<option *ngFor="let version of filterVersions(firmwareVersions.wifi, site.wififirmwareversion)" [value]="version">{{version}}</option>
													</select>
												</div>
												<div class="col-md-4 no-padding">
													<button class="btn btn-info" (click)="pushFirmwareUpdate('wifi', site)" [disabled]="!site.wifiFirmwareVersion">{{'device.push_wifi_fw' | translate}}</button>
												</div>
											</div>
										</td>
										<td>
											<span class="pointer htl glyphicon glyphicon-list-alt" (click)="showFirmwareRequestsLog(site.id)"></span>
										</td>
										<td *ngIf="userService.hasAccessFunction('control_site_live_socket')">
											<input type="checkbox" class="form-control" [ngModel]="site.charger_live_socket_enable" (ngModelChange)="editSiteLiveSocket(site.id, 'charger', $event)"/>
										</td>
										<td *ngIf="userService.hasAccessFunction('control_site_live_socket')">
											<input type="checkbox" class="form-control" [ngModel]="site.battview_live_socket_enable" (ngModelChange)="editSiteLiveSocket(site.id, 'battview', $event)"/>
										</td>
									</tr>
								</tbody>
							</table>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</div>