import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AgmCoreModule } from '@agm/core';
import { SortablejsModule } from 'ngx-sortablejs';
import { DynamicModule } from 'ng-dynamic-component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TableModule } from 'primeng/table';
import { SliderModule } from 'primeng/slider'; 
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';

import { HeaderComponent } from './header/header.component';
import { LoaderComponent } from './loader/loader.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { SearchPipe } from './side-menu/search.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { PaginationComponent } from './pagination/pagination.component';
import { ServerPaginationComponent } from './server-pagination/server-pagination.component';
import { DropdownDirective } from './directives/dropdown.directive';
import { NotificationMessageComponent } from './notification-message/notification-message.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { ModalComponent } from './modal/modal.component';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ManageDeviceBtnComponent } from './manage-device-btn/manage-device-btn.component';
import { SafePipe } from '../safe.pipe';
import { DealerPipe } from './side-menu/dealer.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { ValuesPipe } from './pipes/values.pipe';
import { SimpleWidgetComponent } from './simple-widget/simple-widget.component';
import { NotesComponent } from './notes/notes.component';
import { TagsComponent } from './tags/tags.component';
import { CustomizedWidgetComponent } from './customized-widget/customized-widget.component';
import { WidgetsLoaderComponent } from './widgets-loader/widgets-loader.component';
import { CustomerEstimatedCountsComponent } from '../home/customer-dashboard/customer-estimated-counts/customer-estimated-counts.component';
import * as map from './widgets-loader/widget.service';
import { CustomerMapComponent } from '../home/customer-dashboard/customer-map/customer-map.component';
import { TranscludeDirective } from './directives/transclude.directive';
import { RoundPipe } from './pipes/round.pipe';
import { SecondsToDateTimePipe } from './pipes/seconds-to-date-time.pipe';
import { DataListTableComponent } from './data-list-table/data-list-table.component';
import { GoogleChartComponent } from './google-chart/google-chart.component';
import { PieChartComponent } from './google-chart/Charts/piechart.component';
import { GooglePieChartService } from './google-chart/Services/google-pie-chart.service';
import { GoogleColumnChartService } from './google-chart/Services/google-column-chart.service';
import { ColumnChartComponent } from './google-chart/Charts/columnchart.component';
import { SiteWidgetComponent } from './site-widget/site-widget.component';
import { ComboChartComponent } from './google-chart/Charts/combochart.component';
import { IntRangePipe } from './pipes/int-range.pipe';
import { LineChartComponent } from './google-chart/Charts/linechart.component';
import { GoogleLineChartService } from './google-chart/Services/google-line-chart.service';
import { BarChartComponent } from './google-chart/Charts/barchart.component';
import { GoogleBarChartService } from './google-chart/Services/google-bar-chart.service';
import { GridV2Component } from './grid-v2/grid-v2.component';
import { AgGridModule } from 'ag-grid-angular';
import { DropDownFilterComponent } from './grid-v2/drop-down-filter/drop-down-filter.component';
import { TranslateFileLoader } from '../TranslateFileLoader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

// AoT requires an exported function for factories
export function TranslateFileLoaderFactory() {
	return new TranslateFileLoader();
}

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		AgmCoreModule,
		ModalModule,
		AlertModule,
		NgSelectModule,
		TableModule,
		SliderModule,
		CalendarModule,
		MultiSelectModule,
		DropdownModule,
		ButtonModule,
		InputTextModule,
		SortablejsModule,
		DynamicModule,
		BsDropdownModule,
		AgGridModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: TranslateFileLoaderFactory,
			}
		}),
	],
	providers: [
		GooglePieChartService,
		GoogleColumnChartService,
		GoogleLineChartService,
		GoogleBarChartService
	],
	declarations: [
		SearchPipe,
		DropdownDirective,
		HeaderComponent,
		LoaderComponent,
		SideMenuComponent,
		PaginationComponent,
		ServerPaginationComponent,
		NotificationMessageComponent,
		GoogleMapComponent,
		ModalComponent,
		FooterComponent,
		BreadcrumbComponent,
		ManageDeviceBtnComponent,
		SafePipe,
		DealerPipe,
		FilterPipe,
		KeysPipe,
		ValuesPipe,
		IntRangePipe,
		SimpleWidgetComponent,
		NotesComponent,
		TagsComponent,
		CustomizedWidgetComponent,
		WidgetsLoaderComponent,
		TranscludeDirective,
		RoundPipe,
		SecondsToDateTimePipe,
		DataListTableComponent,
		GoogleChartComponent,
		PieChartComponent,
		ComboChartComponent,
		ColumnChartComponent,
		SiteWidgetComponent,
		LineChartComponent,
		BarChartComponent,
		GridV2Component,
		DropDownFilterComponent
	],
	exports: [
		CommonModule,
		DropdownDirective,
		HeaderComponent,
		LoaderComponent,
		SideMenuComponent,
		PaginationComponent,
		ServerPaginationComponent,
		NotificationMessageComponent,
		GoogleMapComponent,
		ModalComponent,
		FooterComponent,
		BreadcrumbComponent,
		ManageDeviceBtnComponent,
		SafePipe,
		KeysPipe,
		FilterPipe,
		ValuesPipe,
		IntRangePipe,
		SimpleWidgetComponent,
		NotesComponent,
		TagsComponent,
		CustomizedWidgetComponent,
		WidgetsLoaderComponent,
		TranscludeDirective,
		RoundPipe,
		SecondsToDateTimePipe,
		DataListTableComponent,
		GoogleChartComponent,
		PieChartComponent,
		ColumnChartComponent,
		SiteWidgetComponent,
		LineChartComponent,
		ComboChartComponent,
		BarChartComponent,
		GridV2Component,
		DropDownFilterComponent
	]
})
export class SharedModule { }
