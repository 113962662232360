import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'underscore';
import { SiteDashboardService } from '../../site-dashboard.service';
import { UserService } from '../../../../auth/user.service';
import { TruckviewsDashboardService } from '../truckviews-dashboard/truckviews-dashboard.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';

@Component({
	selector: 'app-site-truck-reports',
	templateUrl: './site-truck-reports.component.html',
	styleUrls: ['./site-truck-reports.component.css']
})
export class SiteTruckReportsComponent implements OnInit, OnDestroy {

	user;
	customerId;
	siteId;
	data;
	siteTruckviewsData;
	siteTags;
	siteTruckviewsTags;
	customerName: string = '';
	siteName: string = '';
	currentSiteSub: any;
	activeDashboardDataSub: any;

	constructor(
		private siteDashboardService: SiteDashboardService,
		private userService: UserService,
		private sideMenu: SideMenuService,
		private truckviewsDashboardService: TruckviewsDashboardService,
		private commonUtil: CommonUtil,
	) {
		this.siteDashboardService.setActiveSubTab('reports');
	}

	ngOnInit() {
		this.user = this.userService.getCurrentUser();

		this.currentSiteSub = this.sideMenu.currentSite.subscribe(siteInfo => {
			this.customerId		= siteInfo.customerid;
			this.siteId			= siteInfo.id;
			this.customerName	= siteInfo.customer_name;
			this.siteName		= siteInfo.name;

			if(this.customerId && this.siteId) {
				this.activeDashboardDataSub = this.siteDashboardService.activeDashboardData.subscribe(data => {
					let tempData: any = data
					this.truckviewsDashboardService.getTruckviews({customerid: this.customerId, siteid: this.siteId, getTags: true}).subscribe((response:any) => {
			
						this.siteTruckviewsData	= this.commonUtil.arrayToAssociativeArray(response.list, 'id');
						this.siteTags			= [];
						this.siteTruckviewsTags	= {};
			
						for(var i in response.tags.siteTags) {
							this.siteTags.push(response.tags.siteTags[i]);
						}
				
						for(var truckviewId in response.tags.deviceTags) {
							var truckviewTagsIds = response.tags.deviceTags[truckviewId];
							for (var i in truckviewTagsIds) {
								var tagId = truckviewTagsIds[i];
								if (!this.siteTruckviewsTags[tagId]) {
									this.siteTruckviewsTags[tagId] = [];
								}
								this.siteTruckviewsTags[tagId].push(truckviewId);
							}
						}
						tempData.truckviewsData     = this.siteTruckviewsData;
						tempData.siteTags          = this.siteTags;
						tempData.siteTruckviewsTags = this.siteTruckviewsTags;

						this.data = tempData;
					});
				});
				this.siteDashboardService.getDashboardData(this.customerId, this.siteId);
			}
		});
	}

	ngOnDestroy() {
		if(this.currentSiteSub)
			this.currentSiteSub.unsubscribe();
		if(this.activeDashboardDataSub)
			this.activeDashboardDataSub.unsubscribe();
	}
}