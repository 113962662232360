import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { DevicesDashboardService } from '../devices-dashboard.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { ChargersDashboardService } from '../../chargers/chargers-dashboard/chargers-dashboard.service';
import { BattviewsDashboardService } from '../../battviews/battviews-dashboard/battviews-dashboard.service';
import { ngxCsv } from "ngx-csv/ngx-csv";

@Component({
	selector: 'app-config-track',
	templateUrl: './config-track.component.html',
	styleUrls: ['./config-track.component.css']
})
export class ConfigTrackComponent implements OnInit {

	@Input() device: any = {};
	@Input() deviceType: string = null;


	configsToCompare: any;
	gridColumns: any;
	trackModalData: any = {};
	compareModalData: any = {};
	gridData: any[] = [];
	arrayFields: string[] = [];

	@ViewChild("serverPagination") serverPagination;
	@ViewChild("trackModal") trackModal;
	@ViewChild("compareModal") compareModal;

	@Output() updateAppearanceElementsFlags = new EventEmitter<any>(true);

	constructor(
		private devicesDashboardService: DevicesDashboardService,
		private commonUtil: CommonUtil,
		private battviewsDashboard: BattviewsDashboardService,
		private chargersDashboard: ChargersDashboardService
	) { }

	ngOnInit() {
		if(this.deviceType =='battview') {
			this.arrayFields = this.battviewsDashboard.arrayFields;
			this.updateAppearanceElementsFlags.emit({
				addNoteEnabled: false,
				pageHasFilterByDate: true,
				pageHasBattviewTags: true,
				hasResetPLC: false,
				pageHasManageBtn: true,
				pageHasTimesInfo: true,
			});
		} else {
			this.arrayFields = this.chargersDashboard.arrayFields;
		}

		this.gridColumns = [
			{headerName: "ID", field: "id", type:"number", width: 120},
			{headerName: "Change Time", field: "change_time", width: 200, filterParams: {defaultOption: 'startsWith'}},
			{headerName: "User Name", field: "userName", width: 250},
			{headerName: "Memory Signature", field: "memory_signature", type:"number", width: 150},
			{
				headerName: "Configs", field: "configs", width: 200, execludeOnExport: true,
				onCellClicked: ($e) => {
					this.onTrackCellClicked($e);
				},
				cellRendererSelector:function (params) {
					params['label'] = 'Show configs';
					return {
						component: 'linkCellRenderer',
						params: params,
					};
				}
			},
			{headerName: "Insertion Time", field: "insertion_time", width: 200, filterParams: {defaultOption: 'startsWith'}},
		];
	}

	ngOnChanges() {
		this.getDeviceConfigTrack(false, true);
	}

	onDeviceChanged(device) {
		this.device = device;
		this.getDeviceConfigTrack(false, true);
	}

	onTrackCellClicked(cycle) {
		this.trackModalData = this.formatChanges(cycle.data.configs);
		this.trackModal.show();
	}

	formatChanges(configs) {
		if(this.deviceType == 'charger')
			return this.chargersDashboard.formatChargerQueuedChanges(this.device, configs, 'plainObject');

		return this.battviewsDashboard.formatBattviewQueuedChanges(this.device, configs, 'plainObject');
	}

	getDeviceConfigTrack(isBack=false, firstTime=false) {

		if(!this.device.id)
			return;

		let searchId = this.serverPagination.lastId;
		if(isBack)
			searchId = this.serverPagination.firstId;

		let pageNum = this.serverPagination.pageNum;

		if(firstTime)
			this.serverPagination.init();

		this.devicesDashboardService.getDeviceConfigTrack(this.deviceType, this.device.id, {searchId, isBack, pageNum}).subscribe((response: any) => {
			response.forEach((record, idx) => {
				let changeTimeInZone = this.commonUtil.getZoneTimestampFromUTC(this.device.zoneid, moment(record.change_time).utc().unix(), true);
				record.change_time = this.commonUtil.getDateFormattedFromUnixTimeStamp(changeTimeInZone);
				record.insertion_time = this.commonUtil.getDateFormattedFromUnixTimeStamp(new Date(Date.parse(record.insertion_time)).getTime()/1000);
				record.userName = record.userName;
			});
			this.gridData = response;
			this.serverPagination.updatePagination(this.gridData, isBack, firstTime);
		});
	}

	gridEvent(event) {
		if(event.selection && event.selection.length == 2)
			this.configsToCompare = event.selection;
		else
			this.configsToCompare = null;
	}

	compareConfigs() {

		let oldConfig		= {};
		let oldValsIdx		= 1;
		let newConfig		= this.configsToCompare[0].configs;
		let ignoreFields	= ['memorysignature', 'lastchangetime', 'lastchangeuserid'];

		if(this.configsToCompare[1].configs.memorysignature > this.configsToCompare[0].configs.memorysignature) {

			newConfig	= this.configsToCompare[1].configs;
			oldValsIdx	= 0;
		}

		for(let key in newConfig) {

			if(ignoreFields.includes(key))
				continue;

			let tempOld = this.configsToCompare[oldValsIdx].configs[key];
			if(this.arrayFields.includes(key)) {

				if(tempOld && newConfig[key] && !this.commonUtil.arrayCompare(tempOld, newConfig[key]))
					oldConfig[key] = tempOld;
			} else if(tempOld != newConfig[key]) {

				oldConfig[key] = tempOld;
			}
		}

		this.compareModalData = {
			'old': this.formatChanges(oldConfig),
			'new': this.formatChanges(newConfig)
		};

		this.compareModal.show();
	}

  exportToExcel() {
    const data = [];
    for (let [key, value] of Object.entries(this.trackModalData)) {
      data.push({Property: key, Value:value})
    }
    new ngxCsv(data, `${this.deviceType}-config-track`);
  }
}
