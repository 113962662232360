<div class="row">
  <div id="page-wrapper" >
    <div id="page-inner">
      <div class="boxs3">
        <ul class="nav nav-pills">
          <li [ngClass]="{active:isTabSet('devices')}" (click)="setTab('devices')">
            <a><span style="font-family:sans-serif;font-weight: bold;">{{'g.devices' | translate}}</span></a>
          </li>
          
          <li [ngClass]="{active:isTabSet('customer-site')}" (click)="setTab('customer-site')">
            <a><span style="font-family:sans-serif;font-weight: bold;">{{'g.customers_n_sites' | translate}}</span></a>
          </li>
        </ul>
            </div>
            <ul class="nav nav-pills padding-8" *ngIf="isTabSet('devices')">
        <li [ngClass]="{active:isTabSet('charger', true)}" (click)="setTab('charger', true);resetSelections();">
          <a><span>{{'g.chargers' | translate}}</span></a>
        </li>
        
        <li [ngClass]="{active:isTabSet('battview', true)}" (click)="setTab('battview', true);resetSelections();">
          <a><span>{{'g.battviews' | translate}}</span></a>
        </li>
      </ul>
        <ul class="nav nav-pills padding-8" *ngIf="isTabSet('customer-site')">
        <li [ngClass]="{active:isTabSet('customers', true)}" (click)="setTab('customers', true);">
          <a><span>{{'g.customers' | translate}}</span></a>
        </li>
        
        <li [ngClass]="{active:isTabSet('sites', true)}" (click)="setTab('sites', true);">
          <a><span>{{'g.sites' | translate}}</span></a>
        </li>
      </ul>
      <div class="boxs3 col-md-12" *ngIf="isTabSet('devices')">
        <div class="col-md-1 dirtySettingsField">&nbsp;</div>
        <div><strong>: {{'g.not_verified' | translate}}</strong></div>
        <div class="col-md-1 invalid-input margin-top-5">&nbsp;</div>
        <div class="margin-top-5"><strong>: {{'g.not_exists' | translate}}</strong></div>
        <div class="col-md-12 padding-8"><strong>* {{'g.not_verified_or_not_exists' | translate}}</strong></div>
      </div>
      <div class="col-md-12 panel panel-default" *ngIf="isTabSet('charger', true)">
        <div class="panel-body">
          <div class="col-md-11">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>{{'g.select' | translate}}</th>
                  <th>{{'g.actview_id' | translate}}</th>
                  <th>{{'device.serial_number' | translate}} ({{'device.charger_id' | translate}})</th>
                  <th>{{'g.requested_by_user' | translate}}</th>
                  <th>{{'site.dealer_name' | translate}}</th>
                  <th>{{'site.service_dealer_name' | translate}}</th>
                  <th>{{'device.customer_name' | translate}}</th>
                  <th>{{'site.site_name' | translate}}</th>
                  <th>{{'g.time_requested' | translate}}</th>
                </tr>
              </thead>
              <tbody>
              <tr *ngFor="let charger of chargersList" id="charger-{{charger.deviceid}}">
                <td><input type="checkbox" class="form-control" name="charger_{{charger.deviceid}}" [(ngModel)]="chargers[charger.deviceid]" (change)="updateMassValues('charger', charger.deviceid, chargers[charger.deviceid], true)" id="chargerCheck-{{charger.deviceid}}"/></td>
                <td>{{charger.deviceid}}</td>
                <td>{{chargersInfo[charger.deviceid].serialnumber}} ({{chargersInfo[charger.deviceid].deviceprettyname}})</td>
                <td *ngIf="!charger.requestedbyuserid || !usersInfo[charger.requestedbyuserid]; else elseChargerRuleExp">
                  -
                </td>
                <ng-template #elseChargerRuleExp>
                  <td [ngSwitch]="userService.userRoles[usersInfo[charger.requestedbyuserid].roleid]">
                    <span *ngSwitchCase="'client'">
                      {{usersInfo[charger.requestedbyuserid].firstname + ' ' + usersInfo[charger.requestedbyuserid].lastname}} - ({{customersInfo[usersInfo[charger.requestedbyuserid].customerid].name}})
                    </span>
                    <span *ngSwitchCase="'dealer'">
                      {{usersInfo[charger.requestedbyuserid].firstname + ' ' + usersInfo[charger.requestedbyuserid].lastname}} - ({{dealersInfo[usersInfo[charger.requestedbyuserid].dealerid].name}})
                    </span>
                    <span *ngSwitchCase="'oem'">
                      {{usersInfo[charger.requestedbyuserid].firstname + ' ' + usersInfo[charger.requestedbyuserid].lastname}} - ({{oemsInfo[usersInfo[charger.requestedbyuserid].oemid].name}})
                    </span>
                    <span *ngSwitchDefault>-</span>
                  </td>
                </ng-template>
                <td [ngClass]="{'invalid-input':charger.dealerid > 0 && !dealersInfo[charger.dealerid]}">
                  {{getDealerName(charger.dealerid)}}
                </td>
                <td [ngClass]="{'invalid-input':charger.servicedealerid > 0 && !dealersInfo[charger.servicedealerid]}">
                  {{getDealerName(charger.servicedealerid)}}
                </td>
                <td *ngIf="customersInfo && customersInfo[charger.customerid]; else customerElseExp" [ngClass]="{'dirtySettingsField pointer':!customersInfo[charger.customerid].isverified}" (click)="goToEditCustomer(charger.customerid);">
                  {{getCustomerName(charger.customerid)}}
                </td>
                <ng-template #customerElseExp>
                  <td class="invalid-input">
                    {{getCustomerName(charger.customerid)}}
                  </td>
                </ng-template>
                <td *ngIf="sitesInfo && sitesInfo[charger.siteid]; else siteElseExp" [ngClass]="{'dirtySettingsField pointer':!sitesInfo[charger.siteid].isverified}" (click)="goToEditSite(charger.customerid, charger.siteid);">
                  {{getSiteName(charger.siteid)}}
                </td>
                <ng-template #siteElseExp>
                  <td class="invalid-input">
                    {{getSiteName(charger.siteid)}}
                  </td>
                </ng-template>
                <td>{{charger.timerequested | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-1">
            <div class="col-md-12" *ngIf="chargersList.length > 0">
              <button class="btn btn-success tcenter" (click)="validateDeviceOwnerChangeRequests('approve')" [disabled]="massChargers.length == 0 || disableChargerApprove" [innerHTML]="'g.approve_request' | translate | safe: 'html'"></button>
            </div>
            <div class="col-md-12 margin-top-5" *ngIf="chargersList.length > 0">
              <button class="btn btn-default tcenter" [routerLink]="['/devices','devices-management','charger', devicesIDs]" [queryParams]="{isAdmin: true}" [disabled]="massChargers.length == 0 || disableChargerApprove" [innerHTML]="'g.edit_request' | translate | safe: 'html'"></button>
            </div>
            <div class="col-md-12 margin-top-5" *ngIf="chargersList.length > 0">
              <button class="btn btn-danger tcenter" (click)="validateDeviceOwnerChangeRequests('reject')" [disabled]="massChargers.length == 0" [innerHTML]="'g.reject_request' | translate | safe: 'html'"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 panel panel-default" *ngIf="isTabSet('battview', true)">
        <div class="panel-body">
          <div>
            <div class="col-md-11">
              <table class="table table-striped">
                <thead>
                  <tr>
					<th>{{'g.select' | translate}}</th>
					<th>{{'g.actview_id' | translate}}</th>
					<th>{{'device.serial_number' | translate}} ({{'device.battery_id' | translate}})</th>
					<th>{{'g.requested_by_user' | translate}}</th>
					<th>{{'site.dealer_name' | translate}}</th>
					<th>{{'site.service_dealer_name' | translate}}</th>
					<th>{{'device.customer_name' | translate}}</th>
					<th>{{'site.site_name' | translate}}</th>
					<th>{{'g.time_requested' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                <tr *ngFor="let battview of battviewsList" id="battview-{{battview.deviceid}}">
                  <td><input type="checkbox" class="form-control" name="battview_{{battview.deviceid}}" [(ngModel)]="battviews[battview.deviceid]" (change)="updateMassValues('battview', battview.deviceid, battviews[battview.deviceid], true)" id="battviewCheck-{{battview.deviceid}}"/></td>
                  <td>{{battview.deviceid}}</td>
                  <td>{{battviewsInfo[battview.deviceid].serialnumber}} ({{battviewsInfo[battview.deviceid].deviceprettyname}})</td>
                  <td *ngIf="!battview.requestedbyuserid || !usersInfo[battview.requestedbyuserid]; else elseBattviewRuleExp">
                    -
                  </td>
                  <ng-template #elseBattviewRuleExp>
                    <td [ngSwitch]="userService.userRoles[usersInfo[battview.requestedbyuserid].roleid]">
                      <span *ngSwitchCase="'client'">
                        {{usersInfo[battview.requestedbyuserid].firstname + ' ' + usersInfo[battview.requestedbyuserid].lastname}} - ({{customersInfo[usersInfo[battview.requestedbyuserid].customerid].name}})
                      </span>
                      <span *ngSwitchCase="'dealer'">
                        {{usersInfo[battview.requestedbyuserid].firstname + ' ' + usersInfo[battview.requestedbyuserid].lastname}} - ({{dealersInfo[usersInfo[battview.requestedbyuserid].dealerid]?.name || ' - '}})
                      </span>
                      <span *ngSwitchCase="'oem'">
                        {{usersInfo[battview.requestedbyuserid].firstname + ' ' + usersInfo[battview.requestedbyuserid].lastname}} - ({{oemsInfo[usersInfo[battview.requestedbyuserid].oemid].name}})
                      </span>
                      <span *ngSwitchDefault>-</span>
                    </td>
                  </ng-template>
                  <td ng-class="{'invalid-input':battview.dealerid > 0 && !dealersInfo[battview.dealerid]}">
                      {{getDealerName(battview.dealerid)}}
                  </td>
                  <td [ngClass]="{'invalid-input':battview.servicedealerid > 0 && !dealersInfo[battview.servicedealerid]}">
                    {{getDealerName(battview.servicedealerid)}}
                  </td>
                  <td
                    *ngIf="customersInfo && customersInfo[battview.customerid]; else customerElseExp1"
                    [ngClass]="{'dirtySettingsField pointer':!customersInfo[battview.customerid].isverified}"
                    (click)="goToEditCustomer(battview.customerid);"
                  >
                    {{getCustomerName(battview.customerid)}}
                  </td>
                  <ng-template #customerElseExp1>
                    <td class="invalid-input">
                      {{getCustomerName(battview.customerid)}}
                    </td>
                  </ng-template>
                  <td *ngIf="sitesInfo && sitesInfo[battview.siteid]; else siteElseExp1" [ngClass]="{'dirtySettingsField pointer':!sitesInfo[battview.siteid].isverified}" (click)="goToEditSite(battview.customerid, battview.siteid);">
                    {{getSiteName(battview.siteid)}}
                  </td>
                  <ng-template #siteElseExp1>
                    <td class="invalid-input">
                      {{getSiteName(battview.siteid)}}
                    </td>
                  </ng-template>
                  <td>{{battview.timerequested | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-1" *ngIf="battviewsList.length > 0">
              <div class="col-md-12">
                <button class="btn btn-success tcenter" (click)="validateDeviceOwnerChangeRequests('approve')" [disabled]="massBattviews.length == 0 || disableBattviewApprove" [innerHTML]="'g.approve_request' | translate | safe: 'html'"></button>
              </div>
              <div class="col-md-12 margin-top-5">
                <button class="btn btn-default tcenter" [routerLink]="['/devices','devices-management','battview', devicesIDs]" [queryParams]="{isAdmin: true}" [disabled]="massBattviews.length == 0 || disableBattviewApprove" [innerHTML]="'g.edit_request' | translate | safe: 'html'"></button>
              </div>
              <div class="col-md-12 margin-top-5">
                <button class="btn btn-danger tcenter" (click)="validateDeviceOwnerChangeRequests('reject')" [disabled]="massBattviews.length == 0" [innerHTML]="'g.reject_request' | translate | safe: 'html'"></button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 panel panel-default" *ngIf="isTabSet('customers', true)">
        <div class="panel-body">
          <div class="col-md-11">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>{{'device.customer_name' | translate}}</th>
                  <th>{{'g.modification_date' | translate}}</th>
                  <th>{{'g.actions' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let customer of customersList">
                  <td>{{customer.name}}</td>
                  <td>{{customer.modificationdate | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
                  <td>
                    <button class="btn btn-success tcenter" (click)="validatePendingCustomerSite(customer.id, 'approve')">{{'g.approve' | translate}}</button>
                    <button class="btn btn-default tcenter" (click)="goToEditCustomer(customer.id, true)">{{'g.edit' | translate}}</button>
                    <button class="btn btn-danger tcenter" (click)="validatePendingCustomerSite(customer.id, 'reject')">{{'g.reject' | translate}}</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-12 panel panel-default" *ngIf="isTabSet('sites', true)">
        <div class="panel-body">
          <div class="col-md-11">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>{{'site.site_name' | translate}}</th>
                  <th>{{'g.country' | translate}}</th>
                  <th>{{'g.state' | translate}}</th>
                  <th>{{'g.address' | translate}}</th>
                  <th>{{'g.modification_date' | translate}}</th>
                  <th>{{'g.actions' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let site of sitesList">
                  <td>{{site.name}}</td>
                  <td>{{site.countryid}}</td>
                  <td>{{site.stateid}}</td>
                  <td>{{site.address}}</td>
                  <td>{{site.modificationdate | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
                  <td>
                    <button class="btn btn-success tcenter" [disabled]="!site.dealer_id || !site.act_user_id" (click)="validatePendingCustomerSite(site.id, 'approve')">{{'g.approve' | translate}}</button>
                    <button class="btn btn-default tcenter" (click)="goToEditSite(site.customerid, site.id, true)">{{'g.edit' | translate}}</button>
                    <button class="btn btn-danger tcenter" (click)="validatePendingCustomerSite(site.id, 'reject')">{{'g.reject' | translate}}</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>