import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'underscore';
import { UserService } from '../../auth/user.service';
import { User } from '../../auth/user.model';
import { SideMenuService } from './side-menu.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Customer } from '../models/customer.model';
import { Site } from '../models/site.model';
import { Subscription } from 'rxjs';
import { trigger, style, animate, transition } from '@angular/animations';
import { CommonUtil } from '../services/utility/common.service';

@Component({
	selector: 'app-side-menu',
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.css'],
	animations: [
		trigger(
			'enterAnimation', [
				transition(':enter', [
					style({transform: 'translateX(-100%)'}),
					animate('250ms', style({transform: 'translateX(0)'}))
				]),
				transition(':leave', [
					style({transform: 'translateX(0)'}),
					animate('250ms', style({transform: 'translateX(-100%)'}))
				])
			]
		)
	],
})

export class SideMenuComponent implements OnInit {
	dealersList = [{}];
	salesPersonsList = [{}];
	sitesInfo;
	customers = [];
	sitesCustomerMap = [];

	notACTUser: boolean;
	ACTUser: boolean;
	currentUser: any = {};

	stateOptions: string[] = [];
	actIntelligentFilter = {
		text: '',
		dealer: '',
		demo: '',
		managedByDealer: '',
		act: '',
		salesPerson: '',
		country: '',
		state: ''
	};

	countryOptions = {
		usa: { value: 'United States', text: 'United States' },
		canada: { value: 'Canada', text: 'Canada' }
	};

	currentCustomer: Customer = new Customer();
	currentSite: Site = new Site();

	currentUserSubscription: Subscription = new Subscription();
	expandedCustomersSubscription: Subscription = new Subscription();
	currentCustomerSubscription: Subscription = new Subscription();
	getUserSiteInfoSubscription: Subscription = new Subscription();
	currentSiteSubscription: Subscription = new Subscription();
	hideMenuView = false;

	constructor(
		private httpClient: HttpClient,
		private userService: UserService,
		public sideMenu: SideMenuService,
		private router: Router,
		private route: ActivatedRoute,
		private cdref: ChangeDetectorRef,
		private commonService: CommonUtil
	) {}

	ngOnInit() {
		this.router.routeReuseStrategy.shouldReuseRoute = function(future, curr) {
			return false;
		}
		this.router.events.subscribe((evt) => {
			if (evt instanceof NavigationEnd) {
				this.router.navigated = false;
				window.scrollTo(0, 0);
			}
		});

		this.currentUserSubscription = this.userService.currentUser.subscribe((user: User) => {
			this.ACTUser = user.isACTuser == true;
			this.notACTUser = user.isACTuser == false;
			this.currentUser = user;
		});

		// to respond to removing customer tag on sites menu page
		this.expandedCustomersSubscription = this.sideMenu.expandedCustomers.subscribe(expandedCustomers =>
			this.expandCustomersUI(expandedCustomers)
		);

		// to respond to setting active customer from customer page
		this.currentCustomerSubscription = this.sideMenu.currentCustomer.subscribe(customer => {
			this.currentCustomer = customer;
			this.cdref.detectChanges();
		});

		this.currentSiteSubscription = this.sideMenu.currentSite.subscribe(site => {
			this.currentSite = site;
			this.cdref.detectChanges();
		});

		this.getUserSiteInfoSubscription = this.sideMenu.getUserSitesInfo().subscribe((data: any) => {
			this.dealersList = data.dealersList;
			let sitesInfo = data.sitesInfo;

			for(let siteIdx in sitesInfo) {
				sitesInfo[siteIdx] = this.commonService.decompress(sitesInfo[siteIdx], 'sites');

				if(sitesInfo[siteIdx].countryid == "United States" && !this.stateOptions.includes(sitesInfo[siteIdx].stateid))
					this.stateOptions.push(sitesInfo[siteIdx].stateid)
			}

			this.stateOptions.sort((a, b) => a.localeCompare(b));
			this.sitesInfo = sitesInfo;
			this.salesPersonsList = data.salesPersonsList;
			this.mapSitesToCustomers();
			this.setCustomers();
			this.sideMenu.setAllSites(_.values(this.sitesInfo));

			if (this.router.url == '/sites-map') {
				this.sideMenu.setCurrentSites(_.values(this.sitesInfo));
				this.sideMenu.setExpandedSites(_.values(this.getFilteredSitesInfo()));
				this.sideMenu.allFilteredSites = _.values(this.getFilteredSitesInfo());
			}
		});

		this.sideMenu.hideMenuView.subscribe(value => {
			this.hideMenuView = value;
		});
	}

	applyFilter(filter, value){
		this.actIntelligentFilter[filter] = value;
		this.mapSitesToCustomers(true);
		this.setCustomers(true);
		let filteredSitesInfo = _.values(this.getFilteredSitesInfo());
		this.sideMenu.setExpandedSites(filteredSitesInfo);
		this.sideMenu.allFilteredSites = filteredSitesInfo;
		this.sideMenu.setExpandedCustomers([]);
	}

	expandCustomersUI(expandedCustomers?: Object) {
		expandedCustomers = expandedCustomers ? expandedCustomers : this.sideMenu.getExpandedCustomers();
		if (this.customers.length > 0) {
			let expandedCustomersIds = _.pluck(expandedCustomers, "id");
			this.customers.map(customer => {
				if (expandedCustomersIds.indexOf(customer.id) > -1) {
					customer.expanded = true;
				} else {
					customer.expanded = false;
				}
			});
		}
	}

	mapSitesToCustomers(filterApplied=false) {
		let sitesCustomerMap = [];
		let filteredSitesInfo = this.getFilteredSitesInfo();
		_.values(filteredSitesInfo).forEach((item) => {
			if (!sitesCustomerMap.hasOwnProperty(item.customerid)) {
				sitesCustomerMap[item.customerid] = [item];
			} else {
				sitesCustomerMap[item.customerid].push(item);
			}
		});
		this.sitesCustomerMap = sitesCustomerMap;
		this.sideMenu.setFilteredSitesCustomerMap(sitesCustomerMap);
		if(!filterApplied)
			this.sideMenu.setSitesCustomerMap(sitesCustomerMap);
	}

	setCustomers(filterApplied=false) {
		let customersById = {};
		let filteredSitesInfo = this.getFilteredSitesInfo();
		_.values(filteredSitesInfo).forEach((site) => {
			let key = site.customerid;
			if (customersById.hasOwnProperty(key)) {
				if (site.act_intelligent_type != 0) {
					customersById[key].had_act_intelligence = true;
				}
				if (site.dealer_id) {
					customersById[key].dealersIDs.push(site.dealer_id);
				}
				if (site.service_dealer_id) {
					customersById[key].serviceDealersIDs.push(site.service_dealer_id);
				}
				if (site.truck_dealer_id) {
					customersById[key].truckDealersIDs.push(site.truck_dealer_id);
				}
			} else {
				customersById[key] = {
					id: site.customerid,
					name: site.customer_name,
					dealersIDs: site.dealer_id ? [site.dealer_id] : [],
					had_act_intelligence: site.act_intelligent_type != 0 ? true : false,
					serviceDealersIDs: site.service_dealer_id ? [site.service_dealer_id] : [],
					truckDealersIDs: site.truck_dealer_id ? [site.truck_dealer_id] : [],
					sites: this.sitesCustomerMap[site.customerid].sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0),
					expanded: false
				};
			}
		});
		this.customers =  _.values(customersById).sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0);
		if(!filterApplied){
			this.sideMenu.setAllCustomers(customersById);
		}
		this.expandCustomersUI();
	}

	getFilteredSitesInfo(){
		let usedFilters = {};
		if (this.actIntelligentFilter.country != this.countryOptions.usa.value)
			this.actIntelligentFilter.state = '';

		for(let filter in this.actIntelligentFilter){
			let filterValue = this.actIntelligentFilter[filter];
			if(!filterValue)
				continue;
			if(filter == 'text' && (filterValue == null || filterValue.trim().length == 0))
				continue;

			usedFilters[filter] = filterValue;
		}
		let filteredSitesInfo = {};
		_.values(this.sitesInfo).forEach((site) => {
			let willBeAdded = true;
			for(let filter in usedFilters){

				let filterValue = this.actIntelligentFilter[filter];
				switch (filter) {
					case 'text':
						willBeAdded = willBeAdded && (site.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1 || site.customer_name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1);
						break;
					case 'dealer':
						willBeAdded = willBeAdded && (site.dealer_id == filterValue || site.service_dealer_id == filterValue);
						break;
					case 'demo':
						if(filterValue == 'include')
							willBeAdded = willBeAdded && site.site_subscription_type == 'Demo';
						if(filterValue == 'dontInclude')
							willBeAdded = willBeAdded && site.site_subscription_type != 'Demo';
						break;
					case 'managedByDealer':
						if(filterValue == 'include')
							willBeAdded = willBeAdded && site.managed_by_dealer;
						if(filterValue == 'dontInclude')
							willBeAdded = willBeAdded && !site.managed_by_dealer;
						break;
					case 'act':
						if(filterValue == 'include')
							willBeAdded = willBeAdded && site.act_intelligent_type != '0';
						if(filterValue == 'dontInclude')
							willBeAdded = willBeAdded && site.act_intelligent_type == '0';
						break;
					case 'salesPerson':
						willBeAdded = willBeAdded && (site.act_user_id == filterValue);
						break;
					case 'country':
						willBeAdded = willBeAdded && (site.countryid == filterValue);
						break;
					case 'state':
							willBeAdded = willBeAdded && (site.stateid == filterValue);
						break;
				}
			}
			if(!willBeAdded)
				return;
			filteredSitesInfo[site.id] = site;
		});
		return filteredSitesInfo;
	}

	onSelectCustomer($event, customer) {
		if (this.router.url == '/sites-map' && !customer.expanded) {
			this.toggleCustomerExpand(customer);
			return ;
		} else if (/autoconnect-devices/.test(this.router.url)) {
			this.toggleCustomerExpand(customer);
		} else {
			this.goToCustomerPage(customer);
		}
	}

	toggleCustomerExpand(customer: Customer) {
		customer.expanded = !customer.expanded;
		let customerObj = {id: customer.id, name: customer.name};
		if (customer.expanded) {
			this.sideMenu.addExpandedCustomer(customer.id, customer.name);
		} else {
			this.sideMenu.removeExpandedCustomer(customer.id);
		}
	}

	goToCustomerPage(customer: Customer) {
		this.router.navigate(['main', customer.id]);
	}

	goToSitePage(site: Site) {
		 this.router.navigate([site.customerid, site.id]);
	}

	selectSite(site) {
		let clickedSite = this.sideMenu.getClickedSite();
		let siteInfoBoxVisibility = this.sideMenu.getSiteInfoBoxVisibility();
		let url = this.router.url.split("/")[1];
		if(/autoconnect-devices/.test(this.router.url)) {
			this.router.navigate([site.customerid, site.id, 'autoconnect-devices']);
		} else if ((url == 'sites-map' ||url == 'main') && (!siteInfoBoxVisibility || (siteInfoBoxVisibility && clickedSite.id != site.id))) {
			site.clicked = true;
			this.sideMenu.setClickedSite(site);
		} else {
			 this.goToSitePage(site);
		}
	}

	ngOnDestroy() {
		this.cdref.detach();
		this.currentUserSubscription.unsubscribe();
		this.expandedCustomersSubscription.unsubscribe();
		this.currentCustomerSubscription.unsubscribe();
		this.getUserSiteInfoSubscription.unsubscribe();
		this.currentSiteSubscription.unsubscribe();
	}
}
