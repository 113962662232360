<div class="boxs3 margin-top-lg">
	<div class="col-md-12 margin-bottom-xlg">
		<div class="col-md-2">
			<h4>{{'g.select_date_range' | translate}}</h4>
		</div>
		<div class="col-md-2">
			<div class="input-group">
				<input class="form-control" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="selectedDateRange.fromDate" bsDatepicker #fromDate="bsDatepicker" (ngModelChange)="dateRangeChange()" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
				<span class="input-group-btn">
					<button class="btn btn-default btn-date" (click)="fromDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
				</span>
			</div>
		</div>
		<div class="col-md-2">
			<div class="input-group" id="datetimepicker2">
				<input class="form-control" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="selectedDateRange.toDate" bsDatepicker #toDate="bsDatepicker" (ngModelChange)="dateRangeChange()" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
				<span class="input-group-btn">
					<button class="btn btn-default btn-date" (click)="toDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
				</span>
			</div>
		</div>
	</div>
	<div class="col-md-12 margin-bottom-xlg">
		<div class="col-md-2">
			<h4>{{'device.status' | translate}}</h4>
		</div>
		<div class="col-md-10">
			<span *ngFor="let key of keys" class="col-md-2">
				<input type="checkbox" [checked]="typesStatus[key]" (change)="updateStatuses(key)" /> {{actFirstService.getGroupName(key)}}
			</span>
		</div>
	</div>

	<div class="col-md-12 margin-bottom-xlg">
		<div class="col-md-2">
			<h4>{{'g.display' | translate}}</h4>
		</div>
		<div class="col-md-10">
			<span class="col-md-2">
				<input type="checkbox" [checked]="displayStatus.avg" (change)="updateDisplay('avg')" /> {{'act_first.average' | translate}}
			</span>
			<span class="col-md-2">
				<input type="checkbox" [checked]="displayStatus.max" (change)="updateDisplay('max')" /> {{'g.max' | translate}}
			</span>
			<span class="col-md-2">
				<input type="checkbox" [checked]="displayStatus.min" (change)="updateDisplay('min')" /> {{'g.min' | translate}}
			</span>
		</div>
	</div>

	<div>
		<app-grid-v2
			id="actfirst_daily_availability_report"
			[columns]="gridColumns"
			[data]="gridRows"
			[settings]="{
				hasSelectColumn: false
			}"
		></app-grid-v2>
	</div>

	<div class="margin-top-lg" *ngFor="let groupChart of groupCharts">
		<simple-widget [title]="groupChart.groupname" *ngIf="hasAnyStatusEnabled && hasAnyDisplayEnabled && groupChart.dataTable.length > 2">
			<div class="margin-bottom toolss2">
				<button class="fa fa-print" data-toggle="tooltip" title="Print" (click)="printChart(groupChart.groupname, groupChart.groupid);"></button>
			</div>
			<div class="margin-top-xlg">
				<line-chart
					(onChartReady)="generateImageURI($event)"
					[data]="groupChart.dataTable"
					[config]="config"
					[elementId]="groupChart.groupid">
				</line-chart>
			</div>
		</simple-widget>
	</div>
</div>