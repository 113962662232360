import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import * as _ from 'underscore';
import * as moment from 'moment';
import { DecimalPipe } from '@angular/common';
import { DeviceManagementService } from '../device-management.service';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { CommonUtil } from '../../shared/services/utility/common.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ChargersDashboardService } from '../../home/site-dashboard/chargers/chargers-dashboard/chargers-dashboard.service';
import { BattviewsDashboardService } from '../../home/site-dashboard/battviews/battviews-dashboard/battviews-dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../auth/user.service';
import { User } from '../../auth/user.model';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../admin/admin.service';
import { SiteDashboardService } from '../../home/site-dashboard/site-dashboard.service';
import { DevicesDashboardService } from '../../home/site-dashboard/devices/devices-dashboard.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';

@Component({
	selector: 'app-service-page',
	templateUrl: './service-page.component.html',
	styleUrls: ['./service-page.component.css']
})
export class ServicePageComponent implements OnInit, OnDestroy {
	sites: any[];
	productionIps: any[];
	allSites: any[];
	selectedSite: any = null;

	faults: {
		siteInfo: any,
		siteTags: any,
		battviewTags: any[],
		chargerTags: any[],
		siteConnectivityStatus: any[],
		battviewConnectivityDevices: any[],
		chargerConnectivityDevices: any[],
		chargerPLC: any[],
		battviewPLC: any[],
		chargerFaults: any[],
		PMFaults: any[],
		battviewFaults: any[],
		chargersNotUsed: any[],
		battviewsNotUsed: any[],
		battviewsTimeZone: any[],
		chargersTimeZone: any[],
		battviewsLostTime: any[],
		chargersLostTime: any[],
		battviewsFirmware: any[],
		chargersFirmware: any[],
		disabledBV: any[],
		disabledCH: any[],
		chargersWithError: any[],
		battviewsWithError: any[],
		BVflashIssue: any[],
		BVhighResetsCount: any[],
		chargersCalibration: any[],
		invoiceMismatchCounts: any,
		highEBUBatteries: any[]
	} = {
		siteInfo: {},
		siteTags: {},
		battviewTags: [],
		chargerTags: [],
		siteConnectivityStatus: [],
		battviewConnectivityDevices: [],
		chargerConnectivityDevices: [],
		chargerPLC: [],
		battviewPLC: [],
		chargerFaults: [],
		PMFaults: [],
		battviewFaults: [],
		chargersNotUsed: [],
		battviewsNotUsed: [],
		battviewsTimeZone: [],
		chargersTimeZone: [],
		battviewsLostTime: [],
		chargersLostTime: [],
		battviewsFirmware: [],
		chargersFirmware: [],
		disabledBV: [],
		disabledCH: [],
		chargersWithError: [],
		battviewsWithError: [],
		BVflashIssue: [],
		BVhighResetsCount: [],
		chargersCalibration: [],
		invoiceMismatchCounts: {},
		highEBUBatteries: []
	};
	serviceDate: any;
	isCollapsed: {
		siteConnectivity: boolean,
		battviewConnectivity: boolean,
	} = {
		siteConnectivity: true,
		battviewConnectivity: true,
	};
	tags : any[] = [];
	withTagList : any[] = [];
	withoutTagList : any[] = [];
	battviewTags: any[] = [];
	chargerTags: any[] = [];
	faultsCopy: any = {};
	siteTimeZone: string = null;
	siteChargers: Object;
	siteBattviews: Object;
	isACTuser: boolean = false;
	insideSiteDashboard: boolean = false;
	dealers: any[];
	selectedDealer: any = null;

	batteryTypes: {};
	fromDateFmt: Date;
	toDateFmt: Date;

	fromDateFmtBV: Date;
	toDateFmtBV: Date;
	timeout;
	@ViewChild("siteNotes") siteNotes;

	constructor(
		private decimalPipe: DecimalPipe,
		private deviceManagemntService: DeviceManagementService,
		private route: ActivatedRoute,
		private breadcrumb: BreadcrumbService,
		public commonUtil: CommonUtil,
		private chargersDashboardService: ChargersDashboardService,
		private devicesDashboard: DevicesDashboardService,
		private battviewsDashboardService: BattviewsDashboardService,
		private translateService: TranslateService,
		public userService: UserService,
		private adminService: AdminService,
		private siteDashboardService: SiteDashboardService,
		private notificationMessage: NotificationMessageService,
	) {
		this.breadcrumb.hide();
	}

	ngOnInit() {
		let customerID = null;
		let siteID = null;
		this.insideSiteDashboard = this.route.snapshot.data.insideSiteDashboard;
		if(this.insideSiteDashboard){
			this.route.parent.params.subscribe( params => {
				customerID = params.customerid;
				siteID = params.siteid;
			});
			this.siteDashboardService.setActiveTab('service-page');
		} else {
			customerID = this.route.snapshot.params.customerId || 0;
			siteID = this.route.snapshot.params.siteId || 0;
		}
		if(customerID || siteID) {
			this.selectedSite = {};
		}
		if(customerID) {
			this.selectedSite.customerid = customerID;
		}
		if(siteID) {
			this.selectedSite.id = siteID;
		}
		this.fromDateFmt	= new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0, 0, 0, 0));
		this.toDateFmt		= new Date(new Date().setHours(23,59,59,999));

		this.fromDateFmtBV	= new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0, 0, 0, 0));
		this.toDateFmtBV	= new Date(new Date().setHours(23,59,59,999));

		this.batteryTypes	= this.devicesDashboard.batteryTypesObj;
		this.batteryTypes[3]= {id: 3, text: "Lithium-ion: BMS"}; // add type only in this page
		this.serviceDate = new Date(new Date().getTime() - 24*60*60*1000);
		this.getHasACTintelligentSites();
		this.getDealers();
		this.userService.currentUser.subscribe((user: User) => {
			this.isACTuser = user.isACTuser;
		});
	}

	exportChargersCycles(){
		let fromDate	= Math.floor(new Date(this.fromDateFmt).getTime() / 1000);
		let toDate		= Math.floor(new Date(new Date(this.toDateFmt).setHours(23,59,59,999)).getTime() / 1000);
		if(fromDate > toDate)
			return this.notificationMessage.setMessage('translate|time.end_start_date');

		let csvData = [];
		csvData.push({
			a: this.translateService.instant('charger.sequence'),
			b: this.translateService.instant('charger.cycle_time'),
			c: this.translateService.instant('device.duration'),
			d: this.translateService.instant('g.AHRs'),
			e: this.translateService.instant('g.KWHrs'),
			f: this.translateService.instant('charger.max_input_watts'),
			g: this.translateService.instant('charger.max_temperature'),
			h: this.translateService.instant('charger.start_voltage'),
			i: this.translateService.instant('charger.end_voltage'),
			j: this.translateService.instant('act.exit_status'),
			k: this.translateService.instant('charger.profiles'),
			l: this.translateService.instant('charger.pms_faulted'),
			m: this.translateService.instant('charger.number_of_cells'),
			n: this.translateService.instant('device.battery_id'),
			o: this.translateService.instant('device.battery_type'),
			p: this.translateService.instant('charger.insertion_time'),
			q: this.translateService.instant('device.charger_id'),
			r: this.translateService.instant('charger.charger_sn'),
			s: this.translateService.instant('device.start_soc'),
			t: this.translateService.instant('device.end_soc'),
		});

		this.chargersDashboardService.getAllChargersCycleRecords(this.selectedSite.id, {fromDate, toDate}).subscribe((data:any)=>{
			for (let chargerCycles of data) {
				chargerCycles = this.formatChargerData(chargerCycles, {isVPC: true});
				for (let cycle of chargerCycles) {
					if(cycle.corrupted)
						continue;
					csvData.push({
						a: cycle.cycleid,
						b: cycle.cycletime_formatted,
						c: cycle.duration,
						d: cycle.totalas,
						e: cycle.totalws,
						f: cycle.maxws,
						g: cycle.maxtemperature,
						h: cycle.startvoltageformatted,
						i: cycle.lastvoltageformatted,
						j: cycle.exitStatus,
						k: cycle.profiles,
						l: cycle.PMsFaulted,
						m: cycle.numberofcells,
						n: cycle.batteryid,
						o: cycle.batteryTypeName,
						p: cycle.insertiontime_formatted,
						q: cycle.chargerid,
						r: cycle.serialnumber,
						s: cycle.startsoc,
						t: cycle.endsoc
					});
				}
			}
			csvData.push({});
			let fileName = `${this.selectedSite.name}_${moment(fromDate*1000).format('MM/DD/YYYY')}_${moment(toDate*1000).format('MM/DD/YYYY')}`;
			new ngxCsv(csvData, fileName);
		});
	}

	formatChargerData(cycles, options:any={}) {
		for (var cycle of cycles) {
			cycle.cycletime_formatted = this.commonUtil.getDateFormattedFromUnixTimeStamp(new Date(Date.parse(cycle.cycletime)).getTime()/1000);
			cycle.insertiontime_formatted = this.commonUtil.getDateFormattedFromUnixTimeStamp(new Date(Date.parse(cycle.insertiontime)).getTime()/1000);
			cycle.maxws = Math.round(cycle.maxws * 1.07);

			var batteryTypeName = this.batteryTypes[cycle.batterytype];
			cycle.batteryTypeName = batteryTypeName ? batteryTypeName.text : '';
			cycle.runWithBMS = cycle.batterytype == 3;
			cycle.batteryid = this.chargersDashboardService.getBatteryModelFromBattviewId(cycle, {});

			if(cycle.batterytype == 3){ // if battery is Lithium with BMS, don't divide voltage by no. of cells
				cycle.vpcstartvoltage	= Math.round((cycle.startvoltage) * 100) / 100;
				cycle.vpclastvoltage	= Math.round((cycle.lastvoltage) * 100) / 100;
			} else {
				cycle.vpcstartvoltage	= Math.round((cycle.startvoltage / cycle.numberofcells) * 100) / 100;
				cycle.vpclastvoltage	= Math.round((cycle.lastvoltage / cycle.numberofcells) * 100) / 100;
			}

			cycle.startvoltageformatted	= cycle.vpcstartvoltage;
			cycle.lastvoltageformatted	= cycle.vpclastvoltage;
			if (!options.isVPC) {
				cycle.startvoltageformatted	= Math.round(cycle.startvoltage * 100) / 100;
				cycle.lastvoltageformatted	= Math.round(cycle.lastvoltage * 100) / 100;
			}
		}
		return cycles;
	}

	exportBattviewEvents(){
		let fromDate	= Math.floor(new Date(this.fromDateFmtBV).getTime() / 1000);
		let toDate		= Math.floor(new Date(new Date(this.toDateFmtBV).setHours(23,59,59,999)).getTime() / 1000);
		if(fromDate > toDate)
			return this.notificationMessage.setMessage('translate|time.end_start_date');

		let csvData: any = [{
			a: this.translateService.instant('device.battery_id'),
			b: this.translateService.instant('device.serial_number'),
			c: this.translateService.instant('charger.sequence'),
			d: this.translateService.instant('battery.event_type'),
			e: this.translateService.instant('act.start_time'),
			f: this.translateService.instant('act.end_time'),
			g: this.translateService.instant('device.duration'),
			h: this.translateService.instant('device.start_soc'),
			i: this.translateService.instant('device.end_soc'),
			j: this.translateService.instant('device.charge_ah'),
			k: this.translateService.instant('device.in_use_ah'),
			l: this.translateService.instant('device.charge_kwhr'),
			m: this.translateService.instant('device.in_use_kwhr'),
			n: this.translateService.instant('charger.start_voltage'),
			o: this.translateService.instant('charger.end_voltage'),
			p: this.translateService.instant('charger.min_voltage'),
			q: this.translateService.instant('charger.start_temperature'),
			r: this.translateService.instant('charger.end_temperature'),
			s: this.translateService.instant('charger.max_temperature'),
			t: this.translateService.instant('charger.max_temperature_time'),
			u: this.translateService.instant('charger.average_current'),
			v: this.translateService.instant('charger.max_current'),
			w: this.translateService.instant('battery.water_level'),
			x: this.translateService.instant('charger.profiles'),
			y: this.translateService.instant('device.charger_exit'),
			z: this.translateService.instant('device.charger'),			
			aa: this.translateService.instant('g.debug'),
			ab: this.translateService.instant('charger.insertion_time'),
			ac: this.translateService.instant('g.notes'),
		}];

		this.battviewsDashboardService.getAllBattviewEventRecords(this.selectedSite.id, {fromDate, toDate}).subscribe((data:any)=>{
			for (let deviceData of data) {
				let device = deviceData.device;
				let battviewEvents = deviceData.events;
				this.battviewsDashboardService.generateSequentialSummaryData(battviewEvents);
				this.battviewsDashboardService.generateEndTemperature(battviewEvents);
				let formatResult = this.battviewsDashboardService.formatData(battviewEvents, device, {isACTuser: this.isACTuser});
				battviewEvents = formatResult.formattedSequentials;
				for (let event of battviewEvents) {
					csvData.push({
						a: device.batteryid,
						b: device.serialnumber,
						c: parseFloat(event.event_id) == event.event_id ? this.decimalPipe.transform(event.event_id) : event.event_id,
						d: event.event_name_formatted,
						e: event.start_time_formatted,
						f: event.endTime_formatted,
						g: event.duration_formatted,
						h: parseFloat(event.start_soc) == event.start_soc ? this.decimalPipe.transform(event.start_soc) : event.start_soc,
						i: parseFloat(event.end_soc) == event.end_soc ? this.decimalPipe.transform(event.end_soc) : event.end_soc,
						j: parseFloat(event.charge_as_formatted) == event.charge_as_formatted ? this.decimalPipe.transform(event.charge_as_formatted, "1.1-1") : event.charge_as_formatted,
						k: parseFloat(event.inuse_as_formatted) == event.inuse_as_formatted ? this.decimalPipe.transform(event.inuse_as_formatted, "1.1-1") : event.inuse_as_formatted,
						l: parseFloat(event.charge_ws_formatted) == event.charge_ws_formatted ? this.decimalPipe.transform(event.charge_ws_formatted, "1.2-2") : event.charge_ws_formatted,
						m: parseFloat(event.inuse_ws_formatted) == event.inuse_ws_formatted ? this.decimalPipe.transform(event.inuse_ws_formatted, "1.2-2") : event.inuse_ws_formatted,
						n: parseFloat(event.start_voltage_formatted) == event.start_voltage_formatted ? this.decimalPipe.transform(event.start_voltage_formatted, "1.2-2") : event.start_voltage_formatted,
						o: parseFloat(event.end_voltage_formatted) == event.end_voltage_formatted ? this.decimalPipe.transform(event.end_voltage_formatted, "1.2-2") : event.end_voltage_formatted,
						p: parseFloat(event.min_voltage_formatted) == event.min_voltage_formatted ? this.decimalPipe.transform(event.min_voltage_formatted, "1.2-2") : event.min_voltage_formatted,
						q: parseFloat(event.start_temperature_formatted) == event.start_temperature_formatted ? this.decimalPipe.transform(event.start_temperature_formatted, "1.0-0") : event.start_temperature_formatted,
						r: parseFloat(event.end_temperature_formatted) == event.end_temperature_formatted ? this.decimalPipe.transform(event.end_temperature_formatted, "1.0-0") : event.end_temperature_formatted,
						s: parseFloat(event.max_temperature_formatted) == event.max_temperature_formatted ? this.decimalPipe.transform(event.max_temperature_formatted, "1.0-0") : event.max_temperature_formatted,
						t: event.max_temperature_time_formatted,
						u: parseFloat(event.event_average_current_formatted) == event.event_average_current_formatted ? this.decimalPipe.transform(event.event_average_current_formatted) : event.event_average_current_formatted,
						v: parseFloat(event.event_max_current_formatted) == event.event_max_current_formatted ? this.decimalPipe.transform(event.event_max_current_formatted) : event.event_max_current_formatted,
						w: event.water_ok_text,
						x: event.profiles,
						y: event.exit,
						z: event.charger_name_formatted,
						aa: event.debug,
						ab: event.insertiontime_formatted,
						ac: event.notes,
					});
				}
			}
			csvData.push({});
			let fileName = `${this.selectedSite.name}_${moment(fromDate*1000).format('MM/DD/YYYY')}_${moment(toDate*1000).format('MM/DD/YYYY')}`;
			new ngxCsv(csvData, fileName);
		});
	}

	formatBattviewData(cycles, options:any={}) {
		for (var cycle of cycles) {
			cycle.cycletime_formatted = this.commonUtil.getDateFormattedFromUnixTimeStamp(new Date(Date.parse(cycle.cycletime)).getTime()/1000);
			cycle.insertiontime_formatted = this.commonUtil.getDateFormattedFromUnixTimeStamp(new Date(Date.parse(cycle.insertiontime)).getTime()/1000);
			cycle.maxws = Math.round(cycle.maxws * 1.07);

			var batteryTypeName = this.batteryTypes[cycle.batterytype];
			cycle.batteryTypeName = batteryTypeName ? batteryTypeName.text : '';
			cycle.runWithBMS = cycle.batterytype == 3;
			cycle.batteryid = this.chargersDashboardService.getBatteryModelFromBattviewId(cycle, {});

			if(cycle.batterytype == 3){ // if battery is Lithium with BMS, don't divide voltage by no. of cells
				cycle.vpcstartvoltage	= Math.round((cycle.startvoltage) * 100) / 100;
				cycle.vpclastvoltage	= Math.round((cycle.lastvoltage) * 100) / 100;
			} else {
				cycle.vpcstartvoltage	= Math.round((cycle.startvoltage / cycle.numberofcells) * 100) / 100;
				cycle.vpclastvoltage	= Math.round((cycle.lastvoltage / cycle.numberofcells) * 100) / 100;
			}

			cycle.startvoltageformatted	= cycle.vpcstartvoltage;
			cycle.lastvoltageformatted	= cycle.vpclastvoltage;
			if (!options.isVPC) {
				cycle.startvoltageformatted	= Math.round(cycle.startvoltage * 100) / 100;
				cycle.lastvoltageformatted	= Math.round(cycle.lastvoltage * 100) / 100;
			}
		}
		return cycles;
	}

	exportToCsv(section=""){
		let CSVobj = this.csvExporter(section);
		let fileName = 'service-'+(section.length > 0 ? section : this.selectedSite.name);
		new ngxCsv(CSVobj, fileName);
	}
	setDeviceTags() {
		this.battviewTags = [];
		this.chargerTags  = [];
		for(let deviceId in this.faults.battviewTags) {
			this.battviewTags[deviceId] = this.getDeviceTags(this.faults.battviewTags[deviceId]);
		}
		for(let deviceId in this.faults.chargerTags) {
			this.chargerTags[deviceId] = this.getDeviceTags(this.faults.chargerTags[deviceId]);
		}
	}
	getDeviceTags(tags) {
		var returnList = [];
		tags.forEach((tag) => {
			if(this.faults.siteTags[tag.tagid]) {
				let tmpTag = Object.assign({}, tag);
				returnList.push(Object.assign(tmpTag, this.faults.siteTags[tag.tagid]));
			}
		});
		return returnList;
	}
	getHasACTintelligentSites() {
		this.deviceManagemntService.getHasACTintelligentSites({execludeTestSites: true},).subscribe(
			(response:any[]) => {
				response.forEach((site) => {
					site.sitename = site.customer_name +' / '+site.name;
				});
				response = _.sortBy(response, 'sitename');
				this.allSites = response;
				this.selectSite();
			}
		);
	}

	getDealers() {
		this.adminService.getDealers().subscribe(
			data => {
				this.dealers = data['dealers'] || [];
				this.dealers.unshift({id: 'all', name: this.translateService.instant('g.all_dealers')});
			}
		);
	}

	selectSite() {
		if(!this.selectedSite || !this.selectedSite.id)
			return;

		let siteExists = false;
		this.allSites.forEach((site) => {
			if(site.id == this.selectedSite.id) {
				this.selectedSite = site;
				siteExists = true;
			}
		});
		if(siteExists) {
			this.dealerChanged(this.selectedSite.dealer_id || this.selectedSite.service_dealer_id, false);
			this.siteChanged(this.selectedSite);
		}
	}
	
	dealerChanged(selectedDealer, resetSite = true) {
		this.selectedDealer = selectedDealer;

		if(resetSite)
			this.selectedSite = null;

		if(!selectedDealer) {
			return;
		}

		let sites = [];
		this.allSites.forEach((site) => {
			if([site.dealer_id, site.service_dealer_id].includes(selectedDealer) || selectedDealer == 'all') {
				sites.push(site);
			}
		});
		this.sites = sites;
	}
	siteChanged(selectedSite) {
		this.selectedSite = selectedSite;
		if (this.selectedSite && this.selectedSite.id) {
			this.withTagList = [];
			this.withoutTagList = [];
			this.getSiteFaults();
			this.getSiteChargers();
			this.getSiteBattviews();
			if(this.siteNotes) {
				this.siteNotes.getSiteNotes({
					siteId: this.selectedSite.id,
					modelType: "site",
					modelId : this.selectedSite.id,
					studyId: 0
				});
			}

			if (this.timeout) {
				clearTimeout(this.timeout);
				this.timeout = null;
			}
			this.timeout = setTimeout(() => {
				this.siteChanged(selectedSite);
				//clearTimeout(this.timeout);
				this.timeout = null;
			}, 1000 * 60 * 60 * 24);
		}
	}

	getChargerError(code) {
		return code;
	}
	
	getSiteFaults() {
		var date = (this.serviceDate.getTime() - (this.serviceDate.getTimezoneOffset() * 60000)) / 1000;
		this.deviceManagemntService.getSiteFaults(this.selectedSite.customerid, this.selectedSite.id, date).subscribe(
			(faults: any) => {
				if(faults.BVflashIssue) {
					faults.BVflashIssue.forEach((item) => {
						if(!item.lasteventtime) {
							item.last_event_time = this.translateService.instant('g.na');
						} else {
							item.last_event_time = this.commonUtil.getDateFormattedFromUnixTimeStamp(item.lasteventtime);
						}
					});
				}
				if(faults.chargersWithError) {
					faults.chargersWithError.forEach((item) => {
						item.error_text = this.commonUtil.getChargerErrorText(item.error_code);
						let lastErrorTime = this.commonUtil.getZoneTimestampFromUTC(item.zoneid, moment(item.last_error_time).utc().unix(), item.daylightsaving);
						item.error_time = this.commonUtil.getDateFormattedFromUnixTimeStamp(lastErrorTime);
					});
				}
				if(faults.battviewsWithError) {
					faults.battviewsWithError.forEach((item) => {
						item.error_text = this.commonUtil.getBattviewErrorText(item.bv_error_code);
						let lastErrorTime = this.commonUtil.getZoneTimestampFromUTC(item.zoneid, moment(item.bv_last_error_time).utc().unix(), item.daylightsaving);
						item.error_time = this.commonUtil.getDateFormattedFromUnixTimeStamp(lastErrorTime);
					});
				}
				this.faults = faults;
				this.productionIps = faults.productionIps;
				this.faultsCopy = _.extend(this.faultsCopy, this.faults);
				this.tags = [];
				this.setDeviceTags();
				this.siteTimeZone = this.commonUtil.getZoneName(faults.siteInfo.zoneid);
			}
		);
	}

	getSiteChargers() {
		this.chargersDashboardService.getChargers(
			{
				'customerid':	this.selectedSite.customerid,
				'siteid':		this.selectedSite.id
			}, 
			{
				'getTags': false,
				'getGroups': false
		}).subscribe(data => {
			this.siteChargers = _.indexBy(data['list'], 'id');
		});
	}

	getSiteBattviews() {
		this.battviewsDashboardService.getBattviews(
			{
				customerid : this.selectedSite.customerid,
				siteid : this.selectedSite.id,
				getTags: true,
				getGroups: false
			}
		).subscribe(data => {
				this.siteBattviews = _.indexBy(data['list'], 'id');
			}
		);
	}
	
	addFilterTag(filterType , tag, data) {
		var battviewKeys = [
			"battviewConnectivityDevices",
			"battviewFaults",
			"battviewPLC",
			"battviewsLostTime", 
			"battviewsNotUsed", 
			"battviewsTimeZone",
			"highEBUBatteries",
			"disabledBV",
			'BVflashIssue',
			'battviewsWithError'
		];
		var chargerKeys = [
			"chargerConnectivityDevices",
			"chargerFaults",
			"chargerPLC",
			"chargersCalibration",
			"chargersLostTime",
			"chargersNotUsed",
			"chargersTimeZone",
			"PMFaults",
			"disabledCH",
			"chargersWithError"
		];

		var dataObj = data ? data : this.faults;

		_.each(dataObj, (objects, key) => {
			if (_.contains(battviewKeys, key)) {
				var tagsArr = this.battviewTags;
			} else if (_.contains(chargerKeys, key)) {
				var tagsArr = this.chargerTags;
			} else {
				return;
			}
			if (filterType == "with") {
				this.faults[key] = _.filter(objects, (o) => {
					return _.contains(_.pluck(tagsArr[o.id], "id"), tag.id);
				});
			} else if (filterType == "without") {
				this.faults[key] = _.filter(objects, (o) => {
					return !_.contains(_.pluck(tagsArr[o.id], "id"), tag.id);
				});
			}
		});
	}
	removeFilterTagCheck(event) {
		if (this.withTagList.length > 0 || this.withoutTagList.length > 0) {
			// this will filter again
			this.removeFilterTag();
		}
	}
	removeFilterTag() {
		if (this.withTagList.length === 0 && this.withoutTagList.length === 0) {
			this.faults = _.extend({}, this.faultsCopy)
		} else {
			_.each(this.withTagList, (tag) => {
				this.addFilterTag("with", tag, _.extend({}, this.faultsCopy));
			});
			_.each(this.withoutTagList, (tag) => {
				this.addFilterTag("without", tag, _.extend({}, this.faultsCopy));
			});
		}
	}
	
	getFaultsText(device) {
		var texts = this.commonUtil.getFaultText(device.mainsenseerrorcode, {enablehalleffectsensing: device.enablehalleffectsensing});
		return texts.join(' - ');
	}

	checkSection(selectedSection, currentSection) {
		return (selectedSection == "" || selectedSection ==  currentSection)
	}
	csvExporter(section="") {
		var faultsArr: {a?: any, b?: any, c?: any, d?: any, e?: any}[] = [
			{a: this.translateService.instant('site.client')+": " , b: this.selectedSite.customer_name, c: this.translateService.instant('site.client_site')+":", d:this.selectedSite.name},
			{a: this.translateService.instant('g.generation_date'), b: moment().format('MM/DD/YYYY')}
		];
		faultsArr.push({});
	
		if (this.checkSection(section, 'site_connectivity') && this.faults.siteConnectivityStatus && this.faults.siteConnectivityStatus.length > 0){
			faultsArr.push({a: this.translateService.instant('site.site_connectivity'), b: this.faults.siteConnectivityStatus});
			faultsArr.push({});
		}
		if (this.checkSection(section, 'invoices_count_mismatch') && this.faults.invoiceMismatchCounts && this.faults.invoiceMismatchCounts.isThereMismatch){
			faultsArr.push({a: this.translateService.instant('site.battviews_chargers_invoices_count_mismatch')});
			faultsArr.push({a: this.translateService.instant('site.active_invoices_battviews'), b: this.faults.invoiceMismatchCounts.invoice_battviews});
			faultsArr.push({a: this.translateService.instant('site.site_battviews'), b: this.faults.invoiceMismatchCounts.site_battviews});
			faultsArr.push({});
			faultsArr.push({a: this.translateService.instant('site.active_invoices_chargers'), b: this.faults.invoiceMismatchCounts.invoice_chargers});
			faultsArr.push({a: this.translateService.instant('site.site_chargers'), b: this.faults.invoiceMismatchCounts.site_chargers});
			faultsArr.push({});
			faultsArr.push({a: this.translateService.instant('site.invoice_id'), b: this.translateService.instant('site.num_of_battviews'), c: this.translateService.instant('site.num_of_chargers')});
			this.faults.invoiceMismatchCounts.active_invoices.forEach(invoice => {
				faultsArr.push({a: invoice.invoice_id, b: invoice.battview_count, c: invoice.chargers_count});
			});
			faultsArr.push({});
		}
		if (this.checkSection(section, 'battview_connectivity') && this.faults.battviewConnectivityDevices && this.faults.battviewConnectivityDevices.length > 0){
			faultsArr.push({a: this.translateService.instant('site.battview_connectivity')});
			faultsArr.push({a: this.translateService.instant('device.battery_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('device.truck_id'), d: this.translateService.instant('g.disconnection_length')});
			this.faults.battviewConnectivityDevices.forEach(item => {
				faultsArr.push({a: item.name, b: '*'+item.serialnumber, c: item.truckid, d: this.commonUtil.secondsToElapsedTime(item.dissconnectLimit)});
			});
			faultsArr.push({});
		}
		if (this.checkSection(section, 'charger_connectivity') && this.faults.chargerConnectivityDevices && this.faults.chargerConnectivityDevices.length > 0){
			faultsArr.push({a: this.translateService.instant('site.charger_connectivity')});
			faultsArr.push({a: this.translateService.instant('device.charger_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('g.disconnection_length')});
			this.faults.chargerConnectivityDevices.forEach((item) => {
				faultsArr.push({a: item.name, b: '*'+item.serialnumber, c: this.commonUtil.secondsToElapsedTime(item.dissconnectLimit)});
			});
			faultsArr.push({});
		}
		if (this.checkSection(section, 'charger_plc_failure_detect') && this.faults.chargerPLC && this.faults.chargerPLC.length > 0){
			faultsArr.push({a: this.translateService.instant('device.charger_plc_failure_detect')});
			faultsArr.push({a: this.translateService.instant('device.charger_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('device.plc_fault')});
			this.faults.chargerPLC.forEach((item) => {
				faultsArr.push({a: item.chargerusername, b: '*'+item.serialnumber, c: item.fault});
			});
			faultsArr.push({});
		}
		if (this.checkSection(section, 'charger_calibration') && this.faults.chargersCalibration && this.faults.chargersCalibration.length > 0){
			faultsArr.push({a: this.translateService.instant('device.charger_calibration')});
			faultsArr.push({a: this.translateService.instant('device.charger_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('device.calibration_fault')});
			this.faults.chargersCalibration.forEach((item) => {
				faultsArr.push({a: item.chargerusername, b: '*'+item.serialnumber, c: item.fault});
			});
			faultsArr.push({});
		}
		if (this.checkSection(section, 'battview_plc_failure_detect') && this.faults.battviewPLC && this.faults.battviewPLC.length > 0){
			faultsArr.push({a: this.translateService.instant('device.battview_plc_failure_detect')});
			faultsArr.push({a: this.translateService.instant('device.battery_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('device.truck_id'), d: this.translateService.instant('device.plc_fault')});
			this.faults.battviewPLC.forEach((item) => {
				faultsArr.push({a: item.batteryid, b: '*'+item.serialnumber, c: item.truckid, d: item.fault});
			});
			faultsArr.push({});
		}
		if (this.checkSection(section, 'charger_faults') && this.faults.chargerFaults && this.faults.chargerFaults.length > 0){
			faultsArr.push({a: this.translateService.instant('device.charger_faults')});
			faultsArr.push({a: this.translateService.instant('device.charger_id'), b: this.translateService.instant('device.serial_number')});
			this.faults.chargerFaults.forEach((item) => {
				faultsArr.push({a: item.chargerusername, b: '*'+item.serialnumber});
			});
			faultsArr.push({});
		}
		if (this.checkSection(section, 'pm_faults') && this.faults.PMFaults && this.faults.PMFaults.length > 0){
			faultsArr.push({a: this.translateService.instant('device.pm_faults')});
			this.faults.PMFaults.forEach((item) => {
				faultsArr.push({a: this.translateService.instant('device.charger_id'), b: this.translateService.instant('device.serial_number')});
				faultsArr.push({a: item.chargerusername, b: '*'+item.serialnumber});
				faultsArr.push({a: this.translateService.instant('device.pm_id'), b: this.translateService.instant('device.fault_time'), c:this.translateService.instant('g.fault')});
				item.PMfaults.forEach((fault) => {
					faultsArr.push({a: fault.pmid, b: fault.time, c: fault.fault});
				});
				faultsArr.push({});
			});
		}
		if (this.checkSection(section, 'battview_high_ebu') && this.faults.highEBUBatteries && this.faults.highEBUBatteries.length > 0){
			faultsArr.push({a: this.translateService.instant('device.battview_high_ebu')});
			faultsArr.push({a: this.translateService.instant('device.battery_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('device.truck_id')});
			this.faults.highEBUBatteries.forEach((item) => {
				faultsArr.push({a: item.batteryid, b: '*'+item.serialnumber, c: item.truckid});
			});
			faultsArr.push({});
		}
		if (this.checkSection(section, 'battview_faults') && this.faults.battviewFaults && this.faults.battviewFaults.length > 0){
			faultsArr.push({a: this.translateService.instant('device.battview_faults')});
			faultsArr.push({a: this.translateService.instant('device.battery_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('device.truck_id'), d: this.translateService.instant('g.fault')});
			this.faults.battviewFaults.forEach((item) => {
				faultsArr.push({a: item.batteryid, b: '*'+item.serialnumber, c: item.truckid, d: this.getFaultsText(item)});
			});
			faultsArr.push({});
		}
		if (this.checkSection(section, 'chargers_not_used') && this.faults.chargersNotUsed && this.faults.chargersNotUsed.length > 0){
			faultsArr.push({a: this.translateService.instant('device.charger_not_used')});
			faultsArr.push({a: this.translateService.instant('device.charger_id'), b: this.translateService.instant('device.serial_number')});
			this.faults.chargersNotUsed.forEach((item) => {
				faultsArr.push({a: item.chargerusername, b: '*'+item.serialnumber});
			});
			faultsArr.push({});
		}
		if (this.checkSection(section, 'battviews_not_used') && this.faults.battviewsNotUsed && this.faults.battviewsNotUsed.length > 0){
			faultsArr.push({a: this.translateService.instant('device.battview_not_used')});
			faultsArr.push({a: this.translateService.instant('device.battery_id'), b: this.translateService.instant('device.serial_number')});
			this.faults.battviewsNotUsed.forEach((item) => {
				faultsArr.push({a: item.batteryid, b: '*'+item.serialnumber});
			});
			faultsArr.push({});
		}
		if (this.checkSection(section, 'battviews_have_different_time_zone') && this.faults.battviewsTimeZone && this.faults.battviewsTimeZone.length > 0){
			faultsArr.push({a: this.translateService.instant('device.battviews_have_different_time_zone')});
			faultsArr.push({
				a: this.translateService.instant('device.battery_id'),
				b: this.translateService.instant('device.serial_number'),
				c: this.translateService.instant('device.truck_id'),
				d: this.translateService.instant('device.battview_time_zone'),
				e: this.translateService.instant('site.site_time_zone')
			});
			this.faults.battviewsTimeZone.forEach((item) => {
				faultsArr.push({
					a: item.batteryid,
					b: '*'+item.serialnumber,
					c: item.truckid,
					d: this.commonUtil.getZoneName(item.zoneid),
					e: this.siteTimeZone
				});
			});
			faultsArr.push({});
		}
		if (this.checkSection(section, 'chargers_have_different_time_zone') && this.faults.chargersTimeZone && this.faults.chargersTimeZone.length > 0){
			faultsArr.push({a: this.translateService.instant('device.chargers_have_different_time_zone')});
			faultsArr.push({a: this.translateService.instant('device.charger_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('device.charger_time_zone'), d: this.translateService.instant('site.site_time_zone')});
			this.faults.chargersTimeZone.forEach((item) => {
				faultsArr.push({a: item.chargerusername, b: '*'+item.serialnumber, c: this.commonUtil.getZoneName(item.zoneid), d: this.siteTimeZone});
			});
			faultsArr.push({});
		}
		if (this.checkSection(section, 'battviews_have_not_synched_time') && this.faults.battviewsLostTime && this.faults.battviewsLostTime.length > 0){
			faultsArr.push({a: this.translateService.instant('device.battviews_have_not_synched_time')});
			faultsArr.push({a: this.translateService.instant('device.battery_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('device.truck_id'), d: this.translateService.instant('time.time_gap_seconds')});
			this.faults.battviewsLostTime.forEach((item) => {
				faultsArr.push({a: item.batteryid, b: '*'+item.serialnumber, c: item.truckid, d: item.time_gap});
			});
			faultsArr.push({});
		}
		if (this.checkSection(section, 'chargers_have_not_synched_time') && this.faults.chargersLostTime && this.faults.chargersLostTime.length > 0){
			faultsArr.push({a: this.translateService.instant('device.chargers_have_not_synched_time')});
			faultsArr.push({a: this.translateService.instant('device.charger_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('time.time_gap_seconds')});
			this.faults.chargersLostTime.forEach((item) => {
				faultsArr.push({a: item.chargerusername, b: '*'+item.serialnumber, c: item.time_gap});
			});
			faultsArr.push({});
		}
		if(this.checkSection(section, 'battviews_different_firmware_version') && this.faults.battviewsFirmware && this.faults.battviewsFirmware.length > 0) {
			faultsArr.push({a: this.translateService.instant('device.battviews_different_firmware_version')+': '+this.faults.siteInfo.battviewsfirmwareversion});
			faultsArr.push({a: this.translateService.instant('device.battery_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('device.truck_id'), d: this.translateService.instant('device.battview_firmware_version')});
			this.faults.battviewsFirmware.forEach((item) => {
				faultsArr.push({a: item.batteryid, b: '*'+item.serialnumber, c: item.truckid, d: item.firmwareversion});
			});
			faultsArr.push({});
		}
		if(this.checkSection(section, 'chargers_different_firmware_version') && this.faults.chargersFirmware && this.faults.chargersFirmware.length > 0) {
			faultsArr.push({a: this.translateService.instant('device.chargers_different_firmware_version')+': '+this.faults.siteInfo.chargersfirmwareversion});
			faultsArr.push({a: this.translateService.instant('device.charger_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('device.charger_firmware_version')});
			this.faults.chargersFirmware.forEach((item) => {
				faultsArr.push({a: item.chargerusername, b: '*'+item.serialnumber, c: item.firmwareversion});
			});
			faultsArr.push({});
		}
		if(this.checkSection(section, 'actview_disabled_battviews') && this.faults.disabledBV && this.faults.disabledBV.length > 0) {
			faultsArr.push({a: this.translateService.instant('act.actview_disabled_battviews')});
			faultsArr.push({a: this.translateService.instant('device.battery_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('device.truck_id')});
			this.faults.disabledBV.forEach((item) => {
				faultsArr.push({a: item.batteryid, b: '*'+item.serialnumber, c: item.truckid});
			});
			faultsArr.push({});
		}
		if(this.checkSection(section, 'actview_disabled_chargers') && this.faults.disabledCH && this.faults.disabledCH.length > 0) {
			faultsArr.push({a: this.translateService.instant('act.actview_disabled_chargers')});
			faultsArr.push({a: this.translateService.instant('device.charger_id'), b: this.translateService.instant('device.serial_number')});
			this.faults.disabledCH.forEach((item) => {
				faultsArr.push({a: item.chargerusername, b: '*'+item.serialnumber});
			});
			faultsArr.push({});
		}
		if(this.checkSection(section, 'battviews_have_flash_issue') && this.faults.BVflashIssue && this.faults.BVflashIssue.length > 0) {
			faultsArr.push({a: this.translateService.instant('device.battviews_have_flash_issue')});
			faultsArr.push({a: this.translateService.instant('device.battery_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('device.truck_id'), d: this.translateService.instant('device.last_event_time')});
			this.faults.BVflashIssue.forEach((item) => {
				faultsArr.push({a: item.batteryid, b: '*'+item.serialnumber, c: item.truckid, d: item.last_event_time});
			});
			faultsArr.push({});
		}
		if(this.checkSection(section, 'battviews_bad_connection') && this.faults.BVhighResetsCount && this.faults.BVhighResetsCount.length > 0) {
			faultsArr.push({a: this.translateService.instant('device.battviews_bad_connection')});
			faultsArr.push({a: this.translateService.instant('device.battery_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('device.truck_id')});
			this.faults.BVhighResetsCount.forEach((item) => {
				faultsArr.push({a: item.batteryid, b: '*'+item.serialnumber, c: item.truckid});
			});
			faultsArr.push({});
		}
		if(this.checkSection(section, 'chargers_with_errors') && this.faults.chargersWithError && this.faults.chargersWithError.length > 0) {
			faultsArr.push({a: this.translateService.instant('act.chargers_with_errors')});
			faultsArr.push({a: this.translateService.instant('device.charger_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('device.error_time'), d: this.translateService.instant('device.error_text')});
			this.faults.chargersWithError.forEach((item) => {
				faultsArr.push({a: item.chargerusername, b: '*'+item.serialnumber, c: item.error_time, d: item.error_text});
			});
			faultsArr.push({});
		}
		if(this.checkSection(section, 'battviews_with_errors') && this.faults.battviewsWithError && this.faults.battviewsWithError.length > 0) {
			faultsArr.push({a: this.translateService.instant('act.battviews_with_errors')});
			faultsArr.push({a: this.translateService.instant('device.battery_id'), b: this.translateService.instant('device.serial_number'), c: this.translateService.instant('device.error_time'), d: this.translateService.instant('device.error_text')});
			this.faults.battviewsWithError.forEach((item) => {
				faultsArr.push({a: item.batteryid, b: '*'+item.serialnumber, c: item.error_time, d: item.error_text});
			});
			faultsArr.push({});
		}
		return faultsArr;
	}

	getTranslation(text){
		let list = {
			'Battery ID': this.translateService.instant('device.battery_id'),
			'serial_number': this.translateService.instant('device.serial_number'),
			'Truck ID': this.translateService.instant('device.truck_id'),
			'Disconnection Length': this.translateService.instant('g.disconnection_length'),
			'Charger ID': this.translateService.instant('device.charger_id'),
			'PLC Fault': this.translateService.instant('device.plc_fault'),
			'Calibration Fault': this.translateService.instant('device.calibration_fault'),
			'Fault': this.translateService.instant('g.fault'),
			'Battview Time Zone': this.translateService.instant('device.battview_time_zone'),
			'Charger Time Zone': this.translateService.instant('device.charger_time_zone'),
			'Site Time Zone': this.translateService.instant('site.site_time_zone'),
			'Time Gap (seconds)': this.translateService.instant('time.time_gap_seconds'),
			'Battview Firmware Version': this.translateService.instant('device.battview_firmware_version'),
			'Charger Firmware Version': this.translateService.instant('device.charger_firmware_version'),
			'Last Event Time': this.translateService.instant('device.last_event_time'),
			'Tags': this.translateService.instant('device.tags'),
			'error_text': this.translateService.instant('device.error_text'),
			'error_time': this.translateService.instant('device.error_time')
		};
		return list[text];
	}

	exportServicePageData() {
		this.deviceManagemntService.exportServicePageData().subscribe((res: any) => {
			if(!res || !res.status)
				return this.notificationMessage.setMessage('globalErrMsg');
			switch(res.status) {
				case 2:
					this.notificationMessage.setMessage(this.translateService.instant('site.all_sites_export_locked'), 'warning', 20000);
					break;
				default:
					this.notificationMessage.setMessage(this.translateService.instant('site.all_sites_export_success'), 'success', 20000);
			}
		});
	}

	ngOnDestroy(): void {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}
}