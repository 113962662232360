<div class="row">
	<div class="col-md-12 panel panel-default">
		<div class="panel-body">
			<div class="padding-8">
				<input [(ngModel)]="purchaseOrderNumber" placeholder="{{'device.purchase_order' | translate}}" class="form-control"/>
			</div>
			<div class="padding-8">
				<label><input type='checkbox' class="form-control" [(ngModel)]="isPartial"/> {{'g.partial_search' | translate}}</label>
			</div>
			<div class="padding-8">
				<button class="btn btn-success tcenter" (click)="getDeviceByPO()" [disabled]="!purchaseOrderNumber">{{'device.search_for_charger' | translate}}</button>
			</div>
			<div *ngIf="noResult" class="padding-8">
				{{'g.no_results_found' | translate}}
			</div>
			<table class="table table-striped padding-8" *ngIf="devicesInfo.length > 0">
				<thead>
					<tr>
						<th>{{'device.device_type' | translate}}</th>
						<th class="red-text">{{'g.actview_id' | translate}}</th>
						<th>{{'device.serial_number' | translate}}</th>
						<th>{{'device.purchase_order' | translate}}</th>
						<th>{{'device.charger_id' | translate}}</th>
						<th>{{'g.model' | translate}}</th>
						<th>{{'device.is_replacement_part' | translate}}</th>
						<th>{{'g.is_deleted' | translate}}</th>
						<th>{{'device.commission_date' | translate}}</th>
						<th>{{'device.assigned_to_production_line_date' | translate}}</th>
						<th>{{'device.shipping_date' | translate}}({{'time.utc_gmt' | translate}})</th>
						<th>{{'device.warranty_period' | translate}}</th>
						<th>{{'site.customer_site' | translate}}</th>
						<th *ngIf="user.isACTuser">{{'device.test_confirmed' | translate}}</th>
						<th>{{'g.action' | translate}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let deviceInfo of devicesInfo">
						<td>{{'device.charger' | translate}}</td>
						<td class="red-text">{{deviceInfo.id}}</td>
						<td>{{deviceInfo.serialnumber}}</td>
						<td>{{deviceInfo.purchase_order}}</td>
						<td>
							<a *ngIf="!deviceInfo.isdeleted && !deviceInfo.replacmentpart && deviceInfo.customerid > 0 && deviceInfo.siteid > 0; else noLink" [routerLink]="['/', deviceInfo.customerid, deviceInfo.siteid, 'chargers', 'analytics', 'cycles', deviceInfo.id]">{{deviceInfo.chargerusername}}</a>
							<ng-template #noLink>
								<span>{{deviceInfo.chargerusername}}</span>
							</ng-template>
						</td>
						<td>{{deviceInfo.model}}</td>
						<td>{{deviceInfo.replacmentpart}}</td>
						<td>{{deviceInfo.isdeleted}}</td>
						<td>{{deviceInfo.commissiondate | date:'MM/dd/yyyy'}}</td>
						<td>{{deviceInfo.assignedtoproductionlinedate | date:'MM/dd/yyyy'}}</td>
						<td>{{deviceInfo.shippingdate | date:'MM/dd/yyyy':'UTC/GMT'}}</td>
						<td *ngIf="deviceInfo.isWarrantyVoid">{{(user.isACTuser ? commonUtil.singularOrPlural(deviceInfo.warrantyperiod, 'Year') + '-' + ('g.void' | translate) : 'g.void' | translate)}}</td>
						<td *ngIf="!deviceInfo.isWarrantyVoid">{{commonUtil.singularOrPlural(deviceInfo.warrantyperiod, 'Year')}}</td>
						<td>
							<a *ngIf="deviceInfo.customerid > 0 && !deviceInfo.isdeleted && !deviceInfo.replacmentpart; else noId" [routerLink]="['/main', deviceInfo.customerid]">{{deviceInfo.customername}}</a> / <a *ngIf="deviceInfo.siteid > 0 && !deviceInfo.isdeleted && !deviceInfo.replacmentpart; else noId" [routerLink]="['/', deviceInfo.customerid, deviceInfo.siteid]">{{deviceInfo.sitename}}</a>
							<ng-template #noId>
								-
							</ng-template>
						</td>
						<td *ngIf="user.isACTuser">{{deviceManagemntService.isTestConfirmed('charger', deviceInfo)}}</td>
						<td>
							<div 
								class="btn-group" 
								appDropdown 
								*ngIf="!deviceInfo.replacmentpart && !deviceInfo.isdeleted">
								<i 
									type="button" 
									class="glyphicon glyphicon-cog dropdown-toggle" 
									dropdown-toggle></i>
								<ul class="dropdown-menu" role="menu">
									<li>
										<a 
											(click)="goToEditDevice(deviceInfo)">{{'g.edit' | translate}}</a></li>
									<li 
										*ngIf="userService.hasAccessFunction('act_admin_device_management')"><a (click)="deleteDevice(deviceInfo.id)">{{'g.delete' | translate}}</a></li>
									<li 
										*ngIf="userService.hasAccessFunction('act_admin_device_management')"><a [routerLink]="['/devices','devices-dates-management','charger',deviceInfo.id]">{{'device.edit_device_dates' | translate}}</a></li>
									<li 
										*ngIf="userService.hasAccessFunction('edit_purchase_order')">
										<a 
											[routerLink]="['/devices','devices-purchase-order-management','charger',deviceInfo.id]">{{'device.edit_purchase_order' | translate}}</a></li>
								</ul>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>