<div class="boxs3">
	<ul class="nav nav-pills">
		<li [ngClass]="{active: activePage == 'analytics' && !isShared}" appDropdown>
			<a class="glyphicon glyphicon-stats dropdown-toggle">
				<span style="font-family:sans-serif;" *ngIf="!isBattviewMobile || (device?.id && date.fromDate && date.toDate && !isShared)">&nbsp;Analytics</span>
				<span style="font-family:sans-serif;" *ngIf="isBattviewMobile && !(device?.id && date.fromDate && date.toDate && !isShared)" routerLink="/battview-mobile/analytics/battery-summary" (click)="setTab('battery-summary', 'analytics')">&nbsp;Analytics</span>
				<i class="fa fa-caret-down" *ngIf="!isBattviewMobile || (device?.id && date.fromDate && date.toDate && !isShared)"></i>
			</a>
			<ul class="z-index dropdown-menu" role="menu" *ngIf="!isBattviewMobile || (device?.id && date.fromDate && date.toDate && !isShared)">
				<li [ngClass]="{active:activeTab == 'battery-summary'}" class="batteryTopNav">
					<a (click)="setTab('battery-summary', 'analytics')">
						<i class="custom-icon glyphicon glyphicon-align-justify"></i>
						<span style="font-family:sans-serif;"> 
							&nbsp;Battery Summary
						</span>
					</a>
				</li>
				<li [ngClass]="{active:activeTab == 'battery-report'}" class="dropdown-submenu batteryTopNav" appDropdown>
					<a tabindex="-1">
						<i class="custom-icon fa fa-battery-three-quarters"></i>
						<span style="font-family:sans-serif;">&nbsp;Battery Reports
							<i class="fa fa-caret-right margin-left-sm"></i>
						</span>
					</a>
					<ul class="z-index dropdown-menu" role="menu">
						<li [ngClass]="{active:activeTab == 'battery-usage-summary'}"><a (click)="setTab('battery-usage-summary', 'analytics')" class="has-action">Battery Usage Summary</a></li>
						<li [ngClass]="{active:activeTab == 'battery-daily-usage'}"><a (click)="setTab('battery-daily-usage', 'analytics')" class="has-action">Battery Daily Usage</a></li>
						<li [ngClass]="{active:activeTab == 'events-log'}"><a (click)="setTab('events-log', 'analytics')" class="has-action">Events Log</a></li>
						<li [ngClass]="{active:activeTab == 'exceptions'}" *ngIf="!isBattviewMobile"><a (click)="setTab('exceptions', 'analytics')" class="has-action">Exceptions</a></li>
						<li [ngClass]="{active:activeTab == 'charge-summary'}" *ngIf="!isBattviewMobile"><a (click)="setTab('charge-summary', 'analytics')" class="has-action">Charge Summary</a></li>
					</ul>
				</li>
				<li [ngClass]="{active:activeTab == 'chart'}" class="dropdown-submenu batteryTopNav" appDropdown>
					<a tabindex="-1" subtoggle>
						<i class="custom-icon fa fa-bar-chart"></i>
						<span style="font-family:sans-serif;">&nbsp;Battery Charts
							<i class="fa fa-caret-right margin-left-sm"></i>
						</span>
					</a>
					<ul class="z-index dropdown-menu" role="menu">
						<li [ngClass]="{active:activeSubTab == 'daily-usage'}"><a (click)="setTab('chart','analytics','daily-usage')" class="has-action">Daily Usage</a></li>
						<li [ngClass]="{active:activeSubTab == 'daily-ebus'}"><a (click)="setTab('chart','analytics','daily-ebus')" class="has-action">Daily EBUs</a></li>
						<li [ngClass]="{active:activeSubTab == 'daily-ahrs'}"><a (click)="setTab('chart','analytics','daily-ahrs')" class="has-action">Daily AHrs</a></li>
						<li [ngClass]="{active:activeSubTab == 'soc'}"><a (click)="setTab('chart','analytics','soc')" class="has-action">SOC Chart</a></li>
						<li [ngClass]="{active:activeSubTab == 'max-temperature'}" *ngIf="!hideTemperatureChart"><a (click)="setTab('chart','analytics','max-temperature')" class="has-action">Max Temperature</a></li>
						<li [ngClass]="{active:activeSubTab == 'end-temperature'}" *ngIf="!hideTemperatureChart"><a (click)="setTab('chart','analytics','end-temperature')" class="has-action">End Temperature</a></li>
						<li [ngClass]="{active:activeSubTab == 'end-voltage'}" *ngIf="!isBattviewMobile"><a (click)="setTab('chart','analytics','end-voltage')" class="has-action">End Voltage</a></li>
						<li [ngClass]="{active:activeSubTab == 'customized'}"><a (click)="setTab('chart','analytics','customized')" class="has-action">Customized Charts</a></li>
					</ul>
				</li>
				<li [ngClass]="{active:activeTab == 'global-record'}" class="batteryTopNav" *ngIf="userService.hasAccessFunction('extended_global_records') && !isBattviewMobile">
					<a (click)="setTab('global-record', 'analytics')">
						<i class="custom-icon glyphicon glyphicon-globe"></i>
						<span style="font-family:sans-serif;"> 
							&nbsp;Global Record
						</span>
					</a>
				</li>
				<!-- Operation Analysis -->
				<li [ngClass]="{active:activeTab == 'soc-prediction'}" class="batteryTopNav" *ngIf="userService.hasAccessFunction('has_soc_predication')">
					<a (click)="setTab('soc-prediction', 'analytics')">
						<i class="custom-icon glyphicon glyphicon-tasks"></i>
						<span style="font-family:sans-serif;"> 
							&nbsp;Operation Analysis (Beta)
						</span>
					</a>
				</li>
			</ul>
		</li>
		<li [ngClass]="{active:activePage == 'settings'}" *ngIf="showSettingsTab">
			<a *ngIf="isBattviewMobile" class="glyphicon glyphicon-cd" (click)="setTab('', 'settings')"><span style="font-family:sans-serif;">&nbsp;Settings</span></a>
			<a *ngIf="!isBattviewMobile" class="glyphicon glyphicon-cd" (click)="setTab('', 'settings')"><span style="font-family:sans-serif;">&nbsp;Settings</span></a>
		</li>
		<li [ngClass]="{active: activePage == 'quick-view'}" *ngIf="!isBattviewMobile && userService.hasAccessFunction('access_battview_quick_view') && device?.actviewenabled">
			<a class="glyphicon glyphicon-unchecked" (click)="setTab('','quick-view')"><span style="font-family:sans-serif;">&nbsp;Quick View</span></a>
		</li>
		<li [ngClass]="{active: activePage == 'faults'}" *ngIf="!isBattviewMobile && userService.hasAccessFunction('display_faults_in_analytics_page')">
			<a class="glyphicon glyphicon-alert" (click)="setTab('','faults')"><span style="font-family:sans-serif;">&nbsp;BATTView Faults</span></a>
		</li>

		<li [ngClass]="{active: activePage == 'list-rt-records' || activePage == 'fetch-rt-records'}" *ngIf="!isBattviewMobile && ((userService.hasAccessFunction('act_admin_device_management') && device?.actviewenabled) || (currentUser.isACTuser && device?.actviewenabled))" appDropdown>
			<a closesubtoggles class="glyphicon glyphicon-stats dropdown-toggle" dropdown-toggle>
				<span style="font-family:sans-serif;">RT Records</span>
				<i class="fa fa-caret-down"></i>
			</a>
			<ul class="z-index dropdown-menu" role="menu">
				<li [ngClass]="{active: activePage == 'fetch-rt-records'}" *ngIf="!isBattviewMobile && userService.hasAccessFunction('act_admin_device_management') && device?.actviewenabled">
					<a class="glyphicon glyphicon-floppy-save" (click)="setTab('', 'fetch-rt-records')"><span style="font-family:sans-serif;">&nbsp;Fetch RT Records</span></a>
				</li>
				<li [ngClass]="{active: activePage == 'list-rt-records'}" *ngIf="currentUser.isACTuser && device?.actviewenabled">
					<a class="glyphicon glyphicon-th-list" (click)="setTab('', 'list-rt-records')"><span style="font-family:sans-serif;">&nbsp;List RT Records</span></a>
				</li>
			</ul>
		</li>
		<li [ngClass]="{active: activePage == 'debug-records'}" *ngIf="!isBattviewMobile && userService.hasAccessFunction('access_debug_records')">
			<a class="glyphicon glyphicon-compressed" (click)="setTab('','debug-records')"><span style="font-family:sans-serif;">&nbsp;Debug Records</span></a>
		</li>
		<li [ngClass]="{active: activePage == 'config-track'}" *ngIf="userService.hasAccessFunction('access_device_config_track')">
			<a class="glyphicon glyphicon-list-alt" (click)="setTab('','config-track')"><span style="font-family:sans-serif;">&nbsp;Config Track</span></a>
		</li>
		<li [ngClass]="{active: activePage == 'connectivity-track'}" *ngIf="userService.hasAccessFunction('act_admin_device_management')">
			<a class="glyphicon glyphicon-transfer" (click)="setTab('','connectivity-track')"><span style="font-family:sans-serif;">&nbsp;Connectivity Track</span></a>
		</li>
		<li [ngClass]="{active:isShared}" *ngIf="isBattviewMobile && !currentUser.isACTuser" appDropdown>
			<a closesubtoggles class="dropdown-toggle" dropdown-toggle>
				<span style="font-family:sans-serif;" *ngIf="isShared"><i class="fa fa-share-alt" aria-hidden="true"></i>&nbsp;&nbsp;Shared Studies</span>
				<span style="font-family:sans-serif;" *ngIf="!isShared" routerLink="/shared-battview-mobile/analytics/battery-summary"><i class="fa fa-share-alt" aria-hidden="true"></i>&nbsp;&nbsp;Shared Studies</span>
				<i class="fa fa-caret-down" *ngIf="device?.id && date.fromDate && date.toDate && isShared"></i>
			</a>
			<ul class="z-index dropdown-menu" role="menu" *ngIf="device?.id && date.fromDate && date.toDate && isShared">
				<li [ngClass]="{active:activeTab == 'battery-summary'}" class="batteryTopNav">
					<a (click)="setTab('battery-summary', 'analytics')">
						<i class="custom-icon glyphicon glyphicon-align-justify"></i>
						<span style="font-family:sans-serif;"> 
							&nbsp;Battery Summary
						</span>
					</a>
				</li>
				<li [ngClass]="{active:activeTab == 'battery-report'}" class="dropdown-submenu batteryTopNav" appDropdown>
					<a tabindex="-1" subtoggle>
						<i class="custom-icon fa fa-battery-three-quarters"></i>
						<span style="font-family:sans-serif;">&nbsp;Battery Reports
							<i class="fa fa-caret-right margin-left-sm"></i>
						</span>
					</a>
					<ul class="z-index dropdown-menu" role="menu">
						<li [ngClass]="{active:activeTab == 'battery-usage-summary'}"><a (click)="setTab('battery-usage-summary', 'analytics')" class="has-action">Battery Usage Summary</a></li>
						<li [ngClass]="{active:activeTab == 'battery-daily-usage'}"><a (click)="setTab('battery-daily-usage', 'analytics')" class="has-action">Daily Idle Time Report</a></li>
						<li [ngClass]="{active:activeTab == 'events-log'}"><a (click)="setTab('events-log', 'analytics')" class="has-action">Daily Idle Times</a></li>
					</ul>
				</li>
				<li [ngClass]="{active:activeTab == 'chart'}" class="dropdown-submenu batteryTopNav" appDropdown>
					<a tabindex="-1" subtoggle>
						<i class="custom-icon fa fa-bar-chart"></i>
						<span style="font-family:sans-serif;">&nbsp;Battery Charts
							<i class="fa fa-caret-right margin-left-sm"></i>
						</span>
					</a>
					<ul class="z-index dropdown-menu" role="menu">
						<li [ngClass]="{active:activeSubTab == 'daily-usage'}"><a (click)="setTab('chart','analytics','daily-usage')" class="has-action">Daily Usage</a></li>
						<li [ngClass]="{active:activeSubTab == 'daily-ebus'}"><a (click)="setTab('chart','analytics','daily-ebus')" class="has-action">Daily EBUs</a></li>
						<li [ngClass]="{active:activeSubTab == 'daily-ahrs'}"><a (click)="setTab('chart','analytics','daily-ahrs')" class="has-action">Daily AHrs</a></li>
						<li [ngClass]="{active:activeSubTab == 'soc'}"><a (click)="setTab('chart','analytics','soc')" class="has-action">SOC Chart</a></li>
						<li [ngClass]="{active:activeSubTab == 'customized'}"><a (click)="setTab('chart','analytics','customized')" class="has-action">Customized Charts</a></li>
					</ul>
				</li>
			</ul>
		</li>
		<li [ngClass]="{active: activePage == 'notes'}">
			<a class="glyphicon glyphicon-pencil" (click)="setTab('','notes')"><span style="font-family:sans-serif;">&nbsp;Notes</span></a>
		</li>
		<li [ngClass]="{active: activePage == 'listing'}" *ngIf="showBvMobileListingTab">
			<a (click)="setTab('', 'listing')">
				<i class="fa fa-list-alt margin-right-sm"></i><span style="font-family:sans-serif;">&nbsp;List</span>
			</a>
		</li>

		<li class="r" *ngIf="hasData">
			<div *ngIf="isDeviceSelected() && date.fromDate && date.toDate">
				<a *ngIf="pageHasPrintBtn()" class="btn btn-primary fa fa-print margin-top-sm" (click)="printContent()" data-toggle="tooltip" title="Print"></a>
				<button *ngIf="pageHasExportToExcelBtn()" class="fa fa-upload btn btn-primary margin-top-sm" data-toggle="tooltip" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv()"></button>
			</div>
		</li>
	</ul>
</div>