<div class="panel panel-default">
    <div class="panel-body">
        <div class="col-md-12 padding-8">
            <div class="col-md-2 text-right"><strong>Start Date:</strong></div>
            <div class="col-md-4">
                <div class='input-group date'>
                    <input class="form-control" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="fromDateFmt" bsDatepicker #fromDate="bsDatepicker" (ngModelChange)="datesChanged()" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
                    <span class="input-group-btn">
                        <button class="btn btn-default btn-date" (click)="fromDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-md-12 padding-8">
            <div class="col-md-2 text-right"><strong>End Date:</strong></div>
            <div class="col-md-4">
                <div class='input-group date' id='datetimepicker2'>
                    <input class="form-control" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="toDateFmt" bsDatepicker #toDate="bsDatepicker" (ngModelChange)="datesChanged()" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
                    <span class="input-group-btn">
                        <button class="btn btn-default btn-date" (click)="toDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-md-12 padding-8">
            <div class="col-md-3 col-md-offset-2">
                <button class="form-control btn btn-success" (click)="addFetchBattviewRTrecordsRequest()" [disabled]="!toDateFmt || !fromDateFmt">Request</button>
            </div>
        </div>
    </div>
</div>