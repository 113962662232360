import {AfterViewInit, Component} from "@angular/core";


import { IFloatingFilter, IFloatingFilterParams, TextFilter } from "ag-grid-community";
import {AgFrameworkComponent} from "ag-grid-angular";

export interface DropDownFilterParams extends IFloatingFilterParams {
	value: any;
	dropDownOptions: any[];
	type: string;
	filterOptionType: string;
}

@Component({
	template: `
		<select [(ngModel)]="currentValue" (ngModelChange)="valueChanged()" data-show-value="true" data-popup-enabled="true" style="min-width: 100%;">
			<option *ngFor="let item of dropDownOptions" [value]="item.value">{{item.label}}</option>
		</select>`
})
export class DropDownFilterComponent implements IFloatingFilter, AgFrameworkComponent<DropDownFilterParams> {
	private params: DropDownFilterParams;
	public currentValue: any;
	public dropDownOptions;
	public filterOptionType;
	public suppressFilterButton;

	agInit(params: DropDownFilterParams): void {
		this.params = params;
		this.dropDownOptions = this.params.dropDownOptions;
		this.filterOptionType = this.params.filterOptionType || 'equals';
		this.currentValue = null;
		this.suppressFilterButton = false;
	}

	valueChanged() {
		let valueToUse = this.getFilterValue();
		this.params.parentFilterInstance((instance) => {
			(<TextFilter>instance).onFloatingFilterChanged(this.filterOptionType, valueToUse);
		});
	}

	onParentModelChanged(parentModel: any): void {
		if (!parentModel) {
			this.currentValue = null;
		} else {
			// note that the filter could be anything here, but our purposes we're assuming a greater than filter only,
			// so just read off the value and use that
			if(this.params.type == 'nullFilter') {
				this.currentValue = parentModel.model || null;
			} else {
				this.currentValue = parentModel.filter;
			}
		}
	}

	buildModel() {
		if (this.currentValue === null || this.currentValue == 'null') {
			return null;
		}

		if(this.params.type == 'nullFilter') {
			return this.currentValue;
		}

		return {
			filterType: 'text',
			type: this.filterOptionType,
			filter: this.currentValue
		};
	}

	getFilterValue() {
		if (this.currentValue === 0 || this.currentValue === null || this.currentValue == 'null') {
			return null;
		}

		return this.currentValue;
	}
}