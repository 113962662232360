<app-loader></app-loader>
<div id="wrapper" 
   [ngClass]="{'clrpad': !sideMenu.visible, 'toggled': !hideMenuView && sideMenu.visible}">
   <app-header></app-header>
   <app-side-menu *ngIf="sideMenu.visible"></app-side-menu>
   <div id="page-content-wrapper">
      <div class="container-fluid" [ngClass]="{'no-gutter-left-phone': !sideMenu.visible}">
         <div class="row">
            <div class="col-md-12" [ngClass]="{'no-gutter-left-phone': !sideMenu.visible}">
               <ol class="refresh-browser-banner" *ngIf="showRefreshMessage">
                  <li>{{ 'There has been a recent system update. Please refresh your browser for the changes to take effect.' }}</li>
               </ol>
               <app-breadcrumb></app-breadcrumb>
               <div id="notify-panel" class="alrtx">
                  <app-notification-message></app-notification-message>
               </div>
               <div class="col-md-12">
                  <router-outlet></router-outlet>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<app-footer></app-footer>