<div class="row">
	<div id="page-wrapper" >
		<div id="page-inner">
			<div class="panel panel-default">
				<div class="boxs3">
					<div class="col-md-12">
						<div class="col-md-3 padding-8">
							<div class="col-md-4 text-right"><strong>Get by:</strong></div>
							<div class="col-md-8">
								<select [(ngModel)]="getBy" class="form-control" (change)="value=''">
									<option *ngFor="let option of filterOptions" [value]="option">{{option}}</option>
								</select>
							</div>
						</div>

						<div class="col-md-6 padding-8" *ngIf="getBy == 'Server IP' || getBy == 'Function Name'">
							<div class="col-md-2 text-right"><strong>Value:</strong></div>
							<div class="col-md-10">
								<input type="text" class="form-control" [(ngModel)]="value" />
							</div>
						</div>

						<div class="col-md-6 padding-8" *ngIf="getBy == 'User ID'">
							<div class="col-md-2 text-right"><strong>Value:</strong></div>
							<div class="col-md-10">
								<input type="number" min="0" class="form-control" [(ngModel)]="value" />
							</div>
						</div>
						
						<div class="col-md-6 padding-8" *ngIf="getBy == 'Date'">
							<div class="col-md-6">
								<div class="col-md-4 text-right"><strong>From Date:</strong></div>
								<div class="col-md-8">
									<div class="form-inline">
										<div class="form-group">
											<div class="input-group">
												<input class="form-control" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="fromDate" bsDatepicker #d="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
												<span class="input-group-btn">
													<button class="btn btn-default btn-date" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-md-6">
								<div class="col-md-4 text-right"><strong>To Date:</strong></div>
								<div class="col-md-8">
									<div class="input-group date">
										<div class="form-inline">
											<div class="form-group">
												<div class="input-group">
													<input class="form-control" placeholder="mm/dd/yyyy" name="dp2" [(ngModel)]="toDate" bsDatepicker #d2="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
													<span class="input-group-btn">
														<button class="btn btn-default btn-date" (click)="d2.toggle()" type="button"><i class="fa fa-calendar"></i></button>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-1 padding-8"></div>

						<div class="col-md-2 padding-8 r">
							<button class="form-control btn btn-success" (click)="getLoggingInfo()">Get Logs</button>
							<button class="form-control btn btn-default" (click)="deleteLogs()" [disabled]="!anyLogSelected()">Delete Logs</button>
						</div>
					</div>
					<div>
						<table class="table table-stripped boxs">
							<thead>
								<tr>
									<th><input type="checkbox" [(ngModel)]="selectAll" (change)="selectAllToggle()"/> Select All</th>
									<th>Time</th>
									<th>Function Name</th>
									<th>Main Caller</th>
									<th>User ID</th>
									<th>Error</th>
									<th>Server IP</th>
									<th>Is Sched?</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let log of logData">
									<td><input type="checkbox" class="form-control" [(ngModel)]="log.selected" (change)="checkIfAllSelected();" /></td>
									<td>{{log.error_time | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
									<td>{{log.function_name}}</td>
									<td>{{log.main_caller}}</td>
									<td>{{log.user_id}}</td>
									<td>{{log.error}}</td>
									<td>{{log.server_ip}}</td>
									<td>{{log.is_sched}}</td>
									<td>
										<a [routerLink]="[log.id]" target="_blank">
											<span class="glyphicon glyphicon-info-sign large-icons" aria-hidden="true"></span>&nbsp;
										</a>
										<span (click)="delete(pageName, log.id)" title="Delete">
											<span class="pointer red-text glyphicon glyphicon-trash large-icons"></span>
										</span>
									</td>
								</tr>
							</tbody>
						</table>
						<pagination [allItems]="logInfo" (onPageChanged)="loadData($event)"></pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>