import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdminService } from '../../admin.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { UserService } from '../../../auth/user.service';

@Component({
	selector: 'app-faulted-queue',
	templateUrl: './faulted-queue.component.html',
	styleUrls: ['./faulted-queue.component.css']
})
export class FaultedQueueComponent implements OnInit, OnDestroy {

	faultQueueLog: {battviews: any, chargers: any} = {battviews: [], chargers: []};
	logDataBattview: any = [];
	logDataCharger: any = [];
	numLogsPerPage: number = 10;
	user: any;

	constructor(
		private userService: UserService,
		private adminService: AdminService,
		private breadcrumb: BreadcrumbService,
		private notificationMessage: NotificationMessageService
	) {
	}

	ngOnInit() {
		this.getData();
		this.breadcrumb.setBreadcrumb([
			{label: "Faulted Queue Log", url: ""}
		]);
		this.userService.currentUser.subscribe((user: any) => {
			this.user = user;
		});
	}

	getData() {
		this.adminService.getFaultQueueLog().subscribe(
			data => {
				this.faultQueueLog.battviews = data.battviews;
				this.faultQueueLog.chargers = data.chargers;
			}
		);
	}
	unlock(deviceType, deviceId) {
		var data = {
			deviceType: deviceType,
			deviceId: deviceId
		};
		this.adminService.unlockFaultQueueLog(deviceType, deviceId).subscribe(
			data => {
				this.getData();
				this.notificationMessage.setMessage(data, 'success', 10000, true);
			}
		);
	}
	delete(type, id) {
		this.adminService.deleteLog(id, type).subscribe(
			(isDeleted:any) => {
				if(isDeleted.result) {
					this.getData();
					this.notificationMessage.setMessage('Deleted Successfully!', 'success', 10000, true);        
				}
			}
		);
	};

	mapRequestType(requestId) {
		var types = ['mcbUploadFunc', 'battViewUploadFunc', 'uploadBattEvents', 'uploadMCBCycles', 'uploadMCBFaults', 'replaceMCBDevice', 'replaceBattviewDevice'];
		return types[+(requestId)-1];
	}

	loadData(data, type) {
		if(type == 'battview')
			this.logDataBattview = data;
		else
			this.logDataCharger = data;
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
