import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import * as moment from 'moment';
import { CommonUtil } from './utility/common.service';
import { UserService } from '../../auth/user.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ChartDataService {
	
	user;
	enablePrintPeriod = false;
	enablePrintOperation = false;
	enablePrintModel = false;
	enablePrintPluggedIn = false;
	enablePrintEQtype = false;
	disableMissedPlugs = true;

	widgetDataKeys: any = {
		kwhrs_output: 'KWHRs_sum',
		ahrs_returned: 'AHRs_sum',
		charger_plugins: 'plugins'
	}

	constructor(
		private commonUtil: CommonUtil,
		private userService: UserService,
		private translateService: TranslateService
	) {
		this.user = this.userService.getCurrentUser();
	}

	getCustomerHome(data, widgetName, dateRange, sites) {
		var sitesObject = {};
		for (let i in sites) {
			var site = sites[i];
			sitesObject[site.id] = site.name;
		}
		if (data){
			// force charge and inuse events as true (default), since we are checking customer dashboard and not site data
			let charge_events	= true,
				inuse_events	= true;

			let options = {inuse_events, charge_events};
			var returnData = this.generateCustomerWidgetData(data, widgetName, dateRange, sitesObject, data.customerWidgets, options);
			if (!returnData) {
				return false;
			}
			return returnData;
		}
	}
	
	generateWidgetData(allData, widgetName, options) {

		let dateRange			= options.dateRange || null,
			inactiveWidgets		= options.inactiveWidgets || null,
			operationType		= options.operationType || null,
			workingDaysOnly		= options.workingDaysOnly || false,
			pluggedIn			= options.pluggedIn || null,
			eqType				= options.eqType || null,
			tags				= options.tags || [],
			siteBattviewsTags	= options.siteTags || [],
			charge_events		= options.charge_events,
			inuse_events		= options.inuse_events;

		if(charge_events === undefined)	charge_events = true;
		if(inuse_events === undefined)	inuse_events = true;

		let data, tempData ,i ,j ,total ,item ,index, textArray;
		
		let widgetIndex = this.mapWidgetNameByIndexId(widgetName, workingDaysOnly, inuse_events, charge_events);
		
		let periodIndex = this.mapDateRangeToIndexId(dateRange);

		if (!this.checkWidgetExist(allData, widgetIndex)) {
			return false;
		}

		if (!Array.isArray(widgetIndex)) {
			data = allData[periodIndex][widgetIndex];
		} else {
			tempData = allData;
			for (let wIndex in widgetIndex) {
				tempData = tempData[widgetIndex[wIndex]];
			}
			data = tempData;
		}

		var listData = [];

		var operationIndex = null;
		switch (operationType) {
			case "F":
				operationIndex = 0;
			break;
			case "C":
				operationIndex = 1;
			break;
			case "O":
				operationIndex = 2;
			break;
		}

		var pluggedInIndex = null;
		switch (pluggedIn) {
			case "P":
				pluggedInIndex = 0;
			break;
			case "N":
				pluggedInIndex = 1;
			break;
		}

		var eqTypeIndex = 2;
		switch (eqType) {
			case "S":
				eqTypeIndex = 0;
			break;
			case "C":
				eqTypeIndex = 1;
			break;
		}

		var returnData = {};

		var widgetDisabled = inactiveWidgets.indexOf(widgetName) > -1;

		if (widgetDisabled) {
			return false;
		}

		var filteredInDevices = [];
		for(var tagIndex in tags) {
			var tagId = tags[tagIndex];
			filteredInDevices = filteredInDevices.concat(siteBattviewsTags[tagId]);
		}

		switch (widgetName) {
			case "missed_connection":
				var days_list = ['Sun', 'Mon','Tue','Wed','Thu','Fri','Sat'];
				listData = [];
				data = data[0];

				for (i=0; i<7; i++) {
					var y = [data[0][i]];
					for (j=1;j<data.length;j++) {
						y[j] = data[j][i];
					}
					listData.push({
						x: days_list[i],
						y: y
					});
				}

				returnData['list'] = listData;
				returnData['count'] = i;
			break;
			case "missed_connection_list":
				data = data[1];
				for (i = 0;i < 7; i++) {
					returnData[i] = {};
					for (j = 0; j < data.length; j++) {
						returnData[i][j] = {};
						returnData[i][j].list = [];

						for (index in data[j][i]) {
							item = data[j][i][index];
							returnData[i][j].list.push({battery_id:item[0], truck_id: item[3], battviewId: item[2], value: Math.round(item[1]/60)});
						}
					}
				}
			break;
			case "missed_connection_plugged_in":
				var days_list = ['Sun', 'Mon','Tue','Wed','Thu','Fri','Sat'];
				listData = [];
				data = data[0];

				for (i=0; i<7; i++) {
					listData.push({
						x: days_list[i],
						y: [data[0][i],data[1][i]],
						list_id:(i+1)
					});
				}

				returnData['list'] = listData;
				returnData['count'] = i;
			break;
			case "missed_connection_plugged_in_list":
				data = data[1];
				for (i = 0;i < 7; i++) {
					returnData[i] = {};
					for (j = 0; j < 2; j++) {
						returnData[i][j] = {};
						returnData[i][j].list = [];
						for (index in data[j][i]) {
							let item = data[j][i][index];
							returnData[i][j].list.push({battery_id:item[0], truck_id: item[3], battviewId: item[2], value: Math.round(item[1]/60)});
						}
					}
				}
			break;
			case "batt_eq_summary":
				listData = [];
				data = data[eqTypeIndex][0];
				total = 0;
				for (i=0; i<11; i++) {
					total += data[i];
				}

				for (i=0; i<11; i++) {
					listData.push({
						x:i*10,
						y: (total > 0 ? Math.round((data[i] / total) * 100) : 0),
						list_id:(i+1)
					});
				}

				returnData['list'] = listData;
				returnData['count'] = i;
			break;
			case "batt_eq_summary_list":
				data = data[eqTypeIndex][1];
				for (i = 0;i < data.length; i++) {
					returnData[i + 1] = {};
					returnData[i + 1].list = [];
					for (index in data[i]) {
						let item = data[i][index];
						returnData[i + 1].list.push({battery_id:item[0], truck_id: item[1], battviewId: item[2], value: item[3], battery_sn: item[4]});
					}
					returnData[i + 1].count = returnData[i + 1].list.length;
				}
			break;
			case "batt_eff_score":
				returnData['list'] = [];
				textArray = [
					'Alerts Percentage',
					'Fleet Utilization',
					'Productivity Score',
					'EQs score',
					'Battery Management',
					'Missed Connection',
					'Management Score'
				];
				for (i = 0; i <= 6; i++) {
					returnData['list'].push({Text:textArray[i],value:data[i]});
					var mainId = 'site_managment_score';
					if (i < 3) {
						mainId = 'site_productivity_score';
					}
					returnData['list'].push({main_id:mainId, score:data[i], Text:textArray[i]});
				}
				returnData['count'] = returnData['list'].length;
			break;
			case "batt_alert_high_temp":
			case "batt_alert_cold_temp":
				data = data[0];
				returnData['Pie'] = [
					{"ok": data[0], "list_id": 0},
					{"one_or_more": data[1], "list_id": 1}
				];
			break;
			case "batt_alert_high_temp_list":
				
				for (let iCounter = 0;iCounter < 2; iCounter++) {
					returnData[iCounter] = {};
					returnData[iCounter].list = [];
					for (index in data[1][iCounter]) {
						let item = data[1][iCounter][index];
						returnData[iCounter].list.push({battery_id:item[0],value:item[1],truck_id:item[2],battviewId:item[3],pageSection: 'events-log', pageSubSection: 'high-temperature', max_temp:item[4]});
					}
					returnData[iCounter].count = returnData[iCounter].list.length;
				}
			break;
			case "batt_alert_cold_temp_list":
				
				for (let iCounter = 0;iCounter < 2; iCounter++) {
					returnData[iCounter] = {};
					returnData[iCounter].list = [];
					for (index in data[1][iCounter]) {
						let item = data[1][iCounter][index];
						returnData[iCounter].list.push({battery_id:item[0],value:item[1],truck_id:item[2],battviewId:item[3],pageSection: 'events-log', min_temp:item[4]});
					}
					returnData[iCounter].count = returnData[iCounter].list.length;
				}
			break;
			case "batt_alert_missed_eq":
				var tempData1=0,tempData2=0;

				if (pluggedInIndex !== null) {
					tempData1 = data[pluggedInIndex][0][0];
					tempData2 = data[pluggedInIndex][0][1];
				} else {
					var missedArr		= [],
						notMissedArr	= [];

					// loop on ok and one_or_more data
					for (i = 1; i >= 0; i--) {
						// loop in plugged in and not plugged in
						for (j = 0; j <= 1; j++) {
							for (index in data[j][1][i]) {
								item = data[j][1][i][index];
								if (i === 1) {
									// one_or_more devices
									if (missedArr.indexOf(item[0]) === -1) {
										missedArr.push(item[0]);
										tempData2++;
									}
								} else {
									// ok devices
									if (missedArr.indexOf(item[0]) === -1 && notMissedArr.indexOf(item[0]) === -1) {
										notMissedArr.push(item[0]);
										tempData1++;
									}
								}
							}
						}
					}
				}
				returnData['Pie'] = [
					{"ok": tempData1, "list_id": 0},
					{"one_or_more": tempData2, "list_id": 1},
				];
			break;
			case "batt_alert_missed_fi":
				returnData['Pie'] = [
					{"ok": data[2][0][0], "list_id": 0},
					{"one_or_more": data[2][0][1], "list_id": 1},
				];
			break;
			case "batt_alert_missed_eq_list":
				var types = ['Plugged IN', 'Not Plugged IN'];

				var subSections = ['missed-eq-plugged-in', 'missed-eq-not-plugged-in'];
				if (widgetName == 'batt_alert_missed_fi_list') {
					subSections = ['missed-finish-plugged-in', 'missed-finish-not-plugged-in'];
				}

				// disable missed plugged in and missed not plugged in
				if (this.disableMissedPlugs) {
					subSections = ['missed-eq', 'missed-eq'];
					if (widgetName == 'batt_alert_missed_fi_list') {
						subSections = ['missed-finish', 'missed-finish'];
					}
				}

				var missedArrList		= [],
					notMissedArrList	= [];

				// loop on ok and one_or_more data
				for (i = 1; i >= 0; i--) {
					returnData[i] = {};
					returnData[i].list = [];
					var pluggedInList = [0,1];
					if (pluggedInIndex !== null) {
						pluggedInList = [pluggedInIndex];
					}
					for (var pluggedInListIndex in pluggedInList) {
						j = pluggedInList[pluggedInListIndex];
						for (index in data[j][1][i]) {
							item = data[j][1][i][index];
							var neededIndex = -1;
							if (i === 1) {
								if (missedArrList.indexOf(item[0]) === -1) {
									missedArrList.push(item[0]);
									returnData[i].list.push({battery_id:item[0],value:item[1],truck_id:item[2],battviewId:item[3], type: types[j] ,pageSection: 'battery-daily-usage', pageSubSection: subSections[j]});
								} else {
									returnData[i].list.forEach((searchItem, searchIndex) => {
										if (searchItem.battviewId === item[3]) {
											neededIndex = searchIndex;
										}
									});
									if (neededIndex > -1) {
										returnData[i].list[neededIndex].value += item[1];
									}
								}
							} else {
								if (notMissedArrList.indexOf(item[0]) === -1) {
									if (missedArrList.indexOf(item[0]) === -1) {
										notMissedArrList.push(item[0]);
										returnData[i].list.push({battery_id:item[0],value:item[1],truck_id:item[2],battviewId:item[3], type: types[j] ,pageSection: 'battery-daily-usage', pageSubSection: subSections[j]});
									}
								} else {
									returnData[i].list.forEach((searchItem, searchIndex) => {
										if (searchItem.battviewId === item[3]) {
											neededIndex = searchIndex;
										}
									});
									if (neededIndex > -1) {
										returnData[i].list[neededIndex].value += item[1];
									}
								}
							}
						}
					}
				}
			break;
			case "batt_alert_missed_fi_list":

				// loop on ok and one_or_more data
				for (i = 1; i >= 0; i--) {
					returnData[i] = {};
					returnData[i].list = [];
					
					for (index in data[2][1][i]) {
						item = data[2][1][i][index];
						returnData[i].list.push({battery_id:item[0],expected_finish:item[1],actual_finish:item[2],truck_id:item[3],battviewId:item[4], pageSection: 'battery-daily-usage', pageSubSection: 'missed-finish'});
					}
				}
			break;
			case "missed_plugin_charge_cycles_list":
				// loop on ok and one_or_more data
				for (i = 1; i >= 0; i--) {
					returnData[i] = {};
					returnData[i].list = [];
					
					for (index in data[1][i]) {
						item = data[1][i][index];
						returnData[i].list.push({battery_id: item[0], actual_cycles: item[1], expected_cycles: item[2], truck_id: item[3], battviewId: item[4], pageSection: 'events-log'});
					}
				}
			break;
			case "idle_times_alert_list":
				// loop on ok and one_or_more data
				for (i = 1; i >= 0; i--) {
					returnData[i] = {};
					returnData[i].list = [];
					
					for (index in data[1][i]) {
						item = data[1][i][index];
						returnData[i].list.push({battery_id: item[0], actual_idle_time: item[1], expected_idle_time: item[2], truck_id: item[3], battviewId: item[4], pageSection: 'events-log'});
					}
				}
			break;
			case "batt_alert_over_under_charge":
				returnData['Pie'] = [
					{"under": data[0][0], "list_id": 1},
					{"over": data[0][1], "list_id": 2},
					{"ok": data[0][2], "list_id": 3}
				];
			break;
			case "batt_alert_over_under_charge_list":
				for (let i = 0;i < 3; i++) {
					returnData[i] = {};
					returnData[i].list = [];
					for (index in data[1][i]) {
						let item = data[1][i][index];
						var chargePercentage;
						if(item[1] < 1) {
							chargePercentage = "No Usage";	
						} else {
							chargePercentage = item[1];
						}
						
						if(chargePercentage != null && isFinite(chargePercentage)) {
							chargePercentage += " %";
						}

						returnData[i].list.push({
							battery_id:item[0],
							charge_percentage:chargePercentage,
							truck_id:item[2],
							battviewId:item[3]
						});
					}
					returnData[i].count = returnData[i].list.length;
				}
			break;
			case "batt_alert_deep_discharge":
			case "batt_alert_deep_use":
			case "sulfated_battery_alerts":
			case "missed_plugin_charge_cycles":
			case "idle_times_alert":
			case "batt_alert_missed_eq_weekly":
				returnData['Pie'] = [
					{"ok": data[0][0], "list_id": 0},
					{"one_or_more": data[0][1], "list_id": 1}
				];
			break;
			case "batt_alert_deep_discharge_list":
			case "batt_alert_deep_use_list":
				var pageSubSection = 'potential-weak-cells';
				if (widgetName == 'batt_alert_deep_discharge_list') {
					pageSubSection = 'deep-discharge';
				}
				for (let i = 0;i < 2; i++) {
					returnData[i] = {};
					returnData[i].list = [];
					for (index in data[1][i]) {
						let item = data[1][i][index];
						returnData[i].list.push({battery_id:item[0],value:item[4],truck_id:item[2],battviewId:item[3],pageSection: 'events-log', pageSubSection: pageSubSection, days_count:item[1]});
					}
					returnData[i].count = returnData[i].list.length;
				}
			break;
			case "batt_alert_missed_watering":
				returnData['good'] = data[0][0];
				returnData['bad'] = data[0][1];
			break;
			case "batt_alert_missed_watering_list":
				returnData = {
					0: {list:[],count:0},
					1: {
						list: [],
						count: 0
					}
				};

				for(let i=0; i < 2; i++) {
					for (index in data[1][i]) {
						let item = data[1][i][index];
						returnData[i].list.push({battery_id:item[0],value:item[1],truck_id:item[2],battviewId:item[3], pageSection: 'battery-daily-usage', pageSubSection: 'water-level-low'});
					}
					returnData[i].count = returnData[i].list.length;
				}
			break;
			case "sulfated_battery_alerts_list":
			case "batt_alert_missed_eq_weekly_list":
				returnData = {
					0: {list:[],count:0},
					1: {
						list: [],
						count: 0
					}
				};

				for(i=0; i < 2; i++) {
					for (index in data[1][i]) {
						let item = data[1][i][index];
						returnData[i].list.push({battery_id:item[0],value:item[1],truck_id:item[2],battviewId:item[3], pageSection: 'battery-daily-usage', pageSubSection: 'missed-eq'});
					}
					returnData[i].count = returnData[i].list.length;
				}
			break;
			case "batt_battery_replacement":
			case "batt_ramaining_warranty" :
				returnData['Pie'] = [
					{value: data[0][0], list_id: 1},
					{value: data[0][1], list_id: 2},
					{value: data[0][2], list_id: 3},
					{value: data[0][3], list_id: 4},
					{value: data[0][4], list_id: 5}
				];
			break;
			case "batt_battery_replacement_list":
				index = 1;
				for (let i = 0;i <=4; i++) {
					returnData[index] = {};
					returnData[index].list = [];
					for (j in data[1][i]) {
						let item = data[1][i][j];
						returnData[index].list.push({battery_id:item[0],months:item[1],replacement_budget:item[2],age_years:item[3],battviewId:item[4]});
					}
					returnData[index].count = returnData[index].list.length;
					index++;
				}
			break;
			case "batt_alert_over_usage":
				let overUsageTempData1,overUsageTempData2;

				if (operationIndex !== null) {
					overUsageTempData1 = data[operationIndex][0][0];
					overUsageTempData2 = data[operationIndex][0][1];
				} else {
					overUsageTempData1 = data[0][0][0] + data[1][0][0] + data[2][0][0];
					overUsageTempData2 = data[0][0][1] + data[1][0][1] + data[2][0][1];
				}
				returnData['Pie'] = [
					{"ok_count": overUsageTempData1, "list_id": 1},
					{"bad_count": overUsageTempData2, "list_id": 2},
				];
				
			break;
			case "batt_alert_over_usage_list":
				returnData = {
					0: {list:[],count:0},
					1: {
						list: [],
						count: 0
					}
				};

				if (operationIndex === null) {
					for (i=0; i< 3; i++) {
						for (var type=0; type< 2; type++) {
							for (index in data[i][1][type]) {
								let item = data[i][1][type][index];
								returnData[type].list.push({battery_id:item[0],ebus:item[1],truck_id:item[2],battviewId:item[3], pageSection: 'battery-daily-usage', pageSubSection: 'high-ebu-usage',max:item[4],average:item[5]});
							}
							returnData[type].count = returnData[type].list.length;
						}
					}
				} else {
					for (var type=0; type< 2; type++) {
						for (index in data[operationIndex][1][type]) {
							let item = data[operationIndex][1][type][index];
							returnData[type].list.push({battery_id:item[0],ebus:item[1],truck_id:item[2],battviewId:item[3], pageSection: 'battery-daily-usage', pageSubSection: 'high-ebu-usage',max:item[4],average:item[5]});
						}
						returnData[type].count = returnData[type].list.length;
					}
				}
			break;

			case "batt_ramaining_warranty_list":
				for (let i = 0;i <=4; i++) {
					returnData[i] = {};
					returnData[i].list = [];
					for (index in data[1][i]) {
						let item = data[1][i][index];
						returnData[i].list.push({battery_id:item[0],warranted_ahrs:item[1],remaining_ahrs:item[2],warranted_yrs:item[3],remaining_yrs_ahr:item[4],remaining_yrs:item[5],battviewId:item[6], truck_id:item[7], battery_sn:item[8]});
					}
					returnData[i].count = returnData[i].list.length;
				}
			break;
			case "batt_site_battery_aging":
				returnData['list'] = [];
				data = data[0];
				total = 0;
				for (i = 0; i <=5; i++) {
					total+=data[i];
				}
				for (i = 0; i <=5; i++) {
					returnData['list'].push({
						x:i*20,
						y:(total > 0 ? ((data[i] / total) * 100) : 0),
						list_id:i
					});
				}
				returnData['count'] = returnData['list'].length;
			break;
			case "batt_site_battery_aging_list":
				data = data[1];
				for (let i = 0;i <=5; i++) {
					returnData[i] = {};
					returnData[i].list = [];
					for (index in data[i]) {
						let item = data[i][index];
						returnData[i].list.push({battery_id:item[0],months:item[1],battviewId:item[2], truck_id:item[3], battery_sn: item[4]});
					}
					returnData[i].count = returnData[i].list.length;
				}
			break;
			case "missed_daily_connection":
				returnData['list'] = [];
				textArray = [
					"No Daily Idle Times",
					"At Least 30 mins Daily Idle Times",
					"At Least 1:00 hour Daily Idle Times",
					"At Least 1:30 hours Daily Idle Times",
					"At Least 2:00 hours Daily Idle Times",
					"> 2:00 hours Daily Idle Times"
				];
				data = data[0];
				for (let i = 0; i < 6; i++) {
					returnData['list'].push({Text:textArray[i],value:data[i], list_id:i});
				}
				
				returnData['count'] = returnData['list'].length;
			break;
			case "missed_daily_connection_list":
				data = data[1];
				for (i = 0;i < 6; i++) {
					returnData[i] = {};
					returnData[i].list = [];
					for (index in data[i]) {
						item = data[i][index];
						var TimeObj = this.commonUtil.convertHourToSeconds(item[1]*60, 0, true);
						var value = '';
						if (TimeObj['day'] > 0) {
							value += this.commonUtil.singularOrPlural(TimeObj['day'], 'day');
						}
						value += this.commonUtil.formatTime(TimeObj['hour'], TimeObj['min']);
						returnData[i].list.push({battery_id:item[0],value:value,truck_id:item[2],battviewId:item[3]});
					}
					returnData[i].count = returnData[i].list.length;
				}
			break;
			case "missed_connection_plugged_in_alert":
				returnData['list'] = [];
				textArray = [
					"No Daily Missed Connection Times",
					"At Least 30 mins Daily Missed Connection Times",
					"At Least 1:00 hour Daily Missed Connection Times",
					"At Least 1:30 hours Daily Missed Connection Times",
					"At Least 2:00 hours Daily Missed Connection Times",
					"> 2:00 hours Daily Missed Connection Times"
				];
				data = data[0];
				for (let i = 0; i < 6; i++) {
					returnData['list'].push({Text:textArray[i],value:data[i], list_id:i});
				}
				
				returnData['count'] = returnData['list'].length;
			break;
			case "missed_connection_plugged_in_alert_list":
				data = data[1];
				for (i = 0;i < 6; i++) {
					returnData[i] = {};
					returnData[i].list = [];
					for (index in data[i]) {
						item = data[i][index];
						var TimeObj = this.commonUtil.convertHourToSeconds(item[1]*60, 0, true);
						var value = '';
						if (TimeObj['day'] > 0) {
							value += this.commonUtil.singularOrPlural(TimeObj['day'], 'day');
						}
						value += this.commonUtil.formatTime(TimeObj['hour'], TimeObj['min']);
						returnData[i].list.push({battery_id:item[0],value:value,truck_id:item[2],battviewId:item[3],pageSection: 'events-log', pageSubSection: 'missed-plugged-in'});
					}
					returnData[i].count = returnData[i].list.length;
				}
			break;
		}

		return returnData;		
	}
	
	generateSitePerformanceWidgetData(dataObject, widgetName, options) {
		if(options.isChargers) {
			return this.generateChargersPerformanceWidgetData(dataObject, widgetName, options)
		}
		var dateRange			= options.dateRange,
			operationType		= options.operationType,
			workingDaysOnly		= options.workingDaysOnly,
			inactiveWidgets		= dataObject.inactiveWidgets,
			eqType				= options.eqType,
			battviewsData		= dataObject.battviewsData,
			truckviewsData		= dataObject.truckviewsData,
			allData				= dataObject.sitePerformance,
			isList				= options.isList,
			tags				= options.tags || [],
			siteBattviewsTags	= options.siteTags || [],
			dataTypeModel   	= options.dataTypeModel,
			isACTuser			= options.isACTuser || false,
			charge_events		= options.charge_events,
			inuse_events		= options.inuse_events,
			dateRangeStart		= options.dateRangeStart || 1,
			dateRangeEnd		= options.dateRangeEnd || 1;

		if(charge_events === undefined)	charge_events = true;
		if(inuse_events === undefined)	inuse_events = true;

		var data,tempData,i,j,total,item,index,textArray, battviewId, value, group, batteryFilteredOut,
			battviewdata, totalValues, totalBatteriesCount, tempValue;
		var widgetIndex = widgetName;
		var periodIndex = this.mapDateRangeToIndexId(dateRange);

		if (!this.checkPerformanceWidgetExist(allData, widgetName, inactiveWidgets)) {
			return false;
		}

		var listData = [];

		var operationIndex = null;
		switch (operationType) {
			case "F":
				operationIndex = 0;
			break;
			case "C":
				operationIndex = 1;
			break;
			case "O":
				operationIndex = 2;
			break;
			case "L":
				operationIndex = 3;
			break;
		}

		var eqTypeIndex = 2;
		switch (eqType) {
			case "S":
				eqTypeIndex = 0;
			break;
			case "C":
				eqTypeIndex = 1;
			break;
		}

		let returnData: any = {};

		var elemnetsCount	= 0,
			hasOperations	= false,
			shift			= 0,
			groupSize		= 1;

		var filteredInDevices = [];
		for(var tagIndex in tags) {
			var tagId = tags[tagIndex];
			filteredInDevices = filteredInDevices.concat(siteBattviewsTags[tagId]);
		}

		var widgetData = allData[widgetName];
		switch (widgetName) {
			case 'batt_fleet_utilization':
			case 'batt_fleet_utilization_inuse':
				elemnetsCount	= 11;
				hasOperations	= true;
				shift			= 5;
				groupSize		= 10;

				var workingDaysIndex = (workingDaysOnly ? 1 : 0);

				if(isList) {
					returnData = [];
					for (i = 0; i < elemnetsCount; i++) {
						returnData.push({list: [], count: 0});
					}
					for(battviewId in widgetData) {
						value = widgetData[battviewId][workingDaysIndex][periodIndex];
						if (value !== +(value)) {
							continue;
						}
						battviewdata = battviewsData[+(battviewId)];
						if(!battviewdata)
							continue;

						batteryFilteredOut = false;
						if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
							batteryFilteredOut = true;
						}

						if (!batteryFilteredOut && operationIndex !== null && !this.checkIsValidOperation(battviewdata, operationIndex)) {
							batteryFilteredOut = true;
						}

						if (!batteryFilteredOut) {
							group = this.getValueGroup(value, shift, groupSize, (elemnetsCount -1), 0);
							returnData[group].list.push({
								battviewId: battviewId,
								value: value,
								battery_id:battviewdata.batteryid,
								truck_id: battviewdata.truckid,
								battery_sn: battviewdata.batterysn
							});
							returnData[group].count++;
						}
					}
				} else {
					totalValues = [];
					totalBatteriesCount	= 0;
					for (i = 0; i < elemnetsCount; i++) {
						totalValues.push(0);
					}
					for(battviewId in widgetData) {
						value = widgetData[battviewId][workingDaysIndex][periodIndex];
						if (value !== +(value)) {
							continue;
						}

						battviewdata = battviewsData[+(battviewId)];
						if(!battviewdata)
							continue;

						batteryFilteredOut = false;
						if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
							batteryFilteredOut = true;
						}

						if (!batteryFilteredOut && operationIndex !== null && !this.checkIsValidOperation(battviewdata, operationIndex)) {
							batteryFilteredOut = true;
						}

						if (!batteryFilteredOut) {
							group = this.getValueGroup(value, shift, groupSize, (elemnetsCount -1), 0);
							totalValues[group]++;
							totalBatteriesCount++;
						}
					}

					for (i = 0; i < elemnetsCount; i++) {
						listData.push({
							x: i*10,
							y: totalValues[i] / totalBatteriesCount * 100,
							list_id: i
						});
					}
					returnData.list = listData;
				}
			break;
			case 'batt_eq_summary':
				elemnetsCount	= 11;
				shift			= 5;
				groupSize		= 10;

				if(isList) {
					returnData = [];
					for (i = 0; i < elemnetsCount; i++) {
						returnData.push({list: [], count: 0});
					}
					for(battviewId in widgetData) {
						value = widgetData[battviewId][periodIndex];
						if (!Array.isArray(value)) {
							continue;
						}
						batteryFilteredOut = false;
						if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
							batteryFilteredOut = true;
						}

						if (batteryFilteredOut)
							continue;

						value = value[eqTypeIndex];
						battviewdata = battviewsData[+(battviewId)];
						if(!battviewdata)
							continue;

						group = this.getValueGroup(value, shift, groupSize, (elemnetsCount -1), 0);
						returnData[group].list.push({
							battviewId: battviewId,
							value: value,
							battery_id:battviewdata.batteryid,
							truck_id: battviewdata.truckid,
							battery_sn: battviewdata.batterysn
						});
						returnData[group].count++;
					}
				} else {
					totalValues = [];
					totalBatteriesCount	= 0;
					for (i = 0; i < elemnetsCount; i++) {
						totalValues.push(0);
					}
					for(battviewId in widgetData) {
						value = widgetData[battviewId][periodIndex];
						if (!Array.isArray(value)) {
							continue;
						}
						batteryFilteredOut = false;
						if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
							batteryFilteredOut = true;
						}

						if (batteryFilteredOut)
							continue;

						value = value[eqTypeIndex];
						group = this.getValueGroup(value, shift, groupSize, (elemnetsCount -1), 0);
						totalValues[group]++;
						totalBatteriesCount++;
					}

					for (i = 0; i < elemnetsCount; i++) {
						listData.push({
							x: i*10,
							y: totalValues[i] / totalBatteriesCount * 100,
							list_id: i
						});
					}
					returnData.list = listData;
				}
			break;
			case 'missed_connection':
				elemnetsCount	= 7;
				var days_list = ['Sun', 'Mon','Tue','Wed','Thu','Fri','Sat'];

				// Add calendar dates for "Last Week" only
				if(!dateRange || dateRange == 'W') {
					for(i=0;i<7;i++)
						days_list[i] += `\n${moment().subtract(1, 'week').utc().day(i).format('MM/DD')}`;
				}

				if(isList) {
					returnData = {};
					for (i=0; i<7; i++) {
						returnData[i] = {};
						for (j=0; j < 4; j++) {
							returnData[i][j] = {};
							returnData[i][j].list = [];
						}
					}
					for(battviewId in widgetData) {
						value = widgetData[battviewId][periodIndex];
						if (!Array.isArray(value)) {
							continue;
						}
						batteryFilteredOut = false;
						if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
							batteryFilteredOut = true;
						}

						if (batteryFilteredOut)
							continue;

						battviewdata = battviewsData[+(battviewId)];
						if(!battviewdata)
							continue;

						for(i=0; i < 7; i++) {
							tempValue = value[i];
							if (tempValue !== +(tempValue) || tempValue < (30*60))
								continue;

							group = 3;
							if (tempValue < (60*60)) {
								group = 0;
							} else if (tempValue < (120*60)) {
								group = 1;
							} else if (tempValue < (240*60)) {
								group = 2;
							}
							returnData[i][group].list.push({
								battviewId: battviewId,
								value: Math.round(tempValue/60),
								battery_id:battviewdata.batteryid,
								truck_id: battviewdata.truckid,
								battery_sn: battviewdata.batterysn
							});
						}
					}
				} else {
					totalValues = [];
					for (i = 0; i < elemnetsCount; i++) {
						totalValues.push([0,0,0,0]);
					}
					for(battviewId in widgetData) {
						value = widgetData[battviewId][periodIndex];
						if (!Array.isArray(value)) {
							continue;
						}
						batteryFilteredOut = false;
						if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
							batteryFilteredOut = true;
						}

						if (batteryFilteredOut)
							continue;

						for(i=0; i < 7; i++) {
							tempValue = value[i];
							if (tempValue !== +(tempValue))
								continue;
							group = 3;
							if (tempValue < (60*60)) {
								group = 0;
							} else if (tempValue < (120*60)) {
								group = 1;
							} else if (tempValue < (240*60)) {
								group = 2;
							}
							totalValues[i][group]++;
						}
					}
					for (i = 0; i < elemnetsCount; i++) {
						listData.push({
							x: days_list[i],
							y: totalValues[i],
							list_id: i
						});
					}
					returnData.list = listData;
				}
			break;
			case 'batt_ebu_usage_summary':
			case 'batt_events_ebu_usage_summary':
				elemnetsCount	= 7;
				hasOperations	= true;
				shift			= -0.625;
				groupSize		= 0.25;

				var workingDaysIndex = (workingDaysOnly ? 1 : 0);

				if(isList) {
					returnData = [];
					for (i = 0; i < elemnetsCount; i++) {
						returnData.push({list: [], count: 0});
					}
					for(battviewId in widgetData) {
						value = widgetData[battviewId][workingDaysIndex][periodIndex];
						if (!Array.isArray(value)) {
							continue;
						}
						battviewdata = battviewsData[+(battviewId)];
						if(!battviewdata)
							continue;

						batteryFilteredOut = false;
						if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
							batteryFilteredOut = true;
						}

						if (!batteryFilteredOut && operationIndex !== null && !this.checkIsValidOperation(battviewdata, operationIndex)) {
							batteryFilteredOut = true;
						}
						if (batteryFilteredOut)
							continue;

						group = this.getValueGroup(value[0], shift, groupSize, (elemnetsCount -1), 1);
						returnData[group].list.push({
							battviewId: battviewId,
							value: value[0],
							max_ebu: value[1],
							battery_id:battviewdata.batteryid,
							truck_id: battviewdata.truckid,
							battery_sn: battviewdata.batterysn
						});
						returnData[group].count++;
					}
				} else {
					totalValues = [];
					totalBatteriesCount	= 0;
					for (i = 0; i < elemnetsCount; i++) {
						totalValues.push(0);
					}
					for(battviewId in widgetData) {
						value = widgetData[battviewId][workingDaysIndex][periodIndex];
						if (!Array.isArray(value)) {
							continue;
						}

						battviewdata = battviewsData[+(battviewId)];
						if(!battviewdata)
							continue;

						batteryFilteredOut = false;
						if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
							batteryFilteredOut = true;
						}

						if (!batteryFilteredOut && operationIndex !== null && !this.checkIsValidOperation(battviewdata, operationIndex)) {
							batteryFilteredOut = true;
						}

						if (batteryFilteredOut)
							continue;

						group = this.getValueGroup(value[0], shift, groupSize, (elemnetsCount -1), 1);
						totalValues[group]++;
						totalBatteriesCount++;
					}

					var counter = 0;
					for (i = 0.5; i <= 2; i += 0.25) {
						listData.push({
							x: i,
							y: totalValues[counter] / totalBatteriesCount * 100,
							list_id: counter
						});
						counter++;
					}
					returnData.list = listData;
				}
			break;
			case 'batt_alert_summary':
				var textObject = {
					0: "Weekly Missed EQ",
					1: "High Temperature",
					2: "Over Charge",
					3: "Under Charge",
					4: "Potential Weak Cells",
					5: "Over Usage",
					6: "Missed Finish",
					7: "Deep Discharge",
					8: "Potentially Sulfated Battery Alert",
					9: "Water Level Alert",
					10: "Cold Temperature Alert"
				};

				let overChargeIndex		= "2",
					underChargeIndex	= "3",
					overUsageIndex		= "5";

				var hideElements = [];
				if (!isACTuser) {
					hideElements.push("Under Charge");
					hideElements.push("Cold Temperature Alert");
				}

				_.difference(_.range(20), dataTypeModel).forEach((item) => {
					if(hideElements.indexOf(textObject[item]) === -1)
						hideElements.push(textObject[item]);
				});

				if(isList) {
					returnData = {};
					for(i = 0; i < Object.keys(textObject).length;i++)
						returnData[i] = {list: [], count: 0};

					for(battviewId in widgetData) {
						for (i in textObject) {
							if (hideElements.indexOf(textObject[i]) > -1)
								continue;

							let idx = i;
							if([overChargeIndex, underChargeIndex, overUsageIndex].includes(i)) {
								switch(i) {
									case overChargeIndex:
										if(!charge_events && !inuse_events) {
											idx = 11;
										} else if(!charge_events && inuse_events) {
											idx = 13;
										} else if(charge_events && !inuse_events) {
											idx = 15;
										} else {
											idx = 17;
										}
									break;
									case underChargeIndex:
										if(!charge_events && !inuse_events) {
											idx = 12;
										} else if(!charge_events && inuse_events) {
											idx = 14;
										} else if(charge_events && !inuse_events) {
											idx = 16;
										} else {
											idx = 18;
										}
									break;
									case overUsageIndex:
										if(inuse_events)
											idx = 19;
									break;
								}
							}
							value = widgetData[battviewId][idx][periodIndex];
							if (!Array.isArray(value)) {
								continue;
							}
							batteryFilteredOut = false;
							if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
								batteryFilteredOut = true;
							}

							if (batteryFilteredOut)
								continue;

							battviewdata = battviewsData[+(battviewId)];
							if(!battviewdata)
								continue;

							returnData[i].list.push({
								battviewId: battviewId,
								value: value[0],
								value2: value[1],
								battery_id:battviewdata.batteryid,
								truck_id: battviewdata.truckid,
								battery_sn: battviewdata.batterysn
							});
							returnData[i].count++;
						}
					}
				} else {
					returnData.list = [];
					for (i in textObject) {
						if (hideElements.indexOf(textObject[i]) > -1) {
							continue;
						}
						returnData.list.push({Text:textObject[i],value:0, list_id:i});
					}
					for(battviewId in widgetData) {
						var diff = 0;
						for (i in textObject) {
							if (hideElements.indexOf(textObject[i]) > -1) {
								diff++;
								continue;
							}
							let idx = i;
							if([overChargeIndex, underChargeIndex, overUsageIndex].includes(i)) {
								switch(i) {
									case overChargeIndex:
										if(!charge_events && !inuse_events) {
											idx = 11;
										} else if(!charge_events && inuse_events) {
											idx = 13;
										} else if(charge_events && !inuse_events) {
											idx = 15;
										} else {
											idx = 17;
										}
									break;
									case underChargeIndex:
										if(!charge_events && !inuse_events) {
											idx = 12;
										} else if(!charge_events && inuse_events) {
											idx = 14;
										} else if(charge_events && !inuse_events) {
											idx = 16;
										} else {
											idx = 18;
										}
									break;
									case overUsageIndex:
										if(inuse_events)
											idx = 19;
									break;
								}
							}
							value = widgetData[battviewId][idx][periodIndex];
							if (!Array.isArray(value)) {
								continue;
							}
							batteryFilteredOut = false;
							if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
								batteryFilteredOut = true;
							}

							if (batteryFilteredOut)
								continue;

							returnData.list[i - diff].value++;
						}
					}
				}
			break;
			case 'idle_times':
				var textObjectIdleTimes =  ["12AM","1AM","2AM","3AM","4AM","5AM","6AM","7AM","8AM","9AM","10AM","11AM","12PM","1PM","2PM","3PM","4PM","5PM","6PM","7PM","8PM","9PM","10PM","11PM"];

				if(isList) {
					returnData = {};
					// for(battviewId in widgetData) {
					// 	for (i in textObjectIdleTimes) {
					// 		if (hideElements.indexOf(textObjectIdleTimes[i]) > -1)
					// 			continue;

					// 		value = widgetData[battviewId][i][periodIndex];
					// 		if (!Array.isArray(value)) {
					// 			continue;
					// 		}
					// 		batteryFilteredOut = false;
					// 		if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
					// 			batteryFilteredOut = true;
					// 		}

					// 		if (batteryFilteredOut)
					// 			continue;

					// 		battviewdata = battviewsData[+(battviewId)];
					//		if(!battviewdata)
					//			continue;
							
					// 		if(!returnData[i])
					// 			returnData[i] = {list: [], count: 0};

					// 		returnData[i].list.push({
					// 			battviewId: battviewId,
					// 			value: value[0],
					// 			value2: value[1],
					// 			battery_id:battviewdata.batteryid,
					// 			truck_id: battviewdata.truckid,
					// 			battery_sn: battviewdata.batterysn
					// 		});
					// 		returnData[i].count++;
					// 	}
					// }
				} else {
					returnData.list = [];
					returnData.countOfBatteries = 0;
					returnData.countOfDays = this.mapDateRangeToCountOfDays(dateRange);

					for (i in textObjectIdleTimes) {
						returnData.list.push({Text:textObjectIdleTimes[i],value:0, list_id:i});
					}
					for(battviewId in widgetData) {
						batteryFilteredOut = false;
						if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
							batteryFilteredOut = true;
						}

						if (batteryFilteredOut)
							continue;

						returnData.countOfBatteries++;

						for (var hour in textObjectIdleTimes) { // i is hour
							value = widgetData[battviewId][periodIndex][hour];
							if (!value) {
								continue;
							}
							returnData.list[hour].value += value;
						}
					}
					for (var hour in returnData.list) {
						returnData.list[hour].originalValue = returnData.list[hour].value;
						if(options.averageTime) {
							returnData.list[hour].value = Math.round(returnData.list[hour].value / returnData.countOfBatteries);
						}
					}
				}
			break;
			case 'truck_fleet_utilization':
				elemnetsCount	= 11;
				hasOperations	= true;
				shift			= 5;
				groupSize		= 10;

				var workingDaysIndex = (workingDaysOnly ? 1 : 0);

				if(isList) {
					returnData = [];
					for (i = 0; i < elemnetsCount; i++) {
						returnData.push({list: [], count: 0});
					}
					for(battviewId in widgetData) {
						value = widgetData[battviewId][workingDaysIndex][periodIndex];
						if (value !== +(value)) {
							continue;
						}
						battviewdata = truckviewsData[+(battviewId)];
						if(!battviewdata)
							continue;

						batteryFilteredOut = false;
						if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
							batteryFilteredOut = true;
						}

						if (!batteryFilteredOut && operationIndex !== null && !this.checkIsValidOperation(battviewdata, operationIndex)) {
							batteryFilteredOut = true;
						}

						if (!batteryFilteredOut) {
							group = this.getValueGroup(value, shift, groupSize, (elemnetsCount -1), 0);
							returnData[group].list.push({
								battviewId: battviewId,
								value: value,
								// battery_id:battviewdata.batteryid,
								truck_id: battviewdata.truckid,
								truck_sn: battviewdata.batterysn
							});
							returnData[group].count++;
						}
					}
				} else {
					totalValues = [];
					totalBatteriesCount	= 0;
					for (i = 0; i < elemnetsCount; i++) {
						totalValues.push(0);
					}
					for(battviewId in widgetData) {
						value = widgetData[battviewId][workingDaysIndex][periodIndex];
						if (value !== +(value)) {
							continue;
						}

						battviewdata = truckviewsData[+(battviewId)];
						if(!battviewdata)
							continue;

						batteryFilteredOut = false;
						if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
							batteryFilteredOut = true;
						}

						if (!batteryFilteredOut && operationIndex !== null && !this.checkIsValidOperation(battviewdata, operationIndex)) {
							batteryFilteredOut = true;
						}

						if (!batteryFilteredOut) {
							group = this.getValueGroup(value, shift, groupSize, (elemnetsCount -1), 0);
							totalValues[group]++;
							totalBatteriesCount++;
						}
					}

					for (i = 0; i < elemnetsCount; i++) {
						listData.push({
							x: i*10,
							y: totalValues[i] / totalBatteriesCount * 100,
							list_id: i
						});
					}
					returnData.list = listData;
				}
			break;
			case 'truck_ebu_usage_summary':
				elemnetsCount	= 7;
				hasOperations	= true;
				shift			= -0.625;
				groupSize		= 0.25;

				var workingDaysIndex = (workingDaysOnly ? 1 : 0);

				if(isList) {
					returnData = [];
					for (i = 0; i < elemnetsCount; i++) {
						returnData.push({list: [], count: 0});
					}
					for(battviewId in widgetData) {
						value = widgetData[battviewId][workingDaysIndex][periodIndex];
						if (!Array.isArray(value)) {
							continue;
						}
						battviewdata = truckviewsData[+(battviewId)];
						if(!battviewdata)
							continue;

						batteryFilteredOut = false;
						if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
							batteryFilteredOut = true;
						}

						if (!batteryFilteredOut && operationIndex !== null && !this.checkIsValidOperation(battviewdata, operationIndex)) {
							batteryFilteredOut = true;
						}
						if (batteryFilteredOut)
							continue;

						group = this.getValueGroup(value[0], shift, groupSize, (elemnetsCount -1), 1);
						returnData[group].list.push({
							battviewId: battviewId,
							value: value[0],
							max_ebu: value[1],
							battery_id:battviewdata.batteryid,
							truck_id: battviewdata.truckid,
							battery_sn: battviewdata.batterysn
						});
						returnData[group].count++;
					}
				} else {
					totalValues = [];
					totalBatteriesCount	= 0;
					for (i = 0; i < elemnetsCount; i++) {
						totalValues.push(0);
					}
					for(battviewId in widgetData) {
						value = widgetData[battviewId][workingDaysIndex][periodIndex];
						if (!Array.isArray(value)) {
							continue;
						}

						battviewdata = truckviewsData[+(battviewId)];
						if(!battviewdata)
							continue;

						batteryFilteredOut = false;
						if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
							batteryFilteredOut = true;
						}

						if (!batteryFilteredOut && operationIndex !== null && !this.checkIsValidOperation(battviewdata, operationIndex)) {
							batteryFilteredOut = true;
						}

						if (batteryFilteredOut)
							continue;

						group = this.getValueGroup(value[0], shift, groupSize, (elemnetsCount -1), 1);
						totalValues[group]++;
						totalBatteriesCount++;
					}

					var counter = 0;
					for (i = 0.5; i <= 2; i += 0.25) {
						listData.push({
							x: i,
							y: totalValues[counter] / totalBatteriesCount * 100,
							list_id: counter
						});
						counter++;
					}
					returnData.list = listData;
				}
			break;
			case 'truck_missed_connection':
				elemnetsCount	= 7;
				var days_list = ['Sun', 'Mon','Tue','Wed','Thu','Fri','Sat'];

				if(isList) {
					returnData = {};
					for (i=0; i<7; i++) {
						returnData[i] = {};
						for (j=0; j < 4; j++) {
							returnData[i][j] = {};
							returnData[i][j].list = [];
						}
					}
					for(battviewId in widgetData) {
						value = widgetData[battviewId][periodIndex];
						if (!Array.isArray(value)) {
							continue;
						}
						batteryFilteredOut = false;
						if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
							batteryFilteredOut = true;
						}

						if (batteryFilteredOut)
							continue;

						battviewdata = truckviewsData[+(battviewId)];
						if(!battviewdata)
							continue;

						for(i=0; i < 7; i++) {
							tempValue = value[i];
							if (tempValue !== +(tempValue))
								continue;

							group = 3;
							if (tempValue < (60*60)) {
								group = 0;
							} else if (tempValue < (120*60)) {
								group = 1;
							} else if (tempValue < (240*60)) {
								group = 2;
							}
							returnData[i][group].list.push({
								battviewId: battviewId,
								value: Math.round(tempValue/60),
								battery_id:battviewdata.batteryid,
								truck_id: battviewdata.truckid,
								battery_sn: battviewdata.batterysn
							});
						}
					}
				} else {
					totalValues = [];
					for (i = 0; i < elemnetsCount; i++) {
						totalValues.push([0,0,0,0]);
					}
					for(battviewId in widgetData) {
						value = widgetData[battviewId][periodIndex];
						if (!Array.isArray(value)) {
							continue;
						}
						batteryFilteredOut = false;
						if (tags.length > 0 && filteredInDevices.indexOf(battviewId) < 0) {
							batteryFilteredOut = true;
						}

						if (batteryFilteredOut)
							continue;

						for(i=0; i < 7; i++) {
							tempValue = value[i];
							if (tempValue !== +(tempValue))
								continue;
							group = 3;
							if (tempValue < (60*60)) {
								group = 0;
							} else if (tempValue < (120*60)) {
								group = 1;
							} else if (tempValue < (240*60)) {
								group = 2;
							}
							totalValues[i][group]++;
						}
					}
					for (i = 0; i < elemnetsCount; i++) {
						listData.push({
							x: days_list[i],
							y: totalValues[i],
							list_id: i
						});
					}
					returnData.list = listData;
				}
			break;
			case 'plc_summary':
				let data = {};
				for(let i = 1; i<=12; i++) {
					if(i < dateRangeStart) continue;
					if(i > dateRangeEnd) break;

					for(let battviewID in widgetData) {
						let bvData = widgetData[battviewID][i];
						if(!bvData)	continue;
						if(!data[battviewID]) {
							data[battviewID] = {
								chargerEventsCounts: 0,
								totalChargeEventsCount: 0
							};
						}
						data[battviewID].chargerEventsCounts += bvData.chargerEventsCounts;
						data[battviewID].totalChargeEventsCount += bvData.totalChargeEventsCount;
					}
				}

				elemnetsCount	= 11;
				shift			= 5;
				groupSize		= 10;

				if(isList) {
					returnData = [];
					for (let i = 0; i < elemnetsCount; i++) {
						returnData.push({list: [], count: 0});
					}
				} else {
					totalValues = [];
					var totalChargeEventsCount	= 0,
						chargerEventsCounts	= 0;
					for (let i = 0; i < elemnetsCount; i++) {
						totalValues.push(0);
					}
				}

				for(let battviewID in data) {
					let idx = data[battviewID];
					var value: any	= idx.totalChargeEventsCount == 0 ? 0 : Math.round((idx.chargerEventsCounts / idx.totalChargeEventsCount) * 100);

					battviewdata = battviewsData[+(battviewID)];

					if(!battviewdata)
						continue;

					var chargerFilteredOut = false;
					if (tags.length > 0 && filteredInDevices.indexOf(battviewID) < 0)
						chargerFilteredOut = true;

					if (!chargerFilteredOut) {
						totalChargeEventsCount += idx.totalChargeEventsCount;
						chargerEventsCounts += idx.chargerEventsCounts;
						let group = this.getValueGroup(value, shift, groupSize, (elemnetsCount -1), 0);
						
						if(isList) {
							returnData[group].list.push({
								battviewId: battviewID,
								value: value,
								battery_id:battviewdata.batteryid,
								truck_id: battviewdata.truckid,
								battery_sn: battviewdata.batterysn
							});
							returnData[group].count++;
						} else {
							totalValues[group]++;
						}
					}
				}

				if(!isList) {
					for (let i = 0; i < elemnetsCount; i++) {
						listData.push({
							x: i*10,
							y: totalValues[i],
							list_id: i
						});
					}
					returnData.list = listData;
					returnData.summary = {
						label: this.translateService.instant('charger.total_plc_activity'),
						value: totalChargeEventsCount > 0 ? (chargerEventsCounts / totalChargeEventsCount) * 100 : 0
					};
				}
			break;
		}
		return returnData;
	}

	mapWidgetNameByIndexId(widgetName, workingDaysOnly, inuse_events, charge_events) {
		let mappedObject = {
			batt_eq_summary:1,
			batt_eq_summary_list:1,
			batt_alert_summary:3,
			batt_alert_summary_list:3,
			batt_eff_score:5,
			batt_alert_high_temp:6,
			batt_alert_high_temp_list:6,
			batt_alert_missed_eq:7,
			batt_alert_missed_eq_list:7,
			batt_alert_missed_eq_weekly:20,
			batt_alert_missed_eq_weekly_list:20,
			batt_alert_over_under_charge:27,
			batt_alert_over_under_charge_list:27,
			batt_alert_deep_use:9,
			batt_alert_deep_use_list:9,
			batt_alert_deep_discharge:18,
			batt_alert_deep_discharge_list:18,
			batt_alert_over_usage:10,
			batt_alert_over_usage_list:10,
			batt_alert_missed_watering:11,
			batt_alert_missed_watering_list:11,
			missed_daily_connection:12,
			missed_daily_connection_list:12,
			missed_connection:13,
			missed_connection_list:13,
			batt_alert_missed_fi:15,
			batt_alert_missed_fi_list:15,
			missed_connection_plugged_in_alert:16,
			missed_connection_plugged_in_alert_list:16,
			missed_connection_plugged_in:17,
			missed_connection_plugged_in_list:17,
			sulfated_battery_alerts:19,
			sulfated_battery_alerts_list:19,
			missed_plugin_charge_cycles: 21,
			missed_plugin_charge_cycles_list: 21,
			idle_times_alert: 22,
			idle_times_alert_list: 22,
			batt_alert_cold_temp: 23,
			batt_alert_cold_temp_list: 23,
			batt_battery_replacement:[4,0],
			batt_battery_replacement_list:[4,0],
			batt_ramaining_warranty:[4,1],
			batt_ramaining_warranty_list:[4,1],
			batt_site_battery_aging:[4,2],
			batt_site_battery_aging_list:[4,2],
			battery_efficiency:5,
			battery_aging:[4,2],
			battery_utilization:0
		};

		if(inuse_events) {
			mappedObject.batt_alert_over_usage = 8;
			mappedObject.batt_alert_over_usage_list = 8;
			if(charge_events) {
				mappedObject.batt_alert_over_under_charge = 27;
				mappedObject.batt_alert_over_under_charge_list = 27;
			} else {
				mappedObject.batt_alert_over_under_charge = 25;
				mappedObject.batt_alert_over_under_charge_list = 25;
			}
		} else {
			if(charge_events) {
				mappedObject.batt_alert_over_under_charge = 26;
				mappedObject.batt_alert_over_under_charge_list = 26;
			} else {
				mappedObject.batt_alert_over_under_charge = 24;
				mappedObject.batt_alert_over_under_charge_list = 24;
			}
		}
		return mappedObject[widgetName];
	}

	mapDateRangeToIndexId(dateRange) {
		let validPeriods = ['90','30','14','7','Age','W','Y', 'siteInstallation'];
		let index = validPeriods.indexOf(dateRange);
		return (index >= 0 ? index : 5);
	}

	checkWidgetExist(data, widgetIndex) {

		if (!data || Object.keys(data).length === 0) {
			return false;
		}
		let widgetExists = false;

		// check 3 months only since if data exists in other periods it will be exists in this period
		let period = 0;
		let ignore_period = false;

		if (!Array.isArray(widgetIndex)) {
			widgetIndex = [widgetIndex];
		} else {
			if (widgetIndex[0] == 4) {
				ignore_period = true;
			}
		}
		if (!ignore_period) {
			data = data[period];
		}

		for (let i in widgetIndex) {
			data = data[widgetIndex[i]];
		}

		if (!data) {
			return widgetExists;
		}
		
		switch (data.length) {
			case 2:
				// Check index 0 except case 13 and 17 (index 0 is array) => Check all element in it
				var elements = data[0];
				if ([13,17].indexOf(widgetIndex[0]) > -1) {
					elements = data[0][0].concat(data[0][1]);
				}
				// Check index 0 except case 7 and 15 (index 0 is array) => Check all element in it
				if ([7,15].indexOf(widgetIndex[0]) > -1) {
					elements = data[0][0].concat(data[1][0]);
				}
				for (let i in elements) {
					if (+elements[i] > 0) {
						widgetExists = true;
						break;
					}
				}
			break;
			case 3:
				//Check index 0 for all arrays
				var elements = data[0][0].concat(data[1][0]).concat(data[2][0]);
				for (let i in elements) {
					if (+elements[i] > 0) {
						widgetExists = true;
						break;
					}
				}
			break;
			default:
				if (!Array.isArray(data)) {
					data = [data];
				}
				for (let i in data) {
					if (+data[i] > 0) {
						widgetExists = true;
						break;
					}
				}
			break;
		}
		
		return widgetExists;
	}
	
	generateWidgetPrintData(allData, widgetName, options, inactiveWidgets) {
		var datePeriod				= options.datePeriod || 0,
			operationType			= options.operationType || 0,
			pluggedIn				= options.pluggedIn || 0,
			printOption				= options.printOption || 'all',
			eqType					= options.eqType,
			isSitePerformanceWidget	= options.isSitePerformanceWidget || false,
			siteBattviewsData		= options.siteBattviewsData || {},
			userAlertsSettings		= options.userAlertsSettings || {},
			isACTuser				= options.isACTuser || false,
			charge_events			= options.charge_events,
			inuse_events			= options.inuse_events,
			dateRangeStart			= options.dateRangeStart || 1,
			dateRangeEnd			= options.dateRangeEnd || 1;

		var data, i, j, k, item, index, thisIndexList, operationIndex, tempData, operation, widgetData,
			pluggedInIndex, validIndexes, elemnetsCount, battviewId, battviewdata, prepareData, shift, groupSize, group;

		var returnData = [];

		var widgetDisabled = inactiveWidgets.indexOf(widgetName) > -1;

		if (widgetDisabled) {
			return false;
		}

		if(charge_events === undefined)	charge_events = true;
		if(inuse_events === undefined)	inuse_events = true;

		var widgetIndex = this.mapWidgetNameForPrint(widgetName, inuse_events, charge_events);
		if (Array.isArray(widgetIndex) && !isSitePerformanceWidget) {
			// These widgets don't have period
			data = allData;
			for (i in widgetIndex) {
				data = data[widgetIndex[i]];
			}
			data = data[1];

			for (i = 0;i <= data.length; i++) {
				thisIndexList = {};
				thisIndexList.title = this.mapWidgetIndexByText(widgetName, {index:i});
				thisIndexList.list = [];
				for (index in data[i]) {
					item = data[i][index];
					switch (widgetName) {
						case "batt_battery_replacement_list":
							thisIndexList.list.push({battery_id:item[0],months:item[1],replacement_budget:item[2],age_years:item[3],battviewId:item[4]});
						break;
						case "batt_ramaining_warranty_list":
							thisIndexList.list.push({battery_id:item[0],warranted_ahrs:item[1],remaining_ahrs:item[2],warranted_yrs:item[3],remaining_yrs_ahr:item[4],remaining_yrs:item[5],battviewId:item[6], truck_id:item[7],battery_sn:item[8]});
						break;
						case "batt_site_battery_aging_list":
							thisIndexList.list.push({battery_id:item[0],months:item[1],battviewId:item[2],truck_id:item[3], battery_sn: item[4]});
						break;
					}
				}
				if (thisIndexList.list.length > 0) {
					returnData.push(thisIndexList);
				}
			}
			return returnData;
		}

		var datePeriods = [0,1,2,3];
		if (!this.enablePrintPeriod) {
			var periodIndex = this.mapDateRangeToIndexId(datePeriod);
			datePeriods = [periodIndex];
		}
		var operationIndexes = [0,1,2,3];
		if (!this.enablePrintOperation) {
			switch (operationType) {
				case "F":
					operationIndexes = [0];
				break;
				case "C":
					operationIndexes = [1];
				break;
				case "O":
					operationIndexes = [2];
				break;
				case "L":
					operationIndexes = [3];
				break;
				default:
					this.enablePrintOperation = true;
				break;
			}
		}

		var pluggedInIndexes = [0,1];
		if (!this.enablePrintPluggedIn) {
			switch (pluggedIn) {
				case "P":
					pluggedInIndexes = [0];
				break;
				case "N":
					pluggedInIndexes = [1];
				break;
				default:
					this.enablePrintPluggedIn = true;
				break;
			}
		}

		var eqTypeIndex = 2;
		switch (eqType) {
			case "S":
				eqTypeIndex = 0;
			break;
			case "C":
				eqTypeIndex = 1;
			break;
		}

		validIndexes = [0,1];
		if (printOption == 'alertsOnly') {
			validIndexes = [1];
		}

		for (var dateIndex in datePeriods) {
			var period = datePeriods[dateIndex];
			if (!isSitePerformanceWidget)
				data = allData[period][widgetIndex];

			switch (widgetName) {
				case "batt_fleet_utilization":
				case "batt_fleet_utilization_inuse":
					prepareData = [];

					elemnetsCount	= 11;
					shift			= 5;
					groupSize		= 10;

					widgetData = allData.sitePerformance[widgetName];
					for (i = 0; i < elemnetsCount; i++) {
						// prepareData = [group][operation][isWorkingDaysOnly]
						prepareData.push([[[],[]],[[],[]],[[],[]],[[],[]]]);
					}
					for(battviewId in widgetData) {
						for(var isWorkingDaysOnly = 0 ; isWorkingDaysOnly < 2; isWorkingDaysOnly++) {
							let value = widgetData[battviewId][isWorkingDaysOnly][periodIndex];
							if (value !== (+value)) {
								continue;
							}
							battviewdata = siteBattviewsData[+(battviewId)];

							group = this.getValueGroup(value, shift, groupSize, (elemnetsCount -1), 0);
							let groupIdx = battviewdata.chargertype;
							if(battviewdata.batterytype == 1) {
								groupIdx = 3;
							}
							prepareData[group][groupIdx][isWorkingDaysOnly].push({
								battviewId: battviewId,
								value: value,
								battery_id:battviewdata.batteryid,
								truck_id: battviewdata.truckid,
								battery_sn: battviewdata.batterysn
							});
						}
					}

					for (i = 0;i < elemnetsCount; i++) {
						for (operationIndex in operationIndexes) {
							operation = operationIndexes[operationIndex];
							var widgetTitle = this.mapWidgetIndexByText(widgetName+'_list', {index:i, datePeriod: period,operationIndex:operation, widgetHasOperation: true});
							for (isWorkingDaysOnly = 0; isWorkingDaysOnly < 2; isWorkingDaysOnly++) {
								thisIndexList = {};
								thisIndexList.list = [];
								tempData = prepareData[i][operation][isWorkingDaysOnly];
								if (tempData.length > 0) {
									thisIndexList.list = tempData;
									if (isWorkingDaysOnly) {
										thisIndexList.title = widgetTitle + " (Working Days Only)";
									} else {
										thisIndexList.title = widgetTitle;
									}
									returnData.push(thisIndexList);
								}
							}
						}
					}
				break;
				case "batt_ebu_usage_summary":
				case "batt_events_ebu_usage_summary":
					prepareData = [];

					elemnetsCount	= 7;
					shift			= -0.625;
					groupSize		= 0.25;

					widgetData = allData.sitePerformance[widgetName];
					for (i = 0; i < elemnetsCount; i++) {
						// prepareData = [group][operation][isWorkingDaysOnly]
						prepareData.push([[[],[]], [[],[]], [[],[]], [[],[]]]);
					}
					for(battviewId in widgetData) {
						battviewdata = siteBattviewsData[+(battviewId)];

						for(var isWorkingDaysOnly = 0 ; isWorkingDaysOnly < 2; isWorkingDaysOnly++) {
							value = widgetData[battviewId][isWorkingDaysOnly][periodIndex];
							if (!Array.isArray(value)) {
								continue;
							}
							let groupIdx = battviewdata.chargertype;
							if(widgetName == 'batt_ebu_usage_summary' && battviewdata.batterytype == 1) {
								groupIdx = 3;
							}
							group = this.getValueGroup(value[isWorkingDaysOnly], shift, groupSize, (elemnetsCount -1), 1);
							prepareData[group][groupIdx][isWorkingDaysOnly].push({
								battviewId: battviewId,
								value: value[0],
								battery_id:battviewdata.batteryid,
								truck_id: battviewdata.truckid,
								battery_sn: battviewdata.batterysn,
								max_ebu: value[1]
							});
						}
					}

					for (i = 0;i < elemnetsCount; i++) {
						for (operationIndex in operationIndexes) {
							operation = operationIndexes[operationIndex];
							var widgetTitle = this.mapWidgetIndexByText(widgetName+'_list', {index:i, datePeriod: period,operationIndex:operation, widgetHasOperation: true});

							for (isWorkingDaysOnly = 0; isWorkingDaysOnly < 2; isWorkingDaysOnly++) {
								thisIndexList = {};
								thisIndexList.list = [];
								tempData = prepareData[i][operation][isWorkingDaysOnly];
								
								if (tempData.length > 0) {
									thisIndexList.list = tempData;
									if (isWorkingDaysOnly) {
										thisIndexList.title = widgetTitle + " (Working Days Only)";
									} else {
										thisIndexList.title = widgetTitle;
									}
									returnData.push(thisIndexList);
								}
							}
						}
					}
				break;
				case "missed_connection":
					elemnetsCount	= 7;

					prepareData = {};
					for (i=0; i<elemnetsCount; i++) {
						prepareData[i] = [];

						for (j=0; j < 4; j++) {
							prepareData[i][j] = [];
						}
					}
					widgetData = allData.sitePerformance[widgetName];
					for(battviewId in widgetData) {
						value = widgetData[battviewId][periodIndex];
						if (!Array.isArray(value)) {
							continue;
						}
						battviewdata = siteBattviewsData[+(battviewId)];

						for(i=0; i < elemnetsCount; i++) {
							tempData = value[i];
							if (tempData !== +(tempData))
								continue;

							group = 3;
							if (tempData < (60*60)) {
								group = 0;
							} else if (tempData < (120*60)) {
								group = 1;
							} else if (tempData < (240*60)) {
								group = 2;
							}
							prepareData[i][group].push({
								battviewId: battviewId,
								value: Math.round(tempData/60),
								battery_id:battviewdata.batteryid,
								truck_id: battviewdata.truckid,
								battery_sn: battviewdata.batterysn
							});
						}
					}

					for (i = 0;i < elemnetsCount; i++) {
						for (j = 0; j < 4; j++) {
							thisIndexList = {};
							thisIndexList.list = [];
							thisIndexList.title = this.mapWidgetIndexByText(widgetName+'_list', {index:i, datePeriod: period, secondIndex: j});
							tempData = prepareData[i][j];
							for (index in tempData) {
								thisIndexList.list.push(tempData[index]);
							}
							if (thisIndexList.list.length > 0) {
								returnData.push(thisIndexList);
							}
						}
					}
				break;
				case "missed_connection_plugged_in_list":
					data = data[1];
					for (i = 0;i < 7; i++) {
						for (j = 0; j < 2; j++) {
							thisIndexList = {};
							thisIndexList.title = this.mapWidgetIndexByText(widgetName, {index:i, datePeriod: period, secondIndex: j});
							thisIndexList.list = [];
							for (index in data[j][i]) {
								item = data[j][i][index];
								thisIndexList.list.push({battery_id:item[0], truck_id: item[3], battviewId: item[2], value: Math.round(item[1]/60)});
							}
							if (thisIndexList.list.length > 0) {
								returnData.push(thisIndexList);
							}
						}
					}
				break;
				case "batt_eq_summary":
					prepareData = [];

					elemnetsCount	= 11;
					shift			= 5;
					groupSize		= 10;

					widgetData = allData.sitePerformance[widgetName];
					for (i = 0; i < elemnetsCount; i++) {
						// prepareData = [group]
						prepareData.push([]);
					}
					for(battviewId in widgetData) {
						let value = widgetData[battviewId][periodIndex][eqTypeIndex];
						if (value !== +(value)) {
							continue;
						}
						battviewdata = siteBattviewsData[+(battviewId)];

						group = this.getValueGroup(value, shift, groupSize, (elemnetsCount -1), 0);
						prepareData[group].push({
							battviewId: battviewId,
							value: value,
							battery_id:battviewdata.batteryid,
							truck_id: battviewdata.truckid,
							battery_sn: battviewdata.batterysn
						});
					}

					for (i = 0;i <= elemnetsCount; i++) {
						thisIndexList = {};
						thisIndexList.title = this.mapWidgetIndexByText(widgetName+'_list', {index:i, datePeriod: period});
						thisIndexList.list = [];
						tempData = prepareData[i];
						for (index in tempData) {
							thisIndexList.list.push(tempData[index]);
						}
						if (thisIndexList.list.length > 0) {
							returnData.push(thisIndexList);
						}
					}
				break;
				case "sulfated_battery_alerts_list":
				case "batt_alert_missed_eq_weekly_list":
					data = data[1];
					for (j in validIndexes) {
						i = validIndexes[j];
						thisIndexList = {};
						thisIndexList.title = this.mapWidgetIndexByText(widgetName, {index:i, datePeriod: period});
						thisIndexList.list = [];
						for (index in data[i]) {
							item = data[i][index];
							thisIndexList.list.push({battery_id:item[0], value: item[1], truck_id: item[2]});
						}
						if (thisIndexList.list.length > 0) {
							returnData.push(thisIndexList);
						}
					}
				break;
				case "batt_alert_summary":
					var hiddenIndexes = [];
					if (!isACTuser) {
						hiddenIndexes.push(3);
						hiddenIndexes.push(8);
						hiddenIndexes.push(10);
					}
					_.difference([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], options.dataTypeModel).forEach((item) => {
						if(hiddenIndexes.indexOf(item) === -1)
							hiddenIndexes.push(item);
					});
					let allFiledsColumnsAlertSummary: any = [
						[{title: "Number of weekly missed EQs", property: "value"}],
						[
							{title: "Number of times high temperature recorded", property: "value"},
							{title: "Max temperature recorded", property: "value2"}
						],
						[{title: "% over charge", property: "value"}],
						[{title: "% under charge", property: "value"}],
						[
							{title: "Min Load Voltage", property: "value"},
							{title: "Days of Low Voltage", property: "value2"},
						],
						[
							{title: "Average EBUs", property: "value"},
							{title: "Expected EBUs", property: "value2"}
						],
						[
							{title: "Expected FI Cycles", property: "value"},
							{title: "Actual FI Cycles", property: "value2"}
						],
						[
							{title: "Days of Deep Discharge", property: "value"},
							{title: "Min OCV (VPC)", property: "value2"}
						],
						[{title: "Number of Potentially Sulfated Battery (+4 EQ Missed)", property: "value"}],
						[{title: "Days of Low Water", property: "value"}],
						[
                            {title: "Number of times cold temperature recorded", property: "value"},
                            {title: "Min temperature recorded", property: "value2"}
                        ]
					];

					let overChargeIndex		= 2,
						underChargeIndex	= 3,
						overUsageIndex		= 5;

					prepareData = [];

					elemnetsCount = Object.keys(allFiledsColumnsAlertSummary).length;

					widgetData = allData.sitePerformance[widgetName];
					for (i = 0; i < elemnetsCount; i++) {
						// prepareData = [group]
						prepareData.push([]);
					}
					for(battviewId in widgetData) {
						for(i =0;i < Object.keys(allFiledsColumnsAlertSummary).length; i++) {

							if (hiddenIndexes.indexOf(i) > -1) {
								continue;
							}

							let idx = i;
							if([overChargeIndex, underChargeIndex, overUsageIndex].includes(i)) {
								switch(i) {
									case overChargeIndex:
										if(!charge_events && !inuse_events) {
											idx = 11;
										} else if(!charge_events && inuse_events) {
											idx = 13;
										} else if(charge_events && !inuse_events) {
											idx = 15;
										} else {
											idx = 17;
										}
									break;
									case underChargeIndex:
										if(!charge_events && !inuse_events) {
											idx = 12;
										} else if(!charge_events && inuse_events) {
											idx = 14;
										} else if(charge_events && !inuse_events) {
											idx = 16;
										} else {
											idx = 18;
										}
									break;
									case overUsageIndex:
										if(inuse_events)
											idx = 19;
									break;
								}
							}
							value = widgetData[battviewId][idx][periodIndex];
							if (!Array.isArray(value)) {
								continue;
							}
							battviewdata = siteBattviewsData[+(battviewId)];

							prepareData[i].push({
								battviewId: battviewId,
								value: value[0],
								battery_id:battviewdata.batteryid,
								truck_id: battviewdata.truckid,
								value2: value[1]
							});
						}
					}

					for (i = 0;i <= elemnetsCount; i++) {
						thisIndexList = {};
						thisIndexList.title = this.mapWidgetIndexByText(widgetName+'_list', {index:i, datePeriod: period});
						thisIndexList.columns = [];
						thisIndexList.columns.push({title: "Battery ID", property: "battery_id"});
						if (userAlertsSettings.view_truck_id) {
							thisIndexList.columns.push({title: "Truck ID", property: "truck_id"});
						}
						thisIndexList.columns = thisIndexList.columns.concat(allFiledsColumnsAlertSummary[i]);
						thisIndexList.list = [];
						tempData = prepareData[i];
						for (index in tempData) {
							thisIndexList.list.push(tempData[index]);
						}
						if (thisIndexList.list.length > 0) {
							returnData.push(thisIndexList);
						}
					}
				break;
				case "batt_alert_missed_fi_list":

					for (j in validIndexes) {
						i = validIndexes[j];
						returnData[i] = {};
						returnData[i].list = [];
						returnData[i].title = this.mapWidgetIndexByText(widgetName, {index:i, datePeriod: period});
						
						for (index in data[2][1][i]) {
							item = data[2][1][i][index];
							returnData[i].list.push({battery_id:item[0],expected_finish:item[1],actual_finish:item[2],truck_id:item[3],battviewId:item[4], pageSection: 'battery-daily-usage', pageSubSection: 'missed-finish'});
						}
					}
				break;
				case "missed_plugin_charge_cycles_list":
					data = data[1];
					for (j in validIndexes) {
						i = validIndexes[j];
						thisIndexList = {};
						thisIndexList.title = this.mapWidgetIndexByText(widgetName, {index:i, datePeriod: period});
						thisIndexList.list = [];
						for (index in data[i]) {
							item = data[i][index];
							thisIndexList.list.push({battery_id: item[0], actual_cycles: item[1], expected_cycles: item[2], truck_id: item[3], battviewId: item[4]});
						}
						if (thisIndexList.list.length > 0) {
							returnData.push(thisIndexList);
						}
					}
				break;
				case "idle_times_alert_list":
					data = data[1];
					for (j in validIndexes) {
						i = validIndexes[j];
						thisIndexList = {};
						thisIndexList.title = this.mapWidgetIndexByText(widgetName, {index:i, datePeriod: period});
						thisIndexList.list = [];
						for (index in data[i]) {
							item = data[i][index];
							thisIndexList.list.push({battery_id: item[0], actual_idle_time: item[1], expected_idle_time: item[2], truck_id: item[3], battviewId: item[4]});
						}
						if (thisIndexList.list.length > 0) {
							returnData.push(thisIndexList);
						}
					}
				break;
				case "batt_alert_missed_eq_list":
					var types = ['Plugged IN', 'Not Plugged IN'];
					if (!this.disableMissedPlugs) {
						for (j in validIndexes) {
							i = validIndexes[j];
							for (pluggedInIndex in pluggedInIndexes) {
								var pluggedIn: any = pluggedInIndexes[pluggedInIndex];
								tempData = data[pluggedIn][1][i];
								thisIndexList = {};
								thisIndexList.title = this.mapWidgetIndexByText(widgetName, {index:i, datePeriod: period,pluggedInIndex:pluggedIn, widgetHasPluggedIn: true});
								thisIndexList.list = [];
								for (index in tempData) {
									item = tempData[index];
									thisIndexList.list.push({battery_id:item[0],value:item[1],truck_id:item[2],battviewId:item[3], type: types[pluggedIn]});
								}
								if (thisIndexList.list.length > 0) {
									returnData.push(thisIndexList);
								}
							}
						}
					} else {
						// loop on ok and one_or_more data
						var missedArrList = [],
							notMissedArrList = [];

						for (k in validIndexes) {
							i = validIndexes[k];
							thisIndexList = {};
							thisIndexList.title = this.mapWidgetIndexByText(widgetName, {index:i, datePeriod: period});
							thisIndexList.list = [];
							for (j = 0; j <= 1; j++) {
								for (index in data[j][1][i]) {
									item = data[j][1][i][index];
									if (i === 1) {
										if (missedArrList.indexOf(item[0]) === -1) {
											missedArrList.push(item[0]);
											thisIndexList.list.push({battery_id:item[0],value:item[1],truck_id:item[2],battviewId:item[3], type: types[j]});
										} else {
											var neededIndex = -1;
											thisIndexList.list.forEach((searchItem, searchIndex) => {
												if (searchItem.battviewId === item[3]) {
													neededIndex = searchIndex;
												}
											});
											if (neededIndex > -1) {
												thisIndexList.list[neededIndex].value += item[1];
											}
										}
									} else {
										if (notMissedArrList.indexOf(item[0]) === -1) {
											if (missedArrList.indexOf(item[0]) === -1) {
												notMissedArrList.push(item[0]);
												thisIndexList.list.push({battery_id:item[0],value:item[1],truck_id:item[2],battviewId:item[3], type: types[j]});
											}
										} else {
											var neededIndex = -1;
											thisIndexList.list.forEach((searchItem, searchIndex) => {
												if (searchItem.battviewId === item[3]) {
													neededIndex = searchIndex;
												}
											});
											if (neededIndex > -1) {
												thisIndexList.list[neededIndex].value += item[1];
											}
										}
									}
								}
							}

							if (thisIndexList.list.length > 0) {
								returnData.push(thisIndexList);
							}
						}
					}
				break;
				case "batt_alert_high_temp_list":
				case "batt_alert_cold_temp_list":
				case "batt_alert_deep_discharge_list":
				case "batt_alert_deep_use_list":
				case "batt_alert_missed_watering_list":
					for (j in validIndexes) {
						i = validIndexes[j];
						thisIndexList = {};
						thisIndexList.title = this.mapWidgetIndexByText(widgetName, {index:i, datePeriod: period});
						thisIndexList.list = [];
						for (index in data[1][i]) {
							item = data[1][i][index];
							switch (widgetName) {
								case 'batt_alert_high_temp_list':
									thisIndexList.list.push({battery_id:item[0],value:item[1],truck_id:item[2],battviewId:item[3], max_temp:item[4]});
								break;
								case 'batt_alert_cold_temp_list':
									thisIndexList.list.push({battery_id:item[0],value:item[1],truck_id:item[2],battviewId:item[3], min_temp:item[4]});
								break;
								case 'batt_alert_deep_discharge_list':
								case 'batt_alert_deep_use_list':
									thisIndexList.list.push({battery_id:item[0],value:item[4],truck_id:item[2],battviewId:item[3], days_count:item[1]});
								break;
								default:
									thisIndexList.list.push({battery_id:item[0],value:item[1],truck_id:item[2],battviewId:item[3]});
								break;
							}
						}
						if (thisIndexList.list.length > 0) {
							returnData.push(thisIndexList);
						}
					}
				break;
				case "batt_alert_over_under_charge_list":
					validIndexes = [0,1,2];
					if (printOption == 'alertsOnly') {
						validIndexes = [0,1];
					}

					let allFiledsColumns: any = [
						[{title: "% under charge", property: "charge_percentage"}],
						[{title: "% over charge", property: "charge_percentage"}],
						[{title: "Charge Percentage", property: "charge_percentage"}]
					];

					for (j in validIndexes) {
						i = validIndexes[j];
						thisIndexList = {};
						thisIndexList.title = this.mapWidgetIndexByText(widgetName, {index:i, datePeriod: period});
						thisIndexList.columns = [];
						thisIndexList.columns.push({title: "Battery ID", property: "battery_id"});
						if (userAlertsSettings.view_truck_id) {
							thisIndexList.columns.push({title: "Truck ID", property: "truck_id"});
						}
						thisIndexList.columns = thisIndexList.columns.concat(allFiledsColumns[i]);
						thisIndexList.list = [];
						for (index in data[1][i]) {
							item = data[1][i][index];
							thisIndexList.list.push({
								battery_id:item[0],
								charge_percentage:(item[1] < 1) ? "No Usage" : Math.round(item[1]),
								truck_id:item[2],
								battviewId:item[3]
							});
						}
						if (thisIndexList.list.length > 0) {
							returnData.push(thisIndexList);
						}
					}
				break;
				case "batt_alert_over_usage_list":
					for (j in validIndexes) {
						i = validIndexes[j];
						for (operationIndex in operationIndexes) {
							operation = operationIndexes[operationIndex];
							thisIndexList = {};
							thisIndexList.title = this.mapWidgetIndexByText(widgetName, {index:i, datePeriod: period, operationIndex: operation, widgetHasOperation: true});
							thisIndexList.list = [];
							for (index in data[operation][1][i]) {
								item = data[operation][1][i][index];
								thisIndexList.list.push({battery_id:item[0],ebus:item[1],truck_id:item[2],battviewId:item[3],max:item[4],average:item[5]});
							}
							if (thisIndexList.list.length > 0) {
								returnData.push(thisIndexList);
							}
						}
					}
				break;
				case "missed_daily_connection_list":
				case "missed_connection_plugged_in_alert_list":
					data = data[1];
					validIndexes = [0,1,2,3,4,5];
					if (printOption == 'alertsOnly') {
						validIndexes = [1,2,3,4,5];
					}
					for (j in validIndexes) {
						i = validIndexes[j];
						thisIndexList = {};
						thisIndexList.title = this.mapWidgetIndexByText(widgetName, {index:i, datePeriod: period});
						thisIndexList.list = [];
						for (index in data[i]) {
							item = data[i][index];
							var TimeObj: any = this.commonUtil.convertHourToSeconds(item[1]*60, 0, true);
							var value = '';
							if (TimeObj.day > 0) {
								value += this.commonUtil.singularOrPlural(TimeObj.day, 'day');
							}
							value += this.commonUtil.formatTime(TimeObj.hour, TimeObj.min);
							thisIndexList.list.push({battery_id:item[0],value:value,truck_id:item[2],battviewId:item[3]});
						}
						
						if (thisIndexList.list.length > 0) {
							returnData.push(thisIndexList);
						}
					}
				break;
				case "plc_summary":
					data = {};
					widgetData = allData.sitePerformance[widgetName];
					for(let i = 1; i<=12; i++) {
						if(i < dateRangeStart) continue;
						if(i > dateRangeEnd) break;
	
						for(let battviewID in widgetData) {
							let bvData = widgetData[battviewID][i];
							if(!bvData)	continue;
							if(!data[battviewID]) {
								data[battviewID] = {
									chargerEventsCounts: 0,
									totalChargeEventsCount: 0
								};
							}
							data[battviewID].chargerEventsCounts += bvData.chargerEventsCounts;
							data[battviewID].totalChargeEventsCount += bvData.totalChargeEventsCount;
						}
					}

					prepareData = [];

					elemnetsCount	= 11;
					shift			= 5;
					groupSize		= 10;

					for (let i = 0; i < elemnetsCount; i++) {
						prepareData.push([]);
					}

					for(let battviewID in data) {
						let idx = data[battviewID];
						let value: any	= idx.totalChargeEventsCount == 0 ? 0 : Math.round((idx.chargerEventsCounts / idx.totalChargeEventsCount) * 100);

						battviewdata = siteBattviewsData[+(battviewID)];

						if(!battviewdata)
							continue;

						let group = this.getValueGroup(value, shift, groupSize, (elemnetsCount -1), 0);

						prepareData[group].push({
							battviewId: battviewID,
							value: value,
							battery_id:battviewdata.batteryid,
							truck_id: battviewdata.truckid,
							battery_sn: battviewdata.batterysn
						});
					}

					for (let i = 0; i < elemnetsCount; i++) {
						var thisIndexList:any = {};
						thisIndexList.title = this.mapWidgetIndexByText(widgetName+'_list', {'index': i});
						thisIndexList.list = prepareData[i];

						if (thisIndexList.list.length > 0) {
							returnData.push(thisIndexList);
						}
					}
				break;
			}
		}
		return returnData;
	}

	generateChargerWidgetPrintData(allData, widgetName, options, inactiveWidgets) {
		var dateIndex		    = options.datePeriod || 'W',
			operationType	    = options.operationType || 0,
			modelType		    = options.modelType || 0,
			normalizeKW		    = options.normalizeKW || false,
			siteChargersData    = options.siteChargersData || {},
			dateRangeStart		= options.dateRangeStart || 1,
			dateRangeEnd		= options.dateRangeEnd || 1,
			siteAlertsSettings	= options.siteAlertsSettings,
			idx;

		var returnData = [];

		var widgetDisabled = inactiveWidgets.indexOf(widgetName) > -1;

		if (widgetDisabled) {
			return false;
		}

		var expectedCyclesKey = [
			'expected_fast_chargers_cycles',
			'expected_conventional_chargers_cycles',
			'expected_opportunity_chargers_cycles'
		];

		var datePeriods = [90, 30, 14, 7, 'W', 'Y'];
		var period = datePeriods.indexOf(dateIndex) > -1 ? datePeriods.indexOf(dateIndex) : 5;
		if (!this.enablePrintPeriod) {
			datePeriods = [dateIndex];
		}
		var operationIndexes = [0,1,2];
		if (!this.enablePrintOperation) {
			switch (operationType) {
				case "F":
					operationIndexes = [0];
				break;
				case "C":
					operationIndexes = [1];
				break;
				case "O":
					operationIndexes = [2];
				break;
				default:
					this.enablePrintOperation = true;
				break;
			}
		}

		var modelTypes = ['Q4', 'Q6', 'Q12'];
		if(!this.enablePrintModel) {
			if(modelTypes.indexOf(modelType) == -1)
				this.enablePrintModel = true;
		}

		for(var Idx in datePeriods) {
			
			var dateIndex:any = datePeriods[Idx];
			
			switch (widgetName) {
				case "charger_assets_utilization":
					var prepareData = [];

					var elemnetsCount	= 11;
					var shift			= 5;
					var groupSize		= 10;

					var widgetData = allData.data.count_of_cycles[dateIndex];

					for (let i = 0; i < elemnetsCount; i++) {
						prepareData.push({0: [], 1: [], 2: [], 'Q4': [], 'Q6': [], 'Q12': []});
					}

					for(let idx in widgetData) {
						var chargerId	= +widgetData[idx].chargerid;
						var value		= widgetData[idx].count;

						var chargerdata = siteChargersData[+chargerId];

						if(!chargerdata)
							continue;

						chargerdata.model = chargerdata.model || '';
						chargerdata.model = chargerdata.model.trimRight();

						var modeltype = chargerdata.model.split('-')[0];

						if(['Q4', 'Q6', 'Q12'].indexOf(modeltype) == -1)
							modeltype = 'Q12';

						var expectedCycles = siteAlertsSettings[expectedCyclesKey[+chargerdata.chargertype]];

						if(dateIndex == 'Y') {

							value = (100 * value) / expectedCycles;
						} else {
							
							if(dateIndex == 'W')
								dateIndex = 7; 

							var daysCalc = (dateIndex / 7);
							value = (100 * value) / (siteAlertsSettings.site_weekly_working_days * daysCalc * expectedCycles);
						}

						var group = this.getValueGroup(value, shift, groupSize, (elemnetsCount -1), 0);

						prepareData[group][chargerdata.chargertype].push({
							'chargerId':		chargerId,
							'value':			value,
							'chargerusername':	chargerdata.chargerusername,
							'serialnumber':		chargerdata.serialnumber
						});

						prepareData[group][modeltype].push({
							'chargerId':		chargerId,
							'value':			value,
							'chargerusername':	chargerdata.chargerusername,
							'serialnumber':		chargerdata.serialnumber
						});
					}

					for (let i = 0; i < elemnetsCount; i++) {
						for (var operationIndex in operationIndexes) {
							var operation = operationIndexes[operationIndex];
							var thisIndexList:any = {};
							thisIndexList.title = this.mapWidgetIndexByText(widgetName+'_list', {'index': i, 'datePeriod': period, 'operationIndex': operation, 'widgetHasOperation': true});
							thisIndexList.list = [];
							var tempData = prepareData[i][operation];
							for (var index in tempData) {
								thisIndexList.list.push(tempData[index]);
							}
							if (thisIndexList.list.length > 0) {
								returnData.push(thisIndexList);
							}
						}

						for (var Idx in modelTypes) {
							var modelType:any = modelTypes[Idx];
							var thisIndexList:any = {};
							thisIndexList.title = this.mapWidgetIndexByText(widgetName+'_list', {'index': i, 'datePeriod': period, 'modelType': modelType, 'widgetHasModel': true});
							thisIndexList.list = [];
							var tempData = prepareData[i][modelType];
							for (var index in tempData) {
								thisIndexList.list.push(tempData[index]);
							}
							if (thisIndexList.list.length > 0) {
								returnData.push(thisIndexList);
							}
						}
					}
				break;
				case "plc_activity":
					let data = {};
					for(let i = 1; i<=12; i++) {
						if(i < dateRangeStart) continue;
						if(i > dateRangeEnd) break;
	
						for(let chargerData of allData.data.PLC_summary[i]) {
							if(!data[chargerData.chargerid]) {
								data[chargerData.chargerid] = {
									BVcycleCounts: 0,
									totalCyclesCount: 0,
									chargerid: chargerData.chargerid
								};
							}
							data[chargerData.chargerid].BVcycleCounts += chargerData.BVcycleCounts;
							data[chargerData.chargerid].totalCyclesCount += chargerData.totalCyclesCount;
						}
					}
					var widgetData:any = Object.values(data);

					var prepareData = [];

					var elemnetsCount	= 11;
					var shift			= 5;
					var groupSize		= 10;

					for (let i = 0; i < elemnetsCount; i++) {
						prepareData.push({0: [], 1: [], 2: [], 'Q4': [], 'Q6': [], 'Q12': []});
					}

					for(let idx of widgetData) {
						if(idx.totalCyclesCount == 0)
							continue;
						var chargerId	= +idx.chargerid;
						var value: any	= idx.totalCyclesCount == 0 ? 0 : Math.round((idx.BVcycleCounts / idx.totalCyclesCount) * 100);

						var chargerdata = siteChargersData[+chargerId];

						if(!chargerdata)
							continue;

						chargerdata.model = chargerdata.model || '';
						chargerdata.model = chargerdata.model.trimRight();

						var modeltype = chargerdata.model.split('-')[0];

						if(['Q4', 'Q6', 'Q12'].indexOf(modeltype) == -1)
							modeltype = 'Q12';

						var group = this.getValueGroup(value, shift, groupSize, (elemnetsCount -1), 0);

						prepareData[group][chargerdata.chargertype].push({
							'chargerId':		chargerId,
							'value':			value,
							'chargerusername':	chargerdata.chargerusername,
							'serialnumber':		chargerdata.serialnumber
						});

						prepareData[group][modeltype].push({
							'chargerId':		chargerId,
							'value':			value,
							'chargerusername':	chargerdata.chargerusername,
							'serialnumber':		chargerdata.serialnumber
						});
					}

					for (let i = 0; i < elemnetsCount; i++) {
						for (var operationIndex in operationIndexes) {
							var operation = operationIndexes[operationIndex];
							var thisIndexList:any = {};
							thisIndexList.title = this.mapWidgetIndexByText(widgetName+'_list', {'index': i, 'datePeriod': period, 'operationIndex': operation, 'widgetHasOperation': true});
							thisIndexList.list = [];
							var tempData = prepareData[i][operation];
							for (var index in tempData) {
								thisIndexList.list.push(tempData[index]);
							}
							if (thisIndexList.list.length > 0) {
								returnData.push(thisIndexList);
							}
						}

						for (var Idx in modelTypes) {
							var modelType:any = modelTypes[Idx];
							var thisIndexList:any = {};
							thisIndexList.title = this.mapWidgetIndexByText(widgetName+'_list', {'index': i, 'datePeriod': period, 'modelType': modelType, 'widgetHasModel': true});
							thisIndexList.list = [];
							var tempData = prepareData[i][modelType];
							for (var index in tempData) {
								thisIndexList.list.push(tempData[index]);
							}
							if (thisIndexList.list.length > 0) {
								returnData.push(thisIndexList);
							}
						}
					}
				break;

				case "charger_peak_whr":
					var prepareData = [];

					var widgetData = allData.data.max_WHR[dateIndex];
					elemnetsCount = widgetData.length;

					for (let i = 0; i < elemnetsCount; i++) {
						prepareData.push({0: [], 1: [], 2: [], 'Q4': [], 'Q6': [], 'Q12': []});
					}

					for(let idx in widgetData) {
						var chargerId	= +widgetData[idx].chargerid;
						var value:any	= (widgetData[idx].whr * 1.07).toFixed(2);

						var chargerdata = siteChargersData[+chargerId];
						
						if(!chargerdata)
							continue;

						if(normalizeKW)
							value = ((widgetData[idx].whr * 50) / chargerdata.numberofinstalledpms).toFixed(1);

						chargerdata.model = chargerdata.model || '';
						chargerdata.model = chargerdata.model.trimRight();

						var modeltype = chargerdata.model.split('-')[0];

						if(['Q4', 'Q6', 'Q12'].indexOf(modeltype) == -1)
							modeltype = 'Q12';

						prepareData[idx][chargerdata.chargertype].push({
							'chargerId':		chargerId,
							'value':			value,
							'chargerusername':	chargerdata.chargerusername,
							'serialnumber':		chargerdata.serialnumber
						});

						prepareData[idx][modeltype].push({
							'chargerId':		chargerId,
							'value':			value,
							'chargerusername':	chargerdata.chargerusername,
							'serialnumber':		chargerdata.serialnumber
						});
					}

					for (let i = 0; i < elemnetsCount; i++) {
						for (var operationIndex in operationIndexes) {
							var operation = operationIndexes[operationIndex];
							var thisIndexList:any = {};
							thisIndexList.title = this.mapWidgetIndexByText(widgetName+'_list', {'index': i, 'datePeriod': period, 'operationIndex': operation, 'widgetHasOperation': true});
							thisIndexList.list = [];
							var tempData = prepareData[i][operation];
							for (var index in tempData) {
								thisIndexList.list.push(tempData[index]);
							}
							if (thisIndexList.list.length > 0) {
								returnData.push(thisIndexList);
							}
						}

						for (var Idx in modelTypes) {
							var modelType:any = modelTypes[Idx];
							var thisIndexList:any = {};
							thisIndexList.title = this.mapWidgetIndexByText(widgetName+'_list', {'index': i, 'datePeriod': period, 'modelType': modelType, 'widgetHasModel': true});
							thisIndexList.list = [];
							var tempData = prepareData[i][modelType];
							for (var index in tempData) {
								thisIndexList.list.push(tempData[index]);
							}
							if (thisIndexList.list.length > 0) {
								returnData.push(thisIndexList);
							}
						}
					}
				break;

				case "kwhrs_output":
				case "ahrs_returned":
				case "charger_plugins":
				
					var prepareData = [];
					
					let dataKey = this.widgetDataKeys[widgetName];
					var widgetData = allData.data[dataKey][dateIndex];
					
					var summation = {};

					for(var dateText in widgetData) {

						var dayData = widgetData[dateText];

						for(let chargerId in dayData) {
						
							let value = +dayData[chargerId];
							if(typeof(summation[chargerId]) == 'undefined')
								summation[chargerId] = 0;

							summation[chargerId] += value;
						}
					}

					var siteChargerIds = Object.keys(siteChargersData);
					var summationIds = Object.keys(summation);

					siteChargerIds.forEach((ID) => {
						if(summationIds.indexOf(ID) == -1)
							summation[ID] = 0;
					});

					elemnetsCount = Object.keys(summation).length;

					for (let i = 0; i < elemnetsCount; i++) {
						prepareData.push({0: [], 1: [], 2: [], 'Q4': [], 'Q6': [], 'Q12': []});
					}

					idx = -1;

					for(let index in summation) {

						let chargerId = +index;

						let value = (widgetName == 'charger_plugins') ? summation[chargerId] : parseInt(''+(summation[chargerId] * 1.07)).toFixed(2);

						var chargerdata = siteChargersData[+chargerId];
						
						if(!chargerdata)
							continue;

						idx++;

						chargerdata.model = chargerdata.model || '';
						chargerdata.model = chargerdata.model.trimRight();

						var modeltype = chargerdata.model.split('-')[0];

						if(['Q4', 'Q6', 'Q12'].indexOf(modeltype) == -1)
							modeltype = 'Q12';

						prepareData[idx][chargerdata.chargertype].push({
							'chargerId':		chargerId,
							'value':			value,
							'chargerusername':	chargerdata.chargerusername,
							'serialnumber':		chargerdata.serialnumber
						});

						prepareData[idx][modeltype].push({
							'chargerId':		chargerId,
							'value':			value,
							'chargerusername':	chargerdata.chargerusername,
							'serialnumber':		chargerdata.serialnumber
						});
					}

					for (let i = 0; i < elemnetsCount; i++) {
						for (var operationIndex in operationIndexes) {
							var operation = operationIndexes[operationIndex];
							var thisIndexList:any = {};
							thisIndexList.title = this.mapWidgetIndexByText(widgetName+'_list', {'index': i, 'datePeriod': period, 'operationIndex': operation, 'widgetHasOperation': true});
							thisIndexList.list = [];
							var tempData = prepareData[i][operation];
							for (var index in tempData) {
								thisIndexList.list.push(tempData[index]);
							}
							if (thisIndexList.list.length > 0) {
								returnData.push(thisIndexList);
							}
						}

						for (var Idx in modelTypes) {
							var modelType:any = modelTypes[Idx];
							var thisIndexList:any = {};
							thisIndexList.title = this.mapWidgetIndexByText(widgetName+'_list', {'index': i, 'datePeriod': period, 'modelType': modelType, 'widgetHasModel': true});
							thisIndexList.list = [];
							var tempData = prepareData[i][modelType];
							for (var index in tempData) {
								thisIndexList.list.push(tempData[index]);
							}
							if (thisIndexList.list.length > 0) {
								returnData.push(thisIndexList);
							}
						}
					}
				break;

				case "site_energy_consumption":
				
					var prepareData		= [];
					var widgetData		= allData.data.KWHRs_sum[dateIndex];
					elemnetsCount	= Object.keys(widgetData).length;

					for (let i = 0; i < elemnetsCount; i++) {
						prepareData.push({0: [], 1: [], 2: [], 'Q4': [], 'Q6': [], 'Q12': []});
					}

					idx = -1;

					for(var dateText in widgetData) {

						chargerId = +chargerId;
						idx++;

						var dayData = widgetData[dateText];

						for(let dayDataIndex in dayData) {
							
							let value = (Math.round(dayData[dayDataIndex] * 1.07 * 100) / 100).toFixed(2);
							let chargerId = +dayDataIndex;
							
							var chargerdata = siteChargersData[chargerId];
							
							if(!chargerdata)
								continue;

							chargerdata.model = chargerdata.model || '';
							chargerdata.model = chargerdata.model.trimRight();

							var modeltype = chargerdata.model.split('-')[0];

							if(['Q4', 'Q6', 'Q12'].indexOf(modeltype) == -1)
								modeltype = 'Q12';

							prepareData[idx][chargerdata.chargertype].push({
								'chargerId':		chargerId,
								'value':			value,
								'chargerusername':	chargerdata.chargerusername,
								'serialnumber':		chargerdata.serialnumber
							});

							prepareData[idx][modeltype].push({
								'chargerId':		chargerId,
								'value':			value,
								'chargerusername':	chargerdata.chargerusername,
								'serialnumber':		chargerdata.serialnumber
							});
						}
					}

					for (let i = 0; i < elemnetsCount; i++) {
						for (var operationIndex in operationIndexes) {
							var operation = operationIndexes[operationIndex];
							var thisIndexList:any = {};
							thisIndexList.title = this.mapWidgetIndexByText(widgetName+'_list', {'index': i, 'datePeriod': period, 'operationIndex': operation, 'widgetHasOperation': true});
							thisIndexList.list = [];
							var tempData = prepareData[i][operation];
							for (var index in tempData) {
								thisIndexList.list.push(tempData[index]);
							}
							if (thisIndexList.list.length > 0) {
								returnData.push(thisIndexList);
							}
						}

						for (var Idx in modelTypes) {
							var modelType:any = modelTypes[Idx];
							var thisIndexList:any = {};
							thisIndexList.title = this.mapWidgetIndexByText(widgetName+'_list', {'index': i, 'datePeriod': period, 'modelType': modelType, 'widgetHasModel': true});
							thisIndexList.list = [];
							var tempData = prepareData[i][modelType];
							for (var index in tempData) {
								thisIndexList.list.push(tempData[index]);
							}
							if (thisIndexList.list.length > 0) {
								returnData.push(thisIndexList);
							}
						}
					}
				break;
				
				case "site_alerts_wdgt":

					var AC_faults				= allData.data.AC_faults['30'];
					var OV_faults				= allData.data.OV_faults['30'];
					var over_temp_faults		= allData.data.over_temp_faults['30'];
					var communication_faults	= allData.data.communication_faults['30'];
					var PMs_faults				= allData.data.PMs_faults['30'];

					var widgetData:any	= [AC_faults, OV_faults, over_temp_faults, communication_faults, PMs_faults];
					var faultName	= ['AC', 'Output OV', 'Over Temperature', 'Communication Fault', 'PM Faults'];

					var prepareData		= [];
					var faultIDs		= [];
					elemnetsCount	= faultName.length;

					for (let i = 0; i < elemnetsCount; i++) {
						prepareData.push({0: [], 1: [], 2: [], 'Q4': [], 'Q6': [], 'Q12': []});
					}

					var printColumns = [
						{title: "Cycle ID", property: "value"},
						{title: "PM ID", property: "value"}
					];

					for(var index in widgetData) {

						var typeData = widgetData[index];
						faultIDs.push({});

						for(var Idx in typeData) {
						
							var faultData	= typeData[Idx];
							var chargerId	= +faultData.chargerid;
							var chargerdata = siteChargersData[chargerId];
							
							if(!chargerdata || !chargerdata.actviewenable)
								continue;
							
							var faultid:any	= +faultData.cycleid;
							if(+index == 4)
								faultid = this.commonUtil.PMidToSerialNumber(faultData.pmid, chargerdata);
							
							if(!faultIDs[index][chargerId])
								faultIDs[index][chargerId] = [];

							faultIDs[index][chargerId].push(faultid);
						}

					}

					for(var index in faultIDs) {

						var typeData = faultIDs[index];

						for(let typeDataIndex in typeData) {
							
							chargerId		= +typeDataIndex;
							var IDs			= typeData[chargerId];
							var chargerdata = siteChargersData[chargerId];
							
							if(!chargerdata || !chargerdata.actviewenable)
								continue;

							chargerdata.model = chargerdata.model || '';
							chargerdata.model = chargerdata.model.trimRight();

							var modeltype = chargerdata.model.split('-')[0];

							if(['Q4', 'Q6', 'Q12'].indexOf(modeltype) == -1)
								modeltype = 'Q12';

							prepareData[index][chargerdata.chargertype].push({
								'chargerId':		chargerId,
								'value':			IDs.join(', '),
								'chargerusername':	chargerdata.chargerusername,
								'serialnumber':		chargerdata.serialnumber
							});

							prepareData[index][modeltype].push({
								'chargerId':		chargerId,
								'value':			IDs.join(', '),
								'chargerusername':	chargerdata.chargerusername,
								'serialnumber':		chargerdata.serialnumber
							});
						}
					}

					for (let i = 0; i < elemnetsCount; i++) {
						for (var operationIndex in operationIndexes) {
							var operation = operationIndexes[operationIndex];
							var thisIndexList:any = {};
							thisIndexList.title = faultName[i] + ' - ' + this.mapWidgetIndexByText(widgetName+'_list', {'index': i, 'datePeriod': period, 'operationIndex': operation, 'widgetHasOperation': true});
							thisIndexList.columns = [
								{title: "Charger ID", property: "chargerusername"},
								{title: "Charger SN", property: "serialnumber"}
							];
							thisIndexList.columns.push(printColumns[+(i == 4)]);
							thisIndexList.list = [];
							var tempData = prepareData[i][operation];
							for (var index in tempData) {
								thisIndexList.list.push(tempData[index]);
							}
							if (thisIndexList.list.length > 0) {
								returnData.push(thisIndexList);
							}
						}

						for (var Idx in modelTypes) {
							var modelType:any = modelTypes[Idx];
							var thisIndexList:any = {};
							thisIndexList.title = faultName[i] + ' - ' + this.mapWidgetIndexByText(widgetName+'_list', {'index': i, 'datePeriod': period, 'modelType': modelType, 'widgetHasModel': true});
							thisIndexList.columns = [
								{title: "Charger ID", property: "chargerusername"},
								{title: "Charger SN", property: "serialnumber"}
							];
							thisIndexList.columns.push(printColumns[+(i == 4)]);
							thisIndexList.list = [];
							var tempData = prepareData[i][modelType];
							for (var index in tempData) {
								thisIndexList.list.push(tempData[index]);
							}
							if (thisIndexList.list.length > 0) {
								returnData.push(thisIndexList);
							}
						}
					}
				break;
			}
		}
		return returnData;
	}

	mapWidgetIndexByText(widgetName, options) {
		var id					= options.index || 0,
			datePeriod			= options.datePeriod || 0,
			operationIndex		= options.operationIndex || 0,
			modelType			= options.modelType || 0,
			secondIndex			= options.secondIndex || 0,
			widgetHasOperation	= options.widgetHasOperation || false,
			pluggedInIndex		= options.pluggedInIndex || 0,
			widgetHasPluggedIn	= options.widgetHasPluggedIn || false,
			widgetHasModel		= options.widgetHasModel || false;

		var text = "";
		var operationText;
		var hasTitle = false;
		if (this.enablePrintPeriod) {
			switch (datePeriod) {
				case 0:
					text = "Last 3 Months";
					break;
				case 1:
					text = "Last Month";
					break;
				case 2:
					text = "Last 2 Weeks";
					break;
				case 3:
					text = "Last 7 Days";
					break;
				case 6:
					text = "Yesterday";
					break;
			}
			hasTitle = true;
		}
		if (widgetHasOperation && this.enablePrintOperation) {
			switch (operationIndex) {
				case 0:
					text += ' Fast';
					break;
				case 1:
					text += ' Conventional';
					break;
				case 2:
					text += ' Operational';
					break;
				case 3:
					text += ' Li-Ion';
					break;
			}
			hasTitle = true;
		}

		if(widgetHasModel && this.enablePrintModel) {
			text += modelType;
			hasTitle = true;
		}

		if (widgetHasPluggedIn && this.enablePrintPluggedIn) {
			switch (pluggedInIndex) {
				case 0:
					text += ' Plugged In';
					break;
				case 1:
					text += ' Not Plugged In';
					break;
			}
			hasTitle = true;
		}
		switch (widgetName) {
			case "batt_battery_replacement_list":
			case "batt_ramaining_warranty_list" :
				switch (id) {
					case 0:
						text = "Less than a year";
					break;
					case 1:
						text = "1 year";
					break;
					case 2:
						text = "2 years";
					break;
					case 3:
						text = "3 years";
					break;
					case 4:
						text = "More than 4 years";
					break;
				}
			break;
			case "batt_site_battery_aging_list":
			case "percentage_20_steps":
				switch (id) {
					case 0:
						text = "0%";
					break;
					case 1:
						text = "20%";
					break;
					case 2:
						text = "40%";
					break;
					case 3:
						text = "60%";
					break;
					case 4:
						text = "80%";
					break;
					case 5:
						text = "100%";
					break;
				}
			break;
			case "batt_fleet_utilization_list":
			case "batt_fleet_utilization_inuse_list":
			case "charger_assets_utilization_list":
			case "plc_activity_list":
			case "plc_summary_list":
			case "batt_eq_summary_list":
				if (hasTitle) {
					text += ": ";
				}
				text += (id * 10) + "%";
				break;
			case "batt_ebu_usage_summary_list":
			case "batt_events_ebu_usage_summary_list":
				if (hasTitle) {
					text += ": ";
				}
				switch (id) {
					case 0:
						text += "<0.5 EBU";
					break;
					case 1:
						text += "0.75 EBU";
					break;
					case 2:
						text += "1 EBU";
					break;
					case 3:
						text += "1.25 EBU";
					break;
					case 4:
						text += "1.5 EBU";
					break;
					case 5:
						text += "1.75 EBU";
					break;
					case 6:
						text += "2 EBU";
					break;
				}
			break;
			case "missed_connection_list":
				text += " Average Idle Times: ";
				switch (id) {
					case 0:
						text += "Sunday";
					break;
					case 1:
						text += "Monday";
					break;
					case 2:
						text += "Tuesday";
					break;
					case 3:
						text += "Wednesday";
					break;
					case 4:
						text += "Thursday";
					break;
					case 5:
						text += "Friday";
					break;
					case 6:
						text += "Saturday";
					break;
				}
				switch (secondIndex) {
					case 0:
						text += " (30-60 min)";
					break;
					case 1:
						text += " (60-120 min)";
					break;
					case 2:
						text += " (120-240 min)";
					break;
					case 3:
						text += " (> 240 min)";
					break;
				}
			break;
			case "missed_connection_plugged_in_list":
				if (secondIndex > 0) {
					text += " Average Missed Connection Times: ";
				} else {
					text += " Minimum Missed Connection Times: ";
				}
				switch (id) {
					case 0:
						text += "Sun";
					break;
					case 1:
						text += "Mon";
					break;
					case 2:
						text += "Tue";
					break;
					case 3:
						text += "Wed";
					break;
					case 4:
						text += "Thu";
					break;
					case 5:
						text += "Fri";
					break;
					case 6:
						text += "Sat";
					break;
				}
			break;
			case "batt_alert_summary_list":
				if (hasTitle) {
					text += ": ";
				}
				switch (id) {
					case 0:
						text += "Missed EQ";
					break;
					case 1:
						text += "High Temperature";
					break;
					case 2:
						text += "Over Charge";
					break;
					case 3:
						text += "Under Charge";
					break;
					case 4:
						text += "Potential Weak Cells";
					break;
					case 5:
						text += "Over Usage";
					break;
					case 6:
						text += "Missed Finish";
					break;
					case 7:
						text += "Deep Discharge";
					break;
					case 8:
						text += "Potentially Sulfated Battery Alert";
					break;
					case 9:
						text += "Water Level Alert";
					break;
					case 10:
						text += "Cold Temperature Alert";
					break;
				}
			break;
			case "batt_alert_missed_eq_list":
			case "batt_alert_missed_eq_weekly_list":
			case "sulfated_battery_alerts_list":
			case "batt_alert_missed_fi_list":
			case "missed_plugin_charge_cycles_list":
			case "idle_times_alert_list":
			case "batt_alert_high_temp_list":
			case "batt_alert_cold_temp_list":
			case "batt_alert_deep_discharge_list":
			case "batt_alert_deep_use_list":
				if (hasTitle) {
					text += ": ";
				}
				if (id > 0) {
					text += "1 Alert or More";
				} else {
					text += 'No Alerts';
				}
			break;
			case "batt_alert_over_under_charge_list":
				if (hasTitle) {
					text += ": ";
				}
				switch (id) {
					case 0:
						text += "Under Charge";
					break;
					case 1:
						text += "Over Charge";
					break;
					case 2:
						text += "Ok";
					break;
				}
			break;
			case "batt_alert_missed_watering_list":
				if (hasTitle) {
					text += ": ";
				}
				if (id > 0) {
					text += "water level Low";
				} else {
					text += "water level OK";
				}
			break;
			case "batt_alert_over_usage_list":
				if (hasTitle) {
					text += ": ";
				}
				if (id > 0) {
					text += "EBU Over Usage Alerts";
				} else {
					text += "No EBU Over Usage Alert";
				}
			break;
			case "missed_daily_connection_list":
				if (hasTitle) {
					text += ": ";
				}
				switch (id) {
					case 0:
						text += "No Daily Idle Times";
					break;
					case 1:
						text += "At Least 30 mins Daily Idle Times";
					break;
					case 2:
						text += "At Least 1:00 hour Daily Idle Times";
					break;
					case 3:
						text += "At Least 1:30 hours Daily Idle Times";
					break;
					case 4:
						text += "At Least 2:00 hours Daily Idle Times";
					break;
					case 5:
						text += "> 2:00 hours Daily Idle Times";
					break;
				}
			break;
			case "missed_connection_plugged_in_alert_list":
				if (hasTitle) {
					text += ": ";
				}
				switch (id) {
					case 0:
						text += "No Daily Missed Connection Times";
					break;
					case 1:
						text += "At Least 30 mins Daily Missed Connection Times";
					break;
					case 2:
						text += "At Least 1:00 hour Daily Missed Connection Times";
					break;
					case 3:
						text += "At Least 1:30 hours Daily Missed Connection Times";
					break;
					case 4:
						text += "At Least 2:00 hours Daily Missed Connection Times";
					break;
					case 5:
						text += "> 2:00 hours Daily Missed Connection Times";
					break;
				}
			break;
		}
		return text;
	}
	mapWidgetNameForPrint(widgetName, inuse_events, charge_events) {
		var mappedObject = {
			battery_utilization:0,
			batt_fleet_utilization_list:0,
			batt_eq_summary_list:1,
			batt_ebu_usage_summary_list:2,
			batt_alert_summary_list:3,
			batt_eff_score:5,
			battery_efficiency:5,
			batt_alert_high_temp_list:6,
			batt_alert_missed_eq_list:7,
			batt_alert_missed_eq_weekly_list:20,
			batt_alert_over_under_charge_list:8,
			batt_alert_deep_discharge_list:18,
			batt_alert_deep_use_list:9,
			batt_alert_over_usage_list:10,
			batt_alert_missed_watering_list:11,
			missed_daily_connection_list:12,
			missed_connection_list:13,
			batt_alert_missed_fi_list:15,
			missed_connection_plugged_in_alert_list:16,
			missed_connection_plugged_in_list:17,
			sulfated_battery_alerts_list:19,
			missed_plugin_charge_cycles_list: 21,
			idle_times_alert_list: 22,
			batt_alert_cold_temp_list:23,
			batt_battery_replacement_list:[4,0],
			batt_ramaining_warranty_list:[4,1],
			batt_site_battery_aging_list:[4,2]
		};

		if(inuse_events) {
			mappedObject.batt_alert_over_usage_list = 8;
			if(charge_events) {
				mappedObject.batt_alert_over_under_charge_list = 27;
			} else {
				mappedObject.batt_alert_over_under_charge_list = 25;
			}
		} else {
			if(charge_events) {
				mappedObject.batt_alert_over_under_charge_list = 26;
			} else {
				mappedObject.batt_alert_over_under_charge_list = 24;
			}
		}

		return mappedObject[widgetName];
	}
	getValueGroup(value, shift, groupSize, maxIndex, groupShift) {
		var group = Math.floor(((value + shift) / groupSize) + groupShift);
		if (group < 0) {
			group = 0;
		} else if (group >= maxIndex) {
			group = maxIndex;
		}
		return group;
	}
	checkPerformanceWidgetExist(data, widgetName, inactiveWidgets) {
		if (!data || Object.keys(data).length === 0) {
			return false;
		}

		if(!data.isChargers && (!data[widgetName] || Object.keys(data[widgetName]).length === 0)) {
			return false;
		}

		return inactiveWidgets.indexOf(widgetName) === -1;
	}
	mapDateRangeToCountOfDays(dateRange) {
		var validPeriods = ['90','30','14','7','Age','W','Y'];
		var index = validPeriods.indexOf(dateRange);

		let countOfDays:any = 0;
		switch(index) {
			case 0:
			case 1:
			case 2:
			case 3:
				countOfDays = +validPeriods[index];
			break;
			case 6:
				countOfDays = 365;
			break;
			default:
				countOfDays = 7;
			break;
		}
		return countOfDays;
	}
	generateSiteReportData(dataObject, widgetName, options) {
		var dateRange			= options.dateRange,
			operationType		= options.operationType,
			battviewsData		= dataObject.battviewsData,
			siteAlertsSettings	= dataObject.siteAlertsSettings,
			userAlertsSettings	= dataObject.userAlertsSettings,
			allData				= dataObject.siteReportData,
			tags				= options.tags || [],
			siteBattviewsTags	= options.siteTags || [];

		var i,j, battviewId, batteryFilteredOut, battviewdata, device;
		var periodIndex = this.mapReportDateRangeToIndexId(dateRange);

		var operationIndex = null;
		var ebuLimit = [];
		switch (operationType) {
			case "F":
				ebuLimit = [siteAlertsSettings.fast_ebu_limit];
				operationIndex = 0;
			break;
			case "C":
				ebuLimit = [siteAlertsSettings.conventional_ebu_limit];
				operationIndex = 1;
			break;
			case "O":
				ebuLimit = [siteAlertsSettings.opportunity_ebu_limit];
				operationIndex = 2;
			break;
			default:
				ebuLimit = [siteAlertsSettings.fast_ebu_limit, siteAlertsSettings.conventional_ebu_limit, siteAlertsSettings.opportunity_ebu_limit];
			break;
		}

		var returnData: any = {};

		var filteredInDevicesTemp = [];
		var filteredInDevices = [];
		for(var tagIndex in tags) {
			var tagId = tags[tagIndex];
			filteredInDevicesTemp = filteredInDevicesTemp.concat(siteBattviewsTags[tagId]);
		}

		filteredInDevicesTemp.forEach((id) => {
			filteredInDevices.push(+id);
		});

		let charge_events	= !userAlertsSettings || userAlertsSettings.bv_charge_events_only_for_charge_ahr;
		let inuse_events	= !userAlertsSettings || userAlertsSettings.bv_inuse_events_only_for_charge_ahr;

		switch (widgetName) {
			case 'site_batteries':
				returnData = [];
				var ageObject = this.commonUtil.arrayToAssociativeArray(allData.age, 'bfdid');
				for(i in allData[periodIndex]) {
					device = allData[periodIndex][i];
					battviewId = device.bfdid;
					battviewdata = battviewsData[+(battviewId)];
					if(!battviewdata)
						continue;

					batteryFilteredOut = false;
					if (tags.length > 0 && filteredInDevices.indexOf(+(battviewId)) < 0) {
						batteryFilteredOut = true;
					}

					if (!batteryFilteredOut && operationIndex !== null && !this.checkIsValidOperation(battviewdata, operationIndex)) {
						batteryFilteredOut = true;
					}

					if (!batteryFilteredOut) {
						// check if all values are 0's then temp should be N/A
						let totalChargeAhrs = device.totalChargeAhrs;
						let totalInuseAhrs = device.totalInuseAhrs;
						let ebu = device.ebu[0];

						if(charge_events)
							totalChargeAhrs = device.totalChargeEventsAhrs;

						if(inuse_events) {
							totalInuseAhrs = device.totalInuseEventsAhrs;
							ebu = device.ebu[1];
						}

						let tempValues = ebu[0] + ebu[1] + device.minDailyIdleTime + totalChargeAhrs + totalInuseAhrs;
						let maxTemp = device.maxTemp
						if(tempValues <= 0) {
							maxTemp = 'N/A';
						}
						returnData.push({
							batteryid:battviewdata.batteryid,
							avg_ebu: ebu[0],
							max_ebu: ebu[1],
							maxTemp: maxTemp,
							min_idle: device.minDailyIdleTime,
							total_charge_ahrs: totalChargeAhrs,
							total_inuse_ahrs: totalInuseAhrs,
							remaining_ahrs: ageObject[battviewId].remainingLifeAhrs,
							remaining_yrs: ageObject[battviewId].remainingLifeYrs,
							battviewid: battviewId,
							truck_id: battviewdata.truckid,
							battery_sn: battviewdata.batterysn
						});
					}
				}
			break;
			case 'site_ebus':
				returnData = {};
				returnData.maxValueOnChart = 0;
				returnData.data = [];
				returnData.list = [];
				returnData.list.push(['Battery ID']);
				let countOfDataElements = 0;
				if(options.max_ebu) {
					returnData.list[0].push('Max EBUs');
					countOfDataElements++;
				}
				if(options.avg_ebu) {
					returnData.list[0].push('Avg EBUs');
					countOfDataElements++;
				}
				var series = {};
				var colors = ['green', 'yellow', 'red'];
				if (options.showReference) {
					var operationType;
					if(operationType) {
						operationType = this.mapOperation(operationType);
						returnData.list[0].push('Show '+operationType+' Reference');
						series[countOfDataElements] = {'type': 'line', 'color': 'green'};
					} else {
						for(let i=0;i<3;i++) {
							operationType = this.mapOperation(i);
							returnData.list[0].push(operationType+' Reference');
							series[countOfDataElements+i] = {'type': 'line', 'color': colors[i]};
						}
					}
				}

				returnData.series = series;

				returnData.maxValueOnChart = Math.max.apply(null, ebuLimit);

				for(i in allData[periodIndex]) {
					device = allData[periodIndex][i];
					battviewId = device.bfdid;
					battviewdata = battviewsData[+(battviewId)];
					if(!battviewdata)
						continue;

					batteryFilteredOut = false;
					if (tags.length > 0 && filteredInDevices.indexOf(+(battviewId)) < 0) {
						batteryFilteredOut = true;
					}

					if (!batteryFilteredOut && operationIndex !== null && !this.checkIsValidOperation(battviewdata, operationIndex)) {
						batteryFilteredOut = true;
					}

					if (!batteryFilteredOut) {
						var batteryId = battviewdata.batteryid;
						var row: any[] = [''+batteryId];
						var data: any = {
							battviewId: battviewdata.id,
							battery_id: battviewdata.batteryid
						};
						var hasData = false;

						let ebu = device.ebu[0];
						if(inuse_events) 
							ebu = device.ebu[1]

						if(options.max_ebu) {
							row.push(+(ebu[1]));
							data.max_ebu = +(ebu[1]);
							if(+(ebu[1]) > 0) hasData = true;
						}
						if(options.avg_ebu) {
							row.push(+(ebu[0]));
							data.avg_ebu = +(ebu[0]);
							if(+(ebu[0]) > 0) hasData = true;
						}
						if (options.showReference) {
							for (j in ebuLimit) {
								row.push(ebuLimit[j]);
							}
						}
						if (hasData) {
							returnData.list.push(row);
							returnData.data.push([data]);
						}
					}
				}
				if(returnData.list.length <= 1) {
					returnData.list = [];
				}
			break;
			case 'site_temperature':
				returnData = {};
				returnData.maxValueOnChart = 0;
				returnData.list = [];
				returnData.data = [];

				for(i in allData[periodIndex]) {
					device = allData[periodIndex][i];
					battviewId = device.bfdid;
					battviewdata = battviewsData[+(battviewId)];
					if(!battviewdata)
						continue;

					batteryFilteredOut = false;
					if (tags.length > 0 && filteredInDevices.indexOf(+(battviewId)) < 0) {
						batteryFilteredOut = true;
					}

					if (!batteryFilteredOut && operationIndex !== null && !this.checkIsValidOperation(battviewdata, operationIndex)) {
						batteryFilteredOut = true;
					}

					if(+device.maxTemp != device.maxTemp) {
						batteryFilteredOut = true;
					}

					if (!batteryFilteredOut) {
						if (+(device.maxTemp) > returnData.maxValueOnChart)
							returnData.maxValueOnChart = +(device.maxTemp);

						returnData.data.push([{
							battviewId: battviewdata.id,
							battery_id: battviewdata.batteryid,
							value: +(device.maxTemp)
						}]);
						returnData.list.push([
							'' + battviewdata.batteryid,
							+(device.maxTemp)
						]);
					}
				}

				if(returnData.list.length > 0)
					returnData.list.unshift(['Battery ID', 'Max Temperature']);
			break;
			case 'site_trucks':
				returnData = [];
				returnData.push({
					truckviewid: 100,
					truckid: 100,
					truck_sn: "TRK251547KW24",
					truck_type: "Truck1",
					avg_ebu: 1.9,
					max_ebu: 2.5,
					min_idle: 5,
					max_idle: 25,
					total_inuse_ahrs: 65,
				});
				returnData.push({
					truckviewid: 200,
					truckid: 200,
					truck_sn: "TRK151547KW24",
					truck_type: "Truck2",
					avg_ebu: 1.4,
					max_ebu: 2.2,
					min_idle: 5,
					max_idle: 25,
					total_inuse_ahrs: 65,
				});
				returnData.push({
					truckviewid: 300,
					truckid: 300,
					truck_sn: "TRK857547KW24",
					truck_type: "Truck2",
					avg_ebu: 1.5,
					max_ebu: 2.4,
					min_idle: 5,
					max_idle: 25,
					total_inuse_ahrs: 65,
				});
				returnData.push({
					truckviewid: 400,
					truckid: 400,
					truck_sn: "TRK951467KW24",
					truck_type: "Truck1",
					avg_ebu: 1.8,
					max_ebu: 2.7,
					min_idle: 5,
					max_idle: 25,
					total_inuse_ahrs: 65,
				});
			break;
			case 'site_truck_ebus':
				returnData = {};
				returnData.maxValueOnChart = 0;
				returnData.data = [];
				returnData.list = [];
				returnData.list.push(['Truck ID']);
				let countOfDataElement = 0;
				if(options.max_ebu) {
					returnData.list[0].push('Max EBUs');
					countOfDataElement++;
				}
				if(options.avg_ebu) {
					returnData.list[0].push('Avg EBUs');
					countOfDataElement++;
				}
				var series = {};
				var colors = ['green', 'yellow', 'red'];
				if (options.showReference) {
					var operationType;
					if(operationType) {
						operationType = this.mapOperation(operationType);
						returnData.list[0].push('Show '+operationType+' Reference');
						series[countOfDataElement] = {'type': 'line', 'color': 'green'};
					} else {
						for(let i=0;i<3;i++) {
							operationType = this.mapOperation(i);
							returnData.list[0].push(operationType+' Reference');
							series[countOfDataElement+i] = {'type': 'line', 'color': colors[i]};
						}
					}
				}

				returnData.series = series;

				returnData.maxValueOnChart = Math.max.apply(null, ebuLimit);

				returnData.list.push(['100', 2.5, 1.9]);
				returnData.list.push(['200', 2.2, 1.4]);
				returnData.list.push(['300', 2.4, 1.5]);
				returnData.list.push(['400', 2.7, 1.8]);

				returnData.data = [
					[{truckviewId: '100', truck_id: 'TRK251547KW24', max_ebu: 2.5, avg_ebu: 1.9}],
					[{truckviewId: '200', truck_id: 'TRK151547KW24', max_ebu: 2.2, avg_ebu: 1.4}],
					[{truckviewId: '300', truck_id: 'TRK857547KW24', max_ebu: 2.4, avg_ebu: 1.5}],
					[{truckviewId: '400', truck_id: 'TRK951467KW24', max_ebu: 2.7, avg_ebu: 1.8}],
				];

				if(!options.max_ebu && !options.avg_ebu) {
					let i;
					for(i = 1 ; i < returnData.list.length; i++)
					{
						returnData.list[i].splice(1);
						delete returnData.data[i-1][0].max_ebu;
						delete returnData.data[i-1][0].avg_ebu;
					}
				}
				if(options.max_ebu && !options.avg_ebu) {
					let i;
					for(i = 1 ; i < returnData.list.length; i++)
					{
						returnData.list[i].splice(2);
						delete returnData.data[i-1][0].avg_ebu;
					}
				}
				if(!options.max_ebu && options.avg_ebu) {
					let i;
					for(i = 1 ; i < returnData.list.length; i++)
					{
						returnData.list[i].splice(1,1);
						delete returnData.data[i-1][0].max_ebu;
					}
				}
				if(returnData.list.length <= 1) {
					returnData.list = [];
				}
			break;
		}
		return returnData;
	}
	mapReportDateRangeToIndexId(dateRange) {
		var validPeriods = ['installationDate','365','90','30','14','W'];
		var index = validPeriods.indexOf(dateRange);
		return (index >= 0 ? index : 5);
	}

	getSiteWidgetPrintAlertsList(data, customerId, siteId, options) {
		var widgets = [
			'batt_alert_high_temp_list',
			'batt_alert_cold_temp_list',
			'batt_alert_missed_eq_list',
			'batt_alert_missed_eq_weekly_list',
			'batt_alert_deep_use_list',
			'batt_alert_over_under_charge_list',
			'batt_alert_missed_watering_list',
			'batt_alert_over_usage_list',
			'missed_daily_connection_list',
			'batt_alert_missed_fi_list',
			'missed_plugin_charge_cycles_list',
			'idle_times_alert_list',
			'missed_connection_plugged_in_alert_list',
			'batt_alert_deep_discharge_list',
			'sulfated_battery_alerts_list',
			'idle_times_alert_list'
		];
		var returnData = {};
		options.siteAlertsSettings = data.siteAlertsSettings;
		widgets.forEach((widgetKey) => {
			var widgetData: any = this.generateWidgetPrintAlertsData(data.data, widgetKey, options, data.inactiveWidgets);
			if (widgetData && widgetData !== false) {
				var widgetName = this.getWidgetNameByWidgetKey(widgetKey);
				returnData[widgetName] = widgetData;
			}
		});
		return returnData;
	}

	generateWidgetPrintAlertsData(allData, widgetName, options, inactiveWidgets) {
		var datePeriod		= options.datePeriod || 0,
			operationType	= options.operationType || 0,
			siteAlertsSettings	= options.siteAlertsSettings || {},
			userAlertsSettings	= options.userAlertsSettings || {},
			charge_events	= options.charge_events,
			inuse_events	= options.inuse_events;

		if(charge_events === undefined)	charge_events = true;
		if(inuse_events === undefined)	inuse_events = true;

		var data,i,j,item,index,thisIndexList,operationIndex, tempData, operation;

		var returnData = [];

		var widgetDisabled = false;
		for (let i in inactiveWidgets) {
			var tempWidget = inactiveWidgets[i];
			if (tempWidget.widget_code_name+'_list' == widgetName) {
				widgetDisabled = !tempWidget.is_active;
				break;
			}
		}

		if (widgetDisabled) {
			return false;
		}

		var widgetIndex = this.mapWidgetNameForPrint(widgetName, inuse_events, charge_events);

		var period = this.mapDateRangeToIndexId(datePeriod);

		var operationIndexes = [0,1,2];

		data = allData[period][widgetIndex];

		switch (widgetName) {
			
			case "batt_alert_missed_fi_list":
				var missedArrList = [],
					notMissedArrList = [];

				i = 1; // Do not show no alerts
				thisIndexList = {};
				thisIndexList.list = [];
				thisIndexList.columns = [{title: "Battery ID", property: "battery_id"}];
				if (userAlertsSettings.view_truck_id) {
					thisIndexList.columns.push({title: "Truck ID", property: "truck_id"});
				}
				thisIndexList.columns.push({title: "Expected FI Cycle", property: "expected_finish"});
				thisIndexList.columns.push({title: "Actual FI Cycles", property: "actual_finish"});
				for (index in data[2][1][i]) {
					item = data[2][1][i][index];
					thisIndexList.list.push({battery_id:item[0],expected_finish:item[1],actual_finish:item[2], truck_id:item[3]});
				}

				if (thisIndexList.list.length > 0) {
					returnData.push(thisIndexList);
				}
			break;
			case "missed_plugin_charge_cycles_list":
				var missedArrList = [],
					notMissedArrList = [];

				i = 1; // Do not show no alerts
				thisIndexList = {};
				thisIndexList.list = [];
				thisIndexList.columns = [{title: "Battery ID", property: "battery_id"}];
				if (userAlertsSettings.view_truck_id) {
					thisIndexList.columns.push({title: "Truck ID", property: "truck_id"});
				}
				thisIndexList.columns.push({title: "Expected Charge Cycles", property: "expected_cycles"});
				thisIndexList.columns.push({title: "Actual Charge Cycles", property: "actual_cycles"});
				for (index in data[1][i]) {
					item = data[1][i][index];
					thisIndexList.list.push({battery_id: item[0], actual_cycles: item[1], expected_cycles: item[2], truck_id: item[3]});
				}

				if (thisIndexList.list.length > 0) {
					returnData.push(thisIndexList);
				}
			break;
			case "idle_times_alert_list":
				var missedArrList = [],
					notMissedArrList = [];

				i = 1; // Do not show no alerts
				thisIndexList = {};
				thisIndexList.list = [];
				thisIndexList.columns = [{title: "Battery ID", property: "battery_id"}];
				if (userAlertsSettings.view_truck_id) {
					thisIndexList.columns.push({title: "Truck ID", property: "truck_id"});
				}
				thisIndexList.columns.push({title: "Expected Idle After Event Time (mins)", property: "expected_idle_time"});
				thisIndexList.columns.push({title: "Actual Idle After Event Time (mins)", property: "actual_idle_time"});
				for (index in data[1][i]) {
					item = data[1][i][index];
					thisIndexList.list.push({battery_id: item[0], actual_idle_time: item[1], expected_idle_time: item[2], truck_id: item[3]});
				}

				if (thisIndexList.list.length > 0) {
					returnData.push(thisIndexList);
				}
			break;
			case "batt_alert_missed_eq_list":
				var missedArrList = [],
					notMissedArrList = [];

				var valueColumnName = "Number of missed Eqs";
				if (widgetName == 'batt_alert_missed_fi_list') {
					valueColumnName = "No. of Missed Finish";
				}
				i = 1; // Do not show no alerts
				thisIndexList = {};
				// thisIndexList.title = mapWidgetIndexByText(widgetName, {index:i, datePeriod: period});
				thisIndexList.list = [];
				thisIndexList.columns = [{title: "Battery ID", property: "battery_id"}];
				if (userAlertsSettings.view_truck_id) {
					thisIndexList.columns.push({title: "Truck ID", property: "truck_id"});
				}
				thisIndexList.columns.push({title: valueColumnName, property: "value"});
				for (j = 0; j <= 1; j++) {
					for (index in data[j][1][i]) {
						item = data[j][1][i][index];

						if (missedArrList.indexOf(item[0]) === -1) {
							missedArrList.push(item[0]);
							thisIndexList.list.push({battery_id:item[0],value:item[1],truck_id:item[2], bfdId:item[3]});
						} else {
							var neededIndex = -1;
							thisIndexList.list.forEach((searchItem, searchIndex) => {
								if (searchItem.bfdId === item[3]) {
									neededIndex = searchIndex;
								}
							});
							if (neededIndex > -1) {
								thisIndexList.list[neededIndex].value += item[1];
							}
						}
					}
				}

				if (thisIndexList.list.length > 0) {
					returnData.push(thisIndexList);
				}
			break;
			case "batt_alert_high_temp_list":
			case "batt_alert_cold_temp_list":
			case "batt_alert_deep_discharge_list":
			case "batt_alert_deep_use_list":
			case "batt_alert_missed_watering_list":
			case "sulfated_battery_alerts_list":
			case "batt_alert_missed_eq_weekly_list":
				i = 1; // Do not show no alerts
				var hasMoreColumn = false;
				var otherColumnName;
				switch (widgetName) {
					case 'batt_alert_high_temp_list':
						valueColumnName = 'Days of High Temp';
						hasMoreColumn = true;
						otherColumnName = 'Max Temp Recorded';
					break;
					case 'batt_alert_cold_temp_list':
						valueColumnName = 'Days of Cold Temp';
						hasMoreColumn = true;
						otherColumnName = 'Min Temp Recorded';
					break;
					case 'batt_alert_deep_discharge_list':
						valueColumnName = 'Min OCV (VPC)';
						hasMoreColumn = true;
						otherColumnName = 'Days of Deep Discharge'; // Q: should it be "Days of Low Voltage"?
					break;
					case 'batt_alert_deep_use_list':
						valueColumnName = 'Days of Deep Discharge';
						hasMoreColumn = true;
						otherColumnName = 'Days of Low Voltage';
					break;
					case 'batt_alert_missed_watering_list':
						valueColumnName = 'Days of Low Water';
					break;
					case 'sulfated_battery_alerts_list':
						valueColumnName = 'Number of Potentially Sulfated Battery Alerts';
					break;
					case 'batt_alert_missed_eq_weekly_list':
						valueColumnName = 'Number of Weekly Missed EQs Alerts';
					break;
				}
				thisIndexList = {};
				thisIndexList.columns = [{title: "Battery ID", property: "battery_id"}];
				if (userAlertsSettings.view_truck_id) {
					thisIndexList.columns.push({title: "Truck ID", property: "truck_id"});
				}
				if (hasMoreColumn) {
					thisIndexList.columns.push({title: otherColumnName, property: "otherValue"});
				}
				thisIndexList.columns.push({title: valueColumnName, property: "value"});

				// thisIndexList.title = mapWidgetIndexByText(widgetName, {index:i, datePeriod: period});
				thisIndexList.list = [];
				for (index in data[1][i]) {
					item = data[1][i][index];
					thisIndexList.list.push({battery_id:item[0], value:item[1], truck_id: item[2], otherValue:item[4]});
				}
				if (thisIndexList.list.length > 0) {
					returnData.push(thisIndexList);
				}
			break;
			case "batt_alert_over_under_charge_list":
				for (i = 0;i < 2; i++) {
					thisIndexList = {};
					var valueColumnName = '% under charge';
					if (i == 1)
						valueColumnName = '% over charge';
					thisIndexList.columns = [{title: "Battery ID", property: "battery_id"}];
					if (userAlertsSettings.view_truck_id) {
						thisIndexList.columns.push({title: "Truck ID", property: "truck_id"});
					}
					thisIndexList.columns.push({title: valueColumnName, property: "value"});
					thisIndexList.title = this.mapWidgetIndexByText(widgetName, {index:i, datePeriod: period});
					thisIndexList.list = [];
					for (index in data[1][i]) {
						item = data[1][i][index];
						thisIndexList.list.push({
							battery_id:item[0],
							value:(item[1] < 1) ? "No Usage" : Math.round(item[1]),
							truck_id: item[2]
						});
					}
					if (thisIndexList.list.length > 0) {
						returnData.push(thisIndexList);
					}
				}
			break;
			case "batt_alert_over_usage_list":
				var enablePrintOperationOldValue = this.enablePrintOperation;
				this.enablePrintOperation = true;
				i = 1; // Do not show no alerts
				for (operationIndex in operationIndexes) {
					operation = operationIndexes[operationIndex];
					thisIndexList = {};
					thisIndexList.columns = [{title: "Battery ID", property: "battery_id"}];
					if (userAlertsSettings.view_truck_id) {
						thisIndexList.columns.push({title: "Truck ID", property: "truck_id"});
					}
					thisIndexList.columns.push({title: "Average EBUs", property: "average"});
					thisIndexList.columns.push({title: "Max EBUs", property: "max"});
					thisIndexList.title = this.mapWidgetIndexByText(widgetName, {index:i, datePeriod: period, operationIndex: operation, widgetHasOperation: true});
					thisIndexList.list = [];
					for (index in data[operation][1][i]) {
						item = data[operation][1][i][index];
						thisIndexList.list.push({battery_id:item[0],value:item[1],truck_id:item[2],max:item[4],average:item[5]});
					}
					if (thisIndexList.list.length > 0) {
						returnData.push(thisIndexList);
					}
				}
				this.enablePrintOperation = enablePrintOperationOldValue;
			break;
			case "missed_daily_connection_list":
			case "missed_connection_plugged_in_alert_list":
				data = data[1];
				var valueColumnName = 'Total Daily Idle After Event Times';
				if (widgetName == 'missed_connection_plugged_in_alert_list')
					valueColumnName = 'Avg. Missed Daily (min)';

				for (i = 1;i < 6; i++) {
					thisIndexList = {};
					thisIndexList.columns = [{title: "Battery ID", property: "battery_id"}];
					if (userAlertsSettings.view_truck_id) {
						thisIndexList.columns.push({title: "Truck ID", property: "truck_id"});
					}
					thisIndexList.columns.push({title: valueColumnName, property: "value"});
					thisIndexList.title = this.mapWidgetIndexByText(widgetName, {index:i, datePeriod: period});
					thisIndexList.list = [];
					for (index in data[i]) {
						item = data[i][index];
						var TimeObj: any = this.commonUtil.convertHourToSeconds(item[1]*60, 0, true);
						var value = '';
						if (TimeObj.day > 0) {
							value += this.commonUtil.singularOrPlural(TimeObj.day, 'day');
						}
						value += this.commonUtil.formatTime(TimeObj.hour, TimeObj.min);
						thisIndexList.list.push({battery_id:item[0],value:value, value2:item[1], truck_id:item[2]});
					}
					if (thisIndexList.list.length > 0) {
						returnData.push(thisIndexList);
					}
				}
			break;
		}
		return returnData;
	}
	getWidgetNameByWidgetKey(widgetKey) {
		var widgetName = '';
		switch (widgetKey) {
			case 'batt_alert_missed_eq_list':
				widgetName = 'Missed EQs';
			break;
			case 'batt_alert_missed_eq_weekly_list':
				widgetName = 'Weekly Missed EQs';
			break;
			case 'batt_alert_missed_fi_list':
				widgetName = 'Missed Finish';
			break;
			case 'missed_plugin_charge_cycles_list':
				widgetName = 'Missed Plugin (#of Charge Cycles)';
			break;
			case 'idle_times_alert_list':
				widgetName = 'Idle Times After Event Alert';
			break;
			case 'batt_alert_high_temp_list':
				widgetName = 'High Temperature Alerts';
			break;
			case 'batt_alert_cold_temp_list':
				widgetName = 'Cold Temperature Alerts';
			break;
			case 'batt_alert_deep_use_list':
				widgetName = 'Potential Weak Cells Alerts';
			break;
			case 'batt_alert_over_under_charge_list':
				widgetName = 'Over / Under Charge';
			break;
			case 'batt_alert_missed_watering_list':
				widgetName = 'Water Level Alerts';
			break;
			case 'batt_alert_over_usage_list':
				widgetName = 'EBU Over Usage Alert';
			break;
			case 'missed_daily_connection_list':
				widgetName = 'Total Daily Idle After Event Times';
			break;
			case 'missed_connection_plugged_in_alert_list':
				widgetName = 'Avg. Missed Daily (min)';
			break;
			case 'batt_alert_deep_discharge_list':
				widgetName = 'Deep Discharge Alerts';
			break;
			case 'sulfated_battery_alerts_list':
				widgetName = 'Potentially Sulfated Battery Alert';
			break;
		}
		return widgetName;
	}

	mapOperation(operation) {
		var operationText = '';
		switch (operation) {
			case 'F':
			case 0:
				operationText = 'Fast';
			break;
			case 'C':
			case 1:
				operationText = 'Conventional';
			break;
			case 'O':
			case 2:
				operationText = 'Opportunity';
			break;
		}
		return operationText;
	}
	
	generateChargersPerformanceWidgetData(dataObject, widgetName, options) {
		var periodIndex			= options.dateRange || 'W',
			operationType		= options.operationType,
			model				= options.model || null,
			normalizeKW			= options.normalizeKW || false,
			inactiveWidgets		= dataObject.inactiveWidgets || [],
			chargersData		= options.chargersData,
			allData				= dataObject,
			isList				= options.isList,
			tags				= options.tags || [],
			siteChargersTags	= options.siteTags || [],
			dateRangeStart		= options.dateRangeStart || 1,
			dateRangeEnd		= options.dateRangeEnd || 1,
			siteAlertsSettings	= dataObject.siteAlertsSettings || dataObject.siteSettings.siteSettings || {};

		if (!allData || Object.keys(allData).length === 0) {
			return false;
		}

		allData.isChargers = true;

		if (!this.checkPerformanceWidgetExist(allData, widgetName, inactiveWidgets)) {
			return false;
		}

		var listData = [];

		var operationIndex = null;
		switch (operationType) {
			case "F":
				operationIndex = 0;
			break;
			case "C":
				operationIndex = 1;
			break;
			case "O":
				operationIndex = 2;
			break;
		}

		var expectedCyclesKey = [
			'expected_fast_chargers_cycles',
			'expected_conventional_chargers_cycles',
			'expected_opportunity_chargers_cycles'
		];

		var returnData:any = {};

		var elemnetsCount	= 0,
			shift			= 0,
			groupSize		= 1;

		var filteredInDevices = [];
		for(var tagIndex in tags) {
			var tagId = tags[tagIndex];
			filteredInDevices = filteredInDevices.concat(siteChargersTags[tagId]);
		}

		filteredInDevices = filteredInDevices.map((item) => {
			return +item;
		});

		var widgetData = [];
		switch (widgetName) {
			case 'charger_assets_utilization':
				widgetData = allData.data.count_of_cycles[periodIndex];
				elemnetsCount	= 11;
				shift			= 5;
				groupSize		= 10;

				if(isList) {
					returnData = [];
					for (var i = 0; i < elemnetsCount; i++) {
						returnData.push({list: [], count: 0});
					}
				} else {
					var totalValues = [];
					var totalChargersCount = 0;
					for (var i = 0; i < elemnetsCount; i++) {
						totalValues.push(0);
					}
				}

				for(var dateText in widgetData) {
					var dayData = widgetData[dateText];

					var chargerId	= +dayData.chargerid;
					var value		= dayData.count;

					var chargerdata = chargersData[chargerId];

					if(!chargerdata)
						continue;

					chargerdata.model = chargerdata.model || '';
					chargerdata.model = chargerdata.model.trimRight();

					var modeltype = chargerdata.model.split('-')[0];

					if(['Q4', 'Q6', 'Q12'].indexOf(modeltype) == -1)
						modeltype = 'Q12';

					var chargerFilteredOut = false;
					if (tags.length > 0 && filteredInDevices.indexOf(chargerId) < 0) {
						chargerFilteredOut = true;
					}

					if (!chargerFilteredOut && operationIndex !== null && chargerdata.chargertype != operationIndex) {
						chargerFilteredOut = true;
					}

					if (!chargerFilteredOut && model !== null && modeltype != model) {
						chargerFilteredOut = true;
					}

					if (!chargerFilteredOut) {
						var expectedCycles = siteAlertsSettings[expectedCyclesKey[+chargerdata.chargertype]];
						
						if(periodIndex == 'Y') {

							value = (100 * value) / expectedCycles;
						} else {

							if(periodIndex == 'W')
								periodIndex = 7; 

							var daysCalc = (periodIndex / 7);
							value = (100 * value) / (siteAlertsSettings.site_weekly_working_days * daysCalc * expectedCycles);
						}
						
						var group = this.getValueGroup(value, shift, groupSize, (elemnetsCount -1), 0);
						
						if(isList) {
							returnData[group].list.push({
								'chargerid':		chargerId,
								'value':			value,
								'chargerusername':	chargerdata.chargerusername,
								'serialnumber':		chargerdata.serialnumber
							});
							returnData[group].count++;
						} else {
							totalValues[group]++;
							totalChargersCount++;
						}
					}
				}

				if(!isList) {
					for (var i = 0; i < elemnetsCount; i++) {
						listData.push({
							x: i*10,
							y: totalValues[i] / totalChargersCount * 100,
							list_id: i
						});
					}
					returnData.list = listData;
				}
			break;
			case 'plc_activity':
				let data = {};
				for(let i = 1; i<=12; i++) {
					if(i < dateRangeStart) continue;
					if(i > dateRangeEnd) break;

					for(let chargerData of allData.data.PLC_summary[i]) {
						if(!data[chargerData.chargerid]) {
							data[chargerData.chargerid] = {
								BVcycleCounts: 0,
								totalCyclesCount: 0,
								chargerid: chargerData.chargerid
							};
						}
						data[chargerData.chargerid].BVcycleCounts += chargerData.BVcycleCounts;
						data[chargerData.chargerid].totalCyclesCount += chargerData.totalCyclesCount;
					}
				}
				widgetData = widgetData.concat(Object.values(data));
				elemnetsCount	= 11;
				shift			= 5;
				groupSize		= 10;

				if(isList) {
					returnData = [];
					for (var i = 0; i < elemnetsCount; i++) {
						returnData.push({list: [], count: 0});
					}
				} else {
					var totalValues = [];
					var totalCyclesCount	= 0,
						totalBVcyclesCount	= 0;
					for (var i = 0; i < elemnetsCount; i++) {
						totalValues.push(0);
					}
				}

				for(let idx of widgetData) {
					if(idx.totalCyclesCount == 0)
						continue;

					var chargerId	= +idx.chargerid;
					var value: any	= Math.round((idx.BVcycleCounts / idx.totalCyclesCount) * 100);

					var chargerdata = chargersData[chargerId];

					if(!chargerdata)
						continue;

					chargerdata.model = chargerdata.model || '';
					chargerdata.model = chargerdata.model.trimRight();

					var modeltype = chargerdata.model.split('-')[0];

					if(['Q4', 'Q6', 'Q12'].indexOf(modeltype) == -1)
						modeltype = 'Q12';

					var chargerFilteredOut = false;
					if (tags.length > 0 && filteredInDevices.indexOf(chargerId) < 0) {
						chargerFilteredOut = true;
					}

					if (!chargerFilteredOut && operationIndex !== null && chargerdata.chargertype != operationIndex) {
						chargerFilteredOut = true;
					}

					if (!chargerFilteredOut && model !== null && modeltype != model) {
						chargerFilteredOut = true;
					}

					if (!chargerFilteredOut) {
						totalCyclesCount += idx.totalCyclesCount;
						totalBVcyclesCount += idx.BVcycleCounts;
						var group = this.getValueGroup(value, shift, groupSize, (elemnetsCount -1), 0);
						
						if(isList) {
							returnData[group].list.push({
								'chargerid':		chargerId,
								'value':			value,
								'chargerusername':	chargerdata.chargerusername,
								'serialnumber':		chargerdata.serialnumber
							});
							returnData[group].count++;
						} else {
							totalValues[group]++;
						}
					}
				}

				if(!isList) {
					for (var i = 0; i < elemnetsCount; i++) {
						listData.push({
							x: i*10,
							y: totalValues[i],
							list_id: i
						});
					}
					returnData.list = listData;
					returnData.summary = {
						label: this.translateService.instant('charger.total_plc_activity') + ' - ' + totalBVcyclesCount + ' - ' + totalCyclesCount,
						value: totalCyclesCount > 0 ? (totalBVcyclesCount / totalCyclesCount) * 100 : 0
					};
				}
			break;

			case "charger_peak_whr":
				widgetData = allData.data.max_WHR[periodIndex];
				
				returnData = [];
				if(!isList)
					returnData = {'list': []};

				let charger_peak_whr_idx = -1;
				for(var dateText in widgetData) {
					var elementData = widgetData[dateText];

					var chargerId	= +elementData.chargerid;
					var value: any	= (elementData.whr * 1.07).toFixed(2);

					var chargerdata = chargersData[chargerId];

					if(!chargerdata)
						continue;

					charger_peak_whr_idx++;

					if(normalizeKW)
						value = ((elementData.whr * 50) / chargerdata.numberofinstalledpms).toFixed(1);

					chargerdata.model = chargerdata.model || '';
					chargerdata.model = chargerdata.model.trimRight();

					var modeltype = chargerdata.model.split('-')[0];

					if(['Q4', 'Q6', 'Q12'].indexOf(modeltype) == -1)
						modeltype = 'Q12';

					var chargerFilteredOut = false;
					if (tags.length > 0 && filteredInDevices.indexOf(chargerId) < 0) {
						chargerFilteredOut = true;
					}

					if (!chargerFilteredOut && operationIndex !== null && chargerdata.chargertype != operationIndex) {
						chargerFilteredOut = true;
					}

					if (!chargerFilteredOut && model !== null && modeltype != model) {
						chargerFilteredOut = true;
					}

					if(!chargerFilteredOut) {
						returnData[charger_peak_whr_idx] = {'list': [], 'count': 1};
						if(isList) {
							returnData[charger_peak_whr_idx].list.push({
								'chargerid':		chargerId,
								'value':			value,
								'chargerusername':	chargerdata.chargerusername,
								'serialnumber':		chargerdata.serialnumber
							});
						} else {
							returnData.list.push({
								x: chargerId,
								value: value
							});
						}
					}
				}
			break;

			case "kwhrs_output":
			case "ahrs_returned":
			case "charger_plugins":
				let dataKey = this.widgetDataKeys[widgetName];
				widgetData = allData.data[dataKey][periodIndex];
				
				returnData = [];
				if(!isList)
					returnData = {'list': []};

				var summation = {};

				for(var dateText in widgetData) {

					var dayData = widgetData[dateText];

					for(let chargerId in dayData) {
					
						let value = +dayData[chargerId];
						if(typeof(summation[chargerId]) == 'undefined')
							summation[chargerId] = 0;

						summation[chargerId] += value;
					}
				}

				var siteChargerIds = Object.keys(chargersData);
				var summationIds = Object.keys(summation);

				siteChargerIds.forEach((ID) => {
					if(summationIds.indexOf(ID) == -1)
						summation[ID] = 0;
				});

				let idx = -1;

				for(let chargerId in summation) {

					// chargerId = +chargerId;
					let value = (widgetName == 'charger_plugins') ? summation[chargerId] : parseInt(''+(summation[chargerId] * 1.07)).toFixed(2);

					var chargerdata = chargersData[chargerId];
					
					if(!chargerdata)
						continue;
						
					idx++;
					
					chargerdata.model = chargerdata.model || '';
					chargerdata.model = chargerdata.model.trimRight();

					var modeltype = chargerdata.model.split('-')[0];

					if(['Q4', 'Q6', 'Q12'].indexOf(modeltype) == -1)
						modeltype = 'Q12';

					var chargerFilteredOut = false;
					if (tags.length > 0 && !filteredInDevices.includes(+chargerId)) {
						chargerFilteredOut = true;
					}

					if (!chargerFilteredOut && operationIndex !== null && chargerdata.chargertype != operationIndex) {
						chargerFilteredOut = true;
					}

					if (!chargerFilteredOut && model !== null && modeltype != model) {
						chargerFilteredOut = true;
					}

					if(chargerFilteredOut)
						continue;

					returnData[idx] = {'list': [], 'count': 1};
					if(isList) {
						returnData[idx].list.push({
							'chargerid':		chargerId,
							'value':			value,
							'chargerusername':	chargerdata.chargerusername,
							'serialnumber':		chargerdata.serialnumber
						});
					} else {
						returnData.list.push({
							x: chargerId,
							value: value,
							list_id: idx
						});
					}
				}
			break;

			case "site_energy_consumption":
				widgetData = allData.data.KWHRs_sum[periodIndex];
				
				returnData = {'list': []};

				var index = -1;

				var summation = {};

				for(var dateText in widgetData) {

					index++;

					var dayData = widgetData[dateText];

					var sumValue = 0;

					returnData[index] = {'list': [], 'count': 0};

					for(let chargerId in dayData) {
					
						let value = dayData[chargerId] * 1.07;
						// chargerId = +chargerId;
						sumValue += value;

						var chargerdata = chargersData[chargerId];
						
						if(!chargerdata)
							continue;
						
						chargerdata.model = chargerdata.model || '';
						chargerdata.model = chargerdata.model.trimRight();

						var modeltype = chargerdata.model.split('-')[0];

						if(['Q4', 'Q6', 'Q12'].indexOf(modeltype) == -1)
							modeltype = 'Q12';

						var chargerFilteredOut = false;
						if (tags.length > 0 && filteredInDevices.indexOf(+chargerId) < 0) {
							chargerFilteredOut = true;
						}

						if (!chargerFilteredOut && operationIndex !== null && chargerdata.chargertype != operationIndex) {
							chargerFilteredOut = true;
						}

						if (!chargerFilteredOut && model !== null && modeltype != model) {
							chargerFilteredOut = true;
						}

						if(chargerFilteredOut)
							continue;

						returnData[index].list.push({
							'chargerid':		chargerId,
							'value':			(Math.round(value * 100) / 100).toFixed(2),
							'chargerusername':	chargerdata.chargerusername,
							'serialnumber':		chargerdata.serialnumber
						});
						returnData[index].count++;

						if(typeof(summation[index]) == 'undefined')
							summation[index] = {'date': dateText, 'val': 0};

						summation[index].val += value;
					}

				}

				for(var Idx in summation) {
					returnData.list.push({
						x: +summation[Idx].date.substr(2,2) + '/' + +summation[Idx].date.substr(4,2),
						value: (Math.round(summation[Idx].val * 100) / 100).toFixed(2),
						list_id: Idx
					});
				}
			break;

			case "site_alerts_wdgt":
				var AC_faults				= allData.data.AC_faults['30'];
				var OV_faults				= allData.data.OV_faults['30'];
				var over_temp_faults		= allData.data.over_temp_faults['30'];
				var communication_faults	= allData.data.communication_faults['30'];
				var PMs_faults				= allData.data.PMs_faults['30'];

				widgetData	= [AC_faults, OV_faults, over_temp_faults, communication_faults, PMs_faults];
				var faultName	= ['AC', 'Output OV', 'Over Temperature', 'Communication Fault', 'PM Faults'];

				returnData = [];
				if(!isList)
					returnData = {'list': []};

				var faultIDs = [];

				for(let index in widgetData) {

					var typeData = widgetData[index];
					faultIDs.push({'total': 0, 'list': {}});

					for(var Idx in typeData) {
					
						var faultData	= typeData[Idx];
						var chargerId	= +faultData.chargerid;
						var chargerdata	= chargersData[chargerId];
						
						if(!chargerdata || !chargerdata.actviewenable)
							continue;

						let faultid: any = +faultData.cycleid;
						if(index == '4')
							faultid = this.commonUtil.PMidToSerialNumber(faultData.pmid, chargerdata);
						
						chargerdata.model = chargerdata.model || '';
						chargerdata.model = chargerdata.model.trimRight();

						var modeltype = chargerdata.model.split('-')[0];

						if(['Q4', 'Q6', 'Q12'].indexOf(modeltype) == -1)
							modeltype = 'Q12';

						var chargerFilteredOut =  !chargerdata.actviewenable;
						if (tags.length > 0 && filteredInDevices.indexOf(chargerId) < 0) {
							chargerFilteredOut = true;
						}

						if (!chargerFilteredOut && operationIndex !== null && chargerdata.chargertype != operationIndex) {
							chargerFilteredOut = true;
						}

						if (!chargerFilteredOut && model !== null && modeltype != model) {
							chargerFilteredOut = true;
						}

						if(chargerFilteredOut)
							continue;

						if(!faultIDs[index].list[chargerId])
							faultIDs[index].list[chargerId] = [];

						faultIDs[index].list[chargerId].push(faultid);
						faultIDs[index].total++;
					}

				}

				for(let index in faultIDs) {

					var typeData	= faultIDs[index].list;
					var typeTotal	= faultIDs[index].total;

					if(isList) {
						returnData[index] = {'list': [], 'count': 0};

						for(let chargerId in typeData) {
							
							// chargerId		= +chargerId;
							var IDs			= typeData[chargerId];
							var chargerdata	= chargersData[chargerId];

							returnData[index].list.push({
								'chargerid':		chargerId,
								'value':			IDs.join(', '),
								'chargerusername':	chargerdata.chargerusername,
								'serialnumber':		chargerdata.serialnumber,
								'isPM':				index == '4'
							});
							returnData[index].count++;
						}
					} else {

						returnData.list.push({
							x: faultName[index],
							value: typeTotal,
							list_id: index
						});
					}
				}

			break;
		}

		return returnData;
	}

	generateCustomerWidgetData(allData, widgetName, dateRange, sites, customerWidgets, options) {
		let data,tempData,i,siteId;
		let inuse_events = options.inuse_events,
			charge_events = options.charge_events;

		let widgetIndex = this.mapWidgetNameByIndexId(widgetName, false, inuse_events, charge_events);
		let periodIndex = this.mapDateRangeToIndexId(dateRange);

		let widgetExists = false;
		for (siteId in allData) {
			if (this.checkWidgetExist(allData[siteId], widgetIndex)) {
				widgetExists = true;
				break;
			}
		}

		if (!widgetExists) {
			return false;
		}

		let returnData = {
			list: [],
			count: 0
		};

		let widgetDisabled = false;
		for (let i in customerWidgets) {
			let tempWidget = customerWidgets[i];
			if (tempWidget.widget_code_name == widgetName) {
				widgetDisabled = !tempWidget.is_active;
				break;
			}
		}

		if (widgetDisabled) {
			return returnData;
		}

		switch (widgetName) {
			case "battery_efficiency":
				for (let siteId in allData) {
					tempData = allData[siteId];
					data = tempData[periodIndex][widgetIndex];
					tempData = {};
					tempData.id = siteId;
					tempData.site_name = sites[siteId];
					tempData.productivity_score = Math.round(data[2]);
					tempData.management_score 	= Math.round(data[6]);
					returnData.list.push(tempData);
				}

				returnData.count = returnData.list.length;
			break;

			case "battery_aging":
				for (let siteId in allData) {
					tempData = allData[siteId];
					if(tempData) {
						for (i in widgetIndex) {
							tempData = tempData[widgetIndex[i]];
						}
						data = tempData;
						tempData = {};
						tempData.id = siteId;
						tempData.site_name = sites[siteId];
						tempData.count	= Math.round(data[0][6]);
						tempData.budget	= 0;
						returnData.list.push(tempData);
					}
				}

				returnData.count = returnData.list.length;
			break;

			case "battery_utilization":
				for (let siteId in allData) {
					tempData = allData[siteId];
					if(tempData) {
						data = tempData[periodIndex][widgetIndex];
						tempData = {};
						tempData.id = siteId;
						tempData.site_name = sites[siteId];
						tempData.utilization = Math.round(data[(data.length - 1)]);
						returnData.list.push(tempData);
					}
				}

				returnData.count = returnData.list.length;
			break;
		}
		return returnData;
	}

	private checkIsValidOperation(battviewdata, operationIndex) {
		// in lithium case we need to filter by battery type not charger type
		if(operationIndex == 3)
			return battviewdata.batterytype == 1; // lithium
		return battviewdata.chargertype == operationIndex;
	}
}