import { Component, OnInit, OnDestroy } from '@angular/core';
import { HomeService } from '../home.service';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';

@Component({
	selector: 'app-devices-management',
	templateUrl: './devices-management.component.html',
	styleUrls: ['./devices-management.component.css']
})
export class DevicesManagementComponent implements OnInit, OnDestroy {

	selectedDevices = {
		'charger': [],
		'battview': []
	};
	userRequests = [];
	chargersList = [];
	battviewsList = [];
	chargersRequests = [];
	battviewRequests = [];
	filterChargerSN = '';
	filterBattviewSN = '';

	constructor(
		private homeService: HomeService,
		private breadcrumb: BreadcrumbService
	) {
	}

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([{label: 'Devices Management', url: ''}]);
		this.getUserNotAssignedDevices();
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}

	getUserNotAssignedDevices() {
		this.homeService.getUserNotAssignedDevices().subscribe(
			(response: any) => {

				var devicesRes		= response.devices;
				this.userRequests	= response.userRequests;

				if(devicesRes.length > 0) {

					this.chargersList		= [];
					this.battviewsList	= [];
					var devices = devicesRes;
					
					devices.forEach((item) => {
						if(item.type == 'charger')
							this.chargersList.push(item);
						else if(item.type == 'battview')
							this.battviewsList.push(item);
					});
				}

				this.chargersRequests = [];
				this.battviewRequests = [];
				if(response.userRequests.length > 0) {
					this.userRequests.forEach((req) => {
						if(req.devicetype == 'charger')
							this.chargersRequests.push(''+req.deviceid);
						else if(req.devicetype == 'battview')
							this.battviewRequests.push(''+req.deviceid);
					});
				}
			}
		);
	}

	toggleDeviceSelection(checked, device, type) {
		
		device.checked = checked;
		
		if(checked) {
			this.selectedDevices[type].push(device.id);
			return
		}

		let idx = this.selectedDevices[type].indexOf(device.id);
		if(idx != -1)
			this.selectedDevices[type].splice(idx, 1);
	}
}