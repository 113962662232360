import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-list-rt-records',
	templateUrl: './list-rt-records.component.html',
	styleUrls: ['./list-rt-records.component.css']
})
export class ListRtRecordsComponent implements OnInit {

	@Input() device: any = {};
	@Input() companyName: string = "";
	@Input() siteName: string = "";
	@Output() updateAppearanceElementsFlags = new EventEmitter<any>(true);

	constructor() { }

	ngOnInit() {
		this.updateAppearanceElementsFlags.emit({
			addNoteEnabled: false,
			pageHasFilterByDate: false,
			pageHasBattviewTags: true,
			hasResetPLC: false,
			pageHasManageBtn: true,
			pageHasTimesInfo: true,
		});
	}
	onDeviceChanged(device) {
		this.device = device;
	}
}
