<div class="boxs3">
	<ul class="nav nav-pills">
		<li [ngClass]="{active: activePage == 'analytics'}" appDropdown>
			<a class="glyphicon glyphicon-stats dropdown-toggle"><span style="font-family:sans-serif;">&nbsp;Analytics<i class="margin-left-sm fa fa-caret-down"></i></span></a>
			<ul class="z-index dropdown-menu" role="menu">
				<li [ngClass]="{active:isAnalyticsSet('cycles')}"><a class="glyphicon glyphicon-repeat" [routerLink]="['/', customerID, siteID, 'chargers', 'analytics', 'cycles', chargerID]"><span style="font-family:sans-serif;">&nbsp;Cycles Record</span></a></li>
						
				<li [ngClass]="{active:isAnalyticsSet('faults')}"><a class="glyphicon glyphicon-flash" [routerLink]="['/', customerID, siteID, 'chargers', 'analytics', 'faults', chargerID]">&nbsp;<span style="font-family:sans-serif;">PM Faults</span></a></li>

				<li [ngClass]="{active:isAnalyticsSet('pm-warnings')}" *ngIf="charger.firmwareversion >= 3.01 && userService.hasAccessFunction('access_device_config_track')"><a class="glyphicon glyphicon-warning-sign" [routerLink]="['/', customerID, siteID, 'chargers', 'analytics', 'pm-warnings', chargerID]">&nbsp;<span style="font-family:sans-serif;">PM Warnings</span></a></li>

				<li [ngClass]="{active:isAnalyticsSet('GR')}"><a class="glyphicon glyphicon-globe" [routerLink]="['/', customerID, siteID, 'chargers', 'analytics', 'GR', chargerID]">&nbsp;<span style="font-family:sans-serif;">Global Record</span></a></li>
			</ul>
		</li>
		
		<li [ngClass]="{active: activePage == 'settings'}">
			<a class="glyphicon glyphicon-cd" [routerLink]="['/', customerID, siteID, 'chargers', 'settings']" [queryParams]="{chargersIDs: chargerID}"><span style="font-family:sans-serif;">&nbsp;Settings</span></a>
		</li>

		<li [ngClass]="{active: activePage == 'lcd-projection'}" *ngIf="customerInfo.had_act_intelligence || currentUser.isACTuser">
			<a class="glyphicon glyphicon-unchecked" [routerLink]="['/', customerID, siteID, 'chargers', 'lcd-projection']" [queryParams]="{chargersIDs: chargerID}" ><span style="font-family:sans-serif;">&nbsp;Display</span></a>
		</li>

		<li [ngClass]="{active: activePage == 'lcd-history'}" *ngIf="charger.actviewenable && userService.hasAccessFunction('lcd_history')">
			<a class="glyphicon glyphicon-list-alt" [routerLink]="['/', customerID, siteID, 'chargers', 'lcd-history']" [queryParams]="{chargersIDs: chargerID}"><span style="font-family:sans-serif;">&nbsp;LCD History</span></a>
		</li>

		<li [ngClass]="{active: activePage == 'pms-live-view'}" *ngIf="charger.actviewenable && userService.hasAccessFunction('pms_live_view')">
			<a class="glyphicon glyphicon-modal-window" [routerLink]="['/', customerID, siteID, 'chargers', 'pms-live-view']" [queryParams]="{chargersIDs: chargerID}"><span style="font-family:sans-serif;">&nbsp;PMs Live View</span></a>
		</li>
		<li [ngClass]="{active: activePage == 'notes'}">
			<a class="glyphicon glyphicon-pencil" [routerLink]="['/', customerID, siteID, 'chargers', 'notes', chargerID]"><span style="font-family:sans-serif;">&nbsp;Notes</span></a>
		</li>
		<li [ngClass]="{active: activePage == 'debug-records'}" *ngIf="userService.hasAccessFunction('access_debug_records')">
			<a class="glyphicon glyphicon-compressed" [routerLink]="['/', customerID, siteID, 'chargers', 'debug-records']" [queryParams]="{chargersIDs: chargerID}"><span style="font-family:sans-serif;">&nbsp;Debug Records</span></a>
		</li>
		<li [ngClass]="{active: activePage == 'config-track'}" *ngIf="userService.hasAccessFunction('access_device_config_track')">
			<a class="glyphicon glyphicon-list-alt" [routerLink]="['/', customerID, siteID, 'chargers', 'config-track']" [queryParams]="{chargersIDs: chargerID}"><span style="font-family:sans-serif;">&nbsp;Config Track</span></a>
		</li>
		<li [ngClass]="{active: activePage == 'connectivity-track'}" *ngIf="userService.hasAccessFunction('act_admin_device_management')">
			<a class="glyphicon glyphicon-transfer" [routerLink]="['/', customerID, siteID, 'chargers', 'connectivity-track']" [queryParams]="{chargersIDs: chargerID}"><span style="font-family:sans-serif;">&nbsp;Connectivity Track</span></a>
		</li>
		<li [ngClass]="{active: activePage == 'charger-energy'}" *ngIf="currentUser.isACTuser && charger.actviewenable">
			<a class="glyphicon glyphicon-flash" [routerLink]="['/', customerID, siteID, 'chargers', 'charger-energy']" [queryParams]="{chargersIDs: chargerID}"><span style="font-family:sans-serif;">&nbsp;Charger Energy</span></a>
		</li>
		<li [ngClass]="{active: activePage == 'pms-list'}" *ngIf="chargerID && currentUser.isACTuser">
			<a class="glyphicon glyphicon-modal-window" [routerLink]="['/', customerID, siteID, 'chargers', 'pms-list']" [queryParams]="{chargersIDs: chargerID}"><span style="font-family:sans-serif;">&nbsp;PMs List</span></a>
		</li>
	</ul>
</div>