import { Component, OnInit, OnDestroy } from '@angular/core';
import { DeviceManagementService } from '../../device-management.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { UserService } from '../../../auth/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-daughter-card',
	templateUrl: './daughter-card.component.html',
	styleUrls: ['./daughter-card.component.css']
})
export class DaughterCardComponent implements OnInit, OnDestroy {

	cardsInfo = [];
	serialnumber: string = '';
	isPartial: boolean = false;
	noResult: boolean = null;

	constructor(
		private deviceManagemntService: DeviceManagementService,
		private breadcrumb: BreadcrumbService,
		private userService: UserService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([
			{'label': this.translateService.instant('device.battview_daughter_cards_search'), 'url': ''}
		]);
	}

	getBattviewDaughterCardBySN() {
		this.noResult = false;
		this.cardsInfo = [];

		this.deviceManagemntService.getBattviewDaughterCardBySN(this.serialnumber, this.isPartial).subscribe(
			(response: any) => {

				this.noResult = response.length == 0;
				
				if(!this.isPartial) {

					this.cardsInfo = response;
				} else {
					var cardsInfo = [];
					response.forEach((item) => {
						if(item.is_deleted) {
							
							var idx = item.serialnumber.indexOf('@');
							if(idx != -1)
								item.serialnumber = item.serialnumber.slice(0, idx);
						}
						cardsInfo.push(item);
					});

					this.cardsInfo = cardsInfo;
				}
			}
		);
	}

	deleteBattviewDaughterCard(SN, idx) {
		if(confirm(this.translateService.instant('device.del_battview_daughter_card_prompt', {SN: SN}))) {
			this.deviceManagemntService.deleteBattviewDaughterCard(SN).subscribe(
				data => this.cardsInfo[idx].is_deleted = true
			);
		}
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}