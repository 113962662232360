import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as _ from 'underscore';

import { BattviewsDashboardService } from '../battviews-dashboard.service';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { Router } from '@angular/router';
import { DevicesDashboardService } from '../../../devices/devices-dashboard.service';

@Component({
	selector: 'app-battview-quick-view',
	templateUrl: './battview-quick-view.component.html',
	styleUrls: ['./battview-quick-view.component.css']
})
export class BattviewQuickViewComponent implements OnInit, OnDestroy {

	@Input() device: any = {};

	@Output() updateAppearanceElementsFlags = new EventEmitter<any>(true);

	QVbattview;
	QV;
	chargerInfo:any = null;
	emptyQV;
	countDown;
	originalBattviewsInfo: any[] = [];
	isBulk: boolean = false;
	temperatureFields = {'cooldowntemperature': '', 'foldtemperature': '', 'trtemperature': '', 'batteryhightemperature': ''};
	formatTemperature;
	oldBattview;
	battview;
	changesStack;
	firstAcceptedDay;
	intervalID: any;
	timeoutID: any;
	startTime = 0;

	constructor(
		private battviewsDashboardService: BattviewsDashboardService,
		private commonUtil: CommonUtil,
		private router: Router,
		private deviceDashboards: DevicesDashboardService,
	) { }

	ngOnInit() {
		this.startTime = new Date().getTime();
		this.updateAppearanceElementsFlags.emit({
			addNoteEnabled: false,
			pageHasFilterByDate: false,
			pageHasBattviewTags: true,
			hasResetPLC: false,
			pageHasManageBtn: true,
			pageHasTimesInfo: true,
		});
		this.getBattviewsSettingsInfo();
	}

	onDeviceChanged(device) {
		this.device = device;
		this.getBattviewsSettingsInfo();
	}

	getBattviewsSettingsInfo() {
		if(!this.device.id)
			return;

		this.battviewsDashboardService.getBattviewsSettingsInfo(this.device.id).subscribe((battviews: any)=> {
			
			this.originalBattviewsInfo = _.extend([], battviews.settings);

			if(this.isBulk) {
				this.formatTemperature = 'F';
				battviews.settings.forEach((aBattview) => {
					for(var field in this.temperatureFields) { //In case of bulk: Always show temperature fields in F format
						aBattview[field] = this.commonUtil.fahToCel(aBattview[field], true);
					}
				});
			}

			this.oldBattview = {};
			battviews.settings = this.commonUtil.getArrayOfObjectsIntersection(battviews.settings);

			if(!this.isBulk)
				battviews.settings.wifi_at_version = this.commonUtil.Format_WIFI_AT_Version(battviews.settings.wifi_at_version);

			if(battviews.settings.currentclamp2cala > 0)
				battviews.settings.halleffectreverse = false;
			else
				battviews.settings.halleffectreverse = true;

			this.oldBattview = _.extend(this.oldBattview, battviews.settings);
			this.battview = battviews.settings;

			this.changesStack = this.battviewsDashboardService.formatBattviewQueuedChanges(this.battview, battviews.changesStack);

			for(var field in this.temperatureFields) { //save original temperature fields value
				this.temperatureFields[field] = this.battview[field];
			}

			if(!this.isBulk) {
				this.formatTemperature = 'C';
				if(Object.keys(this.battview).length > 0) {
					if(this.battview.temperatureformat) {

						this.formatTemperature = 'F';
						for(var field in this.temperatureFields) { //temperatureformat is F so; convert C -> F
							this.oldBattview[field]	= this.commonUtil.fahToCel(this.battview[field], true);
							this.battview[field]		= this.commonUtil.fahToCel(this.battview[field], true);
						}
					}
				}
			}
			
			this.battview.lastuploadtime = new Date(this.battview.lastuploadtime);
			this.battview.lasteventtime = new Date(this.battview.lasteventtime);
			this.firstAcceptedDay		= new Date(1*24*60*60*1000);

			this.getBattviewQuickView(true);
			this.intervalID = setInterval(() => {
				this.getBattviewQuickView();
			}, 3000);
		});
	}

	getBattviewQuickView(blockUI = false) {
		if(this.device.id) {
			var timeDiff = Math.abs(new Date().getTime() - this.startTime) / 3600000;
			if (timeDiff >= 1) {
				//Prevent sending API for more than an hour
				clearInterval(this.intervalID);
				return;
			}
			this.battviewsDashboardService.getBattviewQuickView(this.device.id, blockUI).subscribe(
				(response: any) => {
					this.QVbattview	= response.battview;
					this.QV			= response.QV;
					this.chargerInfo= response.chargerInfo;

					if(this.QVbattview) {
	
						if(this.QVbattview.isSupported !== false && blockUI) {
							this.enableAutoconnect();
						}
					}
	
					this.emptyQV = true;
					if(Object.keys(this.QV).length != 0)
						this.emptyQV = false;
					
					if(!this.emptyQV && this.QVbattview.isSupported)
						this.QV.timestamp	= this.commonUtil.getDateFormattedFromUnixTimeStamp(this.QV.startTime, 'LCDprojection');
					
					if(!this.emptyQV) {
						if(this.QV.connectCountDown) {
							if(typeof this.countDown == 'undefined') {
								this.countDown = this.QV.connectCountDown;
								this.connectCountDown();
							}
						} else {
							delete this.countDown;
						}
					}
			});
		}
	}

	connectCountDown() {
		setTimeout(() => {
			this.countDown--;
			if(this.countDown)
				this.connectCountDown();
		}, 1000);
	};

	enableAutoconnect() {
		this.deviceDashboards.enableAutoconnect(this.device.id, 'battview').subscribe(
			(response: any) => {
				this.timeoutID = setTimeout(() => {
					this.enableAutoconnect();
				}, 60000); //every 1 minute
		});
	}

	ngOnDestroy() {
		clearInterval(this.intervalID);
		clearTimeout(this.timeoutID);
	}
}