<ng-select
	[multiple]="isMultiple"
	[addTag]="addTag"
	[items]="items"
	(add)="add($event)"
	(change)="change($event)"
	(remove)="removeTag($event)"
	[hideSelected]="hideSelected"
	placeholder="Add a tag"
	bindLabel="tagname"
	[clearable]="false"
	[(ngModel)]="tag"
>
	<ng-template ng-label-tmp let-tag="item" let-clear="clear">
		<span [ngClass]="{'replacement-tag': checkReplacementTag(tag)}">
			<span class="ng-value-icon left" (click)="clear(tag)" aria-hidden="true">×</span>
			<span class="ng-value-label">{{tag.tagname}}</span>
		</span>
	</ng-template>
</ng-select>

<app-modal #modal [title]="title" confirmBtnText="{{'tag.add_the_tag' | translate}}" [hasCloseButton]="false">
	<div *ngIf="showSharingOption">
		{{'tag.sharing_options' | translate}}:
		<ng-select [items]="tagSharingOptions" bindLabel="text" bindValue="id" placeholder="{{'tag.select_tag_sharing_option' | translate}}" name="shareOption" [(ngModel)]="tempTag.shareOption" [clearable]="false"></ng-select>
	</div>
	<div *ngIf="!addToSite && isACTuser" class="margin-top">
		<input type="checkbox" name="keep_in_replacement" [(ngModel)]="keepInReplacement">{{'tag.keep_tag_in_replacement' | translate}}:
	</div>
</app-modal>
