<app-customized-widget
  *ngIf="!hideWidget"
  title="Overall Battery Replacement Report" 
  widgetId="battery_aging" 
  (onPrintClick)="onPrintClick($event)"
  [options]="{hasPrint: false}">
  <div *appTransclude>
      <table 
        class="table footer-table-no-border table-striped table-condensed table-bordered align-middle margin-top-5"
        *ngIf="items.length > 0">
        <thead>
          <th class="pointer" >
            <a (click)="sortBySite()" class="sortable-column">
              <i 
                class="fa"
                [ngClass]="{'fa-sort-asc': isAsc('site_name'), 'fa-sort-desc': isDesc('site_name')}"></i>
              Site
            </a>
          </th>
          <th class="pointer" >
            <a (click)="sortByCount()" class="sortable-column">
              <i 
                class="fa" 
                [ngClass]="{'fa-sort-asc': isAsc('count'), 'fa-sort-desc': isDesc('count')}"></i>
              No. of Batteries to Replace within 1 Yr
          </a>
          </th>
          <!-- <th sort-column title="'Replacement Budget'" sortable="'budget'" sort-obj="sortObj"></th> -->
        </thead>
        <tbody>
        <tr 
          *ngFor="let item of items">
          <td>{{item['site_name']}}</td>
          <td>
              {{item['count']}}
          </td>
          <!-- <td>
              {{item.budget | currency}}
          </td> -->
        </tr>
        </tbody>
        <!-- <tfoot>
            <tr>
                <td></td>
                <td class="text-right">TOTAL</td>
                <td>{{total | currency}}</td>
            </tr>
        </tfoot> -->
    </table>
   </div>
</app-customized-widget>