<div class="panel panel-default">
	<div *ngIf="activeSubTab != 'end-voltage' || !noChart" class="toolss2 margin-right">
		<button class="fa fa-print" data-toggle="tooltip" title="Print" (click)="printChart(activeSubTab);"></button>
	</div>
	<div class="panel-body">
		<div class="chart_title">
			<h3 *ngIf="!isBattviewMobile">{{customerName}} / {{siteName}}</h3>
			<h3>{{device.batteryid}} ({{!isBattviewMobile ? device.serialnumber : device.truckid}})</h3>
			<span *ngIf="chartTitle != ''"><b>{{chartTitle}}</b><br /></span>
			<b>{{date.fromDate | date:'MM/dd/yyyy'}} - {{date.toDate | date:'MM/dd/yyyy'}}</b>
		</div>
		<div class="noprint" *ngIf="activeSubTab == 'customized'">
			Select charts to show 
			<input type="checkbox" [checked]="true" [(ngModel)]="selectedCharts['daily-usage']" /> Daily Usage 
			<input type="checkbox" [checked]="true" [(ngModel)]="selectedCharts['daily-ebus']" /> Daily EBUs 
			<input type="checkbox" [checked]="true" [(ngModel)]="selectedCharts['daily-ahrs']" /> Daily AHrs 
			<input type="checkbox" [(ngModel)]="selectedCharts.soc" /> SOC Chart 
			<span *ngIf="!hideTemperatureChart">
				<input type="checkbox" [(ngModel)]="selectedCharts['max-temperature']" /> Max Temperature
			</span>
			<span *ngIf="!hideTemperatureChart">
				<input type="checkbox" [(ngModel)]="selectedCharts['end-temperature']" /> End Temperature
			</span>
			<span *ngIf="!isBattviewMobile">
				<input type="checkbox" [(ngModel)]="selectedCharts['end-voltage']" (change)="resetEndVoltageChart($event)" /> End Voltage
			</span>
			<br />
		</div>
		<div *ngIf="activeSubTab == 'daily-usage' || (activeSubTab == 'customized' && selectedCharts['daily-usage'])">
			<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
				<column-chart
					[data]="dailyUsageChart.data"
					[config]="dailyUsageChart.config"
					elementId="dailyUsageChart"
					(onChartReady)="generateDownloadImage($event, 'daily-usage')"
				></column-chart>
			</div>
		</div>
		<div *ngIf="activeSubTab == 'daily-ebus' || (activeSubTab == 'customized' && selectedCharts['daily-ebus'])">
			<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
				<column-chart
					[data]="dailyEbusChart.data"
					[config]="dailyEbusChart.config"
					elementId="dailyEbusChart"
					(onChartReady)="generateDownloadImage($event, 'daily-ebus')"
				></column-chart>
			</div>
		</div>
		<div *ngIf="activeSubTab == 'daily-ahrs' || (activeSubTab == 'customized' && selectedCharts['daily-ahrs'])" style="margin-top: 15px">
			<div class="margin-left-xlg">
				<input type="checkbox" id="{{key}}" class="form-control" (change)="checkBoxChanged($event);" [(ngModel)]="availableAhrsCheckBox">
				<label for="{{key}}">Available AHR</label>
			</div>
			<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
				<column-chart
					[data]="dailyAhrsChart.data"
					[config]="dailyAhrsChart.config"
					elementId="dailyAhrsChart"
					(onChartReady)="generateDownloadImage($event, 'daily-ahrs')"
				></column-chart>
			</div>
		</div>
		<div *ngIf="activeSubTab == 'soc' || (activeSubTab == 'customized' && selectedCharts.soc)">
			<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
				<line-chart
					[data]="socChart.data"
					[config]="socChart.config"
					elementId="socChart"
					(onChartReady)="generateDownloadImage($event, 'soc')"
				></line-chart>
			</div>
		</div>
		<div *ngIf="activeSubTab == 'max-temperature' || (activeSubTab == 'customized' && selectedCharts['max-temperature'])" style="margin-top: 15px">
			<div class="margin-left-xlg">
				<input type="checkbox" class="form-control" (change)="checkBoxChanged($event);" [(ngModel)]="maxTempCheckBox">
				<label>Max Temperature Limit</label>
			</div>
			<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
				<line-chart
					[data]="maxTempChart.data"
					[config]="maxTempChart.config"
					elementId="maxTempChart"
					(onChartReady)="generateDownloadImage($event, 'max-temperature')"
				></line-chart>
			</div>
		</div>
		<div *ngIf="activeSubTab == 'end-temperature' || (activeSubTab == 'customized' && selectedCharts['end-temperature'])" style="margin-top: 15px">
			<div class="margin-left-xlg">
				<input type="checkbox" class="form-control" (change)="checkBoxChanged($event);" [(ngModel)]="endTempCheckBox">
				<label>Max Temperature Limit</label>
			</div>
			<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
				<line-chart
					[data]="endTempChart.data"
					[config]="endTempChart.config"
					elementId="endTempChart"
					(onChartReady)="generateDownloadImage($event, 'end-temperature')"
				></line-chart>
			</div>
		</div>

		<div *ngIf="!noDataChart && (activeSubTab == 'end-voltage' || (activeSubTab == 'customized' && selectedCharts['end-voltage']))" style="margin-top: 15px">
			<div class="margin-left-xlg">
				<label>
					<input type="checkbox" name="Idle" value="Idle" (change)="updateSelectedType($event);checkBoxChanged($event)" /> Idle &nbsp;
				</label>
				<label>
					<input type="checkbox" name="Inuse" value="Inuse" (change)="updateSelectedType($event);checkBoxChanged($event)" /> Inuse &nbsp;
				</label>
				<label>
					<input type="checkbox" name="Charge" value="Charge" (change)="updateSelectedType($event);checkBoxChanged($event)" [checked]="true" /> Charge &nbsp;
				</label>
			</div>
			<div class="margin-left-xlg">
				<span>
					<input type="checkbox" id="show-dischargelimit" class="form-control" [(ngModel)]="inuseLimitCheckBox" (change)="checkBoxChanged($event)">
					<label for="show-dischargelimit">Deep Discharge Limit</label>
				</span>
				<span *ngIf="selectedTypes.indexOf('Charge')>-1 && !noChart">
					<input type="checkbox" id="show-eqvoltage" class="form-control" [(ngModel)]="eqVoltageCheckBox" (change)="checkBoxChanged($event)">
					<label for="show-eqvoltage">EQ Voltage</label>
				</span>
				<span *ngIf="showPotentialCheckBox && !noChart">
					<input type="checkbox" id="show-potential" class="form-control" [(ngModel)]="potentialCheckBox" (change)="checkBoxChanged($event)">
					<label for="show-potential">Potential Weak Cells Limit</label>
				</span>
				<span *ngIf="selectedTypes.indexOf('Charge')>-1 && !noChart">
					<input type="checkbox" id="show-compensated" class="form-control" [(ngModel)]="isCompensatedVoltageCheckBox" (change)="checkBoxChanged($event)">
					<label for="show-compensated">Show Voltages as Temperature Compensated</label>
				</span>
			</div>
			<div *ngIf="!noChart" style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
				<line-chart
					[data]="endVoltageChart.data"
					[config]="endVoltageChart.config"
					elementId="endVoltageChart"
					(onChartReady)="generateDownloadImage($event, 'end-voltage')"
				></line-chart>
			</div>
		</div>
		<div *ngIf="noChart && (activeSubTab == 'end-voltage' || (activeSubTab == 'customized' && selectedCharts['end-voltage']))" class="col-md-12">
			<div class="alert alert-warning" role="alert">
				No data available to draw this chart
			</div>
		</div>
	</div>
</div>