import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { Router } from '@angular/router';

import { NoUIBlockHttpParams } from '../shared/loader/noUIBlock-httpParam';

@Injectable()
export class DeviceManagementService {
	
	constructor(private httpClient: HttpClient) {}

	getReplacementTimeline(SN, ID, type) {
		return this.httpClient.post('/management/getReplacementTimeline', {SN, ID, type}, {
			observe: "body"
		});
	}
	getDeviceByID(ID, type) {
		return this.httpClient.post('/management/getDeviceByID', {ID, type}, {
			observe: "body"
		});
	}
	getClientDevices(clientInfo) {
		return this.httpClient.post('/management/getClientDevices', clientInfo, {
			observe: "body"
		});
	}
	enableDeviceAutoconnect(deviceInfo) {
		return this.httpClient.post('/management/enableDeviceAutoconnect', deviceInfo, {
			observe: "body"
		});
	}
	getDevicesConnectivity() {
		return this.httpClient.post('/management/getDevicesConnectivity', {}, {
			observe: "body"
		});
	}
	getSitesConnectivity() {
		return this.httpClient.post('/management/getSitesConnectivity', {}, {
			observe: "body"
		});
	}
	pushFirmwareUpdate(siteid, type, firmwareVersion, firmwareType) {
		return this.httpClient.post('/management/pushFirmwareUpdate', {siteid, type, firmwareVersion, firmwareType}, {
			observe: "body"
		});
	}
	editSiteLiveSocket(siteID, type, enable) {
		return this.httpClient.post('/management/editSiteLiveSocket', {siteID, type, enable}, {
			observe: "body"
		});
	}
	getSiteFirmwareUpdateRequests(type, siteid) {
		return this.httpClient.post('/management/getSiteFirmwareUpdateRequests', {type, siteid}, {
			observe: "body"
		});
	}
	getDeviceBySN (SN, type, options = {}) {
		return this.httpClient.post('/management/getDeviceBySN', {SN, type, options}, {
			observe: "body"
		});
	}
	getPmBySN (SN, options = {}) {
		return this.httpClient.post('/management/getPmBySN', {SN, options}, {
			observe: "body"
		});
	}
	deleteBattviewDaughterCard(SN) {
		return this.httpClient.post('/bfd/deleteBattviewDaughterCard', {SN}, {
			observe: "body"
		});
	}

	getBattviewDaughterCardBySN(SN, isPartial) {
		return this.httpClient.post('/bfd/getBattviewDaughterCardBySN', {SN, isPartial}, {
			observe: "body"
		});
	}

	getDevicesByIP(IP) {
		return this.httpClient.post('/management/getDevicesByIP', {IP}, {
			observe: "body"
		});
	}

	getBattviewDaughterCard(SN) {
		return this.httpClient.post('/bfd/getBattviewDaughterCard', {SN}, {
			observe: "body"
		});
	}

	saveDaughterCard(card) {
		return this.httpClient.post('/bfd/saveDaughterCard', card, {
			observe: "body"
		});
	}

	resetBattviewHistory(bfdid) {
		return this.httpClient.post('/bfd/resetBattviewHistory', {bfdid}, {
			observe: "body"
		});
	}

	isTestConfirmed(type, device) {
		if(type != 'charger')
			return false;

		return (device.flags2 & 0x02) == 0;
	}

	getEditDeviceLink(type, deviceInfo) {
		let url = [];
		let params = {};

		if (type == 'charger') {
			url = ['/chargers', 'edit', 'customerId', deviceInfo['customerid'] || 0, 'siteId', deviceInfo['siteid'] || 0];
			params = {'chargerId': deviceInfo['id']};
		} else {
			if(deviceInfo['ispa']) {

				url = ['/battviews-mobile/edit'];
				params = {'battviewId': deviceInfo['id']};
			} else {

				url = ['/battviews', 'edit', 'customerId', deviceInfo['customerid'], 'siteId', deviceInfo['siteid']];
				params = {'battviewId': deviceInfo['id']};
			}
		}

		return {url, params};
	};
	updateEquipment(equipment) {
		return this.httpClient.post('/management/updateEquipment', {equipment}, {
			observe: "body",
			responseType:"text"
		});
	}
	getSupportLoggingInfo(tabId) {
		return this.httpClient.post('/management/getSupportLoggingInfo', {tabId}, {
			observe: "body"
		});
	}
	getDeviceOwnerChangeRequests(type) {
		return this.httpClient.post('/management/getDeviceOwnerChangeRequests', {type}, {
			observe: "body"
		});
	}
	getPendingCustomerSite(type) {
		return this.httpClient.post('/management/getPendingCustomerSite', {type}, {
			observe: "body"
		});
	}
	validateDeviceOwnerChangeRequests(ids, type, decision) {
		return this.httpClient.post('/management/validateDeviceOwnerChangeRequests', {ids, type, decision}, {
			observe: "body",
			responseType:"text"
		});
	}
	validatePendingCustomerSite(id, type, decision) {
		return this.httpClient.post('/management/validatePendingCustomerSite', {id, type, decision}, {
			observe: "body",
			responseType:"text"
		});
	}
	getDealersOEMsDropDown() {
		return this.httpClient.post('/management/getDealersOEMsDropDown', {}, {
			observe: "body"
		});
	}
	getCustomersForDealersOEMs(id, roleName) {
		return this.httpClient.post('/customers/getCustomersForDealersOEMs', {id, roleName}, {
			observe: "body"
		});
	}
	getClientSitesByRoleName(siteObj, roleName) {
		return this.httpClient.post('/customers/getClientSitesByRoleName', {siteObj, roleName}, {
			observe: "body"
		});
	}
	getBATTViewsDropDownByRoleName(battviewObj, roleName) {
		return this.httpClient.post('/customers/getBATTViewsDropDownByRoleName', {battviewObj, roleName}, {
			observe: "body"
		});
	}
	getChargersDropDownByRoleName(chargerObj, roleName) {
		return this.httpClient.post('/customers/getChargersDropDownByRoleName', {chargerObj, roleName}, {
			observe: "body"
		});
	}
	getSitesWithoutOwners() {
		return this.httpClient.post('/customers/getSitesWithoutOwners', {}, {
			observe: "body"
		});
	}
	prepareFileDownload(type, enc?) {
		return this.httpClient.post('/management/prepareFileDownload', {type, enc}, {
			observe: "body"
		});
	}
	scheduleReport(data) {
		return this.httpClient.post('/management/scheduleReport', {data}, {
			observe: "body",
			responseType:"text"
		});
	}
	getAllSitesForUser() {
		return this.httpClient.post('/customers/getAllSitesForUser', {}, {
			observe: "body"
		});
	}
	getNonAllocatedBattviews(isPA) {
		return this.httpClient.post('/bfd/getNonAllocatedBattviews', {isPA}, {
			observe: "body"
		});
	}
	allocateBattviews(IDs) {
		return this.httpClient.post('/bfd/allocateBattviews', {IDs}, {
			observe: "body",
			responseType:"text"
		});
	}
	addCommandToBattviewQueue(id, command) {
		return this.httpClient.post('/bfd/addCommandToBattviewQueue', {id, command}, {
			observe: "body"
		});
	}
	getProductionLineBattviews(options, noUIBlock) {
		return this.httpClient.post('/bfd/getProductionLineBattviews', options, {
			observe: "body",
			params: new NoUIBlockHttpParams(noUIBlock)
		});
	}
	getProductionLineBattviewErrors(battviews, noUIBlock) {
		return this.httpClient.post('/bfd/getProductionLineBattviewErrors', {battviews}, {
			observe: "body",
			params: new NoUIBlockHttpParams(noUIBlock)
		});
	}
	editProductionLineIPs(action, value) {
		return this.httpClient.post('/management/editProductionLineIPs', {action, value}, {
			observe: "body",
			responseType:"text"
		});
	}
	getProductionAllowedIPs() {
		return this.httpClient.post('/management/getProductionAllowedIPs', {}, {
			observe: "body"
		});
	}
	getCommissionedDates() {
		return this.httpClient.post('/management/getCommissionedDates', {}, {
			observe: "body"
		});
	}
	getSitesInvoicesForReporting() {
		return this.httpClient.post('/customers/getSitesInvoicesForReporting', {}, {
			observe: "body"
		});
	}
	getSitesInvoicesActivationReminder() {
		return this.httpClient.post('/customers/getSitesInvoicesActivationReminder', {}, {
			observe: "body"
		});
	}
	getAvailableReplacementDevices() {
		return this.httpClient.get('/admins/getReplacementDevices', {
			observe: "body"
		});
	}
	getBattviews(options) {
		return this.httpClient.post('/admins/getBattviews', {options}, {
			observe: "body"
		});
	}
	getChargers(options) {
		return this.httpClient.post('/admins/getChargers', {options}, {
			observe: "body"
		});
	}
	replaceCharger(newId, originalId) {
		return this.httpClient.post('/admins/replaceCharger', {newId, originalId}, {
			observe: "body"
		});
	}
	replaceBattview(newId, originalId, mode) {
		return this.httpClient.post('/admins/replaceBattview', {newId, originalId, mode}, {
			observe: "body"
		});
	}
	getHasACTintelligentSites(options) {
		return this.httpClient.post('/customers/getHasACTintelligentSites', {options}, {
			observe: "body"
		});
	}
	getSiteFaults(customerId, siteId, date) {
		return this.httpClient.post('/customers/getSiteFaults', {customerId, siteId, date}, {
			observe: "body"
		});
	}
	addDeviceTag(customerId, siteId, deviceType, deviceId, tagObj) {
		return this.httpClient.post('/customers/addDeviceTag', {customerId, siteId, deviceType, deviceId, tagObj}, {
			observe: "body"
		});
	}
	removeDeviceTag(customerId, siteId, deviceType, deviceIds, tagsObj) {
		return this.httpClient.post('/customers/removeDeviceTag', {customerId, siteId, deviceType, deviceIds, tagsObj}, {
			observe: "body",
			responseType: "text"
		});
	}

	regenerateDailyDetails(bfdid, studyid) {
		return this.httpClient.post('/bfd/regenerateDailyDetails', {bfdid, studyid});
	}

	getBlockedFWReport() {
		return this.httpClient.post('/management/getBlockedFWReport', {}, {
			observe: "body"
		});
	}
	getDispatchedVoltusLogs() {
		return this.httpClient.post('/dr/getDispatchedVoltusLogs', {}, {
			observe: "body"
		});
	}
	unBlockFWReport(deviceType, section, id, version) {
		return this.httpClient.post('/management/unBlockFWReport', {deviceType, section, id, version}, {
			observe: "body",
			responseType: "text"
		});
	}
	getDeviceByPO(PO, options = {}) {
		return this.httpClient.post('/management/getDeviceByPO', {PO, options}, {
			observe: "body"
		});
	}
	
	exportServicePageData() {
		return this.httpClient.post('/customers/exportServicePageData', {}, {
			observe: "body"
		});
	}
}