<app-customized-widget 
	title="Site Notes"
	[options]="options"> 
	<div *appTransclude>
		<div class="container-fluid">
			<notes 
				[siteId]="siteId" 
				[modelType]="modelType"
				[sourcePage]="sourcePage">
			</notes>
		</div>
	</div>
</app-customized-widget>