import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { saveAs } from 'file-saver';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { UserService } from '../../../auth/user.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-registration-purposes',
	templateUrl: './registration-purposes.component.html',
	styleUrls: ['./registration-purposes.component.css']
})
export class RegistrationPurposesComponent implements OnInit {
	devicesInfo: any;

	constructor(
		private adminService: AdminService,
		private notificationMessage: NotificationMessageService,
		private breadcrumb: BreadcrumbService,
		private userService: UserService,
		private router: Router
	) {}

	ngOnInit() {
		if(!this.userService.hasAccessFunction('registrations_reports'))
			this.router.navigate(['/unauthorized']);

		this.breadcrumb.setBreadcrumb([{'label': 'Registration Reports', 'url': ''}]);
		this.adminService.getRegistrationReportData().subscribe((res: any) => {
			this.devicesInfo = res.data;
		});
	}

	generateReport(customer_id: number, site_id: number) {
		this.adminService.registrationPurposes(customer_id, site_id).subscribe((res: any) => {
			switch (res.response.api_status) {
				case 1:
					return this.notificationMessage.setMessage('translate|official_report.no_report_data');
				case 2:
					return this.notificationMessage.setMessage('translate|official_report.no_devices_founded');
			}

			this.downloadReport(res);
		});
	}
	downloadReport(data: any) {
		if (!data) return;
		const arr = new Uint8Array(data.response.content.data);
		const blob = new Blob([arr]);
		if (blob)
			saveAs(blob, data.response.name);
	}
}