<div>
	<div class="row">
		<form #editSite="ngForm" (ngSubmit)="saveSite()">
			<div class="col-md-6">
				<app-google-map [latitude]="map.latitude" [longitude]="map.longitude" (onAddressChanged)="editTemplate.updateSiteCoords($event)"></app-google-map>
			</div>
			<div class="col-xs-6" id="left">
				<div class="boxs3">
					<h2>Add/Update Company Site Info</h2>
					<div class="panel panel-default">
						<div class="panel-heading">Site Info</div>
					</div>
					<app-site-edit #editTemplate [site]="site" [OEMsInfo]="OEMsInfo" [dealersInfo]="dealersInfo" [serviceDealersInfo]="serviceDealersInfo" [truckDealersInfo]="truckDealersInfo" [ACTusers]="ACTusers" [mode]="'admin'" [siteOwners]="siteOwners"></app-site-edit>
					<br />
					<button type="submit" class="btn btn-primary" [disabled]="editSite.invalid">Submit</button>
					<button type="button" class="btn btn-default" (click)="goBack()">Cancel</button>
				</div>
			</div>
		</form>
	</div>
</div>