<app-site-widget
	title="Battery Remaining Warranty (Ahrs / Year)"
	elementId="batt_ramaining_warranty"
	widgetClass="height-600"
	[data]="data"
	[config]="config"
	[tableColumns]="tableColumns"
	[siteId]="siteId"
	[customerId]="customerId"
	[customerName]="customerName"
	[siteName]="siteName"
></app-site-widget>