import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dealer' })
export class DealerPipe implements PipeTransform {
  transform(items: any, dealerId: any): any {
    if (dealerId == "") return items;
  	return items.filter(function(customer) {
		  let hasDealerId = customer.dealersIDs.indexOf(parseInt(dealerId)) > -1;
		  let hasServiceDealerId = customer.serviceDealersIDs.indexOf(parseInt(dealerId)) > -1;
      return hasDealerId || hasServiceDealerId;
 	 	});
  }
}