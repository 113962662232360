<form #validateGuest="ngForm" class="form-auth" (submit)="addGuestAsUser()">
	<div class="col-md-6 col-md-offset-3">
		<div class="boxs3">
			<h2>Validate Registration Request</h2>
			<div class="panel panel-default">
				<div class="panel-heading">User Info</div>
			</div>
			<div class="form-field margin-bottom-sm">
				<label for="firstName">First name</label> (<span class="mute pointer" title="Use it" (click)="useText('firstname', guestInfo.firstname)">{{guestInfo.firstname}}</span>)
				<input type="text" class="form-control" name="firstname" [(ngModel)]="user.firstname" id="firstName" placeholder="First Name" required/>
			</div>
			
			<div class="form-field margin-bottom-sm">
				<label for="lastName">Last Name</label> (<span class="mute pointer" title="Use it" (click)="useText('lastname', guestInfo.lastname)">{{guestInfo.lastname}}</span>)
				<input type="text" class="form-control" name="lastname" [(ngModel)]="user.lastname" id="lastName" placeholder="Last Name" required/>
			</div>
			
			<div class="form-field margin-bottom-sm">
				<label for="email">Email</label> (<span class="mute pointer" title="Use it" (click)="useText('email', guestInfo.email)">{{guestInfo.email}}</span>)
				<input type="email" class="form-control" name="email" [(ngModel)]="user.email" id="email" placeholder="Email" required>
			</div>
			
			<div class="form-field margin-bottom-sm">
				<label for="roleId">Role</label> (<span class="mute">{{guestInfo.rolename}}</span>)
				<select (ngModelChange)="getUsersByType($event)" class="form-control" name="roleid" [ngModel]="user.roleid" placeholder="Select Role" required>
					<option *ngFor="let role of roles" [value]="role.id">{{role.name}}</option>
				</select>
			</div>
			
			<div class="form-field margin-bottom-sm" *ngIf="userService.userRoles.dealer == user.roleid">
				<label for="dealer">Dealer</label> (<span class="mute">{{guestInfo.companyname}}</span>)
				<ng-select [items]="dealers" bindLabel="name" bindValue="id" name="dealerid" [(ngModel)]="user.dealerid" placeholder="Select a Dealer" [required]="userService.userRoles.dealer == user.roleid">
				</ng-select>
			</div>

			<div class="form-field margin-bottom-sm" *ngIf="userService.userRoles.truckdealer == user.roleid">
				<label for="truckdealerid">Truck Dealer</label> (<span class="mute">{{guestInfo.companyname}}</span>)
				<ng-select [items]="truckDealers" bindLabel="name" bindValue="id" name="truckdealerid" [(ngModel)]="user.truckdealerid" placeholder="Select a Truck Dealer" [required]="userService.userRoles.truckdealer == user.roleid">
				</ng-select>
			</div>

			<div class="form-field margin-bottom-sm" *ngIf="userService.userRoles.oem == user.roleid">
				<label for="oem">Battery OEM</label>
				<ng-select [items]="OEMs" bindLabel="name" bindValue="id" name="oemid" [(ngModel)]="user.oemid" placeholder="Select a Battery OEM" [required]="userService.userRoles.oem == user.roleid">
				</ng-select>
			</div>
				
			<div *ngIf="userService.userRoles.client == user.roleid">
				<div class="form-field margin-bottom-sm">
					<label><input type="radio" name="isNewClient" [(ngModel)]="user.isNewClient" class="form-control" [value]="false" [required]='userService.userRoles.client == user.roleid'/> Select company</label>
					<label><input type="radio" name="isNewClient" [(ngModel)]="user.isNewClient" class="form-control" [value]="true" [required]='userService.userRoles.client == user.roleid' (click)="user.customerid = 0"/> Add new company </label>
				</div>
				
				<div class="form-field margin-bottom-sm" *ngIf="userService.userRoles.client == user.roleid && !user.isNewClient">
					<label for="customer">Company</label> (<span class="mute pointer" (click)="getMatchedCustomerID()">{{guestInfo.companyname}}</span>)
					<ng-select [items]="customers" bindLabel="name" bindValue="id" name="customerid" [(ngModel)]="user.customerid" placeholder="Select a Customer" [required]="userService.userRoles.client == user.roleid && !user.isNewClient">
				</ng-select>
				</div>

				<div class='padding-8' *ngIf="userService.userRoles.client == user.roleid && user.isNewClient">
					<div class='form-field margin-bottom-sm'>
						<label for="name">Customer Name</label> (<span class="mute pointer" title="Use it" (click)="useText('customerName', guestInfo.companyname)">{{guestInfo.companyname}}</span>)
						<input type='text' class='form-control' [required]="userService.userRoles.client == user.roleid && user.isNewClient" name="customerName" [(ngModel)]='user.customerName' placeholder='Customer Name'/>
					</div>
					
					<fieldset>
						<legend>Contact Info</legend>

						<div class='form-field margin-bottom-sm'>
							<label for="name">Name</label> (<span class="mute pointer" title="Use it" (click)="useText('customerContactname', guestInfo.firstname + ' ' + guestInfo.lastname)">{{guestInfo.firstname}} {{guestInfo.lastname}}</span>)
							<input type='text' class='form-control' [(ngModel)]='user.customerContactname' name="customerContactname" placeholder='Person Name' />
						</div>
						
						<div class='form-field margin-bottom-sm'>
							<label for='phoneno'>Phone No.</label> (<span class="mute pointer" title="Use it" (click)="useText('customerPhoneno', guestInfo.phoneno)">{{guestInfo.phoneno}}</span>)
							<input type='text' class='form-control' [(ngModel)]='user.customerPhoneno' name="customerPhoneno" placeholder='Phone No.' />
						</div>
						
						<div class='form-field margin-bottom-sm'>
							<label for='email'>Email</label> (<span class="mute pointer" title="Use it" (click)="useText('customerEmail', guestInfo.email)">{{guestInfo.email}}</span>)
							<input type='email' class='form-control' name="customerEmail" [(ngModel)]='user.customerEmail' placeholder='Email'/>
						</div>
					</fieldset>
				</div>
			</div>
			
			<div class="text-danger margin-bottom-lg" *ngIf="validateGuest.required" >* Please fill all required fields!</div>
			<button type="submit" class="btn btn-primary" [disabled]="validateGuest.invalid">Submit</button>
			<button type="button" class="btn btn-default" routerLink="/registrations-request">Cancel</button>
			<button type="button" class="btn btn-danger r" (click)="addGuestAsUser(true)">Reject Request</button>
		</div>
	</div>
</form>