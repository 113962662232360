<div>
   <app-customized-widget 
   *ngIf="ready['all_sites_alerts']"
   title="All Sites Alerts (# of Batteries)" 
   widgetId="all_sites_alerts" 
   [options]="options"
   [dropDownGroupList]="widgetsDropDownLists['all_sites_alerts']"
   (onDropDownChange)="dateChange($event[0],$event[1])"
   (onPrintClick)="onPrintClick($event)">
   <div *appTransclude>
      <div
         class="margin-top-lg margin-bottom margin-left margin-right">
         <app-grid-v2 
         [id]="'all_sites_alerts'"
         [columns]="gridColumns['all_sites_alerts']"
         [data]="gridRows['all_sites_alerts']">
         </app-grid-v2>
      </div>
   </div>
   </app-customized-widget>
   <app-customized-widget 
   *ngIf="ready['weekly_missed_eqs']"
   title="Weekly Missed EQs Alerts" 
   widgetId="weekly_missed_eqs" 
   [options]="options"
   [dropDownGroupList]="widgetsDropDownLists['weekly_missed_eqs']"
   [radioButtonGroupList]="widgetsRadioButtons['weekly_missed_eqs']"
   (onRadioButtonChange)="radioButtonChange($event[0], $event[1])"
   (onDropDownChange)="dateChange($event[0],$event[1])"
   [checkboxList]="widgetsCheckboxList['weekly_missed_eqs']"
   (onCheckboxChange)="checkboxChange($event[0],$event[1])"
   (onPrintClick)="onPrintClick($event)">
   <div *appTransclude>
      <div
         class="margin-top-lg margin-bottom margin-left margin-right">
         <bar-chart
         (onChartReady)="generateImageURI($event)"
         [data]="chartsDataTables['weekly_missed_eqs']"
         [config]="chartConfig['weekly_missed_eqs']"
         elementId="weekly_missed_eqs">
         </bar-chart>
         <app-grid-v2 
         [id]="'weekly_missed_eqs'"
         [columns]="gridColumns['weekly_missed_eqs']"
         [data]="gridRows['weekly_missed_eqs']">
         </app-grid-v2>
      </div>
   </div>
   </app-customized-widget>
   <app-customized-widget 
   *ngIf="ready['high_temp']"
   title="High Temperature Alerts" 
   widgetId="high_temp" 
   [options]="options"
   [dropDownGroupList]="widgetsDropDownLists['high_temp']"
   (onDropDownChange)="dateChange($event[0],$event[1])"
   [checkboxList]="widgetsCheckboxList['high_temp']"
   (onCheckboxChange)="checkboxChange($event[0],$event[1])"
   (onPrintClick)="onPrintClick($event)">
   <div *appTransclude>
      <div
         class="margin-top-lg margin-bottom margin-left margin-right">
         <bar-chart
         (onChartReady)="generateImageURI($event)"
         [data]="chartsDataTables['high_temp']"
         [config]="chartConfig['high_temp']"
         elementId="high_temp">
         </bar-chart>
         <app-grid-v2 
         [id]="'high_temp'"
         [columns]="gridColumns['high_temp']"
         [data]="gridRows['high_temp']">
         </app-grid-v2>
      </div>
   </div>
   </app-customized-widget>
   <app-customized-widget 
   *ngIf="ready['over_under_charge']"
   title="Over/under Charge Alerts" 
   widgetId="over_under_charge" 
   [options]="options"
   [dropDownGroupList]="widgetsDropDownLists['over_under_charge']"
   (onDropDownChange)="dateChange($event[0],$event[1])"
   [checkboxList]="widgetsCheckboxList['over_under_charge']"
   (onCheckboxChange)="checkboxChange($event[0],$event[1])"
   (onPrintClick)="onPrintClick($event)">
   <div *appTransclude>
      <div
         class="margin-top-lg margin-bottom margin-left margin-right">
         <bar-chart
         (onChartReady)="generateImageURI($event)"
         [data]="chartsDataTables['over_under_charge']"
         [config]="chartConfig['over_under_charge']"
         elementId="over_under_charge">
         </bar-chart>
         <app-grid-v2 
         [id]="'over_under_charge'"
         [columns]="gridColumns['over_under_charge']"
         [data]="gridRows['over_under_charge']">
         </app-grid-v2>
      </div>
   </div>
   </app-customized-widget>
   <app-customized-widget 
   *ngIf="ready['potential_weak_cells']"
   title="Potential Weak Cells Alerts" 
   widgetId="potential_weak_cells" 
   [options]="options"
   [dropDownGroupList]="widgetsDropDownLists['potential_weak_cells']"
   (onDropDownChange)="dateChange($event[0],$event[1])"
   [checkboxList]="widgetsCheckboxList['potential_weak_cells']"
   (onCheckboxChange)="checkboxChange($event[0],$event[1])"
   (onPrintClick)="onPrintClick($event)">
   <div *appTransclude>
      <div
         class="margin-top-lg margin-bottom margin-left margin-right">
         <bar-chart
         (onChartReady)="generateImageURI($event)"
         [data]="chartsDataTables['potential_weak_cells']"
         [config]="chartConfig['potential_weak_cells']"
         elementId="potential_weak_cells">
         </bar-chart>
         <app-grid-v2 
         [id]="'potential_weak_cells'"
         [columns]="gridColumns['potential_weak_cells']"
         [data]="gridRows['potential_weak_cells']">
         </app-grid-v2>
      </div>
   </div>
   </app-customized-widget>
   <app-customized-widget 
   *ngIf="ready['ebu_over_usage']"
   title="EBU Over Usage Alerts" 
   widgetId="ebu_over_usage" 
   [options]="options"
   [dropDownGroupList]="widgetsDropDownLists['ebu_over_usage']"
   (onDropDownChange)="dateChange($event[0],$event[1])"
   [checkboxList]="widgetsCheckboxList['ebu_over_usage']"
   (onCheckboxChange)="checkboxChange($event[0],$event[1])"
   (onPrintClick)="onPrintClick($event)">
   <div *appTransclude>
      <div
         class="margin-top-lg margin-bottom margin-left margin-right">
         <bar-chart
         (onChartReady)="generateImageURI($event)"
         [data]="chartsDataTables['ebu_over_usage']"
         [config]="chartConfig['ebu_over_usage']"
         elementId="ebu_over_usage">
         </bar-chart>
         <app-grid-v2 
         [id]="'ebu_over_usage'"
         [columns]="gridColumns['ebu_over_usage']"
         [data]="gridRows['ebu_over_usage']">
         </app-grid-v2>
      </div>
   </div>
   </app-customized-widget>
   <app-customized-widget 
   *ngIf="ready['water_levels']"
   title="Water Levels Alerts" 
   widgetId="water_levels" 
   [options]="options"
   [dropDownGroupList]="widgetsDropDownLists['water_levels']"
   (onDropDownChange)="dateChange($event[0],$event[1])"
   [checkboxList]="widgetsCheckboxList['water_levels']"
   (onCheckboxChange)="checkboxChange($event[0],$event[1])"
   (onPrintClick)="onPrintClick($event)">
   <div *appTransclude>
      <div
         class="margin-top-lg margin-bottom margin-left margin-right">
         <bar-chart
         (onChartReady)="generateImageURI($event)"
         [data]="chartsDataTables['water_levels']"
         [config]="chartConfig['water_levels']"
         elementId="water_levels">
         </bar-chart>
         <app-grid-v2 
         [id]="'water_levels'"
         [columns]="gridColumns['water_levels']"
         [data]="gridRows['water_levels']">
         </app-grid-v2>
      </div>
   </div>
   </app-customized-widget>
   <app-customized-widget 
   *ngIf="ready['missed_finish']"
   title="Missed Finish Alerts" 
   widgetId="missed_finish" 
   [options]="options"
   [dropDownGroupList]="widgetsDropDownLists['missed_finish']"
   (onDropDownChange)="dateChange($event[0],$event[1])"
   [checkboxList]="widgetsCheckboxList['missed_finish']"
   (onCheckboxChange)="checkboxChange($event[0],$event[1])"
   (onPrintClick)="onPrintClick($event)">
   <div *appTransclude>
      <div
         class="margin-top-lg margin-bottom margin-left margin-right">
         <bar-chart
         (onChartReady)="generateImageURI($event)"
         [data]="chartsDataTables['missed_finish']"
         [config]="chartConfig['missed_finish']"
         elementId="missed_finish">
         </bar-chart>
         <app-grid-v2 
         [id]="'missed_finish'"
         [columns]="gridColumns['missed_finish']"
         [data]="gridRows['missed_finish']">
         </app-grid-v2>
      </div>
   </div>
   </app-customized-widget>
   <app-customized-widget 
   *ngIf="ready['total_daily_idle_times']"
   title="Total Daily Idle Times Alerts" 
   widgetId="total_daily_idle_times" 
   [options]="options"
   [dropDownGroupList]="widgetsDropDownLists['total_daily_idle_times']"
   (onDropDownChange)="dateChange($event[0],$event[1])"
   [checkboxList]="widgetsCheckboxList['total_daily_idle_times']"
   (onCheckboxChange)="checkboxChange($event[0],$event[1])"
   (onPrintClick)="onPrintClick($event)">
   <div *appTransclude>
      <div
         class="margin-top-lg margin-bottom margin-left margin-right">
         <bar-chart
         (onChartReady)="generateImageURI($event)"
         [data]="chartsDataTables['total_daily_idle_times']"
         [config]="chartConfig['total_daily_idle_times']"
         elementId="total_daily_idle_times">
         </bar-chart>
         <app-grid-v2 
         [id]="'total_daily_idle_times'"
         [columns]="gridColumns['total_daily_idle_times']"
         [data]="gridRows['total_daily_idle_times']">
         </app-grid-v2>
      </div>
   </div>
   </app-customized-widget>
   <app-customized-widget 
   *ngIf="ready['deep_discharge']"
   title="Deep Discharge Alerts" 
   widgetId="deep_discharge" 
   [options]="options"
   [dropDownGroupList]="widgetsDropDownLists['deep_discharge']"
   (onDropDownChange)="dateChange($event[0],$event[1])"
   [checkboxList]="widgetsCheckboxList['deep_discharge']"
   (onCheckboxChange)="checkboxChange($event[0],$event[1])"
   (onPrintClick)="onPrintClick($event)">
   <div *appTransclude>
      <div
         class="margin-top-lg margin-bottom margin-left margin-right">
         <bar-chart
         (onChartReady)="generateImageURI($event)"
         [data]="chartsDataTables['deep_discharge']"
         [config]="chartConfig['deep_discharge']"
         elementId="deep_discharge">
         </bar-chart>
         <app-grid-v2 
         [id]="'deep_discharge'"
         [columns]="gridColumns['deep_discharge']"
         [data]="gridRows['deep_discharge']">
         </app-grid-v2>
      </div>
   </div>
   </app-customized-widget>
   <app-customized-widget 
   *ngIf="ready['total_daily_missed_connection']"
   title="Total Daily Missed Connection Times Alerts" 
   widgetId="total_daily_missed_connection" 
   [options]="options"
   [dropDownGroupList]="widgetsDropDownLists['total_daily_missed_connection']"
   (onDropDownChange)="dateChange($event[0],$event[1])"
   [checkboxList]="widgetsCheckboxList['total_daily_missed_connection']"
   (onCheckboxChange)="checkboxChange($event[0],$event[1])"
   (onPrintClick)="onPrintClick($event)">
   <div *appTransclude>
      <div
         class="margin-top-lg margin-bottom margin-left margin-right">
         <bar-chart
         (onChartReady)="generateImageURI($event)"
         [data]="chartsDataTables['total_daily_missed_connection']"
         [config]="chartConfig['total_daily_missed_connection']"
         elementId="total_daily_missed_connection">
         </bar-chart>
         <app-grid-v2 
         [id]="'total_daily_missed_connection'"
         [columns]="gridColumns['total_daily_missed_connection']"
         [data]="gridRows['total_daily_missed_connection']">
         </app-grid-v2>
      </div>
   </div>
   </app-customized-widget>
   <app-customized-widget 
   *ngIf="ready['potentially_sulfated']"
   title="Potentially Sulfated Battery Alert" 
   widgetId="potentially_sulfated" 
   [options]="options"
   [dropDownGroupList]="widgetsDropDownLists['potentially_sulfated']"
   (onDropDownChange)="dateChange($event[0],$event[1])"
   [checkboxList]="widgetsCheckboxList['potentially_sulfated']"
   (onCheckboxChange)="checkboxChange($event[0],$event[1])"
   (onPrintClick)="onPrintClick($event)">
   <div *appTransclude>
      <div
         class="margin-top-lg margin-bottom margin-left margin-right">
         <bar-chart
         (onChartReady)="generateImageURI($event)"
         [data]="chartsDataTables['potentially_sulfated']"
         [config]="chartConfig['potentially_sulfated']"
         elementId="potentially_sulfated">
         </bar-chart>
         <app-grid-v2 
         [id]="'potentially_sulfated'"
         [columns]="gridColumns['potentially_sulfated']"
         [data]="gridRows['potentially_sulfated']">
         </app-grid-v2>
      </div>
   </div>
   </app-customized-widget>
    <app-customized-widget 
   *ngIf="ready['missed_eqs']"
   title="Missed Scheduled EQ Alerts" 
   widgetId="missed_eqs" 
   [options]="options"
   [dropDownGroupList]="widgetsDropDownLists['missed_eqs']"
   [radioButtonGroupList]="widgetsRadioButtons['missed_eqs']"
   (onRadioButtonChange)="radioButtonChange($event[0], $event[1])"
   (onDropDownChange)="dateChange($event[0],$event[1])"
   [checkboxList]="widgetsCheckboxList['missed_eqs']"
   (onCheckboxChange)="checkboxChange($event[0],$event[1])"
   (onPrintClick)="onPrintClick($event)">
   <div *appTransclude>
      <div
         class="margin-top-lg margin-bottom margin-left margin-right">
         <bar-chart
         (onChartReady)="generateImageURI($event)"
         [data]="chartsDataTables['missed_eqs']"
         [config]="chartConfig['missed_eqs']"
         elementId="missed_eqs">
         </bar-chart>
         <app-grid-v2 
         [id]="'missed_eqs'"
         [columns]="gridColumns['missed_eqs']"
         [data]="gridRows['missed_eqs']">
         </app-grid-v2>
      </div>
   </div>
   </app-customized-widget>
   <app-all-sites-battery-fleet-utilization [data]="siteAlerts"></app-all-sites-battery-fleet-utilization>
</div>