<div class="actfirst-bg" [ngClass]="{'glow': buzzing}"> 	
	<div>	
		<div class="row margin-left margin-right margin-top">
			<div class="col-md-2 col-xs-12 margin-top-xs pull-right">
				<button 
				class="btn buzzer-btn" 
				[ngClass]="buzzerEnabled ? 'btn-primary ': 'btn-default'"
				(click)="toggleBuzzerEnabled()">
					<i 
						class="fa"
						[ngClass]="buzzerEnabled ? 'fa-volume-up': 'fa-volume-off'"></i>
				</button>
			</div>
			<div class="col-md-10 col-xs-12 margin-top-xs" [ngClass]="errorMsg ? 'alert alert-danger': ''">{{errorMsg}}</div>
		</div>
        <iframe src="/audio/silence.mp3" allow="autoplay" id="audio" style="display:none"></iframe>

		<div class="row" class="private-container">
            <div 
				*ngFor="let item of data" 
				class="no-gutter group-box col-lg-6">
				<div 
					class="groupname">{{item['groupname']}}
                </div>
				<div 
					class="chargers-box">
                    <div class="col-xs-3 no-gutter">
                        <div 
                            class="innergroup"
                            [ngClass]="{'active-innergroup': groupActiveTab[item['id']] == 'ready'}"
                            (click)="setActiveGroupTab(item['id'], 'ready')">
                            <div>
                                <div
                                 style="font-size: 25px;font-weight: 900; color: #7AC943">{{getGroupInnerGroupCount(item, 'ready')}}</div>
                                <div>{{'act.ready_to_pick' | translate}}</div>
                            </div>
                        </div>
                        <div
                            class="innergroup"
                            [ngClass]="{'active-innergroup': groupActiveTab[item['id']] == 'bifo'}"
                            (click)="setActiveGroupTab(item['id'], 'bifo')">
                            <div>
                                <div style="font-size: 25px;font-weight: 900; color: #5CBCFF">{{getGroupInnerGroupCount(item, 'bifo')}}</div>
                                <div>{{'act.bofo_ranking' | translate}}</div>
                            </div>
                        </div>
                        <div
                            class="innergroup"
                            [ngClass]="{'active-innergroup': groupActiveTab[item['id']] == 'charging'}"
                            (click)="setActiveGroupTab(item['id'], 'charging')">
                            <div>
                                <div style="font-size: 25px;font-weight: 900; color: #FFA84D">{{getGroupInnerGroupCount(item, 'charging')}}</div>
                                <div>{{'act.charging' | translate}}</div>
                            </div>
                        </div>
                        <div
                            class="innergroup"
                            [ngClass]="{'active-innergroup': groupActiveTab[item['id']] == 'parked'}"
                            (click)="setActiveGroupTab(item['id'], 'parked')">
                            <div>
                                <div style="font-size: 25px;font-weight: 900; color: #EB4D26">{{getGroupInnerGroupCount(item, 'parked')}}</div>
                                {{'act.parked_batteries' | translate}}
                            </div>
                        </div>
                    </div>
					<div class="col-xs-9 no-gutter">
						<p-scrollPanel 
                            *ngIf="getActiveTabChargersListing(item, true); else noScroll" 
                            [style]="{width: '100%', height: '254px', background: 'none', border: 'none', color: '#FFF'}"
                            styleClass="custombar2">
                            <app-private-page-chargers-listing [chargersList]="getActiveTabChargersListing(item)" [groupActiveTab]="groupActiveTab" [itemId]="item['id']"></app-private-page-chargers-listing>
                            <div style="padding-bottom: 30px;"></div>
						</p-scrollPanel>
                        <ng-template #noScroll>
                            <app-private-page-chargers-listing [chargersList]="getActiveTabChargersListing(item)" [groupActiveTab]="groupActiveTab" [itemId]="item['id']"></app-private-page-chargers-listing>
                        </ng-template>
					</div>
				</div>
            </div>
		</div>
	</div>
</div>
