<div class="row">
	<div class="col-md-12 panel panel-default">
		<div class="panel-body">

			<div class="col-md-12">
				<div class="col-md-6 padding-8">
					<div class="col-md-3 col-md-offset-2 text-right"><strong>Log Month:</strong></div>
					<div class="col-md-7">
						<select [(ngModel)]="filter.logMonth" class="form-control">
							<option *ngFor="let option of logMonths" [value]="option">{{option}}</option>
						</select>
					</div>
				</div>
			</div>

			<div class="col-md-12">
				<div class="col-md-6 padding-8">
					<div class="col-md-3 col-md-offset-2 text-right"><strong>Action Name:</strong></div>
					<div class="col-md-7">
						<ng-select [items]="actionNames" name="actionName" [(ngModel)]="filter.actionName" [clearable]="false">
						</ng-select>
					</div>
				</div>
			</div>

			<div class="col-md-12">
				<div class="col-md-6 padding-8">
					<div class="col-md-3 col-md-offset-2 text-right"><strong>Related ID:</strong></div>
					<div class="col-md-7">
						<input type="number" class="form-control" [(ngModel)]="filter.relatedID"/>
					</div>
				</div>
			</div>

			<div class="col-md-12">
				<div class="col-md-6 padding-8">
					<div class="col-md-3 col-md-offset-2 text-right"><strong>Action User ID:</strong></div>
					<div class="col-md-7">
						<input type="number" class="form-control" [(ngModel)]="filter.actionUserID"/>
					</div>
				</div>
			</div>

			<div class="col-md-12">
				<div class="col-md-6 padding-8">
					<div class="col-md-3 col-md-offset-2 text-right"><strong>Generic ID search:</strong></div>
					<div class="col-md-7">
						<input type="number" class="form-control" [(ngModel)]="filter.genericIdSearch"/>
					</div>
				</div>
			</div>

			<div class="col-md-12">
				<div class="col-md-6 padding-8">
					<div class="col-md-3 col-md-offset-2 text-right"></div>
					<div class="col-md-3">
						<button class="form-control btn btn-success" (click)="resetPageVars(); getActionLogs()">Get Logs</button>
					</div>
				</div>
			</div>

			<table class="boxs3 table table-striped">
				<thead>
					<tr>
						<th class="col-md-1">Action Name</th>
						<th class="col-md-1">Related ID</th>
						<th class="col-md-1">Additional Related ID</th>
						<th class="col-md-2">Old Values</th>
						<th class="col-md-2">New Values</th>
						<th class="col-md-1">Action User ID</th>
						<th class="col-md-2">Action Time</th>
						<th class="col-md-1">IP Address</th>
						<th class="col-md-1">User Agent</th>
					</tr>
				</thead>
				<tbody>
				<tr *ngFor="let log of actionsLog">
					<td title="Action Name">{{log.actionname}}</td>
					<td title="Related ID">{{log.relatedname || log.relatedid}}</td>
					<td title="Additional Related ID">{{log.additionalrelatedname || log.additionalrelatedid}}</td>
					<td title="Old Values">{{log.oldvalues ? (log.oldvalues | json) : ''}}</td>
					<td title="New Values">{{log.newvalues ? (log.newvalues | json) : ''}}</td>
					<td title="Action User ID">{{log.actionusername || log.actionuserid}}</td>
					<td title="Action Time">{{log.actiontime | date:'MM/dd/yyyy HH:mm:ss'}}</td>
					<td title="IP Address">{{log.ipaddress}}</td>
					<td title="User Agent">{{log.useragent}}</td>
				</tr>
				</tbody>
			</table>
			<div class="r pointer" style="font-size: 25px">
				<span *ngIf="pageNum > 1" class="glyphicon glyphicon-circle-arrow-left" (click)="getActionLogs({'firstActionID': lastActionID, 'prev': true, 'pageNum': pageNum})" title="Back"></span>&nbsp;<span *ngIf="actionsLog.length == 25" class="glyphicon glyphicon-circle-arrow-right" (click)="getActionLogs({'lastActionID': lastActionID}) "title="Next"></span>
			</div>
		</div>
	</div>
</div>