<div [ngClass]="{'row': !insideSiteDashboard}">
	<div id="page-wrapper" >
		<div id="page-inner">
			<div class="boxs">
				<div *ngIf="!insideSiteDashboard">
					<div class="row padding-8">
						<div class="col-md-2">
							<span class="sdax">{{'g.select_dealer' | translate}}</span>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<div class="form-field">
								<ng-select [items]="dealers" bindLabel="name" bindValue="id" name="selectedDealer" [(ngModel)]="selectedDealer" (ngModelChange)="dealerChanged($event)" [clearable]="false" [placeholder]="'g.no_dealer' | translate">
								</ng-select>
							</div>
						</div>
					</div>
					<div class="row padding-8">
						<div class="col-md-2">
							<span class="sdax">{{'site.select_site' | translate}}</span>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<div class="form-field">
								<ng-select [items]="sites" bindLabel="sitename" name="selectedSite" [(ngModel)]="selectedSite" (ngModelChange)="siteChanged($event)" [clearable]="false" [disabled]="!selectedDealer">
								</ng-select>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<button class="btn btn-primary margin-top" title="{{'site.export_all_sites' | translate}}" (click)="exportServicePageData()">
								{{'site.export_all_sites' | translate}}
							</button>
							<div class="form-field">
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="selectedSite">
					<div class="row text-info margin-bottom" *ngIf="faults.siteInfo.site_subscription_type != 'None'">
						<div class="col-md-8 margin-left">
							<h4>{{faults.siteInfo.site_subscription_type}}</h4>
						</div>
						<div class="r margin-right">
							<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv()">
							</button>
						</div>
					</div>
					<div class="margin-top">
						<notes #siteNotes [siteId]="selectedSite.id" [modelType]="'site'" [modelId]="selectedSite.id" [sourcePage]="'service_page'"></notes>
					</div>
					<div class="row">
						<div class="col-md-4">
							<div class='input-group date'>
								<input class="form-control" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="serviceDate" bsDatepicker #d="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" (ngModelChange)="getSiteFaults()">
								<span class="input-group-btn">
									<button class="btn btn-default btn-date" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
								</span>
							</div>
						</div>
					</div>
					<div class="row margin-top">
						<div class="form-group margin-left text-muted">
							<label class="col-md-2 col-form-label col-form-label-lg margin-top-sm">{{'device.filter_devices' | translate}}</label>
							<div class="col-md-5">
								<ng-select
									[multiple]="true"
									[addTag]="false"
									[clearable]="false"
									[items]="faults.siteTags | values"
									(add)="addFilterTag('with', $event)"
									(remove)="removeFilterTag()"
									[hideSelected]="true"
									placeholder="{{'site.filter_by_tag' | translate}}"
									bindLabel="tagname"
									[(ngModel)]="withTagList"
								></ng-select>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="form-group margin-left text-muted">
							<label class="col-md-2 col-form-label"></label>
							<div class="col-md-5">
								<ng-select
									[multiple]="true"
									[addTag]="false"
									[clearable]="false"
									[items]="faults.siteTags | values"
									(add)="addFilterTag('without', $event)"
									(remove)="removeFilterTag()"
									[hideSelected]="true"
									placeholder="{{'site.filter_by_not_tag' | translate}}"
									bindLabel="tagname"
									[(ngModel)]="withoutTagList"
								></ng-select>
							</div>
						</div>
					</div>
					<div class="row margin-top" *ngIf="insideSiteDashboard">
						<fieldset class="col-md-12">
							<legend>{{'charger.export_chargers_cycles' | translate}}</legend>
							<div class="row margin-bottom-sm">
								<div class="col-md-2 text-right-md "><strong>{{'time.from_date'|translate}}:</strong></div>
								<div class="col-md-3">
									<div class="input-group">
										<input class="form-control" placeholder="mm/dd/yyyy" name="startDB" [(ngModel)]="fromDateFmt" bsDatepicker #startDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
										<span class="input-group-btn">
											<button class="btn btn-default btn-date" (click)="startDB.toggle()" type="button"><i class="fa fa-calendar"></i></button>
										</span>
									</div>
								</div>
							</div>
							<div class="row margin-bottom-sm">
								<div class="col-md-2 text-right-md "><strong>{{'time.to_date'|translate}}:</strong></div>
								<div class="col-md-3 ">
									<div class="input-group">
										<input class="form-control" placeholder="mm/dd/yyyy" name="endDB" [(ngModel)]="toDateFmt" bsDatepicker #endDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
										<span class="input-group-btn">
											<button class="btn btn-default btn-date" (click)="endDB.toggle()" type="button"><i class="fa fa-calendar"></i></button>
										</span>
									</div>
								</div>
							</div>
							<button class="btn btn-success tcenter" (click)="exportChargersCycles()">{{'g.get_records' | translate}}</button>
						</fieldset>
					</div>
					<div class="row margin-top" *ngIf="insideSiteDashboard">
						<fieldset class="col-md-12">
							<legend>{{'battery.export_battview_events' | translate}}</legend>
							<div class="row margin-bottom-sm">
								<div class="col-md-2 text-right-md "><strong>{{'time.from_date'|translate}}:</strong></div>
								<div class="col-md-3">
									<div class="input-group">
										<input class="form-control" placeholder="mm/dd/yyyy" name="startDBBV" [(ngModel)]="fromDateFmtBV" bsDatepicker #startDBBV="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
										<span class="input-group-btn">
											<button class="btn btn-default btn-date" (click)="startDBBV.toggle()" type="button"><i class="fa fa-calendar"></i></button>
										</span>
									</div>
								</div>
							</div>
							<div class="row margin-bottom-sm">
								<div class="col-md-2 text-right-md "><strong>{{'time.to_date'|translate}}:</strong></div>
								<div class="col-md-3 ">
									<div class="input-group">
										<input class="form-control" placeholder="mm/dd/yyyy" name="endDBBV" [(ngModel)]="toDateFmtBV" bsDatepicker #endDBBV="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
										<span class="input-group-btn">
											<button class="btn btn-default btn-date" (click)="endDBBV.toggle()" type="button"><i class="fa fa-calendar"></i></button>
										</span>
									</div>
								</div>
							</div>
							<button class="btn btn-success tcenter" (click)="exportBattviewEvents()">{{'g.get_records' | translate}}</button>
						</fieldset>
					</div>
				</div>
			</div>
			<div *ngIf="selectedSite" id="service-page">
				<simple-widget [title]="'device.charger_faults' | translate" *ngIf="faults.chargerFaults && faults.chargerFaults.length > 0" [collapsedByDefault]="true" [headerClass]="'bg-red'">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('charger_faults')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Charger ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'}
						]"
						[devices]="faults.chargerFaults"
						[deviceType]="'charger'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="chargerTags"
						[deviceUrlSection]="'cycles'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'site.charger_connectivity' | translate" *ngIf="faults.chargerConnectivityDevices && faults.chargerConnectivityDevices.length > 0" [collapsedByDefault]="true" [headerClass]="'bg-red'">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('charger_connectivity')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Charger ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},                            
							{label:getTranslation('Disconnection Length'), key:'dissconnectLimit', functionName: 'this.commonUtil.secondsToElapsedTime'}
						]"
						[devices]="faults.chargerConnectivityDevices"
						[deviceType]="'charger'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="chargerTags"
						[deviceUrlSection]="'cycles'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'act.chargers_with_errors' | translate" *ngIf="faults.chargersWithError && faults.chargersWithError.length > 0" [collapsedByDefault]="true" [headerClass]="'bg-red'">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('chargers_with_errors')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Charger ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('error_time'), key: 'error_time'},
							{label:getTranslation('error_text'), key: 'error_text'}
						]"
						[devices]="faults.chargersWithError"
						[deviceType]="'charger'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="chargerTags"
						[deviceUrlSection]="'cycles'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'device.pm_faults' | translate" *ngIf="faults.PMFaults && faults.PMFaults.length > 0" [collapsedByDefault]="true" [headerClass]="'bg-red'">
					<div class="r margin-right">
							<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('pm_faults')">
						</button>
					</div>
					<ng-container *ngFor="let device of faults.PMFaults">
						<servcie-page-table
							[productionIps]="productionIps"
							[columns]="[
								{label:getTranslation('Charger ID'), key: 'url'},
								{label:getTranslation('serial_number'), key: 'serialnumber'},
								{label:getTranslation('Tags'), key: 'devices_tags', hide: !siteChargers || !siteChargers[device.id]}
							]"
							[devices]="[device]"
							[deviceType]="'charger'"
							[siteId]="selectedSite.id"
							[customerId]="selectedSite.customerid"
							[siteTags]="faults.siteTags | values"
							[devicesTags]="chargerTags"
							[deviceUrlSection]="'faults'"
							(onUpdateTags)="removeFilterTagCheck($event)"
							[siteChargers]="siteChargers"
							[siteBattviews]="siteBattviews"
							[isACTuser]="isACTuser"
						></servcie-page-table>
						<table class="table">
							<thead>
								<tr>
									<th>{{'device.pm_id' | translate}}</th>
									<th>{{'device.fault_time' | translate}}</th>
									<th>{{'g.fault' | translate}}</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let fault of device.PMfaults">
									<td>{{fault.pmid}}</td>
									<td>{{fault.time}}</td>
									<td>{{fault.fault}}</td>
								</tr>
							</tbody>
						</table>
					</ng-container>
				</simple-widget>
				<simple-widget [title]="'site.site_connectivity' | translate" *ngIf="faults.siteConnectivityStatus && faults.siteConnectivityStatus.length > 0" [collapsedByDefault]="true">
					<a [routerLink]="['/'+selectedSite.customerid, selectedSite.id]">{{faults.siteConnectivityStatus}}</a>
				</simple-widget>
				<simple-widget [title]="'site.battviews_chargers_invoices_count_mismatch' | translate" *ngIf="faults.invoiceMismatchCounts && faults.invoiceMismatchCounts.isThereMismatch" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('invoices_count_mismatch')">
						</button>
					</div>
					<h4>
						{{'site.active_invoices_battviews_site_battviews' | translate}} <b [ngClass]="{'red-text': +faults.invoiceMismatchCounts.invoice_battviews != +faults.invoiceMismatchCounts.site_battviews}">{{faults.invoiceMismatchCounts.invoice_battviews}} / {{faults.invoiceMismatchCounts.site_battviews}}</b>
					</h4>
					<h4>
						{{'site.active_invoices_chargers_site_chargers' | translate}} <b [ngClass]="{'red-text': +faults.invoiceMismatchCounts.invoice_chargers != +faults.invoiceMismatchCounts.site_chargers}">{{faults.invoiceMismatchCounts.invoice_chargers}} / {{faults.invoiceMismatchCounts.site_chargers}}</b>
					</h4>
					<h3>Related Invoices</h3>
					<table class="table">
						<thead>
							<tr>
								<th>{{'site.invoice_id' | translate}}</th>
								<th>{{'site.num_of_battviews' | translate}}</th>
								<th>{{'site.num_of_chargers' | translate}}</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let invoice of faults.invoiceMismatchCounts.active_invoices">
								<td>
									<a *ngIf="userService.hasAccessFunction('admin_act_intelligent')" [routerLink]="['/sites/manage-act-intelligent/', selectedSite.customerid, selectedSite.id]">{{invoice.invoice_id}}</a>
									<span *ngIf="!userService.hasAccessFunction('admin_act_intelligent')">{{invoice.invoice_id}}</span>
								</td>
								<td>{{invoice.battview_count}}</td>
								<td>{{invoice.chargers_count}}</td>
							</tr>
						</tbody>
					</table>
				</simple-widget>
				<simple-widget [title]="'site.battview_connectivity' | translate" *ngIf="faults.battviewConnectivityDevices && faults.battviewConnectivityDevices.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('battview_connectivity')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Battery ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},
							{label:getTranslation('Truck ID'), key:'truckid'},
							{label:getTranslation('Disconnection Length'), key:'dissconnectLimit', functionName: 'this.commonUtil.secondsToElapsedTime'}
						]"
						[devices]="faults.battviewConnectivityDevices"
						[deviceType]="'battview'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="battviewTags"
						[deviceUrlSection]="'battery-summary'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'device.charger_plc_failure_detect' | translate" *ngIf="faults.chargerPLC && faults.chargerPLC.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('charger_plc_failure_detect')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Charger ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},                            
							{label:getTranslation('PLC Fault'), key:'fault'}
						]"
						[devices]="faults.chargerPLC"
						[deviceType]="'charger'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="chargerTags"
						[deviceUrlSection]="'cycles'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'device.charger_calibration' | translate" *ngIf="faults.chargersCalibration && faults.chargersCalibration.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('charger_calibration')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Charger ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},
							{label:getTranslation('Calibration Fault'), key:'fault'}
						]"
						[devices]="faults.chargersCalibration"
						[deviceType]="'charger'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="chargerTags"
						[deviceUrlSection]="'cycles'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'device.battview_plc_failure_detect' | translate" *ngIf="faults.battviewPLC && faults.battviewPLC.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('battview_plc_failure_detect')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Battery ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},
							{label:getTranslation('Truck ID'), key:'truckid'},
							{label:getTranslation('PLC Fault'), key:'fault'}
						]"
						[devices]="faults.battviewPLC"
						[deviceType]="'battview'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="battviewTags"
						[deviceUrlSection]="'battery-summary'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'device.battview_high_ebu' | translate" *ngIf="faults.highEBUBatteries && faults.highEBUBatteries.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('battview_high_ebu')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Battery ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},                            
							{label:getTranslation('Truck ID'), key:'truckid'}
						]"
						[devices]="faults.highEBUBatteries"
						[deviceType]="'battview'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="battviewTags"
						[deviceUrlSection]="'battery-summary'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'device.battview_faults' | translate" *ngIf="faults.battviewFaults && faults.battviewFaults.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('battview_faults')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Battery ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},
							{label:getTranslation('Truck ID'), key:'truckid'},
							{label:getTranslation('Fault'), functionName: 'this.getFaultsText'}
						]"
						[devices]="faults.battviewFaults"
						[deviceType]="'battview'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="battviewTags"
						[deviceUrlSection]="'events-log'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'device.chargers_not_used' | translate" *ngIf="faults.chargersNotUsed && faults.chargersNotUsed.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('chargers_not_used')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Charger ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'}
						]"
						[devices]="faults.chargersNotUsed"
						[deviceType]="'charger'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="chargerTags"
						[deviceUrlSection]="'cycles'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'device.battviews_not_used' | translate" *ngIf="faults.battviewsNotUsed && faults.battviewsNotUsed.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('battviews_not_used')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Battery ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},
							{label:getTranslation('Truck ID'), key:'truckid'}
						]"
						[devices]="faults.battviewsNotUsed"
						[deviceType]="'battview'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="battviewTags"
						[deviceUrlSection]="'battery-summary'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'device.battviews_have_different_time_zone' | translate" *ngIf="faults.battviewsTimeZone && faults.battviewsTimeZone.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('battviews_have_different_time_zone')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Battery ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},
							{label:getTranslation('Truck ID'), key:'truckid'},
							{label:getTranslation('Battview Time Zone'), key:'zoneid', functionName: 'this.commonUtil.getZoneName'},
							{label:getTranslation('Site Time Zone'), value: siteTimeZone}
						]"
						[devices]="faults.battviewsTimeZone"
						[deviceType]="'battview'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="battviewTags"
						[deviceUrlSection]="'battery-summary'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'device.chargers_have_different_time_zone' | translate" *ngIf="faults.chargersTimeZone && faults.chargersTimeZone.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('chargers_have_different_time_zone')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Charger ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},
							{label:getTranslation('Charger Time Zone'), key: 'zoneid', functionName: 'this.commonUtil.getZoneName'},
							{label:getTranslation('Site Time Zone'), value: siteTimeZone}
						]"
						[devices]="faults.chargersTimeZone"
						[deviceType]="'charger'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="chargerTags"
						[deviceUrlSection]="'cycles'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'device.battviews_have_not_synched_time' | translate" *ngIf="faults.battviewsLostTime && faults.battviewsLostTime.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('battviews_have_not_synched_time')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Battery ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},
							{label:getTranslation('Truck ID'), key:'truckid'},
							{label:getTranslation('Time Gap (seconds)'), key:'time_gap'}
						]"
						[devices]="faults.battviewsLostTime"
						[deviceType]="'battview'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="battviewTags"
						[deviceUrlSection]="'battery-summary'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
						[tableName]="'have_not_synched_time'"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'device.chargers_have_not_synched_time' | translate" *ngIf="faults.chargersLostTime && faults.chargersLostTime.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('chargers_have_not_synched_time')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Charger ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},
							{label:getTranslation('Time Gap (seconds)'), key: 'time_gap'}
						]"
						[devices]="faults.chargersLostTime"
						[deviceType]="'charger'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="chargerTags"
						[deviceUrlSection]="'cycles'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
						[tableName]="'have_not_synched_time'"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="('device.battviews_different_firmware_version' | translate) +': '+(faults.siteInfo.battviewsfirmwareversion)" *ngIf="faults.battviewsFirmware && faults.battviewsFirmware.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('battviews_different_firmware_version')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Battery ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},
							{label:getTranslation('Truck ID'), key:'truckid'},
							{label:getTranslation('Battview Firmware Version'), key:'firmwareversion'}
						]"
						[devices]="faults.battviewsFirmware"
						[deviceType]="'battview'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="battviewTags"
						[deviceUrlSection]="'battery-summary'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="('device.chargers_different_firmware_version' | translate) +': '+(faults.siteInfo.chargersfirmwareversion)" *ngIf="faults.chargersFirmware && faults.chargersFirmware.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('chargers_different_firmware_version')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Charger ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},
							{label:getTranslation('Charger Firmware Version'), key:'firmwareversion'}
						]"
						[devices]="faults.chargersFirmware"
						[deviceType]="'charger'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="chargerTags"
						[deviceUrlSection]="'cycles'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'act.actview_disabled_battviews' | translate" *ngIf="faults.disabledBV && faults.disabledBV.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('actview_disabled_battviews')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Battery ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},
							{label:getTranslation('Truck ID'), key:'truckid'}
						]"
						[devices]="faults.disabledBV"
						[deviceType]="'battview'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="battviewTags"
						[deviceUrlSection]="'battery-summary'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'act.actview_disabled_chargers' | translate" *ngIf="faults.disabledCH && faults.disabledCH.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('actview_disabled_chargers')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Charger ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'}
						]"
						[devices]="faults.disabledCH"
						[deviceType]="'charger'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="chargerTags"
						[deviceUrlSection]="'cycles'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'device.battviews_have_flash_issue' | translate" *ngIf="faults.BVflashIssue && faults.BVflashIssue.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('battviews_have_flash_issue')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Battery ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},
							{label:getTranslation('Truck ID'), key:'truckid'},
							{label:getTranslation('Last Event Time'), key:'last_event_time'}
						]"
						[devices]="faults.BVflashIssue"
						[deviceType]="'battview'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="battviewTags"
						[deviceUrlSection]="'battery-summary'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'device.battviews_bad_connection' | translate" *ngIf="faults.BVhighResetsCount && faults.BVhighResetsCount.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('battviews_bad_connection')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Battery ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('Tags'), key: 'devices_tags'},
							{label:getTranslation('Truck ID'), key:'truckid'}
						]"
						[devices]="faults.BVhighResetsCount"
						[deviceType]="'battview'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="battviewTags"
						[deviceUrlSection]="'battery-summary'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
				<simple-widget [title]="'act.battviews_with_errors' | translate" *ngIf="faults.battviewsWithError && faults.battviewsWithError.length > 0" [collapsedByDefault]="true">
					<div class="r margin-right">
						<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv('battviews_with_errors')">
						</button>
					</div>
					<servcie-page-table
						[productionIps]="productionIps"
						[columns]="[
							{label:getTranslation('Battery ID'), key: 'url'},
							{label:getTranslation('serial_number'), key: 'serialnumber'},
							{label:getTranslation('error_time'), key: 'error_time'},
							{label:getTranslation('error_text'), key: 'error_text'}
						]"
						[devices]="faults.battviewsWithError"
						[deviceType]="'battview'"
						[siteId]="selectedSite.id"
						[customerId]="selectedSite.customerid"
						[siteTags]="faults.siteTags | values"
						[devicesTags]="battviewTags"
						[deviceUrlSection]="'battery-summary'"
						(onUpdateTags)="removeFilterTagCheck($event)"
						[siteChargers]="siteChargers"
						[siteBattviews]="siteBattviews"
						[isACTuser]="isACTuser"
					></servcie-page-table>
				</simple-widget>
			</div>
		</div>
	</div>
</div>