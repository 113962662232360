import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { NoUIBlockHttpParams } from '../../../../shared/loader/noUIBlock-httpParam';
import { CommonDataService } from '../../../../shared/services/common-data.service';

@Injectable()
export class TruckviewsDashboardService {

	batteryTypes = [{id:0, text:"Lead Acid"}, {id:1, text:"Lithium-ion"}, {id:2, text:"GEL"}];
	chargerTypes = [{id:0, text:"Fast"}, {id:1, text:"Conventional"}, {id:2, text:"Opportunity"}];
	TemperatureFallbackOptions = [];

	constructor(
		private httpClient: HttpClient,
		private commonUtil: CommonUtil,
		private datePipe: DatePipe,
		private commonData: CommonDataService,
	) { }

	getTruckviews(options) {
		return this.httpClient.post('/bfd/getBFDDropDown', {options}, {
			observe: "body"
		});
	}

	getDailyDetails(data) {
		return this.httpClient.post('/bfd/GetDailyDetails', data, {
			observe: "body"
		});
	}

	getSequential(data) {
		return this.httpClient.post('/bfd/GetSequential', data, {
			observe: "body"
		});
	}

	getTruckviewsSettingsInfo(ids) {
		return this.httpClient.post('/bfd/getTruckviewsSettingsInfo', {ids}, {
			observe: "body"
		});
	}

	saveTruckviewSettings(stage, truckview) {
		return this.httpClient.post('/bfd/saveTruckviewSettings', {stage, truckview}, {
			observe: "body"
		});
	}

  updateStudy(studyObject) {
		return this.httpClient.post('/studies/updateStudy', studyObject, {
            observe: "body",
            responseType: 'text'
		});
	}

	truckviewUpdatedFieldsEffect(dirtyFields, oldBattview) {
				
		var doChanges = false;
		var batterytype, chargertype;

		if(dirtyFields.hasOwnProperty('batterytype')) {

			batterytype = dirtyFields.batterytype;

			if(batterytype == 0) {
				//"Lead Acid"

				if(dirtyFields.hasOwnProperty('chargertype') || oldBattview.chargertype !== null)
					doChanges = true;
			} else {
				//"Lithium-ion" or "GEL"
				doChanges = true;
			}
		} else {

			batterytype = oldBattview.batterytype;
		}

		if(dirtyFields.hasOwnProperty('chargertype')) {

			chargertype = parseInt(dirtyFields.chargertype);

			if(batterytype === 0)
				doChanges = true;
		} else {

			chargertype = oldBattview.chargertype;
		}

		if(!doChanges)
			return dirtyFields;

		dirtyFields.tricklecurrentrate	= 5;
		dirtyFields.tricklevoltage		= 2.0;
		dirtyFields.ficurrentrate		= 5;
		dirtyFields.eqcurrentrate		= 4;
		dirtyFields.cvmaxduration		= '04:00';
		dirtyFields.fiduration			= '03:00';
		dirtyFields.eqduration			= '04:00';
		dirtyFields.desulfation			= '12:00';
		dirtyFields.fidt				= 59;
		dirtyFields.fidv				= 5;

		dirtyFields.batteryhightemperature	= 54.4;
		dirtyFields.foldtemperature			= 51.6;
		dirtyFields.cooldowntemperature		= 46.1;

		dirtyFields.fistartwindow		= '00:00';
		dirtyFields.fitimer				= '24:00';
		dirtyFields.eqstartwindow		= '00:00';
		dirtyFields.fidaysmask			= [0, 1, 2, 3, 4, 5, 6]; //All the week
		dirtyFields.eqdaysmask			= [0]; //Sunday
		dirtyFields.fischedulingmode	= false;
				
		switch(batterytype) {
			case 0:
				//"Lead Acid"
				dirtyFields.fitargetvoltage		= 2.6;
				dirtyFields.eqvoltage			= 2.65;
				dirtyFields.cvendcurrentrate	= 12;
				dirtyFields.eqtimer				= '24:00';

				switch(chargertype) {
					case 0:
						//FAST
						dirtyFields.cvtargetvoltage	= 2.42;
						dirtyFields.ccrate			= 40;
						
						dirtyFields.fidaysmask			= [0, 6]; //Sunday & Saturday
						dirtyFields.fischedulingmode	= true;
					break;

					case 1:
						//CONVENTIONAL
						dirtyFields.cvtargetvoltage	= 2.37;
						dirtyFields.ccrate			= 17;
					break;

					case 2:
						//OPPORTUNITY
						dirtyFields.cvtargetvoltage	= 2.4;
						dirtyFields.ccrate			= 25;
						
						dirtyFields.fitimer				= '08:00';
						dirtyFields.fischedulingmode	= true;
					break;
				}
			break;

			case 1:
				//"Lithium-ion"
				dirtyFields.eqcurrentrate		= 5;
				dirtyFields.fitargetvoltage		= 2.34;
				dirtyFields.eqvoltage			= 2.34;
				dirtyFields.cvendcurrentrate	= 10;
				dirtyFields.cvtargetvoltage		= 2.3;
				dirtyFields.ccrate				= 50;
				
				dirtyFields.eqdaysmask	= []; //no days
			break;

			case 2:
				//"GEL"
				dirtyFields.ficurrentrate		= 1.5;
				dirtyFields.eqcurrentrate		= 1;
				dirtyFields.fitargetvoltage		= 2.55;
				dirtyFields.eqvoltage			= 2.55;
				dirtyFields.cvendcurrentrate	= 8;
				dirtyFields.cvmaxduration		= '03:00';
				dirtyFields.fiduration			= '04:00';
				dirtyFields.cvtargetvoltage		= 2.33;
				dirtyFields.ccrate				= 17;

				dirtyFields.eqdaysmask	= []; //no days

				dirtyFields.batteryhightemperature	= 48.9;
				dirtyFields.foldtemperature			= 46.1;
				dirtyFields.cooldowntemperature		= 40.6;
			break;
		}

		return dirtyFields;
	}

	generateDailyDetailReport(dailyDetails:any, device: any){
		// var rows = (dailyDetails.length + 1 > 24 ? dailyDetails.length + 1 : 25);
		// var lastRecordDate;
		let dailyDetailsReport = [];

		for(var i = 0; i < dailyDetails.length; i++){
			let reportRecord: any = {};
			let currentDailyDetailsDay = dailyDetails[i];
			
			reportRecord.date = currentDailyDetailsDay.date*1000;
			reportRecord.charges = currentDailyDetailsDay.total_charge_events;
			reportRecord.chargeAHR = currentDailyDetailsDay.charge_as;
			reportRecord.chargeKWHR = currentDailyDetailsDay.charge_ws;
			reportRecord.total_inuse_events = currentDailyDetailsDay.total_inuse_events;
			reportRecord.inuse_ahr = currentDailyDetailsDay.inuse_as / 3600;
			reportRecord.inuse_kwhr = currentDailyDetailsDay.inuse_ws / 3600000;
			reportRecord.totalIdleEvents = currentDailyDetailsDay.total_idle_events;

			reportRecord.chargeDurationValue = currentDailyDetailsDay.charge_duration * 1000;
			reportRecord.dischargeDurationValue = currentDailyDetailsDay.inuse_duration * 1000;
			reportRecord.idleDurationValue = currentDailyDetailsDay.idle_duration * 1000;

			reportRecord.chargeOppurtinityDurationValue = currentDailyDetailsDay.charge_oppurtinity_duration;

			reportRecord.dischargeAHR = (currentDailyDetailsDay.inuse_as / 3600).toFixed(1);
			reportRecord.availableAH = device.ahrcapacity * 0.8;
			reportRecord.chargeAH = (currentDailyDetailsDay.charge_as / 3600).toFixed(1);

			var dayType = [];
			var dayOfWeekNum = new Date(reportRecord.date).getDay();
			if(currentDailyDetailsDay.is_working_day)
				dayType.push("W");
			if(currentDailyDetailsDay.total_charge_events > 0)
				dayType.push("C");

			reportRecord.day_type = dayType.join(' ');

			reportRecord.missedEQ = "";
			if(currentDailyDetailsDay.missed_eq && !currentDailyDetailsDay.has_eq_start_scheduled)
				reportRecord.missedEQ = "Yes";

			reportRecord.missedFinish = "";
			if(currentDailyDetailsDay.missed_fi)
				reportRecord.missedFinish = "Yes";

			reportRecord.EBU = ((currentDailyDetailsDay.inuse_as/3600)/(0.8 * device.ahrcapacity)).toFixed(2);
			if (reportRecord.EBU > 3) {
				reportRecord.EBU = 3;
			}

			reportRecord.temperatureSensorEnabled = currentDailyDetailsDay.temperature_sensor_enabled;

			var AhrPerHr = 0;
			if (currentDailyDetailsDay.inuse_duration > 0) {
				var inuse_duration = currentDailyDetailsDay.inuse_duration * 1000;
				var inuse_ah = currentDailyDetailsDay.inuse_as / 3600;
				AhrPerHr = inuse_ah / (inuse_duration/(3600*1000));
			}
			reportRecord.ahrPerHr = AhrPerHr;
			reportRecord.chargeOppurtinityDuration = this.commonUtil.timeFormat(currentDailyDetailsDay.charge_oppurtinity_duration, {limitToOneDay: true});

			reportRecord.potentialWeekCellsExceeded	= currentDailyDetailsDay.potential_week_cells_exceeded;
			reportRecord.deepDischargeExceeded		= currentDailyDetailsDay.deep_discharge_exceeded;
			reportRecord.maxTemperatureExceeded	= currentDailyDetailsDay.max_temperature_exceeded;
			reportRecord.missedConnectionAlert	= currentDailyDetailsDay.missed_connection_alert;
			reportRecord.isWorkingDay			= currentDailyDetailsDay.is_working_day;
			reportRecord.inuseAs				= currentDailyDetailsDay.inuse_as;
			reportRecord.waterLevelLow			= currentDailyDetailsDay.water_level_low;
			reportRecord.countOfEqs				= currentDailyDetailsDay.count_of_eqs;

			var copyFields = [
				'missed_eq',
				'missed_fi',
				'has_inuse_within_eq',
				'has_inuse_within_eq_morning_window',
				'has_inuse_within_eq_evening_window',
				'has_inuse_within_fi',
				'has_inuse_within_fi_morning_window',
				'has_inuse_within_fi_evening_window',
				'has_charge_within_eq',
				'has_charge_within_eq_morning_window',
				'has_charge_within_eq_evening_window',
				'has_charge_within_fi',
				'has_charge_within_fi_morning_window',
				'has_charge_within_fi_evening_window',
				'eq_prior_event_is_inuse',
				'eq_prior_event_is_inuse_morning_window',
				'eq_prior_event_is_inuse_evening_window',
				'fi_prior_event_is_inuse',
				'fi_prior_event_is_inuse_morning_window',
				'fi_prior_event_is_inuse_evening_window',
				'has_eq_interrupted_charge',
				'has_eq_interrupted_charge_morning_window',
				'has_eq_interrupted_charge_evening_window',
				'has_fi_interrupted_charge',
				'has_fi_interrupted_charge_morning_window',
				'has_fi_interrupted_charge_evening_window',

			];
			for (var j in copyFields) {
				reportRecord[copyFields[j]] = currentDailyDetailsDay[copyFields[j]];
			}

			dailyDetailsReport.push(reportRecord);
		}
		return dailyDetailsReport;
	}

	getMissedData(dailyDetails, filterName, returnDataType, device) {
		var updateMissedType = (type, currentDay, previousDay) => {
			var returnObj:any = {};
			var day				= currentDay.date / 1000,
			oneDaySeconds	= 60*60*24,
			dayOfWeekNum	= new Date(currentDay.date).getDay();

			var hasTypePeriod				,
			missedField					,
			fieldPeriodValue			,
			fieldDaysMask				,
			hasInuseWithinType			,
			hasChargeWithinType			,
			fieldPriorEventIsInuse		,
			hasFieldInterruptedCharge	,
			isFieldPreviousDayWithinPeriod;

			switch(type) {
				case 'eq':
					hasTypePeriod				= hasEQPeriod;
					missedField					= 'missed_eq';
					fieldPeriodValue			= eqPeriodValue;
					isFieldPreviousDayWithinPeriod	= isEQPreviousDayWithinPeriod;
					fieldDaysMask				= 'eqdaysmask';
					hasInuseWithinType			= 'has_inuse_within_eq';
					hasChargeWithinType			= 'has_charge_within_eq';
					fieldPriorEventIsInuse		= 'eq_prior_event_is_inuse';
					hasFieldInterruptedCharge	= 'has_eq_interrupted_charge';
				break;
				case 'finish':
					hasTypePeriod				= hasFiPeriod;
					missedField					= 'missed_fi';
					fieldPeriodValue			= fiPeriodValue;
					isFieldPreviousDayWithinPeriod	= isFiPreviousDayWithinPeriod;
					fieldDaysMask				= 'fidaysmask';
					hasInuseWithinType			= 'has_inuse_within_fi';
					hasChargeWithinType			= 'has_charge_within_fi';
					fieldPriorEventIsInuse		= 'fi_prior_event_is_inuse';
					hasFieldInterruptedCharge	= 'has_fi_interrupted_charge';
		
					// if the FI schedule is daily (all days) then the non-working days don't have missed Finish
					if (device.fidaysmask.length === 7 && !currentDay.isWorkingDay) {
						return returnObj;
					}
				break;
			}
		
			var morningWindowText = '_morning_window',
				eveningWindowText = '_evening_window',
				hasInuseWithinTypeMorningWindow			= hasInuseWithinType+morningWindowText,
				hasInuseWithinTypeEveningWindow			= hasInuseWithinType+eveningWindowText,
				hasChargeWithinTypeMorningWindow		= hasChargeWithinType+morningWindowText,
				hasChargeWithinTypeEveningWindow		= hasChargeWithinType+eveningWindowText,
				fieldPriorEventIsInuseMorningWindow		= fieldPriorEventIsInuse+morningWindowText,
				fieldPriorEventIsInuseEveningWindow		= fieldPriorEventIsInuse+eveningWindowText,
				hasFieldInterruptedChargeMorningWindow	= hasFieldInterruptedCharge+morningWindowText,
				hasFieldInterruptedChargeEveningWindow	= hasFieldInterruptedCharge+eveningWindowText;
		
			if (hasTypePeriod) {
				var previousDayOfWeekNum	= new Date((day - oneDaySeconds) * 1000).getDay();
				isFieldPreviousDayWithinPeriod = (device[fieldDaysMask].indexOf(previousDayOfWeekNum) > -1);
				
				fieldPeriodValue.missedValue &= currentDay[missedField];
		
				if (currentDay[hasInuseWithinTypeMorningWindow] || previousDay[hasInuseWithinTypeEveningWindow] || currentDay[hasInuseWithinType]) {
					fieldPeriodValue.hasInuseWithinValue = true;
				}
		
				if (currentDay[hasChargeWithinTypeMorningWindow] || previousDay[hasChargeWithinTypeEveningWindow] || currentDay[hasChargeWithinType]) {
					fieldPeriodValue.hasChargeWithinValue = true;
				}
		
				if (currentDay[fieldPriorEventIsInuse] || (currentDay[fieldPriorEventIsInuseMorningWindow] && previousDay[fieldPriorEventIsInuseEveningWindow])) {
					fieldPeriodValue.priorEventIsInuse = true;
				}
		
				if (currentDay[hasFieldInterruptedChargeMorningWindow] || previousDay[hasFieldInterruptedChargeEveningWindow] || currentDay[hasFieldInterruptedCharge]) {
					fieldPeriodValue.hasInterruptedChargeValue = true;
				}
		
				if (!isFieldPreviousDayWithinPeriod) {
					if (fieldPeriodValue.missedValue) {
					returnObj.missed = true;
					if ((fieldPeriodValue.hasInuseWithinValue && !fieldPeriodValue.hasChargeWithinValue) ||
						(!fieldPeriodValue.hasInuseWithinValue && !fieldPeriodValue.hasChargeWithinValue && fieldPeriodValue.priorEventIsInuse) ||
						(fieldPeriodValue.hasInuseWithinValue && fieldPeriodValue.hasChargeWithinValue && fieldPeriodValue.hasInterruptedChargeValue)
					) {
						returnObj.isNotPluggedIn = true;
					} else {
						returnObj.isPluggedIn = true;
					}
					}
		
					// set the period object again to default
					fieldPeriodValue = {
					missedValue: true,
					hasInuseWithinValue: false,
					hasChargeWithinValue: false,
					priorEventIsInuse: true,
					hasInterruptedChargeValue: false
					};
				}
			} else {
				if (currentDay[missedField]) {
					returnObj.missed = true;
		
					var currentDayData = {
					hasInuseWithinValue			: false,
					hasChargeWithinValue		: false,
					priorEventIsInuse			: false,
					hasInterruptedChargeValue	: false
					};
					if (currentDay[hasInuseWithinTypeMorningWindow] || currentDay[hasInuseWithinTypeEveningWindow] || currentDay[hasInuseWithinType]) {
						currentDayData.hasInuseWithinValue = true;
					}
		
					if (currentDay[hasChargeWithinTypeMorningWindow] || currentDay[hasChargeWithinTypeEveningWindow] || currentDay[hasChargeWithinType]) {
						currentDayData.hasChargeWithinValue = true;
					}
		
					if (currentDay[fieldPriorEventIsInuse] || (currentDay[fieldPriorEventIsInuseMorningWindow] && currentDay[fieldPriorEventIsInuseEveningWindow])) {
						currentDayData.priorEventIsInuse = true;
					}
		
					if (currentDay[hasFieldInterruptedChargeMorningWindow] || currentDay[hasFieldInterruptedChargeEveningWindow] || currentDay[hasFieldInterruptedCharge]) {
						currentDayData.hasInterruptedChargeValue = true;
					}
		
					if ((currentDayData.hasInuseWithinValue && currentDayData.hasChargeWithinValue === false) ||
					(currentDayData.hasInuseWithinValue === false && currentDayData.hasChargeWithinValue === false && currentDayData.priorEventIsInuse) ||
					(currentDayData.hasInuseWithinValue && currentDayData.hasChargeWithinValue && currentDayData.hasInterruptedChargeValue)
					) {
						returnObj.isNotPluggedIn = true;
					} else {
						returnObj.isPluggedIn = true;
					}
				}
			}
		
			return returnObj;
		}
	
		var type		,
		  isPluggedIn	,
		  generalMissed = false;
	
		switch (filterName) {
		  case 'Missed EQ Plugged In':
				type = 'eq';
				isPluggedIn	= true;
		  break;
		  case 'Missed EQ Not Plugged In':
				type = 'eq';
				isPluggedIn	= false;
		  break;
		  case 'Missed Finish Plugged In':
				type = 'finish';
				isPluggedIn	= true;
		  break;
		  case 'Missed Finish Not Plugged In':
				type = 'finish';
				isPluggedIn	= false;
		  break;
		  case 'Missed EQ':
				type = 'eq';
				generalMissed = true;
		  break;
		  case 'Missed Finish':
				generalMissed = true;
				type = 'finish';
		  break;
		}
	
		var result			= [],
		  hasEQPeriod		= false,
		  hasFiPeriod		= false,
		  isEQPreviousDayWithinPeriod	= false,
		  eqPeriodValue	= {
				missedValue: true,
				hasInuseWithinValue: false,
				hasChargeWithinValue: false,
				priorEventIsInuse: true,
				hasInterruptedChargeValue: false
		  },
		  isFiPreviousDayWithinPeriod	= false,
		  fiPeriodValue	= {
				missedValue: true,
				hasInuseWithinValue: false,
				hasChargeWithinValue: false,
				priorEventIsInuse: true,
				hasInterruptedChargeValue: false
			};
	
		// @todo: check why device.eqtimer and fitimer are in other format "24:00"
		if (device.eqtimer >= 85500) {
		  hasEQPeriod = true;
		}
		if (device.fitimer >= 85500) {
		  hasFiPeriod = true;
		}
	
		var sortedDailyDetails = dailyDetails.sort((a,b) => {return (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0);});
	
		sortedDailyDetails.forEach((currentDailyDetails, i) => {
	
		  var shouldAppendData = false;
		  var previousDay = sortedDailyDetails[i+1] || {};
		  var missedResult = updateMissedType(type, currentDailyDetails, previousDay);
		  if (isPluggedIn && missedResult.isPluggedIn) {
				shouldAppendData = true;
		  }
		  if (!isPluggedIn && missedResult.isNotPluggedIn) {
				shouldAppendData = true;
		  }
		  if (generalMissed && missedResult.missed) {
				shouldAppendData = true;
		  }
		  if (shouldAppendData) {
				if (returnDataType == 'dailyDetails') {
					result.push(currentDailyDetails);
				} else {
					result.push(currentDailyDetails.date);
				}
		  }
		});
	
		return result;
	}
		
	cyclesDetection(params) {
		return this.httpClient.post('/customers/cyclesDetection', params, {
			observe: "body"
		});
	}

	formatTruckviewQueuedChanges(truckview, changesStack) {
	}
}