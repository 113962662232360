<div class="panel panel-default margin-bottom-xs">
	<div class="panel-heading">{{'device.device_notes' | translate}}</div>
	<div class="panel-body">
		<notes
			[siteId]="siteId"
			[modelType]="deviceType"
			[modelId]="device.id"
			[studyId]="device.studyid || 0"
			[sourcePage]="sourcePage"
		></notes>
	</div>
</div>