import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AdminService } from '../../../admin.service';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { HttpParams } from '@angular/common/http';
import { BreadcrumbService } from '../../../../shared/breadcrumb/breadcrumb.service';

@Component({
	selector: 'app-customer-edit-page',
	templateUrl: './customer-edit-page.component.html',
	styleUrls: ['./customer-edit-page.component.css']
})
export class CustomerEditPageComponent implements OnInit, OnDestroy {

	customer: Object = {
		id: 0,
		name: '',
		phoneno: '',
		contactname: ''
	};

	Options = {};

	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute,
		private router: Router,
		private navigation: NavigationService,
		private breadcrumb: BreadcrumbService
	) {
	}

	ngOnInit() {
		this.route.params.subscribe(
			params => {
				this.customer['id'] = Number(params['customerId']);
			
				if(this.customer['id'])
					this.getCustomers(this.customer['id']);
			}
		);

		this.route.queryParams.subscribe( params => {
			if(params['getBy'])
				this.Options['getBy'] = params['getBy'];
			
			if(params['dealerid'])
				this.Options['dealerid'] = +params['dealerid'];
			
			if(params['nameIndex'])
				this.Options['nameIndex'] = params['nameIndex'];
		});

		this.breadcrumb.setBreadcrumb([
			{'label': 'Customers', 'url': '/customers'},
			{'label': 'Edit', 'url': ''}
		]);
	}

	getCustomers(id) {
		this.adminService.getCustomers(id).subscribe(
			(data: Object) => {
				this.customer = data['list'][0];
			}
		);
	}

	saveCustomer() {
		this.adminService.saveCustomer(this.customer).subscribe((data: any) => {
			if(!this.customer['id'] && data.id)
				return this.router.navigate(['/sites/edit/customerId/'+data.id+'/siteId/0']);
			this.goBack();
		});
	}

	goBack() {
		let previousUrl = this.navigation.getPreviousUrl();
		if(previousUrl) {
			if(this.Options.hasOwnProperty("getBy")) {
				if(previousUrl.indexOf("?") != -1)
					previousUrl = previousUrl.substring(0, previousUrl.indexOf("?"));
				let params = new HttpParams();
				for(let key in this.Options){
					params.set(key, this.Options[key]) 
				}
				previousUrl += '?' + params.toString();
			}
			
			this.router.navigateByUrl(previousUrl);
		} else {
			this.router.navigate(['/sites-map']);
		}
	}

	isCustomerNameEmpty(): boolean {
		return !this.customer['name'] || /^\s*$/.test(this.customer['name']);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}