<div class="panel panel-default margin-bottom-xs">
	<div class="panel-body">
		<div class="row padding-8">
			<div *ngIf="!isShared && activePage != 'listing'">
				<div style="width: 18%; float: left">
					<label>
						<input (change)="getBattviews(true)" [(ngModel)]="getAllBattviews" type="checkbox" class="form-control"/>
						Show All Battview Mobile
					</label>
				</div>
				<br /><br />
				<div style="float: left">
					<label for="period">Device Period</label>
					<label><input type="radio" (change)="getBattviews(true)" [(ngModel)]="getDevicesPeriod" name="period" class="form-control" [value]="'1'" /> Last Year </label>
					<label><input type="radio" (change)="getBattviews(true)" [(ngModel)]="getDevicesPeriod" name="period" class="form-control" [value]="'2'" /> Last 2 Years</label>
					<label><input type="radio" (change)="getBattviews(true)" [(ngModel)]="getDevicesPeriod" name="period" class="form-control" [value]="'all'" /> All</label>
				</div>
			</div>
			<div class="col-md-2" *ngIf="currentUser.isACTuser && userHasAccessToShareStudy && device.studyid > 0 && device.studyname && activePage != 'settings' && showElementsFlags.pageHasFilterByDate">
				<div class="btn-group pointer" appDropdown>
					<span class="btn btn-default dropdown-toggle" dropdown-toggle><span class="glyphicon glyphicon-cog"></span></span>
					<ul class="dropdown-menu" role="menu">
						<li (click)="enableShare()">
							<a><span class="fa fa-share-alt" aria-hidden="true"></span>&nbsp;Share Study</a>
						</li>
						<li (click)="deleteStudy()">
							<a><span class="glyphicon glyphicon-trash" aria-hidden="true"></span>&nbsp;Delete Study</a>
						</li>
						<li (click)="reverseEnabled = true" *ngIf="currentUser.isACTuser && userService.hasAccessFunction('act_admin_device_management')">
							<a><span class="glyphicon glyphicon-refresh" aria-hidden="true"></span>&nbsp;Reverse BV Mobile Study</a>
						</li>
						<li class="pointer">
							<a>
								<notes
									modelType="battview mobile"
									sourcePage='battview_mobile'
									[modelId]="device.id"
									[studyId]="device.studyid"
									[isDropDown]="true"
									[isAddNoteOnly]="true"
								></notes>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-2" *ngIf="currentUser.isACTuser">
				<div class="form-field">
					<label class="sdax" for="dealer">Select Dealer</label>
					<ng-select id="dealer" [items]="dealersList" bindLabel="name" bindValue="id" placeholder="Select Dealer" title="Select Dealer" [(ngModel)]="selectedDealerID" [clearable]="false" (change)="dealerSelectionChanged(selectedDealerID)">
					</ng-select>
				</div>
			</div>
			<div class="col-md-2" *ngIf="activePage != 'listing'">
				<div class="form-field">
					<label class="sdax" for="bv-mobile">Battview Mobile</label>
					<ng-select id="bv-mobile" [items]="battviewsMobile" bindLabel="name" placeholder="Select Battview Mobile" title="Select Battview Mobile" [(ngModel)]="selectedBattviewMobile" [clearable]="false" (change)="battviewMobileSelectionChanged()">
					</ng-select>
				</div>
			</div>
			<div class="col-md-2" *ngIf="activePage != 'listing'">
				<div class="form-field">
					<label class="sdax" for="study">Select Study</label>
					<ng-select id="study" [items]="studies" bindLabel="studyname" placeholder="Select Study" title="Select Study" [(ngModel)]="selectedStudy" [clearable]="false" (change)="studyNameSelectionChanged(selectedStudy)">
					</ng-select>
				</div>
			</div>
			<div class="col-md-2 ptop-32" *ngIf="!selectedStudy.studyname && selectedBattviewMobile?.actviewenabled && activePage != 'listing' && !isShared">
				<button type="button" (click)="closeNewStudyBox(true)" class="btn btn-default" [disabled]="!selectedBattviewMobile?.serialnumber">
					Start New Study
				</button>
			</div>
			<div class="col-md-2" *ngIf="selectedStudy.studyname && activePage != 'listing'">
				<div class="form-field">
					<label class="sdax" for="truckid">Select Truck ID</label>
					<ng-select id="truckid" [items]="availableTrucks" bindLabel="truckName" placeholder="Select Truck ID" title="Select Truck ID" [(ngModel)]="device" [clearable]="false" (change)="truckSelectionChanged()">
					</ng-select>
				</div>
			</div>
			<div *ngIf="device.studyid > 0 && device.studyname && activePage != 'settings' && showElementsFlags.pageHasFilterByDate" class="col-md-2">
				<label class="sdax" for="daterange">Select Date Range</label>
				<div id="daterange" class="input-group">
					<input class="form-control" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="date.fromDate" bsDatepicker #fromDate="bsDatepicker" (ngModelChange)="datesChanged()" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
					<span class="input-group-btn">
						<button class="btn btn-default btn-date" (click)="fromDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
					</span>
				</div>
			</div>
			<div *ngIf="device.studyid > 0 && device.studyname && activePage != 'settings' && showElementsFlags.pageHasFilterByDate" class="col-md-2 ptop-32">
				<div class="input-group" id="datetimepicker2">
					<input class="form-control" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="date.toDate" bsDatepicker #toDate="bsDatepicker" (ngModelChange)="datesChanged()" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
					<span class="input-group-btn">
						<button class="btn btn-default btn-date" (click)="toDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
					</span>
				</div>
			</div>
			<div class="col-md-2" *ngIf="!currentUser.isACTuser && userHasAccessToShareStudy && device.studyid > 0 && device.studyname && activePage != 'settings' && showElementsFlags.pageHasFilterByDate">
				<div class="btn-group pointer" appDropdown>
					<span class="btn btn-default dropdown-toggle"><span class="glyphicon glyphicon-cog" dropdown-toggle></span></span>
					<ul class="dropdown-menu" role="menu">
						<li (click)="enableShare()">
							<a><span class="fa fa-share-alt" aria-hidden="true"></span>&nbsp;Share Study</a>
						</li>
						<li (click)="deleteStudy()">
							<a><span class="glyphicon glyphicon-trash" aria-hidden="true"></span>&nbsp;Delete Study</a>
						</li>
						<li (click)="reverseEnabled = true" *ngIf="currentUser.isACTuser && userService.hasAccessFunction('act_admin_device_management')">
							<a><span class="glyphicon glyphicon-refresh" aria-hidden="true"></span>&nbsp;Reverse BV Mobile Study</a>
						</li>
						<li class="pointer">
							<a>
								<notes
									modelType="battview mobile"
									sourcePage='battview_mobile'
									[modelId]="device.id"
									[studyId]="device.studyid"
									[isDropDown]="true"
									[isAddNoteOnly]="true"
								></notes>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-md-4" *ngIf="activePage == 'settings' && selectedBattviewMobile?.actviewenabled">
				<strong>Last Connect Time:</strong> <span>{{selectedBattviewMobile?.lastconnecttime | date:'MM/dd/yyyy hh:mm:ss a'}}</span>
				<span *ngIf="userService.hasAccessFunction('view_rejected_device_connections')"><br/><strong>IP Address: </strong>{{selectedBattviewMobile?.ip_address ? selectedBattviewMobile?.ip_address : 'No IP'}}</span>
			</div>
			<div class="col-md-2" *ngIf="activePage == 'settings' && selectedBattviewMobile?.actviewenabled && userService.hasAccessFunction('restart_devices')">
				<button class="btn btn-default" (click)="restartBattview()">Restart BATTView</button>
			</div>
		</div>
		<div *ngIf="newStudyEnabled" class="boxs margin-top-5">
			<div class="padding-8">
				<label for="studyname">Study Name</label>
				<input type="text" class="form-control" [(ngModel)]="newStudy.studyname" placeholder="Study Name" name="studyname"/>
			</div>
			<div class="padding-8">
				<label for="truckid">Truck ID</label>
				<input type="text" class="form-control" [(ngModel)]="newStudy.truckid" placeholder="Truck ID" name="truckid"/>
			</div>
			<div>
				<button (click)="startNewStudy()" class="btn btn-default" [disabled]="!newStudy.truckid || !newStudy.studyname">Start Study</button>
				<button (click)="closeNewStudyBox()" class="btn btn-default">Close</button>
			</div>
		</div>
	</div>
</div>
<div *ngIf="userHasAccessToShareStudy && shareEnabled" class="boxs">
	<div class="padding-8" *ngIf="currentUser.isACTuser || currentUser.isOEM">
		<label for="Dealer">Select a Dealer</label>
		<ng-select [items]="dealers" bindLabel="name" bindValue="id" placeholder="Select a Dealer" title="Select a Dealer" [(ngModel)]="selectedStudy.toshareddealerid" [clearable]="true">
		</ng-select>
	</div>
	<div class="padding-8" *ngIf="currentUser.isACTuser">
		<label for="OEM">Select a Battery OEM</label>
		<ng-select [items]="OEMs" bindLabel="name" bindValue="id" placeholder="Select a Battery OEM" title="Select a Battery OEM" [(ngModel)]="selectedStudy.tosharedoemid" [clearable]="true">
		</ng-select>
	</div>
	<div class="padding-8">
		<label for="char">Choose Client Index</label>
		<ng-select [items]="clientsAlphabets | values" bindLabel="name" bindValue="id" placeholder="Select a Client Index" title="Select a Client Index" [(ngModel)]="clientNameIndex" [clearable]="false" (change)="getClientsByNameIndex(clientNameIndex)">
		</ng-select>
	</div>
	<div class="padding-8" *ngIf="clientNameIndex && clientNameIndex.length > 0">
		<label for="client">Select a client</label>
		<ng-select [items]="clients" bindLabel="name" bindValue="id" placeholder="Select a Client" title="Select a Client" [(ngModel)]="selectedStudy.tosharedcustomerid" [clearable]="true">
		</ng-select>
	</div>

	<button (click)="shareWithUsers()" class="btn btn-default">Save</button>
	<button (click)="closeShareBox()" class="btn btn-default">Cancel</button>
</div>

<div *ngIf="currentUser.isACTuser && reverseEnabled && userService.hasAccessFunction('act_admin_device_management')" class="boxs">
	<div class="padding-8">
		<label for="char">Choose Events Range</label>
		<input type="text" name="startId" placeholder="Start ID" [(ngModel)]="reverseIds.start">
		<input type="text" name="endId" placeholder="End ID" [(ngModel)]="reverseIds.end">
	</div>
	<button (click)="reverseStudy()" class="btn btn-default">Reverse</button>
	<button (click)="closeReverseBox()" class="btn btn-default">Cancel</button>
</div>