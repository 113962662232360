import { Component, OnInit } from '@angular/core';
import { FooterService } from './footer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  yearAge = new Date().getFullYear();

  constructor(public footer: FooterService) { }

  ngOnInit() { }

}