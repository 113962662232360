import { Component, Input, OnInit, Output } from '@angular/core';
import { SiteInfoService } from '../site-info.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../../auth/user.service';
import { User } from '../../../../auth/user.model';

@Component({
	selector: 'app-site-rma-widget',
	templateUrl: './site-rma-widget.component.html'
})
export class SiteRmaWidgetComponent implements OnInit {

	isCollapsed: boolean = true;
	siteId: number = 0;
	data = [];
	currentUser: User;
	rmaStatesMapper = {
		1: 'RMA Submitted & Pending Shipping from ACT',
		2: 'RMA Submitted & Parts Shipped from ACT',
		3: 'RMA Submitted & Parts Shipped from Dealer',
		4: 'Parts on Location, Pending Evaluation',
		5: 'Parts on Location, Ready for Inventory Return',
		6: 'Returns Complete, Pending Approval',
		7: 'RMA Approved & Closed'
	};

	constructor(
		private siteInfoService: SiteInfoService,
		private route: ActivatedRoute,
		private userService: UserService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.userService.currentUser.subscribe((user: User) => {
			this.currentUser = user;
		});
		this.siteId = this.route.snapshot.params['siteid'];
		this.siteInfoService.getRmaDevices(this.siteId).subscribe((res: any) => {
			if (res.error)
				return;
			
			for(const rmaNo in res) {
				this.data.push({
					rma_no: rmaNo,
					data: res[rmaNo],
					isCollapsed: true
				});
			}
		});
	}

	toggleCollapse(item) {
		item.isCollapsed = !item.isCollapsed;
	}

}
