<form class="form-auth" #accessPermissionsForm="ngForm" (ngSubmit)="updateUserPermissions()">
	<div class="container-fluid" id="main">
		<div class="boxs3 col-md-6 col-md-offset-3" id="left" *ngIf="access">
			<h2 class="tcenter">Edit User Access Permissions</h2>
			<br/>
			<ul class="nav nav-pills nav-justified padding-8">
				<li [ngClass]="{active:tab == type}" (click)="tab = type; selectedType = permissionFields[type]" *ngFor="let type of permissionFields | keys">
					<a><strong>{{type}}</strong></a>
				</li>
			</ul>
				<h2 class="tcenter">{{tab}}</h2>
				<fieldset *ngFor="let group of selectedType | keys">
					<legend>{{group}}</legend>
					<table>
						<tr *ngFor="let fieldKey of selectedType[group] | keys">
							<td class="col-sm-5" [ngClass]="{'red-text':access[fieldKey] != defaultPermissions[fieldKey]}">{{selectedType[group][fieldKey]}}</td>
							<td>
								<select [(ngModel)]="access[fieldKey]" class="form-control" name="access-{{fieldKey}}">
                  					<option *ngFor="let opt of accessOptions" [value]="opt">{{opt}}</option>
								</select>
							</td>
						</tr>
					</table>
					<br/>
				</fieldset>
			<br/>
			<div class="form-buttons bvf">
				<div>
					<label class="l"><input type='checkbox' class="form-control" [(ngModel)]="tempAccess.enable" name="tempAccess-enable" /> Temporary Access:&nbsp;</label>
				</div>
				<div class="text-left margin-bottom-lg">
					<input type="number" class="form-control inline-form-control" [disabled]="!tempAccess.enable" placeholder="Time duration" [(ngModel)]="tempAccess.time" name="tempAccess-time" />
					<span>Time in:</span>
					<select class="form-control inline-form-control" [disabled]="!tempAccess.enable" [(ngModel)]="tempAccess.unit" name="tempAccess-unit">
						<option value="hour">Hour(s)</option>
						<option value="day">Day(s)</option>
					</select>
				</div>
				<div class="text-left">
					<span class="text-warning" *ngIf="tempAccess.enable">* Allowed duration: from 1 hour up to 180 days.</span>
				</div>
				<button *ngIf="userService.hasAccessFunction('act_user_management')" class="btn btn-info" [disabled]="(tempAccess.enable && (!tempAccess.unit || !tempAccess.time)) || accessPermissionsForm.invalid">Save</button>
				<button class="btn btn-default" (click)="goBack()" type="button">Cancel</button>
			</div>
		</div>
	</div>
</form>