import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdminService } from '../../admin/admin.service';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as _ from 'underscore';

@Component({
	selector: 'app-battviews-functional-test',
	templateUrl: './battviews-functional-test.component.html',
	styleUrls: ['./battviews-functional-test.component.css']
})
export class BattviewsFunctionalTestComponent implements OnInit, OnDestroy {

	battviewsList: any;

	constructor(
		private adminService: AdminService,
		private breadcrumb: BreadcrumbService,
		private translate: TranslateService,
	) {
		this.breadcrumb.setBreadcrumb([{label: this.translate.instant('site.battview_functional_test'), url: ''}]);
	}

	ngOnInit(): void {
		this.getBattviewFunctionalTests();
	}

	getBattviewFunctionalTests() {
		this.adminService.getBattviewFunctionalTests().subscribe((data:any) => {
			this.formatBattviews(data);
		});
	}

	formatBattviews(data) {
		let battviewsList = {};
		data.forEach((battview) => {
			let insertionMoment = moment(battview.insertion_time);
			let year = insertionMoment.format('YYYY');
			let month = insertionMoment.format('MM');
			let monthName = this.translate.instant('months.'+insertionMoment.format('MMMM').toLowerCase());
			let testRes = battview.final_functional_test;
			let yearMonthKey = year+month;
			
			if(!battviewsList[yearMonthKey])
				battviewsList[yearMonthKey] = {
					'key': yearMonthKey,
					'label': monthName+', '+year,
					'total': 0,
					'can_test': 0,
					'tested': 0,
					'not_tested': 0,
					'failed': 0,
					'passed': 0
				};
			
			battviewsList[yearMonthKey].total++;
			
			if(testRes != 0) {
				battviewsList[yearMonthKey].can_test++;
				if(testRes == 0xA5) {
					battviewsList[yearMonthKey].not_tested++;
				} else {
					battviewsList[yearMonthKey].tested++;
					if(testRes == 0xA6)
						battviewsList[yearMonthKey].failed++;
					else if(testRes == 0xBB)
						battviewsList[yearMonthKey].passed++;
				}
			}
		});
		this.battviewsList = _.sortBy(Object.values(battviewsList), 'key').reverse();
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}