<div class="row">
	<div id="page-wrapper">
		<div id="content-header">
			<div class="margin-left">
				<div id="dvCompanyFullName">
			  		<h3><span>{{customer.name}}</span></h3>
				</div>
			</div>
		</div>
		<div class="container customers-report">
			<ul class="nav nav-tabs">
				<li [ngClass]="{'active': report == 'default'}">
					<a data-toggle="tab" [routerLink]="['/', customer.id, 'customer-sites-reports', 'default']">
						Customer Intelligent Sites Reports
					</a>
				</li>
				<li [ngClass]="{'active': report == 'snapshots'}">
					<a data-toggle="tab" [routerLink]="['/', customer.id, 'customer-sites-reports', 'snapshots']">
						Customer Intelligent Sites Trends
					</a>
				</li>
			</ul>
		</div>
		<div id="tab-content" class="row customers-report">
			<div *ngIf="report == 'default'">
				<app-intelligent-sites-reports></app-intelligent-sites-reports>
			</div>
			<div *ngIf="report == 'snapshots'">
				<app-intelligent-sites-snapshots></app-intelligent-sites-snapshots>
			</div>
		</div>
	</div>
</div>