import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdminService } from '../../admin.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';

@Component({
	selector: 'app-sched-control',
	templateUrl: './sched-control.component.html',
	styleUrls: ['./sched-control.component.css']
})

export class SchedControlComponent implements OnInit, OnDestroy {

	schedFunctions = <any>[];
	numberOfServers = 1;

	constructor(
		private adminService: AdminService,
		private breadcrumb: BreadcrumbService,
	) {
	}

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([{'label': 'Sched Control', 'url': ''}]);
		this.adminService.getSchedFunctionsStatus().subscribe(
			data => {
				this.schedFunctions = data;
				this.numberOfServers = (Object.keys(data).length ? Object.keys(data).length : 1);
			}
		);
	}

	updateSchedFunctionsStatus() {
		this.adminService.updateSchedFunctionsStatus(this.schedFunctions).subscribe();
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}