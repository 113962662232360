import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import * as moment from 'moment';
import { UserService } from '../../../../../auth/user.service';
import { ChargersDashboardService } from '../chargers-dashboard.service';
import { DevicesDashboardService } from '../../../devices/devices-dashboard.service';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { NotificationMessageService } from '../../../../../shared/notification-message/notification-message.service';

@Component({
	selector: 'app-select-charger',
	templateUrl: './select-charger.component.html',
	styleUrls: ['./select-charger.component.css']
})
export class SelectChargerComponent implements OnInit {

	@Input() charger: any = {};
	@Input() chargerID: number;
	@Input() customerID: number = 0;
	@Input() siteID: number = 0;
	@Input() hasPLCFaults: boolean = false;
	@Input() multipleOptions: boolean = false;
	@Input() activePage: string;
	@Input() analyticsTab: string;
	@Input() currentSite: any;
	
	currentUser: any = {};
	chargersIDs: any = [];
	fromDateFmt: Date;
	toDateFmt: Date;
	siteChargers: any = [];
	siteTags: any = [];
	siteGroups: any = [];
	deviceTags: any = [];
	showDateRange: boolean = false;

	@Output() onChargerSelect = new EventEmitter<Object>();
	@Output() onDatesChanged = new EventEmitter<Object>();
	@Output() onGetSiteChargers = new EventEmitter<boolean>();

	constructor(
		private userService: UserService,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
		private commonUtil: CommonUtil,
		private chargersDashboard: ChargersDashboardService,
		private devicesDashboard: DevicesDashboardService,
		private notificationMessage: NotificationMessageService
	) { }

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();

		if(this.chargerID == 0) this.chargerID = null;

		this.getSiteChargers(this.chargerID);

		if(['connectivity-track', 'debug-records'].indexOf(this.activePage) != -1 || (this.activePage == 'analytics' && this.analyticsTab != 'GR'))
			this.showDateRange = true;
		else
			this.showDateRange = false;

		if(this.showDateRange) {
			switch(this.activePage) {
				case 'connectivity-track':
					this.fromDateFmt	= new Date(moment().utc().subtract(1, 'days').startOf('day').unix() * 1000);
				break;
				case 'debug-records':
					this.fromDateFmt	= new Date(moment().utc().subtract(6, 'days').startOf('day').unix() * 1000);
				break;
				case 'analytics':
					this.fromDateFmt	= new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0, 0, 0, 0));
				break;
				default:
					this.fromDateFmt	= new Date(new Date(new Date().setMonth(new Date().getMonth() - 3)).setHours(0, 0, 0, 0));
				break;
			}
			this.toDateFmt		= new Date(new Date().setHours(23,59,59,999));
			this.datesChanged();
		}
	}

	getSiteChargers(checkDeviceId) {
		
		var chargerInfo = {
			'customerid':	this.customerID,
			'siteid':		this.siteID
		};

		this.chargersDashboard.getChargers(chargerInfo, {'getTags': true, 'getGroups': this.currentSite.act_first_enabled, checkDeviceId: checkDeviceId}).subscribe(
			(chargers: any) => {

				chargers.list.forEach((charger) => {
					charger['prettyName'] = charger['chargerusername'] + ' (' + charger['serialnumber'] + ')';
				});

				this.siteChargers = _.chain(chargers.list).sortBy('serialnumber').sortBy('id').value();
				this.siteTags	= chargers.tags.siteTags;
				this.siteGroups	= chargers.groups || [];
			
				let deviceTags = {};
				
				this.siteChargers.forEach((item) => {
					deviceTags[item.id] = [];
					chargers.tags.deviceTags[item.id].forEach((tagItem) => {
						if(this.siteTags[tagItem.tagid]) {
							let deviceTag = Object.assign({}, this.siteTags[tagItem.tagid]);
							deviceTag.keep_in_replacement = tagItem.keep_in_replacement;
							deviceTags[item.id].push(deviceTag);
						}
					});
				});

				this.deviceTags	= deviceTags;

				this.onGetSiteChargers.emit(this.siteChargers.length > 0);

				if(this.chargerID){
					if(chargers.checkDeviceStatus && chargers.checkDeviceStatus.status){
						switch(chargers.checkDeviceStatus.status){
							case 'replaced':
								this.router.navigate(["/", this.customerID, this.siteID, "chargers", "analytics", "cycles", chargers.checkDeviceStatus.extra]);
							break;
							case 'moved':
								this.notificationMessage.setMessage('Device has been moved to other site, for more information please contact ACT person.', 'warning');
							break;
							case 'deleted':
								this.notificationMessage.setMessage('The device has been deleted.', 'warning');
							break;
							}
					} else if(this.activePage != 'settings')
						this.getCachedChargers();
				}
			}
		);
	};

	resetPLC() {
		this.devicesDashboard.resetPLCFault('charger', this.chargerID).subscribe();
	}

	getCachedChargers() {
		this.chargersDashboard.getCachedChargers(this.chargerID).subscribe(
			(charger: any) => {
				this.charger = charger;
				this.charger.restart_time_fmt = this.commonUtil.secondsToElapsedTime(charger.restart_time * 3600) || '0 hours';

				this.onChargerSelect.emit(this.charger);
			}
		);
	}

	datesChanged() {
		let dates = {
			'fromDateFmt':	this.fromDateFmt,
			'toDateFmt':	this.toDateFmt
		};
		this.onDatesChanged.emit(dates);
	}
	
	chargerChanged() {
		let url: any[] = [this.activePage];
		let queryParams = {};
		
		if(this.activePage == 'analytics')
			url.push(this.analyticsTab, this.chargerID);
		else if(this.activePage == 'notes')
			url.push(this.chargerID);
		else
			queryParams = {'chargersIDs': this.chargerID};

		this.location.go(
			this.router.createUrlTree(
				url,
				{'relativeTo': this.route.parent, 'queryParams': queryParams}
			).toString()
		);

		if(this.activePage == 'settings')
			this.onChargerSelect.emit(this.chargerID);
		else
			this.getCachedChargers();
	}
	updateTags(event) {
		if (event.type == 'add') {
			this.deviceTags[this.chargerID].push(event.tag);
			if(!this.siteTags[event.tag.id]) {
				this.siteTags[event.tag.id] = event.tag;
			}
		} else {
			// remove
			let removedTagId = event.tag.id;
			let deviceTag = [];
			this.deviceTags[this.chargerID].forEach((tag) => {
				if(tag.id != removedTagId) {
					deviceTag.push(tag);
				}
			})
			this.deviceTags[this.chargerID] = deviceTag;
		}
	}
	addGroup(group) {
		this.devicesDashboard.assignDeviceToGroup(this.siteID, group.id, 'charger', this.chargerID).subscribe((res) => {
			this.notificationMessage.setMessage(res, 'success', 10000, true);
		});
	}
}