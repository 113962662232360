import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { BattviewsDashboardService } from '../../battviews-dashboard.service';
import { CommonUtil } from '../../../../../../shared/services/utility/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
	selector: 'app-battery-daily-usage',
	templateUrl: './battery-daily-usage.component.html',
	styleUrls: ['./battery-daily-usage.component.css']
})
export class BatteryDailyUsageComponent implements OnInit, OnChanges {

	@Input() device: any = {};
	@Input() activeTab: string = null;
	@Input() activeSubTab: string = null;
	@Input() date: any = {};
	@Input() dailyDetails: any[] = [];
	@Input() summaryObject: any = {};
	@Input() isBattviewMobile: boolean = false;
	@Input() siteAlertsSettings: any = {};
	@Input() userAlertsSettings: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	@ViewChild("grid", {static: true}) grid;

	dailyUsageFilters: {name: string, url: string}[] = [];
	gridColumns: any = {};
	gridData: any[] = [];
	dailyDetailsReport: any = {};
	selectedDailyUsageFilter: any = {name: "All", url: ""};

	constructor(
		private battviewsDashboard: BattviewsDashboardService,
		private commonUtil: CommonUtil,
		private location: Location,
		private router: Router,
		private route: ActivatedRoute,
	) { }

	ngOnInit() {
		this.dailyUsageFilters = [
			{name: "All",							url: ""},
			// {name: "Missed EQ Not Plugged In",		url: "missed-eq-not-plugged-in"},
			// {name: "Missed EQ Plugged In",			url: "missed-eq-plugged-in"},
			// {name: "Missed Finish Not Plugged In",	url: "missed-finish-not-plugged-in"},
			// {name: "Missed Finish Plugged In",		url: "missed-finish-plugged-in"},
			{name: "Water level Low",				url: "water-level-low"},
			{name: "HIGH EBU usage",				url: "high-ebu-usage"},
			{name: "Missed Plugged In",				url: "missed-plugged-in"},
			{name: "High Temperature",				url: "high-temperature"},
			{name: "Potential Weak Cells",			url: "potential-weak-cells"},
			{name: "Deep Discharge",				url: "deep-discharge"},
			{name: "Weekly Missed EQ",				url: "missed-eq"},
			{name: "Missed Finish",					url: "missed-finish"},
		];
		let dayTypeFilterOptions = [{label: '', value: null}, {value: 'c', label: 'Charging Day'}, {value: 'w', label: 'Working Day'}];
		let missedEQOptions = [{label: '', value: 'null'}, {label: 'No EQ', value: 'none'} , {label: 'With EQ', value:'not_null'}];
		let missedFinishOptions = [{label: '', value: 'null'}, {label: 'Without Finish', value: 'none'} , {label: 'With Finish', value:'not_null'}];
		let waterLevelOptions = [{label: '', value: null}, {label: 'Low', value: 'low'} , {label: 'OK', value:'ok'}];
		this.gridColumns = [
			{headerName: "Date", width:150, field: "date_formatted", filterParams: {defaultOption: 'startsWith'}},
			{
				headerName: "Day Type",
				width:150,
				field: "day_type",
				hide: (this.isBattviewMobile ? 1 : 0),
				floatingFilterComponent: "dropDownFilterComponent",
				floatingFilterComponentParams: {dropDownOptions: dayTypeFilterOptions, filterOptionType: 'contains'}
			},
			{headerName: "Charges", width:135, field: "charges", type: 'number', hide: (this.isBattviewMobile ? 1 : 0)},
			{headerName: "Charge AHr", width:155, field: "chargeAHRValue", type: 'number', filterParams: "1.1-2"},
			{headerName: "Charge KWHr", width:170, field: "chargeKWHRValue", type: 'number', filterParams: "1.2-2"},
			{headerName: "In Use", width:135, field: "total_inuse_events", type: 'number'},
			{headerName: "In Use AHr", width:150, field: "inuseAhrValue", type: 'number', filterParams: "1.2-2"},
			{headerName: "In Use KWHR", width:170, field: "inuseKwhrValue", type: 'number', filterParams: "1.2-2"},
			{
				headerName: "Charge Duration",
				width:185,
				field: "chargeDuration",
				filterValueGetter:"data.chargeDurationOrgValue",
				filterParams: {filterOptions: ["equals", "greaterThan", "lessThan"], defaultOption: "equals", thisObject: this, textCustomComparator: this.grid.durationFormatter}
			},
			{
				headerName: "In Use Duration",
				width:185,
				field: "inuseDuration",
				filterValueGetter:'data.inuseDurationOrgValue',
				filterParams: {filterOptions: ["equals", "greaterThan", "lessThan"], defaultOption: "equals", thisObject: this, textCustomComparator: this.grid.durationFormatter}
			},
			{headerName: "Idle", width:150, field: "totalIdleEvents", type: 'number'},
			{
				headerName: "Idle Duration",
				width:170,
				field: "idleDuration",
				filterValueGetter:'data.idleDurationOrgValue',
				filterParams: {filterOptions: ["equals", "greaterThan", "lessThan"], defaultOption: "equals", thisObject: this, textCustomComparator: this.grid.durationFormatter}
			},
			{
				headerName: "Weekly Missed EQ",
				width:200,
				field: "missedEQ",
				hide: (this.isBattviewMobile ? 1 : 0),
				filter: "nullFilter",
				floatingFilterComponent: "dropDownFilterComponent",
				floatingFilterComponentParams: {dropDownOptions: missedEQOptions, type: "nullFilter"}
			},
			{
				headerName: "Missed Finish",
				width:170,
				field: "missedFinish",
				hide: (this.isBattviewMobile ? 1 : 0),
				filter: "nullFilter",
				floatingFilterComponent: "dropDownFilterComponent",
				floatingFilterComponentParams: {dropDownOptions: missedFinishOptions, type: "nullFilter"}
			},
			{headerName: "EBU", width:120, field: "EBUvalue", type:"number"},
			{headerName: "Ahr / Hr", width:140, field: "ahrPerHrValue", type: 'number', filterParams: "1.2-2"},
			{
				headerName: "Idle After Event Hrs",
				width:300,
				field: "chargeOppurtinityDuration",
				filterValueGetter:"data.chargeOppurtinityDurationValue",
				filterParams: {filterOptions: ["equals", "greaterThan", "lessThan"], defaultOption: "equals", thisObject: this, textCustomComparator: this.grid.durationFormatter}
			},
			{
				headerName: "Water Level Low",
				width:185,
				field: "waterLevelLowText",
				hide: (this.isBattviewMobile ? 1 : 0),
				floatingFilterComponent: "dropDownFilterComponent",
				floatingFilterComponentParams: {dropDownOptions: waterLevelOptions}
			}
		];
		if(this.activeSubTab) {
			for (var i=0; i < this.dailyUsageFilters.length; i++) {
				if (this.dailyUsageFilters[i].url == this.activeSubTab) {
					this.selectedDailyUsageFilter = this.dailyUsageFilters[i];
					break;
				}
			}
			this.filterAllDailyDetails(this.selectedDailyUsageFilter);
		}
	}
	ngOnChanges(changes) {
		if(changes.dailyDetails && (!changes.dailyDetails.firstChange || (Object.keys(changes.dailyDetails.currentValue).length > 0 && changes.dailyDetails.currentValue))) {
			this.dailyDetailsReport = this.battviewsDashboard.generateDailyDetailReport(changes.dailyDetails.currentValue, this.device, this.userAlertsSettings);
			this.filterAllDailyDetails(this.selectedDailyUsageFilter);
		}
	}
	filterAllDailyDetails(selectedDailyUsageFilter?) {
		// change url
		if(selectedDailyUsageFilter) {
			this.changeUrl(selectedDailyUsageFilter.url);
		}

		var filteredDailyDetails = [];
		if(!selectedDailyUsageFilter) selectedDailyUsageFilter={name: null, url: null};

		this.selectedDailyUsageFilter = selectedDailyUsageFilter;
		switch (selectedDailyUsageFilter.name) {
			case 'Missed EQ Not Plugged In':
			case 'Missed EQ Plugged In':
			case 'Missed Finish Plugged In':
			case 'Missed Finish Not Plugged In':
			case 'Weekly Missed EQ':
			case 'Missed Finish':
				filteredDailyDetails = this.battviewsDashboard.getMissedData(this.dailyDetailsReport, selectedDailyUsageFilter.name, 'dailyDetails', this.device);
			break;
			case 'Water level Low':
				var waterLevelLowDays	= [];

				var sortedDailyDetails = this.dailyDetailsReport.sort((a,b) => {return (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0);});

				sortedDailyDetails.forEach((currentDailyDetails, i) => {
					if (currentDailyDetails.waterLevelLow) {
						waterLevelLowDays.push(currentDailyDetails);
					}

					if (currentDailyDetails.countOfEqs > 0 && waterLevelLowDays.length >= this.siteAlertsSettings.water_level_low_days) {
						for (var index in waterLevelLowDays) {
							if (waterLevelLowDays[index]) {
								filteredDailyDetails.push(waterLevelLowDays[index]);
								delete waterLevelLowDays[index];
							}
						}
					}

					if (!currentDailyDetails.waterLevelLow) {
						waterLevelLowDays = [];
					}
				});
			break;
			case "HIGH EBU usage":
				var expectedEBU;
				switch (this.device.chargertype) {
					case 0:
						expectedEBU = this.siteAlertsSettings.fast_ebu_limit;
						break;
					case 1:
						expectedEBU = this.siteAlertsSettings.conventional_ebu_limit;
						break;
					case 2:
						expectedEBU = this.siteAlertsSettings.opportunity_ebu_limit;
						break;
					// case 3:
					// 	expectedEBU = this.siteAlertsSettings.lithium_ebu_limit;
					// 	break;
				}
				this.dailyDetailsReport.forEach((currentDailyDetails, i) => {
					if ((currentDailyDetails.inuseAs/(3600*0.8 * this.device.ahrcapacity)) > expectedEBU) {
						filteredDailyDetails.push(currentDailyDetails);
					}
				});
			break;
			case "Missed Plugged In":
				this.dailyDetailsReport.forEach((currentDailyDetails, i) => {
					if (currentDailyDetails.isWorkingDay && currentDailyDetails.missedConnectionAlert > 0) {
						filteredDailyDetails.push(currentDailyDetails);
					}
				});
			break;
			case "High Temperature":
				var tempDays = [];
				this.dailyDetailsReport.forEach((currentDailyDetails, i) => {
					if (currentDailyDetails.maxTemperatureExceeded > 0) {
						tempDays.push(currentDailyDetails);
					}
				});
				if (tempDays.length >= this.siteAlertsSettings.high_temperature_limit_days) {
					filteredDailyDetails = tempDays;
				}
			break;
			case "Potential Weak Cells":
				this.dailyDetailsReport.forEach((currentDailyDetails, i) => {
					if (currentDailyDetails.potentialWeekCellsExceeded > 0) {
						filteredDailyDetails.push(currentDailyDetails);
					}
				});
			break;
			case "Deep Discharge":
				this.dailyDetailsReport.forEach((currentDailyDetails, i) => {
					if (currentDailyDetails.deepDischargeExceeded > 0) {
						filteredDailyDetails.push(currentDailyDetails);
					}
				});
			break;
			default:
				 filteredDailyDetails = this.dailyDetailsReport;
			break;
		}
		this.gridData = this.formatData(filteredDailyDetails);
	}
	formatData(filteredDailyDetails) {

		for (var i = 0; i < filteredDailyDetails.length; i++) {
			filteredDailyDetails[i].date_formatted			= this.commonUtil.getDateFormattedFromUnixTimeStamp((filteredDailyDetails[i].date / 1000), 'date');
			filteredDailyDetails[i].chargeDurationOrgValue	= filteredDailyDetails[i].chargeDurationValue / 1000;
			filteredDailyDetails[i].inuseDurationOrgValue	= filteredDailyDetails[i].dischargeDurationValue / 1000;
			filteredDailyDetails[i].idleDurationOrgValue	= filteredDailyDetails[i].idleDurationValue / 1000;
			filteredDailyDetails[i].chargeAHRValue			= Math.round((filteredDailyDetails[i].chargeAHR / 3600)*100)/100;
			filteredDailyDetails[i].chargeKWHRValue			= Math.round((filteredDailyDetails[i].chargeKWHR / (3600 * 1000))*100)/100;
			filteredDailyDetails[i].inuseAhrValue			= Math.round(filteredDailyDetails[i].inuse_ahr*100)/100;
			filteredDailyDetails[i].inuseKwhrValue			= Math.round(filteredDailyDetails[i].inuse_kwhr*100)/100;
			filteredDailyDetails[i].EBUvalue				= Math.round(filteredDailyDetails[i].EBU*100)/100;
			filteredDailyDetails[i].ahrPerHrValue			= Math.round(filteredDailyDetails[i].ahrPerHr*100)/100;
			filteredDailyDetails[i].chargeDuration			= this.commonUtil.timeFormat(filteredDailyDetails[i].chargeDurationOrgValue, {limitToOneDay: true});
			filteredDailyDetails[i].inuseDuration			= this.commonUtil.timeFormat(filteredDailyDetails[i].inuseDurationOrgValue, {limitToOneDay: true});
			filteredDailyDetails[i].idleDuration			= this.commonUtil.timeFormat(filteredDailyDetails[i].idleDurationOrgValue, {limitToOneDay: true});
			filteredDailyDetails[i].waterLevelLowText 		= (filteredDailyDetails[i].waterLevelLow) ? 'Low' : 'OK';
		}
		return filteredDailyDetails;
	}
	
	getCSVcontent() {
		var fields = [];
		var displayNamesFields = [];

		var headers = [];
		var i = 0
		this.gridColumns.forEach((col) => {
			if(col.hide != 1) {
				fields.push(col.field);
				headers.push(i++);
				displayNamesFields.push(col.headerName);
			}
		});

		var items = [];
		this.gridData.forEach((row) => {
			var item = [];
			fields.forEach((field) => {
				item.push(row[field]);
			});
			items.push(item);
		});

		var informationRow = [
			["Battery Reports", "Daily Usage"], 
		];
		
		if(!this.isBattviewMobile) {
			informationRow.push(["Model", this.device.model]);
			informationRow.push(["Battery Serial Number", this.device.batterysn]);
		}

		informationRow.push(["BATTview Serial Number", "*" + this.device.serialnumber]);
		informationRow.push(["ID", this.device.batteryid]);

		if(!this.isBattviewMobile) {
			informationRow.push(["Customer / Site" , this.customerName + " " + this.siteName]);
		}

		informationRow.push(["Date Range", moment(this.date.fromDate).format('MM/DD/YYYY') + " - " + moment(this.date.toDate).format('MM/DD/YYYY')]);
		informationRow.push(["Truck ID", (this.device.truckid || 'No Truck ID')]);

		var csv = [headers];
		csv.push([]);

		informationRow.forEach((row) => {
			csv.push(row);
		});

		csv.push([]);
		csv.push([]);
		csv.push([]);
		csv.push(displayNamesFields);
		
		items.forEach((row) => {
			csv.push(row);
		});
		return csv;
	}

	changeUrl(subTab) {
		this.location.go(
			this.router.createUrlTree(
				['analytics', this.activeTab, this.device.id || 0, subTab],
				{'relativeTo': this.route.parent}
			).toString()
		);
	}
}