<div class="boxs3">
	<div class="row">
		<a 
			class="btn"
			[ngClass]="activeChart == 'availability' ? 'btn-primary' : 'btn-default'"
			[routerLink]="['/', customerId, siteId, 'act-first', 'reports-charts', 'availability']"
		>
			{{'act_first.availability_report' | translate}}
		</a>
		<a 
			class="btn"
			[ngClass]="activeChart == 'daily-availability' ? 'btn-primary' : 'btn-default'"
			[routerLink]="['/', customerId, siteId, 'act-first', 'reports-charts', 'daily-availability']"
		>
			{{'act_first.daily_availability_report' | translate}}
		</a>
		<a 
			class="btn"
			[ngClass]="activeChart == 'hourly-availability' ? 'btn-primary' : 'btn-default'"
			[routerLink]="['/', customerId, siteId, 'act-first', 'reports-charts', 'hourly-availability']"
		>
			{{'act_first.hourly_availability_report' | translate}}
		</a>
		<a 
			class="btn" 
			[ngClass]="activeChart == 'battery-picks' ? 'btn-primary' : 'btn-default'"
			[routerLink]="['/', customerId, siteId, 'act-first', 'reports-charts', 'battery-picks']"
		>
			{{'act_first.battery_picks_report' | translate}}
		</a>
		<a 
			class="btn" 
			[ngClass]="activeChart == 'connect-voltage' ? 'btn-primary' : 'btn-default'"
			[routerLink]="['/', customerId, siteId, 'act-first', 'reports-charts', 'connect-voltage']"
		>
			{{'act_first.connect_voltage_report' | translate}}
		</a>
		<a 
			class="btn" 
			[ngClass]="activeChart == 'weekly-connect-voltage' ? 'btn-primary' : 'btn-default'"
			[routerLink]="['/', customerId, siteId, 'act-first', 'reports-charts', 'weekly-connect-voltage']"
		>
			{{'act_first.weekly_connect_voltage_report' | translate}}
		</a>
		<a 
			class="btn" 
			[ngClass]="activeChart == 'parked-batteries' ? 'btn-primary' : 'btn-default'"
			[routerLink]="['/', customerId, siteId, 'act-first', 'reports-charts', 'parked-batteries']"
		>
			{{'act_first.parked_batteries_report' | translate}}
		</a>
	</div>
	<div>
		<app-availability *ngIf="activeChart == 'availability'"></app-availability>
		<app-daily-availability *ngIf="activeChart == 'daily-availability'"></app-daily-availability>
		<app-hourly-availability *ngIf="activeChart == 'hourly-availability'"></app-hourly-availability>
		<app-battery-picks *ngIf="activeChart == 'battery-picks'"></app-battery-picks>
		<app-connect-voltage *ngIf="activeChart == 'connect-voltage'"></app-connect-voltage>
		<app-connect-voltage *ngIf="activeChart == 'weekly-connect-voltage'" [isWeekly]="true"></app-connect-voltage>
		<app-parked-batteries *ngIf="activeChart == 'parked-batteries'"></app-parked-batteries>
	</div>
</div>