<div class="boxs3 margin-top-lg">
	<div class="row">
		<div class="col-lg-4 col-lg-offset-8">
			<select
				class="form-control input-sm"
				[(ngModel)]="selectedDateRange"
				(change)="dateRangeChange($event.target.value)">
					<option
						*ngFor="let option of dropDownOptions"
						[value]="option.value">
						{{option.label}}
					</option>
			</select>
		</div>
	</div>

	<div>
		<h4>
			{{'device.status' | translate}}
		</h4>
		<span *ngFor="let key of keys">
			<input type="checkbox" [checked]="typesStatus[key]" (change)="updateStatuses(key)" /> {{actFirstService.getGroupName(key)}}
		</span>
	</div>
	<div class="margin-bottom-xlg"></div>

	<div>
		<h4 class="margin-bottom-xlg">{{availabilityText}}</h4>
		<app-grid-v2
			id="actfirst_availability_report"
			[columns]="gridColumns"
			[data]="gridRows">
		</app-grid-v2>
	</div>

	<div class="margin-top-lg" *ngFor="let groupChart of groupCharts">
		<simple-widget [title]="groupChart.groupname" *ngIf="hasAnyStatusEnabled">
			<div class="margin-bottom toolss2">
				<button class="fa fa-print" data-toggle="tooltip" title="Print" (click)="printChart(groupChart.groupname, groupChart.groupid);"></button>
			</div>
			<div class="margin-top-xlg">
				<column-chart 
					(onChartReady)="generateImageURI($event)"
					[data]="groupChart.dataTable"
					[config]="config"
					[elementId]="groupChart.groupid">
				</column-chart>
			</div>
		</simple-widget>
	</div>
</div>