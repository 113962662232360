<form #confirmEmail="ngForm" class="form-auth">
	<div class="row">
		<div class="col-md-6 col-md-offset-3">
			<div class="boxs3">
				<h2>{{'g.confirm_email' | translate}}</h2>
				<div class="form-field">
					{{'g.confirm_email_txt1' | translate}}
				</div>
				<br/>
				<button type="submit" class="btn btn-default" (click)="sendConfirmEmail()">{{'g.send_again' | translate}}</button>
			</div>
		</div>
	</div>
</form>