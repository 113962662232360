<div class="panel-body">
    <div class="col-md-12 margin-bottom-lg">
        <div class="col-md-1 btn btn-default disabled default-cursor">Date:</div>
        <div class="col-md-4">
            <div class='input-group date'>
                <input readonly class="form-control" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="fromDateFmt" bsDatepicker #fromDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
                <span class="input-group-btn">
                    <button class="btn btn-default btn-date" (click)="fromDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                </span>
            </div>
        </div>
        <div class="col-md-2 no-gutter">
            <button class="form-control btn btn-primary" (click)="getRTrecordsData()"><span *ngIf="!isBattviewMobile">Get Records</span><span *ngIf="isBattviewMobile">View Real Time Records</span></button>
        </div>
    </div>
    <div class="col-md-12" *ngIf="hasGrid && gridData.length > 0">
        <app-grid-v2 [id]="'rt-records-'+device.id" [columns]="gridColumns" [data]="gridData"></app-grid-v2>
    </div>
    <div *ngIf="RTrecords.length > 0">
        <div class="col-md-12 margin-top-lg">
            <div class="col-md-4">
                <input type="checkbox" name="show-voltage-records" id="show-voltage-records" class="form-control" [(ngModel)]="rtRecordsChartCheckbox.voltage" (change)="drawRTrecordsChart()">
                <label for="show-voltage-records">Voltage Records</label>
            </div>
            <div class="col-md-4">
                <input type="checkbox" id="show-current-records" name="show-current-records" class="form-control" [(ngModel)]="rtRecordsChartCheckbox.current" (change)="drawRTrecordsChart()">
                <label for="show-current-records">Current Records</label>
            </div>
            <div *ngIf="hasTemperature" class="col-md-4">
                <input type="checkbox" name="show-temperature-records" id="show-temperature-records" class="form-control" [(ngModel)]="rtRecordsChartCheckbox.temperature" (change)="drawRTrecordsChart()">
                <label for="show-temperature-records">Temperature Records</label>
            </div>
        </div>
        <div class="col-md-12">
            <div class="toolss2 margin-right btn btn-default">
                <button type="button" class="fa fa-print" data-toggle="tooltip" title="Print" (click)="printRTChart();"></button>
            </div>
        </div>
    </div>
    <div *ngIf="drawChart" id="rtRecordsDashboard" class="col-md-12">
        <line-chart
            (onChartReady)="generateImageURI($event)"
            [data]="chartObject.data"
            [config]="chartObject.config"
            elementId="rt-records">
        </line-chart>
	</div>
	<div class="col-md-12 margin-bottom-lg">
		<div *ngIf="!drawChart" class="alert alert-warning" role="alert">
			No data available to draw this chart
		</div>
	</div>
</div>