<app-modal #trackModal title="Configurations" [hasConfirmButton]="false" size="lg">
  <div class="w-100 export">
    <a class="btn btn-info " (click)="exportToExcel()">Export</a>
  </div>
	<table class="table table-striped">
		<thead>
			<tr>
				<th>Property</th>
				<th>Value</th>
			</tr>
		</thead>
		<tbody>
		<tr *ngFor="let key of trackModalData | keys">
			<td>{{key}}</td>
			<td>{{trackModalData[key]}}</td>
		</tr>
		</tbody>
	</table>
</app-modal>

<app-modal #compareModal title="Compare Configurations" [hasConfirmButton]="false" size="lg">
	<table class="table table-striped">
		<thead>
			<tr>
				<th>Property</th>
				<th>Old</th>
				<th>New</th>
			</tr>
		</thead>
		<tbody>
		<tr *ngFor="let key of compareModalData.old | keys">
			<td>{{key}}</td>
			<td>{{compareModalData.old[key]}}</td>
			<td>{{compareModalData.new[key]}}</td>
		</tr>
		</tbody>
	</table>
</app-modal>

<div class="panel panel-default">
	<div class="panel-body">
		<div class="col-md-12">
			<button class="btn btn-info" (click)="compareConfigs()" [disabled]="!configsToCompare" [attr.title]="!configsToCompare ? 'Select two records to compare' : ''">Compare Configs</button>
			<app-grid-v2 [id]="deviceType+'-config-track'" [columns]="gridColumns" [data]="gridData" (emitEventToParent)="gridEvent($event)"></app-grid-v2>
			<server-pagination idField="id" [limit]="10" (paginate)="getDeviceConfigTrack($event)" #serverPagination></server-pagination>
		</div>
	</div>
</div>
