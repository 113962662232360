import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ChargersDashboardService } from '../chargers-dashboard.service';
import *  as moment from 'moment';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { SideMenuService } from '../../../../../shared/side-menu/side-menu.service';

@Component({
	selector: 'app-charger-pms-list',
	templateUrl: './charger-pms-list.component.html',
	styleUrls: ['./charger-pms-list.component.css']
})
export class ChargerPmsListComponent implements OnInit, OnChanges {

	@Input() charger: any = {};
	@Input() chargerID: number = 0;

	allPMsSnapshots: any;
	currentSite: any = {};
	
	constructor(
		private chargersDashboard: ChargersDashboardService,
		private sideMenuService: SideMenuService,
		private commonUtil: CommonUtil
	) { }

	ngOnInit() {
		this.sideMenuService.currentSite.subscribe(data => {
			if(data && data.id) {
				this.currentSite = data;
			}
		});
	}
	
	ngOnChanges(changes) {
		if(changes.chargerID) {
			delete this.allPMsSnapshots;
			this.fetchData();
		}
	}
	
	fetchData() {
		this.chargersDashboard.readChargerPMsAllSnapshots(this.chargerID).subscribe((response: any) => {
			this.allPMsSnapshots = response;
		});
	}
}