import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter, ViewEncapsulation} from '@angular/core';
import * as _ from 'underscore';
import { CommonUtil } from '../services/utility/common.service';
import { DecimalPipe, DatePipe } from '@angular/common';
import { DropDownFilterComponent } from './drop-down-filter/drop-down-filter.component';
import { NotificationMessageService } from '../notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-grid-v2',
	templateUrl: './grid-v2.component.html',
	styleUrls: ['./grid-v2.component.css'],
	encapsulation: ViewEncapsulation.None 
})
export class GridV2Component implements OnInit, OnChanges {

	@Input() id: string = '';
	@Input() data: Array<Object>;
	@Input() columns: any[];
	@Input() settings: any = {};
	@Input() sortField: string = '';
	@Input() modelId: string = '';
	@Input() sizeColumnsToFit: boolean = false;
	@Input() translateHeaders: boolean = false;

	_settings = {
		hasExport: true,
		hasSelectColumn: true,
		hasReset: true,
		showSummary: true,
		height: "400",
		enableFloatingFilter: true,
		pagination: false,
		paginationPageSize: 10,
		domLayout: 'normal',
		keepDefaultColumnsInExport: false
	};
	key;

	@Output() emitEventToParent = new EventEmitter<object>();

	// new
	gridApi: any;
	gridColumnApi: any;
	customizedColumnTypes: any = {};
	frameworkComponents: any = {};
	component: any = {};
	totalItemsCount: number = 0;

	filterParams: any; //temp to fix error

	constructor(
		private commonUtil: CommonUtil,
		private notificationMessage: NotificationMessageService,
		private translateService: TranslateService
	) {
		this.customizedColumnTypes = {
			number: {filter: "agNumberColumnFilter", valueFormatter: numberFormatter, comparator: numberComparator},
			date: {filter: "agDateColumnFilter", valueFormatter: dateFormatter, filterParams: {
				comparator: dateComparator
			}},
			time: {filter: "agTextColumnFilter", valueFormatter: timeFormatter, filterParams: {
				textCustomComparator: timeComparator, defaultOption: "startsWith"
			}},
		};
		this.frameworkComponents = {dropDownFilterComponent: DropDownFilterComponent}

		this.component = {
			linkCellRenderer: linkCellRenderer,
			nullFilter: nullFilter
		};
	}

	ngOnInit() {
		this.key = this.id + "_grid_v3";

		this.setSettings();

		this.columns = this.columns.map((col: any) => { return { ...col, floatingFilter: this.settings.enableFloatingFilter } });
	}

	ngOnChanges(changes: SimpleChanges) {
		if(changes['settings'] && changes['settings'].previousValue != changes['settings'].currentValue) {
			this.setSettings();
		}
		if (changes['data'] && changes['data'].previousValue != changes['data'].currentValue) {
			this.data = changes['data'].currentValue;
			this.totalItemsCount = this.data.length;
		}
		if(this.gridApi && this.sizeColumnsToFit) {
			setTimeout(() => {
				this.gridApi.sizeColumnsToFit();
			});
		}
		if(this.translateHeaders && this.columns && changes['columns']) {
			this.columns.forEach((col) => {
				col['headerName'] = this.translateService.instant(col['headerName']);
			});
		}
	}

	setSettings() {
		this.settings = {...this._settings, ...this.settings};
	}

	toggleColumn(col) {
		col['hide'] = col['hide'] == 1 ? 0 : 1;
		this.gridColumnApi.setColumnVisible(col.field, (col.hide ? false: true));
		this.onColVisibiltyChange();
	}

	getGridFromLocalStorage() {
		const storageValue = JSON.parse(localStorage.getItem(this.key)) || {};
		let localStorageGrid = storageValue.grid;

		if (localStorageGrid) {
		
			let sessionCookie = this.commonUtil.getCookie('userSession');

			if(sessionCookie != storageValue.session) {

				localStorage.removeItem(this.key);
				return;
			}

			if (localStorageGrid['columns']) {

				if(localStorageGrid['columns'].length != this.columns.length) {
					localStorage.removeItem(this.key);
					return;
				}

				this.gridColumnApi.applyColumnState({ state: localStorageGrid['columns'] });
				this.updateColumns(localStorageGrid['columns']);
			}

			if (localStorageGrid['filter']) {
				this.gridApi.setFilterModel(localStorageGrid['filter']);
			}
		}
	}

	updateColumns(localColumns) {
		this.columns.forEach((col) => {
			let foundCol = localColumns.find((localCol) => {
				return ((col.colId && col.colId == localCol.colId) || col.field == localCol.colId);
			});
			if(foundCol)
				col.hide = foundCol.hide;
		});
	}

	onColVisibiltyChange() {
		this.setGridLocalSotorage();
	}

	onSort() {
		this.setGridLocalSotorage();
	}

	onColReorder(event) {
		this.setGridLocalSotorage();
	}

	onFilter(event) {
		this.setGridLocalSotorage();
	}

	selectionChanged(event) {
		this.emitEventToParent.emit({'selection': this.gridApi.getSelectedRows()});
	}

	setGridLocalSotorage() {
		if (this.id) {
			let storageValue = JSON.parse(localStorage.getItem(this.key)) || {};
			let grid = storageValue.grid;
			if (!grid)
				grid = {};

			grid.columns = this.gridColumnApi.getColumnState();
			grid.filter = this.gridApi.getFilterModel();

			let toStore = {
				grid: grid,
				session: this.commonUtil.getCookie('userSession')
			};
			localStorage.setItem(this.key, JSON.stringify(toStore));
		}
	}

	onReset() {
		this.gridColumnApi.resetColumnState();
		
		// clear filters
		this.columns.forEach((col) => {
			this.gridApi.destroyFilter(col.field);
		});

		// to clear any highlighted rows
		this.gridApi.deselectAll();

		this.gridColumnApi.applyColumnState([]);

		let i = 0;
		this.gridColumnApi.getColumnState().forEach((col) => {
			this.columns[i].hide = (col.hide) ? 1 : 0;
			i++;
		});
		if(this.sizeColumnsToFit) {
			this.gridApi.sizeColumnsToFit();
		}
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.getGridFromLocalStorage();
		if(this.sizeColumnsToFit) {
			this.gridApi.sizeColumnsToFit();
		}
	}

	onBtExport() {

		var columnKeys = [];

		this.columns.forEach((column) => {
			if(!column.execludeOnExport && !column.hide)
				columnKeys.push(column.field);
		});

		if(columnKeys.length === 0) {
			this.notificationMessage.setMessage(this.translateService.instant('g.no_columns_to_export'));
			return;
		}

		var fileName = this.id;

		if(this.modelId)
			fileName += '-' + this.modelId

		var params: any = {
			skipHeader: false,
			columnGroups: true,
			skipFooters: true,
			skipGroups: true,
			skipPinnedTop: true,
			skipPinnedBottom: true,
			allColumns: false,
			onlySelected: false,
			suppressQuotes: false,
			fileName: fileName,
			columnSeparator: ','
		};
		
		if(!this.settings.keepDefaultColumnsInExport) {
			var columnKeys = [];
			this.columns.forEach((column) => {
				if(!column.execludeOnExport && !column.hide)
					columnKeys.push(column.field);
			});
			params.columnKeys = columnKeys;
		}

		params.processCellCallback = function(item) {
			if (item.value && ['date', 'time'].includes(item.column.colDef.type)) {
				let format = 'MM/dd/yyyy';
				if(item.value && item.column.colDef.type == 'time')
					format += ' HH:mm:ss';
				let datePipe: DatePipe = new DatePipe("en-US");
				return datePipe.transform(item.value, format)
			}
			return item.value;
		};

		this.gridApi.exportDataAsCsv(params);
	}

	redrawRows() {
		this.gridApi.redrawRows();
	}

	durationFormatter(filter, value, filterText) {
		if(!filterText) {
			return true;
		}
		let filterTextSplitted	= filterText.split(':');
		let filterValue = this.filterParams.thisObject.commonUtil.convertHourToSeconds(filterTextSplitted[0], filterTextSplitted[1]||0, false, filterTextSplitted[2] || 0);
		let returnVal = false;
		let rowValue = value - (value % 60);
		switch (filter) {
			case 'lessThan':
				returnVal = rowValue < filterValue;
			break;
			case 'greaterThan':
				returnVal = rowValue > filterValue;
			break;
			default:
				returnVal = rowValue == filterValue;
			break;
		}
		return returnVal;
	}
}

function numberFormatter(params) {
	// lang
	let decimalPipe: DecimalPipe = new DecimalPipe("en-US");
	if(isNaN(+(params.value))) {
		return params.value;
	}
	
	let format = '1.0-0';
	
	if(typeof params.colDef.filterParams == 'string')
		format = params.colDef.filterParams;
	else if(params.colDef.filterParams)
		format = params.colDef.filterParams.format;

	return decimalPipe.transform(params.value, format);
}
function dateFormatter(params) {
	// lang
	let datePipe: DatePipe = new DatePipe("en-US");
	return datePipe.transform(params.value, 'MM/dd/yyyy');
}
function timeFormatter(params) {
	// lang
	let datePipe: DatePipe = new DatePipe("en-US");
	return datePipe.transform(params.value, 'MM/dd/yyyy hh:mm:ss a');
}

function linkCellRenderer() {
}

linkCellRenderer.prototype.init = function (params) {
	this.eGui = document.createElement('div');
	if (params.value !== "" || params.value !== undefined || params.value !== null) {
		let aTag = '<a>';
		if(params.link) {
			aTag = '<a href="'+params['link']+'" class="no-print">';
		}
		this.eGui.innerHTML = aTag+params['label']+'</a>'+'<span class="showOnPrint">'+params['label']+'</span>';
	}
};

linkCellRenderer.prototype.getGui = function () {
	return this.eGui;
};

function nullFilter() {
}

nullFilter.prototype.init = function (params) {
	this.valueGetter = params.valueGetter;
	this.filterText = null;
	this.eFilterText = {};
	this.params = params;
	this.gui = document.createElement('div');
};

nullFilter.prototype.getGui = function () {
	return this.gui;
};

nullFilter.prototype.doesFilterPass = function (params) {
	// make sure each word passes separately, ie search for firstname, lastname
	var passed = true;
	var valueGetter = this.valueGetter;
	var filterWord = this.filterText;
	if(filterWord.model) {
		filterWord = filterWord.model;
	}
	var value = valueGetter(params);
	switch(filterWord) {
		case 'none':
			if (value != '') {
				passed = false;
			}
		break;
		case 'not_null':
			let columnName2 = this.params.colDef.floatingFilterComponentParams.notNull2Field;
			if ((params.data[columnName2] && params.data[columnName2] != '') || value == '') {
				passed = false;
			}
		break;
		case 'not_null2':
			let columnName = this.params.colDef.floatingFilterComponentParams.notNull2Field;
			if (params.data[columnName] == '' || !params.data[columnName] || value == '') {
				passed = false;
			}
		break;
	}
	return passed;
};

nullFilter.prototype.isFilterActive = function () {
	return this.filterText !== null && this.filterText !== undefined && this.filterText !== '';
};

nullFilter.prototype.getModel = function() {
	return this.filterText;
};

nullFilter.prototype.setModel = function(model) {
	this.filterText = model;
};

// used for sort
function numberComparator(var1, var2) {
	var var1Number = +(var1);
	var var2Number = +(var2);
	if (var1Number === null && var2Number === null) {
		return 0;
	}
	if (var1Number === null || isNaN(var1Number)) {
		return -1;
	}
	if (var2Number === null || isNaN(var2Number)) {
		return 1;
	}
	return var1Number - var2Number;
}
function dateComparator(filter, cellValue) {
	cellValue = new Date(cellValue);
	var day = cellValue.getDate();
	var month = cellValue.getMonth();
	var year = cellValue.getFullYear();

	var cellDate = new Date(year, month, day);
	if (cellDate < filter) {
		return -1;
	}
	if (cellDate > filter) {
		return 1;
	}
	return 0;
}
function timeComparator(filter, cellValue, filterText) {
	let datePipe: DatePipe = new DatePipe("en-US");
	cellValue = datePipe.transform(cellValue, 'MM/dd/yyyy hh:mm:ss a');
	return cellValue.indexOf(filterText) === 0;
}