
import {distinctUntilChanged} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationMessage } from './notification-message.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NotificationMessageService {
	private messageSubject: BehaviorSubject<NotificationMessage> = new BehaviorSubject<NotificationMessage>(new NotificationMessage());
	message = this.messageSubject.asObservable().pipe(distinctUntilChanged());

	constructor(
		private translateService: TranslateService
	) {

	}
	setMessage(msg: string, type: string = 'danger', timeout: number = 10000, scrollToTop: boolean = true, show: boolean = true) {
		if(msg == 'globalSuccessMsg') {
			msg = this.translateService.instant('g.operation_done_successfully');
			type = 'success';
		} else if(msg == 'globalErrMsg') {
			msg = this.translateService.instant('g.an_error_occurred');
		} else if(msg == 'globalAuthMsg') {
			msg = this.translateService.instant('g.unauthorized_access');
		} else if(msg !== null) {
			let splitted = msg.split('|');
			if(splitted[0] === "translate")
				msg = this.translateService.instant(splitted[1]);
		}

		let obj = {
			message: msg,
			type: type,
			timeout: timeout,
			scrollToTop: scrollToTop,
			show: show
		};
		
		this.messageSubject.next(obj);
	}
	closeNotification(){
		this.setMessage(null, null, 700, false, false);
	};
}