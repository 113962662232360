import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AdminService } from '../../admin.service';
import { NavigationService } from '../../../shared/services/navigation.service';
import { UserService } from '../../../auth/user.service';
import { User } from '../../../auth/user.model';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import * as _ from 'lodash';

@Component({
	selector: 'app-manage-device-purchase-order',
	templateUrl: './manage-device-purchase-order.component.html',
	styleUrls: ['./manage-device-purchase-order.component.css']
})
export class ManageDevicePurchaseOrderComponent implements OnInit, OnDestroy {

	deviceIDs = [];
	devices: any[] = [];
	currentUser: User = new User();

	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute,
		private navigation: NavigationService,
		private userService: UserService,
		private breadcrumb: BreadcrumbService,
		private router: Router
	){}

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([{'label': 'Devices Purchase Order Management', 'url': ''}]);
		this.userService.currentUser.subscribe((user: User) => {
			this.currentUser = user;
			if (!this.currentUser.isACTuser && !this.userService.hasAccessFunction('edit_purchase_order')) {
				this.router.navigate(['/unauthorized']);
			}
		});
		this.deviceIDs = this.route.snapshot.params['deviceIDs'].split(',');

		this.adminService.getDevicePO(this.deviceIDs).subscribe((data: any) => {
			let formattedData = [];
			data.forEach((item) => {
				let tempObj = _.cloneDeep(item);
				tempObj.purchase_order = _.clone(item.PO);
				tempObj.old_purchase_order = _.clone(item.PO);
				formattedData.push(tempObj);
			});
			this.devices = formattedData;
		});
	}

	saveDevicePurchaseOrder() {
		this.adminService.saveDevicePO(this.devices).subscribe(() => {
			this.goBack();
		});
	};

	goBack() {
		this.navigation.goBack();
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}