import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdminService } from '../../admin.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-action-log',
	templateUrl: './action-log.component.html',
	styleUrls: ['./action-log.component.css']
})
export class ActionLogComponent implements OnInit, OnDestroy {

	logMonths = [];
	actionsLog = <any>[];
	firstActionID: number;
	lastActionID: number;
	pageNum: number;
	filter: any = {
		actionName: 'All'
	};

	actionNames = [
		"All", 'Manual User Edit', 'Manual User Permissions Edit', 'Manual User Temporary Permissions Edit', 'Manual User Add', 
		'User Delete', 'User Registration Confirmed', 'ACT Reset Password', 'Email Confirmation', 'Update User Access Functions',
		'User Edit his Profile', 'Become User', 'User Login Quantum', 'User Login ACTView', 'Push New BMS Firmware',
		'User Changed his Password', 'User Reset his Password', 'User Registration Rejected', 'Push New WiFi Firmware',
		'Site Add', 'Site Edit', 'Site Delete', 'Request Adding Site', 'Update Site Commodity', 'Modify Site ACT-Intelligent',
		'Approve Management Request (Site)', 'Edit Alerts Settings For Site', 'Edit Users Alerts Settings', 'Site Widgets Management',
		'Customer Add', 'Customer Edit', 'Customer Delete', 'Request Adding Customer', 'Unlock Faulted Upload Record',
		'Approve Management Request (Customer)', 'Customer Widgets Management', 'Edit BATTView Daughter Card', 'Commission BATTView Daughter Card',
		'Delete BATTView Daughter Card', 'Dealer Add', 'Dealer Edit', 'Delete Dealer', 'Truck Dealer Add', 'Truck Dealer Edit', 'Delete Truck Dealer',
		'OEM Edit', 'OEM Add', 'Delete OEM', 'Send Customized Email', 'Add Production Line IP', 'Delete Production Line IP',
		'Manual BATTView Add', 'Manual BATTView Reassign', 'Change BATTView Serial Number', 'Request Manage BATTView', 'Manual Manage BATTView',
		'Confirm Managing BATTView', 'Replace BATTView Quantum', 'Replace BATTView', 'Push New BATTViews Firmware', 'Assign BATTViews to East Penn',
		'Commission BATTView Original', 'Commission BATTView Duplicate', 'BATTView Programming (DB)', 'Replace BATTView on The Fly',
		'BATTView Programming (Queue)', 'Delete BATTView', 'Restart BATTView (Queue)', 'Remote BATTView Calibration (Queue)', 'Load Default Calibration',
		'Add BATTViews to ACT Production Line', 'Load PLC Firmware BATTView (Queue)', 'Delete Study', 'Setup Production Line BATTView as Replacement',
		'Manual Charger Add', 'Manual Charger Reassign', 'Change Charger Serial Number', 'Request Manage Charger', 'Manual Manage Charger',
		'Confirm Managing Charger', 'Replace MCB Quantum', 'Replace MCB', 'Push New Chargers Firmware', 'Push BMS FW (Queue)',
		'Commission MCB Original', 'Commission MCB Duplicate', 'Charger Programming (DB)', 'Load PLC Firmware Charger (Queue)',
		'Charger Programming (Queue)', 'Delete Charger', 'Restart Charger (Queue)', 'APECOR Login', 'Enable Full Access', 'Note Reminder Unsubscribe',
		'Equipment Add', 'Equipment Edit', 'Equipment Device Edit', 'Add Equipment Device', 'Edit Site DR Profile', 'Add Site DR Profile', 'Delete Site DR Profile',
		'Add DR Event', 'Add DR Event - Manual', 'Add DR Event - Weekly', 'Cancel DR Event', 'Delete DR Event', 'Add Disable Alert Temporary', 'Edit Disable Alert Temporary', 'Delete Disable Alert Temporary',
		'Schedule DR weekly events', 'Reset BATTView history', 'Renew PM Password', 'Bounced User Email', 'Generate Smart Reports Reports'
	];
	
	constructor(
		private adminService: AdminService,
		private commonUtil: CommonUtil,
		private breadcrumb: BreadcrumbService,
		private router: Router
	) {
	}

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([{'label': 'Action Logs', 'url': ''}]);
		let startMonth	= 10;
		let startYear	= 2016;
		let currDate	= new Date();
		let currMonth	= currDate.getMonth() + 1;
		let currYear	= currDate.getFullYear();

		for(let i = currYear; i >= startYear; i--) {
			for(let j = 12; j > 0; j--) {
				if(i == currYear && j > currMonth)
					continue;
				this.logMonths.push(this.commonUtil.lpad(String(j), 2) + '_' + i);
				if(i == startYear && j == startMonth)
					break;
			};
		};

		this.filter['logMonth'] = this.logMonths[0];
		this.resetPageVars();
		this.getActionLogs();
	}

	resetPageVars() {
		this.lastActionID	= 0;
		this.firstActionID	= 0;
		this.pageNum		= 0;
	}

	getActionLogs(options = {}) {
						
		options['filter'] = this.filter;

		this.adminService.getActionLogs(options).subscribe(
      		(data: Object[]) => {
				this.actionsLog = data;
				if(this.actionsLog.length > 0) {
					this.lastActionID	= this.actionsLog[this.actionsLog.length - 1].id;
					this.firstActionID	= this.actionsLog[0].id;

					if(options['prev'])
						this.pageNum--;
					else
						this.pageNum++;
				}
			}	
		);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}