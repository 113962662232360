<div
	id="{{widgetId}}"
	class="panel panel-default panel-widget {{widgetClass}} margin-bottom-xs"
	[ngClass]="{'panel-collapsed': isCollapsed, 'auto-height-widget': isCollapsed}">
	<div class="panel-heading drag-handle">
		{{title}}
		<a 
			class="pull-right noprint"
			href="javascript:">
			<i 
				*ngIf="!isExpanded" 
				(click)="toggleCollapse()"
				class="fa"
				[ngClass]="{'fa-minus': !isCollapsed, 'fa-plus': isCollapsed}">
			</i>
		</a>
		<a 
			*ngIf="options.hasExpand && !isCollapsed && !isExpanded"
			href="javascript:"
			class="btn btn-primary btn-xs no-print pull-right margin-right-sm"
			(click)="expand()">
		  <span class="glyphicon glyphicon-fullscreen"></span>
	   </a>
	</div>
	<div 
		class="panel-body"
		[hidden]="isCollapsed">
		<div class="row noprint">
			<div class="col-xs-4 no-gutter">
				<a
				class="btn btn-xs margin-left-sm"
				(click)="onPrint()"
				href="javascript:" 
				*ngIf="options.hasPrint">
					<i class="glyphicon glyphicon-print"></i>
				</a>
				<a
				class="btn btn-xs margin-left-sm"
				(click)="onExport()"
				href="javascript:" 
				*ngIf="options.hasExport">
					<i class="fa fa-lg fa-file-excel-o"></i>
				</a>
			</div>
			<div class="col-xs-4 col-xs-offset-4">
				<select 
					*ngFor="let dropDownGroup of dropDownGroupList"
					class="form-control input-sm"
					[(ngModel)]="selectedDropDown[dropDownGroup.name]"
					(change)="dropDownChange(dropDownGroup.name, $event.target.value)">
						<option
							*ngFor="let option of dropDownGroup.options"
							[value]="option.value">
							{{option.label}}
						</option>
				</select>
			</div>
		</div>
		<div class="row noprint">
			<div class="col-lg-12 margin-top-sm">
				<label *ngFor="let checkbox of checkboxList">
					<input 
						type='checkbox'
						name='{{checkbox.name}}'
						class="form-control inline-form-control" 
						[(ngModel)]="selectedCheckbox[checkbox.name]"
						(ngModelChange)="checkboxChange(checkbox.name, $event)"
							/> {{checkbox.label}} &nbsp;
				</label>
			</div>
			<div 
				*ngFor="let radioGroup of radioButtonGroupList"
				class="col-lg-12 margin-top-sm">
					<label
						*ngFor="let radio of radioGroup['options']">
						<input 
							type='radio'
							name='{{radioGroup.name}}'
							value='{{radio.value}}'
							class="form-control"
							[(ngModel)]="selectedRadioButton[radioGroup.name]"
							(ngModelChange)="radioButtonChange(radioGroup.name, selectedRadioButton[radioGroup.name])"
							 /> {{radio.label}}
					</label>
			</div>
		</div>
		<div class="row margin-top-sm noprint" *ngIf="tags && tags.length > 0">
			<div class="col-md-5">
				<ng-select
					[multiple]="tagsSettings.isMultiple"
					[items]="tags"
					[addTag]="false"
					[clearable]="false"
					[hideSelected]="true"
					bindLabel="tagname"
					bindValue="id"
					(add)="tagAdd($event)"
					(remove)="tagRemove($event)"
					[placeholder]="tagsSettings.placeholder"
					[(ngModel)]="selectedTags">
				</ng-select>
			</div>
		</div>
		<!-- <ng-content></ng-content> -->
		<ng-container *ngTemplateOutlet="transcludeTemplate"></ng-container>
	</div>
</div>

<!-- repeated to bind modal data  -->
<app-modal *ngIf="options.hasExpand" #modal size="lg" [title]="title" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div
	id="{{widgetId}}"
	class="panel panel-default panel-widget {{widgetClass}} margin-bottom-xs"
	[ngClass]="{'panel-collapsed': isCollapsed, 'auto-height-widget': isCollapsed}">
	<div class="panel-heading drag-handle">
		{{title}}
		<a 
			class="pull-right noprint"
			href="javascript:">
			<i 
				*ngIf="!isExpanded" 
				(click)="toggleCollapse()"
				class="fa"
				[ngClass]="{'fa-minus': !isCollapsed, 'fa-plus': isCollapsed}">
			</i>
		</a>
		<a 
			*ngIf="options.hasExpand && !isCollapsed && !isExpanded"
			href="javascript:"
			class="btn btn-primary btn-xs no-print pull-right margin-right-sm"
			(click)="expand()">
		  <span class="glyphicon glyphicon-fullscreen"></span>
	   </a>
	</div>
	<div 
		class="panel-body"
		[hidden]="isCollapsed">
		<div class="row noprint">
			<div class="col-xs-4">
				<a
				class="btn btn-xs"
				(click)="onPrint()"
				href="javascript:" 
				*ngIf="options.hasPrint">
					<i class="glyphicon glyphicon-print"></i>
				</a>
				<a
				class="btn btn-xs margin-left-sm"
				(click)="onExport()"
				href="javascript:" 
				*ngIf="options.hasExport">
					<i class="fa fa-lg fa-file-excel-o"></i>
				</a>
			</div>
			<div class="col-xs-4 col-xs-offset-4">
				<select 
					*ngFor="let dropDownGroup of dropDownGroupList"
					class="form-control input-sm"
					[(ngModel)]="selectedDropDown[dropDownGroup.name]"
					(change)="dropDownChange(dropDownGroup.name, $event.target.value)">
						<option
							*ngFor="let option of dropDownGroup.options"
							[value]="option.value">
							{{option.label}}
						</option>
				</select>
				<!-- <ui-select ng-if="options.hasDataTypeFilter" multiple ng-model="options.dataTypeModel" title="Data Types" class="margin-top-xs">
					<ui-select-match placeholder="Select Data Types">
						<span class="ng-cloak">{{$item.label ? $item.label : "Alert"}}</span>
					</ui-select-match>
					<ui-select-choices repeat="type.id as type in (options.dataTypes | propertyFilter: {label: $select.search})">
						<span ng-bind="(type.label)"></span>
					</ui-select-choices>
				</ui-select> -->
			</div>
		</div>
		<div class="row noprint">
			<div class="col-lg-12">
				<label *ngFor="let checkbox of checkboxList">
					<input 
						type='checkbox'
						name='{{checkbox.name}}'
						class="form-control inline-form-control" 
						[(ngModel)]="selectedCheckbox[checkbox.name]"
						(ngModelChange)="checkboxChange(checkbox.name, $event)"
							/> {{checkbox.label}}
				</label>
			</div>
			<div 
				*ngFor="let radioGroup of radioButtonGroupList"
				class="col-lg-12">
					<label
						*ngFor="let radio of radioGroup['options']">
						<input 
							type='radio'
							name='{{radioGroup.name}}'
							class="form-control" 
							[(ngModel)]="selectedRadioButton[radioGroup.name]"
							(change)="radioButtonChange(radio.name, $event.target.value)"
							 /> {{radio.label}}
					</label>
			</div>
		</div>
		<!-- <div ng-show="options.hasTags" class="noprint">
			<tags-input ng-model="tags" display-property="tagname" key-property="tagname" max-length="127" on-tag-added="tagAdded($tag)" on-tag-removing="tagRemoved($tag)" add-on-comma="false" placeholder="Filter By Tag" add-from-autocomplete-only="true" tag-class="'auto-height-widget word-break-btn'">
				<auto-complete source="siteTags" max-results-to-show="100"></auto-complete>
			</tags-input>
		</div> -->

		<!-- <ng-content></ng-content> -->
		<ng-container *ngTemplateOutlet="transcludeTemplate"></ng-container>
	</div>
</div>

	<!-- add the same -->
</app-modal>
