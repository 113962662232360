<div class="actfirst-chargers-control-bg">
	<div class="row"> 
		<div>
			<div class="col-lg-6 margin-top-5" *ngFor="let col of ['charger_faulted', 'battery_faulted', 'user_faulted', 'in_maintenance']">
				<div class="charger-control" [ngClass]="{'custom-gutter': col != 'user_faulted'}">
					{{ getText(col) }}
				</div>
				<div class="col-content">
					<div *ngIf="data[col].length == 0; else items" class="no-items">{{'act.no_items' | translate}}</div>
					<ng-template #items>
						<div *ngFor="let item of data[col]" class="row faulted-item">
							<div class="col-sm-3 col-xs-6 left-container">
								<img src="/images/faulted.png">
							</div>
							<div class="col-md-6">
								<div 
									class="pointer"
									[routerLink]="item['link']" [queryParams]="item['queryParams']"><strong>{{ item['name'] }}</strong></div>
								<div class="sit-down">{{item['groupname']}}</div>
							</div>
							<div class="col-md-2 no-gutter-left col-xs-push-3 col-sm-push-0 col-xs-btn">
								<button
									*ngIf="col != 'in_maintenance' && col == 'charger_faulted' && item['connected']"
									(click)="restartCharger(item, col)"
									class="charger-restart-btn"></button>
								<button 
									*ngIf="col != 'in_maintenance' && col != 'charger_faulted' && item['voltageRangeStatus'] == 1 && item['connected'] && item['exit_status'] != '18' && item['exit_status'] != '17' && !item['energyManagmentType']" 
									[ngClass]="{'lcd-restart-blue-btn': col == 'battery_faulted', 'lcd-restart-orange-btn': col == 'user_faulted'}"
									class="col-xs-push-3 col-sm-push-0 col-xs-btn"
									(click)="startLCDCycle(item, col)"></button>
								<button
									*ngIf="col != 'in_maintenance'"
									(click)="moveToMaintenance(item, col)"
									class="col-xs-push-3 col-sm-push-0 col-xs-btn btn btn-warning margin-bottom-xs">
										<img 
											src="/images/maintenance.png"
											height="35"
											width="35"
											title="{{'act.move_to_maintenance' | translate}}">
								</button>
								<div
									class="pointer"
									*ngIf="col != 'in_maintenance' && !item['energyManagmentType'] && item['exit_status'] != '18' && !item.unplugged"
									(click)="preventAlertUnplug(item.id)">
									<button class="btn btn-primary">
										<img 
											src="/images/unplug_white.png"
											height="35"
										  width="40">
										</button>
								</div>
							</div>
						</div>
					</ng-template>
				</div>
			</div>
		</div>
	</div>
</div>