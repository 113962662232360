<div class="ui-helper-clearfix no-print" *ngIf="settings['hasExport'] || settings['hasReset']">
	<div class="dropdown pull-right" appDropdown>
		<i type="button" class="btn btn-primary btn-xs dropdown-toggle fa fa-bars" dropdown-toggle></i>
		<ul class="dropdown-menu dropdown-menu-custom" role="menu">
			<li class="dropdown-header">{{'g.actions' | translate}}</li>
			<li *ngIf="settings['hasExport']">
				<a (click)="onBtExport()"><i class="fa fa-download margin-right-xs"></i>{{'g.export_data' | translate}}</a>
			</li>
			<li *ngIf="settings['hasReset']">
				<a (click)="onReset()"><i class="fa fa-repeat margin-right-xs"></i>{{'g.reset' | translate}}</a>
			</li>
			<li class="divider" *ngIf="settings['hasSelectColumn']"></li>
			<li class="dropdown-header" *ngIf="settings['hasSelectColumn']">{{'g.columns' | translate}}</li>
			<ng-container *ngIf="settings['hasSelectColumn']">
				<li *ngFor="let col of columns">
					<a class="pointer" (click)="toggleColumn(col)"><i class="fa" [ngClass]="col.hide ? 'fa-times' : 'fa-check'"></i> {{col.headerName}}</a>
				</li>
			</ng-container>
		</ul>
	</div>
</div>

<ag-grid-angular 
	class="ag-theme-balham no-print"
	[ngStyle]="{'height': (settings.height == +settings.height) ? settings.height+'px' : settings.height}" 
	[rowData]="data" 
	[columnDefs]="columns"
	[suppressCsvExport]="!settings['hasExport']"
	[pagination]="settings['pagination']"
	[paginationPageSize]="settings['paginationPageSize']"
	[defaultColDef]="{
		sortable: true,
		filter: true
	}"
	[domLayout]="settings['domLayout']"
	(gridReady)="onGridReady($event)"
	#agGrid
	id="myGrid"
	[rowSelection]="'multiple'"
	[rowMultiSelectWithClick]="true"
	[columnTypes]="customizedColumnTypes"
	[frameworkComponents]="frameworkComponents"
	[components]="component"
	(sortChanged)="onSort()"
	(selectionChanged)="selectionChanged($event)"
	(filterChanged)="onFilter($event)"
>
</ag-grid-angular>
<div class="ag-status-panel ag-status-panel-aggregations no-print" *ngIf="gridApi && settings.showSummary">
	<div class="ag-name-value" key="count" default-value="Count" ref="countAggregationComp">
		<span *ngIf="gridApi.getDisplayedRowCount() != totalItemsCount"> {{'g.showing_items' | translate}}: {{gridApi.getDisplayedRowCount()}}</span>
		{{'g.total_items' | translate}}: {{totalItemsCount}} <span *ngIf="gridApi.getSelectedRows().length > 0">({{'g.selected' | translate}}: {{gridApi.getSelectedRows().length}} )</span>
	</div>
</div>