import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'underscore';
import { ActivatedRoute } from '@angular/router';
import { DeviceManagementService } from '../../device-management.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-devices-connectivity',
	templateUrl: './devices-connectivity.component.html',
	styleUrls: ['./devices-connectivity.component.css']
})
export class DevicesConnectivityComponent implements OnInit, OnDestroy {

	customerID = 0;
	siteID = 0;
	connectivity = [];
	customerNameFilter = '';

	constructor(
		private route: ActivatedRoute,
		private commonUtil: CommonUtil,
		private deviceManagemntService: DeviceManagementService,
		private breadcrumb: BreadcrumbService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([{'label': this.translateService.instant('site.devices_connectivity'), 'url': ''}]);
		this.route.queryParams.subscribe( params => {
			this.customerID = Number(params['customerID']) || 0;
			this.siteID = Number(params['siteID']) || 0;
		});
		this.getDevicesConnectivity();
	}

	getDevicesConnectivity() {
		this.deviceManagemntService.getDevicesConnectivity().subscribe(
			(data: any) => {
				data.forEach((customer) => {
					customer.sites = this.commonUtil.arrayToAssociativeArray(_.sortBy(customer.sites, 'test_site'), 'id');
				});
				this.connectivity = data;
				let selectedCustomer = [];
				if(this.customerID) {
					selectedCustomer = this.connectivity.filter((obj) => {return obj.id == this.customerID;});
					if(selectedCustomer.length > 0)
						this.toggleExpanded(selectedCustomer[0]);
				}
				if(this.siteID && selectedCustomer.length > 0 && selectedCustomer[0]['sites']) {
					let selectedSite = selectedCustomer[0]['sites'][this.siteID];
					if(selectedSite)
						this.toggleExpanded(selectedSite);
				}
			}
		)
	}

	toggleExpanded(item){
		if(item.expanded)
			item.expanded = false;
		else
			item.expanded = true;
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}