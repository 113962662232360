import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { DeviceManagementService } from '../../device-management.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-production-line-ips',
  templateUrl: './production-line-ips.component.html',
  styleUrls: ['./production-line-ips.component.css']
})
export class ProductionLineIpsComponent implements OnInit, OnDestroy {

  IPaddress: string;
  ipAddresses: any[]; //| orderBy: 'ip'

  constructor(
    private deviceManagemntService: DeviceManagementService,
    // private route: ActivatedRoute,
    private breadcrumb: BreadcrumbService,
    private notificationMessage: NotificationMessageService,
    // private navigation: NavigationService,
    // private router: Router,
    // private commonUtil: CommonUtil,
	  // private userService: UserService,
	  private translateService: TranslateService
  ) {
    this.breadcrumb.setBreadcrumb([
      {label: this.translateService.instant('act.act_production_ip_address'), url: ''}
    ]);
  }

  ngOnInit() {
    this.getProductionAllowedIPs();
    
  }

  editProductionLineIPs(action, value) {
    this.deviceManagemntService.editProductionLineIPs(action, value).subscribe(
      response => {
		if (response === 'duplicateIP') 
			this.notificationMessage.setMessage('translate|g.duplicate_ip_address', 'danger', 10000, true);
		else {
			this.notificationMessage.setMessage(response, 'success', 10000, true);
			this.getProductionAllowedIPs();
		}
      }
    );
  }

  getProductionAllowedIPs() {
    this.deviceManagemntService.getProductionAllowedIPs().subscribe(
      (response: any[]) => {
        this.ipAddresses = response;
      }
    );
  }

  ngOnDestroy() {
		this.breadcrumb.hide();
	}
}