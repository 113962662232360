<div id="page-wrapper" >
  <div id="addnewPanel" *ngIf="userService.hasAccessFunction('manage_equipment','write')">
    <button class="btn btn-primary mb20" type="button" [routerLink]="['/equipments','edit','equipmentId',0]">
      <span class="glyphicon glyphicon-user"></span> {{'g.add_new' | translate}} <span class="glyphicon glyphicon-plus"></span>
    </button>
  </div>
  <div class="boxs">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>{{'g.name' | translate}}</th>
          <th>{{'g.action' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let equipment of equipments">
          <td>{{equipment.name}}</td>
          <td>
            <div class="btn-group" appDropdown *ngIf="userService.hasAccessFunction('manage_equipment','write')">
                <i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
                <ul class="dropdown-menu" role="menu">
                  <li><a [routerLink]="['/equipments','edit','equipmentId', equipment.id]">{{'g.edit' | translate}}</a></li>
                </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>