<div class="row">
  <div id="page-wrapper" >
    <div id="page-inner">
      <div class="panel panel-default">
        <div class="col-md-1 r padding-8">
          <button class="form-control btn btn-success" (click)="getData()">Get Logs</button>
        </div>
        <div class="boxs3">
          <h2>Battview Queue</h2>
          <div *ngIf="faultQueueLog.battviews.length > 0">
            <table class="table table-stripped boxs">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Request Name</th>
                  <th>Device ID</th>
                  <th>Upload Error</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let log of logDataBattview">
                  <td>{{log.time_of_request | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
                  <td>{{mapRequestType(log.request_type)}}</td>
                  <td>{{log.device_id}}</td>
                  <td>{{log.upload_error}}</td>
                  <td>
                    <button (click)="unlock('battview',log.device_id)" class="btn btn-default">Unlock</button>
                    <a [routerLink]="['battview', log.id]" target="_blank" class="btn btn-info" *ngIf="user.accessFunctions?.view_error_log">Show Data</a>
                    <button class="btn btn-danger" (click)="delete('faulted_queue_log_battview', log.id)"><span class="glyphicon glyphicon-trash" aria-hidden="true"></span></button>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination [allItems]="faultQueueLog.battviews" (onPageChanged)="loadData($event, 'battview')" [count]="numLogsPerPage"></pagination>
          </div>
          <div *ngIf="faultQueueLog.battviews.length == 0">
            <h3>No Fault In Queue!</h3>
          </div>
        </div>
        <div class="boxs3">
          <h2>Charger Queue</h2>
          <div *ngIf="faultQueueLog.chargers.length > 0">
            <table class="table table-stripped boxs">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Request Name</th>
                  <th>Device ID</th>
                  <th>Upload Error</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let log of logDataCharger">
                  <td>{{log.time_of_request | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
                  <td>{{mapRequestType(log.request_type)}}</td>
                  <td>{{log.device_id}}</td>
                  <td>{{log.upload_error}}</td>
                  <td>
                    <button (click)="unlock('charger',log.device_id)" class="btn btn-default">Unlock</button>
                    <a [routerLink]="['charger', log.id]" target="_blank" class="btn btn-info" *ngIf="user.accessFunctions?.view_error_log">Show Data</a>
                    <button class="btn btn-danger" (click)="delete('faulted_queue_log_charger', log.id)"><span class="glyphicon glyphicon-trash" aria-hidden="true"></span></button>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination [allItems]="faultQueueLog.chargers" (onPageChanged)="loadData($event, 'charger')" [count]="numLogsPerPage"></pagination>
          </div>
          <div *ngIf="faultQueueLog.chargers.length == 0">
            <h3>No Fault In Queue!</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>