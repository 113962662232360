import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SideMenuService } from '../../../../../shared/side-menu/side-menu.service';
import { UserService } from '../../../../../auth/user.service';

@Component({
	selector: 'app-charger-tabs',
	templateUrl: './charger-tabs.component.html',
	styleUrls: ['./charger-tabs.component.css']
})
export class ChargerTabsComponent implements OnInit {

	customerInfo: any = {};
	currentUser: any = {};
	@Input() charger: any = {};
	@Input() activePage: string;
	@Input() analyticsTab: string;
	
	@Input() chargerID: number;
	@Input() customerID: number = 0;
	@Input() siteID: number = 0;

	constructor(
		private sideMenu: SideMenuService,
		private route: ActivatedRoute,
		private router: Router,
		public userService: UserService
	) { }

	ngOnInit() {
		this.sideMenu.currentCustomer.subscribe(data => {
			this.customerInfo = data;
		});
	
		this.currentUser = this.userService.getCurrentUser();
	}

	isAnalyticsSet(tab) {
		if(this.activePage == 'analytics' && tab == this.analyticsTab)
			return true;
		return false;
	}
}
