<div>
	<div *ngIf="userService.hasAccessFunction('act_user_management')">
		<button class="btn btn-primary mb20" type="button" [routerLink]="['edit', 'dealerId', 0]">
			<span class="glyphicon glyphicon-user"></span> Add New <span class="glyphicon glyphicon-plus"></span>
		</button>
	</div>
	<div class="boxs">
		<table class="table table-striped">
			<thead>
				<tr>
					<th width="80%">Name</th>
					<th width="20%" *ngIf="userService.hasAccessFunction('act_user_management')">Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let dealer of dealers">
					<td>{{dealer.name}}</td>
					<td *ngIf="userService.hasAccessFunction('act_user_management')">
						<div class="btn-group" appDropdown>
							<i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
							<ul class="dropdown-menu" role="menu">
								<li><a class="pointer" [routerLink]="['edit', 'dealerId', dealer.id]">Edit</a></li>
								<li><a class="pointer" (click)="deleteDealer(dealer.id)">Delete</a></li>
								<li class="pointer" *ngIf="currentUser.isACTuser">
									<a [routerLink]="['/equipments-devices', (isTruckDealer ? 'truck-dealer' : 'dealer'), dealer.id]">Equipments</a>
								</li>
							</ul>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>