<div class="row">
	<div class="col-md-12 panel panel-default">
		<div class="panel-body">
			<div class="padding-8">
				<input [(ngModel)]="serialnumber" placeholder="{{'device.serial_number' | translate}}" class="form-control"/>
			</div>
			<div class="padding-8">
				<label><input type='checkbox' class="form-control" [(ngModel)]="isPartial"/> {{'g.partial_search' | translate}}</label>
			</div>
			<div class="padding-8">
				<button class="btn btn-success tcenter" (click)="getPmBySN()" [disabled]="!serialnumber">{{'device.search_for_pms' | translate}}</button>
			</div>
			<div *ngIf="noResult" class="padding-8">
				{{'g.no_results_found' | translate}}
			</div>
			<table class="table table-striped padding-8" *ngIf="devicesInfo.length > 0">
				<thead>
					<tr>
						<th>{{'g.db_id' | translate}}</th>
						<th>{{'device.serial_number' | translate}}</th>
						<th>{{'device.last_known_charger' | translate}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let deviceInfo of devicesInfo">
						<td>{{deviceInfo.id}}</td>
						<td><a [routerLink]="['/', 'pm-info', deviceInfo.serialnumber]">{{deviceInfo.serialnumber}}</a></td>
						<td>
							<div *ngIf="deviceInfo.lastCharger">
								{{deviceInfo.lastCharger.chargerusername}} <a *ngIf="!deviceInfo.lastCharger.isdeleted && !deviceInfo.lastCharger.replacmentpart && deviceInfo.lastCharger.customerid > 0 && deviceInfo.lastCharger.siteid > 0; else noLink" [routerLink]="['/', deviceInfo.lastCharger.customerid, deviceInfo.lastCharger.siteid, 'chargers', 'analytics', 'cycles', deviceInfo.lastCharger.id]">({{deviceInfo.last_known_charger}})</a>
							</div>
							<div *ngIf="!deviceInfo.lastCharger">
								{{deviceInfo.last_known_charger}}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>