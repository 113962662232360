import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { DevicesDashboardService } from '../devices-dashboard.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import * as _ from 'underscore';


@Component({
	selector: 'app-debug-records',
	templateUrl: './debug-records.component.html',
	styleUrls: ['./debug-records.component.css']
})
export class DebugRecordsComponent implements OnInit, OnChanges {

	@Input() device: any = {};
	@Input() deviceType: string = null;
	@Input() date: {
		fromDate: any,
		toDate: any
	} = {
		fromDate: null,
		toDate: null
	};

	@Output() updateAppearanceElementsFlags = new EventEmitter<any>(true);

	gridColumns: any;
	gridData: any[] = [];

	constructor(
		private devicesDashboardService: DevicesDashboardService,
		private commonUtil: CommonUtil,
	) {
		this.gridColumns = [
			{headerName: "ID", field: "recordid", type:"number", width: 120},
			{headerName: "Time", field: "recordtime", width: 200, filterParams: {defaultOption: 'startsWith'}},
			{headerName: "Info", field: "infoText", width: 350},
		];
	}

	ngOnInit() {
		if(this.deviceType =='battview') {
			this.updateAppearanceElementsFlags.emit({
				addNoteEnabled: false,
				pageHasFilterByDate: true,
				pageHasBattviewTags: true,
				hasResetPLC: false,
				pageHasManageBtn: true,
				pageHasTimesInfo: true,
			});
		}
		this.getDebugRecords();
	}

	ngOnChanges() {
		this.getDebugRecords();
	}
	onDeviceChanged(device) {
		this.device = device;
		this.getDebugRecords();
	}
	onDatesUpdated(date) {
		this.date = date;
		this.getDebugRecords();
	}

	getDebugRecords() {
		
		if(!this.device.id || !this.date.fromDate || !this.date.toDate)
			return;

		var zoneDiff		= new Date().getTimezoneOffset() * -1;
		var fromDate:any	= new Date(new Date(this.date.fromDate).getTime() + (zoneDiff * 60 * 1000));
		var toDate:any		= new Date(new Date(this.date.toDate).getTime() + (zoneDiff * 60 * 1000));
		fromDate			= moment(fromDate).utc().startOf('day').unix();
		toDate				= moment(toDate).utc().endOf('day').unix();

		this.devicesDashboardService.getDebugRecords(this.device.id, this.deviceType, fromDate, toDate, this.device.studyid).subscribe((response: any) => {
			response.forEach((record, idx) => {
				if(record.corrupted) {
					response.splice(idx, 1);
					idx--;
				} else {

					if(this.deviceType == 'battview') {
						response[idx].infoText		= this.commonUtil.getDebugRecordsInfoText(record.debug);
						response[idx].recordtime	= this.commonUtil.getDateFormattedFromUnixTimeStamp(new Date(Date.parse(record.recordtime)).getTime()/1000);
					} else {
						response[idx].infoText		= this.commonUtil.getMCBdebugRecordsInfoText(record.debug);
						var zoneRecordTime			= this.commonUtil.getZoneTimestampFromUTC(this.device.zoneid, moment(record.recordtime).utc().unix(), this.device.daylightsaving);
						response[idx].recordtime	= this.commonUtil.getDateFormattedFromUnixTimeStamp(zoneRecordTime);
					}
				}
			});

			response = _.sortBy(response, 'recordid').reverse();
			if(this.deviceType == 'charger') {
				if(this.device.error_code > 0) {
					let lastErrorTime = this.commonUtil.getZoneTimestampFromUTC(this.device.zoneid, moment(this.device.last_error_time).utc().unix(), this.device.daylightsaving);
					
					response.unshift({
						infoText: this.commonUtil.getChargerErrorText(this.device.error_code),
						recordtime: this.commonUtil.getDateFormattedFromUnixTimeStamp(lastErrorTime),
						recordid: ''
					});
				}
			} else {
				//battview
				if(this.device.bv_error_code > 0) {
					let lastErrorTime = this.commonUtil.getZoneTimestampFromUTC(this.device.zoneid, moment(this.device.bv_last_error_time).utc().unix(), this.device.daylightsaving);
					
					response.unshift({
						infoText: this.commonUtil.getBattviewErrorText(this.device.bv_error_code),
						recordtime: this.commonUtil.getDateFormattedFromUnixTimeStamp(lastErrorTime),
						recordid: ''
					});
				}
			}
			this.gridData = response;
		});
	};
}