<app-modal #profileModal [title]="modalTitle" [hasConfirmButton]="false" [hasCloseButton]="false">
	<form #editProfile="ngForm" novalidate>
		<div class="modal-body">
			<div class="col-md-12">
				<div class="row">
					<div class="col-md-5"><strong>Operation</strong><span class="red-text" *ngIf="missedRequiredField(editProfile, 'operation')"> *</span></div>
					<div class="col-md-7">
						<select class="form-control" name="operation" [(ngModel)]="modalProfile.operation" required (ngModelChange)="operationTypeChanged()">
							<option *ngFor="let type of operationTypes | values" [value]="type">{{type | titlecase}}</option>
						</select>
					</div>
				</div>
			
				<div class="row margin-top-lg" *ngIf="modalProfile.operation != operationTypes['lockout']">
					<div class="col-md-5"><strong> Power Setting</strong><span class="red-text" *ngIf="missedRequiredField(editProfile, 'operation_power')"> *</span></div>
					<div class="col-md-7">
						<div class='input-group'>
							<input type="number" required min="1" max="12000" name="operation_power" class="form-control currency" [(ngModel)]="modalProfile.operation_power"/>
							<span class="input-group-addon">KW</span>
						</div>
					</div>
				</div>
				
				<div class="row margin-top-lg">
					<div class="col-md-5"><strong>Max Duration</strong><span class="red-text" *ngIf="missedRequiredField(editProfile, 'max_duration')"> *</span></div>
					<div class="col-md-7">
						<div class='input-group'>
							<input type="number" min="60" max="32000000" name="max_duration" class="form-control" [(ngModel)]="modalProfile.max_duration" required/>
							<span class="input-group-addon">Seconds</span>
						</div>
					</div>
				</div>
			
				<div class="row margin-top-lg" *ngIf="modalProfile.type == profileTypes['None']">
					<div class="col-md-5"><strong>Name</strong></div>
					<div class="col-md-7">
						<input [(ngModel)]="modalProfile.name" name="name" class="form-control"/>
					</div>
				</div>

				<div class="row margin-top-lg">
					<div class="col-md-5"><strong>Type</strong></div>
					<div class="col-md-7" *ngIf="currentSiteProvider == 'VOLTUS'">
						{{modalProfile.type}}
					</div>
					<div class="col-md-7" *ngIf="currentSiteProvider == 'ENERNOC' && this.hasENERNOCProvider">
						<label><input type="radio" [(ngModel)]="modalProfile.type" name="type" class="form-control" value="SRM"/> SRM</label>
						<label><input type="radio" [(ngModel)]="modalProfile.type" name="type" class="form-control" value="ELRP"/> ELRP</label>
						<label><input type="radio" [(ngModel)]="modalProfile.type" name="type" class="form-control" value="ELRP Audit"/> ELRP Audit</label>
						<label><input type="radio" [(ngModel)]="modalProfile.type" name="type" class="form-control" value="Audit Test"/> Audit Test</label>
					</div>
				</div>

				<div class="row margin-top-lg">
					<div class="col-md-5"><strong>Provider</strong></div>
					<div class="col-md-7">
						{{this.hasENERNOCProvider ? currentSiteProvider : modalProfile.provider}}
					</div>
				</div>

				<div class="red-text row col-md-12 margin-top-lg" *ngIf="!allFormRequiredFilled(editProfile)">
					* Please fill all required fields.
				</div>

				<div class="red-text row col-md-12 margin-top-sm">
					<p *ngFor="let msg of errMsgs">
						* {{msg}}.
					</p>
				</div>
			</div>
		</div>
		<div class="modal-footer padding-8">
			<button type="button" class="btn btn-primary" (click)="updateSiteProfile(modalProfile, modalProfile.action)" [disabled]="editProfile.invalid">Submit</button>
			<button type="button" class="btn btn-default" (click)="profileModal.hide()">Cancel</button>
		</div>
	</form>
</app-modal>

<app-modal #executeProfileModal title="Profile New Event" [hasConfirmButton]="false" [hasCloseButton]="false">
	<form #newEvent="ngForm" novalidate>
		<div class="modal-body">
			<div class="col-md-12">
				<div class="row">
					<div class="col-md-5"><strong>Start Time(UTC/GMT)<span class="red-text" *ngIf="start.invalid"> *</span></strong></div>
					<div class="col-md-7">
						<input type="datetime-local" class="form-control inline-form-control" name="start" required [(ngModel)]="modalExecuteProfile.startTime" #start="ngModel">
					</div>
				</div>
			</div>
			<div class="col-md-12 margin-top-lg">
				<div class="row">
					<div class="col-md-5"><strong>End Time(UTC/GMT)<span class="red-text" *ngIf="end.invalid"> *</span></strong></div>
					<div class="col-md-7">
						<input type="datetime-local" class="form-control inline-form-control" name="end" required [(ngModel)]="modalExecuteProfile.endTime" #end="ngModel">
					</div>
				</div>
			</div>
			
			<div class="red-text row col-md-12 margin-top-lg" *ngIf="!allFormRequiredFilled(newEvent)">
				* Please fill all required fields.
			</div>

			<div class="col-md-12 margin-top-lg">
				<div class="red-text">
					<p *ngFor="let msg of executeProfileErrMsgs">
						* {{msg}}.
					</p>
				</div>
			</div>
		</div>
		<div class="modal-footer padding-8">
			<button type="button" class="btn btn-primary" (click)="executeProfile()" [disabled]="newEvent.invalid">Add</button>
			<button type="button" class="btn btn-default" (click)="executeProfileModal.hide()">Cancel</button>
		</div>
	</form>
</app-modal>

<app-modal #executeWeeklyProfileModal title="Execute Weekly Profile" [hasConfirmButton]="false" [hasCloseButton]="false">
	<form #newEvent="ngForm" novalidate>
		<div class="modal-body">
			<div class="col-md-12 margin-bottom-lg">
				<div class="row">
					<div class="col-md-5"><strong>Day<span class="red-text" *ngIf="days.invalid"> *</span></strong></div>
					<div class="col-md-7">
						<select multiple name="days" [(ngModel)]="modalExecuteWeeklyProfile.days" class="form-control" size="7" #days="ngModel">
							<option *ngFor="let day of daysOfTheWeek" [ngValue]="day.id">{{day.text}}</option>
						</select>
					</div>
				</div>
			</div>
			
			<div class="col-md-12">
				<div class="row">
					<div class="col-md-5"><strong>Start Time<span class="red-text" *ngIf="start.invalid"> *</span></strong></div>
					<div class="col-md-7">
						<input type="time" class="form-control inline-form-control" name="start" [(ngModel)]="modalExecuteWeeklyProfile.startTime" #start="ngModel">
					</div>
				</div>
			</div>

			<div class="col-md-12 margin-top-lg">
				<div class="row">
					<div class="col-md-5"><strong>End Time<span class="red-text" *ngIf="end.invalid"> *</span></strong></div>
					<div class="col-md-7">
						<input type="time" class="form-control inline-form-control" name="end" [(ngModel)]="modalExecuteWeeklyProfile.endTime" #end="ngModel">
					</div>
				</div>
			</div>

			<div class="col-md-12 margin-top-lg">
				<div class="red-text">
					<p *ngFor="let msg of executeProfileErrMsgs">
						* {{msg}}.
					</p>
				</div>
			</div>
		</div>
		<div class="modal-footer padding-8">
			<button type="button" class="btn btn-primary" (click)="executeWeeklyProfile()" [disabled]="modalExecuteWeeklyProfile.days?.length == 0 && !modalExecuteWeeklyProfile.startTime && !modalExecuteWeeklyProfile.endTime">
				<span *ngIf="weeklyProfilesWithData[modalExecuteWeeklyProfile.profileId]">Update</span>
				<span *ngIf="!weeklyProfilesWithData[modalExecuteWeeklyProfile.profileId]">Add</span>
			</button>

			<button *ngIf="weeklyProfilesWithData[modalExecuteWeeklyProfile.profileId]" type="button" class="btn btn-danger" (click)="descheduleWeeklyProfile()">Deschedule</button>
			<button type="button" class="btn btn-default" (click)="executeWeeklyProfileModal.hide()">Cancel</button>
		</div>
	</form>
</app-modal>

<div id="tab-content">
	<div class="container-fluid boxs3">
		<div class="margin-bottom-xlg">
			<div class="margin-top-lg" *ngIf="drProfiles.none.length == 0">
				<button class="btn btn-primary mb20" type="button" (click)="openProfileModal()">Add New <span class="glyphicon glyphicon-plus"></span></button>
			</div>
			<div class="row margin-top-xlg margin-bottom-lg">
				<div class="col-md-2">
					<strong>Operation</strong>
				</div>
				<div class="col-md-2">
					<strong>Operation Power (KW)</strong>
				</div>
				<div class="col-md-2">
					<strong>Max Duration</strong>
				</div>
				<div class="col-md-3">
					<strong>Name</strong>
				</div>
				<div class="col-md-2">
					<strong>Type/Provider</strong>
				</div>
				<div class="col-md-1">
					<strong>Options</strong>
				</div>
			</div>

			<div class="row margin-bottom-lg" *ngFor="let profile of drProfiles.normal; let idx = index">
				<ng-container *ngTemplateOutlet="drProfile; context:{profile: profile, idx: idx, section: 'normal'}"></ng-container>
			</div>

			<div class="margin-top-xxlg" *ngIf="drProfiles.none.length > 0">
				<div class="row blue-border-b margin-bottom-lg">
					<div class="col-md-10 font-size-22">
						Custom Profiles
					</div>
					<div class="r margin-bottom-sm margin-right-sm">
						<button class="btn btn-primary" type="button" (click)="openProfileModal()">Add New <span class="glyphicon glyphicon-plus"></span></button>
					</div>
				</div>
				<div class="row margin-top-xlg margin-bottom-lg">
					<div class="col-md-2">
						<strong>Operation</strong>
					</div>
					<div class="col-md-1">
						<strong>Operation Power (KW)</strong>
					</div>
					<div class="col-md-1">
						<strong>Max Duration</strong>
					</div>
					<div class="col-md-3">
						<strong>Name</strong>
					</div>
					<div class="col-md-2">
						<strong>Type/Provider</strong>
					</div>
					<div class="col-md-2">
						<strong>Weekly Sched.</strong>
					</div>
					<div class="col-md-1">
						<strong>Options</strong>
					</div>
				</div>
				<div class="row margin-bottom-lg" *ngFor="let profile of drProfiles.none; let idx = index">
					<ng-container *ngTemplateOutlet="drProfile; context:{profile: profile, idx: idx, section: 'none'}"></ng-container>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #drProfile let-profile="profile" let-idx="idx" let-section="section">
	<div class="col-md-2">
		{{profile.operation | titlecase}}
	</div>
	<div [ngClass]="{'col-md-2': section != 'none', 'col-md-1': section == 'none'}">
		<span *ngIf="profile.operation != operationTypes['lockout']">{{profile.operation_power}}</span>
		<span *ngIf="profile.operation == operationTypes['lockout']">-</span>
	</div>
	<div [ngClass]="{'col-md-2': section != 'none', 'col-md-1': section == 'none'}">
		{{profile.max_duration}}
	</div>
	<div class="col-md-3">
		{{profile.name}}
	</div>
	<div class="col-md-2">
		{{profile.type}}/{{profile.provider}}
	</div>
	<div *ngIf="section == 'none'" class="col-md-2">
		<span *ngIf="weeklyProfilesWithData[profile.id]">{{convertWeeklySchedDaysToString(profile.weekly_sched_days)}} : {{convertWeeklySchedTimespanToHoursMinutes(profile.weekly_sched_start_exec_time)}} - {{convertWeeklySchedTimespanToHoursMinutes(profile.weekly_sched_end_exec_time)}}</span>
		<span *ngIf="!weeklyProfilesWithData[profile.id]">-</span>
	</div>
	<div class="col-md-1">
		<div class="btn-group" appDropdown>
			<i type="button" class="glyphicon glyphicon-cog dropdown-toggle pointer" dropdown-toggle></i>
			<ul class="dropdown-menu options-menu" role="menu">
				<li><a (click)="openProfileModal(profile, idx, section)">Edit</a></li>
				<li *ngIf="currentUser.isACTuser"><a (click)="openExecuteProfileModal(profile.id)">Execute</a></li>
				<li *ngIf="section == 'none'"><a (click)="openExecuteWeeklyProfileModal(profile.id)">Execute Weekly</a></li>
				<li *ngIf="section == 'none'"><a (click)="updateSiteProfile(profile, 'delete', idx)">Delete</a></li>
				<li *ngIf="profile.provider == 'ENERNOC' && !profile.is_disabled && hasENERNOCProvider"><a (click)="updateSiteProfile(profile, 'disable', idx)">Unsubscribe</a></li>
				<li *ngIf="profile.provider == 'ENERNOC' && profile.is_disabled && hasENERNOCProvider"><a (click)="updateSiteProfile(profile, 'enable', idx)">Subscribe</a></li>
			</ul>
		</div>
	</div>
</ng-template>