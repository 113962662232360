import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'underscore';

import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { DeviceManagementService } from '../../device-management.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-allocate-production-line',
	templateUrl: './allocate-production-line.component.html',
	styleUrls: ['./allocate-production-line.component.css']
})
export class AllocateProductionLineComponent implements OnInit, OnDestroy {

	searchSN: string;
	isPAbattviews: boolean = false;
	battviewsToAllocate: any[] = [];
	nonAllocatedBattviews: any[] = [];

	constructor(
		private deviceManagemntService: DeviceManagementService,
		// private route: ActivatedRoute,
		private breadcrumb: BreadcrumbService,
		private notificationMessage: NotificationMessageService,
		// private navigation: NavigationService,
		// private router: Router,
		private commonUtil: CommonUtil,
		// private userService: UserService,
		private translateService: TranslateService
	) {
		this.breadcrumb.setBreadcrumb([
			{label: this.translateService.instant('site.allocate_production_line'), url: ''}
		]);
	}

	ngOnInit() {
		this.getNonAllocatedBattviews(false);
	}

	getNonAllocatedBattviews(isPAbattviews){
		this.deviceManagemntService.getNonAllocatedBattviews(isPAbattviews).subscribe(
			(response: any[]) => {
				this.nonAllocatedBattviews	= response;
				this.orderBattviews();
				this.battviewsToAllocate	= [];
			}
		);
	}
	allocateBattviews(){
		if(this.battviewsToAllocate.length == 0)
			return;

		var battviewIDs = this.commonUtil.extractKeyFromObjects('id', this.battviewsToAllocate);

		this.deviceManagemntService.allocateBattviews(battviewIDs).subscribe(
			result => {
				this.notificationMessage.setMessage(result, 'success', 10000, true);
				this.battviewsToAllocate = [];
			}
		);
	}
	moveBattview(source, battview, index) {
		if(typeof(index) == 'undefined')
			index = null;
		
		if(source == 'rightColumn') {
			this.battviewsToAllocate.push(battview);
			if(index === null) {
				for(var idx in this.nonAllocatedBattviews) {
					var item = this.nonAllocatedBattviews[idx];
					if(item.id == battview.id) {
						index = idx;
						break;
					}
				}
			}
			
			if(index !== null)
				this.nonAllocatedBattviews.splice(index, 1);
		} else {
			//source == 'leftColumn'
			this.nonAllocatedBattviews.push(battview);
			this.orderBattviews();
			if(index === null) {
				for(var idx in this.battviewsToAllocate) {
					var item = this.battviewsToAllocate[idx];
					if(item.id == battview.id) {
						index = idx;
						break;
					}
				}
			}
			
			if(index !== null)
				this.battviewsToAllocate.splice(index, 1);
		}
	}

	orderBattviews() {
		this.nonAllocatedBattviews = _.sortBy(this.nonAllocatedBattviews, 'serialnumber');
	}

	searchSNFunction() {
		var index = null;
		for(var idx in this.nonAllocatedBattviews) {
			var item = this.nonAllocatedBattviews[idx];
			if(item.serialnumber == this.searchSN) {
				item = {
					'id':			item.id,
					'serialnumber':	item.serialnumber,
					'batteryid':	item.batteryid,
					'createdat':	item.createdat
				};
				index = idx;
				break;
			}
		}
		if(index !== null) {
			this.searchSN = '';
			this.moveBattview('rightColumn', item, index)
		}
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}