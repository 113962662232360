<div id="page-wrapper">
  <div class="boxs3 col-md-12">
    <ul class="nav nav-pills margin-left-xlg margin-top-sm">
      <li [ngClass]="{active: tab == 'battviews'}" (click)="setTab('battviews')">
       <a> {{'g.battviews' | translate}}</a>
      </li>

      <li [ngClass]="{active: tab == 'chargers'}" (click)="setTab('chargers')">
        <a>{{'g.chargers' | translate}}</a>
      </li>
    </ul>
    <form class="col-md-4">
      <div class="input-group">
              <input type="text" class="form-control" placeholder="{{'device.search_for_device' | translate}}" [(ngModel)]="serialnumber" name="serialnumber">
              <div class="input-group-btn">
                  <div class="btn-group" role="group">
                      <button type="button" class="btn btn-primary"><span class="glyphicon glyphicon-search" aria-hidden="true"></span></button>
                  </div>
              </div>
          </div>
    </form>
  </div>
  <div class="boxs">
    <table class="table table-stripped">
      <thead>
        <tr>
          <th class="red-text">{{'g.actview_id' | translate}}</th>
          <th>{{'device.serial_number' | translate}}</th>
          <th>{{'g.action' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let device of devices | filter: serialnumber : 'serialnumber' : true">
          <td class="red-text">{{device.id}}</td>
          <td>{{device.serialnumber}}</td>
          <td>
            <div>
              <a [routerLink]="['/replacement-device', device.type, device.id, 'normal']" class="btn btn-primary">{{'device.replace_for' | translate}}</a>
              <button [routerLink]="['/replacement-device', device.type, device.id, 'doReplacement']" class="btn btn-info" *ngIf="device.type == 'battview' && userService.hasAccessFunction('act_admin_device_management')" [disabled]="device.hasReplacementQueue">{{'device.do_replacement' | translate}}</button>
              </div>
            </td>
        </tr>	
      </tbody>
    </table>
    </div>
</div>