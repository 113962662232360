import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { DeviceManagementService } from '../device-management.service';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	providers: [DatePipe],
	selector: 'app-site-invoices-activation-reminder',
	templateUrl: './site-invoices-activation-reminder.component.html',
	styleUrls: ['./site-invoices-activation-reminder.component.css']
})
export class SiteInvoicesActivationReminderComponent implements OnInit {
	
	sitesInvoicesGridColumns = [];
	sitesInvoicesGridData = [];
	
	constructor(
		private deviceManagementService: DeviceManagementService,
		private breadcrumb: BreadcrumbService,
		private datePipe: DatePipe,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([{label: this.translateService.instant('site.site_invoices_activation_reminder'), url: ''}]);

		this.getSitesInvoicesActivationReminder();
	}

	getSitesInvoicesActivationReminder() {
		this.deviceManagementService.getSitesInvoicesActivationReminder().subscribe(
			(response: any) => {

				this.sitesInvoicesGridData = [];
				
				var demoSites		= [];
				var engSites		= [];
				var dealersInfo		= response.dealersInfo;
				var sitesInfo		= response.sitesInfo;
				var salesACTinfo	= response.salesACTinfo;

				response.invoices.forEach((invoice) => {
					
					var siteInfo			= sitesInfo[invoice.site_id] || {};
					invoice.site_name		= siteInfo.name;
					invoice.site_name_link	= '/sites/manage-act-intelligent/'+siteInfo.customerid+'/'+invoice.site_id;
					invoice.customer_name	= siteInfo.customer_name;
					invoice.dealer_name		= dealersInfo[siteInfo.dealer_id].name;
					invoice.start_date		= new Date(new Date(new Date(invoice.start_date).getTimezoneOffset() * 60000 + new Date(invoice.start_date).getTime()));
					invoice.end_date		= new Date(new Date(new Date(invoice.end_date).getTimezoneOffset() * 60000 + new Date(invoice.end_date).getTime()));
					invoice.start_date_fmt	= this.datePipe.transform(invoice.start_date, 'MM/dd/yyyy');
					invoice.end_date_fmt	= this.datePipe.transform(invoice.end_date, 'MM/dd/yyyy');

					if(salesACTinfo[siteInfo.act_user_id]) {
						var userInfo = salesACTinfo[siteInfo.act_user_id];
						invoice.salesperson = userInfo.firstname + ' ' + userInfo.lastname;
					}

					var hoursDiff			= moment(invoice.end_date).utc().diff(moment().utc(), 'hours');
					invoice.remaining_days	= hoursDiff > 0 ? Math.floor(hoursDiff / 24) : 0;

					invoice.invoice_date		= new Date(new Date(new Date(invoice.invoice_date).getTimezoneOffset() * 60000 + new Date(invoice.invoice_date).getTime()));
					invoice.invoice_date_fmt	= this.datePipe.transform(invoice.invoice_date, 'MM/dd/yyyy');
					invoice.total_battviews		= siteInfo.total_battviews;
					invoice.total_chargers		= siteInfo.total_chargers;
					invoice.is_active			= siteInfo.is_deactivated ? 'No' : 'Yes';

					if(invoice.is_active == 'Yes' && hoursDiff <= 0)
						invoice.is_active = 'Expired';
					
					this.sitesInvoicesGridData.push(invoice);

					invoice.act_first_enabled = invoice.act_first_enabled ? this.translateService.instant('g.yes') : this.translateService.instant('g.no');
					invoice.act_energy_enabled = invoice.act_energy_enabled ? this.translateService.instant('g.yes') : this.translateService.instant('g.no');
					// translation
					switch (invoice.is_active) {
						case 'Yes':
							invoice.is_active = this.translateService.instant('g.yes')
							break;
						case 'No':
							invoice.is_active = this.translateService.instant('g.no')
							break;
						case 'Expired':
							invoice.is_active = this.translateService.instant('g.expired')
							break;
					}
				});

				let siteNameColumn = {
					headerName: this.translateService.instant('site.site_name'),
					field: "site_name",
					cellRendererSelector:function (params) {
						params['label'] = params.data.site_name;
						params['link'] = ["/#/sites/manage-act-intelligent", params.colDef.cellRendererParams.sitesInfo[params.data.site_id].customerid, params.data.site_id].join('/');
						return {
							component: 'linkCellRenderer',
							params: params
						};
					},
					cellRendererParams: {sitesInfo: sitesInfo},
					filter: 'agTextColumnFilter',
					width: 200
				};
				this.sitesInvoicesGridColumns = [
					siteNameColumn,
					{headerName: this.translateService.instant('device.customer_name'), field: "customer_name", width: 200},
					{headerName: this.translateService.instant('site.dealer_name'), field: "dealer_name", width: 200},
					{headerName: this.translateService.instant('site.invoice_id'), field: "invoice_id", width: 200},
					{headerName: this.translateService.instant('time.start_date'), field: "start_date_fmt", type: "date", width: 250},
					{headerName: this.translateService.instant('time.end_date'), field: "end_date_fmt", type: "date", width: 250},
					{headerName: this.translateService.instant('site.invoice_date'), field: "invoice_date_fmt", type: "date", width: 250},
					{headerName: this.translateService.instant('g.amount'), field: "amount", type: "number", width: 200, filterParams: '1.0-0'},
					{headerName: this.translateService.instant('site.num_of_battviews'), field: "battview_count", type: "number", width: 200},
					{headerName: this.translateService.instant('site.num_of_chargers'), field: "chargers_count", type: "number", width: 200},
					{headerName: this.translateService.instant('site.num_of_battviews_in_site'), field: "total_battviews", type: "number", width: 230},
					{headerName: this.translateService.instant('site.num_of_chargers_in_site'), field: "total_chargers", type: "number", width: 230},
					{
						headerName: this.translateService.instant('act.act_first'),
						field: "act_first_enabled",
						floatingFilterComponent: "dropDownFilterComponent",
						floatingFilterComponentParams: {dropDownOptions: [{label: '', value: null}, {value: this.translateService.instant('g.yes'), label: this.translateService.instant('g.yes')}, {value: this.translateService.instant('g.no'), label: this.translateService.instant('g.no')}]},
						width: 110
					},
					{
						headerName: this.translateService.instant('act.act_energy'),
						field: "act_energy_enabled",
						floatingFilterComponent: "dropDownFilterComponent",
						floatingFilterComponentParams: {dropDownOptions: [{label: '', value: null}, {value: this.translateService.instant('g.yes'), label: this.translateService.instant('g.yes')}, {value: this.translateService.instant('g.no'), label: this.translateService.instant('g.no')}]},
						width: 110
					},
					{headerName: "Expires in (days)", field: "remaining_days", type: "number", width: 200},
					{
						headerName: this.translateService.instant('g.active'),
						field: "is_active",
						floatingFilterComponent: "dropDownFilterComponent",
						floatingFilterComponentParams: {dropDownOptions: [{label: '', value: null}, {value: this.translateService.instant('g.yes'), label: this.translateService.instant('g.yes')}, {value: this.translateService.instant('g.no'), label: this.translateService.instant('g.no')}, {value: this.translateService.instant('g.expired'), label: this.translateService.instant('g.expired')}]},
						width: 200
					},
					{headerName: this.translateService.instant('user.salesperson'), field: "salesperson", width: 300},
					{headerName: "Note", field: "invoice_note", width: 300}
				];
			}
		)
	};

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}