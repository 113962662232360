import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdminService } from '../../admin.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-registration-requests',
	templateUrl: './registration-requests.component.html',
	styleUrls: ['./registration-requests.component.css']
})
export class RegistrationRequestsComponent implements OnInit, OnDestroy {


	guests = <any>[];

	constructor(
		private adminService: AdminService,
		private breadcrumb: BreadcrumbService,
		private router: Router
	) {
	}

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([{'label': 'Registrations Request', 'url': ''}]);
		this.adminService.getGuestUser().subscribe(
			data => {
				this.guests = data;
			}
		);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
