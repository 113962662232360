export class Site {
	act_intelligent_type: string;
	act_user_id: number;
	address: string;
	battview_live_socket_enable: boolean;
	battviewsdowngrade: boolean;
	battviewsfirmwareversion: number;
	charger_live_socket_enable: boolean;
	chargersdowngrade: number;
	chargersfirmwareversion: number;
	commodity_download: boolean;
	commodity_firmware: boolean;
	commodity_settings: boolean;
	contactname: string;
	country: string;
	countryid: string;
	createdate: string;
	customer_name: string;
	customerid: number;
	dealer_id: number;
	email: string;
	has_router: boolean;
	id: number;
	installation_date: string;
	isverified: boolean;
	lastconnecttime: string;
	latitude: number;
	longitude: number;
	modificationdate: string;
	name: string;
	oem_id: number;
	phoneno: string;
	service_dealer_id: number;
	truck_dealer_id: number;
	site_subscription_type: string;
	state: string;
	stateid: string;
	test_site: boolean;
	total_battviews: number;
	total_chargers: number;
	zoneid: number;
	act_first_enabled: boolean;
	act_first_token: string;
	managed_by_dealer: boolean
	dr_provider: string;
}