import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { ChargersDashboardService } from '../chargers-dashboard.service';
import * as _ from 'lodash';
import { UserService } from '../../../../../auth/user.service';

@Component({
	selector: 'app-charger-pms-live-view',
	templateUrl: './charger-pms-live-view.component.html',
	styleUrls: ['./charger-pms-live-view.component.css']
})
export class ChargerPmsLiveViewComponent implements OnInit, OnChanges, OnDestroy {

	@Input() charger: any = {};
	@Input() chargerID: number = 0;

	PMs: any	= {};
	countDown: any;
	PMsCharger	= {};
	emptyPMs	= true;
	intervalID: any;
	PMsLiveViewGridData = [];
	PMsLiveViewGridColumns = [];
	
	constructor(
		private chargersDashboard: ChargersDashboardService,
	) { }

	ngOnInit() {

		this.PMsLiveViewGridColumns = [
			{headerName: "g.id", field: "ID", width: 180, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.pm_state", field: "state", width: 180, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.pm_firmware", field: "firmware", type:"number", width: 180, filterParams: {newRowsAction: 'keep', format: '1.1-1'}},
			{headerName: "charger.pm_model", field: "model", width: 180, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.pm_current", field: "current", type:"number", width: 180, filterParams: {newRowsAction: 'keep'}},
			{headerName: "device.pm_voltage", field: "lastReadingVoltage", type:"number", width: 180, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.instant_error", field: "instantError", width: 180, filterParams: {newRowsAction: 'keep'}},
			{headerName: "g.rating", field: "rating", width: 180, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.pm_can_vout_ov", field: "PM_CAN_vout_OV_FAULT", type:"number", width: 180, hide: true, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.pm_can_iout_ov", field: "PM_CAN_iout_OV_FAULT", type:"number", width: 180, hide: true, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.pm_can_in_fail", field: "PM_CAN_in_fail_FAULT", type:"number", width: 180, hide: true, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.pm_can_temp_ol", field: "PM_CAN_temp_OL_FAULT", type:"number", width: 180, hide: true, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.pm_can_timeout", field: "PM_CAN_timeout_FAULT", type:"number", width: 180, hide: true, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.pm_can_ocp", field: "PM_CAN_OCP_FAULT", type:"number", width: 180, hide: true, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.pm_can_fan", field: "PM_CAN_FAN_FAULT", type:"number", width: 180, hide: true, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.buffer_error_counter", field: "Buffer1ErrorCounter", type:"number", width: 180, hide: true, filterParams: {newRowsAction: 'keep'}}
		];

		this.readPMsState(true);

		this.intervalID = setInterval(() => {

			this.readPMsState();

		}, 3000); //call every 3 seconds
	}

	ngOnChanges(changes) {
		if(this.chargerID)
			this.readPMsState(true);
	}

	readPMsState(blockUI = false) {
		if(!this.chargerID)
			return;

		this.chargersDashboard.readPMsState(this.chargerID, blockUI).subscribe( (response: any) => {

			this.PMs			= response.PMs;
			this.PMsCharger		= response.charger;
			this.emptyPMs		= this.PMs.list.length === 0;

			if(this.PMs.list.length > 0) {
				let pmsList = this.PMs.list;
				pmsList.forEach((item: any) => {
					item.lastReadingVoltage = Math.round(item.lastReadingVoltage * 10) /10;
					item.firmware = Math.round(item.firmware * 10) /10;
				});
				this.PMsLiveViewGridData = pmsList;
			} else {
				if(this.PMs.connectCountDown) {
					if(typeof this.countDown == 'undefined') {
						this.countDown = this.PMs.connectCountDown;
						this.connectCountDown();
					}
				} else {
					delete this.countDown;
				}
			}
		});
	}

	connectCountDown() {
		setTimeout(() => {
			this.countDown--;
			if(this.countDown)
				this.connectCountDown();
		}, 1000);
	};

	ngOnDestroy() {
		clearInterval(this.intervalID);
	}
}