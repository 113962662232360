import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../auth/user.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { AdminService } from '../../admin.service';

@Component({
	selector: 'app-admin-actions',
	templateUrl: './admin-actions.component.html',
	styleUrls: ['./admin-actions.component.css']
})

export class AdminActionsComponent implements OnInit, OnDestroy {

	constructor(
		private router: Router,
		private breadcrumb: BreadcrumbService,
		private translateService: TranslateService,
		private userService: UserService,
		private adminService: AdminService
	) {
	}

	ngOnInit() {
		if(!this.userService.hasAccessFunction('sched_functions_control'))
			this.router.navigate(['/unauthorized']);

		this.breadcrumb.setBreadcrumb([{
			'label': this.translateService.instant('nav.admin_actions'), 'url': ''
		}]);
	}

	forceWebsiteRefresh() {
		this.adminService.forceWebsiteRefresh().subscribe();
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}