import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import {Subscription} from 'rxjs';

import { GoogleColumnChartService } from '../Services/google-column-chart.service';
import { ColumnChartConfig } from '../Models/ColumnChartConfig';

declare var google: any;

@Component({
  selector: 'column-chart',
  template: '<div id="{{elementId}}"></div>'
})
export class ColumnChartComponent implements OnInit, OnChanges {

	@Input() data: any[];
	@Input() config: ColumnChartConfig;
	@Input() elementId: String;
	@Output() onSelectItem = new EventEmitter<any>(true);
	@Output() onChartReady = new EventEmitter<any>();

	subscription:Subscription;
	readySubscription: Subscription;
	_columnChartService: GoogleColumnChartService = new GoogleColumnChartService();
	constructor() {}

	ngOnInit(): void {
		if(this.data) {
			this.buildChart();
		}
		this.subscription = this._columnChartService.selectItem.subscribe(
			item => {
				this.onSelectItem.emit(item);
			}
		);
		this.readySubscription = this._columnChartService.chartReady.subscribe(
			chart => {
				this.onChartReady.emit({elementId: this.elementId, chart: chart});
			}
		);
	}
	ngOnChanges() {
		this.buildChart();
	}
	buildChart() {
		this._columnChartService.BuildColumnChart(this.elementId, this.data, this.config);
	}
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}