import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';
import { ChartDataService } from '../../../../shared/services/chart-data.service';
import { BarChartConfig } from '../../../../shared/google-chart/Models/BarChartConfig';
import { ChartUtil } from '../../../../shared/services/utility/chart.service';
import { Customer } from '../../../../shared/models/customer.model';

@Component({
	selector: 'app-all-sites-battery-fleet-utilization',
	templateUrl: './all-sites-battery-fleet-utilization.component.html',
	styleUrls: ['./all-sites-battery-fleet-utilization.component.css']
})
export class AllSitesBatteryFleetUtilizationComponent implements OnInit, OnChanges, OnDestroy {

	@Input() data;

	date = "30";
	currentCustomerSubscription: Subscription = new Subscription();
	hideWidget: boolean = false;
	sites = {};
	chartObject;
	chartDataTable;
	chartConfig;
	dropDownOptions = [];
	customer : Customer = new Customer();

	constructor(
		private sideMenuService: SideMenuService,
		private chartDataService: ChartDataService,
		private chartUtil: ChartUtil
	) { }

	ngOnInit() {
		this.dropDownOptions = [{
			name: 'overall_battery_fleet_utilization',
			options: [
				{
					value: "W",
					label: "Last Week"
				}, {
					value: 7,
					label: "Last 7 Days"
				}, {
					value: 14,
					label: "Last 14 Days"
				}, {
					value: 30,
					label: "Last 30 Days"
				}, {
					value: 90,
					label: "Last 90 Days"
				}
			],
			selectedValue: "30"
		}];

		this.currentCustomerSubscription =  this.sideMenuService.currentCustomer.subscribe(customer => {
			if (customer && customer.id) {
				this.customer = customer;
				this.sites = this.sideMenuService.getSitesCustomerMap(customer.id);
				if (!this.chartObject) {
					this.getData();
				}
			}
		});
	}

	ngOnChanges() {
		this.getData();
	}

	getData(date?) {
		date = date || this.date;
		let data;
		if (!_.isEmpty(this.sites) && this.data) {
			data = this.chartDataService.getCustomerHome(this.data, 'battery_utilization', date, this.sites);
			if(!data) {
				this.hideWidget = true;
				return;
			} else {
				this.hideWidget = false;
			}

			let height = 600;
			let ids = _.pluck(this.sites, "id");
			if (this.sites && ids.length < 10) {
				height = 400;
			}
			this.chartConfig  = {
				"displayed": false,
				hAxis: {textStyle: {fontSize:11, format: "percent"}},
				annotations: {
					textStyle: {
						fontName: 'Times-Roman',
						fontSize: 10,
						bold: true,
						italic: true,
						// The color of the text.
						color: '#871b47'
					}
				},
				chartArea: {
					height: '100%',
					width: '60%',
				},
				legend: {position: 'none'},
				height: height
			};

			this.chartObject = new BarChartConfig(this.chartConfig);
			let columns = [
				{"label": "Name", "type": "string"},
				{"label": "Value", "type": "number"},
				{"type": "string", role: 'annotation'},
				{"type": "string", role: 'style'}
			];
			let chartDataTable = [columns];
			let chartData = [];
			for (let item of data['list']) {
				let annotation = item['utilization'] + "%";
				annotation = item['utilization'] > 0 ? annotation + " " + item['site_name'] : annotation;
				chartData.push([item['site_name'], item['utilization'], annotation, 'color: ' +this.chartUtil.getColor(item.utilization)]);
			}
			this.chartDataTable = chartDataTable.concat(chartData);
		}
	}

	dateChange(name, value) {
		this.getData(value);
	}

	ngOnDestroy() {
		this.currentCustomerSubscription.unsubscribe();
	}
}