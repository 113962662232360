<div class="panel panel-default">
  <div class="panel-body">
    <div class="col-md-12 padding-8">
      <div class="col-md-5">
        <div class="col-md-12 padding-8">
          <div class="col-md-4">
            <strong>{{'g.select_a_dealer' | translate}}</strong>
          </div>
          <div class="col-md-8">
            <ng-select [items]="dealers" bindLabel="name" bindValue="id" placeholder="{{'g.select_a_dealer' | translate}}" name="selectedDealers" [(ngModel)]="model.selectedDealers" multiple="true">
            </ng-select>
          </div>
        </div>
        <div class="col-md-12 padding-8">
          <div class="col-md-4">
            <strong>{{'g.select_an_oem' | translate}}</strong>
          </div>
          <div class="col-md-8">
            <ng-select [items]="OEMs" bindLabel="name" bindValue="id" placeholder="{{'g.select_an_oem' | translate}}" name="selectedOEMs" [(ngModel)]="model.selectedOEMs" multiple="true">
            </ng-select>
          </div>
        </div>
        <div class="col-md-12 padding-8">
          <div class="col-md-4">
            <strong>{{'g.select_a_customer' | translate}}</strong>
          </div>
          <div class="col-md-8">
            <ng-select [items]="customers" bindLabel="name" bindValue="id" placeholder="{{'g.select_a_customer' | translate}}" name="selectedCustomers" [(ngModel)]="model.selectedCustomers" multiple="true">
            </ng-select>
          </div>
        </div>
      </div>
      
      <div class="col-md-1">
      </div>

      <div class="col-md-1" style="border-left: 2px lightcoral solid; height: 150px">
      </div>
      
      <div class="col-md-5">
        <div class="col-md-12 padding-8">
          <div class="col-md-4">
            <strong>{{'user.is_test_user' | translate}}</strong>
          </div>
          <div class="col-md-8">
            <label class="radio-inline"><input type="radio" name="isTestUser" [(ngModel)]="model.isTestUser" [value]="null">{{'g.not_specified' | translate}}</label>
            <label class="radio-inline"><input type="radio" name="isTestUser" [(ngModel)]="model.isTestUser" [value]="true">{{'user.test_user' | translate}}</label>
            <label class="radio-inline"><input type="radio" name="isTestUser" [(ngModel)]="model.isTestUser" [value]="false">{{'user.non_test' | translate}}</label>
          </div>
        </div>

        <div class="col-md-12 padding-8">
          <div class="col-md-4">
            <strong>{{'user.email_confirmed' | translate}}</strong>
          </div>
          <div class="col-md-8">
            <label class="radio-inline"><input type="radio" name="isEmailConfirmed" [(ngModel)]="model.isEmailConfirmed" [value]="null">{{'g.not_specified' | translate}}</label>
            <label class="radio-inline"><input type="radio" name="isEmailConfirmed" [(ngModel)]="model.isEmailConfirmed" [value]="true">{{'user.email_confirmed' | translate}}</label>
            <label class="radio-inline"><input type="radio" name="isEmailConfirmed" [(ngModel)]="model.isEmailConfirmed" [value]="false">{{'user.email_not_confirmed' | translate}}</label>
          </div>
        </div>
        
        <div class="col-md-12 padding-8">
          <div class="col-md-4">
            <strong>{{'user.user_confirmed' | translate}}</strong>
          </div>
          <div class="col-md-8">
            <label class="radio-inline"><input type="radio" name="isUserConfirmed" [(ngModel)]="model.isUserConfirmed" [value]="null">{{'g.not_specified' | translate}}</label>
            <label class="radio-inline"><input type="radio" name="isUserConfirmed" [(ngModel)]="model.isUserConfirmed" [value]="true">{{'user.user_confirmed' | translate}}</label>
            <label class="radio-inline"><input type="radio" name="isUserConfirmed" [(ngModel)]="model.isUserConfirmed" [value]="false">{{'user.user_not_confirmed' | translate}}</label>
          </div>
        </div>
      </div>
      <div class="col-md-12 padding-8">
        <div class="tcenter">
          <button class="btn btn-primary mb20" type="button" (click)="modal.show()" [disabled]="submitDisabled()">
            <span class="glyphicon glyphicon-search"></span> {{'g.search' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal #modal [title]="'g.report_name' | translate" [confirmBtnText]="'submit' | translate" [hasCloseButton]="false">
  <span>{{'g.enter_report_name' | translate}}</span>
  <input type="text" class="form-control" [(ngModel)]="reportName" placeholder="{{'g.report_name' | translate}}" />
</app-modal>