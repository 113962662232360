import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import *  as moment from 'moment';
import { UserService } from '../../../../auth/user.service';
import { SiteDashboardService } from '../../site-dashboard.service';
import { DynamicResponseService } from '../dynamic-response.service';
import { NotificationMessageService } from '../../../../shared/notification-message/notification-message.service';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';

@Component({
	selector: 'app-events-history',
	templateUrl: './events-history.component.html',
	styleUrls: ['./events-history.component.css']
})
export class EventsHistoryComponent implements OnInit {

	siteId: number = 0;
	customerId: number = 0;
	currentSite: any = {};
	fromDate = new Date(moment().subtract(1, 'days').startOf('day').unix() * 1000);
	toDate = new Date(moment().startOf('day').unix() * 1000);
	profiles: any[] = [];
	noData: boolean = false;

	constructor(
		public userService: UserService,
		private siteDashboardService: SiteDashboardService,
		private route: ActivatedRoute,
		private dynamicResponseService: DynamicResponseService,
		private notificationMessage: NotificationMessageService,
		private sideMenuService: SideMenuService,
		private commonUtil: CommonUtil,
	) { }

	ngOnInit() {
		this.siteDashboardService.setActiveSubTab('events-history');
		this.siteId = +this.route.parent.parent.snapshot.params.siteid || 0;
		this.customerId = +this.route.parent.parent.snapshot.params.customerid || 0;
		this.sideMenuService.currentSite.subscribe(data => {
			if(data && data.id) {
				this.currentSite = data;
			}
		});
	}

	fetchData() {
		var fromDate:any= new Date(new Date(this.fromDate).setHours(0, 0, 0, 0));
		fromDate		= Math.floor((-fromDate.getTimezoneOffset() * 60000 + fromDate.getTime()) / 1000);
		fromDate		= this.commonUtil.getUTCTimestampFromZone(this.currentSite.zoneid, fromDate, true);

		var toDate:any	= new Date(new Date(this.toDate).setHours(23,59,59,0));
		toDate			= Math.floor((-toDate.getTimezoneOffset() * 60000 + toDate.getTime()) / 1000);
		toDate			= this.commonUtil.getUTCTimestampFromZone(this.currentSite.zoneid, toDate, true);

		let data = [];
		this.profiles = [];
		this.noData = false;
		this.dynamicResponseService.getEventsSiteHistory(this.siteId, fromDate, toDate).subscribe((profiles: any) => {
			for (let profileID in profiles) {
				let profile = profiles[profileID];
				profile.expanded = true;
				profile.events.forEach((event) => {
					for(let col of ['create_time', 'start_execution_time', 'end_execution_time', 'sched_start_time', 'sched_end_time']){
						if(!event[col])
							continue;
						event[col]	= this.commonUtil.getZoneTimestampFromUTC(this.currentSite.zoneid, moment(event[col]).utc().unix(), true);
						event[col]	= this.commonUtil.getDateFormattedFromUnixTimeStamp(event[col], '12h');
						event[col]	= new Date(event[col]);
					}
				});
				data.push(profile);
			}
			
			this.profiles = data;
			if(this.profiles.length === 0)
				this.noData = true;
		});
	}

	DeleteOrCancel(eventId) {
		this.dynamicResponseService.DeleteOrCancelEvent(this.siteId, eventId).subscribe(() => {
			this.notificationMessage.setMessage('globalSuccessMsg');
			this.fetchData();
		});
	}
}