<form #editDeviceOwners="ngForm" (submit)="saveDeviceOwners()">
	<div class="col-md-10 col-md-offset-1">
		<div class="boxs3">
			<h2>Edit {{deviceType == 'charger' ? 'Charger' : 'Battview'}} {{device.ispa ? 'Mobile' : ''}} Owners
				<span class='r' *ngIf="devicesSN.length == 1 && !currentUser.isDealer">ActView ID: {{devicesSN[0].ID}}</span>
				<span class='r' *ngIf="devicesSN.length == 1 && currentUser.isDealer">Serial Number: {{devicesSN[0].SN}}</span>
			</h2>
			
			<div *ngIf="devicesSN.length > 1" class="margin-bottom row col-sm-12">
				<h2>Serial Numbers:</h2>
				<div *ngFor="let SNs of devicesSN" class="col-sm-2">
					<div class="boxs3 body-bg-color">
						{{SNs.SN}}
					</div>
				</div>
			</div>
			
			<div class="col-sm-12">
				
				<div class="padding-8 col-sm-12" *ngIf="OEMsList.length > 0">
					<div class="col-sm-8" *ngIf="deviceType == 'charger'">
						<label><input type="checkbox" [(ngModel)]="device.actviewenable" name="actviewenable" class="form-control"/> <strong>ACT intelligent</strong></label>
					</div>

					<div class="col-sm-8" *ngIf="deviceType == 'battview'">
						<label><input type="checkbox" [(ngModel)]="device.actviewenabled" name="actviewenabled" class="form-control"/> <strong>ACT intelligent</strong></label>
					</div>
				</div>

				<div class="padding-8 col-sm-12" *ngIf="clients.length > 0">
					<div class="col-sm-3">Select a Client</div>
					<div class="col-sm-5">
						<ng-select [items]="clients" (ngModelChange)="GetClientSitesByRoleId($event)" bindLabel="name" bindValue="id" placeholder="Select a Client" name="customerid" [ngModel]="device.customerid" [clearable]="false" [required]="device.customerid != 0">
						</ng-select>
					</div>
					<div class="col-sm-4" *ngIf="deviceRequest">
						<select class="form-control" [(ngModel)]="deviceRequest.deviceInfo.customerid" name="deviceRequestCustomerId" disabled>
							<option *ngFor="let client of deviceRequest.clientsList" [value]="client.id">{{client.name}}</option>
						</select>
					</div>
				</div>

				<div class="padding-8 col-sm-12" *ngIf="device.customerid == 0 && clients.length > 0">
					<div class="col-sm-8">
						<app-customer-edit [customer]="device.client"></app-customer-edit>
					</div>
				</div>
		
				<div class="padding-8 col-sm-12" *ngIf="!device.ispa">
					<div class="col-sm-3">Select a Site</div>
					<div class="col-sm-5">
						<ng-select [items]="clientSites | values" (ngModelChange)="showSelectedAddress($event)" bindLabel="name" bindValue="id" placeholder="Select a Site" name="siteid" [ngModel]="device.siteid" [clearable]="false" [required]="device.customerid > 0 || device.client.name">
						</ng-select>
					</div>
					<div class="col-sm-4" *ngIf="deviceRequest">
						<select class="form-control" [(ngModel)]="deviceRequest.deviceInfo.siteid" name="deviceRequestSiteId" disabled>
							<option *ngFor="let site of deviceRequest.sitesList | values" [value]="site.id">{{site.name}}</option>
						</select>
					</div>
				</div>

				<div class="padding-8 col-sm-12" *ngIf="!device.ispa && device.site.id != 0">
					<div class="col-sm-3">Site Address</div>
					<div class="col-sm-5">
						<input type="text" class="form-control" [readonly]="true" [required]="(device.customerid > 0 || device.client.name) && !device.ispa" [(ngModel)]="device.site.address" name="siteAddress" />
					</div>
					<div class="col-sm-4" *ngIf="deviceRequest">
						<input type="text" class="form-control" [(ngModel)]="deviceRequest.siteAddress" name="deviceRequestSiteAddress" disabled />
					</div>
				</div>

				<div class="padding-8 col-sm-12" *ngIf="userService.hasAccessFunction('official_reporting') && deviceType == 'charger'">
					<div class="col-md-4">{{'device.included_in_reports'| translate}}</div>
					<label class="col-md-4"><input type="radio" [(ngModel)]="device.included_in_reports" name="included_in_reports" class="form-control" [value]="true"/> {{'g.enable'| translate}}</label>
					<label><input type="radio" [(ngModel)]="device.included_in_reports" name="included_in_reports" class="form-control" [value]="false"/> {{'g.disable'| translate}}</label>
				</div>

				<div class="padding-8 col-sm-12" *ngIf="device.siteid == 0 && !device.ispa">
					<div class="col-sm-8">
						<app-site-edit #appSiteEdit [site]="device.site" [OEMsInfo]="OEMsList" [dealersInfo]="dealersList" [serviceDealersInfo]="serviceDealersList" [ACTusers]="ACTusers" mode="manage"></app-site-edit>
					</div>
					<div class="col-sm-4" *ngIf="deviceRequest">
						<div class="col-sm-12">
							<div [style.marginTop.px]="92">
								<input type="text" class="form-control" [(ngModel)]="deviceRequest.siteAddress" name="deviceRequestSiteAddress" disabled />
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-12" *ngIf="device.siteid == 0 && !device.ispa">
					<app-google-map (onAddressChanged)="appSiteEdit.updateSiteCoords($event)"></app-google-map>
				</div>
			</div>
			<div>
				<button type="submit" [ngClass]="{'btn-success': isAdmin, 'btn-primary': !isAdmin}" class="btn" [disabled]="editDeviceOwners.invalid">{{isAdmin ? 'Save and Approve' : 'Submit'}}</button>
				<button type="button" class="btn btn-default" (click)="goBack()">Cancel</button>
			</div>
		</div>
	</div>
</form>