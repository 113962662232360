<form class="form-auth">
  <div class="container-fluid" id="main">
    <div class="boxs3 col-md-6 col-md-offset-3" id="left">
      <div>
        <h2 class="tcenter">{{'site.battview_report' | translate}}</h2>
        <fieldset>
          <legend class="padding-8">{{'g.site' | translate}} <a class="pull-right noprint" href="javascript:">
            <i (click)="isCollapsed['site'] = !isCollapsed['site']" class="fa " [ngClass]="{'fa-minus': !isCollapsed['site'], 'fa-plus': isCollapsed['site']}"></i>
          </a></legend>
          
          <div *ngIf="!isCollapsed['site']">
            <div class="col-md-12 padding-8">
              <label>
                <input type="checkbox" [(ngModel)]="model.hasSite" name="hasSite" /> {{'site.has_a_site' | translate}}
              </label>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4"><strong>{{'g.customer' | translate}}</strong></div>
              <div class="col-md-3">
                <label>
                  <input type="checkbox" [(ngModel)]="model.selectedCustomersNegation" name="selectedCustomersNegation" (change)="updateSitesAvailable()" /> {{'g.exclude' | translate}}
                </label>
              </div>
              <div class="col-md-5">
                <ng-select [items]="customers" bindLabel="customer_name" bindValue="customerid" placeholder="{{'g.all_customers' | translate}}" name="customerid" [(ngModel)]="model.selectedCustomers" [disabled]="!model.hasSite" multiple="true" (change)="updateSitesAvailable()">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4"><strong>{{'g.sites' | translate}}</strong></div>
              <div class="col-md-8">
                <ng-select [items]="sites" bindLabel="name" bindValue="id" name="siteid" [(ngModel)]="model.selectedSites" [disabled]="!model.hasSite" multiple="true">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'g.dealer' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <label>
                  <input type="checkbox" [(ngModel)]="model.selectedDealersNegation" name="selectedDealersNegation" /> {{'g.exclude' | translate}}
                </label>
              </div>
              <div class="col-md-5">
                <ng-select [items]="dealers" bindLabel="name" bindValue="id" name="dealerid" [(ngModel)]="model.selectedDealers" [disabled]="!model.hasSite" multiple="true">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'g.service_dealer' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <label>
                  <input type="checkbox" [(ngModel)]="model.selectedServiceDealersNegation" name="selectedServiceDealersNegation" /> {{'g.exclude' | translate}}
                </label>
              </div>
              <div class="col-md-5">
                <ng-select [items]="dealers" bindLabel="name" bindValue="id" name="servicedealerid" [(ngModel)]="model.selectedServiceDealers" [disabled]="!model.hasSite" multiple="true">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'g.select_an_oem' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <label>
                  <input type="checkbox" [(ngModel)]="model.selectedOEMsNegation" name="selectedOEMsNegation" /> {{'g.exclude' | translate}}
                </label>
              </div>
              <div class="col-md-5">
                <ng-select [items]="OEMs" bindLabel="name" bindValue="id" name="oemid" [(ngModel)]="model.selectedOEMs" [disabled]="!model.hasSite" multiple="true">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'site.test_site' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <label class="radio-inline"><input type="radio" name="isTestSite" [(ngModel)]="model.isTestSite" [value]="true" [disabled]="!model.hasSite">{{'g.yes' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="isTestSite" [(ngModel)]="model.isTestSite" [value]="false" [disabled]="!model.hasSite">{{'g.no' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="isTestSite" [(ngModel)]="model.isTestSite" [value]="null" [disabled]="!model.hasSite">{{'g.not_selected' | translate}}</label>
              </div>
            </div>
              <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'act.act_services' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <label class="radio-inline"><input type="radio" name="actIntelligentSiteType" [(ngModel)]="model.actIntelligentSiteType" [value]="0" [disabled]="!model.hasSite">{{'g.none' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="actIntelligentSiteType" [(ngModel)]="model.actIntelligentSiteType" [value]="1" [disabled]="!model.hasSite">{{'act.act_intelligent' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="actIntelligentSiteType" [(ngModel)]="model.actIntelligentSiteType" [value]="null" [disabled]="!model.hasSite">{{'g.not_selected' | translate}}</label>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend class="padding-8">{{'g.warnings_and_faults' | translate}} <a class="pull-right noprint" href="javascript:">
            <i (click)="isCollapsed['warnings'] = !isCollapsed['warnings']" class="fa " [ngClass]="{'fa-minus': !isCollapsed['warnings'], 'fa-plus': isCollapsed['warnings']}"></i></a></legend>
          <div *ngIf="!isCollapsed['warnings']">
            <div class="col-md-12 padding-8">
              <div class="col-md-4"><strong>{{'act.warnings_for_actview_enabled_devices_only' | translate}}</strong></div>
              <div class="col-md-8">
                <ng-select [items]="warnings" bindLabel="name" bindValue="id" name="warnings" [(ngModel)]="model.selectedWarnings" multiple="true">
                </ng-select>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend class="padding-8">{{'device.battview_version' | translate}} <a class="pull-right noprint" href="javascript:">
            <i (click)="isCollapsed['version'] = !isCollapsed['version']" class="fa " [ngClass]="{'fa-minus': !isCollapsed['version'], 'fa-plus': isCollapsed['version']}"></i></a></legend>
          <div *ngIf="!isCollapsed['version']">
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.firmware_version' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.fwVersionOperation" class="form-control" name="fwVersionOperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value="=" label="=">=</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                  <option value="!=" label="!=">!=</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="number" step="0.01" name="fwVersion" [(ngModel)]="model.fwVersion" [disabled]="!model.fwVersionOperation">
              </div>
            </div>
			<div class="col-md-12 padding-8">
				<div class="col-md-4">
				  <strong>{{'device.wifi_firmware_version' | translate}}</strong>
				</div>
				<div class="col-md-3">
				  <select [(ngModel)]="model.wifiFwVersionOperation" class="form-control" name="wifiFwVersionOperation">
					<option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
					<option value="=" label="=">=</option>
					<option value=">" label=">">></option>
					<option value="<" label="<"><</option>
					<option value="!=" label="!=">!=</option>
				  </select>
				</div>
				<div class="col-md-5">
				  <input type="number" step="0.01" name="wifiFwVersion" [(ngModel)]="model.wifiFwVersion" [disabled]="!model.wifiFwVersionOperation">
				</div>
			  </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.battview_hardware_revision' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.bvHwVersionOperation" class="form-control" name="bvHwVersionOperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value="=" label="=">=</option>
                  <option value="!=" label="!=">!=</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="text" name="bvHwVersion" [(ngModel)]="model.bvHwVersion" [value]="1" [disabled]="!model.bvHwVersionOperation">
              </div>
            </div>
            <!-- <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>Wifi version</strong>
              </div>
              <div class="col-md-8">
                <label class="radio-inline"><input type="radio" name="wifiVersion" [(ngModel)]="model.wifiVersion" [value]="y">1</label>
                <label class="radio-inline"><input type="radio" name="wifiVersion" [(ngModel)]="model.wifiVersion" [value]="n">2</label>
                <label class="radio-inline"><input type="radio" name="wifiVersion" [(ngModel)]="model.wifiVersion" [value]="">{{'g.not_selected' | translate}}</label>
              </div>
            </div> -->
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.battview_mobile_or_not' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <label class="radio-inline"><input type="radio" name="isBattviewMobile" [(ngModel)]="model.isBattviewMobile" [value]="false">{{'g.battview' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="isBattviewMobile" [(ngModel)]="model.isBattviewMobile" [value]="true">{{'g.battview_mobile' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="isBattviewMobile" [(ngModel)]="model.isBattviewMobile" [value]="">{{'g.not_selected' | translate}}</label>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend class="padding-8">{{'device.battview_configurations' | translate}} <a class="pull-right noprint" href="javascript:">
            <i (click)="isCollapsed['config'] = !isCollapsed['config']" class="fa " [ngClass]="{'fa-minus': !isCollapsed['config'], 'fa-plus': isCollapsed['config']}"></i></a></legend>
          <div *ngIf="!isCollapsed['config']">
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'act.actview_enable' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <label class="radio-inline"><input type="radio" name="isActviewEnabled" [(ngModel)]="model.isActviewEnabled" [value]="true">{{'g.enabled' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="isActviewEnabled" [(ngModel)]="model.isActviewEnabled" [value]="false">{{'g.disabled' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="isActviewEnabled" [(ngModel)]="model.isActviewEnabled" [value]="null">{{'g.not_selected' | translate}}</label>
              </div>
            </div>
              <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.battery_type' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <ng-select [items]="batteryTypes" bindLabel="name" bindValue="id" name="batterytype" placeholder="{{'device.select_a_battery_type' | translate}}" [(ngModel)]="model.batteryType" multiple="true">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.battview_time_zone' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <ng-select [items]="commonDataService.TimeZonesMenu" bindLabel="display_name" bindValue="id" name="timeZonesMenu" placeholder="{{'time.select_a_time_zone' | translate}}" [(ngModel)]="model.zoneid" multiple="true">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'act.connected_within_in_minutes_for_actview_enabled_devices_only' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <label>
                  <input type="checkbox" [(ngModel)]="model.connectionTimeNegation" name="connectionTimeNegation" /> {{'g.exclude' | translate}}
                </label>
              </div>
              <div class="col-md-5">
                <input type="number" min="0" class="form-control" [(ngModel)]="model.connectionTime" name="connectionTime" />
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.enabled_plc' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <label class="radio-inline"><input type="radio" name="enabledPLC" [(ngModel)]="model.enabledPLC" [value]="true">{{'g.enabled' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="enabledPLC" [(ngModel)]="model.enabledPLC" [value]="false">{{'g.disabled' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="enabledPLC" [(ngModel)]="model.enabledPLC" [value]="null">{{'g.not_selected' | translate}}</label>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.enabled_hall_effect_sensor' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <label class="radio-inline"><input type="radio" name="enabledHallSensor" [(ngModel)]="model.enabledHallSensor" [value]="true">{{'g.enabled' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="enabledHallSensor" [(ngModel)]="model.enabledHallSensor" [value]="false">{{'g.disabled' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="enabledHallSensor" [(ngModel)]="model.enabledHallSensor" [value]="null">{{'g.not_selected' | translate}}</label>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.enabled_water_level_sensor' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <label class="radio-inline"><input type="radio" name="enabledWaterSensor" [(ngModel)]="model.enabledWaterSensor" [value]="true">{{'g.enabled' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="enabledWaterSensor" [(ngModel)]="model.enabledWaterSensor" [value]="false">{{'g.disabled' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="enabledWaterSensor" [(ngModel)]="model.enabledWaterSensor" [value]="null">{{'g.not_selected' | translate}}</label>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend class="padding-8">{{'g.data_history' | translate}}
            <a class="pull-right noprint" href="javascript:">
              <i (click)="isCollapsed['history'] = !isCollapsed['history']" class="fa " [ngClass]="{'fa-minus': !isCollapsed['history'], 'fa-plus': isCollapsed['history']}"></i>
            </a>
          </legend>
          <div *ngIf="!isCollapsed['history']">
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.total_charge_ahr' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.totalChargeAHROperation" class="form-control" name="totalChargeAHROperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="number" name="totalChargeAHR" [(ngModel)]="model.totalChargeAHR" [disabled]="!model.totalChargeAHROperation" >
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.total_inuse_ahr' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.totalInuseAHROperation" class="form-control" name="totalInuseAHROperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="number" name="totalInuseAHR" [(ngModel)]="model.totalInuseAHR" [disabled]="!model.totalInuseAHROperation" >
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.total_charge_kwhr' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.totalChargeKWHROperation" class="form-control" name="totalChargeKWHROperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="number" name="totalChargeKWHR" [(ngModel)]="model.totalChargeKWHR" [disabled]="!model.totalChargeKWHROperation" >
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.total_inuse_kwhr' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.totalInuseKWHROperation" class="form-control" name="totalInuseKWHROperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="number" name="totalInuseKWHR" [(ngModel)]="model.totalInuseKWHR" [disabled]="!model.totalInuseKWHROperation" >
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.total_charge_time_in_hours' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.totalChargeTimeOperation" class="form-control" name="totalChargeTimeOperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="number" name="totalChargeTime" [(ngModel)]="model.totalChargeTime" [disabled]="!model.totalChargeTimeOperation">
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.total_idle_time_in_hours' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.totalIdleTimeOperation" class="form-control" name="totalIdleTimeOperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="number" name="totalIdleTime" [(ngModel)]="model.totalIdleTime" [disabled]="!model.totalIdleTimeOperation" >
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.total_inuse_time_in_hours' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.totalInuseTimeOperation" class="form-control" name="totalInuseTimeOperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="number" name="totalInuseTime" [(ngModel)]="model.totalInuseTime" [disabled]="!model.totalInuseTimeOperation" >
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'g.total_events' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.totalEventsOperation" class="form-control" name="totalEventsOperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="number" name="totalEvents" [(ngModel)]="model.totalEvents" [disabled]="!model.totalEventsOperation" >
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend class="padding-8">{{'time.dates' | translate}} <a class="pull-right noprint" href="javascript:">
            <i (click)="isCollapsed['dates'] = !isCollapsed['dates']" class="fa " [ngClass]="{'fa-minus': !isCollapsed['dates'], 'fa-plus': isCollapsed['dates']}"></i></a></legend>
          <div *ngIf="!isCollapsed['dates']">
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.commission_date' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.commissionDateOperation" class="form-control" name="commissionDateOperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input class="form-control" placeholder="mm/dd/yyyy" name="commissionDate" [(ngModel)]="model.commissionDate" bsDatepicker #commissionDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" [disabled]="!model.commissionDateOperation">
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.installation_date' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.installationDateOperation" class="form-control" name="installationDateOperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value="=" label="=">=</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                  <option value="!=" label="!=">!=</option>
                </select>
              </div>
              <div class="col-md-5">
                <input class="form-control" placeholder="mm/dd/yyyy" name="installationDate" [(ngModel)]="model.installationDate" bsDatepicker #installationDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" [disabled]="!model.installationDateOperation">
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.manufacturing_date' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.manafacturingDateOperation" class="form-control" name="manafacturingDateOperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value="=" label="=">=</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input class="form-control" placeholder="mm/dd/yyyy" name="manafacturingDate" [(ngModel)]="model.manafacturingDate" bsDatepicker #manafacturingDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" [disabled]="!model.manafacturingDateOperation">
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="form-buttons bvf">
        <button class="btn btn-primary mb20" type="button" (click)="modal.show()"><span class="glyphicon glyphicon-search"></span> {{'g.search' | translate}}</button>
      </div>
    </div>
  </div>
</form>
<app-modal #modal [title]="'g.report_name' | translate" [confirmBtnText]="'submit' | translate" [hasCloseButton]="false">
	<span>{{'g.enter_report_name' | translate}}</span>
	<input type="text" class="form-control" [(ngModel)]="reportName" placeholder="{{'g.report_name' | translate}}" />
</app-modal>