import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { HomeService } from '../home.service';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { FooterService } from '../../shared/footer/footer.service';

@Component({
  selector: 'app-user-agreement',
  templateUrl: './user-agreement.component.html',
  styleUrls: ['./user-agreement.component.css']
})
export class UserAgreementComponent implements OnInit, OnDestroy {

  constructor(
    private authService: AuthService, 
    private router: Router, 
    private homeService: HomeService, 
    private breadcrumb: BreadcrumbService,
    private footerService: FooterService
  ) {
    this.footerService.hide();
    this.breadcrumb.setBreadcrumb([
      {label: 'User Agreement', url: ''}
    ]);
  }

  ngOnInit() { }

  onLogout() {
  	this.authService.logoutUser().subscribe((authenticated: boolean) => {
				this.router.navigate(['login']);
		});
  }
  confirmUserAgreement() {

    this.homeService.confirmUserAgreement().subscribe(
      data => {
        this.router.navigate(['sites-map']);
      }
    );
  };

  ngOnDestroy() {
    this.footerService.show();
    this.breadcrumb.hide();
  }
}
