<form #editDeviceDates="ngForm" (submit)="saveDeviceDates()">
	<div class="col-md-10 col-md-offset-1">
		<div class="boxs3">
			<h2>
				Edit {{deviceType == 'charger' ? 'Charger' : 'Battview'}} {{device[0]?.ispa ? 'Mobile' : ''}} Dates
			</h2>

			<div class="col-sm-12">
				<div *ngFor="let item of device; let i = index">

					<div class="padding-8 col-sm-12 boxs3 body-bg-color">
						<div class="col-sm-3">Serial Number</div>
						<div class="col-sm-5">
							{{item.SN}}
						</div>
						<div class="col-sm-4" *ngIf="currentUser.isACTuser">
							ActView ID: {{item.id}}
						</div>
					</div>

					<div class="padding-8 col-sm-12" *ngIf="isEditingSingleDevices">
						<div class="col-sm-3">Create Date</div>
						<div class="col-sm-5">
							<div class="input-group">
								<input class="form-control" placeholder="mm/dd/yyyy" [name]="'dp1'+i" [(ngModel)]="item.createdat" bsDatepicker #createdat="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" [disabled]="!userService.hasAccessFunction('users_function_management', 'write')">
								<span class="input-group-btn">
									<button class="btn btn-default btn-date" (click)="createdat.toggle()" type="button"><i class="fa fa-calendar"></i></button>
								</span>
							</div>
						</div>
					</div>

					<div class="padding-8 col-sm-12" *ngIf="isEditingSingleDevices">
						<div class="col-sm-3">Commission Date</div>
						<div class="col-sm-5">
							<div class="input-group">
								<input class="form-control" placeholder="mm/dd/yyyy" [name]="'dp2'+i" [(ngModel)]="item.commissiondate" bsDatepicker #commissiondate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" [disabled]="!userService.hasAccessFunction('users_function_management', 'write')">
								<span class="input-group-btn">
									<button class="btn btn-default btn-date" (click)="commissiondate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
								</span>
							</div>
						</div>
					</div>

					<div class="padding-8 col-sm-12" *ngIf="isEditingSingleDevices">
						<div class="col-sm-3">Assigned to Production Line Date</div>
						<div class="col-sm-5">
							<div class="input-group">
								<input class="form-control" placeholder="mm/dd/yyyy" [name]="'dp3'+i" [(ngModel)]="item.assignedtoproductionlinedate" bsDatepicker #assignedtoproductionlinedate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" [disabled]="!userService.hasAccessFunction('users_function_management', 'write')">
								<span class="input-group-btn">
									<button class="btn btn-default btn-date" (click)="assignedtoproductionlinedate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-sm-12">
				<div class="padding-8 col-sm-12">
					<div class="col-sm-3">Shipping Date(UTC/GMT)</div>
					<div class="col-sm-5">
						<div class="input-group">
							<input class="form-control" placeholder="mm/dd/yyyy" name="shippingdate" [(ngModel)]="shippingdate" bsDatepicker #shippingdateDp="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
							<span class="input-group-btn">
								<button class="btn btn-default btn-date" (click)="shippingdateDp.toggle()" type="button"><i class="fa fa-calendar"></i></button>
							</span>
						</div>
					</div>
				</div>
	
				<div class="padding-8 col-sm-12">
					<div class="col-sm-3">Warranty Period <strong *ngIf="isWarrantyVoid">({{'g.void' | translate}})</strong></div>
					<div class="col-sm-5">
						<input type="integer" name="warranty" class="form-control" [(ngModel)]="warrantyperiod">
					</div>
				</div>
			</div>

			<div>
				<button type="submit" class="btn btn-primary" [disabled]="editDeviceDates.invalid">Submit</button>
				<button type="button" class="btn btn-default" (click)="goBack()">Cancel</button>
			</div>
		</div>
	</div>
</form>