import { Component, OnInit, OnDestroy } from '@angular/core';
import { HomeService } from '../home.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { Router } from '@angular/router';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { FooterService } from '../../shared/footer/footer.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit, OnDestroy {

  subject: string;
  enquiry: string;
  constructor(
    private homeService: HomeService,
    private breadcrumb: BreadcrumbService,
    private notificationMessage: NotificationMessageService,
    private router: Router,
    private footer: FooterService
  ) {
    this.breadcrumb.hide();
    this.footer.hide();
  }

  ngOnInit() {
  }
  ngOnDestroy(){
    this.footer.show();
  }
  sendContactUsEmail(){
    this.homeService.sendContactUsEmail(this.subject, this.enquiry).subscribe(
      response => {
        this.notificationMessage.setMessage(response, 'success', 10000, true);
        this.router.navigate(['/sites-map']);
      }
    );
  }
}
