import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { HeaderService } from '../../shared/header/header.service';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { Router } from "@angular/router";

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {

	guest: any = {};
	invalidEmailDomain = true;
	phoneNumberHasDashes = false;
	constructor(
		private authService: AuthService,
		private header: HeaderService,
		private breadcrumb: BreadcrumbService,
		private router: Router,
		private notificationMessage: NotificationMessageService
	) {
		this.header.hide();
		this.breadcrumb.hide();
	}

	ngOnInit() {
	 
	}

	checkDomain(emailNewValue) {
		if(typeof(emailNewValue) == 'string' && emailNewValue.toLowerCase().match(/@(gmail|aol|yahoo|hotmail).com/))
			this.invalidEmailDomain = true;
		else
			this.invalidEmailDomain = false;
	}

	register() {
		this.authService.registerUser(this.guest).subscribe(
			(data: any) => {
				if(data.httpStatus == 'error')
					this.notificationMessage.setMessage(data.msg);
				else if(data.httpStatus == 'success') {
					this.notificationMessage.setMessage(data.msg, 'success');
					this.router.navigate(['/login']);
				}
			}
		);
	}

	onPhoneNumberChanged(value) {
		this.phoneNumberHasDashes = `${value}`.includes('-');
	}

	ngOnDestroy() {
		this.header.show();
	}

	keyPressNumbers(event) {
		var charCode = (event.which) ? event.which : event.keyCode;		
		// Only Numbers 0-9
		if ((charCode < 48 || charCode > 57) && charCode != 45) {
		  event.preventDefault();
		  return false;
		} else {
		  return true;
		}
	  }
}
