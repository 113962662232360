import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { UserService } from '../../auth/user.service';
import { User } from '../../auth/user.model';
import { NavigationService } from '../../shared/services/navigation.service';
import { Router } from '@angular/router';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { HomeService } from '../home.service';

@Component({
	selector: 'app-user-settings',
	templateUrl: './user-settings.component.html',
	styleUrls: ['./user-settings.component.css']
})
export class UserSettingsComponent implements OnInit, OnDestroy {

	user: {
		id: number,
		firstname: string,
		lastname: string,
		lang: string,
		unsubscribe_alert_days: any[]
	} = {
		id: 0,
		firstname:null,
		lastname:null,
		lang:null,
		unsubscribe_alert_days: []
	};
	daysList: {id: number, text: string}[] = [
		{id: 0, text: 'Sunday'},
		{id: 6, text: 'Saturday'},
	];
	unsubscribeDays: boolean[] = [false,false,false,false,false,false,false];

	constructor(
		private homeService: HomeService,
		private breadcrumb: BreadcrumbService,
		private notificationMessage: NotificationMessageService,
		public navigation: NavigationService,
		private router: Router,
		private userService: UserService,    
	) {
		this.breadcrumb.setBreadcrumb([
			{label: 'User Settings', url: ''}
		]);
	}

	ngOnInit() {
		this.userService.currentUser.subscribe((user: User) => {
			this.homeService.getUsers(user.id, false).subscribe(
				(response: {user}) => {
					this.user = response.user;
					for(let day of response.user.unsubscribe_alert_days) {
						this.unsubscribeDays[day] = true;
					}
				}
			);
		});
	}
	updateUserSettings(){
		this.user.unsubscribe_alert_days = [];
					
		for(var day in this.unsubscribeDays) {
			if(this.unsubscribeDays[day] == true)
				this.user.unsubscribe_alert_days.push(+day);
		}
		
		this.homeService.updateUserSettings(this.user).subscribe(
			(userInfo: User) => {
				// override backend user accessFunctions attribute since it always return empty
				let currentUser = this.userService.getCurrentUser();
				userInfo.accessFunctions = currentUser.accessFunctions;
				this.userService.setCurrentUser(userInfo);
				this.notificationMessage.setMessage('Operation done successfully!', 'success', 10000, true);
				this.router.navigate(['/sites-map']);
			}
		);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
