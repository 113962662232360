
import {distinctUntilChanged} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Site } from '../models/site.model';

import * as _ from 'underscore';
import { Customer } from '../models/customer.model';

@Injectable()
export class SideMenuService {
	
	// acvtive customer > set in customer page
	private currentCustomerSubject = new BehaviorSubject<Customer>(new Customer());
	currentCustomer = this.currentCustomerSubject.asObservable().pipe(distinctUntilChanged());
	// active site > set in site page
	private currentSiteSubject = new BehaviorSubject<Site>(new Site());
	currentSite = this.currentSiteSubject.asObservable().pipe(distinctUntilChanged());
	// all expanded customers
	private expandedCustomersSubject = new BehaviorSubject<Object[]>([]);
	expandedCustomers = this.expandedCustomersSubject.asObservable().pipe(distinctUntilChanged());
	 // sites of the expanded customer
	private currentSitesSubject = new BehaviorSubject<Site[]>([]);
	currentSites = this.currentSitesSubject.asObservable().pipe(distinctUntilChanged());
	// mapper between all customers id and their sites
	private sitesCustomerMapSubject = new BehaviorSubject<Map<number, Site[]>>(new Map<number, Site[]>());
	sitesCustomerMap = this.sitesCustomerMapSubject.asObservable().pipe(distinctUntilChanged());

	// mapper between all customers id and their sites
	private filteredSitesCustomerMapSubject = new BehaviorSubject<Map<number, Site[]>>(new Map<number, Site[]>());
	filteredSitesCustomerMap = this.filteredSitesCustomerMapSubject.asObservable().pipe(distinctUntilChanged());

	// all customers
	private customersSubject = new BehaviorSubject<Object>({});
	customers = this.customersSubject.asObservable().pipe(distinctUntilChanged());

	private clickedSiteSubject = new BehaviorSubject<Site>(new Site());
	clickedSiteNotDistinct = this.clickedSiteSubject.asObservable();

	private expandedSitesSubject = new BehaviorSubject<Site[]>([]);
	expandedSites = this.expandedSitesSubject.asObservable().pipe(distinctUntilChanged());
	
	private hideMenuViewSubject = new BehaviorSubject<boolean>(false);
	hideMenuView = this.hideMenuViewSubject.asObservable().pipe(distinctUntilChanged());

	visible : boolean;

	siteInfoBoxVisibility: boolean = false;
	
	private allSites: Site[]= [];
	allFilteredSites: Site[]= [];

	constructor(private httpClient: HttpClient) { 
		this.visible = false;
	}

	hide() { this.visible = false;}

	show() { this.visible = true;}

	toggle() { this.visible = !this.visible; }

	getUserSitesInfo() {
		return this.httpClient.post('/customers/getUserSitesInfo', {getConnectivity: true} );
	}

	setExpandedCustomers(expandedArr) {
		this.expandedCustomersSubject.next([...expandedArr]);
		let currentSites = [];
		let sitesCustomerMap = this.getFilteredSitesCustomerMap();
		expandedArr.forEach((obj) => {
				currentSites = currentSites.concat(sitesCustomerMap[obj['id']]);
		});
		if(currentSites.length == 0){
			this.setExpandedSites(this.allFilteredSites);
		} else
			this.setExpandedSites(currentSites);
	}

	getExpandedCustomers() {
		return this.expandedCustomersSubject.value;
	}

	addExpandedCustomer(id: number, name: string) {
		let expandedCustomersArr = this.getExpandedCustomers();
		let exists = _.find(expandedCustomersArr, function (item) {
			return item.id == id;
		})
		if (!exists) {
			expandedCustomersArr.push({id: id, name: name});
			this.setExpandedCustomers(expandedCustomersArr);
		}
	}

	removeExpandedCustomer(id: number) {
		let expandedCustomersArr = this.getExpandedCustomers();
		expandedCustomersArr = expandedCustomersArr.filter(obj=> obj['id'] != id);
		this.setExpandedCustomers(expandedCustomersArr);
	}

	setAllCustomers(customers) {
		this.customersSubject.next(customers);
	}

	getAllCustomers() {
		return this.customersSubject.value;
	}

	getCustomer(id : number) {
		return this.customersSubject.value[id];
	}

	getSitefromCustomerId(customerId:number, siteId: number): Site {
		const customerData = this.getSitesCustomerMap(customerId);
		if (customerData) {
			const keys =  _.keys(customerData);
			for (let i = 0; i< keys.length; i ++) {
				if (customerData[keys[i]].id == siteId) {
					return customerData[keys[i]];
				} 
			}
		}
		return new Site();
	}

	addSitesCustomerMap(customerId: number, sites: Site[]) {
		let map = this.sitesCustomerMapSubject.value;
		map.set(customerId , sites);
		this.sitesCustomerMapSubject.next(new Map(map));
	}

	setFilteredSitesCustomerMap(obj) {
		const newMap = new Map();
		Object.keys(obj).forEach(k => { newMap.set(parseInt(k), obj[k]) });
		this.filteredSitesCustomerMapSubject.next(newMap);
	}

	getFilteredSitesCustomerMap(customerId = null) {
		if (customerId) {
			return this.filteredSitesCustomerMapSubject.value.get(parseInt(customerId));
		} else {
			const obj = {};
			this.filteredSitesCustomerMapSubject.value.forEach((v,k) => { obj[k] = v });
			return obj;
		}
	}

	setSitesCustomerMap(obj) {
		const newMap = new Map();
		Object.keys(obj).forEach(k => { newMap.set(parseInt(k), obj[k]) });
		this.sitesCustomerMapSubject.next(newMap);
	}

	getSitesCustomerMap(customerId = null) {
		if (customerId) {
			return this.sitesCustomerMapSubject.value.get(parseInt(customerId));
		} else {
			const obj = {};
			this.sitesCustomerMapSubject.value.forEach((v,k) => { obj[k] = v });
			return obj;
		}
	}

	setAllSites(sites: Site[]) {
		this.allSites = sites;
	}

	getAllSites() {
		return this.allSites;
	}

	setExpandedSites(sites: Site[]) {
		this.expandedSitesSubject.next(sites);
	}

	getExpandedSites() {
		return this.expandedSitesSubject.value;
	}

	setCurrentSites(sites: Site[]) {
		this.currentSitesSubject.next(sites);
	}

	getCurrentSites() {
		return this.currentSitesSubject.value;
	}

	setCurrentCustomer(customer: Customer){
		this.currentCustomerSubject.next(customer);
	}

	getCurrentCustomer() {
		return this.currentCustomerSubject.value;
	}
	
	setCurrentSite(site: Site) {
		this.currentSiteSubject.next(site);
	}

	setClickedSite(site: Site) {
		this.clickedSiteSubject.next(site);
	}

	getClickedSite() {
		return this.clickedSiteSubject.value;
	}
	getCurrentSite() {
		return this.currentSiteSubject.value;
	}

	getSiteInfoBoxVisibility() {
		return this.siteInfoBoxVisibility;
	}

	setSiteInfoBoxVisibility(value: boolean) {
		this.siteInfoBoxVisibility = value;
	}

	setHideMenuView(value: boolean) {
		this.hideMenuViewSubject.next(value);
	}

	getHideMenuView() {
		return this.hideMenuViewSubject.value;
	}
}