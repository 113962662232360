<app-modal #cyclesDetectionModal [title]="'battery.charger_cycles_detection' | translate" [hasConfirmButton]="false" [hasCloseButton]="false" [size]="'lg'">
	<div role="alert" class="alert alert-danger alert-dismissible" *ngIf="detectionConfig.invalidFields.length > 0">
		<div>{{'g.invalid_input' | translate}}!</div>
	</div>
	<div class="form-inline row padding-8">
		<div class="col-md-4">{{'device.start_time_detection' | translate}}</div>
		<div class="col-md-8 form-group">
			<span style="margin-right: 10px;">{{'g.from' | translate}} </span>
			<span class="col-md-3 input-group">
				<input type="number" min="-60" max="0" step="1" class="form-control" [(ngModel)]="detectionConfig.minStartTimeRange" [ngClass]="{'invalid-input': isInvalidDetectionConfigField('minStartTimeRange')}" />
				<span class="input-group-addon">{{'g.minute' | translate}}</span>
			</span>
			<span style="margin-left: 10px;margin-right: 10px;">{{'g.to' | translate}} </span>
			<span class="col-md-3 input-group">
				<input type="number" min="0" max="60" step="1" class="form-control" [(ngModel)]="detectionConfig.maxStartTimeRange" [ngClass]="{'invalid-input': isInvalidDetectionConfigField('maxStartTimeRange')}" />
				<span class="input-group-addon">{{'g.minute' | translate}}</span>
			</span>
		</div>
	</div>
	<div class="row padding-8">
		<div class="col-md-4">{{'device.dynamic_event_duration' | translate}}</div>
		<div class="col-md-8">
			<label><input type="radio" name="durationEnabled" [(ngModel)]="detectionConfig.durationEnabled" class="form-control" [value]="true"/> {{'g.enabled' | translate}}</label>
			<label><input type="radio" name="durationEnabled" [(ngModel)]="detectionConfig.durationEnabled" class="form-control" [value]="false"/> {{'g.disabled' | translate}}</label>
		</div>
	</div>
	<div class="form-inline row padding-8">
		<div class="col-md-4">{{'device.duration' | translate}}</div>
		<div class="col-md-8 form-group">
			<span class="col-md-5 input-group">
				<input type="number" min="-60" max="60" step="1" class="form-control" [(ngModel)]="detectionConfig.duration" [disabled]="!detectionConfig.durationEnabled" [ngClass]="{'invalid-input': isInvalidDetectionConfigField('duration')}" />
				<span class="input-group-addon">{{'g.minute' | translate}}</span>
			</span>
		</div>
	</div>
	<div class="modal-footer">
		<div class="col-md-5"><span class="save-btn" (click)="runDetection('OK')">{{'device.run_detection' | translate}}</span></div>
		<div class="col-md-5"><span class="back-btn" (click)="runDetection('cancel')">{{'g.cancel' | translate}}</span></div>
	</div>
</app-modal>
<app-modal #cyclesDetectionResultModal [title]="'Charger Events Detection'" [hasConfirmButton]="false">
	<table class="table table-striped table-condensed table-bordered" *ngIf="cycleDetectionObj?.data?.length > 0">
		<tr>
			<td>Charger</td>
			<td>Cycle ID</td>
		</tr>
		<tr *ngFor="let item of cycleDetectionObj.data">
			<td>
				<a (click)="goToChargerCycle(item.chargerid)" [routerLink]="['/', customerID, siteID, 'chargers', 'analytics', 'cycles', item.chargerid]">
					{{cycleDetectionObj.devices[item.chargerid].chargerusername}} ({{cycleDetectionObj.devices[item.chargerid].serialnumber}})
				</a>
			</td>
			<td>
				{{item.cycleid}}
			</td>
		</tr>
	</table>
	<div class="alert alert-warning" *ngIf="cycleDetectionObj?.data?.length == 0">
		{{'battery.detection_no_result' | translate}}
	</div>
</app-modal>
<app-modal #DaughterCardModal [title]="'Edit BATTView Daughter Card'" [hasConfirmButton]="false" [hasCloseButton]="false">
	<form name="editDaughterCard" novalidate #editDaughterCard="ngForm">
		<div class="modal-body">
			<div class="col-md-12">
				<div class="row padding-8">
					<div class="col-md-5"><strong>Serial Number</strong></div>
					<div class="col-md-7">
						<input disabled="disabled" type="text" class="form-control" [(ngModel)]="daughterCardObject.serialnumber" name="serialnumber" />
					</div>
				</div>

				<div class="row padding-8">
					<div class="col-md-5"><strong>Charger Name</strong></div>
					<div class="col-md-7">
						<input ng-required="true" type="text" class="form-control" [(ngModel)]="daughterCardObject.pretty_name" name="pretty_name" />
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<div class="row padding-8">
				<div class="col-md-6 col-md-offset-6">
					<div class="col-md-12 margin-top">
						<button class="btn btn-primary" (click)="editDaughterCardRequest()" [disabled]="editDaughterCard.invalid">Save</button>
						<button class="btn btn-default" (click)="DaughterCardModal.hide();">Cancel</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</app-modal>
<div class="boxs3">
	<div style="padding: 10px">
		<div style="width: 25%;padding-bottom: 10px;">
			<div class="form-field">
				<ng-select [items]="eventsFilters" bindLabel="name" name="filter" placeholder="Select a Filter" (ngModelChange)="filterEvents($event)" [(ngModel)]="selectedEventsFilter" *ngIf="!isBattviewMobile" [clearable]="false">
				</ng-select>
			</div>
		</div>
		<div class="row">
			<label class="col-md-1"><input type="radio" [(ngModel)]="isVPC" class="form-control" [value]="true" (click)="onChangeVPC(true)" /> VPC</label>
			<label class="col-md-2"><input type="radio" [(ngModel)]="isVPC" class="form-control" [value]="false" (click)="onChangeVPC(false)" /> Battery Voltage</label>
			<span class="col-md-9" *ngIf="!isBattviewMobile">
				<input type="checkbox" id="tmp-checkbox" [(ngModel)]="isCompensatedVoltage" class="form-control" (ngModelChange)="onChangeTemperatureCompensatedVoltage($event)" [checked]="true"/>
				<label for="tmp-checkbox">Show Voltages as Temperature Compensated</label>
			</span>
		</div>
	</div>
</div>

<app-grid-v2 #grid id="events-grid" [columns]="gridColumns" [data]="gridData" [settings]="{floatingFiltersHeight: 60}" translateHeaders="true"></app-grid-v2>