<div class="boxs3">
	<ul class="nav nav-pills">
		<li [ngClass]="{active: activePage == 'analytics' && !isShared}" appDropdown>
			<a class="glyphicon glyphicon-stats dropdown-toggle">
				<span style="font-family:sans-serif;" >&nbsp;Analytics</span>
				<i class="fa fa-caret-down"></i>
			</a>
			<ul class="z-index dropdown-menu" role="menu">
				<li [ngClass]="{active:activeTab == 'truck-summary'}" class="truckTopNav">
					<a (click)="setTab('truck-summary', 'analytics')">
						<i class="custom-icon glyphicon glyphicon-align-justify"></i>
						<span style="font-family:sans-serif;"> 
							&nbsp;Truck Summary
						</span>
					</a>
				</li>
				<li [ngClass]="{active:activeTab == 'truck-report'}" class="dropdown-submenu truckTopNav" appDropdown>
					<a tabindex="-1">
						<i class="custom-icon fa fa-truck-three-quarters"></i>
						<span style="font-family:sans-serif;">&nbsp;Truck Reports
							<i class="fa fa-caret-right margin-left-sm"></i>
						</span>
					</a>
					<ul class="z-index dropdown-menu" role="menu">
						<li [ngClass]="{active:activeTab == 'truck-usage-summary'}"><a (click)="setTab('truck-usage-summary', 'analytics')" class="has-action">Truck Usage Summary</a></li>
						<li [ngClass]="{active:activeTab == 'truck-daily-usage'}"><a (click)="setTab('truck-daily-usage', 'analytics')" class="has-action">Truck Daily Usage</a></li>
						<li [ngClass]="{active:activeTab == 'events-log'}"><a (click)="setTab('events-log', 'analytics')" class="has-action">Events Log</a></li>
						<li [ngClass]="{active:activeTab == 'exceptions'}"><a (click)="setTab('exceptions', 'analytics')" class="has-action">Exceptions</a></li>
						<li [ngClass]="{active:activeTab == 'charge-summary'}"><a (click)="setTab('charge-summary', 'analytics')" class="has-action">Charge Summary</a></li>
					</ul>
				</li>
				<li [ngClass]="{active:activeTab == 'chart'}" class="dropdown-submenu truckTopNav" appDropdown>
					<a tabindex="-1" subtoggle>
						<i class="custom-icon fa fa-bar-chart"></i>
						<span style="font-family:sans-serif;">&nbsp;Truck Charts
							<i class="fa fa-caret-right margin-left-sm"></i>
						</span>
					</a>
					<ul class="z-index dropdown-menu" role="menu">
						<li [ngClass]="{active:activeSubTab == 'daily-usage'}"><a (click)="setTab('chart','analytics','daily-usage')" class="has-action">Daily Usage</a></li>
						<li [ngClass]="{active:activeSubTab == 'daily-ebus'}"><a (click)="setTab('chart','analytics','daily-ebus')" class="has-action">Daily EBUs</a></li>
						<li [ngClass]="{active:activeSubTab == 'daily-ahrs'}"><a (click)="setTab('chart','analytics','daily-ahrs')" class="has-action">Daily AHrs</a></li>
						<li [ngClass]="{active:activeSubTab == 'soc'}"><a (click)="setTab('chart','analytics','soc')" class="has-action">SOC Chart</a></li>
						<li [ngClass]="{active:activeSubTab == 'max-temperature'}" *ngIf="!hideTemperatureChart"><a (click)="setTab('chart','analytics','max-temperature')" class="has-action">Max Temperature</a></li>
						<li [ngClass]="{active:activeSubTab == 'end-temperature'}" *ngIf="!hideTemperatureChart"><a (click)="setTab('chart','analytics','end-temperature')" class="has-action">End Temperature</a></li>
						<li [ngClass]="{active:activeSubTab == 'end-voltage'}"><a (click)="setTab('chart','analytics','end-voltage')" class="has-action">End Voltage</a></li>
						<li [ngClass]="{active:activeSubTab == 'customized'}"><a (click)="setTab('chart','analytics','customized')" class="has-action">Customized Charts</a></li>
					</ul>
				</li>
				<li [ngClass]="{active:activeTab == 'global-record'}" class="truckTopNav" *ngIf="userService.hasAccessFunction('extended_global_records')">
					<a (click)="setTab('global-record', 'analytics')">
						<i class="custom-icon glyphicon glyphicon-globe"></i>
						<span style="font-family:sans-serif;"> 
							&nbsp;Global Record
						</span>
					</a>
				</li>
				<!-- Operation Analysis -->
				<li [ngClass]="{active:activeTab == 'soc-prediction'}" class="truckTopNav" *ngIf="userService.hasAccessFunction('has_soc_predication')">
					<a (click)="setTab('soc-prediction', 'analytics')">
						<i class="custom-icon glyphicon glyphicon-tasks"></i>
						<span style="font-family:sans-serif;"> 
							&nbsp;Operation Analysis (Beta)
						</span>
					</a>
				</li>
			</ul>
		</li>
		<li [ngClass]="{active:activePage == 'settings'}" *ngIf="showSettingsTab">
			<a class="glyphicon glyphicon-cd" (click)="setTab('', 'settings')"><span style="font-family:sans-serif;">&nbsp;Settings</span></a>
		</li>
		<li [ngClass]="{active: activePage == 'quick-view'}" *ngIf="1 || userService.hasAccessFunction('access_truckview_quick_view') && device?.actviewenabled">
			<a class="glyphicon glyphicon-unchecked" (click)="setTab('','quick-view')"><span style="font-family:sans-serif;">&nbsp;Quick View</span></a>
		</li>
		<li [ngClass]="{active: activePage == 'faults'}" *ngIf="userService.hasAccessFunction('display_faults_in_analytics_page')">
			<a class="glyphicon glyphicon-alert" (click)="setTab('','faults')"><span style="font-family:sans-serif;">&nbsp;TRUCKView Faults</span></a>
		</li>

		<li [ngClass]="{active: activePage == 'list-rt-records' || activePage == 'fetch-rt-records'}" *ngIf="((userService.hasAccessFunction('act_admin_device_management') && device?.actviewenabled) || (currentUser.isACTuser && device?.actviewenabled))" appDropdown>
			<a closesubtoggles class="glyphicon glyphicon-stats dropdown-toggle" dropdown-toggle>
				<span style="font-family:sans-serif;">RT Records</span>
				<i class="fa fa-caret-down"></i>
			</a>
			<ul class="z-index dropdown-menu" role="menu">
				<li [ngClass]="{active: activePage == 'fetch-rt-records'}" *ngIf="userService.hasAccessFunction('act_admin_device_management') && device?.actviewenabled">
					<a class="glyphicon glyphicon-floppy-save" (click)="setTab('', 'fetch-rt-records')"><span style="font-family:sans-serif;">&nbsp;Fetch RT Records</span></a>
				</li>
				<li [ngClass]="{active: activePage == 'list-rt-records'}" *ngIf="currentUser.isACTuser && device?.actviewenabled">
					<a class="glyphicon glyphicon-th-list" (click)="setTab('', 'list-rt-records')"><span style="font-family:sans-serif;">&nbsp;List RT Records</span></a>
				</li>
			</ul>
		</li>
		<li [ngClass]="{active: activePage == 'debug-records'}" *ngIf="userService.hasAccessFunction('access_debug_records')">
			<a class="glyphicon glyphicon-compressed" (click)="setTab('','debug-records')"><span style="font-family:sans-serif;">&nbsp;Debug Records</span></a>
		</li>
		<li [ngClass]="{active: activePage == 'connectivity-track'}" *ngIf="userService.hasAccessFunction('act_admin_device_management')">
			<a class="glyphicon glyphicon-transfer" (click)="setTab('','connectivity-track')"><span style="font-family:sans-serif;">&nbsp;Connectivity Track</span></a>
		</li>
		<li [ngClass]="{active: activePage == 'notes'}">
			<a class="glyphicon glyphicon-pencil" (click)="setTab('','notes')"><span style="font-family:sans-serif;">&nbsp;Notes</span></a>
		</li>
		<li [ngClass]="{active: activePage == 'listing'}" *ngIf="showBvMobileListingTab">
			<a (click)="setTab('', 'listing')">
				<i class="fa fa-list-alt margin-right-sm"></i><span style="font-family:sans-serif;">&nbsp;List</span>
			</a>
		</li>

		<li class="r" *ngIf="hasData">
			<div *ngIf="isDeviceSelected() && date.fromDate && date.toDate">
				<a *ngIf="pageHasPrintBtn()" class="btn btn-primary fa fa-print margin-top-sm" (click)="printContent()" data-toggle="tooltip" title="Print"></a>
				<button *ngIf="pageHasExportToExcelBtn()" class="fa fa-upload btn btn-primary margin-top-sm" data-toggle="tooltip" title="{{'g.export_to_csv' | translate}}" (click)="exportToCsv()"></button>
			</div>
		</li>
	</ul>
</div>