<div *ngIf="viewName == 'dashboard'">
	<app-battview-tabs #tabs
		[isBattviewMobile]="isBattviewMobile"
		[date]="date"
		[device]="device"
		[showSettingsTab]="showSettingsTab"
		[showBvMobileListingTab]="showBvMobileListingTab"
		[hideTemperatureChart]="true"
		[activePage]="activePage"
		[activeTab]="activeTab"
		[activeSubTab]="activeSubTab"
		[currentUser]="currentUser"
		[isShared]="isShared"
		[hasData]="hasData"
		(onTabChanged)="updateTabs($event)"
		(onExport)="analyticsPageComponent.onExport()"
	></app-battview-tabs>
	<app-battviews-mobiles-select-criteria
		#battviewSelectCriteriaComponent
		*ngIf="activePage != 'settings' || (activePage == 'settings' && battviewsIDs.length <= 1)"
		[currentUser]="currentUser"
		[battviewID]="battviewID"
		[studyID]="studyID"
		[activePage]="activePage"
		[showSettingsTab]="showSettingsTab"
		[activeTab]="activeTab"
		[activeSubTab]="activeSubTab"
		[showElementsFlags]="showElementsFlags"
		[isShared]="isShared"
		(updateShowSettingsTab)="updateShowSettingsTab($event)"
		(onDeviceChanged)="updateSelectedDevice($event)"
		(onDateChanged)="updateSelectedDate($event)"
		(updateAlertSettings)="updateAlertSettings($event)"
		(updatePageHasRTrecord)="updatePageHasRTrecord($event)"
		(updateHasBvMobileListing)="updateHasBvMobileListing($event)"
		(onDealerChanged)="onDealerChanged($event)"
	></app-battviews-mobiles-select-criteria>
</div>
<div [ngSwitch]="activePage">
	<app-battview-analytics #analyticsPageComponent
		*ngSwitchCase="'analytics'"
		[customerID]="customerID"
		[siteID]="siteID"
		[device]="device"
		[date]="date"
		[activeTab]="activeTab"
		[activeSubTab]="activeSubTab"
		[isBattviewMobile]="isBattviewMobile"
		[siteInfo]="siteInfo"
		[customerInfo]="customerInfo"
		[currentUser]="currentUser"
		[siteAlertsSettings]="siteAlertsSettings"
		[userAlertsSettings]="userAlertsSettings"
		[hideTemperatureChart]="true"
		[pageHasRTrecord]="pageHasRTrecord"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
		(updateHasData)="hasData = $event"
	></app-battview-analytics>
	<app-battview-quick-view #quickViewComponent
		*ngSwitchCase="'quick-view'"
		[device]="device"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-battview-quick-view>
	<app-battviews-faults #faultsComponent
		*ngSwitchCase="'faults'"
		[device]="device"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-battviews-faults>
	<app-device-notes
		*ngSwitchCase="'notes'"
		[device]="device"
		[deviceType]="isBattviewMobile ? 'battview mobile' : 'battview'"
		[sourcePage]="isBattviewMobile ? 'battview_mobile' : 'battview_notes'"
		[siteId]="siteID"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-device-notes>
	<app-debug-records #debugRecordsComponent
		*ngSwitchCase="'debug-records'"
		[device]="device"
		[date]="date"
		[deviceType]="'battview'"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-debug-records>
	<app-config-track #configTrackComponent
		*ngSwitchCase="'config-track'"
		[device]="device"
		[deviceType]="'battview'"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-config-track>
	<app-fetch-rt-records #fetchRtRecordsComponent
		*ngSwitchCase="'fetch-rt-records'"
		[device]="device"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-fetch-rt-records>
	<app-list-rt-records #listRtRecordsComponent
		*ngSwitchCase="'list-rt-records'"
		[device]="device"
		[siteName]="siteInfo.name"
		[companyName]="customerInfo.name"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-list-rt-records>
	<app-connectivity-track #connectivityTrackComponent
		*ngSwitchCase="'connectivity-track'"
		[hasDevices]="true"
		[device]="device"
		[deviceType]="'battview'"
		[dateRange]="{
			'fromDateFmt': date.fromDate,
			'toDateFmt': date.toDate
		}"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-connectivity-track>
	<app-battviews-mobiles-listing
		*ngSwitchCase="'listing'"
		#bvMLComponent
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-battviews-mobiles-listing>
	<app-battview-settings
		*ngSwitchCase="'settings'"
		[battviewsIDs]="battviewsIDs"
		[customerID]="customerID"
		[siteID]="siteID"
		[isBattviewMobile]="isBattviewMobile"
		[hasBattviews]="true"
		(onGetBattviewInfo)="updateSelectedDevice([$event], 'onGetBattviewInfo')"
		(onMultipleOptionsFlag)="battviewSelectCriteriaComponent.multipleOptions = $event"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-battview-settings>
</div>