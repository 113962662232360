<div class="row">
  <div id="page-wrapper" >
    <div id="page-inner">
      <div class="panel panel-default">
        <div class="boxs3">
          <form name="log" novalidate>
            <div class="col-md-12">
              <div class="col-md-3 padding-8">
                <strong>Get by:</strong>
                <select [(ngModel)]="logOptions.getBy" class="form-control inline-form-control" (change)="resetSelects()" name="getBy">
                  <option *ngFor="let option of filterOptions" [value]="option">{{option}}</option>
                </select>
              </div>

              <div class="padding-8" *ngIf="logOptions.getBy != 'Date' && logOptions.getBy != 'Rejection Reason' && logOptions.getBy != 'Device Type'" [ngClass]="{'col-md-6': (logOptions.getBy != 'IP Address'), 'col-md-4': (logOptions.getBy == 'IP Address')}">
                <div class="col-md-2 text-right"><strong>Value:</strong></div>
                <div class="col-md-10" *ngIf="logOptions.getBy != 'IP Address'">
                  <input type="text" class="form-control" [(ngModel)]="logOptions.value" name="value" />
                </div>
                <div class="col-md-10" *ngIf="logOptions.getBy == 'IP Address'">
                  <input
                    type="text"
                    class="form-control inline-form-control"
                    [(ngModel)]="logOptions.ipAddress"
                    name="ipAddress"
                    pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
                    placeholder='0.0.0.0'
                    ng-ip-address
                    #ipAddress="ngModel"
                  />
                  <span class="red-text" *ngIf="ipAddress.invalid && (ipAddress.dirty || ipAddress.touched)">* Invalid IP Address</span>
                </div>
              </div>

              <div class="col-md-6 padding-8" *ngIf="logOptions.getBy == 'Rejection Reason'">
                <div class="col-md-2 text-right"><strong>Reason:</strong></div>
                <div class="col-md-10">
                  <select [(ngModel)]="logOptions.reason" class="form-control inline-form-control" name="reason">
                    <option *ngFor="let rejection_reason of rejection_reasons" [value]="rejection_reason">{{rejection_reason}}</option>
                  </select>
                </div>
              </div>

              <div class="col-md-6 padding-8" *ngIf="logOptions.getBy == 'Device Type'">
                <div class="col-md-2 text-right"><strong>Type:</strong></div>
                <div class="col-md-10">
                  <select [(ngModel)]="logOptions.deviceType" class="form-control inline-form-control" name="deviceType">
                    <option *ngFor="let rejection_reason of ['battview', 'charger']" [value]="rejection_reason">{{rejection_reason}}</option>                    
                  </select>
                </div>
              </div>

              <div class="col-md-2 padding-8" *ngIf="logOptions.getBy == 'IP Address'">
                <input type="checkbox" [(ngModel)]="logOptions.execlude" name="execlude" /> Exclude
              </div>
              
              <div class="col-md-1 padding-8" *ngIf="logOptions.getBy != 'Date'">
              </div>
              
              <div class="col-md-7 padding-8" *ngIf="logOptions.getBy == 'Date'">
                <div class="col-md-6">
                  <div class="col-md-4 text-right"><strong>From Date:</strong></div>
                  <div class="col-md-8">
                    <div class="input-group">
                      <input class="form-control" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="fromDateFmt" bsDatepicker #d="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
                      <span class="input-group-btn">
                        <button class="btn btn-default btn-date" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="col-md-4 text-right"><strong>To Date:</strong></div>
                  <div class="col-md-8">
                    <div class="input-group">
                      <input class="form-control" placeholder="mm/dd/yyyy" name="dp1" [(ngModel)]="toDateFmt" bsDatepicker #d1="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
                      <span class="input-group-btn">
                        <button class="btn btn-default btn-date" (click)="d1.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-1 padding-8">
                <button class="form-control btn btn-success" (click)="getRejectedDeviceConnections()" [disabled]="checkDisableSubmitButton() || (logOptions.getBy == 'IP Address' && ipAddress.invalid)">Get Logs</button>
              </div>

              <div class="col-md-1">
                <button class="fa fa-upload btn btn-primary margin-top-sm" title="{{'g.export_to_csv' | translate}}" (click)="modal.show()"></button>
              </div>
            </div>
          </form>

          <table class="table table-stripped boxs">
            <thead>
              <tr>
                <th>Time</th>
                <th>Device Type</th>
                <th>ACTView ID</th>
                <th>Serial Number</th>
                <th>Customer Name</th>
                <th>Site Name</th>
                <th>Reason</th>
                <th>Extra Info</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody *ngIf="filteredLogs.length > 0">
              <tr *ngFor="let log of filteredLogs">
                <td>{{log.time | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
                <td>{{log.devicetype}}</td>
                <td>{{log.actviewid}}</td>
                <td>{{log.serialnumber}}</td>
                <td>{{getCustomerName(log)}}</td>
                <td>{{getSiteName(log)}}</td>
                <td>{{log.reason}}</td>
                <td>{{log.extrainfo | json}}</td>
                <td>
                  <div class="btn-group" appDropdown>
                    <i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
                    <ul class="dropdown-menu" role="menu">
                      <li *ngIf="log.devicetype == 'charger'">
                        <a *ngIf="chargers[log.actviewid] && !chargers[log.actviewid].isdeleted && !chargers[log.actviewid].replacmentpart" class="pointer" (click)="goToEditDevice(chargers[log.actviewid], log.devicetype)">Edit Device</a>
                      </li>
                      <li *ngIf="log.devicetype == 'battview'">
                        <a *ngIf="battviews[log.actviewid] && !battviews[log.actviewid].isdeleted && !battviews[log.actviewid].replacmentpart" class="pointer" (click)="goToEditDevice(battviews[log.actviewid], log.devicetype)">Edit Device</a>
                      </li>
                      <li><a class="pointer" (click)="deleteRejectionLog(log.id)">Delete Log</a></li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination [allItems]="logInfo" (onPageChanged)="loadData($event)" [count]="numLogsPerPage"></pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal #modal [title]="'Export Rejected Device Connections'" [hasConfirmButton]="false" [hasCloseButton]="false">
  <div class="margin-bottom-sm">Select export period:</div>
  <select class="form-control" [(ngModel)]="interval">
    <option value="">Select a period</option>
    <option value="day">Last 24 hours</option>
    <option value="week">Last week</option>
    <option value="all">Since beginning</option>
  </select>
  <div class="padding-8">
    <button class="btn btn-primary" title="{{'g.export_to_csv' | translate}}" (click)="rejectionUserAction()" [disabled]="!interval">
      <span class="glyphicon glyphicon-export"></span> Export
    </button>
    <button class="btn btn-default" (click)="modal.hide();interval = '';">Close</button>
  </div>
</app-modal>