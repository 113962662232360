import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditorModule } from '@tinymce/tinymce-angular';

import { ErrorLogComponent } from './logs/error-log/error-log.component';
import { ViewErrorComponent } from './logs/error-log/view-error/view-error.component';
import { SchedControlComponent } from './sched/sched-control/sched-control.component';
import { SchedMonitorComponent } from './sched/sched-monitor/sched-monitor.component';
import { AdminRoutingModule } from './admin-routing.module';
import { OemComponent } from './users/oem/oem.component';
import { OemEditComponent } from './users/oem/oem-edit/oem-edit.component';
import { DealerComponent } from './users/dealer/dealer.component';
import { DealerEditComponent } from './users/dealer/dealer-edit/dealer-edit.component';
import { CustomerComponent } from './users/customer/customer.component';
import { CustomerEditComponent } from './users/customer/customer-edit/customer-edit.component';
import { ActlinkFailedDbComponent } from './logs/actlink-failed-db/actlink-failed-db.component';
import { FaultedQueueComponent } from './logs/faulted-queue/faulted-queue.component';
import { SitesComponent } from './sites/sites.component';
import { WidgetsManagementComponent } from './widgets-management/widgets-management.component';
import { SiteEditComponent } from './sites/site-edit/site-edit.component';
import { ChargersComponent } from './devices/chargers/chargers.component';
import { BattviewsComponent } from './devices/battviews/battviews.component';
import { ActionLogComponent } from './logs/action-log/action-log.component';
import { UserSearchComponent } from './users/user/user-search/user-search.component';
import { UserEditComponent } from './users/user/user-edit/user-edit.component';
import { SiteEditPageComponent } from './sites/site-edit-page/site-edit-page.component';
import { UserSearchOptionsComponent } from './users/user/user-search-options/user-search-options.component';
import { AccessPermissionsComponent } from './users/user/access-permissions/access-permissions.component';
import { EditAccessFunctionsComponent } from './users/user/edit-access-functions/edit-access-functions.component';
import { CustomerEditPageComponent } from './users/customer/customer-edit-page/customer-edit-page.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RegistrationRequestsComponent } from './users/registration-requests/registration-requests.component';
import { RegistrationPurposesComponent } from './users/registration-purposes/registration-purposes.component';
import { SmartRebatesSitesComponent } from './users/smart_rebates_sites/smart_rebates_sites.component';
import { ValidateRequestComponent } from './users/registration-requests/validate-request/validate-request.component';
import { ManageDevicePageComponent } from './devices/manage-device-page/manage-device-page.component';
import { DeviceEditPageComponent } from './devices/device-edit-page/device-edit-page.component';
import { EquipmentListingComponent } from './devices/equipment/equipment-listing/equipment-listing.component';
import { ManageEquipmentComponent } from './devices/equipment/manage-equipment/manage-equipment.component';
import { CustomizedEmailsComponent } from './customized-emails/customized-emails.component';
import { RejectedDeviceConnectionsComponent } from './logs/rejected-device-connections/rejected-device-connections.component';
import { ReplacementDetectionComponent } from './logs/replacement-detection/replacement-detection.component';
import { ManageActIntelligentComponent } from './sites/manage-act-intelligent/manage-act-intelligent.component';
import { ManageDeviceDatesPageComponent } from './devices/manage-device-dates-page/manage-device-dates-page.component';
import { ManageDevicePurchaseOrderComponent } from './devices/manage-device-purchase-order/manage-device-purchase-order.component';
import { ViewFaultedQueueComponent } from './logs/faulted-queue/view-faulted-queue/view-faulted-queue.component';
import { ShortUrlComponent } from './devices/short-url/short-url.component';
import { TranslateFileLoader } from '../TranslateFileLoader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { SchedHistoryComponent } from './sched/sched-history/sched-history.component';
import { InvoiceMismatchForSiteBattviewsComponent } from './sites/invoice-mismatch-for-site-battviews/invoice-mismatch-for-site-battviews.component';
import { AdminActionsComponent } from './sched/admin-actions/admin-actions.component';
import { ApisLogsComponent } from './apis-logs/apis-logs.component';
import { SchedRunComponent } from './sched/sched-run/sched-run.component';
import { FakeDataGeneratorComponent } from './fake-data-generator/fake-data-generator.component';

// AoT requires an exported function for factories
export function TranslateFileLoaderFactory() {
	return new TranslateFileLoader();
}

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
		AdminRoutingModule,
		SharedModule,
		NgSelectModule,
		EditorModule,
		BsDatepickerModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: TranslateFileLoaderFactory,
			}
		}),
	],
	declarations: [
		ErrorLogComponent,
		SchedControlComponent,
		SchedMonitorComponent,
		AdminActionsComponent,
		OemComponent,
		ViewErrorComponent,
		OemEditComponent,
		DealerComponent,
		DealerEditComponent,
		ActlinkFailedDbComponent,
		CustomerComponent,
		CustomerEditComponent,
		FaultedQueueComponent,
		SitesComponent,
		WidgetsManagementComponent,
		SiteEditComponent,
		ChargersComponent,
		BattviewsComponent,
		ActionLogComponent,
		UserSearchComponent,
		UserEditComponent,
		SiteEditPageComponent,
		UserSearchOptionsComponent,
		AccessPermissionsComponent,
		EditAccessFunctionsComponent,
		CustomerEditPageComponent,
		RegistrationRequestsComponent,
		RegistrationPurposesComponent,
		SmartRebatesSitesComponent,
		ValidateRequestComponent,
		ManageDevicePageComponent,
		DeviceEditPageComponent,
		EquipmentListingComponent,
		ManageEquipmentComponent,
		CustomizedEmailsComponent,
		RejectedDeviceConnectionsComponent,
		ReplacementDetectionComponent,
		ManageActIntelligentComponent,
		ManageDeviceDatesPageComponent,
		ManageDevicePurchaseOrderComponent,
		ViewFaultedQueueComponent,
		ShortUrlComponent,
		SchedHistoryComponent,
		InvoiceMismatchForSiteBattviewsComponent,
		ApisLogsComponent,
		SchedRunComponent,
		FakeDataGeneratorComponent,
	]
})
export class AdminModule { }
