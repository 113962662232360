import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ControlContainer, NgForm } from '@angular/forms';
import * as _ from 'underscore';

import { AdminService } from '../../admin.service';
import { NavigationService } from '../../../shared/services/navigation.service';
import { UserService } from '../../../auth/user.service';
import { SiteEditComponent } from '../../sites/site-edit/site-edit.component';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { BattviewService } from '../battviews/battview.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';

@Component({
	selector: 'app-manage-device-page',
	templateUrl: './manage-device-page.component.html',
	styleUrls: ['./manage-device-page.component.css'],
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class ManageDevicePageComponent implements OnInit, OnDestroy {

	@ViewChild('appSiteEdit') appSiteEdit: SiteEditComponent;

	deviceIDs = [];
	devicesSN = [];
	OEMsList = [];
	ACTusers = [];
	clients = [];
	clientSites = {};
	dealersList = [];
	serviceDealersList = [];
	// truckDealersList = [];
	device: any = {};
	deviceType: String;
	currentUser: any = {};
	deviceRequest = null;
	isAdmin = false;

	newClientSites	= {'id': 0, 'name':'- New Site -'};
	newClient		= {'id': 0, 'name':'- New Customer -'};
	noClient		= {'id': 'no', 'name':'- No Customer -'};

	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute,
		private navigation: NavigationService,
		public userService: UserService,
		private battviewService: BattviewService,
		private notificationMessage: NotificationMessageService,
		private commonUtil: CommonUtil,
		private breadcrumb: BreadcrumbService,
	){
		this.device['site'] = {};
		this.device['client'] = {};
	}

	ngOnInit() {
		this.deviceIDs = this.route.snapshot.params['deviceIDs'].split(',');
		this.deviceType = this.route.snapshot.params['type'];
		this.route.queryParams.subscribe( params => {
			this.isAdmin = params['isAdmin'] === "true";
		});
		this.currentUser = this.userService.getCurrentUser();

		this.breadcrumb.setBreadcrumb([{'label': 'Devices Management', 'url': ''}]);

		if(this.isAdmin) {
			this.adminService.getDeviceOwnerChangeRequest(this.deviceIDs, this.deviceType).subscribe(
				data => this.initializePageVariables(data)
			);
		} else {
			this.adminService.getDeviceOwners(this.deviceIDs, this.deviceType).subscribe(
				data => this.initializePageVariables(data)
			);
		}
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}

	initializePageVariables(response) {

		this.device				= response.deviceInfo || {};
		this.device['site']		= {};
		this.device['client']	= {};
		this.OEMsList			= response.OEMsList || [];
		this.dealersList		= response.dealersList || [];
		this.serviceDealersList	= response.serviceDealersList || [];
		// this.truckDealersList	= response.truckDealersList || [];
		this.clients			= response.clientsList || [];
		this.deviceRequest		= response.deviceRequest || null;
		this.ACTusers			= response.ACTusers || [];
		this.devicesSN			= response.devicesSN || [];

		// sort clients by name
		this.clients = _.sortBy(this.clients, function(o) { return o.name.toLowerCase(); })

		if(this.isAdmin) {

			this.serviceDealersList = this.dealersList.slice();
			this.device['site'].act_user_id			= 0;
			this.device['site'].oem_id				= 0;
			this.device['site'].dealer_id			= 0;
			this.device['site'].service_dealer_id	= 0;
			// this.device['site'].truck_dealer_id		= 0;
		}

		if(response.clientsList) {
			if(this.device['ispa']) {
				
				if(this.device['customerid'] === 0)
					this.device['customerid'] = this.noClient.id;

				this.clients.unshift(this.noClient);
			}
			this.clients.unshift(this.newClient);
		}

		if(response.sitesList) {

			let clientSites		= [this.newClientSites].concat(response.sitesList);
			this.clientSites	= this.commonUtil.arrayToAssociativeArray(clientSites, 'id');
			
			this.showSelectedAddress(this.device['siteid']);
		}

		if(this.deviceRequest && this.deviceRequest['sitesList']) {

			this.deviceRequest['sitesList']	= this.commonUtil.arrayToAssociativeArray(this.deviceRequest['sitesList'], 'id');
			
			if(this.deviceRequest['deviceInfo']['siteid'] && this.deviceRequest['sitesList'][this.deviceRequest['deviceInfo']['siteid']])
				this.deviceRequest['siteAddress'] = this.deviceRequest['sitesList'][this.deviceRequest['deviceInfo']['siteid']].address;
		}
	}

	showSelectedAddress(siteId) {
		this.device['siteid'] = siteId;
		
		if(this.device['ispa'])
			return;

		if(siteId == 0) {
			this.device['site'] = {
				id: 0,
				address: ''
			};
		} else {

			this.device['site'].id = siteId;
			if(siteId && this.clientSites[siteId])
				this.device['site'].address = this.clientSites[siteId].address;
		}
	}

	GetClientSitesByRoleId(customerid) {
		this.device['customerid'] = customerid;
		
		if(this.device['ispa'])
			return;

		this.device['site'] = {};
		this.device['client'] = {};
		delete this.device['siteid'];
		
		if(customerid > 0) {
			this.adminService.GetClientSitesByRoleId({'Id': 0, 'customerId': customerid}).subscribe(
				data => {
					let clientSites		= [this.newClientSites].concat(data['list']);
					this.clientSites	= this.commonUtil.arrayToAssociativeArray(clientSites, 'id');
				}
			);
		} else {
			this.clientSites = {'0': this.newClientSites};
		}
	};

	saveDeviceOwners() {
				
		this.device['devicetype']	= this.deviceType;
		this.device['deviceIDs']	= this.deviceIDs;

		this.adminService.saveDeviceOwners(this.device).subscribe(
			(data: any) =>	{
				if(data.httpStatus == 'error') {

					var errorMessage = this.battviewService.formatProductionLineErrors(data.msg);
					this.notificationMessage.setMessage(errorMessage, 'danger', 30000);
				} else {
					if (data.duplicateSite)
						return this.notificationMessage.setMessage('translate|site.duplicate_site');

					// URL params IDs (customerid,siteid) will be updated with newly loaded IDs & corresponding page should be reflected
					let url = this.navigation.getPreviousUrl();
					let urls: any[] = url.split('/');
					if(urls[1] == +urls[1] && urls[2] == +urls[2]) {
						urls[1] = data.customerid;
						urls[2] = data.siteid;
						this.navigation.setPreviousUrl(urls.join('/'));
					}
					this.goBack();
				}
			}
		);
	};

	goBack() {
		this.navigation.goBack();
	}
}