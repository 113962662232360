import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PieChartConfig } from '../../../../../shared/google-chart/Models/PieChartConfig';

@Component({
	selector: 'app-missed-plugin-charge-cycles-alert',
	templateUrl: './missed-plugin-charge-cycles-alert.component.html',
	styleUrls: ['./missed-plugin-charge-cycles-alert.component.css']
})
export class MissedPluginChargeCyclesAlertComponent implements OnInit, OnChanges {

	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: PieChartConfig;
	tableColumns: any[] = [];
	title: string = 'Missed Plugin (#of Charge Cycles)';
	elementId: string = 'missed_plugin_charge_cycles';
	widgetClass: string = 'height-530';
	siteWidgetOptions: any = {
		hasDate: true,
		isAlertWidget: true
	};

	constructor() { }

	ngOnInit() {
	
		this.config = new PieChartConfig({
			colors: [
				{ color: '#109618' },
				{ color: '#bd2f26' },
			]
		});
		
		this.config.formatFunction = (data) => {
			let formattedData: any[] = [
				[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"}
				]
			];

			formattedData.push([{v: "No Alerts"}, {v: data.Pie[0].ok}]);
			formattedData.push([{v: "Missed Charge Cycles"}, {v: data.Pie[1].one_or_more}]);
			return formattedData;
		};
	}
	
	ngOnChanges(changes) {
		if(this.data) {
			let tableColumns = [{title: "Battery ID", property: "battery_id"}];
			if (this.data.siteSettings.userSettings.view_truck_id) {
				tableColumns.push({title: "Truck ID", property: "truck_id"});
			}
			tableColumns.push({title: "Expected Charge Cycles", property: "expected_cycles"});
			tableColumns.push({title: "Actual Charge Cycles", property: "actual_cycles"});
			this.tableColumns = tableColumns;
		}
	}
}