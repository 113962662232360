import { Component, OnInit } from '@angular/core';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-site-notes-widget',
  templateUrl: './site-notes-widget.component.html',
  styleUrls: ['./site-notes-widget.component.css']
})
export class SiteNotesWidgetComponent implements OnInit {

	siteId : number;
	modelType: string = 'site';
	sourcePage: string = 'siteDashboard';
  options = {hasPrint: false};
  
  constructor(
    private sideMenuService: SideMenuService,
    private route: ActivatedRoute) { }

  ngOnInit() {
  	this.siteId = this.route.snapshot.params['siteid'];
  }

}
