<form #editDevice="ngForm" (ngSubmit)="saveDevice()">
	<div class="col-md-8 col-md-offset-2">
		<div class="boxs3">
			<h2>{{device.id == 0 ? 'Add' : 'Update'}} {{deviceType == 'charger' ? 'Charger' : 'BattView'}} {{deviceType == 'battviewMobile' ? 'Mobile' : ''}} {{deviceType != 'battviewMobile' ? 'Info' : ''}}<span class='r' *ngIf="device.id">ActView ID: {{device.id}}</span></h2>
			<div class="padding-8">
				<label for="serialnumber">Serial Number</label>
				<input [disabled]= "device.id != null && !currentUser.isACTuser" type="text" class="form-control" name="serialnumber" [(ngModel)]="device.serialnumber" required/>
			</div>
			<div class="padding-8">
				<label *ngIf="deviceType == 'charger'"><input type='checkbox' class="form-control" name="actviewenable" [(ngModel)]="device.actviewenable" /> ACT intelligent</label>
				<label *ngIf="deviceType == 'battview' || deviceType == 'battviewMobile'"><input type='checkbox' class="form-control" name="actviewenabled" [(ngModel)]="device.actviewenabled" /> ACT intelligent</label>
			</div>
			<div class="padding-8" *ngIf="!device.isClientNeeded || deviceType == 'battviewMobile'">
				<label for="OEM">Select a Battery OEM</label>
				<ng-select [items]="OEMsInfo" bindLabel="name" bindValue="id" placeholder="Select a Battery OEM" name="oemid" [(ngModel)]="device.oemid" [clearable]="false" [required]="!device.isClientNeeded || deviceType == 'battviewMobile'">
				</ng-select>
			</div>
			<div class="padding-8" *ngIf="!device.isClientNeeded || deviceType == 'battviewMobile'">
				<label for="Dealer">Select a Dealer</label>
				<ng-select [items]="dealersInfo" bindLabel="name" bindValue="id" placeholder="Select a Dealer" name="dealer_id" [(ngModel)]="device.dealerid" [clearable]="false" [required]="!device.isClientNeeded || deviceType == 'battviewMobile'">
				</ng-select>
			</div>
			<div class="padding-8" *ngIf="!device.isClientNeeded || deviceType == 'battviewMobile'">
				<label for="OEM">Select a Service Dealer</label>
				<ng-select [items]="serviceDealersInfo" bindLabel="name" bindValue="id" placeholder="Select a Service Dealer" name="service_dealer_id" [(ngModel)]="device.servicedealerid" [clearable]="false" [required]="!device.isClientNeeded || deviceType == 'battviewMobile'">
				</ng-select>
			</div>
			<div class="padding-8" *ngIf="!device.isClientNeeded || deviceType == 'battviewMobile'">
				<label for="OEM">Select a Truck Dealer</label>
				<ng-select [items]="truckDealersInfo" bindLabel="name" bindValue="id" placeholder="Select a Truck Dealer" name="truck_dealer_id" [(ngModel)]="device.truckdealerid" [clearable]="false" [required]="!device.isClientNeeded || deviceType == 'battviewMobile'">
				</ng-select>
			</div>
			<div class="padding-8">
				<label for="isClientNeeded">Add Client?</label>
				<label><input type="radio" [(ngModel)]="device.isClientNeeded" name="isClientNeeded" class="form-control" [value]="false" /> No </label>
				<label><input type="radio" [(ngModel)]="device.isClientNeeded" name="isClientNeeded" class="form-control" [value]="true" /> Yes</label>
			</div>
			<div class="padding-8 col-sm-12" *ngIf="device.isClientNeeded">
				
				<div class="padding-8 col-sm-12">
					<div class="col-sm-3">Choose client index</div>
					<div class="col-sm-6">
						<select [ngModel]="device.clientNameIndex" class="form-control" [required]="device.isClientNeeded" name="clientNameIndex" (ngModelChange)="getClientsByNameIndex($event)">
							<option [ngValue]="new">- New Client -</option>
							<option *ngFor="let char of alphabets" [value]="char">{{char}}</option>
						</select>
					</div>
				</div>
				
				<div class="col-sm-12" *ngIf="device.isClientNeeded && device.clientNameIndex == 'new'">
					<div class="col-sm-9">
						<app-customer-edit [customer]="device.client"></app-customer-edit>
					</div>
				</div>

				<div class="padding-8 col-sm-12" *ngIf="device.clientNameIndex != 'new'">
					<div class="col-sm-3">Select a Client</div>
					<div class="col-sm-6">
						<ng-select [items]="clients" bindLabel="name" bindValue="id" placeholder="Select a Client" name="customerid" [ngModel]="device.customerid" (ngModelChange)="getClientSites($event)" [required]="device.clientNameIndex != 'new' && device.isClientNeeded" [clearable]="false">
						</ng-select>
					</div>
				</div>

				<div *ngIf="deviceType != 'battviewMobile'">
					<div class="padding-8 col-sm-12">
						<div class="col-sm-3">Select a Site</div>
						<div class="col-sm-6">
							<ng-select [items]="clientSites" bindLabel="name" bindValue="id" placeholder="Select a Site" name="siteid" [ngModel]="device.site.id" (ngModelChange)="showSelectedAddress($event)" [required]="device.isClientNeeded" [clearable]="false">
							</ng-select>
						</div>
					</div>
					
					<div class="padding-8 col-sm-12" *ngIf="device.site.id != 0">
						<div class="col-sm-3">Site address</div>
						<div class="col-sm-6">
							<input type="text" class="form-control" [readonly]="true" [required]="device.site.id == 0 && device.isClientNeeded" [(ngModel)]="device.site.address" name="siteAddress"/>
						</div>
					</div>

					<div class="padding-8 col-sm-12" *ngIf="device.site.id == 0 && device.isClientNeeded">
						<div class="col-sm-9">
							<app-site-edit #appSiteEdit [site]="device.site" [OEMsInfo]="OEMsInfo" [dealersInfo]="dealersInfo" [serviceDealersInfo]="serviceDealersInfo" [truckDealersInfo]="truckDealersInfo" [ACTusers]="ACTusers" [readyArrays]="true"></app-site-edit>
						</div>
					</div>
					<div class="col-sm-12" *ngIf="device.site.id == 0 && device.isClientNeeded">
						<app-google-map (onAddressChanged)="appSiteEdit.updateSiteCoords($event)"></app-google-map>
					</div>
				</div>
			</div>
			<br />
			<div>
				<button type="submit" class="btn btn-primary" [disabled]="editDevice.invalid || (deviceType == 'battviewMobile' && device.customerid == 0 && device.clientNameIndex != 'new')">Submit</button>
				<button type="button" class="btn btn-default" (click)="goBack()">Cancel</button>
			</div>
		</div>
	</div>
</form>