import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ActFirstService } from '../act-first.service';
import { SiteDashboardService } from '../../site-dashboard.service';
import * as _ from 'lodash';
import { UserService } from '../../../../auth/user.service';
import { DevicesDashboardService } from '../../devices/devices-dashboard.service';
import { LoaderService } from '../../../../shared/loader/loader.service';
import { ChargersDashboardService } from '../../chargers/chargers-dashboard/chargers-dashboard.service';
import { Router } from "@angular/router";
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-chargers-control',
	templateUrl: './chargers-control.component.html',
	styleUrls: ['./chargers-control.component.css']
})
export class ChargersControlComponent implements OnInit, OnDestroy {

	data = {
		'charger_faulted': [],
		'battery_faulted': [],
		'user_faulted': [],
		'in_maintenance': [],
	}
	refreshTimerId;
	siteId;
	customerId;
	getChargersControlSubscription: Subscription = new Subscription();
	restartDeviceSub: Subscription = new Subscription();
	sendLCDActionSub: Subscription = new Subscription();

	constructor(
		private actFirstService: ActFirstService,
		private siteDashboardService: SiteDashboardService,
		private route: ActivatedRoute,
		public userService: UserService,
		private devicesDashboard: DevicesDashboardService,
		private loaderService: LoaderService,
		private chargersDashboard: ChargersDashboardService,
		private router: Router
	) { }

	ngOnInit() {
		if (!this.userService.hasAccessFunction('act_first_chargers_control')) {
			this.router.navigate(['/unauthorized']);
		}

		this.siteDashboardService.setActiveTab('actfirst');
		this.siteDashboardService.setActiveSubTab('chargers-control');

		this.siteId = this.route.parent.snapshot.params['siteid'];
		this.customerId = this.route.parent.snapshot.params['customerid'];

		this.getChargersControlSubscription = this.actFirstService.getData(null, parseInt(this.siteId)).subscribe(data=> {
			for (let group of data['groups']) {
				for (let innerGroup in this.data) {
					group['innergroups'][innerGroup].forEach(item => {
						item['groupname'] = group['groupname'];
						item['link'] =  ['/', this.customerId, this.siteId, 'chargers', 'lcd-projection'];
						item['queryParams'] = {chargersIDs: item.id};
					});
					this.data[innerGroup] = this.data[innerGroup].concat(group['innergroups'][innerGroup]);
				}
			}
		});

		this.refreshTimerId = setInterval(() => {
			window.location.reload();
		}, 60000);
	}

	restartCharger(charger, innerGroup) {
		let options = {'mode': null};
		this.loaderService.display(true);
		this.restartDeviceSub = this.devicesDashboard.restartDevice('charger', charger.id, options).subscribe(res => {
			this.updateDataAfterAction(charger.id, innerGroup);
		});
	}

	updateDataAfterAction(chargerId, innerGroup) {
		this.loaderService.display(false);
		this.data[innerGroup] = _.filter(this.data[innerGroup], function(o) { return o.id != chargerId; });
	}

	startLCDCycle(charger, innerGroup) {
		this.loaderService.display(true);
		clearInterval(this.refreshTimerId);
		this.refreshTimerId = setInterval(() => {
			window.location.reload();
		}, 60000);

		if (charger.showLastChargeCycleDetails_p == 0) {

		 this.sendLCDActionSub = this.chargersDashboard.sendLCDaction(charger.id, 'start', {}).subscribe(res=> {
				this.updateDataAfterAction(charger.id, innerGroup);
			});

		} else {

			let action = 'return';
			
			if (charger.paused != 0) {
				action = 'resume';
			}
			
		 this.sendLCDActionSub = this.chargersDashboard.sendLCDaction(charger.id, action, {}).subscribe(res=> {
				this.chargersDashboard.sendLCDaction(charger.id, 'start', {}).subscribe(res=> {
					this.updateDataAfterAction(charger.id, innerGroup);
				});
			});
		}
	}
	
	getText(col) {
		return this.actFirstService.getGroupName(col).toUpperCase();
	}

	preventAlertUnplug(chargerid) {
		this.actFirstService.preventAlertUnplug({siteid: this.siteId, chargerid: chargerid}).subscribe();
	}

	moveToMaintenance(charger, innerGroup){
		this.actFirstService.updateChargerActfirstRanking({siteId: this.siteId, chargerId: charger.id, newValue: true}).subscribe((data) => {
			charger.ignoreactfirstranking = true;
			this.updateDataAfterAction(charger.id, innerGroup);
		});
	}

	ngOnDestroy() {
		this.getChargersControlSubscription.unsubscribe();
		this.restartDeviceSub.unsubscribe();
		this.sendLCDActionSub.unsubscribe();
		if(this.refreshTimerId) {
			clearInterval(this.refreshTimerId);
		}
	}
}