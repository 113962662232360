import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
	selector: 'server-pagination',
	templateUrl: './server-pagination.component.html',
	styleUrls: ['./server-pagination.component.css']
})
export class ServerPaginationComponent implements OnInit {

	@Input() idField: string;
	@Input() limit: number;
	
	firstId: number = 0;
	lastId: number = 0;
	pageNum: number = 0;
	showRight: boolean = false;
	showLeft: boolean = false;
	topFirstId: number = 0;

	@Output() paginate = new EventEmitter();

	constructor() { }

	ngOnInit() {
	}

	init() {
		this.showRight	= false;
		this.showLeft	= false;
		this.pageNum 	= 0;
		this.lastId		= 0;
		this.firstId	= 0;
		this.topFirstId	= 0;
	}

	doPagination(isBack=false) {
		this.paginate.emit(isBack);
	}
	
	updatePagination(elements, isBack=false, firstTime=false) {
		
		let recordsLength = elements.length;

		if(isBack)
			this.pageNum--;
		else
			this.pageNum++;
		
		if(recordsLength > 0) {
			this.firstId	= elements[0][this.idField];
			this.lastId		= elements[recordsLength-1][this.idField];

			if(firstTime)
				this.topFirstId = this.firstId;
			
			if(recordsLength >= this.limit)
				this.showRight = true;
			else
				this.showRight = false;

			if(this.firstId != this.topFirstId)
				this.showLeft = true;
			else
				this.showLeft = false;
		}
	}
}