import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService } from '../../../../../auth/user.service';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';

@Component({
	selector: 'app-battview-tabs',
	templateUrl: './battview-tabs.component.html',
	styleUrls: ['./battview-tabs.component.css']
})
export class BattviewTabsComponent implements OnInit {

	@Input() isShared: boolean = false;
	@Input() isBattviewMobile: boolean = false;
	@Input() device: any = {};
	@Input() date: {
		fromDate: any,
		toDate: any
	} = {
		fromDate: null,
		toDate: null
	};
	@Input() showSettingsTab:boolean = false;
	@Input() showBvMobileListingTab:boolean = false;
	@Input() hideTemperatureChart: boolean =  true;
	@Input() activePage: string;
	@Input() activeTab: string;
	@Input() activeSubTab: string;
	@Input() currentUser: any = {};
	@Input() customerId: number = 0;
	@Input() siteId: number = 0;
	@Input() dealerId: number = 0;
	@Input() hasData: boolean = true;

	@Output() onTabChanged = new EventEmitter<string[]>(true);
	@Output() onExport = new EventEmitter<string[]>(true);
	
	constructor(
		public userService: UserService,
		private route: ActivatedRoute,
		private location: Location,
		private router: Router,
		private commonUtil: CommonUtil,
	) { }

	ngOnInit() {
		if(this.activePage == 'settings') {
			this.showSettingsTab = true;
		}
	}
	setTab(tabName, type, subTabName = "") {
		let url: any[] = [type];
		let queryParams = {};
		let updateSharedStudiesUrl = false;
		if(this.isBattviewMobile) {
			if(this.isShared && type != 'analytics') {
				updateSharedStudiesUrl = true;
				url = ['/battview-mobile', type];
				this.isShared = false;
			}
		}
		switch(type) {
			case 'analytics':
			case 'notes':
			case 'connectivity-track':
			case 'config-track':
				if(this.isBattviewMobile) {
					if(tabName)
						url.push(tabName);
					queryParams = {
						battviewId: this.device.id || 0,
						studyId: this.device.studyid || 0,
						dealerId: this.dealerId || '',
					};
				} else {
					if(tabName)
						url.push(tabName);

					url.push(this.device.id || 0);
				}

				if(subTabName) {
					url.push(subTabName);
				}
			break;
			case 'settings':
				let battviewIds = this.device.id;
				if(this.isBattviewMobile) {
					battviewIds +='-'+this.device.studyid
				}
				queryParams = {'battviewsIDs': battviewIds};
			break;
			case 'listing':
				queryParams = {dealerId: this.dealerId || 0};
			break;
			default:
				if(!this.isBattviewMobile)
					url.push(this.device.id || 0);
			break;
		}
		
		if(updateSharedStudiesUrl)
			return this.router.navigate(url);

		if(type != 'analytics' || this.activePage != 'analytics')
			return this.router.navigate(url, {relativeTo: this.route.parent, 'queryParams': queryParams});

		this.location.go(
			this.router.createUrlTree(
				url,
				{relativeTo: this.route.parent, 'queryParams': queryParams}
			).toString()
		);
		this.onTabChanged.emit([type, tabName, subTabName]);
	}
	pageHasPrintBtn() {
		return ['battery-summary', 'battery-usage-summary', 'exceptions', 'charge-summary'].indexOf(this.activeTab) > -1;
	}
	pageHasExportToExcelBtn() {
		return (['battery-usage-summary', 'battery-daily-usage', 'events-log', 'exceptions', 'charge-summary'].indexOf(this.activeTab) > -1);
	}

	printContent() {
		this.commonUtil.printContent(document.getElementById("analytics-page").innerHTML);
	}

	exportToCsv() {
		this.onExport.emit();
	}
	isDeviceSelected() {
		return (this.device && (Object.keys(this.device).length > 0));
	}

	updateDealerId(dealerId) {
		this.dealerId = dealerId;
	}
}