import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../admin.service';

@Component({
	selector: 'app-short-url',
	templateUrl: './short-url.component.html',
})
export class ShortUrlComponent implements OnInit {

	showError: boolean = false;
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private adminService: AdminService
	){}

	ngOnInit() {
		this.route.params.subscribe( params => {
			let id = Number(params['id']);
			let type = params['type'];

			switch (type) {
				case 'bv':
					this.adminService.getBattview(id).subscribe(
						(data: any) => {
							if(data.battview){
								this.router.navigate(['/', data.battview.customerid, data.battview.siteid, 'battviews', 'analytics', 'battery-summary', id],);
							}
						}, () => {
							this.showError = true;
						}
					);
					break;

				case 'charger':
					this.adminService.getCharger(id).subscribe(
						(data: any) => {
							if(data.charger){
								this.router.navigate(['/', data.charger.customerid, data.charger.siteid, 'chargers', 'analytics', 'cycles', id],);
							}
						}, () => {
							this.showError = true;
						}
					);
					break;
			}
		});
	}
}
