import { Component, OnInit, OnDestroy, ElementRef, ViewChild, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import {Howl, Howler} from 'howler';
import { ActFirstService } from '../act-first.service';
import { ActivatedRoute } from "@angular/router";
import { SiteDashboardService } from '../../site-dashboard.service';
import * as moment from 'moment';
import { UserService } from '../../../../auth/user.service';
import * as _ from 'underscore';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../../../shared/loader/loader.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-private-page',
  templateUrl: './private-page.component.html',
  styleUrls: ['./private-page.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PrivatePageComponent implements OnInit, OnDestroy {

	innerGroupList = [];
	data = [];
	buzzerEnabled: boolean = true;
	sound: Howl;
	buzzing: boolean = false;
	innerGroupMapper = {};
  rankingrefreshTimerId;
  siteId;
  customerId;
  serverTime = "";
  user;
  firstPageLoad: boolean = false;
  chargersWithAlerts = [];
  showNotifications: boolean = false;
  errorMsg: string = '';
  groupActiveTab = {};
  getRankSubscription: Subscription = new Subscription();
  getRankEnables: boolean = true;

  constructor(
		private actFirstService: ActFirstService,
		private route: ActivatedRoute,
    private siteDashboardService: SiteDashboardService,
    public userService: UserService,
    private cdref: ChangeDetectorRef,
    private loaderService: LoaderService,
    private translateService: TranslateService
	) {
   
	}

  ngOnInit() {

    this.firstPageLoad = true;
    let pc = this;
    this.sound = new Howl({
      src: ['/audio/buzz.mp3', '/audio/buzz.webm'],
      format: ['mp3', 'webm'],
      loop: true,
      autoUnlock: true,
      onplayerror: function() {
        this.sound.once('unlock', function() {
          pc.playBuzzer(0);
        });
      }
    });
    


    let user = this.userService.getCurrentUser();

    if (this.userService.hasAccessFunction('act_user_admin') || user.id == 239)
      this.showNotifications = true

    this.siteDashboardService.setActiveTab('actfirst');
    this.siteDashboardService.setActiveSubTab('private');
  	this.siteId = this.route.parent.snapshot.params['siteid'];
    this.customerId = this.route.parent.snapshot.params['customerid'];
  	
    this.getRank(false);
  
  	this.innerGroupList = ["ready", "bifo", "charging", "parked"];
  	this.innerGroupMapper = {
  		ready: {
  			text: this.translateService.instant('act.ready_to_pick'),
  			map: ["ready"]
  		}, 
  		bifo: {
  			text: this.translateService.instant('act.bofo_ranking'),
  			map: ["relaxed", "relaxing"]
  		},
  		charging: {
  			text: this.translateService.instant('act.charging'),
  			map: ["charging"]
  		},
  		parked: {
  			text: this.translateService.instant('act.parked_batteries'),
  			map: ["charger_faulted", "battery_faulted", "user_faulted", "in_maintenance"]
  		}
  	}
  }


  toggleBuzzerEnabled() {
  	this.buzzerEnabled = !this.buzzerEnabled;
  	if (!this.buzzerEnabled) 
  		this.sound.stop();
    if (this.buzzing && this.buzzerEnabled) 
        this.sound.play();
  }

  getInnerGroupMap(innerGroup) {
  	let result = this.innerGroupMapper[innerGroup].map;
  	return result;
  }

  setActiveGroupTab(groupId, tab) {
    this.groupActiveTab[groupId] = tab;
  }

  getActiveTabChargersListing(item, checkScroll = false) {
    let innergroup = this.groupActiveTab[item['id']] || "ready";
    let list = this.getInnerGroupList(item, innergroup);
    let isScrollable = list.length > 2;

    return checkScroll ? isScrollable : list;
  }

  getGroupInnerGroupCount(groupObj, innerGroupKey) {
    let list = this.getInnerGroupList(groupObj, innerGroupKey);
    return list.length;
  }

  getInnerGroupList(item, innergroup) {
    let list = [];
    if (innergroup == 'bifo') {
      list = list.concat(item['innergroups']['relaxed']).concat(item['innergroups']['relaxing']);
    } else if (innergroup == 'parked') {
      list = list.concat(item['innergroups']['battery_faulted']).concat(item['innergroups']['charger_faulted']).concat(item['innergroups']['user_faulted']).concat(item['innergroups']['in_maintenance']);
    } else {
      list = item['innergroups'][innergroup];
    }
    return list;
  }

  getRank(hideLoader) {
    this.getRankSubscription = this.actFirstService.getData(null,parseInt(this.siteId), hideLoader).subscribe(data=> {
      for (let group of data['groups']) {
        group['groupname'] = group['groupname'] ? group['groupname'].toUpperCase() : '';
        if (!hideLoader) { // first time render
          this.groupActiveTab[group['id']] = 'ready';
        }
        let thisGroup = {};
        for (let dataGroup of this.data) {
          if (dataGroup.id == group.id) {
            thisGroup = dataGroup;
            break;
          }
        }
        let checkForUpdate = true;
       
        if (!thisGroup['id']) {
          this.data = data['groups'];
          checkForUpdate = false;
        }
       
        for (let status in group['innergroups']) {
          let thisGroupStatusChargersById = {};
          let thisGroupStatusChargersIds = [];
          let groupStatusChargersIds = [];
          if (checkForUpdate) {
            thisGroupStatusChargersById = _.indexBy(thisGroup['innergroups'][status], 'id');            
            thisGroupStatusChargersIds = _.pluck(thisGroup['innergroups'][status], 'id');
            groupStatusChargersIds = _.pluck(group['innergroups'][status], "id");
            let areEquals = _.isEmpty(_.difference(thisGroupStatusChargersIds, groupStatusChargersIds)) && _.isEmpty(_.difference(groupStatusChargersIds, thisGroupStatusChargersIds));
            if (!areEquals) {
             this.data = data['groups'];
             checkForUpdate = false;
             this.cdref.detectChanges();
            }
          }

          for (let charger of group['innergroups'][status]) {
            charger['siteid'] = this.siteId;
            charger['actfirstgroupid'] = group['id'];
            if (checkForUpdate) {
              for (let key in charger) {
                // update data if there is change in chargers list except for these attributes: ['relaxedBatteryTimer', 'time'];
                if (['relaxedBatteryTimer', 'time'].indexOf(key) == -1) {
                 if ( thisGroupStatusChargersById[charger.id][key] != charger[key]) {
                   this.data = data['groups'];
                   break;
                 }
                }
              }
            }

            charger['link'] =  ['/', this.customerId, this.siteId, 'chargers', 'lcd-projection'];
            charger['queryParams'] = {chargersIDs: charger.id};
            if (charger['batteryid']) {
              charger['batteryid_link'] = '/' + this.customerId + '/' + this.siteId + '/battviews/analytics/battery-summary/' + charger.batteryid;
            }

            if (!this.buzzing && charger['alertFlag']) {
              if (this.showNotifications) this.showMsg(charger['name']);
              this.playBuzzer(data['servertime']);
            }
            
          }

        }
      }

      setTimeout(() => {
        if(this.getRankEnables)
          this.getRank(true);
      }, 3000);
    }, error=> {
      setTimeout(() => {
        if(this.getRankEnables)
          this.getRank(true);
      }, 3000);
    });
  }

  playBuzzer(serverTime) {
 
    if (this.buzzerEnabled) {
        this.sound.play();
    }
    
    this.buzzing = true;
   
    let buzzerTime = 5000;
    // if (this.firstPageLoad) {
    //   buzzerTime = buzzerTime + 3000;
    //   this.firstPageLoad = false;
    // }

    setTimeout(() => {
      this.sound.stop();
      this.buzzing = false;
      this.chargersWithAlerts = [];
      this.errorMsg = "";
    }, buzzerTime);
  }

  showMsg(chargername) {

    this.chargersWithAlerts.push(chargername);
    let chargersWithAlerts = this.chargersWithAlerts.join(", ");
    this.errorMsg = this.translateService.instant('act.chargers_with_alert_flag') + chargername;
  }
  
  ngOnDestroy() {
    this.getRankSubscription.unsubscribe();
  	this.sound.stop();
    if(this.rankingrefreshTimerId) {
      clearInterval(this.rankingrefreshTimerId);
    }
    this.getRankEnables = false;
  }

}

