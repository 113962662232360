import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { AdminService } from '../../admin.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';

@Component({
  selector: 'app-replacement-detection',
  templateUrl: './replacement-detection.component.html',
  styleUrls: ['./replacement-detection.component.css']
})
export class ReplacementDetectionComponent implements OnInit, OnDestroy {

  options: {
    type: string,
    originalid: number,
    originalsn: string,
    newid: number,
    newsn: string,
    userid: string
  } = {
    type: null,
    originalid: null,
    originalsn: null,
    newid: null,
    newsn: null,
    userid: null
  };
  selectedLogs: any[] = [];
  selectedlogs = {};
  
  replacementHistory: any[]; // order by -uploadtime
  constructor(
    private adminService: AdminService,
    private breadcrumb: BreadcrumbService,
    private notificationMessage: NotificationMessageService,
  ) {
  }

  ngOnInit() {
    this.breadcrumb.setBreadcrumb([
      {label: 'Replacement Detection', url: ''}
    ]);
  }

  getReplacementHistory() {
    this.adminService.getReplacementHistory(this.options).subscribe(
      (response : any[]) => {
        this.replacementHistory = response;
      }
    );
  }
  deleteReplacementLogs() {
    if(this.selectedLogs.length === 0)
      return;
  
    this.adminService.deleteReplacementLogs(this.selectedLogs).subscribe(
      response => {
        this.selectedlogs = {};
        this.selectedLogs = [];
        this.getReplacementHistory();
        this.notificationMessage.setMessage(response, 'success', 10000, true);
      }
    );
  }
  toggleLogsSelection(){
    this.selectedLogs = [];
    for(var id in this.selectedlogs) {
      var currLog = this.selectedlogs[id];
      if(currLog)
        this.selectedLogs.push(parseInt(id));
    }
  }

  ngOnDestroy() {
		this.breadcrumb.hide();
	}
}