<div *ngIf="device.id">
	<div class="panel panel-default">
		<div class="panel-body">
			<form name="predictionForm" novalidate>
				<div class="row">
					<div class="col-md-3">
						<label>Working Days</label>
					</div>
					<div class="col-md-9">
						<div class="col-md-12">
							<label *ngFor="let day of days; let dayIndex = index;" class="m-r-20"><input type="checkbox" class="form-control" [(ngModel)]="workingDays[dayIndex]" name="workingDays-{{dayIndex}}"> {{day}}</label>
						</div>
					</div>
				</div>
				<div class="row margin-top-lg">
					<div class="col-md-3">
						<label>Shifts</label>
					</div>
					<div class="col-md-9">
						<label class="col-md-4">
							<input type="radio" [(ngModel)]="predictionSettings.shiftAutoDetect" class="form-control" [value]="false" name="shiftAutoDetect" (ngModelChange)="predictionSettings.showShiftsPeriods=true" /> Manual
						</label>
						<label class="col-md-4">
							<input type="radio" [(ngModel)]="predictionSettings.shiftAutoDetect" class="form-control" [value]="true" name="shiftAutoDetect" (ngModelChange)="predictionSettings.showShiftsPeriods=false" /> Auto-detect
						</label>
					</div>
				</div>
				<div class="row margin-top-lg" *ngIf="predictionSettings.showShiftsPeriods">
					<div class="col-md-9 col-md-offset-3" *ngIf="shifts.length > 0">
						<div class="col-md-12">
							<table class="table">
								<tbody>
									<tr class="body-bg-color">
										<th>Shift Number</th>
										<th>From</th>
										<th>To</th>
										<th>Shift AHR</th>
										<th>Action</th>
									</tr>
									<tr *ngFor="let shift of shifts; let index = index;">
										<td>Shift #{{index + 1}}</td>
										<td>{{shift.startTimeFormatted}}</td>
										<td>{{shift.endTimeFormatted}}</td>
										<td>{{shift.overrideShiftAHr}}</td>
										<td>
											<span (click)="deleteShift(index)" title="Delete">
												<span class="pointer red-text glyphicon glyphicon-trash"></span>
											</span>
											<span title="Edit" (click)="editShift(index)">
												<span class="pointer red-text glyphicon glyphicon-edit"></span>
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="col-md-9 col-md-offset-3">
						<div class="col-md-4">
							<a (click)="modal.show()" class="text-center pie-view-more btn btn-default">
								<span><i class="glyphicon glyphicon-plus margin-right-xs"></i>Add Shift</span>
							</a>
						</div>
					</div>
				</div>
				<div class="row margin-top-lg">
					<div class="col-md-3">
						<label>SOC</label>
					</div>
					<div class="col-md-9">
						<label class="col-md-4">Min Daily SOC <input type="number" min="20" max="40" step="1" [(ngModel)]="predictionSettings.minSOC" name="minSOC" class="form-control" /></label>
						<label class="col-md-4">Max Daily SOC <input type="number" min="70" max="90" step="1" [(ngModel)]="predictionSettings.maxSOC" name="maxSOC" class="form-control" /></label>
					</div>
				</div>

				<div class="row margin-top-lg">
					<div class="col-md-3">
						<label>In Use Settings</label>
					</div>
					<div class="col-md-9 margin-bottom" *ngIf="!predictionSettings.showShiftsPeriods">
						<label class="col-md-4">Min Inuse AHR</label>
						<div class="col-md-4">
							<input type="number" min="0" step="1" [(ngModel)]="minInuseAhr" class="form-control" name="minInuseAhr" />
						</div>
					</div>
					<div class="col-md-9" [ngClass]="{'col-md-offset-3': !predictionSettings.showShiftsPeriods}">
						<label class="col-md-4">Min charge opportunity Time</label>
						<div class="col-md-4">
							<div class="input-group">
								<input type="number" min="10" max="120" step="5" [(ngModel)]="predictionSettings.minChargeOppurtinityTime" name="minChargeOppurtinityTime" class="form-control" />
								<span class="input-group-addon"><span>Minutes</span></span>
							</div>
						</div>
					</div>
					<div class="col-md-9 col-md-offset-3 margin-top" *ngIf="currentUser.isACTuser">
						<label class="col-md-4">Charge efficiency</label>
						<div class="col-md-4">
							<input type="number" min="0" max="100" step="5" [(ngModel)]="predictionSettings.chargeEfficiency" name="chargeEfficiency" class="form-control" />
						</div>
					</div>
					<div class="col-md-9 col-md-offset-3 margin-top">
						<label class="col-md-4">Override charge rate</label>
						<label class="col-md-2">
							<input type="checkbox" class="form-control" [(ngModel)]="predictionSettings.overrideChargeRateEnabled" name="overrideChargeRateEnabled"> Enabled
						</label>
						<div class="col-md-2">
							<input type="number" min="17" max="40" step="1" [(ngModel)]="predictionSettings.overrideChargeRate" name="overrideChargeRate" [disabled]="!predictionSettings.overrideChargeRateEnabled" class="form-control" />
						</div>
					</div>
					<div class="col-md-9 col-md-offset-3 margin-top" *ngIf="!predictionSettings.showShiftsPeriods">
						<label class="col-md-4">Ignore days that has EBU less than</label>
						<div class="col-md-4">
							<div class="input-group">
								<input type="number" min="0" max="2" step="0.05" [(ngModel)]="predictionSettings.minDailyEBU" name="minDailyEBU" class="form-control" />
								<span class="input-group-addon"><span>EBU</span></span>
							</div>
						</div>
					</div>

					<div class="form-buttons bvf" style="text-align: center !important">
						<button class="btn btn-primary btn-lg" (click)="predictionCalculation(predictionForm);">Calculate</button>
					</div>
				</div>
			</form>

			<div class="row">
				<div *ngIf="showPredictionResult">
					<div class="col-md-12">
						<div id ="battery-summary">
							<app-customized-widget 
								title="Study Information"
								widgetClass="height-auto"
								widgetId="prediction_summary"
								[options]="{hasExpand: false, hasPrint: false}"
							>
								<div *appTransclude>
									<table class="table table-striped table-condensed table-bordered">
										<tr>
											<td><b>Study Name</b></td>
											<td>{{device.studyname}}</td>
											<td><b>Truck ID</b></td>
											<td>{{device.truckid}}</td>
										</tr>
										<tr>
											<td><b>Study Start Date</b></td>
											<td>{{device.studyStartDate | date: "shortDate"}}</td>
											<td><b>Study End Date</b></td>
											<td>{{device.studyEndDate | date: "shortDate"}}</td>
										</tr>
										<tr>
											<td><b>Min SOC</b></td>
											<td>{{predictionSettings.minSOC}}%</td>
											<td><b>Max SOC</b></td>
											<td>{{predictionSettings.maxSOC}}%</td>
										</tr>
										<tr>
											<td><b>Average Daily AHR</b></td>
											<td>{{predictionObject.avg_daily_inuse_ahr | number:'0.2-2'}}</td>
											<td><b>Charge Rate</b></td>
											<td>{{predictionObject.minDailyChargeRate | number:'0.0-0'}}% ({{predictionObject.chargerType}})</td>
										</tr>
									</table>

									<table class="table">
										<tr class="body-bg-color">
											<td>Shift Number</td>
											<td>Shift Start Time</td>
											<td>Shift End Time</td>
											<td>Shift AHR</td>
											<td>Shift Start SOC</td>
											<td>Shift End SOC</td>
										</tr>

										<tr *ngFor="let shift of predictionObject.shifts; let index = index;">
											<td>{{index + 1}}</td>
											<td>{{shift.start | secondsToDateTime | date:'hh:mm a'}}</td>
											<td>{{shift.end | secondsToDateTime | date:'hh:mm a'}}</td>
											<td>{{shift.ahr | number:'0.2-2'}}</td>
											<td>{{(shift.aahr_start * 100 / device.ahrcapacity) | number:'0.2-2'}}</td>
											<td>{{(shift.aahr_end * 100 / device.ahrcapacity) | number:'0.2-2'}}</td>
										</tr>
									</table>
								</div>
							</app-customized-widget>
						</div>
					</div>
					<div class="col-md-12">
						<app-customized-widget 
							title="Recommended Charger"
							widgetClass="height-auto"
							widgetId="prediction_recommended_charger"
							[options]="{hasExpand: false, hasPrint: false}"
						>
							<div class="col-md-12" *appTransclude>
								<div class="col-md-2 col-md-offset-3">
									<img src="/images/{{recommendedChargerImage}}">
								</div>
								<h3>{{recommendedCharger}}</h3>
								<h3>{{predictionObject.chargerType}}</h3>
							</div>
						</app-customized-widget>
					</div>
					<div class="col-md-12" id="predictionShiftsChart">
						<app-customized-widget 
							title="Shifts SOC Chart"
							widgetClass="height-auto"
							widgetId="prediction_shift_soc_chart"
							[options]="{hasExpand: false, hasPrint: false}"
						>
							<div *appTransclude>
								<div class="toolss2 margin-right">
									<button class="fa fa-print" data-toggle="tooltip" title="Print" (click)="printChart('prediction_SOC_chart');"></button>
								</div>
								<div id="predictionSOCDashboard">
									<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
										<line-chart
											(onChartReady)="generateImageURI($event)"
											[data]="chartObject.data['shiftSOC']"
											[config]="chartObject.config['shiftSOC']"
											elementId="prediction_SOC_chart">
										</line-chart>
									</div>
								</div>
							</div>
						</app-customized-widget>
					</div>
					<div class="col-md-12" id="predictionEventsChart">
						<app-customized-widget 
							title="Events SOC Chart"
							widgetClass="height-auto"
							widgetId="prediction_events_soc_chart"
							[options]="{hasExpand: false, hasPrint: false}"
						>
							<div *appTransclude>
								<div class="toolss2 margin-right">
									<button class="fa fa-print" data-toggle="tooltip" title="Print" (click)="printChart('prediction_events_SOC_chart');"></button>
								</div>
								<div id="predictionEventsSOCDashboard">
									<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
										<line-chart
											(onChartReady)="generateImageURI($event)"
											[data]="chartObject.data['eventSOC']"
											[config]="chartObject.config['eventSOC']"
											elementId="prediction_events_SOC_chart">
										</line-chart>
									</div>
								</div>
							</div>
						</app-customized-widget>
					</div>
					<div class="col-md-12" id="predictionShiftsChartConventional" *ngIf="hasConventionalCharts">
						<app-customized-widget 
							title="Shifts SOC Chart (Conventional)"
							widgetClass="height-auto"
							widgetId="prediction_shifts_conventional_soc_chart"
							[options]="{hasExpand: false, hasPrint: false}"
						>
							<div *appTransclude>
								<div class="toolss2 margin-right">
									<button class="fa fa-print" data-toggle="tooltip" title="Print" (click)="printChart('prediction_SOC_chart_conventional');"></button>
								</div>
								<div id="predictionSOCDashboardConventional">
									<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;">
										<line-chart
											(onChartReady)="generateImageURI($event)"
											[data]="chartObject.data['shiftSOC-conventional']"
											[config]="chartObject.config['shiftSOC-conventional']"
											elementId="prediction_SOC_chart_conventional">
										</line-chart>
									</div>
								</div>
							</div>
						</app-customized-widget>
					</div>
					<div class="col-md-12" id="predictionEventsChartConventional" *ngIf="hasConventionalCharts && false">
						<app-customized-widget 
							title="Events SOC Chart (Conventional)"
							widgetClass="height-auto"
							widgetId="prediction_events_conventional_soc_chart"
							[options]="{hasExpand: false, hasPrint: false}"
						>
							<div *appTransclude>
								<div class="toolss2 margin-right">
									<button class="fa fa-print" data-toggle="tooltip" title="Print" (click)="printChart('predictionEventsChartConventional');"></button>
								</div>
								<div id="predictionEventsSOCDashboardConventional">
									<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px;" id="prediction-events-SOC-chart-conventional"></div>
									<div id="predictionEventsSOCConventionalChartController" class="noprint" style="height:25px;"></div>
								</div>
							</div>
						</app-customized-widget>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<app-modal #modal [title]="'Shitfs'" [confirmBtnText]="isEditMode ? 'Update' : 'Add'" size="lg">
	<form class="form-horizontal">
		<div class="row margin-bottom">
			<label class="col-md-4">Shift Start Time</label>
			<div class="col-md-6">
				<input name="shift-start-time" [(ngModel)]="shift.startTime" name="shift-startTime" class="form-control" required type="time">
			</div>
		</div>
		<div class="row margin-bottom">
			<label class="col-md-4">Shift End Time</label>
			<div class="col-md-6">
				<input name="shift-end-time" [(ngModel)]="shift.endTime" name="shift-endTime" class="form-control" required type="time">
			</div>
		</div>
		<div class="row margin-bottom">
			<label class="col-md-4">Shift AHR</label>
			<div class="col-md-6">
				<input name="shift-ahr" class="form-control" required min="ahrcapacity*0.5" max="ahrcapacity*0.8" step="5" [(ngModel)]="shift.overrideShiftAHr" name="shift-overrideShiftAHr">
			</div>
		</div>
	</form>
</app-modal>