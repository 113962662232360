<div id="tab-content" class="row settings">
	<div class="container-fluid no-gutter">
		<div class="boxs3">
			<h2 id="Alerts" class="margin-bottom-xlg">Disable Alert Temporary</h2>
			<form class="form-auth">
				<input type="hidden" [(ngModel)]="form.id" name="form.id" [value]="form.id || 0">

				<div class="form-group row">
					<label for="deviceType" class="col-md-3 col-form-label col-form-label-lg">Device Type</label>
					<div class="col-md-6">
						<label><input type="radio" [(ngModel)]="form.device_type" name="form.device_type" class="form-control" (ngModelChange)="onDeviceTypeChanged()" value="site" />Site</label>
						<br>
						<label><input type="radio" [(ngModel)]="form.device_type" name="form.device_type" class="form-control" (ngModelChange)="onDeviceTypeChanged()" value="battview" />Battview</label>
						<br>
						<label><input type="radio" [(ngModel)]="form.device_type" name="form.device_type" class="form-control" (ngModelChange)="onDeviceTypeChanged()" value="charger" />Charger</label>
					</div>
				</div>

				<div class="form-group row">
					<label for="form.device_id" class="col-md-3 col-form-label col-form-label-lg">Select Device</label>
					<div class="col-md-6">
						<ng-select [items]="devicesList" bindValue="id" bindLabel="prettyName" placeholder="Select Device" name="form.device_id" [(ngModel)]="form.device_id" [clearable]="true"></ng-select>
					</div>
				</div>
				<div class="form-group row">
					<label for="form.alert_type" class="col-md-3 col-form-label col-form-label-lg">Select Alert Type</label>
					<div class="col-md-6">
						<ng-select [items]="alertsList" bindValue="id" bindLabel="value" placeholder="Select Alert Type" name="form.alert_type" [(ngModel)]="form.alert_type" [clearable]="true"></ng-select>
					</div>
				</div>
				<div class="form-group row">
					<label for="form.disabled_until_date" class="col-md-3 col-form-label col-form-label-lg">Until Date</label>
					<div class="col-md-6">
						<div class="input-group">
							<input class="form-control" [minDate]="today" [maxDate]="after30days" placeholder="Disabled until" name="form.disabled_until_date" [(ngModel)]="form.disabled_until_date" bsDatepicker #fromDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
							<span class="input-group-btn">
								<button class="btn btn-default btn-date" (click)="fromDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
							</span>
						</div>
					</div>
				</div>
				<div class="form-buttons bvf">
					<button class="btn btn-info btn-lg" (click)="addOrUpdate()" [disabled]="(!form.device_id && form.device_id !== 0) || !form.alert_type || !form.disabled_until_date">
						<span *ngIf="!form.id">Add</span>
						<span *ngIf="form.id">Update</span>
					</button>
					<button *ngIf="form.id" class="btn btn-secondary btn-lg" (click)="clearForm()" >Cancel</button>
				</div>
			</form>

			<hr>
			<div>
				<div *ngIf="noResult" class="padding-8">
					No results found
				</div>
				<table class="table table-striped padding-8" *ngIf="!noResult && tempDisabledAlertsList.length > 0">
					<thead>
						<tr>
							<th>Device Type</th>
							<th>Device ID</th>
							<th>Alert Type</th>
							<th>Disabled until Date</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let disabledAlert of tempDisabledAlertsList; let idx = index">
							<td>{{disabledAlert.device_type.charAt(0).toUpperCase() + disabledAlert.device_type.slice(1)}}</td>

							<td *ngIf="disabledAlert.device_type == 'battview'"><a [routerLink]="['/', customerID, siteID, 'battviews', 'analytics', 'battery-summary', disabledAlert.device_id]">{{disabledAlert.prettyName}}</a></td>
							<td *ngIf="disabledAlert.device_type == 'charger'"><a [routerLink]="['/', customerID, siteID, 'chargers', 'analytics', 'cycles', disabledAlert.device_id]">{{disabledAlert.prettyName}}</a></td>
							<td *ngIf="disabledAlert.device_type == 'site'"><a [routerLink]="['/', customerID, siteID]">This Site</a></td>

							<td>{{alertsTxt[disabledAlert.alert_type]}}</td>
							<td>{{disabledAlert.disabled_until_date | date:'MM/dd/yyyy'}}</td>
							<td><button (click)="loadInsideForm(disabledAlert)" class="btn btn-primary btn-sm">Update</button> <button (click)="delete(disabledAlert.id, idx)" class="btn btn-danger btn-sm">Delete</button></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>