
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from "rxjs";
import { UserService } from './user.service';


@Injectable()
export class AuthService {
	
	constructor(private httpClient: HttpClient, private userService: UserService) {}
	 
	isAuthenticated(routeSnapshot) {
		let stateName = routeSnapshot.data['stateName'];
		return this.httpClient.post('/auth/isLogged', {stateName: stateName}, {
			observe: "body",
			responseType:"json"
		}).pipe(
		map(
    	data => {

				if (data && data['status'] && data['status'] == 'loggedOut') {
					return false;
				}
				
				if (data['userInfo'] && data['userAccess']) {
					if (this.userService.getCurrentUser().id != data['userInfo'].id) {
						this.userService.setCurrentUser(data['userInfo'], data['userAccess']);
					}
					return true;
				}
    	}
  	));
	}

	registerUser(user) {
		return this.httpClient.post('/users/register', user, {
			observe: "body"
		});
	}

	loginUser(user) {
		return this.httpClient.post('/auth/login', user);
	}

	forgotPassword(forgot) {
		return this.httpClient.post('/users/forgotPassword', forgot, {
			observe: "body"
		});
	}

	becomeUser(data) {
		return this.httpClient.post('/users/becomeUser', data, {
			observe: "body",
			responseType:"json"
		});
	}

	logoutUser():Observable<boolean> {
		return this.httpClient.post('/auth/logout',{}).pipe(
			map(res => {
				return true;
			}))
	}
	enableFullAccessMode() {
		return this.httpClient.post('/users/enableFullAccessMode', {}, {
            observe: "body",
            responseType: "text"
		});
	}
}