import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import * as _ from 'underscore';
import * as moment from 'moment';
import { NotificationMessageService } from '../../../../../../shared/notification-message/notification-message.service';
import { CommonUtil } from '../../../../../../shared/services/utility/common.service';
import { ChartUtil } from '../../../../../../shared/services/utility/chart.service';
import { UserService } from '../../../../../../auth/user.service';
import { LineChartConfig } from '../../../../../../shared/google-chart/Models/LineChartConfig';

@Component({
	selector: 'app-soc-prediction',
	templateUrl: './soc-prediction.component.html',
	styleUrls: ['./soc-prediction.component.css']
})
export class SocPredictionComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input() isBattviewMobile: boolean = false;
	@Input() device: any = {};
	@Input() dailyDetails: any = {};
	@Input() events: any = {};
	@Input() siteAlertsSettings: any = {};
	@Input() userAlertsSettings: any = {};

	@ViewChild("modal") modal;
	
	days	= ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
	hours	= ["12AM","1AM","2AM","3AM","4AM","5AM","6AM","7AM","8AM","9AM","10AM","11AM","12PM","1PM","2PM","3PM","4PM","5PM","6PM","7PM","8PM","9PM","10PM","11PM"];
	workingDays = [true,true,true,true,true];
	shifts = [];
	minInuseAhr = 10;
	predictionSettings = {
		minChargeOppurtinityTime: 10,
		showShiftsPeriods: true,
		overrideChargeRateEnabled: false,
		overrideChargeRate: 25,
		shiftAutoDetect: false,
		maxSOC: 80,
		minSOC: 30,
		chargeEfficiency: 95,
		minDailyEBU: 0.25
	};
	predictionObject: any = {};
	showPredictionResult = false;
	recommendedChargerImage: string = '';
	recommendedCharger: any;
	hasConventionalCharts: boolean = false;
	shift: any = {};
	images: any[] = [];
	currentUser: any;
	isEditMode = false;
	shiftIndex: number;
	chartObject: any = {
		data: {},
		config: {},
	};

	constructor(
		private notificationMessage: NotificationMessageService,
		private commonUtil: CommonUtil,
		private chartUtil: ChartUtil,
		private userService: UserService,
	) { }

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();
		this.shift = {
			overrideShiftAHr: (Math.ceil(0.1*this.device.ahrcapacity/5)*5)
		};
	}
	ngAfterViewInit() {
		this.modal.onClose.subscribe(
			(ok) => {
				if(ok) {
					this.addShift();
				}
			}
		);
	}

	deleteShift(index) {
		this.shifts.splice(index, 1);
	}

	predictionCalcualteLongestIdleTime() {
		var idleTimes = [];
		var longestIdleTimeIndex = 0;

		if(this.predictionObject.shifts.length === 1) {

			idleTimes.push((24*3600) - this.calculatePeriod(this.predictionObject.shifts[0].start, this.predictionObject.shifts[0].end));
		} else {
			// Get the idle times
			var prevShiftEndTime = -1;
			this.predictionObject.shifts.forEach((shift) => {
				if(prevShiftEndTime > -1) {
					idleTimes.push(shift.start - prevShiftEndTime);
				}
				prevShiftEndTime = shift.end;
			});
			if(prevShiftEndTime > -1) {
				var lastIdleTime = this.predictionObject.shifts[0].start - prevShiftEndTime;
				if(lastIdleTime < 0) lastIdleTime += (24*3600);
				idleTimes.push(lastIdleTime);
			}

			// Select the longest idle time, if we had multiple with the same time, select the first one in the day.
			longestIdleTimeIndex = idleTimes.indexOf(Math.max.apply(null, idleTimes));
		}
		return [idleTimes, longestIdleTimeIndex];
	}

	calculatePeriod(start, end) {
		var period = 0;
		if(start > end) {
			end += 24*3600;
		}
		period = end - start;
		return period;
	}

	predictionCalculation(form) {
		this.predictionObject.total_inuse_as = 0;
		this.predictionObject.count_inuse_events = 0;
		this.predictionObject.avg_inuse_ahr = 0;
		this.predictionObject.total_daily_inuse_as = 0;
		this.predictionObject.count_daily_inuse_events = 0;
		this.predictionObject.avg_daily_inuse_ahr = 0;
		this.predictionObject.max_inuse_ahr = 0;
		this.predictionObject.shifts = [];
		var minChargeRate = 17;

		var validateInputs = (form) => {
			// check required and html rules
			var validInputs = true;
			if (!this.predictionSettings.shiftAutoDetect) {

				// validInputs = !form.$error.required && form.$valid;
			}
			return validInputs;
		};

		var predictionInuseAHRcalculations = () => {

			// Calculate the Average Inuse events AHR and the Max.
			var previousDayTimeStamp: any = 0;
			var predictionDaysObject = {};
			var predictionEventsPerDay = {};

			if(!this.predictionSettings.shiftAutoDetect) {
				this.predictionObject.avg_daily_inuse_ahr = 0;
				this.shifts.forEach((shift) => {
					if(shift.overrideShiftAHr)
						this.predictionObject.avg_daily_inuse_ahr += +(shift.overrideShiftAHr);
				});
				return predictionEventsPerDay;
			}

			for(let dailyDetails of this.dailyDetails) {
				predictionDaysObject[dailyDetails.date] = dailyDetails;
			}

			var allEvents = {};
			allEvents = _.extend(allEvents, this.events);

			// Only count the days and events where the check boxes in the configurations selected.
			// And we have > 0.25 EBU in that day. (dailyDetailsJson[day].inuse_as/3600) < (battview.ahrcapacity * 0.8 * 0.25)
			for(var eventId in allEvents) {
				var event			= allEvents[eventId].data;
				var eventDayTime	= moment(event.start_time*1000).utc().startOf('day').format("X");
				var eventDayNumber	= moment(event.start_time*1000).utc().day();
				if(!predictionDaysObject[eventDayTime])
					continue;

				var thisDayEBU		= +(predictionDaysObject[eventDayTime].EBU);
				if(!this.workingDays[eventDayNumber] || thisDayEBU < this.predictionSettings.minDailyEBU) {
					continue;
				}

				if(!predictionEventsPerDay[eventDayTime]) {
					predictionEventsPerDay[eventDayTime] = [];
				}

				predictionEventsPerDay[eventDayTime].push(event);

				if(event.event_name != 3) {
					continue;
				}

				if(this.predictionObject.max_inuse_ahr < event.inuse_as) {
					this.predictionObject.max_inuse_ahr = event.inuse_as;
				}

				this.predictionObject.total_inuse_as += event.inuse_as;
				this.predictionObject.count_inuse_events++;
				if(previousDayTimeStamp != eventDayTime) {

					let inuseAsValue = predictionDaysObject[eventDayTime].inuse_as;
					if(this.userAlertsSettings && this.userAlertsSettings.bv_inuse_events_only_for_charge_ahr) {
						inuseAsValue = predictionDaysObject[eventDayTime].inuse_events_as;
					}
					this.predictionObject.total_daily_inuse_as += +(inuseAsValue);
					this.predictionObject.count_daily_inuse_events++;
					previousDayTimeStamp = eventDayTime;
				}
			}

			this.predictionObject.max_inuse_ahr /= 3600;
			if(this.predictionObject.count_inuse_events > 0) {
				this.predictionObject.avg_inuse_ahr = this.predictionObject.total_inuse_as / (this.predictionObject.count_inuse_events * 3600);
			}

			if(this.predictionObject.count_daily_inuse_events > 0) {
				this.predictionObject.avg_daily_inuse_ahr = this.predictionObject.total_daily_inuse_as / (this.predictionObject.count_daily_inuse_events * 3600);
			}

			return predictionEventsPerDay;
		};

		var predictionShiftsCalculations = (predictionEventsPerDay) => {

			var shiftsArray = [];
			if (!this.predictionSettings.shiftAutoDetect) {
				this.shifts.forEach((shift) => {
					if(Object.keys(shift).length > 0) {
						var tempObject: any	= {};
						tempObject.start	= shift.start_time_seconds;
						tempObject.end		= shift.end_time_seconds;
						tempObject.overrideShiftAHr	= shift.overrideShiftAHr;
						shiftsArray.push(tempObject);
					}
				});

				// if no shift enabled we need to calculate shifts
				if(shiftsArray.length > 0) {
					shiftsArray.sort((a, b) => {
						return a.start - b.start;
					});

					this.predictionObject.shifts = shiftsArray;
					return;
				}
			}

			var daysShiftsObject = [];
			var minInuseAs = (+this.minInuseAhr) * 3600;
			for(var day in predictionEventsPerDay) {
				var currentDayEvents = predictionEventsPerDay[day];

				for(var i in currentDayEvents) {
					var currEvent = currentDayEvents[i];
					if(currEvent.event_name != 3) {
						continue;
					}

					if (currEvent.inuse_as >= minInuseAs) {
						var eventStartTime	= currEvent.start_time - (+day),
							eventEndTime	= eventStartTime + currEvent.duration;
						
						daysShiftsObject.push({start: eventStartTime, end: eventEndTime});
					}
				}
			}

			// sort shifts by start time
			daysShiftsObject.sort((a, b) => {
				return a.start - b.start;
			});

			for(var k = 0; k < daysShiftsObject.length; k++) {
				var item = daysShiftsObject[k];
				var intersectIndex = -1;
				for(var j = shiftsArray.length -1 ; j >= 0; j--) {
					if(item.start <= (shiftsArray[j].end + minChargeOppurtinitySeconds) && item.end >= (shiftsArray[j].start - minChargeOppurtinitySeconds)) {
						intersectIndex = j;
						if(item.start < shiftsArray[j].start) {
							shiftsArray[j].start = item.start;
						}
						if(item.end > shiftsArray[j].end) {
							shiftsArray[j].end = item.end;
						}
						break;
					}
				}
				if(intersectIndex === -1) {
					shiftsArray.push(item);
				}
			}

			if (shiftsArray.length > 1) {
				var lastShift = shiftsArray[shiftsArray.length -1];
				var oneDaySeconds = 60*60*24;
				if(shiftsArray[0].start <= (lastShift.end + minChargeOppurtinitySeconds - oneDaySeconds)) {
					// merge last shift with first shift
					shiftsArray[shiftsArray.length -1].end = shiftsArray[0].end;
					delete shiftsArray[0];
				}
			}

			// to fix indexes; index with no object in case of merge
			shiftsArray.forEach((shift) => {
				if(shift)
					this.predictionObject.shifts.push(shift);
			});
		};

		var predictionCalculateShiftAHR = () => {
			var hasError = false;
			for(var shiftIndex in this.predictionObject.shifts) {
				var shift = this.predictionObject.shifts[shiftIndex];
				if(shift.overrideShiftAHr) {
					shift.ahr = shift.overrideShiftAHr;
				} else {
					var shiftPeriod = (shift.end - shift.start);
					if(shiftPeriod < 0) shiftPeriod += (24*3600);
					shift.ahr = this.predictionObject.avg_daily_inuse_ahr * shiftPeriod / (3600 * 24);
				}

				// If the Shift AHR (regardless if the user enter it OR calculated) > 100% – Min SOC  * battery capacity
				// show Error “Shift AHRs is more than (100- $ min SOC) % of battery capacity.“
				if(shift.ahr > (100 - this.predictionSettings.minSOC)/100 * this.device.ahrcapacity) {
					this.notificationMessage.setMessage("Shift AHRs is more than "+(100 - this.predictionSettings.minSOC)+"% of battery capacity.", 'danger', 10000, true);
					hasError = true;
					break;
				}
			}
			return hasError;
		};

		var predictionChargeRateCalculations = () => {
			// If the user entered the charge rate, no need to calculate
			if(this.predictionSettings.overrideChargeRateEnabled && this.predictionSettings.overrideChargeRate > 0) {
				this.predictionObject.minDailyChargeRate = this.predictionSettings.overrideChargeRate;
				return;
			}

			// Charge Time Per day: time available between shifts that at least more than 10 mins (as in the settings).
			var chargeTimePerDay		= 0;
			var previousShiftEndTime	= -1;
			this.predictionObject.shifts.forEach((shift) => {
				if(previousShiftEndTime > -1) {
					chargeTimePerDay += (shift.start - previousShiftEndTime);
				}
				previousShiftEndTime = shift.end;
			});

			if(previousShiftEndTime > -1) {
				var lastVsFirstShift = this.predictionObject.shifts[0].start - previousShiftEndTime;
				if(lastVsFirstShift < 0) lastVsFirstShift += (24*3600);
				chargeTimePerDay += lastVsFirstShift;
			}

			chargeTimePerDay /= 3600;

			this.predictionObject.minDailyChargeRate = 0;
			if(chargeTimePerDay > 0) {
				// Min Daily Charge Rate = Average AHR / (charge Hours * battery capacity)
				this.predictionObject.minDailyChargeRate = (this.predictionObject.avg_daily_inuse_ahr / (chargeTimePerDay * this.device.ahrcapacity)) * 100;
			}
			if(this.predictionObject.minDailyChargeRate < minChargeRate) {
				this.predictionObject.minDailyChargeRate = minChargeRate;
			}
			/*
				For example: 
				Average daily AHR: 500AHRs
				idle times: 10.75 hours
				battery capacity: 400 AHR.
				Then the Min daily charge rate: 500/(10.75 * 400) = 11.6%
			*/
		};

		var predictionShiftChargeRateCalculations = () => {
			// Shift Charge Rate Calculations:
			// Calculate the recommended Shift charger rate
			var hasError = false;
			var firstShiftAfterLongestIdleIndex	= 0,
				chargeRate = this.predictionObject.minDailyChargeRate;

			var idleTiming = this.predictionCalcualteLongestIdleTime();
			var idleTimes = idleTiming[0],
				longestIdleTimeIndex = idleTiming[1];

			if(this.predictionObject.shifts.length === 1) {
				// If the 1 shift AHR + Min SOC * battery capacity is less than 100%
				// then use the average daily AHR else make an exception daily AHR as above
				if(this.predictionObject.shifts[0].ahr + ((this.predictionSettings.minSOC / 100) * this.device.ahrcapacity) < this.device.ahrcapacity) {
					this.predictionObject.shiftChargeRate = this.predictionObject.avg_daily_inuse_ahr;
				} else {
					this.predictionObject.shiftChargeRate = this.predictionObject.minDailyChargeRate;
				}
			} else {
				// Select the longest idle time, Find the shift followed by the longest idle
				firstShiftAfterLongestIdleIndex = (+longestIdleTimeIndex) + 1;
				if(firstShiftAfterLongestIdleIndex === this.predictionObject.shifts.length) {
					firstShiftAfterLongestIdleIndex = 0;
				}
			}
			var loopIdx = firstShiftAfterLongestIdleIndex;

			if(chargeRate < minChargeRate) {
				chargeRate = minChargeRate;
			}

			var ovverrideChargeRate = false;
			if(this.predictionSettings.overrideChargeRateEnabled && this.predictionSettings.overrideChargeRate > 0) {
				this.predictionObject.shiftChargeRate = this.predictionSettings.overrideChargeRate;
				if(this.predictionObject.shiftChargeRate < minChargeRate) {
					this.predictionObject.shiftChargeRate = minChargeRate;
				}
				chargeRate = this.predictionObject.shiftChargeRate;
				ovverrideChargeRate = true;
			}

			// Loop over shifts and idles: with different CR values
			var isShift = true;
			this.predictionObject.activeAHR = this.predictionSettings.maxSOC * this.device.ahrcapacity / 100;
			var maxActiveAHR = this.predictionObject.activeAHR;
			var firstloop = true;

			do {
				var changeIsShift = true;

				if(isShift) {
					// Set  AAHR = AAHR – average shift AHR, if the result < Min SOC *  capacity , stop calculations and set CR to be CR+1% and start over.
					this.predictionObject.shifts[loopIdx].aahr_start = this.predictionObject.activeAHR;
					this.predictionObject.activeAHR = this.predictionObject.activeAHR - this.predictionObject.shifts[loopIdx].ahr;
					this.predictionObject.shifts[loopIdx].aahr_end = this.predictionObject.activeAHR;
					if(this.predictionObject.activeAHR < (this.predictionSettings.minSOC * this.device.ahrcapacity)/100) {
						// NOTE: IF CR reaches 40% Show Error
						if(chargeRate >= 40 || ovverrideChargeRate) {
							this.notificationMessage.setMessage("There is no enough Charge Time available", 'danger', 10000, true);
							hasError = true;
							break;
						}
						chargeRate = chargeRate + 1;
						this.predictionObject.minDailyChargeRate = chargeRate;
						loopIdx = firstShiftAfterLongestIdleIndex;
						changeIsShift = false;
						this.predictionObject.activeAHR = maxActiveAHR;
						firstloop = true;
					}
				} else {
					var chargeAHR = 0;
					if(idleTimes[loopIdx] >= minChargeOppurtinitySeconds) {
						chargeAHR = (chargeRate / 100) * this.device.ahrcapacity * (idleTimes[loopIdx]/3600) * (this.predictionSettings.chargeEfficiency / 100);
					}
					this.predictionObject.activeAHR += chargeAHR;

					if(this.predictionObject.activeAHR > maxActiveAHR) {
						this.predictionObject.activeAHR = maxActiveAHR;
					}

					if(this.predictionObject.activeAHR > this.device.ahrcapacity) {
						this.predictionObject.activeAHR = this.device.ahrcapacity;
					}
				}
				if(changeIsShift) {
					isShift = !isShift;
					if(isShift) {
						loopIdx++;
						firstloop = false;
					}
				}
				if(loopIdx >= this.predictionObject.shifts.length) {
					loopIdx = 0;
				}
			} while(loopIdx !== firstShiftAfterLongestIdleIndex || firstloop);
			return hasError;
		};

		var predictionGetChargerType = () => {
			var chargerType;
			let idleTiming: any[] = this.predictionCalcualteLongestIdleTime();
			var idleTimes = idleTiming[0];
			let longestIdleTimeIndex: number = idleTiming[1];

			var mostIdleTime = idleTimes[longestIdleTimeIndex] / 3600;
			// conventional:
			// 	- ((100 - min soc )/100) * battery ahr > avg daily
			// 	- idel > 3 hours
			// 	- left time (most idle time - 3 hours) -> use for generate charge rate as following:
			// ((90 - min soc) / time in hour) -> if that number less than 20% then conventional
			if(
				((100 - this.predictionSettings.minSOC) / 100) * this.device.ahrcapacity > this.predictionObject.avg_daily_inuse_ahr 
			&& (mostIdleTime > 3)
			&& ((90 - this.predictionSettings.minSOC) / (mostIdleTime-3) < 20)
			) {
				chargerType = 'Conventional';
			} else {
				// if not conventional:
				// 	check charge rate calculated (which is already uploaded to prod):
				// 	> 30 -> fast
				// 	avg daily EBU > 1.6 => fast
				if(
					(this.predictionObject.minDailyChargeRate > 30
					&& this.predictionObject.avg_daily_inuse_ahr / this.device.ahrcapacity > 1.6
					)
				|| this.predictionObject.minDailyChargeRate > 35
				) {
					chargerType = 'fast';
				} else {
					// 	else opportunity
					chargerType = 'Opportunity';
				}
			}

			this.predictionObject.chargerType = chargerType;
		};

		var getRecommendedCharger = () => {
			var currentRating;
			if (this.device.NominalVoltage <= 36)
				currentRating = 50;
			else if (this.device.NominalVoltage <= 48)
				currentRating = 40;
			else
				currentRating = 25;

			var totalCurrent = this.device.ahrcapacity * this.predictionObject.minDailyChargeRate/100;
			var count_of_PMs = Math.ceil(totalCurrent / currentRating);
			if(count_of_PMs > 12) count_of_PMs = 12;
			var modelType;
			if(count_of_PMs <= 4){
				modelType = 'Q4';
				this.recommendedChargerImage = '4x_Idle.png';
			} else if(count_of_PMs <= 6) {
				modelType = 'Q6';
				this.recommendedChargerImage = '6x_Idle.png';
			} else {
				modelType = 'Q12';
				this.recommendedChargerImage = '12x_Idle.png';
			}

			this.recommendedCharger = modelType + "-" + (count_of_PMs * currentRating) + "-XXX";
		};

		this.showPredictionResult = false;
		var formIsValid = validateInputs(form);
		if(typeof(formIsValid) !== "boolean" || !formIsValid) {
			return this.notificationMessage.setMessage(''+formIsValid || "Please verify inputs and make sure data inserted is correct!", 'danger', 10000, true);
		}

		var minChargeOppurtinitySeconds = (+this.predictionSettings.minChargeOppurtinityTime) * 60;
		var predictionEventsPerDay = predictionInuseAHRcalculations();
		predictionShiftsCalculations(predictionEventsPerDay);
		if(this.predictionObject.shifts.length === 0) {
			return this.notificationMessage.setMessage("Cannot generate shifts with current inputs, please verify your inputs and make sure to enter valid values", 'danger', 10000, true);
		}
		if(predictionCalculateShiftAHR()) return; // return in case of error happen
		predictionChargeRateCalculations();
		if(predictionShiftChargeRateCalculations()) return; // return in case of error happen
		this.drawSOCpredictionChart();
		this.drawEventsSOCpredictionChart();
		predictionGetChargerType();
		getRecommendedCharger();

		this.hasConventionalCharts = false;
		if(this.predictionObject.chargerType == 'Conventional') {
			this.hasConventionalCharts = true;

			this.drawSOCpredictionChart(true);
			// this.drawEventsSOCpredictionChart();
		}
		
		this.showPredictionResult = true;
	}
	
	prepareConventionalChartData() {
		var data = [];
		var startOfDayIsIdle = true,
			lastEventOfDayWithinShift = false;

		var idleTiming: any[] = this.predictionCalcualteLongestIdleTime();
		var idleTimes = idleTiming[0],
			longestIdleTimeIndex = idleTiming[1];

		var mostIdleTime = idleTimes[longestIdleTimeIndex] / 3600;

		var conventionalShifts = [];
		conventionalShifts = _.extend(conventionalShifts, this.predictionObject.shifts)

		var firstShiftAfterLongestIdleIndex = longestIdleTimeIndex +1;
		if(firstShiftAfterLongestIdleIndex === conventionalShifts.length) firstShiftAfterLongestIdleIndex = 0;
		for(var i = 0 ; i < firstShiftAfterLongestIdleIndex; i++) {
			var temp = conventionalShifts.shift();
			conventionalShifts.push(temp);
		}

		// data.push([new Date(2017, 11, 11, 0, 0, 0, 0), 0, this.predictionSettings.maxSOC, this.predictionSettings.minSOC]);
		var currentAHR = this.device.ahrcapacity;
		conventionalShifts.forEach((shift) => {
			data.push([shift.start, currentAHR]);
			if (shift.start > shift.end) {
				var period = ((24*60*60) - shift.start) / 3600;
				var endOfDayAHR = currentAHR - Math.round(this.predictionObject.avg_daily_inuse_ahr * period / 24);
				data.push([(24*60*60) - 1, endOfDayAHR]);
				data.push([0, endOfDayAHR]);
			}
			currentAHR -= shift.ahr;
			data.push([shift.end, currentAHR]);
		});

		var maxSOCPeriod = mostIdleTime - 3;
		var lastShift = conventionalShifts[conventionalShifts.length -1];
		var maxSOCEndTime = lastShift.end + (maxSOCPeriod * 3600);
		var maxSOCvalue = this.predictionSettings.maxSOC;
		if((currentAHR / this.device.ahrcapacity * 100) > maxSOCvalue) {
			maxSOCvalue = currentAHR / this.device.ahrcapacity * 100;
		}
		if(maxSOCEndTime === 24*60*60) {
			var period = ((24*60*60) - lastShift.end) / 3600;
			var endOfDayAHR = maxSOCvalue * this.device.ahrcapacity / 100;
			data.push([(24*60*60) - 1, endOfDayAHR]);
			data.push([0, endOfDayAHR]);
		} else {
			if(maxSOCEndTime > 24*60*60) {
				var period = ((24*60*60) - lastShift.end) / 3600;
				var endOfDayAHR: number = currentAHR;
				if(currentAHR < this.device.ahrcapacity * this.predictionSettings.maxSOC / 100) {
					endOfDayAHR += Math.round(this.predictionObject.avg_daily_inuse_ahr * period / 24);
				}
				data.push([(24*60*60) - 1, endOfDayAHR]);
				data.push([0, endOfDayAHR]);
				maxSOCEndTime -= 24*60*60;
				if(maxSOCvalue < (endOfDayAHR / this.device.ahrcapacity * 100)) {
					maxSOCvalue = endOfDayAHR / this.device.ahrcapacity * 100;
				}
			} 
			data.push([maxSOCEndTime, maxSOCvalue * this.device.ahrcapacity / 100]);
		}

		// sort data to be the start of day first
		data = data.sort((a, b) => {return a[0] - b[0];});

		var conventionalData = [];
		data.forEach((element) => {
			var timeObj: any	= this.commonUtil.convertHourToSeconds(element[0], null, true);
			conventionalData.push([
				new Date(2017, 11, 11, timeObj.hour, timeObj.min, 0, 0),
				Math.round((element[1] / this.device.ahrcapacity) * 100),
				this.predictionSettings.maxSOC,
				this.predictionSettings.minSOC
			]);
		});

		return conventionalData;
	}

	drawSOCpredictionChart(isConventional = false){
		var prepareChartData = () => {
			var data = [];
			var startOfDayIsIdle = true,
				lastEventOfDayWithinShift = false;

			data.push([new Date(2017, 11, 11, 0, 0, 0, 0), 0, this.predictionSettings.maxSOC, this.predictionSettings.minSOC]);
			this.predictionObject.shifts.forEach((shift) => {
				var endTime = shift.end;
				if (shift.start > shift.end) {
					startOfDayIsIdle = false;
					endTime = (24*60*60) - 1;
				}
				var startTimeObj: any	= this.commonUtil.convertHourToSeconds(shift.start, null, true);
				var endTimeObj: any		= this.commonUtil.convertHourToSeconds(endTime, null, true);
				if((endTimeObj.hour == 0 && endTimeObj.min == 0) || (startTimeObj.hour == 0 && startTimeObj.min == 0)) {
					lastEventOfDayWithinShift = true;
				}
				data.push([
					new Date(2017, 11, 11, startTimeObj.hour, startTimeObj.min, 0, 0),
					Math.round((shift.aahr_start / this.device.ahrcapacity) * 100),
					this.predictionSettings.maxSOC,
					this.predictionSettings.minSOC
				]);
				data.push([
					new Date(2017, 11, 11, endTimeObj.hour, endTimeObj.min, 0, 0),
					Math.round((shift.aahr_end / this.device.ahrcapacity) * 100),
					this.predictionSettings.maxSOC,
					this.predictionSettings.minSOC
				]);
			});
			var lastShift = this.predictionObject.shifts[this.predictionObject.shifts.length-1];
			var period, startOfDaySOC;
			if(startOfDayIsIdle) {
				if(!lastEventOfDayWithinShift) {
					data.push([new Date(2017, 11, 11, 23, 59, 0, 0), 0, this.predictionSettings.maxSOC, this.predictionSettings.minSOC]);
				}
				var lastShiftEndSOC = (lastShift.aahr_end / this.device.ahrcapacity) * 100;
				period = (24*60*60) - lastShift.end;
				startOfDaySOC = Math.round(lastShiftEndSOC + (this.predictionObject.avg_daily_inuse_ahr * period / (3600 * 24)));
				if (startOfDaySOC>this.predictionSettings.maxSOC) startOfDaySOC = this.predictionSettings.maxSOC;
				data[0][1] = startOfDaySOC;
				data[data.length-1][1] = startOfDaySOC;
			} else {
				period = (24*60*60) - lastShift.start;
				var avgHourlyShiftAHR = lastShift.ahr / ((lastShift.end - lastShift.start + (24*60*60)) / 3600);
				var startOfDayAHR = Math.round(lastShift.aahr_start - (avgHourlyShiftAHR * period / 3600));
				startOfDaySOC = (startOfDayAHR / this.device.ahrcapacity) * 100;
				data[data.length-1][1] = startOfDaySOC;
				data[0][1] = startOfDaySOC;
				var endTimeObj: any		= this.commonUtil.convertHourToSeconds(lastShift.end, null, true);
				data.splice(1, 0, [new Date(2017, 11, 11, endTimeObj.hour, endTimeObj.min, 0, 0), Math.round((lastShift.aahr_end / this.device.ahrcapacity) * 100), this.predictionSettings.maxSOC, this.predictionSettings.minSOC]);
			}
			return data;
		};

		if(this.predictionObject.shifts.length === 0) {
			return;
		}

		let key = 'shiftSOC';        
		if(isConventional) {
			key += '-conventional';
		}
		
		let SOCData = [
			[
				{label: "Time", type: "datetime"},
				{label: "SOC", type: "number"},
				{label: "Max SOC", type: 'number'},
				{label: "Min SOC", type: 'number'},
			]
		];
		var data;
		if(isConventional) {
			data = this.prepareConventionalChartData();
		} else {
			data = prepareChartData();
		}
		SOCData = SOCData.concat(data);
		this.chartObject.data[key] = SOCData;

		let config = new LineChartConfig({
			LegendPosition: 'top', 
			hAxis: {
				title: 'Day Time',
				titleTextStyle: {color: 'black', fontSize: 18}
			},
			colors: ["#3799db", "green", "red"],
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
			vAxis: {
				title: '%SOC',
				titleTextStyle: {color: 'black', fontSize: 18},
				minValue: 0, 
				maxValue: 100,
				gridlines: {'count': 10},
				viewWindow: { min: 0, max: 100},
				viewWindowMode: "explicit"
			},
			explorer: {
				axis: 'horizontal',
				actions: ['dragToZoom', 'rightClickToReset'] ,
				maxZoomIn: 50.0,
				keepInBounds: true
			},
			widgetHeight: 400
		});

		this.chartObject.config[key] = config;
	}
	
	drawEventsSOCpredictionChart(){
		var prepareChartData = () => {
			var data = [];
			var previousSOCvalue = this.predictionSettings.maxSOC;
			var minChargeOppurtinitySeconds = (+this.predictionSettings.minChargeOppurtinityTime) * 60;
			var maxSOC = this.predictionSettings.maxSOC;
			data.push([new Date(this.events[0].data.start_time*1000), previousSOCvalue, this.predictionSettings.maxSOC, this.predictionSettings.minSOC]);
			this.events.forEach((eventData) => {
				var event = eventData.data;
				var endSOC = previousSOCvalue;
				if(event.event_name == 3) {
					// inuse
					var eventSOC = Math.round((event.inuse_as / (this.device.ahrcapacity * 3600)) * 100);
					endSOC-= eventSOC;
					if(endSOC < 0) {
						endSOC = 0;
					}
				} else {
					// other
					if(event.duration >= minChargeOppurtinitySeconds) {
						var chargeSOC = Math.round((this.predictionObject.minDailyChargeRate / 100) * this.device.ahrcapacity * (event.duration/3600) * (this.predictionSettings.chargeEfficiency / 100));
						endSOC += chargeSOC;
						if(endSOC > maxSOC) {
							endSOC = maxSOC;
						}
					}
				}

				data.push([new Date((event.start_time + event.duration)*1000), endSOC, this.predictionSettings.maxSOC, this.predictionSettings.minSOC]);
				previousSOCvalue = endSOC;
			});
			return data;
		};
		if(this.events.length === 0) {
			return;
		}

		let key = 'eventSOC';

		let SOCData = [
			[
				{label: "Time", type: "datetime"},
				{label: "SOC", type: "number"},
				{label: "Max SOC", type: 'number'},
				{label: "Min SOC", type: 'number'},
			]
		];
		
		let data = prepareChartData();
		SOCData = SOCData.concat(data);
		this.chartObject.data[key] = SOCData;

		let config = new LineChartConfig({
			LegendPosition: 'top', 
			hAxis: {
				title: 'Date',
				titleTextStyle: {color: 'black', fontSize: 18}
			},
			colors: ["#3799db", "green", "red"],
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
			vAxis: {
				title: '%SOC',
				titleTextStyle: {color: 'black', fontSize: 18},
				minValue: 0, 
				maxValue: 100,
				gridlines: {'count': 10},
				viewWindow: { min: 0, max: 100},
				viewWindowMode: "explicit"
			},
			explorer: {
				axis: 'horizontal',
				actions: ['dragToZoom', 'rightClickToReset'] ,
				maxZoomIn: 50.0,
				keepInBounds: true
			},
			widgetHeight: 400
		});

		this.chartObject.config[key] = config;
	}

	editShift(index) {
		this.shift = {};
		this.shift = _.extend(this.shift, this.shifts[index]);
		this.isEditMode = true;
		this.shiftIndex = index;
		this.modal.show();
	}

	addShift() {
		this.shift.startTimeFormatted	= moment(this.shift.startTime, 'HH:mm').format('hh:mm A');
		this.shift.endTimeFormatted		= moment(this.shift.endTime, 'HH:mm').format('hh:mm A');
		var startTimeObj				= this.shift.startTime.split(":");
		var endTimeObj					= this.shift.endTime.split(":");
		this.shift.start_hour			= startTimeObj[0];
		this.shift.start_minute			= startTimeObj[1];
		this.shift.end_hour				= endTimeObj[0];
		this.shift.end_minute			= endTimeObj[1];
		this.shift.start_time_seconds	= this.commonUtil.convertHourToSeconds(this.shift.start_hour, this.shift.start_minute);
		this.shift.end_time_seconds		= this.commonUtil.convertHourToSeconds(this.shift.end_hour, this.shift.end_minute);

		var validPeriod = true;
		for(var k in this.shifts) {
			if(this.isEditMode && (+k) == this.shiftIndex) {
				continue;
			}
			var item = this.shifts[k];
			
			if(item.start_time_seconds <= this.shift.end_time_seconds && item.end_time_seconds >= this.shift.start_time_seconds) {
				validPeriod = false;
				this.notificationMessage.setMessage('There are shifts overlapping, please recheck selected shifts periods.', 'danger', 10000, true);
				break;
			}
		}

		if(validPeriod) {
			if(this.isEditMode) {
				this.shifts[this.shiftIndex] = this.shift;
			} else {
				this.shifts.push(this.shift);
			}
		}

		if(!this.isEditMode) {
			this.shift = {overrideShiftAHr: (Math.ceil(0.1*this.device.ahrcapacity/5)*5)};
		}
		this.isEditMode = false;
		this.modal.hide();
	}
	generateImageURI(event) {
		let chart = event['chart'];
		let elementId = event['elementId'];
		let image = this.chartUtil.getChartImageUri(chart);
		this.images[elementId] = image;
	}
	ngOnDestroy() {
		this.modal.onClose.unsubscribe();
	}
	printChart = function(elementId) {
		var image = this.images[elementId];
		var printContent = '<img src="'+image+'" style="width:100%;"" border="0" alt="Null">';

		this.commonUtil.print({
			appendPrintContent: printContent,
		});
	}
}