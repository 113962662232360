<div class="col-md-12 boxs3">
	<div class="col-md-2">
		<a href="javascript:;" class="btn btn-default" (click)="printDateEnabled = true"><i class="glyphicon glyphicon-print"></i> Print Alerts</a>
	</div>
	<div *ngIf="printDateEnabled" class="col-md-3">
		<select class="form-control input-sm" [(ngModel)]="printDate" (change)="printAlerts()">
			<option value="" selected="selected">Select Date Period</option>
			<option *ngIf="currentSite.act_intelligent_type != 0" value="Y">Yesterday</option>
			<option *ngIf="currentSite.act_intelligent_type != 0" value="W">Last Week</option>
			<option *ngIf="currentSite.act_intelligent_type != 0" value="7">Last 7 Days</option>
			<option *ngIf="currentSite.act_intelligent_type != 0" value="14">Last 14 Days</option>
			<option value="30">Last 30 Days</option>
			<option value="90">Last 90 Days</option>
			<option *ngIf="data?.siteSettings?.reports_since_installation" value="siteInstallation">Since Installation</option>
		</select>
	</div>
</div>
<app-widgets-loader
	key="battery-alerts"
	[data]="{
		data: data,
		siteId: currentSite?.id,
		customerId: customerId,
		isACTuser: user.isACTuser,
		siteName: siteName,
		customerName: customerName
	}"
	[passAllDataOnce]="true"
	*ngIf="data"
></app-widgets-loader>