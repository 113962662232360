import { Component, OnInit } from '@angular/core';
import { SiteDashboardService } from '../../site-dashboard.service';
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: 'app-reports-charts',
	templateUrl: './reports-charts.component.html',
	styleUrls: ['./reports-charts.component.css']
})
export class ReportsChartsComponent implements OnInit {

	activeChart: string;
	data = {};
	customerId;
	siteId;
	constructor(
		private siteDashboardService: SiteDashboardService,
		private route: ActivatedRoute
	) { }

	ngOnInit() {
		this.customerId =  this.route.parent.snapshot.params['customerid'];
		this.siteId =  this.route.parent.snapshot.params['siteid'] ;
		this.activeChart =  this.route.snapshot.params['report'] || 'availability';
		this.siteDashboardService.setActiveTab('actfirst');
		this.siteDashboardService.setActiveSubTab('reports-charts');
	}
}