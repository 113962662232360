<div id="page-wrapper">
	<div class="boxs">
		<table class="table table-stripped">
			<thead>
				<tr>
					<th>Customer Name</th>
					<th>Site Name</th>
					<th>Generate report</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let deviceInfo of devicesInfo">
					<td><a [routerLink]="['/main', deviceInfo.customer_id]">{{deviceInfo.customer_name}}</a></td>
					<td><a [routerLink]="['/', deviceInfo.customer_id, deviceInfo.site_id]">{{deviceInfo.site_name}}</a></td>
					<td><button class="btn btn-primary" (click)="generateReport(deviceInfo.customer_id, deviceInfo.site_id)"><i class="fa fa-download"></i></button></td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
