import * as _ from 'underscore';

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Site } from '../../../shared/models/site.model';
import { SideMenuService } from '../../../shared/side-menu/side-menu.service';
import { CustomerDashboardService } from '../customer-dashboard.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-customer-map',
	templateUrl: './customer-map.component.html',
	styleUrls: ['./customer-map.component.css']
})
export class CustomerMapComponent implements OnInit, OnDestroy {
	// this component has a different behavior than sites map, better to be built separately.
	sites : Site[] = [];
	allSites : Site[] = [];
	siteInfoBox = {
		show: false,
		id: 0,
		site: {},
		actIntelligent: false,
		customer: {}
	};
	clickedSiteNotDistinctSubscription: Subscription = new Subscription();
	currentSitesSubscription: Subscription = new Subscription(); 
	intelligentSitesCheckboxSubscription: Subscription = new Subscription(); 

	constructor(
		private sideMenu: SideMenuService,
		private cdService: CustomerDashboardService) { }

	ngOnInit() {
		this.setInfoBoxVisibility(false);
		this.currentSitesSubscription = this.sideMenu.currentSites.subscribe((sites) => {
            this.sites = this.cdService.addSitesConnectivityStatusIcons(sites);
			this.allSites = _.extend([], this.sites);
            this.sites = this.filterSites(false);
		});
		this.intelligentSitesCheckboxSubscription = this.cdService.checkBoxSubject.subscribe((value) => {
			this.sites = this.filterSites(value);
        });
		this.clickedSiteNotDistinctSubscription = this.sideMenu.clickedSiteNotDistinct.subscribe(site => {
			if (site && site.id > 0) {
				this.siteMarkerClick(site);
			}
		});
	}

	siteMarkerClick(site) {
		this.setInfoBoxVisibility(true);
		this.siteInfoBox.site = site;
		this.siteInfoBox.customer = this.sideMenu.getCustomer(site.customerid);
		this.siteInfoBox.actIntelligent = site.act_intelligent_type != 0 ? true: false;
	}

	setInfoBoxVisibility(value: boolean) {
		this.siteInfoBox.show = value;
		this.sideMenu.setSiteInfoBoxVisibility(value);
	}

	filterSites(value) {
        let intelligentFilter;
		let sites = this.allSites.filter((site: any) => {
            intelligentFilter = value ? site.act_intelligent_type != 0 : true ;
			return intelligentFilter && !site.test_site;
		});
		return sites;
	}

	ngOnDestroy() {
		this.currentSitesSubscription.unsubscribe();
		this.intelligentSitesCheckboxSubscription.unsubscribe();
		this.clickedSiteNotDistinctSubscription.unsubscribe();
		this.sideMenu.setClickedSite(new Site());
	}
}