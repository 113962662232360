
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { NoUIBlockHttpParams } from '../../../../shared/loader/noUIBlock-httpParam';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { CommonDataService } from '../../../../shared/services/common-data.service';
import { DevicesDashboardService } from '../../devices/devices-dashboard.service';
import * as lodash from 'lodash';

@Injectable()
export class ChargersDashboardService {

	arrayFields = ['finishdaysmask', 'autostartmask', 'eqdaysmask', 'energydaysmask', 'lockoutdaysmask'];
	gctBatteryModels = {
		0x23: "Main Deck Loader",
		0x24: "Lower Deck Loader",
		0x25: "Belt Loader",
		0x26: "Baggage Tractor",
		0x27: "Baggage Tractor",
		0x38: "Baggage Tractor"
	}

	constructor(
		private httpClient: HttpClient,
		private commonUtil: CommonUtil,
		private commonData: CommonDataService,
		private datePipe: DatePipe,
		private devicesDashboard: DevicesDashboardService
	) { }

	getChargers(charger, options) {
		return this.httpClient.post('/chargers/getChargers', {charger, options}, {
			observe: "body"
		});
	}

	getCachedChargers(id) {
		return this.httpClient.post('/chargers/getCachedChargers', {id}, {
			observe: "body"
		});
	}

	getChargersSettingsInfo(ids) {
		return this.httpClient.post('/chargers/getChargersSettingsInfo', {ids}, {
			observe: "body"
		});
	}

	saveChargerSettings(stage, charger) {
		return this.httpClient.post('/chargers/saveChargerSettings', {stage, charger}, {
			observe: "body"
		});
	}

	readChargerPMsAllSnapshots(chargerId) {
		return this.httpClient.post('/chargers/readChargerPMsAllSnapshots', {chargerId}, {
			observe: "body"
		});
	}

	getCycleRecords(chargerID, options) {
		if(options.hasOwnProperty('cycleId') && !options.cycleId)
			return observableOf(null);

		return this.httpClient.post('/chargers/getCycleRecords', {chargerID, options}, {
			observe: "body"
		});
	}

	getAllChargersCycleRecords(siteId, options) {
		return this.httpClient.post('/chargers/getAllChargersCycleRecords', {siteId, options}, {
			observe: "body"
		});
	}

	readPMsState(id, blockUI) {
		return this.httpClient.post('/chargers/readPMsState', {id}, {
			observe: "body",
			params: new NoUIBlockHttpParams(!blockUI)
		});
	}

	getChargersListing(customerId, siteId, noUIBlock, option) {
		return this.httpClient.post('/chargers/getChargersListing', {customerId, siteId, option}, {
			observe: "body",
			params: new NoUIBlockHttpParams(noUIBlock)
		});
	}

	getLCDhistory(id, options) {
		return this.httpClient.post('/chargers/getLCDhistory', {id, options}, {
			observe: "body"
		});
	}

	readLCDstatus(id, blockUI) {
		return this.httpClient.post('/chargers/readLCDstatus', {id}, {
			observe: "body",
			params: new NoUIBlockHttpParams(!blockUI)
		});
	}

	sendLCDaction(id, action, options) {
		return this.httpClient.post('/chargers/sendLCDaction', {id, action, options}, {
			observe: "body",
			params: new NoUIBlockHttpParams(true)
		});
	}

	getPMFaults(chargerID, fromDate, toDate) {
		return this.httpClient.post('/chargers/getPMFaults', {chargerID, fromDate, toDate}, {
			observe: "body"
		});
	}

	getPmWarnings(chargerID, fromDate, toDate) {
		return this.httpClient.post('/chargers/getPmWarnings', {chargerID, fromDate, toDate}, {
			observe: "body"
		});
	}

	getPMInfo(pmSN) {
		return this.httpClient.post('/chargers/getPMInfo', {pmSN}, {
			observe: "body"
		});
	}

	getPMReportStatus() {
		return this.httpClient.post('/chargers/getPMReportStatus', {}, {
			observe: "body"
		});
	}

	requestPMReport() {
		return this.httpClient.post('/chargers/requestPMReport', {}, {
			observe: "body"
		});
	}

	fetchChargerData(payLoadObject) {
		var noUIBlock = false;
		if(payLoadObject.from == 'MostRecent')
			noUIBlock = true;
		
		return this.httpClient.post('/chargers/fetchChargerData', payLoadObject, {
			observe: "body",
			params: new NoUIBlockHttpParams(noUIBlock)
		});
	}

	eventsDetection(params) {
		return this.httpClient.post('/customers/eventsDetection', params, {
			observe: "body"
		});
	}

	addCommandToChargerQueue(id, command, options = {}) {
		return this.httpClient.post('/chargers/addCommandToChargerQueue', {id, command, options}, {
			observe: "body"
		});
	}
	getChargerPowerSnapshots(id, startDate, endDate, isSiteEnergy, isRefresh) {
		var url = '/chargers/getChargerPowerSnapshots';
		if(isSiteEnergy)
			url = '/chargers/getSiteAggPowerSnapshots';
		return this.httpClient.post(url, {id, startDate, endDate}, {
			observe: "body",
			params: new NoUIBlockHttpParams(isRefresh)
		});
	}

	getBatteryModelFromBattviewId(params, charger) {

		let battviewId		= params.battviewid;
		let intBattviewId	= parseInt(battviewId);
		let batteryId		= params.batteryid;
		
		if(battviewId != 0 && !batteryId && params.runWithBMS && intBattviewId == battviewId) {
			
			battviewId	= (+battviewId).toString(16);
			battviewId	= this.commonUtil.intToHexArray(battviewId);
			
			let batteryModel	= battviewId.shift(); //remove and return first element
			batteryModel		= +("0x"+batteryModel); //convert to hex number ex: 10 => 0x10 => 16
			let modelType		= this.gctBatteryModels[batteryModel] || '';

			if(battviewId.length < 3)
				battviewId = modelType + " - " + battviewId.join('');
			else {
				// CTVW-938
				let bvid = modelType + " - ";
				for(let i = 0; i < battviewId.length; i++) {
					if(i == battviewId.length-1)
						bvid += '-';
					bvid += battviewId[i];
				}
				battviewId = bvid;
			}
			params.haveBatteryModel = true;
			
			return battviewId;
		}

		return batteryId;
	}

	chargerUpdatedFieldsEffect(dirtyFields, oldCharger) {
		var doChanges = false;
		var batterytype, chargertype;

		if(dirtyFields.hasOwnProperty('defaultbrightness') && dirtyFields.defaultbrightness) {
			dirtyFields.defaultbrightness = dirtyFields.defaultbrightness * 2;
		}

		if(dirtyFields.hasOwnProperty('reconnecttimer') && dirtyFields.reconnecttimer == 0) {
			dirtyFields.reconnecttimer = 60;
		}

		if(dirtyFields.hasOwnProperty('pmmaxcurrent') && dirtyFields.pmmaxcurrent == 0) {
			dirtyFields.pmmaxcurrent = 99;
		}

		if(dirtyFields.hasOwnProperty('batterytype')) {

			batterytype = parseInt(dirtyFields.batterytype);

			if(batterytype == 0) {
				//"Lead Acid"

				if(dirtyFields.hasOwnProperty('chargertype') || oldCharger.chargertype !== null)
					doChanges = true;
			} else {
				//"Lithium-ion" or "GEL"
				doChanges = true;

				//"Lithium-ion": deal with it as lead acid
				if(batterytype == 1 && oldCharger.firmwareversion <= 2.5)
					batterytype = 0;
			}
		} else {

			batterytype = oldCharger.batterytype;
		}

		if(dirtyFields.hasOwnProperty('chargertype')) {

			chargertype = parseInt(dirtyFields.chargertype);

			if(batterytype === 0)
				doChanges = true;
		} else {

			chargertype = oldCharger.chargertype;
		}

		if(!doChanges)
			return dirtyFields;

		switch(batterytype) {
			case 0: {
				//"Lead Acid"
				dirtyFields.firate					= 5;
				dirtyFields.tricklevoltage			= 2.0;
				dirtyFields.forcefinishtimeout		= false;
				dirtyFields.eqtimer					= '04:00';
				dirtyFields.desulfationtimer		= '12:00';
				dirtyFields.finishdv				= 5;
				dirtyFields.finishdt				= 59;
				dirtyFields.fistartwindow			= '00:00';
				dirtyFields.eqstartwindow			= '00:00';
				dirtyFields.cc_ramping_min_steps	= 0;
				dirtyFields.fivoltage				= 2.6;
				dirtyFields.eqvoltage				= 2.65;
				dirtyFields.cvfinishcurrent			= 12;
				dirtyFields.cvtimer					= '04:00';
				dirtyFields.finishtimer				= '03:00';
				dirtyFields.cvcurrentstep			= 0;
				dirtyFields.finishwindow			= '24:00';
				dirtyFields.fischedulingmode		= false;
				dirtyFields.finishdaysmask			= [0, 1, 2, 3, 4, 5, 6]; //All the week
				dirtyFields.trrate					= 5;
				dirtyFields.maxtemperaturefault		= 54.4;
				dirtyFields.foldtemperature			= 51.6;
				dirtyFields.cooldowntemperature		= 46.1;
				dirtyFields.eqrate					= 4;

				switch(chargertype) {
					case 0:
						//FAST
						dirtyFields.cvvoltage			= 2.42;
						dirtyFields.ccrate				= 40;
						dirtyFields.eqdaysmask			= [0]; //Sunday
						dirtyFields.fischedulingmode	= true;
					break;

					case 1:
						//CONVENTIONAL
						dirtyFields.cvvoltage	= 2.37;
						dirtyFields.ccrate		= 17;
						dirtyFields.eqdaysmask	= [0]; //Sunday
					break;

					case 2:
						//OPPORTUNITY
						dirtyFields.cvvoltage	= 2.4;
						dirtyFields.ccrate		= 25;
						dirtyFields.eqdaysmask	= [0]; //Sunday
					break;
				}
			break;
		} case 1:
				//"Lithium-ion"
				dirtyFields.eqrate					= 5;
				dirtyFields.forcefinishtimeout		= false;
				dirtyFields.eqtimer					= '04:00';
				dirtyFields.desulfationtimer		= '12:00';
				dirtyFields.finishdv				= 5;
				dirtyFields.finishdt				= 59;
				dirtyFields.fistartwindow			= '00:00';
				dirtyFields.eqstartwindow			= '00:00';
				dirtyFields.cc_ramping_min_steps	= 60;
				dirtyFields.fivoltage				= 2.34;
				dirtyFields.eqvoltage				= 2.34;
				dirtyFields.cvfinishcurrent			= 10;
				dirtyFields.cvtimer					= '04:00';
				dirtyFields.finishtimer				= '03:00';
				dirtyFields.cvcurrentstep			= 2;
				dirtyFields.cvvoltage				= 2.3;
				dirtyFields.tricklevoltage			= 2.2;
				dirtyFields.trrate					= 40;
				dirtyFields.ccrate					= 50;
				dirtyFields.fischedulingmode		= false;
				dirtyFields.finishdaysmask			= [0, 1, 2, 3, 4, 5, 6]; //All the week
				dirtyFields.eqdaysmask				= []; //no days
				dirtyFields.firate					= 5;
				dirtyFields.finishwindow			= '24:00';
				dirtyFields.maxtemperaturefault		= 54.4;
				dirtyFields.foldtemperature			= 51.6;
				dirtyFields.cooldowntemperature		= 46.1;
			break;

			case 2:
				//"GEL"
				dirtyFields.eqrate					= 1;
				dirtyFields.firate					= 1.5;
				dirtyFields.tricklevoltage			= 2.0;
				dirtyFields.forcefinishtimeout		= false;
				dirtyFields.eqtimer					= '04:00';
				dirtyFields.desulfationtimer		= '12:00';
				dirtyFields.finishdv				= 5;
				dirtyFields.finishdt				= 59;
				dirtyFields.fistartwindow			= '00:00';
				dirtyFields.eqstartwindow			= '00:00';
				dirtyFields.cc_ramping_min_steps	= 0;
				dirtyFields.fivoltage				= 2.55;
				dirtyFields.eqvoltage				= 2.55;
				dirtyFields.cvfinishcurrent			= 8;
				dirtyFields.cvtimer					= '03:00';
				dirtyFields.finishtimer				= '04:00';
				dirtyFields.cvcurrentstep			= 0;
				dirtyFields.cvvoltage				= 2.33;
				dirtyFields.ccrate					= 17;
				dirtyFields.fischedulingmode		= false;
				dirtyFields.finishdaysmask			= [0, 1, 2, 3, 4, 5, 6]; //All the week
				dirtyFields.eqdaysmask				= []; //no days
				dirtyFields.finishwindow			= '24:00';
				dirtyFields.trrate					= 5;
				dirtyFields.maxtemperaturefault		= 48.9;
				dirtyFields.foldtemperature			= 46.1;
				dirtyFields.cooldowntemperature		= 40.6;
			break;

			case 4:
				//Thin Plate Pure Lead
				dirtyFields.trrate				= 26;
				dirtyFields.ccrate				= 30;
				dirtyFields.firate				= 10;
				dirtyFields.eqrate				= 2;
				dirtyFields.tricklevoltage		= 2;
				dirtyFields.cvvoltage			= 2.4;
				dirtyFields.fivoltage			= 2.5;
				dirtyFields.cvfinishcurrent		= 4;
				dirtyFields.cvcurrentstep		= 0;
				dirtyFields.cvtimer				= '03:00';
				dirtyFields.finishtimer			= '04:00';
				dirtyFields.eqtimer				= '04:00';
				dirtyFields.desulfationtimer	= '12:00';
				dirtyFields.finishdv			= 5;
				dirtyFields.finishdt			= 59;
			break;
		}

		return dirtyFields;
	}

	formatChargerQueuedChanges(charger, changesStackOrigin, mode='normal') {
		if(Object.keys(changesStackOrigin).length === 0)
			return changesStackOrigin;

		let changesStack = lodash.cloneDeep(changesStackOrigin);

		var daysMask			= ['energydaysmask', 'finishdaysmask', 'eqdaysmask', 'lockoutdaysmask', 'autostartmask'];
		var temperatureFields	= ['cooldowntemperature', 'foldtemperature', 'maxtemperaturefault', 'trtemperature'];
		var dates				= ['installationdate'];
		var times				= ['lastchangetime'];
		var booleans			= ['enableplc', 'doplcstackcheck', 'enableautodetectmultivoltage', 'temperaturesensorinstalled', 'enable_72v',
									'enablemanualdesulfate', 'daylightsaving', 'enablemanualeq', 'autostartenable', 'actviewenable', 'softapenabled',
									'forcefinishtimeout', 'battviewautocalibrationenable'];
		var invertedBooleans	= ['disablepushbutton'];
		var yesNoFields			= ['ignorebattviewsoc', 'chargeroverridebattviewfieqsched', 'enablerefreshcycleaftereq', 'enablerefreshcycleafterfi'];

		var days			= ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
		var chargerTypes	= [{id:0, text:"Fast"}, {id:1, text:"Conventional"}, {id:2, text:"Opportunity"}];
		var batteryTypes	= this.devicesDashboard.batteryTypes;
		var bmsTypes		= this.commonUtil.arrayToAssociativeArray(this.devicesDashboard.bmsTypes, 'id');
		var PMinputVoltages = [{id:0, text:"480"}, {id:1, text:"208"}, {id:2, text:"600"}, {id:3, text:"380"}];
		var daysOfTheWeek	= this.commonUtil.getDropDownListData('week');
		var disableEnable	= ['Disable', 'Enable'];
		var remoteStopBtn	= ['Disable', 'Remote Stop', 'Dual Cables'];
		var noYes			= ['No', 'Yes'];
		var tempFormat		= ['Fahrenheit', 'Celsius'];
		var ledcontrolList  = ['LED Strip', 'Disabled', 'Stack Light'];
		var BMSDaughterCardList = ['Disabled', 'BMS Only', 'BMS with fail over'];

		for (var field in changesStack) {

			if(mode == 'plainObject')
				changesStack[field] = [[changesStack[field]]];

			if(temperatureFields.indexOf(field) > -1) {

				if(!charger.temperatureformat) {
					changesStack[field].forEach((change) => {
						change[0] = Math.round(change[0] * 1.8 + 32);
					});
				}
			} else if(dates.indexOf(field) > -1) {

				changesStack[field].forEach((change) => {
					change[0] = this.datePipe.transform(change[0] * 1000, 'MM/dd/yyyy');
				});
			} else if(times.indexOf(field) > -1) {

				changesStack[field].forEach((change) => {
					change[0] = this.datePipe.transform(change[0] * 1000, 'MM/dd/yyyy hh:mm:ss a');
				});
			} else if(booleans.indexOf(field) > -1) {

				changesStack[field].forEach((change) => {
					change[0] = +change[0];
					change[0] = disableEnable[change[0]];
					if(!change[0])
						change[0] = 'Invalid option!';
				});
			} else if(invertedBooleans.indexOf(field) > -1) {

				changesStack[field].forEach((change) => {
					change[0] = +!change[0];
					change[0] = disableEnable[change[0]];
					if(!change[0])
						change[0] = 'Invalid option!';
				});
			} else if(daysMask.indexOf(field) > -1) {

				changesStack[field].forEach((change) => {
					if(typeof change[0] == 'object' && change[0].length > 0) {
						var dayNames = [];
						change[0].forEach((day) => {
							if(daysOfTheWeek[day])
								dayNames.push(daysOfTheWeek[day].text);
							else
								dayNames.push('Invalid day!');
						});
						change[0] = dayNames.join(', ');
					}
				});
			} else if(yesNoFields.indexOf(field) > -1) {
				changesStack[field].forEach((change) => {
					change[0] = +change[0];
					change[0] = noYes[change[0]];
					if(!change[0])
						change[0] = 'Invalid option!';
				});
			} else if(field == 'zoneid') {

				changesStack[field].forEach((change) => {
					
					if(change[0])
						change[0]--;

					var zoneInfo = this.commonData.TimeZonesMenu[change[0]];
					if(!zoneInfo)
						change[0] = 'Invalid zone!';
					else
						change[0] = zoneInfo.display_name;
				});
			} else if(field == 'chargertype') {
				changesStack[field].forEach((change) => {
					var type = chargerTypes[change[0]];
					if(!type)
						change[0] = 'Invalid type!';
					else
						change[0] = type['text'];
				});
			} else if(field == 'ocd_remote') {
				changesStack[field].forEach((change) => {
					change[0] = remoteStopBtn[change[0]];
					if(!change[0])
						change[0] = 'Invalid option!';
				});
			} else if(field == 'batterytype') {
				changesStack[field].forEach((change) => {
					var type = batteryTypes[change[0]];
					if(!type)
						change[0] = 'Invalid type!';
					else
						change[0] = type['text'];
				});
			} else if(field == 'pmvoltageinputvalue') {
				changesStack[field].forEach((change) => {
					var type = PMinputVoltages[change[0]];
					if(!type)
						change[0] = 'Invalid type!';
					else
						change[0] = type['text'];
				});
			} else if(field == 'temperatureformat') {
				changesStack[field].forEach((change) => {
					change[0] = +change[0];
					change[0] = tempFormat[change[0]];
					if(!change[0])
						change[0] = 'Invalid format!';
				});
			} else if(field == 'cvcurrentstep') {
				changesStack[field].forEach((change) => {
					if(change[0] == 0)
						change[0] = 'Default';
				});
			} else if(field == 'cc_ramping_min_steps') {
				changesStack[field].forEach((change) => {
					if(change[0] == 0)
						change[0] = 'Default';
				});
			} else if(field == 'nominal_temperature_shift') {
				changesStack['plant_temperature'] = [];
				changesStack[field].forEach((change) => {
					var platTemp = change[0] <= 18 ? "<65" : (change[0] == 25 ? "65-90" : ">90");
					changesStack['plant_temperature'].push([platTemp, change[1]]);
				});
			} else if(field == 'fischedulingmode') {
				changesStack[field].forEach((change) => {
					if(change[0])
						change[0] = 'Custom';
					else
						change[0] = 'Always';
				});
			} else if(field == 'lcd_fq') {
				changesStack[field].forEach((change) => {
					if(change[0] == 0)
						change[0] = 'V1';
					else 
						change[0] = 'V2';
				});
			} else if(field == 'ledcontrol'){
				changesStack[field].forEach((change) => {
					change[0] = ledcontrolList[change[0]];
					if(!change[0])
						change[0] = 'Invalid option!';
				});
			} else if(field == 'daughtercardenabled'){
				changesStack[field].forEach((change) => {
					change[0] = BMSDaughterCardList[change[0]];
					if(!change[0])
						change[0] = 'Invalid option!';
				});
			} else if(field == 'bms_id'){
				changesStack[field].forEach((change) => {
					change[0] = bmsTypes[change[0]];
					if(change[0])
						change[0] = change[0].text;
					else
						change[0] = 'Invalid option!';
				});
			} else if(field == 'defaultbrightness'){
				changesStack[field].forEach((change) => {
					change[0] = (change[0] != 0 ? change[0] / 2 : 0);
				});
			}
		}

		if(mode == 'plainObject') {

			let toReturn = {};
			for (var field in changesStack) {
				toReturn[field] = changesStack[field][0][0];
			}
			return toReturn;
		}

		return changesStack;
	}
}