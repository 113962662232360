import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import * as moment from 'moment';

import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { AdminService } from '../../admin.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../auth/user.service';

@Component({
	selector: 'app-smart-rebates-sites',
	templateUrl: './smart_rebates_sites.component.html',
	styleUrls: ['./smart_rebates_sites.component.css']
})
export class SmartRebatesSitesComponent implements OnInit {
	@ViewChild("consumptionsReportDialog") consumptionsReportDialog: any;
	downloadConsumptionsReportSubscription!: Subscription;
	selectedYear: number = new Date().getFullYear();
	selectedSiteId: number = 0;
	selectedStartDate: any;

	quarters = [
		{id: 0, value: 'All Year'},
		{id: 1, value:'Q1'},
		{id: 2, value:'Q2'},
		{id: 3, value:'Q3'},
		{id: 4, value:'Q4'}
	];

	selectedQuarter = this.quarters[0].id;
	years = [];

	data: any[] = [];
	constructor(
		private router: Router,
		private adminService: AdminService,
		private notificationMessage: NotificationMessageService,
		public translate: TranslateService,
		private userService: UserService,
	) { }

	ngOnInit() {
		if (!(this.userService.hasAccessFunction('official_reporting')))
			return this.router.navigate(['/unauthorized']);

		return this.getSmartRebatesSitesInfo();
	}

	ngAfterViewInit() {
		this.consumptionsReportDialog.onClose.subscribe((result: any) => {
			if (result) {
				this.downloadConsumptionsReport();
			}
		});
	}

	getSmartRebatesSitesInfo() {
		this.adminService.getSmartRebatesSitesInfo().subscribe((data: any) => {
			this.data = data;
		})
	}

	showConsumptionsReport(site) {
		this.selectedSiteId = site.id;
		const startDate = new Date(site.installation_date);
		this.getAllYearsFromDate(startDate);
		this.consumptionsReportDialog.show();
	}

	getAllYearsFromDate(startDate) {
		if (!startDate)
			return

		const currentDate = new Date();
		const startYear = startDate.getFullYear();
		const currentYear = currentDate.getFullYear();
		const years = [];

		for (let year = startYear; year <= currentYear; year++) {
				years.push(year);
		}

		if (!years.length)
			years.push(currentYear);

		this.selectedYear = years[years.length -1];
		this.years = years;
	}

	downloadConsumptionsReport() {
		let selectedStartDate = this.selectedStartDate;
		if(selectedStartDate)
			selectedStartDate = moment(this.selectedStartDate).utc().startOf('day').unix();

		this.downloadConsumptionsReportSubscription = this.adminService.downloadConsumptionsReport(this.selectedYear, this.selectedQuarter, this.selectedSiteId, selectedStartDate).subscribe((res: any) => {
			switch (res.err) {
				case 'not_sr_site':
					this.notificationMessage.setMessage(this.translate.instant('site.not_sr_site'));
					break;
				case 'no_devices_founded':
					this.notificationMessage.setMessage(this.translate.instant('site.no_devices_founded'));
					break;
				case 'no_report_data':
					this.notificationMessage.setMessage(this.translate.instant('smart_rebates_reports.no_data'));
					break;
				case 'file_not_found':
					this.notificationMessage.setMessage(this.translate.instant('smart_rebates_reports.file_not_found'));
					break;
				default:
					this.downloadReport(res);
					break;
				}
		});
	}

	downloadReport(data: any) {
		if (!data) return;
		const arr = new Uint8Array(data.content.data);
		const blob = new Blob([arr]);
		if (blob)
			saveAs(blob, data.name);
	}

	ngOnDestroy() {
		if (this.downloadConsumptionsReportSubscription)
			this.downloadConsumptionsReportSubscription.unsubscribe();
	}
}
