import { Component, OnInit, Output, EventEmitter, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'underscore';
import * as moment from 'moment';

import { UserService } from '../../../../../auth/user.service';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { NotificationMessageService } from '../../../../../shared/notification-message/notification-message.service';
import { BattviewsDashboardService } from '../../../battviews/battviews-dashboard/battviews-dashboard.service';
import { BattviewService } from '../../../../../admin/devices/battviews/battview.service';

@Component({
	selector: 'app-battviews-mobiles-listing',
	templateUrl: './battviews-mobiles-listing.component.html',
	styleUrls: ['./battviews-mobiles-listing.component.css']
})
export class BattviewsMobilesListingComponent implements OnInit, OnDestroy {

	@Output() updateAppearanceElementsFlags = new EventEmitter<any>(true);

	hasBattviews: boolean = false;
	newStudyEnabled: boolean = false;
	newStudy: any = {};
	batteriesListing: any[] = [];
	autoconnectFreq = 1;
	bvMobiles = [];
	studiesByBVMobile: any = [];
	selectedDealerID 	= '0';
	startNewStudyBVid	= 0;
	currentUser: any = {};
	timeoutID: any;

	constructor(
		private renderer: Renderer2,
		public elementRef: ElementRef,
		private battviewService: BattviewService,
		public userService: UserService,
		private commonUtil: CommonUtil,
		private notificationMessage: NotificationMessageService,
		private battviewsDashboardService: BattviewsDashboardService,
		private route: ActivatedRoute,
	) { }

	ngOnInit() {
		this.updateAppearanceElementsFlags.emit({
			addNoteEnabled: false,
			pageHasFilterByDate: false,
			pageHasBattviewTags: false,
			pageHasManageBtn: false,
			pageHasTimesInfo: false,
		});
		this.selectedDealerID = this.route.snapshot.data['dealerId'] || '1';
		this.currentUser = this.userService.getCurrentUser();

		this.getBatteriesListing();
	}

	selectedDealerChanged(dealerId) {
		this.selectedDealerID = dealerId;
		this.renderBattviewMobileListing();
	}

	filterBattviewMobiles() {
		var result = [];
		if(!this.currentUser.isACTuser) {
			return this.bvMobiles;
		}
		this.bvMobiles.forEach((bvMobile) => {
			if(bvMobile.dealerid == this.selectedDealerID) {
				result.push(bvMobile);
			}
		});
		return result;
	}

	getLatestStudy(bfdId) {
		var latestStudyIndex	= -1,
			latestStudyId		= 0;

		if(!this.studiesByBVMobile[bfdId]) {
			return false;
		}

		for(var i = 0; i < this.studiesByBVMobile[bfdId].length; i++) {
			var study = this.studiesByBVMobile[bfdId][i];
			if(study.studyid > latestStudyId) {
				latestStudyId = study.studyid;
				latestStudyIndex = i;
			}
		}

		if(latestStudyIndex === -1) 
			return false;
		return this.studiesByBVMobile[bfdId][latestStudyIndex];
	}
	
	renderBattviewMobileListing () {
		var bvMobiles = this.filterBattviewMobiles();

		this.hasBattviews = (bvMobiles.length > 0);

		var result = _.sortBy(bvMobiles, 'batteryid');

		let batteriesListing: any = {};
		
		result.forEach((item) => {
			
			item.latestStudy	= this.getLatestStudy(item.id);
			var labelName		= item.nominalvoltage + ' Volts';
				
			if(!batteriesListing[labelName])
				batteriesListing[labelName] = [];

			batteriesListing[labelName].push(item);
		});

		var batteriesListingOld = {};
		batteriesListingOld = _.extend(batteriesListingOld, this.batteriesListing);
		
		this.batteriesListing	= batteriesListing;

		// list all the battviews as categories based on nominal voltage:
		var nowDate		= Math.round(new Date().getTime() / 1000);
		var todayTime	= +(moment().utc().startOf('day').format("X"));

		for (var deviceGroup in this.batteriesListing) {

			this.batteriesListing[deviceGroup].forEach((item) => {
				
				item.isConnected = false;
				var disconnectTime;

				var connectFrequency = +item.actviewconnectfrequency;

				if(connectFrequency == this.autoconnectFreq)
					connectFrequency = 900;

				var lastconnecttime	= Math.round(new Date(item.lastconnecttime).getTime() / 1000);
				disconnectTime		= nowDate - lastconnecttime; //disconnectTime in seconds

				// last connect time within last 48 hours
				item.hasExtraActions = disconnectTime < (48 * 60 * 60);
				if (disconnectTime <= (2.5 * connectFrequency)) {

					//Less than 2.5 times of normal connectFrequency
					item.isConnected = true;
					item.bvStatus = 'bv-connected';
				} else if(disconnectTime <= 24*3600) {

					//More than 24 hrs and less than a week
					item.bvStatus = 'bv-not-connected';
				} else if(disconnectTime > 24*3600 && disconnectTime <= 7*24*3600) {

					//More than 24 hrs and less than a week
					item.bvStatus = 'bv-not-connected-day';
				} else {

					//More than a week disconnectTime > 7*24*3600
					if(!item.actviewenabled)
						item.bvStatus = 'bv-mobile-not-connected-week';
					else
						item.bvStatus = 'bv-not-connected-week';
				}

				item.hasFault = false;
				item.timespanTextArr = [];
				if(!item.isConnected) {
					if(!item.actviewenabled) {
						item.timespanTextArr.push('Not Connected');
					} else {
						var disconnectedTimeText = this.commonUtil.secondsToElapsedTime(disconnectTime);
		
						item.timespanTextArr.push(disconnectedTimeText ? 'Last connect: ' + disconnectedTimeText : '');
					}
				}

				if(item.mainsenseerrorcode > 0 && item.hasExtraActions) {
					var faults = this.commonUtil.getFaultText(item.mainsenseerrorcode, {enablehalleffectsensing: item.enablehalleffectsensing});
					item.hasFault = true;
					item.timespanTextArr = item.timespanTextArr.concat(faults);
				}

				if (item.enableplc && item.hasExtraActions) {
					var plc_event_time = +(moment(item.plc_event_time).utc().startOf('day').format("X"));
					var hide_plc_fault_date = +(moment(item.hide_plc_fault_date).utc().startOf('day').format("X"));
					var last_charge_cycle_time = +(moment(item.last_charge_cycle_time).utc().startOf('day').format("X"));
					if (todayTime > hide_plc_fault_date) {
						// if plc_event_time is 1-1-1970 and last_charge_cycle_time != 1-1-1970 save a fault called no_plc_actvity
						if (plc_event_time < 1*24*60*60 && last_charge_cycle_time > 1*24*60*60) {
							item.timespanTextArr = item.timespanTextArr.concat('No PLC Activity');
						} else if (last_charge_cycle_time > todayTime - (1*7*24*60*60) && plc_event_time < todayTime - (1*8*24*60*60)) {
							// ELSE if last_charge_cycle_time is within last 1 week, and plc_event_time is older than 8 days..save a fault called plc_warning
							item.timespanTextArr = item.timespanTextArr.concat('PLC Warning');
						}
					}
				}
				
				item.bvStatus += " bv-thumbnail";
			});
		}
	}

	getBatteriesListing(isRefresh = false) {
		if(!this.batteriesListing || this.batteriesListing.length === 0 || isRefresh) {

			var dataObj = {
				isShared: false,
				getBy: 'getAll'
			};

			this.battviewsDashboardService.getListOfStudies(dataObj).subscribe((res: any) => {
				this.bvMobiles = res.battviewMobile;
				this.studiesByBVMobile = this.commonUtil.arrayToAssociativeArray(res.studies, 'bfdid', true);
				this.renderBattviewMobileListing();
				this.refreshBatteriesList(2 * 60 * 1000); //every 2 mins
			});
		}
	}

	refreshBatteriesList(checkFreq) {	
		this.timeoutID = setTimeout(() => {
			if(this.batteriesListing)
				this.getBatteriesListing(true);
		}, checkFreq);
	}

	highlightInvalidFields(invalidFields){
		this.notificationMessage.setMessage('Invalid input!', 'danger');
		for (let i = 0; i < invalidFields.length; i++) {
			let field = invalidFields[i];
			var ele = this.elementRef.nativeElement.querySelector('[name=' + field + ']');
			if(ele)
				ele.classList.add('invalid-input');
		}
	}
	startNewStudy() {
		let invalidFields = this.battviewService.validateStudy(this.newStudy);
		if(invalidFields.length){
			this.highlightInvalidFields(invalidFields);
			return;
		}
		this.notificationMessage.closeNotification();
		let inputs = this.elementRef.nativeElement.querySelectorAll('input');
		inputs.forEach((select) => {
			this.renderer.removeClass(select, 'invalid-input');
		});

		this.battviewsDashboardService.startNewStudy(this.startNewStudyBVid, this.newStudy).subscribe((response:any) => {
			if(response.httpStatus == 'error') {
				if(response.msg == 'invalidFields') {
					this.highlightInvalidFields(response.invalidFields);
				}
			}
		});
	}

	closeNewStudyBox(open, bvMobileId) {

		if(!open) {
			this.newStudyEnabled = false;
			return;
		}

		this.startNewStudyBVid = bvMobileId;
		this.newStudy = {};
		this.newStudyEnabled = true;
		window.scrollTo(0, 0);
	}

	ngOnDestroy() {
		if(this.timeoutID)
			clearTimeout(this.timeoutID);
	}
}