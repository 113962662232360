import { Directive } from '@angular/core';

@Directive({
  selector: '[appTransclude]'
})
export class TranscludeDirective {

  constructor() { }

}

