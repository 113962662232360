import { Directive, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { UserService } from '../../../auth/user.service';
import { DevicesDashboardService } from '../devices/devices-dashboard.service';

@Directive({
	selector: '[check-permission]'
})
export class CheckPermissionDirective implements OnInit {

	@Input('check-permission') fieldName: string;

	currentUser: any = {};
	userAccess: any = {};

	isBulk: boolean = false;
	deviceType: string = '';

	constructor(
		private el: ElementRef,
		private renderer: Renderer2,
		private devicesDashboard: DevicesDashboardService,
		private userService: UserService
	) {
		this.isBulk = devicesDashboard.isBulkSettings;
		this.deviceType = devicesDashboard.settingsDeviceType;
	}

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();
		this.userAccess = this.currentUser.userAccess;

		let accessObj = Object.assign({}, this.userAccess);

		if(this.isBulk && accessObj[this.deviceType + '_BULK'][this.fieldName])
			accessObj = accessObj[this.deviceType + '_BULK'];

		if(accessObj[this.fieldName] == 'noAccess')
			this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
		else if(accessObj[this.fieldName] == 'readOnly')
			this.renderer.setProperty(this.el.nativeElement, 'disabled', 'disabled');
	}
}
