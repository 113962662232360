import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NotesService {
	constructor(private httpClient: HttpClient) {}

	getSiteNotes(params) {
		return this.httpClient.post('/customers/note/read', {params}, {
			observe: "body"
		});
	}
	updateNoteSharingType(params) {
		return this.httpClient.post('/customers/note/updateSharingType', {params}, {
			observe: "body",
			responseType: "text"
		});
	}
	updateNoteReminderFreq(params) {
		return this.httpClient.post('/customers/note/updateReminderFreq', {params}, {
			observe: "body",
			responseType: "text"
		});
	}
	deleteSiteNote(params) {
		return this.httpClient.post('/customers/note/delete', {params}, {
			observe: "body",
			responseType: "text"
		});
	}
	addSiteNote(params) {
		return this.httpClient.post('/customers/note/add', {params}, {
			observe: "body"
		});
	}
  updateNOCNote(params) {
		return this.httpClient.post('/customers/note/updateNOCNote', {params}, {
			observe: "body"
		});
	}
	getNOCNote(params) {
		return this.httpClient.post('/customers/note/get-noc-status-note', {params}, {
			observe: "body"
		});
	}
	getUserNotificationList(params) {
		return this.httpClient.post('/customers/note/getUserNotificationList', {params}, {
			observe: "body"
		});
	}
}
