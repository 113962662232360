<form #editEquipment="ngForm" class="form-auth" (ngSubmit)="updateEquipment()">
  <div class="container-fluid" id="main">
    <div class="row">
      <div class="col-md-6 col-md-offset-3" id="left">
        <div class="boxs3">
          <h2>{{'site.add_update_equipment_info' | translate}}</h2>
          <div class="panel panel-default">
            <div class="panel-heading">{{'site.equipment_info' | translate}}</div>
          </div>
          <div class="form-field">
            <label for="name">{{'g.name' | translate}}</label>
            <input type="text" required class="form-control" [(ngModel)]="equipment.name" placeholder="{{'g.name' | translate}}" name="Name" />
          </div>
          <br/>
          <div class="form-field">
            <label for="name">{{'g.description' | translate}}</label>
            <input type="text" required class="form-control" [(ngModel)]="equipment.description" placeholder="{{'g.description' | translate}}" name="desc" />
          </div>
          <br/>
          <div class="form-field">
            <label for="name">{{'device.parent_devices' | translate}}</label>
            <ng-select [items]="equipmentsParents" bindLabel="name" bindValue="id" multiple="true" placeholder="{{'device.select_parent' | translate}}" name="parentdeviceid" [(ngModel)]="equipment.parent_device_id">
            </ng-select>
          </div>
          <br/>
          <div class="form-field">
            <label for="name">{{'device.linked_devices' | translate}}</label>
            <ng-select [items]="linkedToTypes" bindLabel="name" bindValue="id" multiple="true" placeholder="{{'device.select_linked_device' | translate}}" name="linked" [(ngModel)]="equipment.link_to">
            </ng-select>
          </div>
          <br/>
          <button type="submit" class="btn btn-primary" [disabled]="editEquipment.invalid">{{'g.submit' | translate}}</button>
          <button type="button" class="btn btn-default" (click)="navigation.goBack(['equipments'])">{{'g.cancel' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</form>