<app-site-widget
    [title]="widgetName"
    elementId="missed_connection"
    chartType="column"
    widgetClass="height-600"
    [siteWidgetOptions]="{hasDate: true, isSitePerformanceWidget: true, isMaterialChart: true, countOfMaterialColumns: 4}"
    [widgetOptions]="{hasExport: true}"
    (eventEmit)="eventEmit($event)"
    [data]="data"
    [config]="config"
    [tableColumns]="tableColumns"
    [siteId]="siteId"
    [customerId]="customerId"
    [customerName]="customerName"
    [siteName]="siteName"
></app-site-widget>