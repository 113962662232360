<app-modal #saveOptionsModal title="Save BATTView Settings" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="margin-bottom-sm">Do you want to apply changes immediately?</div>
	<div class="modal-footer padding-8">
		<button class="btn btn-primary" (click)="userSaveOption('direct');saveOptionsModal.hide()">OK</button>
		<button class="btn btn-warning" (click)="userSaveOption();saveOptionsModal.hide()">Queue It</button>
		<button class="btn btn-default" (click)="saveOptionsModal.hide()">Cancel</button>
	</div>
</app-modal>

<app-modal #chargerProfileModal title="Save BATTView Settings" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="margin-bottom-sm">Charge profile & EQ, FI scheduling will be updated based on profile selected!</div>
	<div class="modal-footer padding-8">
		<button class="btn btn-primary" (click)="saveTruckviewSettings();chargerProfileModal.hide()">OK</button>
		<button class="btn btn-default" (click)="chargerProfileModal.hide()">Cancel</button>
	</div>
</app-modal>

<div *ngIf="isBulk">
	<div class="row" *ngFor="let aTruckviewInfo of originalTruckviewsInfo; let idx = index" [ngSwitch]="idx % 4">
		<div class="col-xs-3" *ngSwitchCase="0">
			<div class="boxs3 tcenter padding8" *ngIf="originalTruckviewsInfo[idx]">
				{{originalTruckviewsInfo[idx].truckid}}<br/>({{originalTruckviewsInfo[idx].serialnumber}})
			</div>
		</div>
		<div class="col-xs-3" *ngSwitchCase="0">
			<div class="boxs3 tcenter padding8" *ngIf="originalTruckviewsInfo[idx+1]">
				{{originalTruckviewsInfo[idx+1].truckid}}<br/>({{originalTruckviewsInfo[idx+1].serialnumber}})
			</div>
		</div>
		<div class="col-xs-3" *ngSwitchCase="0">
			<div class="boxs3 tcenter padding8" *ngIf="originalTruckviewsInfo[idx+2]">
				{{originalTruckviewsInfo[idx+2].truckid}}<br/>({{originalTruckviewsInfo[idx+2].serialnumber}})
			</div>
		</div>
		<div class="col-xs-3" *ngSwitchCase="0">
			<div class="boxs3 tcenter padding8" *ngIf="originalTruckviewsInfo[idx+3]">
				{{originalTruckviewsInfo[idx+3].truckid}}<br/>({{originalTruckviewsInfo[idx+3].serialnumber}})
			</div>
		</div>
	</div>
</div>

<div *ngIf="userAccess && hasTruckviews">
	<div *ngIf="isSet('CARDS')">
		<div class="row col-md-offset-0">
			<div style="background-image: url(./images/Batt_BattViewInfoButton.bmp);" check-permission="BATTVIEW-INFO" (click)="setTab('BATTVIEW-INFO')" class="cards boxs3 col-md-2"></div>
			<div class="col-md-1" check-permission="BATTVIEW-INFO"></div>

			<div style="background-image: url(./images/Batt_WIFISettingsButton.bmp);" check-permission="BATTVIEW-WIFI" (click)="setTab('BATTVIEW-WIFI')" class="cards boxs3 col-md-2"></div>
			<div class="col-md-1" check-permission="BATTVIEW-WIFI"></div>

			<div style="background-image: url(./images/Batt_TruckInformationButton.bmp);" check-permission="BATTVIEW-BATTERY-INFO" (click)="setTab('BATTVIEW-BATTERY-INFO')" class="cards boxs3 col-md-2"></div>
			<div class="col-md-1" check-permission="BATTVIEW-BATTERY-INFO"></div>

			<div style="background-image: url(./images/Batt_BattViewSettingsButton.bmp);" check-permission="BATTVIEW-SETTINGS" (click)="setTab('BATTVIEW-SETTINGS')" class="cards boxs3 col-md-2"></div>
		</div>
	</div>

	<div check-permission="BATTVIEW-INFO" *ngIf="isSet('BATTVIEW-INFO')">
		<div class="panel panel-default">
			<div class="panel-body">
				<form name="truckviewInfo">
					<div class="row">
				 		<div class="panel-body">
							<table class="cellpadding-10 col-xs-5 l">
								<tr check-permission="Batt_SN">
									<td class="col-md-6">Serial Number</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.serialnumber}" [pTooltip]="getStackHTML('serialnumber')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right"><input check-permission="Batt_SN" type="text" class="form-control" name="serialnumber" [(ngModel)]="truckview.serialnumber"/>
									</td>
								</tr>
								<tr check-permission="Batt_HWRevision">
									<td class="col-md-6">Hardware Revision</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.hwversion}" [pTooltip]="getStackHTML('hwversion')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right"><input check-permission="Batt_HWRevision" type="text" class="form-control" name="hwversion" [(ngModel)]="truckview.hwversion"/></td>
								</tr>
								<tr *ngIf="!isBulk">
									<td class="col-md-6">Firmware Version</td>
									<td><input [disabled]="true" type="text" class="form-control" name="firmwareversion" [(ngModel)]="truckview.firmwareversion"/>
									</td>
								</tr>
								<tr check-permission="Batt_memorySignature">
									<td class="col-md-6">Memory Signature</td>
									<td><input check-permission="Batt_memorySignature" type="text" class="form-control" name="memorysignature" [(ngModel)]="truckview.memorysignature" />
									</td>
								</tr>
								<tr check-permission="Batt_onlyForEnginneringTeam" *ngIf="truckview.esp32_wifi_version >= 1">
									<td class="col-md-6">WiFi FW Version</td>
									<td><input [disabled]="true" type="text" class="form-control" name="esp32_wifi_version" [(ngModel)]="truckview.esp32_wifi_version" />
									</td>
								</tr>
								<tr check-permission="Batt_setup_version">
									<td class="col-md-6">Last Change User ID</td>
									<td><input check-permission="Batt_setup_version" type="text" class="form-control" name="lastchangeuserid" [(ngModel)]="truckview.lastchangeuserid" />
									</td>
								</tr>
								<tr>
									<td class="col-md-6" check-permission="SAVE-BATTVIEW-INFO"><span class="save-btn" (click)="checkChanges('truckviewInfo')">Save</span></td>
									<td><span class="back-btn" (click)="setTab('CARDS'); resetForms()">Back</span></td>
								</tr>
							</table>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div check-permission="BATTVIEW-SETTINGS" *ngIf="isSet('BATTVIEW-SETTINGS')">
		<div class="panel panel-default">
			<div class="panel-body">
				<form name="truckviewSettings">
					<div class="row">
						<div class="panel-body">
							<table class="cellpadding-10 col-md-8 l">
								<tr check-permission="Batt_RTSampleTime">
									<td class="col-md-4">Samples Time Span</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.autologtime}" [pTooltip]="getStackHTML('autologtime')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<span class="col-md-12"><input check-permission="Batt_RTSampleTime" type="text" class="form-control" name="autologtime" [(ngModel)]="truckview.autologtime"/></span>
									</td>
								</tr>
								<tr check-permission="Batt_setPA">
									<td class="col-md-4">BATTView Mobile</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.ispa}" [pTooltip]="getStackHTML('ispa')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<label class="col-md-6"><input check-permission="Batt_setPA" type="radio" name="ispa" [(ngModel)]="truckview.ispa" class="form-control" [value]="true"/> Yes</label>
										<label><input check-permission="Batt_setPA" type="radio" name="ispa" [(ngModel)]="truckview.ispa" class="form-control" [value]="false"/> No</label>
									</td>
								</tr>
								<tr check-permission="Batt_enablePostSensor">
									<td class="col-md-4"><span>Post/Electrolyte NTC</span></td>
									<td [ngClass]="{'dirtySettingsField': changesStack.enableexttempsensing}" [pTooltip]="getStackHTML('enableexttempsensing')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<label class="col-md-6"><input check-permission="Batt_enablePostSensor" type="radio" name="enableexttempsensing" [(ngModel)]="truckview.enableexttempsensing" class="form-control" [value]="true"/> Enable</label>
										<label><input check-permission="Batt_enablePostSensor" type="radio" name="enableexttempsensing" [(ngModel)]="truckview.enableexttempsensing" class="form-control" [value]="false"/> Disable</label>
									</td>
								</tr>
								<tr check-permission="Batt_enablePostSensor">
									<td class="col-md-4"><span>External NTC</span></td>
									<td [ngClass]="{'dirtySettingsField': changesStack.disableintercell}" [pTooltip]="getStackHTML('disableintercell')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<label class="col-md-6"><input check-permission="Batt_enablePostSensor" type="radio" name="disableintercell" [(ngModel)]="truckview.disableintercell" class="form-control" [value]="false"/> Enable</label>
										<label><input check-permission="Batt_enablePostSensor" type="radio" name="disableintercell" [(ngModel)]="truckview.disableintercell" class="form-control" [value]="true"/> Disable</label>
									</td>
								</tr>
								<tr check-permission="Batt_EnableEL">
									<td class="col-md-4"><span>Electrolyte sensing</span></td>
									<td [ngClass]="{'dirtySettingsField': changesStack.enableelectrolyesensing}" [pTooltip]="getStackHTML('enableelectrolyesensing')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<label class="col-md-6"><input check-permission="Batt_EnableEL" type="radio" name="enableelectrolyesensing" [(ngModel)]="truckview.enableelectrolyesensing" class="form-control" [value]="true"/> Enable</label>
										<label><input check-permission="Batt_EnableEL" type="radio" name="enableelectrolyesensing" [(ngModel)]="truckview.enableelectrolyesensing" class="form-control" [value]="false"/> Disable</label>
									</td>
								</tr>
								<tr check-permission="Batt_setHallEffect">
									<td class="col-md-4">Use hall effect sensor</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.enablehalleffectsensing}" [pTooltip]="getStackHTML('enablehalleffectsensing')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<label class="col-md-6"><input check-permission="Batt_setHallEffect" type="radio" name="enablehalleffectsensing" [(ngModel)]="truckview.enablehalleffectsensing" class="form-control" [value]="true"/> Yes</label>
										<label><input check-permission="Batt_setHallEffect" type="radio" name="enablehalleffectsensing" [(ngModel)]="truckview.enablehalleffectsensing" class="form-control" [value]="false"/> No</label>
									</td>
								</tr>
								<tr check-permission="Batt_setHallEffect" *ngIf="truckview.enablehalleffectsensing">
									<td class="col-md-4">Hall Effect Reverse</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.halleffectreverse}" [pTooltip]="getStackHTML('halleffectreverse')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<label class="col-md-6"><input check-permission="Batt_setHallEffect" type="radio" name="halleffectreverse" [(ngModel)]="truckview.halleffectreverse" class="form-control" [value]="true"/> Yes</label>
										<label><input check-permission="Batt_setHallEffect" type="radio" name="halleffectreverse" [(ngModel)]="truckview.halleffectreverse" class="form-control" [value]="false"/> No</label>
									</td>
								</tr>
								<tr check-permission="Batt_enablePLC">
									<td class="col-md-4">Enable PLC</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.enableplc}" [pTooltip]="getStackHTML('enableplc')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<label class="col-md-6"><input check-permission="Batt_enablePLC" type="radio" name="enableplc" [(ngModel)]="truckview.enableplc" class="form-control" [value]="true"/> Enable</label>
										<label><input check-permission="Batt_enablePLC" type="radio" name="enableplc" [(ngModel)]="truckview.enableplc" class="form-control" [value]="false"/> Disable</label>
									</td>
								</tr>
								<tr check-permission="Batt_TemperatureFormat">
									<td class="col-md-4">Temperature Format</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.temperatureformat}" [pTooltip]="getStackHTML('temperatureformat')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<label class="col-md-6"><input check-permission="Batt_TemperatureFormat" type="radio" name="temperatureformat" [(ngModel)]="truckview.temperatureformat" class="form-control" [value]="1"/> Fahrenheit</label>
										<label><input check-permission="Batt_TemperatureFormat" type="radio" name="temperatureformat" [(ngModel)]="truckview.temperatureformat" class="form-control" [value]="0"/> Celsius</label>
									</td>
								</tr>
								<tr check-permission="Batt_dayLightSaving_Enable">
									<td class="col-md-4">Daylight Saving</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.enabledaylightsaving}" [pTooltip]="getStackHTML('enabledaylightsaving')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<label class="col-md-6"><input check-permission="Batt_dayLightSaving_Enable" type="radio" name="enabledaylightsaving" [(ngModel)]="truckview.enabledaylightsaving" class="form-control" [value]="true"/> Enable</label>
										<label><input check-permission="Batt_dayLightSaving_Enable" type="radio" name="enabledaylightsaving" [(ngModel)]="truckview.enabledaylightsaving" class="form-control" [value]="false"/> Disable</label>
									</td>
								</tr>
								<tr *ngIf="truckview.firmwareversion >= 2.2 && truckview.enablehalleffectsensing">
									<td class="col-md-4">Number of Cables</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.halleffectscale}" [pTooltip]="getStackHTML('halleffectscale')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<label class="col-md-6"><input type="radio" name="halleffectscale" [(ngModel)]="truckview.halleffectscale" class="form-control" [value]="1"/> Single</label>
										<label><input type="radio" name="halleffectscale" [(ngModel)]="truckview.halleffectscale" class="form-control" [value]="2"/> Dual</label>
									</td>
								</tr>
								<tr check-permission="Batt_onlyForEnginneringTeam">
									<td class="col-md-4">Temperature Fallback control</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.temperaturecontrol}" [pTooltip]="getStackHTML('temperaturecontrol')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<label class="col-md-12" *ngFor="let opt of TemperatureFallbackOpts" >
											<input check-permission="Batt_dayLightSaving_Enable" type="radio" name="temperaturecontrol" [(ngModel)]="truckview.temperaturecontrol" class="form-control" [value]="opt.id"/> {{opt.text}}
										</label>
									</td>
								</tr>
								<tr>
									<td class="col-md-4" check-permission="SAVE-BATTVIEW-SETTINGS"><span class="save-btn" (click)="checkChanges('truckviewSettings')">Save</span></td>
									<td><span class="back-btn" (click)="setTab('CARDS'); resetForms()">Back</span></td>
								</tr>
							</table>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div check-permission="BATTVIEW-WIFI" *ngIf="isSet('BATTVIEW-WIFI')">
		<div class="panel panel-default">
			<div class="panel-body">
				<form name="wifi">
					<div class="row">
						<div class="panel-body">
							<table class="cellpadding-10 col-xs-5 l">
								<tr check-permission="Batt_mobileAccessSSID">
									<td class="col-md-6">Mobile SSID</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.mobileaccessssid}" [pTooltip]="getStackHTML('mobileaccessssid')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<input check-permission="Batt_mobileAccessSSID" type="text" class="form-control" name="mobileaccessssid" [(ngModel)]="truckview.mobileaccessssid"/>
									</td>
								</tr>
								<tr check-permission="Batt_mobileAccessSSIDpassword">
									<td class="col-md-6">Mobile SSID Password</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.mobileaccessssidpassword}" [pTooltip]="getStackHTML('mobileaccessssidpassword')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<input check-permission="Batt_mobileAccessSSIDpassword" type="text" class="form-control" name="mobileaccessssidpassword" [(ngModel)]="truckview.mobileaccessssidpassword"/>
									</td>
								</tr>
								<tr check-permission="Batt_mobilePort">
									<td class="col-md-6">Mobile SSID Port</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.mobileport}" [pTooltip]="getStackHTML('mobileport')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<input check-permission="Batt_mobilePort" type="text" class="form-control" name="mobileport" [(ngModel)]="truckview.mobileport"/>
									</td>
								</tr>
								<tr check-permission="Batt_actViewEnabled">
									<td class="col-md-6">ACT intelligent</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.actviewenabled}" [pTooltip]="getStackHTML('actviewenabled')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<label><input check-permission="Batt_actViewEnabled" type="radio" name="actviewenabled" [(ngModel)]="truckview.actviewenabled" class="form-control" [value]="true"/> Enable</label>
										<label><input check-permission="Batt_actViewEnabled" type="radio" name="actviewenabled" [(ngModel)]="truckview.actviewenabled" class="form-control" [value]="false"/> Disable</label>
									</td>
								</tr>
								<tr check-permission="Batt_actViewIP">
									<td class="col-md-6">ACTView IP</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.actviewip}" [pTooltip]="getStackHTML('actviewip')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<input check-permission="Batt_actViewIP" type="text" class="form-control" name="actviewip" [(ngModel)]="truckview.actviewip" [disabled]="!truckview.actviewenabled"/>
									</td>
								</tr>
								<tr check-permission="Batt_actViewPort">
									<td class="col-md-6">ACTView Port</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.actviewport}" [pTooltip]="getStackHTML('actviewport')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<input check-permission="Batt_actViewPort" type="text" class="form-control" name="actviewport" [(ngModel)]="truckview.actviewport" [disabled]="!truckview.actviewenabled"/>
									</td>
								</tr>
								<tr check-permission="Batt_actViewConnectFrequency">
									<td class="col-md-6">ACTView Connect Frequency</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.actviewconnectfrequency}" [pTooltip]="getStackHTML('actviewconnectfrequency')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<input check-permission="Batt_actViewConnectFrequency" type="text" class="form-control" name="actviewconnectfrequency" [(ngModel)]="truckview.actviewconnectfrequency" [disabled]="!truckview.actviewenabled"/>
									</td>
								</tr>
								<tr check-permission="Batt_actAccessSSID">
									<td class="col-md-6">ACTaccess SSID</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.actaccessssid}" [pTooltip]="getStackHTML('actaccessssid')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<input check-permission="Batt_actAccessSSID" type="text" class="form-control" name="actaccessssid" [(ngModel)]="truckview.actaccessssid" [disabled]="!truckview.actviewenabled"/>
									</td>
								</tr>
								<tr check-permission="Batt_actAccessSSIDpassword">
									<td class="col-md-6">ACTaccess SSID Password</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.actaccessssidpassword}" [pTooltip]="getStackHTML('actaccessssidpassword')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<input check-permission="Batt_actAccessSSIDpassword" type="text" class="form-control" name="actaccessssidpassword" [(ngModel)]="truckview.actaccessssidpassword" [disabled]="!truckview.actviewenabled"/>
									</td>
								</tr>
								<tr *ngIf="currentUser.isACTuser && !isBulk && truckview.firmwareversion >= 2.2">
									<td colspan="2">
										<fieldset>
											<legend>WiFi Info</legend>
											<table style="width:100%">
												<tr>
													<td class="col-md-6">AT Version</td>
													<td>
														{{truckview.wifi_at_version}}
													</td>
												</tr>
												<tr>
													<td class="col-md-6">Local IP Address</td>
													<td>
														{{truckview.wifi_local_ip}}
													</td>
												</tr>
												<tr>
													<td class="col-md-6">Gateway</td>
													<td>
														{{truckview.wifi_gateway ? truckview.wifi_gateway : 'Unsupported'}}
													</td>
												</tr>
												<tr>
													<td class="col-md-6">MAC Address</td>
													<td>
														{{truckview.wifi_mac_address ? truckview.wifi_mac_address : 'Unsupported'}}
													</td>
												</tr>
											</table>
										</fieldset>
									</td>
								</tr>
								<tr>
									<td class="col-md-6" check-permission="SAVE-BATTVIEW-WIFI"><span class="save-btn" (click)="checkChanges('wifi')">Save</span></td>
									<td><span class="back-btn" (click)="setTab('CARDS'); resetForms()">Back</span></td>
								</tr>
							</table>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div check-permission="BATTVIEW-BATTERY-INFO" *ngIf="isSet('BATTVIEW-BATTERY-INFO')">
		<div check-permission="BATTVIEW-BATTERY-SETTINGS" (click)="setTab('BATTVIEW-BATTERY-SETTINGS')" class="sub-cards boxs3 col-md-4 col-md-offset-4">View Truck Settings</div>
		<div check-permission="BATTVIEW-DEFAULT-CHARGE-PROFILE" (click)="setTab('BATTVIEW-DEFAULT-CHARGE-PROFILE')" class="sub-cards boxs3 col-md-4 col-md-offset-4" *ngIf="!isTruckviewMobile">View Default Charge Profile</div>
		<div check-permission="BATTVIEW-FI-EQ-SCHEDULING" (click)="setTab('BATTVIEW-FI-EQ-SCHEDULING')" class="sub-cards boxs3 col-md-4 col-md-offset-4" *ngIf="!isTruckviewMobile">View Finish and EQ Scheduling</div>
		<div (click)="setTab('CARDS')" class="back-btn-card boxs3 col-md-4 col-md-offset-4">Back</div>
	</div>

	<div check-permission="BATTVIEW-BATTERY-SETTINGS" *ngIf="isSet('BATTVIEW-BATTERY-SETTINGS')">
		<div class="panel panel-default">
			<div class="panel-body">
				<form name="truckSettings">
					<div class="row">
						<div class="panel-body">
							<table class="cellpadding-10 col-xs-6 l">
								<tr check-permission="Batt_truckID">
									<td class="col-md-6">Truck ID</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.truckid}" [pTooltip]="getStackHTML('truckid')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<input check-permission="Batt_truckID" type="text" class="form-control" name="truckid" [(ngModel)]="truckview.truckid"/>
									</td>
								</tr>
								<tr check-permission="Batt_truckModelandSN" *ngIf="!isBulk">
									<td class="col-md-6">Truck Serial Number</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.trucksn}" [pTooltip]="getStackHTML('trucksn')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<input check-permission="Batt_truckModelandSN" type="text" class="form-control" name="trucksn" [(ngModel)]="truckview.trucksn"/>
									</td>
								</tr>
								<tr check-permission="Batt_truckModelandSN" *ngIf="!isBulk">
									<td class="col-md-6"><span *ngIf="!isTruckviewMobile">Truck Model</span><span *ngIf="isTruckviewMobile">Truck Type</span></td>
									<td [ngClass]="{'dirtySettingsField': changesStack.truckmodel}" [pTooltip]="getStackHTML('truckmodel')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<input check-permission="Batt_truckModelandSN" type="text" class="form-control" name="truckmodel" [(ngModel)]="truckview.truckmodel"/>
									</td>
								</tr>
								<tr check-permission="Batt_truckModelandSN" *ngIf="!isBulk">
									<td class="col-md-6">Truck ID</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.truckid}" [pTooltip]="getStackHTML('truckid')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<input check-permission="Batt_truckModelandSN" type="text" class="form-control" name="truckid" [(ngModel)]="truckview.truckid"/>
									</td>
								</tr>
								<tr check-permission="Batt_InstallationDate">
									<td class="col-md-6">Installation Date</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.installationdate}" [pTooltip]="getStackHTML('installationdate')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<div class="input-group">
											<input check-permission="Batt_InstallationDate" class="form-control" placeholder="mm/dd/yyyy" name="installationdate" [(ngModel)]="truckview.installationdate" bsDatepicker #installationdate="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
											<span class="input-group-btn">
												<button check-permission="Batt_InstallationDate" class="btn btn-default btn-date" (click)="installationdate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
											</span>
										</div>
									</td>
								</tr>
								<tr check-permission="Batt_InstallationDate" *ngIf="!isBulk">
									<td rowspan="2" class="col-md-6">Truck Manufacturing Date</td>
									<td class="col-md-6" style="padding-bottom:0px; padding-left: 8px" [ngClass]="{'dirtySettingsField': changesStack.truckmanfacturingdate}" [pTooltip]="getStackHTML('truckmanfacturingdate')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<label>
											<span class="col-md-12">Same as Installation Date:</span>
											<label class="col-md-6"><input check-permission="Batt_InstallationDate" type="radio" (click)="setManufacturingDate()" name="manufacturingdatesameasinstallation" [(ngModel)]="truckview.manufacturingdatesameasinstallation" class="form-control" [value]="true"/> Yes</label>
											<label><input check-permission="Batt_InstallationDate" type="radio" (click)="setManufacturingDate()" name="manufacturingdatesameasinstallation" [(ngModel)]="truckview.manufacturingdatesameasinstallation" class="form-control" [value]="false"/> No</label>
										</label>
									</td>
								</tr>
								<tr check-permission="Batt_InstallationDate" *ngIf="!isBulk">
									<td style="padding-top:0px">
										<div class="input-group">
											<input check-permission="Batt_InstallationDate" class="form-control" placeholder="mm/dd/yyyy" name="manufacturingdateview" [(ngModel)]="truckview.manufacturingdateview" bsDatepicker #manufacturingdateview="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" [disabled]="truckview.manufacturingdatesameasinstallation"/>
											<span class="input-group-btn">
												<button check-permission="Batt_InstallationDate" [disabled]="truckview.manufacturingdatesameasinstallation" class="btn btn-default btn-date" (click)="manufacturingdateview.toggle()" type="button"><i class="fa fa-calendar"></i></button>
											</span>
										</div>
									</td>
								</tr>
								<tr check-permission="Batt_TimeZone">
									<td class="col-md-6">Time Zone</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.zoneid}" [pTooltip]="getStackHTML('zoneid')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<select check-permission="Batt_TimeZone" id="timezone" class="form-control" name="zoneid" [(ngModel)]="truckview.zoneid">
											<option *ngFor="let zone of commonData.TimeZonesMenu" [ngValue]="zone.id">{{zone.display_name}}</option>
										</select>
									</td>
								</tr>
								<tr check-permission="Batt_TruckCapacity">
									<td class="col-md-6">Truck Capacity</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.ahrcapacity}" [pTooltip]="getStackHTML('ahrcapacity')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<div class='input-group'>
											<input check-permission="Batt_TruckCapacity" type="number" class="form-control" name="ahrcapacity" [(ngModel)]="truckview.ahrcapacity" (ngModelChange)="updateWarrantedAhr()"/>
											<span class="input-group-addon"><span>AHRs</span></span>
										</div>
									</td>
								</tr>
								<tr check-permission="Batt_TruckVoltage">
									<td class="col-md-6">Truck Voltage</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.nominalvoltage}" [pTooltip]="getStackHTML('nominalvoltage')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<select check-permission="Batt_TruckVoltage" name="nominalvoltage" [(ngModel)]="truckview.nominalvoltage" class="form-control">
											<option *ngFor="let voltage of truckVoltages" [ngValue]="voltage">{{voltage}}</option>
										</select>
									</td>
								</tr>
								<tr check-permission="Batt_temperatureCompensation">
									<td class="col-md-6">Temperature Compensation</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.trucktemperaturecompesnation}" [pTooltip]="getStackHTML('trucktemperaturecompesnation')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<div class='input-group'>
											<input check-permission="Batt_temperatureCompensation" type="text" class="form-control" name="trucktemperaturecompesnation" [(ngModel)]="truckview.trucktemperaturecompesnation"/>
											<span class="input-group-addon"><span>mVPC/C</span></span>
										</div>
									</td>
								</tr>
								<tr check-permission="Batt_TempertureHigh">
									<td class="col-md-6">Max Truck Temperature</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.truckhightemperature}" [pTooltip]="getStackHTML('truckhightemperature')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<div class='input-group'>
											<input check-permission="Batt_TempertureHigh" type="text" class="form-control" name="truckhightemperature" [(ngModel)]="truckview.truckhightemperature"/>
											<span class="input-group-addon"><span>&deg;{{formatTemperature}}</span></span>
										</div>
									</td>
								</tr>
								<tr check-permission="Batt_TruckType">
									<td class="col-md-6">Truck Type</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.trucktype}" [pTooltip]="getStackHTML('trucktype')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<select check-permission="Batt_TruckType" name="trucktype" [(ngModel)]="truckview.trucktype" class="form-control">
											<option *ngFor="let type of truckTypes" [ngValue]="type.id">{{type.text}}</option>
										</select>
									</td>
								</tr>
								<tr check-permission="Batt_OverrideWarrantedAHR">
									<td class="col-md-6">Warranted AHR</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.warrantedahr}" [pTooltip]="getStackHTML('warrantedahr')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<input check-permission="Batt_OverrideWarrantedAHR" type="text" class="form-control" name="warrantedahr" [(ngModel)]="truckview.warrantedahr"/>
									</td>
								</tr>
								<tr check-permission="Batt_TempertureHigh">
									<td class="col-md-6">Min Cold Charger Temperature</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.trtemperature}" [pTooltip]="getStackHTML('trtemperature')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<div class='input-group'>
											<input check-permission="Batt_TempertureHigh" type="text" class="form-control" name="trtemperature" [(ngModel)]="truckview.trtemperature"/>
											<span class="input-group-addon"><span>&deg;{{formatTemperature}}</span></span>
										</div>
									</td>
								</tr>
								<tr check-permission="Batt_TempertureHigh">
									<td class="col-md-6">Foldback Temperature</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.foldtemperature}" [pTooltip]="getStackHTML('foldtemperature')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<div class='input-group'>
											<input check-permission="Batt_TempertureHigh" type="text" class="form-control" name="foldtemperature" [(ngModel)]="truckview.foldtemperature"/>
											<span class="input-group-addon"><span>&deg;{{formatTemperature}}</span></span>
										</div>
									</td>
								</tr>
								<tr check-permission="Batt_TempertureHigh">
									<td class="col-md-6">Cool Down Temperature</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.cooldowntemperature}" [pTooltip]="getStackHTML('cooldowntemperature')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<div class='input-group'>
											<input check-permission="Batt_TempertureHigh" type="text" class="form-control" name="cooldowntemperature" [(ngModel)]="truckview.cooldowntemperature"/>
											<span class="input-group-addon"><span>&deg;{{formatTemperature}}</span></span>
										</div>
									</td>
								</tr>
								<tr check-permission="Batt_chargerType">
									<td class="col-md-6">Application Type</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.chargertype}" [pTooltip]="getStackHTML('chargertype')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
										<select check-permission="Batt_chargerType" name="chargertype" [(ngModel)]="truckview.chargertype" class="form-control">
											<option *ngFor="let type of chargerTypes" [ngValue]="type.id">{{type.text}}</option>
										</select>
									</td>
								</tr>
								<tr>
									<td class="col-md-6" check-permission="SAVE-BATTVIEW-BATTERY-SETTINGS"><span class="save-btn" (click)="checkChanges('truckSettings')">Save</span></td>
									<td><span class="back-btn" (click)="setTab('BATTVIEW-BATTERY-INFO'); resetForms()">Back</span></td>
								</tr>
							</table>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div check-permission="BATTVIEW-DEFAULT-CHARGE-PROFILE" *ngIf="isSet('BATTVIEW-DEFAULT-CHARGE-PROFILE')">
		<div class="panel panel-default">
			<div class="panel-body">
				<form name="defaultChargeProfile">
					<div class="row">
						<div class="panel-body">
							<table class="cellpadding-10 col-xs-5 l">
							<tr check-permission="Batt_TR_CurrentRate">
								<td class="col-md-6">TR Current Rate (%Capacity) - TR Amps</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.tricklecurrentrate}" [pTooltip]="getStackHTML('tricklecurrentrate')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
									<select check-permission="Batt_TR_CurrentRate" name="tricklecurrentrate" [(ngModel)]="truckview.tricklecurrentrate" class="form-control">
										<option *ngFor="let trcurrent of TRCurrentRates" [ngValue]="trcurrent">{{trcurrent}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="Batt_CC_CurrentRate">
								<td class="col-md-6">CC Current Rate (%Capacity) - CC Amps</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.ccrate}" [pTooltip]="getStackHTML('ccrate')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
									<select check-permission="Batt_CC_CurrentRate" name="ccrate" [(ngModel)]="truckview.ccrate" class="form-control">
										<option *ngFor="let cccurrent of CCCurrentRates" [ngValue]="cccurrent">{{cccurrent}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="Batt_FI_CurrentRate">
								<td class="col-md-6">FI Current Rate (%Capacity) - FI Amps</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.ficurrentrate}" [pTooltip]="getStackHTML('ficurrentrate')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
									<select check-permission="Batt_FI_CurrentRate" name="ficurrentrate" [(ngModel)]="truckview.ficurrentrate" class="form-control">
										<option *ngFor="let ficurrent of FICurrentRates" [ngValue]="ficurrent">{{ficurrent}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="Batt_EQ_CurrentRate">
								<td class="col-md-6">EQ Current Rate (%Capacity) - EQ Amps</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.eqcurrentrate}" [pTooltip]="getStackHTML('eqcurrentrate')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
									<select check-permission="Batt_EQ_CurrentRate" name="eqcurrentrate" [(ngModel)]="truckview.eqcurrentrate" class="form-control">
										<option *ngFor="let eqcurrent of EQCurrentRates" [ngValue]="eqcurrent">{{eqcurrent}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="Batt_TrickleVoltage">
								<td class="col-md-6">TR Target Voltage</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.tricklevoltage}" [pTooltip]="getStackHTML('tricklevoltage')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
									<input check-permission="Batt_TrickleVoltage" type="text" class="form-control" name="tricklevoltage" [(ngModel)]="truckview.tricklevoltage"/>
								</td>
							</tr>
							<tr check-permission="Batt_CVVoltage">
								<td class="col-md-6">CV Target Voltage</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.cvtargetvoltage}" [pTooltip]="getStackHTML('cvtargetvoltage')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
									<input check-permission="Batt_CVVoltage" type="text" class="form-control" name="cvtargetvoltage" [(ngModel)]="truckview.cvtargetvoltage"/>
								</td>
							</tr>
							<tr check-permission="Batt_finishVoltage">
								<td class="col-md-6">FI Target Voltage</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.fitargetvoltage}" [pTooltip]="getStackHTML('fitargetvoltage')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
									<input check-permission="Batt_finishVoltage" type="text" class="form-control" name="fitargetvoltage" [(ngModel)]="truckview.fitargetvoltage"/>
								</td>
							</tr>
							<tr check-permission="Batt_EqualaizeVoltage">
								<td class="col-md-6">EQ Target Voltage</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.eqvoltage}" [pTooltip]="getStackHTML('eqvoltage')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
									<input check-permission="Batt_EqualaizeVoltage" type="text" class="form-control" name="eqvoltage" [(ngModel)]="truckview.eqvoltage"/>
								</td>
							</tr>
							<tr check-permission="Batt_cvFinishCurrent">
								<td class="col-md-6">Partial Charge Stop Current (%Capacity)</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.cvendcurrentrate}" [pTooltip]="getStackHTML('cvendcurrentrate')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
									<select check-permission="Batt_cvFinishCurrent" name="cvendcurrentrate" [(ngModel)]="truckview.cvendcurrentrate" class="form-control">
										<option *ngFor="let current of cvFinishCurrents" [ngValue]="current">{{current}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="Batt_cvCurrentStep">
								<td class="col-md-6">CV Current Step (%CC)</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.cvcurrentstep}" [pTooltip]="getStackHTML('cvcurrentstep')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
									<select check-permission="Batt_cvCurrentStep" name="cvcurrentstep" [(ngModel)]="truckview.cvcurrentstep" class="form-control">
										<option *ngFor="let step of cvCurrentSteps" [ngValue]="step.id">{{step.name}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="Batt_CVMaxTimer">
								<td class="col-md-6">CV Timeout</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.cvmaxduration}" [pTooltip]="getStackHTML('cvmaxduration')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
									<select check-permission="Batt_CVMaxTimer" name="cvmaxduration" [(ngModel)]="truckview.cvmaxduration" class="form-control">
										<option *ngFor="let period of hours_1_5" [ngValue]="period">{{period}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="Batt_finishTimer">
								<td class="col-md-6">Finish Timeout</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.fiduration}" [pTooltip]="getStackHTML('fiduration')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
									<select check-permission="Batt_finishTimer" name="fiduration" [(ngModel)]="truckview.fiduration" class="form-control">
										<option *ngFor="let finishtime of quarts_1_6" [ngValue]="finishtime">{{finishtime}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="Batt_EqualizeTimer">
								<td class="col-md-6">Equalize Timeout</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.eqduration}" [pTooltip]="getStackHTML('eqduration')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
									<select check-permission="Batt_EqualizeTimer" name="eqduration" [(ngModel)]="truckview.eqduration" class="form-control">
										<option *ngFor="let eqtime of quarts_2_6" [value]="eqtime">{{eqtime}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="Batt_desulfationTimer">
								<td class="col-md-6">Desulfation Duration</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.desulfation}" [pTooltip]="getStackHTML('desulfation')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
									<select check-permission="Batt_desulfationTimer" name="desulfation" [(ngModel)]="truckview.desulfation" class="form-control">
										<option *ngFor="let hour of quarts_6_18" [value]="hour">{{hour}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="Batt_finishdVdT">
								<td class="col-md-6">Finish dV Voltage</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.fidv}" [pTooltip]="getStackHTML('fidv')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
									<div class='input-group'>
										<select check-permission="Batt_finishdVdT" name="fidv" [(ngModel)]="truckview.fidv" class="form-control">
											<option *ngFor="let dvvoltage of finishDvVoltages" [ngValue]="dvvoltage">{{dvvoltage}}</option>
										</select>
										<span class="input-group-addon"><span>mVPC</span></span>
									</div>
								</td>
							</tr>
							<tr check-permission="Batt_finishdVdT">
								<td class="col-md-6">Finish dT Time</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.fidt}" [pTooltip]="getStackHTML('fidt')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">
									<div class='input-group'>
										<select check-permission="Batt_finishdVdT" name="fidt" [(ngModel)]="truckview.fidt" class="form-control">
											<option *ngFor="let dt of finishDt" [ngValue]="dt">{{dt}}</option>
										</select>
										<span class="input-group-addon"><span>minutes</span></span>
									</div>
								</td>
							</tr>
							<tr>
								<td class="col-md-6" check-permission="SAVE-BATTVIEW-DEFAULT-CHARGE-PROFILE"><span class="save-btn" (click)="checkChanges('defaultChargeProfile')">Save</span></td>
								<td><span class="back-btn" (click)="setTab('BATTVIEW-BATTERY-INFO'); resetForms()">Back</span></td>
							</tr>
							</table>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div check-permission="BATTVIEW-FI-EQ-SCHEDULING" *ngIf="isSet('BATTVIEW-FI-EQ-SCHEDULING')">
		<div class="panel panel-default">
			<div class="panel-body">
				<form name="FIEQscheduling">
					<div class="row">
						<div class="panel-body">
							<fieldset check-permission="Batt_FI_sched_Settings" [disabled]="[0, 2].indexOf(truckview.chargertype) > -1">
								<legend><span [ngClass]="{'dirtySettingsField': changesStack.fischedulingmode}" [pTooltip]="getStackHTML('fischedulingmode')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">Finish Cycle Settings</span></legend>

								<label><input check-permission="Batt_FI_sched_Settings" type="radio" name="fischedulingmode" [(ngModel)]="truckview.fischedulingmode" class="form-control" [value]="0"/> Always</label>
								<label><input check-permission="Batt_FI_sched_Settings" type="radio" name="fischedulingmode" [(ngModel)]="truckview.fischedulingmode" class="form-control" [value]="1"/> Custom</label>
							</fieldset>
							<br/>
							<div class="row">
								<div check-permission="Batt_EQ_sched_CustomSettings" class="col-md-4">
									<fieldset>
										<legend>Equalize Cycle</legend>

										<label [ngClass]="{'dirtySettingsField': changesStack.eqstartwindow}" [pTooltip]="getStackHTML('eqstartwindow')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">Window Start Time</label>
										<input type='text' check-permission="Batt_EQ_sched_CustomSettings" name="eqstartwindow" [(ngModel)]="truckview.eqstartwindow" class="form-control" placeholder="00:00"/>

										<label [ngClass]="{'dirtySettingsField': changesStack.eqtimer}" [pTooltip]="getStackHTML('eqtimer')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">Window Duration</label>
										<select check-permission="Batt_EQ_sched_CustomSettings" name="eqtimer" [(ngModel)]="truckview.eqtimer" class="form-control">
											<option *ngFor="let eqtime of quarts_2_24" [value]="eqtime">{{eqtime}}</option>
										</select>

										<label [ngClass]="{'dirtySettingsField': changesStack.eqdaysmask}" [pTooltip]="getStackHTML('eqdaysmask')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">Window Days</label>
										<label class="col-md-12">
											<button class="btn btn-info" check-permission="Batt_EQ_sched_CustomSettings" (click)="deselectAllDays('eqdaysmask')">Deselect All</button>
										</label>
										<select multiple check-permission="Batt_EQ_sched_CustomSettings" name="eqdaysmask" [(ngModel)]="truckview.eqdaysmask" class="form-control" size="7">
											<option *ngFor="let day of daysOfTheWeek" [ngValue]="day.id">{{day.text}}</option>
										</select>

										<div *ngIf="truckview.firmwareversion >= 2.1">
											<label [ngClass]="{'dirtySettingsField': changesStack.blockedeqdays}" [pTooltip]="getStackHTML('blockedeqdays')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">EQ Frequency</label>
											<div class='input-group'>
												<select check-permission="Batt_EQ_sched_CustomSettings" name="blockedeqdays" [(ngModel)]="truckview.blockedeqdays" class="form-control">
													<option *ngFor="let day of blockedDaysOpts" [ngValue]="day.id">{{day.name}}</option>
												</select>
												<span class="input-group-addon"><span>Days</span></span>
											</div>
										</div>
									</fieldset>
								</div>

								<div check-permission="Batt_FI_sched_Settings" class="col-md-4 col-md-offset-2" *ngIf="truckview.fischedulingmode">
									<fieldset>
										<legend>Finish Cycle Settings</legend>

										<label [ngClass]="{'dirtySettingsField': changesStack.fistartwindow}" [pTooltip]="getStackHTML('fistartwindow')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">Window Start Time</label>
										<input type='text' check-permission="Batt_FI_sched_CustomSettings" name="fistartwindow" [(ngModel)]="truckview.fistartwindow" class="form-control" placeholder="00:00"/>

										<label [ngClass]="{'dirtySettingsField': changesStack.fitimer}" [pTooltip]="getStackHTML('fitimer')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">Window Duration</label>
										<select check-permission="Batt_FI_sched_CustomSettings" name="fitimer" [(ngModel)]="truckview.fitimer" class="form-control">
											<option *ngFor="let finishtime of quarts_2_24" [value]="finishtime">{{finishtime}}</option>
										</select>

										<label [ngClass]="{'dirtySettingsField': changesStack.fidaysmask}" [pTooltip]="getStackHTML('fidaysmask')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">Window Days</label>
										<label class="col-md-12">
											<button class="btn btn-info" check-permission="Batt_FI_sched_CustomSettings" (click)="deselectAllDays('fidaysmask')">Deselect All</button>
										</label>
										<select multiple check-permission="Batt_FI_sched_CustomSettings" name="fidaysmask" [(ngModel)]="truckview.fidaysmask" class="form-control" size="7">
											<option *ngFor="let day of daysOfTheWeek" [ngValue]="day.id">{{day.text}}</option>
										</select>

										<div *ngIf="truckview.firmwareversion >= 2.1">
											<label [ngClass]="{'dirtySettingsField': changesStack.blockedfidays}" [pTooltip]="getStackHTML('blockedfidays')" [escape]="false" tooltipStyleClass="device-changes-log" tooltipPosition="right">FI Frequency</label>
											<div class='input-group'>
												<select check-permission="Batt_FI_sched_CustomSettings" name="blockedfidays" [(ngModel)]="truckview.blockedfidays" class="form-control">
													<option *ngFor="let day of blockedDaysOpts" [ngValue]="day.id">{{day.name}}</option>
												</select>
												<span class="input-group-addon"><span>Days</span></span>
											</div>
										</div>
									</fieldset>
								</div>
							</div>
							<br/>
							<table class="cellpadding-10 col-xs-5 l">
								<tr>
									<td class="col-md-6" check-permission="SAVE-BATTVIEW-FI-EQ-SCHEDULING"><span class="save-btn" (click)="checkChanges('FIEQscheduling')">Save</span></td>
									<td><span class="back-btn" (click)="setTab('BATTVIEW-BATTERY-INFO'); resetForms()">Back</span></td>
								</tr>
							</table>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>