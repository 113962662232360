<div class="row">
	<div class="col-md-12 panel panel-default">
		<div class="panel-body">
			<div class="padding-8">
				<input type="number" [(ngModel)]="id" placeholder="{{'g.actview_id' | translate}}" class="form-control"/>
			</div>
			<div class="padding-8">
				<button class="btn btn-success tcenter" (click)="getDeviceByID('charger')" [disabled]="!id">{{'device.search_for_charger' | translate}}</button>
				<button class="btn btn-success tcenter" (click)="getDeviceByID('battview')" [disabled]="!id">{{'device.search_for_battview' | translate}}</button>
			</div>
			<div *ngIf="noResult" class="padding-8">
				{{'g.no_results_found' | translate}}
			</div>
			<table class="table table-striped padding-8" *ngIf="!deviceInfo.empty">
				<thead>
					<tr>
						<th>{{'device.device_type' | translate}}</th>
						<th>{{'device.serial_number' | translate}}</th>
						<th *ngIf="type=='charger'">{{'device.charger_id' | translate}}</th>
						<th *ngIf="type=='charger'">{{'device.purchase_order' | translate}}</th>
						<th *ngIf="type=='battview'">{{'device.battery_id' | translate}}</th>
						<th *ngIf="type=='charger'">{{'g.model' | translate}}</th>
						<th *ngIf="type=='battview'">{{'device.is_battview_2_0' | translate}}</th>
						<th>{{'device.is_replacement_part' | translate}}</th>
						<th *ngIf="type=='battview'">{{'device.is_pa' | translate}}</th>
						<th>{{'g.is_deleted' | translate}}</th>
						<th>{{'device.commission_date' | translate}}</th>
						<th>{{'device.assigned_to_production_line_date' | translate}}</th>
						<th>{{'device.shipping_date' | translate}}({{'time.utc_gmt' | translate}})</th>
						<th>{{'device.warranty_period' | translate}}</th>
						<th>{{'site.customer_site' | translate}}</th>
						<th *ngIf="type=='charger' && currentUser.isACTuser">{{'device.test_confirmed' | translate}}</th>
						<th>{{'g.action' | translate}}</th>
					</tr>
				</thead>
				<tbody>
				<tr>
					<td>{{type == 'charger' ? 'Charger' : 'Battview'}}</td>
					<td>{{deviceInfo.serialnumber}}</td>
					<td *ngIf="type=='charger'">
						<a *ngIf="!deviceInfo.isdeleted && !deviceInfo.replacmentpart && deviceInfo.customerid > 0 && deviceInfo.siteid > 0; else noLink" [routerLink]="['/', deviceInfo.customerid, deviceInfo.siteid, 'chargers', 'analytics', 'cycles', deviceInfo.id]" >{{deviceInfo.chargerusername}}</a>
						<ng-template #noLink>
							<span>{{deviceInfo.chargerusername}}</span>
						</ng-template>
					</td>
					<td *ngIf="type=='charger'">{{deviceInfo.purchase_order}}</td>
					<td *ngIf="type=='battview'">
						<a *ngIf="!deviceInfo.ispa && !deviceInfo.isdeleted && !deviceInfo.replacmentpart && deviceInfo.customerid > 0 && deviceInfo.siteid > 0" [routerLink]="['/', deviceInfo.customerid, deviceInfo.siteid, 'battviews', 'analytics', 'battery-summary', deviceInfo.id]">{{deviceInfo.batteryid}}</a>
						<span *ngIf="deviceInfo.isdeleted || deviceInfo.replacmentpart || (!deviceInfo.ispa && (!deviceInfo.customerid || !deviceInfo.siteid))">{{deviceInfo.batteryid}}</span>
						<a *ngIf="deviceInfo.ispa && !deviceInfo.isdeleted && !deviceInfo.replacmentpart" 
						[routerLink]="['/battview-mobile/analytics/battery-summary']" [queryParams]="{battviewId: deviceInfo.id}">{{deviceInfo.batteryid}}</a>
					</td>
					<td *ngIf="type == 'charger'">{{deviceInfo.model}}</td>
					<td *ngIf="type == 'battview'">{{deviceInfo.hwversion.length == 2 ? 'true' : 'false'}}</td>
					<td>{{deviceInfo.replacmentpart}}</td>
					<td *ngIf="type=='battview'">{{deviceInfo.ispa}}</td>
					<td>{{deviceInfo.isdeleted}}</td>
					<td>{{deviceInfo.commissiondate | date:'MM/dd/yyyy'}}</td>
					<td>{{deviceInfo.assignedtoproductionlinedate | date:'MM/dd/yyyy'}}</td>
					<td>{{deviceInfo.shippingdate | date:'MM/dd/yyyy':'UTC/GMT'}}</td>
					<td *ngIf="deviceInfo.isWarrantyVoid">{{(currentUser.isACTuser ? commonUtil.singularOrPlural(deviceInfo.warrantyperiod, 'Year') + '-' + ('g.void' | translate) : 'g.void' | translate)}}</td>
					<td *ngIf="!deviceInfo.isWarrantyVoid">{{commonUtil.singularOrPlural(deviceInfo.warrantyperiod, 'Year')}}</td>
					<td>
						<a *ngIf="deviceInfo.customerid > 0 && !deviceInfo.ispa && !deviceInfo.isdeleted && !deviceInfo.replacmentpart; else noId" [routerLink]="['/main', deviceInfo.customerid]">{{deviceInfo.customername}}</a> / <a *ngIf="deviceInfo.siteid > 0 && !deviceInfo.ispa && !deviceInfo.isdeleted && !deviceInfo.replacmentpart; else noId" [routerLink]="['/', deviceInfo.customerid, deviceInfo.siteid]">{{deviceInfo.sitename}}</a>
						<ng-template #noId>
							-
						</ng-template>
					</td>
					<td *ngIf="type=='charger' && currentUser.isACTuser">{{deviceManagemntService.isTestConfirmed('charger', deviceInfo)}}</td>
					<td>
						<div class="btn-group" appDropdown *ngIf="!deviceInfo.replacmentpart && !deviceInfo.isdeleted">
							<i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
							<ul class="dropdown-menu" role="menu">
								<li *ngIf="(!deviceInfo.isProductionLine || userService.hasAccessFunction('act_admin_device_management') || userService.hasAccessFunction('production_line_management'))"><a (click)="goToEditDevice()">{{'g.edit' | translate}}</a></li>
								<li *ngIf="manageDatesAccess"><a [routerLink]="['/devices','devices-dates-management',type,deviceInfo.id]">{{'device.edit_device_dates' | translate}}</a></li>
								<li *ngIf="userService.hasAccessFunction('act_admin_device_management') && type=='battview' && !deviceInfo.isdeleted && !deviceInfo.replacmentpart"><a (click)="regenerateDailyDetails(deviceInfo)">{{'site.regen_all_daily_details' | translate}}</a></li>
								<li 
									*ngIf="type == 'charger' && userService.hasAccessFunction('edit_purchase_order')">
									<a 
										[routerLink]="['/devices','devices-purchase-order-management','charger',deviceInfo.id]">{{'device.edit_purchase_order' | translate}}</a></li>
							</ul>
						</div>
					</td>
				</tr>
				</tbody>
			</table>
			<div *ngIf="deviceInfo.isProductionLine">* {{'act.act_production_line' | translate}}</div>
			<div class="padding-8">
				<button class="btn btn-info tcenter" (click)="getReplacementTimeline(deviceInfo.originalSerialNumber, deviceInfo.id)" *ngIf="deviceInfo.originalSerialNumber">{{'device.show_replacement_history' | translate}}</button>
				<div class="padding-8" *ngIf="!replacementHistory.empty">
					<h4>{{'device.replacement_history' | translate}}:</h4>
					<p *ngFor="let item of replacementHistory">
						{{item.serialnumber}}
					</p>
					<p *ngIf="replacementHistory.length == 0">
						{{'g.no_results_found' | translate}}
					</p>
				</div>
			</div>
		</div>
	</div>
</div>