import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AdminService } from '../../../admin.service';
import * as _ from 'underscore';
import { NotificationMessageService } from '../../../../shared/notification-message/notification-message.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-user-search-options',
	templateUrl: './user-search-options.component.html',
	styleUrls: ['./user-search-options.component.css']
})
export class UserSearchOptionsComponent implements OnInit {

	@Input() userTypes: {id: string, text: string}[]; // getUsersOptions
	@Input() pageName: string;
	@Input() hasAddUserButton: boolean = false;
	@Input() resetCriteria() {
		this.getUsersBy = '';
		this.resetUsers();
	}
	usersOfType: {id: number, name: string}[];
	userSearch: any;
	usersByType: {dealer: any[], client: any[], oem: any[], truckdealer: any[]};
	users: any;

	getUsersBy: any;
	multiUsers: any;

	@Output() onGetResult = new EventEmitter<any>(true);

	constructor(
		private adminService: AdminService,
		private notificationMessage: NotificationMessageService,
		private route: ActivatedRoute
	) {
		this.userSearch = {firstname: '', lastname: '', email: '', id: '', domain: ''};
	}

	ngOnInit() {
		this.usersByType = {
			'dealer':	[],
			'client':	[],
			'oem':		[],
			'truckdealer': []
		};
		this.route.queryParams.subscribe(params => {
			if(params.searchType == 'id' && params.Val == +params.Val) {
				this.getUsersBy = 'specific';
				this.userSearch = {'id': +params.Val};
				this.getUsers('search', this.userSearch);
			}
		});
	}

	resetUsers(){
		this.users	= [];

		this.multiUsers = {
			'dealer':	[],
			'client':	[],
			'oem':		[],
			'truckdealer': []
		};

		this.usersByType = {
			'dealer':	[],
			'client':	[],
			'oem':		[],
			'truckdealer': []
		};
		this.userSearch = {firstname: '', lastname: '', email: '', id: '', domain: ''};    
	}

	getUsers(getBy, options: any = {}){

		if(getBy == 'specific') {
			return this.fillUsers(getBy);;
		}

		if(this.pageName != 'customized_emails')
			this.users = [];
		
		this.usersOfType = [];

		if(getBy == 'search' && options.id && !(Number.isInteger(options.id) && options.id < Math.pow(2, 31) -1)) {
			// invalid input
			this.notificationMessage.setMessage('Invalid Input', 'danger', 10000, true);
			return;
		}

		this.adminService.getUsersListing(getBy, options).subscribe(
			(response: any[]) => {
				if(['act', 'all', 'search'].indexOf(getBy) != -1) {

					if(getBy == 'search' && this.pageName == 'customized_emails') {
						var userIDs = _.pluck(this.users, 'id');
						response.forEach((item) => {
							if(userIDs.indexOf(item.id) == -1)
								this.users.push(item);
						});
					} else {
						this.users = response;
					}
				} else {
					this.usersOfType = response;
				}

				this.fillUsers(getBy);
			}
		);
	};

	// fillUsers function should be moved to customized email component
	fillUsers(role, id?) {
		this.refellUsers(role);
		
		this.onGetResult.emit({'users': this.users, 'role': role});

		// if(role != 'act' && !id)
		//   return;

		// if(role == 'act') {
		//   // this.users.forEach(function(user) {
		//   //   if(user.email_confirmed && !user.test_user)
		//   //     this.email.to.push(user.id);
		//   // });
		// } else {
		//   var checked = this.multiUsers[role][id];

		//   if(checked) {

		//     this.usersByType[role][id].forEach(function(user) {
		//       if(user.email_confirmed && !user.test_user) {

		//         // this.email.to.push(user.id);
		//         this.users.push(user);
		//       }
		//     });
		//   } else {
		//     this.usersByType[role][id].forEach(function(userByType) {
		//       // var idx = this.email.to.indexOf(userByType.id);
		//       // if(idx != -1)
		//       //   this.email.to.splice(idx, 1);

		//       this.users.forEach(function(user, idx) {
		//         if(user.id == userByType.id) {

		//           this.users.splice(idx, 1);
		//           idx--;
		//         }
		//       });
		//     });
		//   }
		// }
	}

	getUsersByRole(role, id) {
		if(!id) return;

		if(this.usersByType && this.usersByType[role] && this.usersByType[role][id]) {
			return this.fillUsers(role, id);
		}

		this.adminService.getUsersByRole(role, id).subscribe(
			data => {
				this.usersByType[role][id] = data;
				this.users = data;

				this.fillUsers(role, id);
			},
			err => {
				this.users = [];
			}
		);
	}

	private refellUsers(role: string): void {
		if(['act', 'all', 'search'].indexOf(role) != -1) {
			return;
		}
		this.users = [];
		for(let userId in this.multiUsers[role]) {
			if(this.multiUsers[role][userId]) {
				this.users = this.users.concat(this.usersByType[role][userId]);
			}
		}
	}
}
