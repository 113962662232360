import { Component, OnInit, OnDestroy } from '@angular/core';
import { SideMenuService } from '../shared/side-menu/side-menu.service';
import { BreadcrumbService } from '../shared/breadcrumb/breadcrumb.service';

@Component({
	selector: 'app-home',
	template: `<router-outlet></router-outlet>`
})
export class HomeComponent implements OnInit, OnDestroy {
	
	constructor(
		private sideMenu: SideMenuService, 
		private breadcrumb: BreadcrumbService 
	) {
		this.sideMenu.show();
		this.breadcrumb.hide();
	}

	ngOnInit() {

	}

	ngOnDestroy() {
		this.sideMenu.hide();
	}
}