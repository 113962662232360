import { Component, OnInit, OnDestroy } from '@angular/core';
import { DeviceManagementService } from '../device-management.service';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-support-logs',
	templateUrl: './support-logs.component.html',
	styleUrls: ['./support-logs.component.css']
})
export class SupportLogsComponent implements OnInit, OnDestroy {

	selectedTab: string;
	supportLog: any[]; // | orderBy:'-id'
	constructor(
		private deviceManagemntService: DeviceManagementService,
		private route: ActivatedRoute,
		private breadcrumb: BreadcrumbService,
		private translateService: TranslateService
	) {
		this.breadcrumb.setBreadcrumb([
			{label: this.translateService.instant('site.support_log'), url: ''}
		]);
	}

	ngOnInit() {
		this.route.params.subscribe(
			params => this.selectedTab = params.type
		);
		this.getSupportLoggingInfo(this.selectedTab);
		
	}
	getSupportLoggingInfo(selectedTab){
		var tab = 1;
		if (selectedTab == 'notes') {
			tab = 2;
		}
		this.deviceManagemntService.getSupportLoggingInfo(tab).subscribe(
			(response: any[]) => {
				this.supportLog = response;
			}
		);
	}
	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
