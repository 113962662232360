import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdminService } from '../../../admin.service';
import { NotificationMessageService } from '../../../../shared/notification-message/notification-message.service';
import { ActivatedRoute } from '@angular/router';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { NavigationService } from '../../../../shared/services/navigation.service';
import * as _ from 'underscore';
import { BreadcrumbService } from '../../../../shared/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-edit-access-functions',
  templateUrl: './edit-access-functions.component.html',
  styleUrls: ['./edit-access-functions.component.css']
})
export class EditAccessFunctionsComponent implements OnInit, OnDestroy {

  user: {accessfunctions: any};
  accessFunctions: any;
  accessOptions: string[];
  boolAccessOptions: string[];
  accessFunctionsGroups: string[];
  accessFunctionsArr: any;
  allAccessFunctions: any;
  newAccessFunction: {
    hasReadOption: boolean,
    access: string
  }
  accessFunctionsDesc: any;
  docAccessFunction: any;
  userId: number;

  constructor(
    private adminService: AdminService,
    private notificationMessage: NotificationMessageService,
    private route: ActivatedRoute,
    private commonUtil: CommonUtil,
    public navigation: NavigationService,
    private breadcrumb: BreadcrumbService
  ) {

    this.user = {
      accessfunctions: []
    };
    this.userId = parseInt(this.route.snapshot.params.userId);
  }

  ngOnInit() {
    //Edit user
    if(this.userId != 0)
      this.getUsers('byID', {'id': this.userId});

    this.accessOptions		= ['read', 'write'];
    this.boolAccessOptions	= ['Yes', 'No'];
    this.newAccessFunction = {
      hasReadOption: null,
      access: null
    };
    this.docAccessFunction = {};
    this.breadcrumb.setBreadcrumb([
      {label: "Users", url: "/users"},
      {label: "Edit Access Functions", url: ""}
    ]);
  }

  editAccessFunction(mode, data, group) {
    if(!this.user || !this.user.accessfunctions)
      return false;

    if(mode == 'del') {

      var idx = data;

      // add removed access function to list of available access functions
      var id = this.user.accessfunctions[idx].id;
      var accessFunction = this.accessFunctions[id];
      accessFunction.id = id;
      this.accessFunctionsArr[accessFunction.group].push(accessFunction);
      this.accessFunctionsArr[accessFunction.group] = [...this.accessFunctionsArr[accessFunction.group]];

      this.user.accessfunctions.splice(idx, 1);
    } else if(mode == 'add') {
      // remove added access function from list of available access functions
      for(var i in this.accessFunctionsArr[group]) {
        var tempAccessFunction = this.accessFunctionsArr[group][i];
        if(tempAccessFunction.id == data.id) {
          break;
        }
      }
      this.accessFunctionsArr[group].splice(i, 1);
      this.accessFunctionsArr[group] = [...this.accessFunctionsArr[group]];      

      this.user.accessfunctions.push(data);
      this.newAccessFunction = {
        hasReadOption: null,
        access: null
      };
      this.docAccessFunction = {};
    }
  };

  getUsers(getBy, options){

    this.adminService.getUsers(this.userId, this.userId != 0).subscribe(
      (response: {user: any, accessFunctions: any}) => {
        this.user = response.user;
        this.accessFunctions	= response.accessFunctions;
        this.allAccessFunctions = response.accessFunctions;
        
        this.accessFunctionsGroups = [];
        this.accessFunctionsArr = {};
        this.accessFunctionsDesc = {};
        for (var accessFunctionKey in response.accessFunctions) {
          if (this.accessFunctionsGroups.indexOf(response.accessFunctions[accessFunctionKey].group) === -1) {
            this.accessFunctionsGroups.push(response.accessFunctions[accessFunctionKey].group);
            this.accessFunctionsArr[response.accessFunctions[accessFunctionKey].group] = [];
            this.accessFunctionsDesc[response.accessFunctions[accessFunctionKey].group] = [];
          }
          var tempObject = {
            id: accessFunctionKey,
            title: response.accessFunctions[accessFunctionKey].title,
            desc: response.accessFunctions[accessFunctionKey].desc,
            hasReadOption: response.accessFunctions[accessFunctionKey].hasReadOption
          };
          if(!this.user.accessfunctions.hasOwnProperty(accessFunctionKey))
            this.accessFunctionsArr[response.accessFunctions[accessFunctionKey].group].push(tempObject);
          else if(this.accessFunctionsArr[response.accessFunctions[accessFunctionKey].group].length === 0) {
            var idx = this.accessFunctionsGroups.indexOf(response.accessFunctions[accessFunctionKey].group);
            if(idx != -1)
              this.accessFunctionsGroups.splice(idx, 1);
          }
          this.accessFunctionsDesc[response.accessFunctions[accessFunctionKey].group].push(tempObject);
          if(!tempObject.hasReadOption && this.user.accessfunctions.hasOwnProperty(accessFunctionKey)) {
            if(!this.user.accessfunctions[accessFunctionKey])
              this.user.accessfunctions[accessFunctionKey] = 'No';
            else
              this.user.accessfunctions[accessFunctionKey] = 'Yes';
          }
        }
        
        this.user.accessfunctions	= this.commonUtil.objectToLabledArrayOfObjects(this.user.accessfunctions, 'id', 'access');
      }
    );
  };
  updateFunctionManagement(){
    let tempAccessFunction = _.extend(this.user.accessfunctions, {});
    for(var item of tempAccessFunction) {
      if(!this.allAccessFunctions[item.id].hasReadOption) {
        if(item.access == 'No'){
          item.access = '';
        } else {
          item.access = 'write';
        }
      }
    }
    let data = {
      id: this.userId,
			accessfunctions: tempAccessFunction
    };
    this.adminService.updateUserAccessManagement(this.userId, tempAccessFunction).subscribe(
      response => {
        this.navigation.goBack(['/users']);
      }
    );
  }

  ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
