<div class="row">
	<div class="panel panel-default">
		<div class="panel-body" *ngIf="users.length > 0">
			<div class="col-md-12" *ngFor="let user of users; let idx = index ">
				<simple-widget [title]="user.firstname + ' ' + user.lastname" [collapsedByDefault]="true">
					<div class="col-md-6">
						<table class="table table-striped">
							<tr>
								<td><span class="green-text">{{'groupadmin.t1' | translate}}</span></td>
								<td><div class="onoffswitch">
									<input type="checkbox" name="onOffLogin" class="onoffswitch-checkbox" id="onOffLogin{{user.id}}" checked="" [(ngModel)]="user.loginDisabled" (ngModelChange)="sendGroupAdminRequest('login', user, idx)">
									<label class="onoffswitch-label" for="onOffLogin{{user.id}}">
										<span class="onoffswitch-inner"></span>
										<span class="onoffswitch-switch"></span>
									</label>
								</div></td>
							</tr>
							<tr>
								<td><span class="green-text">{{'groupadmin.t2' | translate}}</span></td>
								<td><div class="onoffswitch">
									<input type="checkbox" name="onOffAlerts" class="onoffswitch-checkbox" id="onOffAlerts{{user.id}}" checked="" [(ngModel)]="user.alertsDisabled" (ngModelChange)="sendGroupAdminRequest('alerts', user, idx)">
									<label class="onoffswitch-label" for="onOffAlerts{{user.id}}">
										<span class="onoffswitch-inner"></span>
										<span class="onoffswitch-switch"></span>
									</label>
								</div></td>
							</tr>
							<tr>
								<td><span class="green-text">{{'groupadmin.t3' | translate}}</span></td>
								<td><div class="onoffswitch">
									<input type="checkbox" name="onOffSettings" class="onoffswitch-checkbox" id="onOffSettings{{user.id}}" checked="" [(ngModel)]="user.settingsDisabled" (ngModelChange)="sendGroupAdminRequest('settings', user, idx)">
									<label class="onoffswitch-label" for="onOffSettings{{user.id}}">
										<span class="onoffswitch-inner"></span>
										<span class="onoffswitch-switch"></span>
									</label>
								</div></td>
							</tr>
							<tr>
								<td><span class="green-text">{{'groupadmin.t4' | translate}}</span></td>
								<td><div class="onoffswitch">
									<input type="checkbox" name="onOffRemoteChargerControl" class="onoffswitch-checkbox" id="onOffRemoteChargerControl{{user.id}}" checked="" [(ngModel)]="user.remoteChargerControlDisabled" (ngModelChange)="sendGroupAdminRequest('remoteChargerControl', user, idx)">
									<label class="onoffswitch-label" for="onOffRemoteChargerControl{{user.id}}">
										<span class="onoffswitch-inner"></span>
										<span class="onoffswitch-switch"></span>
									</label>
								</div></td>
							</tr>
							<tr>
								<td><span class="green-text">{{'groupadmin.t5' | translate}}</span></td>
								<td><div class="onoffswitch">
									<input type="checkbox" name="onOffReassignDevices" class="onoffswitch-checkbox" id="onOffReassignDevices{{user.id}}" checked="" [(ngModel)]="user.reassignDevicesDisabled" (ngModelChange)="sendGroupAdminRequest('reassignDevices', user, idx)">
									<label class="onoffswitch-label" for="onOffReassignDevices{{user.id}}">
										<span class="onoffswitch-inner"></span>
										<span class="onoffswitch-switch"></span>
									</label>
								</div></td>
							</tr>
							<tr>
								<td><span class="green-text">{{'groupadmin.t6' | translate}}</span></td>
								<td><a class="pointer" (click)="sendGroupAdminRequest('delete', user)">{{'g.send_request' | translate}}</a></td>
							</tr>
						</table>
					</div>
				</simple-widget>
			</div>
		</div>
		<div class="panel-body" *ngIf="!users.empty && users.length == 0">
			<div>
				{{'groupadmin.t7' | translate}}
			</div>
		</div>
	</div>
</div>