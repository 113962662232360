import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { User } from '../../../../auth/user.model';
import { UserService } from '../../../../auth/user.service';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';
import { Site } from '../../../../shared/models/site.model';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { DevicesDashboardService } from '../../devices/devices-dashboard.service';

@Component({
	selector: 'app-battview-list-widget',
	templateUrl: './battview-list-widget.component.html',
	styleUrls: ['./battview-list-widget.component.css']
})
export class BattviewListWidgetComponent implements OnInit, OnChanges {
	
	@Input() data;
	@Input() userSettings;

	user: User;
	widgetId :string = "battview_list";
	hideWidget: boolean = false;
	options = {
		hasPrint: false,
		hasExpand: false
	};
	columns : Array<object> = [];
	currentSite: Site = new Site();

	constructor(
		private userService: UserService,
		private sideMenu: SideMenuService,
		private commonUtil: CommonUtil,
		private devicesDashboardService: DevicesDashboardService) {
	}

	ngOnInit() {
		this.user = this.userService.getCurrentUser();
		
		this.sideMenu.currentSite.subscribe(site => {
			if (site && site.id) {
				this.currentSite = site;
				this.setExtraData();

				let columns : any[] = [
					{
						headerName: "Battery ID",
						field: "battery_id",
						cellRendererSelector:function (params) {
							params['label'] = params.data.battery_id;
							params['link'] = ["/#", params.colDef.cellRendererParams.customerId, params.colDef.cellRendererParams.siteId, "battviews", "analytics", "battery-summary", params.data.id].join('/');
							return {
								component: 'linkCellRenderer',
								params: params
							};
						},
						cellRendererParams: {customerId: this.currentSite.customerid, siteId: this.currentSite.id},
						filter: 'agTextColumnFilter'
					}
				];
				if(this.userSettings.view_truck_id) {
					columns = columns.concat([
						{headerName: "Truck ID", width: 150, field: "truckid", filter: 'agTextColumnFilter'}
					]);
				}
				columns = columns.concat([
					{headerName: "Warranty", width: 170, field: "warrantyperiod", filter: 'agTextColumnFilter'},
					{headerName: "BATTView SN", width: 170, field: "serialnumber", filter: 'agTextColumnFilter'},
					{headerName: "Model", width: 135, field: "model", filter: 'agTextColumnFilter'},
					{headerName: "Battery Voltage", field: "batteryvoltage", type: "number", width: 180},
					{headerName: "Battery Capacity", field: "batterycapacity", type: "number", width: 190},
					{headerName: "Firmware Version", field: "firmwareversion", width: 190, hide: true, filter: 'agTextColumnFilter'},
					{headerName: "ACT Intelligent", field: "actviewenabled", width: 190, hide: true, filter: 'agTextColumnFilter'},
					{headerName: "Time Zone", field: "zonename", width: 300, hide: true, filter: 'agTextColumnFilter'},
					{headerName: "Application Type",field: "chargerType", width: 200, hide: true, filter: 'agTextColumnFilter'},
					{headerName: "Battery SN", field: "batterysn", width: 170, hide: true, filter: 'agTextColumnFilter'}
				]);
				if (this.user.accessFunctions["view_rejected_device_connections"]) {
					columns.push({headerName: "IP Address", field: "ip_address", width: 170, filter: 'agTextColumnFilter'});
				}

				this.columns = columns;
			}
		})
	}

	ngOnChanges() {
		if (this.data && this.data.length > 0) {
			this.hideWidget = false;
			const sortKey = 'battery_id';
			this.data = this.data.sort(function(a,b) {
				return (a[sortKey] > b[sortKey]) ? 1 : ((b[sortKey] > a[sortKey]) ? -1 : 0);
			});
			this.setExtraData();
		} else {
			this.hideWidget = true;
		}
	}

	setExtraData() {
		if(this.currentSite && this.currentSite.id && this.data && this.data.length > 0) {
			this.data.forEach((item) => {
				item.zonename			= this.commonUtil.getZoneName(item.zoneid);
				item.chargerType		= this.devicesDashboardService.chargerTypes[item.chargertype] ? this.devicesDashboardService.chargerTypes[item.chargertype].text : ""; 
			});
		}
	}
}
