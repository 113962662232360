import { Component, OnInit } from '@angular/core';
import { SiteDashboardService } from '../../site-dashboard.service';

@Component({
  selector: 'app-site-history',
  templateUrl: './site-history.component.html',
  styleUrls: ['./site-history.component.css']
})
export class SiteHistoryComponent implements OnInit {

	activeHistoryPage: string = 'historical-log';
  
  constructor(private siteDashboardService: SiteDashboardService) { }

  ngOnInit() {
    this.siteDashboardService.setActiveTab('actfirst');
    this.siteDashboardService.setActiveSubTab('site-history');
  }

  activateHistoricalPage (page) {
  	this.activeHistoryPage = page;
  }

}
