
import {distinctUntilChanged} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable ,  Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SiteDashboardService {

	autoconnectFreq: number = 1;

	private activeTabSubject = new BehaviorSubject<string>("");
	activeTab = this.activeTabSubject.asObservable().pipe(distinctUntilChanged());

	private activeSubTabSubject = new BehaviorSubject<string>("");
	activeSubTab = this.activeSubTabSubject.asObservable().pipe(distinctUntilChanged());

	private dashboardData: any = {};
	public activeDashboardData:Subject<any> = new Subject();

	constructor(
		private httpClient: HttpClient
	) {}

	setActiveTab(tab: string): void {
		this.activeTabSubject.next(tab);
	}

	getActiveTab(): string {
		return this.activeTabSubject.value;
	}

	setActiveSubTab(tab: string): void {
		this.activeSubTabSubject.next(tab);
	}

	getActiveSubTab(): string {
		return this.activeSubTabSubject.value;
	}
	
	getSiteTags(customerId, siteId) {
		return this.httpClient.post('/management/getSiteTags', {customerId, siteId}, {
			observe: "body"
		});
	}

	updateTagShareOption(customerId, siteId, tag, newShareOption) {
		return this.httpClient.post('/management/updateTagShareOption', {customerId, siteId, tag, newShareOption}, {
			observe: "body"
		});
	}

	removeTagFromSite(customerId, siteId, tag) {
		return this.httpClient.post('/management/removeTagFromSite', {customerId, siteId, tag}, {
			observe: "body"
		});
	}

	getSiteGroups(siteId) {
		return this.httpClient.post('/management/getSiteGroups', {siteId}, {
			observe: "body"
		});
	}

	addSiteGroup(siteId, groupText) {
		return this.httpClient.post('/management/addSiteGroup', {siteId, groupText}, {
			observe: "body"
		});
	}

	removeGroupFromSite(siteId, groupId) {
		return this.httpClient.post('/management/removeGroupFromSite', {siteId, groupId}, {
			observe: "body"
		});
	}

	getDashboardData(customerId, siteId=0, options:any = {}) {
		let key: any = 'site_'+siteId,
			isCustomerDashboard: boolean = false,
			noAlertsSettings = options.noAlertsSettings || false;

		if (!siteId) {
			key = 'cust_'+customerId;
			isCustomerDashboard = true;
		}
		if(noAlertsSettings) {
			key += '_noAlert';
		}

		if (this.dashboardData[key] !== undefined) {
			this.activeDashboardData.next(this.dashboardData[key]);
			return new Observable((observer) => {
				observer.next(this.dashboardData[key]);
				observer.complete();
			});
		}

		this.httpClient.post('/batteries/getGeneratedDashoardData', {customerId, siteId, isCustomerDashboard, noAlertsSettings}, {
			observe: "body"
		}).subscribe(data => {
			this.dashboardData[key] = data;
			this.activeDashboardData.next(data);
			return new Observable((observer) => {
				observer.next(this.dashboardData[key]);
				observer.complete();
			});
		});
	}
	
	getChargersDashboardData(customerId, siteId=0) {
		let key: any = 'char_' + siteId,
			isCustomerDashboard: boolean = false;

		if (!siteId) {
			key = 'cust_'+customerId;
			isCustomerDashboard = true;
		}

		if (this.dashboardData[key] !== undefined) {
			this.activeDashboardData.next(this.dashboardData[key]);
			return new Observable((observer) => {
				observer.next(this.dashboardData[key]);
				observer.complete();
			});
		}

		this.httpClient.post('/chargers/getChargersPerformanceData', {customerId, siteId, isCustomerDashboard}, {
			observe: "body"
		}).subscribe(data => {
			this.dashboardData[key] = data;
			this.activeDashboardData.next(data);
			return new Observable((observer) => {
				observer.next(this.dashboardData[key]);
				observer.complete();
			});
		});
	}
	
	deleteDashboardData(customerId, siteId=0) {
		let key: any = 'site_'+siteId;

		if (!siteId) {
			key = 'cust_'+customerId;
		}
		delete this.dashboardData[key];

		let chargerKey: any = 'char_' + siteId;

		if (!siteId) {
			chargerKey = 'cust_'+customerId;
		}
		delete this.dashboardData[chargerKey];
	}

	getAlertsSettingsWithSubscribers(id, options) {
		return this.httpClient.post('/users/getAlertsSettingsWithSubscribers', {id, options}, {
			observe: "body"
		});
	}
	updateSiteAlertsSettings(alert, id) {
		return this.httpClient.post('/users/updateSiteAlertsSettings', {alert, id}, {
			observe: "body"
		});
	}
	updateUsersAlertsSettings(userSettings, siteID, selectedUser) {
		return this.httpClient.post('/users/updateUsersAlertsSettings', {userSettings, siteID, selectedUser}, {
			observe: "body"
		});
	}
	updatePluggedInAlertsFiltersSettings(alert, id, customerid) {
		return this.httpClient.post('/users/updatePluggedInAlertsFiltersSettings', {alert, id, customerid}, {
			observe: "body",
			responseType:"text"
		});
	}
	getSiteDailyDetails(params) {
		return this.httpClient.post('/customers/getSiteDailyDetails', params, {
			observe: "body"
		});
	}
	getSiteTemporaryDisabledAlerts(params) {
		return this.httpClient.post('/customers/getSiteTemporaryDisabledAlerts', params, {
			observe: "body"
		});
	}
	setSiteTemporaryDisabledAlerts(params) {
		return this.httpClient.post('/customers/setSiteTemporaryDisabledAlerts', params, {
			observe: "body"
		});
	}
	deleteSiteTemporaryDisabledAlerts(params) {
		return this.httpClient.post('/customers/deleteSiteTemporaryDisabledAlerts', params, {
			observe: "body"
		});
	}
}