import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { HeaderService } from './header.service';
import { SideMenuService } from '../side-menu/side-menu.service';
import { User } from '../../auth/user.model';
import { UserService } from '../../auth/user.service';
import { NavigationService } from '../services/navigation.service';
import { Site } from '../models/site.model';
import { Customer } from '../models/customer.model';
import { CommonDataService } from '../../shared/services/common-data.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

	user: User = new User();
	hostname:string = window.location.hostname;
	isProduction: boolean = true;
	isStaging: boolean = true;
	showHeader = false;
	
	constructor(
		private authService: AuthService, 
		private router: Router,
		private userService: UserService,
		public header: HeaderService,
		public sideMenu: SideMenuService,
		private navigation: NavigationService,
		private commonData: CommonDataService,
	) {
			this.isProduction = this.hostname == this.commonData.productionDomain;
			this.isStaging = this.hostname == this.commonData.stagingDomain;
	}
		
	ngOnInit() {
		this.userService.currentUser.subscribe((user: User) => {
			this.user = user;
		})
	}

	onLogout() {
		this.authService.logoutUser().subscribe((authenticated: boolean) => {
			this.sideMenu.setExpandedCustomers([]);
			this.sideMenu.setCurrentSite(new Site());
			this.sideMenu.setCurrentCustomer(new Customer());
			this.userService.setCurrentUser(new User());
			this.navigation.setPreviousUrl(null, true);
			this.router.navigate(['login']);
		});
	}
	enableFullAccessMode() {
		this.authService.enableFullAccessMode().subscribe(
			response => {
				window.location.reload();
			}
		);
	}

	toggleMenu() {
		let menuView = this.sideMenu.getHideMenuView();
		this.sideMenu.setHideMenuView(!menuView);
	}

	toggleHeader() {
	  let temp = this.showHeader;
	  this.showHeader = temp === false ? true : false; 
	}

	closeMobileHeader() {
		this.showHeader = false;
	}

}