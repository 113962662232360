<div *ngIf="!isAddNoteOnly">
	<div class="row">
		<div *ngIf="addButtonPositionRight" class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
		</div>
		<div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 no-gutter-left">
			<button type="button" class="btn btn-sm margin-bottom btn-primary" [ngClass]="{'pull-right': addButtonPositionRight}" [disabled]="isDisabled()" (click)="addNote()">
				<i class="glyphicon glyphicon-plus margin-right-xs"></i>{{'note.add_note' | translate}}
			</button>
		</div>
	</div>
	<div *ngIf="allNotes.notes.length">
		<div class="row">
			<table class="table">
				<tr *ngFor="let note of notes.notes" class="margin-bottom-sm">
					<td class="col-md-2" *ngIf="note.canDelete">
						<button type="button" class="btn btn-xs btn-danger margin-right-sm" (click)="deleteNote(note)">
							<i class="glyphicon glyphicon-trash"></i>
						</button>
						<button type="button" class="btn btn-xs btn-default margin-right-sm" (click)="editReminderFreq(note)" data-toggle="tooltip" data-placement="top" title="{{'note.edit_self_reminder_frequency' | translate}}" *ngIf="note.canEditReminder">
							<i class="fa fa-bell" data-toggle="tooltip" data-placement="top">
							</i>
						</button>
						<button type="button" class="btn btn-xs btn-default margin-right-sm" [disabled]="!note.canUpdateSharingType" (click)="editSharingType(note)" data-toggle="tooltip" data-placement="top" title="{{'note.edit_sharing_type' | translate}}">
							<i [ngClass]="getSharingtypeIcon(note.sharingType)" class="fa-fw" data-toggle="tooltip" data-placement="top" title="{{'note.'+note.sharingType | translate}}">
							</i>
						</button>
					</td>
					<td class="col-md-2">{{note.formattedDate}}</td>
					<td *ngIf="note.type == 'user_note'" class="col-md-6">{{note.fname}} {{note.lname}}: <span [innerHTML]="note.note | safe: 'html'"></span></td>
					<td *ngIf="note.type == 'site_creation'" class="col-md-6">{{'note.site_created_by' | translate:{"fname": note.fname, "lname": note.lname} }} </td>
					<td *ngIf="note.type == 'site_firmware_update'" class="col-md-6">{{'note.firmware_update_by' | translate:{"fname": note.fname, "lname": note.lname} }}: {{note.note}} </td>
					<td *ngIf="note.type == 'device_restarted'" class="col-md-6">{{'note.restart_request_by' | translate:{"noteOwnerId": note.noteOwnerId} }} </td>
					<td *ngIf="note.type == 'study_created'" class="col-md-6">{{'note.study_created_by' | translate:{"fname": note.fname, "lname": note.lname} }} </td>
					<td *ngIf="note.type == 'device_plc_update'" class="col-md-6">{{'note.plc_update_request_by' | translate:{"noteOwnerId": note.noteOwnerId} }} </td>
					<td *ngIf="note.type == 'device_time_set'" class="col-md-6">{{'note.time_synch_request_by' | translate:{"noteOwnerId": note.noteOwnerId} }} </td>
					<td *ngIf="note.type == 'commission'" class="col-md-6">{{'note.commissioned_by' | translate:{"noteOwnerId": note.noteOwnerId} }} </td>
					<td *ngIf="note.type == 'calibrate_battview_with_quantum'" class="col-md-6">{{'note.calibration_request_by' | translate:{"noteOwnerId": note.noteOwnerId} }} </td>
					<td *ngIf="note.type == 'bms_fw_version'" class="col-md-6">{{'note.note_by_id' | translate:{"note": note.note, "noteOwnerId": note.noteOwnerId} }} </td>

					<td class="col-md-2">
						<p *ngFor="let notifiedUser of note.notifiedList">{{notifiedUser.fname}} {{notifiedUser.lname}}</p>
					</td>
				</tr>
			</table>
		</div>
		<div class="text-center" *ngIf="allNotes.notes.length > 3">
			<button *ngIf="showLoadMoreCheck()" type="button" class="btn btn-xs margin-bottom btn-default" (click)="loadMoreNotes()">
				<i class="glyphicon glyphicon-collapse-down"></i>
			</button>
			<button *ngIf="showLoadLessCheck()" type="button" class="btn btn-xs margin-bottom btn-default" (click)="loadLessNotes()">
				<i class="glyphicon glyphicon-collapse-up"></i>
			</button>
		</div>
	</div>
	<div *ngIf="allNotes.notes.length == 0" class="text-center margin-bottom-lg text-muted" [ngClass]="noNotesMessagePosition">
		<h5>{{'note.no_notes' | translate}}</h5>
	</div>
</div>
<span *ngIf="isAddNoteOnly" (click)="addNote()" [ngClass]="{'padding_right has-action': isDropDown}"><i class="glyphicon glyphicon-plus margin-right-xs"></i>{{'note.add_note' | translate}}</span>
<app-modal #editSharingTypeModal title="{{'note.edit_sharing_type' | translate}}" confirmBtnText="{{'g.save' | translate}}" [size]="'md'">
	<form class="form-horizontal" *ngIf="thisNote">
		<div class="row margin-bottom">
			<label class="col-md-4">{{'g.note' | translate}}</label>
			<div class="col-md-6">
				<span style="height: auto;" disabled name="new-site-note" [innerHTML]="thisNote.originalNote | safe: 'html'" class="form-control"></span>
			</div>
		</div>
		<div class="row margin-bottom">
			<label class="col-md-4">
				{{'note.share_with' | translate}}
			</label>
			<div class="col-md-6">
				<select class="form-control" [(ngModel)]="thisNote.sharingType" name="sharingType">
					<option value="public">{{'note.public' | translate}}</option>
					<option value="private">{{'note.private' | translate}}</option>
					<option *ngIf="user.isACTuser" value="group">{{'note.group' | translate}}</option>
				</select>
			</div>
		</div>
	</form>
</app-modal>
<app-modal #editReminderFreqModal title="{{'note.edit_self_reminder_frequency' | translate}}" confirmBtnText="{{'g.save' | translate}}" [size]="'md'">
	<form class="form-horizontal" *ngIf="thisNote">
		<div class="row margin-bottom">
			<label class="col-md-4">{{'g.note' | translate}}</label>
			<div class="col-md-6">
				<span style="height: auto;" disabled name="new-site-note" [innerHTML]="thisNote.originalNote | safe: 'html'" class="form-control"></span>
			</div>
		</div>
		<div class="row margin-bottom">
			<label class="col-md-4">
				{{'note.self_reminder_frequency' | translate}}
			</label>
			<div class="col-md-6">
				<ng-select [items]="reminderOptions" bindLabel="label" bindValue="id" placeholder="{{'note.select_reminder_frequency' | translate}}..." name="reminder" [(ngModel)]="thisNote.reminder_freq" [multiple]="false" [clearable]="false">
				</ng-select>
			</div>
		</div>
	</form>
</app-modal>
	
<app-modal #deleteModal title="{{'note.delete_note' | translate}}" confirmBtnText="{{'g.delete' | translate}}" [size]="'md'" [confirmBtnClass]="'btn-danger'">
	<form class="form-horizontal" *ngIf="thisNote">
		<div class="row margin-bottom-lg">
			<label class="col-md-4">{{'g.note' | translate}}</label>
			<div class="col-md-6">
				<span style="height: auto;" disabled name="new-site-note" [innerHTML]="thisNote.originalNote | safe: 'html'" class="form-control"></span>
			</div>
		</div>
		<div class="row text-center margin-top-lg">
			<strong>{{'note.delete_note_msg' | translate}}</strong>
		</div>
	</form>
</app-modal>
<app-modal #addNoteModal title="{{'g.notes' | translate}}" confirmBtnText="{{'g.save' | translate}}" [size]="'lg'">
	<form class="form-horizontal" *ngIf="thisNote">
		<div class="row margin-bottom">
			<label class="col-md-4">{{'note.add_new_note' | translate}}</label>
			<div class="col-md-6">
				<input name="new-site-note" [(ngModel)]="thisNote.newSiteNote" placeholder="{{'g.note' | translate}} ..." class="form-control" required>
			</div>
		</div>
		<div class="row margin-bottom">
			<label class="col-md-4">
				{{'note.share_with' | translate}}
			</label>
			<div class="col-md-6">
				<select class="form-control" [(ngModel)]="thisNote.sharingType" name="sharingType" (change)="onChangeSharingType($event.target.value)">
					<option value="public">{{'note.public' | translate}}</option>
					<option value="private">{{'note.private' | translate}}</option>
					<option value="group" *ngIf="user.isACTuser">{{'note.group' | translate}}</option>
				</select>
			</div>
		</div>
		<div class="row margin-bottom">
			<div *ngIf="thisNote.sharingType != 'private'">
				<label class="col-md-4 col-form-label col-form-label-lg">{{'note.notification_users' | translate}}</label>
				<div class="col-md-6">
					<ng-select [items]="notes.notification_users" bindLabel="appearanceName" placeholder="{{'g.select_user' | translate}}..." name="notification_user" [(ngModel)]="thisNote.notificationUsers" multiple="true">
					</ng-select>
				</div>
			</div>
		</div>
		<div class="row margin-bottom">
			<div>
				<label class="col-md-4 col-form-label col-form-label-lg">{{'note.self_reminder_frequency' | translate}}</label>
				<div class="col-md-6">
					<ng-select [items]="reminderOptions" bindLabel="label" bindValue="id" placeholder="{{'note.select_reminder_frequency' | translate}}..." name="reminder" [(ngModel)]="thisNote.reminder_freq" [multiple]="false" [clearable]="false">
					</ng-select>
				</div>
			</div>
		</div>
	</form>
</app-modal>