import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HomeService } from '../home.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { HeaderService } from '../../shared/header/header.service';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';

@Component({
	selector: 'app-confirm-user-email',
	templateUrl: './confirm-user-email.component.html',
	styleUrls: ['./confirm-user-email.component.css']
})
export class ConfirmUserEmailComponent implements OnInit {

	URLparameters: any = {};
	userId: number = 0;

	constructor(
		private notificationMessage: NotificationMessageService,
		private breadcrumb: BreadcrumbService,
		private homeService: HomeService,
		private route: ActivatedRoute,
		private header: HeaderService,
		private router: Router
	) { }

	ngOnInit() {
		this.route.params.subscribe(
			params => {
				this.userId = params['userId'] || 0;
			}
		);
		
		this.route.queryParams.subscribe( params => {
			this.URLparameters = params || {};
		});
		this.header.hide();
		this.breadcrumb.hide();
		this.confirmUserEmail();
	}

	confirmUserEmail() {
					
		if(Object.keys(this.URLparameters).length > 0) {

			if(this.userId && this.URLparameters.enc) {
				
				this.homeService.confirmUserEmail(this.userId, this.URLparameters.enc).subscribe(
					(data: any) => {
						if(data.httpStatus == 'success') {
							this.notificationMessage.setMessage(data.msg, 'success');
							this.router.navigate(['/sites-map'])
						}
					}
				);
			}
		}
	}

	sendConfirmEmail() {
		this.homeService.sendConfirmEmail().subscribe(
			(data: any) => {
				if(data.httpStatus == 'success')
					this.notificationMessage.setMessage(data.msg, 'success');
				else if(data.httpStatus == 'error')
					this.notificationMessage.setMessage(data.msg);
			}
		);
	}

	ngOnDestroy() {
		this.header.show();
	}
}