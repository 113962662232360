import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../admin/admin.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';

@Component({
	selector: 'app-invoice-mismatch-for-site-battviews',
	templateUrl: './invoice-mismatch-for-site-battviews.component.html',
})
export class InvoiceMismatchForSiteBattviewsComponent implements OnInit {

	data:any = null;
	ACTusers:any = [];
	subscribers:any = [];
	constructor(
		private adminService: AdminService,
		private notificationMessage: NotificationMessageService,
	){}

	ngOnInit() {
		this.adminService.getInvoicesMismatchForBattviews().subscribe((data:any)=>{
			this.data			= data.invoicesMismatch;
			this.ACTusers		= data.ACTusers;
			this.subscribers	= data.subscribers;
		});
	}
	
	updateInvoicesMismatchSubscribers(){
		this.adminService.updateInvoicesMismatchSubscribers(this.subscribers).subscribe((data:any)=>{
			this.notificationMessage.setMessage('globalSuccessMsg');
		});
	}
}