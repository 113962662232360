import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import * as _ from 'underscore';
import { UserService } from '../../../auth/user.service';
import { AdminService } from '../../admin.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';

@Component({
	selector: 'app-customer',
	templateUrl: './customer.component.html',
	styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit, OnDestroy, AfterViewInit {

	clients = <any>[];
	currentUser = {};
	Options: any = {};
	filterOptions = ['Name index', 'Dealer', 'Match both', 'Match any'];
	alphabets = [];
	dealersList = [];
	shouldSearch = false;
	deleteMsg: string;
	deleteTitle: string;
	confirmedField: string = '';
	delCustomerId = 0;

	@ViewChild("modal") modal;

	constructor(
		private commonUtil: CommonUtil,
		private adminService: AdminService,
		private userService: UserService,
		private route: ActivatedRoute,
		private router: Router,
		private notificationMessage: NotificationMessageService,
		private breadcrumb: BreadcrumbService,
	) {
		this.alphabets = commonUtil.alphabetCharacters;
	}

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([{'label': 'Customers', 'url': ''}]);
		this.currentUser = this.userService.getCurrentUser();
		this.route.queryParams.subscribe( params => {
			switch(params['getBy']) {
				case 'Name index':
					if(params['nameIndex']) {
						this.Options['nameIndex'] = params['nameIndex'];
						this.shouldSearch = true;
					}
				break;
				case 'Dealer':
					if(params['dealerid']) {
						this.Options['dealerid'] = Number(params['dealerid']);
						this.shouldSearch = true;
					}
				break;
				case 'Match both':
				case 'Match any':
					if(params['dealerid'] && params['nameIndex']) {
						this.Options['dealerid'] = Number(params['dealerid']);
						this.Options['nameIndex'] = params['nameIndex'];
						this.shouldSearch = true;
					}
				break;
			}

			if(this.shouldSearch)
				this.Options['getBy'] = params['getBy'];
		});
		this.getDealers();
	}

	ngAfterViewInit() {
		this.modal.onClose.subscribe(
			(agree) => {
				if(agree) {
					this.customerDelete(this.delCustomerId);
				} else {
					let msg = '';
					switch (this.confirmedField) {
						case 'battviewmobile':
							msg = "Delete operation failed since you have battviewmobile or more assigned to it.";
						break;
						case 'request':
							msg = "Delete operation failed since you have request or more assigned to it.";
						break;
					}

					if(msg)
						this.notificationMessage.setMessage(msg, 'danger', 10000);

					this.confirmedField = '';
				}
			}
		);
	}

	getDealers(id = 0) {
		this.adminService.getDealers(id).subscribe(
			data => {
				this.dealersList = data['dealers'];

				if(this.shouldSearch)
					this.searchForCustomers();
			}
		);
	}

	goToEditCustomer(customerId) {
		this.router.navigate(['edit', 'customerId', customerId], {queryParams: this.Options, relativeTo: this.route});
	}

	searchForCustomers() {
		this.adminService.searchForCustomers(this.Options).subscribe(
			data => {
				this.clients = _.sortBy(data, function(o) { return o.name.toLowerCase(); })
			}
		);
	}

	showCustomerDeleteModal(customerid) {
		this.deleteTitle = 'Confirm Deletion';
		switch (this.confirmedField) {
			case "battviewmobile":
				this.deleteMsg = "There are some battviewmobile studies for this customer, are you sure you want to delete it?";
			break;
			case "request":
				this.deleteMsg = "There are requests for this customer, are you sure you want to delete it?";
			break;
			default:
				this.deleteMsg = "Are you sure you want to delete this customer? This operation can't be undone";
				this.deleteTitle = 'Delete Customer';
			break;
		}

		this.delCustomerId = customerid;
		this.modal.show();
	}

	customerDelete(customerid) {
		this.adminService.deleteCustomerOrSite(customerid, 0, this.confirmedField).subscribe(
			data => {
				this.confirmedField = '';
				if(data['httpStatus'] == 'error') {
					let errorMessage;
					let validErrors = ['has_battview','has_charger','has_battviewmobile', 'has_site', 'has_request', 'has_user'];
					if (validErrors.indexOf(data['msg']) > -1) {
						switch (data['msg']) {
							case 'has_battview':
								errorMessage = "Delete operation failed since you have battview or more assigned to it.";
							break;
							case 'has_charger':
								errorMessage = "Delete operation failed since you have charger or more assigned to it.";
							break;
							case 'has_battviewmobile':
								this.confirmedField = 'battviewmobile';
								this.showCustomerDeleteModal(customerid);
							break;
							case 'has_site':
								errorMessage = "Delete operation failed since you have site or more assigned to it.";
							break;
							case 'has_request':
								errorMessage = "Delete operation failed since request or more assigned to it.";
							break;
							case 'has_user':
								errorMessage = "Delete operation failed since you have user or more assigned to it.";
							break;
						}
						
						if(data['msg'] != 'has_battviewmobile') {
							this.notificationMessage.setMessage(errorMessage, 'danger', 10000);
						}
					}
				} else {
					this.searchForCustomers();
				}
			}
		)
	}

	ngOnDestroy() {
		this.modal.onClose.unsubscribe();
		this.breadcrumb.hide();
	}
}