import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from '../../shared/header/header.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { AuthService } from '../auth.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

	forgot = {
		email: '',
		recaptcha: ''
	};

	constructor(
		private header: HeaderService,
		private authService: AuthService,
		private notificationMessage: NotificationMessageService
	) {  	
		this.header.hide();
	}

	ngOnInit() {
		
	}

	forgotPassword() {
		this.authService.forgotPassword(this.forgot).subscribe(
			(data: any) => {
				if(data.httpStatus == 'error')
					this.notificationMessage.setMessage(data.msg);
				else if(data.httpStatus == 'success')
					this.notificationMessage.setMessage(data.msg, 'success');
			}
		);
	}

	ngOnDestroy() {
		this.header.show();
	}
}
