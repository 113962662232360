<div id="{{widgetId}}" class="panel panel-default">
  <div class="panel-heading {{headerClass}}">
    {{title}}
    <a class="pull-right noprint" href="javascript:">
      <i (click)="toggleCollapse()" class="fa " [ngClass]="{'fa-minus': !isCollapsed, 'fa-plus': isCollapsed}"></i>
    </a>
  </div>
  <div class="panel-body" *ngIf="!isCollapsed">
    <ng-content></ng-content>
  </div>
</div>