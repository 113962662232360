<div *ngIf="hasTruckviews; else noTruckviews">
	<div *ngFor="let modelType of truckviewsListing | keys" class="col-md-12 boxs3">
		<div class="panel panel-default">
			<div class="panel-heading"><strong>{{modelType}}</strong></div>
		</div>
		<div *ngFor="let truck of truckviewsListing[modelType]; let idx = index">
			<div class="row" *ngIf="(idx % 3) == 0 && truckviewsListing[modelType][idx]">
				<div class="col-md-4" *ngIf="(idx % 3) == 0 && truckviewsListing[modelType][idx]">
					<ng-container *ngTemplateOutlet="truckviewItem;context:{truck: truckviewsListing[modelType][idx]}" ></ng-container>
				</div>
				<div class="col-md-4" *ngIf="(idx % 3) == 0 && truckviewsListing[modelType][idx+1]">
					<ng-container *ngTemplateOutlet="truckviewItem;context:{truck: truckviewsListing[modelType][idx+1]}" ></ng-container>
				</div>
				<div class="col-md-4" *ngIf="(idx % 3) == 0 && truckviewsListing[modelType][idx+2]">
					<ng-container *ngTemplateOutlet="truckviewItem;context:{truck: truckviewsListing[modelType][idx+2]}" ></ng-container>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #truckviewItem let-truck="truck">
	<div *ngIf="truck.isShow" [ngClass]="{red: truck.hasFault && userService.hasAccessFunction('display_faults_in_listing_page') && truck.actviewenabled && truck.isConnected}">
		<div class="text-center">
			<label class="checkbox tcenter p-b-5" for="{{truck.id}}">
			<input type="checkbox" [(ngModel)]="truck.selected" name="group" (ngModelChange)="deviceChecked($event)" />
			</label>
		</div>
		<div class="text-center margin-top-lg">
			<div class="btn-group pointer" appDropdown *ngIf="currentSite.act_intelligent_type != 0">
				<a type="button" style="display: inline-block;" [pTooltip]="truck.timespanTextArr.join('\n')" tooltipPosition="top" tooltipStyleClass="device-changes-log">
					<p [ngClass]="truck.bvStatus"></p>
				</a>
				<ul class="z-index-10k dropdown-menu" role="menu">
					<li><a [routerLink]="['/', customerId, siteId, 'truckviews', 'settings']" [queryParams]="{truckviewsIDs: truck.id}">Settings</a></li>
					<li><a [routerLink]="['/', customerId, siteId, 'truckviews', 'analytics', 'truck-summary', truck.id]">Analytics</a></li>
					<li *ngIf="1 || userService.hasAccessFunction('access_truckview_quick_view') && truck.actviewenabled"><a [routerLink]="['/', customerId, siteId, 'truckviews', 'quick-view', truck.id]">Quick View</a></li>

					<li class="dropdown-submenu" appDropdown [indirectOptionsList]="true">
						<a tabindex="-1" *ngIf="!userService.hasAccessFunction('no_manage_devices') || (userService.hasAccessFunction('restart_devices')) || (userService.hasAccessFunction('act_admin_device_management') && truck.enableplc) || (!truck.isCalibrated && truck.actviewenabled && truck.enableplc && truck.firmwareversion >= 2.12 && userService.hasAccessFunction('remote_truckview_calibration'))">
							<span>Actions
								<i class="fa fa-caret-right margin-left-sm"></i>
							</span>
						</a>
						<app-truckview-actions pageName='listing' [truckview]="truck" [siteID]="siteId" [customerID]="customerId"></app-truckview-actions>
					</li>
					<li class="dropdown-submenu" appDropdown>
						<a tabindex="-1">
							<span>Notes
							<i class="fa fa-caret-right margin-left-sm"></i>
							</span>
						</a>
						<ul class="dropdown-menu" role="menu">
							<li>
								<a [routerLink]="['/', customerId, siteId, 'truckviews', 'notes', truck.id]">View Notes</a>
							</li>
							<li class="pointer">
								<a>
									<notes
										[siteId]="siteId"
										modelType="truckview"
										[modelId]="truck.id"
										[isDropDown]="true"
										[isAddNoteOnly]="true"
									></notes>
								</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="btn-group pointer" *ngIf="currentSite.act_intelligent_type == 0" [routerLink]="['/', customerId, siteId, 'truckviews', 'analytics', 'truck-summary', truck.id]">
				<i type="button" style="display: inline-block;">
					<p [ngClass]="truck.bvStatus"></p>
				</i>
			</div>
		</div>
		<div class="text-center">
			<p class="sm-font tcenter">{{truck.truckid}}</p>
			<p class="sm-font tcenter">{{truck.serialnumber}}</p>
		</div>
		<div class="text-center">
			<div class="btn-group p-5">
				<div class="listing-tags" *ngFor="let tag of truck.tags">
					<button class="btn btn-sm ng-binding" [ngClass]="{'btn-danger': tag.sharingoption == 'private', 'btn-info': tag.sharingoption == 'group', 'btn-default': tag.sharingoption == 'public'}">
					{{tag.tagname}}
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #noTruckviews>
	<div class="panel panel-default">
		<div class="panel-body">
			<strong>No Truckview Registered!</strong>
		</div>
	</div>
</ng-template>