<div class="boxs3 margin-top-lg">
	<div class="row">
		<div class="col-lg-4 col-lg-offset-8">
			<select 
				class="form-control input-sm"
				[(ngModel)]="selectedDateRange"
				(change)="dateRangeChange($event.target.value)">
					<option
						*ngFor="let option of dropDownOptions"
						[value]="option.value">
						{{option.label}}
					</option>
			</select>
		</div>
	</div>
	<div>
		<h4>{{picksText}}</h4>
		<div class="margin-top-sm">
			<label>
				<input 
					type='checkbox'
					name='by_group'
					class="form-control inline-form-control" 
					[(ngModel)]="selectedCheckbox"
					(ngModelChange)="checkboxChange($event)"
						/> Show By Truck Type &nbsp;
			</label>
		</div>
		<div class="margin-top-lg">
			<app-grid-v2 *ngIf="selectedCheckbox"
		   id="battery_picks_reports"
		   [columns]="gridColumnsPerGroup"
		   [data]="gridRows">
		  </app-grid-v2>
		  <app-grid-v2 *ngIf="!selectedCheckbox"
		   id="battery_picks_reports_no_group"
		   [columns]="gridColumnsNoGroup"
		   [data]="gridRows">
		  </app-grid-v2>
		</div>
	</div>
	
	<div class="margin-top-lg">
		<column-chart 
			*ngIf="!selectedCheckbox"
      [data]="chartDataTable"
      [config]="config"
      elementId="act_first_battery_picks_chart">
  	</column-chart>
  </div>
  <div *ngIf="selectedCheckbox">
	  <div class="margin-top-lg" *ngFor="let groupChart of groupCharts">
				<h4>{{groupChart.groupname}}</h4>
				<column-chart 
		      [data]="groupChart.dataTable"
		      [config]="config"
		      [elementId]="groupChart.groupid">
		  	</column-chart>
		  </div>
		</div>
</div>