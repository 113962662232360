import { Injectable } from '@angular/core';
import { CustomerMapComponent } from '../../home/customer-dashboard/customer-map/customer-map.component';
import { CustomerEstimatedCountsComponent } from '../../home/customer-dashboard/customer-estimated-counts/customer-estimated-counts.component';
import { OverallBatteryReplacementComponent } from '../../home/customer-dashboard/customer-reports/overall-battery-replacement/overall-battery-replacement.component';
import { AllSitesBatteryFleetUtilizationComponent } from '../../home/customer-dashboard/customer-reports/all-sites-battery-fleet-utilization/all-sites-battery-fleet-utilization.component';
import { SiteInformationWidgetComponent } from '../../home/site-dashboard/site-info/site-information-widget/site-information-widget.component';
import { SiteNotesWidgetComponent } from '../../home/site-dashboard/site-info/site-notes-widget/site-notes-widget.component';
import { BattviewListWidgetComponent } from '../../home/site-dashboard/site-info/battview-list-widget/battview-list-widget.component';
import { ChargerListWidgetComponent } from '../../home/site-dashboard/site-info/charger-list-widget/charger-list-widget.component';
import * as _ from 'lodash';
import { BatteryAgingComponent } from '../../home/site-dashboard/battviews/site-battery-aging/battery-aging/battery-aging.component';
import { BatteryRemainingWarrantyComponent } from '../../home/site-dashboard/battviews/site-battery-aging/battery-remaining-warranty/battery-remaining-warranty.component';
import { BatteryFleetUtilizationComponent } from '../../home/site-dashboard/battviews/site-battery-performance/battery-fleet-utilization/battery-fleet-utilization.component';
import { EbuDailyUsageComponent } from '../../home/site-dashboard/battviews/site-battery-performance/ebu-daily-usage/ebu-daily-usage.component';
import { EqWeeklySummaryComponent } from '../../home/site-dashboard/battviews/site-battery-performance/eq-weekly-summary/eq-weekly-summary.component';
import { SiteIdleTimesComponent } from '../../home/site-dashboard/battviews/site-battery-performance/site-idle-times/site-idle-times.component';
import { SiteAlertSummaryComponent } from '../../home/site-dashboard/battviews/site-battery-performance/site-alert-summary/site-alert-summary.component';
import { HourlyIdleTimesComponent } from '../../home/site-dashboard/battviews/site-battery-performance/hourly-idle-times/hourly-idle-times.component';
import { PluggedInMissedConnectionComponent } from '../../home/site-dashboard/battviews/site-battery-performance/plugged-in-missed-connection/plugged-in-missed-connection.component';
import { MissedEqsAlertComponent } from '../../home/site-dashboard/battviews/site-alerts/missed-eqs-alert/missed-eqs-alert.component';
import { OverUnderChargeAlertComponent } from '../../home/site-dashboard/battviews/site-alerts/over-under-charge-alert/over-under-charge-alert.component';
import { EbuOverUsageAlertComponent } from '../../home/site-dashboard/battviews/site-alerts/ebu-over-usage-alert/ebu-over-usage-alert.component';
import { MissedFinishAlertComponent } from '../../home/site-dashboard/battviews/site-alerts/missed-finish-alert/missed-finish-alert.component';
import { DeepDischargeAlertComponent } from '../../home/site-dashboard/battviews/site-alerts/deep-discharge-alert/deep-discharge-alert.component';
import { SulfatedBatteryAlertComponent } from '../../home/site-dashboard/battviews/site-alerts/sulfated-battery-alert/sulfated-battery-alert.component';
import { MissedPluginChargeCyclesAlertComponent } from '../../home/site-dashboard/battviews/site-alerts/missed-plugin-charge-cycles-alert/missed-plugin-charge-cycles-alert.component';
import { HighTemperatureAlertComponent } from '../../home/site-dashboard/battviews/site-alerts/high-temperature-alert/high-temperature-alert.component';
import { DeepUseAlertComponent } from '../../home/site-dashboard/battviews/site-alerts/deep-use-alert/deep-use-alert.component';
import { WaterLevelAlertComponent } from '../../home/site-dashboard/battviews/site-alerts/water-level-alert/water-level-alert.component';
import { MissedDailyConnectionAlertComponent } from '../../home/site-dashboard/battviews/site-alerts/missed-daily-connection-alert/missed-daily-connection-alert.component';
import { MissedConnectionPluggedInAlertComponent } from '../../home/site-dashboard/battviews/site-alerts/missed-connection-plugged-in-alert/missed-connection-plugged-in-alert.component';
import { MissedEqsWeeklyAlertComponent } from '../../home/site-dashboard/battviews/site-alerts/missed-eqs-weekly-alert/missed-eqs-weekly-alert.component';
import { IdleTimesAlertComponent } from '../../home/site-dashboard/battviews/site-alerts/idle-times-alert/idle-times-alert.component';
import { SiteBatteriesReportComponent } from '../../home/site-dashboard/battviews/site-reports/site-batteries-report/site-batteries-report.component';
import { SiteEbusReportComponent } from '../../home/site-dashboard/battviews/site-reports/site-ebus-report/site-ebus-report.component';
import { SiteTemperatureReportComponent } from '../../home/site-dashboard/battviews/site-reports/site-temperature-report/site-temperature-report.component';
import { ChargerAssetsUtilizationComponent } from '../../home/site-dashboard/chargers/chargers-performance/charger-assets-utilization/charger-assets-utilization.component';
import { ChargerPeakWhrComponent } from '../../home/site-dashboard/chargers/chargers-performance/charger-peak-whr/charger-peak-whr.component';
import { KwhrsOutputComponent } from '../../home/site-dashboard/chargers/chargers-performance/kwhrs-output/kwhrs-output.component';
import { AhrsReturnedComponent } from '../../home/site-dashboard/chargers/chargers-performance/ahrs-returned/ahrs-returned.component';
import { SiteEnergyConsumptionComponent } from '../../home/site-dashboard/chargers/chargers-performance/site-energy-consumption/site-energy-consumption.component';
import { ChargerSiteAlertsComponent } from '../../home/site-dashboard/chargers/chargers-performance/charger-site-alerts/charger-site-alerts.component';
import { ChargerPluginsComponent } from '../../home/site-dashboard/chargers/chargers-performance/charger-plugins/charger-plugins.component';
import { SiteTrucksReportComponent } from '../../home/site-dashboard/truckviews/site-truck-reports/site-trucks-report/site-trucks-report.component';
import { SiteTruckEbusReportComponent } from '../../home/site-dashboard/truckviews/site-truck-reports/site-truck-ebus-report/site-truck-ebus-report.component';
import { TruckFleetUtilizationComponent } from '../../home/site-dashboard/truckviews/site-truck-performance/truck-fleet-utilization/truck-fleet-utilization.component';
import { TruckEbuDailyUsageComponent } from '../../home/site-dashboard/truckviews/site-truck-performance/truck-ebu-daily-usage/truck-ebu-daily-usage.component';
import { TruckSiteIdleTimesComponent } from '../../home/site-dashboard/truckviews/site-truck-performance/truck-site-idle-times/truck-site-idle-times.component';
import { ColdTemperatureAlertComponent } from '../../home/site-dashboard/battviews/site-alerts/cold-temperature-alert/cold-temperature-alert.component';
import { PlcActivityComponent } from '../../home/site-dashboard/chargers/chargers-performance/plc-activity/plc-activity.component';
import { BattviewPlcActivityComponent } from '../../home/site-dashboard/battviews/site-battery-performance/battview-plc-activity/battview-plc-activity.component';
import { SiteRmaWidgetComponent } from '../../home/site-dashboard/site-info/site-rma-widget/site-rma-widget.component';

export const map = {
	"map-view": CustomerMapComponent,
	"customer-estimated-counts" : CustomerEstimatedCountsComponent,
	"overall-battery-replacement-report": OverallBatteryReplacementComponent,
	"all-sites-battery-fleet-utilization": AllSitesBatteryFleetUtilizationComponent,
	"site-information": SiteInformationWidgetComponent,
	"site-notes": SiteNotesWidgetComponent,
	"site-rma": SiteRmaWidgetComponent,
	"battview-list": BattviewListWidgetComponent,
	"charger-list": ChargerListWidgetComponent,
	"site-battery-aging": BatteryAgingComponent,
	'battery-remaining-warranty': BatteryRemainingWarrantyComponent,
	'battery-fleet-utilization': BatteryFleetUtilizationComponent,
	'ebu-daily-usage-summary': EbuDailyUsageComponent,
	'eq-weekly-summary': EqWeeklySummaryComponent,
	'site-idle-times': SiteIdleTimesComponent,
	'battery-alert-summary': SiteAlertSummaryComponent,
	'hourly-idle-times': HourlyIdleTimesComponent,
	'plugged-in-missed-connection': PluggedInMissedConnectionComponent,
	'missed-eqs': MissedEqsAlertComponent,
	'over-under-charge': OverUnderChargeAlertComponent,
	'ebu-over-usage-alert': EbuOverUsageAlertComponent,
	'missed-fi': MissedFinishAlertComponent,
	'deep-discharge-alerts': DeepDischargeAlertComponent,
	'sulfated-battery-alerts': SulfatedBatteryAlertComponent,
	'missed-plugin-charge-cycles': MissedPluginChargeCyclesAlertComponent,
	'high-temperature-alerts': HighTemperatureAlertComponent,
	'cold-temperature-alerts': ColdTemperatureAlertComponent,
	'deep-use-alerts': DeepUseAlertComponent,
	'water-level-alerts': WaterLevelAlertComponent,
	'missed-daily-connection': MissedDailyConnectionAlertComponent,
	'missed-connection-plugged-in-alert': MissedConnectionPluggedInAlertComponent,
	'missed-eqs-weekly': MissedEqsWeeklyAlertComponent,
	'idle-times-alert': IdleTimesAlertComponent,
	'site-batteries': SiteBatteriesReportComponent,
	'site-ebus': SiteEbusReportComponent,
	'site-temperature': SiteTemperatureReportComponent,
	'charger-assets-utilization':ChargerAssetsUtilizationComponent,
	'charger-peak-whr': ChargerPeakWhrComponent,
	'kwhrs-output': KwhrsOutputComponent,
	'site-energy-consumption': SiteEnergyConsumptionComponent,
	'site-alerts': ChargerSiteAlertsComponent,
	'site-trucks': SiteTrucksReportComponent,
	'site-truck-ebus': SiteTruckEbusReportComponent,
	'truck-fleet-utilization': TruckFleetUtilizationComponent,
	'truck-ebu-daily-usage-summary': TruckEbuDailyUsageComponent,
	'truck-site-idle-times': TruckSiteIdleTimesComponent,
	'plc-activity': PlcActivityComponent,
	'battery-plc-activity': BattviewPlcActivityComponent,
	'ahrs-returned': AhrsReturnedComponent,
	'charger-plugins': ChargerPluginsComponent,
}

let valuesArr = [];
for (let item in map) {
	valuesArr.push(map[item]);
}

export const values = valuesArr;