<div *ngIf="hasData || pageHasRTrecord" id="truckSummaryPage">
	<div class="row">
		<div class="col-md-12">
			<div id ="truck-summary">
				<app-customized-widget
					id="truck_truck_summary"
					[title]="'Truck Summary'"
					[widgetId]="'truck_truck_summary'"
					class="height-auto {{pageIsActive ? '' : 'showOnPrint'}}"
					options="options"
				>
					<div *appTransclude>
						<table class="table table-striped table-condensed table-bordered">
							<tr *ngIf="!isTruckviewMobile">
								<td>Truck ID</td>
								<td>29332 HR</td>
								<td>Truck SN</td>
								<td>4620JC</td>
							</tr>
							<tr>
								<td>Battery Nominal Voltage</td>
								<td>{{truckSummary.truck_voltage}}</td>
								<td>Truck Type</td>
								<td>Truck1</td>
							</tr>
							<tr *ngIf="!isTruckviewMobile">
								<td>Manufacturing Date</td>
								<td>7/1/19 {{truckSummary.extraAgingMessage}}</td>
								<td>Truckview SN</td>
								<td>{{truckSummary.serialnumber}}</td>
							</tr>
							<tr *ngIf="!isTruckviewMobile">
								<td>Truck Warranty Date</td>
								<td>1/1/2020</td>
								<td>Installation Date</td>
								<td>3/15/19</td>
							</tr>
							<tr *ngIf="isTruckviewMobile">
								<td>Study Name</td>
								<td>
									<span *ngIf="!studyNameEditEnabled">
										{{device.studyname}}
										<a *ngIf="userService.hasAccessFunction('act_admin_device_management')" class="pointer" (click)="studyNameEditEnabled = !studyNameEditEnabled;" style="float: right;padding-right: 10px;font-weight: bold;" class="noprint">Edit</a>
									</span>
									<span *ngIf="studyNameEditEnabled">
										<input [(ngModel)]="device.studyname">
										<a (click)="editStudyName()" class="pointer" style="float: right;padding-right: 10px;font-weight: bold;">Done</a>
									</span>
								</td>
								<td>Study Start Date</td>
								<td>{{device.studystartdate | date: "shortDate"}}</td>
							</tr>
							<tr *ngIf="isTruckviewMobile">
								<td>Truckview Mobile SN</td>
								<td>{{device.serialnumber}}</td>
								<td>Study End Date</td>
								<td>{{device.studyEndDate | date: "shortDate"}}</td>
							</tr>
						</table>
					</div>
				</app-customized-widget>
			</div>
		</div>

		<div class="col-md-6" *ngIf="!isTruckviewMobile && pageIsActive">
			<app-customized-widget
				id="baterry_performance_metrics"
				[title]="'Truck Performance Metrics'"
				[widgetId]="'baterry_performance_metrics'"
				options="options"
			>
				<table class="table align-middle truck-performance-table" *appTransclude>
					<colgroup>
						<col style="width:200px;"/>
						<col style="width:30px;"/>
						<col  />
						<col style="width:30px;"/>
					</colgroup>
					<tr>
						<td>Truck Utilization</td>
						<td class="text-center">
							{{truckSummary.truck_utilization}}%
						</td>
						<td>
							<div class="bar-cell">
								<div class="pull-left bar-progress bar-progress--full">
									<div class="bar-progress-green" [ngStyle]="{'width': truckSummary.truck_utilization+'%'}" style="background-color: green !important;"></div>
									<div class="bar-progress-red" [ngStyle]="{'width': 100-truckSummary.truck_utilization+'%'}" style="background-color: gray !important;"></div>
								</div>
							</div>
						</td>
						<td class="text-center">
							{{100-truckSummary.truck_utilization}}%
						</td>
					</tr>
					<tr>
						<td>Max Daily EBU's</td>
						<td class="text-center">
							{{truckSummary.max_daily_ebu}}
						</td>
						<td>
							<div class="bar-cell">
								<div class="pull-left bar-progress bar-progress--full">
									<div class="bar-progress-green" [ngStyle]="{'width': truckSummary.max_daily_ebu/3*100+'%'}" style="background-color: red !important;"></div>
								</div>
							</div>
						</td>
						<td class="text-center">
						</td>
					</tr>
					<tr>
						<td>Average Daily EBU's</td>
						<td class="text-center">
							{{truckSummary.avg_daily_ebu}}
						</td>
						<td>
							<div class="bar-cell">
								<div class="pull-left bar-progress bar-progress--full">
									<div class="bar-progress-green" [ngStyle]="{'width': truckSummary.avg_daily_ebu/5*100+'%'}" style="background-color: blue !important;"></div>
								</div>
							</div>
						</td>
						<td class="text-center">
						</td>
					</tr>
					<tr>
						<td class="no-border">
							<label>
							<input type='checkbox' class="form-control" [(ngModel)]="truckSummary.workingDaysOnly" (change)="workingDaysChanged()" /> Working Days Only
							</label>
						</td>
					</tr>
					<tr>
						<td>Min Daily Idle Times</td>
						<td class="text-center">
							{{truckSummary.min_charge_oppurtinity_duration}}
						</td>
						<td>
							<div class="bar-cell">
								<div class="pull-left bar-progress bar-progress--full">
									<div class="bar-progress-green" [ngStyle]="{'width': truckSummary.min_charge_oppurtinity_width+'%'}" style="background-color: red !important;"></div>
								</div>
							</div>
						</td>
						<td class="text-center">
						</td>
					</tr>
					<tr>
						<td>Max Daily Idle Times</td>
						<td class="text-center">
							00:30
						</td>
						<td>
							<div class="bar-cell">
								<div class="pull-left bar-progress bar-progress--full">
									<div class="bar-progress-green" [ngStyle]="{'width': 30+'%'}" style="background-color: red !important;"></div>
								</div>
							</div>
						</td>
						<td class="text-center">
						</td>
					</tr>
					
				</table>
			</app-customized-widget>
		</div>
		<div class="col-md-6" *ngIf="hasMissedDailyChart && pageIsActive">
			<app-customized-widget
				id="truck_missedDailyChartAlert"
				[title]="(isTruckviewMobile ? 'Daily Charge Opportunities' : 'Total Daily Idle Times')"
				[widgetId]="'truck_missedDailyChartAlert'"
				[customerName]="customerName"
				[siteName]="siteName"
				options="options"
			>
				<div class="alerts-widget" *appTransclude>
					<div class="chart-wrapper">
						<column-chart
							[data]="avgMissedDailyChart.data"
							[config]="avgMissedDailyChart.config"
							elementId="avgMissedDailyChart"
						></column-chart>
					</div>
					<div class="chart-wrapper">
						<column-chart
							[data]="minMissedDailyChart.data"
							[config]="minMissedDailyChart.config"
							elementId="minMissedDailyChart"
						></column-chart>
					</div>
					<div class="chart-wrapper" *ngIf="isTruckviewMobile">
						<column-chart
							[data]="maxMissedDailyChart.data"
							[config]="maxMissedDailyChart.config"
							elementId="maxMissedDailyChart"
						></column-chart>
					</div>
					<div class="chart-wrapper" *ngIf="!isTruckviewMobile && minMissedDailyColumnsItems.length > 0">
						<app-data-list-table
							[title]="(isTruckviewMobile ? 'isTruckviewMobile' : 'Total Daily Idle Times')"
							[items]="minMissedDailyColumnsItems"
							[columns]="minMissedDailyColumns"
							[itemsPerPage]="7"
							[showTableOnPrint]="true"
						></app-data-list-table>
					</div>
				</div>
			</app-customized-widget>
		</div>
		<div class="col-md-6" *ngIf="hasEBUusageChart && pageIsActive">
			<app-customized-widget
				id="truck_ebu_daily_usage_summary"
				[title]="'EBU Daily Usage Summary'"
				[widgetId]="'truck_ebu_daily_usage_summary'"
				options="options"
			>
				<div *appTransclude>
					<column-chart
						[data]="dailyUsageAverageChart.data"
						[config]="dailyUsageAverageChart.config"
						elementId="dailyUsageAverageChart"
					></column-chart>
					<column-chart
						[data]="maxDailyUsageChart.data"
						[config]="maxDailyUsageChart.config"
						elementId="maxDailyUsageChart"
					></column-chart>
				</div>
			</app-customized-widget>
		</div>
		<div class="col-md-6" *ngIf="hasMissedConnectionChart && pageIsActive">
			<app-customized-widget
				id="truck_missedConnectionChartAlert"
				[title]="'Total Daily Missed Connection Times'"
				[widgetId]="'truck_missedConnectionChartAlert'"
			>
				<div class="alerts-widget" *appTransclude>
					<div class="chart-wrapper">
						<column-chart
							[data]="avgMissedConnectionChart.data"
							[config]="avgMissedConnectionChart.config"
							elementId="avgMissedConnectionChart"
						></column-chart>
					</div>
					<div class="chart-wrapper">
						<column-chart
							[data]="minMissedConnectionChart.data"
							[config]="minMissedConnectionChart.config"
							elementId="minMissedConnectionChart"
						></column-chart>
						<div *ngIf="minMissedConnectionColumnsItems.length > 0">
							<app-data-list-table
								[title]="'Total Daily Missed Connection Times'"
								[items]="minMissedConnectionColumnsItems"
								[columns]="minMissedConnectionColumns"
							></app-data-list-table>
						</div>
					</div>
					<div class="chart-wrapper" *ngIf="false">
						<column-chart
							[data]="maxMissedConnectionChart.data"
							[config]="maxMissedConnectionChart.config"
							elementId="maxMissedConnectionChart"
						></column-chart>
					</div>
				</div>
			</app-customized-widget>
		</div>
		<div class="col-md-12" *ngIf="hasIdleTimes && isACTuser && pageIsActive">
			<app-site-widget
				[title]="'Idle Times'"
				elementId="truck_idle_times"
				chartType="column"
				[widgetOptions]="options"
				[data]="idleTimesChart.data"
				[config]="idleTimesChart.config"
				[isSiteWidget]="false"
			></app-site-widget>
		</div>
	</div>
</div>