import { GoogleChartsBaseService } from './google-charts.base.service';
import { Injectable } from '@angular/core';
import { PieChartConfig } from './../Models/PieChartConfig';

declare var google: any;

@Injectable()
export class GooglePieChartService extends GoogleChartsBaseService {

	constructor() { super(); }

	public BuildPieChart(elementId: String, data: any[], config: PieChartConfig) : void {  
		var chartFunc = () => { return new google.visualization.PieChart(document.getElementById(<string>elementId)); };
		var options: any =  {
			chartArea: {
				height: 170
			},
			tooltip: {
				textStyle: {
					fontSize: 12
				}
			},
			height: 190,
			legend: {
				position: 'labeled'
			},
			pieSliceText: 'value',
			slices: config.colors
		};
		if(config.title)
			options.title = config.title;
		if(config.tooltip)
			options.tooltip = config.tooltip;
		if(config.chartArea)
			options.chartArea = config.chartArea;
		if(config.legend)
			options.legend = config.legend;
		if(config.height)
			options.height = config.height;
		if(config.pieSliceText)
			options.pieSliceText = config.pieSliceText;

		options.useUserDefinedFormatter = config.useUserDefinedFormatter;
		if(config.userDefinedFormatter)
			options.userDefinedFormatter = config.userDefinedFormatter;

		this.buildChart(data, chartFunc, options);
	}
}