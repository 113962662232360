import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../../admin.service';

@Component({
	selector: 'app-view-faulted-queue',
	templateUrl: './view-faulted-queue.component.html',
	styleUrls: ['./view-faulted-queue.component.css']
})
export class ViewFaultedQueueComponent implements OnInit {

	log: any;
	id: number;
	deviceType: string;

	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute
	) { }

	ngOnInit() {

		this.id = this.route.snapshot.params.id;
		this.deviceType = this.route.snapshot.params.deviceType;

		this.adminService.getUploadQueueFaultedRecord(this.id, this.deviceType).subscribe((data: any) => {
			data.data = JSON.parse(data.data);
			this.log = data;
		});
	}
}