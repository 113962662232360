<div id="site-info-images-grid" class="boxs2">
	<div class="wrapper">
		<div class="container">
			<div class="grid-container fixed-height" style="display:block;">
				<ul class="rig columns-2">
					<li class="iconic">
						<img src="/images/icon_chargers.png" class="small">
						<h3>{{chargers | number:'1.0-0'}}</h3>
						<p>Quantum Chargers installed</p>
					</li>
					<li class="iconic">
						<img src="/images/icon_batteries.png" class="small">
						<h3>{{battviews | number:'1.0-0'}}</h3>
						<p>BattViews Installed</p>
					</li>
					<li class="iconic">
						<img src="/images/icon_kwhr_used.png" class="small">
						<h3>{{whrsUsed | number:'1.0-2'}}</h3>
						<p>{{whrsUnit}} Used</p>
					</li>
					<li class="iconic">
						<img src="/images/icon_ahrs_used.png" class="small">
						<h3>{{ahrsUsed | number:'1.0-2'}}</h3>
						<p>{{ahrsUnit}} Used</p>
					</li>
					<li class="iconic">
						<img src="/images/icon_charge_cycles.png" class="small">
						<h3>{{chargeCycles | number:'1.0-2'}}{{chargerCyclesUnits}}</h3>
						<p>Charge Cycles Delivered</p>
					</li>
					<li class="iconic">
						<img src="/images/icon_data_records.png" class="small" />
						<h3>{{dataRecords | number:'1.0-1'}}{{dataRecordsUnit}}</h3>
						<p>Events Recorded</p>
					</li>
					<li class="iconic">
						<img src="/images/icon_kwhr_saved.png" class="small" />
						<h3>{{energySavings| number:'1.0-0'}}</h3>
						<p>kWhrs Total Energy Saved</p>
					</li>
					<li class="iconic">
						<img src="/images/icon_co2_saving.png" class="small" />
						<h3>{{co2Savings | number:'1.0-0'}}</h3>
						<p>lbs CO2 Saved</p>
					</li>
					<li class="iconic">
						<img src="/images/icon_trees.png" class="small" />
						<h3>{{co2SavedPerTree| number:'1.0-0'}}</h3>
						<p>Number of Trees Planted</p>
					</li>
				</ul>
			</div>
		</div>	
	</div>
</div>
