<div class="row">
	<div id="page-wrapper">
		<div id="content-header">
			<div class="margin-left">
				<div id="dvCompanyFullName">
					<h3>
						<span>
							<a 
								*ngIf="hasCustomerSitesReportsPage; else notACTuser" 
								[routerLink]="[ '/', customer.id ,'customer-sites-reports', 'default']">{{customer.name}}</a>
							<ng-template #notACTuser>{{customer.name}}</ng-template>
						</span>
					</h3>
				</div>
			</div>
			<div *ngIf="user.isACTuser" class="margin-left">
				<a [routerLink]="['/equipments-devices', 'customer', customer.id]">View Equipments</a>
			</div>
		</div>
		<div id="page-content-wrapper" style="padding: 20px 0 0;" *ngIf="showToggleIntelligentFilter">
			<div class="sitemap">
				<div>
					<div class="row tags-container">
						<div class="col-md-12 margin-left">
							<input
								[checked]="intelligentSitesCheckbox"
								(change)="toggleIntelligentFilter()"
								type="checkbox"
								name="intelligent"
								id="intelligent" />
							<label for="intelligent">ACT Intelligent Enabled Sites Only</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="margin-top-lg"></div>
		<div id="tab-content" class="row">
			<app-widgets-loader key="company" [data]="data" [passAllDataOnce]="true"></app-widgets-loader>
		</div>
	</div>
</div>
