<app-customized-widget 
	*ngIf="!hideWidget && data"
	[title]="title"
	[widgetClass]="widgetClass"
	[widgetId]="elementId"
	[options]="widgetOptions"
	[dropDownGroupList]="dropDownGroupList"
	[checkboxList]="checkboxList"
	[tags]="siteWidgetOptions.isSitePerformanceWidget ? data.siteTags : []"
	(onDropDownChange)="onSelectionChanged($event)"
	(onCheckboxChange)="onSelectionChanged($event)"
	(onPrintClick)="onPrintClick($event)"
	(onExportClick)="exportClick($event)"
	(onTagAdd)="updateSelectedTags($event)"
	(onTagRemove)="updateSelectedTags($event)"
	#customizedWidget
>
	<div *appTransclude>
		<div class="col-xs-4 col-xs-offset-8" *ngIf="siteWidgetOptions.hasDateRange">
			<div class="form-row noprint">
				<div class="form-group col-md-6">
					<label for="inputCity">{{'g.from' | translate}}</label>
					<select class="form-control" id="inputCity" [(ngModel)]="siteWidgetOptions.dateRangeStart" (ngModelChange)="onSelectionChanged(['dateRangeStart', $event])">
						<option [value]="i.value" *ngFor="let i of siteWidgetOptions.dateRangeStartOptions">{{i.label}}</option>
					</select>
				</div>
				<div class="form-group col-md-6">
					<label for="toCity">{{'g.to' | translate}}</label>
					<select class="form-control" id="toCity" [(ngModel)]="siteWidgetOptions.dateRangeEnd" (ngModelChange)="onSelectionChanged(['dateRangeEnd', $event])">
						<option [value]="i.value" *ngFor="let i of siteWidgetOptions.dateRangeEndOptions">{{i.label}}</option>
					</select>
				</div>
			</div>
			<div class="showOnPrint">
				({{siteWidgetOptions.dateRangeStart}} - {{siteWidgetOptions.dateRangeEnd}}) {{'g.weeks' | translate}}
			</div>
		</div>
		<span class="padding-8" *ngIf="siteWidgetOptions.showWidgetSummary && widgetSummary">
			<strong> {{widgetSummary.label}}:</strong> {{widgetSummary.value | number:'1.0-0'}}%
		</span>
		<div class="col-xs-4 col-xs-offset-8 noprint" *ngIf="siteWidgetOptions.hasDataTypeFilter">
			<div class="margin-top-xs">
				<ng-select
					[multiple]="true"
					[addTag]="false"
					[items]="siteWidgetOptions.dataTypes"
					(add)="onSelectionChanged(['dataType'])"
					(remove)="onSelectionChanged(['dataType'])"
					[hideSelected]="true"
					placeholder="Select Data Types"
					bindLabel="label"
					bindValue="id"
					[clearable]="false"
					[(ngModel)]="siteWidgetOptions.dataTypeModel"
				></ng-select>
			</div>
		</div>
		<div class="col-xs-12 margin-top-5">
			<column-chart
				*ngIf="chartType == 'column'"
				[data]="chartData"
				[config]="config"
				[elementId]="'site_'+elementId"
				(onSelectItem)="onSelectItem($event)"
			></column-chart>
			<pie-chart
				*ngIf="chartType == 'pie'"
				[data]="chartData"
				[config]="config"
				[elementId]="'site_'+elementId"
				(onSelectItem)="onSelectItem($event)"
			></pie-chart>
			<combo-chart
				*ngIf="chartType == 'combo'"
				[data]="chartData"
				[config]="config"
				[elementId]="'site_'+elementId"
				(onSelectItem)="onSelectItem($event)"
			></combo-chart>
			<span class="padding-8" *ngIf="siteWidgetOptions.showBarLabels && barLabels && barLabels.label">
				<strong> Date:</strong> {{barLabels.label}}, <strong>KWHRs:</strong> {{barLabels.value}}
			</span>
			<app-data-list-table
				*ngIf="chartType != 'grid' && tableItems.length > 0"
				[title]="title"
				[items]="tableItems"
				[columns]="tableColumns"
				[siteId]="siteId"
				[companyId]="customerId"
			></app-data-list-table>
			<app-grid-v2 *ngIf="chartType == 'grid'" [id]="elementId+'_grid'" [columns]="tableColumns" [data]="chartData"></app-grid-v2>
		</div>
	</div>
</app-customized-widget>

<app-modal #printAlertModal [title]="'Print Widget'" [confirmBtnText]="'Print'" [closeBtnText]="'Cancel'">
	<div>
		<p>What data you want to print?</p>
		<label><input type="radio" [(ngModel)]="printOption" value="all">All Data</label><br />
		<label><input type="radio" [(ngModel)]="printOption" value="alertsOnly">Alerts Only</label><br />
	</div>
</app-modal>