import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { EquipmentsComponent } from './devices/equipments/equipments/equipments.component';
import { DeviceManagementRoutingModule } from './device-management-routing.module';
import { SharedModule } from '../shared/shared.module';
import { EquipmentEditComponent } from './devices/equipments/equipment-edit/equipment-edit.component';
import { SupportLogsComponent } from './support-logs/support-logs.component';
import { DeviceByIdComponent } from './search/device-by-id/device-by-id.component';
import { DeviceByIpComponent } from './search/device-by-ip/device-by-ip.component';
import { DeviceBySnComponent } from './search/device-by-sn/device-by-sn.component';
import { PendingApprovalRequestsComponent } from './pending-approval-requests/pending-approval-requests.component';
import { DaughterCardComponent } from './search/daughter-card/daughter-card.component';
import { DealersOemsHierarchyComponent } from './site-management/dealers-oems-hierarchy/dealers-oems-hierarchy.component';
import { SitesWithoutOwnersComponent } from './site-management/sites-without-owners/sites-without-owners.component';
import { CommissionDaughterCardComponent } from './commission-daughter-card/commission-daughter-card.component';
import { UserReportComponent } from './reporting/user-report/user-report.component';
import { AutoconnectDevicesComponent } from './connectivity/autoconnect-devices/autoconnect-devices.component';
import { DevicesConnectivityComponent } from './connectivity/devices-connectivity/devices-connectivity.component';
import { SitesConnectivityComponent } from './connectivity/sites-connectivity/sites-connectivity.component';
import { BattviewReportComponent } from './reporting/battview-report/battview-report.component';
import { ChargerReportComponent } from './reporting/charger-report/charger-report.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'primeng/tooltip';
import { AllocateProductionLineComponent } from './production-line/allocate-production-line/allocate-production-line.component';
import { ProcessProductionLineComponent } from './production-line/process-production-line/process-production-line.component';
import { ProductionLineIpsComponent } from './production-line/production-line-ips/production-line-ips.component';
import { ReplacementDevicesListingComponent } from './devices/replacement-devices-listing/replacement-devices-listing.component';
import { ReplacementDeviceComponent } from './devices/replacement-device/replacement-device.component';
import { ServicePageComponent } from './service-page/service-page.component';
import { ServciePageTablesComponent } from './service-page/servcie-page-tables/servcie-page-tables.component';
import { DealerDeviceBySnComponent } from './search/dealer-device-by-sn/dealer-device-by-sn.component';
import { PmBySnComponent } from './search/pm-by-sn/pm-by-sn.component';
import { SitesInvoicesReportingComponent } from './sites-invoices-reporting/sites-invoices-reporting.component';
import { HomeModule } from '../home/home.module';
import { BlockedFwReportsComponent } from './blocked-fw-reports/blocked-fw-reports.component';
import { DeviceByPoComponent } from './search/device-by-po/device-by-po.component';
import { SiteInvoicesActivationReminderComponent } from './site-invoices-activation-reminder/site-invoices-activation-reminder.component';
import { TranslateFileLoader } from '../TranslateFileLoader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BattviewsFunctionalTestComponent } from './battviews-functional-test/battviews-functional-test.component';
import { VoltusDispatchesLogComponent } from './voltus-dispatches-log/voltus-dispatches-log.component';
import { PMReportComponent } from './pm-report/pm-report.component';
import { ReportDownloadComponent } from './report-download/report-download.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ProductionPerformanceComponent } from './production-line/production-performance/production-performance.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';

// AoT requires an exported function for factories
export function TranslateFileLoaderFactory() {
	return new TranslateFileLoader();
}
@NgModule({
	imports: [
		FormsModule,
		CommonModule,
		DeviceManagementRoutingModule,
		SharedModule,
		NgSelectModule,
		BsDatepickerModule.forRoot(),
		TooltipModule,
		HomeModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: TranslateFileLoaderFactory,
			}
		}),
		NgxJsonViewerModule,
		Ng2GoogleChartsModule
	],
	declarations: [
		EquipmentsComponent,
		EquipmentEditComponent,
		SupportLogsComponent,
		DeviceByIdComponent,
		DeviceByIpComponent,
		DeviceBySnComponent,
		PendingApprovalRequestsComponent,
		DaughterCardComponent,
		DealersOemsHierarchyComponent,
		SitesWithoutOwnersComponent,
		CommissionDaughterCardComponent,
		AutoconnectDevicesComponent,
		DevicesConnectivityComponent,
		SitesConnectivityComponent,
		UserReportComponent,
		BattviewReportComponent,
		ChargerReportComponent,
		AllocateProductionLineComponent,
		ProcessProductionLineComponent,
		ProductionLineIpsComponent,
		ReplacementDevicesListingComponent,
		ReplacementDeviceComponent,
		ServicePageComponent,
		ServciePageTablesComponent,
		DealerDeviceBySnComponent,
		PmBySnComponent,
		SitesInvoicesReportingComponent,
		BlockedFwReportsComponent,
		DeviceByPoComponent,
		SiteInvoicesActivationReminderComponent,
		BattviewsFunctionalTestComponent,
		VoltusDispatchesLogComponent,
		PMReportComponent,
		ReportDownloadComponent,
		ProductionPerformanceComponent
	]
})
export class DeviceManagementModule { }