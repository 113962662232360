import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceManagementService } from '../device-management.service';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { BattviewService } from '../../admin/devices/battviews/battview.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { CommonUtil } from '../../shared/services/utility/common.service';
import { NavigationService } from '../../shared/services/navigation.service';

@Component({
	selector: 'app-commission-daughter-card',
	templateUrl: './commission-daughter-card.component.html',
	styleUrls: ['./commission-daughter-card.component.css']
})
export class CommissionDaughterCardComponent implements OnInit, OnDestroy {

	daughterSN = "0";
	isEditMode: boolean = false;
	operation = '';
	daughterCard: any = {};

	constructor(
		private deviceManagemntService: DeviceManagementService,
		private breadcrumb: BreadcrumbService,
		private route: ActivatedRoute,
		private battviewService: BattviewService,
		private notificationMessage: NotificationMessageService,
		private commonUtil: CommonUtil,
		private navigation: NavigationService
	) { }

	ngOnInit() {
		this.daughterSN	= this.route.snapshot.params.daughterSN;
		this.isEditMode = this.daughterSN != "0";
		this.operation	= this.isEditMode == false ? 'Commission' : 'Edit';
		this.breadcrumb.setBreadcrumb([
			{'label': 'BATTView Daughter Cards', 'url': ''}
		]);
		
		if(this.isEditMode) {
			this.deviceManagemntService.getBattviewDaughterCard(this.daughterSN).subscribe(
				data => this.daughterCard = data
			);
		}
	}

	saveDaughterCard() {

		if(!this.isEditMode) {

			var rule	= this.battviewService.daughterCardSNRules;
			var SN		= this.daughterCard.serialnumber;
			var isValid	= (typeof(SN) === "string") && this.commonUtil.dataValidator(rule.type, rule, SN);

			if(!isValid) {
				this.notificationMessage.setMessage('Invalid serial number!');
				return;
			}
		}

		this.daughterCard.isEdit = this.isEditMode;
		this.deviceManagemntService.saveDaughterCard(this.daughterCard).subscribe(
			(data: any) => {
				if(data.httpStatus == 'error') {
					
					this.notificationMessage.setMessage(data.msg);
					return;
				}
				this.goBack();
			}
		);
	}

	goBack() {
		this.navigation.goBack();
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}