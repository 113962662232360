import { Component, Input, OnInit, Output, EventEmitter, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import {Subscription} from 'rxjs';

import { GooglePieChartService } from './../Services/google-pie-chart.service';
import { PieChartConfig } from './../Models/PieChartConfig';

declare var google: any;

@Component({
	selector: 'pie-chart',
	template: '<div id="{{elementId}}"></div>'
})
export class PieChartComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

	@Input() data: any[];
	@Input() config: PieChartConfig;
	@Input() elementId: String;
	@Output() onSelectItem = new EventEmitter<any>(true);

	subscription:Subscription;
	_pieChartService: GooglePieChartService = new GooglePieChartService();

	constructor() {}

	ngOnInit(): void {
		if(this.data) {
			this.buildChart();
		}
	}
	ngAfterViewInit() {
		this.subscription = this._pieChartService.selectItem.subscribe(
			item => {
				this.onSelectItem.emit(item);
			}
		);
	}
	ngOnChanges(changes) {
		this.buildChart();
	}
	buildChart() {
		this._pieChartService.BuildPieChart(this.elementId, this.data, this.config);
	}
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}