import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { UserService } from '../../../../auth/user.service';
import { BattviewsDashboardService } from '../battviews-dashboard/battviews-dashboard.service';
import { SiteDashboardService } from '../../../site-dashboard/site-dashboard.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import * as moment from 'moment';
import * as _ from 'underscore';

@Component({
	selector: 'app-battviews-listing',
	templateUrl: './battviews-listing.component.html',
	styleUrls: ['./battviews-listing.component.css']
})
export class BattviewsListingComponent implements OnInit {

	@Input() currentSite: any = {};
	@Input() siteId: number;
	@Input() customerId: number;
	@Input() currentUser: any = {};
	
	battviewsListing: any = {};
	timeoutId;
	hasBattviews: boolean = true;
	connectivityStatusList = [];
	productionIps: any[];

	@Output() battviewsListingReady = new EventEmitter<object>();
	@Output() siteTagsChanged = new EventEmitter<object>();
	@Output() deviceTagsChanged = new EventEmitter<object>();
	@Output() refreshFilter = new EventEmitter();
	@Output() deviceIsChecked = new EventEmitter<boolean>();

	constructor(
		private commonUtil: CommonUtil,
		public userService: UserService,
		private siteDashboard: SiteDashboardService,
		private battviewsDashboard: BattviewsDashboardService,
	) { }

	ngOnInit() {
		this.siteDashboard.setActiveSubTab('batteries-listing');
		this.connectivityStatusList = [
			{label: "Connected", value:"connected" },
			{label: "Disconnected Since 1 Day", value:"disconnected-1-day" },
			{label: "Disconnected Since 1-7 Days", value:"disconnected-1-7-days" },
			{label: "Disconnected Since > 7 days", value:"disconnected-over-7-days" }
		]
	}

	ngOnChanges() {
		if(Object.keys(this.currentSite).length > 0 && this.customerId && this.customerId)
			this.getBatteriesListing();
	}

	getBatteriesListing(isRefresh = false) {

		if(Object.keys(this.battviewsListing).length == 0 || isRefresh) {

			var siteid		= this.siteId;
			var customerid	= this.customerId;

			if(siteid && customerid) {
				let options = {
					customerid: this.customerId,
					siteid: this.siteId,
					getTags: true,
					getProdIps: true,
					getGroups: this.currentSite.act_first_enabled
				};
				this.battviewsDashboard.getBattviews(options).subscribe((response: any) => {

					this.hasBattviews = (Object.keys(response).length > 0);

					if(Object.keys(response).length === 0) {
						this.battviewsListingReady.emit({
							'list': {},
							'hasDevices': false,
							'connectivityStatusList': []
						});
						return;
					}

					var result	= _.sortBy(response.list, 'batteryid');

					var battviewsListing = {};
					var deviceGroups = {};

					let is_site_plc_enabled = response.isSitePLCEnabled || false;

					result.forEach((item) => {

						var labelName = item.nominalvoltage + ' Volts';

						if(!battviewsListing[labelName])
							battviewsListing[labelName] = [];

						deviceGroups[item.id] = item.actfirstgroupid;
						battviewsListing[labelName].push(item);
					});

					var batteriesListingOld = Object.assign({}, this.battviewsListing);
					
					this.battviewsListing	= battviewsListing;
					this.productionIps		= response.productionIps || [];
					let siteTagsById	= {};
					let deviceTags		= {};

					if(response.tags) {
						siteTagsById	= response.tags.siteTags;
						deviceTags		= response.tags.deviceTags;
						this.deviceTagsChanged.emit([deviceTags, deviceGroups]);
					}

					if (Object.keys(batteriesListingOld).length > 0) {
						for (var deviceGroup in this.battviewsListing) {
							this.battviewsListing[deviceGroup].forEach((item, index) => {
								var oldItem = batteriesListingOld[deviceGroup][index] || {};
								item.selected = oldItem.selected;
							});
						}
					}

					this.refreshBatteriesList(2 * 60 * 1000); //every 2 mins

					// list all the battviews as categories based on nominal voltage:
					var nowDate		= response.nowTime;
					var todayTime	= +(moment().utc().startOf('day').format("X"));
					let newListing = {};

					for (var deviceGroup in this.battviewsListing) {

						newListing[deviceGroup] = [];
						this.battviewsListing[deviceGroup].forEach((item) => {
							
							item.isConnected = false;
							var disconnectTime;
							item.connectivityStatus = 'disconnected-over-7-days';
							
							if(this.currentSite.act_intelligent_type != 0) {

								var connectFrequency = +item.actviewconnectfrequency;

								if(connectFrequency == this.siteDashboard.autoconnectFreq)
									connectFrequency = 900;

								var lastconnecttime	= Math.round(new Date(item.lastconnecttime).getTime() / 1000);
								disconnectTime		= nowDate - lastconnecttime; //disconnectTime in seconds

								let isConnected = (disconnectTime <= (2.5 * connectFrequency));
								item.bvStatus = this.battviewsDashboard.getBattviewDisconnectionImageClass(disconnectTime, item.actviewenabled, this.currentSite.act_first_enabled, isConnected);
								if (isConnected) {

									//Less than 2.5 times of normal connectFrequency
									item.isConnected = true;
									item.connectivityStatus = 'connected';
								} else if(disconnectTime <= 24*3600) {

									//More than 24 hrs and less than a week
									item.connectivityStatus = 'disconnected-1-day';
								} else if(disconnectTime <= 7*24*3600) {
									//More than 24 hrs and less than a week
									item.connectivityStatus = 'disconnected-1-7-days';
								}
							} else {

								//More than a week disconnectTime > 7*24*3600
								if(!item.actviewenabled)
									item.bvStatus = 'bv-mobile-not-connected-week';
								else
									item.bvStatus = 'bv-not-connected-week';
							}

							item.hasFault = false;
							item.timespanTextArr = [];
							if(!item.isConnected) {
								if(!item.actviewenabled) {
									item.timespanTextArr.push('Not Connected');
								} else {
									var disconnectedTimeText = this.commonUtil.secondsToElapsedTime(disconnectTime);
					
									item.timespanTextArr.push(disconnectedTimeText ? 'Last connect: ' + disconnectedTimeText : '');
								}
							}

							if (this.userService.hasAccessFunction('display_faults_in_listing_page') && item.mainsenseerrorcode > 0 && item.actviewenabled) {
								var faults = this.commonUtil.getFaultText(item.mainsenseerrorcode, {enablehalleffectsensing: item.enablehalleffectsensing});
								item.hasFault = true;
								item.timespanTextArr = item.timespanTextArr.concat(faults);
							}

							if(this.productionIps.includes(item.ip_address)) {
								item.timespanTextArr.push("Production Line IP");
							}

							if(item.bv_error_code && this.currentUser.isACTuser && this.userService.hasAccessFunction('service_page')) {
								item.timespanTextArr.push(this.commonUtil.getBattviewErrorText(item.bv_error_code));
							}

							if (
								is_site_plc_enabled &&
								this.userService.hasAccessFunction('PLC_error_detection') &&
								item.enableplc
							) {
								var plc_event_time = +(moment(item.plc_event_time).utc().startOf('day').format("X"));
								var hide_plc_fault_date = +(moment(item.hide_plc_fault_date).utc().startOf('day').format("X"));
								var last_charge_cycle_time = +(moment(item.last_charge_cycle_time).utc().startOf('day').format("X"));
								if (todayTime > hide_plc_fault_date) {
									// if plc_event_time is 1-1-1970 and last_charge_cycle_time != 1-1-1970 save a fault called no_plc_actvity
									if (plc_event_time < 1*24*60*60 && last_charge_cycle_time > 1*24*60*60) {
										item.timespanTextArr = item.timespanTextArr.concat('No PLC Activity');
									} else if (last_charge_cycle_time > todayTime - (1*7*24*60*60) && plc_event_time < todayTime - (1*8*24*60*60)) {
										// ELSE if last_charge_cycle_time is within last 1 week, and plc_event_time is older than 8 days..save a fault called plc_warning
										item.timespanTextArr = item.timespanTextArr.concat('PLC Warning');
									}
								}
							}
							
							item.bvStatus += " bv-thumbnail";

							var tags = deviceTags[item.id];
							item.tags = [];
							for (var i = 0; i < tags.length; i++) {
								if (siteTagsById[tags[i].tagid]) {
									let tmpTag = Object.assign({}, tags[i]);
									item.tags.push(Object.assign(tmpTag, siteTagsById[tags[i].tagid]));
								}
							}
							
							item.isShow = true;
							newListing[deviceGroup].push(item);
						});
					}

					// site tags
					if (!isRefresh) {
						let siteGroups = response.groups;
						var siteTags = [];
						for (var tagIndex in siteTagsById) {
							var item = response.tags.siteTags[tagIndex];
							item.isActive = false;
							siteTags.push(item);
						}
						this.siteTagsChanged.emit([siteTags, siteGroups]);
					}
					
					this.battviewsListing = newListing;
					this.battviewsListingReady.emit({
						'list': newListing,
						'hasDevices': this.hasBattviews,
						'connectivityStatusList': this.connectivityStatusList
					});
					if(isRefresh)
						this.refreshFilter.emit();
				});
			}
		}
	}

	getBgClass(battery) {
		var className = "";
		
		if(this.productionIps.includes(battery.ip_address)) {

			className = 'orange-bg';
		} else if((battery.hasFault && this.userService.hasAccessFunction('display_faults_in_listing_page') && battery.actviewenabled && battery.isConnected)
			|| (battery.bv_error_code && this.currentUser.isACTuser && this.userService.hasAccessFunction('service_page'))
		) {

			className = 'red-bg';
		}

		return className;
	}

	deviceChecked(isChecked) {
		this.deviceIsChecked.emit(isChecked);
	}

	refreshBatteriesList(checkFreq) {	
		this.timeoutId = setTimeout(() => {
			if(Object.keys(this.battviewsListing).length > 0)
				this.getBatteriesListing(true);

		}, checkFreq);
	}

	ngOnDestroy() {
		clearInterval(this.timeoutId);
	}

	setListingObject(data) {
		this.battviewsListing = data;
	}
}