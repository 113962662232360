import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import {Howl} from 'howler';
import { HeaderService } from '../../../../shared/header/header.service';
import { BreadcrumbService } from '../../../../shared/breadcrumb/breadcrumb.service';
import { ActFirstService } from '../act-first.service';
import { NotificationMessageService } from '../../../../shared/notification-message/notification-message.service';
import * as _ from 'underscore';

@Component({
	selector: 'app-public-page',
	templateUrl: './public-page.component.html',
	styleUrls: ['./public-page.component.css']
})
export class PublicPageComponent implements OnInit, OnDestroy {

	refreshTimerId;
	buzzing: boolean = false;
	pageConnectivity: boolean = true;
	failedDataFetchCounter: number = 0;
	token: string = '';
	sound: Howl;
	lastAlertTime;
	data = [];
	dataSorted = [];
	site = {};
	misPick;
	getRankEnables: boolean = true;
	siteId = 0;
	password: number;

	constructor(
		private header: HeaderService,
		private breadcrumb: BreadcrumbService,
		private actFirstService: ActFirstService,
		private route: ActivatedRoute,
		private cdref: ChangeDetectorRef,
		private router: Router,
		private notificationMessage: NotificationMessageService
	) {
		this.header.hide();
		this.breadcrumb.hide();
	}

	ngOnInit() {

		this.token = this.route.snapshot.params['token'];
		this.siteId = this.route.snapshot.queryParams['siteId'] || 0;
		let pc = this;
		this.sound = new Howl({
			src: ['/audio/buzz.mp3', '/audio/buzz.webm'],
			format: ['mp3', 'webm'],
			autoUnlock: true,
			loop: true,
			onplayerror: function() {
				this.sound.once('unlock', function() {
					pc.playBuzzer();
				});
			}
		});
		this.lastAlertTime = 0;
		this.getRank(false);

		this.refreshTimerId = setInterval(() => {
			window.location.reload();
		}, 900000);
	}

	playBuzzer() {
		let buzzerTime = 5000;
		this.sound.play();
		this.buzzing = true;
		setTimeout(() => {
			this.buzzing = false;
			this.sound.stop();
		}, buzzerTime);
	}

	getRank(hideLoader) {
		let checkForUpdate = true;
		this.actFirstService.getData(this.token, this.siteId, hideLoader, true).subscribe(data => {
			if (data['siteId']) {
				this.siteId = data['siteId'];
				this.router.navigate(
					['/act-first', 'public', this.token],
					{queryParams: {'siteId': data['siteId']}}
				);
			} else {
				if (this.failedDataFetchCounter) {
					this.failedDataFetchCounter = 0;
					this.setPageAsConnectivityStatus(true);
				}
				data = this.mapResponseData(data);
				this.site = data['site'];
				let hasFirst = false;
				// filter groups without groupname
				data['groups'] = _.filter(data['groups'], function(group){ return group['groupname'];})

				for (let group of data['groups']) {
				 
					group['groupname'] = group['groupname'].toUpperCase();

					let thisGroup = {};
					for (let dataGroup of this.data) {
						if (dataGroup.id == group.id) {
							thisGroup = dataGroup;
							break;
						}
					}

					if (!thisGroup['id']) {
						this.data = data['groups'];
						checkForUpdate = false;
					}

					if (this.data.length % 2  && !hasFirst) {
						group['is_full_row'] = true;
						hasFirst = true;
					} else {
						group['is_full_row'] = false;
					}
					for (let status in group['innergroups']) {
						let thisGroupStatusChargersById = {};
						let thisGroupStatusChargersIds = [];
						let groupStatusChargersIds = [];
						if (checkForUpdate) {
							thisGroupStatusChargersById = _.indexBy(thisGroup['innergroups'][status], 'id');            
							thisGroupStatusChargersIds = _.pluck(thisGroup['innergroups'][status], 'id');
							groupStatusChargersIds = _.pluck(group['innergroups'][status], "id");
							
							let areEquals = _.isEmpty(_.difference(thisGroupStatusChargersIds, groupStatusChargersIds)) && _.isEmpty(_.difference(groupStatusChargersIds, thisGroupStatusChargersIds));
							if (!areEquals) {
								this.data = data['groups'];
								checkForUpdate = false;
								this.cdref.detectChanges();
							}
						}

						for (let charger of group['innergroups'][status]) {
							charger['actfirstgroupid'] = group['id'];
							if (checkForUpdate) {
								for (let key in charger) {
									// update data if there is change in chargers list except for these attributes: ['relaxedBatteryTimer', 'time'];
									if (['relaxedBatteryTimer', 'time'].indexOf(key) == -1) {
										if ( thisGroupStatusChargersById[charger.id][key] != charger[key]) {
											this.data = data['groups'];
											break;
										}
									}
								}
							}
							if (!this.buzzing && charger['alertFlag']) {
								this.playBuzzer();
							}
						}
					}
				}
				this.dataSorted = _.sortBy(this.data, ["is_full_row", "innergroups['ready']"]).reverse();
			}
			setTimeout(() => {
				if(this.getRankEnables)
					this.getRank(true);
			}, 3000);
		}, error => {
			this.failedDataFetchCounter++;
			if (this.failedDataFetchCounter == 30) {
				this.setPageAsConnectivityStatus(false);
			}
			setTimeout(() => {
				if(this.getRankEnables)
					this.getRank(true);
			}, 3000);
		});
	}

	mapResponseData(data: any) {
		let mappedData: any = {};
		mappedData.site = {
			customerid: data.s.cid,
			id: data.s.id,
			pm: data.s.pm
		};
		mappedData.groups = [];
		data.g.forEach((group) => {
			let chargers = [];
			group.c.forEach((charger) => {
				chargers.push({
					id: charger.id,
					alertFlag: charger.af,
					currentSOC: charger.csoc,
					connected: charger.c,
					name: charger.n,
					EQ_p: charger.EQ_p
				});
			});
			mappedData.groups.push(
				{
					groupname: group.gn,
					id: group.id,
					innergroups: {
						ready: chargers
					}
				}
			);
		});

		return mappedData;
	}

	setPageAsConnectivityStatus(isOnline: boolean) {
		this.pageConnectivity = isOnline;
		if (isOnline) {
			if (!this.refreshTimerId) {
				this.refreshTimerId = setInterval(() => {
					window.location.reload();
				}, 900000);
			}
		} else {
			clearInterval(this.refreshTimerId)
		}
	}

	unplugReadyCharger(charger) {
		// update to is manual pick and log as pick
		this.actFirstService.addManualPick({chargerid: charger.id, token:this.token, actfirstgroupid: charger.actfirstgroupid}).subscribe();
	}

	ignorePMMispick() {
		this.actFirstService.ignorePMMispick(this.token, this.siteId, this.password).subscribe(res=> {
			this.password = null;
			if (res) {
				this.notificationMessage.setMessage('globalSuccessMsg');
			} else {
				this.notificationMessage.setMessage('globalAuthMsg');
			}
		});
	}

	ngOnDestroy() {
		this.header.show();
		if (this.refreshTimerId) {
			clearInterval(this.refreshTimerId);
		}
		this.getRankEnables = false;
	}
}