<div class="text-center center-block margin-top-4-per" *ngIf="emptyPMs && !PMs.isConnecting">
	<div class="col-md-5 alert alert-info center-block float-none">
		<h4>
			<strong>{{'charger.not_connected'|translate}}</strong>
		</h4>
	</div>
</div>
<div class="text-center center-block margin-top-4-per" *ngIf="PMs.isConnecting">
	<div class="col-md-5 alert alert-success center-block float-none">
		<h4>
			<strong>{{'charger.connecting_in'|translate}} {{countDown | secondsToDateTime | date:'HH:mm:ss'}}</strong>
		</h4>
	</div>
</div>
<app-grid-v2 *ngIf="!emptyPMs && !PMs.isConnecting"
	id="PMsLiveViewGrid"
	[modelId]="chargerID"
	[columns]="PMsLiveViewGridColumns"
	[data]="PMsLiveViewGridData"
	translateHeaders="true"
></app-grid-v2>