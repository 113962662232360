import { Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../../auth/user.service';
import { NotesService } from '../../../../shared/notes/notes.service';
import { NotificationMessageService } from '../../../../shared/notification-message/notification-message.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-noc-note',
	templateUrl: './noc-note.component.html',
	styleUrls: ['./noc-note.component.css']
})
export class NOCNoteComponent implements OnInit, OnChanges {
	@ViewChild("addNoteModal") addNoteModal;
	@Input() siteId: number;
	@Input() macAddress: string;
	deviceNote: string;

	note: {
		insertion_date: any
		last_connection_time_note: string,
		user_note: string
	} = {
		insertion_date: null,
		last_connection_time_note: '',
		user_note: ''
	};

	formattedNote = '';
	formattedDate: string = '';

	constructor(
		private route: ActivatedRoute,
		private translateService: TranslateService,
		private notificationMessage: NotificationMessageService,
		private notesService: NotesService,
		public userService: UserService,
	){}

	ngOnInit() {
		this.route.params.subscribe(params => {
			this.macAddress = params['chargerID'];

			if (this.userService.hasAccessFunction('noc','read'))
				this.getNOCNote();
		});
	}

	ngOnChanges(changes) {
		if (changes.macAddress && changes.macAddress.currentValue != changes.macAddress.previousValue && this.userService.hasAccessFunction('noc','read'))
			this.getNOCNote();
	}

	getNOCNote() {
		this.notesService.getNOCNote({mac_address: this.macAddress, site_id: this.siteId}).subscribe((data: any) => {
			if(data.note) {
				const { user_note, last_connection_time_note, insertion_date } = data.note;
				this.note = { ...this.note, user_note, last_connection_time_note, insertion_date };
				this.deviceNote = user_note;
				this.updateFormattedNote();
				this.updateFormattedDate();
			}
		})
	}

	updateFormattedNote() {
		const noteList = []

		if (this.note.user_note != '')
			noteList.push(this.note.user_note);

		if (this.note.last_connection_time_note != '')
			noteList.push(this.note.last_connection_time_note);

		this.formattedNote = noteList.join(' / ');
	}

	updateFormattedDate(timeNow = false) {
		if (timeNow)
			return this.formattedDate = moment().format("LLLL");

		if (!this.note.insertion_date)
			return this.formattedDate = '--/--/--';

		this.formattedDate = moment(this.note.insertion_date).format("LLLL");
	}

	ngAfterViewInit() {
		this.addNoteModal.onClose.subscribe(
			(ok) => {
				if(ok) {
					this.updateNOCNote();
				}
			}
		);
	}

	showModal() {
		this.deviceNote = this.note.user_note;
		if (this.userService.hasAccessFunction('noc','write'))
			this.addNoteModal.show();
	}

	updateNOCNote() {
		this.notesService.updateNOCNote({id: this.macAddress, note: this.deviceNote}).subscribe((data: any) => {
			if (data.err)
				return this.notificationMessage.setMessage(this.translateService.instant('g.invalid_input'));

			this.note.user_note = this.deviceNote;
			this.updateFormattedDate(true);
			this.updateFormattedNote();
		})
	}
}
