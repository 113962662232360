<div id="tab-content" class="row settings" *ngIf="runSLider">
	<form class="form-auth">
		<div class="container-fluid no-gutter">
			<div class="boxs3">
				<h2 id="Alerts" class="margin-bottom-xlg">Users Settings</h2>

				<div class="form-group row" *ngIf="(currentUser.isEndClient || currentUser.isTruckDealer) && !userIsSubscribed">
					<alert type="danger">
						<span>
							Contact service dealer to subscribe to the email alerts
						</span>
					</alert>
				</div>

				<div class="form-group row" *ngIf="currentUser.isACTuser">
					<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Select User</label>
					<div class="col-md-7">
						<select class="form-control" name="selectedUser" [(ngModel)]="selectedUser" (ngModelChange)="updateUserAlertsSettings(selectedUser)" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable')">
							<option value="0">Default</option>
							<option *ngFor="let user of subscribedUsers" [value]="user.id">{{user.firstname + ' ' + user.lastname}}</option>
						</select>
					</div>
				</div>
				<div class="form-group row">
					<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Display Truck ID</label>
					<div class="col-md-7">
						<label><input type="checkbox" name="view_truck_id" [(ngModel)]="frequencies.view_truck_id" class="form-control" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable')" /><span> Enabled</span></label>
					</div>
				</div>

				<div class="form-group row" *ngIf="currentSite.act_intelligent_type != 0 && userIsSubscribed">
					<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Email Notification With No Alerts</label>
					<div class="col-md-7">
						<label><input type="checkbox" name="frequencies-enable_no_alert" [(ngModel)]="frequencies.enable_no_alert" class="form-control" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable')" /><span> Enabled</span></label>
					</div>
				</div>

				<!-- <div class="form-group row" *ngIf="currentSite.act_intelligent_type != 0 && userIsSubscribed">
					<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Receiving NOC Emails</label>
					<div class="col-md-7">
						<label><input type="checkbox" name="frequencies-enable_receiving_noc_emails" [(ngModel)]="frequencies.enable_receiving_noc_emails" class="form-control" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable')" /><span> Enabled</span></label>
					</div>
				</div> -->

				<div class="form-group row" *ngIf="currentSite.act_intelligent_type != 0 && userIsSubscribed">
					<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Weekly Dispatch Email Day</label>
					<div class="col-md-7">
						<select class="form-control" name="frequencies-weekly_dispatch_email_day" [(ngModel)]="frequencies.weekly_dispatch_email_day" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable')">
							<option *ngFor="let day of weeklyDispatchDays; let i = index" [value]="i+1">{{day}}</option>
						</select>
					</div>
				</div>

				<div class="form-group row" *ngIf="currentUser.isACTuser && currentSite.act_intelligent_type != 0">
					<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{'battery.bv_charge_events_only_for_charge_ahr' | translate}}</label>
					<div class="col-md-7">
						<label><input type="checkbox" name="frequencies-bv_charge_events_only_for_charge_ahr" [(ngModel)]="frequencies.bv_charge_events_only_for_charge_ahr" class="form-control" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable')" /><span> {{'g.enabled' | translate}}</span></label>
					</div>
				</div>

				<div class="form-group row" *ngIf="currentUser.isACTuser && currentSite.act_intelligent_type != 0">
					<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{'battery.bv_inuse_events_only_for_charge_ahr' | translate}}</label>
					<div class="col-md-7">
						<label><input type="checkbox" name="frequencies-bv_inuse_events_only_for_charge_ahr" [(ngModel)]="frequencies.bv_inuse_events_only_for_charge_ahr" class="form-control" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable')" /><span> {{'g.enabled' | translate}}</span></label>
					</div>
				</div>

				<div class="form-group row" *ngIf="currentSite.act_intelligent_type != 0 && userIsSubscribed">
					<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{'battery.receiving_NOC_emails_frequency' | translate}}</label>
					<div class="col-md-7">
						<ng5-slider class="pointer" [(value)]="frequencies.receive_customer_report_frequency" [options]="slider.options"></ng5-slider>
					</div>
				</div>

				<fieldset class="margin-bottom-xlg" *ngIf="currentSite.act_intelligent_type != 0 && userIsSubscribed">
					<legend class="margin-bottom-lg">Temperature</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Temperature Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.temperature_send_frequency" [options]="weeklySlider.options"></ng5-slider>
						</div>
					</div>
					<div *ngIf="currentUser.isACTuser" class="form-group row">
						<label for="cold-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Cold Temperature Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.cold_temperature_frequency" [options]="weeklySlider.options"></ng5-slider>
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg" *ngIf="currentSite.act_intelligent_type != 0 && userIsSubscribed">
					<legend class="margin-bottom-lg">EBU Over usage</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">EBU Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.ebu_alert_send_frequency" [options]="slider.options"></ng5-slider>
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg" *ngIf="currentUser.isACTuser && currentSite.act_intelligent_type != 0 && userIsSubscribed">
					<legend class="margin-bottom-lg">Site connectivity</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Site Disconnection Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.site_disconnection_frequency" [options]="slider.options"></ng5-slider>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Charger Disconnection Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.charger_disconnection_frequency" [options]="connectivity.charger.isWeekly ? weeklySlider.options : dailySlider.options"></ng5-slider>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">BATTView Disconnection Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.battview_disconnection_frequency" [options]="connectivity.battview.isWeekly ? weeklySlider.options : dailySlider.options"></ng5-slider>
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg" *ngIf="currentSite.act_intelligent_type != 0 && userIsSubscribed">
					<legend class="margin-bottom-lg">Deep usage</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Potential Weak Cells Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.deep_use_frequency" [options]="slider.options"></ng5-slider>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Deep Discharge Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.deep_discharge_frequency" [options]="slider.options"></ng5-slider>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Over Charge Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.over_charge_frequency" [options]="weeklySlider.options"></ng5-slider>
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg" *ngIf="currentSite.act_intelligent_type != 0 && userIsSubscribed">
					<legend class="margin-bottom-lg">Water Level</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Low Water Level Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.water_level_send_frequency" [options]="slider.options"></ng5-slider>
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg" *ngIf="currentSite.act_intelligent_type != 0 && userIsSubscribed">
					<legend class="margin-bottom-lg">Missed Connection</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Missed Connection Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.missed_plugged_in_frequency" [options]="mergeObjects(slider.options, {disabled: (currentUser.isEndClient || currentUser.isTruckDealer)})"></ng5-slider>
						</div>
					</div>

					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Idle Times Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.idle_times_frequency" [options]="mergeObjects(slider.options, {disabled: (currentUser.isEndClient || currentUser.isTruckDealer)})"></ng5-slider>
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg" *ngIf="currentSite.act_intelligent_type != 0 && userIsSubscribed">
					<legend class="margin-bottom-lg">Missed EQ and Finish</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Missed Scheduled EQ Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.missed_eq_send_frequency" [options]="weeklySlider.options"></ng5-slider>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Missed Weekly EQ Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.missed_weekly_eq_send_frequency" [options]="weeklySlider.options"></ng5-slider>
						</div>
					</div>

					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Potentially Sulfated Battery Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.sulfated_battery_frequency" [options]="weeklySlider.options"></ng5-slider>
						</div>
					</div>

					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Missed FI Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.missed_fi_send_frequency" [options]="weeklySlider.options"></ng5-slider>
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg" *ngIf="currentSite.act_intelligent_type != 0 && userIsSubscribed">
					<legend class="margin-bottom-lg">Missed Plugin (#of Charge Cycles)</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Missed Plugin Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.missed_plugin_cycles_alert_frequency" [options]="slider.options"></ng5-slider>
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg" *ngIf="currentUser.isACTuser && currentSite.act_intelligent_type != 0 && userIsSubscribed">
					<legend class="margin-bottom-lg">Charger Faults</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Charger Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.charger_alert_frequency" [options]="slider.options"></ng5-slider>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">PM Faults Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.pm_faults_frequency" [options]="slider.options"></ng5-slider>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Cycle Abnormal Stop Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.cycle_abnormal_stop_frequency" [options]="slider.options"></ng5-slider>
						</div>
						<div class="col-md-7 col-md-offset-5">
							<div class="row">
								<div class="col-md-6">
									<label>
										<input type="checkbox" name="tempCycleAbnormalFaults1" [(ngModel)]="frequencies.tempCycleAbnormalFaults['charger-restart']" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || (currentUser.isEndClient || currentUser.isTruckDealer)" /> Abnormal Charger Restart
									</label>
								</div>
								<div class="col-md-6">
									<label>
										<input type="checkbox" name="tempCycleAbnormalFaults2" [(ngModel)]="frequencies.tempCycleAbnormalFaults['timeout']" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || (currentUser.isEndClient || currentUser.isTruckDealer)" /> Timeout
									</label>
								</div>
								<div class="col-md-6">
									<label>
										<input type="checkbox" name="tempCycleAbnormalFaults3" [(ngModel)]="frequencies.tempCycleAbnormalFaults['aborted-operation']" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || (currentUser.isEndClient || currentUser.isTruckDealer)" /> Aborted Charger Operation
									</label>
								</div>
								<div class="col-md-6">
									<label>
										<input type="checkbox" name="tempCycleAbnormalFaults4" [(ngModel)]="frequencies.tempCycleAbnormalFaults['pm-fauts']" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || (currentUser.isEndClient || currentUser.isTruckDealer)" /> PM Faults
									</label>
								</div>
								<div class="col-md-6">
									<label>
										<input type="checkbox" name="tempCycleAbnormalFaults5" [(ngModel)]="frequencies.tempCycleAbnormalFaults['battery-faults']" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || (currentUser.isEndClient || currentUser.isTruckDealer)" /> Battery Faults
									</label>
								</div>
							</div>
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg" *ngIf="currentSite.act_intelligent_type != 0 && userIsSubscribed">
					<legend class="margin-bottom-lg">Chargers Site Performance</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Charger Utilization Alert Frequency</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.charger_utilization_alert_frequency" [options]="slider.options"></ng5-slider>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70"> {{'user.kwh_alert_frequency' | translate}} </label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="frequencies.kwhrs_alert_frequency" [options]="slider.options"></ng5-slider>
						</div>
					</div>
				</fieldset>

				<div class="form-buttons bvf" *ngIf="!userService.hasAccessFunction('edit_alert_setting_disable')">
					<button class="btn btn-info" (click)="updateAlertsSettings()">Save</button>
					<button class="btn btn-default" (click)="navigation.goBack()">Cancel</button>
				</div>
			</div>
		</div>
	</form>
</div>
