<app-site-widget
	title="Hourly Idle Times"
	elementId="idle_times"
	chartType="column"
	widgetClass="auto-height-widget"
	[siteWidgetOptions]="{hasDate: true, isSitePerformanceWidget: true, hasAverageTimesPerBattview: true}"
	[widgetOptions]="{}"
	[data]="data"
	[config]="config"
	[tableColumns]="tableColumns"
	[customerName]="customerName"
	[siteName]="siteName"
	[siteId]="siteId"
></app-site-widget>