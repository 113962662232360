import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
	selector: 'app-customer-edit',
	templateUrl: './customer-edit.component.html',
	styleUrls: ['./customer-edit.component.css'],
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CustomerEditComponent implements OnInit {

	@Input() customer: any;

	constructor() {
	}

	ngOnInit() {
	}
}