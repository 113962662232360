
import {map} from 'rxjs/operators';
import { 
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router
 } from '@angular/router';

import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { NavigationService } from '../shared/services/navigation.service';
import { UserService } from './user.service';


@Injectable()
export class ActFirstGuard implements CanActivate {
	constructor(private authService: AuthService, 
		private router: Router, 
		private userService: UserService,
		private navigation: NavigationService, 
		private location: Location
		) {}

	canActivate(route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
			return this.authHandler(route);
	}

	private authHandler(route) {
		return this.authService.isAuthenticated(route).pipe(
			map(user => {
				if (user) {
					if (this.userService.hasAccessFunction('act_first_pages', 'write')) {
						return true;
					}
					return false;
				}
				this.navigation.setPreviousUrl(this.location.path());
				this.router.navigate(['login']);
				return false;
			}));
	}

}