<div class="margin-top">
	<div class="margin-top">
		<div class="panel panel-default">
			<div class="panel-body">
				<div class="row" id="filters">
					<label class="col-lg-3 col-form-label col-form-label-lg margin-top-sm">Date Range Filter</label>
					<div 
						class="col-lg-9"
						*ngIf="user.accessFunctions?.enable_full_access_mode ;else notFullAccessMode">
						<div class="row">
							<div class="col-lg-2 input-group">
					<input 
						class="form-control"
						placeholder="mm/dd/yyyy"
						name="dp" 
						[(ngModel)]="date.fromDate"
						bsDatepicker 
						#fromDate="bsDatepicker" 
						[bsConfig]="{ containerClass: 'theme-dark-blue' }">
					<span class="input-group-btn">
					  <button
					  	class="btn btn-default btn-date"
					  	(click)="fromDate.toggle()"
					  	type="button"><i class="fa fa-calendar"></i></button>
					</span>
					</div>
					<div class="col-lg-2 input-group" id="datetimepicker2">
					<input
						class="form-control"
						placeholder="mm/dd/yyyy" 
						name="dp" 
						[(ngModel)]="date.toDate" 
						bsDatepicker 
						#toDate="bsDatepicker"
						[bsConfig]="{ containerClass: 'theme-dark-blue' }">
					<span class="input-group-btn">
						<button class="btn btn-default btn-date" (click)="toDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
					</span>
					</div>
				  </div>
					</div>
				</div>
				<ng-template #notFullAccessMode>
					<div 
						class="row">
						<div class="col-md-4">
							<select 
								[disabled]="isReportGenerated"
							 	class="form-control input-sm"
							 	[(ngModel)]="date.selected">
								<option value="1" label="Last Week">Last Week</option>
								<option value="4" label="Last 4 Weeks">Last 4 Weeks</option>
								<option value="6" label="Last 6 Weeks">Last 6 Weeks</option>
								<option value="8" label="Last 8 Weeks">Last 8 Weeks</option>
							</select>
						</div>
					</div>
				</ng-template>
				<div class="row margin-top">
					<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">Sites Filter</label>
					<div class="col-md-9">
						<span 
							*ngFor="let site of sitesArr"
							class="col-md-6">
							<input 
								[disabled]="isReportGenerated"
								type="checkbox"
								name="{{site.name}}-report" 
								[(ngModel)]="site.hasReport"
								(ngModelChange)="toogleSiteChecked($event)">{{site.name}}
						</span>
					</div>
				</div>
				<div class="row margin-top-xxlg margin-bottom">
					<div class="col-md-12 text-center">
						<button 
							*ngIf="!isReportGenerated"
							class="btn btn-primary"
							(click)="generateReport()"
							[disabled]="countOfSelectedSites <= 0">Generate Report</button>
						<button 
							*ngIf="isReportGenerated" 
							class="btn btn-success" 
							(click)="reGenerateReport()">Filter Report</button>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="isReportGenerated">
			<div *ngFor="let widgetName of widgetsNames">
				<app-customized-widget
					[title]="getWidgetTitle(widgetName)" 
					[widgetId]="widgetReportNameMapper(widgetName)"
					[checkboxList]="[{name: widgetReportNameMapper(widgetName), label: 'Show Percentage', selectedValue: 1}]"
					(onCheckboxChange)="onPercentageCheckboxChanged($event)"
					(onPrintClick)="onPrintClick($event)"
				>
					<div *appTransclude>
						<div *ngIf="ready[widgetReportNameMapper(widgetName)]">
							<line-chart
								(onChartReady)="generateImageURI($event)"
								[data]="chartsDataTables[widgetReportNameMapper(widgetName)]"
								[config]="chartConfig[widgetReportNameMapper(widgetName)]"
								[elementId]="widgetReportNameMapper(widgetName)"
							></line-chart>
							<app-grid-v2
								[id]="widgetReportNameMapper(widgetName)"
								[columns]="gridColumns[widgetReportNameMapper(widgetName)]"
								[data]="gridRows[widgetReportNameMapper(widgetName)]"
								[settings]="{pagination:true}"
							></app-grid-v2>
						</div>
					</div>
				</app-customized-widget>
			</div>
		</div>
	</div>
</div>