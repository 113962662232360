<form name="editEquipment" (ngSubmit)="saveEquipment();" #editEquipment="ngForm">
  <div class="container-fluid" id="main">
    <div class="col-md-8 col-md-offset-2" id="left">
      <div class="boxs3">
        <h2>{{operation}} Equipment</h2>
        <div class="padding-8">
          <label for="equipment_id">Equipment Type</label>
          <ng-select [items]="equipments" bindLabel="name" bindValue="id" placeholder="Select an Equipment Type" name="equipment_id" [(ngModel)]="equipment.equipment_id">
				  </ng-select>
        </div>
        <div class="padding-8">
          <label for="serialnumber">Serial number</label>
          <input type="text" class="form-control" [(ngModel)]="equipment.serialnumber" name="serialnumber" />
        </div>
        <div class="padding-8">
          <label for="description">Description</label>
          <input type="text" class="form-control" [(ngModel)]="equipment.description" name="description" />
        </div>
        <div class="padding-8">
          <label for="shipping_date">Shipping Date</label>
          <div class="input-group">
						<input class="form-control" placeholder="mm/dd/yyyy" name="shipping_date" [(ngModel)]="equipment.shipping_date" bsDatepicker #d="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
            <span class="input-group-btn">
              <button class="btn btn-default btn-date" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
            </span>
          </div>
        </div>
        <div class="padding-8">
          <label for="purchase_date">Purchase Date</label>
          <div class="input-group">
            <input class="form-control" placeholder="mm/dd/yyyy" name="purchase_date" [(ngModel)]="equipment.purchase_date" bsDatepicker #d2="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
            <span class="input-group-btn">
              <button class="btn btn-default btn-date" (click)="d2.toggle()" type="button"><i class="fa fa-calendar"></i></button>
            </span>
          </div>
        </div>
        <br />
        <div>
          <button type="submit" class="btn btn-primary" [disabled]="editEquipment.invalid">Submit</button>
          <button type="button" class="btn btn-default" (click)="navigation.goBack()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</form>