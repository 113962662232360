<form #editDaughterCard="ngForm" (ngSubmit)="saveDaughterCard()">
	<div class="container-fluid" id="main">
		<div class="col-md-8 col-md-offset-2" id="left">
            <div class="boxs3">
				<h2>{{operation}} BATTView Daughter Card</h2>
				<div class="padding-8">
					<label for="serialnumber">Serial Number</label>
					<input [disabled]="isEditMode == true" type="text" class="form-control" [(ngModel)]="daughterCard.serialnumber" name="serialnumber" required/>
				</div>
				<div class="padding-8">
					<label for="pretty_name">Charger Name</label>
					<input type="text" class="form-control" name="pretty_name" [(ngModel)]="daughterCard.pretty_name" required/>
				</div>
				<br />
				<div>
					<button type="submit" class="btn btn-primary" [disabled]="editDaughterCard.invalid">Submit</button>
					<button type="button" class="btn btn-default" (click)="goBack()">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</form>