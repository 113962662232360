import { Component, OnInit } from '@angular/core';
import { SiteDashboardService } from '../site-dashboard.service';

@Component({
	selector: 'app-dynamic-response',
	templateUrl: './dynamic-response.component.html',
	styleUrls: ['./dynamic-response.component.css']
})
export class DynamicResponseComponent implements OnInit {

	constructor(
		private siteDashboardService: SiteDashboardService,
	) { }

	ngOnInit() {
		this.siteDashboardService.setActiveTab('dynamic-response');
	}

}