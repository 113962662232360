import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'underscore';
import { ActivatedRoute } from '@angular/router';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';
import { UserService } from '../../../../auth/user.service';

@Component({
	selector: 'app-battviews-dashboard',
	templateUrl: './battviews-dashboard.component.html',
	styleUrls: ['./battviews-dashboard.component.css']
})
export class BattviewsDashboardComponent implements OnInit {

	isBattviewMobile: boolean = false;
	showSettingsTab: boolean = false;
	hideTemperatureChart: boolean = true;
	hasBattviews: boolean = true;
	hasData: boolean = true;
	siteAlertsSettings: any = {};
	userAlertsSettings: any = {};
	battviewsIDs: any = [];
	viewName: string;
	device: any = {};
	customerInfo: any =  {};
	siteInfo: any =  {};
	currentUser: any = {};
	customerID: number = 0;
	siteID: number = 0;
	battviewID: number = 0;
	date: {
		fromDate: any,
		toDate: any
	} = {
		fromDate: null,
		toDate: null
	};
	activePage: string = null;
	activeTab: string = null;
	activeSubTab: string = null;
	showElementsFlags: any = {};
	pageHasFilterByDate: boolean = false;
	hasResetPLC: boolean = false;
	pageHasBattviewTags: boolean = false;
	pageHasManageBtn: boolean = false;
	pageHasTimesInfo: boolean = false;
	isACTuser: boolean = false;

	@ViewChild("analyticsPageComponent") analyticsPageComponent;
	@ViewChild("battviewSelectCriteriaComponent") battviewSelectCriteriaComponent;
	@ViewChild("quickViewComponent") quickViewComponent;
	@ViewChild("faultsComponent") faultsComponent;
	@ViewChild("debugRecordsComponent") debugRecordsComponent;
	@ViewChild("fetchRtRecordsComponent") fetchRtRecordsComponent;
	@ViewChild("listRtRecordsComponent") listRtRecordsComponent;

	constructor(
		private route: ActivatedRoute,
		private sideMenu: SideMenuService,
		public userService: UserService,
	) {
	}
	
	ngOnInit() {
		this.sideMenu.currentSite.subscribe(data => {
			if(!data.id)
				return;

			this.siteInfo = data;

			this.customerID = this.route.parent.parent.snapshot.params.customerid || 0;
			this.siteID = this.route.parent.parent.snapshot.params.siteid || 0;
			this.activePage = this.route.snapshot.data['pageName'];
			this.viewName = this.route.snapshot.data['viewName'];

			if(this.activePage == 'analytics') {
				this.route.params.subscribe(params => {
					
					this.activeTab = params['tab'];
					this.activeSubTab = params['subTab'];
				});

			} else if(this.activePage == 'settings') {
				this.activeTab = this.activePage;
				this.route.queryParams.subscribe( params => {
					let battviewsIDs = params['battviewsIDs'] || '';

					battviewsIDs = _.map(battviewsIDs.split(','), function(value){ return parseInt(value, 10); });

					if(!battviewsIDs[0]) {
						this.battviewsIDs = [];
					} else {
						this.battviewsIDs = battviewsIDs.filter((elem, pos) => {
							return battviewsIDs.indexOf(elem) == pos && elem;//unique array of IDs
						});
					}
					this.battviewID = Number(this.battviewsIDs[0]) || 0;
				});
			}

			this.sideMenu.currentCustomer.subscribe(customerData => {
				this.customerInfo = customerData;
			});
		
			this.currentUser = this.userService.getCurrentUser();
			this.isACTuser = this.currentUser.isACTuser;
			this.showSettingsTab = this.customerInfo.had_act_intelligence || this.currentUser.isACTuser;
			this.hideTemperatureChart = !this.currentUser.isACTuser || this.isBattviewMobile;
		});
	}
	updateSelectedDevice($event, source: string = ''){
		this.device = $event[0];
		if($event[1])
			this.date = $event[1];

		switch(this.activePage) {
			case 'analytics':
				this.analyticsPageComponent.onDeviceChanged(this.device);
			break;
			case 'quick-view':
				this.quickViewComponent.onDeviceChanged(this.device);
			break;
			case 'faults':
				this.faultsComponent.onDeviceChanged(this.device);
			break;
			case 'fetch-rt-records':
				this.fetchRtRecordsComponent.onDeviceChanged(this.device);
			break;
			case 'list-rt-records':
				this.listRtRecordsComponent.onDeviceChanged(this.device);
			break;
			case 'settings':
				if(source != 'onGetBattviewInfo') {
					this.battviewID = Number(this.device.id) || 0;
					this.battviewsIDs = [this.device.id || 0];
				}
			break;
		}
	}
	updateSelectedDate(date){
		this.date = date;
		switch(this.activePage) {
			case 'analytics':
				this.analyticsPageComponent.onDatesUpdated(date);
			break;
			case 'debug-records':
				this.debugRecordsComponent.onDatesUpdated(date);
			break;
		}
	}
	updateHasBattviews(hasBattviews){
		this.hasBattviews = hasBattviews;
	}

	onResetPLC(reset) {
		if(this.faultsComponent)
			this.faultsComponent.onResetPLC(reset);
	}

	updateAlertSettings(alertSettings){
		this.siteAlertsSettings = alertSettings[0];
		this.userAlertsSettings = alertSettings[1];
	}
	updateTabs(tabs) {
		this.activePage = tabs[0];
		this.activeTab = tabs[1] || null;
		this.activeSubTab = tabs[2] || null;
		this.battviewSelectCriteriaComponent.setDates(this.activePage);
	}

	updateAppearanceElementsFlags(object) {
		this.showElementsFlags = _.extend(this.showElementsFlags, object);
	}
}