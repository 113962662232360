<div class="boxs3">
	<div class="row">
		<a
			*ngFor="let sitegroup of siteGroups"
			class="btn m-5"
			[ngClass]="selectedGroupId == sitegroup.id ? 'btn-primary' : 'btn-default'"
			href="javascript:;"
			(click)="changeSelectedSiteGroup(sitegroup.id)"
		>
			{{sitegroup.text}}
		</a>
	</div>
	<div class="row">
		<div class="col-md-5">
			<div class="center-block">
				<h3 class="text-center">Operational</h3>
				<table class="table table-stripped">
					<thead>
						<tr>
							<th>ID</th>
							<th>Name</th>
							<th>Serial Number</th>
							<th>Move to (In Maintenance)</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let charger of operational">
							<td>{{charger.id}}</td>
							<td><a [routerLink]="['/', charger.customerid, charger.siteid, 'chargers', 'analytics', 'cycles', charger.id]">{{charger.chargerusername}}</a></td>
							<td>{{charger.serialnumber}}</td>
							<td><button (click)="moveTo(charger, ! charger.ignoreactfirstranking)">
								<i class="fa fa-arrow-right"></i>
							</button></td>
						</tr>	
					</tbody>
				</table>
			</div>
		</div>
		<!-- <div class="col-md-1">&nbsp;</div> -->
		<div class="col-md-5 col-md-offset-1">
			<div class="center-block">
				<h3 class="text-center">In Maintenance</h3>
				<table class="table table-stripped">
					<thead>
						<tr>
							<th>ID</th>
							<th>Name</th>
							<th>Serial Number</th>
							<th>Move to (Operational)</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let charger of inMaintenance">
							<td>{{charger.id}}</td>
							<td><a [routerLink]="['/', charger.customerid, charger.siteid, 'chargers', 'analytics', 'cycles', charger.id]">{{charger.chargerusername}}</a></td>
							<td>{{charger.serialnumber}}</td>
							<td><button (click)="moveTo(charger, ! charger.ignoreactfirstranking)">
								<i class="fa fa-arrow-left"></i>
							</button></td>
						</tr>	
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>