<div class="row sitemap">
   <div id="page-content-wrapper">
      <div>
         <div class="boxs2" ng-if="device()!='phone' && device()!='tablet'">
            <div class="row tags-container">
               <div class="col-md-12 margin-left">
                  <input
                     [checked]="intelligentSitesCheckbox"
                     (change)="toggleIntelligentFilter()"
                     type="checkbox"
                     name="intelligent"
                     id="intelligent" />
                  <label for="intelligent">ACT Intelligent Enabled Sites Only</label>
               </div>
               <div class="col-lg-12 margin-left">
                  <span *ngFor="let tag of tags" class="tag label label-info">
                    <span>{{tag.name}}</span>
                    <a><i class="remove glyphicon glyphicon-remove-sign glyphicon-white" (click)="closeExpandedCustomer(tag.id)"></i></a> 
                  </span>
               </div>
            </div>
         </div>
         <app-sites-estimated-counts></app-sites-estimated-counts>
         <div class="col-lg-7 boxs2" ng-if="device()!='phone' && device()!='tablet'">
            <agm-map
             class="map"
             [latitude]="40.513799"
             [longitude]="-101.953125"
             [disableDefaultUI]="true"
             [zoom]="4"
             [streetViewControl]="false"
             [styles]="[{stylers:[{gamma:0.9}]},{featureType:'road',stylers:[{visibility:'off'}]},{featureType:'poi',stylers:[{visibility:'off'}]}]"
             [panControl]="false"
             [zoomControl]="true"
             [zoomControlOptions]="{style:'SMALL', position: 'RIGHT_BOTTOM'}">
               <agm-marker *ngFor="let site of sites"
                [latitude]="site.latitude"
                [longitude]="site.longitude"
                [iconUrl]="site.icon"
                (markerClick)="siteMarkerClick(site)"
                ></agm-marker>
             </agm-map>
         </div>
         <div id="over_map" *ngIf="siteInfoBox.show">
            <a 
              (click)="setInfoBoxVisibility(false)"
              class="icon-close pointer">&#10006;</a>
            <div class="site-info">
               <a 
                class="link"
                [routerLink]="['/', siteInfoBox.customer['id'], siteInfoBox.site['id']]">{{siteInfoBox.customer.name}}/ {{siteInfoBox.site.name}}</a>
               <div class="address" >{{siteInfoBox.site.address}}</div>
               <div class="margin-top margin-bottom">
                  <div class="charger">
                     <img src="images/Charger_3.png" class="margin-right">
                     <span>{{siteInfoBox.site.total_chargers}}</span>
                     : Quantum Chargers
                  </div>
                  <div class="battery">
                     <img src="images/Battery_icon.png" class="margin-right">
                     <span>{{siteInfoBox.site.total_battviews}}</span>
                     : BATTViews
                  </div>
                  <div class="intelligent" *ngIf="siteInfoBox.actIntelligent">
                     <img src="images/ACTintelligent.png" class="margin-right">
                     ACT Intelligent
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>