<div class="row">
	<div id="page-wrapper" >
		<div id="page-inner">
			<div class="panel panel-default">
				<div class="boxs3">
					<table class="table table-stripped boxs" *ngIf="log">
						<tbody>
							<tr></tr>
							<tr>
								<td>ID</td>
								<td>{{log.id}}</td>
							</tr>
							<tr>
								<td>Time</td>
								<td>{{log.time_of_request}}</td>
							</tr>
							<tr>
								<td>Device ID</td>
								<td>{{log.device_id}}</td>
							</tr>
							<tr>
								<td>Extra Device ID</td>
								<td>{{log.extra_device_id}}</td>
							</tr>
							<tr>
								<td>Data</td>
								<td>{{log.data | json}}</td>
							</tr>
							<tr>
								<td>User ID</td>
								<td>{{log.user_id}}</td>
							</tr>
							<tr>
								<td>Unlock User ID</td>
								<td>{{log.unlock_user_id}}</td>
							</tr>
							<tr>
								<td>Request Type</td>
								<td>{{log.request_type}}</td>
							</tr>
							<tr>
								<td>Flag</td>
								<td>{{log.flag}}</td>
							</tr>
							<tr>
								<td>Upload Error</td>
								<td>{{log.upload_error}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>