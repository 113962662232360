import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CustomerDashboardService {
    
    public checkBoxSubject:Subject<any> = new Subject();

	constructor() {}

	addSitesConnectivityStatusIcons(sites) {
		let icons = {
			white: '/images/wht-circle-lv.png',
			green: '/images/green-dot.png',
			yellow: '/images/yellow-dot.png',
			red: '/images/red-dot.png',
			blue: '/images/blue-dot.png',
		};
		let blueCustomerIdsList = [240];
		sites.map((site) => {
			if(blueCustomerIdsList.indexOf(site.customerid) > -1 || site.site_subscription_type == 'Demo') {
				site.icon = icons.blue;
			} else if (site.act_intelligent_type == 0) {
				site.icon = icons.white;
			} else {
				switch (site.connectivity) {
					case 'connectedNow':
						site.icon = icons.green;
						break;
					case 'connected':
						site.icon = icons.yellow;
						break;
					default:
						site.icon = icons.red;
						break;
				}
			}
		});
		return sites;
    }
    
    setIntelligentSitesCheckbox(value) {
		this.checkBoxSubject.next(value);
	}
}