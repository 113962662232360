<div class="panel panel-default">
	<div class="panel-body">
		<div *ngIf="hasBattviews">
			<div *ngIf="newStudyEnabled" class="boxs margin-top-5">
				<div class="padding-8">
					<label for="studyname">Study Name</label>
					<input type="text" class="form-control" [(ngModel)]="newStudy.studyname" placeholder="Study Name" name="studyname"/>
				</div>
				<div class="padding-8">
					<label for="truckid">Truck ID</label>
					<input type="text" class="form-control" [(ngModel)]="newStudy.truckid" placeholder="Truck ID" name="truckid"/>
				</div>
				<div>
					<button (click)="startNewStudy()" class="btn btn-default" [disabled]="!newStudy.truckid || !newStudy.studyname">Start Study</button>
					<button (click)="closeNewStudyBox()" class="btn btn-default">Close</button>
				</div>
			</div>
			<div *ngFor="let modelType of batteriesListing | keys" class="row boxs3">
				<div class="panel panel-default">
					<div class="panel-heading"><strong>{{modelType}} Batteries</strong></div>
				</div>
				<div *ngFor="let battery of batteriesListing[modelType]" class="l" [ngClass]="{red: battery.hasFault && userService.hasAccessFunction('display_faults_in_listing_page') && battery.actviewenabled && battery.isConnected}">
					<div>
						<div class="btn-group pointer" appDropdown>
							<a type="button" style="display: inline-block;" [pTooltip]="battery.timespanTextArr.join('\n')" tooltipPosition="top" tooltipStyleClass="device-changes-log">
								<p [ngClass]="battery.bvStatus"></p>
							</a>
							<ul class="z-index-10k dropdown-menu" role="menu" appDropdown>
								<li *ngIf="battery.hasExtraActions">
									<a routerLink="/battview-mobile/settings" [queryParams]="{battviewsIDs: battery.id+'-'+battery.latestStudy.studyid}">Settings</a>
								</li>
								<li>
									<a routerLink="/battview-mobile/analytics/battery-summary" [queryParams]="{'battviewId': battery.id, 'studyId': battery.latestStudy.studyid}">Analytics</a>
								</li>
								<li *ngIf="battery.hasExtraActions && battery.serialnumber">
									<a (click)="closeNewStudyBox(true, battery.id)">Start New Study</a>
								</li>
								<li class="dropdown-submenu" appDropdown>
									<a tabindex="-1">
										<span>Notes
										<i class="fa fa-caret-right margin-left-sm"></i>
										</span>
									</a>
									<ul class="dropdown-menu" role="menu">
										<li>
											<a routerLink="/battview-mobile/notes" [queryParams]="{'battviewId': battery.id, 'studyId': battery.latestStudy.studyid}">View Notes</a>
										</li>
										<li class="pointer">
											<a>
												<notes
													modelType="battview mobile"
													sourcePage='battview_mobile'
													[modelId]="battery.id"
													[studyId]="battery.latestStudy.studyid"
													[isDropDown]="true"
													[isAddNoteOnly]="true"
												></notes>
											</a>
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
					<p class="sm-font tcenter" *ngIf="battery.latestStudy">{{battery.latestStudy.studyname}}</p>
					<p class="sm-font tcenter" *ngIf="battery.latestStudy">{{battery.latestStudy.truckid}}</p>
					<p class="sm-font tcenter" *ngIf="battery.latestStudy">{{battery.lasteventtime | date:'MM/dd/yyyy'}}</p>
				</div>
			</div>
		</div>

		<div *ngIf="!hasBattviews">
			<div class="panel panel-default">
				<div class="panel-body">
					<strong>No Battview Registered!</strong>
				</div>
			</div>
		</div>
	</div>
</div>