import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActFirstService } from '../../act-first.service';
import * as moment from 'moment';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { Site } from '../../../../../shared/models/site.model';
import { Subscription } from 'rxjs';
import { SideMenuService } from '../../../../../shared/side-menu/side-menu.service';
import { TranslateService } from '@ngx-translate/core';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';

@Component({
	selector: 'app-parked-batteries',
	templateUrl: './parked-batteries.component.html',
})
export class ParkedBatteriesComponent implements OnInit, OnDestroy {

	startDate: any;
	endDate: any;

	startDateUnix;

	gridRows = [];
	gridColumns = [];
	reportNotReadyYet = null;
	site: Site;
	dataFetched = false;
	currentSiteSub: Subscription = new Subscription();

	chartDataTable = [];
	chartDataTableHeader = [];
	chartConfig: ColumnChartConfig;
	image;

	constructor(
		private actFirstService: ActFirstService,
		private commonUtil: CommonUtil,
		private sideMenuService: SideMenuService,
		private translateService: TranslateService,
		private chartUtil: ChartUtil
	) { }

	ngOnInit() {
		this.startDateUnix	= moment().utc().subtract(1, 'days').startOf('day').unix();

		this.currentSiteSub	= this.sideMenuService.currentSite.subscribe(siteInfo => {
			if(siteInfo.id) {
				this.site = siteInfo;
				let siteTimeNow		= this.commonUtil.getZoneTimestampFromUTC(this.site.zoneid, moment().utc().unix(), true);
				let siteTimeHour24	= moment(siteTimeNow*1000).utc().format('H'); // 0...23
				if(+siteTimeHour24 < 8) // if site time now is less than 8 afternoon, set default day the day before yesterday.
					this.startDateUnix = moment().utc().subtract(2, 'days').startOf('day').unix();

				this.startDate	= new Date(this.startDateUnix * 1000);

				this.gridColumns = [
					{
						headerName: this.translateService.instant('device.charger_id'),
						field: "chargerusername",
						cellRendererSelector:function (params) {
							params['label'] = params.data.chargerusername;
							params['link'] = ["/#", params.colDef.cellRendererParams.customerId, params.colDef.cellRendererParams.siteId, "chargers", "analytics", "cycles", params.data.chargerid].join('/');
							return {
								component: 'linkCellRenderer',
								params: params
							};
						},
						cellRendererParams: {customerId: this.site.customerid, siteId: this.site.id},
					},
					{
						headerName: this.translateService.instant('device.battery_id'),
						field: "batteryid",
						cellRendererSelector:function (params) {
							params['label'] = params.data.batteryid;
							params['link'] = ["/#", params.colDef.cellRendererParams.customerId, params.colDef.cellRendererParams.siteId, "battviews", "analytics", "battery-summary", params.data.battviewid].join('/');
							return {
								component: 'linkCellRenderer',
								params: params
							};
						},
						cellRendererParams: {customerId: this.site.customerid, siteId: this.site.id}
					},
					{headerName: this.translateService.instant("device.cycle_id"),	field: "cycleid", type: "number"},
					{headerName: this.translateService.instant("device.cycle_time"),	field: "cycletime"},
					{headerName: this.translateService.instant("device.duration"),	field: "duration"},
					{
						headerName: this.translateService.instant("act_first.parked_reason"),
						field: "statusFormatted",
						floatingFilterComponent: "dropDownFilterComponent",
						floatingFilterComponentParams: {
							dropDownOptions: [
								{label: '', value: null},
								{value: this.translateService.instant('act.battery_faulted'), label: this.translateService.instant('act.battery_faulted')},
								{value: this.translateService.instant('act.charger_faulted'), label: this.translateService.instant('act.charger_faulted')},
								{value: this.translateService.instant('act.user_faulted'), label: this.translateService.instant('act.user_faulted')},
							]
						},
					},
					{headerName: this.translateService.instant("device.charger_exit"),	field: "exitStatus"},
					{headerName: this.translateService.instant("device.profiles"),		field: "profiles"},
					{headerName: this.translateService.instant("device.truck_type"),		field: "actfirstgroup"},
				];
			}
		});

		this.chartConfig = new ColumnChartConfig({
			isPercent: false,
			explorer: {
				'axis': 'horizontal',
				'actions': ['dragToZoom', 'rightClickToReset'],
				maxZoomIn: 50.0,
				keepInBounds: true
			},
			tooltip:{},
			vAxisMinValue: 0,
			vAxisMaxValue: 2,
			xTitle: this.translateService.instant('act_first.parked_reason'),
			yTitle: this.translateService.instant('g.count')
		});

		this.chartDataTableHeader = [
			{ type: "string", label: this.translateService.instant('act_first.parked_reason') },
			{ type: "number", label: this.translateService.instant("g.count") }
		];
	}

	fetchData(){
		this.dataFetched = false;
		let fromDate:any;

		fromDate = new Date(new Date(this.startDate).setHours(0, 0, 0, 0));
		fromDate = Math.floor((-fromDate.getTimezoneOffset() * 60000 + fromDate.getTime()) / 1000);

		this.actFirstService.getParkedBatteriesReport(this.site.id, {fromDate}).subscribe((data:any)=> {
			this.dataFetched = true;
			if(!data.data)
				return this.reportNotReadyYet = true;

			this.reportNotReadyYet = false;

			this.formatData(data);
		});
	}

	formatData(data) {
		let cyclesData	= data.data.cycle_events || [];
		let chargers	= data.chargers || {};
		let battviews	= data.battviews || {};
		let siteGroups	= data.siteGroups || {};

		let parkedReasonCounts = {
			user_faulted:		0,
			charger_faulted:	0,
			battery_faulted:	0
		};
		this.gridRows = [];
		this.chartDataTable = [this.chartDataTableHeader];

		for(let cycle of cyclesData){
			cycle.cycletime			= moment(cycle.cycletime * 1000).utc().format('MM/DD/YYYY hh:mm:ss A');
			cycle.batteryid			= '';
			cycle.chargerusername	= '';
			cycle.actfirstgroup		= '';
			cycle.actfirstgroupid	= 0;

			if(chargers[cycle.chargerid]){
				cycle.chargerusername = chargers[cycle.chargerid].chargerusername + ' (' + chargers[cycle.chargerid].serialnumber + ')';
				cycle.actfirstgroupid = chargers[cycle.chargerid].actfirstgroupid;
			}

			if(battviews[cycle.battviewid])
				cycle.batteryid = battviews[cycle.battviewid].batteryid + ' (' + battviews[cycle.battviewid].serialnumber + ')';

			if(siteGroups[cycle.actfirstgroupid])
				cycle.actfirstgroup = siteGroups[cycle.actfirstgroupid].text;

			if(parkedReasonCounts.hasOwnProperty(cycle.statusAsKey))
				parkedReasonCounts[cycle.statusAsKey]++;

			cycle.statusFormatted = this.translateService.instant('act.'+cycle.statusAsKey);
			this.gridRows.push(cycle);
		}

		for (let parkedReason in parkedReasonCounts){
			this.chartDataTable.push([this.translateService.instant('act.'+parkedReason), parkedReasonCounts[parkedReason]]);
		}
	}

	generateImageURI(event) {
		this.image	= this.chartUtil.getChartImageUri(event['chart']);
	}

	printChart() {
		let chartTitle = this.translateService.instant('act_first.parked_batteries_report')+' ('+moment(this.startDate, 'MM/DD/YYYY').utc().format('MM/DD/YYYY')+')'
		let printContent = '<h4>' + chartTitle + '</h4><br><br><img src="'+this.image+'" style="width:100%;"" border="0" alt="Null">';

		this.commonUtil.print({
			appendPrintContent: printContent,
		});
	}

	ngOnDestroy() {
		this.currentSiteSub.unsubscribe();
	}
}