<div *ngIf="viewName == 'dashboard'">
	<app-battview-tabs #tabs
		[isBattviewMobile]="isBattviewMobile"
		[date]="date"
		[device]="device"
		[showSettingsTab]="showSettingsTab"
		[hideTemperatureChart]="hideTemperatureChart"
		[activePage]="activePage"
		[activeTab]="activeTab"
		[activeSubTab]="activeSubTab"
		[currentUser]="currentUser"
		[customerId]="customerID"
		[siteId]="siteID"
		[hasData]="hasData"
		(onTabChanged)="updateTabs($event)"
		(onExport)="analyticsPageComponent.onExport()"
	></app-battview-tabs>
	<app-battview-select-criteria
		#battviewSelectCriteriaComponent
		*ngIf="activePage != 'settings' || (activePage == 'settings' && battviewsIDs.length <= 1)"
		[currentUser]="currentUser"
		[customerId]="customerID"
		[siteId]="siteID"
		[battviewID]="battviewID"
		[activePage]="activePage"
		[activeTab]="activeTab"
		[activeSubTab]="activeSubTab"
		[showElementsFlags]="showElementsFlags"
		[isACTuser]="isACTuser"
		[siteInfo]="siteInfo"
		(onDeviceChanged)="updateSelectedDevice($event)"
		(onDateChanged)="updateSelectedDate($event)"
		(updateHasBattviews)="updateHasBattviews($event)"
		(updateAlertSettings)="updateAlertSettings($event)"
		(onResetPLC)="onResetPLC($event)"
	></app-battview-select-criteria>
</div>
<div [ngSwitch]="activePage" *ngIf="hasBattviews">
	<app-battview-analytics #analyticsPageComponent
		*ngSwitchCase="'analytics'"
		[customerID]="customerID"
		[siteID]="siteID"
		[device]="device"
		[date]="date"
		[activeTab]="activeTab"
		[activeSubTab]="activeSubTab"
		[isBattviewMobile]="isBattviewMobile"
		[siteInfo]="siteInfo"
		[customerInfo]="customerInfo"
		[currentUser]="currentUser"
		[siteAlertsSettings]="siteAlertsSettings"
		[userAlertsSettings]="userAlertsSettings"
		[hideTemperatureChart]="hideTemperatureChart"
		(updateHasData)="hasData = $event"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-battview-analytics>
	<app-battview-quick-view #quickViewComponent
		*ngSwitchCase="'quick-view'"
		[device]="device"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-battview-quick-view>
	<app-battviews-faults #faultsComponent
		*ngSwitchCase="'faults'"
		[device]="device"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-battviews-faults>
	<app-device-notes
		*ngSwitchCase="'notes'"
		[device]="device"
		deviceType="battview"
		sourcePage="battview_notes"
		[siteId]="siteID"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-device-notes>
	<app-debug-records #debugRecordsComponent
		*ngSwitchCase="'debug-records'"
		[device]="device"
		[date]="date"
		[deviceType]="'battview'"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-debug-records>
	<app-config-track #configTrackComponent
		*ngSwitchCase="'config-track'"
		[device]="device"
		[deviceType]="'battview'"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-config-track>
	<app-fetch-rt-records #fetchRtRecordsComponent
		*ngSwitchCase="'fetch-rt-records'"
		[device]="device"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-fetch-rt-records>
	<app-list-rt-records #listRtRecordsComponent
		*ngSwitchCase="'list-rt-records'"
		[device]="device"
		[siteName]="siteInfo.name"
		[companyName]="customerInfo.name"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-list-rt-records>
	<app-connectivity-track #connectivityTrackComponent
		*ngSwitchCase="'connectivity-track'"
		[hasDevices]="true"
		[device]="device"
		[deviceType]="'battview'"
		[dateRange]="{
			'fromDateFmt': date?.fromDate,
			'toDateFmt': date?.toDate
		}"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-connectivity-track>
	<app-devices-listing *ngSwitchCase="'listing'" deviceType="battview"></app-devices-listing>
	<app-battview-settings *ngSwitchCase="'settings'" [battviewsIDs]="battviewsIDs" [customerID]="customerID" [siteID]="siteID" [hasBattviews]="hasBattviews" (onGetBattviewInfo)="updateSelectedDevice([$event], 'onGetBattviewInfo')" (onMultipleOptionsFlag)="battviewSelectCriteriaComponent.multipleOptions = $event" (updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)" [isBattviewMobile]="isBattviewMobile"></app-battview-settings>
</div>
<div *ngIf="!hasBattviews">
	<div class="panel panel-default">
		<div class="panel-body">
			<strong>No Battview Registered!</strong>
		</div>
	</div>
</div>