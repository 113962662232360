<div class="panel panel-default margin-bottom-xs" *ngIf="activePage == 'lcd-history'">
	<div class="panel-body">
		<div *ngIf="!cycleId">
			<span class="col-md-5">{{'g.start' |translate}}: <input type="datetime-local" step="1" class="form-control inline-form-control" [(ngModel)]="snapshotStart"></span>
			<span class="col-md-5">{{'g.end' |translate}}: <input type="datetime-local" step="1" class="form-control inline-form-control" [(ngModel)]="snapshotEnd"></span>
			<span class="col-md-2"><button class="form-control inline-form-control btn btn-success" (click)="getLCDhistory()" [disabled]="!snapshotStart || !snapshotEnd">{{'charger.get_video' |translate}}</button></span>
		</div>
		<div *ngIf="cycleId">
			<h5>
				<strong>{{'charger.cycle_id' |translate}}: </strong>{{cycleId}}
			</h5>
		</div>
	</div>
</div>
<div class="text-center center-block margin-top-4-per" *ngIf="LCDcharger.isSupported && LCD.isConnecting">
	<div class="col-md-5 alert alert-success center-block float-none">
		<h4>
			<strong>{{'charger.connecting_in' |translate}} {{countDown | secondsToDateTime | date:'HH:mm:ss'}}</strong>
		</h4>
	</div>
</div>
<div class="text-center center-block margin-top-4-per" *ngIf="LCDcharger.isSupported === false">
	<div class="col-md-5 alert alert-warning center-block float-none">
		<h4>
			<strong>{{'charger.not_supported' |translate}}</strong>
		</h4>
	</div>
</div>
<div class="text-center center-block margin-top-4-per" *ngIf="emptyLCD && LCDcharger.isSupported && !LCD.isConnecting">
	<div class="col-md-5 alert alert-info center-block float-none">
		<h4>
			<strong>{{'charger.not_connected' |translate}}</strong>
		</h4>
	</div>
</div>
<div class="panel panel-default margin-bottom-xs" *ngIf="!emptyLCD && LCDcharger.isSupported && !LCD.isConnecting">
	<div class="panel-body" *ngIf="LCD"> 
		<div class="col-md-12 tcenter padding-8" [ngClass]="LCD.batviewOn==0?'bg-black':(LCD.batviewOn==1?'bg-blue':'bg-grey')"> <!-- TOP BAR -->
			<div class="col-md-4"> <!-- battviewID -->
				<strong class="white-text" [ngClass]="{'pointer': !LCD.haveBatteryModel}" *ngIf="LCD.batviewOn > 0 || LCD.runWithBMS" id="battview-analytics-url" (click)="goToBattviewAnalytics()">{{getBatteryId()}}</strong>
			</div>
			<div class="white-text col-md-4"> <!-- charger time -->
				{{LCD.LCDtimestamp}}
			</div>
			<div class=" white-text col-md-4"> <!-- charger user name -->
				{{LCDcharger.chargerusername}}
			</div>
		</div>
		<div class="col-md-1"> <!-- LEFT BAR -->
			<div class="col-md-12"> <!-- Warning sign -->
				<img *ngIf="LCD.warningSign == 'ACline'" src="./images/MCB_LCD/Side%20Bar/1-Warning%20Sign/V4_N_IMAGE_AC_LINE_63X63.bmp"/>
				<img class="pointer white-tooltip" *ngIf="LCD.warningSign == 'fault'" src="./images/MCB_LCD/Side%20Bar/1-Warning%20Sign/IMAGE_FAULT_INDICATOR_63X63.bmp" [pTooltip]="warningSignTooltip" tooltipPosition="right"/>
				<img *ngIf="LCD.warningSign == 'info'" src="./images/MCB_LCD/Side%20Bar/1-Warning%20Sign/IMAGE_FAULT_INFO_63X63.png"/>
			</div>
			<div class="col-md-12"> <!-- Battery sign -->
				<img *ngIf="LCD.batterySign == 'noBattery'" src="./images/MCB_LCD/Side%20Bar/2-%20Battery%20Sign/a-%20Not%20connected/IMAGE_NO_BATTERY_63X63.bmp"/>
				<img *ngIf="LCD.batterySign == 'unknown'" src="./images/MCB_LCD/Side%20Bar/2-%20Battery%20Sign/b-%20Battery%20connected%20but%20Unkown/IMAGE_UNKOWN_BATTERY_63X63.bmp"/>
				
				<img *ngIf="LCD.batterySign == 'battery0'" src="./images/MCB_LCD/Side%20Bar/2-%20Battery%20Sign/c-%20Battery%20connected%20charger%20not%20running/IMAGE_BATTERY_00_63X63.bmp"/>
				<img *ngIf="LCD.batterySign == 'battery20'" src="./images/MCB_LCD/Side%20Bar/2-%20Battery%20Sign/c-%20Battery%20connected%20charger%20not%20running/IMAGE_BATTERY_20_63X63.bmp"/>
				<img *ngIf="LCD.batterySign == 'battery40'" src="./images/MCB_LCD/Side%20Bar/2-%20Battery%20Sign/c-%20Battery%20connected%20charger%20not%20running/IMAGE_BATTERY_40_63X63.bmp"/>
				<img *ngIf="LCD.batterySign == 'battery60'" src="./images/MCB_LCD/Side%20Bar/2-%20Battery%20Sign/c-%20Battery%20connected%20charger%20not%20running/IMAGE_BATTERY_60_63X63.bmp"/>
				<img *ngIf="LCD.batterySign == 'battery80'" src="./images/MCB_LCD/Side%20Bar/2-%20Battery%20Sign/c-%20Battery%20connected%20charger%20not%20running/IMAGE_BATTERY_80_63X63.bmp"/>
				<img *ngIf="LCD.batterySign == 'battery100'" src="./images/MCB_LCD/Side%20Bar/2-%20Battery%20Sign/c-%20Battery%20connected%20charger%20not%20running/IMAGE_BATTERY_100_63X63.bmp"/>
				
				<img *ngIf="LCD.batterySign == 'noCharge0'" src="./images/MCB_LCD/Side%20Bar/2-%20Battery%20Sign/d-%20Battery%20connected%20charger%20running/NO_CHARGE_0.gif"/>
				<img *ngIf="LCD.batterySign == 'noCharge20'" src="./images/MCB_LCD/Side%20Bar/2-%20Battery%20Sign/d-%20Battery%20connected%20charger%20running/NO_CHARGE_20.gif.gif"/>
				<img *ngIf="LCD.batterySign == 'noCharge40'" src="./images/MCB_LCD/Side%20Bar/2-%20Battery%20Sign/d-%20Battery%20connected%20charger%20running/NO_CHARGE_40.gif.gif"/>
				<img *ngIf="LCD.batterySign == 'noCharge60'" src="./images/MCB_LCD/Side%20Bar/2-%20Battery%20Sign/d-%20Battery%20connected%20charger%20running/NO_CHARGE_60.gif.gif"/>
				<img *ngIf="LCD.batterySign == 'noCharge80'" src="./images/MCB_LCD/Side%20Bar/2-%20Battery%20Sign/d-%20Battery%20connected%20charger%20running/NO_CHARGE_80.gif.gif"/>
				<img *ngIf="LCD.batterySign == 'noCharge100'" src="./images/MCB_LCD/Side%20Bar/2-%20Battery%20Sign/d-%20Battery%20connected%20charger%20running/NO_CHARGE_100.gif.gif"/>
			</div>
			<div class="col-md-12"> <!-- Scheduled Profile -->
				<img *ngIf="LCD.scheduledProfile == 'desulfate'" src="./images/MCB_LCD/Side%20Bar/3-%20Scheduled%20Profile/IMAGE_SCHED_PROFILE_D_63X63.bmp"/>
				<img *ngIf="LCD.scheduledProfile == 'EQ' && !LCD.runWithBMS" src="./images/MCB_LCD/Side%20Bar/3-%20Scheduled%20Profile/IMAGE_SCHED_PROFILE_EQ_63X63.bmp"/>
				<img *ngIf="LCD.scheduledProfile == 'refresh'" src="./images/MCB_LCD/Side%20Bar/3-%20Scheduled%20Profile/IMAGE_SCHED_PROFILE_RF_63X63.bmp"/>
			</div>
			<div class="col-md-12"> <!-- WIFI status -->
				<img *ngIf="LCD.WIFIstatus == 0" src="./images/MCB_LCD/Side%20Bar/4-%20Wifi/IMAGE_NO_CONNECT_WIFI_63X63.bmp"/>
				<img *ngIf="LCD.WIFIstatus == 1" src="./images/MCB_LCD/Side%20Bar/4-%20Wifi/IMAGE_CONNECTED_WIFI_BAD_QUALITY_63X63.bmp"/>
				<img *ngIf="LCD.WIFIstatus == 2" src="./images/MCB_LCD/Side%20Bar/4-%20Wifi/IMAGE_CONNECTED_WIFI_LOW_QUALITY_63X63.bmp"/>
				<img *ngIf="LCD.WIFIstatus == 3" src="./images/MCB_LCD/Side%20Bar/4-%20Wifi/IMAGE_CONNECTED_WIFI_MEDIUM_QUALITY_63X63.bmp"/>
				<img *ngIf="LCD.WIFIstatus == 4" src="./images/MCB_LCD/Side%20Bar/4-%20Wifi/IMAGE_CONNECTED_WIFI_HIGH_QUALITY_63X63.bmp"/>
			</div>
			<div class="col-md-12"> <!-- Energy Managment -->
				<img *ngIf="LCD.energyManagment == 'lockout'" src="./images/MCB_LCD/Side%20Bar/5-%20Energy%20Managment/V2_N_IMAGE_LOCKOUT_ICON_65X65.bmp"/>
				<img *ngIf="LCD.energyManagment == 'powerLimiting'" src="./images/MCB_LCD/Side%20Bar/5-%20Energy%20Managment/V2_N_IMAGE_POWER_LIMITING_ICON_65X65.bmp"/>
			</div>
			<div class="col-md-12"> <!-- Autostart -->
				<img *ngIf="LCD.autoStart == 'TR'" src="./images/MCB_LCD/Side%20Bar/6-%20Auto_start/IMAGE_AUTO_START_TR_63X63.bmp"/>
				<div *ngIf="LCD.autoStart == 'TR' && LCD.autoSatrtTimer" style="width: 100%; position: absolute; top: 35px; left: 20px; color: white; text-align: center">
					{{LCD.autoSatrtTimer}}
				</div>
			</div>
		</div>
		<div class="col-md-11 LCD-body"> <!-- BODY -->
			<div *ngIf="LCD.body == 'simulationMode'" style="width: 100%; position: absolute; top: 35px; text-align: center">
				<strong>SIMULATION MODE</strong>
			</div>
			<div *ngIf="LCD.body == 'voltageWarning'" style="width: 100%; position: absolute; top: 35px; text-align: center">
				<strong>{{LCD.voltageWarning}}</strong>
			</div>
			<div *ngIf="LCD.body == 'chargerReady'">
				<div style="text-align: center">
					<img *ngIf="LCD.modeltype == 'Q4'" src="./images/MCB_LCD/Charger%20Ready/4x.gif"/>
					<img *ngIf="LCD.modeltype == 'Q6'" src="./images/MCB_LCD/Charger%20Ready/6x.gif"/>
					<img *ngIf="LCD.modeltype == 'Q12'" src="./images/MCB_LCD/Charger%20Ready/12x.gif"/>
				</div>
				<div style="text-align: center; font-size: x-large;">
					<strong>{{'charger.connect_battery' |translate}}</strong>
				</div>
			</div>
			<div *ngIf="LCD.body == 'chargerScreen'">
				<div class="col-md-12">
					<div class="col-md-3 tcenter" *ngIf="(newLithiumBattery && LCD.runWithBMS!=1) || LCD.runWithBMS==1">
					</div>
					<div class="col-md-6 tcenter blue-xx-large" *ngIf="newLithiumBattery && LCD.runWithBMS!=1">
						<strong>{{'charger.Lithiumion' |translate}}</strong>
					</div>
					<div class="col-md-6 tcenter blue-xx-large" *ngIf="LCD.runWithBMS==1">
						<strong>{{'charger.lithiumion_bms' |translate}}</strong>
					</div>
					<div class="col-md-3 tcenter" *ngIf="(newLithiumBattery && LCD.runWithBMS!=1) || LCD.runWithBMS==1">
					</div>
					<div class="col-md-6 tcenter" *ngIf="showSOC">
						<img *ngIf="LCD.SOCfactor == 0" src="./images/MCB_LCD/SOC%20ARC/IMAGE_SOC_GAUGE_0_213X108.bmp"/>
						<img *ngIf="LCD.SOCfactor == 1" src="./images/MCB_LCD/SOC%20ARC/IMAGE_SOC_GAUGE_1_213X108.bmp"/>
						<img *ngIf="LCD.SOCfactor == 2" src="./images/MCB_LCD/SOC%20ARC/IMAGE_SOC_GAUGE_2_213X108.bmp"/>
						<img *ngIf="LCD.SOCfactor == 3" src="./images/MCB_LCD/SOC%20ARC/IMAGE_SOC_GAUGE_3_213X108.bmp"/>
						<img *ngIf="LCD.SOCfactor == 4" src="./images/MCB_LCD/SOC%20ARC/IMAGE_SOC_GAUGE_4_213X108.bmp"/>
						<img *ngIf="LCD.SOCfactor == 5" src="./images/MCB_LCD/SOC%20ARC/IMAGE_SOC_GAUGE_5_213X108.bmp"/>
						<img *ngIf="LCD.SOCfactor == 6" src="./images/MCB_LCD/SOC%20ARC/IMAGE_SOC_GAUGE_6_213X108.bmp"/>
						<img *ngIf="LCD.SOCfactor == 7" src="./images/MCB_LCD/SOC%20ARC/IMAGE_SOC_GAUGE_7_213X108.bmp"/>
						<img *ngIf="LCD.SOCfactor > 7" src="./images/MCB_LCD/SOC%20ARC/IMAGE_SOC_GAUGE_8_213X108.bmp"/>
						<div style="width: 100%; position: absolute; top: 100px; left: 0px; text-align: center; font-size: large"><strong>{{LCD.currentSOC}}%</strong></div>
					</div>
					<div class="col-md-6 tcenter">
						<img *ngIf="LCD.currentFactor == 0" src="./images/MCB_LCD/CURRENT%20ARC/IMAGE_CURRENT_GAUGE_0_213X108.bmp"/>
						<img *ngIf="LCD.currentFactor == 1" src="./images/MCB_LCD/CURRENT%20ARC/IMAGE_CURRENT_GAUGE_1_213X108.bmp"/>
						<img *ngIf="LCD.currentFactor == 2" src="./images/MCB_LCD/CURRENT%20ARC/IMAGE_CURRENT_GAUGE_2_213X108.bmp"/>
						<img *ngIf="LCD.currentFactor == 3" src="./images/MCB_LCD/CURRENT%20ARC/IMAGE_CURRENT_GAUGE_3_213X108.bmp"/>
						<img *ngIf="LCD.currentFactor == 4" src="./images/MCB_LCD/CURRENT%20ARC/IMAGE_CURRENT_GAUGE_4_213X108.bmp"/>
						<img *ngIf="LCD.currentFactor == 5" src="./images/MCB_LCD/CURRENT%20ARC/IMAGE_CURRENT_GAUGE_5_213X108.bmp"/>
						<img *ngIf="LCD.currentFactor == 6" src="./images/MCB_LCD/CURRENT%20ARC/IMAGE_CURRENT_GAUGE_6_213X108.bmp"/>
						<img *ngIf="LCD.currentFactor == 7" src="./images/MCB_LCD/CURRENT%20ARC/IMAGE_CURRENT_GAUGE_7_213X108.bmp"/>
						<img *ngIf="LCD.currentFactor > 7" src="./images/MCB_LCD/CURRENT%20ARC/IMAGE_CURRENT_GAUGE_8_213X108.bmp"/>
						<div style="width: 100%; position: absolute; top: 100px; left: 0px; text-align: center; font-size: large"><strong>{{LCD.current / 10 | number:'1.0-0'}} A</strong></div>
					</div>
				</div>
				<div class="col-md-12">
					<div class="col-md-3 tcenter">
						<img *ngIf="LCD.voltageFactorARC == 0" src="./images/MCB_LCD/VOLTAGE%20CIRCLE/IMAGE_VOLTGAE_CIRCLE_0_156X155.bmp"/>
						<img *ngIf="LCD.voltageFactorARC == 1" src="./images/MCB_LCD/VOLTAGE%20CIRCLE/IMAGE_VOLTGAE_CIRCLE_1_156X155.bmp"/>
						<img *ngIf="LCD.voltageFactorARC == 2" src="./images/MCB_LCD/VOLTAGE%20CIRCLE/IMAGE_VOLTGAE_CIRCLE_2_156X155.bmp"/>
						<img *ngIf="LCD.voltageFactorARC == 3" src="./images/MCB_LCD/VOLTAGE%20CIRCLE/IMAGE_VOLTGAE_CIRCLE_3_156X155.bmp"/>
						<img *ngIf="LCD.voltageFactorARC == 4" src="./images/MCB_LCD/VOLTAGE%20CIRCLE/IMAGE_VOLTGAE_CIRCLE_4_156X155.bmp"/>
						<img *ngIf="LCD.voltageFactorARC == 5" src="./images/MCB_LCD/VOLTAGE%20CIRCLE/IMAGE_VOLTGAE_CIRCLE_5_156X155.bmp"/>
						<img *ngIf="LCD.voltageFactorARC == 6" src="./images/MCB_LCD/VOLTAGE%20CIRCLE/IMAGE_VOLTGAE_CIRCLE_6_156X155.bmp"/>
						<img *ngIf="LCD.voltageFactorARC > 6" src="./images/MCB_LCD/VOLTAGE%20CIRCLE/IMAGE_VOLTGAE_CIRCLE_7_156X155.bmp"/>
						<div *ngIf="LCD.compVPC && !LCD.runWithBMS" style="color:red; width: 100%; position: absolute; top: 35px; left: 0px; text-align: center; font-size: small">TC</div>
						<div style="width: 100%; position: absolute; top: 55px; left: 0px; text-align: center; font-size: large"><strong>{{LCD.voltageVal / 100 | number:'1.1-1'}}V</strong></div>
						<div *ngIf="LCD.VPC && !LCD.runWithBMS" style="width: 100%; position: absolute; top: 85px; left: 0px; text-align: center; font-size: small">{{LCD.VPC | number:'1.2-2'}}VPC</div>
						<div *ngIf="LCD.compVPC && !LCD.runWithBMS" style="color:red; width: 100%; position: absolute; top: 100px; left: 0px; text-align: center; font-size: small">{{LCD.compVPC | number:'1.2-2'}}VPC</div>
					</div>
					<div class="col-md-6">
						<div class="col-md-12 tcenter">
							<img *ngIf="LCD.durationImg == 0" src="./images/MCB_LCD/Duration/IMAGE_DURATION_1_32X32.bmp"/>
							<img *ngIf="LCD.durationImg == 1" src="./images/MCB_LCD/Duration/IMAGE_DURATION_2_32X32.bmp"/>
							<img *ngIf="LCD.durationImg == 2" src="./images/MCB_LCD/Duration/IMAGE_DURATION_3_32X32.bmp"/>
							<img *ngIf="LCD.durationImg == 3" src="./images/MCB_LCD/Duration/IMAGE_DURATION_4_32X32.bmp"/>
							 <strong style="color: #32B961; font-size: large">{{LCD.durationTime}}</strong>
						</div>
						<div class="col-md-12">
							<div class="col-md-5 tcenter bg-grey" *ngIf="LCD.AHR > 0">
								<strong>{{LCD.AHR | number:'1.1-1'}} AHR</strong>
							</div>
							<div class="col-md-5 tcenter bg-grey col-md-offset-2" *ngIf="LCD.WHRs > 0">
								<strong>{{LCD.WHRs | number:'1.1-1'}} KWHr</strong>
							</div>
						</div>
						<div class="col-md-12 tcenter">
							<img *ngIf="LCD.profile == 'running' && LCD.ProfileStatus == 'D'" src="./images/MCB_LCD/profiles/IMAGE_CHARGE_D_120X72.bmp"/>
							<img *ngIf="LCD.profile == 'running' && LCD.ProfileStatus == 'RF'" src="./images/MCB_LCD/profiles/IMAGE_CHARGE_RF_120X72.bmp"/>
							<img *ngIf="LCD.profile == 'running' && LCD.ProfileStatus == 'EQ' && LCD.runWithBMS!=1" src="./images/MCB_LCD/profiles/IMAGE_CHARGE_EQ_120X72.bmp"/>
							<img *ngIf="LCD.profile == 'running' && LCD.ProfileStatus == 'FI'" src="./images/MCB_LCD/profiles/IMAGE_CHARGE_FI_120X72.bmp"/>
							<img *ngIf="LCD.profile == 'running' && LCD.ProfileStatus == 'CV'" src="./images/MCB_LCD/profiles/IMAGE_CHARGE_CV_120X72.bmp"/>
							<img *ngIf="LCD.profile == 'running' && LCD.ProfileStatus == 'CC'" src="./images/MCB_LCD/profiles/IMAGE_CHARGE_CC_120X72.bmp"/>
							<span class="padding-8" *ngIf="LCD.profile == 'msg'">{{LCD.ProfileStatus}}</span>
						</div>
					</div>
					<div class="col-md-3 tcenter">
						<img *ngIf="LCD.temperatureFactor == 0" src="./images/MCB_LCD/TEMPERATURE%20CICRCLE/IMAGE_TEMPERATURE_0_156X155.bmp"/>
						<img *ngIf="LCD.temperatureFactor == 1" src="./images/MCB_LCD/TEMPERATURE%20CICRCLE/IMAGE_TEMPERATURE_1_156X155.bmp"/>
						<img *ngIf="LCD.temperatureFactor == 2" src="./images/MCB_LCD/TEMPERATURE%20CICRCLE/IMAGE_TEMPERATURE_2_156X155.bmp"/>
						<img *ngIf="LCD.temperatureFactor == 3 || LCD.temperatureFactor == 'N/A'" src="./images/MCB_LCD/TEMPERATURE%20CICRCLE/IMAGE_TEMPERATURE_3_156X155.bmp"/>
						<img *ngIf="LCD.temperatureFactor == 4" src="./images/MCB_LCD/TEMPERATURE%20CICRCLE/IMAGE_TEMPERATURE_4_156X155.bmp"/>
						<img *ngIf="LCD.temperatureFactor == 5" src="./images/MCB_LCD/TEMPERATURE%20CICRCLE/IMAGE_TEMPERATURE_5_156X155.bmp"/>
						<img *ngIf="LCD.temperatureFactor == 6" src="./images/MCB_LCD/TEMPERATURE%20CICRCLE/IMAGE_TEMPERATURE_6_156X155.bmp"/>
						<img *ngIf="LCD.temperatureFactor > 6" src="./images/MCB_LCD/TEMPERATURE%20CICRCLE/IMAGE_TEMPERATURE_7_156X155.bmp"/>
						<div style="width: 100%; position: absolute; top: 45px; left: 0px; text-align: center; font-size: large"><strong>{{LCD.temperatureText}}</strong></div>
					</div>
				</div>
				<div 
					*ngIf="showActionButtons"
					class="col-md-12 tcenter">
					<img [ngClass]="LCDbuttonsClass" *ngIf="LCD.buttons == 'stop' && !userService.hasAccessFunction('prevent_display_control')" (click)="sendLCDaction('stop')" [src]="'./images/MCB_LCD/Buttons/IMAGE_STOP_685X104'+imgPostfix+'.bmp'"/>
					<img *ngIf="LCD.buttons == 'disabledStart'" [src]="'./images/MCB_LCD/Buttons/V2_N_IMAGE_Pushed_START_LOCKOUT_685X104'+imgPostfix+'.bmp'"/>
					<img [ngClass]="LCDbuttonsClass" *ngIf="LCD.buttons == 'start' && !userService.hasAccessFunction('prevent_display_control')" (click)="sendLCDaction('start')" [src]="'./images/MCB_LCD/Buttons/IMAGE_START_685X104'+imgPostfix+'.bmp'"/>
					<img [ngClass]="LCDbuttonsClass" *ngIf="LCD.buttons == 'return'" (click)="sendLCDaction('return')" [src]="'./images/MCB_LCD/Buttons/IMAGE_RETURN_685X104'+imgPostfix+'.bmp'"/>
					<img [ngClass]="LCDbuttonsClass" *ngIf="LCD.buttons == 'paused' && !userService.hasAccessFunction('prevent_display_control')" (click)="sendLCDaction('resume')" [src]="'./images/MCB_LCD/Buttons/IMAGE_RESUME_335X104'+imgPostfix+'.bmp'"/>
					<img [ngClass]="LCDbuttonsClass" *ngIf="LCD.buttons == 'paused' && !userService.hasAccessFunction('prevent_display_control')" (click)="sendLCDaction('exit')" [src]="'./images/MCB_LCD/Buttons/IMAGE_EXIT_335X104'+imgPostfix+'.bmp'"/>
				</div>
				<div
					*ngIf="!showActionButtons"
					class="col-md-12 tcenter">
					<div
						*ngIf="activePage=='lcd-projection' && LCD.runWithBMS && LCD.voltageRangeStatus!=0 && LCD.voltageRangeStatus!=1 && LCDcharger.isbatteryconnected">
							<div 
								class="no-btn-status"
								*ngIf="LCD.voltageRangeStatus=='0xA6' || LCD.voltageRangeStatus=='0x9C'">{{'charger.bms_daughter_card_error' |translate}}</div>
							<div 
								class="no-btn-status"
								*ngIf="LCD.voltageRangeStatus=='0xB0'">{{'charger.bms_not_detected' |translate}}</div>
							<div
								class="no-btn-status"
								*ngIf="LCD.voltageRangeStatus!='0xA6' && LCD.voltageRangeStatus!='0x9C' && LCD.voltageRangeStatus!='0xB0'">{{'charger.incorrect_battery_voltage' |translate}} </div>
					</div>
					<div 
						*ngIf="LCD.runWithBMS && LCD.voltageRangeStatus==1 && LCD.EQ_p==1 && LCD.showLastChargeCycleDetails_p == 0 && LCDcharger.isbatteryconnected">
							<div class="no-btn-status">{{'charger.waiting_for_bms' |translate}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="panel-body" style="background-color: #000b35" *ngIf="activePage == 'lcd-history' && LCDhistory.length > 0">
		<div class="col-md-2" style="color: #0db9f0; font-size: 20px; margin-top: 4px; letter-spacing: 10px;">
			<span (click)="playWithLCDvideo('backward')" class="pointer glyphicon glyphicon-step-backward"></span>
			<span (click)="playWithLCDvideo('play')" class="pointer glyphicon glyphicon-play" *ngIf="!LCDvideo.playing"></span>
			<span (click)="playWithLCDvideo('pause')" class="pointer glyphicon glyphicon-pause" *ngIf="LCDvideo.playing"></span>
			<span (click)="playWithLCDvideo('forward')" class="pointer glyphicon glyphicon-step-forward"></span>
		</div>
		<div class="col-md-10">
			<ng5-slider style="margin-top: 16px" class="pointer" [(value)]="LCDvideo.value" [options]="LCDvideo.options"></ng5-slider>
		</div>
	</div>
</div>
<div class="panel panel-default col-md-12 margin-top-xs" *ngIf="activePage == 'lcd-history'">
	<div class="panel-body text-center center-block lg-font" *ngIf="LCDhistory.length == 0">
		<strong>{{'charger.video_unavailable' |translate}}</strong>
	</div>
</div>

<div class="panel panel-default col-md-3" *ngIf="activePage == 'lcd-projection' && !emptyLCD && LCDcharger.isSupported && (showEQ || showDesulfate) && !LCD.isConnecting">
	<div class="row panel-body" *ngIf="LCD">
		<div class="col-md-12 save-btn" [ngClass]="{'gray': readonlyMCBremote}" (click)="sendLCDaction('EQenable')" *ngIf="showEQ && LCD.EQ_p == 0">{{'charger.enable_eq' |translate}}</div>
		<div class="col-md-12 back-btn" [ngClass]="{'gray': readonlyMCBremote}" (click)="sendLCDaction('EQdisable')" *ngIf="showEQ && LCD.EQ_p == 1">{{'charger.disable_eq' |translate}}</div>
		<fieldset class="col-md-12 padding-8 margin-top-5" *ngIf="showDesulfate">
			<legend>{{'charger.desulfate' |translate}}</legend>
			<label><input type="radio" [(ngModel)]="LCD.desulfate_p" class="form-control" [value]="1" (click)="sendLCDaction('desulfateEnable')" [disabled]="readonlyMCBremote"/> {{'g.enable' |translate}}</label>
			<label><input type="radio" [(ngModel)]="LCD.desulfate_p" class="form-control" [value]="0" (click)="sendLCDaction('desulfateDisable')" [disabled]="readonlyMCBremote"/> {{'g.disable' |translate}}</label>
		</fieldset>
		<div class="col-md-12 tcenter" *ngIf="LCD.desulfate_p != 0">
			<div class="col-md-12 padding-8 text-danger" *ngIf="desulfateSaveError">{{'g.please_fill_all_fields' |translate}}</div>
			<div class="col-md-4 padding-8">{{'g.voltage' |translate}}</div>
			<div class="col-md-8 padding-8">
				<select [(ngModel)]="LCD.DesulfationVoltage_ToUse" class="form-control" [disabled]="readonlyMCBremote">
					<option *ngFor="let type of desulfationVoltages" [value]="type.id">{{type.text}}</option>
				</select>
			</div>
			<div class="col-md-4 padding-8">{{'g.capacity' |translate}}</div>
			<div class="col-md-8 padding-8">
				<input type="number" min="100" max="5000" class="form-control" [(ngModel)]="LCD.DesulfationCapacity_ToUse" [disabled]="readonlyMCBremote"/>
			</div>
			<div class="col-md-4 padding-8">{{'device.duration' |translate}}</div>
			<div class="col-md-8 padding-8">
				<select [(ngModel)]="LCD.DesulfationTimer" class="form-control" [disabled]="readonlyMCBremote">
					<option *ngFor="let hour of quarts_6_18" [value]="hour">{{hour}}</option>
				</select>
			</div>
			<div class="col-md-7" *ngIf="!readonlyMCBremote">
				<div class="save-btn center-block" (click)="sendLCDaction('desulfateSave')">{{'g.save' |translate}}</div>
			</div>
		</div>
	</div>
</div>