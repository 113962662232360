import { Component, OnInit, ViewChild } from '@angular/core';
import {Subject} from 'rxjs';

import { NotificationMessageService } from './notification-message.service';
import { NotificationMessage } from './notification-message.model';


@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html'
})
export class NotificationMessageComponent implements OnInit {

  private visible: boolean = false;
  autoClose: boolean;
  message: NotificationMessage = new NotificationMessage();
  //message.type possible values = ['success', 'info', 'warning', 'danger']
  constructor(private notificationMessageService: NotificationMessageService) {}

  ngOnInit() {
  	this.notificationMessageService.message.subscribe((message) => {
      this.message = message;
      if (message.show) {
        this.autoClose = false;
        setTimeout(() => this.autoClose = true, this.message.timeout);
        if (this.message.scrollToTop) {
            window.scrollTo(0, 0)
        }
      }
    });
  }

  close() {
    this.notificationMessageService.setMessage(null, null, 700, false, false);
  }

}
