import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { ChargersDashboardService } from '../../home/site-dashboard/chargers/chargers-dashboard/chargers-dashboard.service';
import { UserService } from '../../auth/user.service';
import { User } from '../../auth/user.model';

@Component({
	selector: 'app-pm-report',
	templateUrl: './pm-report.component.html',
	styleUrls: ['./pm-report.component.css']
})
export class PMReportComponent implements OnInit, OnDestroy {

	status: boolean;

	constructor(
		private router: Router,
		private translateService: TranslateService,
		private breadcrumbService: BreadcrumbService,
		private notificationMessage: NotificationMessageService,
		private chargersDashboardService: ChargersDashboardService,
		private userService: UserService
	) { }

	ngOnInit(): void {
		this.userService.currentUser.subscribe((user: User) => {
			let currentUser: User = user;
			if (!currentUser.isACTuser) {
				return this.router.navigate(['/unauthorized']);
			}
		});

		this.breadcrumbService.setBreadcrumb([{'label': this.translateService.instant('site.pm_report'), 'url': ''}]);
		this.chargersDashboardService.getPMReportStatus().subscribe((res: any) => {
			this.status = res.status;
		});
	}

	requestPMReport() {
		this.chargersDashboardService.requestPMReport().subscribe((res: any) => {
			if(res.status === undefined)
				this.notificationMessage.setMessage('globalErrMsg');
			else if(res.status)
				this.notificationMessage.setMessage(this.translateService.instant('g.pm_request_already_registered'), 'warning', 10000);
			else {
				this.notificationMessage.setMessage(this.translateService.instant('g.pm_request_registered_success'), 'success', 20000);
				this.status = true;
			}
		});
	}

	ngOnDestroy(): void {
		this.breadcrumbService.hide();
	}
}
