import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as _ from 'underscore';
import * as lodash from 'lodash';

import { SiteDashboardService } from '../site-dashboard.service';
import { UserService } from '../../../auth/user.service';
import { SideMenuService } from '../../../shared/side-menu/side-menu.service';
import { NavigationService } from '../../../shared/services/navigation.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';

@Component({
	selector: 'app-temp-disabled-alerts',
	templateUrl: './temp-disabled-alerts.component.html',
	styleUrls: ['./temp-disabled-alerts.component.css']
})
export class TempDisabledAlertsComponent implements OnInit, OnDestroy {

	currentUser: any;
	customerID: number = 0;
	siteID: number = 0;
	currentSite: any = {};

	form: any = {};
	devicesList = [];
	cacheList = {
		battview: null,
		charger: null,
		site: [{id: 0, prettyName: "N/A"}],
	};
	tempDisabledAlertsList = [];
	noResult = false;

	alertsList = [];
	alertsObj: any = {
		battview: [
			{id: 'temperature_send', value: "Temperature Alert"},
			{id: 'ebu_alert_send', value: "EBU Alert"},
			{id: 'water_level_send', value: "Low Water Level Alert"},
			{id: 'missed_eq_send', value: "Missed Scheduled EQ Alert"},
			{id: 'missed_fi_send', value: "Missed FI Alert"},
			{id: 'missed_plugged_in', value: "Missed Connection Alert"},
			{id: 'idle_times', value: "Idle Times Alert"},
			{id: 'deep_use', value: "Potential Weak Cells Alert"},
			{id: 'deep_discharge', value: "Deep Discharge Alert"},
			{id: 'battview_disconnection', value: "BATTView Disconnection Alert"},
			{id: 'sulfated_battery', value: "Potentially Sulfated Battery Alert"},
			{id: 'missed_weekly_eq_send', value: "Missed Weekly EQ Alert"},
			{id: 'missed_plugin_cycles_alert', value: "Missed Plugin Alert"},
			{id: 'over_charge', value: "Over Charge Alert"},
			{id: 'cold_temperature', value: "Cold Temperature Alert"},
		],
		charger: [
			{id: 'charger_alert', value: "Charger Alert"},
			{id: 'pm_faults', value: "PM Faults Alert"},
			{id: 'cycle_abnormal_stop', value: "Cycle Abnormal Stop Alert"},
			{id: 'charger_disconnection', value: "Charger Disconnection Alert"},
			{id: 'charger_utilization_alert', value: "Charger Utilization Alert"},
		],
		site: [
			{id: 'site_disconnection', value: "Site Disconnection"},
		],
	};

	alertsTxt: any = {
		temperature_send: "Temperature Alert",
		ebu_alert_send: "EBU Alert",
		water_level_send: "Low Water Level Alert",
		missed_eq_send: "Missed Scheduled EQ Alert",
		missed_fi_send: "Missed FI Alert",
		missed_plugged_in: "Missed Connection Alert",
		idle_times: "Idle Times Alert",
		deep_use: "Potential Weak Cells Alert",
		deep_discharge: "Deep Discharge Alert",
		battview_disconnection: "BATTView Disconnection Alert",
		sulfated_battery: "Potentially Sulfated Battery Alert",
		missed_weekly_eq_send: "Missed Weekly EQ Alert",
		missed_plugin_cycles_alert: "Missed Plugin Alert",
		over_charge: "Over Charge Alert",
		cold_temperature: "Cold Temperature Alert",
		charger_alert: "Charger Alert",
		pm_faults: "PM Faults Alert",
		cycle_abnormal_stop: "Cycle Abnormal Stop Alert",
		charger_disconnection: "Charger Disconnection Alert",
		charger_utilization_alert: "Charger Utilization Alert",
		site_disconnection: "Site Disconnection",
	};
	today = new Date();
	after30days = new Date(this.today.getFullYear(), this.today.getMonth()+1, this.today.getDate()+1);

	days: any = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
	weeklyDispatchDays: any = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

	siteSubscription: any;

	constructor(
		public userService: UserService,
		private siteDashboardService: SiteDashboardService,
		private route: ActivatedRoute,
		private sideMenuService: SideMenuService,
		private router: Router,
		public navigation: NavigationService,
		private notificationMessage: NotificationMessageService,
	) { }

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();
		this.siteDashboardService.setActiveTab('settings');
		this.siteDashboardService.setActiveSubTab('temp-disabled-alerts');

		if (this.currentUser.accessFunctions['edit_alert_setting_disable'] == 'read') {
			this.router.navigate(['/sites-map']);
		}

		this.route.parent.params.subscribe(params => {
			this.customerID = params['customerid'];
			this.siteID = params['siteid'];
			this.siteSubscription = this.sideMenuService.currentSite.subscribe(siteInfo => {
				this.currentSite = siteInfo;
			});
		});
		this.siteDashboardService.getSiteTemporaryDisabledAlerts({customerid: this.customerID, siteid: this.siteID}).subscribe((data:any) => {
			data.battview.list.forEach((batt) => {
				batt.prettyName = batt.batteryid + ' (' + batt.serialnumber + ') ';
			});

			this.cacheList.battview = _.chain(data.battview.list).sortBy('serialnumber').sortBy('batteryid').value();

			data.charger.list.forEach((charger) => {
				charger['prettyName'] = charger['chargerusername'] + ' (' + charger['serialnumber'] + ')';
			});

			this.cacheList.charger = _.chain(data.charger.list).sortBy('serialnumber').sortBy('id').value();

			data.tempDisabledAlerts.forEach((item) => {
				if(item.device_type != 'site'){
					item.prettyName = item.device_id;
					let deviceInfo = data[item.device_type].list.find(x => +x.id === +item.device_id);
					if(deviceInfo)
						item.prettyName = deviceInfo.prettyName;
				}

				item.disabled_until_date = new Date(item.disabled_until_date);
			});
			this.tempDisabledAlertsList = data.tempDisabledAlerts;
		});
		this.alertsList = this.getAlertsList(null);
	}

	loadDevicesList(deviceType){
		this.alertsList = this.getAlertsList(deviceType);
	
		if(this.cacheList[deviceType] !== null)
			this.devicesList = this.cacheList[deviceType];
		if(deviceType == 'site')
			this.form.device_id = 0;
	}

	ngOnDestroy() {
		this.siteSubscription.unsubscribe();
	}

	getAlertsList(deviceType){
		if(deviceType)
			return this.alertsObj[deviceType];

		return [];
	}

	addOrUpdate(){
		this.form.disabled_until_date = new Date(Date.UTC(this.form.disabled_until_date.getFullYear(), this.form.disabled_until_date.getMonth(), this.form.disabled_until_date.getDate(), 0, 0, 0));
 
		this.siteDashboardService.setSiteTemporaryDisabledAlerts(Object.assign({siteid: this.siteID}, this.form)).subscribe((data: any) => {
			if(this.form.device_type != 'site'){
				data.prettyName = this.form.device_id;
				let deviceInfo = this.cacheList[this.form.device_type].find(x => +x.id === +this.form.device_id);
				if(deviceInfo)
					data.prettyName = deviceInfo.prettyName;
			}

			data.disabled_until_date = new Date(data.disabled_until_date);
			if(this.form.id){
				let idx = this.tempDisabledAlertsList.findIndex(x => +x.id === +this.form.id);
				this.tempDisabledAlertsList[idx] = lodash.cloneDeep(data);
			}
			else{
				this.tempDisabledAlertsList.push(lodash.cloneDeep(data));
			}
			this.clearForm();
			this.notificationMessage.setMessage('globalSuccessMsg');
		});
	}

	loadInsideForm(disabledAlert){
		this.loadDevicesList(disabledAlert.device_type);
		this.form = lodash.cloneDeep(disabledAlert);
	}

	delete(modelId, index){
		this.siteDashboardService.deleteSiteTemporaryDisabledAlerts({id: modelId, siteid: this.siteID}).subscribe((data:any)=>{
			this.tempDisabledAlertsList.splice(index, 1);
			this.notificationMessage.setMessage('globalSuccessMsg');
		});
	}

	clearForm(){
		this.form = {};
		this.devicesList = [];
		this.alertsList  = [];
	}
	onDeviceTypeChanged() {
		let deviceType = this.form.device_type;
		this.clearForm();
		this.form.device_type = deviceType;
		this.loadDevicesList(deviceType);
	}
}