import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { NoAuthGuard } from './no-auth-guard.service';
import { BecomeUserComponent } from './become-user/become-user.component';

const authRoutes: Routes = [
	{ path: 'login', component: LoginComponent, canActivate: [NoAuthGuard] },
	{ path: 'register', component: RegisterComponent, canActivate: [NoAuthGuard] },
	{ path: 'forgot-password', component: ForgotPasswordComponent },
	{ path: 'become-user/:token', component: BecomeUserComponent },
	{ path: 'unauthorized', component: UnauthorizedComponent }
];

@NgModule({
	imports :[
		RouterModule.forChild(authRoutes)
	],
	exports: [RouterModule]
})
export class AuthRoutingModule {

}