import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ChartDataService } from '../../../../shared/services/chart-data.service';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';

@Component({
	selector: 'app-overall-battery-replacement',
	templateUrl: './overall-battery-replacement.component.html',
	styleUrls: ['./overall-battery-replacement.component.css']
})
export class OverallBatteryReplacementComponent implements OnInit, OnChanges {

	@Input() data;
	hideWidget: boolean = false;
	currentCustomerSubscription: Subscription = new Subscription();
	sites = {};
	items = [];
	sortDirection = {};
	sortBy = '';

	constructor(
		private chartDataService: ChartDataService,
		private sideMenuService: SideMenuService
	) { }

	ngOnInit() {
		this.currentCustomerSubscription =  this.sideMenuService.currentCustomer.subscribe(customer => {
			if (customer && customer.id) {
				this.sites = this.sideMenuService.getSitesCustomerMap(customer.id);
				if (!this.items || !this.items.length) {
					this.getData();
				}
			}
		});
	}

	ngOnChanges() {
		this.getData();
	}

	getData() {
		if (!_.isEmpty(this.sites) && this.data) {
			let data = this.chartDataService.getCustomerHome(this.data, 'battery_aging', '30', this.sites);
			if (!data) {
				this.hideWidget = true;
			} else {
				this.hideWidget = false;
			}
			this.items = data['list'];
		}
	}

	sortBySite() {
		this.sortBy = 'site_name';
		this.setNewSortDirection('site_name');
	}

	sortByCount() {
		this.sortBy = 'count';
		this.setNewSortDirection('count');
	}

	setNewSortDirection(sortKey) {
		if (this.sortDirection[sortKey] == "desc") {
			this.items = _.sortBy(this.items, function(o) { return o[sortKey]; })
			this.sortDirection[sortKey] = "asc";
		} else {
			this.sortDirection[sortKey] = "desc";
			let items = _.sortBy(this.items, function(o) { return o[sortKey]; });
			this.items = items.reverse();
		}
	}

	isAsc(sortKey) {
		return this.sortBy == sortKey && this.sortDirection[sortKey] == "asc";
	}

	isDesc(sortKey) {
		return this.sortBy == sortKey && this.sortDirection[sortKey] == "desc";
	}
}