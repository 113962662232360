<form #userForm="ngForm" name="editAuser" class="form-auth" (ngSubmit)="saveUser()">
	<div class="container-fluid" id="main">
		<div class="row">
			<div class="col-md-6 col-md-offset-3" id="left">
				<div class="boxs3">
					<h2>Add/Update User Info</h2>
					<div class="panel panel-default">
						<div class="panel-heading">User Info</div>
					</div>
					<div class="form-field">
						<label for="firstName">First name</label>
						<input type="text" class="form-control" [(ngModel)]="user.firstname" name="firstName" placeholder="First Name" required />
					</div>
					<br/>
					<div class="form-field">
					<label for="lastName">Last Name</label>
					<input type="text" class="form-control" [(ngModel)]="user.lastname" name="lastName" placeholder="Last Name" required />
					</div>
					<br/>
					<div class="form-field">
						<label for="email">Email</label>
						<input email type='email' class="form-control" [(ngModel)]="user.email" placeholder="Email" name="email" required />
					</div>
					<br/>
					<div class="form-field">
						<label for="password">Password</label>
						<input type="password" class="form-control" [(ngModel)]="user.password" placeholder="Password" [required]="isPasswordRequired()" name="password">
					</div>
					<br/>
					<div class="form-field">
						<label>Role</label>
						<ng-select [items]="roles" bindLabel="name" bindValue="id" placeholder="Select Role" name="role_id" [(ngModel)]="user.roleid" [clearable]="false" required></ng-select>
					</div>
					<br/>
					<div class="form-field">
						<label><input type="checkbox" [(ngModel)]="user.allowtochangedeviceattribute" class="form-control" name="allowtochangedeviceattribute" /> Allow To Change Device Attribute</label>
					</div>
					<br/>
					<div class="form-field">
						<label><input type="checkbox" [(ngModel)]="user.email_confirmed" class="form-control" name="emailConfirmed" /> Email is Confirmed</label>
					</div>
					<br/>
					<div class="form-field">
						<label><input type="checkbox" [(ngModel)]="user.test_user" class="form-control" name="testUser" /> Is Test User</label>
					</div>
					<br/>
					<div class="form-field">
						<label for="dealer">Dealer</label>
						<ng-select [items]="dealers" bindLabel="name" bindValue="id" placeholder="Select a Dealer" name="dealer_id" [(ngModel)]="user.dealerid" [clearable]="false">
						</ng-select>
					</div>
					<br/>
					<div class="form-field">
						<label for="dealer">Truck Dealer</label>
						<ng-select [items]="truckDealers" bindLabel="name" bindValue="id" placeholder="Select a Truck Dealer" name="truck_dealer_id" [(ngModel)]="user.truckdealerid" [clearable]="false">
						</ng-select>
					</div>
					<br/>
					<div class="form-field">
						<label for="oem">OEM</label>
						<ng-select [items]="oems" bindLabel="name" bindValue="id" placeholder="Select a Battery OEM" name="oem_id" [(ngModel)]="user.oemid" [clearable]="false">
						</ng-select>
					</div>
					<br/>
					<div class="form-field">
						<label for="customer">Company</label>
						<ng-select [items]="customers" bindLabel="name" bindValue="id" placeholder="Select Company" name="customer_id" [(ngModel)]="user.customerid" [clearable]="false">
						</ng-select>
					</div>
					<br/>
					<br/>
					<button type="submit" class="btn btn-primary" [disabled]="userForm.invalid || user.roleid == 0">Submit</button>
					<button type="button" class="btn btn-default" (click)="goBack()">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</form>