import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TagsService {
    constructor(private httpClient: HttpClient) {}

    removeDeviceTag(customerId, siteId, deviceType, deviceIds, tagsObj) {
		return this.httpClient.post('/customers/removeDeviceTag', {customerId, siteId, deviceType, deviceIds, tagsObj}, {
			observe: "body"
		});
    }
    addDeviceTag(customerId, siteId, deviceType, deviceId, tagObj) {
		return this.httpClient.post('/customers/addDeviceTag', {customerId, siteId, deviceType, deviceId, tagObj}, {
			observe: "body"
		});
	}
	addSiteTags(customerId, siteId, tag) {
		return this.httpClient.post('/management/addSiteTags', {customerId, siteId, tag}, {
			observe: "body"
		});
	}
}