import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HomeService } from '../home.service';
import { UserService } from '../../auth/user.service';
import { User } from '../../auth/user.model';
import { AuthService } from '../../auth/auth.service';
import { HeaderService } from '../../shared/header/header.service';
import { FooterService } from '../../shared/footer/footer.service';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

	URLparameters: any = {};
	pageName = '';
	mode: string = 'normal';
	user: {
		oldPassword: string,
		newPassword: string,
		confirmPassword: string,
		id: number
	} = {
		oldPassword: null,
		newPassword: null,
		confirmPassword: null,
		id: 0
	};
	constructor(
		private authService: AuthService,
		private homeService: HomeService,
		private breadcrumb: BreadcrumbService,
		private notificationMessage: NotificationMessageService,
		public navigation: NavigationService,
		private route: ActivatedRoute,
		private router: Router,
		private header: HeaderService,
		private userService: UserService,
		private footer: FooterService,
	) {
	}

	ngOnInit() {

		this.pageName = this.route.snapshot.data['pageName'];
		this.user.id = this.userService.getCurrentUser().id;

		switch(this.pageName) {
			case 'reset_password':
				this.header.hide();
				this.user.id = this.route.snapshot.params['userId'];
				this.route.queryParams.subscribe( params => {
					this.URLparameters = params || {};
				});
			break;
			case 'change_password':
				// after login
				this.header.hide();
				this.mode = 'register';
				this.breadcrumb.hide();
				this.footer.hide();
			break;
			case 'change_user_password':
				// from header
				this.breadcrumb.setBreadcrumb([{label: 'Change Password', url: ''}]);
			break;
		}
	}

	changeUserPassword() {
		let mode = 'change';
		if(this.pageName == 'reset_password')
			mode = 'reset';
		
		this.homeService.changeUserPassword(this.user, mode, this.URLparameters.enc).subscribe(
			(response: any) => {
				if(response.httpStatus == 'success') {
					this.notificationMessage.setMessage(response.msg, 'success', 10000, true);
					this.authService.logoutUser().subscribe((authenticated: boolean) => {
						this.router.navigate(['/login']);
					});
				} else if(response.httpStatus == 'error') {

					this.notificationMessage.setMessage(response.msg);
				}
			}
		);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
		if(this.pageName == 'reset_password' || this.mode == 'register')
			this.header.show();
	}
}