import { Component, OnInit, Input } from '@angular/core';
import { CommonUtil } from '../../../../../../shared/services/utility/common.service';

@Component({
	selector: 'app-global-record',
	templateUrl: './global-record.component.html',
	styleUrls: ['./global-record.component.css']
})
export class GlobalRecordComponent implements OnInit {

	@Input() isBattviewMobile: boolean = false;
	@Input() device: any = {};

	constructor(public commonUtil: CommonUtil) { }

	ngOnInit() {
	}
}
