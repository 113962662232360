import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceManagementService } from '../../device-management.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { UserService } from '../../../auth/user.service';
import { DevicesDashboardService } from '../../../home/site-dashboard/devices/devices-dashboard.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-sites-connectivity',
	templateUrl: './sites-connectivity.component.html',
	styleUrls: ['./sites-connectivity.component.css']
})
export class SitesConnectivityComponent implements OnInit, OnDestroy {

	battviewMobileSiteID = 2780;
	connectivity = [];
	firmwareVersions: any = {};
	requestsLog: any = {};
	bmsTypes: any = [];
	bmsTypesObj: any = {};
	usersInfo = {};
	modalSiteId = 0;
	allChargersFirmwareVersion = 0;
	allBattviewsFirmwareVersion = 0;
	customerNameFilter = '';
	allWifiFirmwareVersion: any;
	allBmsFirmwareVersion: any;
	allBmsFirmwareType: any;

	@ViewChild("modal") modal;

	constructor(
		private route: ActivatedRoute,
		private userService: UserService,
		private breadcrumb: BreadcrumbService,
		private deviceManagemntService: DeviceManagementService,
		private notificationMessage: NotificationMessageService,
		private commonUtil: CommonUtil,
		private devicesDashboard: DevicesDashboardService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([{'label': this.translateService.instant('site.sites_connectivity'), 'url': ''}]);
		this.bmsTypes		= this.devicesDashboard.bmsTypes;
		this.bmsTypesObj	= this.commonUtil.arrayToAssociativeArray(this.bmsTypes, 'id');
		this.getSitesConnectivity();
	}

	toggleExpanded(item){
		if(item.expanded)
			item.expanded = false;
		else
			item.expanded = true;
	}

	getSitesConnectivity() {
		this.deviceManagemntService.getSitesConnectivity().subscribe(
			(response: any) => {
				this.connectivity		= response.connectivity;
				this.firmwareVersions	= response.firmwareVersions;
			}
		);
	}

	filterVersions(versions, currentVersion, type) {

		if(type == 'bms') {
			if(!currentVersion.bmsFirmwareType)
				return [];

			versions = versions[currentVersion.bmsFirmwareType];
			currentVersion = currentVersion.bmsfirmwareversion[currentVersion.bmsFirmwareType];
		}
						
		if(this.userService.hasAccessFunction('act_admin_device_management'))
			return versions.filter(function(ver) {return ver != currentVersion;});

		return versions.filter(function(ver) {return ver > currentVersion;});
	};

	pushFirmwareUpdate(type, site) {
		this.deviceManagemntService.pushFirmwareUpdate(site.id, type, site[type+'FirmwareVersion'], site[type+'FirmwareType']).subscribe(
			(data: any) => {
				if(data.httpStatus == 'error')
					this.notificationMessage.setMessage(data.msg);
			}
		);
	};

	editSiteLiveSocket = function(siteID, type, enable) {
		this.deviceManagemntService.editSiteLiveSocket(siteID, type, enable).subscribe();
	};

	showFirmwareRequestsLog(siteid) {
		this.deviceManagemntService.getSiteFirmwareUpdateRequests('all', siteid).subscribe(
			(data: any) => {
				this.modalSiteId = siteid;
				this.usersInfo = data.usersInfo;
				this.requestsLog = data.requests;
				this.modal.show();
			}
		);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}