import { TranslateService } from '@ngx-translate/core';
import { GoogleLineChartService } from './../../../shared/google-chart/Services/google-line-chart.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { DeviceManagementService } from '../../device-management.service';
import * as _ from 'underscore';
import * as moment from 'moment';
import { GoogleChartInterface } from 'ng2-google-charts';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';

declare var google: any;

@Component({
	selector: 'app-production-performance',
	templateUrl: './production-performance.component.html',
	styleUrls: ['./production-performance.component.css']
})
export class ProductionPerformanceComponent implements OnInit, OnDestroy {
	allowedIPs;
	commissionDatesList;
	deviceInfo;
	printChartLink: string = '';
	config;
	chartData;
	selectedIps: string[] = [];
	selectedYear;
	_lineChartService: GoogleLineChartService = new GoogleLineChartService();
	yearList = [];

	chart: GoogleChartInterface;
	mapIpsNames = {
		'12.1.211.66': this.translateService.instant('act.fountain_valley'),
		'72.131.192.10': this.translateService.instant('act.san_antonio')
	};

	constructor(
		private deviceManagementService: DeviceManagementService,
		private translateService: TranslateService,
		private breadcrumb: BreadcrumbService
	) {
		this.breadcrumb.setBreadcrumb([
			{label: this.translateService.instant('act.production_performance'), url: ''}
		]);
	}

	ngOnInit(): void {
		forkJoin([this.deviceManagementService.getProductionAllowedIPs(), this.deviceManagementService.getCommissionedDates()]).subscribe((res: any[]) => {
			this.allowedIPs = res[0];
			this.allowedIPs.forEach(element => {
				element.checked = true;
				this.selectedIps.push(element.ip);
			});
			this.commissionDatesList = res[1].dataKeyByYear;
			this.deviceInfo = res[1].deviceInfo;
			google.charts.load('current', {'packages':['line']});
			google.charts.setOnLoadCallback(() => this.drawChart());
		});
		this.selectedYear = moment().year();
		for (let year = 2016; year <= moment().year(); year++)
			this.yearList.push(year);
	}

	prepareData() {
		this.chartData = new google.visualization.DataTable();
		this.chartData.addColumn('number', 'Week');
		for (const ip of this.selectedIps) {
			this.chartData.addColumn('number', ip);
			this.chartData.addColumn({type: 'string', role: 'tooltip'});
		}
		
		const tempData = [];
		const numberOfWeeks = moment().weeksInYear();
		const currentYear = moment().year();
		const currentWeek = moment().week() - 1;
		const data = this.commissionDatesList[this.selectedYear];
		
		for (let week = 0;
			week < numberOfWeeks && 
			((+this.selectedYear === currentYear && week < currentWeek)
			|| +this.selectedYear !== currentYear )
			; week++) {
			const temp = [];
			for (const ip of this.selectedIps) {
				if (data && data[ip])
					temp.push(data[ip][week], this.getTooltipInfo(week, ip));
				else
					temp.push(0, this.getTooltipInfo(week, ip));
			}
			tempData.push([week, ...temp]);
		}		
		this.chartData.addRows(tempData);
	}

	getTooltipInfo(week: number, ip: string) {
		const sn = this.deviceInfo[`${this.selectedYear}_${ip}_${week}`] || [];
		return `
			${this.translateService.instant('g.weeks')}: ${week}\n\t
			${this.translateService.instant('g.ip_address')}: ${this.mapIpsNames[ip] || ip}\n\t
			${this.translateService.instant('charger.number_of_chargers')}: ${sn.length}
		`;
	}

	drawChart() {
		this.prepareData();

		this.config = {
			hAxis: {
				title: this.translateService.instant('g.week'),
				viewWindow: {
					min: 1,
				},
				gridlines: {
					count: 10,
				}
			},
			vAxis: {
				title: this.translateService.instant('charger.number_of_chargers'),
				format: '0'
			},
			legend: { position: 'bottom' },
			height: 500,
			title: this.translateService.instant('charger.chargers_shipped'),
			tooltip: {isHtml: true},
		};

		this.chart = {
			chartType: 'LineChart',
			dataTable: this.chartData,
			options: this.config,
		};

	}

	ipChanged(event, ip) {
		if (event) {
			if (!this.selectedIps.includes(ip))
				this.selectedIps.push(ip);
		} else {
			this.selectedIps = this.selectedIps.filter((i) => i !== ip);
		}
		this.drawChart();
	}


	onPrintClick() {
		const CSVobj = [
			{
				week: this.translateService.instant('g.week'),
				number_of_chargers: this.translateService.instant('charger.number_of_chargers'),
				ip_address: this.translateService.instant('g.ip_address'),
			}
		];
		const numberOfWeeks = moment().weeksInYear();
		const currentYear = moment().year();
		const currentWeek = moment().week() - 1;
		
		for (let week = 0; week < numberOfWeeks && ((+this.selectedYear === currentYear && week < currentWeek) || +this.selectedYear !== currentYear ); week++) {
			const temp = {
				week: week + 1,
				number_of_chargers: 0,
				ip_address: []
			};
			for (const ip of this.selectedIps) {
				const count = (this.deviceInfo[`${this.selectedYear}_${ip}_${week}`] || []).length;
				if (count > 0){
					temp.number_of_chargers += (this.deviceInfo[`${this.selectedYear}_${ip}_${week}`] || []).length;
					temp.ip_address.push( this.mapIpsNames[ip] || ip);
				}
			}

			CSVobj.push({
				week: temp.week,
				number_of_chargers: temp.number_of_chargers,
				ip_address: temp.ip_address.join(', ') || '-'
			});
		}

		new ngxCsv(CSVobj, `production_line_${this.selectedYear}.csv`);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}