import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AdminService } from '../../admin.service';
import { NavigationService } from '../../../shared/services/navigation.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';

@Component({
	selector: 'app-site-edit-page',
	templateUrl: './site-edit-page.component.html',
	styleUrls: ['./site-edit-page.component.css']
})
export class SiteEditPageComponent implements OnInit, OnDestroy {

	site: any = {
		id: 0,
		siteid: 0,
		customerid: 0,
		isNew: false
	};

	map: any = {};

	ACTusers = <any>[];
	dealersInfo = <any>[];
	serviceDealersInfo = <any>[];
	truckDealersInfo = <any>[];
	OEMsInfo = <any>[];
	siteOwners = <any>[];

	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute,
		private navigation: NavigationService,
		private breadcrumb: BreadcrumbService,
		private notificationMessageService: NotificationMessageService,
	) {
	}

	ngOnInit() {
		this.route.params.subscribe(
			params => {
				this.site.id = Number(params['siteId']);
				this.site.siteid = this.site.id;
				this.site.customerid = Number(params['customerId']);
				this.site.isNew = this.site.id == 0;
			}
		);
		if (this.route.snapshot.queryParams['source'] != "site-info") {
			this.breadcrumb.setBreadcrumb([
				{'label': 'Customers', 'url': '/customers'},
				{'label': 'Sites', 'url': '/sites/customerId/'+this.site.customerid},
				{'label': 'Edit', 'url': ''}
			]);
		}

		this.getSite();
	}

	getSite() {

		this.adminService.getSite(this.site).subscribe((data: any) => {
			let insDate = new Date(new Date().setHours(0, 0, 0, 0));

			if(!this.site.isNew) {
				
				this.site = data.list[0];
				this.map.latitude = this.site.latitude;
				this.map.longitude = this.site.longitude;
				insDate = new Date(new Date(this.site.installation_date).setHours(0, 0, 0, 0));
			}

			this.site.installation_date = insDate;

			if(data.ACTusers) {
				data.ACTusers.forEach((user) => {
					user.name = user.firstname + ' ' + user.lastname;
				});
				this.ACTusers = data.ACTusers;
			}

			if(data.OEMsInfo)
				this.OEMsInfo = data.OEMsInfo;
			
			if(data.dealersInfo) {
				this.dealersInfo = data.dealersInfo;
				this.serviceDealersInfo = [];
				this.serviceDealersInfo = this.dealersInfo.slice();
			}

			if(data.truckDealersInfo)
				this.truckDealersInfo = data.truckDealersInfo;

			if(data.siteOwners)
				this.siteOwners = data.siteOwners;
		});
	}

	saveSite() {
		let tempSite: any = Object.assign({}, this.site);
		let insDate = new Date(tempSite.installation_date);
		let drPhoneNumberWhiteList = [];
		if (tempSite.dr_provider == "ENERNOC" && tempSite.dr_phones_whitelist) {
			for (let phone of tempSite.dr_phones_whitelist) {
				if (phone.value) {
					drPhoneNumberWhiteList.push(phone.value);
				}
			}
		}
		tempSite.dr_phones_whitelist = drPhoneNumberWhiteList;
		tempSite.installation_date = Math.floor((-insDate.getTimezoneOffset() * 60000 + insDate.getTime()) / 1000);
		this.adminService.saveSite(tempSite).subscribe((res:any) => {
			if (res.duplicateSite)
				return this.notificationMessageService.setMessage('translate|site.duplicate_site');

			this.goBack();
		});
	}

	goBack() {
		let defaultPage = ['/sites', 'customerId', this.site.customerid];
		if (this.route.snapshot.queryParams['source'] == "site-info") {
			defaultPage = ['/', this.site.customerid, this.site.siteid];
		}
		this.navigation.goBack(defaultPage);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}