import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'search' })
export class SearchPipe implements PipeTransform {
  transform(items: any, searchText: any, type: string): any {
    if(searchText == null) return items;
    searchText = searchText.trim();
    if(searchText.length == 0) return items;
    if (type == 'customer') {
    	return items.filter(function(customer) {
      		 let hasCustomer = customer.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      		 let filteredSites = customer.sites.filter(function(site) {
      		 	  return site.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      		 });
           return hasCustomer || filteredSites.length > 0;
   	 	});
    }
  }
}