import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import * as moment from 'moment';

import { CommonUtil } from '../../../../../../shared/services/utility/common.service';
import { RoundPipe } from '../../../../../../shared/pipes/round.pipe';

@Component({
	selector: 'app-battery-usage-summary',
	templateUrl: './battery-usage-summary.component.html',
	styleUrls: ['./battery-usage-summary.component.css']
})
export class BatteryUsageSummaryComponent implements OnInit, OnChanges {

	@Input() date: {
		fromDate: any,
		toDate: any
	} = {
		fromDate: new Date(),
		toDate: new Date()
	};
	@Input() dailyDetails: any = {};
	@Input() summaryObject: any = {};
	@Input() device: any = {};
	@Input() isBattviewMobile: boolean = false;
	@Input() customerName: string = null;
	@Input() siteName: string = null;
	@Input() showPage: boolean = false;

	dataObj : {
		ChargeHours: any,
		InUseHours: any,
		IdleHours: any,
		ChargeHoursPercentage: any,
		InUseHoursPercentage: any,
		IdleHoursPercentage: any,
		ChargeAhr: any,
		InUseAhr: any,
		ChargeAhrPercentage: any,
		InUseArhPercentabe: any,
		NumberOfDays: any,
		minDailyUsageDateTime: any,
		MinDailyUsageHours: any,
		AvgDailyUsageHours: any,
		maxDailyUsageDateTime: any,
		MaxDailyUsageHours: any,
		minDailyUsageAhrsDateTime: any,
		MinDailyUsageAhrs: any,
		AvgDailyUsageAhrs: any,
		maxDailyUsageAhrsDateTime: any,
		MaxDailyUsageAhr: any,
		AvgEBUs: any,
		MaxEBUs: any,
		minChargeOppurtinityTime: any,
		MinDailyAvailableHours: any,
		AvgDailyAvailableHours: any,
		minAHperHrTime: any,
		MinHourlyAhrUsage: any,
		AvgHourlyAhrUsage: any,
		maxAHperHrTime: any,
		MaxHourlyAhrUsage: any,
	} = {
		ChargeHours: null,
		InUseHours: null,
		IdleHours: null,
		ChargeHoursPercentage: null,
		InUseHoursPercentage: null,
		IdleHoursPercentage: null,
		ChargeAhr: null,
		InUseAhr: null,
		ChargeAhrPercentage: null,
		InUseArhPercentabe: null,
		NumberOfDays: null,
		minDailyUsageDateTime: null,
		MinDailyUsageHours: null,
		AvgDailyUsageHours: null,
		maxDailyUsageDateTime: null,
		MaxDailyUsageHours: null,
		minDailyUsageAhrsDateTime: null,
		MinDailyUsageAhrs: null,
		AvgDailyUsageAhrs: null,
		maxDailyUsageAhrsDateTime: null,
		MaxDailyUsageAhr: null,
		AvgEBUs: null,
		MaxEBUs: null,
		minChargeOppurtinityTime: null,
		MinDailyAvailableHours: null,
		AvgDailyAvailableHours: null,
		minAHperHrTime: null,
		MinHourlyAhrUsage: null,
		AvgHourlyAhrUsage: null,
		maxAHperHrTime: null,
		MaxHourlyAhrUsage: null,
	}

	constructor(
		private decimalPipe: DecimalPipe,
		private commonUtil: CommonUtil,
		private roundPipe: RoundPipe
	) { }

	ngOnInit() {
	}

	ngOnChanges(changes) {
		if(changes.summaryObject && Object.keys(changes.summaryObject.currentValue).length > 0 && changes.summaryObject.currentValue) {
			this.prepareData();
		}
	}

	prepareData() {
		let batteryUsageSummary: any = {};

		batteryUsageSummary.ChargeHours = this.decimalPipe.transform(this.summaryObject.totalTime[0] / (60*60*1000), '1.0-0');
		batteryUsageSummary.InUseHours = this.decimalPipe.transform(this.summaryObject.totalTime[1] / (60*60*1000), '1.0-0');
		batteryUsageSummary.IdleHours = this.decimalPipe.transform(this.summaryObject.totalTime[2] / (60*60*1000), '1.0-0');

		batteryUsageSummary.ChargeHoursPercentage = this.decimalPipe.transform(Math.round(this.summaryObject.totalTime[0] * 100 / this.summaryObject.totalTimeOfAll)) + '%';
		batteryUsageSummary.InUseHoursPercentage = this.decimalPipe.transform(Math.round(this.summaryObject.totalTime[1] * 100 / this.summaryObject.totalTimeOfAll)) + '%';
		batteryUsageSummary.IdleHoursPercentage = this.decimalPipe.transform(Math.round(this.summaryObject.totalTime[2] * 100 / this.summaryObject.totalTimeOfAll)) + '%';

		batteryUsageSummary.ChargeAhr = this.decimalPipe.transform(Math.round((this.summaryObject.totalchargeAHR * 10) / 10));
		batteryUsageSummary.InUseAhr = this.decimalPipe.transform(Math.round(this.summaryObject.totalDischargeAHR));

		var count = this.summaryObject.totalchargeAHR + this.summaryObject.totalDischargeAHR;
		batteryUsageSummary.ChargeAhrPercentage = count > 0 ? this.decimalPipe.transform(Math.round((this.summaryObject.totalchargeAHR / count) * 100)) + '%' : 0 + '%';
		batteryUsageSummary.InUseArhPercentabe = count > 0 ? this.decimalPipe.transform(Math.round((this.summaryObject.totalDischargeAHR / count) * 100)) + '%' : 0 + '%';
		
		
		if(this.summaryObject.totalDischargeAHR != 0 && this.summaryObject.totalDischargeDays != 0){
			batteryUsageSummary.MinDailyUsageHours = this.commonUtil.timeFormat(this.summaryObject.minHoursInInUse / 1000, {limitToOneDay: true});
			batteryUsageSummary.AvgDailyUsageHours = this.commonUtil.timeFormat(this.summaryObject.totalTime[1] / 1000 / this.summaryObject.totalDischargeDays, {limitToOneDay: true});
			batteryUsageSummary.MaxDailyUsageHours = this.commonUtil.timeFormat(this.summaryObject.maxHoursInInUse / 1000, {limitToOneDay: true});
			batteryUsageSummary.minDailyUsageDateTime = this.commonUtil.getDateFormattedFromUnixTimeStamp((this.summaryObject.minHoursInInUseTime), 'date');
			batteryUsageSummary.maxDailyUsageDateTime = this.commonUtil.getDateFormattedFromUnixTimeStamp((this.summaryObject.maxHoursInInUseTime), 'date');

			// AHR
			batteryUsageSummary.MinDailyUsageAhrs	= this.roundPipe.transform(this.summaryObject.minDailyDischarge, 1);
			batteryUsageSummary.AvgDailyUsageAhrs	= this.roundPipe.transform((this.summaryObject.totalDischargeAHR / this.summaryObject.totalDischargeDays), 1);
			batteryUsageSummary.MaxDailyUsageAhr		= this.roundPipe.transform(this.summaryObject.maxDailyDischarge, 1);

			batteryUsageSummary.minDailyUsageAhrsDateTime = this.commonUtil.getDateFormattedFromUnixTimeStamp((this.summaryObject.minDailyInuseAhrsTime), 'date');
			batteryUsageSummary.maxDailyUsageAhrsDateTime = this.commonUtil.getDateFormattedFromUnixTimeStamp((this.summaryObject.maxDailyInuseAhrsTime), 'date');

			// Hourly Ahr Usage
			batteryUsageSummary.MinHourlyAhrUsage = this.roundPipe.transform(this.summaryObject.minAHperHr, 1);
			batteryUsageSummary.AvgHourlyAhrUsage = this.roundPipe.transform(this.summaryObject.AHPerHrSum / this.summaryObject.totalDischargeDays);
			batteryUsageSummary.MaxHourlyAhrUsage = this.roundPipe.transform(this.summaryObject.maxAHPerHr, 1);

			batteryUsageSummary.minAHperHrTime = this.commonUtil.getDateFormattedFromUnixTimeStamp((this.summaryObject.minAHperHrTime), 'date');
			batteryUsageSummary.maxAHperHrTime = this.commonUtil.getDateFormattedFromUnixTimeStamp((this.summaryObject.maxAHperHrTime), 'date');
		}else{
			batteryUsageSummary.MinDailyUsageHours = "00:00";
			batteryUsageSummary.AvgDailyUsageHours = "00:00";
			batteryUsageSummary.MaxDailyUsageHours = "00:00";

			//AHR
			batteryUsageSummary.MinDailyUsageAhrs = "0";
			batteryUsageSummary.AvgDailyUsageHours = "0";
			batteryUsageSummary.MaxDailyDischarge = "0";

			// Hourly Ahr Usage
			batteryUsageSummary.MinHourlyAhrUsage = "0";
			batteryUsageSummary.AvgHourlyAhrUsage = "0";
			batteryUsageSummary.MaxHourlyAhrUsage = "0";
		}

		var calculated = this.summaryObject.totalDischargeDays * 0.8 * this.device.ahrcapacity;
		var calculated2 = 0.8 * this.device.ahrcapacity;
		batteryUsageSummary.AvgEBUs = calculated > 0 ? this.decimalPipe.transform((this.summaryObject.totalDischargeAHR / calculated).toFixed(2)) : 0;
		batteryUsageSummary.MaxEBUs = calculated2 > 0 ? this.decimalPipe.transform((this.summaryObject.maxDailyDischarge/ calculated2).toFixed(2)) : 0;

		// limit EBU value to 3
		if (batteryUsageSummary.AvgEBUs > 3)
			batteryUsageSummary.AvgEBUs = 3;
		if (batteryUsageSummary.MaxEBUs > 3)
			batteryUsageSummary.MaxEBUs = 3;

		batteryUsageSummary.MinDailyAvailableHours = 0;
		batteryUsageSummary.AvgDailyAvailableHours = 0;
		batteryUsageSummary.minChargeOppurtinityTime = "";

		if (this.summaryObject.totalWorkingDays > 0) {
			batteryUsageSummary.MinDailyAvailableHours = this.commonUtil.timeFormat(this.summaryObject.minHoursOfIdle, {limitToOneDay: true});
			batteryUsageSummary.AvgDailyAvailableHours = this.summaryObject.totalWorkingDays > 0 ? this.commonUtil.timeFormat(this.summaryObject.totalChargeOppurtinityDuration / this.summaryObject.totalWorkingDays) : 0;
			batteryUsageSummary.minChargeOppurtinityTime = this.commonUtil.getDateFormattedFromUnixTimeStamp((this.summaryObject.minChargeOppurtinityTime), 'date');
		}			
		batteryUsageSummary.NumberOfDays = this.decimalPipe.transform(this.dailyDetails.length);

		this.dataObj = batteryUsageSummary;
	}

	getCSVcontent() {
		var dailyUsageSummaryObj = [];
		if (!this.isBattviewMobile) {
			dailyUsageSummaryObj.concat([
				{a: "Client: " , b: this.customerName, c: "Site:", d: this.siteName},
				{a: "Model", b: this.device.batteryid},
				{a: "Battery S/N", b: this.device.batterysn},
				{a: "Battery ID", b: this.device.batteryid},
				{a: "Date Range", b: moment(this.date.fromDate).format('MM/DD/YYYY') + " - " + moment(this.date.toDate).format('MM/DD/YYYY')},
				{a: "Battery Truck ID", b: this.device.truckid}
			]);
		} else {
			dailyUsageSummaryObj.concat([
				{a: "Date Range: ", b: this.date.fromDate + " - " + this.date.toDate},
				{a: "Battery/ Truck Rating /ID"},
				{a: "Battery S/N", b: this.device.batteryid},
				{a: "Battery/ Truck Rating /ID"}
			]);
		}
		
		return dailyUsageSummaryObj.concat([
			
			{a: "Battery Nominal Voltage", b: this.device.nominalvoltage},
			{a: "Battery AMP-HOUR Capacity", b: this.device.ahrcapacity},
			{a: "Cumulative Usage Summary"},
			{a: "", b: "Charge", c: "In Use", d: "Idle"},
			{a: "Hours", b: this.dataObj.ChargeHours, c: this.dataObj.InUseHours, d: this.dataObj.IdleHours},
			{a: "%", b: this.dataObj.ChargeHoursPercentage, c: this.dataObj.InUseHoursPercentage, d: this.dataObj.IdleHoursPercentage},
			{a: "AHRs", b: this.dataObj.ChargeAhr || 0, c: this.dataObj.InUseAhr || 0},
			{a: "%", b: this.dataObj.ChargeAhrPercentage, c: this.dataObj.InUseArhPercentabe},
			{a: "Daily Usage (Discharge) Summary (" + this.dataObj.NumberOfDays + " days)"},
			{a: "", b: "Min", c: "Average", d: "Max"},
			{a: "Hours", b: this.dataObj.MinDailyUsageHours, c: this.dataObj.AvgDailyUsageHours, d: this.dataObj.MaxDailyUsageHours},
			{a: "AHRs", b: this.dataObj.MinDailyUsageAhrs, c: this.dataObj.AvgDailyUsageAhrs, d: this.dataObj.MaxDailyUsageAhr},
			{a: "Daily EBU Usage Summary"},
			{a: "", b: "Average", c: "Max"},
			{a: "EBUs", b: this.dataObj.AvgEBUs, c: this.dataObj.MaxEBUs},
			{a: "Daily Hours Available for Charging"},
			{a: "", b: "Min", c: "Average"},
			{a: "Available Charging Hrs", b: this.dataObj.MinDailyAvailableHours, c: this.dataObj.AvgDailyAvailableHours},
			{a: "Hourly Ahr Usage Summary"},
			{a: "", b: "Min", c: "Average", d: "Max"},
			{a: "Ahrs / Hour", b: this.dataObj.MinHourlyAhrUsage, c: this.dataObj.AvgHourlyAhrUsage, d: this.dataObj.MaxHourlyAhrUsage}
		]);
	}
}