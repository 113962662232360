import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApisLogsService } from './apis-logs.service';
import { SideMenuService } from '../../shared/side-menu/side-menu.service';
import { UserService } from '../../auth/user.service';

@Component({
	selector: 'app-apis-logs',
	templateUrl: './apis-logs.component.html',
})
export class ApisLogsComponent implements OnInit {

	logs: any[] = [];

	constructor(
		public userService: UserService,
		private router: Router,
		private apisLogsService: ApisLogsService,
		private sideMenuService: SideMenuService,
	) { }

	ngOnInit() {
		if (!this.userService.hasAccessFunction('apis_logs'))
			return this.router.navigate(['/unauthorized']);

		this.sideMenuService.hide();
		return this.getLogs()
	}

	getLogs() {
		this.apisLogsService.getLogs().subscribe((logs: any) => {
			this.logs = logs;
		});
	}

	viewLog(log: any) {
		if (log.response.err)
			log.response.error = this.apisLogsService.getLogError(log.response.err);

		const data = { request: log.request, response: log.response };

		const document = window.open('', '')?.document;
		if (!document)
			return;

		document.open();
		document.write([
			'<!DOCTYPE html>', '<html>', '<head>', '</head>', '<body>', '<pre>',
			JSON.stringify(data, null, 4),
			'</pre>', '</body>'
		].join(""));

		document.title = `Log ${log.id}`;
		document.close();
	}
}
