<div class="row">
	<div class="col-md-12 panel panel-default">
		<div class="panel-body">
			<form #searchBySN="ngForm">
				<div class="col-md-6">
					<input [(ngModel)]="serialnumber" placeholder="Serial Number" class="form-control" (ngModelChange)="SNchanged()" name="serialnumber"/>
				</div>
				<div class="col-md-6">
					<button class="btn btn-success tcenter" (click)="getDeviceBySN()" [disabled]="!searchEnabled" type="submit">Search For Device</button>
				</div>
			</form>
		</div>
	</div>
</div>
<div class="row" *ngIf="devicesInfo.length > 0 || noResult">
	<div class="col-md-12 panel panel-default">
		<div class="panel-body">
			<div *ngIf="noResult" class="padding-8">
				No results found
			</div>
			<div *ngIf="type == 'charger'">
				<div *ngFor="let deviceInfo of devicesInfo" class="col-md-12 padding-8">
					<div class="col-md-6 tcenter">
						<a type="button" dropdown-toggle closesubtoggles style="display: inline-block;" *ngIf="deviceInfo.customerid > 0 && deviceInfo.siteid > 0" [routerLink]="['/', deviceInfo.customerid, deviceInfo.siteid, 'chargers', 'analytics', 'cycles', deviceInfo.id]">
							<p class="{{deviceInfo.imageclass}}"></p>
						</a>
						<a type="button" dropdown-toggle closesubtoggles style="display: inline-block;" *ngIf="!userService.hasAccessFunction('no_manage_devices') && (deviceInfo.customerid == 0 || deviceInfo.siteid == 0)" [routerLink]="['/devices','devices-management','charger', deviceInfo.id]">
							<p class="{{deviceInfo.imageclass}}"></p>
						</a>
						<span type="button" dropdown-toggle closesubtoggles style="display: inline-block;" *ngIf="userService.hasAccessFunction('no_manage_devices') && (deviceInfo.customerid == 0 || deviceInfo.siteid == 0)">
							<p class="{{deviceInfo.imageclass}}"></p>
						</span>
					</div>
					<div class="col-md-6">
						<p class="col-md-4">Device Name</p>
						<p class="col-md-8">
							<a *ngIf="deviceInfo.customerid > 0 && deviceInfo.siteid > 0" [routerLink]="['/', deviceInfo.customerid, deviceInfo.siteid, 'chargers', 'analytics', 'cycles', deviceInfo.id]">{{deviceInfo.chargerusername}}</a>
							<a *ngIf="!userService.hasAccessFunction('no_manage_devices') && (deviceInfo.customerid == 0 || deviceInfo.siteid == 0)" [routerLink]="['/devices','devices-management','charger', deviceInfo.id]">{{deviceInfo.chargerusername}}</a>
							<span *ngIf="userService.hasAccessFunction('no_manage_devices') && (deviceInfo.customerid == 0 || deviceInfo.siteid == 0)">{{deviceInfo.chargerusername}}</span>
						</p>
						<p class="col-md-4">Serial Number</p>
						<p class="col-md-8">
							{{deviceInfo.serialnumber}}
						</p>
						<p class="col-md-4">ActView ID</p>
						<p class="col-md-8">
							{{deviceInfo.id}}
						</p>
						<p class="col-md-4" *ngIf="deviceInfo.customerid > 0">Customer Name</p>
						<p class="col-md-8" *ngIf="deviceInfo.customerid > 0">
							<a [routerLink]="['/main', deviceInfo.customerid]" *ngIf="deviceInfo.siteid > 0">
								{{deviceInfo.customername}}
							</a>
							<span *ngIf="deviceInfo.siteid == 0">{{deviceInfo.customername}}</span>
						</p>
						<p class="col-md-4" *ngIf="deviceInfo.siteid > 0">Site Name</p>
						<p class="col-md-8" *ngIf="deviceInfo.siteid > 0">
							<a [routerLink]="['/', deviceInfo.customerid, deviceInfo.siteid]">
								{{deviceInfo.sitename}}
							</a>
						</p>
						<p class="col-md-4">{{'device.warranty_period' | translate}}</p>
						<p class="col-md-8" *ngIf="deviceInfo.isWarrantyVoid">{{'g.void' | translate}}</p>
						<p class="col-md-8" *ngIf="!deviceInfo.isWarrantyVoid">{{commonUtil.singularOrPlural(deviceInfo.warrantyperiod, 'Year')}}</p>
						<p class="col-md-4">{{'g.model' | translate}}</p>
						<p class="col-md-8">{{deviceInfo.model}}</p>
					</div>
				</div>
			</div>
			<div *ngIf="type == 'battview'">
				<div *ngFor="let deviceInfo of devicesInfo" class="col-md-12">
					<div class="col-md-6 tcenter">
						<a type="button" dropdown-toggle closesubtoggles style="display: inline-block;" *ngIf="!deviceInfo.ispa && deviceInfo.customerid > 0 && deviceInfo.siteid > 0" [routerLink]="['/', deviceInfo.customerid, deviceInfo.siteid, 'battviews', 'analytics', 'battery-summary', deviceInfo.id]">
							<p class="{{deviceInfo.imageclass}} bv-thumbnail"></p>
						</a>
						<a type="button" dropdown-toggle closesubtoggles style="display: inline-block;" *ngIf="!userService.hasAccessFunction('no_manage_devices') && !deviceInfo.ispa && (deviceInfo.customerid == 0 || deviceInfo.siteid == 0)" [routerLink]="['/devices','devices-management','battview', deviceInfo.id]">
							<p class="{{deviceInfo.imageclass}} bv-thumbnail"></p>
						</a>
						<span type="button" dropdown-toggle closesubtoggles style="display: inline-block;" *ngIf="userService.hasAccessFunction('no_manage_devices') && !deviceInfo.ispa && (deviceInfo.customerid == 0 || deviceInfo.siteid == 0)">
							<p class="{{deviceInfo.imageclass}} bv-thumbnail"></p>
						</span>
						<a type="button" dropdown-toggle closesubtoggles style="display: inline-block;" *ngIf="deviceInfo.ispa" routerLink="/battview-mobile/analytics/battery-summary" [queryParams]="{'battviewId': deviceInfo.id, studyId: 0}">
							<p class="{{deviceInfo.imageclass}} bv-thumbnail"></p>
						</a>
					</div>
					
					<div class="col-md-6">
						<p class="col-md-4">Device Name</p>
						<p class="col-md-8">
							<a *ngIf="!deviceInfo.ispa && deviceInfo.customerid > 0 && deviceInfo.siteid > 0" [routerLink]="['/', deviceInfo.customerid, deviceInfo.siteid, 'battviews', 'analytics', 'battery-summary', deviceInfo.id]">{{deviceInfo.batteryid}}</a>
							<a *ngIf="!userService.hasAccessFunction('no_manage_devices') && !deviceInfo.ispa && (deviceInfo.customerid == 0 || deviceInfo.siteid == 0)" [routerLink]="['/devices','devices-management','battview', deviceInfo.id]">{{deviceInfo.batteryid}}</a>
							<span *ngIf="userService.hasAccessFunction('no_manage_devices') && !deviceInfo.ispa && (deviceInfo.customerid == 0 || deviceInfo.siteid == 0)">{{deviceInfo.batteryid}}</span>
							<a *ngIf="deviceInfo.ispa" routerLink="/battview-mobile/analytics/battery-summary" [queryParams]="{'battviewId': deviceInfo.id}">{{deviceInfo.batteryid}}</a>
						</p>
						<p class="col-md-4">Serial Number</p>
						<p class="col-md-8">
							{{deviceInfo.serialnumber}}
						</p>
						<p class="col-md-4">ActView ID</p>
						<p class="col-md-8">
							{{deviceInfo.id}}
						</p>
						<p class="col-md-4" *ngIf="deviceInfo.customerid > 0">Customer Name</p>
						<p class="col-md-8" *ngIf="deviceInfo.customerid > 0">
							<a [routerLink]="['/main', deviceInfo.customerid]" *ngIf="deviceInfo.siteid > 0">
								{{deviceInfo.customername}}
							</a>
							<span *ngIf="deviceInfo.siteid == 0">{{deviceInfo.customername}}</span>
						</p>
						<p class="col-md-4" *ngIf="deviceInfo.siteid > 0">Site Name</p>
						<p class="col-md-8" *ngIf="deviceInfo.siteid > 0">
							<a [routerLink]="['/', deviceInfo.customerid, deviceInfo.siteid]">
								{{deviceInfo.sitename}}
							</a>
						</p>
						<p class="col-md-4">{{'device.warranty_period' | translate}}</p>
						<p class="col-md-8" *ngIf="deviceInfo.isWarrantyVoid">{{'g.void' | translate}}</p>
						<p class="col-md-8" *ngIf="!deviceInfo.isWarrantyVoid">{{commonUtil.singularOrPlural(deviceInfo.warrantyperiod, 'Year')}}</p>
						<p class="col-md-4">{{'g.model' | translate}}</p>
						<p class="col-md-8">{{deviceInfo.model}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>