<div *ngIf="viewName == 'dashboard'">
	<app-charger-tabs [activePage]="activePage" [analyticsTab]="analyticsTab" [chargerID]="chargerID" [charger]="chargerInfo" [customerID]="customerID" [siteID]="siteID"></app-charger-tabs>

	<app-select-charger *ngIf="activePage != 'settings' || (activePage == 'settings' && chargersIDs.length <= 1)" [activePage]="activePage" [analyticsTab]="analyticsTab" [chargerID]="chargerID" [charger]="chargerInfo" [customerID]="customerID" [siteID]="siteID" [currentSite]="currentSite" (onChargerSelect)="onChargerSelect($event)" (onDatesChanged)="dateRange = $event" (onGetSiteChargers)="hasChargers = $event" #selectCharger></app-select-charger>
</div>

<div [ngSwitch]="activePage">
	<app-devices-listing *ngSwitchCase="'listing'" deviceType="charger"></app-devices-listing>

	<app-charger-analytics *ngSwitchCase="'analytics'" [tab]="analyticsTab" [customerID]="customerID" [siteID]="siteID" [charger]="chargerInfo" [dateRange]="dateRange" [hasChargers]="hasChargers" (onPLCFaultsEvent)="selectCharger.hasPLCFaults = $event"></app-charger-analytics>

	<app-charger-settings *ngSwitchCase="'settings'" [chargersIDs]="chargersIDs" [customerID]="customerID" [siteID]="siteID" [hasChargers]="hasChargers" (onGetChargerInfo)="chargerInfo = $event" (onMultipleOptionsFlag)="selectCharger.multipleOptions = $event"></app-charger-settings>

	<app-charger-display *ngSwitchCase="'lcd-projection'" [activePage]="activePage" [chargerID]="chargerID" [charger]="chargerInfo"></app-charger-display>
	
	<app-connectivity-track *ngSwitchCase="'connectivity-track'" deviceType="charger" [device]="chargerInfo" [dateRange]="dateRange" [hasDevices]="hasChargers"></app-connectivity-track>

	<app-debug-records *ngSwitchCase="'debug-records'" [device]="chargerInfo" [date]="{fromDate: dateRange?.fromDateFmt, toDate: dateRange?.toDateFmt}" deviceType="charger"></app-debug-records>

	<app-config-track *ngSwitchCase="'config-track'" [device]="chargerInfo" deviceType="charger"></app-config-track>

	<app-chargers-energy *ngSwitchCase="'charger-energy'" [dateObj]="{from: dateRange?.fromDateFmt, to: dateRange?.toDateFmt}" [charger]="chargerInfo" (updateActivePage)="updateActivePage($event)"></app-chargers-energy>

	<app-noc-note *ngSwitchCase="'notes'" [macAddress]="chargerID" [siteId]="siteID"></app-noc-note>

	<app-device-notes *ngSwitchCase="'notes'" [device]="chargerInfo" deviceType="charger" sourcePage="charger_notes" [siteId]="siteID"></app-device-notes>
	
	<app-charger-display *ngSwitchCase="'lcd-history'" [activePage]="activePage" [chargerID]="chargerID" [charger]="chargerInfo"></app-charger-display>
	
	<app-charger-pms-live-view *ngSwitchCase="'pms-live-view'" [chargerID]="chargerID" [charger]="chargerInfo"></app-charger-pms-live-view>

	<app-charger-pms-list *ngSwitchCase="'pms-list'" [chargerID]="chargerID" [charger]="chargerInfo"></app-charger-pms-list>

	<app-chargers-energy *ngSwitchCase="'site-energy'"></app-chargers-energy>
</div>