<div class="panel panel-default">
  <div class="panel-body boxs">

    <div class="panel panel-default tcenter">
      <div class="panel-heading" *ngIf="sitesInfo && sitesInfo.time">
        <strong>{{'g.data_was_generated_at' | translate}} {{sitesInfo.time*1000 | date:'hh:mm a'}}</strong>
      </div>
    </div>

    <div *ngIf="!sitesInfo || sitesInfo.sites.length === 0">
		{{'site.no_sites_without_owners_found' | translate}}
    </div>

    <div class="col-md-12" *ngIf="sitesInfo && sitesInfo.sites.length > 0">
      <div *ngFor="let site of sitesInfo.sites">
        <div class="col-md-5 margin-left padding-8 body-bg-color">
          <strong class="pointer" [routerLink]="['/', site.customerid, site.id]">{{site.name}}</strong>
        </div>
      </div>
    </div>
  </div>
</div>