
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {finalize, catchError, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { LoaderService } from './loader.service';
import { NotificationMessageService } from '../notification-message/notification-message.service';

import { NoUIBlockHttpParams } from './noUIBlock-httpParam';

@Injectable()
export class loaderInterceptor implements HttpInterceptor {
	
	pendingRequests: number = 0;
	constructor(private loaderService: LoaderService, private router: Router, private notificationMessage: NotificationMessageService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let noUIBlock = (req.params instanceof NoUIBlockHttpParams && req.params.noUIBlock);

		if(!noUIBlock) {
			if (this.pendingRequests == 0) {
				this.loaderService.display(true);
			}
			this.pendingRequests++;
		}

		return next.handle(req).pipe(
			tap(
				(response) => {
					if (response instanceof HttpResponse) {
						let noUIBlock = (req.params instanceof NoUIBlockHttpParams && req.params.noUIBlock);
						if(!noUIBlock) {
							this.pendingRequests--;
						}
						if(response.body && response.body.httpStatus) {
							switch(response.body.httpStatus) {
								case 'success':
									this.notificationMessage.setMessage('globalSuccessMsg');
									break;
							}
						}
					}
				}
			),
			catchError(HttpError => {
				let noUIBlock = (req.params instanceof NoUIBlockHttpParams && req.params.noUIBlock);
				if(!noUIBlock) {
					this.pendingRequests--;
				}
				if (HttpError instanceof HttpErrorResponse) {
					switch(HttpError.status) {
						case 404:
							//Not Found
							this.notificationMessage.setMessage('globalErrMsg');
							break;
						case 403:
							//Not Authorized
							this.notificationMessage.setMessage('globalAuthMsg');
							break;
						case 302:
							//Redirect
							this.pendingRequests = 0;
							let params = HttpError.error;
							if(typeof params != 'object')
								params = JSON.parse(HttpError.error);
							this.router.navigate(['/' + params.target], {'queryParams': params.params || {}});
							break;
					}
					return observableThrowError(HttpError.status);
				}
				
				return observableThrowError(HttpError);
			}),
		finalize(() => {
			let noUIBlock = (req.params instanceof NoUIBlockHttpParams && req.params.noUIBlock);
			if (this.pendingRequests <= 0) {
				this.loaderService.display(false);
			}
		}),);
	}
}