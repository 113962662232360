
import {distinctUntilChanged} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { User } from './user.model';
// import { ReplaySubject } from 'rxjs/ReplaySubject';

@Injectable()
export class UserService {
	
	private currentUserSubject = new BehaviorSubject<User>(new User());
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  userRoles = {
		'ACTuser':	1,
		'oem':		6,
		'dealer':	4,
		'client':	5,
		'truckdealer': 7,
		'1':		'ACTuser',
		'6':		'oem',
		'4':		'dealer',
		'5':		'client',
		'7': 		'truckdealer',
	};

    // private isAuthenticatedSubjeck9 im t = new ReplaySubject<boolean>(1);
    // public isAuthenticated = this.isAuthenticatedSubject.asObservable();

	constructor() {}
	 
	getCurrentUser() {
		return this.currentUserSubject.value;
	}

	setCurrentUser(user: User, userAccess?: {}) {
		user.isACTuser = user.roleId == this.userRoles.ACTuser;
		user.isDealer = user.roleId == this.userRoles.dealer;
		user.isOEM 	= user.roleId == this.userRoles.oem;
		user.isEndClient = user.roleId == this.userRoles.client;
		user.isTruckDealer = user.roleId == this.userRoles.truckdealer;
		if(userAccess)
			user.userAccess = userAccess;
		this.currentUserSubject.next(user);
	}

	hasAccessFunction(accessFunction, level = 'read') {
		if(!this.currentUserSubject.value || !this.currentUserSubject.value.accessFunctions)
			return false;

		if(level == 'read')
			return this.currentUserSubject.value.accessFunctions[accessFunction];
		return this.currentUserSubject.value.accessFunctions[accessFunction] == level;
	}

}
