<div class="backgroundlogin">
	<div class="row">
		<div>
			<div class="loginds"> 
				<div class="bran-actview"><a class="logo-login">{{'act.actview' | translate}}</a>
				</div>
				<div>
					<form class="form col-md-12 center-block" (ngSubmit)="f.form.valid && onLogin(f)" #f="ngForm" ngNativeValidate>
						<div 
							class="form-group usrn"
							[ngClass]="{ 'has-error': f.submitted && !username.valid }">
							<input 
							 type="email"
							 class="form-control input-lg"
							 name="username"
							 required 
							 ngModel
							 #username="ngModel"
							 placeholder="{{'user.user_name' | translate}}" >
						</div>
						<div 
							class="form-group pasn"
							[ngClass]="{ 'has-error': f.submitted && !password.valid }">
							<input 
							  type="password"
							  placeholder="{{'user.' | translate}}Password"
							  class="form-control input-lg"
							  required
							  name="password"
							  ngModel
							  #password="ngModel"> 
						</div>
						<div class="form-group">
							<button
							 type="submit" [disabled]="showCaptcha && !loginBlock.recaptcha"
							 class="btn btn-primary btnlogn btn-block">
							 	<b>{{'user.sign_in' | translate}}</b>
							 </button>
						</div>
						<div class="tcenter">
							<a 
							 routerLink="/forgot-password"
							 class="pointer">
							 	<span class="glyphicon glyphicon-lock"></span>
								 {{'user.forgot_password' | translate}}</a>
						</div>
						<div *ngIf="showCaptcha">
							<p-captcha #captcha siteKey="6LeVcCAUAAAAAEKZnR7tM3nEinNQE0JEzAbmQqXj" (onResponse)="loginBlock.recaptcha = $event.response" (onExpire)="loginBlock.recaptcha = ''"></p-captcha>
						</div>
					</form>
				</div>
				<div class="col-md-12 center-block">
					<button 
					 class="btn btn-default btnlogn btn-block" 
					 routerLink="/register"><b>{{'user.register' | translate}}</b>
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="copyrit">{{'g.copyright' | translate}} © {{yearAge}} {{(isAct ? 'act.actview' : 'act.powered_by_act') | translate}}</div>
</div>