<app-user-search-options [userTypes]="userTypes" [pageName]="'user-search'" (onGetResult)="loadData($event)" [hasAddUserButton]="userService.hasAccessFunction('act_user_management')" #searchOptions></app-user-search-options>

<div class="panel panel-default" *ngIf="allUsers.length > 0">
  <div class="panel-body">
    <div class="padding-8">
      <table class="boxs table table-striped">
        <thead>
          <tr>
            <th>{{'user.first_name' | translate}}</th>
            <th>{{'user.last_name' | translate}}</th>
            <th>{{'user.email' | translate}}</th>
            <th>{{'user.role' | translate}}</th>
            <th>{{'user.temporary_password' | translate}}</th>
            <th>{{'g.windows' | translate}}</th>
            <th>{{'g.ios' | translate}}</th>
            <th>{{'g.android' | translate}}</th>
            <th>{{'g.action' | translate}}</th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let user of users">
          <td>{{user.firstname}}</td>
          <td>{{user.lastname}}</td>
          <td>{{user.email}} <span *ngIf="!user.email_confirmed" class="invalid-input red-text">(Not confirmed)</span></td>
          <td>{{user.role}}</td>
          <td>{{user.temppassword}}</td>
          <td>
            <p>{{'user.last_login_time' | translate}} {{user.windows_last_login_time | date: 'MM/dd/yyyy HH:mm:ss'}}</p>
            <p>{{'g.app_version' | translate}} {{user.windows_login_version}}</p>
          </td>
          <td>
            <p>{{'user.last_login_time' | translate}} {{user.ios_last_login_time | date: 'MM/dd/yyyy HH:mm:ss'}}</p>
            <p>{{'g.app_version' | translate}} {{user.ios_login_version}}</p>
          </td>
          <td>
            <p>{{'user.last_login_time' | translate}} {{user.android_last_login_time | date: 'MM/dd/yyyy HH:mm:ss'}}</p>
            <p>{{'g.app_version' | translate}} {{user.android_login_version}}</p>
          </td>
          <td>
            <div class="btn-group" appDropdown *ngIf="userService.userRoles.ACTuser != user.roleid || userService.hasAccessFunction('act_user_management')">
              <i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
              <ul class="dropdown-menu" role="menu">
                <li *ngIf="userService.hasAccessFunction('act_user_management')"><a class="pointer" [routerLink]="['edit', 'userId', user.id]">{{'user.edit_user' | translate}}</a></li>
                <li *ngIf="userService.hasAccessFunction('act_user_management')"><a [routerLink]="['access-permissions', user.id]">{{'user.edit_permissions' | translate}}</a></li>
                <li *ngIf="userService.hasAccessFunction('act_user_management') && !user.special_user"><a class="pointer" (click)="delete(user.id)">{{'user.delete_user' | translate}}</a></li>
                <li *ngIf="userService.userRoles.ACTuser != user.roleid"><a class="pointer" (click)="getBecomeUserToken(user.id)">{{'user.become_user' | translate}}</a></li>
                <li *ngIf="currentUser.canGrantAccessFunctions"><a class="pointer" [routerLink]="['edit-function-management', user.id]">{{'user.access_functions' | translate}}</a></li>
                <li *ngIf="userService.hasAccessFunction('act_user_management') || userService.userRoles.ACTuser != user.roleid">
                  <a class="pointer" (click)="ACTresetUserPassword(user.id)">{{'g.reset_password' | translate}}</a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <pagination [allItems]="allUsers" (onPageChanged)="loadDataPagination($event)" count="25"></pagination>
    </div>
  </div>
</div>  