<div *ngIf="device.id && date.fromDate && date.toDate" id="analytics-page">
	<div *ngIf="(hasData || pageHasRTrecord) || activeTab == 'global-record'">
		<app-battery-summary
			*ngIf="['battery-summary', 'chart'].indexOf(activeTab) > -1"
			[isBattviewMobile]="isBattviewMobile"
			[date]="date"
			[device]="device"
			[dailyDetails]="DailyDetails"
			[warrantyDate]="warrantyDate"
			[summaryObject]="summaryObject"
			[isACTuser]="currentUser.isACTuser"
			[hideTemperatureChart]="hideTemperatureChart"
			[siteAlertsSettings]="siteAlertsSettings"
			[userAlertsSettings]="userAlertsSettings"
			[pageHasRTrecord]="pageHasRTrecord"
			[pageIsActive]="activeTab == 'battery-summary'"
			[customerName]="customerInfo.name"
			[siteName]="siteInfo.name"
		></app-battery-summary>
		<div [ngSwitch]="activeTab">
			<app-charge-summary #chargeSummary
				*ngSwitchCase="'charge-summary'"
				[date]="date"
				[device]="device"
				[dailyDetails]="DailyDetails"
				[summaryObject]="summaryObject"
				[customerName]="customerInfo.name"
				[siteName]="siteInfo.name"
			></app-charge-summary>
			<app-exceptions #exceptions
				*ngSwitchCase="'exceptions'"
				[date]="date"
				[device]="device"
				[dailyDetails]="DailyDetails"
				[summaryObject]="summaryObject"
				[customerName]="customerInfo.name"
				[siteName]="siteInfo.name"
			></app-exceptions>
			<app-battery-usage-summary #batteryUsageSummary
				*ngSwitchCase="'battery-usage-summary'"
				[date]="date"
				[device]="device"
				[dailyDetails]="DailyDetails"
				[summaryObject]="summaryObject"
				[isBattviewMobile]="isBattviewMobile"
				[customerName]="customerInfo.name"
				[siteName]="siteInfo.name"
			></app-battery-usage-summary>
			<app-battery-daily-usage #batteryDailyUsage
				*ngSwitchCase="'battery-daily-usage'"
				[customerName]="customerInfo.name"
				[siteName]="siteInfo.name"
				[device]="device"
				[date]="date"
				[activeTab]="activeTab"
				[activeSubTab]="activeSubTab"
				[dailyDetails]="DailyDetails"
				[summaryObject]="summaryObject"
				[isBattviewMobile]="isBattviewMobile"
				[siteAlertsSettings]="siteAlertsSettings"
				[userAlertsSettings]="userAlertsSettings"
			></app-battery-daily-usage>
			<app-events-log #eventsLog
				*ngSwitchCase="'events-log'"
				[device]="device"
				[events]="events"
				[dailyDetails]="DailyDetails"
				[summaryObject]="summaryObject"
				[isBattviewMobile]="isBattviewMobile"
				[customerID]="customerID"
				[siteID]="siteID"
				[siteAlertsSettings]="siteAlertsSettings"
				[userAlertsSettings]="userAlertsSettings"
				[isACTuser]="currentUser.isACTuser"
				[customerName]="customerInfo.name"
				[siteName]="siteInfo.name"
				[date]="date"
				[activeTab]="activeTab"
				[activeSubTab]="activeSubTab"
			></app-events-log>
			<app-battview-analytics-charts
				*ngSwitchCase="'chart'"
				[isBattviewMobile]="isBattviewMobile"
				[device]="device"
				[dailyDetails]="DailyDetails"
				[events]="events"
				[customerName]="customerInfo.name"
				[siteName]="siteInfo.name"
				[date]="date"
				[activeSubTab]="activeSubTab"
				[siteAlertsSettings]="siteAlertsSettings"
				[userAlertsSettings]="userAlertsSettings"
				[hideTemperatureChart]="hideTemperatureChart"
			></app-battview-analytics-charts>
			<app-global-record
				*ngSwitchCase="'global-record'"
				[isBattviewMobile]="isBattviewMobile"
				[device]="device"
			></app-global-record>
			<app-soc-prediction
				*ngSwitchCase="'soc-prediction'"
				[isBattviewMobile]="isBattviewMobile"
				[device]="device"
				[dailyDetails]="DailyDetails"
				[events]="events"
				[siteAlertsSettings]="siteAlertsSettings"
				[userAlertsSettings]="userAlertsSettings"
			></app-soc-prediction>
		</div>
	</div>
	<div *ngIf="!(hasData || pageHasRTrecord) && activeTab != 'global-record'">
		<div class="panel panel-default">
			<div class="panel-body">
				<strong>No Battview Data Found in Selected Period!</strong>
			</div>
		</div>
	</div>
</div>