import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import * as moment from 'moment';
import { CommonDataService } from '../common-data.service'
import { isDevMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import objectMappers from '../mapper.json';
import { saveAs } from 'file-saver';

@Injectable()
export class CommonUtil {

	alphabetCharacters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');

	DayOfWeek = {
		'Sunday': 0,
		'Monday': 1,
		'Tuesday': 2,
		'Wednesday': 3,
		'Thursday': 4,
		'Friday': 5,
		'Saturday': 6
	};

	constructor(
		private commonData: CommonDataService,
		private translateService: TranslateService
	) { }

	singularOrPlural(val, text, concat = null) {

		concat = concat == null ? true : false;

		if(val == 1)
			return concat ? val + ' ' + text : text;

		return concat ? val + ' ' + text + 's' : text + 's';
	};

	clockTimeToTimeSpan(value) {
		var time = value.split(':');
		var timeSpan = this.convertHourToSeconds(time[0], time[1], false, time[2]);
		return timeSpan;
	};

	getCookie(name) {

		var value	= "; " + document.cookie;
		var parts	= value.split("; " + name + "=");

		if(parts.length == 2)
			return parts.pop().split(";").shift();

		return "";
	}

	lpad(str, length, padString = '0') {
		while (str.length < length)
			str = padString + str;
		return str;
	};

	formatSerialNumber(deviceInfo, ID = 0) {
		deviceInfo.originalSerialNumber = deviceInfo.serialnumber;
		if(deviceInfo.isdeleted) {
			// we have 2 cases:
			// 1- replaced device
			// 2- deleted device
			if (deviceInfo.serialnumber.indexOf('b') > -1 && deviceInfo.serialnumber.indexOf('w') > -1) {
				var replacedDeviceSerialNumber = deviceInfo.serialnumber.slice(deviceInfo.serialnumber.indexOf('b')+1, deviceInfo.serialnumber.indexOf('w'));
				var timeOfDeletion = deviceInfo.serialnumber.slice(deviceInfo.serialnumber.indexOf('w')+1);
				var deviceID = deviceInfo.serialnumber.slice(0, deviceInfo.serialnumber.indexOf('b'));
				deviceInfo.serialnumber = (ID ? ID : replacedDeviceSerialNumber) + ' Replaced by ' + deviceID + ' (' + moment(timeOfDeletion * 1000).format('MM/DD/YYYY hh:mm:ss A') + ')';
				deviceInfo.originalSerialNumber = replacedDeviceSerialNumber;
				deviceInfo.timeOfDeletion = timeOfDeletion;
			} else if (deviceInfo.serialnumber.indexOf('del') > -1 && deviceInfo.serialnumber.indexOf('-') > -1){
				var serialnumber = deviceInfo.serialnumber.slice(deviceInfo.serialnumber.indexOf('del')+3, deviceInfo.serialnumber.indexOf('-'));
				var timeOfDeletion = deviceInfo.serialnumber.slice(deviceInfo.serialnumber.indexOf('-')+1);
				deviceInfo.serialnumber = 'Deleted : '+serialnumber + ' (' + moment(timeOfDeletion * 1000).format('MM/DD/YYYY hh:mm:ss A') + ')';
				deviceInfo.originalSerialNumber = serialnumber;
				deviceInfo.timeOfDeletion = timeOfDeletion;
			}
		}

		return deviceInfo.serialnumber;
	};

	arrayCompare(arr1, arr2) {
		return arr1.length == arr2.length && _.difference(arr1, arr2).length == 0;
	}

	getArrayOfObjectsIntersection(originalObjsArr, noMatchValue = null) {

		if(originalObjsArr.length == 1)
			return originalObjsArr[0];

		if(!noMatchValue)
			noMatchValue = null; //noMatchValue: The value you want to set when a key of all objects doesn't match

		var mergedObjs = originalObjsArr[0];
		originalObjsArr.forEach((Obj, i) => {
			if(i > 0) {
				for(var field in Obj) {
					if(Obj[field] && mergedObjs[field] && typeof(Obj[field]) == 'object') {
						//in case of array
						if(!this.arrayCompare(Obj[field], mergedObjs[field]))
							mergedObjs[field] = [];
					} else if(Obj[field] != mergedObjs[field])
						if(field == 'firmwareversion') {

							if(Obj[field] < mergedObjs[field])
								mergedObjs[field] = Obj[field];

						} else {

							mergedObjs[field] = noMatchValue;
						}
				}
			}
		});

		return mergedObjs;
	};

	Format_WIFI_AT_Version(ATversion) {
		if(ATversion.trim() == "AT version:1.4.0.0(May  5 2017")
			return "WiFi Firmware (1.4.0.0)";

		if(ATversion.trim() == "AT version:1.5.0.0(Oct 24 2017")
			return "Latest WiFi Firmware (1.5.0.0)";

		if(ATversion.trim() == "AT version:1.3.0.0(Oct 28 2016")
			return "WiFi Firmware (1.3.0.0)";

		if(ATversion.trim() == "AT version:0.50.0.0(Sep 18 2015")
			return "Old WiFi Firmware (0.50.0.0)";

		return "Unkown WiFi Module Firmware: " + ATversion;
	}

	getDropDownListData(type, options: any = {}) {
		var dataList = [];
		var i, j;
		switch(type) {
			case "number":
				var num = options.start;
				for(i=0; i<=(options.end-options.start)/options.step; i++) {
					dataList.push(num);
					num += options.step;
				}
			break;
			case "clock":
				var hour, minute: any = "";
				for(j=options.start; j<=options.end; j++) {
					hour = j;
					if (j < 10) {
						hour = "0"+j;
					}
					for(i=0; i<60/options.step; i++) {

						if(j == options.start && options.ignoreFirst) {
							options.ignoreFirst--;
							continue;
						}

						minute = i * options.step;
						if (minute < 10) {
							minute = "0"+minute;
						}
						dataList.push(hour+":"+minute);
						if (j == options.end) {
							break;
						}
					}
				}
			break;
			case "week":
				for(var day in this.DayOfWeek) {
					if(options && options.days && options.days.indexOf(day) == -1)
						continue;
					dataList.push({id:this.DayOfWeek[day], text:day});
				}
			break;
		}
		return dataList;
	}

	extractKeyFromObjects(key, objectsArr, unique = null, integers = null) {
		// We can use _.pluck instead of this function
		// var userIDs = _.pluck(this.users, 'id');
		let items = [];
		objectsArr.forEach((obj) => {
			let val = obj[key];
			if(integers)
				val = +obj[key];

			if(val) {
				if(unique) {
					if(items.indexOf(val) == -1)
						items.push(val);
				} else {
					items.push(val);
				}
			}
		});
		return items;
	};

	arrayToArrayOfObjects(keys, values, keyText = 'id', valueText = 'name') {
		var arrayResult = [];

		if(keys.length != values.length) {
			console.error('arrayToArrayOfObjects: keys.length != values.length');
			return arrayResult;
		}
		for (var i=0; i<keys.length; i++) {
			arrayResult[i] = {};
			arrayResult[i][keyText] = keys[i];
			arrayResult[i][valueText] = values[i];
		}
		return arrayResult;
	}

	objectToLabledArrayOfObjects(obj, keyText, valueText) {

		// we can use below: (not tested)
		// pluckFields(arr, fields) {
		// 	return _.map(arr, function(item) {
		// 	  return _.pick(item, fields)
		// 	})
		// }
		keyText		= keyText || 'id';
		valueText	= valueText || 'text';

		var resObj = [];

		for(var id in obj) {

			var tempItem = {};

			tempItem[keyText]	= id;
			tempItem[valueText] = obj[id];
			resObj.push(tempItem);
		}

		return resObj;
	}

	dataValidator(type, options, value) {
		var isValid = false;
		switch(type) {
			case 'integer':
				if(value == parseInt(value)) {

					value = parseInt(value);

					var validMin = true, validMax = true, validStep = true;

					if(typeof(options.min) !== "undefined" && value < options.min)
						validMin = false;
					if(typeof(options.max) !== "undefined" && value > options.max)
						validMax = false;
					if(typeof(options.step) !== "undefined" && (value % options.step != 0))
						validStep = false;

					if(validMin && validMax && validStep)
						isValid = true;
				}
			break;

			case 'float':
				if(value == parseFloat(value)) {

					value = parseFloat(value);

					var validMin = true, validMax = true, validStep = true;

					if(typeof(options.min) !== "undefined" && value < options.min)
						validMin = false;
					if(typeof(options.max) !== "undefined" && value > options.max)
						validMax = false;
					if(typeof(options.step) !== "undefined" && (value % options.step != 0))
						validStep = false;

					if(validMin && validMax && validStep)
						isValid = true;
				}
			break;

			case 'boolean':
				if(typeof(value) === "boolean")
					isValid = true;
			break;

			case 'string':
				if(typeof(value) === "string") {
					var validMin = true, validMax = true;
					var strLen = value.length;

					if(typeof(options.min) !== "undefined" && strLen < options.min)
						validMin = false;
					if(typeof(options.max) !== "undefined" && strLen > options.max)
						validMax = false;

					if(validMin && validMax)
						isValid = true;
				}
			break;

			case 'inArray':
				switch(options.subType) {
					case 'integer':
						if(value == parseInt(value)) {
							value = parseInt(value);
						}
					break;
				}
				if(options.values.indexOf(value) > -1)
					isValid = true;
			break;

			case 'notInArray':
				switch(options.subType) {
					case 'float':
						if(value == parseFloat(value)) {

							value = parseFloat(value);

							if(options.values.indexOf(value) == -1)
								isValid = true;
						}
					break;
				}
			break;

			case 'time':
				if(typeof(value) === "string") {
					var patt = /^((0|1)[0-9]|2[0-4]):[0-5][0-9]$/;
					if(patt.test(value)) {
						var time = value.split(':');
						var timeInMints = parseInt(time[0]) * 60 + parseInt(time[1]);

						var validMin = true, validMax = true, validStep = true;;

						if(typeof(options.min) !== "undefined") {
							var minTime = options.min.split(':');
							var minTimeInMints = parseInt(minTime[0]) * 60 + parseInt(minTime[1]);

							 if(timeInMints < minTimeInMints)
								validMin = false;
						}

						if(typeof(options.max) !== "undefined") {
							var maxTime = options.max.split(':');
							var maxTimeInMints = parseInt(maxTime[0]) * 60 + parseInt(maxTime[1]);

							 if(timeInMints > maxTimeInMints)
								validMin = false;
						}

						if(typeof(options.step) !== "undefined" && (timeInMints % options.step != 0))
							validStep = false;

						if(validMin && validMax && validStep)
							isValid = true;
					}
				}
			break;

			case 'daysMask':
				var days = [0, 1, 2, 3, 4, 5, 6];
				if(typeof(value) === "object") {
					var withinDays = true;
					value.forEach((ele) => {
						if(withinDays && days.indexOf(ele) == -1) {
							withinDays = false;
						}
					});

					if(withinDays)
						isValid = true;
				}
			break;

			case 'dateRange':
				var validMin = true, validMax = true;

				var DateInSecs = new Date(value).getTime();
				if(typeof(options.min) !== "undefined" && DateInSecs < options.min.getTime())
					validMin = false;
				if(typeof(options.max) !== "undefined" && DateInSecs > options.max.getTime())
					validMax = false;

				if(validMin && validMax)
					isValid = true;
			break;

			case 'regex':
				if(options.patt.test(value))
					isValid = true;
			break;

			case 'arraySubset':
				if(typeof(value) === "object") {
					var withinDays = true;
					value.forEach((ele) => {
						if(withinDays && options.values.indexOf(ele) == -1) {
							withinDays = false;
						}
					});

					if(withinDays)
						isValid = true;
				}
			break;
		}

		return isValid;
	};

	findObjectInArray(value, ObjsArr, fieldName = 'name', valueType = 'string', caseSensitive = false, returnIndexOnly = false) {

		let match = null;

		if(valueType == 'string' && !caseSensitive)
			value = value.toLowerCase();

		let index = -1;
		for(let i = 0; i < ObjsArr.length; i++) {
			let item = ObjsArr[i];
			let tempVal = item[fieldName];

			if(valueType == 'string' && !caseSensitive)
				tempVal = tempVal.toLowerCase();

			if(tempVal == value) {
				index = i;
				break;
			}
		}

		if(returnIndexOnly)
			return index;

		if(index == -1)
			return null;

		return ObjsArr[index];
	};

	arrayToAssociativeArray(array, key = 'id', multipleValues = false) {
		var finalResult = {};
		if(!multipleValues)
			multipleValues = false;
		for (var item in array) {
			if(!multipleValues)
				finalResult[array[item][key]] = array[item];
			else {
				if(!finalResult[array[item][key]])
					finalResult[array[item][key]] = [];
				finalResult[array[item][key]].push(array[item]);
			}
		}
		return finalResult;
	}
	extractKeysFromObjects(keysArr, objectsArr, unique?, integers?) {
		var items = [];
		var itemsValues = [];
		objectsArr.forEach((obj) => {
			var tempObject = {};
			keysArr.forEach((key) => {
				var val = obj[key];
				if(integers)
					val = +obj[key];

				if(val) {
					if(unique) {
						if(itemsValues.indexOf(val) == -1) {
							itemsValues.push(val);
							tempObject[key] = val;
						}
					} else
						tempObject[key] = val;
				}
			});
			if (Object.keys(tempObject).length > 0)
				items.push(tempObject);
		});
		return items;
	}
	getDebugRecordsInfoText(record) {

		var debug		= record.debug;
		var longInfo	= record.info;

		var debugMsg = '';
		var commandsMap =
	  {
		0x01: "_PLC_Connect",
		0x02: "_PLC_Get_Profile",
		0x03: "_PLC_getVIT",
		0x04: "_PLC_Calibrate",
		0x05: "_PLC_Config_READ1",
		0x06: "_PLC_Config_READ2",
		0x07: "_PLC_Config_READ3",
		0x08: "_PLC_Config_READ4",
		0x09: "_PLC_Config_READ5",
		0x0A: "_PLC_Config_READ6",
		0x0B: "_PLC_Config_WRITE1",
		0x0C: "_PLC_Config_WRITE2",
		0x0D: "_PLC_Config_WRITE3",
		0x0E: "_PLC_Config_WRITE4",
		0x0F: "_PLC_Config_WRITE5",
		0x10: "_PLC_Config_WRITE6",
		0x11: "_PLC_RTC_WRITE1",
		0x12: "_PLC_RTC_WRITE2",
		0x13: "_PLC_RTC_WRITE3",
		0x14: "_PLC_NEW_SEARCH",
		0x15: "_PLC_Global_READ1",
		0x16: "_PLC_Global_READ2",
		0x17: "_PLC_RESTART",
		0x18: "_PLC_Get_Profile_sub1",
		0x19: "_PLC_Get_Profile_sub2",
		0x1A: "_PLC_Get_TemperatureOnly",
		0x98: "_PLC_Get_Profile_sub1_REC",
		0x99: "_PLC_Get_Profile_sub2_REC",
		0x9A: "_PLC_Get_TemperatureOnly_REC",
		0x81: "_PLC_Connect_REC",
		0x82: "_PLC_Get_Profile_REC",
		0x83: "_PLC_getVIT_REC",
		0x84: "_PLC_Calibrate_REC",
		0x94: "_PLC_NEW_SEARCH_REC",
	  }


		switch(debug) {
			case 1:
				var firmware = (longInfo / 100).toFixed(2);

				debugMsg = "CONNECT @" + firmware;
			break;

			case 2: debugMsg = "SET SOC BY V"; break;

			case 3: debugMsg = "SET SOC BY USER: " + longInfo; break;

			case 4:

				debugMsg = "CALIBRATE BY USER: ";

				if((longInfo & 0x80000000) != 0) {

					if ((longInfo & 0x7FFFFFFF) == 0)
						debugMsg += "Calibrator";
					else
						debugMsg += "Charger: " + (longInfo & 0x7FFFFFFF);

				} else {
					debugMsg+= "USER:" + longInfo;
				}
			break;

			case 5: debugMsg = "Firmware Update BY USER: " + longInfo; break;

			case 6: debugMsg = "set RTC BY USER: " + longInfo; break;

			case 7: debugMsg = "Defaults Loaded"; break;

			case 8: debugMsg = "RESTART"; break;

			case 9: debugMsg = "RTC FAILURE:";

				if((longInfo & 0x80) != 0)
					debugMsg += "OSCILLATOR FAILED;";
				if((longInfo & 0x40) != 0)
					debugMsg += "VBAT SWITCH FAILED;";
				if((longInfo & 0x20) != 0)
					debugMsg += "VBAT SWITCH during run time;";
				if((longInfo & 0x10) != 0)
					debugMsg += "VBAT BELOW Threshold!!!";
				if(longInfo == 1)
					debugMsg += "RTC COMM";
			break;

			case 10:
				debugMsg = "Wifi HARD Restart";
				if(longInfo != 100 && longInfo != 0)
					debugMsg = "Wifi Soft Restart: " + longInfo;
			break;

			case 11:
				if(longInfo == 0)
					debugMsg = "Austria Soft Restart";
				else
					debugMsg = "Austria Hard Restart: " + longInfo;
			break;

			case 12:
				if(longInfo == 1)
					debugMsg = "RTC COMM FAULT";
				else
					debugMsg = "RTC INT FAULT";
			break;

			case 13:
				switch(longInfo) {
					case 1: debugMsg = "CANNOT Do a successful Basic communication with PLC Chip "; break;
					case 2: debugMsg = "CANNOT Load and communicate with the PLC Chip "; break;
					case 3: debugMsg = "PLC Firmware Never downloaded"; break;
					default: debugMsg = "PLC N/A"; break;
				}
			break;

			case 14:
				debugMsg = "WiFi Chip Failed @init";
			break;

			case 15:
				debugMsg = "Austria Chip Failed @init";
			break;

			case 16:
				switch(longInfo) {
					case 0: debugMsg = "Restart @synch"; break;
					case 1: debugMsg = "Restart @ 1Year "; break;
					case 3: debugMsg = "Restart @ Long Loop"; break;
					default: debugMsg = "Restart N/A"; break;
				}
			break;
			case 17:
				switch(longInfo) {
					case 0: debugMsg = "PLC restart - 8 hrs of idle PLC"; break;
					case 1: debugMsg = "PLC restart - Lost MCB comm for 12 minutes "; break;
					default: debugMsg = "PLC restart - Unable to Send"; break;
				}
			break;
			case 18:
				switch(longInfo) {
					case 0: debugMsg = "PLC FW Load from Copy passed"; break;
					case 1: debugMsg = "PLC FW load from Original CRC passed "; break;
					case 2: debugMsg = "PLC FW load from Copy CRC passed"; break;
					case 3: debugMsg = "PLC FW load from Copy FLush"; break;
					default: debugMsg = "PLC FW load N/A"; break;
				}
			break;
			case 19:
	  	{
			debugMsg = "";
			let Type = (longInfo & 0xFF);
			let sub = ((longInfo >> 8) & 0xFF);
			let extra = ((longInfo >> 16) & 0x0000FFFF);
			switch (Type)
			{
			case 1:
			  {
				switch (sub)
				{
				  case 0: debugMsg = "PHY-SPI READ-Line 825"; break;
				  case 1: debugMsg = "PHY-INT with no delimeter"; break;
				  case 2: debugMsg = "PHY-SPI READ-Line 843"; break;
				  case 3: debugMsg = "PHY-SPI READ-Line 864"; break;
				  case 4: debugMsg = "PHY-payloadLength too large=" + extra; break;
				  case 5: debugMsg = "PHY-SPI READ-Line 882"; break;
				  case 6: debugMsg = "PHY-SPI READ-Line 891"; break;
				  case 7: debugMsg = "PHY-SPI READ-Line 900"; break;
				  case 8: debugMsg = "PHY-Unlown physical layer command:" + extra; break;
				  case 9: debugMsg = "PHY-Failed Send - Reason:" + extra; break;
				  case 10: if(extra!=1){ debugMsg = "PHY-Failed Receive - Reason:" + extra;} else debugMsg= "IGNORE"; break;
				}
			  }
			  break;
			  case 100:
			  {
				debugMsg = "Recieved packet Info:Level" + sub + "dB,PGAgain:" + (extra & 0xFF) + "dB,SNR" + (3 * (extra >> 8 & 0xFF)) + "dB";
			  }
			  break;
			case 2:
			  {
			  	debugMsg = "Reset PLC Reason" + extra;
			  }
			  break;
			case 3:
			  {
				if (sub == 0)
				{

				  if (commandsMap.hasOwnProperty(extra))
				 		debugMsg = "Send Command" + commandsMap[extra];
				  else
				  	debugMsg = "Send Command(missed documentation) = " + extra;
				}
				else
				{
					debugMsg = "Send Unnkown Command" + extra;
				}
			  }
			  break;
			  case 4:
			  {
				if (sub == 1)
				{
				  debugMsg = "Failed in Receive too short=" + extra;
				}
				else if (sub == 2)
				{
				  debugMsg = "Failed in Receive";
				  if (extra == 1)
					debugMsg += "unkown length";
				  else
					debugMsg += "missed delimeter";
				}
			  }
			  break;
			  case 5:
			  {
				debugMsg = "Too Short packet,command=";
				if (commandsMap.hasOwnProperty(sub))
				  debugMsg += commandsMap[sub];
				else
				  debugMsg += sub;
				debugMsg += ",Length:" + extra;
			  }
			  break;
			case 6:
			  {
				debugMsg = "something wrong in Id matching,command=";
				if (commandsMap.hasOwnProperty(sub))
				  debugMsg += commandsMap[sub];
				else
				  debugMsg += sub;
			  }
			  break;
			case 7:
			  {
				debugMsg = "unkown command received:" + sub;
				debugMsg += ",Length:" + extra;
			  }
			  break;
			case 8:
			  {
				debugMsg = "Received command=";
				if (commandsMap.hasOwnProperty(sub))
				  debugMsg += commandsMap[sub];
				else
				  debugMsg += sub;
			  }
			  break;
			case 9:
			  {
				debugMsg = "Send Failed";
			  }
			  break;
			case 10:
			  {
			  	debugMsg = "Reset struct:" + sub;
			  }
			  break;
		  }
		}
		break;
		case 20:
		{
		  debugMsg = "";
		   let SNR = (longInfo & 0xFF);
		   let PGAGain = ((longInfo >> 8) & 0xFF);
		   let level = ((longInfo >> 16) & 0xFF);
		   let CMD = ((longInfo >> 24) & 0xFF);
		  if (commandsMap.hasOwnProperty(CMD))
			debugMsg += commandsMap[CMD];
		  else
			debugMsg += "PLC UKnown Receieved Command: " + CMD;
		  debugMsg += "," + (2 * level) + "dB, PGAGain" + (3 * PGAGain) + "dB, SNR" + (3 * SNR) + "dB";
		}
		break;
		case 21:
		{
		  debugMsg = "PLC connected MCB_ID:" + longInfo;
		}
		break;
		case 22:
		{
		  debugMsg = "PLC Search BV_ID:" + longInfo;
		}
		break;
		case 23:
		{
		  	debugMsg =  "Force Zero:"  ;
	        switch(longInfo)
	        {
	            case 0: debugMsg += "Issue detected";break;
	            case 1: debugMsg += "reset by ngeative current";break;
	            case 2: debugMsg += "reset by voltage increase"; break;
	            default:
	                debugMsg += longInfo;break;
	        }
		}
		break;
		case 24: {

			let fw = 'FW';
			switch(longInfo) {
				case 1: fw = 'old FW';break;
				case 2: fw = 'new FW';break;
			}
			debugMsg = `PLC ${fw} Version`;
		}
		break;
		default: debugMsg = "Unknown"; break;
		}

		return debugMsg;
	}
	getMCBdebugRecordsInfoText(record) {

		var type		= record.type;
		var longInfo	= record.info;

		var debugMsg = '';
		var commandsMap = {
			0x01: "_PLC_Connect",
			0x02: "_PLC_Get_Profile",
			0x03: "_PLC_getVIT",
			0x04: "_PLC_Calibrate",
			0x05: "_PLC_Config_READ1",
			0x06: "_PLC_Config_READ2",
			0x07: "_PLC_Config_READ3",
			0x08: "_PLC_Config_READ4",
			0x09: "_PLC_Config_READ5",
			0x0A: "_PLC_Config_READ6",
			0x0B: "_PLC_Config_WRITE1",
			0x0C: "_PLC_Config_WRITE2",
			0x0D: "_PLC_Config_WRITE3",
			0x0E: "_PLC_Config_WRITE4",
			0x0F: "_PLC_Config_WRITE5",
			0x10: "_PLC_Config_WRITE6",
			0x11: "_PLC_RTC_WRITE1",
			0x12: "_PLC_RTC_WRITE2",
			0x13: "_PLC_RTC_WRITE3",
			0x14: "_PLC_NEW_SEARCH",
			0x15: "_PLC_Global_READ1",
			0x16: "_PLC_Global_READ2",
			0x17: "_PLC_RESTART",
			0x18: "_PLC_Get_Profile_sub1",
			0x19: "_PLC_Get_Profile_sub2",
			0x1A: "_PLC_Get_TemperatureOnly",
			0x98: "_PLC_Get_Profile_sub1_REC",
			0x99: "_PLC_Get_Profile_sub2_REC",
			0x9A: "_PLC_Get_TemperatureOnly_REC",
			0x81: "_PLC_Connect_REC",
			0x82: "_PLC_Get_Profile_REC",
			0x83: "_PLC_getVIT_REC",
			0x84: "_PLC_Calibrate_REC",
			0x94: "_PLC_NEW_SEARCH_REC",
		};

		switch(type) {
			case 1:
				var firmware = (longInfo / 100).toFixed(2);
				debugMsg = "CONNECT @" + firmware;
			break;

			case 2:
				switch(longInfo) {
					case 0: debugMsg = "WiFi failure @ Restart "; break;
					case 100: debugMsg = "WiFi Hard Restart "; break;
					default: debugMsg = "WiFi Soft Restart (" + longInfo + ")"; break;
				}
			break;

			case 3:
				switch(longInfo) {
					case 1: debugMsg = "CANNOT Do a successful Basic communication with PLC Chip"; break;
					case 2: debugMsg = "CANNOT Load and communicate with the PLC Chip"; break;
					case 3: debugMsg = "PLC Firmware Never downloaded"; break;
					default: debugMsg = "PLC N/A"; break;
				}
			break;

			case 4:
				switch(longInfo) {
					case 1: debugMsg = "CAN BUS FAILED,NO HW detected"; break;
					case 2: debugMsg = "CAN BUS FAILED,NO Power modules found."; break;
					case 3: debugMsg = "Power modules error, messed up voltage"; break;
					case 10: debugMsg = "Power modules Enable Line Failed"; break;
					default: debugMsg = "PM N/A"; break;
				}
			break;

			case 5:
				switch(longInfo) {
					case 1: debugMsg = "LCD Calibration Done"; break;
					case 0: debugMsg = "LCD Calibration Timed Out"; break;
					default: debugMsg = "LCD Calibration N/A"; break;
				}
			break;

			case 6: debugMsg = "Firmware Update Request"; break;

			case 7: debugMsg = "Restart Request"; break;

			case 8: debugMsg = "Permanent Error"; break;

			case 9: debugMsg = "RTC SET"; break;

			case 10: debugMsg = "RTC Lost"; break;

			case 11: debugMsg = "LCD Reset(" + longInfo + ")"; break;
			case 12: {
				debugMsg = "";
				var Type = (longInfo & 0xFF);
				var sub = ((longInfo >> 8) & 0xFF);
				var extra = ((longInfo >> 16) & 0x0000FFFF);
				switch (Type) {
					case 1: {
						switch(sub) {
							case 0: debugMsg = "PHY-SPI READ-Line 825";break;
							case 1: debugMsg = "PHY-INT with no delimeter"; break;
							case 2: debugMsg = "PHY-SPI READ-Line 843"; break;
							case 3: debugMsg = "PHY-SPI READ-Line 864"; break;
							case 4: debugMsg = "PHY-payloadLength too large=" + extra; break;
							case 5: debugMsg = "PHY-SPI READ-Line 882"; break;
							case 6: debugMsg = "PHY-SPI READ-Line 891"; break;
							case 7: debugMsg = "PHY-SPI READ-Line 900"; break;
							case 8: debugMsg = "PHY-Unlown physical layer command:" + extra; break;
							case 9: debugMsg = "PHY-Failed Send - Reason:" + extra; break;
							case 10: if(extra!=1){ debugMsg = "PHY-Failed Receive - Reason:" + extra;} else debugMsg= "IGNORE"; break;
						}
					}
					break;
					case 100: {
						debugMsg = "Recieved packet Info:Level"+sub+ "dB,PGAgain:"+(extra&0xFF)+ "dB,SNR"+ (3*(extra>>8 & 0xFF))+"dB";
					}
					break;
					case 2: {
						debugMsg = "Reset PLC Reason" + extra;
					}
					break;
					case 3: {
						if (sub == 0) {
							if (commandsMap.hasOwnProperty(extra))
								debugMsg = "Send Command" + commandsMap[extra];
							else
								debugMsg = "Send Command(missed documentation) = " + extra;
						} else
							debugMsg = "Send Unnkown Command" + extra;
					}
					break;
					case 4: {
						if (sub == 1) {
							debugMsg = "Failed in Receive too short=" + extra;
						} else if(sub==2) {
							debugMsg = "Failed in Receive";
							if (extra == 1)
								debugMsg += "unkown length";
							else
								debugMsg += "missed delimeter";
						}
					}
					break;
					case 5: {
						debugMsg = "Too Short packet,command=";
						if (commandsMap.hasOwnProperty(sub))
							debugMsg += commandsMap[sub];
						else
							debugMsg += sub;
						debugMsg += ",Length:" + extra;
					}
					break;
					case 6: {
						debugMsg = "something wrong in Id matching,command=";
						if (commandsMap.hasOwnProperty(sub))
							debugMsg += commandsMap[sub];
						else
							debugMsg += sub;
					}
					break;
					case 7: debugMsg = "unkown command received:"+ sub + ",Length:" + extra; break;
					case 8: {
						debugMsg = "Received command=";
						if (commandsMap.hasOwnProperty(sub))
							debugMsg += commandsMap[sub];
						else
							debugMsg += sub;
					}
					break;
					case 9: debugMsg = "Send Failed"; break;
					case 10: debugMsg = "Reset struct:"+sub; break;
				}
			}
			break;
			case 13: {
				debugMsg = "";
				var SNR = (longInfo & 0xFF);
				var PGAGain = ((longInfo >> 8) & 0xFF);
				var level = ((longInfo >> 16) & 0xFF);
				var CMD = ((longInfo >> 24) & 0xFF);
				if (commandsMap.hasOwnProperty(CMD))
					debugMsg += commandsMap[CMD];
				else
					debugMsg += "PLC UKnown Receieved Command: " +CMD;

				debugMsg += "," + (2 * level) + "dB, PGAGain" + (3 * PGAGain) + "dB, SNR" + (3 * SNR) + "dB";
			}
			break;
			case 14: debugMsg = "PLC connected BV_ID:" + longInfo; break;
			case 15: debugMsg = "PLC Search BV_ID:" + longInfo; break;
		  	case 16: debugMsg = "PLC Black Listed BV_ID:" + longInfo; break;
		  	case 17: debugMsg = "MCB PLC drop"; break;
		  	case 18: debugMsg = "CAN Timeout"; break;
			case 19: {

				let fw = 'FW';
				switch(longInfo) {
					case 1: fw = 'old FW';break;
					case 2: fw = 'new FW';break;
				}
				debugMsg = `PLC ${fw} Version`;
			}
			break;
		}
		return debugMsg;
	}

	getBattviewFaultText(faultId, enablehalleffectsensing) {
		var text = '';
		switch (faultId) {
			case 1:
				text = "SOC Low Error";
			break;
			case 2:
				text = "SOC Medium Error";
			break;
			case 3:
				text = "SOC High Error";
			break;
			case 4:
				text = "Too Long Events";
			break;
			case 8:
				text = "Current Not calibrated";
				if(enablehalleffectsensing)
					text = "Hall effect sensor is not connected";
			break;
			case 16:
				text = "Voltage Not Calibrated";
			break;
			case 32:
				text = "Voltage Value is off";
			break;
			case 64:
				text = "External Temperature Sensor Damaged";
			break;
			case 128:
				text = "Intercell Temperature Sensor Damaged";
			break;
			case 256:
				text = "Temperature Sensor Failure";
			break;
		}

		return text;
	}
	getChargerErrorText(errorCode) {
		var text = '';
		if(errorCode >= 10 && errorCode <= 19) {
			text = this.translateService.instant('error_codes.10')+' '+errorCode;
			return text;
		}
		if(errorCode >= 20 && errorCode <= 29) {
			text = this.translateService.instant('error_codes.20')+' '+errorCode;
			return text;
		}
		if(errorCode >= 80 && errorCode <= 89) {
			text = this.translateService.instant('error_codes.80')+' '+errorCode;
			return text;
		}

		if([41, 42, 43, 43, 44, 60, 30, 72, 73, 71, 50, 100].includes(errorCode)) {
			text = this.translateService.instant('error_codes.'+errorCode);
		} else {
			text = this.translateService.instant('error_codes.default');
		}
		return text;
	}

	getBattviewErrorText(errorCode) {

		var text = [];

		if(errorCode != 0) {

			if((errorCode & 0x01) != 0)
				text.push(this.translateService.instant('bv_error_codes.1'));

			if((errorCode & 0x02) != 0)
				text.push(this.translateService.instant('bv_error_codes.2'));

			if((errorCode & 0x04) != 0)
				text.push(this.translateService.instant('bv_error_codes.3'));

			if((errorCode & 0x08) != 0)
				text.push(this.translateService.instant('bv_error_codes.4'));

			if((errorCode & 0x10) != 0)
				text.push(this.translateService.instant('bv_error_codes.5'));
		}

		return text.join(', ');
	}

	getDateFormattedFromUnixTimeStamp(timeStamp, format = 'default') {
		if(timeStamp!=+timeStamp) return "";
		var LEAPOCH =(946684800 + 86400*(31+29));
		var DAYS_PER_400Y =(365*400 + 97);
		var DAYS_PER_100Y =(365*100 + 24);
		var DAYS_PER_4Y  = (365*4   + 1);
		var remdays, remsecs, remyears;
		var qc_cycles, c_cycles, q_cycles;
		var years, months;
		var wday, yday, leap;
		var days_in_month = [31,30,31,30,31,31,30,31,30,31,31,29];
		var secs = timeStamp - LEAPOCH;
		var days = Math.floor(secs / 86400);
		remsecs = secs % 86400;
		if (remsecs < 0) {
			remsecs += 86400;
			days--;
		}

		wday = (3+days)%7;
		if (wday < 0) wday += 7;

		qc_cycles = Math.floor(days / DAYS_PER_400Y);
		remdays = days % DAYS_PER_400Y;
		if (remdays < 0) {
			remdays += DAYS_PER_400Y;
			qc_cycles--;
		}

		c_cycles = Math.floor(remdays / DAYS_PER_100Y);
		if (c_cycles == 4) c_cycles--;
		remdays -= c_cycles * DAYS_PER_100Y;

		q_cycles = Math.floor(remdays / DAYS_PER_4Y);
		if (q_cycles == 25) q_cycles--;
		remdays -= q_cycles * DAYS_PER_4Y;

		remyears = Math.floor(remdays / 365);
		if (remyears == 4) remyears--;
		remdays -= remyears * 365;

		leap = !remyears && (q_cycles || !c_cycles);
		yday = remdays + 31 + 28 + leap;
		if (yday >= 365+leap) yday -= 365+leap;

		years = remyears + 4*q_cycles + 100*c_cycles + 400*qc_cycles;

		for (months=0; days_in_month[months] <= remdays; months++)
			remdays -= days_in_month[months];

		years+=2000;
		months+=3;//1 = January
		if(months > 12) {
			months -=12;
			years++;
		}
		function zeroPad(num, places) {
			var zero = places - num.toString().length + 1;
			return Array(+(zero > 0 && zero)).join("0") + num;
		}

		function getHourFromSeconds(timeInSeconds) {
			var hours = Math.floor(timeInSeconds / 3600);
			var minutes = Math.floor((timeInSeconds % 3600) / 60);
			var period;
			if(hours >= 12 && hours != 24) {

				if(hours != 12)
					hours -= 12;
				 period = 'PM';
			} else {

				if(hours == 0 || hours == 24)
					hours = 12;
				period = 'AM';
			}

			return {
				'hours':	hours,
				'minutes':	minutes,
				'period':	period
			};
		}

		var daysText	= ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		var monthsText	= ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

		var str;
		switch (format) {
			case "date":
				str = zeroPad(months,2) +"/" + zeroPad(remdays + 1,2) +  "/" + years.toString();
			break;
			case "LCDprojection":
				var time = getHourFromSeconds(remsecs);
				str = zeroPad(time.hours, 2) + ":" + zeroPad(time.minutes, 2) + ' ' + time.period + ' - ' + daysText[wday] + ' ' + monthsText[months-1] + '/' + (remdays + 1);
			break;
			case "12h":
				let hours: any = Math.floor(remsecs / 3600);
				let amPM = 'PM';
				if(hours < 12) {
					amPM = 'AM';
					if(hours == 0) {
						hours = 12;
					}
				} else {
					hours -= 12;
				}
				hours = zeroPad(hours, 2);
				str = zeroPad(months,2) +"/" + zeroPad(remdays + 1,2) +  "/" + years.toString() + " " + hours + ":" + zeroPad(Math.floor(remsecs / 60 % 60),2) + ":" + zeroPad(Math.floor(remsecs % 60),2) + " "+ amPM;
			break;
			default:
				str = zeroPad(months,2) +"/" + zeroPad(remdays + 1,2) +  "/" + years.toString() + " " + zeroPad(Math.floor(remsecs / 3600),2) + ":" + zeroPad(Math.floor(remsecs / 60 % 60),2) + ":" + zeroPad(Math.floor(remsecs % 60),2);
			break;
		}
		return str;
	};
	secondsToElapsedTime(disconnectTime) {
		var timespanObj = this.convertHourToSeconds(disconnectTime, 0, true);

		var timespanTextArr = [];
		var timespanText	= "";
		if(timespanObj['day'] > 0)
			timespanTextArr.push(this.singularOrPlural(timespanObj['day'], "day"));
		if(timespanObj['hour'] > 0)
			timespanTextArr.push(this.singularOrPlural(timespanObj['hour'], "hour"));

		if(timespanTextArr.length > 0)
			timespanText = timespanTextArr.join(" and ");
		else if(timespanObj['min'] > 0)
			timespanText = this.singularOrPlural(timespanObj['min'], "min");

		return timespanText;
	}
	convertHourToSeconds(hour, min, viseVersa = false, sec = 0) {
		let seconds: number = 0;
		if(viseVersa) {

			seconds			= +hour;
			let days		= Math.floor(seconds / 86400);
			let hourTime	= Math.floor((seconds - (days * 24 * 3600)) / 3600);
			let minTime		= Math.floor((seconds - (hourTime * 3600) - (days * 24 * 3600)) / 60);

			return ({day: days, hour: hourTime, min: minTime});
		} else {

			//Converts hour point in time to seconds passed
			//Ex: 01:55 -> 1 * 3600 + 55 * 60 = 6900 seconds
			var hourSeconds = hour * 3600;
			var minSeconds = min * 60;
			seconds = (+sec) || 0;

			return hourSeconds + minSeconds + seconds;
		}
	}
	getZoneName(zoneId, defaults = 0) : string {
		let zone = this.commonData.TimeZonesMenu[zoneId - 1];

		if(!zone) {
			if(!defaults)
				defaults = 14;
			zone = this.commonData.TimeZonesMenu[defaults];
		}
		return zone.display_name;
	}
	getFaultText(faultId, flags: any) {
		var errors = [];

		if ((faultId & 0x03) == 0x03)
			errors.push("SOC High Error");
		else if ((faultId & 0x03) == 0x02)
			errors.push("SOC Medium Error");
		// else if ((faultId & 0x03) == 0x01)
		// 	errors.push(1); //"SOC Low Error"

		if ((faultId & 0x04) != 0)
			errors.push("Too Long Events");

		if ((faultId & 0x08) != 0)
			if(flags && flags.enablehalleffectsensing) {
				errors.push("Hall effect sensor is not connected");
			} else {
				errors.push("Current Not calibrated");
			}

		if ((faultId & 0x10) != 0)
			errors.push("Voltage Not Calibrated");

		if ((faultId & 0x20) != 0)
			errors.push("Voltage Value is off");

		if ((faultId & 0x40) != 0)
			errors.push("External Temperature Sensor Damaged");

		if ((faultId & 0x80) != 0)
			errors.push("Intercell Temperature Sensor Damaged");

		if ((faultId & 0x100) != 0)
			errors.push('Temperature Sensor Failure');

		return errors;
	}
	timeFormat(input, options: any = {timeSystem: 24, limitToOneDay: false}) {
		var timeSystem		= options.timeSystem || 24;
		var limitToOneDay	= options.limitToOneDay || false;
		if (limitToOneDay && input >=(1*24*60*60)) {
			return '24:00';
		}

		var format = "HH:mm";
		if (timeSystem == 12) {
			format = "hh:mm A";
		}

		return moment("2015-01-01").startOf('day').seconds(input).format(format);
	}
	fahToCel(val, reverse = false) {

		if(reverse)
			return Math.round(val * 1.8 + 32);

		return parseFloat(((val - 32 ) / 1.8).toFixed(1));
	}
	getDeviceExitCode(deviceType, code) {
		var statusCode = {
			0:		'Restart (Power Recycle)',
			1:		'CC Timeout (TR)',
			2:		'CC Timeout',
			3:		'Battery Disconnection',
			4:		'User Request',
			5:		'Output Over Current',
			6:		'Output Over Voltage',
			7:		'AC line Fault',
			8:		'Over Temperature',
			9:		'Timeout',
			10:		'Output Under Voltage',
			11:		'Input Over Current Protection',
			12:		'Communication ERROR',
			13:		'CV Time Out',
			14:		'Control Saturation',
			15:		'OVDS',
			16:		'Fan Fault',
			17:		'Battery Over Temperature',
			18:		'Lockout',
			19:		'Output Current Control',
			20: 	'Battery Disconnection',
			21:     'BATTview Disconnect',
			25: 	'Completed: BMS',
			26:		'CAN Lost',
			0xAA:	'The Cycle Is Still Running',
			0xFE:	'Unknown ERROR',
			0xFF:	'Completed'
		};
		code = +code;
		if (deviceType == 'battview') {
			statusCode[0] = 'Disconnect';
			statusCode[3] = 'Disconnect';
			statusCode[0xAA] = '';
			statusCode[0xFE] = '';
		}

		var returnValue = 'Unknown ERROR';
		if (statusCode[code]!=undefined)
			returnValue = statusCode[code];
		return returnValue;
	}
	getZoneTimestampFromUTC(zoneId, unixTimestamp, enableDaylightSaving = false) {

		var zone = this.commonData.TimeZonesMenu[zoneId - 1];

		if (!zone || zone.id == 0)
			return unixTimestamp;

		unixTimestamp = (unixTimestamp + zone.base_utc);

		if (enableDaylightSaving) {

			for (let i = 0; i < 50; i++) {

				if (unixTimestamp >= zone.changes_time[i])
					unixTimestamp = (unixTimestamp + ((i % 2 === 0) ? 1 : -1) * zone.changes_value);
			}
		}

		return unixTimestamp;
	}
	monthDiff(d1, d2) {
		var months;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth();
		months += d2.getMonth();
		return months <= 0 ? 0 : months;
	}

	formatTime(hr, min, sec?) {
		let time = [];
		if(hr < 10) {
			hr = '0' + hr;
		}
		time.push(hr);
		if(min < 10) {
			min = '0' + min;
		}
		time.push(min);
		if(typeof(sec) != 'undefined') {
			if(sec < 10) {
				sec = '0' + sec;
			}
			time.push(sec);
		}
		return time.join(':');
	}
	PMidToSerialNumber(pmid, charger) {
		var model		= pmid>>>28;
		var modelMap:any	= 0;
		switch(model) {
			case 0: modelMap = "01"; break;
			case 1: modelMap = "10"; break;
			case 3: modelMap = "73"; break;
			case 4: modelMap = "02"; break;
			case 5: modelMap = "20"; break;
			case 6: modelMap = "03"; break;
			case 7: modelMap = "41"; break;
			case 8: modelMap = "42"; break;
			case 9: modelMap = "43"; break;
			case 10: modelMap = "51"; break;
			case 11: modelMap = "52"; break;
			case 12: modelMap = "53"; break;
			case 13: modelMap = "61"; break;
			case 14: modelMap = "62"; break;
			case 15: modelMap = "63"; break;
		}

		var s = (pmid & 0x00FFFFFF).toString();
		while (s.length < 8) s = "0" + s;

		// CTVW-910
		if([1, 5].includes(model)) {
			let month = s.substr(0,2);
			let year = s.substr(2,4)
			if(year+month >= "2012") {
				if(model == 1) {
					modelMap = "71";
				} else {
					modelMap = "72";
				}
			}
		}

		var startWith = "1";
		if(charger.serialnumber[0] == "7")
			startWith = "5";

		var fullSN = startWith + modelMap + s;

		return fullSN.substr(0, 7) + ((pmid & 0x03000000)>>>24) + fullSN.substr(7, 4);
	}
	getRandomColor() {
		var letters = '0123456789ABCDEF'.split('');
		var color = '#';
		for (var i = 0; i < 6; i++ ) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}
	formatTimeWithCurrentDate(input) {
		return moment(new Date()).startOf('day').seconds(input).valueOf();
	}

	getExportWidgetFiltersInfo(options) {
		let customerName			= options.customerName,
			siteName				= options.siteName,
			radioButtonGroupList	= options.radioButtonGroupList || [],
			selectedRadioButton		= options.selectedRadioButton,
			dropDownGroupList		= options.dropDownGroupList || [],
			selectedDropDown		= options.selectedDropDown,
			checkboxList			= options.checkboxList || [],
			selectedCheckbox		= options.selectedCheckbox || {},
			selectedTags			= options.selectedTags || [],
			widgetId				= options.widgetId;

		let filtersInfo = {};

		if (customerName) {
			filtersInfo['customerName'] = customerName;
			if (siteName) {
				filtersInfo['siteName'] = siteName;
			}
		}

		// add selected radio buttons
		let sectionData = [];
		for (let radioButtonGroup of radioButtonGroupList) {
			let value = selectedRadioButton[radioButtonGroup['name']];
			for (let option of radioButtonGroup['options']) {
				if (option['value'] == value) {
					sectionData.push(option.label);
					break;
				}
			}
		}

		if(sectionData.length)
			filtersInfo['radioButtons'] = sectionData;

		//add selected dropdowns
		sectionData = [];
		for (let dropDownGroup of dropDownGroupList) {
			let value = selectedDropDown[dropDownGroup['name']];
			for (let option of dropDownGroup['options']) {
				if (option['value'] == value) {
					sectionData.push(option.label);
					break;
				}
			}
		}

		if(sectionData.length)
			filtersInfo['dropDown'] = sectionData;

		// add selected checkboxes
		sectionData = [];
		for (let checkbox of checkboxList) {
			let value = selectedCheckbox[checkbox['name']];
			if (value) {
				sectionData.push(checkbox['label']);
			}
		}

		if(sectionData.length)
			filtersInfo['checkboxes'] = sectionData;

		// add selected checkboxes
		sectionData = [];
		for (let tag of selectedTags) {
			let value = selectedTags[tag['tagname']];
			if (value) {
				sectionData.push(tag['tagname']);
			}
		}

		if(sectionData.length)
			filtersInfo['tags'] = sectionData;

		return filtersInfo;
	}

	print(options) {
		let addPrintHeaderFn		= options.addPrintHeaderFn,
			customerName			= options.customerName,
			siteName				= options.siteName,
			radioButtonGroupList	= options.radioButtonGroupList || [],
			selectedRadioButton		= options.selectedRadioButton,
			dropDownGroupList		= options.dropDownGroupList || [],
			selectedDropDown		= options.selectedDropDown,
			checkboxList			= options.checkboxList || [],
			selectedCheckbox		= options.selectedCheckbox || {},
			addPrintContentFn		= options.addPrintContentFn,
			selectedTags			= options.selectedTags || [],
			widgetId				= options.widgetId,
			appendPrintContent		= options.appendPrintContent;

		let printContent = '';
		if (addPrintHeaderFn) {
			printContent+= addPrintHeaderFn();
		} else {
			printContent += '<div id="dvCompanyFullName"><h3>';
			if (customerName) {
				printContent += customerName;
				if (siteName) {
					printContent += ' / '+ siteName;
				}
			}
			printContent += '</h3></div>';
			// add selected radio buttons
			for (let radioButtonGroup of radioButtonGroupList) {
				let value = selectedRadioButton[radioButtonGroup['name']];
				for (let option of radioButtonGroup['options']) {
					if (option['value'] == value) {
						printContent+= option.label + ' ';
						break;
					}
				}
			}
			//add selected dropdowns
			for (let dropDownGroup of dropDownGroupList) {
				let value = selectedDropDown[dropDownGroup['name']];
				for (let option of dropDownGroup['options']) {
					if (option['value'] == value) {
						printContent+= option.label + '<br/>';
						break;
					}
				}
			}
			// add selected checkboxes
			for (let checkbox of checkboxList) {
				let value = selectedCheckbox[checkbox['name']];
				if (value) {
					printContent+= checkbox['label'] + ' ';
				}
			}
			// add selected checkboxes
			for (let tag of selectedTags) {
				let value = selectedTags[tag['tagname']];
				if (value) {
					printContent+= tag['tagname'] + ' ';
				}
			}
		}

		// additional content
		if (addPrintContentFn) {
			printContent+= addPrintContentFn();
		} else {
			if(widgetId) {
				const printId = widgetId.replace( /(:|\.|\[|\])/g, "\\$1" );
				printContent+=  document.getElementById(printId).innerHTML;
			}
		}

		if(appendPrintContent) {
			printContent+= appendPrintContent;
		}
		this.printContent(printContent);
	}
	printContent(content) {
		let style = '', inline = '';
		if (isDevMode()) {
			style = '<script type="text/javascript" src="styles.bundle.js"></script>';
			inline = '<script type="text/javascript" src="inline.bundle.js"></script>';
		} else {
			let links = document.getElementsByTagName('link');
			for (let i = 0; i < links.length; i++) {
			 	let elem = links[i];
				if(/styles./.test(elem.href))
					style = '<link href="' + elem.href + '" rel="stylesheet"/>' ;
			}
		}

		let wnd = window.open('','');
		let doc = wnd.document;
		doc.open();

		doc.write([
			'<!DOCTYPE html>',
			'<html>',
			'<head>',
			inline,
			style,
			'</head>',
			'<body>',
			content,
			'<script>setTimeout(function() { window.print();window.close(); } , 1000);</script>',
			'</body>'
		].join(""));
		wnd.document.close();
	}
	getUTCTimestampFromZone(zoneId, unixTimestamp, enableDaylightSaving = false) {
		var zone = this.commonData.TimeZonesMenu[zoneId - 1];

		if (!zone || zone.id == 0)
			return unixTimestamp;

		unixTimestamp = (unixTimestamp - zone.base_utc);

		if (enableDaylightSaving) {

			for (let i = 0; i < 50; i++) {

				if (unixTimestamp >= zone.changes_time[i])
					unixTimestamp = (unixTimestamp + ((i % 2 == 0) ? -1 : 1) * zone.changes_value);
			}
		}

		return unixTimestamp;
	}

	makeAssociativeArray(keys, values, keyText: any = false, valueText: any = false) {
		if(keys.length != values.length) {
			return false;
		}
		if(!keyText) {
			keyText = 'id';
		}
		if(!valueText) {
			valueText = 'name';
		}
		var arrayResult = {};
		for (var i=0; i<keys.length; i++) {
			arrayResult[keys[i]] = {};
			arrayResult[keys[i]][keyText] = keys[i];
			arrayResult[keys[i]][valueText] = values[i];
		}
		return arrayResult;
	}

	getElapsedMonths(start, end) {
		var months;
		months = (end.getFullYear() - start.getFullYear()) * 12;
		months -= start.getMonth() + 1;
		months += end.getMonth();
		return months <= 0 ? 0 : months;
	}

	getUTCstartOfDay(date) {
		if(moment(date).startOf('day').unix() != (date.getTime() / 1000))
			date = moment(date).utc().startOf('day').unix();
		else
			date = (date.getTime() + (date.getTimezoneOffset() * -1 * 60 * 1000)) / 1000;
		return date;
	}

	intToHexArray(num) {

		let res = [];

		if(num.length % 2 != 0)
			num = "0" + num;

		for(let i = 2; i <= num.length; i+=2) {
			let byte = num.substring(num.length-i, num.length-i+2);
			res.push(byte);
		}

		return res.reverse();
	}

	getHexMacAddress(macAddress) {
		if (!macAddress) return;
		let splittedAddress = macAddress.split(':');
		let hexMacAdress = [];
		for (let item of splittedAddress) {
			if (isNaN(parseInt(item))) {
				return macAddress;
			}
			hexMacAdress.push(parseInt(item).toString(16));
		}
		return hexMacAdress.join(':');
	}

	decompress(data, type) {
		let decompressedData: any = {};
		let mapped = this.swapObjectKeyAndValue(objectMappers[type]);
		for (let field in data) {
			let mappedKey = mapped[field];
			if(mappedKey) {
				let special = {"live_event": 'events', "live_rt": 'rt'};
				let specialType = special[mappedKey];
				if(specialType)
					decompressedData[mappedKey] = this.decompress(data[field], specialType);
				else
					decompressedData[mappedKey] = data[field];
			} else
				decompressedData[field] = data[field];
		}
		return decompressedData;
	}

	swapObjectKeyAndValue(sourceObj) {
		return Object.keys(sourceObj).reduce((obj, key) => (obj[sourceObj[key]] = key, obj), {});
	}

	checkChargerGseModel(serialNumber) {
		const regex = /^[72](14|24|34|26|44|45|46|36)/;
		return regex.test(serialNumber);
	}

	downloadReport(data: any) {
		if (!data) return;
		const arr = new Uint8Array(data.fileData.Body.data);
		const blob = new Blob([arr]);
		if (blob)
			saveAs(blob, data.fileName);
	}

	calculateValuesWithUnit(recordValue: number) {
		let dataRecordsUnit = '';
		if (recordValue < 1000000) {
			recordValue /= 1000;
			dataRecordsUnit = 'K';
		} else {
			recordValue /= 1000000;
			dataRecordsUnit = 'M';
		}
		return {value: recordValue, unit: dataRecordsUnit};
	}
}
