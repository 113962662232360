import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { CommonUtil } from '../../../../../../shared/services/utility/common.service';
import { BattviewsDashboardService } from '../../battviews-dashboard.service';
import { UserService } from '../../../../../../auth/user.service';
import { DeviceManagementService } from '../../../../../../device-management/device-management.service';
import { NotificationMessageService } from '../../../../../../shared/notification-message/notification-message.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-events-log',
	templateUrl: './events-log.component.html',
	styleUrls: ['./events-log.component.css']
})
export class EventsLogComponent implements OnInit, OnChanges {

	@Input() device: any = {};
	@Input() date: any = {};
	@Input() events: any[] = [];
	@Input() dailyDetails: any[] = [];
	@Input() summaryObject: any = {};
	@Input() isBattviewMobile: boolean = false;
	@Input() siteAlertsSettings: any = {};
	@Input() userAlertsSettings: any = {};
	@Input() customerID: number = 0;
	@Input() siteID: number = 0;
	@Input() isACTuser: boolean = false;
	@Input() customerName: string = '';
	@Input() siteName: string = '';
	@Input() activeTab: string = null;
	@Input() activeSubTab: string = null;

	eventsFilters: {name: string, url: string}[] = [];
	gridColumns: any = {};
	gridData: any[] = [];
	cycleDetectionObj: any = {};
	daughterCardObject: any = {};
	eventsReport: any = {};
	dailyDetailsReport: any = {};
	isVPC: boolean = true;
	isCompensatedVoltage: boolean = true;
	eventTypeColumn: any = {};
	waterLevelColumn: any = {};
	notesColumn: any = {};
	debugColumn: any = {};
	chargerExitColumn: any = {};
	selectedEventsFilter: any = {name: "All", url: ""};
	detectionConfig: any = {
		minStartTimeRange: -30,
		maxStartTimeRange: 30,
		durationEnabled: true,
		duration: 11,
		event: {},
		invalidFields: []
	};

	@ViewChild("cyclesDetectionModal") cyclesDetectionModal;
	@ViewChild("cyclesDetectionResultModal") cyclesDetectionResultModal;
	@ViewChild("DaughterCardModal") DaughterCardModal;
	@ViewChild("grid", {static: true}) grid;

	constructor(
		private battviewsDashboard: BattviewsDashboardService,
		private commonUtil: CommonUtil,
		private userService: UserService,
		private deviceManagementService: DeviceManagementService,
		private notificationMessage: NotificationMessageService,
		private location: Location,
		private router: Router,
		private route: ActivatedRoute,
		private translateService: TranslateService
	) {
		this.eventsFilters = [
			{name: "All",							url: ""},
			{name: "High Temperature",				url: "high-temperature"},
			{name: "Potential Weak Cells",			url: "potential-weak-cells"},
			{name: "Deep Discharge",				url: "deep-discharge"},
			{name: "Missed Plugged In",				url: "missed-plugged-in"},
			// {name: "Missed EQ Not Plugged In",		url: "missed-eq-not-plugged-in"},
			// {name: "Missed EQ Plugged In",			url: "missed-eq-plugged-in"},
			// {name: "Missed Finish Not Plugged In",	url: "missed-finish-not-plugged-in"},
			// {name: "Missed Finish Plugged In",		url: "missed-finish-plugged-in"},
			{name: "Water level Low",				url: "water-level-low"},
			{name: "HIGH EBU usage",				url: "high-ebu-usage"},
			{name: "Missed EQ",						url: "missed-eq"},
			{name: "Missed Finish",					url: "missed-finish"},
		];

		this.eventTypeColumn = {
			headerName: "battery.event_type",
			field: "event_name_formatted",
			width: 150,
			floatingFilterComponent: "dropDownFilterComponent",
			floatingFilterComponentParams: {dropDownOptions: [{label: '', value: null}], filterOptionType: 'contains'},
		};
		this.waterLevelColumn = {
			headerName: "battery.water_level",
			field: "water_ok_text",
			width: 130,
			floatingFilterComponent: "dropDownFilterComponent",
			floatingFilterComponentParams: {dropDownOptions: [{label: '', value: null}]},
		};
		this.chargerExitColumn = {
			headerName: "device.charger_exit",
			field: "exit",
			width: 350,
			hide: (this.isBattviewMobile ? 1 : 0),
			floatingFilterComponent: "dropDownFilterComponent",
			floatingFilterComponentParams: {dropDownOptions: [{label: 'N/A Option', value: null}]},
		};
		this.debugColumn = {
			headerName: "g.debug",
			field: "debug",
			width: 260,
			floatingFilterComponent: "dropDownFilterComponent",
			floatingFilterComponentParams: {dropDownOptions: [{label: '', value: null}]},
		};
		this.notesColumn = {
			headerName: "g.notes",
			field: "notes",
			width: 450,
			hide: 1,
			floatingFilterComponent: "dropDownFilterComponent",
			floatingFilterComponentParams: {dropDownOptions: [{label: '', value: null}]},
		};
	}

	onChargerCellClicked(event) {
		let data = event.node.data;
		if(data.charger_name_formatted && !(data.isChargerCyclesDetectionCol && data.userHasActAdminDeviceManagement)) {
			if(data.is_bv_daughter_card) {
				return this.daughterCardModal(data);
			}
			return this.router.navigate(["/", this.customerID, this.siteID, "chargers", "analytics", "cycles", data.charger_id], {queryParams: {batterySerialNumber: data.batterySerialNumber}});
		}
		if(data.event_name == 1 && (this.isACTuser || this.userService.hasAccessFunction('events_view_debug'))) {
			return this.showCyclesDetectionModal(data);
		}
		return null;
	}

	ngOnInit() {
		this.gridColumns = [
			{headerName: "charger.sequence", field: "event_id", type:"number", width: 150},
			this.eventTypeColumn,
			{headerName: "act.start_time", field: "start_time_formatted", width: 150, filterParams: {defaultOption: 'startsWith'}},
			{headerName: "act.end_time", field: "endTime_formatted", hide: 1, width: 150, filterParams: {defaultOption: 'startsWith'}},
			{headerName: "device.duration", field: "duration_formatted", width: 150, filterValueGetter: 'data.duration', filterParams: {
				filterOptions: ["equals", "greaterThan", "lessThan"],
				defaultOption: "equals",
				thisObject: this,
				textCustomComparator: this.grid.durationFormatter
			}},
			{headerName: "device.start_soc", field: "start_soc", type:"number", width: 140, hide: !(this.isBattviewMobile ? 0 : 1)},
			{headerName: "device.end_soc", field: "end_soc", type:"number", width: 140, hide: !(this.isBattviewMobile ? 0 : 1)},
			{headerName: "device.charge_ah", field: "charge_as_formatted", type:"number", width: 150, filterParams: "1.1-1"},
			{headerName: "device.in_use_ah", field: "inuse_as_formatted", type:"number", width: 150, filterParams: "1.1-1"},
			{headerName: "device.charge_kwhr", field: "charge_ws_formatted", type:"number", width: 170, hide: !(this.isBattviewMobile ? 1 : 0), filterParams: "1.2-2"},
			{headerName: "device.in_use_kwhr", field: "inuse_ws_formatted", type:"number", width: 160, hide: !(this.isBattviewMobile ? 1 : 0), filterParams: "1.2-2"},
			{headerName: "charger.start_voltage", field: "start_voltage_formatted", width: 160, hide: !(this.isBattviewMobile ? 0 : 1), type:"number", filterParams: "1.2-2"},
			{headerName: "charger.end_voltage", field: "end_voltage_formatted", width: 160, type:"number", filterParams: "1.2-2"},
			{headerName: "charger.min_voltage", field: "min_voltage_formatted", width: 160, type:"number", filterParams: "1.2-2"},
			{headerName: "charger.start_temperature", field: "start_temperature_formatted", type:"number", filterParams:"1.0-0", width: 200, hide: !(this.isBattviewMobile ? 0 : 1)},
			{headerName: "charger.end_temperature", field: "end_temperature_formatted", type:"number", filterParams:"1.0-0", width: 200, hide: !(this.isBattviewMobile ? 0 : 1)},
			{headerName: "charger.max_temperature", field: "max_temperature_formatted", type:"number", filterParams:"1.0-0", width: 200, hide: !(this.isBattviewMobile ? 0 : 1)},
			{headerName: "charger.max_temperature_time", field: "max_temperature_time_formatted", width: 220, hide: 1, filterParams: {defaultOption: 'startsWith'}},
			{headerName: "charger.average_current", field: "event_average_current_formatted", type: "number", width: 200, hide: !(this.isBattviewMobile ? 0 : 1)},
			{headerName: "charger.max_current", field: "event_max_current_formatted", type: "number", width: 160, hide: !(this.isBattviewMobile ? 0 : 1)},
			this.waterLevelColumn,
			{headerName: "charger.profiles", field: "profiles", width: 300, hide: !(this.isBattviewMobile ? 0 : 1)},
			this.chargerExitColumn,
			{
				headerName: "device.charger", width:380, field: "charger_name_formatted", hide: !(this.isBattviewMobile ? 0 : 1), filter: "nullFilter",
				onCellClicked: ($e) => {
					this.onChargerCellClicked($e);
				},
				cellRendererSelector: (params) => {
					if(params.data.charger_name_formatted) {
						if(params.data.is_bv_daughter_card) {
							params['label'] = params.data.daughterCard.pretty_name+' ('+params.data.charger_name_formatted+') BV Daughter Card';
						} else {
							params['label'] = params.data.charger_name_formatted;
						}

						return {
							component: 'linkCellRenderer',
							params: params
						};
					}
					if(params.data.event_name == 1 && (params.colDef.cellRendererParams.isACTuser || params.colDef.cellRendererParams.user_has_events_view_debug)) {
						params['label'] = params.colDef.cellRendererParams.translateService.instant('battery.charger_cycles_detection');
						return {
							component: 'linkCellRenderer',
							params: params,
						};
					}
					return null;
				},
				cellRendererParams: {
					isACTuser: this.isACTuser,
					user_has_events_view_debug: this.userService.hasAccessFunction('events_view_debug'),
					translateService: this.translateService
				},
				floatingFilterComponent: "dropDownFilterComponent",
				floatingFilterComponentParams: {
					dropDownOptions: [
						{label: '', value: 'null'},
						{label: 'None', value: 'none'},
						{label: 'With Quantum', value: 'not_null'},
						{label: 'With BATTView Daughter Card', value: 'not_null2'},
					],
					type: "nullFilter",
					notNull2Field: 'is_bv_daughter_card'
				}
			}
		];

		if(this.userService.hasAccessFunction('act_admin_device_management')) {
			this.gridColumns.push({
				headerName: 'battery.charger_cycles_detection',
				width:380,
				field: "detection_column",
				hide: 1,
				filter: false,
				execludeOnExport: true,
				onCellClicked: ($e) => {
					if($e.node.data.event_name == 1) {
						this.showCyclesDetectionModal($e.node.data);
					}
				},
				cellRendererSelector: (params) => {
					if(params.data.event_name == 1) {
						params['label'] = params.colDef.cellRendererParams.translateService.instant('battery.charger_cycles_detection');
						return {
							component: 'linkCellRenderer',
							params: params,
						};
					}
					return null;
				},
				cellRendererParams: {
					translateService: this.translateService
				}
			});
		}

		if (this.isACTuser) {
			if(!this.isBattviewMobile)
				this.gridColumns.push(this.debugColumn);
		}
		if (this.isACTuser || this.userService.hasAccessFunction('events_view_debug'))
			this.gridColumns.push({headerName: "charger.insertion_time",field: "insertiontime_formatted",width: 200, filterParams: {defaultOption: 'startsWith'}});
		
		if (this.isACTuser) {
			this.gridColumns.push(this.notesColumn);
		}

		if(this.activeSubTab) {
			for (var i=0; i < this.eventsFilters.length; i++) {
				if (this.eventsFilters[i].url == this.activeSubTab) {
					this.selectedEventsFilter = this.eventsFilters[i];
					break;
				}
			}
			this.filterEvents(this.selectedEventsFilter);
		}
	}

	ngOnChanges(changes) {
		if(changes.events && Object.keys(changes.events.currentValue).length > 0 && changes.events.currentValue) {
			// this.eventsReport = this.battviewsDashboard.generateDailyDetailReport(changes.dailyDetails.currentValue, this.device);
			this.dailyDetailsReport = this.battviewsDashboard.generateDailyDetailReport(this.dailyDetails, this.device, this.userAlertsSettings);
			this.filterEvents(this.selectedEventsFilter);
		}
	}

	filterEvents(selectedEventsFilter?) {
		// change url
		if(selectedEventsFilter) {
			this.changeUrl(selectedEventsFilter.url);
		}
		if(!selectedEventsFilter) selectedEventsFilter={name: null, url: null};

		this.selectedEventsFilter = selectedEventsFilter;
		var filteredEvents = [];
		switch (selectedEventsFilter.name) {
			case "High Temperature":
				//  if the flag override_temperature_limit is true,then use temperature_limit to check the temperature if high , the value comes is in C not in Fahrenheit.
				var temperatureLimit = this.device.batteryhightemperature;
				if (this.siteAlertsSettings.override_temperature_limit) {
					temperatureLimit = this.siteAlertsSettings.temperature_limit;
				}
				this.events.forEach((event, i) => {
					if (event.data.temperature_sensor_enabled == true && event.data.max_temperature > temperatureLimit) {
						filteredEvents.push(event);
					}
				});
			break;
			case "Potential Weak Cells":
				var deep_use_limit = 1.7;
				if (this.siteAlertsSettings.deep_use_limit) {
					deep_use_limit = this.siteAlertsSettings.deep_use_limit;
				}
				this.events.forEach((event, i) => {
					if (event.data.event_name == 3) {
						var VPC = event.data.end_voltage / (this.device.nominalvoltage/2);
						if(VPC < deep_use_limit) {
							filteredEvents.push(event);
						}
					}
				});
			break;
			case "Deep Discharge":
				var deep_discharge_limit = 2;
				if (this.siteAlertsSettings.deep_discharge_limit) {
					deep_discharge_limit = this.siteAlertsSettings.deep_discharge_limit;
				}
				this.events.forEach((event, i) => {
					if (event.data.event_name == 2) {
						var VPC = event.data.end_voltage / (this.device.nominalvoltage/2);
						if(VPC < deep_discharge_limit) {
							filteredEvents.push(event);
						}
					}
				});
			break;
			case "Missed Plugged In":

				if (this.events[0]) {
					var prevEvent = this.events[0].data || {};
				}
				var localEvents		= [],
					oneDaySeconds	= 60*60*24;

				// angular.copy(this.events, localEvents);
				localEvents = localEvents.sort((a,b) => {return (a.data.event_id > b.data.event_id) ? 1 : ((b.data.event_id > a.data.event_id) ? -1 : 0);});

				this.events.forEach((event, i) => {
					var currEvent = event.data;
					if (currEvent.event_name == 2 && currEvent.event_id > 1 && prevEvent.event_name == 3) {
						if((currEvent.start_time == (+prevEvent.start_time + +prevEvent.duration)) && currEvent.duration > 600) {
							
							var day = parseInt(moment(+currEvent.start_time*1000).utc().startOf('day').format("X")),
							previousDay			= day - oneDaySeconds,
							previousDayOfWeek	= new Date(previousDay * 1000).getDay();

							var pluggedInPeriodsFilters = [];
							if (this.siteAlertsSettings.missed_connection_alert && this.siteAlertsSettings.missed_connection_alert[previousDayOfWeek]) {
								pluggedInPeriodsFilters = this.siteAlertsSettings.missed_connection_alert[previousDayOfWeek];
							}

							var eventWithinWorkingHours = false;
							for (var periodIndex in pluggedInPeriodsFilters) {
								var period = pluggedInPeriodsFilters[periodIndex];
								if (+currEvent.start_time >= (+period.start + day) || (+currEvent.start_time + +currEvent.duration) <= (+period.end + day)) {
									eventWithinWorkingHours = true;
									break;
								}
							}

							if (!eventWithinWorkingHours) {
								filteredEvents.push(event);
							}
						}
					}
					prevEvent = currEvent;
				});
			break;
			case 'Water level Low':
				var waterLevelLowDays = [];

				var filteredDays = [];

				var sortedDailyDetails = this.dailyDetailsReport.sort((a,b) => {return (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0);});

				sortedDailyDetails.forEach((currentDailyDetails, i) => {
					if (currentDailyDetails.waterLevelLow) {
						waterLevelLowDays.push(currentDailyDetails.date / 1000);
					}

					if (currentDailyDetails.countOfEqs > 0 && waterLevelLowDays.length >= this.siteAlertsSettings.water_level_low_days) {
						for (var index in waterLevelLowDays) {
							if (waterLevelLowDays[index]) {
								filteredDays.push(waterLevelLowDays[index]);
								delete waterLevelLowDays[index];
							}
						}
					}

					if (!currentDailyDetails.waterLevelLow) {
						waterLevelLowDays = [];
					}
				});

				this.events.forEach((event, i) => {
					if (filteredDays.indexOf(parseInt(moment(+event.data.start_time*1000).utc().startOf('day').format("X"))) > -1) {
						filteredEvents.push(event);
					}
				});
			break;
			case "HIGH EBU usage":
				var expectedEBU;
				switch (this.device.chargertype) {
					case 0:
						expectedEBU = this.siteAlertsSettings.fast_ebu_limit;
					break;
					case 1:
						expectedEBU = this.siteAlertsSettings.conventional_ebu_limit;
					break;
					case 2:
						expectedEBU = this.siteAlertsSettings.opportunity_ebu_limit;
					break;
					// case 3:
					// 	expectedEBU = this.siteAlertsSettings.lithium_ebu_limit;
					// break;
				}

				var filteredDays = [];
				this.dailyDetailsReport.forEach((currentDailyDetails, i) => {
					if ((currentDailyDetails.inuseAs/(3600*0.8 * this.device.ahrcapacity)) > expectedEBU) {
						filteredDays.push(currentDailyDetails.date / 1000);
					}
				});

				this.events.forEach((event, i) => {
					if (filteredDays.indexOf(parseInt(moment(+event.data.start_time*1000).utc().startOf('day').format("X"))) > -1) {
						filteredEvents.push(event);
					}
				});
			break;
			case 'Missed EQ Not Plugged In':
			case 'Missed EQ Plugged In':
			case 'Missed Finish Plugged In':
			case 'Missed Finish Not Plugged In':
			case 'Missed EQ':
			case 'Missed Finish':
				var filteredDays = this.battviewsDashboard.getMissedData(this.dailyDetails, selectedEventsFilter.name, 'date', this.device);
				this.events.forEach((event, i) => {
					if (filteredDays.indexOf(parseInt(moment(+event.data.start_time*1000).utc().startOf('day').format("X"))) > -1) {
						filteredEvents.push(event);
					}
				});
			break;
			default:
				filteredEvents = this.events;
			break;
		}
		let formatOptions = {isACTuser: this.isACTuser, isCompensatedVoltage: this.isCompensatedVoltage, isVPC: this.isVPC};
		let formatResult = this.battviewsDashboard.formatData(filteredEvents, this.device, formatOptions);
		this.gridData = formatResult.formattedSequentials;
		this.eventTypeColumn.floatingFilterComponentParams.dropDownOptions = [this.eventTypeColumn.floatingFilterComponentParams.dropDownOptions[0]];
		for(var eventType in formatResult.filteredEventTypes) {
			this.eventTypeColumn.floatingFilterComponentParams.dropDownOptions.push({value: formatResult.filteredEventTypes[eventType].toLowerCase(), label: formatResult.filteredEventTypes[eventType]});
		}
		this.waterLevelColumn.floatingFilterComponentParams.dropDownOptions = [this.waterLevelColumn.floatingFilterComponentParams.dropDownOptions[0]];
		for(var waterLevel in formatResult.waterLevelOptions) {
			this.waterLevelColumn.floatingFilterComponentParams.dropDownOptions.push({value: formatResult.waterLevelOptions[waterLevel].toLowerCase(), label: formatResult.waterLevelOptions[waterLevel]});
		}
		this.notesColumn.floatingFilterComponentParams.dropDownOptions = [this.notesColumn.floatingFilterComponentParams.dropDownOptions[0]];
		for(var notesOption in formatResult.notesOptions) {
			this.notesColumn.floatingFilterComponentParams.dropDownOptions.push({value: formatResult.notesOptions[notesOption].toLowerCase(), label: formatResult.notesOptions[notesOption]});
		}
		this.debugColumn.floatingFilterComponentParams.dropDownOptions = [this.debugColumn.floatingFilterComponentParams.dropDownOptions[0]];
		for(var debugOption in formatResult.debugOptions) {
			this.debugColumn.floatingFilterComponentParams.dropDownOptions.push({value: formatResult.debugOptions[debugOption].toLowerCase(), label: formatResult.debugOptions[debugOption]});
		}
		this.chargerExitColumn.floatingFilterComponentParams.dropDownOptions = [this.chargerExitColumn.floatingFilterComponentParams.dropDownOptions[0]];
		for(var chargerExitOption in formatResult.chargerExitOptions) {
			this.chargerExitColumn.floatingFilterComponentParams.dropDownOptions.push({value: formatResult.chargerExitOptions[chargerExitOption].toLowerCase(), label: formatResult.chargerExitOptions[chargerExitOption]});
		}
	}

	onChangeVPC(isVPC) {
		for (var i = 0; i < this.events.length; i++) {
			var end_voltage_formatted = this.events[i].data.vpc_end_voltage;
			var min_voltage_formatted = this.events[i].data.vpc_min_voltage;
			var start_voltage_formatted = this.events[i].data.vpc_start_voltage;
			if (!isVPC) {
				end_voltage_formatted = this.events[i].data.end_voltage;
				min_voltage_formatted = this.events[i].data.min_voltage;
				start_voltage_formatted = this.events[i].data.start_voltage;
			}
			else if(this.isCompensatedVoltage && this.events[i].data.event_name_formatted == 'Charge') {
				start_voltage_formatted = this.events[i].data.compensation_start_voltage;
				end_voltage_formatted = this.events[i].data.compensation_end_voltage;
			}
			this.events[i].data.end_voltage_formatted = Math.round(end_voltage_formatted * 100) /100;
			this.events[i].data.min_voltage_formatted = Math.round(min_voltage_formatted * 100) /100;
			this.events[i].data.start_voltage_formatted = Math.round(start_voltage_formatted * 100) /100;
		}
		this.grid.redrawRows();
	}

	onChangeTemperatureCompensatedVoltage(toTemperature) {
		if(!this.isVPC)
			return;

		for(var i = 0; i < this.events.length; i++) {
			
			if(this.events[i].data.event_name_formatted != 'Charge')
				continue;

			var end_voltage_formatted	= this.events[i].data.compensation_end_voltage;
			var start_voltage_formatted	= this.events[i].data.compensation_start_voltage;
			if (!toTemperature) {
				end_voltage_formatted	= this.events[i].data.vpc_end_voltage;
				start_voltage_formatted	= this.events[i].data.vpc_start_voltage;
			}
			this.events[i].data.end_voltage_formatted	= Math.round(end_voltage_formatted * 100) /100;
			this.events[i].data.start_voltage_formatted	= Math.round(start_voltage_formatted * 100) /100;
		}
		this.grid.redrawRows();
	}

	daughterCardModal(event) {
		this.deviceManagementService.getBattviewDaughterCard(event.daughterCard.serialnumber).subscribe(
			(response: any) => {
				this.daughterCardObject = response;
				this.DaughterCardModal.show();
			}
		);
	}

	editDaughterCardRequest() {
		
		this.daughterCardObject.isEdit = true;
		this.DaughterCardModal.hide();

		this.deviceManagementService.saveDaughterCard(this.daughterCardObject).subscribe(
			(response:any) => {
				this.notificationMessage.setMessage(response, 'success')

				if(this.events) {
					this.events.forEach((item) => {
						if(item.daughterCard && item.daughterCard.serialnumber == this.daughterCardObject.serialnumber)
							item.daughterCard.pretty_name = this.daughterCardObject.pretty_name;
					});
				}
				if(this.gridData) {
					this.gridData.forEach((item) => {
						if(item.daughterCard && item.daughterCard.serialnumber == this.daughterCardObject.serialnumber)
							item.daughterCard.pretty_name = this.daughterCardObject.pretty_name;
					});
				}
		});
	}

	goToChargerCycle(id) {
		this.cyclesDetectionResultModal.hide();
		this.cyclesDetectionModal.hide();
		return this.router.navigate(['/', this.customerID, this.siteID, 'chargers', 'analytics', 'cycles', id]);
	}

	runDetection(stage) {
		if(stage != 'OK') {
			return this.cyclesDetectionModal.hide();
		}
		this.detectionConfig.invalidFields = this.validateDetectionConfig();
		if(this.detectionConfig.invalidFields.length > 0) {
			return;
		}

		this.cyclesDetectionModal.hide();
		this.setDefaultDetectionConfig();
		let config: any = {
			minStartTimeRange: this.detectionConfig.minStartTimeRange,
			maxStartTimeRange: this.detectionConfig.maxStartTimeRange,
		};
		if (this.detectionConfig.durationEnabled) {
			config.duration = this.detectionConfig.duration;
		}
		let params = {
			siteid: this.siteID,
			customerid: this.customerID,
			eventTime: this.detectionConfig.event.start_time,
			ampSec: this.detectionConfig.event.charge_as,
			duration: this.detectionConfig.event.duration,
			config: config
		};
		
		this.battviewsDashboard.cyclesDetection(params).subscribe(
			(response: any) => {
				this.cycleDetectionObj = {
					data: response.cycles,
					devices: response.chargers,
					customerid: this.customerID,
					siteid: this.siteID
				};

				this.cyclesDetectionResultModal.show();
			}
		);
	}

	showCyclesDetectionModal(event) {
		if(!(this.isACTuser || this.userService.hasAccessFunction('act_admin_device_management')) || !event)
			return;

		this.detectionConfig.invalidFields = [];

		this.detectionConfig.event = event;
		this.getDefaultDetectionConfig();

		return this.cyclesDetectionModal.show();
	}

	getDefaultDetectionConfig() {
		try {
			let config = JSON.parse(localStorage.getItem('defaultDetectionConfig'));
			if(!config)
				return;
	
			if(config.minStartTimeRange != undefined)
				this.detectionConfig.minStartTimeRange = config.minStartTimeRange;
			if(config.maxStartTimeRange != undefined)
				this.detectionConfig.maxStartTimeRange = config.maxStartTimeRange;
			if(config.durationEnabled != undefined)
				this.detectionConfig.durationEnabled = config.durationEnabled;
			if(config.duration != undefined)
				this.detectionConfig.duration = config.duration;
		} catch(ex) {}
	}

	setDefaultDetectionConfig() {
		let config = {
			minStartTimeRange: this.detectionConfig.minStartTimeRange,
			maxStartTimeRange: this.detectionConfig.maxStartTimeRange,
			durationEnabled: this.detectionConfig.durationEnabled,
			duration: this.detectionConfig.duration
		};

		return localStorage.setItem('defaultDetectionConfig', JSON.stringify(config));
	}

	validateDetectionConfig() {
		let validationRules = {
			'minStartTimeRange'	: [{type: 'integer', min: -60, max: 0}],
			'maxStartTimeRange'	: [{type: 'integer', min: 0, max: 60}],
			'duration'			: [{type: 'integer', min: -60, max: 60}],
		};

		if(!this.detectionConfig.durationEnabled)
			delete validationRules.duration;

		let invalidFields = [];

		for(let field in this.detectionConfig) {

			let rules = validationRules[field] || [];
			for (let i = 0; i < rules.length; i++) {
				let rule = rules[i];
				let isValid = this.commonUtil.dataValidator(rule.type, rule, this.detectionConfig[field]);

				if(!isValid)
					invalidFields.push(field);
			}
		}

		return invalidFields;
	}

	getCSVcontent() {
		var fields = [];
		var displayNamesFields = [];

		var headers = [];
		var i = 0
		this.gridColumns.forEach((col) => {
			if(col.hide != 1) {
				fields.push(col.field);
				headers.push(i++);
				displayNamesFields.push(col.headerName);
			}
		});

		var items = [];
		this.gridData.forEach((row) => {
			var item = [];
			fields.forEach((field) => {
				item.push(row[field]);
			});
			items.push(item);
		});

		var informationRow = [
			["Battery Reports", "Events Log"], 
		];
		
		if(!this.isBattviewMobile) {
			informationRow.push(["Model", this.device.model]);
			informationRow.push(["Battery Serial Number", this.device.batterysn]);
		}

		informationRow.push(["BATTview Serial Number", "*" + this.device.serialnumber]);
		informationRow.push(["ID", this.device.batteryid]);

		if(!this.isBattviewMobile) {
			informationRow.push(["Customer / Site" , this.customerName + " " + this.siteName]);
		}

		informationRow.push(["Date Range", moment(this.date.fromDate).format('MM/DD/YYYY') + " - " + moment(this.date.toDate).format('MM/DD/YYYY')]);
		informationRow.push(["Truck ID", this.device.truckid]);

		var csv = [headers];
		csv.push([]);

		informationRow.forEach((row) => {
			csv.push(row);
		});

		csv.push([]);
		csv.push([]);
		csv.push([]);
		csv.push(displayNamesFields);
		
		items.forEach((row) => {
			csv.push(row);
		});
		return csv;
	}

	changeUrl(subTab) {
		this.location.go(
			this.router.createUrlTree(
				['analytics', this.activeTab, this.device.id || 0, subTab],
				{'relativeTo': this.route.parent}
			).toString()
		);
	}

	isInvalidDetectionConfigField(field) {
		return this.detectionConfig.invalidFields.includes(field);
	}
}