import { Component, OnInit, Input } from '@angular/core';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';

@Component({
	selector: 'app-charger-assets-utilization',
	templateUrl: './charger-assets-utilization.component.html',
	styleUrls: ['./charger-assets-utilization.component.css']
})
export class ChargerAssetsUtilizationComponent implements OnInit {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: ColumnChartConfig;
	tableColumns: any[] = [
		{title: "Charger ID", property: "chargerusername"},
		{title: "Charger SN", property: "serialnumber"}
	];
	title: string = 'Charger Assets Utilization';
	elementId: string = 'charger_assets_utilization';
	widgetClass: string = 'auto-height-widget';
	siteWidgetOptions: any = {
		hasOperation: true,
		hasChargerModels: true,
		hasDate: true,
		isSitePerformanceWidget: true,
		isChargers: true,
		excludeSinceInstallationOption: true
	};

	constructor(private chartUtil: ChartUtil) { }

	ngOnInit() {
		this.config = new ColumnChartConfig({
			isPercent: true,
			xTitle: "% Utilization",
			yTitle: "% Chargers",
			tooltip: {trigger: 'hover'},
			vAxisMinValue: 0
		});

		this.config.formatFunction = (data) => {
			data = data.list;
			let colors = ['#bd2f26', '#3f69ec'];
			let formattedData: any[] = [
				[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'},
				]
			];

			for(let i = 0; i < data.length; i++) {
				let item: any = data[i];
				formattedData.push(
					[
						{v: item.x + "%"},
						{v: item.y / 100},
						{v: ''},
						{v: 'color: ' + this.chartUtil.getColor(item.x)}
					]
				);
			}

			return formattedData;
		};
	}
}
