import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import * as _ from 'lodash';
import { ActFirstService } from '../act-first.service';
import { SiteDashboardService } from '../../site-dashboard.service';
import { ChargersDashboardService } from '../../chargers/chargers-dashboard/chargers-dashboard.service';
import * as moment from 'moment';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';

@Component({
	selector: 'app-historical-charger-log',
	templateUrl: './historical-charger-log.component.html',
	styleUrls: ['./historical-charger-log.component.css']
})
export class HistoricalChargerLogComponent implements OnInit {

	fromDateFmt: Date;
	data = {};
	chargerIds = [];
	siteId: number;
  customerId: number;
  siteChargers = [];
  chargerId = null;
  validFrmTime: boolean = true;
  validToTime: boolean = true;
  fromTime;
  toTime;
  dataFetched:boolean = false;
  dataArr = [];
  currentSite: any;

	constructor(
		private actFirstService: ActFirstService,
		private route: ActivatedRoute,
		private siteDashboardService: SiteDashboardService,
		private chargersDashboard: ChargersDashboardService,
		private commonUtil : CommonUtil,
		private sideMenuService: SideMenuService
	) { }

	ngOnInit() {

		this.siteDashboardService.setActiveTab('actfirst');
		this.siteDashboardService.setActiveSubTab('historical-charger-log');

		this.siteId = this.route.parent.snapshot.params['siteid'];
    this.customerId = this.route.parent.snapshot.params['customerid'];

    this.getChargersList();
    this.fromDateFmt = new Date();
    this.fromTime = new Date(new Date().setHours(0,0,0,0));
	  this.toTime = new Date(new Date().setHours(1,0,0,0));

		this.sideMenuService.currentSite.subscribe(data => {
			if(data && data.id) {
				this.currentSite = data;
			}
		});
	}

	getChargersList() {
    this.chargersDashboard.getChargers(
      {
        'customerid':  this.customerId,
        'siteid':    this.siteId
      }, 
      {
        'getTags': false,
        'getGroups': false
    }).subscribe(
      data => {
        data['list'].forEach((charger) => {
          charger['prettyName'] = charger['chargerusername'] + ' (' + charger['serialnumber'] + ')';
        });
        this.siteChargers = _.chain(data['list']).sortBy('serialnumber').sortBy('id').value()
      }
    );
  }

	getHistoricalChargerLogData() {
		this.dataFetched = true;
		if (!this.validFrmTime || !this.validToTime) {
			return;
		}

		let fmhrs = moment(this.fromTime).hours();
		let fmmins = moment(this.fromTime).minutes();
		let tohrs = moment(this.toTime).hours();
		let tomins = moment(this.toTime).minutes();

		let fromDate:any= new Date(new Date(this.fromDateFmt).setHours(fmhrs, fmmins, 0, 0));
		fromDate		= Math.floor((-fromDate.getTimezoneOffset() * 60000 + fromDate.getTime()) / 1000);
		fromDate		= this.commonUtil.getUTCTimestampFromZone(this.currentSite.zoneid, fromDate, true);

		let toDate:any	= new Date(new Date(this.fromDateFmt).setHours(tohrs,tomins,0,0));
		toDate			= Math.floor((-toDate.getTimezoneOffset() * 60000 + toDate.getTime()) / 1000);
		toDate			= this.commonUtil.getUTCTimestampFromZone(this.currentSite.zoneid, toDate, true);

		this.dataArr = [];
		this.data = {};

		this.actFirstService.getHistoricalLog(this.siteId, fromDate, toDate, this.chargerId).subscribe(result => {
			if(!result['data'])
				return;
			for (let logObj of result['data']) {
				for (let group of logObj['groups']) {
					for (let innerGroup in group['innergroups']) {
						for(let obj of group['innergroups'][innerGroup]) {
							if(this.data[obj.id]) {
								this.data[obj.id].push(obj);
							} else {
								this.data[obj.id] = [obj];
							}
							obj.groupname = group['groupname'];
							obj.status = this.actFirstService.getGroupName(obj.innergroup);
							obj.time_formatted = moment(this.commonUtil.getZoneTimestampFromUTC(this.currentSite.zoneid, obj.time, true)*1000).utc().format('MMM DD, YYYY, hh:mm:ss A');
							obj.exit_status_text = this.commonUtil.getDeviceExitCode('charger', obj.exit_status);
							obj.name_link = '/' + this.customerId + '/' + this.siteId + '/chargers/analytics/cycles/' + obj.id;
							if (obj.batteryid) {
								obj.battery_id_link = '/' + this.customerId + '/' + this.siteId + '/battviews/analytics/events-log/' + obj.batteryid;
							}
							
						}
					}
				}
			}

			for (let chargerId in this.data) {
				this.dataArr = this.dataArr.concat(this.data[chargerId]);
			}

			this.dataArr =  _.sortBy(this.dataArr, ['time']).reverse();
			this.chargerIds = _.keys(this.data);
		});

	}

}
