<div class="row col-md-8 col-md-offset-2">
  <div id="page-wrapper" >
    <div id="page-inner">
      <div class="panel panel-default">
        <form #addNewIPForm="ngForm" class="margin-bottom-xs" novalidate name="addNewIP">
          <div class="padding-8">
            <strong>{{'g.add_new_ip_address' | translate}}</strong>
          </div>
          
          <div class="padding-8">
            <input
              type="text"
              class="form-control inline-form-control"
              [(ngModel)]="IPaddress"
              name="ipAddress"
              pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
              placeholder='0.0.0.0'
              #ipAddress="ngModel"
            />
            <span class="red-text" *ngIf="ipAddress.invalid">* {{'g.invalid_ip_address' | translate}}</span>
          </div>

          <div class="padding-8">
            <button class="btn btn-success tcenter" (click)="editProductionLineIPs('add', IPaddress)" [disabled]="!IPaddress"><span class="glyphicon glyphicon-plus"></span> {{'g.add' | translate}}</button>
          </div>
        </form>

        <div class="panel-body padding-8">
          <br/>
          <table class="table">
            <thead>
              <tr>
                <th>{{'g.ip_address' | translate}}</th>
                <th>{{'g.actions' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of ipAddresses">
                <td>{{item.ip}}</td>
                <td><span (click)="editProductionLineIPs('delete', item)" title="Delete"><span class="pointer red-text glyphicon glyphicon-trash"></span></span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>