import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PieChartConfig } from '../../../../../shared/google-chart/Models/PieChartConfig';

@Component({
	selector: 'app-over-under-charge-alert',
	templateUrl: './over-under-charge-alert.component.html',
	styleUrls: ['./over-under-charge-alert.component.css']
})
export class OverUnderChargeAlertComponent implements OnInit, OnChanges {

	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: PieChartConfig;
	tableColumns: any[] = [];
	columnsNames: any[] = [];
	title: string = 'Over / Under Charge';
	elementId: string = 'batt_alert_over_under_charge';
	widgetClass: string = 'height-530';
	siteWidgetOptions: any = {
		hasDate: true,
		isAlertWidget: true
	};

	constructor() { }

	ngOnInit() {
	
		this.config = new PieChartConfig({
			colors: [
				{ color: '#FED631' },
				{ color: '#bd2f26' },
				{ color: '#109618' },
			]
		});
		
		this.config.formatFunction = (data) => {
			let formattedData: any[] = [
				[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"}
				]
			];

			formattedData.push([{v: "Under"}, {v: data.Pie[0].under}]);
			formattedData.push([{v: "Over"}, {v: data.Pie[1].over}]);
			formattedData.push([{v: "OK"}, {v: data.Pie[2].ok}]);
			return formattedData;
		};
		this.config.updateColumns = (index) => {
			let columnsArray = [];
			columnsArray.push(this.tableColumns[0]);
			columnsArray.push(this.tableColumns[1]);
			columnsArray.push(this.columnsNames[index]);
			return columnsArray;
		}
		this.columnsNames = [
			{title: "% under charge", property: "charge_percentage"},
			{title: "% over charge", property: "charge_percentage"},
			{title: "Charge Percentage", property: "charge_percentage"}
		];
	}
	
	ngOnChanges(changes) {
		if(this.data) {
			let tableColumns = [{title: "Battery ID", property: "battery_id"}];
			if (this.data.siteSettings.userSettings.view_truck_id) {
				tableColumns.push({title: "Truck ID", property: "truck_id"});
			}
			tableColumns.push({title: "Charge Percentage", property: "charge_percentage"});
			this.tableColumns = tableColumns;
		}
	}
}
