import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import * as moment from 'moment';

@Component({
	selector: 'app-charge-summary',
	templateUrl: './charge-summary.component.html',
	styleUrls: ['./charge-summary.component.css']
})
export class ChargeSummaryComponent implements OnInit, OnChanges {

	@Input() date: {
		fromDate: any,
		toDate: any
	} = {
		fromDate: new Date(),
		toDate: new Date()
	};
	@Input() dailyDetails: any = {};
	@Input() summaryObject: any = {};
	@Input() device: any = {};
	@Input() customerName: string = null;
	@Input() siteName: string = null;

	summaryObj: {
		SerialNumber: any,
		ModelNumber: any,
		NominalVoltage: any,
		AhrCapacity: any,
		totalRecodedDays: any,
		totalCharges: any,
		totalMissedFinishes: any,
		totalchargeAHR: any,
		totalDischargeAHR: any,
		maxTemp: any,
		minSoc: any,
		totalEQ: any,
		totalEQWaterOK: any,
		totalMissedEQs: any,
		AvgEBUPerDay: any,
	} = {
		SerialNumber: null,
		ModelNumber: null,
		NominalVoltage: null,
		AhrCapacity: null,
		totalRecodedDays: null,
		totalCharges: null,
		totalMissedFinishes: null,
		totalchargeAHR: null,
		totalDischargeAHR: null,
		maxTemp: null,
		minSoc: null,
		totalEQ: null,
		totalEQWaterOK: null,
		totalMissedEQs: null,
		AvgEBUPerDay: null,
	};

	constructor(
		private decimalPipe: DecimalPipe
	) { }

	ngOnInit() {
	}
	ngOnChanges(changes) {
		if(changes.summaryObject && changes.summaryObject.currentValue) {
			this.generateChargeSummaryBattView(changes.summaryObject.currentValue);
		}
	}

	generateChargeSummaryBattView = function(_dailyDetails){
		let chargeSummary: any = {};
		chargeSummary.Title = '';
		if (this.dailyDetails.length > 0) {
			let startDate = new Date(this.dailyDetails[0].date*1000);
			let endDate = new Date(this.dailyDetails[this.dailyDetails.length - 1].date*1000);
			chargeSummary.Title = 'Start ' + (startDate.getMonth()+1) + '/' + startDate.getDate() +'/'+startDate.getFullYear() + "   End " + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' + endDate.getFullYear();
		}
		chargeSummary.SerialNumber = this.device.serialnumber;
		chargeSummary.ModelNumber = this.device.batteryid;
		chargeSummary.NominalVoltage = this.device.nominalvoltage;
		chargeSummary.AhrCapacity = this.device.ahrcapacity;
		chargeSummary.totalRecodedDays = _dailyDetails.totalRecodedDays;
		chargeSummary.totalCharges = _dailyDetails.totalCharges;
		chargeSummary.totalMissedFinishes = _dailyDetails.totalMissedFinishes;
		chargeSummary.totalchargeAHR = this.decimalPipe.transform(Math.round((_dailyDetails.totalchargeAHR * 10) / 10));
		chargeSummary.totalDischargeAHR = this.decimalPipe.transform(Math.round(_dailyDetails.totalDischargeAHR));
		
		if(_dailyDetails.maxTemp != this.minValue && _dailyDetails.maxTemp === parseInt(_dailyDetails.maxTemp))
			chargeSummary.maxTemp = Math.round(1.8 * _dailyDetails.maxTemp + 32);
		else
			chargeSummary.maxTemp = 'N/A';
		chargeSummary.minSoc = _dailyDetails.minSoc;
		chargeSummary.totalEQ = _dailyDetails.totalEQ;
		chargeSummary.totalEQWaterOK = _dailyDetails.totalEQWaterOK;
		chargeSummary.totalMissedEQs = _dailyDetails.totalMissedEQs;
		if(_dailyDetails.totalDischargeAHR != 0 && _dailyDetails.totalDischargeDays != 0) {
			chargeSummary.AvgEBUPerDay = this.decimalPipe.transform(_dailyDetails.totalDischargeAHR / (0.8 * _dailyDetails.totalDischargeDays * this.device.ahrcapacity), '1.2-2');
			// upper limit of EBU to 3
			if (chargeSummary.AvgEBUPerDay > 3)
				chargeSummary.AvgEBUPerDay = 3;
		} else
			chargeSummary.AvgEBUPerDay = 'N/A';

		this.summaryObj = chargeSummary;
	}

	getCSVcontent() {
		return [
			["Client: ", this.customerName, " Site: ", this.siteName],
			["Model", this.device.batterymodel],
			["Battery S/N", this.device.batterysn],
			["Battery ID", this.device.batteryid],
			["Date Range", moment(this.date.fromDate).format('MM/DD/YYYY') + " - " + moment(this.date.toDate).format('MM/DD/YYYY')],
			["Battery Truck ID", this.device.truckid],
			["Nominal Voltage", this.device.nominalvoltage],
			["Capacity AHR", this.device.ahrcapacity],
			["Days", this.summaryObj.totalRecodedDays],
			["Charges", this.summaryObj.totalCharges],
			["Missed Finish", this.summaryObj.totalMissedFinishes],
			["Total Charge AH", this.summaryObj.totalchargeAHR],
			["Total Discharge AH", this.summaryObj.totalDischargeAHR],
			["Max Temp", this.summaryObj.maxTemp],
			["Min SOC", this.summaryObj.minSoc],
			["EQs", this.summaryObj.totalEQ],
			["EQs With Water Ok", this.summaryObj.totalEQWaterOK],
			["Missed EQs", this.summaryObj.totalMissedEQs],
			["Avg EBU Per Day", this.summaryObj.AvgEBUPerDay]
		];
	}
}