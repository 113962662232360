<div class="boxs3 margin-top-lg">
	<div class="row" *ngIf="!isWeekly">
		<div class="row margin-bottom">
			<div class="col-md-2 text-right-md "><strong>{{'g.date' | translate}}:</strong></div>
			<div class="col-md-3">
				<div class="input-group">
					<input class="form-control" placeholder="mm/dd/yyyy" name="startDP1" [(ngModel)]="startDate" bsDatepicker #startDP1="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
					<span class="input-group-btn">
						<button class="btn btn-default btn-date" (click)="startDP1.toggle()" type="button"><i class="fa fa-calendar"></i></button>
					</span>
				</div>
			</div>
		</div>
	</div>

	<div class="row" *ngIf="isWeekly">
		<div class="col-md-6">
			<div class="form-row noprint">
				<div class="form-group col-md-6">
					<label for="fromDate">{{'g.from' | translate}}</label>
					<select class="form-control" id="fromDate" [(ngModel)]="startDate">
						<option [value]="i.value" *ngFor="let i of startWeekList">{{i.label}}</option>
					</select>
				</div>
				<div class="form-group col-md-6">
					<label for="toDate">{{'g.to' | translate}}</label>
					<select class="form-control" id="toDate" [(ngModel)]="endDate">
						<option [value]="i.value" *ngFor="let i of endWeekList">{{i.label}}</option>
					</select>
				</div>
			</div>
		</div>
	</div>
	<div class="text-danger" *ngIf="invalidDates && isWeekly">{{'time.end_start_time' | translate}}</div>

	<div class="row">
		<button class="btn btn-primary" (click)="fetchData()" [disabled]="isWeekly && (!startDate || !endDate)">{{'g.get_records' | translate}}</button>
	</div>
	<div class="row" *ngIf="reportNotReadyYet">
		<h4>Report is not ready yet!</h4>
	</div>
	<div *ngIf="getData && !reportNotReadyYet">
		<div class="margin-top-lg" *ngIf="!isWeekly">
			<div class="col-md-12">
				<div class="row">
					<label class="col-md-2"><input type="radio" [(ngModel)]="isVPCVoltage" class="form-control" [value]="true" (change)="formatData()" /> {{'device.vpc' | translate}}</label>
					<label class="col-md-2"><input type="radio" [(ngModel)]="isVPCVoltage" class="form-control" [value]="false" (change)="formatData()" /> {{'charger.battery_voltage' | translate}}</label>
					<span class="col-md-8">
						<input type="checkbox" [(ngModel)]="isCompensatedVoltage" class="form-control" (change)="formatData()"/>
						<label>{{'device.voltages_as_temperature_compensated' | translate}}</label>
					</span>
				</div>
			</div>
			<app-grid-v2
				id="connect_voltage_charge_events"
				[columns]="gridColumnsPerGroup"
				[data]="gridRows"
			></app-grid-v2>
		</div>
		<div class="margin-top-lg">
			<div class="col-md-12">
				<div class="col-md-3">
					<h4><input type="checkbox" [(ngModel)]="hideZeros" (change)="formatData()"> Hide Zero Values</h4>
				</div>
				<div class="col-md-9">
				</div>
			</div>
			<app-grid-v2
				id="connect_voltage_percentage"
				[columns]="gridColumnsNoGroup"
				[data]="gridRows2"
				[settings]="{
					keepDefaultColumnsInExport: true,
					hasSelectColumn: false,
					hasReset: false
				}"
			></app-grid-v2>
		</div>
	</div>

	<div class="margin-top-lg" *ngFor="let groupChart of groupCharts">
		<simple-widget [title]="groupChart.groupname" *ngIf="getData && !reportNotReadyYet">
			<div class="margin-bottom toolss2">
				<button class="fa fa-print" data-toggle="tooltip" title="Print" (click)="printChart(groupChart.groupname, groupChart.groupid);"></button>
			</div>
			<div class="margin-top-xlg">
				<column-chart 
					(onChartReady)="generateImageURI($event)"
					[data]="groupChart.dataTable"
					[config]="config"
					[elementId]="groupChart.groupid"
					(onSelectItem)="onSelectVPCBarItem($event, groupChart.groupid)">
				</column-chart>
			</div>
			<div class="margin-top-xlg" style="width: 410px;" *ngIf="!isWeekly">
				<app-grid-v2 *ngIf="selectedItemVPCFilter[groupChart.groupid]"
					[id]="groupChart.groupname+'_for_vpc_'+selectedItemVPCFilter[groupChart.groupid]+'.csv'"
					[columns]="gridColumnsVPCFiltered"
					[data]="selectedItemVPCGrid[groupChart.groupid]"
					[settings]="{
						height: 'auto',
						domLayout: 'autoHeight'
					}"
				></app-grid-v2>
			</div>
		</simple-widget>
	</div>
</div>