import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SiteDashboardService } from '../site-dashboard.service';
import { User } from '../../../auth/user.model';
import { UserService } from '../../../auth/user.service';
import { SideMenuService } from '../../../shared/side-menu/side-menu.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { SiteInfoService } from './site-info.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-site-info',
	templateUrl: './site-info.component.html',
	styleUrls: ['./site-info.component.css']
})
export class SiteInfoComponent implements OnInit {

	siteId;
	customerId;
	user: User = new User();
	canManageACTintelligent: boolean = false;
	canViewRejectedDevices: boolean = false;
	uniqueIPaddresses = [];
	siteCommodity;
	siteInfoData ;
	widgetData:any = {'battview-list' : { data: null } , 'charger-list': { data: null }, 'site-information': {data: null} };
	SiteInformationDataSubscription: Subscription = new Subscription();
	updateSiteCommoditySubscription: Subscription = new Subscription();

	constructor(
		private siteDashboardService: SiteDashboardService,
		private userService: UserService,
		private sideMenu: SideMenuService,
		private notificationMessageService: NotificationMessageService,
		private siteInfoService: SiteInfoService,
		private route: ActivatedRoute,
		private commonUtil: CommonUtil
	) {
		this.siteDashboardService.setActiveTab('site-info');
	}

	ngOnInit() {
		this.user = this.userService.getCurrentUser();
		this.canManageACTintelligent = this.user.accessFunctions.accounting || this.user.accessFunctions.act_intelligent_management || this.user.accessFunctions.admin_act_intelligent;
		this.canViewRejectedDevices = this.user.accessFunctions.view_rejected_device_connections;
		
		this.siteId = this.route.snapshot.params.siteid;
		this.customerId = this.route.snapshot.params.customerid;

		this.SiteInformationDataSubscription = this.siteInfoService.getSiteInformationData(this.customerId, this.siteId).subscribe(res=>{
			this.siteInfoData = res;
			this.widgetData['site-information'].data = res;
			this.widgetData['battview-list'].data = res.battview_list;
			this.widgetData['battview-list'].userSettings = res.userSettings;
			this.widgetData['charger-list'].data = res.charger_list;
			if(res && this.user.accessFunctions.view_rejected_device_connections) {
				for (let deviceType of ['charger_list', 'battview_list']) {
					if(res[deviceType]) {
						res[deviceType].forEach(device=> {
							if(device.ip_address && this.uniqueIPaddresses.indexOf(device.ip_address) == -1)
								this.uniqueIPaddresses.push(device.ip_address);
						});
					}
				}
			}
			if(res && res.siteInfo) {
				this.siteCommodity = {
					'firmware': res.siteInfo.commodity_firmware,
					'download': res.siteInfo.commodity_download,
					'settings': res.siteInfo.commodity_settings
				};
			}
		});
	}

	printSiteInfo() {
		const widgetNameKeyMapper = {
			'charger_list': 'Chargers List',
			'battview_list': 'BATTview List'
		};
		const deviceListColumns = {
			'battview_list': [
				{"name": "Battery ID",			'field': "battery_id"},
				{"name": "BATTView SN",			'field': "serialnumber"},
				{"name": "Model",				'field': "model"},
				{"name": "Battery Voltage",		'field': "batteryvoltage"},
				{"name": "Battery Capacity",	'field': "batterycapacity"}
			],
			'charger_list': [
				{"name": "Charger ID",	'field': "chargerusername"},
				{"name": "Charger S/N",	'field': "serialnumber"},
				{"name": "Model",		'field': "model"}
			]
		};
		
		let HTMLcontent = document.getElementById('site_info_widget').innerHTML;

		for(let widgetName in this.siteInfoData) {
			if(['battview_list', 'charger_list'].indexOf(widgetName) == -1)
				continue;
			let tempHtml = '';
			tempHtml += '<div class="boxs3 margin-bottom-lg element-contains-table"><h2>' + widgetNameKeyMapper[widgetName] + '</h2>';
			tempHtml += '<table class="table"><thead><tr>';
			deviceListColumns[widgetName].forEach(function(column) {
				tempHtml += '<th>' + column.name + '</th>';
			});
			tempHtml += '</tr></thead><tbody>';
			this.siteInfoData[widgetName].forEach(function(printItem) {
				tempHtml += '<tr>';
				deviceListColumns[widgetName].forEach(function(column) {
					tempHtml += '<td><span>'+(printItem[column.field] ? printItem[column.field] : '')+'</span></td>';
				});
				tempHtml += '</tr>';
			});
			tempHtml += '</tbody></table></div>';
			HTMLcontent += tempHtml;
		}
		this.commonUtil.print({
			appendPrintContent: HTMLcontent
		});
	}


	updateSiteCommodity(type) {
		let value = this.siteCommodity[type];
		this.updateSiteCommoditySubscription = this.siteInfoService.updateSiteCommodity(this.siteId, type, value).subscribe(res => {
				this.notificationMessageService.setMessage('globalSuccessMsg', 'success', 10000);
			}, err => {
				this.siteCommodity[type] = !value;
			 this.notificationMessageService.setMessage('globalErrMsg', 'danger', 10000);
			}
		)
	}


	showOnlyConnectedIPs(filterIPs) {
		let autoconnectFreq = 1;
		let IPs		= [];
		let data	= this.siteInfoData;
		let currentSite = this.sideMenu.getCurrentSite();

		if(!data) {
			this.uniqueIPaddresses = [];
			return;
		}
		
		if(filterIPs) {
			
			if(currentSite.act_intelligent_type == "0") {
				this.uniqueIPaddresses = [];
				return;
			}
			
			let defaultFreq = {
				'charger_list':		60,
				'battview_list':	900
			};

			let nowDate	= Math.round(new Date().getTime() / 1000);
			
			['charger_list', 'battview_list'].forEach(function(type) {
				if(data[type]) {
					data[type].forEach(function(item) {

						let connectFrequency = +item.actviewconnectfrequency;

						if(connectFrequency == autoconnectFreq)
							connectFrequency = defaultFreq[type];

						let lastconnecttime	= Math.round(new Date(item.lastconnecttime).getTime() / 1000);
						let disconnectTime		= nowDate - lastconnecttime; //disconnectTime in seconds

						if(item.ip_address && disconnectTime <= (2.5 * connectFrequency))
							IPs.push(item.ip_address);
					});
				}
			});

		} else {

			['charger_list', 'battview_list'].forEach(function(type) {
				if(data[type]) {
					data[type].forEach(function(device) {
						if(device.ip_address)
							IPs.push(device.ip_address);
					});
				}
			});
		}
		
		IPs = IPs.filter(function(elem, pos) {
			return IPs.indexOf(elem) == pos;
		});

		this.uniqueIPaddresses = IPs;
	}


	ngOnDestroy() {
		this.SiteInformationDataSubscription.unsubscribe();
		this.updateSiteCommoditySubscription.unsubscribe();
	}
}
