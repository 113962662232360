<div *ngIf="customerId && siteId">
	<div id="page-inner">
		<div class="panel panel-default">
			<div class="panel-heading"><strong>Site Tags</strong></div>
		</div>
		<div class="col-md-4">
			<div class="panel panel-default">
				<div class="panel-heading"><strong>Public</strong></div>
				<div class="panel-body">
					<div *ngFor="let tag of sitePublicTags" class="l">
						<div class="btn btn-md btn-default pointer p-5 m-5 word-break-btn btn-group">
							<span>{{tag.tagname}}</span>
							<span *ngIf="currentUser.isACTuser || tag.ownerid == currentUser.id" appDropdown>
								<i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
								<ul class="dropdown-menu" role="menu">
									<li><a class="pointer" *ngIf="tag.ownerid == currentUser.id" (click)="editTagSharingOption(tag, 'private')">Change Permission to Private</a></li>
									<li><a class="pointer" *ngIf="currentUser.isACTuser" (click)="editTagSharingOption(tag, 'group')">Change Permission to Group</a></li>
									<li *ngIf="currentUser.id == tag.ownerid || currentUser.isACTuser"><a class="pointer" (click)="tagRemoved(tag)">Delete Tag</a></li>
								</ul>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-4">
			<div class="panel panel-default">
				<div class="panel-heading"><strong>Private</strong></div>
				<div class="panel-body">
					<div *ngFor="let tag of sitePrivateTags" class="l">
						<div class="btn btn-md btn-default pointer p-5 m-5 word-break-btn btn-group">
							<span>{{tag.tagname}}</span>
							<span *ngIf="currentUser.isACTuser || tag.ownerid == currentUser.id" appDropdown>
								<i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
								<ul class="dropdown-menu" role="menu">
									<li><a class="pointer" (click)="editTagSharingOption(tag, 'public')">Change Permission to Public</a></li>
									<li><a class="pointer" *ngIf="currentUser.isACTuser" (click)="editTagSharingOption(tag, 'group')">Change Permission to Group</a></li>
									<li *ngIf="currentUser.id == tag.ownerid || currentUser.isACTuser"><a class="pointer" (click)="tagRemoved(tag)">Delete Tag</a></li>
								</ul>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-4" *ngIf="currentUser.isACTuser">
			<div class="panel panel-default">
				<div class="panel-heading"><strong>Group</strong></div>
				<div class="panel-body">
					<div *ngFor="let tag of siteGroupTags" class="l">
						<div class="btn btn-md btn-default pointer p-5 m-5 word-break-btn btn-group" appDropdown>
							{{tag.tagname}}
							<i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
							<ul class="dropdown-menu" role="menu">
								<li><a class="pointer" (click)="editTagSharingOption(tag, 'public')">Change Permission to Public</a></li>
								<li><a class="pointer" *ngIf="tag.ownerid == currentUser.id" (click)="editTagSharingOption(tag, 'private')">Change Permission to Private</a></li>
								<li *ngIf="currentUser.id == tag.ownerid"><a class="pointer" (click)="tagRemoved(tag)">Delete Tag</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-12">
			<div class="panel panel-default">
				<div class="panel-heading"><strong>Add New Tags</strong></div>
				<div class="panel-body">
					<tags [customerId]="customerId" [siteId]="siteId" [isMultiple]="false" [addToSite]="true" [isACTuser]="currentUser.isACTuser" (onUpdateTags)="siteTagAdded($event)"></tags>
				</div>
			</div>
		</div>

		<div *ngIf="currentSite.act_first_enabled" class="col-md-12">
			<div class="panel panel-default">
				<div class="panel-heading"><strong>Site Truck Types</strong></div>
				<div class="panel-body">
					<div *ngFor="let group of siteGroups" class="l">
						<div class="btn btn-md btn-default pointer p-5 m-5 word-break-btn btn-group">
							<span>{{group.text}}</span>
							<span appDropdown>
								<i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
								<ul class="dropdown-menu" role="menu">
									<li><a class="pointer" (click)="removeGroup(group.id)">Delete Truck Type</a></li>
								</ul>
							</span>
						</div>
					</div>
					<div style="margin-top:50px">
						<ng-select
							[multiple]="false"
							[addTag]="true"
							[items]="[]"
							(change)="addGroup($event)"
							[hideSelected]="false"
							placeholder="Add new truck type"
							[clearable]="false"
							[(ngModel)]="newGroup"
						></ng-select>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>