import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { HeaderService } from '../../shared/header/header.service';
import { FooterService } from '../../shared/footer/footer.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
	providers: [AuthService]
})
export class LoginComponent implements OnInit, OnDestroy {

	showCaptcha:boolean = false;
	blockRemining: number;
	blocki
	loginBlock: any ={
		recaptcha: ''
	};

	@ViewChild("captcha") captcha;

	constructor( 
		private authService: AuthService, 
		private header: HeaderService, 
		private footer: FooterService,
		private router: Router,
		private notificationMessage: NotificationMessageService,
		private navigation: NavigationService,
		private translateService: TranslateService
	) { 
		this.header.hide();
		this.footer.hide();
	}

	isAct = true;
	yearAge = new Date().getFullYear();

	ngOnInit() {

	}

	onLogin(form: NgForm) {
		const username = form.value.username;
		const password = form.value.password; 
		const recaptcha = this.loginBlock.recaptcha;
		this.authService.loginUser({
			username: username,
			password: password,
			roleId: -1,
			name: '',
			recaptcha: recaptcha
		}).subscribe(res => {
			if (res['obj'].code == 1) {
				// SUCCESSFUL LOGIN
				let url = this.navigation.getPreviousUrl();
				if (url) {
					this.router.navigateByUrl(url);
				} else {
					this.router.navigate(['/']);
				}
			} else if (res['obj']?.showCaptcha){
				this.notificationMessage.setMessage(this.translateService.instant('user.too_many_invalid_attempt_login'))
				this.showCaptcha = res['obj'].showCaptcha;
				this.resetCaptcha();
			} else if (res['obj']?.shouldBlockLogin){
				this.showCaptcha = true;
				this.blockRemining = res['obj'].blockTimeRemaining;
				this.resetCaptcha();
				this.notificationMessage.setMessage(this.translateService.instant('user.login_blocked_try_after_x_mints')+ (` ${this.blockRemining} minutes`));
			} else if (res['obj'].code == -1) {
				this.notificationMessage.setMessage(this.translateService.instant('user.invalid_username_or_password'));
			} else if (res['obj'].code == -2) {
				this.notificationMessage.setMessage(this.translateService.instant('user.login_to_actview_is_not_allowed'));
			} else {
				this.notificationMessage.setMessage('globalErrMsg');
			}

		});
	}

	resetCaptcha() {
		if (this.captcha) {
			this.captcha.reset();
			this.loginBlock.recaptcha = '';
		}
	  }

	ngOnDestroy() {
		this.header.show();
		this.footer.show();
	}
}
