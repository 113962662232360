<div class="boxs3">
  <div class="dvBatteryUsageSummary" id="dvBatteryUsageSummary">
    <span class="showOnPrint" *ngIf="!isBattviewMobile"><b>Client:</b> {{customerName}}&nbsp;&nbsp;&nbsp;&nbsp;<b>Client Site:</b> {{siteName}}</span>
    <span class="showOnPrint"><b>{{date.fromDate | date:'MM/dd/yyyy'}} - {{date.toDate | date:'MM/dd/yyyy'}}</b></span>
    <hr class="showOnPrint" />
    <b>Battery/ Truck Rating /ID</b>
    <table cellspacing="1" cellpadding="1" class="reportTable">
      <tr>
        <td class="col-md-3">Battery ID</td>
        <td>{{device.batteryid}}</td>
      </tr>
      <tr>
        <td class="col-md-3">Battery Nominal Voltage</td>
        <td>{{device.nominalvoltage}}</td>
      </tr>
      <tr>
        <td class="col-md-3">Battery Capacity</td>
        <td>{{device.ahrcapacity}}</td>
      </tr>
    </table>
    <br />
    <table class="table reportTable">
      <tr>
        <td colspan="4"><b>Cumulative Usage Summary</b></td>
      </tr>
      <tr>
        <td></td>
        <td style="text-decoration:underline;">Charge</td>
        <td style="text-decoration:underline;">In Use</td>
        <td style="text-decoration:underline;">Idle</td>
      </tr>
      <tr>
        <td>Hours</td>
        <td>{{dataObj.ChargeHours}}</td>
        <td>{{dataObj.InUseHours}}</td>
        <td>{{dataObj.IdleHours}}</td>
      </tr>
      <tr>
        <td>%</td>
        <td>{{dataObj.ChargeHoursPercentage}}</td>
        <td>{{dataObj.InUseHoursPercentage}}</td>
        <td>{{dataObj.IdleHoursPercentage}}</td>
      </tr>
      <tr>
        <td>AHrs</td>
        <td>{{dataObj.ChargeAhr}}</td>
        <td>{{dataObj.InUseAhr}}</td>
        <td>-</td>
      </tr>
      <tr>
        <td>%</td>
        <td>{{dataObj.ChargeAhrPercentage}}</td>
        <td>{{dataObj.InUseArhPercentabe}}</td>
        <td>-</td>
      </tr>
      <tr>
        <td colspan="4"><b>Daily Usage (Inuse) Summary ({{dataObj.NumberOfDays}} days)</b></td>
      </tr>
      <tr>
        <td></td>
        <td style="text-decoration:underline;">Min</td>
        <td style="text-decoration:underline;">Average</td>
        <td style="text-decoration:underline">Max</td>
      </tr>
      <tr>
        <td>Hours</td>
        <td>
          <div class="tooltip">
            {{dataObj.MinDailyUsageHours}}
            <span class="tooltiptext tooltip-top">{{dataObj.minDailyUsageDateTime}}</span>
          </div>
        </td>
        <td>{{dataObj.AvgDailyUsageHours}}</td>
        <td>
          <div class="tooltip">
            {{dataObj.MaxDailyUsageHours}}
            <span class="tooltiptext tooltip-top">{{dataObj.maxDailyUsageDateTime}}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td>AHrs</td>
        <td>
          <div class="tooltip">
            {{dataObj.MinDailyUsageAhrs}}
            <span class="tooltiptext tooltip-top">{{dataObj.minDailyUsageAhrsDateTime}}</span>
          </div>
        </td>
        <td>{{dataObj.AvgDailyUsageAhrs}}</td>
        <td>
          <div class="tooltip">
            {{dataObj.MaxDailyUsageAhr}}
            <span class="tooltiptext tooltip-top">{{dataObj.maxDailyUsageAhrsDateTime}}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="4"><b>Daily EBU Usage Summary</b></td>
      </tr>
      <tr>
        <td></td>
        <td style="text-decoration:underline;">Average</td>
        <td style="text-decoration:underline;">Max</td>
      </tr>
      <tr>
        <td>EBUs</td>
        <td>{{dataObj.AvgEBUs}}</td>
        <td>
          <div class="tooltip">
            {{dataObj.MaxEBUs}}
            <span class="tooltiptext tooltip-top">{{dataObj.maxDailyUsageAhrsDateTime}}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="4"><b>Daily Hours Available for Charging</b></td>
      </tr>
      <tr>
        <td style="width:33%"></td>
        <td style="text-decoration:underline;width:33%;">Min</td>
        <td style="text-decoration:underline">Average</td>
      </tr>
      <tr>
        <td>Available Charging Hrs</td>
        <td>
          <div class="tooltip">
            {{dataObj.MinDailyAvailableHours}}
            <span class="tooltiptext tooltip-top">{{dataObj.minChargeOppurtinityTime}}</span>
          </div>
        </td>
        <td>{{dataObj.AvgDailyAvailableHours}}</td>
      </tr>
      <tr>
        <td ><b>Hourly AHr Usage Summary</b></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td style="width:33%"></td>
        <td style="text-decoration:underline;width:33%;">Min</td>
        <td style="text-decoration:underline;width:33%">Average</td>
        <td style="text-decoration:underline">Max</td>
      </tr>
      <tr>
        <td>AHrs / Hour</td>
        <td>
          <div class="tooltip">
            {{dataObj.MinHourlyAhrUsage}}
            <span class="tooltiptext tooltip-top">{{dataObj.minAHperHrTime}}</span>
          </div>
        </td>
        <td>{{dataObj.AvgHourlyAhrUsage}}</td>
        <td>
          <div class="tooltip">
            {{dataObj.MaxHourlyAhrUsage}}
            <span class="tooltiptext tooltip-top">{{dataObj.maxAHperHrTime}}</span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>