import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { LoaderService } from '../../../../../shared/loader/loader.service';
import { UserService } from '../../../../../auth/user.service';
import { ChargersDashboardService } from '../chargers-dashboard.service';
import { DevicesDashboardService } from '../../../devices/devices-dashboard.service';

@Component({
	selector: 'app-charger-display',
	templateUrl: './charger-display.component.html',
	styleUrls: ['./charger-display.component.css']
})
export class ChargerDisplayComponent implements OnInit, OnDestroy, OnChanges {

	@Input() charger: any = {};
	@Input() chargerID: number = 0;
	@Input() activePage: string = '';

	cycleId: number = 0;
	LCDcharger: any = {};
	currentUser: any = {};
	LCD: any = {};
	oldLCD: any;
	countDown: any;
	intervalIDs: any = {};
	quarts_6_18 = [];
	desulfationVoltages = [];
	LCDbuttonsClass		= "";
	imgPostfix			= "";
	readonlyMCBremote	= false;
	isBlockedUI			= false;
	emptyLCD			= true;
	newLithiumBattery	= true;

	showEQ = false;
	showDesulfate = false;
	warningSignTooltip = "";
	showActionButtons: boolean = true;
	showSOC: boolean = true;


	LCDhistory = [];
	snapshotStart: string;
	snapshotEnd: string;
	LCDvideo = {
		value: 0,
		playing: false,
		options: {
			hidePointerLabels: true,
			hideLimitLabels: true,
			floor: 0,
			ceil: 0,
			step: 1,
		}
	};
	pmvoltage;
	firmwareversion;
	desulfateSaveError = false;
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private loaderService: LoaderService,
		private commonUtil: CommonUtil,
		public userService: UserService,
		private chargersDashboard: ChargersDashboardService,
		private devicesDashboard: DevicesDashboardService,
		private datePipe: DatePipe,
	) { }

	ngOnInit() {

		this.currentUser = this.userService.getCurrentUser();

		if(this.activePage == 'lcd-projection') {
			
			this.quarts_6_18 = this.commonUtil.getDropDownListData('clock',  {start:6, end:18, step:15});

			if(this.userService.hasAccessFunction('readonly_mcb_remote')) {
				
				this.imgPostfix			= "-gray";
				this.readonlyMCBremote	= true;
			} else {
				this.LCDbuttonsClass = "lcd-btn pointer";
			}
			
			this.readLCDstatus(true);
			
			this.intervalIDs.readLCDstatus = setInterval(() => {

				this.readLCDstatus();

			}, 3000); //every 3 seconds

			this.intervalIDs.enableAutoconnect = setInterval(() => {

				if(this.chargerID && this.LCDcharger.isSupported !== false)
					this.devicesDashboard.enableAutoconnect(this.chargerID, 'charger').subscribe();

			}, 60000); //every 1 minute
		} else if(this.activePage == 'lcd-history') {

			this.route.queryParams.subscribe( params => {
				this.cycleId = Number(params['cycleId']) || 0;
			});
		}
	}

	ngOnChanges(changes) {
		if(this.activePage == 'lcd-history' && this.charger.id)
			this.getLCDhistory(true);
	}

	readLCDstatus(blockUI = false) {
		if(this.chargerID) {
			this.chargersDashboard.readLCDstatus(this.chargerID, blockUI).subscribe( (response: any) => {

				this.LCDcharger	= response.charger;
				var newLCD		= response.LCD;

				if(!this.oldLCD) {

					this.oldLCD	= {};
					this.LCD	= newLCD;
				} else {

					for(var field in newLCD) {
						
						var oldVal = this.oldLCD[field];
						var newVal = newLCD[field];
						
						if(!this.oldLCD.hasOwnProperty(field) || oldVal != newVal)
							this.LCD[field] = newVal;
					}

					for(var field in this.oldLCD) {
						
						if(!newLCD.hasOwnProperty(field))
							delete this.LCD[field];
					}
				}

				this.oldLCD = Object.assign({}, newLCD);
				
				if(this.LCDcharger && this.LCDcharger.commandExecuted && this.isBlockedUI) {
					this.loaderService.display(false);
					this.isBlockedUI = false;
				}

				this.initializeLCDvariables();
				
				if(!this.emptyLCD) {
					if(this.LCD.connectCountDown) {
						if(typeof this.countDown == 'undefined') {
							this.countDown = this.LCD.connectCountDown;
							this.connectCountDown();
						}
					} else {
						delete this.countDown;
					}
				}
			});
		}
	}

	connectCountDown() {
		setTimeout(() => {
			this.countDown--;
			if(this.countDown)
				this.connectCountDown();
		}, 1000);
	};

	initializeLCDvariables() {
				
		this.emptyLCD = true;
		this.newLithiumBattery = false;
		if(Object.keys(this.LCD).length > 1)
			this.emptyLCD = false;

		if (this.firmwareversion !== this.LCDcharger.firmwareversion || this.pmvoltage !== this.LCDcharger.pmvoltage) {
			this.desulfationVoltages = [];
			var start	= 24;
			this.pmvoltage		= this.LCDcharger.pmvoltage;
	
			var step = 12;
			this.firmwareversion = this.LCDcharger.firmwareversion;
			if(this.LCDcharger.firmwareversion > 2.1)
				step = 2;
	
			for(var i = start; i <= this.pmvoltage; i+=step) {
				this.desulfationVoltages.push({'id': this.desulfationVoltages.length, 'text': i});
			}
		}
		if(!this.emptyLCD) {

			if(this.LCDcharger.batterytype == 1 && this.LCDcharger.firmwareversion > 2.5) {
				this.newLithiumBattery = true;

				var numberOfCells = this.LCDcharger.liion_numberofcells;
				var factor = 1;

				if(this.LCDcharger.enableautodetectmultivoltage) {

					factor = 1.5;

					if(this.LCDcharger.firmwareversion >= 2.52)
						numberOfCells = this.LCD.numberOfCells_2;
				}
				
				this.LCD.VPC = (factor * this.LCD.voltageVal / 100) / numberOfCells;
			} else {
				var enableLauncher = this.currentUser.userAccess.MCB_enableLauncher == 'write';

				this.showEQ = false;
				if(enableLauncher || this.LCDcharger.enablemanualeq) {

					this.showEQ = true;
				}

				if(this.LCD.simulationMode > 0 && this.LCD.batteryCharging_p > 0 && this.LCD.batteryDisCharging_p > 0 && this.LCD.EQ_p == 2) {
					
					this.showEQ = false;
				}

				this.showDesulfate = false;
				if(enableLauncher || this.LCDcharger.enablemanualdesulfate) {

					this.showDesulfate = true;
				}

				if(this.LCD.simulationMode > 0 && this.LCD.batteryCharging_p > 0 && this.LCD.batteryDisCharging_p > 0) {
					
					this.showDesulfate = false;
				}

				if(this.LCD.runWithBMS != 0) {
					this.showEQ = false;
					this.showDesulfate = false;
				}
			}

			this.warningSignTooltip = '';

			if(this.LCD.warningInfo) {
				if(this.LCD.warningInfo.type == 'Faulted') {

					if(this.LCD.warningInfo.count == 1)
						this.warningSignTooltip = this.LCD.warningInfo.count + ' PM has Fault(s)';
					else
						this.warningSignTooltip = this.LCD.warningInfo.count + ' PMs have Fault(s)';
				} else if(this.LCD.warningInfo.type == 'Never connected') {

					this.warningSignTooltip = this.LCD.warningInfo.count + ' PMs not Detected';
				}
			}

			if(this.LCDcharger.isSupported && this.LCD.timestamp) {
				this.LCD.LCDtimestamp = this.commonUtil.getDateFormattedFromUnixTimeStamp(this.LCD.timestamp, 'LCDprojection');
			}
		}

		this.showActionButtons = !(this.activePage=='lcd-projection' && this.LCD.runWithBMS && this.LCD.voltageRangeStatus!=0 && this.LCD.voltageRangeStatus!=1 && this.LCDcharger.isbatteryconnected) && !(this.LCD.runWithBMS && this.LCD.voltageRangeStatus==1 && this.LCD.EQ_p==1 && this.LCD.showLastChargeCycleDetails_p == 0 && this.LCDcharger.isbatteryconnected);
		this.showSOC = (this.LCD.bmsSOCAvailable && this.LCDcharger.firmwareversion >= 3.00) || (!this.newLithiumBattery && !this.LCD.runWithBMS);
	}

	sendLCDaction(action) {
		if(this.activePage == 'lcd-history')
			return;
		if(!this.readonlyMCBremote && this.chargerID && this.LCD) {
			if(action == 'desulfateSave'){
				this.desulfateSaveError = false;
				if((!this.LCD.DesulfationVoltage_ToUse && +this.LCD.DesulfationVoltage_ToUse != 0) || !this.LCD.DesulfationCapacity_ToUse || !this.LCD.DesulfationTimer){
					this.desulfateSaveError = true;
					return;
				}
			}
			this.loaderService.display(true);
			this.isBlockedUI = true;
			setTimeout(() => {
				if(this.isBlockedUI) {
					this.loaderService.display(false);
					this.isBlockedUI = false;
				}
			}, 60000);
			this.chargersDashboard.sendLCDaction(this.chargerID, action, this.LCD || {}).subscribe();
		}
	}


	formatCycleLCD(cycleInfo, LCD) {
		LCD.ProfileStatus		= cycleInfo.exitStatus;
		LCD.AHR					= cycleInfo.totalas;
		LCD.WHRs				= cycleInfo.totalws;
		LCD.voltageVal			= cycleInfo.lastvoltage * 100;
		LCD.voltageFactor		= LCD.voltageVal / 100.0;
		LCD.voltageFactor		/= (LCD.BatteryVoltage / 2.0);
		LCD.voltageFactor		-= (LCD.TrickleVoltage / 100.0);
		LCD.voltageFactor		/= ((LCD.EqualaizeVoltage - LCD.TrickleVoltage) / 1000.0);
		LCD.voltageFactor		*= 0.8;
		LCD.voltageFactorARC	= LCD.voltageFactor > 0 ? Math.floor(LCD.voltageFactor) : 0;
		LCD.VPC					= LCD.voltageVal / (100.0 * LCD.numberOfCells);
		LCD.durationTime		= cycleInfo.duration;
		LCD.duration			= this.commonUtil.clockTimeToTimeSpan(cycleInfo.duration);
		LCD.durationImg			= LCD.duration % 4;
		LCD.timestamp			= moment((moment(cycleInfo.cycletime).utc().unix() + LCD.duration) * 1000).utc().unix();
		LCD.profile				= "msg";

		if(LCD.battConnected_p && (LCD.voltageRangeStatus & 0x80) == 0) {

			LCD.battviewID			= cycleInfo.batteryid;
			LCD.batteryCharging_p	= 0;
			LCD.batterySign			= "battery100";
		}
		if(LCD.showLastChargeCycleDetails_p == 0) {
							
			if(LCD.energyManagmentType == 1)
				LCD.buttons = "disabledStart";
			else
				LCD.buttons = "start";
		} else if(LCD.showLastChargeCycleDetails_p == 1) {
			
			LCD.buttons = "return";
		}

		return LCD;
	}
	
	playWithLCDvideo(option, bySystem) {
		switch(option) {
			case 'play':
				if(this.LCDvideo.value >= (this.LCDhistory.length-1)) {
					if(bySystem) {

						this.playWithLCDvideo('pause', true);
						break;
					} else {
						this.LCDvideo.value = -1;
					}
				} else if(this.LCDvideo.value < 0) {

					this.LCDvideo.value = -1;
				}
				
				this.LCDvideo.playing = true;

				this.LCDvideo.value++;
				this.LCDcharger	= this.LCDhistory[this.LCDvideo.value].jsonresponse.charger;
				this.LCD		= this.LCDhistory[this.LCDvideo.value].jsonresponse.LCD;
				this.LCDcharger.isSupported = true;
				this.initializeLCDvariables();
				
				setTimeout(() => {
					if(this.LCDvideo.playing) {
						this.playWithLCDvideo('play', true);
					}
				}, 1000);
			break;

			case 'pause':
				this.LCDvideo.playing = false;
			break;

			case 'forward':
				this.LCDvideo.value += 10;
			break;

			case 'backward':
				this.LCDvideo.value -= 10;
			break;
		}
	}

	getLCDhistory(firstTime) {
		
		if(this.cycleId && !firstTime) {
			this.router.navigate(
				['.'], 
				{relativeTo: this.route, queryParams: {chargersIDs: this.chargerID}}
			);
			return;
		}

		if(this.chargerID) {

			this.chargersDashboard.getCycleRecords(this.chargerID, {'cycleId': this.cycleId}).subscribe(
				(cyclesInfo: any) => {
					let cycleInfo = cyclesInfo && cyclesInfo.length ? cyclesInfo[0] : null;
					if(firstTime) {
						if(cycleInfo) {
							var startTime: any	= this.commonUtil.getDateFormattedFromUnixTimeStamp(new Date(Date.parse(cycleInfo.cycletime)).getTime()/1000);
							var duration: any	= this.commonUtil.clockTimeToTimeSpan(cycleInfo.duration);
							var endTime:any		= new Date(new Date(startTime).getTime() + (duration * 1000));
						} else {

							var endTime: any	= Math.floor(new Date().setMilliseconds(0) / 1000);
							var startTime: any	= endTime - 86400;
							
							startTime	= this.commonUtil.getZoneTimestampFromUTC(this.charger.zoneid, startTime, this.charger.daylightsaving);
							startTime	= this.commonUtil.getDateFormattedFromUnixTimeStamp(startTime);
							endTime		= this.commonUtil.getZoneTimestampFromUTC(this.charger.zoneid, endTime, this.charger.daylightsaving);
							endTime		= this.commonUtil.getDateFormattedFromUnixTimeStamp(endTime);
						}

						this.snapshotStart	= this.datePipe.transform(new Date(startTime), 'yyyy-MM-ddTHH:mm:ss');
						this.snapshotEnd	= this.datePipe.transform(new Date(endTime), 'yyyy-MM-ddTHH:mm:ss');
					}

					var zoneDiff = new Date().getTimezoneOffset() * -1;
					var options = {
						'start': new Date(new Date(this.snapshotStart).getTime() + (zoneDiff * 60 * 1000)),
						'end':   new Date(new Date(this.snapshotEnd).getTime() + (zoneDiff * 60 * 1000))
					};
					
					this.chargersDashboard.getLCDhistory(this.chargerID, options).subscribe(
						(response: any) => {

							this.LCDhistory = response;

							var resLength = this.LCDhistory.length;

							if(resLength > 0) {

								if(cycleInfo) {
									var lastLCD = Object.assign({}, this.LCDhistory[resLength - 1]);
									this.LCDhistory.push(lastLCD);
									var cycleLCD = this.LCDhistory[resLength].jsonresponse.LCD;
									this.LCDhistory[resLength].jsonresponse.LCD = this.formatCycleLCD(cycleInfo, cycleLCD);
								}

								this.LCDcharger	= this.LCDhistory[0].jsonresponse.charger;
								this.LCD		= this.LCDhistory[0].jsonresponse.LCD;
								
								this.LCDvideo.options.ceil	= this.LCDhistory.length - 1;
								this.LCDcharger.isSupported	= true;
							} else {
								
								this.LCDcharger	= {};
								this.LCD			= {};
							}
							
							this.LCDvideo.value = 0;
							this.LCDvideo.playing = false;
							this.initializeLCDvariables();
						}
					);
				}
			);
		}
	}

	getBatteryId() {
		let params: any = {
			battviewid: this.LCD.BVID,
			runWithBMS: this.LCD.runWithBMS,
		};
		
		let newBatteryId = this.chargersDashboard.getBatteryModelFromBattviewId(params, this.charger);

		if(params.haveBatteryModel) {
			this.LCD.haveBatteryModel = true;
			return newBatteryId;
		}

		this.LCD.haveBatteryModel = false;
		return this.LCD.battviewID;
	}

	goToBattviewAnalytics() {

		if(this.LCD.haveBatteryModel || !this.LCDcharger.firmwareversion || this.LCDcharger.firmwareversion < 2.19 || !this.LCD.BVID)
			return false;
		
		this.devicesDashboard.getDeviceInfo(this.LCD.BVID, 'battview', false).subscribe((data: any) => {

			if(data.customerid && data.siteid) {

				var urlParts = [data.customerid, data.siteid, 'battviews', 'analytics', 'battery-summary', data.id];
				var path = this.router.createUrlTree(urlParts).toString();
			
				window.open('#'+path, '_blank');
			}
		});
	}

	ngOnDestroy() {
		for(let key in this.intervalIDs) {
			clearInterval(this.intervalIDs[key]);
		}
	}
}