import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../../auth/user.service';
import { SideMenuService } from '../../../shared/side-menu/side-menu.service';
import { User } from '../../../auth/user.model';
import { ActivatedRoute } from '@angular/router';
import { Customer } from '../../../shared/models/customer.model';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-customer-reports',
	templateUrl: './customer-reports.component.html',
	styleUrls: ['./customer-reports.component.css']
})
export class CustomerReportsComponent implements OnInit, OnDestroy {

	user: User;
	customer: Customer = new Customer();
	report: String = 'default';
	subscription: Subscription = new Subscription();

	constructor(
		private route: ActivatedRoute,
		private userService: UserService,
		private sideMenu: SideMenuService
	) { }

	ngOnInit() {
		this.user = this.userService.getCurrentUser();
		this.report = this.route.snapshot.params['report'];

		this.subscription = this.sideMenu.customers.subscribe(value => {
			let customer = this.sideMenu.getCustomer(this.route.snapshot.params['id']);
			if (customer) {
				this.customer = customer;
				this.sideMenu.setCurrentCustomer(customer);
				this.sideMenu.setCurrentSites(customer.sites);
			}
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}