import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceManagementService } from '../../device-management.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { SideMenuService } from '../../../shared/side-menu/side-menu.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-autoconnect-devices',
	templateUrl: './autoconnect-devices.component.html',
	styleUrls: ['./autoconnect-devices.component.css']
})
export class AutoconnectDevicesComponent implements OnInit, OnDestroy {

	clientInfo = {
		'customerid': 0,
		'siteid': 0
	};
	chargersList = [];
	battviewsList = [];
	filterChargerSN = '';
	filterBattviewSN = '';

	constructor(
		private route: ActivatedRoute,
		private sideMenu: SideMenuService,
		private deviceManagemntService: DeviceManagementService,
		private breadcrumb: BreadcrumbService,
		private translateService: TranslateService
	) {
		this.sideMenu.show();
	}

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([
			{'label': this.translateService.instant('device.autoconnect_devices'), 'url': ''}
		]);
		this.clientInfo.customerid = this.route.snapshot.params.clientId;
		this.clientInfo.siteid = this.route.snapshot.params.site;
		this.getClientDevices();
	}

	getClientDevices() {

		if(!this.clientInfo.customerid || !this.clientInfo.siteid)
			return;

		this.deviceManagemntService.getClientDevices(this.clientInfo).subscribe(
			(devices: any) => {
				this.chargersList	= devices.chargers;
				this.battviewsList	= devices.battviews;
			}
		);
	};

	enableDeviceAutoconnect(type, device) {
		let deviceInfo = {
			'type': type,
			'deviceID': device.id,
			'time': device.autoconnectPeriod
		};
		
		this.deviceManagemntService.enableDeviceAutoconnect(deviceInfo).subscribe(
			data => {
				device.enabled = true;
			}
		);
	};

	ngOnDestroy() {
		this.sideMenu.hide();
		this.breadcrumb.hide();
	}
}
