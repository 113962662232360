import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../../../../auth/user.service';
import { DevicesDashboardService } from '../../../devices/devices-dashboard.service';
import { DeviceManagementService } from '../../../../../device-management/device-management.service';

@Component({
	selector: 'app-truckview-actions',
	templateUrl: './truckview-actions.component.html',
	styleUrls: ['./truckview-actions.component.css']
})
export class TruckviewActionsComponent implements OnInit {

	@Input() truckview: any = {};
	@Input() siteID: number = 0;
	@Input() customerID: number = 0;
	@Input() pageName: string;
	
	currentUser: any = {};
	manageDeviceBtnText = [];

	constructor(
		public userService: UserService,
		private deviceManagemnt: DeviceManagementService,
		private devicesDashboard: DevicesDashboardService,
	) { }

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();

		let truckviewEditBtnTxt	= ["Manage", "TRUCKView"];
		let truckviewReqBtnTxt	= ["Request TRUCKView", "management change"];

		if(this.currentUser['allowToChangeDeviceAttribute'])
			this.manageDeviceBtnText = [truckviewEditBtnTxt[0], truckviewEditBtnTxt[1]];
		else
			this.manageDeviceBtnText = [truckviewReqBtnTxt[0], truckviewReqBtnTxt[1]];
	}

	restartTruckview(id) {
		this.devicesDashboard.restartDevice('truckview', id, {}).subscribe();
	}

	refreshDeviceWiFiInfo(id) {
		this.devicesDashboard.refreshDeviceWiFiInfo(id, 'truckview').subscribe();
	}

	addCommandToTruckviewQueue(id, command) {
		// this.deviceManagemnt.addCommandToTruckviewQueue(id, command).subscribe();
	}
}