import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../auth/user.service';
import { User } from '../../../../auth/user.model';
import { ActivatedRoute } from '@angular/router';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';
import * as _ from 'underscore';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { LineChartConfig } from '../../../../shared/google-chart/Models/LineChartConfig';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { ChartUtil } from '../../../../shared/services/utility/chart.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-intelligent-sites-snapshots',
	templateUrl: './intelligent-sites-snapshots.component.html',
	styleUrls: ['./intelligent-sites-snapshots.component.css']
})
export class IntelligentSitesSnapshotsComponent implements OnInit {

	user: User;
	date: any = {};
	widgets = {
		"batt_eq_summary": true,
		"batt_alert_high_temp": true,
		"batt_alert_over_under_charge": true,
		"batt_alert_deep_use": true,
		"batt_alert_over_usage": true,
		"batt_alert_deep_discharge": true,
		"batt_alert_missed_watering": true,
		"batt_alert_missed_fi": true,
		"sulfated_battery_alerts": true,
		"batt_alert_missed_eq": true
	};
	reportsData: any = {};
	api = {};
	sites = {};
	sitesArr = []
	customerId;
	isReportGenerated = false;
	chartsDataTables = {};
	chartConfig = {};
	gridColumns = {};
	gridRows = {};
	ready = {};
	images = {};
	customersSubscription: Subscription = new Subscription() ;
	countOfSelectedSites: number = 0;
	widgetsNames: string[] = [];

	constructor(
		private userService: UserService,
		private route: ActivatedRoute,
		private sideMenuService: SideMenuService,
		private httpClient: HttpClient,
		private commonUtil: CommonUtil,
		private chartUtil: ChartUtil,
		private translateService: TranslateService
	) {
		this.widgetsNames = Object.keys(this.widgets);
	}

	ngOnInit() {
		this.user = this.userService.getCurrentUser();
		this.date.fromDate = new Date();
		this.date.fromDate.setDate(this.date.fromDate.getDate() - 7);
		this.date.toDate = new Date();
		this.date.selected =  "1";
		this.customerId = this.route.snapshot.params['id'];
		
		this.customersSubscription = this.sideMenuService.customers.subscribe(value => {
			if (!_.isEmpty(value)) {
				let sites = this.sideMenuService.getSitesCustomerMap(parseInt(this.customerId));
				if (!_.isEmpty(sites)) {
					for (let idx in sites) {
						if (sites[idx]['act_intelligent_type'] != "0") {
							sites[idx]['hasReport'] = true;
							this.sites[sites[idx].id] = sites[idx];
							this.countOfSelectedSites++;
						}
					}
					this.sitesArr = _.values(this.sites);
				}
			}
		});
	}

	toogleSiteChecked(isChecked) {
		isChecked ? this.countOfSelectedSites++ : this.countOfSelectedSites--;
	}

	generateReport() {
		this.isReportGenerated = true;
		let fromDate, toDate;
		if (this.user.accessFunctions && this.user.accessFunctions.enable_full_access_mode) {
			fromDate = this.date.fromDate;
			toDate   = this.date.toDate;
		} else {
			let prevMonday = new Date();
			prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() + 6) % 7);
			toDate = moment(prevMonday).subtract(1, 'days');;
			switch(this.date.selected) {
				case "1" :
					fromDate = moment(prevMonday).subtract(7, 'days');
					break;
				case "2" :
					fromDate = moment(prevMonday).subtract(14, 'days');
					break;
				case "4" :
					fromDate = moment(prevMonday).subtract(28, 'days');
					break;
				case "6" :
					fromDate = moment(prevMonday).subtract(42, 'days');
					break;
				case "8" :
					fromDate = moment(prevMonday).subtract(56, 'days');
					break;
			}
		}

		let startWeekDate = moment(fromDate).utc().startOf('day').format("X");
		let endweekDate =  moment(toDate).utc().startOf('day').format("X");
		this.httpClient.post('/batteries/getSiteAlertsData', {
			customerId 				 : parseInt(this.customerId), 
			isCustomer 				 : 'true',
			actIntelligenceSitesOnly : true,
			startWeekDate 			 : startWeekDate ,
			endweekDate 			 : endweekDate
		}).subscribe((data: any) => {
			for (let siteID in this.sites) {
				let site = this.sites[siteID];
				if (!site.hasReport) {
					delete data.data[0][site.id];
				}
			}
			this.reportsData = data;
			window.scrollTo(0, 600);
			// this.filterWidgetsList();
			for (let widgetName in this.widgets) {
				if (this.widgets[widgetName]) {
					let fieldName = this.widgetReportNameMapper(widgetName);
					this.drawReport(fieldName, true);
				}
			}
		});			
	}
	
	filterWidgetsList() {
		let customerWidgets = _.pluck(this.reportsData.customerWidgets, 'widget_code_name');
		for (let widget in this.widgets) {
			if (customerWidgets.indexOf(widget) == -1) {
				this.widgets[widget] = false;
			}
		}
	}

	reGenerateReport() {
		this.isReportGenerated = false;
		window.scrollTo(0, 200);
	}

	widgetReportNameMapper(widget) {
		if (widget == "batt_eq_summary") {
			return "batt_alert_missed_eq_weekly"
		}
		return widget
	}

	drawReport(report, percentage) {
		let chartColumns = [], gridColumns = [], gridRows= [],  dates = {};
		chartColumns.push({label: 'Date', id: 'date',  type: 'string'});
		gridColumns.push({headerName: 'Date', width: 200, field: 'date', colId: "date", filter: false});

		for (let siteId in this.reportsData.data[0]) {
			let sitesAlerts = this.reportsData.data[0][siteId];
			chartColumns.push({ label: this.sites[siteId].name, type: 'number' });
			chartColumns.push({ type: 'string', role: 'tooltip', 'p': {'html': true} });
			gridColumns.push({headerName : this.sites[siteId].name, field: report + "_" + siteId, colId: report + "_" + siteId, width: 200, filter: 'agTextColumnFilter'});

			for (let day in sitesAlerts) {
				let siteDayAlerts = sitesAlerts[day];
				let siteDayAlert;
				if (report == "batt_alert_missed_eq") {
					let pluggedIn = siteDayAlerts && siteDayAlerts[report] && siteDayAlerts[report].plugged_in ? siteDayAlerts[report].plugged_in.one_or_more_count : 0;
					let notPluggedIn = siteDayAlerts && siteDayAlerts[report] && siteDayAlerts[report].not_plugged_in ? siteDayAlerts[report].not_plugged_in.one_or_more_count : 0;
					siteDayAlert = pluggedIn + notPluggedIn;
				} else if (report == "batt_alert_over_usage") {
					let reportBasedOnUser = 'batt_alert_over_usage';
					if(
						!this.reportsData.siteSettings ||
						!this.reportsData.siteSettings[siteId] ||
						this.reportsData.siteSettings[siteId].bv_inuse_events_only_for_charge_ahr !== false
					) {
						reportBasedOnUser = 'batt_events_alert_over_usage';
					}
					let fast = siteDayAlerts && siteDayAlerts[reportBasedOnUser] && siteDayAlerts[reportBasedOnUser].fast ? siteDayAlerts[reportBasedOnUser].fast.one_or_more_count : 0;
					let conventional = siteDayAlerts && siteDayAlerts[reportBasedOnUser] && siteDayAlerts[reportBasedOnUser].conventional ? siteDayAlerts[reportBasedOnUser].conventional.one_or_more_count : 0;
					let opportunity = siteDayAlerts && siteDayAlerts[reportBasedOnUser] && siteDayAlerts[reportBasedOnUser].opportunity ? siteDayAlerts[reportBasedOnUser].opportunity.one_or_more_count : 0;
					siteDayAlert = fast + conventional + opportunity;
				} else {
					let reportBasedOnUser = report;
					if (report == "batt_alert_over_under_charge") {
						let charge_events	= !this.reportsData.siteSettings || this.reportsData.siteSettings.bv_charge_events_only_for_charge_ahr;
						let inuse_events	= !this.reportsData.siteSettings || this.reportsData.siteSettings.bv_inuse_events_only_for_charge_ahr;
						if(!charge_events && !inuse_events) {
							reportBasedOnUser += '1';
						} else if(!charge_events && inuse_events) {
							reportBasedOnUser += '2';
						} else if(charge_events && !inuse_events) {
							reportBasedOnUser += '3';
						} else {
							reportBasedOnUser += '4';
						}
					}
					siteDayAlert = siteDayAlerts && siteDayAlerts[reportBasedOnUser] ? siteDayAlerts[reportBasedOnUser].one_or_more_count : 0;
				}

				// if percentage checkbox is checked
				if (percentage) {
					let total = 0;
					if (report == "batt_alert_missed_eq") {
						let pluggedInOk = siteDayAlerts && siteDayAlerts[report] && siteDayAlerts[report].plugged_in ? siteDayAlerts[report].plugged_in.ok_count : 0;
						let notPluggedInOk = siteDayAlerts && siteDayAlerts[report] && siteDayAlerts[report].not_plugged_in ? siteDayAlerts[report].not_plugged_in.ok_count : 0;
						total = siteDayAlert + pluggedInOk + notPluggedInOk;
					} else if (report == "batt_alert_over_usage") {
						let reportBasedOnUser = 'batt_alert_over_usage';
						if(
							!this.reportsData.siteSettings ||
							!this.reportsData.siteSettings[siteId] ||
							this.reportsData.siteSettings[siteId].bv_inuse_events_only_for_charge_ahr !== false
						) {
							reportBasedOnUser = 'batt_events_alert_over_usage';
						}
						let fastOk = siteDayAlerts && siteDayAlerts[reportBasedOnUser] && siteDayAlerts[reportBasedOnUser].fast ? siteDayAlerts[reportBasedOnUser].fast.ok_count : 0;
						let conventionalOk = siteDayAlerts && siteDayAlerts[reportBasedOnUser] && siteDayAlerts[reportBasedOnUser].conventional ? siteDayAlerts[reportBasedOnUser].conventional.ok_count : 0;
						let opportunityOk = siteDayAlerts && siteDayAlerts[reportBasedOnUser] && siteDayAlerts[reportBasedOnUser].opportunity ? siteDayAlerts[reportBasedOnUser].opportunity.ok_count : 0;
						total = siteDayAlert + fastOk + conventionalOk + opportunityOk;
					} else {
						let reportBasedOnUser = report;
						if (report == "batt_alert_over_under_charge") {
							let charge_events	= !this.reportsData.siteSettings || this.reportsData.siteSettings.bv_charge_events_only_for_charge_ahr;
							let inuse_events	= !this.reportsData.siteSettings || this.reportsData.siteSettings.bv_inuse_events_only_for_charge_ahr;
							if(!charge_events && !inuse_events) {
								reportBasedOnUser += '1';
							} else if(!charge_events && inuse_events) {
								reportBasedOnUser += '2';
							} else if(charge_events && !inuse_events) {
								reportBasedOnUser += '3';
							} else {
								reportBasedOnUser += '4';
							}
						}
						total = siteDayAlerts && siteDayAlerts[reportBasedOnUser] ? siteDayAlert + siteDayAlerts[reportBasedOnUser].ok_count : siteDayAlert;
					} 
					siteDayAlert = total ? this.getRoundedAlertPercentage(siteDayAlert / total) : 0;
				}
				if (!dates[day]) {
					dates[day] = {};
					dates[day][siteId] = siteDayAlert;
				} else {
					dates[day][siteId] = siteDayAlert;
				}
			}
		}
		let chartDataTable = [chartColumns];
		for (let key in dates) {
			let value = dates[key];
			let chartDate = moment(parseInt(key) * 1000).utc().format('MMM DD YYYY');

			let girdDate = moment(parseInt(key) * 1000).utc().format('MM/DD/YYYY') + " - " + moment(parseInt(key) * 1000).utc().add(6, 'days').format('MM/DD/YYYY') ;
			let row = [chartDate];
			let gridRow = {date: girdDate};
			for (let id in value) {
				let data = value[id];
				let tooltipData;
				row.push(data);
				let percentVal = Math.round(data * 100);
				if (percentage) {
					tooltipData = percentVal + ' %';
				} else {
					tooltipData = data;
				}
				let tooltip = '<div style="width: 200px;font-size:15px;padding:5px"><div><strong>' + girdDate + "</strong></div><div>" + this.sites[id].name +  ": <strong>" + tooltipData + "</strong></div></div>";
				row.push(tooltip);
				if (percentage) {
					gridRow[report + "_" + id] = percentVal + ' %';
				} else {
					gridRow[report + "_" + id] = data ;
				}
			}
			chartDataTable.push(row);
			gridRows.push(gridRow);
		}

		let chartConfig = {
			tooltip: { isHtml: true },
			chartArea: {
				width:"65%"
			},
			widgetHeight: 40 * _.keys(this.sites).length, 
			vAxisMinValue: 0,
			legend: { 'position': 'top', maxLines: 5},
		};
		if (percentage) {
			chartConfig['vAxisFormat'] = '#%';
		}
		this.chartConfig[report] = new LineChartConfig(chartConfig);
		// chartUtils.generateDownloadPNG(chart, report);
		this.chartsDataTables[report] = chartDataTable;
		this.gridColumns[report] = gridColumns;
		this.gridRows[report] = gridRows;
		this.ready[report]= true;
	}

	onPercentageCheckboxChanged(value) {
		if (value[1]) {
			this.drawReport(value[0], true);
		} else {
			let name = value[0].split('_percentage');
			this.drawReport(name[0], false);
		}
	}

	getRoundedAlertPercentage(data) {
		let res =  Math.round((data) * 100) / 100;
		return res;
	}

	generateImageURI(event) {
		let chart = event['chart'];
		let elementId = event['elementId'];
		let image = this.chartUtil.getChartImageUri(chart);
		this.images[elementId] = image;
	}

	onPrintClick(event) {

		let widgetId = event['widgetId'];
		let customer = this.sideMenuService.getCurrentCustomer();
		
		let table = '<div class="row"><div class="col-md-10"><table class="table table-striped table-condensed table-bordered"><thead>';
		for (let col of this.gridColumns[widgetId]) {
			table+= '<th>' + col['headerName'] + '</th>'
		}
		table+='</thead><tbody>';
		for (let row of this.gridRows[widgetId]) {
			table+= '<tr>';
			for (let field in row) {
				table+= '<td>' + row[field] + '</td>';
			}
			table+= '</tr>';
		}
		table+='</tbody></table></div></div>';
		let printContent = '<div class="boxs3 margin-bottom-lg element-contains-table"><h2>' + this.getWidgetTitle(widgetId) + '</h2>';
		
		if (this.user.accessFunctions && this.user.accessFunctions['enable_full_access_mode']) {
			printContent+= '<h4>' + moment(this.date.fromDate).format("MM-DD-YYYY") + ' to ' + moment(this.date.toDate).format("MM-DD-YYYY") + '</h4>';
		} else {
			let dateText= '';
			switch(this.date.selected) {
				case "1" :
					dateText = "Last Week";
					break;
				case "2" :
					dateText = "Last 2 Weeks";
					break;
				case "4" :
					dateText = "Last 4 Weeks";
					break;
				case "6" :
					dateText = "Last 6 Weeks";
					break;
				case "8" :
					dateText = "Last 8 Weeks";
					break;
			}
			printContent+= '<h4>' + dateText + '</h4>';
		}
		let imageHtml = '<img src="' + this.images[widgetId] + '" style="width:100%;" border="0" alt="Null">'; 
		printContent+= imageHtml
		printContent+= table;
		printContent+= '</div>';
	
		this.commonUtil.print({
			customerName: customer.name,
			appendPrintContent: printContent,
		});
	}

	getWidgetTitle(widgetId) {
		return this.translateService.instant('int_sites_snapshots.'+widgetId);
	}
}