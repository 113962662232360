import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { DeviceManagementService } from '../../device-management.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-process-production-line',
	templateUrl: './process-production-line.component.html',
	styleUrls: ['./process-production-line.component.css']
})
export class ProcessProductionLineComponent implements OnInit, OnDestroy {

	battviewsCat: Object = {};
	battviewsInfo: Object = {};
	messages: any = {};
	siteInfo: any = {};
	type: string = 'normal';
	connectivity: string = '';
	connectivityDays: number = 0;
	errorsTimeoutID: any;
	battviewsTimeoutID: any;
	productionLineBattviews: any[] = [];
	constructor(
		private deviceManagemntService: DeviceManagementService,
		private breadcrumb: BreadcrumbService,
		private notificationMessage: NotificationMessageService,
		private router: Router,
		private commonUtil: CommonUtil,
		private decimalPipe: DecimalPipe,
		private translateService: TranslateService
	) {
		this.breadcrumb.setBreadcrumb([
			{label: 'Process Production Line', url: ''}
		]);
	}

	ngOnInit() {
		this.getProductionLineBattviews();
	}

	processBattviews(id){
		if (!confirm(this.translateService.instant('g.are_you_sure_you_want_to_proceed')))
			return false;
		this.deviceManagemntService.addCommandToBattviewQueue(id, 'processProductionLine').subscribe(
			response => {
				let msg = this.translateService.instant('device.op_done_device_will_be_removed');
				this.notificationMessage.setMessage(msg, 'success', 10000, true);
			}
		);
	}

	clearTimeout() {
		if(this.battviewsTimeoutID)
			clearTimeout(this.battviewsTimeoutID);
	}

	getProductionLineBattviews(isRefresh = false) {
		this.clearTimeout();
		let allowBVM = this.type == 'mobile';
		let connectivity = 0;
		if(this.connectivity == 'now')
			connectivity = 600;
		else if(this.connectivity == 'in')
			connectivity = this.connectivityDays * 86400;
		this.deviceManagemntService.getProductionLineBattviews({allowBVM, connectivity}, isRefresh).subscribe(
			(response:any) => {
				this.battviewsTimeoutID = setTimeout(() => {
					this.getProductionLineBattviews(true);
				}, 10000);
		
				this.siteInfo = response.siteInfo;
				this.battviewsCat = {};
				this.battviewsInfo = {};
				
				this.productionLineBattviews = [];
	
				for(let battview of response.battviews) {
					
					var cat = this.translateService.instant('g.battviews');

					if(battview.replacmentpart)
						cat = this.translateService.instant('device.replacement_battviews');
					else if(battview.hasReplacement)
						cat = this.translateService.instant('device.pending_setup_as_replacement');
					else if(battview.ispa)
						cat = this.translateService.instant('g.mobile_battviews');
					
					if(!this.battviewsCat[cat])
						this.battviewsCat[cat] = [];

					if(this.siteInfo.battviewsfirmwareversion > battview.firmwareversion) {
						
						let progress = "0";

						if(battview.firmwareStatus) {

							if(battview.firmwareStatus.step > 0) {

								let tmpProgress = 100 * (battview.firmwareStatus.step/124);

								if(tmpProgress >= 100)
									progress = "99.9";
								else
									progress = this.decimalPipe.transform(tmpProgress, '1.0-0');
							}
						}
						
						battview.fwUpdateProgress = progress;
					}

					if(this.siteInfo.wififirmwareversion > battview.esp32_wifi_version) {
						
						let progress = "0";

						if(battview.wifiFirmwareStatus) {

							if(battview.wifiFirmwareStatus.step > 0) {

								let tmpProgress = 100 * (battview.wifiFirmwareStatus.step/73);

								if(tmpProgress >= 100)
									progress = "99.9";
								else
									progress = this.decimalPipe.transform(tmpProgress, '1.0-0');
							}
						}
						
						if(+progress < 0)
							progress = "0";
						battview.wifiFwUpdateProgress = progress;
					}
	
					this.battviewsCat[cat].push(battview);
					
					if(battview.firmwareversion >= 2.19 && battview.connectivity == 'Connected') {
						this.productionLineBattviews.push({
							'id':				battview.id,
							'ispa':				battview.ispa,
							'studyid':			battview.studyid,
							'insertion_time':	battview.insertion_time,
							'final_functional_test':	battview.final_functional_test
						});
						this.battviewsInfo[battview.id] = {
							'enablehalleffectsensing': battview.enablehalleffectsensing
						};
					}
				}

				if(!isRefresh)
					this.getProductionLineBattviewErrors(isRefresh);
			}
		);
	}

	getProductionLineBattviewErrors(isRefresh) {

		if(!this.productionLineBattviews || this.productionLineBattviews.length == 0)
			return;
	
		this.deviceManagemntService.getProductionLineBattviewErrors(this.productionLineBattviews, isRefresh).subscribe(
			response => {
				this.errorsTimeoutID = setTimeout(() => {
					this.getProductionLineBattviewErrors(true);
				}, 60000);
		
				response = response || {};
				
				let messages = {};
				
				for(var battviewID in response) {
					
					var battview = response[battviewID];
					
					var debugs = battview.debugs || [];
					var faults = battview.faults || [];
					var misc = battview.misc || [];
		
					messages[battviewID] = [];
		
					debugs.forEach((record) => {
						messages[battviewID].push(this.commonUtil.getDebugRecordsInfoText(record));
					});
		
					faults.forEach((fault) => {
						messages[battviewID].push(this.commonUtil.getBattviewFaultText(fault, this.battviewsInfo[battviewID].enablehalleffectsensing));
					});

					misc.forEach((error) => {
						messages[battviewID].push(this.translateService.instant('bv_misc_err.'+error));
					});
				}

				this.messages = Object.assign({}, messages);
			}
		);
	}

	ngOnDestroy() {
		clearTimeout(this.errorsTimeoutID);
		this.clearTimeout();
		this.breadcrumb.hide();
	}
}