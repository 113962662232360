<div id="page-wrapper">
	<div class="boxs">
		<table class="table table-stripped">
			<thead>
				<tr>
					<th>First Name</th>
					<th>Last Name</th>
					<th>Company Name</th>
					<th>Date Requested</th>
					<th *ngIf="guests">Action</th>
				</tr>
			</thead>
			<tbody *ngIf="guests">
				<tr *ngFor="let guest of guests">
					<td>{{guest.firstname}}</td>
					<td>{{guest.lastname}}</td>
					<td>{{guest.companyname}}</td>
					<td>{{guest.date | date:'MM/dd/yyyy'}}</td>
					<td>
						<div class="btn-group" appDropdown>
							<i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle>
							</i>
							<ul class="dropdown-menu" role="menu">
								<li><a [routerLink]="['/validate-registration-request', guest.id]">Validate</a></li>
							</ul>
						</div>
					</td>
				</tr>	
			</tbody>
		</table>
	</div>
</div>