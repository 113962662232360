<div *ngIf="hasDevices" class="panel panel-default">
	<div class="panel-body">
		<div class="col-md-12">
			<app-grid-v2  *ngIf="gridData.length > 0"
				[id]="deviceType+'ConnectivityTrack'"
				[modelId]="device.id"
				[columns]="gridColumnsClone"
				[data]="gridData"
				translateHeaders="true"
			></app-grid-v2>
			<div *ngIf="gridData.length == 0" >
				{{'g.no_results_found'|translate}}!
			</div>
		</div>
	</div>
</div>