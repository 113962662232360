<app-customized-widget
	*ngIf="!hideWidget"
	title="All Sites Battery Fleet Utilization"
	[customerName]="customer.name" 
	widgetId="battery_utilization"
	[dropDownGroupList]="dropDownOptions"
  (onDropDownChange)="dateChange($event[0],$event[1])"
	>
	<div *appTransclude>
		<div>
			<h4>Overall Battery Fleet Utilization</h4>
			<bar-chart
	      [data]="chartDataTable"
	      [config]="chartConfig"
	      elementId="batt_alert_missed_eq_weekly">
	    </bar-chart>
	    
  	</div>
	</div>
</app-customized-widget>
