<div class="row">
  <div class="col-md-5 panel panel-default">
    <div class="panel-body">
      <h3>{{'g.chargers' | translate}}</h3>
      <input [(ngModel)]="filterChargerSN" placeholder="{{'device.search_filter_by_serial_number' | translate}}" class="form-control"/>
      <br/>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>{{'device.serial_number' | translate}} ({{'device.charger_id' | translate}})</th>
            <th>{{'g.action' | translate}}</th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let charger of chargersList | filter: filterChargerSN: 'serialnumber'" (mouseleave)="charger.showActions=false" (mouseenter)="charger.showActions=true">
          <td>{{charger.serialnumber}} ({{charger.chargerusername}})</td>
          <td>
            <input type="number" min="1" [(ngModel)]="charger.autoconnectPeriod" placeholder="{{'time.timespan_seconds' | translate}}" class="form-control"/>
            <button type="button" class="btn btn-default" (click)="enableDeviceAutoconnect('charger', charger)" *ngIf="charger.showActions && !charger.enabled">{{'g.enable' | translate}}</button>
            <button type="button" class="btn btn-success" *ngIf="charger.showActions && charger.enabled" disabled>{{'g.enabled' | translate}}</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-md-5 col-md-offset-1 panel panel-default">
    <div class="panel-body">
      <h3>{{'g.battviews' | translate}}</h3>
      <input [(ngModel)]="filterBattviewSN" placeholder="{{'device.search_filter_by_serial_number' | translate}}" class="form-control"/>
      <br/>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>{{'device.serial_number' | translate}} ({{'device.battery_id' | translate}})</th>
            <th>{{'g.action' | translate}}</th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let battview of battviewsList | filter: filterBattviewSN: 'serialnumber'" (mouseleave)="battview.showActions=false" (mouseenter)="battview.showActions=true">
          <td>{{battview.serialnumber}} ({{battview.batteryid}})</td>
          <td>
            <input type="number" min="1" [(ngModel)]="battview.autoconnectPeriod" placeholder="{{'time.timespan_seconds' | translate}}" class="form-control"/>
            <button type="button" class="btn btn-default" (click)="enableDeviceAutoconnect('battview', battview)" *ngIf="battview.showActions && !battview.enabled">{{'g.enable' | translate}}</button>
            <button type="button" class="btn btn-success" *ngIf="battview.showActions && battview.enabled" disabled>{{'g.enabled' | translate}}</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>