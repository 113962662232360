import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { AdminService } from '../../../admin/admin.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { DeviceManagementService } from '../../device-management.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
@Component({
	selector: 'app-user-report',
	templateUrl: './user-report.component.html',
	styleUrls: ['./user-report.component.css']
})
export class UserReportComponent implements OnInit, AfterViewInit, OnDestroy {

	model: {
		selectedDealers: number[],
		selectedOEMs: number[],
		selectedCustomers: number[],
		isTestUser: boolean,
		isEmailConfirmed: boolean,
		isUserConfirmed: boolean,
	};
	dealers: any[];
	OEMs: any[];
	customers: any[];
	reportName: string;

	@ViewChild("modal") modal;
	
	constructor(
		private route: ActivatedRoute,
		private deviceManagemntService: DeviceManagementService,
		private adminService: AdminService,
		private breadcrumb: BreadcrumbService,
		private notificationMessage: NotificationMessageService,
		private translateService: TranslateService,
		private commonUtil: CommonUtil
	) {
		this.breadcrumb.setBreadcrumb([
			{label: this.translateService.instant('user.users_search'), url: ''}
		]);
		this.model = {
			selectedDealers: [],
			selectedOEMs: [],
			selectedCustomers: [],
			isTestUser: null,
			isEmailConfirmed: null,
			isUserConfirmed: null
		};
	}

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			if(params.isExpired){
				this.notificationMessage.setMessage('translate|g.file_has_been_deleted', 'warning', 20000);
			}
			if(params.enc){
				this.deviceManagemntService.prepareFileDownload('too_big_report', params.enc).subscribe((data:any)=>{
					this.commonUtil.downloadReport(data);
				});
			}
		});
		this.getDealers(0);
		this.getOEMs(0);
		this.getCustomers(0);
	}
	ngAfterViewInit() {
		this.modal.onClose.subscribe(
			(ok) => {
				if(ok) {
					this.scheduleReport();
				}
			}
		);
	}
	submitDisabled() {
		return this.model.selectedDealers.length === 0 && this.model.selectedOEMs.length === 0 && this.model.selectedCustomers.length === 0 && this.model.isTestUser == null && this.model.isEmailConfirmed == null && this.model.isUserConfirmed == null;
	}
	scheduleReport() {
		if (!this.reportName)
			return this.notificationMessage.setMessage(this.translateService.instant('user.please_enter_report_name'), 'danger', 10000, true);

		var model = this.model;
		model['reportName'] = this.reportName;
		model['state'] = 'user';

		this.deviceManagemntService.scheduleReport(model).subscribe(
			res => {
				this.reportName = '';
				this.notificationMessage.setMessage(this.translateService.instant('user.the_report_will_be_processed_and_sent_to_your_email'), 'success', 20000, true);
			}
		);
	}
	getDealers(id = 0) {
		let noDealer = {'id':0, 'name':this.translateService.instant('g.no_dealer')};
		this.adminService.getDealers(id).subscribe(
			data => {
				let dealers = data['dealers'] || [];
				dealers.unshift(noDealer);
				this.dealers = dealers;
			}
		);
	}
	getOEMs(id=0) {
		let noOEM = {'id':0, 'name':this.translateService.instant('g.no_oem')};    
		this.adminService.getOEMs(id).subscribe(
			(data: any[]) => {
				let OEMs = data || [];
				OEMs.unshift(noOEM);
				this.OEMs = OEMs;
			}
		);
	}
	getCustomers(id = 0) {
		let noCustomer = {'id':0, 'name':this.translateService.instant('g.no_customer')};    
		this.adminService.getCustomers(id).subscribe(
			(data: Object) => {
				let customers = data['list'] || [];
				customers.unshift(noCustomer);
				this.customers = customers;
			}
		);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
