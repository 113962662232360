import { Component, OnInit } from '@angular/core';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
	selector: 'app-become-user',
	templateUrl: './become-user.component.html',
	styleUrls: ['./become-user.component.css']
})
export class BecomeUserComponent implements OnInit {

	token: string;

	constructor(
		private notificationMessage: NotificationMessageService,
		private route: ActivatedRoute,
		private authService: AuthService,
		private router: Router
	) {
		this.route.params.subscribe( params => {
			this.token = params.token;
		});
	}

	ngOnInit() {

		this.notificationMessage.closeNotification();
		// AuthenticationService.ClearCredentials();
		
		let params = {
			params: this.token
		};
		this.authService.becomeUser(params).subscribe(
			() => {
				this.router.navigate(['/']);
			}
		);
	}
}
