<div id="tab-content" class="row settings" *ngIf="runSLider">
	<form class="form-auth">
		<div class="container-fluid no-gutter">
			<div class="boxs3">
				<h2 id="Alerts" class="margin-bottom-xlg">Site Settings</h2>
				
				<div class="form-group row" *ngIf="(currentUser.isEndClient || currentUser.isTruckDealer) && !userIsSubscribed">
					<alert type="danger">
						<span>
							Contact service dealer to subscribe to the email alerts
						</span>
					</alert>
				</div>

				<div class="form-group row" *ngIf="currentUser.isACTuser">
					<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Reports Since Installation</label>
					<div class="col-md-7">
						<label><input type="checkbox" name="reports_since_installation" [(ngModel)]="alert.reports_since_installation" class="form-control" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable')" /><span> Enabled</span></label>
					</div>
				</div>

				<fieldset class="margin-bottom-xlg">
					<legend class="margin-bottom-lg">Temperature</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Temperature Limit</label>
						<div class="col-md-7" *ngIf="!userService.hasAccessFunction('edit_alert_setting_disable')">
							<label><input type="checkbox" name="override_temperature_limit" [(ngModel)]="alert.override_temperature_limit" class="form-control" [disabled]="currentUser.isEndClient || currentUser.isTruckDealer" /><span> Override Temperature Limit</span></label>
						</div>
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg"></label>
						<div class="col-md-7">
							<div class='input-group'>
								<input type="number" min="90" max="160" step="0.1" class="form-control" name="temperature_limit" [(ngModel)]="alert.temperature_limit" [disabled]="!alert.override_temperature_limit || userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer"/>
								<span class="input-group-addon"><span>&deg;F</span></span>
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">High Temperature Limit Days</label>
						<div class="col-md-7">
							<select class="form-control" name="high_temperature_limit_days" [(ngModel)]="alert.high_temperature_limit_days" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer">
								<option *ngFor="let n of [] | intRange:1:4" [value]="n">{{n}}</option>
							</select>
						</div>
					</div>
					<div class="form-group row" *ngIf="currentUser.isACTuser">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Cold Temperature Limit</label>
						<div class="col-md-7">
							<div class='input-group'>
								<input type="number" min="0" max="80" step="0.1" class="form-control" name="cold_temperature_limit" [(ngModel)]="alert.cold_temperature_limit"/>
								<span class="input-group-addon"><span>&deg;F</span></span>
							</div>
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg">
					<legend class="margin-bottom-lg">EBU Over usage</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Conventional EBU Daily Limit</label>
						<div class="col-md-7">
							<input type="number" min="0.25" max="2" step="0.01" class="form-control" name="conventional_ebu_limit" [(ngModel)]="alert.conventional_ebu_limit" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer" />
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Opportunity EBU Daily Limit</label>
						<div class="col-md-7">
							<input type="number" min="0.25" max="2" step="0.01" class="form-control" name="opportunity_ebu_limit" [(ngModel)]="alert.opportunity_ebu_limit" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer" />
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Fast EBU Daily Limit</label>
						<div class="col-md-7">
							<input type="number" min="0.25" max="2" step="0.01" class="form-control" name="fast_ebu_limit" [(ngModel)]="alert.fast_ebu_limit" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer" />
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Lithium EBU Daily Limit</label>
						<div class="col-md-7">
							<input type="number" min="0.25" max="2" step="0.01" class="form-control" name="lithium_ebu_limit" [(ngModel)]="alert.lithium_ebu_limit" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer" />
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg" *ngIf="currentUser.isACTuser && currentSite.act_intelligent_type != 0">
					<legend class="margin-bottom-lg">Site connectivity</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Site Disconnection Timespan (Hrs)</label>
						<div class="col-md-7">
							<input type="number" min="4" max="48" step="1" class="form-control" name="site_disconnection_timespan" [(ngModel)]="alert.site_disconnection_timespan" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable')" />
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Charger Disconnection Timespan (Hrs)</label>
						<div class="col-md-7">
							<input type="number" min="2" max="120" step="1" class="form-control" name="charger_disconnection_timespan" [(ngModel)]="alert.charger_disconnection_timespan" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable')" />
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">BATTView Disconnection Timespan (Hrs)</label>
						<div class="col-md-7">
							<input type="number" min="2" max="120" step="1" class="form-control" name="battview_disconnection_timespan" [(ngModel)]="alert.battview_disconnection_timespan" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable')" />
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg">
					<legend class="margin-bottom-lg">Deep usage</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Potential Weak Cells Limit</label>
						<div class="col-md-7">
							<div class='input-group'>
								<input type="number" min="0" max="10" step="0.01" class="form-control" name="deep_use_limit" [(ngModel)]="alert.deep_use_limit" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer" />
								<span class="input-group-addon"><span>VPC</span></span>
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Deep Discharge Limit</label>
						<div class="col-md-7">
							<div class='input-group'>
								<input type="number" min="0" max="10" step="0.01" class="form-control" name="deep_discharge_limit" [(ngModel)]="alert.deep_discharge_limit" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer" />
								<span class="input-group-addon"><span>VPC</span></span>
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Potential Weak Cells Limit Days</label>
						<div class="col-md-7">
							<select class="form-control" name="potential_weak_cells_limit_days" [(ngModel)]="alert.potential_weak_cells_limit_days" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer">
								<option *ngFor="let n of [] | intRange:1:4" [value]="n">{{n}}</option>
							</select>
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Deep Discharge Limit Days</label>
						<div class="col-md-7">
							<select class="form-control" name="deep_discharge_limit_days" [(ngModel)]="alert.deep_discharge_limit_days" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer">
								<option *ngFor="let n of [] | intRange:1:4" [value]="n">{{n}}</option>
							</select>
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg">
					<legend class="margin-bottom-lg">Water Level </legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Low Water Level Delay Days</label>
						<div class="col-md-7">
							<select class="form-control" name="water_level_low_days" [(ngModel)]="alert.water_level_low_days" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer">
								<option *ngFor="let n of [] | intRange:1:7" [value]="n">{{n}}</option>
							</select>
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg" *ngIf="currentSite.act_intelligent_type != 0">
					<legend class="margin-bottom-lg">Missed Connection</legend>

					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Total Missed Connection Limit Hours</label>
						<div class="col-md-7">
							<input type="number" min="1" max="168" step="1" class="form-control" name="missed_plugged_in_timespan" [(ngModel)]="alert.missed_plugged_in_timespan" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer" />
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Average Daily Idle Times (mins)</label>
						<div class="col-md-7">
							<input type="number" min="1" max="1440" step="1" class="form-control" name="idle_times_limit" [(ngModel)]="alert.idle_times_limit" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer" />
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg">
					<legend class="margin-bottom-lg">Missed Plugin (#of Charge Cycles)</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Expected Number of Charge Cycles</label>
						<div class="col-md-7">
							<input type="number" min="1" max="10" class="form-control" name="expected_plugin_charge_cycles" [(ngModel)]="alert.expected_plugin_charge_cycles" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer" />
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Site Weekly Working Days Count</label>
						<div class="col-md-7">
							<input type="number" min="1" max="7" class="form-control" name="weekly_plugin_working_days" [(ngModel)]="alert.weekly_plugin_working_days" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer" />
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg" *ngIf="currentUser.isACTuser && currentSite.act_intelligent_type != 0 && userIsSubscribed">
					<legend class="margin-bottom-lg">Charger Faults</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg line-height-70">Charger Alert Type</label>
						<div class="col-md-7">
							<ng5-slider class="pointer" [(value)]="alert.charger_alert_type" [options]="chargerAlertTypes.options"></ng5-slider>
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg">
					<legend class="margin-bottom-lg">Chargers Site Performance</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Expected Number of Cycles Delivered From Fast Chargers</label>
						<div class="col-md-7">
							<input type="number" min="1" max="10" class="form-control" name="expected_fast_chargers_cycles" [(ngModel)]="alert.expected_fast_chargers_cycles" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer" />
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Expected Number of Cycles Delivered From Conventional Chargers</label>
						<div class="col-md-7">
							<input type="number" min="1" max="10" class="form-control" name="expected_conventional_chargers_cycles" [(ngModel)]="alert.expected_conventional_chargers_cycles" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer" />
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Expected Number of Cycles Delivered From Opportunity Chargers</label>
						<div class="col-md-7">
							<input type="number" min="1" max="10" class="form-control" name="expected_opportunity_chargers_cycles" [(ngModel)]="alert.expected_opportunity_chargers_cycles" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer" />
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Site Weekly Working Days Count</label>
						<div class="col-md-7">
							<input type="number" min="1" max="7" class="form-control" name="site_weekly_working_days" [(ngModel)]="alert.site_weekly_working_days" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer" />
						</div>
					</div>
				</fieldset>

				<fieldset class="margin-bottom-xlg" *ngIf="showAlertsSubscribedUsers()">
					<legend class="margin-bottom-lg">Alerts Subscribers</legend>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Subscribed Users</label>
						<div class="col-md-7">
							<ng-select
								name="alert-subscribedUsers"
								[multiple]="true"
								[addTag]="false"
								[items]="usersHaveAccessToSite"
								[hideSelected]="hideSelected"
								placeholder="Select user..."
								bindLabel="appearanceName"
								bindValue="id"
								[clearable]="false"
								[(ngModel)]="alert.subscribed_users"
								[disabled]="userService.hasAccessFunction('edit_alert_setting_disable')"
							></ng-select>
						</div>
					</div>
					<div class="form-group row" *ngIf="currentUser.isACTuser">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Subscribed ACT Users</label>
						<div class="col-md-7">
							<ng-select
								name="alert-subscribedActUsers"
								[multiple]="true"
								[addTag]="false"
								[items]="ACTusersHaveAccessToSite"
								[hideSelected]="hideSelected"
								placeholder="Select user..."
								bindLabel="appearanceName"
								bindValue="id"
								[clearable]="false"
								[(ngModel)]="alert.subscribed_act_users"
								[disabled]="userService.hasAccessFunction('edit_alert_setting_disable')"
							></ng-select>
						</div>
					</div>
					<div class="form-group row" *ngIf="currentUser.isACTuser">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">Site Disconnection SMS Subscribers</label>
						<div class="col-md-7">
							<ng-select
								name="alert-subscribed_numbers_for_site_disconnection"
								[multiple]="true"
								[addTag]="true"
								[items]="subscribed_numbers_for_site_disconnection"
								[hideSelected]="hideSelected"
								placeholder="Add phone number..."
								[clearable]="false"
								[(ngModel)]="alert.subscribed_numbers_for_site_disconnection"
								[disabled]="userService.hasAccessFunction('edit_alert_setting_disable')"
							></ng-select>
						</div>
					</div>
					<div class="form-group row" *ngIf="currentUser.isACTuser && currentSite.act_intelligent_type != 0">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">SOC Charger Email Subscribers</label>
						<div class="col-md-7">
							<ng-select
								name="alert-chargerSOCEmailsubscribedUsers"
								[multiple]="true"
								[addTag]="false"
								[items]="SOCChargeUsersHaveAccessToSite"
								[hideSelected]="hideSelected"
								placeholder="Select user..."
								bindLabel="appearanceName"
								bindValue="id"
								[clearable]="false"
								[(ngModel)]="alert.subscribed_soc_charger_users"
								[disabled]="userService.hasAccessFunction('edit_alert_setting_disable')"
							></ng-select>
						</div>
					</div>
					<div class="form-group row" *ngIf="currentUser.isACTuser && currentSite.act_intelligent_type != 0">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">GSE Charging Header</label>
						<div class="col-md-7">
							<input class="form-control" name="gse_charging_header" [(ngModel)]="alert.gse_charging_header" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable')" />
						</div>
					</div>
					<div class="form-group row">
						<label for="tmp-limit" class="col-md-5 col-form-label col-form-label-lg">{{'act.charger_energy' | translate}}</label>
						<div class="col-md-7">
							<ng-select
								name="alert-chargerEnergySubscribedUser"
								[multiple]="true"
								[addTag]="false"
								[items]="SOCChargeUsersHaveAccessToSite"
								[hideSelected]="hideSelected"
								placeholder="{{'g.select_user_list' | translate}}"
								bindLabel="appearanceName"
								bindValue="id"
								[clearable]="false"
								[(ngModel)]="alert.charger_energy_subscribed_user"
								[disabled]="userService.hasAccessFunction('edit_alert_setting_disable')"
							></ng-select>
						</div>
					</div>
				</fieldset>
				
				<fieldset class="margin-bottom-xlg">
					<legend>Operation/Work Schedule</legend>
					<div class="col-md-12" *ngIf="!isEditPluggedInFilters">
						<div id="update-schedule" *ngIf="!userService.hasAccessFunction('edit_alert_setting_disable') && anyDaySelected()">
							<button class="btn btn-primary margin-left-lg" type="button" (click)="updateMissedConnection()" >Update Schedule</button>
						</div>
						<table class="table table-striped">
							<tbody>
								<tr *ngFor="let dayIndex of days | keys; let i = index;">
									<td>
										<div>
											<label><input type="checkbox" class="form-control" name="selectedDays-dayIndex-{{i}}" [(ngModel)]="selectedDays[dayIndex]" [disabled]="userService.hasAccessFunction('edit_alert_setting_disable') || currentUser.isEndClient || currentUser.isTruckDealer"></label>
											<span> {{days[dayIndex]}}</span>
										</div>
									</td>
									<td>
										<div *ngFor="let interval of alert.missedConnectionAlert[dayIndex]" style="display: block;">
											<span class="angular-multi-slider-key">
												<span class="key">
													<span [ngStyle]="{ 'background-color': ' ' + interval.color + '' }"></span>
												</span>
											</span>
											<span>{{interval.interval}}</span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="col-md-10 col-md-offset-1" *ngIf="isEditPluggedInFilters">

						<div class="padding-8 col-md-12">
							<hr>
							<h2 class="tcenter">Plugged in Filters</h2>
							<hr>
							<div class="col-md-12">
								<div class="col-md-2 p-b-5">
									<label>Day</label>
								</div>
								<div class="col-md-10 red-text">
									<div *ngFor="let day of daysSelected | values">
										<div class="col-md-4"><label>{{day}}</label></div>
									</div>
								</div>
							</div>
							<div class="col-md-12 padding-8">
								<div class="col-md-4 p-b-5"><label>Start</label></div>
								<div class="col-md-4 p-b-5"><label>End</label></div>
								<div class="col-md-4 p-b-5"><label>Action</label></div>
							</div>
							<div class="col-md-12 p-b-5" *ngFor="let period of commonPeriods; let i = index;">
								<div class="col-md-4"><input type="time" name="period-start_formatted_{{i}}" [(ngModel)]="commonPeriods[i].start_formatted"></div>
								<div class="col-md-4"><input type="time" name="period-end_formatted_{{i}}" [(ngModel)]="commonPeriods[i].end_formatted"></div>
								<div class="col-md-4"><button class="btn btn-default" (click)="removeRange(i)">Delete</button></div>
							</div>
							<div class="col-md-12 padding-8">
								<div class="col-md-4">
									<button class="btn btn-default" (click)="addNewRange()">Add New</button>
								</div>
							</div>
							<div class="form-buttons bvf">
								<button class="btn btn-info" (click)="updateRanges()">Save</button>
								<button class="btn btn-default" (click)="cancelAddingRange()">Cancel</button>
							</div>
						</div>
					</div>
				</fieldset>

				<div class="form-buttons bvf" *ngIf="!userService.hasAccessFunction('edit_alert_setting_disable')">
					<button class="btn btn-info" (click)="updateAlertsSettings()" *ngIf="!(currentUser.isEndClient || currentUser.isTruckDealer)">Save</button>
					<button class="btn btn-default" (click)="navigation.goBack()"><span *ngIf="!(currentUser.isEndClient || currentUser.isTruckDealer) || userIsSubscribed">Cancel</span><span *ngIf="(currentUser.isEndClient || currentUser.isTruckDealer) && !userIsSubscribed">Back</span></button>
				</div>
			</div>
		</div>
	</form>
</div>