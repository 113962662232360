import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActFirstService } from '../../act-first.service';
import { SiteDashboardService } from '../../../site-dashboard.service';
import { ActivatedRoute } from "@angular/router";
import * as _ from 'lodash';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import * as moment from 'moment';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';
import { Site } from '../../../../../shared/models/site.model';
import { Subscription } from 'rxjs';
import { SideMenuService } from '../../../../../shared/side-menu/side-menu.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-battery-picks',
	templateUrl: './battery-picks.component.html',
	styleUrls: ['./battery-picks.component.css']
})
export class BatteryPicksComponent implements OnInit, OnDestroy {

	data = {};
	dataCopy = {};
	gridColumnsPerGroup = [];
	gridColumnsNoGroup = [];
	gridRows = [];
	chartDataTable;
	site: Site;
	config: ColumnChartConfig = new ColumnChartConfig({});
	selectedCheckbox = true;
	groupCharts = [];
	chartObjs = {};
	dropDownOptions;
	selectedDateRange;
	picksText = "";
	siteGroups = [];
	images = {};
	fromDate;
	toDate;
	currentSiteSub: Subscription = new Subscription();
	getReportSubscription: Subscription = new Subscription();


	constructor(  private actFirstService: ActFirstService,
		private siteDashboardService: SiteDashboardService,
		private route: ActivatedRoute,
		private commonUtil: CommonUtil,
		private chartUtil: ChartUtil,
		private sideMenuService: SideMenuService,
		private translateService: TranslateService) { }

	ngOnInit() {

		this.dropDownOptions = [
			{
				value: "90",
				label: "Last 90 Days"
			},
			{
				value: "30",
				label: "Last 30 Days"
			},
			{
				value: "w",
				label: "Last Week"
			},
			{
				value: "7",
				label: "Last 7 Days"
			},
			{
				value: "1",
				label: "Yesterday"
			}
		];

		this.selectedDateRange = this.dropDownOptions[0].value;

		this.currentSiteSub = this.sideMenuService.currentSite.subscribe(siteInfo => {
			if(siteInfo.id) {
				this.site = siteInfo;
				this.getReportSubscription = this.actFirstService.getReport(this.site.id).subscribe(data=> {
					this.dataCopy = _.cloneDeep(data);
					this.siteDashboardService.getSiteGroups(this.site.id).subscribe((response: any) => {
						this.siteGroups = _.map(response, "id");
						this.dateRangeChange(this.selectedDateRange);
					});
				});
			}
		});
		this.gridColumnsPerGroup = [
			{headerName: "Date", type: "date", field: "date", filter: false},
			{headerName: "Truck Type Name", field: "groupname", filter: false},
			{headerName: "Total Correct Picked Batteries", type: "number", field: "total_number_of_picks", filter: false},
			{headerName: "Total Correct Picked Batteries %", field: "percent_of_picks", filter: false},
			{headerName: "Mispicks off charge", type: "number", field: "total_number_of_miss_picks_Off_charge", filter: false},
			{headerName: "% of Mispicks off charge", field: "percentage_of_miss_picks_Off_charge", filter: false},
			{headerName: "total Mispicks on charge", type: "number", field: "total_number_of_miss_picks_On_charge", filter: false},
			{headerName: "% of total Mispicks on charge", field: "percent_of_miss_picks_On_charge", filter: false},
			{headerName: this.translateService.instant('act.total_number_of_mispicks_pm'), type: "number", field: "total_number_of_mispicks_pm", filter: false},
			{headerName: this.translateService.instant('act.percent_of_mispicks_pm'), type: "number", field: "percent_of_mispicks_pm", filter: false}


		];
		this.gridColumnsNoGroup = [
			{headerName: "Date", type: "date", field: "date", filter: false},
			{headerName: "Total Correct Picked Batteries", type: "number", field: "all_groups_total_number_of_picks", filter: false},
			{headerName: "Total Correct Picked Batteries %", type: "string", field: "all_groups_percent_of_picks", filter: false},
			{headerName: "Mispicks off charge", type: "number", field: "all_groups_total_number_of_miss_picks_Off_charge", filter: false},
			{headerName: "% of Mispicks of charge", type: "string", field: "all_groups_percentage_of_miss_picks_Off_charge", filter: false},
			{headerName: "Total Mispicks on charge", type: "number", field: "all_groups_total_number_of_miss_picks_On_charge", filter: false},
			{headerName: "% of total Mispicks on charge", type: "string", field: "all_groups_percent_of_miss_picks_On_charge", filter: false},
			{headerName: this.translateService.instant('act.total_number_of_mispicks_pm'), type: "number", field: "all_groups_total_number_of_mispicks_pm", filter: false},
			{headerName: this.translateService.instant('act.percent_of_mispicks_pm'), type: "number", field: "all_groups_percent_of_mispicks_pm", filter: false}
		];
		
	}

	dateRangeChange(value) {
		let oneDaySeconds = 60*60*24;

		this.toDate = moment.utc().add(-1, 'day').endOf('day').unix();

		switch (value) {
			case "90":
				this.fromDate = this.toDate - (oneDaySeconds * 90);
				break;
			case "30":
				this.fromDate = this.toDate - (oneDaySeconds * 30);
				break;
			case "w":
				let daysDifference = 0;
				if (new Date().getDay() === 0) {
					daysDifference = 7;
				}
				this.toDate = moment.utc().day(0-daysDifference).endOf('day').unix();    // last Sunday
				this.fromDate = moment.utc().day(-6-daysDifference).startOf('day').unix();  // last Monday before Sunday
				break;
			case "7":
				this.fromDate = this.toDate - (oneDaySeconds * 7);
				break;
			case "1":
				this.fromDate = this.toDate - oneDaySeconds;
				break;
		}

		let fromDateSiteZone = this.commonUtil.getZoneTimestampFromUTC(this.site.zoneid, moment.utc(parseInt(this.fromDate) * 1000).unix(), true);
		let toDateSiteZone = this.commonUtil.getZoneTimestampFromUTC(this.site.zoneid, moment.utc(parseInt(this.toDate) * 1000).unix(), true);

		if (value == "1") {
			this.picksText = 'Number of Daily Battery Picks (' + moment.utc(fromDateSiteZone*1000).format('MM/DD/YYYY') + ')';
		} else {
			this.picksText = 'Number of Daily Battery Picks (' + moment.utc(fromDateSiteZone*1000).format('MM/DD/YYYY') + ' - ' + moment.utc(toDateSiteZone*1000).format('MM/DD/YYYY') + ')';
		}
		
		this.formatData(this.dataCopy, {fromDate: this.fromDate, toDate: this.toDate});
	}

	checkboxChange(event) {
		this.formatData(this.dataCopy, {fromDate: this.fromDate, toDate: this.toDate});
	}

	formatData(data, dateRange= {}) {
		
		this.data = {};
		this.gridRows = [];
		this.chartObjs = {};
		this.groupCharts = [];

		let filter = false;
		if (dateRange['fromDate'] && dateRange['toDate']) {
			filter = true;
		}

		let keys = Object.keys(data);
		keys = keys.sort();
		if (this.selectedCheckbox) { 

			for (let dateItem of keys) {

				if (filter && (dateItem < dateRange['fromDate'] || dateItem > dateRange['toDate']) ) {
					continue;
				}

				this.data[dateItem] = {};
				this.data[dateItem].date = parseInt(dateItem) * 1000;
				let chartRows = [];
				for (let groupid in data[dateItem]) {
					if (this.siteGroups.indexOf(parseInt(groupid)) == -1) {
						continue;
					}

					let dateIteminZone = this.commonUtil.getZoneTimestampFromUTC(this.site.zoneid, moment.utc(parseInt(dateItem) * 1000).unix(), true);
					let dateIteminZoneFormatted = this.commonUtil.getDateFormattedFromUnixTimeStamp(dateIteminZone, "date");
					
					let groupname = data[dateItem][groupid]['groupname'];
					let totalPicksMispicks = parseInt(data[dateItem][groupid].total_number_of_picks) + parseInt(data[dateItem][groupid].total_number_of_miss_picks_Off_charge) + parseInt(data[dateItem][groupid].total_number_of_miss_picks_On_charge) + parseInt(data[dateItem][groupid].total_number_of_mispicks_pm || 0);
					
					this.data[dateItem][groupid]= data[dateItem][groupid];
					this.data[dateItem][groupid].date = dateIteminZoneFormatted;
					this.data[dateItem][groupid].groupname = groupname;

					if (totalPicksMispicks) {
						this.data[dateItem][groupid].percentage_of_miss_picks_Off_charge = Math.round( ( parseInt(data[dateItem][groupid].total_number_of_miss_picks_Off_charge) / totalPicksMispicks) * 100) + "%";
						this.data[dateItem][groupid].percent_of_picks = Math.round( ( parseInt(data[dateItem][groupid].total_number_of_picks) / totalPicksMispicks ) * 100) + "%";
						this.data[dateItem][groupid].percent_of_miss_picks_On_charge = Math.round( ( parseInt(data[dateItem][groupid].total_number_of_miss_picks_On_charge) / totalPicksMispicks) * 100) + "%";
						this.data[dateItem][groupid].percent_of_mispicks_pm = Math.round( ( parseInt(data[dateItem][groupid].total_number_of_mispicks_pm || 0) / totalPicksMispicks) * 100) + "%";
					} else {
						this.data[dateItem][groupid].percentage_of_miss_picks_Off_charge = 0 
						this.data[dateItem][groupid].percent_of_picks = 0 
						this.data[dateItem][groupid].percent_of_miss_picks_On_charge = 0;
						this.data[dateItem][groupid].percent_of_mispicks_pm = 0;
					}
					
					this.gridRows.push(this.data[dateItem][groupid]);
					if (!this.chartObjs[groupid]) {
						this.chartObjs[groupid] = {
							data: [],
							dataTable: [[
								{ type: "date", label: "Date" },
								{ type: "number", label: "Total Correct Picked Batteries" },
								{ type: "number", label: "Mispicks off charge" },
								{ type: "number", label: "Total Mispicks on charge" },
								{ type: "number", label: this.translateService.instant('act.total_number_of_mispicks_pm') }
							]],
							groupname: groupname,
							groupid: groupid
						};
					}

					this.chartObjs[groupid].data.push([
						new Date(dateIteminZoneFormatted),
						parseInt(this.data[dateItem][groupid].total_number_of_picks),
						parseInt(this.data[dateItem][groupid].total_number_of_miss_picks_Off_charge),
						parseInt(this.data[dateItem][groupid].total_number_of_miss_picks_On_charge),
						parseInt(this.data[dateItem][groupid].total_number_of_mispicks_pm || 0)
					]);
					
					if (this.selectedDateRange =="1") {
						this.chartObjs[groupid].data.push([new Date(), 0, 0, 0, 0]);
					}

				}
			}

			this.groupCharts = [];
			for (let chartObj in this.chartObjs) {
				this.chartObjs[chartObj].dataTable = this.chartObjs[chartObj].dataTable.concat(this.chartObjs[chartObj].data);
				this.groupCharts.push(this.chartObjs[chartObj]);
			}

		} else {

			this.chartDataTable = [[
				{ type: "date", label: "Date" },
				{ type: "number", label: "Total Correct Picked Batteries" },
				{ type: "number", label: "Mispicks off charge" },
				{ type: "number", label: "Total Mispicks on charge" },
				{ type: "number", label: this.translateService.instant('act.total_number_of_mispicks_pm')}
			]];

			let chartRows = [];

			for (let dateItem of keys) {
				if (filter && (dateItem < dateRange['fromDate'] || dateItem > dateRange['toDate']) ) {
					continue;
				}
				this.data[dateItem] = {
					all_groups_total_number_of_picks: 0,
					all_groups_percent_of_picks: 0,
					all_groups_total_number_of_miss_picks_Off_charge: 0,
					all_groups_percentage_of_miss_picks_Off_charge: 0,
					all_groups_total_number_of_miss_picks_On_charge: 0,
					all_groups_percent_of_miss_picks_On_charge: 0,
					all_groups_total_number_of_mispicks_pm: 0,
					all_groups_percent_of_mispicks_pm: 0,
					total_picks_mispicks: 0
				};

				for (let groupid in data[dateItem]) {
					if (this.siteGroups.indexOf(parseInt(groupid)) == -1) {
						continue;
					}

					let dateIteminZone = this.commonUtil.getZoneTimestampFromUTC(this.site.zoneid, moment.utc(parseInt(dateItem) * 1000).unix(), true);
					let dateIteminZoneFormatted = this.commonUtil.getDateFormattedFromUnixTimeStamp(dateIteminZone, "date");
				
					this.data[dateItem].date = dateIteminZoneFormatted;
					this.data[dateItem].total_picks_mispicks = parseInt(this.data[dateItem].total_picks_mispicks) + parseInt(data[dateItem][groupid].total_number_of_picks) + parseInt(data[dateItem][groupid].total_number_of_miss_picks_Off_charge) + parseInt(data[dateItem][groupid].total_number_of_miss_picks_On_charge) + parseInt(data[dateItem][groupid].total_number_of_mispicks_pm || 0);

					this.data[dateItem].all_groups_total_number_of_picks = parseInt(this.data[dateItem]['all_groups_total_number_of_picks']) + parseInt(data[dateItem][groupid]['total_number_of_picks']);
					this.data[dateItem].all_groups_total_number_of_miss_picks_Off_charge = parseInt(this.data[dateItem].all_groups_total_number_of_miss_picks_Off_charge) +  parseInt(data[dateItem][groupid].total_number_of_miss_picks_Off_charge);
					this.data[dateItem].all_groups_total_number_of_miss_picks_On_charge = parseInt(this.data[dateItem].all_groups_total_number_of_miss_picks_On_charge) +  parseInt(data[dateItem][groupid].total_number_of_miss_picks_On_charge);
					this.data[dateItem].all_groups_total_number_of_mispicks_pm = parseInt(this.data[dateItem].all_groups_total_number_of_mispicks_pm || 0) +  parseInt(data[dateItem][groupid].total_number_of_mispicks_pm || 0);
				}
				if (this.data[dateItem].total_picks_mispicks) {
					this.data[dateItem].all_groups_percent_of_picks = Math.round(( parseInt(this.data[dateItem].all_groups_total_number_of_picks) / this.data[dateItem].total_picks_mispicks) * 100) + "%";
					this.data[dateItem].all_groups_percentage_of_miss_picks_Off_charge = Math.round(( parseInt(this.data[dateItem].all_groups_percentage_of_miss_picks_Off_charge) / this.data[dateItem].total_picks_mispicks) * 100) + "%";
					this.data[dateItem].all_groups_percent_of_miss_picks_On_charge = Math.round(( parseInt(this.data[dateItem].all_groups_percent_of_miss_picks_On_charge) / this.data[dateItem].total_picks_mispicks) * 100) + "%";
					this.data[dateItem].all_groups_percent_of_mispicks_pm = Math.round(( parseInt(this.data[dateItem].all_groups_percent_of_mispicks_pm || 0) / this.data[dateItem].total_picks_mispicks) * 100) + "%";
				} else {
					this.data[dateItem].all_groups_percent_of_picks = 0;
					this.data[dateItem].all_groups_percentage_of_miss_picks_Off_charge = 0;
					this.data[dateItem].all_groups_percent_of_miss_picks_On_charge = 0;
					this.data[dateItem].all_groups_percent_of_mispicks_pm = 0;
				}
				
				this.gridRows.push(this.data[dateItem]);
				
				let chartRow = [
					new Date(this.data[dateItem].date),
					this.data[dateItem].all_groups_total_number_of_picks,
					this.data[dateItem].all_groups_total_number_of_miss_picks_Off_charge,
					this.data[dateItem].all_groups_total_number_of_miss_picks_On_charge,
					this.data[dateItem].all_groups_total_number_of_mispicks_pm

				];
				chartRows.push(chartRow);
			}

			this.chartDataTable  = this.chartDataTable.concat(chartRows);
		}

		this.config = new ColumnChartConfig({
			isPercent: false,
			LegendPosition: 'top',
			colors: ['#00b00b', '#fffb00','#ff0400', '#0033ff'],
			explorer: {
				'axis': 'horizontal',
				'actions': ['dragToZoom', 'rightClickToReset'],
				maxZoomIn: 50.0,
				keepInBounds: true
			},
			tooltip:{},
			vAxisMinValue: 0,
			vAxisMaxValue: 2
		});
	}

	ngOnDestroy() {
		this.currentSiteSub.unsubscribe();
		this.getReportSubscription.unsubscribe();
	}

}
