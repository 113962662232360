import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DynamicResponseService {

	drOperationTypes = {
		"lockout": "lockout",
		"reduce power": "reduce power",
		"limit power": "limit power"
	};

	drTypes = {
		"SRM": "SRM",
		"ELRP": "ELRP",
		"ELRP Audit": "ELRP Audit",
		"Audit Test": "Audit Test",
		"None" :"None"
	};

	drProviders = {
		"ENERNOC": "ENERNOC",
		"VOLTUS": "VOLTUS"
	};

	constructor(
		private httpClient: HttpClient,
	) { }

	getSiteProfiles(siteId) {
		return this.httpClient.post('/dr/getSiteProfiles', {siteId}, {
			observe: "body"
		});
	}

	getEventLogs(siteId, eventId, fromDate, toDate) {
		return this.httpClient.post('/dr/getEventLogs', {siteId, eventId, fromDate, toDate}, {
			observe: "body"
		});
	}

	getEventsSiteHistory(siteId, fromDate, toDate) {
		return this.httpClient.post('/dr/getEventsSiteHistory', {siteId, fromDate, toDate}, {
			observe: "body"
		});
	}

	getEventsChart(siteId, fromDate, toDate) {
		return this.httpClient.post('/dr/getEventsChart', {siteId, fromDate, toDate}, {
			observe: "body"
		});
	}

	updateSiteProfile(siteId, action, profile) {
		return this.httpClient.post('/dr/updateSiteProfile', {siteId, action, profile}, {
			observe: "body"
		});
	}

	addProfileEvent(siteId, event) {
		return this.httpClient.post('/dr/addProfileEvent', {siteId, event}, {
			observe: "body"
		});
	}
	addProfileWeeklyEvent(siteId, event) {
		return this.httpClient.post('/dr/addWeeklyProfileEvent', {siteId, event}, {
			observe: "body"
		});
	}
	DeleteOrCancelEvent(siteId, eventId) {
		return this.httpClient.post('/dr/DeleteOrCancel', {siteId, eventId}, {
			observe: "body"
		});
	}

}