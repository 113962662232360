import { Component, OnInit, Input, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { DevicesDashboardService } from '../devices-dashboard.service';
import { ChartUtil } from '../../../../shared/services/utility/chart.service';
import { LineChartConfig } from '../../../../shared/google-chart/Models/LineChartConfig';
declare var google: any;

@Component({
	selector: 'app-rt-records',
	templateUrl: './rt-records.component.html',
	styleUrls: ['./rt-records.component.css']
})
export class RtRecordsComponent implements OnInit {

	@Input() hasTemperature: boolean = false;
	@Input() hasGrid: boolean = false;
	@Input() device: any = {};
	@Input() companyName: string = '';
	@Input() siteName: string = '';
	@Input() isBattviewMobile: boolean = false;

	RTrecords: any[] = [];
	fromDateFmt: Date = new Date();
	rtRecordsChartCheckbox: any = {
		voltage: true,
		current: true,
		temperature:true
	};
	gridColumns: any[] = [];
	gridData: any[] = [];
	chartObject: any = {};
	printChartLink: string = '';
	drawChart: boolean = true;
	hasPrevChartData: boolean = true; // Previous search yielded chart data

	@ViewChild('chartHtmlObject') chartHtmlObject;

	constructor(
		private commonUtil: CommonUtil,
		private devicesDashboardService: DevicesDashboardService,
		private chartUtil: ChartUtil,
	) { }

	ngOnInit() {
		this.gridColumns = [
			{headerName: "Sequence", field: "sequence", type:"number", width: 180},
			{headerName: "Voltage", field: "voltage", type:"number", width: 180},
			{headerName: "Current", field: "current", type:"number", width: 180},
			{headerName: "Temperature", field: "temperature", type:"number", width: 180},
			{headerName: "Soc", field: "soc", type:"number", width: 180},
			{headerName: "Time", field: "timestamp", filterParams: {defaultOption: 'startsWith'}, width: 180},
		];
	}
	drawRTrecordsChart() {
		var vAxes = {
			"0" : {
				"textPosition": "out",
					textStyle: {color: '#328332'},
					title: "Voltage (V)",
					titleTextStyle: {color: '#328332'},
				},
			"1" : {
				"textPosition": "in",
				textStyle:{color: '#2828ab'},
				title: "Current (A)",
				titleTextStyle: {color: '#2828ab'}
			}
		};
		let series: any = {
			"0" : {
				"targetAxisIndex":0, 
				"color": "#328332" ,
				type: 'line'
			},
			"1" : {
				"targetAxisIndex":1, 
				"color" : "#2828ab", 
				type: 'line'
			}
		};
		if(this.hasTemperature) {
			var temperatureformat = 'F';
			if(this.device.temperatureformat == 0) {
				temperatureformat = 'C';
			}
			vAxes["2"] = {
				"textPosition": "out", 
				textStyle:{color: '#b20e66'}, 
				title: "Temperature ("+temperatureformat+")", 
				titleTextStyle: {color: '#b20e66'}
			};
			series["2"] = {
				"targetAxisIndex":2, 
				"color" : "#b20e66", 
				type: 'line'
			};
		}

		let config = new LineChartConfig({
			LegendPosition: 'top', 
			hAxis: {
				title: "Time of Day"
			},
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
			vAxes: vAxes,
			"series": series,
			explorer: {
				"actions": [
					"dragToZoom",
					"rightClickToReset"
				],
				maxZoomIn: 50.0,
				keepInBounds: true
			},
			widgetHeight: 400,
			dataIsDataTable: true,
		});

		if (!this.rtRecordsChartCheckbox.voltage) {
			config.series["0"].lineWidth = 0;
			config.series["0"].enableInteractivity= false;
			config.series["0"].tooltip ="none";
			config.vAxes["0"].textPosition = "none";
			config.vAxes["0"].title = "";
		}
		if (!this.rtRecordsChartCheckbox.current) {
			config.series["1"].lineWidth = 0;
			config.series["1"].enableInteractivity= false;
			config.series["1"].tooltip ="none";
			config.vAxes["1"].textPosition = "none";
			config.vAxes["1"].title = "";
		}
		if (this.hasTemperature && !this.rtRecordsChartCheckbox.temperature) {
			config.series["2"].lineWidth = 0;
			config.series["2"].enableInteractivity= false;
			config.series["2"].tooltip ="none";
			config.vAxes["2"].textPosition = "none";
			config.vAxes["2"].title = "";
		}
		if (this.RTrecords.length === 0) {
			config.vAxes["0"].title = "";
			config.vAxes["1"].title = "";
			if(this.hasTemperature)
				config.vAxes["2"].title = "";
		}

		let columnNames = ['Date', 'Voltage', 'Current'];

		if(this.hasTemperature) {
			columnNames.push('Temperature');
		}

		let data = [];
		this.RTrecords.forEach((item) => {
			var temp = [item.time, item.voltage, item.current];
			if(this.hasTemperature) {
				temp.push(item.temperature);
			}
			data.push(temp);
		});

		var rtRecordsDataTable = new google.visualization.DataTable();
		rtRecordsDataTable.addColumn('datetime', 'Date');
		rtRecordsDataTable.addColumn('number', 'Voltage');
		rtRecordsDataTable.addColumn('number', 'Current');
		if(this.hasTemperature)
			rtRecordsDataTable.addColumn('number', 'Temperature');

		rtRecordsDataTable.addRows(data);
		rtRecordsDataTable.sort([{column: 0}]);
		this.chartObject.data = rtRecordsDataTable;
		this.chartObject.config = config;
	}

	getRTrecordsData() {
		var studyId	= this.device.studyid || 0;

		var fromDate:any	= new Date(new Date(this.fromDateFmt).setHours(0, 0, 0, 0));
		fromDate			= Math.floor((-fromDate.getTimezoneOffset() * 60000 + fromDate.getTime()) / 1000);

		var toDate	= fromDate + (24*60*60);

		this.devicesDashboardService.getBattviewRTrecords(this.device.id, studyId, fromDate, toDate).subscribe((response: any) => {
			var recordsRTGridData = [];
			for (var i = 0; i < response.length; i++) {
				var formattedTime = this.commonUtil.getDateFormattedFromUnixTimeStamp(response[i].data.timestamp);
				recordsRTGridData[i] = {
					sequence: response[i].recordid,
					voltage: response[i].data.voltage,
					current: response[i].data.current,
					soc: response[i].data.soc,
					timestamp: formattedTime,
					time: new Date(formattedTime),
				};
				if(this.hasTemperature){
					recordsRTGridData[i].temperature = null;
					if(response[i].data.temperatureEnabled) {
						if(this.device.temperatureformat == 1) {
							recordsRTGridData[i].temperature = this.commonUtil.fahToCel(response[i].data.temperature, true);
						} else {
							recordsRTGridData[i].temperature = response[i].data.temperature;
						}
					}
				}
			}
			this.drawChart = (recordsRTGridData.length > 1);

			this.RTrecords = recordsRTGridData;
			this.gridData = recordsRTGridData;

			if(this.drawChart) {
				if(this.hasPrevChartData)
					this.drawRTrecordsChart();
				else
					setTimeout(() => { this.drawRTrecordsChart(); }, 0);
			}
			this.hasPrevChartData = this.drawChart;
		}); 
	}

	generateImageURI(event) {
		let chart = event['chart'];
		let image = this.chartUtil.getChartImageUri(chart);
		this.printChartLink = image;
	}

	printRTChart() {
		var image = this.printChartLink;
		var printContent = '<img src="'+image+'" style="width:100%;"" border="0" alt="Null">';

		var battviewIdsText	= '',
			date = new Date(this.fromDateFmt).toLocaleDateString();

		if(this.device.id)
			battviewIdsText = "<h4> Battview ID: " + this.device.id + "</h4>";

		printContent ='<div><h3>RT Records </h3><h4>' + date+ "</h4>" + battviewIdsText + printContent + '</div>';

		this.commonUtil.print({
			appendPrintContent: printContent,
			customerName: this.companyName,
			siteName: this.siteName
		});
	}
}