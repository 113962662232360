import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';

@Component({
	selector: 'app-battery-aging',
	templateUrl: './battery-aging.component.html',
	styleUrls: ['./battery-aging.component.css']
})
export class BatteryAgingComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: ColumnChartConfig;
	tableColumns: any[] = [];

	constructor(private chartUtil: ChartUtil) { }

	ngOnInit() {
		this.config = new ColumnChartConfig({
			isPercent: true,
			xTitle: "% Remaining Life",
			yTitle: "% Batteries"
		});

		this.config.formatFunction = (data) => {
			data = data.list;
			let colors = ['#bd2f26', '#3f69ec'];
			let formattedData: any[] = [
				[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'},
				]
			];

			for(let i = 0; i < data.length; i++) {
				let item: any = data[i];
				formattedData.push(
					[
						{v: item.x + "%"},
						{v: item.y / 100},
						{v: Math.round(item.y*10)/10 + "%"},
						{v: 'color: ' + (this.chartUtil.getColor(item.x) || colors[i % 2])}
					]
				);
			}

			return formattedData;
		};
	}

	ngOnChanges(changes) {
		if(this.data) {
			let tableColumns = [{title: "Battery ID", property: "battery_id"}];
			if (this.data.siteSettings.userSettings.view_truck_id) {
				tableColumns.push({title: "Truck ID", property: "truck_id"});
			}
			tableColumns.push(
				{title: "Age (Months)", property: "months"},
				{title: "Battery SN", property: "battery_sn"}
			);
			this.tableColumns = tableColumns;
		}
	}
}
