<div>
	<h2>{{'nav.smart_rebates_sites' | translate}}</h2>
	<table class="table table-striped" *ngIf="data.length > 0; else noData">
		<thead>
			<tr>
				<th>{{'device.customer_name' | translate}}</th>
				<th>{{'site.site_name' | translate}}</th>
				<th>{{'device.number_of_devices' | translate}}</th>
				<th>{{'site.consumptions_report' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let site of data">
				<td><a [routerLink]="['/', 'main', site.customerId]">{{site.customer_name}}</a></td>
				<td><a [routerLink]="['/', site.customerId, site.id]">{{site.name}}</a></td>
				<td>{{site.count}}</td>
				<td>
					<i class="fa fa-download pointer mr-1" (click)="showConsumptionsReport(site)"></i>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>

<app-modal
	#consumptionsReportDialog
	title="{{'site.consumptions_report' | translate}}"
	[confirmBtnText]="'g.export_to_xlsx' | translate"
	[closeBtnText]="'g.cancel' | translate"
>
	<div class="d-flex flex-wrap justify-content-between py-4">
		<div class="d-flex justify-content-start flex-wrap">
			<div class="mx-2 mb-2 quarterly-filter">
				{{'g.select_year' | translate}}
				<ng-select class="mr-auto w-100 option" [items]="years" bindLabel="label" bindValue="id" placeholder="{{'g.select_year' | translate}}" name="year" [(ngModel)]="selectedYear" [clearable]="false">
				</ng-select>
			</div>
			<div class="mx-2 mb-2 quarterly-filter">
				{{'g.select_quarter' | translate}}
				<ng-select class="mr-auto w-100 option" [items]="quarters" bindLabel="value" bindValue="id" placeholder="{{'g.select_quarter' | translate}}" name="year" [(ngModel)]="selectedQuarter" [clearable]="false">
				</ng-select>
			</div>
			<div class="mx-2 quarterly-filter">
				{{'smart_rebates_reports.sr_enable_date' | translate}}
				<div class="input-group">
					<input readonly class="form-control" id="fromDate" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="selectedStartDate" bsDatepicker #fromDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
					<span class="input-group-btn">
						<button class="btn btn-default btn-date" (click)="fromDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
					</span>
				</div>
			</div>
		</div>
	</div>
</app-modal>
