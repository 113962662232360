import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
	selector: 'app-site-batteries-report',
	templateUrl: './site-batteries-report.component.html',
	styleUrls: ['./site-batteries-report.component.css']
})
export class SiteBatteriesReportComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};

	tableColumns: any[] = [];
	title: string = 'Site Batteries';
	elementId: string = 'site_batteries';
	widgetClass: string = 'auto-height-widget p-b-30';
	siteWidgetOptions: any = {
		hasOperation: true,
		hasDate: true,
		hideYesterdayDate: true,
		isReport: true,
		noprint: true
	};

	constructor() { }

	ngOnInit() {
		this.setColumns();
	}

	ngOnChanges() {
		if(this.data && this.data.siteAlertsSettings) {
			this.setColumns();
		}
	}

	setColumns() {
		let columns: any = [
			{
				headerName: "Battery ID",
				field: "batteryid",
				cellRendererSelector:function (params) {
					params['label'] = params.data.batteryid;
					params['link'] = ["/#", params.colDef.cellRendererParams.customerId, params.colDef.cellRendererParams.siteId, "battviews", "analytics", "battery-summary", params.data.battviewid].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				cellRendererParams: {customerId: this.customerId, siteId: this.siteId},
				filter: 'agTextColumnFilter',
				width: 150
			}
		];

		if(this.data && this.data.siteAlertsSettings && this.data.siteAlertsSettings.view_truck_id) {
			columns = columns.concat([
				{headerName: "Truck ID", width: 150, field: "truck_id", filter: 'agTextColumnFilter'}
			]);
		}
		columns = columns.concat([
			{headerName: "Avg Daily EBU", width: 180, field: "avg_ebu", type:"number"},
			{headerName: "Max Daily EBU", field: "max_ebu", width: 180, type:"number"},
			{headerName: "Max Temperature", field: "maxTemp", width: 190},
			{headerName: "Min daily idle after event time (min)", field: "min_idle", width: 250, type:"number"},
			{headerName: "Total Charge AHRs", field: "total_charge_ahrs", width: 200, type:"number"},
			{headerName: "Total In Use AHRs", field: "total_inuse_ahrs", width: 200, type:"number"},
			{headerName: "Remaining Life Based on Usage", field: "remaining_ahrs", width: 265, type:"number", filterParams: '1.2-2'},
			{headerName: "Remaining Life Years", field: "remaining_yrs", width: 210, type:"number", filterParams: '1.2-2'},
		]);

		this.tableColumns = columns;
	}
}
