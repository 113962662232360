<div>
	<div class="panel panel-default margin-bottom-xs label-text" *ngIf="siteChargers.length > 0">
			<div class="panel-body">
				<div class="row margin-bottom">
					<div class="col-md-2 text-right-md margin-top"><strong>{{'act.select_charger' | translate}}:</strong></div>
					<div class="col-md-3 margin-top">
						<div class="form-field">
							<ng-select [items]="siteChargers" bindLabel="prettyName" bindValue="id" placeholder="{{'act.select_charger' | translate}}" name="chargerID" [(ngModel)]="chargerId" [clearable]="false">
							</ng-select>
						</div>
					</div>
				</div>
				<div class="row margin-bottom">
					<div class="col-md-2 text-right-md "><strong>{{'g.date' | translate}}:</strong></div>
					<div class="col-md-3">
						<div class="input-group">
							<input class="form-control" placeholder="mm/dd/yyyy" name="startDB" [(ngModel)]="fromDateFmt" bsDatepicker #startDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
							<span class="input-group-btn">
								<button class="btn btn-default btn-date" (click)="startDB.toggle()" type="button"><i class="fa fa-calendar"></i></button>
							</span>
						</div>
					</div>
				</div>
				<div class="text-danger" *ngIf="dataFetched && (!validFrmTime || !validToTime)">{{'time.please_enter_a_valid_time_range' | translate}}</div>
				<div class="row">
					<div class="col-md-2 text-right-md margin-top-sm"><strong>{{'time.from_time' | translate}}:</strong></div>
					<div class="col-md-3 no-gutter"><timepicker (isValid)="validFrmTime = $event" [(ngModel)]="fromTime" [showSpinners]="false" [showMeridian]="false"></timepicker></div>
				</div>
				<div class="row">
					<div class="col-md-2 text-right-md margin-top-sm"><strong>{{'time.to_time' | translate}}:</strong></div>
					<div class="col-md-3 no-gutter"><timepicker (isValid)="validToTime = $event" [(ngModel)]="toTime" [showSpinners]="false" [showMeridian]="false"></timepicker></div>
				</div>
				<div>
					<button class="btn btn-primary" (click)="getHistoricalChargerLogData()">{{'g.get_data' | translate}}</button>
				</div>
			</div>
	</div>


	<div class="panel panel-default margin-bottom-xs label-text" *ngIf="dataFetched">
		<div class="panel-body">
			<div *ngIf="chargerIds.length==0"><strong>{{'g.no_results' | translate}}</strong></div>
			<table class="table" *ngIf="chargerIds.length>0">
				<tr>
					<th>{{'device.charger' | translate}}</th>
					<th>{{'device.truck_type' | translate}}</th>
					<th>{{'g.time' | translate}}</th>
					<th>{{'device.status' | translate}}</th>
					<th>{{'g.rank' | translate}}</th>
					<th>{{'g.alert' | translate}}</th>
					<th>{{'g.data' | translate}}</th>
				</tr>
				<tr *ngFor="let charger of dataArr" class="margin-bottom-sm">
					<td class="col-md-2">
					 <a [routerLink]="charger['name_link']" >{{charger['name']}}</a>
					</td>
					<td class="col-md-2">
							{{charger['groupname']}}
					</td>
					<td class="col-md-2">
						{{charger['time_formatted']}}
					</td>
					<td class="col-md-1">
							{{charger['status']}}
					</td>
					<td class="col-md-1">
							{{charger['rank']}}
					</td>
					<td class="col-md-1">
							{{charger['alertFlag']}}
					</td>
					<td class="col-md-3">
							<div *ngIf="charger['batteryid']">{{'device.battery' | translate}}: <a [routerLink]="charger['battery_id_link']">{{charger.batteryname}}</a></div>
							<div>{{'g.AHR' | translate}}: {{charger['Ahrs']}}</div>
							<div>{{'device.temperature' | translate}}: {{charger['temperature']}}</div>
							<div>{{'device.connected' | translate}}: {{charger['connected']}}</div>
							<div>{{'device.cooled_down_timer' | translate}}: {{charger['relaxedBatteryTimer']}}</div>
							<div>{{'device.score' | translate}}: {{charger['score']}}</div>
							<div>{{'device.exit_status' | translate}}: {{charger['exit_status_text']}}</div>
					</td>
				</tr>
			</table>
		</div>
	</div>
</div>