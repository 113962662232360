import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdminService } from '../../admin.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';

@Component({
	selector: 'app-actlink-failed-db',
	templateUrl: './actlink-failed-db.component.html',
	styleUrls: ['./actlink-failed-db.component.css']
})
export class ActlinkFailedDbComponent implements OnInit, OnDestroy {

	faultQueueLog: any = [];
	logData: any = [];
	numLogsPerPage: number = 10;

	constructor(
		private adminService: AdminService,
		private breadcrumb: BreadcrumbService,
		private notificationMessage: NotificationMessageService
	) {
	}

	ngOnInit() {
		this.getUploadFaildDB();
		this.breadcrumb.setBreadcrumb([
			{label: "ACTLink Failed DB", url: ""}
		]);
	}
	getUploadFaildDB() {

		this.adminService.getUploadFaildDB().subscribe(
			data => {
				this.faultQueueLog = data;
			}
		);
	}
	delete(type, id) {
		this.adminService.deleteLog(id, type).subscribe(
			(isDeleted: any) => {
				if(isDeleted.result) {
					this.notificationMessage.setMessage('Deleted Successfully!', 'success', 10000, true);
					for(let i in this.logData) {
						if (this.logData[i].id == id) {
							this.logData.splice(+i, 1);
							break;
						}
					}
				}
			}
		);
	}
	download(id, data, event) {
		var url = URL.createObjectURL( new Blob( [JSON.stringify(data)], {type:'text/plain'} ) );
		event.currentTarget.href = url;
		event.currentTarget.download = 'faultedDB_'+id+'.json';
	}
	loadData(data) {
		this.logData = data;
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
