import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'underscore';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../auth/user.service';

@Component({
	selector: 'app-battviews-mobiles-dashboard',
	templateUrl: './battviews-mobiles-dashboard.component.html',
	styleUrls: ['./battviews-mobiles-dashboard.component.css']
})
export class BattviewsMobilesDashboardComponent implements OnInit {

	isBattviewMobile: boolean = true;
	showSettingsTab: boolean = false;
	showBvMobileListingTab: boolean = false;
	hideTemperatureChart: boolean = true;
	siteAlertsSettings: any = {};
	userAlertsSettings: any = {};
	battviewsIDs: any = [];
	viewName: string;
	device: any = {};
	customerInfo: any =  {};
	siteInfo: any =  {};
	currentUser: any = {};
	battviewID: number = 0;
	studyID: number = 0;
	date: {
		fromDate: any,
		toDate: any
	} = {
		fromDate: null,
		toDate: null
	};
	hasData: boolean = true;
	activePage: string = null;
	activeTab: string = null;
	activeSubTab: string = null;
	showElementsFlags: any = {};
	hasResetPLC: boolean = false;
	pageHasBattviewTags: boolean = false;
	pageHasManageBtn: boolean = false;
	pageHasTimesInfo: boolean = false;
	isShared: boolean = false;
	pageHasRTrecord: boolean = false;

	@ViewChild("analyticsPageComponent") analyticsPageComponent;
	@ViewChild("battviewSelectCriteriaComponent") battviewSelectCriteriaComponent;
	@ViewChild("quickViewComponent") quickViewComponent;
	@ViewChild("faultsComponent") faultsComponent;
	@ViewChild("debugRecordsComponent") debugRecordsComponent;
	@ViewChild("configTrackComponent") configTrackComponent;
	@ViewChild("fetchRtRecordsComponent") fetchRtRecordsComponent;
	@ViewChild("listRtRecordsComponent") listRtRecordsComponent;
	@ViewChild("bvMLComponent") bvMLComponent;
	@ViewChild("tabs") tabs;

	constructor(
		private route: ActivatedRoute,
		public userService: UserService,
	) {
	}
	
	ngOnInit() {
		this.activePage = this.route.snapshot.data['pageName'];
		this.viewName = this.route.snapshot.data['viewName'];
		this.isShared = this.route.snapshot.parent.data.isShared;

		if(this.activePage == 'analytics') {
			this.route.params.subscribe(params => {
				
				this.activeTab = params['tab'];
				this.activeSubTab = params['subTab'];
			});

		} else if(this.activePage == 'settings') {
			this.activeTab = this.activePage;
			this.route.queryParams.subscribe( params => {
				let battviewsIDs = params['battviewsIDs'] || '';
				battviewsIDs = _.map(battviewsIDs.split(','), function(value){ return value.split('-'); });
				
				if(!battviewsIDs[0]) {
					battviewsIDs = [];
				}
				this.battviewID = Number(battviewsIDs[0][0]) || 0;
				this.studyID = Number(battviewsIDs[0][1]) || 0;
				
				battviewsIDs = params['battviewsIDs'] || '';

				if(!battviewsIDs) return;

				this.battviewsIDs = _.map(battviewsIDs.split(','), function(value){ return parseInt(value, 10); });
			});
		}

		this.currentUser = this.userService.getCurrentUser();
		if(!this.currentUser.isACTuser) {
			this.updateHasBvMobileListing(this.isBattviewMobile);
		}
	}
	
	updateShowSettingsTab(showSettingsTab) {
		this.showSettingsTab = showSettingsTab;
	}

	updateSelectedDevice($event, source: string = ''){
		this.device = $event[0];
		if($event[1])
			this.date = $event[1];

		switch(this.activePage) {
			case 'analytics':
				this.analyticsPageComponent.onDeviceChanged(this.device);
			break;
			case 'quick-view':
				this.quickViewComponent.onDeviceChanged(this.device);
			break;
			case 'faults':
				this.faultsComponent.onDeviceChanged(this.device);
			break;
			case 'debug-records':
				this.debugRecordsComponent.onDeviceChanged(this.device);
			break;
			case 'config-track':
				this.configTrackComponent.onDeviceChanged(this.device);
			break;
			case 'fetch-rt-records':
				this.fetchRtRecordsComponent.onDeviceChanged(this.device);
			break;
			case 'list-rt-records':
				this.listRtRecordsComponent.onDeviceChanged(this.device);
			break;
			case 'settings':
				if(source != 'onGetBattviewInfo') {
					if(this.device.id) {
						this.battviewID = Number(this.device.id) || 0;
						this.battviewsIDs = [this.device.id || 0];
					}
				}
			break;
		}
	}
	updateSelectedDate(date){
		this.date = date;
		switch(this.activePage) {
			case 'analytics':
				this.analyticsPageComponent.onDatesUpdated(date);
			break;
			case 'debug-records':
				this.debugRecordsComponent.onDatesUpdated(date);
			break;
		}
	}

	onResetPLC(reset) {
		if(this.faultsComponent)
			this.faultsComponent.onResetPLC(reset);
	}

	updateAlertSettings(alertSettings){
		this.siteAlertsSettings = alertSettings[0];
		this.userAlertsSettings = alertSettings[1];
	}
	updatePageHasRTrecord(pageHasRTrecord) {
		this.pageHasRTrecord = pageHasRTrecord;
	}

	updateTabs(tabs) {
		this.activePage = tabs[0];
		if(this.activePage == 'settings') 
			this.activeTab = this.activePage;
		else
			this.activeTab = tabs[1] || null;
		this.activeSubTab = tabs[2] || null;
		this.battviewSelectCriteriaComponent.setDates(this.activePage);
	}

	updateAppearanceElementsFlags(object) {
		this.showElementsFlags = _.extend(this.showElementsFlags, object);
	}
	updateHasBvMobileListing(showBvMobileListingTab) {
		this.showBvMobileListingTab = showBvMobileListingTab;
	}
	onDealerChanged(dealerId) {
		if(this.bvMLComponent)
			this.bvMLComponent.selectedDealerChanged(dealerId);
		this.tabs.updateDealerId(dealerId);
	}
}