import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectChargerComponent } from './select-charger/select-charger.component';
import { SiteDashboardService } from '../../site-dashboard.service';

import * as _ from 'underscore';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';


@Component({
	selector: 'app-chargers-dashboard',
	templateUrl: './chargers-dashboard.component.html',
	styleUrls: ['./chargers-dashboard.component.css']
})
export class ChargersDashboardComponent implements OnInit, OnDestroy {

	chargerInfo: any = {};
	customerID: number = 0;
	chargerID: number = 0;
	chargersIDs: any = [];
	siteID: number = 0;
	hasChargers: boolean = false;
	dateRange: {
		'fromDateFmt': Date,
		'toDateFmt': Date
	};
	activePage: string;
	viewName: string;
	analyticsTab: string;
	siteSubscription: any;
	currentSite: any = {};

	@ViewChild('selectCharger') selectCharger: SelectChargerComponent;

	constructor(
		private route: ActivatedRoute,
		private siteDashboardService: SiteDashboardService,
		private sideMenu: SideMenuService,
		private router: Router,
	) { }

	ngOnInit() {
		this.siteSubscription = this.sideMenu.currentSite.subscribe(siteInfo => {
			if(!siteInfo.id)
				return;

			this.siteDashboardService.setActiveTab('chargers');
			this.activePage = this.route.snapshot.data['pageName'];
			this.viewName = this.route.snapshot.data['viewName'];
			this.customerID = this.route.parent.parent.snapshot.params.customerid || 0;
			this.siteID = this.route.parent.parent.snapshot.params.siteid || 0;
			this.currentSite = siteInfo;
			if(this.activePage == 'analytics' || this.activePage == 'notes') {
				this.route.params.subscribe(params => {
					this.chargerID = Number(params['chargerID']) || 0;
	
					if(this.activePage == 'analytics')
						this.analyticsTab = params['tab'];
				});
			} else {
	
				this.route.queryParams.subscribe( params => {
					let chargersIDs = params['chargersIDs'] || '';
	
					chargersIDs = _.map(chargersIDs.split(','), function(value){ return parseInt(value, 10); });
	
					if(!chargersIDs[0]) {
						this.chargersIDs = [];
					} else {
						this.chargersIDs = chargersIDs.filter((elem, pos) => {
							return chargersIDs.indexOf(elem) == pos && elem;//unique array of IDs
						});
					}
					this.chargerID = Number(this.chargersIDs[0]) || 0;
				});
			}
		});
	}
	
	ngOnDestroy() {
		if(this.siteSubscription)
			this.siteSubscription.unsubscribe();
	}

	onChargerSelect(charger) {
		if(this.activePage == 'settings') {
			this.chargerID = Number(charger) || 0;
			this.chargersIDs = [charger || 0]; //charger ID
		} else {
			this.chargerInfo = charger;
			this.chargerID = charger.id;
		}
	}

	updateActivePage(activePage) {
		let url: any[] = [...activePage, this.chargerID || 0];
		return this.router.navigate(url, {relativeTo: this.route.parent});
	}
}