import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BattviewsDashboardService } from '../../battviews-dashboard.service';
import * as _ from 'underscore';
import { CommonUtil } from '../../../../../../shared/services/utility/common.service';
import { ChartUtil } from '../../../../../../shared/services/utility/chart.service';
import { ColumnChartConfig } from '../../../../../../shared/google-chart/Models/ColumnChartConfig';
import { LineChartConfig } from '../../../../../../shared/google-chart/Models/LineChartConfig';

@Component({
	selector: 'app-battview-analytics-charts',
	templateUrl: './battview-analytics-charts.component.html',
	styleUrls: ['./battview-analytics-charts.component.css']
})
export class BattviewAnalyticsChartsComponent implements OnInit {
	@Input() device: any = {};
	@Input() dailyDetails: any = {};
	@Input() siteAlertsSettings: any = {};
	@Input() userAlertsSettings: any = {};
	@Input() events: any = {};
	@Input() isBattviewMobile: boolean = false;
	@Input() customerName: string = null;
	@Input() siteName: string = null;
	@Input() activeSubTab: string = null;
	@Input() hideTemperatureChart: boolean =  true;
	@Input() date: {
		fromDate: any,
		toDate: any
	} = {
		fromDate: null,
		toDate: null
	};
	
	@ViewChild('dailyUsageChartHtmlObject') dailyUsageChartHtmlObject;
	@ViewChild('dailyEbusChartHtmlObject') dailyEbusChartHtmlObject;
	@ViewChild('dailyAhrsChartHtmlObject') dailyAhrsChartHtmlObject;
	@ViewChild('socChartHtmlObject') socChartHtmlObject;
	@ViewChild('maxTempChartHtmlObject') maxTempChartHtmlObject;
	@ViewChild('endTempChartHtmlObject') endTempChartHtmlObject;
	@ViewChild('endVoltageChartHtmlObject') endVoltageChartHtmlObject;
	
	pageChart;
	dailyUsageChart: any = {};
	dailyEbusChart: any = {};
	dailyAhrsChart: any = {};
	socChart: any = {};
	maxTempChart: any = {};
	endTempChart: any = {};
	endVoltageChart: any = {};

	availableAhrsCheckBox: boolean = true;
	maxTempCheckBox: boolean = true;
	endTempCheckBox: boolean = true;
	inuseLimitCheckBox: boolean = true;
	eqVoltageCheckBox: boolean = true;
	potentialCheckBox: boolean = true;
	isCompensatedVoltageCheckBox: boolean = true;
	showPotentialCheckBox: boolean = false;

	selectedCharts: {
		'daily-usage': boolean,
		'daily-ebus': boolean,
		'daily-ahrs': boolean,
		soc: boolean,
		'max-temperature': boolean,
		'end-temperature': boolean,
		'end-voltage': boolean,
	} = {
		'daily-usage': true,
		'daily-ebus': true,
		'daily-ahrs': true,
		soc: false,
		'max-temperature': false,
		'end-temperature': false,
		'end-voltage': false,
	};
	
	selectedTypes: any[] = ['Charge'];

	printChartLinks: string[] = [];

	dailyDetailsReport: any[] = [];
	eventsReport: any[] = [];

	hasHorizontalLimit: boolean = false;
	horizontalLimitObject: any = {};
	chartTitle: string = '';
	noChart: boolean = false;
	noDataChart: boolean = false;

	constructor(
		private battviewsDashboard: BattviewsDashboardService,
		private commonUtil: CommonUtil,
		private chartUtil: ChartUtil,
	) { }

	ngOnChanges(changes) {
		if(changes.dailyDetails && (!changes.dailyDetails.firstChange || (Object.keys(changes.dailyDetails.currentValue).length > 0 && changes.dailyDetails.currentValue))) {
			this.dailyDetailsReport = this.battviewsDashboard.generateDailyDetailReport(changes.dailyDetails.currentValue, this.device, this.siteAlertsSettings);
			this.drawChart();
		}
		if(changes.events && Object.keys(changes.events.currentValue).length > 0 && changes.events.currentValue) {
			this.eventsReport = this.battviewsDashboard.generateEventsReport(changes.events.currentValue, this.device);
			this.noDataChart = (this.eventsReport.length == 0);
			this.drawChart();
		}
	}

	drawChart() {
		switch (this.activeSubTab) {
			case 'daily-usage':
				this.dailyUsageChart = this.getChartObject({chartType: 'ColumnChart', getDataFromDailyDetails: true});
				this.chartTitle = 'Daily Usage';
			break;
			case 'daily-ebus':
				this.dailyEbusChart = this.getChartObject({chartType: 'ColumnChart', getDataFromDailyDetails: true});
				this.chartTitle = 'Daily EBUs';
			break;
			case 'daily-ahrs':
				this.dailyAhrsChart = this.getChartObject({chartType: 'ColumnChart', getDataFromDailyDetails: true});
				this.chartTitle = 'Daily AHrs';
			break;
			case 'soc':
				this.socChart = this.getChartObject({chartType: 'LineChart'});
				this.chartTitle = 'SOC Chart';
			break;
			case 'max-temperature':
				this.maxTempChart = this.getChartObject({chartType: 'LineChart'});
				this.chartTitle = 'Max Temperature';
			break;
			case 'end-temperature':
				this.endTempChart = this.getChartObject({chartType: 'LineChart'});
				this.chartTitle = 'End Temperature';
			break;
			case 'end-voltage':
				this.endVoltageChart = this.getChartObject({chartType: 'LineChart'});
				this.chartTitle = 'End Voltage';
			break;
			case 'customized':
				this.chartTitle = '';
				this.dailyUsageChart = this.getChartObject({chartType: 'ColumnChart', getDataFromDailyDetails: true}, 'daily-usage');
				this.dailyEbusChart = this.getChartObject({chartType: 'ColumnChart', getDataFromDailyDetails: true}, 'daily-ebus');
				this.dailyAhrsChart = this.getChartObject({chartType: 'ColumnChart', getDataFromDailyDetails: true}, 'daily-ahrs');
				this.socChart = this.getChartObject({chartType: 'LineChart'}, 'soc');
				this.maxTempChart = this.getChartObject({chartType: 'LineChart'}, 'max-temperature');
				this.endTempChart = this.getChartObject({chartType: 'LineChart'}, 'end-temperature');
				this.endVoltageChart = this.getChartObject({chartType: 'LineChart'}, 'end-voltage');
			break;
		}
	}

	private getOptions(subTab) {
		let options = {};
		switch (subTab) {
			case 'daily-usage':
				options = this.getDailyUsageOptions();
			break;
			case 'daily-ebus':
				options = this.getDailyEbusOptions();
			break;
			case 'daily-ahrs':
				options = this.getDailyAhrsOptions();
			break;
			case 'soc':
				options = this.getSocOptions();
			break;
			case 'max-temperature':
				options = this.getMaxTemperatureOptions();
			break;
			case 'end-temperature':
				options = this.getEndTemperatureOptions();
			break;
			case 'end-voltage':
				options = this.getEndVoltageOptions();
			break;
		}
		return options;
	}

	getChartColumnsTypes(chartType) {
		let columns = [];
		switch(chartType) {
			case 'daily-usage':
				columns = [
					{col :'date', type: 'date'}, 
					{col: 'chargeDurationValue', type: 'timeofday'},
					{col:'dischargeDurationValue', type: 'timeofday'}, 
					{col :'idleDurationValue', type: 'timeofday'}
				];
			break;
			case 'daily-ebus':
				columns = [
					{col :'date', type: 'date'},
					{col: 'EBU', type: 'float'}
				];
				if(this.hasHorizontalLimit) {
					columns.push({col: this.horizontalLimitObject.text, type: 'passedvalue', value: this.horizontalLimitObject.value});
				}
			break;
			case 'daily-ahrs':
				columns = [
					{col :'date', type: 'date'},
					{col: 'chargeAH', type: 'float'}, 
					{col:'dischargeAHR', type: 'float'}
				];
				if(this.availableAhrsCheckBox) {
					var AHChartLimit =  0.8 * this.device.ahrcapacity;
					columns.push({col: 'passedvalue', type: 'passedvalue', value: AHChartLimit});
				}
			break;
			case 'soc':
				columns = [
					{col: 'date', type: 'date'},
					{col: 'soc', type: 'float'}
				];
			break;
			case 'max-temperature':
				columns = [
					{col: 'date', type: 'date'}
				];

				if (this.maxTempCheckBox) {
					// if there is any value >=  high temperature value , then add a horizontal line at  high temperature value
					let battertHighTempLimit = this.getBatteryHighTempLimit();
					columns.push({col: 'passedvalue', type: 'passedvalue', value: battertHighTempLimit});
				}
				
				columns.push({col: 'temp', type: 'float'});
			break;
			case 'end-temperature':
				columns = [
					{col: 'date', type: 'date'}
				];

				if (this.endTempCheckBox) {
					// if there is any value >=  high temperature value , then add a horizontal line at  high temperature value
					let battertHighTempLimit = this.getBatteryHighTempLimit();
					columns.push({col: 'passedvalue', type: 'passedvalue', value: battertHighTempLimit});
				}
				
				columns.push({col: 'endTemp', type: 'float'});
			break;
			case 'end-voltage':
				columns = [
					{col: 'date', type: 'date'},
					{col: 'endVoltage', type: 'float'}
				];

				if (this.inuseLimitCheckBox) {
					// add a horizontal line for  Deep Discharge Limit
					var deepDischargeLimit = 2;
					if (this.siteAlertsSettings.deep_discharge_limit) {
						deepDischargeLimit = this.siteAlertsSettings.deep_discharge_limit;
					}
					// endVoltageData.addColumn('number', 'Deep Discharge Limit');
					columns.push({col: 'passedvalue', type: 'passedvalue', value: deepDischargeLimit});
				}

				let filteredEndVoltageData = this.filterEndVoltage();

				var minEndVoltageData = Math.min.apply(null, this.getFilteredChartArr(filteredEndVoltageData, "endVoltage"));

				var PotentialWeakCellsLimit = 1.7;
				if (this.siteAlertsSettings.deep_use_limit) {
					PotentialWeakCellsLimit = this.siteAlertsSettings.deep_use_limit;
				}

				if (minEndVoltageData <= PotentialWeakCellsLimit) {
					this.showPotentialCheckBox = true;
					if (this.potentialCheckBox)
						columns.push({col: 'passedvalue', type: 'passedvalue', value: PotentialWeakCellsLimit});
				} else
					this.showPotentialCheckBox = false;

				// add a horizontal line at EQ voltage
				if (this.eqVoltageCheckBox && this.selectedTypes.indexOf('Charge') > -1)
					columns.push({col: 'passedvalue', type: 'passedvalue', value: this.device.eqvoltage});
			break;
		}
		return columns;
	}

	private getChartColumnsNames(chartType) {
		let columns: any = [];
		switch(chartType) {
			case 'daily-usage':
				columns = [
					{"type": 'date', "label" : 'Date'},
					{"type": 'timeofday', "label" : 'Charge Duration'},
					{"type": 'timeofday', "label" : 'Inuse Duration'},
					{"type": 'timeofday', "label" : 'Idle Duration'}
				];
			break;
			case 'daily-ebus':
				columns = [
					{"type": 'date', "label" : 'Date'},
					{"type": 'number', "label" : 'EBU'},
				];
				if(this.hasHorizontalLimit)
					columns.push({type: 'number', label: this.horizontalLimitObject.text});
			break;
			case 'daily-ahrs':
				columns = [
					{"type": 'date', "label" : 'Date'},
					{"type": 'number', "label" : 'Charge AHR'},
					{"type": 'number', "label" : 'Inuse AHR'},
				];
				if(this.availableAhrsCheckBox)
					columns.push({"type": 'number', "label" : 'Available AHR'});
			break;
			case 'soc':
				columns = [
					{"type": 'date', "label" : 'Date'},
					{"type": 'number', "label" : 'SOC'}
				];
			break;
			case 'max-temperature':
				columns = [{"type": 'date', "label" : 'Date'}];
				// if there is any value >=  high temperature value , then add a horizontal line at  high temperature value
				if (this.maxTempCheckBox)
					columns.push({"type": 'number', "label" : 'Max Temperature Limit'});
				
				columns.push({"type": 'number', "label" : 'Temperature'});
			break;
			case 'end-temperature':
				columns = [{"type": 'date', "label" : 'Date'}];
				// if there is any value >=  high temperature value , then add a horizontal line at  high temperature value
				if(this.endTempCheckBox)
					columns.push({"type": 'number', "label" : 'Max Temperature Limit'});
				
				columns.push({"type": 'number', "label" : 'Temperature'});
			break;
			case 'end-voltage':
				columns = [
					{"type": 'date', "label" : 'Date'},
					{"type": 'number', "label" : 'End Voltage'}
				];
				
				// add a horizontal line for  Deep Discharge Limit
				if(this.inuseLimitCheckBox)
					columns.push({"type": 'number', "label" : 'Deep Discharge Limit'});
				
				let filteredEndVoltageData = this.filterEndVoltage();

				var minEndVoltageData = Math.min.apply(null, this.getFilteredChartArr(filteredEndVoltageData, "endVoltage"));

				var PotentialWeakCellsLimit = 1.7;
				if (this.siteAlertsSettings.deep_use_limit)
					PotentialWeakCellsLimit = this.siteAlertsSettings.deep_use_limit;
			
				// if there is any value <=  Potential Weak Cells Limit value , then add a horizontal line at Potential Weak Cells Limit voltage
				if(minEndVoltageData <= PotentialWeakCellsLimit && this.potentialCheckBox)
					columns.push({"type": 'number', "label" : 'Potential Weak Cells Limit'});

				// add a horizontal line at EQ voltage
				if(this.eqVoltageCheckBox && this.selectedTypes.indexOf('Charge') > -1)
					columns.push({"type": 'number', "label" : 'EQ Voltage'});
			break;
		}
		return columns;
	}
	
	ngOnInit() {
		if (this.dailyDetailsReport.length == 0)
			return;

		this.drawChart();
	}

	private filterData(subTab, getDataFromDailyDetails) {
		let returnData = [];
		
		switch (subTab) {
			case 'end-voltage':
				returnData = this.filterEndVoltage();
			break;
			default:
				returnData = this.dailyDetailsReport;
				if(!getDataFromDailyDetails)
					returnData = this.eventsReport;
			break;
		}
		return returnData;
	}

	private getChartObject(chartOptions, subTab = null) {
		let getDataFromDailyDetails = chartOptions.getDataFromDailyDetails || false;
		let tab = this.activeSubTab;
		if(subTab !== null)
			tab = subTab;

		let config = this.getOptions(tab);

		let colArray = this.getChartColumnsTypes(tab);
		let _data = this.filterData(tab, getDataFromDailyDetails);

		let data = this.chartUtil.generateChartData(colArray, _data);
		data.unshift(this.getChartColumnsNames(tab));
		return {data, config};
	}

	generateDownloadImage($event, chartType) {
		let chartObj = $event.chart;
		this.printChartLinks[chartType] = this.chartUtil.getChartImageUri(chartObj);
	}

	printChart(chartType, hideBatterySummaryHeader = false) {
		var title = '',
			batterySummaryContent = '';
			
		if(!hideBatterySummaryHeader) {
			let titleObject = document.getElementsByClassName("chart_title")[0];
			title = titleObject.innerHTML;
			batterySummaryContent += document.getElementById('battery-summary').innerHTML;
		}

		if(chartType != 'customized') {
			batterySummaryContent+= '<img src="'+this.printChartLinks[chartType]+'" style="width:100%;"" border="0" alt="Null">';
		} else {
			for (var key in this.printChartLinks) {
				if(this.selectedCharts[key])
					batterySummaryContent+= '<div class="page-break-inside"><img src="'+this.printChartLinks[key]+'" style="width:100%;"" border="0" alt="Null"></div>';
			}
		}
		var content = title + batterySummaryContent;
		this.commonUtil.printContent(content);
	}

	private getDailyUsageOptions() {
		var ticksAxisV = [];
		for (var i = 0 ;i < 25; i++) {
			var tickValue = [i,0,0,0];
			var tickFormat = i + " h";
			var tick = {
				v: tickValue,
				f: tickFormat
			};
			ticksAxisV.push(tick);
		}

		return new ColumnChartConfig({ 
			LegendPosition: 'top',
			hAxis: {'title': 'Date', 'titleTextStyle': {'color': 'black', 'fontSize': 18}},
			colors: ["#428bca", "#EF980C", "#666666"],
			widgetHeight: 400,
			tooltip: {},
			yTitle: 'Number of Hours',
			vAxisTitleTextStyle: {'color': 'black', 'fontSize': 18},
			vAxisTicks: ticksAxisV,
			vAxisGridlines: {count: 24},
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
			explorer: {
				'axis': 'horizontal',
				'actions': ['dragToZoom', 'rightClickToReset'],
				maxZoomIn: 50.0,
				keepInBounds: true
			},
		});
	}

	private getDailyEbusOptions() {
		var hLineValue = 0;
		var hLineText = "";
		var EBUdata = this.getFilteredChartArr(this.dailyDetailsReport, "EBU");
		if(this.isBattviewMobile) {
			var total = 0;
			var count = 0;
			for(var i = 0; i < EBUdata.length; i++) {
				total += (+EBUdata[i]);
				if(+EBUdata[i] > 0) 
					count++;
			}
			hLineValue = total / count;
			hLineText = "Total Study Average";
		} else {
			switch (this.device.chargertype) {
				case 0:
					hLineValue = this.siteAlertsSettings.fast_ebu_limit || 1.6;
					break;
				case 1:
					hLineValue = this.siteAlertsSettings.conventional_ebu_limit || 1;
					break;
				case 2:
					hLineValue = this.siteAlertsSettings.opportunity_ebu_limit || 1.25;
					break;
				// case 3:
				// 	hLineValue = this.siteAlertsSettings.lithium_ebu_limit || 1.65;
				// 	break;
			}
			hLineText = "EBU Limit";
		}
		// The max value of the data value + 10% OR the battery EBU limit, whatever is greater.
		var maxDataEBU = Math.max.apply(null, EBUdata);
		var maxEBU = _.isNaN(maxDataEBU) ? 0 : maxDataEBU;
		// If there is any value >=  battery EBU limit , then add a horizontal line of EBU limit
		if (hLineValue >= maxEBU) {
			var maxEBUChartValue = hLineValue;
		} else {
			var maxEBUChartValue = maxDataEBU * 1.10;
			this.hasHorizontalLimit = true;
			this.horizontalLimitObject = {
				text: hLineText,
				value: hLineValue,
				type: 'passedvalue'
			};
		}

		let options = new ColumnChartConfig({
			LegendPosition: 'top',
			widgetHeight: 400,
			'hAxis': {'title': 'Date', 'titleTextStyle': {'color': 'black', 'fontSize': 18}},
			'colors': ['#3799db'],
			'explorer': { maxZoomIn: 50.0, keepInBounds: true, axis: 'horizontal', actions: ['dragToZoom', 'rightClickToReset']},
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
							stroke: '#000',
							strokeWidth: 2
					}
			},
			yTitle: 'EBU',
			tooltip: 0,
			vAxisTitleTextStyle: {'color': 'black', 'fontSize': 18},
			vAxisMaxValue: maxEBUChartValue,
			vAxisMinValue: 0,
			seriesType: 'bars',
			interpolateNulls: true
		});

		if (this.hasHorizontalLimit) 
			options = _.extend(options, {
				series: {
					1: {
						type: 'line',
						color: 'red',
					}
				}
			});

		return options;
	}

	getDailyAhrsOptions() {
		var maxChargeAH = Math.max.apply(null, this.getFilteredChartArr(this.dailyDetailsReport, "chargeAH"));
		maxChargeAH =  _.isNaN(maxChargeAH) ? 0 : maxChargeAH;
		
		var maxDischargeAHR = Math.max.apply(null, this.getFilteredChartArr(this.dailyDetailsReport, "dischargeAHR"));
		maxDischargeAHR =  _.isNaN(maxDischargeAHR) ? 0 : maxDischargeAHR;

		var maxAHValue = Math.max.apply(null, [maxChargeAH, maxDischargeAHR]);
		maxAHValue = maxAHValue * 1.1;

		var maxAHValueOnChart = maxAHValue > this.device.ahrcapacity ? maxAHValue : this.device.ahrcapacity;
		
		return new ColumnChartConfig({ 
			LegendPosition: 'top',
			widgetHeight: 400,
			'hAxis': {'title': 'Date', 'titleTextStyle': {'color': 'black', 'fontSize': 18}},
			vAxisTitleTextStyle: {'color': 'black', 'fontSize': 18},
			yTitle: 'AHR Value',
			vAxisMaxValue: maxAHValueOnChart,
			vAxisMinValue: 0,
			vAxisGridlines: {'count': 10},
			'colors': ["#428bca", "#EF980C", "#AAAEB0"],
			'explorer': {axis: 'horizontal', 'actions': ['dragToZoom', 'rightClickToReset'], 'maxZoomIn': 50.0, 'keepInBounds': true},		
			'seriesType': 'bars',
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
			'series': { 2: {'type': 'line', 'color': 'green'}},
		});
	}

	getSocOptions() {
		return new LineChartConfig({
			'legend': { 'position': 'top'},
			widgetHeight: 400,
			'hAxis': {'title': 'Date', 'titleTextStyle': {'color': 'black', 'fontSize': 18}},
			'colors': ["#3799db"],
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
			'vAxis': {
				'title': '%SOC',
				'titleTextStyle': {'color': 'black', 'fontSize': 18},
				'minValue': 0, 
				'maxValue': 100,
				'gridlines': {'count': 10},
				viewWindow: { min: 0, max: 100},
				viewWindowMode: "explicit"
			},
			explorer: {
				axis: 'horizontal',
				actions: ['dragToZoom', 'rightClickToReset'] ,
				maxZoomIn: 50.0,
				keepInBounds: true
			}
		});
	}

	getMaxTemperatureOptions() {
		// the max should be max data value + 10% Or the battry high temperature value , whatever is greater
		var maxReportTempData = Math.max.apply(null, this.getFilteredChartArr(this.eventsReport, "temp"));
		var maxDataLimit = maxReportTempData * 1.1;				

		var battertHighTempLimit = this.getBatteryHighTempLimit();

		var tempMaxChartLimit = maxDataLimit > battertHighTempLimit ? maxDataLimit : battertHighTempLimit;

		var chartColors = ["#3799db"];

		if (this.maxTempCheckBox) {
			// if there is any value >=  high temperature value , then add a horizontal line at  high temperature value
			chartColors = ['red', "#3799db"];
		}
		
		// the min will be TR temperature or min data value -10%, whatever is less.
		var minReportTempData = Math.min.apply(null, this.getFilteredChartArr(this.eventsReport, "temp"));
		var minReportTempLimit = minReportTempData * 0.9;
		var tempMinChartLimit = minReportTempLimit;
		
		// if there is any value <=  TR value , then add a horizontal line at  TR temperature value
		var trtemperature = this.device.trtemperature;
		var temperatureFormat = 'C';
		if(this.device.temperatureformat == 1) {
			trtemperature = this.commonUtil.fahToCel(trtemperature, true);
			temperatureFormat = 'F';
		}
		tempMinChartLimit = minReportTempLimit < trtemperature ? minReportTempLimit : trtemperature;
	
		var ticksAxisV = [];
		var increment = 10;
		for (var i = tempMinChartLimit ;i <= tempMaxChartLimit; i+= increment) {
			ticksAxisV.push(i);
		}

		var limit = tempMaxChartLimit;
		if (_.last(ticksAxisV) < tempMaxChartLimit) {
			var last = _.last(ticksAxisV) + increment;
			if (last) {
				limit = last;
				ticksAxisV.push(limit);
			}
		}

		return new LineChartConfig({
			'legend': { 'position': 'top'},
			widgetHeight: 400,
			'hAxis': {'title': 'Date', 'titleTextStyle': {'color': 'black', 'fontSize': 18}},
			'vAxis': {'title': 'Temperature ('+temperatureFormat+')', 'titleTextStyle': {'color': 'black', 'fontSize': 18},
				'minValue': tempMinChartLimit, 'maxValue': limit, 'ticks': ticksAxisV,
				'viewWindow': {min: tempMinChartLimit, max: limit}, viewWindowMode: "explicit"
			},
			'colors': chartColors,
			'explorer': { 'actions': ['dragToZoom', 'rightClickToReset'] ,maxZoomIn: 50.0, keepInBounds: true},
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
		});
	}

	getEndTemperatureOptions() {
		// the max should be max data value + 10% Or the battry high temperature value , whatever is greater
		var maxReportTempData = Math.max.apply(null, this.getFilteredChartArr(this.eventsReport, "endTemp"));
		var maxDataLimit = maxReportTempData * 1.1;				
		
		var BattertHighTempLimit = this.getBatteryHighTempLimit();

		var tempMaxChartLimit = maxDataLimit > BattertHighTempLimit ? maxDataLimit : BattertHighTempLimit;

		var chartColors = ["#3799db"];

		if (this.endTempCheckBox) {
			// if there is any value >=  high temperature value , then add a horizontal line at  high temperature value
			chartColors = ['red', "#3799db"];
		}
		
		// the min will be TR temperature or min data value -10%, whatever is less.
		var minReportTempData = Math.min.apply(null, this.getFilteredChartArr(this.eventsReport, "endTemp"));
		var minReportTempLimit = minReportTempData * 0.9;
		var tempMinChartLimit = minReportTempLimit;
		
		// if there is any value <=  TR value , then add a horizontal line at  TR temperature value
		var trtemperature = this.device.trtemperature;
		var temperatureFormat = 'C';
		if(this.device.temperatureformat == 1) {
			trtemperature = this.commonUtil.fahToCel(trtemperature, true);
			temperatureFormat = 'F';
		}
		tempMinChartLimit = minReportTempLimit < trtemperature ? minReportTempLimit : trtemperature;
	
		var ticksAxisV = [];
		var increment = 10;
		for (var i = tempMinChartLimit ;i <= tempMaxChartLimit; i+= increment) {
			ticksAxisV.push(i);
		}

		var limit = tempMaxChartLimit;
		if (_.last(ticksAxisV) < tempMaxChartLimit) {
			var last = _.last(ticksAxisV) + increment;
			if (last) {
				limit = last;
				ticksAxisV.push(limit);
			}
		}

		return new LineChartConfig({
			'legend': { 'position': 'top'},
			widgetHeight: 400,
			'hAxis': {'title': 'Date', 'titleTextStyle': {'color': 'black', 'fontSize': 18}},
			'vAxis': {'title': 'Temperature ('+temperatureFormat+')', 'titleTextStyle': {'color': 'black', 'fontSize': 18},
				'minValue': tempMinChartLimit, 'maxValue': limit, 'ticks': ticksAxisV,
				'viewWindow': {min: tempMinChartLimit, max: limit}, viewWindowMode: "explicit"
			},
			'colors': chartColors,
				'explorer': { 'actions': ['dragToZoom', 'rightClickToReset'] ,maxZoomIn: 50.0, keepInBounds: true},
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
		});
	}

	getEndVoltageOptions() {
		let filteredEndVoltageData = this.filterEndVoltage();

		// the max should be max data value + 0.1 Or the EQ voltage + 0.1
		var maxEndVoltageData = Math.max.apply(null, this.getFilteredChartArr(filteredEndVoltageData, "endVoltage"));
		var eqvoltage = this.device.eqvoltage;
		var chartMaxVoltage = eqvoltage > maxEndVoltageData ? eqvoltage + 0.1 : maxEndVoltageData  + 0.1;
		// the min will be Potential Weak Cells Limit voltage or min data value -0.1, whatever is less.
		var minEndVoltageData = Math.min.apply(null, this.getFilteredChartArr(filteredEndVoltageData, "endVoltage"));
		var PotentialWeakCellsLimit = 1.7;
		if (this.siteAlertsSettings.deep_use_limit) {
			PotentialWeakCellsLimit = this.siteAlertsSettings.deep_use_limit;
		}
		var chartMinVoltage = PotentialWeakCellsLimit < minEndVoltageData - 0.1 ? PotentialWeakCellsLimit : minEndVoltageData;
		
		// if there is any value <=  Potential Weak Cells Limit value , then add a horizontal line at Potential Weak Cells Limit voltage
		this.showPotentialCheckBox = (minEndVoltageData <= PotentialWeakCellsLimit);

		var ticksAxisV = [];
		for (var i = chartMinVoltage ;i <= chartMaxVoltage; i+=0.1) {
			ticksAxisV.push(i);
		}

		return new LineChartConfig({
			'legend': { 'position': 'top'},
			widgetHeight: 400,
			'hAxis': {
				'title': 'Date',
				'titleTextStyle' : {'color':'black', 'fontSize': 18},
				// the below 2 options have been disabled since those supported for a discrete axis only, and we have this error:
				//jsapi_compiled_ui_module.js:84 Error: <path> attribute d: Expected number, "MNaN,NaNL177.6197…".
				// slantedText:true,
				// slantedTextAngle:180
			},
			'vAxis': {'title': 'Voltage', 'titleTextStyle': {'color': 'black', 'fontSize': 18},
				'minValue': chartMinVoltage, 'maxValue': chartMaxVoltage, format:'0.0', 'ticks': ticksAxisV
			},
			'colors': ["#3799db", "#8A2BE2", "#EF980C"],
			'explorer': { 'keepInBounds': true, actions: ['dragToZoom', 'rightClickToReset']},
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
		});
	}

	getFilteredChartArr(arr, attr) {
		var data = arr.filter(( element ) => {
			 return (element !== undefined && element[attr] !== undefined);
		});
		return _.pluck(data, attr);
	}

	checkBoxChanged($event) {
		this.drawChart();
	}

	getBatteryHighTempLimit() {
		let battertHighTempLimit;
		if (this.siteAlertsSettings.override_temperature_limit) {
			battertHighTempLimit = this.siteAlertsSettings.temperature_limit;
		} else {
			battertHighTempLimit = this.device.batteryhightemperature;
		}

		if(this.device.temperatureformat == 1) {
			battertHighTempLimit = this.commonUtil.fahToCel(battertHighTempLimit, true);
		}
		return battertHighTempLimit;
	}

	filterEndVoltage() {
		let filteredEndVoltageData = [];
		this.eventsReport.forEach((item) => {
			if (this.selectedTypes.indexOf(item.type) > -1) {
				if(item.type == 'Charge') {
					item.endVoltage	= item.VPCendVoltage;
					if(this.isCompensatedVoltageCheckBox) {
						item.endVoltage	= item.compensationEndVoltage;
					}
				}
				filteredEndVoltageData.push(item);
			}
		});
		this.noChart = (filteredEndVoltageData.length == 0);
		return filteredEndVoltageData;
	}
	updateSelectedType($event) {
		let type = $event.currentTarget.value;
		let isChecked = $event.currentTarget.checked;
		if(isChecked) {
			if(this.selectedTypes.indexOf(type) === -1)
				this.selectedTypes.push(type);
		} else {
			let index = this.selectedTypes.indexOf(type);
			if(index > -1)
				this.selectedTypes.splice(index, 1);
		}
	}

	resetEndVoltageChart($event){
		let isChecked = $event.currentTarget.checked;
		if(!isChecked){
			this.selectedTypes = ['Charge'];
			this.filterEndVoltage();
			this.checkBoxChanged(null);
		}
	}
}