import { GoogleChartsBaseService } from './google-charts.base.service';
import { Injectable } from '@angular/core';
import { ColumnChartConfig } from '../Models/ColumnChartConfig';
import * as _ from 'underscore';

declare var google: any;

@Injectable()
export class GoogleColumnChartService extends GoogleChartsBaseService {

	constructor() { super(); }

	public BuildColumnChart(elementId: String, data: any[], config: ColumnChartConfig) : void {
		var chartFunc = () => {
			return new google.visualization.ColumnChart(document.getElementById(<string>elementId));
		};
		var options: any = {
			legend: config.legend,
			vAxis: {
				format: config.vAxisFormat,
				title: config.yTitle,
				minValue:config.vAxisMinValue || 0,
				maxValue:config.vAxisMaxValue || 0,
			},
		}
		if(config.title) {
			options.title = config.title;
		}
		if(config.tooltip) {
			options.tooltip = config.tooltip;
		}
		if (!_.isUndefined(config.vAxisMinValue)) {
			// to accept zero value
			options.vAxis['viewWindow'] = {min: config.vAxisMinValue};
		}
		if (config.vAxisTitleTextStyle) {
			options.vAxis['titleTextStyle'] = config.vAxisTitleTextStyle;
		}
		if (config.vAxisTicks) {
			options.vAxis['ticks'] = config.vAxisTicks;
		}
		if (config.vAxisGridlines) {
			options.vAxis['gridlines'] = config.vAxisGridlines;
		}

		if (config.vAxisViewWindowMaxValue) {
			if (options.vAxis['viewWindow']) {
				options.vAxis['viewWindow'].max = config.vAxisViewWindowMaxValue;
			} else {
				options.vAxis['viewWindow'] = {max: config.vAxisViewWindowMaxValue};
			}
		}

		if (config.hAxis)           options.hAxis           = config.hAxis;
		if (config.chartArea)       options['chartArea']    = config.chartArea;
		if (config.widgetHeight)    options['height']       = config.widgetHeight;
		if (config.widgetWidth)     options['width']        = config.widgetWidth;
		if (config.series)          options.series       	= config.series;

		if (config.colors) {
			options['colors'] = config.colors;
		}
		if(config.explorer) options['explorer'] = config.explorer;

		let dataIsDataTable = config.dataIsDataTable;
		this.buildChart(data, chartFunc, options, null, {dataIsDataTable: dataIsDataTable});
	}
}