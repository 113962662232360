import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DynamicResponseService } from '../dynamic-response.service';
import { SiteDashboardService } from '../../site-dashboard.service';
import *  as moment from 'moment';

@Component({
	selector: 'app-dr-event-log',
	templateUrl: './dr-event-log.component.html',
	styleUrls: ['./dr-event-log.component.css']
})
export class DrEventLogComponent implements OnInit {

	siteId: number = 0;
	customerId: number = 0;
	eventId: number = 0;
	eventLogs: any[] = [];
	allData: any[] = [];
	fromDateFmt = new Date(moment().subtract(1, 'days').startOf('day').unix() * 1000);
	toDateFmt = new Date(moment().startOf('day').unix() * 1000);
	chargers_record_only: boolean = false;
	
	constructor(
		private siteDashboardService: SiteDashboardService,
		private dynamicResponseService: DynamicResponseService,
		private route: ActivatedRoute
	) { }

	ngOnInit() {
		this.siteDashboardService.setActiveSubTab('event-logs');
		this.siteId = +this.route.parent.parent.snapshot.params.siteid || 0;
		this.customerId = +this.route.parent.parent.snapshot.params.customerid || 0;

		this.route.queryParams.subscribe( params => {

			this.eventId	= params['eventId'] || 0;
			let startDate	= params['startDate'] || '';
			let endDate		= params['endDate'] || '';

			if(startDate)
				this.fromDateFmt = new Date(startDate);
			
			if(endDate)
				this.toDateFmt = new Date(endDate);
		});
	}

	getKeys(data) {
		return Object.keys(data);
	}

	getEventLogs() {
		let fromDate	= Math.floor((new Date(new Date(this.fromDateFmt).setHours(0,0,0,0)).getTime()) /1000) ;
		let toDate		= Math.floor((new Date(new Date(this.toDateFmt).setHours(23,59,59,999)).getTime()) /1000);

		this.dynamicResponseService.getEventLogs(this.siteId, this.eventId, fromDate, toDate).subscribe((results: any) => {
			this.allData = results;
			this.filterData();
		});
	}

	filterData() {
		if(!this.chargers_record_only) {
			this.eventLogs = this.allData;
			return;
		}
		
		let filteredResults = [];
		this.allData.forEach((item) => {
			if(item.data.Chargers)
				filteredResults.push(item);
		});
		this.eventLogs = filteredResults;
	}
}