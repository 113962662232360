import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../../../../auth/user.service';
import { DevicesDashboardService } from '../../../devices/devices-dashboard.service';
import { ChargersDashboardService } from '../../chargers-dashboard/chargers-dashboard.service';

@Component({
	selector: 'app-charger-actions',
	templateUrl: './charger-actions.component.html',
	styleUrls: ['./charger-actions.component.css']
})
export class ChargerActionsComponent implements OnInit {

	@Input() charger: any = {};
	@Input() siteID: number = 0;
	@Input() customerID: number = 0;
	@Input() pageName: string;
	
	currentUser: any = {};
	manageDeviceBtnText = [];

	constructor(
		public userService: UserService,
		private devicesDashboard: DevicesDashboardService,
		private chargersDashboard: ChargersDashboardService
	) { }

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();

		let chargerEditBtnTxt	= ["g.manage", "device.charger"];
		let chargerReqBtnTxt	= ["charger.request_charger", "charger.management_change"];

		if(this.currentUser['allowToChangeDeviceAttribute'])
			this.manageDeviceBtnText = [chargerEditBtnTxt[0], chargerEditBtnTxt[1]];
		else
			this.manageDeviceBtnText = [chargerReqBtnTxt[0], chargerReqBtnTxt[1]];
	}

	restartCharger(id, mode: any = null) {
		var options = {'mode': mode};
		this.devicesDashboard.restartDevice('charger', id, options).subscribe();
	}

	refreshDeviceWiFiInfo(id) {
		this.devicesDashboard.refreshDeviceWiFiInfo(id, 'charger').subscribe();
	}

	addCommandToChargerQueue(id, command) {
		this.chargersDashboard.addCommandToChargerQueue(id, command).subscribe();
	}
}