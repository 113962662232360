import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActFirstService } from '../../act-first.service';
import { SiteDashboardService } from '../../../site-dashboard.service';
import { ActivatedRoute } from "@angular/router";
import * as _ from 'lodash';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import * as moment from 'moment';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';
import { SideMenuService } from '../../../../../shared/side-menu/side-menu.service';
import { Subscription } from 'rxjs';
import { Site } from '../../../../../shared/models/site.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-availability',
	templateUrl: './availability.component.html',
	styleUrls: ['./availability.component.css']
})
export class AvailabilityComponent implements OnInit, OnDestroy {

	apiResponse = {};
	data = {};
	gridColumns = [];
	gridRows = [];
	site: Site;
	config: ColumnChartConfig;
	chartObjs = {};
	groupCharts = [];
	dropDownOptions;
	selectedDateRange;
	availabilityText = "";
	siteGroups = [];
	images = {};
	currentSiteSub: Subscription = new Subscription();
	getReportSubscription: Subscription = new Subscription();
	keys: string[] = ['parked', 'charging', 'ready', 'relaxed', 'relaxing'];

	typesStatus: any = {
		'parked': false,
		'charging': false,
		'ready': true,
		'relaxed': true,
		'relaxing': true
	};

	hasAnyStatusEnabled: boolean = true;

	constructor( 
		public actFirstService: ActFirstService,
		private siteDashboardService: SiteDashboardService,
		private route: ActivatedRoute,
		private commonUtil: CommonUtil,
		private chartUtil: ChartUtil,
		private sideMenuService: SideMenuService,
		private translateService: TranslateService) { }

	ngOnInit() {
		
		this.dropDownOptions = [
			{
				value: "90",
				label: this.translateService.instant('time.last_90_days')
			},
			{
				value: "30",
				label: this.translateService.instant('time.last_30_days')
			},
			{
				value: "w",
				label: this.translateService.instant('time.last_week')
			},
			{
				value: "7",
				label: this.translateService.instant('time.last_7_days')
			},
			{
				value: "1",
				label: this.translateService.instant('time.yesterday')
			}
		];

		this.selectedDateRange = this.dropDownOptions[0].value;
		
		this.gridColumns = [
			{headerName: this.translateService.instant('device.truck_type'), field: "groupname"},
			{headerName: this.translateService.instant('device.status'), field: "innergroup", filter: false},
			{headerName: this.translateService.instant('device.average_count'), type: "number", field: "averaged_count_of_charger", filter: false},
			{headerName: "% "+this.translateService.instant('device.average_count'), type: "number", field: "percent_averaged_count_of_charger", filter: false, hide: true},
			{headerName: this.translateService.instant('device.max_count'), type: "number", field: "max_count_of_charger", filter: false},
			{headerName: "% "+this.translateService.instant('device.max_count'), type: "number", field: "percent_max_count_of_charger", filter: false, hide: true},
			{headerName: this.translateService.instant('device.min_count'), type: "number", field: "min_count_of_charger", filter: false},
			{headerName: "% "+this.translateService.instant('device.min_count'), type: "number", field: "percent_min_count_of_charger", filter: false, hide: true}
		];

		this.config = new ColumnChartConfig({
			isPercent: false,
			LegendPosition: 'top',
			colors: ['#f7d9d7','#3f69ec', '#109618'],
			vAxisMinValue: 0,
			vAxisMaxValue: 2
		});

		this.currentSiteSub = this.sideMenuService.currentSite.subscribe(siteInfo => {
			if(siteInfo.id) {
				this.site = siteInfo;

				this.getReportSubscription = this.actFirstService.getReport(this.site.id).subscribe(data=> {
					this.apiResponse = data;
					this.siteDashboardService.getSiteGroups(this.site.id).subscribe((response: any) => {
						this.siteGroups = _.map(response, "id");
						this.dateRangeChange(this.selectedDateRange);
					});
				});
			}
		});
	}

	dateRangeChange(value) {
		let fromDate, toDate, oneDaySeconds = 60*60*24;
		
		toDate = moment.utc().add(-1, 'day').endOf('day').unix();
		switch (value) {
			case "90":
				fromDate = toDate - (oneDaySeconds * 90);
				break;
			case "30":
				fromDate = toDate - (oneDaySeconds * 30);
				break;
			case "w":
				let daysDifference = 0;
				if (new Date().getDay() === 0) {
					daysDifference = 7;
				}
				toDate = moment.utc().day(0-daysDifference).endOf('day').unix();    // last Sunday
				fromDate = moment.utc().day(-6-daysDifference).startOf('day').unix();  // last Monday before Sunday
				break;
			case "7":
				fromDate = toDate - (oneDaySeconds * 7);
				break;
			case "1":
				fromDate = toDate - oneDaySeconds;
				break;
		}
		
		let fromDateSiteZone = this.commonUtil.getZoneTimestampFromUTC(this.site.zoneid, moment.utc(parseInt(fromDate) * 1000).unix(), true);
		let toDateSiteZone = this.commonUtil.getZoneTimestampFromUTC(this.site.zoneid, moment.utc(parseInt(toDate) * 1000).unix(), true);
		if (value == "1") {
			this.availabilityText = this.translateService.instant('g.availability') +' (' + moment.utc(fromDateSiteZone*1000).format('MM/DD/YYYY') + ')';
		} else {
			this.availabilityText = this.translateService.instant('g.availability') +' (' + moment.utc(fromDateSiteZone*1000).format('MM/DD/YYYY') + ' - ' + moment.utc(toDateSiteZone*1000).format('MM/DD/YYYY') + ')';
		}
		this.formatData(this.apiResponse, {fromDate: fromDate, toDate: toDate});
	}

	formatData(data, dateRange= {}) {
		
		this.data = {};
		this.gridRows = [];
		this.chartObjs = {};
		this.groupCharts = [];

		let filter = false;
		if (dateRange['fromDate'] && dateRange['toDate']) {
			filter = true;
		}

		let numberOfDays = 0;
		for (let item in data) {

			if (filter && (item < dateRange['fromDate'] || item > dateRange['toDate']) ) {
				continue;
			}
			numberOfDays++;
			for (let groupid in data[item]) {
				if (this.siteGroups.indexOf(parseInt(groupid)) == -1) {
					continue;
				}
				let groupname = data[item][groupid]['groupname'];
				
				for (let status in data[item][groupid]) {
					if (!this.data[groupid]) {
						this.data[groupid] = {
							groupname: groupname,
							id: groupid,
							count_of_chargers: 0,
							count_of_battviews: 0
						}
					}
					this.data[groupid].count_of_chargers += data[item][groupid].count_of_chargers;
					this.data[groupid].count_of_battviews += data[item][groupid].count_of_battviews;

					if (this.keys.indexOf(status) > -1 && !this.data[groupid][status] && status != "battery_faulted" && status != "charger_faulted" && status != "user_faulted" && status != "in_maintenance") {
						this.data[groupid][status] = {
							averaged_count_of_charger:0,
							max_count_of_charger:0,
							min_count_of_charger:100000,
							count_of_chargers: 0,
							count_of_battviews: 0
						};
					}
					if (status == "battery_faulted" || status == "charger_faulted" || status == "user_faulted" || status == "in_maintenance") {
						if (!this.data[groupid]['parked']) {
							this.data[groupid]['parked'] = {
								averaged_count_of_charger:0,
								max_count_of_charger:0,
								min_count_of_charger:100000
							};
						}
						this.data[groupid]['parked']['averaged_count_of_charger'] += data[item][groupid][status].averaged_count_of_charger;
						this.data[groupid]['parked']['max_count_of_charger'] = data[item][groupid][status].max_count_of_charger > this.data[groupid]['parked']['max_count_of_charger'] ? data[item][groupid][status].max_count_of_charger : this.data[groupid]['parked']['max_count_of_charger'];
						this.data[groupid]['parked']['min_count_of_charger'] = data[item][groupid][status].min_count_of_charger < this.data[groupid]['parked']['min_count_of_charger'] ? data[item][groupid][status].min_count_of_charger : this.data[groupid]['parked']['min_count_of_charger'];
					} else {
						if (this.keys.indexOf(status) > -1) {
							this.data[groupid][status]['averaged_count_of_charger'] += data[item][groupid][status].averaged_count_of_charger;
							this.data[groupid][status]['max_count_of_charger'] = data[item][groupid][status].max_count_of_charger > this.data[groupid][status]['max_count_of_charger'] ? data[item][groupid][status].max_count_of_charger : this.data[groupid][status]['max_count_of_charger'];
							this.data[groupid][status]['min_count_of_charger'] = data[item][groupid][status].min_count_of_charger < this.data[groupid][status]['min_count_of_charger'] ? data[item][groupid][status].min_count_of_charger : this.data[groupid][status]['min_count_of_charger'];
						}
					}
				}
			}
		}

		for (let group in this.data) {
			let chartRows = [];
			for (let innerGroup in this.data[group]) {
				if (this.keys.indexOf(innerGroup) == -1) continue;

				let count_of_chargers = this.data[group].count_of_chargers/numberOfDays ;
				let count_of_battviews = this.data[group].count_of_battviews/numberOfDays ;
				let percent_averaged_count_of_charger, percent_max_count_of_charger, percent_min_count_of_charger ;

				if(!count_of_battviews || count_of_battviews < count_of_chargers) {
					percent_averaged_count_of_charger = "N/A"
					percent_max_count_of_charger = "N/A"
					percent_min_count_of_charger = "N/A"
				} else {
					percent_averaged_count_of_charger = Math.round( (parseInt(this.data[group][innerGroup].averaged_count_of_charger) / count_of_battviews) * 100) + "%";
					percent_max_count_of_charger = Math.round( (parseInt(this.data[group][innerGroup].max_count_of_charger) / count_of_battviews) * 100) + "%";
					percent_min_count_of_charger = Math.round( (parseInt(this.data[group][innerGroup].min_count_of_charger) / count_of_battviews) * 100) + "%";
				}

				if(numberOfDays==0)
					numberOfDays=1;

				if(this.typesStatus[innerGroup]) {
				
					this.gridRows.push({
						groupname: this.data[group].groupname,
						innergroup: this.actFirstService.getGroupName(innerGroup),
						averaged_count_of_charger: Math.round(this.data[group][innerGroup].averaged_count_of_charger/numberOfDays),
						max_count_of_charger: this.data[group][innerGroup].max_count_of_charger,
						min_count_of_charger: this.data[group][innerGroup].min_count_of_charger,
						percent_averaged_count_of_charger: percent_averaged_count_of_charger,
						percent_max_count_of_charger: percent_max_count_of_charger,
						percent_min_count_of_charger: percent_min_count_of_charger
					});

					chartRows.push([
						this.actFirstService.getGroupName(innerGroup),
						Math.round(this.data[group][innerGroup].averaged_count_of_charger/numberOfDays),
						parseInt(this.data[group][innerGroup].max_count_of_charger),
						parseInt(this.data[group][innerGroup].min_count_of_charger)
					]);
				}
			}

			this.chartObjs[group] = {groupid : group, groupname: this.data[group].groupname}
			this.chartObjs[group].dataTable = [
				[
					{label: 'Status', id: 'status'},
					{label: 'Average Count', id: 'averaged_count_of_charger'},
					{label: 'Max Count', id: 'max_count_of_charger'},
					{label: 'Min Count', id: 'min_count_of_charger'},
				]
			];
			this.chartObjs[group].dataTable = this.chartObjs[group].dataTable.concat(chartRows);
		}

		this.groupCharts = _.values(this.chartObjs);
	}

	printChart(title, elementId) {
		let image = this.images[elementId];
		let printContent = '<h4>' + title + ' ' + this.availabilityText + '</h4><br><br><img src="'+image+'" style="width:100%;"" border="0" alt="Null">';

		this.commonUtil.print({
			appendPrintContent: printContent,
		});
	}

	generateImageURI(event) {
		let chart = event['chart'];
		let elementId = event['elementId'];
		let image = this.chartUtil.getChartImageUri(chart);
		this.images[elementId] = image;
	}

	ngOnDestroy() {
		this.getReportSubscription.unsubscribe();
		this.currentSiteSub.unsubscribe();
	}

	updateStatuses(key) {
		this.typesStatus[key] = !this.typesStatus[key];
		
		let hasAnyStatusEnabled = false;
		for (let i in this.typesStatus) {
			hasAnyStatusEnabled = hasAnyStatusEnabled || this.typesStatus[i];
		}
		this.hasAnyStatusEnabled = hasAnyStatusEnabled;
		this.dateRangeChange(this.selectedDateRange);
	}
}