<table class="table">
	<thead>
		<tr>
			<th *ngFor="let item of columns; let i = index" [ngClass]="{'col-md-2': i == 0}">
				{{item.label}}
			</th>
		</tr>
	</thead>
	<tbody>
		<ng-container *ngFor="let device of devices">
			<tr [ngClass]="getBgClass(device)">
				<td *ngFor="let item of columns">
					<ng-container *ngIf="item.key == 'url'; else elseExp">
						<i 
							*ngIf="showExpandButton(device)"
							class="fa fa-lg margin-right-sm text-info"
							[ngClass]="device.expanded ? 'fa-minus-square' : 'fa-plus-square'"
							aria-hidden="true"
							(click)="device.expanded = !device.expanded"></i>
						<span *ngIf="deviceType == 'battview'" >
							<a *ngIf="siteBattviews && siteBattviews[device.id] && !siteBattviews[device.id].isdeleted; else deletedBV" [routerLink]="['/',customerId, siteId, 'battviews', 'analytics', deviceUrlSection, device.id]">{{device.name ? device.name : device.batteryid}}</a>
							<ng-template #deletedBV>{{device.name ? device.name : device.batteryid}} - {{'device.replaced_or_deleted' | translate}}</ng-template>
						</span>
						<a *ngIf="deviceType == 'charger'" [routerLink]="['/',customerId, siteId, 'chargers', 'analytics', deviceUrlSection, device.id]">{{device.name ? device.name : device.chargerusername}}</a>
					</ng-container>
					<ng-template #elseExp>
						{{getItem(item, device)}}
					</ng-template>
				</td>
				
			</tr>
			<tr *ngIf="device.expanded" class="bg-info">
				<td class="padding-left-30" colspan="2">
					<div class="col-lg-6">
						<tags
							*ngIf="(deviceType=='charger' && siteChargers && siteChargers[device.id]) || (deviceType=='battview' && siteBattviews && siteBattviews[device.id])"
							[items]="siteTags"
							[deviceType]="deviceType"
							[deviceId]="device.id"
							[tag]="devicesTags[device.id]"
							[addTag]="false"
							[customerId]="customerId"
							[siteId]="siteId"
							[isACTuser]="isACTuser"
							(onUpdateTags)="onUpdateTagsEvent($event, device.id)"
						></tags>
					</div>
					<div *ngIf="deviceType=='charger' && siteChargers && siteChargers[device.id]">
						<div class="btn-group pointer" appDropdown [indirectOptionsList]="true">
							<i type="button" class="btn btn-default glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
							<app-charger-actions [charger]="siteChargers[device.id]" [siteID]="siteID" pageName="listing" [customerID]="customerId"></app-charger-actions>
						</div>
					</div>
					<div *ngIf="deviceType=='battview' && siteBattviews && siteBattviews[device.id]">
						<div class="btn-group pointer" appDropdown [indirectOptionsList]="true">
							<i type="button" class="btn btn-default glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
							<app-battview-actions pageName='listing' [battview]="siteBattviews[device.id]" [siteID]="siteId" [customerID]="customerId"></app-battview-actions>
						</div>
					</div>
					<div class="margin-top" *ngIf="siteBattviews && siteBattviews[device.id] && !siteBattviews[device.id].isdeleted">
						<notes [siteId]="siteId" [modelType]="deviceType" [modelId]="device.id" [sourcePage]="'service_page'"></notes>
					</div>
				</td>
				<td></td>
				<td></td>
			</tr>
		</ng-container>
	</tbody>
</table>