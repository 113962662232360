<div *ngIf="hasBattviews; else noBattviews">
	<div *ngFor="let modelType of battviewsListing | keys" class="col-md-12 boxs3">
		<div class="panel panel-default">
			<div class="panel-heading"><strong>{{modelType}} Batteries</strong></div>
		</div>
		<div *ngFor="let battery of battviewsListing[modelType]; let idx = index">
			<div class="row" *ngIf="(idx % 3) == 0 && battviewsListing[modelType][idx]">
				<div class="col-md-4" *ngIf="(idx % 3) == 0 && battviewsListing[modelType][idx]">
					<ng-container *ngTemplateOutlet="battviewItem;context:{battery: battviewsListing[modelType][idx]}" ></ng-container>
				</div>
				<div class="col-md-4" *ngIf="(idx % 3) == 0 && battviewsListing[modelType][idx+1]">
					<ng-container *ngTemplateOutlet="battviewItem;context:{battery: battviewsListing[modelType][idx+1]}" ></ng-container>
				</div>
				<div class="col-md-4" *ngIf="(idx % 3) == 0 && battviewsListing[modelType][idx+2]">
					<ng-container *ngTemplateOutlet="battviewItem;context:{battery: battviewsListing[modelType][idx+2]}" ></ng-container>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #battviewItem let-battery="battery">
	<div *ngIf="battery.isShow" [ngClass]="getBgClass(battery)">
		<div class="text-center">
			<label class="checkbox tcenter p-b-5" for="{{battery.id}}">
			<input type="checkbox" [(ngModel)]="battery.selected" name="group" (ngModelChange)="deviceChecked($event)" />
			</label>
		</div>
		<div class="text-center margin-top-lg">
			<div class="btn-group pointer" appDropdown *ngIf="currentSite.act_intelligent_type != 0">
				<a type="button" style="display: inline-block;" [pTooltip]="battery.timespanTextArr.join('\n')" tooltipPosition="top" tooltipStyleClass="device-changes-log">
					<p [ngClass]="battery.bvStatus"></p>
				</a>
				<ul class="z-index-10k dropdown-menu" role="menu">
					<li><a [routerLink]="['/', customerId, siteId, 'battviews', 'settings']" [queryParams]="{battviewsIDs: battery.id}">Settings</a></li>
					<li><a [routerLink]="['/', customerId, siteId, 'battviews', 'analytics', 'battery-summary', battery.id]">Analytics</a></li>
					<li *ngIf="userService.hasAccessFunction('access_battview_quick_view') && battery.actviewenabled"><a [routerLink]="['/', customerId, siteId, 'battviews', 'quick-view', battery.id]">Quick View</a></li>

					<li class="dropdown-submenu" appDropdown [indirectOptionsList]="true">
						<a tabindex="-1" *ngIf="!userService.hasAccessFunction('no_manage_devices') || (userService.hasAccessFunction('restart_devices')) || (userService.hasAccessFunction('act_admin_device_management') && battery.enableplc) || (!battery.isCalibrated && battery.actviewenabled && battery.enableplc && battery.firmwareversion >= 2.12 && userService.hasAccessFunction('remote_battview_calibration'))">
							<span>Actions
								<i class="fa fa-caret-right margin-left-sm"></i>
							</span>
						</a>
						<app-battview-actions pageName='listing' [battview]="battery" [siteID]="siteId" [customerID]="customerId"></app-battview-actions>
					</li>
					<li class="dropdown-submenu" appDropdown>
						<a tabindex="-1">
							<span>Notes
							<i class="fa fa-caret-right margin-left-sm"></i>
							</span>
						</a>
						<ul class="dropdown-menu" role="menu">
							<li>
								<a [routerLink]="['/', customerId, siteId, 'battviews', 'notes', battery.id]">View Notes</a>
							</li>
							<li class="pointer">
								<a>
									<notes
										[siteId]="siteId"
										modelType="battview"
										[modelId]="battery.id"
										[isDropDown]="true"
										[isAddNoteOnly]="true"
									></notes>
								</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="btn-group pointer" *ngIf="currentSite.act_intelligent_type == 0" [routerLink]="['/', customerId, siteId, 'battviews', 'analytics', 'battery-summary', battery.id]">
				<i type="button" style="display: inline-block;">
					<p [ngClass]="battery.bvStatus"></p>
				</i>
			</div>
		</div>
		<div class="text-center">
			<p class="sm-font tcenter">{{battery.batteryid}}</p>
			<p class="sm-font tcenter">{{battery.serialnumber}}</p>
		</div>
		<div class="text-center">
			<div class="btn-group p-5">
				<div class="listing-tags" *ngFor="let tag of battery.tags">
					<button class="btn btn-sm ng-binding" [ngClass]="{'btn-danger': tag.sharingoption == 'private', 'btn-info': tag.sharingoption == 'group', 'btn-default': tag.sharingoption == 'public', 'replacement-tag': tag.keep_in_replacement && currentUser.isACTuser}">
					{{tag.tagname}}
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #noBattviews>
	<div class="panel panel-default">
		<div class="panel-body">
			<strong>No Battview Registered!</strong>
		</div>
	</div>
</ng-template>