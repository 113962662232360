import { Component, OnInit, Input, Output, EventEmitter, OnChanges} from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit, OnChanges {

    constructor() { }

    // array of all items to be paged
    @Input() allItems: any[];
    @Input('count') itemsCountInPage: number = 20;

    // pager object
    pager: any = {};

    // paged items
    pagedItems: any[];
    @Output() onPageChanged = new EventEmitter<any[]>(true);

    ngOnInit() {
    }

    ngOnChanges(changes) {
        this.setPage(1);
    }

    setPage(page: number = 1) {
        if (page < 1 || page > this.pager.totalPages || this.allItems.length === 0) {
            this.onPageChanged.emit([]);
            return;
        }

        // get pager object from service
        this.pager = this.getPager(page);

        // get current page of items
        this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
        this.onPageChanged.emit(this.pagedItems);
    }

    private getPager(currentPage: number = 1) {
        let totalItems = this.allItems.length;
        let pageSize = (+this.itemsCountInPage);

        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        let startPage: number, endPage: number;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = [];
        for(let i = startPage; i < endPage + 1; i++) {
            pages.push(i);
        }

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }
}