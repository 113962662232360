import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ControlContainer, NgForm } from '@angular/forms';
import { AdminService } from '../../admin.service';
import { NavigationService } from '../../../shared/services/navigation.service';
import { UserService } from '../../../auth/user.service';
import { SiteEditComponent } from '../../sites/site-edit/site-edit.component';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import * as moment from 'moment';

@Component({
	selector: 'app-manage-device-dates-page',
	templateUrl: './manage-device-dates-page.component.html',
	styleUrls: ['./manage-device-dates-page.component.css'],
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class ManageDeviceDatesPageComponent implements OnInit, OnDestroy {

	@ViewChild('appSiteEdit') appSiteEdit: SiteEditComponent;

	deviceIDs = [];
	device: any[] = [];
	deviceType: String;
	currentUser: any = {};

	shippingdate: any = null;
	warrantyperiod: number = null;
	isWarrantyVoid: boolean = false;
	isEditingSingleDevices: boolean = false;

	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute,
		private navigation: NavigationService,
		private userService: UserService,
		private notificationMessage: NotificationMessageService,
		private breadcrumb: BreadcrumbService,
		private router: Router
	){}

	ngOnInit() {
		this.deviceIDs = this.route.snapshot.params['deviceIDs'].split(',');
		this.deviceType = this.route.snapshot.params['type'];
		this.currentUser = this.userService.getCurrentUser();

		if(
			!this.userService.hasAccessFunction('accounting') &&
			!this.userService.hasAccessFunction('act_intelligent_management') &&
			!this.userService.hasAccessFunction('admin_act_intelligent') &&
			!this.userService.hasAccessFunction('users_function_management')
		)
			this.router.navigate(['/unauthorized']);

		this.breadcrumb.setBreadcrumb([{'label': 'Devices Dates Management', 'url': ''}]);

		if(this.deviceIDs.length == 1)
			this.isEditingSingleDevices = true;

		this.adminService.getDeviceDates(this.deviceIDs, this.deviceType).subscribe(
			(data: any) => {
				let formattedData = [];
				if(data.length > 0){ // first initialization
					this.shippingdate   = moment.utc(data[0].shippingdate); 
					this.warrantyperiod = data[0].warrantyperiod;
					this.isWarrantyVoid = data[0].isWarrantyVoid;
				}

				data.forEach((item) => {
					let tempObj:any = {};
					tempObj.SN								= item.SN;
					tempObj.id								= item.id;
					tempObj.shippingdate					= moment(moment.utc(item.shippingdate).format("YYYY-MM-DD")).toDate();
					tempObj.warrantyperiod					= item.warrantyperiod;

					if(!this.shippingdate || (this.shippingdate.unix() != moment.utc(item.shippingdate).unix()))
						this.shippingdate = null;

					if(this.warrantyperiod != item.warrantyperiod)
						this.warrantyperiod = null;

					if(this.isWarrantyVoid != item.isWarrantyVoid)
						this.isWarrantyVoid = false;

					if(this.isEditingSingleDevices){
						tempObj.createdat						= new Date(item.createdat);
						tempObj.commissiondate					= new Date(item.commissiondate);
						tempObj.assignedtoproductionlinedate	= new Date(item.assignedtoproductionlinedate);
					}

					formattedData.push(tempObj);
				});
				if(this.shippingdate != null)
					this.shippingdate = moment(this.shippingdate.format("YYYY-MM-DD")).toDate();

				this.device	= formattedData;
			}
		);
	}

	saveDeviceDates() {
		if(this.shippingdate != null){
			this.device.forEach((item) => {
				item.shippingdate = this.shippingdate;
			});
		}
		if(this.warrantyperiod != null){
			this.device.forEach((item) => {
				item.warrantyperiod = this.warrantyperiod;
			});
		}
		this.adminService.saveDeviceDates(this.deviceType, this.deviceIDs, this.device).subscribe(
			(data: any) => {
				this.goBack();
			}
		);
	};

	goBack() {
		this.navigation.goBack();
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
