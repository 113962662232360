import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../../../../auth/user.service';
import { AdminService } from '../../../admin.service';
import { NotificationMessageService } from '../../../../shared/notification-message/notification-message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonDataService } from '../../../../shared/services/common-data.service';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { BreadcrumbService } from '../../../../shared/breadcrumb/breadcrumb.service';
import * as lo from 'lodash';

@Component({
  selector: 'app-access-permissions',
  templateUrl: './access-permissions.component.html',
  styleUrls: ['./access-permissions.component.css']
})
export class AccessPermissionsComponent implements OnInit, OnDestroy {

	tab: string = 'Generic';
	selectedType = {};
	defaultPermissions = {};
  permissionFields: any;
  access: any;
  accessOptions: string[];
  tempAccess: {
    enable: boolean,
    time: number,
    unit: string
  };
  userId: any;
  constructor(
    private userService: UserService,
    private adminService: AdminService,
    private notificationMessage: NotificationMessageService,
    private route: ActivatedRoute,
    private commonData: CommonDataService,
    private navigation: NavigationService,
    private router: Router,
    private breadcrumb: BreadcrumbService
  ) {
	this.permissionFields = this.commonData.accessPermissionFields;
	this.selectedType = this.permissionFields[this.tab];
    this.tempAccess = {
      enable: false,
      time: null,
      unit: null
    };
    this.accessOptions	= ['noAccess', 'readOnly', 'write'];
    this.userId = this.route.snapshot.params.userId;
    if(this.userId != parseInt(this.userId)) {
      this.notificationMessage.setMessage('Invalid User');
    } else {
      this.getUserPermissions();
    }
  }

  ngOnInit() {
    this.breadcrumb.setBreadcrumb([
      {label: "Users", url: "/users"},
      {label: "Access Permissions", url: ''}
    ]);
  }

  getKeys(obj) {
    return Object.keys(obj);
  }
  updateUserPermissions(){
    if(confirm('Please note that this action will override any other temporary permissions for this user!')) {
      this.adminService.updateUserPermissions(this.userId, this.access, this.tempAccess).subscribe(
        response => {
          this.notificationMessage.setMessage(response, 'success', 10000, true);
          this.goBack();
        }
      );
    }
  }
  goBack(){
    this.navigation.goBack(['/users']);
  }

  getUserPermissions() {
    this.adminService.getUserPermissions(this.userId).subscribe(
      response => {
		this.access = response['userPermissions'];
		this.defaultPermissions = response['defaultPermissions'];
		let diff = lo.difference(Object.keys(this.access), Object.keys(this.defaultPermissions));
		if(diff.length)
			console.log(diff);
      }
    );
  }

  ngOnDestroy() {
		this.breadcrumb.hide();
	}
}