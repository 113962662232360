import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UserService } from '../../../../../auth/user.service';
import { DevicesDashboardService } from '../../../devices/devices-dashboard.service';
import { DeviceManagementService } from '../../../../../device-management/device-management.service';

@Component({
	selector: 'app-battview-actions',
	templateUrl: './battview-actions.component.html',
	styleUrls: ['./battview-actions.component.css']
})
export class BattviewActionsComponent implements OnInit {

	@Input() battview: any = {};
	@Input() siteID: number = 0;
	@Input() customerID: number = 0;
	@Input() pageName: string;

	@ViewChild("resetConfirmModal") resetConfirmModal;

	currentUser: any = {};
	manageDeviceBtnText = [];

	constructor(
		public userService: UserService,
		private deviceManagemnt: DeviceManagementService,
		private devicesDashboard: DevicesDashboardService,
	) { }

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();

		let battviewEditBtnTxt	= ["Manage", "BATTView"];
		let battviewReqBtnTxt	= ["Request BATTView", "management change"];

		if(this.currentUser['allowToChangeDeviceAttribute'])
			this.manageDeviceBtnText = [battviewEditBtnTxt[0], battviewEditBtnTxt[1]];
		else
			this.manageDeviceBtnText = [battviewReqBtnTxt[0], battviewReqBtnTxt[1]];
	}

	restartBattview(id) {
		this.devicesDashboard.restartDevice('battview', id, {}).subscribe();
	}

	refreshDeviceWiFiInfo(id) {
		this.devicesDashboard.refreshDeviceWiFiInfo(id, 'battview').subscribe();
	}

	addCommandToBattviewQueue(id, command) {
		this.deviceManagemnt.addCommandToBattviewQueue(id, command).subscribe();
	}

	resetBattviewHistory(bfdid) {
		this.deviceManagemnt.resetBattviewHistory(bfdid).subscribe((data)=>{
			window.location.reload();
		});
	}
}