<app-site-widget
    title="Idle Times"
    elementId="truck_missed_connection"
    chartType="column"
    widgetClass="height-600"
    [siteWidgetOptions]="{hasDate: true, isSitePerformanceWidget: true, isMaterialChart: true, countOfMaterialColumns: 4, isTrucks: true}"
    [widgetOptions]="{}"
    [data]="data"
    [config]="config"
    [tableColumns]="tableColumns"
    [siteId]="siteId"
    [customerId]="customerId"
    [customerName]="customerName"
    [siteName]="siteName"
></app-site-widget>