<div *ngIf="hasDevices" class="boxs3">
	<label *ngIf="hideAddDeviceTagBox && hideAddDeviceGroupBox">
		<input type="checkbox" [(ngModel)]="selectedAll" (ngModelChange)="selectAll($event)" />Select All
	</label>
	<div class="boxs3" *ngIf="!hideAddDeviceTagBox">
		<div class="col-md-2">
			<span class="sdax">Add Tags</span>
		</div>
		<div class="col-md-4">
			<tags
				[items]="siteTags"
				[tag]="commonTags"
				[customerId]="customerId"
				[deviceType]="deviceType"
				[siteId]="siteId"
				[isACTuser]="currentUser.isACTuser"
				[deviceId]="getSelectedDevices()"
				(onUpdateTags)="siteTagAdded($event)"
				(onAddTagFault)="tagAddedFault($event)"
			></tags>
		</div>
		<button class="btn btn-md btn-default" (click)="hideAddDeviceTagBox = true">
		Done
		</button>
	</div>
	<div class="boxs3" *ngIf="!hideAddDeviceGroupBox">
		<div class="col-md-2">
			<span class="sdax">Attach to Truck Type</span>
		</div>
		<div class="col-md-4">
			<ng-select
				[multiple]="false"
				[addTag]="false"
				[items]="siteGroups"
				(change)="addGroup($event)"
				[hideSelected]="false"
				placeholder="Assign to Truck Type"
				bindLabel="text"
				bindValue="id"
				[clearable]="false"
				[(ngModel)]="commonGroup"
			></ng-select>
		</div>
		<button class="btn btn-md btn-default" (click)="hideAddDeviceGroupBox = true">Done</button>
	</div>
	<button class="btn btn-md btn-default" [disabled]="countOfDevicesChecked <= 0" (click)="goToSettingsPage()" *ngIf="currentSite.act_intelligent_type != 0 && hideAddDeviceTagBox && hideAddDeviceGroupBox">
		Settings
	</button>
	<app-manage-device-btn
		*ngIf="hideAddDeviceTagBox && hideAddDeviceGroupBox && !userService.hasAccessFunction('no_manage_devices')"
		[selectedDevices]="getSelectedDevices()"
		[type]="deviceType"
		[hasBottomMargin]="false"
	></app-manage-device-btn>
	<button class="btn btn-md btn-default" (click)="restartDevices()" [disabled]="countOfDevicesChecked <= 0" *ngIf="hideAddDeviceTagBox && hideAddDeviceGroupBox && userService.hasAccessFunction('restart_devices') && currentSite.act_intelligent_type != 0">
		Restart {{deviceType == 'battview' ? 'Battviews' : (deviceType == 'truckview' ? 'Truckviews' : 'Chargers')}}
	</button>
	<button class="btn btn-md btn-default" (click)="showAddTagBox()" *ngIf="hideAddDeviceTagBox && hideAddDeviceGroupBox">
		Add Tag
	</button>
	<button class="btn btn-md btn-default" (click)="deleteTags()" *ngIf="hideAddDeviceTagBox && hideAddDeviceGroupBox">
		Delete Tags
	</button>
	<button class="btn btn-md btn-default" (click)="showAddGroupBox()" *ngIf="currentSite.act_first_enabled && hideAddDeviceTagBox && hideAddDeviceGroupBox">
		Assign to Truck Type
	</button>
</div>

<simple-widget [title]="'Site Tags'" *ngIf="siteTags.length > 0">
	<span *ngFor="let tag of siteTags">
		<span class="btn-group pointer p-5">
			<button class="btn btn-md" (click)="filterTags(tag)" [ngClass]="{'active': tag.isActive, 'btn-danger': tag.sharingoption == 'private', 'btn-info': tag.sharingoption == 'group', 'btn-default': tag.sharingoption == 'public'}">
				{{tag.tagname}}
			</button>
		</span>
	</span>
</simple-widget>

<simple-widget [title]="'Site Truck Types'" *ngIf="siteGroups.length > 0">
	<span *ngFor="let group of siteGroups">
		<span class="btn-group pointer p-5">
			<button class="btn btn-md btn-default" (click)="filterGroups(group)" [ngClass]="{'active': group.isActive}">
				{{group.text}}
			</button>
		</span>
	</span>
</simple-widget>

<simple-widget [title]="'Search Chargers'" [isCollapsed]="searchChargersIsCollapse" *ngIf="deviceType == 'charger' && hasDevices" (onToggleCollapse)="searchToggle($event, 'chargers')">
	<div class="p-5 row form-group">
		<label for="batteryType" class="col-md-2">Battery Type</label>
		<select class="form-control inline-form-control col-md-4" name="batteryType" [(ngModel)]="filter.batteryType" (ngModelChange)="filterData()">
			<option [value]="'all'">All</option>
			<option *ngFor="let batteryType of devicesDashboard.batteryTypes" [value]="batteryType.id">{{batteryType.text}}</option>
		</select>
	</div>
	<div class="p-5 row form-group">
		<label for="chargerType" class="col-md-2">Charger Type</label>
		<select class="form-control inline-form-control col-md-4" name="chargerType" [(ngModel)]="filter.chargerType" (ngModelChange)="filterData()">
			<option [value]="'all'">All</option>
			<option *ngFor="let chargerType of devicesDashboard.chargerTypes" [value]="chargerType.id">{{chargerType.text}}</option>
		</select>
	</div>
	<div class="p-5 row form-group">
		<label for="multiVoltage" class="col-md-2">Multi Voltage</label>
		<label class="row col-md-2"><input type="radio" [(ngModel)]="filter.multiVoltage" name="multiVoltage" class="form-control inline-form-control" [value]="true" (ngModelChange)="filterData()" /> Enable </label>
		<label class="row col-md-2"><input type="radio" [(ngModel)]="filter.multiVoltage" name="multiVoltage" class="form-control inline-form-control" [value]="false" (ngModelChange)="filterData()" /> Disable </label>
	</div>
	<div class="p-5 row form-group" *ngIf="filter.multiVoltage == false && filter.batteryType == 1">
		<label for="numberOfCells" class="col-md-2">Number of Cells</label>
		<select class="form-control inline-form-control col-md-4" name="numberOfCells" [(ngModel)]="filter.numberOfCells" (ngModelChange)="filterData()">
			<option [value]="'all'">All</option>
			<option *ngFor="let n of numberOfCells" [value]="n">{{n}}</option>
		</select>
	</div>
	<div class="p-5 row form-group" *ngIf="filter.multiVoltage == false && (filter.batteryType == 0 || filter.batteryType == 2)">
		<label for="batteryVoltage" class="col-md-2">Battery Voltage</label>
		<select class="form-control inline-form-control col-md-4" name="batteryVoltage" [(ngModel)]="filter.batteryVoltage" (ngModelChange)="filterData()">
			<option [value]="'all'">All</option>
			<option *ngFor="let b of batteryVoltages" [value]="b">{{b}}</option>
		</select>
	</div>
	<div class="p-5 row form-group" *ngIf="currentSite.act_intelligent_type != 0">
		<label for="chargerConnectivity" class="col-md-2">Charger Status</label>
		<select class="form-control inline-form-control col-md-4" name="chargerConnectivity" [(ngModel)]="filter.chargerConnectivity" (ngModelChange)="filterData()">
			<option [value]="'all'">All</option>
			<option *ngFor="let connectivity of connectivityStatusList" [value]="connectivity.value">{{connectivity.label}}</option>
		</select>
	</div>
</simple-widget>


<simple-widget [title]="'Search Battviews'" [isCollapsed]="searchBattviewsIsCollapse" *ngIf="deviceType == 'battview' && hasDevices && currentSite.act_intelligent_type != 0" (onToggleCollapse)="searchToggle($event, 'battviews')">
	<div class="p-5 row form-group">
		<label for="battviewConnectivity" class="col-md-2">Battview Connectivity</label>
		<select class="form-control inline-form-control col-md-4" name="battviewConnectivity" [(ngModel)]="filter.battviewConnectivity" (ngModelChange)="filterData()">
			<option [value]="'all'">All</option>
			<option *ngFor="let connectivity of connectivityStatusList" [value]="connectivity.value">{{connectivity.label}}</option>
		</select>
	</div>
	<div class="p-5 row form-group" 
		*ngIf="userService.hasAccessFunction('display_faults_in_listing_page')">
		<label for="battviewFaults" class="col-md-2">Battview Faults</label>
		<select class="form-control inline-form-control col-md-4" name="battviewFaults" [(ngModel)]="filter.battviewHasFaults" (ngModelChange)="filterData()">
			<option value="all">All</option>
			<option value="1">Has Battview Faults</option>
			<option value="0">No Battview Faults</option>
		</select>
	</div>
</simple-widget>

<app-chargers-listing #chargerlisting
	*ngIf="deviceType == 'charger'"
	(siteTagsChanged)="siteTagsChanged($event)"
	(deviceTagsChanged)="deviceTags = $event[0]; deviceGroups = $event[1];"
	(refreshFilter)="filterData(true)"
	(chargersListingReady)="onListingReady($event)"
	(deviceIsChecked)="deviceChecked($event)"
	[currentUser]="currentUser"
	[currentSite]="currentSite"
	[customerId]="customerId"
	[siteId]="siteId"
></app-chargers-listing>

<app-battviews-listing #battviewListing
	*ngIf="deviceType == 'battview'"
	(siteTagsChanged)="siteTagsChanged($event)"
	(deviceTagsChanged)="deviceTags = $event[0]; deviceGroups = $event[1];"
	(refreshFilter)="filterData(true)"
	(battviewsListingReady)="onListingReady($event)"
	(deviceIsChecked)="deviceChecked($event)"
	[currentUser]="currentUser"
	[currentSite]="currentSite"
	[customerId]="customerId"
	[siteId]="siteId">
</app-battviews-listing>

<app-truckviews-listing
	*ngIf="deviceType == 'truckview'"
	(siteTagsChanged)="siteTagsChanged($event)"
	(deviceTagsChanged)="deviceTags = $event[0]; deviceGroups = $event[1];"
	(refreshTagsFilter)="filterTags('REFRESH')"
	(truckviewsListingReady)="onListingReady($event)"
	(deviceIsChecked)="deviceChecked($event)"
	[currentUser]="currentUser"
	[currentSite]="currentSite"
	[customerId]="customerId"
	[siteId]="siteId">
</app-truckviews-listing>