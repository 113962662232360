<div class="boxs3">
	<div class="row">
		<button 
			class="btn"
			[ngClass]="activeHistoryPage == 'historical-log' ? 'btn-primary' : 'btn-default'"
			(click)="activateHistoricalPage('historical-log')">{{'act.historical_log' | translate}}</button>
		<button 
			class="btn" 
			[ngClass]="activeHistoryPage == 'mispicks' ? 'btn-primary' : 'btn-default'"
			(click)="activateHistoricalPage('mispicks')">{{'act.mis_picks_page' | translate}}</button>
		<button 
			class="btn"
			[ngClass]="activeHistoryPage == 'picks'? 'btn-primary' : 'btn-default'"
			(click)="activateHistoricalPage('picks')">{{'act.picks_page' | translate}}</button>
	</div>

	<div>
		<app-historical-log *ngIf="activeHistoryPage == 'historical-log'"></app-historical-log>
		<app-mispicks *ngIf="activeHistoryPage == 'mispicks'"></app-mispicks>
		<app-picks *ngIf="activeHistoryPage == 'picks'"></app-picks>
	</div>
</div>
