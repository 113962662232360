export class LineChartConfig {
	yTitle;
	xTitle;
	vAxisFormat;
	vAxisMinValue;
	vAxisMaxValue;
	hAxis;
	vAxis;
	vAxes;
	chartArea;
	widgetHeight:number = 200;
	widgetWidth;
	explorer: any;
	pointSize: any;
	series: any;
	tooltip: any;
	colors: string[];
	legend: {position: string} = {position: 'none'};
	useDateFormat: boolean = false;
	dataIsDataTable: boolean = false;
	formatFunction: any = () => {};

	constructor(options: any = {}) {
		if(options.yTitle) this.yTitle = options.yTitle;
		if(options.xTitle) this.xTitle = options.xTitle;
		if(options.vAxisMinValue) this.vAxisMinValue = options.vAxisMinValue;
		if(options.vAxisMaxValue) this.vAxisMaxValue = options.vAxisMaxValue;
		if(options.colors) this.colors = options.colors;
		if(options.hAxis) this.hAxis = options.hAxis;
		if(options.isPercent) this.vAxisFormat = "percent";
		if(options.vAxisFormat) this.vAxisFormat = options.vAxisFormat;
		if(options.vAxis) this.vAxis = options.vAxis;
		if(options.vAxes) this.vAxes = options.vAxes;
		if(options.widgetHeight) this.widgetHeight = options.widgetHeight;
		if(options.widgetWidth) this.widgetWidth = options.widgetWidth;
		if(options.chartArea) this.chartArea = options.chartArea;
		if(options.tooltip) this.tooltip = options.tooltip;
		if(options.legend) this.legend = options.legend;
		if(options.explorer) this.explorer = options.explorer;
		if(options.pointSize) this.pointSize = options.pointSize;
		if(options.series) this.series = options.series;
		if(options.dataIsDataTable) this.dataIsDataTable = options.dataIsDataTable;
		if(options.useDateFormat !== undefined) this.useDateFormat = options.useDateFormat;
		if(options.LegendPosition) this.legend = {position: options.LegendPosition};
	}
}