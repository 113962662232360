export class BarChartConfig {
    yTitle;
    xTitle;
    chartArea;
    isStacked;
    height:number = 200;
    tooltip: any = {trigger: 'none'};
    colors: string[];
    legend: Object = {position: 'none'};
    bar : Object = {groupWidth: '75%'};
    bars: string = 'horizontal';
    annotations: Object = {}
    vAxis = {};
    hAxis = {};
    displayed =  null;

    formatFunction: any = () => {};

    constructor(options: any = {}) {
        if(options.yTitle) this.yTitle = options.yTitle;
        if(options.xTitle) this.xTitle = options.xTitle;
        if(options.colors) this.colors = options.colors;
        if(options.height) this.height = options.height;
        if(options.chartArea) this.chartArea = options.chartArea;
        if(options.tooltip) this.tooltip = options.tooltip;
        if(options.legend) this.legend =  options.legend;
        if(options.isStacked) this.isStacked = options.isStacked;
        if(options.annotations) this.annotations = options.annotations;
        if(options.vAxis) this.vAxis = options.vAxis;
        if(options.hAxis) this.hAxis = options.hAxis;
        if(options.displayed) this.displayed = options.displayed;
    }
}