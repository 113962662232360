import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ngxCsv } from 'ngx-csv/ngx-csv';

import { AdminService } from '../../admin.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';

@Component({
  selector: 'app-rejected-device-connections',
  templateUrl: './rejected-device-connections.component.html',
  styleUrls: ['./rejected-device-connections.component.css']
})
export class RejectedDeviceConnectionsComponent implements OnInit, AfterViewInit, OnDestroy {

  numLogsPerPage:number	= 100;
  logOptions: {
    getBy: string,
    value?: string,
    reason?: string,
    execlude?: boolean,
    deviceType?: string,
    ipAddress?: string,
  };
  filterOptions: string[];
  rejection_reasons: string[];
  fromDateFmt: Date;
  toDateFmt: Date;
  filteredLogs: any[] = [];
  customers: any;
  sites: any;
  chargers: any;
  battviews: any;
  logInfo: any[] = [];
  rejectionsToExport: any;
  interval: string = '';
  
  @ViewChild('ipAddress') ipAddress;
  @ViewChild("modal") modal;

  constructor(
    private adminService: AdminService,
    private breadcrumb: BreadcrumbService,
    private router: Router,
    private notificationMessage: NotificationMessageService,
    private commonUtil: CommonUtil
  ) {
  }

  ngOnInit() {
    this.filterOptions	= ['Date', 'ACTView ID', 'Serial Number', 'IP Address', 'Device Type', 'Rejection Reason'];
    this.logOptions = {
      getBy: 'Date',
      execlude: false
    };

    this.breadcrumb.setBreadcrumb([
      {label: 'Rejected Device Connections', url: ''}
    ]);

    this.getRejectedDeviceConnections();
  }

  ngAfterViewInit() {
		this.modal.onClose.subscribe(
			(ok) => {
				if(!ok) {
					this.interval = '';
				}
			}
		);
  }
  
  ngOnDestroy() {
    this.modal.onClose.unsubscribe();
    this.breadcrumb.hide();
	}

  resetSelects(){
    this.logOptions.value = '';
    this.logOptions.reason = '';
    this.logOptions.execlude = false;
    this.logOptions.deviceType = '';
    this.logOptions.ipAddress='';
  }
  getRejectedDeviceConnections(options?){
    options = options || this.logOptions;

    if(options.getBy == 'Date') {
      let fromDate, toDate;

      if(!this.fromDateFmt || !this.toDateFmt) {
        this.fromDateFmt = new Date(new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0));
        fromDate = Math.floor(new Date(this.fromDateFmt).getTime() / 1000);
        
        this.toDateFmt = new Date(new Date().setHours(23,59,59,999));
        toDate = Math.floor(new Date(new Date(this.toDateFmt).setHours(23,59,59,999)).getTime() / 1000);
      } else {
        
        var fromDateFmt	= this.fromDateFmt;
        var toDateFmt	= this.toDateFmt;
        if(options.passedDates) {
          fromDateFmt	= options.fromDate;
          toDateFmt	= options.toDate;
        }

        fromDate	= Math.floor(new Date(fromDateFmt).getTime() / 1000);
        toDate 		= Math.floor(new Date(new Date(toDateFmt).setHours(23,59,59,999)).getTime() / 1000);
      }

      options.fromDate	= fromDate;
      options.toDate		= toDate;
    }

    this.adminService.getRejectedDeviceConnections(options).subscribe(
      (response: any) => {
        this.customers			= response.customers;
        this.sites				= response.sites;
        this.chargers				= response.chargers;
        this.battviews			= response.battviews;
        this.rejection_reasons	= response.rejectionReasons;
    
        this.customers[0]	= {'id': 0, 'name': '-'};
        this.sites[0]		= {'id': 0, 'name': '-'};
    
        this.filteredLogs = [];
        this.logInfo	= response.logs;
      }
    );

  }
  rejectionUserAction() {
    var interval	= this.interval;
    var nowTime		= new Date();

    var timeDiff = nowTime.getTime();
    if(interval == "day")
      timeDiff = 24*60*60*1000;
    if(interval == "week")
      timeDiff = 7*24*60*60*1000;

    var fromDate	= Math.floor(new Date(nowTime.setTime(nowTime.getTime() - timeDiff)).getTime() / 1000);
    var toDate		= Math.floor(new Date(new Date().setHours(23,59,59,999)).getTime() / 1000);
    var options		= {'getBy': 'Date', passedDates: true, 'fromDate': fromDate, 'toDate': toDate}

    this.adminService.getRejectedDeviceConnections(options).subscribe(
      response => {
        this.rejectionsToExport = response;
        let CSVobj = this.exportRejectionLog(response);
        new ngxCsv(CSVobj, 'rejected-device-connections.csv');
        this.interval = '';
        this.modal.hide();
      }
    );
  }
  exportRejectionLog(data) {
    var logs		= data.logs;
    var battviews	= data.battviews;
    var chargers	= data.chargers;
    var customers	= data.customers;
    var sites		= data.sites;

    var CSVobj = [{a: "Device ID", b: 'Serial Number', c: 'Reason + Extra Info', d: 'Site Name', e: 'Customer Name', f: 'Type'}];
    
    if(!logs)
      return CSVobj;

    customers[0]	= {'id': 0, 'name': '-'};
    sites[0]		= {'id': 0, 'name': '-'};

    var groupedData = {};
    
    for(let log of logs) {
      log.customername	= '-';
      log.sitename		= '-';

      try {
        if(log.devicetype == 'charger') {

          log.sitename		= sites[chargers[log.actviewid].siteid].name;
          log.customername	= customers[chargers[log.actviewid].customerid].name;
        } else {
          log.sitename		= sites[battviews[log.actviewid].siteid].name;
          log.customername	= customers[battviews[log.actviewid].customerid].name;
        }
      } catch(err) {}

      if(!groupedData[log.sitename])
        groupedData[log.sitename] = {'charger': {}, 'battview': {}};

      groupedData[log.sitename][log.devicetype][log.serialnumber] = log.id;
    }

    var logsObj		= this.commonUtil.arrayToAssociativeArray(logs, 'id');
    var siteNames	= Object.keys(groupedData).sort();

    var nullSiteIdx = siteNames.indexOf('-');
    if(nullSiteIdx > -1) {
      siteNames.splice(nullSiteIdx, 1);
      siteNames.push('-');
    }

    siteNames.forEach(function(site) {
      var siteData = groupedData[site];
      ['charger', 'battview'].forEach(function(type) {
        var SNs = Object.keys(siteData[type]).sort();
        SNs.forEach(function(sn) {
          var logID = siteData[type][sn];
          var logData = logsObj[logID];
          var extraInfo = JSON.stringify(logData.extrainfo);
          CSVobj.push({
            a: logData.actviewid, b: '*'+logData.serialnumber, c: logData.reason + ' ' + extraInfo,
            d: logData.sitename, e: logData.customername, f: logData.devicetype
          });
        });
      });
    });
    return CSVobj;
  };
  goToEditDevice(device, type){
    var isFreeDevice = device.customerid == 0;
    var paramsObject = {
    };
    var toStateName = '';
    if (type == 'charger') {
      toStateName = 'chargers';
      paramsObject['chargerId'] = +device.id;
      // paramsObject.isFreeCharger = isFreeDevice;
    } else {
      toStateName = 'battviews';
      paramsObject['battviewId'] = +device.id;
      // paramsObject.isFreeBattview = isFreeDevice;

      // if(device.ispa)
      //   toStateName = 'battview_mobile_edit';
    }
    this.router.navigate(
      ['/'+toStateName, 'edit', 'customerId', device.customerid, 'siteId', device.siteid],
      {queryParams: paramsObject}
    );
  }
  deleteRejectionLog(logId) {
    this.adminService.deleteRejectionLog(logId).subscribe(
      response => {
        for(var i in this.filteredLogs) {
          if (this.filteredLogs[i].id == logId) {
            this.filteredLogs.splice(+i, 1);
            break;
          }
        }
        this.notificationMessage.setMessage(response, 'success', 10000, true);
      }
    );
  }
  loadData(data) {
    this.filteredLogs = data;
  }
  getCustomerName(log) {
    let customerName = '';
    if(log.devicetype == 'charger') {
      if(this.chargers[log.actviewid] && this.customers[this.chargers[log.actviewid].customerid])
        customerName = this.customers[this.chargers[log.actviewid].customerid].name
    } else {
      if(this.battviews[log.actviewid] && this.customers[this.battviews[log.actviewid].customerid])
        customerName = this.customers[this.battviews[log.actviewid].customerid].name;
    }
    return customerName;
  }
  getSiteName(log) {
    let siteName = '';
    if(log.devicetype == 'charger') {
      if(this.chargers[log.actviewid] && this.sites[this.chargers[log.actviewid].siteid])
        siteName = this.sites[this.chargers[log.actviewid].siteid].name;
    } else {
      if(this.battviews[log.actviewid] && this.sites[this.battviews[log.actviewid].siteid])
        siteName = this.sites[this.battviews[log.actviewid].siteid].name;
    }
    return siteName;
  }
  checkDisableSubmitButton() {
    return this.logOptions.getBy != 'Date' &&
          !this.logOptions.value &&
          !this.logOptions.reason &&
          !this.logOptions.deviceType &&
          !this.logOptions.ipAddress;
  }
}
