import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { User } from '../../../../auth/user.model';
import { UserService } from '../../../../auth/user.service';
import * as moment from 'moment';

@Component({
	selector: 'app-site-information-widget',
	templateUrl: './site-information-widget.component.html',
	styleUrls: ['./site-information-widget.component.css']
})
export class SiteInformationWidgetComponent implements OnInit, OnChanges, OnDestroy {

	@Input() data: any = {};

	liveSiteTime;
	liveSiteTimeInterval;
	user: User;
	customerId;
	siteInfo: any = {};
	estimatedCounts : {
		total_chargers:						number,
		total_battviews:					number,
		kwhrs_used:							number,
		ahrs_used:							number,
		charger_ahrs:						number,
		charge_cycles_delivered:			number,
		data_records_saved:					number,
		days_of_operations:					number,
		total_energy_kwhrs_saved:			number,
		total_dollar_saving:				number,
		co2_saving:							number,
		co2_emitted_per_gallon_of_gasoline:	number,
		passenger_vehicles_per_year:		number,
		barrels_of_oil_emissions:			number,
		co2_saved_per_urban_tree_planted:	number
	} = {
		total_chargers:						0,
		total_battviews:					0,
		kwhrs_used:							0,
		ahrs_used:							0,
		charger_ahrs:						0,
		charge_cycles_delivered:			0,
		data_records_saved:					0,
		days_of_operations:					0,
		total_energy_kwhrs_saved:			0,
		total_dollar_saving:				0,
		co2_saving:							0,
		co2_emitted_per_gallon_of_gasoline:	0,
		passenger_vehicles_per_year:		0,
		barrels_of_oil_emissions:			0,
		co2_saved_per_urban_tree_planted:	0
	};
	whrsUsed = '';
	whrsUnit= '';
	ahrsUsed= '';
	ahrsUnit= '';
	chargerCyclesUnit = '';

	constructor(
		private decimalPipe: DecimalPipe,
		private commonUtil: CommonUtil,
		private userService: UserService
	) { }

	ngOnInit() {
		this.user = this.userService.getCurrentUser();
	}

	ngOnChanges() {
		if (this.data && this.data.siteInfo) {
			this.data = this.getSiteDataFormatted(this.data);
			this.siteInfo = this.data.siteInfo;
			this.estimatedCounts = this.data.estimatedCounts;
			this.liveSiteTimeInterval = setInterval(() => {
				this.liveSiteTime = this.commonUtil.getDateFormattedFromUnixTimeStamp(this.commonUtil.getZoneTimestampFromUTC(this.data.siteInfo.zoneid, moment().utc().unix(), true));
			}, 500);
			/* Data Formatting */
			/* Whrs Used */
			let whrsUsed = this.decimalPipe.transform(this.estimatedCounts['kwhrs_used'], '1.0-0'); 
			let whrsUnitNumber = Number(whrsUsed.replace(/[^\d]/gi, ''));

			if (whrsUnitNumber < 1000) {
				this.whrsUsed = this.addNumberCommas(whrsUsed);
				this.whrsUnit = "kWhrs";
			} else {
				/*convert to Mega and keep two decimals*/
				this.whrsUnit = "MWhrs";
				whrsUsed = this.decimalPipe.transform(whrsUnitNumber/1000, '1.0-2');
				/*Add commas to number*/
				this.whrsUsed = this.addNumberCommas(whrsUsed);
			}

			/* Ahrs Used */
			let ahrsUsed = this.decimalPipe.transform(this.estimatedCounts['ahrs_used'], '1.0-0');
			let ahrsUnitNumber = Number(ahrsUsed.replace(/[^\d]/gi, ''));
			if (ahrsUnitNumber < 1000000) {
				this.ahrsUsed = this.addNumberCommas(ahrsUsed);
				this.ahrsUnit = "Ahrs";
			} else {
				ahrsUsed = this.decimalPipe.transform(ahrsUnitNumber/1000000, '1.0-2');
				/*Add commas to number*/
				this.ahrsUsed = this.addNumberCommas(ahrsUsed);
				this.ahrsUnit = "Million Ahrs";
			}
		}
	}

	addNumberCommas(number) {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	getSiteDataFormatted(data) {
		let returnData =  {
			dealerName			: data.dealerName,
			truckDealerName		: data.truckDealerName,
			serviceDealerName	: data.serviceDealerName,
			chargersCount		: data.chargersCount,
			battviewCount		: data.battviewCount,
			salesName			: data.salesName,
			siteInfo			: data.siteInfo,
			siteLastConnectTime	: data.siteLastConnectTime,
			siteInvoice			: data.siteInvoice,
			estimatedCounts		: {},
			timezone: null
		};
		returnData.estimatedCounts = this.calculateSiteEstimations(data.estimatedCounts),
		returnData.timezone = this.commonUtil.getZoneName(data.siteInfo.zoneid);
		return returnData;
	}

	calculateSiteEstimations(site) {
		let calculations = {
			total_chargers:						site.chargers.total_chargers,
			total_battviews:					site.battviews.total_battviews,
			kwhrs_used:							1.07 * site.chargers.total_kwhrs,
			ahrs_used:							site.battviews.total_inuse_ahrs,
			charger_ahrs:						site.battviews.total_charger_ahrs,
			charge_cycles_delivered:			site.chargers.total_records,
			data_records_saved:					site.battviews.total_events,
			days_of_operations:					0,
			total_energy_kwhrs_saved:			0,
			total_dollar_saving:				0,
			co2_saving:							0,
			co2_emitted_per_gallon_of_gasoline:	0,
			passenger_vehicles_per_year:		0,
			barrels_of_oil_emissions:			0,
			co2_saved_per_urban_tree_planted:	0
		};
		
		let DOO = site.battviews.total_days;
		const chargerCycles = this.commonUtil.calculateValuesWithUnit(calculations.charge_cycles_delivered);
		calculations.charge_cycles_delivered = chargerCycles.value;
		this.chargerCyclesUnit = chargerCycles.unit;

		if(site.chargers.total_days > DOO)
			DOO = site.chargers.total_days;
			
		calculations.days_of_operations					= DOO;
		calculations.total_energy_kwhrs_saved			= 0.05 * calculations.kwhrs_used;
		calculations.total_dollar_saving				= 0.05 * calculations.total_energy_kwhrs_saved;
		calculations.co2_saving							= Math.pow(10, -4) * 7.03 * calculations.total_energy_kwhrs_saved;
		calculations.co2_emitted_per_gallon_of_gasoline	= calculations.co2_saving / (Math.pow(10, -3) * 8.887);
		calculations.passenger_vehicles_per_year		= calculations.co2_saving / 4.73;
		calculations.barrels_of_oil_emissions			= calculations.co2_saving / 0.43;
		calculations.co2_saved_per_urban_tree_planted	= calculations.co2_saving / 0.039;
		calculations.co2_saving *= 2204.62;

		return calculations;
	}

	ngOnDestroy(){
		if (this.liveSiteTimeInterval) {
			clearInterval(this.liveSiteTimeInterval);
		}
	}
}