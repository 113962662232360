<div *ngIf="hasChargers; else noChargers">
	<div *ngFor="let modelType of chargersListing | keys" class="col-md-12 boxs3">
		<div class="panel panel-default">
			<div class="panel-heading"><strong>{{modelType}} Chargers</strong></div>
		</div>
		<div *ngFor="let charger of chargersListing[modelType]; let idx = index">
			<div class="row" *ngIf="(idx % 6) == 0 && chargersListing[modelType][idx]">
				<div class="col-md-2" *ngIf="(idx % 6) == 0 && chargersListing[modelType][idx]">
					<ng-container *ngTemplateOutlet="chargerItem;context:{charger: chargersListing[modelType][idx]}" ></ng-container>
				</div>
				<div class="col-md-2" *ngIf="(idx % 6) == 0 && chargersListing[modelType][idx+1]">
					<ng-container *ngTemplateOutlet="chargerItem;context:{charger: chargersListing[modelType][idx+1]}" ></ng-container>
				</div>
				<div class="col-md-2" *ngIf="(idx % 6) == 0 && chargersListing[modelType][idx+2]">
					<ng-container *ngTemplateOutlet="chargerItem;context:{charger: chargersListing[modelType][idx+2]}" ></ng-container>
				</div>
				<div class="col-md-2" *ngIf="(idx % 6) == 0 && chargersListing[modelType][idx+3]">
					<ng-container *ngTemplateOutlet="chargerItem;context:{charger: chargersListing[modelType][idx+3]}" ></ng-container>
				</div>
				<div class="col-md-2" *ngIf="(idx % 6) == 0 && chargersListing[modelType][idx+4]">
					<ng-container *ngTemplateOutlet="chargerItem;context:{charger: chargersListing[modelType][idx+4]}" ></ng-container>
				</div>
				<div class="col-md-2" *ngIf="(idx % 6) == 0 && chargersListing[modelType][idx+5]">
					<ng-container *ngTemplateOutlet="chargerItem;context:{charger: chargersListing[modelType][idx+5]}" ></ng-container>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #chargerItem let-charger="charger">
	<div *ngIf="charger.isShow" [ngClass]="getBgClass(charger)">
		<div class="text-center">
			<label class="checkbox tcenter p-b-5" for="{{charger.id}}">
			<input type="checkbox" [(ngModel)]="charger.selected" name="group" (ngModelChange)="deviceChecked($event, charger.id)" />
			</label>
		</div>
		<div class="text-center margin-top-lg">
			<div class="btn-group pointer" appDropdown *ngIf="currentSite.act_intelligent_type != 0">
				<a type="button" style="display: inline-block;" [pTooltip]="charger.tooltipMessage.join('\n')" tooltipPosition="top" tooltipStyleClass="device-changes-log">
					<p [ngClass]="charger.imageclass"></p>
				</a>
				<ul class="z-index-10k dropdown-menu" role="menu">
					<li><a [routerLink]="['/', customerId, siteId, 'chargers', 'settings']" [queryParams]="{chargersIDs: charger.id}">Settings</a></li>
					<li><a [routerLink]="['/', customerId, siteId, 'chargers', 'analytics', charger.tab, charger.id]">Analytics</a></li>
					<li *ngIf="charger.actviewenable"><a [routerLink]="['/', customerId, siteId, 'chargers', 'lcd-projection']" [queryParams]="{chargersIDs: charger.id}">Display</a></li>
					<li *ngIf="charger.actviewenable && userService.hasAccessFunction('lcd_history')"><a [routerLink]="['/', customerId, siteId, 'chargers', 'lcd-history']" [queryParams]="{chargersIDs: charger.id}">LCD History</a></li>
					<li *ngIf="charger.actviewenable && userService.hasAccessFunction('pms_live_view')"><a [routerLink]="['/', customerId, siteId, 'chargers', 'pms-live-view']" [queryParams]="{chargersIDs: charger.id}">PMs Live View</a></li>
					<li *ngIf="currentUser.isACTuser"><a [routerLink]="['/', customerId, siteId, 'chargers', 'pms-list']" [queryParams]="{chargersIDs: charger.id}">PMs List</a></li>
					<li *ngIf="charger.actviewenable && currentUser.isACTuser"><a [routerLink]="['/', customerId, siteId, 'chargers', 'charger-energy']" [queryParams]="{chargersIDs: charger.id}">Charger Energy</a></li>

					<li class="dropdown-submenu" appDropdown [indirectOptionsList]="true">
						<a tabindex="-1" *ngIf="!userService.hasAccessFunction('no_manage_devices') || (currentUser.isACTuser && charger.time_gap > 0) || (userService.hasAccessFunction('act_admin_device_management') && charger.enableplc) || (charger.actviewenable && userService.hasAccessFunction('restart_devices'))">
							<span>Actions
								<i class="fa fa-caret-right margin-left-sm"></i>
							</span>
						</a>
						<app-charger-actions pageName='listing' [charger]="charger" [siteID]="siteId" [customerID]="customerId"></app-charger-actions>
					</li>
					<li class="dropdown-submenu" appDropdown>
						<a tabindex="-1">
							<span>Notes
							<i class="fa fa-caret-right margin-left-sm"></i>
							</span>
						</a>
						<ul class="dropdown-menu" role="menu">
							<li>
								<a [routerLink]="['/', customerId, siteId, 'chargers', 'notes', charger.id]">View Notes</a>
							</li>
							<li class="pointer">
								<a>
									<notes
										[siteId]="siteId"
										modelType="charger"
										[modelId]="charger.id"
										[isDropDown]="true"
										[isAddNoteOnly]="true"
									></notes>
								</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="btn-group pointer" *ngIf="currentSite.act_intelligent_type == 0" [routerLink]="['/', customerId, siteId, 'chargers', 'analytics', charger.tab, charger.id]">
				<i type="button" style="display: inline-block;">
					<p [ngClass]="charger.imageclass"></p>
				</i>
			</div>
		</div>
		<div class="text-center">
			<p class="sm-font tcenter">{{charger.chargerusername}}</p>
			<p class="sm-font tcenter">{{charger.serialnumber}}</p>
		</div>
		<div class="text-center">
			<div class="btn-group p-5">
				<div class="listing-tags" *ngFor="let tag of charger.tags">
					<button class="btn btn-sm ng-binding" [ngClass]="{'btn-danger': tag.sharingoption == 'private', 'btn-info': tag.sharingoption == 'group', 'btn-default': tag.sharingoption == 'public', 'replacement-tag': tag.keep_in_replacement && currentUser.isACTuser}">
					{{tag.tagname}}
					</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #noChargers>
	<div class="panel panel-default">
		<div class="panel-body">
			<strong>No Charger Registered!</strong>
		</div>
	</div>
</ng-template>