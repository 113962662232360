import { GoogleChartsBaseService } from './google-charts.base.service';
import { Injectable } from '@angular/core';

import * as _ from 'underscore';
import { BarChartConfig } from '../Models/BarChartConfig';

declare var google: any;

@Injectable()
export class GoogleBarChartService extends GoogleChartsBaseService {

	constructor() { super(); }

	public BuildBarChart(elementId: String, data: any[], config: BarChartConfig) : void {  
		var chartFunc = () => { return new google.visualization.BarChart(document.getElementById(<string>elementId)); };
		var clearFunc = () => { document.getElementById(<string>elementId).innerHTML = ""; }
		var options = {
			tooltip: config.tooltip,
			bar: config.bar,
			bars: config.bars,
		}
    if (config.yTitle) options['yTitle'] = config.yTitle;      
    if (config.xTitle) options['xTitle'] = config.xTitle;      
		if (config.chartArea) options['chartArea'] = config.chartArea;        
		if (config.height)  options['height'] = config.height;
		if (config.legend) options['legend'] = config.legend;
		if (config.isStacked) options['isStacked'] = config.isStacked;
		if (config.annotations) options['annotations'] = config.annotations;
		if (config.hAxis) options['hAxis'] = config.hAxis;
		if (config.vAxis) options['vAxis'] = config.vAxis;
		if (!_.isNull(config.displayed)) options['displayed'] = config.displayed;

		if (config.colors) {
			options['colors'] = config.colors;
		}
		this.buildChart(data, chartFunc, options, clearFunc);
	}
}