import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as moment from 'moment';

import { BattviewsDashboardService } from '../battviews-dashboard.service';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { UserService } from '../../../../../auth/user.service'
import { SideMenuService } from '../../../../../shared/side-menu/side-menu.service';

@Component({
	selector: 'app-battviews-faults',
	templateUrl: './battviews-faults.component.html',
	styleUrls: ['./battviews-faults.component.css']
})
export class BattviewsFaultsComponent implements OnInit, OnChanges {
	@Input() device: any = {};

	@Output() updateAppearanceElementsFlags = new EventEmitter<any>(true);

	hasBattviewFautlsData:boolean = false;
	gridColumns;
	gridData;
	faults: any[] = [];
	hasPLCFaults: boolean = false;
	isSitePLCEnabled: boolean = false;
	siteInfo: any;

	constructor(
		private battviewsDashboardService: BattviewsDashboardService,
		private commonUtil: CommonUtil,
		private userService: UserService,
		private sideMenu: SideMenuService
	) {
		this.gridColumns = [
			{headerName: "ID", field: "id", type:"number", width: 150},
			{headerName: "Time", field: "time_formatted", width: 150, filterParams: {defaultOption: 'startsWith'}},
			{headerName: "Fault", field: "fault_name", width: 300},
		];
	}

	ngOnInit() {
		this.updateAppearanceElementsFlags.emit({
			addNoteEnabled: false,
			pageHasFilterByDate: false,
			pageHasBattviewTags: false,
			hasResetPLC: false,
			pageHasManageBtn: true,
			pageHasTimesInfo: true,
		});
		
		this.sideMenu.currentSite.subscribe(data => {
			if(data.id) {
				this.siteInfo = data;
			}
		});
	}

	ngOnChanges(changes) {
		if(changes.device.currentValue) {
			this.getBattviewFaultsData();
		}
	}
	
	getBattviewFaultsData() {
		if(!this.device.id)
			return;

		this.battviewsDashboardService.getBattviewFaults(this.device.id).subscribe((data: any) => {
			this.faults = data;
			this.isSitePLCEnabled = data.isSitePLCEnabled || false;
			this.gridData = this.formatBattviewFaults(this.faults);
			this.hasBattviewFautlsData = (this.gridData.length > 0);
		});
	}
	formatBattviewFaults(faults, resetFaults = false) {
		var formattedFaults = [];
		for (var i = 0; i < faults.length; i++) {
			var faultId = parseInt(faults[i].fault, 16);
			faults[i].fault_name = this.commonUtil.getBattviewFaultText(faultId, this.device.enablehalleffectsensing);
			faults[i].time_formatted = this.commonUtil.getDateFormattedFromUnixTimeStamp(Math.round(new Date(faults[i].time).getTime() / 1000));

			formattedFaults.push(faults[i]);
		}
		
		this.updateAppearanceElementsFlags.emit({hasResetPLC: false});
		if(
			this.isSitePLCEnabled &&
			this.userService.hasAccessFunction('PLC_error_detection') &&
			this.device.enableplc &&
			resetFaults !== true
		) {
			var todayTime		= +(moment().utc().startOf('day').format("X"));
			var plc_event_time	= +(moment(this.device.plc_event_time).utc().startOf('day').format("X"));
			var hide_plc_fault_date	= +(moment(this.device.hide_plc_fault_date).utc().startOf('day').format("X"));
			var last_charge_cycle_time = +(moment(this.device.last_charge_cycle_time).utc().startOf('day').format("X"));
			let lastChargePeriod = 7;
			if(this.siteInfo && this.siteInfo.act_first_enabled) {
				lastChargePeriod = 30;
			}
			if (todayTime > hide_plc_fault_date) {
				// if plc_event_time is 1-1-1970 and last_charge_cycle_time != 1-1-1970 save a fault called no_plc_actvity
				let plcFaultsObject: any = {};
				if (plc_event_time < 1*24*60*60 && last_charge_cycle_time > 1*24*60*60) {
					plcFaultsObject.fault_name = 'No PLC Activity';
					plcFaultsObject.time_formatted = this.commonUtil.getDateFormattedFromUnixTimeStamp(last_charge_cycle_time);
					formattedFaults.push(plcFaultsObject);
					this.hasPLCFaults = true;
					this.updateAppearanceElementsFlags.emit({hasResetPLC: true});
				} else if (last_charge_cycle_time > todayTime - (1*lastChargePeriod*24*60*60) && plc_event_time < todayTime - (1*8*24*60*60)) {
					// ELSE if last_charge_cycle_time is within last 1 week, and plc_event_time is older than 8 days..save a fault called plc_warning
					plcFaultsObject.fault_name = 'PLC Warning';
					plcFaultsObject.time_formatted = this.commonUtil.getDateFormattedFromUnixTimeStamp(plc_event_time);
					formattedFaults.push(plcFaultsObject);
					this.hasPLCFaults = true;
					this.updateAppearanceElementsFlags.emit({hasResetPLC: true});
				}
			}
		}

		return formattedFaults;
	}

	onDeviceChanged(device) {
		this.device = device;
	}

	onResetPLC(reset) {
		if(reset) {
			this.gridData = this.formatBattviewFaults(this.faults, true);
			this.hasBattviewFautlsData = (this.gridData.length > 0);
		}
	}
}
