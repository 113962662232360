import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActFirstService } from '../../act-first.service';
import * as lo from 'lodash';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import * as moment from 'moment';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';
import { Site } from '../../../../../shared/models/site.model';
import { Subscription } from 'rxjs';
import { SideMenuService } from '../../../../../shared/side-menu/side-menu.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-connect-voltage',
	templateUrl: './connect-voltage.component.html',
})
export class ConnectVoltageComponent implements OnInit, OnDestroy {

	@Input() isWeekly: boolean = false;
	startDate: any;
	endDate: any;

	startDateUnix;
	// for weekly
	submitted:boolean = false;
	invalidDates = false;
	startWeekList = [];
	endWeekList = [];
	// weekly

	reportNotReadyYet = null;
	reportData: any = {};
	data = {};
	gridColumnsPerGroup = [];
	gridColumnsNoGroup = [];
	gridColumnsVPCFiltered = [];
	gridRows = [];
	gridRows2 = [];
	site: Site;
	config: ColumnChartConfig = new ColumnChartConfig({});
	getData = false;
	groupCharts = [];
	chartObjs = {};
	siteGroups = [];
	images = {};
	currentSiteSub: Subscription = new Subscription();
	hideZeros: boolean = true;
	isVPCVoltage: boolean = true;
	isCompensatedVoltage: boolean = true;
	showedVPCArray: any = {};
	selectedItemVPCGrid: any = {};
	selectedItemVPCFilter: any = {};

	exitStatusDropdownOptions: any = [{label: '', value: null}];
	constructor(
		private actFirstService: ActFirstService,
		private commonUtil: CommonUtil,
		private chartUtil: ChartUtil,
		private sideMenuService: SideMenuService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.startDateUnix	= moment().utc().subtract(1, 'days').startOf('day').unix();

		this.currentSiteSub	= this.sideMenuService.currentSite.subscribe(siteInfo => {
			if(siteInfo.id) {
				this.site = siteInfo;
				let siteTimeNow		= this.commonUtil.getZoneTimestampFromUTC(this.site.zoneid, moment().utc().unix(), true);
				let siteTimeHour24	= moment(siteTimeNow*1000).utc().format('H'); // 0...23
				if(+siteTimeHour24 < 12) // if site time now is less than 12 afternoon, set default day the day before yesterday.
					this.startDateUnix = moment().utc().subtract(2, 'days').startOf('day').unix();

				if(this.isWeekly)
					this.buildWeeklyRangeLists(this.startDateUnix);
				else
					this.startDate	= new Date(this.startDateUnix * 1000);
			}
		});
	}

	fetchData(){
		this.getData = false;
		this.submitted = true;
		this.invalidDates = false;

		let fromDate:any, toDate:any;

		if(this.isWeekly){
			fromDate	= this.startDate;
			toDate		= this.endDate;
			if(toDate <= fromDate || !fromDate || !toDate)
				return this.invalidDates = true;
		} else {
			fromDate = new Date(new Date(this.startDate).setHours(0, 0, 0, 0));
			fromDate = Math.floor((-fromDate.getTimezoneOffset() * 60000 + fromDate.getTime()) / 1000);
		}

		this.actFirstService.getConnectVoltageReport(this.site.id, {fromDate, toDate}).subscribe((data:any)=> {
			this.getData = true;
			if(data.length === 0)
				return this.reportNotReadyYet = true;

			this.reportNotReadyYet = false;
			this.selectedItemVPCFilter = {};

			if(this.isWeekly)
				this.reportData = this.aggregateWeeklyData(data);
			else
				this.reportData = data[0];

			let gridColumnsNoGroup: any = [
				{headerName: "VPC Connect Voltage", field: "vpc_connect_voltage", colId: 'vpc_connect_voltage'},
			];
			for(let groupIdx in this.reportData.site_groups) {
				let group = this.reportData.site_groups[groupIdx];
				gridColumnsNoGroup.push({
					headerName: group.text,
					children: [
						{headerName: "Connect Voltage", field: "connect_voltage"+group.id, colId: "connect_voltage"+group.id},
						{headerName: "Percentage (%)", field: "percent"+group.id, colId: "percent"+group.id}
					]
				});
			}
			this.gridColumnsNoGroup = gridColumnsNoGroup;

			this.formatData();
		});
	}

	formatData() {
		let data			= lo.cloneDeep(this.reportData);
		let vpcReport		= data.vpc_data;
		let siteGroups		= data.site_groups;
		let chargeEvents	= data.charge_events;
		let siteBattviews	= data.bfds;

		this.data = {};
		this.gridRows = [];
		this.gridRows2 = [];
		this.chartObjs = {};
		this.groupCharts = [];
		this.selectedItemVPCGrid = {};
		let exitStatusOptions = {'N/A': true};
		this.exitStatusDropdownOptions = [{label: '', value: null}];

		let gridRows = [];
		for(let bfdid in chargeEvents){
			let events			= lo.cloneDeep(chargeEvents[bfdid]);
			let battview		= siteBattviews[bfdid];
			let actfirstgroupid	= battview.actfirstgroupid;
			let groupname		= siteGroups[actfirstgroupid].text;
			if(!this.selectedItemVPCGrid[actfirstgroupid])
				this.selectedItemVPCGrid[actfirstgroupid] = [];

			events.forEach((event) => {
				event.start_time = this.commonUtil.getDateFormattedFromUnixTimeStamp(event.start_time);
				if(+(event.duration) == event.duration) {
					event.duration = moment("2015-01-01").startOf('day').seconds(event.duration).format('H:mm');
				}

				event.start_soc			= Math.round(event.start_soc);
				event.charge_ah			= (Math.round(event.charge_ah*10))/10;
				event.event_max_current	= Math.round(event.event_max_current);

				if(this.selectedItemVPCFilter[actfirstgroupid] && this.selectedItemVPCFilter[actfirstgroupid] == event.vpc_connect_voltage){
					if(this.selectedItemVPCGrid[actfirstgroupid].findIndex((ele) => ele.id == battview.id) == -1)
						this.selectedItemVPCGrid[actfirstgroupid].push(battview);
				}

				event.groupname		= groupname;
				event.battery_id	= bfdid;
				event.batteryid		= battview.batteryid;

				event = this.calculateCompensatedVoltage(event, battview);

				exitStatusOptions[event.exitStatus] = true;
				gridRows.push(event);
			});
		}

		for(var exitStatus of Object.keys(exitStatusOptions)) {
			this.exitStatusDropdownOptions.push({value: exitStatus.toLowerCase(), label: exitStatus});
		}

		this.gridRows = gridRows;

		let vpcGroupsTotalCounts = this.getVpcGroupsTotalCounts(vpcReport);
		let gridRows2 = [];
		this.showedVPCArray = {};
		for(let vpcVolt in vpcReport) {
			let temp = {};
			let totalCount = 0;
			temp['vpc_connect_voltage'] = vpcVolt;
			for(let groupId in vpcReport[vpcVolt]) {
				temp['connect_voltage'+groupId]	= vpcReport[vpcVolt][groupId].volt;
				temp['count'+groupId]			= vpcReport[vpcVolt][groupId].count;
				temp['percent'+groupId]			= parseFloat(""+(vpcReport[vpcVolt][groupId].count / vpcGroupsTotalCounts[groupId])*100).toFixed(1);
				totalCount += vpcReport[vpcVolt][groupId].count;
				
				if(!this.hideZeros || vpcReport[vpcVolt][groupId].count > 0) {
					if(!this.chartObjs[groupId]) {
						this.chartObjs[groupId] = {
							dataTable: [[
								{ type: "string", label: this.translateService.instant('device.vpc') },
								{ type: "number", label: "Count" },
								{ type: "string", role: "style"}
							]],
							groupname: siteGroups[groupId].text,
							groupid: groupId
						};
					}
					let color = 'green';
					if((+vpcVolt) > 2.02) {
						color = 'red';
					} else if((+vpcVolt) < 1.9) {
						color = 'yellow';
					}

					if(!this.showedVPCArray[groupId])
						this.showedVPCArray[groupId] = [];
					this.showedVPCArray[groupId].push(vpcVolt);

					this.chartObjs[groupId].dataTable.push([""+vpcVolt, vpcReport[vpcVolt][groupId].count, 'color: '+color]);
				}
			}
			if(this.hideZeros && totalCount <= 0) {
				temp = {};
			}
			if(Object.keys(temp).length > 0)
				gridRows2.push(temp);
		}

		this.gridRows2 = gridRows2;

		this.groupCharts = lo.values(this.chartObjs);

		this.prepareColumnsDef();
	}

	prepareColumnsDef(){
		this.gridColumnsPerGroup = [
			{headerName: this.translateService.instant('act_first.truck_type_name'), field: "groupname", colId: 'groupname'},
			{
				headerName: this.translateService.instant('device.battery_id'),
				field: "batteryid",
				colId: 'batteryid',
				cellRendererSelector:function (params) {
					params['label'] = params.data.batteryid;
					params['link'] = ["/#", params.colDef.cellRendererParams.customerId, params.colDef.cellRendererParams.siteId, "battviews", "analytics", "battery-summary", params.data.battery_id].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				cellRendererParams: {customerId: this.site.customerid, siteId: this.site.id}
			},
			{headerName: this.translateService.instant("charger.sequence"), 		field: "event_id", colId: 'event_id', type: "number"},
			{headerName: this.translateService.instant("device.connect_voltage"), 	field: "connect_voltage_formatted", colId: 'connect_voltage_formatted', type: "number", filterParams: {format: "1.2-2"}},
			{headerName: this.translateService.instant("charger.end_voltage"), 		field: "end_voltage_formatted", colId: 'end_voltage_formatted', type: "number", filterParams: {format: "1.2-2"}},
			{headerName: this.translateService.instant("act.start_time"), 			field: "start_time", colId: 'start_time', filterParams: {defaultOption: 'startsWith'}},
			{headerName: this.translateService.instant("device.duration"), 			field: "duration", colId: 'duration'},
			{headerName: this.translateService.instant("device.start_soc"), 		field: "start_soc", colId: 'start_soc', type: "number", filterParams: {format: "1.0-0"}},
			{headerName: this.translateService.instant("device.charge_ahr"), 		field: "charge_ah", colId: 'charge_ah', type: "number", filterParams: {format: "1.1-1"}},
			{
				headerName: this.translateService.instant('device.exit_status'),
				field: "exitStatus",
				colId: 'exitStatus',
				// filterParams: {newRowsAction: 'keep'},
				floatingFilterComponent: "dropDownFilterComponent",
				floatingFilterComponentParams: {dropDownOptions: this.exitStatusDropdownOptions},
			},
		];

		this.gridColumnsVPCFiltered = [
			{
				headerName: this.translateService.instant('device.battery_id'),
				field: "batteryid",
				colId: 'batteryid',
				cellRendererSelector:function (params) {
					params['label'] = params.data.batteryid;
					params['link'] = ["/#", params.colDef.cellRendererParams.customerId, params.colDef.cellRendererParams.siteId, "battviews", "analytics", "battery-summary", params.data.id].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				cellRendererParams: {customerId: this.site.customerid, siteId: this.site.id},
			},
			{headerName: this.translateService.instant('device.serial_number'), field: "serialnumber", colId: 'serialnumber'},
		];

		this.config = new ColumnChartConfig({
			isPercent: false,
			explorer: {
				'axis': 'horizontal',
				'actions': ['dragToZoom', 'rightClickToReset'],
				maxZoomIn: 50.0,
				keepInBounds: true
			},
			tooltip:{},
			vAxisMinValue: 0,
			vAxisMaxValue: 2,
			xTitle: this.translateService.instant('device.vpc'),
			yTitle: this.translateService.instant('device.charge_events_count')
		});
	}

	getVpcGroupsTotalCounts(vpcReport){
		let groupTotalCount = {};
		for(let vpcVolt in vpcReport) {
			for(let groupId in vpcReport[vpcVolt]) {
				if(!groupTotalCount[groupId])
					groupTotalCount[groupId] = 0;
				groupTotalCount[groupId] += vpcReport[vpcVolt][groupId].count;
			}
		}
		return groupTotalCount;
	}

	onSelectVPCBarItem(item, groupId){
		if(!item || this.isWeekly)
			return;
		this.selectedItemVPCGrid[groupId]	= [];
		this.selectedItemVPCFilter[groupId]	= this.showedVPCArray[groupId][item.row];
		this.formatData();
	}

	generateImageURI(event) {
		let chart = event['chart'];
		let elementId = event['elementId'];
		let image = this.chartUtil.getChartImageUri(chart);
		this.images[elementId] = image;
	}

	printChart(title, elementId) {
		let image = this.images[elementId];
		let reportDate = moment(this.startDate, 'MM/DD/YYYY').utc().format('MM/DD/YYYY');
		if(this.isWeekly)
			reportDate = moment(this.startDate * 1000).utc().format('MM/DD/YYYY') + ' - ' + moment(this.endDate * 1000).utc().format('MM/DD/YYYY');
		let chartTitle = this.translateService.instant('act_first.connect_voltage_report')+' ('+reportDate+')'
		let printContent = '<h4>' + title + ' ' + chartTitle + '</h4><br><br><img src="'+image+'" style="width:100%;"" border="0" alt="Null">';

		this.commonUtil.print({
			appendPrintContent: printContent,
		});
	}

	ngOnDestroy() {
		this.currentSiteSub.unsubscribe();
	}

	calculateCompensatedVoltage(event, battview){
		let voltageCompensation		= (battview.batterytemperaturecompesnation || 0) / 1000;
		let maxTemperatureLimitCel	= this.commonUtil.fahToCel(200);

		event.max_temperature_formatted = Math.round(event.max_temperature);
		if (event.max_temperature_formatted > maxTemperatureLimitCel) {
			event.max_temperature_formatted = maxTemperatureLimitCel;
		}

		if (+event.start_temperature == event.start_temperature && event.start_temperature_enabled == true) {
			event.start_temperature_formatted = Math.round(event.start_temperature);
			if (event.start_temperature_formatted > maxTemperatureLimitCel) {
				event.start_temperature_formatted = maxTemperatureLimitCel;
			}
		} else {
			event.start_temperature_formatted = 'N/A';
		}

		if(event.start_temperature_formatted != 'N/A') {
		
			let endVolTemp = event.max_temperature;
			if(event.end_temperature != 'N/A')
				endVolTemp = event.end_temperature > maxTemperatureLimitCel ? +maxTemperatureLimitCel : event.end_temperature;
	
			let connectVolTemp = event.start_temperature;
			if(event.start_temperature > maxTemperatureLimitCel)
				connectVolTemp = +maxTemperatureLimitCel;
	
			event.compensation_connect_voltage	= event.vpc_connect_voltage + (connectVolTemp - 25) * voltageCompensation;
			event.compensation_end_voltage		= event.vpc_end_voltage + (endVolTemp - 25) * voltageCompensation;
		} else {
	
			event.compensation_connect_voltage	= event.vpc_connect_voltage;
			event.compensation_end_voltage		= event.vpc_end_voltage;			
		}

		if(this.isCompensatedVoltage) {
			event.vpc_connect_voltage	= event.compensation_connect_voltage;
			event.vpc_end_voltage		= event.compensation_end_voltage;
		}

		event.vpc_connect_voltage	= Math.round(event.vpc_connect_voltage * 100) /100;
		event.vpc_end_voltage		= Math.round(event.vpc_end_voltage * 100) /100;

		
		if(this.isVPCVoltage) {
			event.connect_voltage_formatted	= event.vpc_connect_voltage;
			event.end_voltage_formatted		= event.vpc_end_voltage;
		} else {
			event.connect_voltage_formatted	= event.connect_voltage;
			event.end_voltage_formatted		= event.end_voltage;
		}

		return event;
	}

	aggregateWeeklyData(data){
		let toReturn = {
			bfds: {},
			site_groups: {},
			vpc_data: {}
		};
		for (let report of data){
			Object.assign(toReturn.bfds, report.bfds);
			Object.assign(toReturn.site_groups, report.site_groups);

			for(let vpcVolt in report.vpc_data) {

				if(!toReturn.vpc_data[vpcVolt])
					toReturn.vpc_data[vpcVolt] = {};

				for(let groupId in report.vpc_data[vpcVolt]) {
					if(!toReturn.vpc_data[vpcVolt][groupId])
						toReturn.vpc_data[vpcVolt][groupId] = {count: 0, volt: null};

					toReturn.vpc_data[vpcVolt][groupId].volt	= report.vpc_data[vpcVolt][groupId].volt;
					toReturn.vpc_data[vpcVolt][groupId].count  += report.vpc_data[vpcVolt][groupId].count;
				}
			}
		}
		return toReturn;
	}

	buildWeeklyRangeLists(startDate) {
		let startList = [],
			endList = [],
			min = 0,
			max = 23,
			daySeconds = 60*60*24;

		for(let i = min; i <= max; i++) {
			startList.push({
				value: startDate - ((i+1) * 7 *daySeconds),
				label: this.translateService.instant("g.last")+" "+(i+1)+" "+this.translateService.instant("time.weeks")
			});
			endList.push({
				value: startDate - (i * 7 *daySeconds),
				label: this.translateService.instant("g.last")+" "+(i+1)+" "+this.translateService.instant("time.weeks")
			});
		}
		startList[0].label	= this.translateService.instant("time.last_week")
		endList[0].label	= this.translateService.instant("time.last_week")

		this.startWeekList	= startList;
		this.endWeekList	= endList;
	}
}