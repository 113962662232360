import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';

@Component({
	selector: 'app-ebu-daily-usage',
	templateUrl: './ebu-daily-usage.component.html',
	styleUrls: ['./ebu-daily-usage.component.css']
})
export class EbuDailyUsageComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: ColumnChartConfig;
	tableColumns: any[] = [];
	elementId: string = 'batt_events_ebu_usage_summary';

	constructor() { }

	ngOnInit() {
		this.config = new ColumnChartConfig({
			isPercent: true,
			xTitle: "Daily EBU Usage",
			yTitle: "% Batteries"
		});

		this.config.formatFunction = (data) => {
			data = data.list;
			let formattedData: any[] = [
				[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'},
				]
			];

			for(let i = 0; i < data.length; i++) {
				let item: any = data[i];
				var color;
				if (item.x < 1) {
					color = "#ADD8E6";
				} else if (item.x === 1) {
					color = "#0000FF";
				} else {
					color = "#00008B";
				}
				formattedData.push(
					[
						{v: item.x <= 0.5 ? "<0.5" : item.x},
						{v: item.y / 100},
						{v: Math.round(item.y*10)/10 + "%"},
						{v: 'color: ' + color}
					]
				);
			}

			return formattedData;
		};
	}

	ngOnChanges(changes) {
		if(this.data) {
			let userAlertsSettings = this.data.siteSettings.userSettings;
			if(userAlertsSettings && !userAlertsSettings.bv_inuse_events_only_for_charge_ahr) {
				this.elementId = 'batt_ebu_usage_summary';
			}
			this.tableColumns = [{title: "Battery ID", property: "battery_id"}];
			if (userAlertsSettings.view_truck_id) {
				this.tableColumns.push({title: "Truck ID", property: "truck_id"});
			}
			this.tableColumns.push({title: "Battery SN", property: "battery_sn"});
			this.tableColumns.push({title: "Average EBU", property: "value"});
			this.tableColumns.push({title: "Max EBUs", property: "max_ebu"});

			this.data['battviewsData'] = this.siteBattviewsData;
			this.data['siteTags'] = this.siteTags;
			this.data['siteBattviewsTags'] = this.siteBattviewsTags;
		}
	}
}
