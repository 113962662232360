import { GoogleChartsBaseService } from './google-charts.base.service';
import { Injectable } from '@angular/core';

import * as _ from 'underscore';
import { LineChartConfig } from '../Models/LineChartConfig';

declare var google: any;

@Injectable()
export class GoogleLineChartService extends GoogleChartsBaseService {

	constructor() { super(); }

	public BuildLineChart(elementId: String, data: any[], config: LineChartConfig) : void {
		var chartFunc = () => { return new google.visualization.LineChart(document.getElementById(<string>elementId)); };
		var clearFunc = () => { document.getElementById(<string>elementId).innerHTML = ""; }
		var options: any = {
			tooltip: config.tooltip,
			legend: config.legend,
			hAxis: { title: config.xTitle },
		}
		let vAxis:any = {};
		if(config.vAxisFormat){
			vAxis.format = config.vAxisFormat;
		}
		if(config.yTitle){
			vAxis.title = config.yTitle;
		}
		if (!_.isUndefined(config.vAxisMinValue)) {
			// to accept zero value
			vAxis['viewWindow'] = {min: config.vAxisMinValue};
		}

		if (config.vAxisMaxValue) {
			if (vAxis['viewWindow']) {
				vAxis['viewWindow'].max = config.vAxisMaxValue;
			} else {
				vAxis['viewWindow'] = {max: config.vAxisMaxValue};
			}
		}

		if (vAxis && Object.keys(vAxis).length > 0) options.vAxis = vAxis;
		if (config.hAxis)           options.hAxis               = config.hAxis;
		if (config.vAxis)           options.vAxis               = config.vAxis;
		if (config.vAxes)           options.vAxes               = config.vAxes;
		if (config.chartArea)       options['chartArea']        = config.chartArea;
		if (config.widgetHeight)    options['height']           = config.widgetHeight;
		if (config.widgetWidth)     options['width']            = config.widgetWidth;
		if (config.explorer)        options['explorer']         = config.explorer;
		if (config.pointSize)       options['pointSize']        = config.pointSize;
		if (config.series)          options['series']           = config.series;
		if (config.useDateFormat)   options['useDateFormat']    = config.useDateFormat;

		if (config.colors) {
			options['colors'] = config.colors;
		}
		let dataIsDataTable = config.dataIsDataTable;
		this.buildChart(data, chartFunc, options, clearFunc, {dataIsDataTable: dataIsDataTable});
	}
}