import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../../../shared/breadcrumb/breadcrumb.service';
import { UserService } from '../../../../auth/user.service';
import { AdminService } from '../../../../admin/admin.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-equipments',
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.css']
})
export class EquipmentsComponent implements OnInit, OnDestroy {

  equipments: any[];

  constructor(
    private breadcrumb: BreadcrumbService,
    public userService: UserService, 
	private adminService: AdminService,
	private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.getEquipments();
    this.breadcrumb.setBreadcrumb([
      {label: this.translateService.instant('site.equipments'), url: ''}
    ]);
  }

  getEquipments() {
    this.adminService.getEquipments(0).subscribe(
      (response: {equipment: any}) => {
        this.equipments = response.equipment;
      }
    );
  };

  ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
