<div class="row margin-top-lg m-5">
	<div class="row">
		<div class="col-md-2 text-right-md "><strong>{{'time.from_date' | translate}}:</strong></div>
		<div class="col-md-3">
			<div class="input-group">
				<input class="form-control" placeholder="mm/dd/yyyy" name="startDB" [(ngModel)]="startDate" bsDatepicker #startDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<span class="input-group-btn">
					<button class="btn btn-default btn-date" (click)="startDB.toggle()" type="button"><i class="fa fa-calendar"></i></button>
				</span>
			</div>
		</div>
	</div>
	<div class="text-danger" *ngIf="submitted && !validStartFromTime">{{'time.please_enter_a_valid_time_range' | translate}}</div>
	<div class="row">
		<div class="col-md-2 text-right-md margin-top-sm"><strong>{{'time.from_time' | translate}}:</strong></div>
		<div class="col-md-3 no-gutter"><timepicker (isValid)="validStartFromTime = $event" [(ngModel)]="startFromTime" [showSpinners]="false" [showMeridian]="false"></timepicker></div>
	</div>

	<div class="row margin-top-lg">
		<div class="col-md-2 text-right-md "><strong>{{'time.to_date' | translate}}:</strong></div>
		<div class="col-md-3">
			<div class="input-group">
				<input class="form-control" placeholder="mm/dd/yyyy" name="endDB" [(ngModel)]="endDate" bsDatepicker #endDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<span class="input-group-btn">
					<button class="btn btn-default btn-date" (click)="endDB.toggle()" type="button"><i class="fa fa-calendar"></i></button>
				</span>
			</div>
		</div>
	</div>
	<div class="text-danger" *ngIf="submitted && !validEndToTime">{{'time.please_enter_a_valid_time_range' | translate}}</div>
	<div class="row">
		<div class="col-md-2 text-right-md margin-top-sm"><strong>{{'time.to_time' | translate}}:</strong></div>
		<div class="col-md-3 no-gutter"><timepicker (isValid)="validEndToTime = $event" [(ngModel)]="endToTime" [showSpinners]="false" [showMeridian]="false"></timepicker></div>
	</div>
	<div class="text-danger" *ngIf="invalidDates">{{'time.end_start_time' | translate}}</div>
	<div class="margin-top-lg margin-bottom-lg"><button class="btn btn-md btn-info" (click)="fetchData()">{{'g.fetch_data' | translate}}</button></div>
	<div *ngIf="misPicksData.length>0">
		<app-grid-v2
			id="act_first_mispicks"
			[columns]="misPicksColumns"
			[data]="misPicksData">
		</app-grid-v2>
	</div>

</div>