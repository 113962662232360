import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'underscore';
import { SiteDashboardService } from '../../site-dashboard.service';
import { UserService } from '../../../../auth/user.service';
import { BattviewsDashboardService } from '../battviews-dashboard/battviews-dashboard.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';

@Component({
	selector: 'app-site-reports',
	templateUrl: './site-reports.component.html',
	styleUrls: ['./site-reports.component.css']
})
export class SiteReportsComponent implements OnInit, OnDestroy {

	user;
	customerId;
	siteId;
	data;
	siteBattviewsData;
	siteTags;
	siteBattviewsTags;
	customerName: string = '';
	siteName: string = '';
	currentSiteSub: any;
	activeDashboardDataSub: any;

	constructor(
		private siteDashboardService: SiteDashboardService,
		private userService: UserService,
		private sideMenu: SideMenuService,
		private battviewsDashboardService: BattviewsDashboardService,
		private commonUtil: CommonUtil,
	) {
		this.siteDashboardService.setActiveSubTab('reports');
	}

	ngOnInit() {
		this.user = this.userService.getCurrentUser();

		this.currentSiteSub = this.sideMenu.currentSite.subscribe(siteInfo => {
			this.customerId		= siteInfo.customerid;
			this.siteId			= siteInfo.id;
			this.customerName	= siteInfo.customer_name;
			this.siteName		= siteInfo.name;

			if(this.customerId && this.siteId) {
				this.activeDashboardDataSub = this.siteDashboardService.activeDashboardData.subscribe(data => {
					let tempData: any = data
					let options = {
						customerid: this.customerId,
						siteid: this.siteId,
						getTags: true
					};

					this.battviewsDashboardService.getBattviews(options).subscribe((response:any) => {

						this.siteBattviewsData	= this.commonUtil.arrayToAssociativeArray(response.list, 'id');
						this.siteTags			= [];
						this.siteBattviewsTags	= {};

						for(var i in response.tags.siteTags) {
							this.siteTags.push(response.tags.siteTags[i]);
						}

						for(var battviewId in response.tags.deviceTags) {
							var battviewTagsIds = response.tags.deviceTags[battviewId];
							for (var i in battviewTagsIds) {
								var tagId = battviewTagsIds[i];
								if (!this.siteBattviewsTags[tagId]) {
									this.siteBattviewsTags[tagId] = [];
								}
								this.siteBattviewsTags[tagId].push(battviewId);
							}
						}
						tempData.battviewsData		= this.siteBattviewsData;
						tempData.siteTags			= this.siteTags;
						tempData.siteBattviewsTags	= this.siteBattviewsTags;
						tempData.siteAlertsSettings	= tempData.siteSettings;
						tempData.userAlertsSettings	= tempData.userSettings;

						this.data = tempData;
					});
				});
				this.siteDashboardService.getDashboardData(this.customerId, this.siteId);
			}
		});
	}

	ngOnDestroy() {
		if(this.currentSiteSub)
			this.currentSiteSub.unsubscribe();
		if(this.activeDashboardDataSub)
			this.activeDashboardDataSub.unsubscribe();
	}
}