import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonUtil } from '../../../shared/services/utility/common.service';

@Component({
	selector: 'servcie-page-table',
	templateUrl: './servcie-page-tables.component.html',
	styleUrls: ['./servcie-page-tables.component.css']
})
export class ServciePageTablesComponent implements OnInit {
	@Input() columns: {label, key, functionName?, hide?}[] = [];
	@Input() devices: any[] = [];
	@Input() productionIps: any[] = [];
	@Input() deviceType: string = null;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() devicesTags: any = {};
	@Input() deviceUrlSection: string = null;
	@Input() siteTags: any[] = [];
	@Output() onUpdateTags = new EventEmitter<any>(true);
	@Input() siteBattviews: Object = {};
	@Input() siteChargers: Object = {};
	@Input() isACTuser: boolean =  false;
	@Input() tableName: string;

	constructor(public commonUtil: CommonUtil) { }

	ngOnInit() {
		this.columns.forEach((column, idx) => {
			if(column.hide) {
				this.columns.splice(idx, 1);
				idx++;
			}
		})
	}

	onUpdateTagsEvent(event, deviceId) {
		if(event.type == 'remove')
		{
			// remove
			let removedTagId = event.tag.id;
			let deviceTag = [];
			this.devicesTags[deviceId].forEach((tag) => {
				if(tag.id != removedTagId) {
					deviceTag.push(tag);
				}
			})
			this.devicesTags[deviceId] = deviceTag;
		}
		else
		{
			// add
			this.devicesTags[deviceId].push(event.tag);
		}
		this.onUpdateTags.emit(event);
	}
	getItem(item, device){
		if(item.key == 'devices_tags') {
			let tagsNames = [];
			if(this.devicesTags[+device.id]) {
				this.devicesTags[+device.id].forEach(tag => {
					tagsNames.push(tag.tagname)
				});
			}
			return tagsNames.join(", ");
		}

		let returnValue = '';
		if(item.value) {
			returnValue = item.value;
		} else {
			if(item.functionName) {
				if(item.key)
					returnValue = eval(item['functionName']+'('+device[item.key]+')');
				else
					returnValue = eval(item['functionName']+'(device)');
			} else {
				returnValue = device[item.key];
			}
		}
		return returnValue;
	}
	getFaultsText(device) {
		var texts = this.commonUtil.getFaultText(device.mainsenseerrorcode, {enablehalleffectsensing: device.enablehalleffectsensing});
		return texts.join(' - ');
	}
	getBgClass(device) {
		if(this.productionIps.includes(device.ip_address))
			return 'orange-bg';
		if(this.tableName == 'have_not_synched_time') {
			if(device.time_gap > 24*60*60)
				return 'red-bg bold-900';
			
			if(device.time_gap > 30*60)
				return 'red-bg';
		}
		return "";
	}

	showExpandButton(device) {
		if (this.deviceType == 'battview') {
			return this.siteBattviews && this.siteBattviews[device.id] && !this.siteBattviews[device.id].isdeleted;
		} else if (this.deviceType == 'charger') {
			return this.siteChargers && this.siteChargers[device.id] && !this.siteChargers[device.id].isdeleted;
		}
	}
}