import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-plc-activity',
	templateUrl: './plc-activity.component.html',
	styleUrls: ['./plc-activity.component.css']
})
export class PlcActivityComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';
	@Input() isACTuser: boolean = false;

	@ViewChild("widget", {static: true}) widget;

	config: ColumnChartConfig;
	tableColumns: any[];
	title: string;
	elementId: string = 'plc_activity';
	widgetClass: string = 'auto-height-widget';
	siteWidgetOptions: any = {
		hasOperation: true,
		hasChargerModels: true,
		hasDate: false,
		hasDateRange: true,
		isSitePerformanceWidget: true,
		isChargers: true,
		showWidgetSummary: true
	};

	constructor(
		private chartUtil: ChartUtil,
		private translateService: TranslateService
	) {
		this.title = this.translateService.instant('charger.plc_activity');
		this.tableColumns = [
			{title: this.translateService.instant('device.charger_id'), property: "chargerusername"},
			{title: this.translateService.instant('charger.charger_sn'), property: "serialnumber"},
			{title: "% "+this.translateService.instant('charger.plc'), property: "value"}
		];
	}

	ngOnInit() {
		if(!this.isACTuser) {
			this.widget.hideWidget = true;
		}
		this.config = new ColumnChartConfig({
			// isPercent: true,
			xTitle: "% "+this.translateService.instant('charger.plc'),
			yTitle: this.translateService.instant("g.chargers"),
			vAxisMinValue: 0
		});

		this.config.formatFunction = (data) => {
			data = data.list;
			let formattedData: any[] = [
				[
					{"label": this.translateService.instant('g.name'), "type": "string"},
					{"label": this.translateService.instant('g.value'), "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'},
				]
			];

			for(let i = 0; i < data.length; i++) {
				let item: any = data[i];
				formattedData.push(
					[
						{v: item.x},
						{v: item.y},
						{v: ''},
						{v: 'color: ' + this.chartUtil.getColor(item.x)}
					]
				);
			}

			return formattedData;
		};
	}

	ngOnChanges(changes) {
		if(changes.data && this.data)
			this.widget.hideWidget = !this.data.data.siteHasPLCEnabled;
	}
}
