import { Component, OnInit, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as _ from 'underscore';
import * as moment from 'moment';

import { SiteDashboardService } from '../site-dashboard.service';
import { UserService } from '../../../auth/user.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { SideMenuService } from '../../../shared/side-menu/side-menu.service';
import { NavigationService } from '../../../shared/services/navigation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-alerts-settings',
	templateUrl: './alerts-settings.component.html',
	styleUrls: ['./alerts-settings.component.css']
})
export class AlertsSettingsComponent implements OnInit, OnDestroy {

	currentUser: any;
	userIsSubscribed: boolean = false;
	customerID: number = 0;
	siteID: number = 0;
	currentSite: any = {};

	alert: any = {
		missed_connection_alert: [],
		missedConnectionAlert: []
	};
	oldAlertsSettings: any = {
		missed_connection_alert: [],
		missedConnectionAlert: []
	};
	usersHaveAccessToSite: any;
	ACTusersHaveAccessToSite: any;
	SOCChargeUsersHaveAccessToSite: any;
	chargerEnegySubscribed: any;
	selectedDays: any = {};
	days: any = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
	daysSelected: any;
	commonPeriods: any = [];
	isEditPluggedInFilters: boolean = false;
	runSLider: boolean;

	chargerAlertTypes: any = {
		value: 'none',
		options: {
			showTicksValues: true,
			stepsArray: [
				{value: "latching"},
				{value: "both"}
			],
			translate: function(value){
				if(value == 'both')
					return "latching and non-latching";
				return value;
			}
		}
	};

	siteSubscription: any;
	constructor(
		public userService: UserService,
		private siteDashboardService: SiteDashboardService,
		private commonUtil: CommonUtil,
		private route: ActivatedRoute,
		private notificationMessage: NotificationMessageService,
		private sideMenuService: SideMenuService,
		private router: Router,
		public navigation: NavigationService,
		private elementRef: ElementRef,
		private renderer: Renderer2,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();
		this.siteDashboardService.setActiveTab('settings');
		this.siteDashboardService.setActiveSubTab('alerts-settings');

		if (this.currentUser.accessFunctions['edit_alert_setting_disable'] == 'read') {
			this.router.navigate(['/sites-map']);
		}

		var sliderDisabled = false;
		if(this.userService.hasAccessFunction('edit_alert_setting_disable')) {
			sliderDisabled = true;
		}

		this.chargerAlertTypes.options.disabled = sliderDisabled;

		this.route.parent.params.subscribe(params => {
			this.customerID = params['customerid'];
			this.siteID = params['siteid'];
			this.siteSubscription = this.sideMenuService.currentSite.subscribe(siteInfo => {
				this.currentSite = siteInfo;
				this.alertsHandler();
			});
		});
	}

	ngOnDestroy() {
		this.siteSubscription.unsubscribe();
	}

	setDirtyFields() {
		let dirtyFields = {};
		let oldAlertsSettings = this.oldAlertsSettings;

		let arrayFields = ['cycle_abnormal_faults'];
		for (let key in oldAlertsSettings) {
			
			if(arrayFields.indexOf(key) != -1) {

				if(oldAlertsSettings[key] && this.alert[key] && !this.commonUtil.arrayCompare(oldAlertsSettings[key], this.alert[key])) {
					dirtyFields[key] = this.alert[key];
				}
			} else if(oldAlertsSettings[key] != this.alert[key]) {
			
				dirtyFields[key] = this.alert[key];
			}
		}

		return dirtyFields;
	}

	alertsHandler() {
		this.siteDashboardService.getAlertsSettingsWithSubscribers(this.siteID, {getUsersAlertsSettings: false}).subscribe((response: any) => {
			let usersHaveAccessToSite = [];
			response.usersHaveAccessToSite.forEach((item) => {
				item.appearanceName = item.firstname + ' ' + item.lastname;
				usersHaveAccessToSite.push(item);
			});
			this.usersHaveAccessToSite = usersHaveAccessToSite;

			let ACTusersHaveAccessToSite	= [];
			response.ACTusersHaveAccessToSite.forEach((item) => {
				item.appearanceName = item.firstname + ' ' + item.lastname;
				ACTusersHaveAccessToSite.push(item);
			});
			this.ACTusersHaveAccessToSite = ACTusersHaveAccessToSite;
			this.SOCChargeUsersHaveAccessToSite = this.ACTusersHaveAccessToSite.concat(this.usersHaveAccessToSite);
			this.alert = response.alertsSettings;

			this.userIsSubscribed = false;

			if(this.alert) {
				this.alert.temperature_limit = parseFloat((this.alert.temperature_limit * 1.8 + 32).toFixed(1)); //In Fahrenheit 
				this.alert.cold_temperature_limit = parseFloat((this.alert.cold_temperature_limit * 1.8 + 32).toFixed(1)); //In Fahrenheit
			}

			this.oldAlertsSettings = Object.assign({}, response.alertsSettings);

			if(this.alert && this.alert.subscribed_users) {
				this.alert.subscribed_users.forEach((user, i) => {
					this.alert.subscribed_users[i] = parseInt(user);
				});
				this.userIsSubscribed = this.userIsSubscribed || this.alert.subscribed_users.includes(this.currentUser.id);
			}

			if (this.alert && this.alert.charger_energy_subscribed_user) {
				this.alert.charger_energy_subscribed_user.forEach((item, i) => {
					this.alert.charger_energy_subscribed_user[i] = parseInt(item);
				});
				this.userIsSubscribed = this.userIsSubscribed || this.alert.charger_energy_subscribed_user.includes(this.currentUser.id);
			}
			
			if(this.alert && this.alert.subscribed_act_users) {
				this.alert.subscribed_act_users.forEach((user, i) => {
					this.alert.subscribed_act_users[i] = parseInt(user);
				});
				this.userIsSubscribed = this.userIsSubscribed || this.alert.subscribed_act_users.includes(this.currentUser.id);
			}

			if(this.alert && this.alert.subscribed_soc_charger_users) {
				this.alert.subscribed_soc_charger_users.forEach((user, i) => {
					this.alert.subscribed_soc_charger_users[i] = parseInt(user);
				});
			}

			if (!this.alert.missed_connection_alert) {
				this.alert.missed_connection_alert = [];
			}

			this.alert.missedConnectionAlert = [];
			for (let i = 0; i < 7; i++) {
				this.alert.missedConnectionAlert[i] = [];
				if (this.alert.missed_connection_alert[i] && this.alert.missed_connection_alert[i].length > 0) {
					for(var period in this.alert.missed_connection_alert[i]) {
						if(this.alert.missed_connection_alert[i][period].end == 86400) {
							this.alert.missed_connection_alert[i][period].end = 0;
						}
						var start	= this.commonUtil.timeFormat(+this.alert.missed_connection_alert[i][period].start, {timeSystem:12});
						var end		= this.commonUtil.timeFormat(+this.alert.missed_connection_alert[i][period].end, {timeSystem:12});
						
						this.alert.missedConnectionAlert[i].push({interval: start+" - "+end});
					}
				} else {
					this.alert.missed_connection_alert[i] = [];
					this.alert.missedConnectionAlert[i].push({interval: '(no work)'});
				}
			}

			this.alert.gse_charging_header = this.alert.gse_charging_header || 'GSE Charging';

			this.setDateSliders();
		});
	}

	updateAlertsSettings() {
		let inputs = this.elementRef.nativeElement.querySelectorAll('input');
		inputs.forEach((input) => {
			this.renderer.removeClass(input, 'invalid-input');
		});

		let selects = this.elementRef.nativeElement.querySelectorAll('input');
		selects.forEach((select) => {
			this.renderer.removeClass(select, 'invalid-input');
		});

		// check if charger/battview disconnection limit have changed from daily to weekly
		let oldChargerDisconnectionIsDaily		= this.commonUtil.dataValidator('integer', {'min': 2, 'max': 24},	this.oldAlertsSettings.charger_disconnection_timespan),
			oldChargerDisconnectionIsWeekly		= this.commonUtil.dataValidator('integer', {'min': 25, 'max': 120},	this.oldAlertsSettings.charger_disconnection_timespan),
			oldBattviewDisconnectionIsDaily		= this.commonUtil.dataValidator('integer', {'min': 2, 'max': 24},	this.oldAlertsSettings.battview_disconnection_timespan),
			oldBattviewDisconnectionIsWeekly	= this.commonUtil.dataValidator('integer', {'min': 25, 'max': 120},	this.oldAlertsSettings.battview_disconnection_timespan),
			newChargerDisconnectionIsDaily		= this.commonUtil.dataValidator('integer', {'min': 2, 'max': 24},	this.alert.charger_disconnection_timespan),
			newChargerDisconnectionIsWeekly		= this.commonUtil.dataValidator('integer', {'min': 25, 'max': 120},	this.alert.charger_disconnection_timespan),
			newBattviewDisconnectionIsDaily		= this.commonUtil.dataValidator('integer', {'min': 2, 'max': 24},	this.alert.battview_disconnection_timespan),
			newBattviewDisconnectionIsWeekly	= this.commonUtil.dataValidator('integer', {'min': 25, 'max': 120},	this.alert.battview_disconnection_timespan);

		if(
			(oldChargerDisconnectionIsDaily && newChargerDisconnectionIsWeekly) ||
			(oldChargerDisconnectionIsWeekly && newChargerDisconnectionIsDaily) ||
			(oldBattviewDisconnectionIsDaily && newBattviewDisconnectionIsWeekly) ||
			(oldBattviewDisconnectionIsWeekly && newBattviewDisconnectionIsDaily)
		){
			if(!confirm(this.translateService.instant('g.change_disconnection_limit_prompt')))
				return;
		}

		if(!this.alert.reports_since_installation) this.alert.reports_since_installation = false;

		var invalidFields = this.validateAlertsSettings(this.alert);
		if(invalidFields.length > 0) {
			this.notificationMessage.setMessage('Invalid input!', 'danger');
			let form = this.elementRef.nativeElement.querySelectorAll('form')[0];
			for(let idx in form) {
				let ele = form[idx];
				if(ele && typeof ele.getAttribute === 'function' && invalidFields.indexOf(ele.getAttribute('name')) > -1) {
					this.renderer.addClass(ele, 'invalid-input');
				}
			}
		} else {

			let dirtyFields = this.setDirtyFields();
			if(Object.keys(dirtyFields).length === 0) {
				return;
			}
			this.siteDashboardService.updateSiteAlertsSettings(dirtyFields, this.siteID).subscribe((response:any) => {
				if(response.httpStatus == 'error') {
					if(response.msg == 'invalidFields') {
						this.notificationMessage.setMessage('Invalid input!', 'danger');
						let form = this.elementRef.nativeElement.querySelectorAll('form')[0];
						for(let idx in form) {
							let ele = form[idx];
							if(ele && typeof ele.getAttribute === 'function' && invalidFields.indexOf(ele.getAttribute('name')) > -1) {
								this.renderer.addClass(ele, 'invalid-input');
							}
						}
					}
				} else {
					this.siteDashboardService.deleteDashboardData(this.customerID, this.siteID);
					this.notificationMessage.setMessage('globalSuccessMsg', 'success');
					this.alertsHandler();
				}
			});
		}
	}

	anyDaySelected() {
		let selectedDays = this.getDaysSelected();
		return Object.keys(selectedDays).length > 0;
	}

	getDaysSelected() {
		var daysSelected = {};
		for (var selectedDay in this.selectedDays) {
			if (this.selectedDays[selectedDay] && this.selectedDays[selectedDay] == true) {
				daysSelected[+selectedDay] = this.days[+selectedDay];
			}
		}
		return daysSelected;
	}

	getCommonPeriods(daysSelected) {
		var daysIntervals = [];
		var daysSelectedArray = Object.keys(daysSelected);

		var missed_connection_alert = JSON.parse(JSON.stringify(this.alert.missed_connection_alert));
		if (missed_connection_alert.length === 0) {
			return [];
		}
		for(var dayPeriods in daysSelectedArray) {
			daysIntervals.push(missed_connection_alert[daysSelectedArray[dayPeriods]]);
		}

		var commonPeriods = daysIntervals[Object.keys(daysIntervals)[0]];

		if (!commonPeriods || Object.keys(commonPeriods).length === 0)
			return [];

		var forceNoCommon = false;
		commonPeriods.forEach((commonInterval, index) => {
			if (!forceNoCommon) {
				for(var day in daysIntervals) {

					// no periods in any day means there is no common period
					if (!daysIntervals[day]) {
						forceNoCommon = true;
						commonPeriods = [];
						break;
					}
					if (+day > 0) {
						var noCommon = true;

						daysIntervals[day].forEach((interval) => {
							if(commonInterval.s == interval.s && commonInterval.e == interval.e)
								noCommon = false;
						});

						if(noCommon) {
							commonPeriods.splice(index, 1);
							index--;
						}
					}
				}
			}
		});

		return commonPeriods;
	}

	updateMissedConnection() {
		this.daysSelected	= this.getDaysSelected();
		this.commonPeriods	= this.getCommonPeriods(this.daysSelected);
		this.formatPeriods();
		this.isEditPluggedInFilters = true;
	}

	formatPeriods() {
		var formattedPeriods = [];
		for (var period of this.commonPeriods) {
			period.start_formatted	= this.commonUtil.timeFormat(+period.start, {limitToOneDay: true});
			period.end_formatted	= this.commonUtil.timeFormat(+period.end, {limitToOneDay: true});
			formattedPeriods.push(period);
		}
		return formattedPeriods;
	}

	addNewRange() {
		this.commonPeriods.push({});
	}

	removeRange(index) {
		this.commonPeriods.splice(index, 1);
	}

	cancelAddingRange() {
		this.commonPeriods = [];
		this.isEditPluggedInFilters = false;
		this.selectedDays = {};
	}

	updateRanges() {
		let errorText = "";
		let commonPeriods = [];
		for (var periodIndex in this.commonPeriods) {
			if(!this.commonPeriods[periodIndex].start_formatted || !this.commonPeriods[periodIndex].end_formatted) {
				errorText = 'Please enter valid plugged in filter for empty range';
				break;
			}

			commonPeriods.push({
				start: this.commonUtil.clockTimeToTimeSpan(this.commonPeriods[periodIndex].start_formatted),
				end: this.commonUtil.clockTimeToTimeSpan(this.commonPeriods[periodIndex].end_formatted)
			});
		}
		if(errorText) {
			return this.notificationMessage.setMessage(errorText, 'danger');
		}

		// check if there any ranges overlapped
		if (!this.validatePeriods()) {
			return this.notificationMessage.setMessage('Invalid Plugged in Filters Periods', 'danger');
		}

		this.commonPeriods = commonPeriods;

		this.siteDashboardService.updatePluggedInAlertsFiltersSettings({periods: commonPeriods,days: Object.keys(this.daysSelected)}, this.siteID, this.customerID).subscribe((response: any) => {
			for (var dayIndex in this.daysSelected) {
				this.alert.missed_connection_alert[dayIndex] = this.commonPeriods;
				this.alert.missedConnectionAlert[dayIndex] = [];
				if (!this.alert.missed_connection_alert[dayIndex] || this.alert.missed_connection_alert[dayIndex].length === 0) {
					this.alert.missed_connection_alert[dayIndex] = [];
					this.alert.missedConnectionAlert[dayIndex].push({interval: '(no work)'});
				} else {
					for(var period in this.alert.missed_connection_alert[dayIndex]) {
						var start	= this.commonUtil.timeFormat(+this.alert.missed_connection_alert[dayIndex][period].start, {timeSystem:12});
						var end		= this.commonUtil.timeFormat(+this.alert.missed_connection_alert[dayIndex][period].end, {timeSystem:12});
						
						this.alert.missedConnectionAlert[dayIndex].push({interval: start+" - "+end});
					}
				}
			}
			this.setDateSliders();
			this.cancelAddingRange();
			this.notificationMessage.setMessage(response,'success');
			this.runSLider = true;
		});
	};

	validatePeriods() {
		var allPeriods = Object.assign([], this.commonPeriods);

		var sortedPeriods = allPeriods.sort((previous, current) => {

			// get the start date from previous and current
			var previousTime = previous.start;
			var currentTime = current.start;

			if(currentTime == 0) {
				current.end = 86400;
			}

			// if the previous is earlier than the current
			if (previousTime < currentTime) return -1;

			// if the previous time is the same as the current time
			if (previousTime === currentTime) return 0;

			// if the previous time is later than the current time
			return 1;
		});

		var overlap = sortedPeriods.reduce((overlap, current, idx, arr) => {
			// get the previous range
			if (idx === 0) {
				return overlap;
			}
			var previous = arr[idx-1];

			// check for any overlap
			var previousEnd = previous.end;
			var currentStart = current.start;
			overlap = overlap || (previousEnd >= currentStart);

			return overlap;
		}, false);

		return !overlap;
	}

	setDateSliders() {
		let dateSliders = [];
		for(let sliderIndex in this.alert.missed_connection_alert) {
			let slider = this.alert.missed_connection_alert[sliderIndex];

			// to draw an empty slider if there is no values on a certain day
			slider = _.isEmpty(slider) ? [{}] : slider;
			var sliderIntervals = [];

			for (let i in slider) {
				var value = slider[i] || {};
				var color = this.commonUtil.getRandomColor();
				var start = _.isUndefined(value.start) ? null : moment(new Date()).startOf('day').seconds(value.start).format('HH');
				var end = _.isUndefined(value.end) ? null : moment(new Date()).startOf('day').seconds((+value.end) - 1).format('HH');
				
				sliderIntervals.push([start, end]);
				if (this.alert.missedConnectionAlert[sliderIndex][i] != undefined) {
					this.alert.missedConnectionAlert[sliderIndex][i].color = color;
				}
			}

			dateSliders.push(sliderIntervals);
		}

		this.runSLider = true;
	}

	validateAlertsSettings(alert: any) {
		var alertFreq = ['none', 'daily', 'weekly'];

		var validationRules = {
			'override_temperature_limit'			: [{type: 'boolean'}],
			'enable_no_alert'						: [{type: 'boolean'}],
			'temperature_limit'						: [{type: 'float', min: 90, max: 160}],
			'high_temperature_limit_days'			: [{type: 'integer', min: 1, max: 4}],
			'site_disconnection_timespan'			: [{type: 'integer', min: 4, max: 48}],
			'cold_temperature_limit'				: [{type: 'float', min: 0, max: 80}],
			'conventional_ebu_limit'				: [{type: 'float', min: 0.25, max: 2}],
			'opportunity_ebu_limit'					: [{type: 'float', min: 0.25, max: 2}],
			'fast_ebu_limit'						: [{type: 'float', min: 0.25, max: 2}],
			'lithium_ebu_limit'						: [{type: 'float', min: 0.25, max: 2}],
			'water_level_low_days'					: [{type: 'integer', min: 1, max: 7}],
			'weekly_dispatch_email_day'				: [{type: 'integer', min: 1, max: 5}],
			'missed_plugged_in_timespan'			: [{type: 'integer', min: 1, max: 168}],
			'idle_times_limit'						: [{type: 'integer', min: 1, max: 1440}],
			'deep_use_limit'						: [{type: 'float', min: 1, max: 10}],
			'deep_discharge_limit'					: [{type: 'float', min: 1, max: 10}],
			'potential_weak_cells_limit_days'		: [{type: 'integer', min: 1, max: 4}],
			'deep_discharge_limit_days'				: [{type: 'integer', min: 1, max: 4}],
			'expected_fast_chargers_cycles'			: [{type: 'integer', min: 1, max: 10}],
			'expected_conventional_chargers_cycles'	: [{type: 'integer', min: 1, max: 10}],
			'expected_opportunity_chargers_cycles'	: [{type: 'integer', min: 1, max: 10}],
			'site_weekly_working_days'				: [{type: 'integer', min: 1, max: 7}],
			'deep_use_frequency'					: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'deep_discharge_frequency'				: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'over_charge_frequency'					: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'missed_plugged_in_frequency'			: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'idle_times_frequency'					: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'temperature_send_frequency'			: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'cold_temperature_frequency'			: [{type: 'inArray', subType: 'string', values: ['none', 'weekly']}],
			'ebu_alert_send_frequency'				: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'water_level_send_frequency'			: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'missed_eq_send_frequency'				: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'missed_weekly_eq_send_frequency'		: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'sulfated_battery_frequency'			: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'missed_fi_send_frequency'				: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'site_disconnection_frequency'			: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'charger_alert_frequency'				: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'pm_faults_frequency'					: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'cycle_abnormal_stop_frequency'			: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'charger_alert_type'					: [{type: 'inArray', subType: 'string', values: ['latching', 'both']}],
			'cycle_abnormal_faults'					: [{type: 'arraySubset', values: ['charger-restart', 'aborted-operation', 'timeout', 'pm-fauts', 'battery-faults']}],
			'battview_disconnection_frequency'		: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'charger_disconnection_frequency'		: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'charger_disconnection_timespan'		: [{type: 'integer', min: 2, max: 120}],
			'battview_disconnection_timespan'		: [{type: 'integer', min: 2, max: 120}],
			'charger_utilization_alert_frequency'	: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'missed_plugin_cycles_alert_frequency'	: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'expected_plugin_charge_cycles'			: [{type: 'integer', min: 1, max: 10}],
			'weekly_plugin_working_days'			: [{type: 'integer', min: 1, max: 7}],
			'view_truck_id'							: [{type: 'boolean'}],
			'bv_charge_events_only_for_charge_ahr'	: [{type: 'boolean'}],
			'bv_inuse_events_only_for_charge_ahr'	: [{type: 'boolean'}],
			'receive_customer_report_frequency'		: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'reports_since_installation'			: [{type: 'boolean'}],
			'gse_charging_header'					: [{type: 'string', min: 0, max: 50}],
		};

		var invalidFields = [];

		for(var field in alert) {
			var rules = validationRules[field] || [];
			rules.forEach((rule) => {

				if(invalidFields.indexOf(field) == -1) {

					var isValid = this.commonUtil.dataValidator(rule.type, rule, alert[field]);

					if(!isValid)
						invalidFields.push(field);
				}
			});

			if(field == 'charger_disconnection_timespan') {
			
				var isValid = true;

				if(alert.charger_disconnection_frequency == 'daily')
					isValid = this.commonUtil.dataValidator('integer', {'min': 2, 'max': 24}, alert[field]);
				else if(alert.charger_disconnection_frequency == 'weekly')
					isValid = this.commonUtil.dataValidator('integer', {'min': 25, 'max': 120}, alert[field]);

				if(!isValid)
					invalidFields.push(field);
			} else if(field == 'battview_disconnection_timespan') {
				
				var isValid = true;

				if(alert.battview_disconnection_frequency == 'daily')
					isValid = this.commonUtil.dataValidator('integer', {'min': 2, 'max': 24}, alert[field]);
				else if(alert.battview_disconnection_frequency == 'weekly')
					isValid = this.commonUtil.dataValidator('integer', {'min': 25, 'max': 120}, alert[field]);

				if(!isValid)
					invalidFields.push(field);
			}
		}

		return invalidFields;
	}

	showAlertsSubscribedUsers() {
		if(this.currentSite.act_intelligent_type == 0) {
			return false;
		}

		if(this.currentUser.isACTuser) {
			return true;
		}
		
		var showUsers = true;
		if(this.currentUser.isEndClient || this.currentUser.isTruckDealer) {
			showUsers = false;
		}
		if(!this.userIsSubscribed) {
			showUsers = false;
		}
		return showUsers;
	}
}