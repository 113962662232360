import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from 'underscore';
import { AdminService } from '../admin.service';
import { UserService } from '../../auth/user.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-sites',
	templateUrl: './sites.component.html',
	styleUrls: ['./sites.component.css']
})
export class SitesComponent implements OnInit, OnDestroy, AfterViewInit {
	siteObj = {
		siteid: 0,
		customerid: 0
	};

	deleteObj = {
		siteid: 0,
		customerid: 0
	};

	sites = <any>[];
	currentUser = {};
	canManageACTintelligent = false;
	deleteMsg: string;
	deleteTitle: string;
	confirmedField: string = '';
	battviewMobileSiteID = 2780;
	productionLineSiteID = 554;

	@ViewChild("modal") modal;

	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute,
		private userService: UserService,
		private notificationMessage: NotificationMessageService,
		private breadcrumb: BreadcrumbService,
		private router: Router,
		private translateService: TranslateService
	) {
	}

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([
			{'label': 'Customers', 'url': '/customers'},
			{'label': 'Sites', 'url': ''},
		]);
		this.currentUser = this.userService.getCurrentUser();
		this.route.params.subscribe(
			params => {
				this.siteObj['customerid'] = Number(params['customerId'])
				this.getSites();
			}
		);
		
		this.canManageACTintelligent = false;
		let access = this.currentUser['accessFunctions'];

		if(access.accounting || access.act_intelligent_management || access.admin_act_intelligent)
			this.canManageACTintelligent = true;
	}

	ngAfterViewInit() {
		this.modal.onClose.subscribe(
			(agree) => {
				if(agree) {
					this.siteDelete(this.deleteObj.customerid, this.deleteObj.siteid);
				} else {
					let msg = '';
					switch (this.confirmedField) {
						case 'battviewmobile':
							msg = "Delete operation failed since you have battviewmobile or more assigned to it.";
						break;
						case 'request':
							msg = "Delete operation failed since you have request or more assigned to it.";
						break;
					}

					if(msg)
						this.notificationMessage.setMessage(msg, 'danger', 10000);
				}
			}
		);
	}

	getSites() {
		this.adminService.getSite(this.siteObj).subscribe(
			(data: Array<any>) => {
				this.sites = _.sortBy(data['list'], 'name');
			}
		);
	}

	showSiteDeleteModal(customerid, siteid) {
		this.deleteTitle = 'Confirm Deletion';
		switch (this.confirmedField) {
			case "battviewmobile":
				this.deleteMsg = "There are some battviewmobile studies for this site, are you sure you want to delete it?";
			break;
			case "request":
				this.deleteMsg = "There are requests for this site, are you sure you want to delete it?";
			break;
			default:
				this.deleteMsg = "Are you sure you want to delete this site? This operation can't be undone";
				this.deleteTitle = 'Delete Site';
			break;
		}

		this.deleteObj = {customerid, siteid};

		this.modal.show();
	}

	siteDelete(customerid, siteid) {
		this.adminService.deleteCustomerOrSite(customerid, siteid, this.confirmedField).subscribe(
			data => {
				this.confirmedField = '';
				if(data['httpStatus'] == 'error') {
					let errorMessage;
					let validErrors = ['has_battview','has_charger','has_battviewmobile', 'has_site', 'has_request', 'has_user'];
					if (validErrors.indexOf(data['msg']) > -1) {
						switch (data['msg']) {
							case 'has_battview':
								errorMessage = "Delete operation failed since you have battview or more assigned to it.";
							break;
							case 'has_charger':
								errorMessage = "Delete operation failed since you have charger or more assigned to it.";
							break;
							case 'has_battviewmobile':
								this.confirmedField = 'battviewmobile';
								this.showSiteDeleteModal(customerid, siteid);
							break;
							case 'has_site':
								errorMessage = "Delete operation failed since you have site or more assigned to it.";
							break;
							case 'has_request':
								errorMessage = "Delete operation failed since request or more assigned to it.";
							break;
							case 'has_user':
								errorMessage = "Delete operation failed since you have user or more assigned to it.";
							break;
						}
						
						if(data['msg'] != 'has_battviewmobile') {
							this.notificationMessage.setMessage(errorMessage, 'danger', 10000);
						}
					}
				} else {
					this.getSites();
				}
			}
		)
	}

	autoCorrectDevices(siteid, deviceType) {

		this.adminService.autoCorrentDevices(siteid, deviceType, []).subscribe(res=> {
			this.notificationMessage.setMessage(this.translateService.instant('device.auto_correct_msg') , 'success', 10000, true);
		});
	}

	ngOnDestroy() {
		this.modal.onClose.unsubscribe();
		this.breadcrumb.hide();
	}
}