import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-battview-plc-activity',
	templateUrl: './battview-plc-activity.component.html',
	styleUrls: ['./battview-plc-activity.component.css']
})
export class BattviewPlcActivityComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';
	@Input() isACTuser: boolean = false;

	@ViewChild("widget", {static: true}) widget;

	config: ColumnChartConfig;
	tableColumns: any[];
	title: string;
	elementId: string = 'plc_summary';
	widgetClass: string = 'auto-height-widget';
	siteWidgetOptions: any = {
		hasOperation: false,
		hasChargerModels: false,
		hasDate: false,
		hasDateRange: true,
		isSitePerformanceWidget: true,
		isChargers: false,
		showWidgetSummary: true
	};

	constructor(
		private chartUtil: ChartUtil,
		private translateService: TranslateService
	) {
		this.title = this.translateService.instant('charger.plc_activity');
	}

	ngOnInit() {
		if(!this.isACTuser) {
			this.widget.hideWidget = true;
		}
		this.config = new ColumnChartConfig({
			// isPercent: true,
			xTitle: "% "+this.translateService.instant('charger.plc'),
			yTitle: this.translateService.instant("g.chargers"),
			vAxisMinValue: 0
		});

		this.config.formatFunction = (data) => {
			data = data.list;
			let formattedData: any[] = [
				[
					{"label": this.translateService.instant('g.name'), "type": "string"},
					{"label": this.translateService.instant('g.value'), "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'},
				]
			];

			for(let i = 0; i < data.length; i++) {
				let item: any = data[i];
				formattedData.push(
					[
						{v: item.x},
						{v: item.y},
						{v: ''},
						{v: 'color: ' + this.chartUtil.getColor(item.x)}
					]
				);
			}

			return formattedData;
		};
	}

	ngOnChanges() {
		if(this.data) {
			let userAlertsSettings = this.data.siteSettings.userSettings;
			this.tableColumns = [{title: "Battery ID", property: "battery_id"}];
			if (userAlertsSettings.view_truck_id) {
				this.tableColumns.push({title: "Truck ID", property: "truck_id"});
			}
			this.tableColumns.push({title: "Battery SN", property: "battery_sn"});
			this.tableColumns.push({title: "% "+this.translateService.instant('charger.plc'), property: "value"});
		}
	}
}