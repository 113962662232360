<app-customized-widget 
	*ngIf="!hideWidget"  
	title="BATTview List" 
	[widgetId]="widgetId"
	[options]="options">
	<div *appTransclude>
		<app-grid-v2 *ngIf="currentSite.id"
			[id]="widgetId"
			[fileName]="widgetId+'_v1_'+currentSite.id"
			[columns]="columns"
			[data]="data"
		></app-grid-v2>
	</div>
</app-customized-widget>