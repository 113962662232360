<div
	*ngFor="let charger of chargersList"
	class="margin-top-xs margin-bottom"
	style="position: relative;">
	<a 
		*ngIf="charger['batteryid']; else noBattery" [routerLink]="charger['batteryid_link']" >
		<img
			class="img-6"
			[src]="images[groupActiveTab[itemId]]"
			height="57"
			width="90">
	</a>
	<ng-template #noBattery>
	  <img
		  class="img-6"
		  [src]="images[groupActiveTab[itemId]]"
		  height="57"
		  width="90">
	</ng-template>
	<span class="over-img-6">{{charger.currentSOC}}
		<span style="font-size: 14px; font-weight: 100; opacity: 0.8; margin-left: 1px;">%</span>
	</span>
	<div
		class="row location">
		<div 
			class="col-md-6 col-md-push-3 col-xs-7 col-xs-push-5 light">{{'act.location' | translate}}</div>
		<div
			class="unplug-container pointer"
			*ngIf="groupActiveTab[itemId] == 'ready' && !charger.connected"
			(click)="unplugReadyCharger(charger)">
			<button class="btn btn-primary">
				<img class="unplug-img"
					src="/images/unplug_white.png">
			</button>
		</div>
	</div>
	<div class="row">
		<div 
			class="charger col-md-6 col-md-push-3 col-xs-4 col-xs-push-5">
			<span
				class="pointer"
				[pTooltip]="getRankedToltip(charger)"
				tooltipPosition="right"
				[escape]="false"
				[routerLink]="charger['link']" [queryParams]="charger['queryParams']"
				>{{charger['name']}}
			</span>
		</div>
	</div>
</div>