<form #registerUser="ngForm" (ngSubmit)="register()">
	<div class="row">
		<div class="col-md-6 col-md-offset-3">
			<div class="boxs3">
				<h2>{{'user.create_an_account' | translate}}</h2>
				<div class="panel panel-default">
					<div class="panel-heading">{{'user.user_info' | translate}}</div>
				</div>
				<div class="form-field">
					<label for="firstName"><span class="text-danger">* </span>{{'user.first_name' | translate}}</label>
					<input type="text" class="form-control" [(ngModel)]="guest.firstname" name="firstname" placeholder="{{'user.first_name' | translate}}" required/>
				</div>
				<br/>
				<div class="form-field">
					<label for="lastName"><span class="text-danger">* </span>{{'user.last_name' | translate}}</label>
					<input type="text" class="form-control" [(ngModel)]="guest.lastname" name="lastname" placeholder="{{'user.last_name' | translate}}" required/>
				</div>
				<br/>
				<div class="form-field">
					<label for="email"><span class="text-danger">* </span>{{'user.email' | translate}}</label>
					<input email type="email" name='email' [(ngModel)]="guest.email" placeholder="{{'user.email' | translate}}" required class="form-control" (ngModelChange)="checkDomain($event)" #email='ngModel'/>
					<span class="text-danger" *ngIf="email.invalid && (email.dirty || email.touched)">{{'user.invalid_email_address' | translate}}</span>
					<span class="text-danger" *ngIf="email.valid && invalidEmailDomain">{{'user.please_use_your_corporate_email' | translate}}</span>
				</div>
				<br/>
				<div class="form-field">
					<label for="phoneno"><span class="text-danger">* </span>{{'user.phone_no' | translate}}</label>
					<input type="text" class="form-control" (keypress)="keyPressNumbers($event)" (ngModelChange)="onPhoneNumberChanged($event)" [(ngModel)]="guest.phoneno" name='phoneno' placeholder="{{'user.phone_no' | translate}}" required>
					<span class="text-danger" *ngIf="phoneNumberHasDashes"> {{'user.remove_dashes_from_phone_number' | translate}} </span>
				</div>
				<br/>
				<div class="form-field">
					<label for="companyname"><span class="text-danger">* </span>{{'user.company_name' | translate}}</label>
					<input type="text" class="form-control" [(ngModel)]="guest.companyname" placeholder="{{'user.company_name' | translate}}" name='companyname' required>
				</div>
				<br/>
				<div class="form-field">
					<label for="isdealer"><span class="text-danger">* </span>{{'user.dealer_q' | translate}}</label>
					<label><input type="radio" required [(ngModel)]="guest.isdealer" name='isdealer' class="form-control" [value]="false"/> {{'g.no' | translate}} </label>
					<label><input type="radio" required [(ngModel)]="guest.isdealer" name='isdealer' class="form-control" [value]="true"/> {{'g.yes' | translate}}</label>
				</div>
				<br/>
				<div class="form-field">
					<label for="rolename">{{'user.role' | translate}}</label>
					<input type="text" class="form-control" [(ngModel)]="guest.rolename" name='rolename' placeholder="{{'user.role' | translate}}">
				</div>
				<br/>
				<div class="form-field">
					<label for="website">{{'user.website' | translate}}</label>
					<input type="text" class="form-control" [(ngModel)]="guest.website" name='website' placeholder="{{'user.website' | translate}}">
				</div>
				<br/>
				<p class="text-danger" *ngIf="registerUser.invalid" >* {{'g.please_fill_all_required_fields' | translate}}</p>
				<p-captcha siteKey="6LeVcCAUAAAAAEKZnR7tM3nEinNQE0JEzAbmQqXj" (onResponse)="guest.recaptcha = $event.response" (onExpire)="guest.recaptcha = ''"></p-captcha>
				<br/>
				<button type="submit" class="btn btn-primary" [disabled]="registerUser.invalid || invalidEmailDomain || !guest.recaptcha">{{'g.submit' | translate}}</button>
				<button type="button" class="btn btn-default" routerLink="/login">{{'g.cancel' | translate}}</button>
			</div>
		</div>
	</div>
</form>