import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';
import { UserService } from '../../../../auth/user.service';
import { TagsService } from '../../../../shared/tags/tags.service';
import { DevicesDashboardService } from '../devices-dashboard.service';
import { NotificationMessageService } from '../../../../shared/notification-message/notification-message.service';

import * as _ from 'underscore';

@Component({
	selector: 'app-devices-listing',
	templateUrl: './devices-listing.component.html',
	styleUrls: ['./devices-listing.component.css']
})
export class DevicesListingComponent implements OnInit {

	@Input() deviceType: string;

	devicesListing: any = {};
	siteTags = [];
	currentSite: any = {};
	currentUser: any = {};
	hasDevices = true;
	hideAddDeviceTagBox = true;
	hideAddDeviceGroupBox = true;
	countOfDevicesChecked = 0;
	tagsFilters = [];
	groupsFilters = [];
	commonTags = [];
	commonGroup: any;
	siteTagsById = [];
	selectedAll = false;
	deviceTags = {};
	deviceGroups = {};
	siteId: number;
	customerId: number;
	countOfDevices: number = 0;
	devicesById: any = {};
	siteGroups: any = [];
	
	filter: any = {
		batteryType: 'all',
		chargerType: 'all',
		numberOfCells: 'all',
		batteryVoltage: 'all',
		multiVoltage: true,
		chargerConnectivity: 'all',
		battviewConnectivity: 'all',
		battviewHasFaults: 'all'
	};
	numberOfCells: any[] = [];
	batteryVoltages: any[] = [];
	searchChargersIsCollapse: boolean = true;
	searchBattviewsIsCollapse: boolean = true;
	connectivityStatusList = [];

	@ViewChild("chargerlisting") chargerlisting;
	@ViewChild("battviewListing") battviewListing;

	constructor(
		private router: Router,
		private tagsService: TagsService,
		public userService: UserService,
		private sideMenu: SideMenuService,
		private route: ActivatedRoute,
		public devicesDashboard: DevicesDashboardService,
		private notificationMessage: NotificationMessageService,
	) { }

	ngOnInit() {
		this.sideMenu.currentSite.subscribe(data => {
			this.currentSite = data;
		});

		this.currentUser = this.userService.getCurrentUser();
		this.siteId = Number(this.route.parent.parent.snapshot.params['siteid']);
		this.customerId = Number(this.route.parent.parent.snapshot.params['customerid']);
	}

	filterTags(tag) {
		// if the tag filter was enabled and clicked to disabled
		if (tag !== 'REFRESH') {
			if (tag.isActive) {
				var index = this.tagsFilters.indexOf(+tag.id);
				if (index > -1) {
					this.tagsFilters.splice(index, 1);
				}
			} else {
				this.tagsFilters.push(+tag.id);
			}
			tag.isActive = !tag.isActive;
		}

		this.filterData(tag == 'REFRESH');

		if (tag !== 'REFRESH') {
			this.selectedAll = false;
		}
	}
	filterGroups(group) {
		// if the group filter was enabled and clicked to disabled
		if (group !== 'REFRESH') {
			if (group.isActive) {
				var index = this.groupsFilters.indexOf(+group.id);
				if (index > -1) {
					this.groupsFilters.splice(index, 1);
				}
			} else {
				this.groupsFilters.push(+group.id);
			}
			group.isActive = !group.isActive;
		}

		this.filterData(group == 'REFRESH');

		if (group !== 'REFRESH') {
			this.selectedAll = false;
		}
	}

	selectAll(selectedAll) {
		var deviceList = this.devicesListing;

		for (var deviceGroup in deviceList) {
			deviceList[deviceGroup].forEach((item) => {
				if (item.isShow) {
					item.selected = selectedAll;
					this.deviceChecked(selectedAll);
				}
			});
		}
	}

	getDevicesCommonTags(selectedDevices) {

		var devicesTags	= this.getDevicesTags(selectedDevices);
		// @todo: move it to common
		var commonTags = devicesTags.shift().filter((v) => {
			return devicesTags.every((a) => {
				var ids = _.pluck(a, "tagid");
				return ids.indexOf(v.tagid) !== -1;
			});
		});
		var commonTagsIds = commonTags.map((e) => { return +e.tagid; });
		return commonTagsIds;
	}

	showAddTagBox() {
		var selectedDevices = this.getSelectedDevices();
		if (selectedDevices.length === 0) {
			return alert('No device selected!');
		}

		var commonTagsIds = this.getDevicesCommonTags(selectedDevices);
		this.commonTags = this.getTagsObject(commonTagsIds);
		this.hideAddDeviceTagBox = false;
	}

	showAddGroupBox() {
		var selectedDevices = this.getSelectedDevices();
		if (selectedDevices.length === 0) {
			return alert('No device selected!');
		}

		let commonGroup = this.getCommonGroup(selectedDevices);

		// reset commonGroups
		this.commonGroup = undefined;
		if(commonGroup)
			this.commonGroup = commonGroup;

		this.hideAddDeviceGroupBox = false;
	}

	getDevicesTags(deviceIds) {
		if (!Array.isArray(deviceIds)) deviceIds = [deviceIds];
		var devicesTags = [];
		for (var deviceIdIndex in deviceIds) {
			var deviceId = deviceIds[deviceIdIndex];
			devicesTags.push(this.deviceTags[deviceId]);
		}
		return devicesTags;
	}

	getCommonGroup(deviceIds) {
		if (!Array.isArray(deviceIds)) deviceIds = [deviceIds];
		var devicesGroup = this.deviceGroups[deviceIds[0]];
		for (var deviceIdIndex in deviceIds) {
			var deviceId = deviceIds[deviceIdIndex];
			// each device has single group at most, so to get common we need to make sure all devices have same group
			if(!this.deviceGroups[deviceId] || this.deviceGroups[deviceId] != devicesGroup)
			{
				devicesGroup = null;
				break;
			}
		}
		return devicesGroup;
	}

	deleteTags() {

		var selectedDevices = this.getSelectedDevices();
		if (selectedDevices.length === 0) {
			return alert('No device selected!');
		}

		var commonTags = this.getDevicesCommonTags(selectedDevices);

		if (commonTags.length === 0) {
			return alert('There is no common tags between selected devices!');
		}

		var tagsToDelete = this.getTagsObject(commonTags);

		var tagsNamesToDelete = [];
		tagsToDelete.forEach((obj) => {
			if(obj.tagname) {
				tagsNamesToDelete.push(obj.tagname);
			}
		});

		if (confirm('Are you sure that you want to remove the following tag(s) from selected device?\n'+tagsNamesToDelete.join('\n'))) {
			var siteid		= this.siteId;
			var customerid	= this.customerId;

			this.tagsService.removeDeviceTag(customerid, siteid, this.deviceType, selectedDevices, tagsToDelete).subscribe( data => {

				this.removeTagFromUI(selectedDevices, commonTags);
			});
		}
	}
	
	removeTagFromUI(selectedDevices, commonTags) {
		var devicesGroups = this.devicesListing;
		// Remove tags from UI
		for (var devicesGroupIndex in devicesGroups) {
			var devicesGroup = devicesGroups[devicesGroupIndex];
			for (var deviceIndex in devicesGroup) {
				var device = devicesGroup[deviceIndex];
				if (selectedDevices.indexOf(device.id) < 0) {
					continue;
				}

				for (var deviceTagIndex = device.tags.length - 1; deviceTagIndex >= 0; deviceTagIndex--) {
					var deviceTag = device.tags[deviceTagIndex];
					if (commonTags.indexOf(+deviceTag.id) < 0) {
						continue;
					}
					device.tags.splice(deviceTagIndex, 1);
				}

				for (deviceTagIndex = this.deviceTags[device.id].length - 1; deviceTagIndex >= 0; deviceTagIndex--) {
					var deviceTag = this.deviceTags[device.id][deviceTagIndex];
					if (commonTags.indexOf(+deviceTag.tagid) < 0) {
						continue;
					}
					this.deviceTags[device.id].splice(deviceTagIndex, 1);
				}
			}
		}
		
		this.filterTags('REFRESH');
	}

	siteTagsChanged(event) {
		let siteTags = event[0];
		let siteGroups = event[1];
		this.siteTags = siteTags;
		
		let siteTagsById = [];
		
		siteTags.forEach((item) => {
			siteTagsById[item.id] = item;
		});

		this.siteTagsById = siteTagsById;
		this.siteGroups = siteGroups;
		this.filterData();
	}

	getTagsObject(tagsIds) {
		if (!Array.isArray(tagsIds)) tagsIds = [tagsIds];
		var tagsArr = [];
		for (var i in tagsIds) {
			var id = tagsIds[i];
			if (this.siteTagsById[id])
				tagsArr.push(this.siteTagsById[id]);
		}
		return tagsArr;
	}

	deviceChecked(isChecked) {
		this.hideAddDeviceTagBox = true;
		this.hideAddDeviceGroupBox = true;

		if (isChecked) {
			this.countOfDevicesChecked++;
		} else {
			this.countOfDevicesChecked--;
			this.selectedAll = false;
		}
		
		if (this.countOfDevicesChecked <= 0) {
			this.countOfDevicesChecked = 0;
		}

		if(this.countOfDevicesChecked >= this.countOfDevices) {
			this.countOfDevicesChecked = this.countOfDevices;
			this.selectedAll = true;
		}
	}
	
	onTagRemoved(tagObj) {
		let selectedDevices = this.getSelectedDevices();
		let tagRemoved = [+tagObj.id]
		this.removeTagFromUI(selectedDevices, tagRemoved);
	}

	tagAddedFault(info) {
		// close add tag 
		this.hideAddDeviceTagBox = true;
	}

	siteTagAdded(info) {
		if(info.type == 'remove') {
			return this.onTagRemoved(info.tag);
		}
		var addedTag = info.tag;
		var selectedDevices = this.getSelectedDevices();
		for (var devicesGroupIndex in this.devicesListing) {
			var devicesGroup = this.devicesListing[devicesGroupIndex];
			for (var deviceIndex in devicesGroup) {
				var device = devicesGroup[deviceIndex];
				if (selectedDevices.indexOf(device.id) < 0) {
					continue;
				}

				var deviceHasTheTag = false;
				for (var deviceTagIndex in device.tags) {
					var deviceTag = device.tags[deviceTagIndex];
					if (deviceTag.id == addedTag.id) {
						deviceHasTheTag = true;
						break;
					}
				}

				if (!deviceHasTheTag) {
					device.tags.push(addedTag);
					this.deviceTags[device.id].push({tagid: addedTag.id});
				}
			}
		}

		if (!this.siteTagsById[addedTag.id]) {
			this.siteTags.push(addedTag);
			this.siteTagsById[addedTag.id] = addedTag;
		}
	}

	goToSettingsPage = function() {
		var selectedDevices = this.getSelectedDevices();
		if(this.deviceType == 'charger') {
			this.router.navigate(
				['/', this.customerId, this.siteId, 'chargers', 'settings'],
				{queryParams: {'chargersIDs': selectedDevices.join(',')}}
			);
		} else if(this.deviceType == 'battview') {
			this.router.navigate(
				['/', this.customerId, this.siteId, 'battviews', 'settings'],
				{queryParams: {'battviewsIDs': selectedDevices.join(',')}}
			);
		} else if(this.deviceType == 'truckview') {
			this.router.navigate(
				['/', this.customerId, this.siteId, 'truckviews', 'settings'],
				{queryParams: {'truckviewsIDs': selectedDevices.join(',')}}
			);
		}
	}

	getSelectedDevices() {
		var deviceList = this.devicesListing;

		var selectedDevices = [];
		for (var deviceGroup in deviceList) {
			deviceList[deviceGroup].forEach((item) => {
				if (item.selected) {
					selectedDevices.push(item.id);
				}
			});
		}
		return selectedDevices;
	}

	restartDevices() {
		var selectedDevices = this.getSelectedDevices();
		if (selectedDevices.length === 0) {
			return alert('No device selected!');
		}

		// check if all devices have restart action
		if(this.deviceType == 'charger') {
			let invalidRestartDevices: any[] = [];
			selectedDevices.forEach(deviceId => {
				let device = this.devicesById[deviceId];
				if(!device || !device.actviewenable)
					invalidRestartDevices.push(device.serialnumber);
			});
			if(invalidRestartDevices.length > 0) {
				return alert("Below devices cannot be restarted:\r\n"+invalidRestartDevices.join("\n\r"));
			}
		}
		
		this.devicesDashboard.restartDevice(this.deviceType, selectedDevices, {}).subscribe();
	}

	onListingReady(event) {
		this.devicesListing = event.list;
		this.hasDevices = event.hasDevices;
		this.connectivityStatusList = event.connectivityStatusList;
		if(this.hasDevices) {
			let countOfDevices = 0;
			let numberOfCells = [];
			let batteryVoltages = [];
			for(var group in event.list){
				countOfDevices += event.list[group].length;
				event.list[group].forEach((device) => {
					this.devicesById[device.id] = device;
					if(this.deviceType == 'charger') {
						if(device.enableautodetectmultivoltage == false) {
							let newLithiumBattery = (device.batterytype == 1 && device.firmwareversion > 2.5);
							if(newLithiumBattery) {
								if(device.liion_numberofcells > 0)
									numberOfCells.push(device.liion_numberofcells);
							} else {
								batteryVoltages.push(device.batteryvoltage);
							}
						}
					}
				});
			}
			this.countOfDevices = countOfDevices;
			if(this.deviceType == 'charger') {
				this.numberOfCells = _.uniq(numberOfCells).sort(function(a, b){return a - b});
				this.batteryVoltages = _.uniq(batteryVoltages).sort();
			}
		}
	}

	addGroup(group) {
		var selectedDevices = this.getSelectedDevices();
		this.devicesDashboard.assignDeviceToGroup(this.siteId, group.id, this.deviceType, selectedDevices).subscribe((res) => {
			selectedDevices.forEach(deviceId => {
				this.deviceGroups[deviceId] = group.id;
			});
			this.notificationMessage.setMessage(res, 'success', 10000, true);
		});
	}

	filterData(isRefresh = false) {
		let filterdDevices = {};
		for (var deviceGroup in this.devicesListing) {
			filterdDevices[deviceGroup] = [];
			this.devicesListing[deviceGroup].forEach((item) => {
				// reset
				item.isShow = true;

				// filter by tags => filterTags(tag)
				if (this.tagsFilters.length > 0) {
					
					item.isShow = false;
					this.deviceTags[item.id].forEach((deviceTag) => {
						item.isShow = item.isShow || this.tagsFilters.indexOf(+deviceTag.tagid) > -1;
					});
				}

				// filter by groups => filterGroups(group)
				if (item.isShow && this.groupsFilters.length > 0) {
					item.isShow = this.groupsFilters.indexOf(+this.deviceGroups[item.id]) > -1;
				}

				if(this.deviceType == 'charger' && item.isShow && !this.searchChargersIsCollapse) {
					// filtering chargers depending on filters
					let isShow = item.isShow;
					if (isShow && [0,1,2,3,4].indexOf(+this.filter.batteryType) != -1) {
						isShow = item.batterytype == this.filter.batteryType;
					}
					if (isShow && [0,1,2].indexOf(+this.filter.chargerType) != -1) {
						isShow = item.chargertype == this.filter.chargerType;
					}

					if(isShow) {
						isShow = (this.filter.multiVoltage == item.enableautodetectmultivoltage);
					}

					if(isShow && this.filter.multiVoltage == false && +this.filter.batteryType === 1 && this.numberOfCells.indexOf(+this.filter.numberOfCells) != -1) {
						isShow = item.liion_numberofcells == this.filter.numberOfCells;
					}

					if(isShow && this.filter.multiVoltage == false && [0,2,4].indexOf(+this.filter.batteryType) != -1 && this.batteryVoltages.indexOf(+this.filter.batteryVoltage) != -1) {
						isShow = item.batteryvoltage == this.filter.batteryVoltage;
					}
					let connectivityValues = _.pluck(this.connectivityStatusList, "value");
					if (isShow && connectivityValues.indexOf(this.filter.chargerConnectivity) != -1) {
						isShow = this.filter.chargerConnectivity == item.connectivityStatus
					}

					item.isShow = isShow;

				}

				if(this.deviceType == 'battview' && item.isShow && !this.searchBattviewsIsCollapse) {
					// filtering battviews depending on filters
					let isShow = item.isShow;
					let connectivityValues = _.pluck(this.connectivityStatusList, "value");
					if (isShow && connectivityValues.indexOf(this.filter.battviewConnectivity) != -1) {
						isShow = this.filter.battviewConnectivity == item.connectivityStatus;
					}
					if (isShow && this.filter.battviewHasFaults != 'all') {
						let connectivityFlag = item.actviewenabled && item.isConnected;
						if(this.filter.battviewHasFaults == "0")
							connectivityFlag = true;
						isShow = (item.hasFault == this.filter.battviewHasFaults) && connectivityFlag;
					}

					item.isShow = isShow;

				}

				if (!item.isShow) {
					if (!isRefresh) {
						item.selected = false;
						this.deviceChecked(false);
					}
				} else {
					filterdDevices[deviceGroup].push(item);
				}
			});
		}

		if(Object.keys(this.devicesListing).length) {
			if(this.deviceType == 'battview')
				this.battviewListing.setListingObject(filterdDevices);
			else if(this.deviceType == 'charger')	
				this.chargerlisting.setListingObject(filterdDevices);
		}
	}

	searchToggle(isCollapse, type) {
		if (type == "chargers") {
			this.searchChargersIsCollapse = isCollapse;
		} else if (type == "battviews") {
			this.searchBattviewsIsCollapse = isCollapse;
		}
		this.filterData();
	}
	
}