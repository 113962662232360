import { Component, OnInit } from '@angular/core';
import { SiteDashboardService } from '../../site-dashboard.service';
import { ActivatedRoute } from "@angular/router";
import { ActFirstService } from '../act-first.service';

@Component({
	selector: 'app-actfirst-management',
	templateUrl: './management.component.html',
})
export class ManagementComponent implements OnInit {
	
	siteId;
	customerId;
	selectedGroupId;

	siteGroups		= [];
	siteChargers	= [];

	operational		= [];
	inMaintenance	= [];

	constructor(
		private siteDashboardService: SiteDashboardService,
		private actFirstService: ActFirstService,
		private route: ActivatedRoute
	) { }

	ngOnInit() {
		this.customerId		=  this.route.parent.snapshot.params['customerid'];
		this.siteId			=  this.route.parent.snapshot.params['siteid'] ;

		this.siteDashboardService.setActiveTab('actfirst');
		this.siteDashboardService.setActiveSubTab('management');

		this.getData();
	}

	getData(){
		this.actFirstService.actFirstManagement(this.siteId).subscribe((data:any) => {
			if(data.groups.length && !this.selectedGroupId)
				this.selectedGroupId = data.groups[0].id;

			this.siteGroups		= data.groups;
			this.siteChargers	= data.chargers;

			this.changeSelectedSiteGroup(this.selectedGroupId);
		});
	}

	changeSelectedSiteGroup(id){
		this.selectedGroupId = id;
		this.operational	= [];
		this.inMaintenance	= [];
		for (let charger of this.siteChargers) {
			if(charger.actfirstgroupid == this.selectedGroupId){
				if(charger.ignoreactfirstranking)
					this.inMaintenance.push(charger);
				else
					this.operational.push(charger);
			}
		}
	}

	moveTo(charger, newIgnoreRankValue){
		this.actFirstService.updateChargerActfirstRanking({siteId: this.siteId, chargerId: charger.id, newValue: newIgnoreRankValue}).subscribe((data) => {
			charger.ignoreactfirstranking = newIgnoreRankValue;
			this.changeSelectedSiteGroup(this.selectedGroupId);
		});
	}
}