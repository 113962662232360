import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AdminService } from '../../admin.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';

@Component({
  selector: 'app-error-log',
  templateUrl: './error-log.component.html',
  styleUrls: ['./error-log.component.css']
})
export class ErrorLogComponent implements OnInit, OnDestroy {

  filterOptions = ['Date', 'User ID', 'Server IP', 'Function Name', 'Groups'];
  getBy: string = 'Date';
  value: string;
  fromDate: Date;
  toDate: Date;
  selectedErrors: Array<number> = [];
  logInfo: any = [];
  logData: Array<any> = [];
  selectAll: Boolean;

  pageName: string;

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private breadcrumb: BreadcrumbService,
    private notificationMessage: NotificationMessageService
  ) {
  }

  ngOnInit() {
    this.pageName = this.route.snapshot.data['pageName'];
    this.getLoggingInfo();
    let breadCrumbText = this.pageName.split('_');
    let breadCrumbLabel = '';
    for(let i of breadCrumbText) {
      breadCrumbLabel += i.charAt(0).toUpperCase() + i.substr(1) + ' ';
    }
    this.breadcrumb.setBreadcrumb([
      {label: breadCrumbLabel, url: ''}
    ]);
  }

  getLoggingInfo() {
    let options = {
      fromDate: "",
      toDate: "",
      value: "",
      type: this.pageName,
      getBy: this.getBy
    };
    if(this.getBy == 'Date') {
      
      if(!this.fromDate || !this.toDate) {
        this.fromDate = new Date(new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0));
        this.toDate = new Date(new Date().setHours(23,59,59,999));
      }

      options.fromDate  = Math.floor(this.fromDate.getTime() / 1000)+'';
      options.toDate    = Math.floor(this.toDate.getTime() / 1000)+'';
    } else if (this.getBy == 'User ID') {
      options.value = (parseInt(this.value) || 0) + "";
    } else {
      options.value = this.value;
    }
    
    this.adminService.getLoggingInfo(options).subscribe(
      data => {
        this.logInfo = data;
      }
    );
  }

  delete(type, id) {
    this.adminService.deleteLog(id, type).subscribe(
      (isDeleted: any) => {
        if(isDeleted.result) {
          for(let i in this.logData) {
            if (this.logData[i].id == id) {
              this.logData.splice(+i, 1);
              break;
            }
          }
          this.notificationMessage.setMessage('Deleted Successfully!', 'success', 10000, true);
        }
      }
    );
  };

  deleteLogs() {
    let selectedLogsIds = [];
    for (var i = 0; i < this.logData.length; i++) {
      if(this.logData[i].selected)
        selectedLogsIds.push(this.logData[i].id);
    }
    if(selectedLogsIds.length === 0)
      return;

    this.adminService.deleteLog(selectedLogsIds, this.pageName).subscribe(
      (isDeleted: any) => {
        if(isDeleted.result) {
          this.notificationMessage.setMessage('Deleted Successfully!', 'success', 10000, true);
          this.selectAll = false;
          this.getLoggingInfo();
        }
      }
    );
  }
  selectAllToggle() {
    for (var i = 0; i < this.logData.length; i++) {
      this.logData[i].selected = this.selectAll;
    }
  }
  loadData(data) {
    this.logData = data;
  }

  checkIfAllSelected() {
    this.selectAll = this.logData.every((item:any) => {
        return item.selected == true;
      })
  }
  anyLogSelected() {
    let returnValue = false;
    for (let i = 0; i < this.logData.length; i++) {
      if(this.logData[i].selected) {
        returnValue = true;
        break;
      }
    }
    return returnValue;
  };

  ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
