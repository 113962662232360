import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import * as _ from 'underscore';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { AdminService } from '../../../admin/admin.service';
import { DeviceManagementService } from '../../device-management.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-battview-report',
	templateUrl: './battview-report.component.html',
	styleUrls: ['./battview-report.component.css']
})
export class BattviewReportComponent implements OnInit, AfterViewInit, OnDestroy {

	isCollapsed: {
		site: boolean,
		warnings: boolean,
		version: boolean,
		config: boolean,
		history: boolean,
		dates: boolean,
	} = {
		site: true,
		warnings: true,
		version: true,
		config: true,
		history: true,
		dates: true,
	};
	model: {
		hasSite: boolean,
		selectedCustomersNegation: boolean,
		selectedCustomers: number[],
		selectedSites: number[],
		selectedDealersNegation: boolean,
		selectedDealers: number[],
		selectedServiceDealersNegation: boolean,
		selectedServiceDealers: number[],
		selectedOEMsNegation: boolean,
		selectedOEMs: number[],
		isTestSite: boolean,
		actIntelligentSiteType: string,
		selectedWarnings: number[],
		fwVersionOperation: string,
		fwVersion: number,
		wifiFwVersionOperation: string,
		wifiFwVersion: number,
		bvHwVersionOperation: string,
		bvHwVersion: number,
		isBattviewMobile: boolean,
		isActviewEnabled: boolean,
		batteryType: number[],
		zoneid: number[],
		connectionTimeNegation: boolean,
		connectionTime: number,
		enabledPLC: boolean,
		enabledHallSensor: boolean,
		enabledWaterSensor: boolean,
		totalChargeAHROperation: string,
		totalChargeAHR: number,
		totalInuseAHROperation: string,
		totalInuseAHR: number,
		totalChargeKWHROperation: string,
		totalChargeKWHR: number,
		totalInuseKWHROperation: string,
		totalInuseKWHR: number,
		totalChargeTimeOperation: string,
		totalChargeTime: number,
		totalIdleTimeOperation: string,
		totalIdleTime: number,
		totalInuseTimeOperation: string,
		totalInuseTime: number,
		totalEventsOperation: string,
		totalEvents: number,
		commissionDateOperation: string,
		commissionDate: Date,
		installationDateOperation: string,
		installationDate: Date,
		manafacturingDateOperation: string,
		manafacturingDate: Date,
	} = {
		hasSite: true,
		selectedCustomersNegation: false,
		selectedCustomers: [],
		selectedSites: [],
		selectedDealersNegation: false,
		selectedDealers: [],
		selectedServiceDealersNegation: false,
		selectedServiceDealers: [],
		selectedOEMsNegation: false,
		selectedOEMs: [],
		isTestSite: false,
		actIntelligentSiteType: null,
		selectedWarnings: [],
		fwVersionOperation: '',
		wifiFwVersionOperation: '',
		wifiFwVersion: null,
		fwVersion: null,
		bvHwVersionOperation: '',
		bvHwVersion: null,
		isBattviewMobile: false,
		isActviewEnabled: null,
		batteryType: [],
		zoneid: [],
		connectionTimeNegation: false,
		connectionTime: null,
		enabledPLC: null,
		enabledHallSensor: null,
		enabledWaterSensor: null,
		totalChargeAHROperation: '',
		totalChargeAHR: null,
		totalInuseAHROperation: '',
		totalInuseAHR: null,
		totalChargeKWHROperation: '',
		totalChargeKWHR: null,
		totalInuseKWHROperation: '',
		totalInuseKWHR: null,
		totalChargeTimeOperation: '',
		totalChargeTime: null,
		totalIdleTimeOperation: '',
		totalIdleTime: null,
		totalInuseTimeOperation: '',
		totalInuseTime: null,
		totalEventsOperation: '',
		totalEvents: null,
		commissionDateOperation: '',
		commissionDate: null,
		installationDateOperation: '',
		installationDate: null,
		manafacturingDateOperation: '',
		manafacturingDate: null,
	};
	customers: any[];
	customersSites: Object;
	sites: any[];
	allSites: any[];
	dealers: any[];
	OEMs: any[];
	warnings: {id: string, name: string}[] = [
		{id:'battviewConnectivity', name: this.translateService.instant('site.battview_connectivity')},
		{id:'battviewPLC', name: this.translateService.instant('device.battview_plc')},
		{id:'highEBUBatteries', name: this.translateService.instant('device.battview_high_ebu')},
		{id:'battviewFaults', name: this.translateService.instant('device.battview_faults')},
		{id:'battviewsNotUsed', name: this.translateService.instant('device.battview_not_used')},
		{id:'battviewsTimeZone', name: this.translateService.instant('device.battview_time_zone')},
		{id:'battviewsLostTime', name: this.translateService.instant('device.battviews_have_not_synched_time')},
	];
	batteryTypes: {id: number, name:string}[] = [
		{id: 0, name: this.translateService.instant('battery.fast')},
		{id: 1, name: this.translateService.instant('battery.conventional')},
		{id: 2, name: this.translateService.instant('battery.opportunity')}
	];
	reportName: string = null;
	
	@ViewChild("modal") modal;
	
	constructor(
		private route: ActivatedRoute,
		private deviceManagemntService: DeviceManagementService,
		private adminService: AdminService,
		private breadcrumb: BreadcrumbService,
		private notificationMessage: NotificationMessageService,
		private commonUtil: CommonUtil,
		private commonDataService: CommonDataService,
		private translateService: TranslateService
	) {
		this.breadcrumb.setBreadcrumb([
			{label: this.translateService.instant('device.battview_search'), url: ''}
		]);
	}

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			if(params.isExpired){
				this.notificationMessage.setMessage('translate|g.file_has_been_deleted', 'warning', 20000);
			}
			if(params.enc){
				this.deviceManagemntService.prepareFileDownload('too_big_report', params.enc).subscribe((data:any)=>{
					this.commonUtil.downloadReport(data);
				});
			}
		});
		this.getDealers(0);
		this.getOEMs(0);
		this.deviceManagemntService.getAllSitesForUser().subscribe(
			(data: any[]) => {
				this.customers = this.commonUtil.extractKeysFromObjects(['customerid', 'customer_name'], data, true);
				this.customersSites = this.commonUtil.arrayToAssociativeArray(data, 'customerid', true);
				this.sites = data;
				this.allSites = data;
			}
		);
	}
	ngAfterViewInit() {
		this.modal.onClose.subscribe(
			(ok) => {
				if(ok) {
					this.scheduleReport();
				}
			}
		);
	}

	updateSitesAvailable = function() {
		this.sites = [];
		if(this.model.selectedCustomersNegation) {
			for(let customerId in this.customersSites) {
				if (this.model.selectedCustomers.indexOf((+customerId)) === -1) {
					this.sites = this.sites.concat(this.customersSites[customerId]);
				}
			}
		} else {
			if (this.model.selectedCustomers.length === 0) {
				_.extend(this.sites, this.allSites);
			} else {
				for(let customerId of this.model.selectedCustomers) {
					this.sites = this.sites.concat(this.customersSites[customerId]);
				}
			}
		}

		if (this.sites.length === 0)
			return;

		// remove any selected site not exists in list of sites (this.sites)
		var siteIds = this.commonUtil.extractKeyFromObjects('id', this.sites, true, true);
		var selectedSites = [];
		for(let selectedSite of this.model.selectedSites) {
			if(siteIds.indexOf(selectedSite) > -1){
				selectedSites.push(selectedSite);
			}
		}
		this.model.selectedSites = selectedSites;
	}

	scheduleReport() {
		if (!this.reportName)
			return this.notificationMessage.setMessage(this.translateService.instant('user.please_enter_report_name'), 'danger', 10000, true);

		var model = this.model;
		model['reportName'] = this.reportName;
		model['state'] = 'battview';

		this.deviceManagemntService.scheduleReport(model).subscribe(
			res => {
				this.reportName = '';
				this.notificationMessage.setMessage(this.translateService.instant('user.the_report_will_be_processed_and_sent_to_your_email'), 'success', 20000, true);
			}
		);
	}

	getDealers(id = 0) {
		let noDealer = {'id':0, 'name': this.translateService.instant('g.no_dealer')};
		this.adminService.getDealers(id).subscribe(
			data => {
				let dealers = data['dealers'] || [];
				dealers.unshift(noDealer);
				this.dealers = dealers;
			}
		);
	}
	getOEMs(id=0) {
		let noOEM = {'id':0, 'name': this.translateService.instant('g.no_oem')};    
		this.adminService.getOEMs(id).subscribe(
			(data: any[]) => {
				let OEMs = data || [];
				OEMs.unshift(noOEM);
				this.OEMs = OEMs;
			}
		);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
