
import {map} from 'rxjs/operators';
import { 
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router
 } from '@angular/router';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { NavigationService } from '../shared/services/navigation.service';


@Injectable()
export class NoAuthGuard implements CanActivate {
	constructor(private authService: AuthService, private router: Router, private navigation: NavigationService) {}
	
	canActivate(route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
		
		return this.authService.isAuthenticated(route).pipe(map( user => {
			if (user) {
				let url = this.navigation.getPreviousUrl();
				if (!url) {
					this.router.navigate(['/']);
				}
				return false;
			}

			return true;
		}));
	}

}