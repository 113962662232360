<simple-widget *ngIf="data.length && currentUser.isACTuser" [title]="'RMA Info'" widgetId="rma_info_widget">
	<div id="site-rma" class="container-fluid">
		<div *ngFor="let item of data">
			<a class="pull-left noprint margin-right-sm" href="javascript:">
				<i (click)="toggleCollapse(item)" class="fa " [ngClass]="{'fa-minus': !item.isCollapsed, 'fa-plus': item.isCollapsed}"></i>
			</a>
			{{ item.rma_no }}
			 <div *ngIf="!item.isCollapsed" class="margin-left-xlg margin-top-sm margin-bottom-sm">
				<div class="row" *ngFor="let d of item.data">
					<div class="col-md-3"><b>SN</b>: <a [routerLink]="[(d.device_type == 1 ? 'chargers/analytics/cycles/' : 'battviews/analytics/battery-summary/')+d.device_id]">{{ d.serial_number }}</a></div>
					<div class="col-md-3"><b>Issue Reported</b>: {{ d.report_issue }}</div>
					<div class="col-md-3"><b>Date Created</b>: {{ d.created_at | date:'shortDate' }}</div>
					<div class="col-md-3"><b>State</b>: {{ rmaStatesMapper[d.rma_state] }}</div>
				</div>
			 </div>
		</div>
	</div>
</simple-widget>