<div class="row">
	<div class="margin-top" id="page-wrapper" >
		<div id="page-inner">
			<div class="boxs">
				<table class="table table-striped" *ngIf="battviewsList">
					<thead>
						<tr>
							<th>{{'g.month' | translate}}</th>
							<th>{{'g.total' | translate}}</th>
							<th>{{'site.total_with_ability_to_test' | translate}}</th>
							<th>{{'g.tested' | translate}}</th>
							<th>{{'g.not_tested' | translate}}</th>
							<th>{{'g.failed' | translate}}</th>
							<th>{{'g.passed' | translate}}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let tests of battviewsList">
							<td>{{tests.label}}</td>
							<td>{{tests.total}}</td>
							<td>{{tests.can_test}}</td>
							<td>{{tests.tested}}</td>
							<td>{{tests.not_tested}}</td>
							<td>{{tests.failed}}</td>
							<td>{{tests.passed}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>