import { Injectable } from '@angular/core';
import { UserService } from '../../../auth/user.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { CommonDataService } from '../../../shared/services/common-data.service';

@Injectable()
export class ChargerService {

	chargerSNRules = [
		{type: 'string', min: 12, max: 12},
		{type: 'regex', patt: /^(2|7)(10|11|12|13|14|15|17|20|21|22|23|24|25|27|30|31|32|33|34|35|37|44|45|46)(0[1-9]|1[0-2])(1[6-9]|[2-9][0-9])([0-9]{5})$/}
	]; //format: "2|7"+"01|02|10|20"+"01-12"+"16-99"+"00000-99999"

	constructor(private userService: UserService, private commonUtil: CommonUtil, private commonData: CommonDataService) { }

	validateChargerSettings(charger, isBulk, oldCharger, originalChargersInfo) {

		//Quantum3 Charger check
		if (oldCharger.serialnumber?.trim().length == 14) {
			this.chargerSNRules = [
				{ type: 'string', min: 14, max: 14 },
				{ type: 'regex', patt: /^11(146|115|135|136|117|127)(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{5}$/ }
			]; //format: 11- xxx(model)- xxxx(date)- xxxxx(#)
		}

		let minInstallationDate = new Date(new Date().setFullYear(new Date().getFullYear()-1)); //'1 year ago'
		let maxInstallationDate = new Date(new Date().setFullYear(new Date().getFullYear()+1)); //'1 year from now'

		var maxSSIDpasswordLength = 13;
		if(oldCharger.version == 2)
			maxSSIDpasswordLength = 31;

		var newLithiumBattery = false;
		var minTargetVoltages, maxTargetVoltages;
		var maxTRrate = 30;
		if(oldCharger.batterytype == 1 && oldCharger.firmwareversion > 2.5) {
			newLithiumBattery = true;
			maxTRrate = 100;
			minTargetVoltages = 1.7;
			maxTargetVoltages = 3.2;
		}

		let validationRules = {
			'installationdate'					: [{type: 'dateRange', min: minInstallationDate, max: maxInstallationDate}],
			'serialnumber'						: this.chargerSNRules,
			'model'								: [{type: 'string', min: 1, max: 15}],
			'hwrevision'						: [{type: 'string', min: 1, max: 2}, {type: 'regex', patt: /^([a-zA-Z ]{1,2})$/}], //format: "A" -> "ZZ"
			'chargerusername'					: [{type: 'string', min: 1, max: 23}],
			'chargertype'						: [{type: 'inArray', subType: 'integer', values: [0, 1, 2]}],
			'zoneid'							: [{type: 'integer', min: 0, max: 107}],
			'ir'								: [{type: 'float', min: 0, max: 9999.99}],
			'autostartenable'					: [{type: 'boolean'}],
			'autostartcountdowntimer'			: [{type: 'integer', min: 9, max: 127}],
			'enablerefreshcycleafterfi'			: [{type: 'boolean'}],
			'enablerefreshcycleaftereq'			: [{type: 'boolean'}],
			'refreshtimer'						: [{type: 'time', min: '00:15', max: '18:00', step: 15}],
			'temperaturesensorinstalled'		: [{type: 'boolean'}],
			'enableautodetectmultivoltage'		: [{type: 'boolean'}],
			'temperatureformat'					: [{type: 'boolean'}],
			'daylightsaving'					: [{type: 'boolean'}],
			'enableplc'							: [{type: 'boolean'}],
			'doplcstackcheck'					: [{type: 'boolean'}],
			'enablemanualeq'					: [{type: 'boolean'}],
			'enablemanualdesulfate'				: [{type: 'boolean'}],
			'ledcontrol'						: [{type: 'integer', min: 0, max: 2}],
			'chargeroverridebattviewfieqsched'	: [{type: 'boolean'}],
			'ignorebattviewsoc'					: [{type: 'boolean'}],
			'battviewautocalibrationenable'		: [{type: 'boolean'}],
			'disablepushbutton'					: [{type: 'boolean'}],
			'ocd_remote'						: [{type: 'integer', min: 0, max: 3}],
			'forcefinishtimeout'				: [{type: 'boolean'}],
			'lcdmemoryversion'					: [{type: 'integer', min: 1, max: 255}],
			'included_in_reports'				: [{type: 'boolean'}],
			'wififirmwareversion'				: [{type: 'integer', min: 1, max: 255}],
			'mobileaccessssid'					: [{type: 'string', min: 8, max: 31}],
			'mobileaccesspassword'				: [{type: 'string', min: 8, max: 13}],
			'mobileport'						: [{type: 'integer', min: 49152, max: 65535}],
			'softapenabled'						: [{type: 'boolean'}],
			'softappassword'					: [{type: 'string', min: 8, max: 13}],
			'actviewenable'						: [{type: 'boolean'}],
			'actviewip'							: [{type: 'string', min: 7, max: 31}],
			'actviewport'						: [{type: 'integer', min: 1024, max: 65535}],
			'actviewconnectfrequency'			: [{type: 'integer', min: 1, max: 65535}],
			'actaccessssid'						: [{type: 'string', min: 1, max: 31}],
			'actaccesspassword'					: [{type: 'string', min: 8, max: maxSSIDpasswordLength}],
			'batterycapacity'					: [{type: 'integer', min: 40, max: 5000}],
			'batterycapacity24'					: [{type: 'integer', min: 40, max: 5000}],
			'batterycapacity36'					: [{type: 'integer', min: 40, max: 5000}],
			'batterycapacity48'					: [{type: 'integer', min: 40, max: 5000}],
			'batterycapacity80'					: [{type: 'integer', min: 40, max: 5000}],
			'temperaturevoltagecompensation'	: [{type: 'float', min: 3, max: 8}],
			'maxtemperaturefault'				: [{type: 'float', min: 25, max: 125}],
			'cooldowntemperature'				: [{type: 'float', min: 25, max: 125}],
			'foldtemperature'					: [{type: 'float', min: 25, max: 125}],
			'trtemperature'						: [{type: 'float', min: -5, max: 25}],
			'batterytype'						: [{type: 'inArray', subType: 'integer', values: [0, 1, 2, 4]}],
			'trrate' 							: [{type: 'integer', min: 3, max: maxTRrate}],
			'ccrate' 							: [{type: 'integer', min: 4, max: 100}],
			'firate' 							: [{type: 'float', min: 1, max: 13, step: 0.5}],
			'eqrate' 							: [{type: 'integer', min: 1, max: 11}],
			'tricklevoltage'					: [{type: 'float', min: newLithiumBattery ? minTargetVoltages : 1.9, max: newLithiumBattery ? maxTargetVoltages : 2.1}],
			'cvvoltage'							: [{type: 'float', min: newLithiumBattery ? minTargetVoltages : 2.1, max: newLithiumBattery ? maxTargetVoltages : 2.5}],
			'fivoltage'							: [{type: 'float', min: newLithiumBattery ? minTargetVoltages : 2.25, max: newLithiumBattery ? maxTargetVoltages : 2.7}],
			'eqvoltage'							: [{type: 'float', min: 2.3, max: 2.7}],
			'liion_cellvoltage'					: [{type: 'float', min: 2.5, max: 4}],
			'liion_numberofcells'				: [{type: 'integer', min: 8, max: 27}],
			'cvfinishcurrent'					: [{type: 'float', min: 4, max: 15, step: 0.5}],
			'cvcurrentstep'						: [{type: 'float', min: 0, max: 10, step: 0.5}],
			'nominal_temperature_shift'			: [{type: 'float', min: -25, max: 50}],
			'cc_ramping_min_steps'				: [{type: 'integer', min: 0, max: 60, step: 5}],
			'cvtimer'							: [{type: 'time', min: '01:00', max: '05:00', step: 15}],
			'finishtimer'						: [{type: 'time', min: '01:00', max: '06:00', step: 15}],
			'eqtimer'							: [{type: 'time', min: '02:00', max: '06:00', step: 15}],
			'desulfationtimer'					: [{type: 'time', min: '06:00', max: '18:00', step: 15}],
			'finishdv'							: [{type: 'integer', min: 5, max: 100, step: 5}],
			'finishdt'							: [{type: 'integer', min: 1, max: 59}],
			'fischedulingmode'					: [{type: 'boolean'}],
			'eqstartwindow'						: [{type: 'time', min: '00:00', max: '23:59'}],
			'eqwindow'							: [{type: 'time', min: '00:15', max: '24:00', step: 15}],
			'eqdaysmask'						: [{type: 'daysMask'}],
			'fistartwindow'						: [{type: 'time', min: '00:00', max: '23:59'}],
			'finishwindow'						: [{type: 'time', min: '00:15', max: '24:00', step: 15}],
			'finishdaysmask'					: [{type: 'daysMask'}],
			'numberofinstalledpms'				: [{type: 'integer', min: 1, max: 12}],
			'pmvoltage'							: [{type: 'inArray', subType: 'integer', values: [36, 48, 80]}],
			'pmvoltageinputvalue'				: [{type: 'integer', min: 0, max: 3}],
			'pmefficiency'						: [{type: 'float', min: 85, max: 99.999}],
			'lockoutstarttime'					: [{type: 'time', min: '00:00', max: '23:59'}],
			'lockoutduration'					: [{type: 'time', min: '00:15', max: '18:00', step: 15}],
			'lockoutdaysmask'					: [{type: 'daysMask'}],
			'energydecreasevalue'				: [{type: 'integer', min: 50, max: 95}],
			'energystarttime'					: [{type: 'time', min: '00:00', max: '23:59'}],
			'energyduration'					: [{type: 'time', min: '00:15', max: '18:00', step: 15}],
			'energydaysmask'					: [{type: 'daysMask'}],
			'reconnecttimer'					: [{type: 'integer', min: 1, max: 65534}],
			'plc_gain'							: [{type: 'integer', min: 1, max: 5}],
			'lcd_fq'							: [{type: 'boolean'}],
			'pmmaxcurrent'						: [{type: 'integer', min: 10, max: 99}],
			'defaultbrightness'					: [{type: 'integer', min: 0, max: 100}],
			'bmsbitrate'						: [{type: 'inArray', subType: 'integer', values: [125, 250, 500]}],
		};

		let access = this.userService.getCurrentUser()['userAccess'];

		let invalidFields = [];

		for(let field in charger) {

			let accessField = this.commonData.accessFieldsMapper['charger'][field];
			if(accessField) {

				let permission = access[accessField];
				if(permission == 'write') {

					let rules = validationRules[field] || [];
					for (let i = 0; i < rules.length; i++) {
						let rule = rules[i];

						if(invalidFields.indexOf(field) == -1) {

							let isValid = this.commonUtil.dataValidator(rule.type, rule, charger[field]);

							if(!isValid)
								invalidFields.push(field);
						}
					}

					if(field == 'batteryvoltage') {

						let start	= 24;
						let end		= oldCharger['pmvoltage'];

						if(end == 80)
							end = 48;

						let step = 12;
						if(oldCharger['firmwareversion'] > 2.1)
							step = 2;

						let isValid = this.commonUtil.dataValidator('integer', {'min': start, 'max': end, 'step': step}, charger[field]);
						if(!isValid) {

							if(oldCharger['pmvoltage'] == 80) {
								if([72, 80].indexOf(charger[field]) != -1)
									isValid = true;
							}
						}

						if(!isValid)
							invalidFields.push(field);
					}
				} else {

					let isValid = false;

					if(field == 'nominal_temperature_shift') {
						if([18, 25, 32].indexOf(charger[field]) != -1)
							isValid = true;
					}

					if(!isValid)
						invalidFields.push(field);
				}
			}
		}

		let temperatureFields = ['cooldowntemperature', 'foldtemperature', 'maxtemperaturefault', 'trtemperature'];

		let validateTemperatures = function(_charger, _oldCharger) {

			let isValid = false;

			_charger['cooldowntemperature']	= _charger.hasOwnProperty('cooldowntemperature') ? _charger['cooldowntemperature'] : _oldCharger['cooldowntemperature'];
			_charger['foldtemperature']		= _charger.hasOwnProperty('foldtemperature') ? _charger['foldtemperature'] : _oldCharger['foldtemperature'];
			_charger['maxtemperaturefault']	= _charger.hasOwnProperty('maxtemperaturefault') ? _charger['maxtemperaturefault'] : _oldCharger['maxtemperaturefault'];
			_charger['trtemperature']		= _charger.hasOwnProperty('trtemperature') ? _charger['trtemperature'] : _oldCharger['trtemperature'];

			if(_charger['trtemperature'] < _charger['cooldowntemperature'] && _charger['cooldowntemperature'] < _charger['foldtemperature']
				&& _charger['foldtemperature'] < _charger['maxtemperaturefault']
			) {

				isValid = true;
			}

			if(!isValid) {
				temperatureFields.forEach(function(temperField) {
					invalidFields.push(temperField);
				});
			}

			return;
		}

		if(charger.hasOwnProperty('cooldowntemperature') || charger.hasOwnProperty('foldtemperature')
			|| charger.hasOwnProperty('maxtemperaturefault') || charger.hasOwnProperty('trtemperature')
		) {

			validateTemperatures(charger, oldCharger);
		}

		let chargerCurrents = ['firate', 'trrate', 'eqrate', 'ccrate', 'cvfinishcurrent'];

		let validateCurrentRates = function(tempCHAR, _oldCharger) {

			let isValid = false;

			let _charger = Object.assign({}, tempCHAR);

			_charger['firate']			= _charger.hasOwnProperty('firate') ? _charger['firate'] : _oldCharger['firate'];
			_charger['trrate']			= _charger.hasOwnProperty('trrate') ? _charger['trrate'] : _oldCharger['trrate'];
			_charger['eqrate']			= _charger.hasOwnProperty('eqrate') ? _charger['eqrate'] : _oldCharger['eqrate'];
			_charger['ccrate']			= _charger.hasOwnProperty('ccrate') ? _charger['ccrate'] : _oldCharger['ccrate'];
			_charger['cvfinishcurrent']	= _charger.hasOwnProperty('cvfinishcurrent') ? _charger['cvfinishcurrent'] : _oldCharger['cvfinishcurrent'];

			if(_charger['ccrate'] >= _charger['trrate'] && _charger['ccrate'] >= _charger['firate']
				&& _charger['firate'] >= _charger['eqrate'] && _charger['ccrate'] >= _charger['cvfinishcurrent']
			) {

				isValid = true;
			}

			if(!isValid) {
				chargerCurrents.forEach(function(temperField) {
					invalidFields.push(temperField);
				});
			}

			return;
		}

		if(!newLithiumBattery && (charger.hasOwnProperty('firate') || charger.hasOwnProperty('trrate')
			|| charger.hasOwnProperty('eqrate') || charger.hasOwnProperty('ccrate') || charger.hasOwnProperty('cvfinishcurrent'))
		) {

			if(isBulk) {

				originalChargersInfo.forEach(function(_oldCharger) {
					validateCurrentRates(charger, _oldCharger);
				});
			} else {

				validateCurrentRates(charger, oldCharger);
			}
		}

		let chargerVoltages = ['eqvoltage', 'fivoltage', 'cvvoltage', 'tricklevoltage'];

		let validateTargetVoltages = function(tempCHAR, _oldCharger) {

			let isValid = false;

			let _charger = Object.assign({}, tempCHAR);

			_charger['eqvoltage']			= _charger.hasOwnProperty('eqvoltage') ? _charger['eqvoltage'] : _oldCharger['eqvoltage'];
			_charger['fivoltage']			= _charger.hasOwnProperty('fivoltage') ? _charger['fivoltage'] : _oldCharger['fivoltage'];
			_charger['cvvoltage']			= _charger.hasOwnProperty('cvvoltage') ? _charger['cvvoltage'] : _oldCharger['cvvoltage'];
			_charger['tricklevoltage']		= _charger.hasOwnProperty('tricklevoltage') ? _charger['tricklevoltage'] : _oldCharger['tricklevoltage'];

			if(_charger['cvvoltage'] >= _charger['tricklevoltage'] && _charger['cvvoltage'] <= _charger['fivoltage']
				&& (newLithiumBattery || (_charger['fivoltage'] <= _charger['eqvoltage']))
			) {

				isValid = true;
			}

			if(!isValid) {
				chargerVoltages.forEach(function(voltField) {
					invalidFields.push(voltField);
				});
			}

			return;
		}

		if(!newLithiumBattery) {
			if(charger.hasOwnProperty('eqvoltage') || charger.hasOwnProperty('fivoltage')
				|| charger.hasOwnProperty('cvvoltage') || charger.hasOwnProperty('tricklevoltage')
			) {

				if(isBulk) {

					originalChargersInfo.forEach(function(_oldCharger) {
						validateTargetVoltages(charger, _oldCharger);
					});
				} else {

					validateTargetVoltages(charger, oldCharger);
				}
			}
		}

		return invalidFields;
	}
}
