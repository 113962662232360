import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActFirstService } from '../../act-first.service';
import { SiteDashboardService } from '../../../site-dashboard.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';
import { SideMenuService } from '../../../../../shared/side-menu/side-menu.service';
import { Subscription } from 'rxjs';
import { Site } from '../../../../../shared/models/site.model';
import { TranslateService } from '@ngx-translate/core';
import { LineChartConfig } from '../../../../../shared/google-chart/Models/LineChartConfig';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-daily-availability',
	templateUrl: './daily-availability.component.html',
	styleUrls: ['./daily-availability.component.css']
})
export class DailyAvailabilityComponent implements OnInit, OnDestroy {
	apiResponse = {};
	data = {};
	gridColumns = [];
	gridRows = [];
	site: Site;
	config: LineChartConfig;
	chartObjs = {};
	groupCharts = [];
	selectedDateRange: any = {
		fromDate: null,
		toDate: null
	};
	availabilityText = "";
	siteGroups = [];
	images = {};
	currentSiteSub: Subscription = new Subscription();
	keys: string[] = ['parked', 'charging', 'ready', 'relaxed', 'relaxing'];

	getReportVars: any = {
		fromDate: null,
		toDate: null
	};
	displayStatus: any = {
		'avg': true,
		'min': false,
		'max': false
	};

	typesStatus: any = {
		'parked': false,
		'charging': false,
		'ready': true,
		'relaxed': true,
		'relaxing': true
	};

	largeNumber = 100000;

	hasAnyStatusEnabled: boolean = true;
	hasAnyDisplayEnabled: boolean = true;

	constructor( 
		public actFirstService: ActFirstService,
		private siteDashboardService: SiteDashboardService,
		private commonUtil: CommonUtil,
		private chartUtil: ChartUtil,
		private sideMenuService: SideMenuService,
		private translateService: TranslateService,
		private router: Router,
		private route: ActivatedRoute
	) { }

	ngOnInit() {
		this.selectedDateRange = {
			fromDate: new Date(),
			toDate: new Date()
		};

		this.route.queryParams.subscribe(params => {
				const keys = Object.keys(params);
				['min','max', 'avg'].forEach(key => {
					if (keys.includes(key))
						this.displayStatus[key] = JSON.parse(params[key]);
				})
		});

		this.selectedDateRange.fromDate.setDate(this.selectedDateRange.fromDate.getDate() - 31);
		this.selectedDateRange.toDate.setDate(this.selectedDateRange.toDate.getDate() - 1);

		this.config = new LineChartConfig({
			LegendPosition: 'top', 
			hAxis: {
				title: 'Day',
				titleTextStyle: {color: 'black', fontSize: 18}
			},
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
			vAxis: {
				minValue:0,
				viewWindow: {
					min: 0
				},
				maxValue: 2
			},
			explorer: {
				axis: 'horizontal',
				actions: ['dragToZoom', 'rightClickToReset'] ,
				maxZoomIn: 50.0,
				keepInBounds: true
			},
			widgetHeight: 400
		});

		this.currentSiteSub = this.sideMenuService.currentSite.subscribe(siteInfo => {
			if(siteInfo.id) {
				this.site = siteInfo;
				this.getReport();
			}
		});
		this.setGridColumns();
	}

	getReport() {
		this.getReportVars = {
			fromDate: this.selectedDateRange.fromDate,
			toDate: this.selectedDateRange.toDate
		};
		this.actFirstService.getReport(this.site.id, moment.utc(this.selectedDateRange.fromDate.getTime()).startOf('day').unix(), moment.utc(this.selectedDateRange.toDate.getTime()).endOf('day').unix()).subscribe(data => {
			this.apiResponse = data;
			this.siteDashboardService.getSiteGroups(this.site.id).subscribe((response: any) => {
				this.siteGroups = _.map(response, "id");
				this.dateRangeChange();
			});
		});
	}

	dateRangeChange() {
		if(this.getReportVars.fromDate > this.selectedDateRange.fromDate || this.getReportVars.toDate < this.selectedDateRange.toDate) {
			this.getReport();
		} else {
			this.updateAvailabilityText();
			this.formatData(this.apiResponse, this.selectedDateRange);
		}
	}

	updateAvailabilityText() {
		let fromDateSiteZone = this.commonUtil.getZoneTimestampFromUTC(this.site.zoneid, moment.utc(this.selectedDateRange.fromDate.getTime()).unix(), true);
		let toDateSiteZone = this.commonUtil.getZoneTimestampFromUTC(this.site.zoneid, moment.utc(this.selectedDateRange.toDate.getTime()).unix(), true);
		this.availabilityText = this.translateService.instant('g.availability') +' (' + moment.utc(fromDateSiteZone*1000).format('MM/DD/YYYY') + ' - ' + moment.utc(toDateSiteZone*1000).format('MM/DD/YYYY') + ')';
	}

	setGridColumns(){
		this.gridColumns = [];
		let gridColumns = [];
		gridColumns = [
			{headerName: this.translateService.instant('g.day'), field: "date", type: "date"},
			{headerName: this.translateService.instant('device.truck_type'), field: "groupname"},
		];
		for(let innerGroup of this.keys) {
			if(this.typesStatus[innerGroup]) {
				if(this.displayStatus.avg) {
					gridColumns.push({headerName: this.translateService.instant('act_first.average') + ' ' + this.actFirstService.getGroupName(innerGroup), type: "number", field: innerGroup+"_averaged_count_of_charger", filter: false});
					gridColumns.push({headerName: this.translateService.instant('act_first.percent_average') + ' ' + this.actFirstService.getGroupName(innerGroup), type: "number", field: innerGroup+"_percent_averaged_count_of_charger", filter: false, hide: true});
				}
				if(this.displayStatus.max) {
					gridColumns.push({headerName: this.translateService.instant('g.max') + ' ' + this.actFirstService.getGroupName(innerGroup), type: "number", field: innerGroup+"_max_count_of_charger", filter: false});
					gridColumns.push({headerName: this.translateService.instant('act_first.percent_max') + ' ' + this.actFirstService.getGroupName(innerGroup), type: "number", field: innerGroup+"_percent_max_count_of_charger", filter: false, hide: true});
				}
				if(this.displayStatus.min) {
					gridColumns.push({headerName: this.translateService.instant('g.min') + ' ' + this.actFirstService.getGroupName(innerGroup), type: "number", field: innerGroup+"_min_count_of_charger", filter: false});
					gridColumns.push({headerName: this.translateService.instant('act_first.percent_min') + ' ' + this.actFirstService.getGroupName(innerGroup), type: "number", field: innerGroup+"_percent_min_count_of_charger", filter: false, hide: true});
				}
			}
		}

		this.gridColumns = gridColumns;
	}

	formatData(data, dateRange) {
		this.data = {};
		this.gridRows = [];
		this.chartObjs = {};
		this.groupCharts = [];

		let fromDate	= Math.round(dateRange.fromDate.getTime() / 1000),
			toDate		= Math.round(dateRange.toDate.getTime() / 1000);

		let numberOfDays = 0;

		let chartData = {};

		for (let date in data) {

			if (+date < fromDate || +date > toDate) {
				continue;
			}
			numberOfDays++;

			for (let groupid in data[date]) {
				if (this.siteGroups.indexOf(parseInt(groupid)) == -1) {
					continue;
				}
				if(!chartData[groupid]) {
					chartData[groupid] = [];
				}
				let chartRow: any = {};
				chartRow.date = date;

				let groupname = data[date][groupid]['groupname'];

				let row = {
					groupname: groupname,
					date: (+date)*1000
				};
				let count_of_chargers	= data[date][groupid].count_of_chargers,
					count_of_battviews	= data[date][groupid].count_of_battviews;

				var validStatuses = [
					"not_connected",
					"charging",
					"in_maintenance",
					"battery_faulted",
					"charger_faulted",
					"user_faulted",
					"relaxing",
					"relaxed",
					"ready"
				];
				for (let status in data[date][groupid]) {
					if(!validStatuses.includes(status))
						continue;

					if (!this.data[groupid]) {
						this.data[groupid] = {
							groupname: groupname,
							id: groupid
						}
					}

					if (this.keys.indexOf(status) > -1 && !this.data[groupid][status] && status != "battery_faulted" && status != "charger_faulted" && status != "user_faulted" && status != "in_maintenance") {
						this.data[groupid][status] = {
							averaged_count_of_charger:0,
							max_count_of_charger:0,
							min_count_of_charger:this.largeNumber,
							count_of_chargers: 0,
							count_of_battviews: 0
						};
					}
					let innerGroup = status;
					if (status == "battery_faulted" || status == "charger_faulted" || status == "user_faulted" || status == "in_maintenance") {
						if (!this.data[groupid]['parked']) {
							this.data[groupid]['parked'] = {
								averaged_count_of_charger:0,
								max_count_of_charger:0,
								min_count_of_charger:this.largeNumber
							};
						}
						this.data[groupid]['parked']['averaged_count_of_charger'] += data[date][groupid][status].averaged_count_of_charger;
						this.data[groupid]['parked']['max_count_of_charger'] = data[date][groupid][status].max_count_of_charger > this.data[groupid]['parked']['max_count_of_charger'] ? data[date][groupid][status].max_count_of_charger : this.data[groupid]['parked']['max_count_of_charger'];
						this.data[groupid]['parked']['min_count_of_charger'] = data[date][groupid][status].min_count_of_charger < this.data[groupid]['parked']['min_count_of_charger'] ? data[date][groupid][status].min_count_of_charger : this.data[groupid]['parked']['min_count_of_charger'];
						innerGroup = 'parked';
					} else {
						if (this.keys.indexOf(status) > -1) {
							this.data[groupid][status]['averaged_count_of_charger'] += data[date][groupid][status].averaged_count_of_charger;
							this.data[groupid][status]['max_count_of_charger'] = data[date][groupid][status].max_count_of_charger > this.data[groupid][status]['max_count_of_charger'] ? data[date][groupid][status].max_count_of_charger : this.data[groupid][status]['max_count_of_charger'];
							this.data[groupid][status]['min_count_of_charger'] = data[date][groupid][status].min_count_of_charger < this.data[groupid][status]['min_count_of_charger'] ? data[date][groupid][status].min_count_of_charger : this.data[groupid][status]['min_count_of_charger'];
						}
					}

					if (this.keys.indexOf(innerGroup) > -1 && this.typesStatus[innerGroup]) {
						let percent_averaged_count_of_charger, percent_max_count_of_charger, percent_min_count_of_charger ;

						if(!count_of_battviews || count_of_battviews < count_of_chargers) {
							percent_averaged_count_of_charger = "N/A"
							percent_max_count_of_charger = "N/A"
							percent_min_count_of_charger = "N/A"
						} else {
							percent_averaged_count_of_charger = Math.round((parseInt(this.data[groupid][innerGroup].averaged_count_of_charger) / count_of_battviews) * 100) + "%";
							percent_max_count_of_charger = Math.round((parseInt(this.data[groupid][innerGroup].max_count_of_charger) / count_of_battviews) * 100) + "%";
							percent_min_count_of_charger = Math.round((parseInt(this.data[groupid][innerGroup].min_count_of_charger) / count_of_battviews) * 100) + "%";
						}
						// let avg = Math.round(this.data[groupid][innerGroup].averaged_count_of_charger/numberOfDays);
						// let max = this.data[groupid][innerGroup].max_count_of_charger;
						// let min = this.data[groupid][innerGroup].min_count_of_charger == this.largeNumber ? 0 : this.data[groupid][innerGroup].min_count_of_charger;

						row[innerGroup+"_averaged_count_of_charger"] = data[date][groupid][status].averaged_count_of_charger;
						row[innerGroup+"_max_count_of_charger"] = data[date][groupid][status].max_count_of_charger;
						row[innerGroup+"_min_count_of_charger"] = data[date][groupid][status].min_count_of_charger;
						row[innerGroup+"_percent_averaged_count_of_charger"] = percent_averaged_count_of_charger;
						row[innerGroup+"_percent_max_count_of_charger"] = percent_max_count_of_charger;
						row[innerGroup+"_percent_min_count_of_charger"] = percent_min_count_of_charger;
						chartRow[innerGroup] = {
							avg: data[date][groupid][status].averaged_count_of_charger,
							max: data[date][groupid][status].max_count_of_charger,
							min: data[date][groupid][status].min_count_of_charger
						};
					}
				}
				this.gridRows.push(row);
				chartData[groupid].push(chartRow);
			}
		}

		for (let group in this.data) {
			let availabilityData = [
				[
					{label: this.translateService.instant('g.time'), type: "datetime"}
				]
			];
	
			for(let innerGroup of this.keys) {
				if(this.typesStatus[innerGroup]) {
					if(this.displayStatus.avg) {
						availabilityData[0].push({label: this.translateService.instant('act_first.avg')+" "+this.actFirstService.getGroupName(innerGroup), type: "number"});
					}
					if(this.displayStatus.max) {
						availabilityData[0].push({label: this.translateService.instant('g.max')+" "+this.actFirstService.getGroupName(innerGroup), type: "number"});
					}
					if(this.displayStatus.min) {
						availabilityData[0].push({label: this.translateService.instant('g.min')+" "+this.actFirstService.getGroupName(innerGroup), type: "number"});
					}
				}
			}
	
			let chart_data = this.prepareChartData(chartData[group]);
			
			availabilityData = availabilityData.concat(chart_data);
			this.chartObjs[group] = {groupid : group, groupname: this.data[group].groupname};
			this.chartObjs[group].dataTable = availabilityData;
		}

		this.groupCharts = _.values(this.chartObjs);
	}

	prepareChartData(chartData) {
		let result = [];
		for(let record of chartData) {
			let row = [];
			row.push(new Date(+(record.date) * 1000));
			for(let innerGroup of this.keys) {
				if(this.typesStatus[innerGroup]) {
					if(this.displayStatus.avg) {
						row.push(record[innerGroup].avg);
					}
					if(this.displayStatus.max) {
						row.push(record[innerGroup].max);
					}
					if(this.displayStatus.min) {
						row.push(record[innerGroup].min);
					}
				}
			}
			result.push(row);
		}
		return result;
	}

	printChart(title, elementId) {
		let image = this.images[elementId];
		let printContent = '<h4>' + title + ' ' + this.availabilityText + '</h4><br><br><img src="'+image+'" style="width:100%;"" border="0" alt="Null">';

		this.commonUtil.print({
			appendPrintContent: printContent,
		});
	}

	generateImageURI(event) {
		let chart = event['chart'];
		let elementId = event['elementId'];
		let image = this.chartUtil.getChartImageUri(chart);
		this.images[elementId] = image;
	}

	ngOnDestroy() {
		this.currentSiteSub.unsubscribe();
	}

	updateStatuses(key) {
		this.typesStatus[key] = !this.typesStatus[key];
		let hasAnyStatusEnabled = false;
		for(let key in this.typesStatus) {
			hasAnyStatusEnabled = hasAnyStatusEnabled || this.typesStatus[key];
		}
		this.hasAnyStatusEnabled = hasAnyStatusEnabled;
		this.dateRangeChange();
	}

	updateDisplay(key) {
		this.displayStatus[key] = !this.displayStatus[key];
		let hasAnyDisplayEnabled = false;
		for(let key in this.displayStatus) {
			hasAnyDisplayEnabled = hasAnyDisplayEnabled || this.displayStatus[key];
		}
		this.hasAnyDisplayEnabled = hasAnyDisplayEnabled;
		this.dateRangeChange();
		this.router.navigate([], {
			queryParams: { ...this.displayStatus },
		});
	}
}