export class ColumnChartConfig {
	yTitle;
	xTitle;
	vAxisFormat;
	vAxisMinValue;
	vAxisMaxValue;
	vAxisViewWindowMaxValue;
	vAxisTitleTextStyle;
	vAxisTicks;
	vAxisGridlines;
	hAxis;
	chartArea;
	widgetHeight:number = 200;
	widgetWidth;
	tooltip: any = {trigger: 'none'};
	colors: string[] = ['#bd2f26', '#3f69ec'];
	legend: {position} = {position: 'none'};
	formatFunction: any;
	updateColumns: any;
	updateConfig: any;
	explorer: any;
	title: string;
	getPrintItems;
	dataIsDataTable: boolean = false;
	series: any;
	setLocalStorage: any;

	constructor(options: any = {}) {
		if(options.yTitle) this.yTitle = options.yTitle;
		if(options.title) this.title = options.title;
		if(options.xTitle) this.hAxis = { title: options.xTitle };
		if(options.vAxisMinValue != undefined) this.vAxisMinValue = options.vAxisMinValue;
		if(options.vAxisMaxValue) this.vAxisMaxValue = options.vAxisMaxValue;
		if(options.vAxisViewWindowMaxValue) this.vAxisViewWindowMaxValue = options.vAxisViewWindowMaxValue;
		if(options.vAxisTitleTextStyle) this.vAxisTitleTextStyle = options.vAxisTitleTextStyle;
		if(options.vAxisTicks) this.vAxisTicks = options.vAxisTicks;
		if(options.vAxisGridlines) this.vAxisGridlines = options.vAxisGridlines;
		if(options.colors) this.colors = options.colors;
		if(options.hAxis) this.hAxis = options.hAxis;
		if(options.isPercent) this.vAxisFormat = "percent";
		if(options.vAxisFormat) this.vAxisFormat = options.vAxisFormat;
		if(options.widgetHeight) this.widgetHeight = options.widgetHeight;
		if(options.widgetWidth) this.widgetWidth = options.widgetWidth;
		if(options.chartArea) this.chartArea = options.chartArea;
		if(options.tooltip != undefined) this.tooltip = options.tooltip;
		if(options.explorer) this.explorer = options.explorer;
		if(options.LegendPosition) this.legend = {position: options.LegendPosition};
		if(options.dataIsDataTable) this.dataIsDataTable = options.dataIsDataTable;
		if(options.series) this.series = options.series;
	}
}