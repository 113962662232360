<div class="row">
	<div class="col-md-6 col-md-offset-3">
		<div class="boxs3">
			<form #customerForm="ngForm" (ngSubmit)="saveCustomer()">
				<div class="row">
					<div class="col-xs-12">
						<h2>Add/Update Customer Info</h2>
						<app-customer-edit [customer]="customer"></app-customer-edit>
						<button type="submit" class="btn btn-primary" [disabled]="customerForm.invalid || isCustomerNameEmpty()">Submit</button>
						<button type="button" class="btn btn-default" (click)="goBack()">Cancel</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>