import { Component, OnInit, Input } from '@angular/core';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';

@Component({
	selector: 'app-charger-peak-whr',
	templateUrl: './charger-peak-whr.component.html',
	styleUrls: ['./charger-peak-whr.component.css']
})
export class ChargerPeakWhrComponent implements OnInit {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: ColumnChartConfig;
	tableColumns: any[] = [
		{title: "Charger ID", property: "chargerusername"},
		{title: "Charger SN", property: "serialnumber"},
		{title: "Value", property: "value"}
	];
	title: string = 'Charger Peak Demand (Peak kW)';
	elementId: string = 'charger_peak_whr';
	widgetClass: string = 'auto-height-widget';
	siteWidgetOptions: any = {
		hasOperation: true,
		hasChargerModels: true,
		hasNormalizeCheckBox: true,
		hasDate: true,
		isSitePerformanceWidget: true,
		isChargers: true,
		hideYesterdayDate: true,
		excludeSinceInstallationOption: true
	};

	constructor(private chartUtil: ChartUtil) { }

	ngOnInit() {
		this.config = new ColumnChartConfig({
			isPercent: false,
			yTitle: "KW",
			widgetHeight: 400,
			chartArea: {top: 10, width:'70%', height:'80%'},
			vAxisMinValue: 0,
			tooltip: {trigger: 'hover'},
			vAxisViewWindowMaxValue: null,
			vAxisFormat: null,
		});

		this.config.formatFunction = (data) => {
			data = data.list;
			let formattedData: any[] = [
				[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'},
				]
			];

			for(let i = 0; i < data.length; i++) {
				let item: any = data[i];
				formattedData.push(
					[
						{v: ''},
						{v: item.value},
						{v: ''},
						{v: 'color: ' + this.chartUtil.getColor(item.value, true)}
					]
				);
			}

			return formattedData;
		};

		this.config.updateConfig = (config, flags) => {
			let normalizeKW = flags.normalizeKW || false;
			config.yTitle = normalizeKW ? "KW %" : "KW";
			config.vAxisViewWindowMaxValue = normalizeKW ? 100 : null;
			config.vAxisFormat = normalizeKW ? '#\'%\'' : null;

			return config;
		}
	}
}
