<div class="panel-body boxs3">
	<div class="col-md-12 margin-bottom-lg margin-top-lg">
		<div class="col-md-1 btn btn-default disabled default-cursor">Date:</div>
		<div class="col-md-4">
			<div class='input-group date'>
				<input readonly class="form-control" placeholder="mm/dd/yyyy" id="fromDate" name="dp" [(ngModel)]="fromDateFmt" bsDatepicker #fromDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
				<span class="input-group-btn">
					<button class="btn btn-default btn-date" (click)="fromDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
				</span>
			</div>
		</div>
		<div class="col-md-2 no-gutter">
			<button class="form-control btn btn-primary" (click)="fetchData()">{{'g.get_records' | translate}}</button>
		</div>
	</div>

	<div class="col-md-12" *ngIf="chargerEnergyData.length > 0">
		<div class="toolss2 margin-right btn btn-default">
			<button type="button" class="fa fa-print" data-toggle="tooltip" title="Print" (click)="printChargerEnergyChart();"></button>
		</div>
	</div>
	<div class="col-md-12" *ngIf="dataIsFetched">
		<div *ngIf="noChart" class="alert alert-warning" role="alert">
			{{'g.no_data_available_to_draw_this_chart' | translate}}
		</div>
		<div *ngIf="!noChart" class="margin-left-xlg">
			<input type="checkbox" id="showNumberOfChargers" class="form-control" (change)="showNumberOfChargersChanged($event);" [(ngModel)]="showNumberOfChargers">
			<label for="showNumberOfChargers">{{'g.show_number_of_chargers' | translate}}</label>
		</div>

		<h1 *ngIf="!noChart" class="tcenter">
			{{'act.energy_shaved' | translate}}: {{shaved_energy | number:'1.0-1'}} {{'g.KWHR' | translate}}
		</h1>
		<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px" *ngIf="!noChart">
			<combo-chart
				[data]="snapshotsData"
				[config]="snapshotsConfig"
				elementId="chargerEnergy"
				(onChartReady)="generateImageURI($event)"
			></combo-chart>
		</div>
	</div>
</div>