import { Component, OnInit } from '@angular/core';
import { ActFirstService } from '../../act-first.service';
import { ActivatedRoute } from "@angular/router";
import * as _ from 'lodash';
import *  as moment from 'moment';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { SideMenuService } from '../../../../../shared/side-menu/side-menu.service';

@Component({
  selector: 'app-historical-log',
  templateUrl: './historical-log.component.html',
  styleUrls: ['./historical-log.component.css']
})
export class HistoricalLogComponent implements OnInit {

  startDate = new Date(moment().subtract(1, 'days').startOf('day').unix() * 1000);
  endDate = new Date(moment().startOf('day').unix() * 1000);
  startFromTime;
  endToTime;
  validStartFromTime: boolean = true;
  validEndToTime: boolean = true;
  submitted:boolean = false;
  invalidDates = false;

  siteId;
  customerId;
  currentSite:any;
  dataByDate = {};
  dateTitles = {};
  dataByDateArr = [];
  constructor(
    private actFirstService: ActFirstService,
    private route: ActivatedRoute,
	private commonUtil: CommonUtil,
	private sideMenuService: SideMenuService) { }

  ngOnInit() {
    this.siteId = this.route.parent.snapshot.params['siteid'];
	this.customerId = this.route.parent.snapshot.params['customerid'];

	this.sideMenuService.currentSite.subscribe(data => {
		if(data && data.id) {
			this.currentSite = data;
		}
	});
  }

  fetchData() {
	this.submitted = true;
	this.invalidDates = false;
	if (!this.validStartFromTime || !this.validEndToTime) {
		return;
	}

	let fromDate:any= new Date(new Date(this.startDate).setHours(moment(this.startFromTime).hours(), moment(this.startFromTime).minutes(), 0, 0));
	fromDate		= Math.floor((-fromDate.getTimezoneOffset() * 60000 + fromDate.getTime()) / 1000);
	fromDate		= this.commonUtil.getUTCTimestampFromZone(this.currentSite.zoneid, fromDate, true);

	let toDate:any	= new Date(new Date(this.endDate).setHours(moment(this.endToTime).hours(), moment(this.endToTime).minutes(), 0, 0));
	toDate			= Math.floor((-toDate.getTimezoneOffset() * 60000 + toDate.getTime()) / 1000);
	toDate			= this.commonUtil.getUTCTimestampFromZone(this.currentSite.zoneid, toDate, true);

	if(toDate < fromDate)
		return this.invalidDates = true;

    let siteId = this.route.parent.snapshot.params['siteid'];
    this.dataByDate = {};
    this.dataByDateArr = [];
    this.actFirstService.getHistoricalLog(siteId, fromDate, toDate).subscribe(result => {
      
      for (let logObj of result['data']) {
        for (let group of logObj['groups']) {
          for (let innerGroup in group['innergroups']) {
            for(let obj of group['innergroups'][innerGroup]) {

              obj.groupname = group['groupname'];
              obj.status = obj.innergroup;

              obj.date = parseInt(obj.time) * 1000;
			  this.dateTitles[obj.date] = moment(this.commonUtil.getZoneTimestampFromUTC(this.currentSite.zoneid, obj.time, true)*1000).utc().format('MMM DD, YYYY, hh:mm:ss A');

              obj.name_link = '/' + this.customerId + '/' + this.siteId + '/chargers/analytics/cycles/' + obj.id;
              obj.exit_status_text = this.commonUtil.getDeviceExitCode('charger', obj.exit_status);
              if (obj.batteryid) {
                obj.battery_id_link = '/' + this.customerId + '/' + this.siteId + '/battviews/analytics/events-log/' + obj.batteryid;
              }

              if (!this.dataByDate[obj.date]) {
                this.dataByDate[obj.date] = {date: obj.date, innergroups: {}};
              }
              if (!this.dataByDate[obj.date]['innergroups'][innerGroup]) {
                this.dataByDate[obj.date]['innergroups'][innerGroup] = {};
              }
              if (!this.dataByDate[obj.date]['innergroups'][innerGroup][obj.id]) {
                this.dataByDate[obj.date]['innergroups'][innerGroup][obj.id] = [];
              }
              this.dataByDate[obj.date]['innergroups'][innerGroup][obj.id].push(obj);
              
            }
          }
        }
      }
      
      this.dataByDateArr = _.sortBy(_.values(this.dataByDate), "date");

    });
  }

  getGroupName(key) {
    return this.actFirstService.getGroupName(key);
  }

  getDateInnerGroups(date) {
    return _.keys(this.dataByDate[date].innergroups);
  }

  getKeys(obj) {
    let keys = _.keys(obj);
    return _.remove(keys, function(n) {
        return parseInt(n);
      });
  }

}
