import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActFirstService } from '../../act-first.service';
import { ActivatedRoute } from "@angular/router";
import * as _ from 'lodash';
import * as moment from 'moment';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { SideMenuService } from '../../../../../shared/side-menu/side-menu.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	providers: [DatePipe],
	selector: 'app-picks',
	templateUrl: './picks.component.html',
	styleUrls: ['./picks.component.css']
})
export class PicksComponent implements OnInit {

	picksData = [];
	picksColumns = [];

	startDate = new Date(moment().subtract(1, 'days').startOf('day').unix() * 1000);
	endDate = new Date();
	startFromTime;
	endToTime;
	validStartFromTime: boolean = true;
	validEndToTime: boolean = true;
	submitted:boolean = false;
	invalidDates = false;

	siteId;
	customerId;
	currentSite: any = {}

	constructor(
		private actFirstService: ActFirstService,
		private route: ActivatedRoute,
		private datePipe: DatePipe,
		private commonUtil: CommonUtil,
		private sideMenuService: SideMenuService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.siteId = this.route.parent.snapshot.params['siteid'];
		this.customerId = this.route.parent.snapshot.params['customerid'];
		this.sideMenuService.currentSite.subscribe(data => {
			if(data && data.id) {
				this.currentSite = data;
			}
		});
	}

	fetchData() {
		this.submitted = true;
		this.invalidDates = false;
		if (!this.validStartFromTime || !this.validEndToTime) {
			return;
		}

		let fromDate:any= new Date(new Date(this.startDate).setHours(moment(this.startFromTime).hours(), moment(this.startFromTime).minutes(), 0, 0));
		fromDate		= Math.floor((-fromDate.getTimezoneOffset() * 60000 + fromDate.getTime()) / 1000);
		fromDate		= this.commonUtil.getUTCTimestampFromZone(this.currentSite.zoneid, fromDate, true);

		let toDate:any	= new Date(new Date(this.endDate).setHours(moment(this.endToTime).hours(), moment(this.endToTime).minutes(), 0, 0));
		toDate			= Math.floor((-toDate.getTimezoneOffset() * 60000 + toDate.getTime()) / 1000);
		toDate			= this.commonUtil.getUTCTimestampFromZone(this.currentSite.zoneid, toDate, true);

		if(toDate < fromDate)
			return this.invalidDates = true;

		let siteId = this.route.parent.snapshot.params['siteid'];
		this.actFirstService.getPicksHistory(siteId, fromDate, toDate).subscribe(result => {
			let groups = result['groups'];
			let chargers = result['chargers'];
			for (let item of result['data']) {
				item['date'] = moment(this.commonUtil.getZoneTimestampFromUTC(this.currentSite.zoneid, item['time'], true)*1000).utc().format('MMM DD, YYYY, hh:mm:ss A');
				item['groupname'] = groups[item['chargergroupid']];
				if(chargers[item['chargerid']])
					item['name'] = chargers[item['chargerid']]['name'];
				else
					item['name'] = "DELETED,REPLACED OR MOVED";
					
				item['type'] =  _.capitalize(item['type']);
			}
			this.picksData = result['data'];
			let customerId = this.customerId;
			let siteId = this.siteId ;
			

			this.picksColumns = [
				{headerName: this.translateService.instant('g.name'), width: 200,
					field: "name",
					cellRendererSelector:function (params) {
						params['label'] = params.data.name;
						params['link'] = '/#/' + customerId + '/' + siteId + '/chargers/analytics/cycles/' + params.data.chargerid;
						return {
							component: 'linkCellRenderer',
							params: params
						};
					},
					cellRendererParams: {picksData: this.picksData},
					filter: 'agTextColumnFilter'
				},
				{headerName: this.translateService.instant('device.truck_type'), field: "groupname", width: 200},
				{headerName: this.translateService.instant('g.date'), field: "date", width: 200},
				{headerName: this.translateService.instant('g.type'), field: "type", width: 200},
			];

		});
	}

}
