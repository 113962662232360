import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
	selector: 'app-exceptions',
	templateUrl: './exceptions.component.html',
	styleUrls: ['./exceptions.component.css']
})
export class ExceptionsComponent implements OnInit, OnChanges {

	@Input() date: {
		fromDate: any,
		toDate: any
	} = {
		fromDate: new Date(),
		toDate: new Date()
	};
	@Input() device: any = {};
	@Input() dailyDetails: any = {};
	@Input() summaryObject: any = {};
	@Input() customerName: string = null;
	@Input() siteName: string = null;

	dataObj: {
		SerialNumber: any,
		ID: any,
		NominalVoltage: any,
		AhrCapacity: any,
		missedChargersPerDay: any,
		missedEQ: any,
		totalMissedFinishes: any,
		reachedMaxedEBU: any,
		countOfHighTemp: any,
		reachedTheMissedDays: any,
		currentSensingValue: any,
		deepDischarge: any,
		deepUse: any,
		AHRReturn: any
	} = {
		SerialNumber: null,
		ID: null,
		NominalVoltage: null,
		AhrCapacity: null,
		missedChargersPerDay: null,
		missedEQ: null,
		totalMissedFinishes: null,
		reachedMaxedEBU: null,
		countOfHighTemp: null,
		reachedTheMissedDays: null,
		currentSensingValue: null,
		deepDischarge: null,
		deepUse: null,
		AHRReturn: null
	};
 
	constructor() { }

	ngOnInit() {
	}

	ngOnChanges(changes) {
		if(changes.summaryObject && Object.keys(changes.summaryObject.currentValue).length > 0 && changes.summaryObject.currentValue) {
			this.prepareData();
		}
	}

	prepareData() {
		this.dataObj.ID = this.device.batteryid;
		this.dataObj.SerialNumber = this.device.serialnumber;
		this.dataObj.NominalVoltage = this.device.nominalvoltage;
		this.dataObj.AhrCapacity = this.device.ahrcapacity;

		this.dataObj.missedChargersPerDay = {
			value: this.summaryObject.missedChargersPerDay + " Times",
			style: () =>{
				return this.summaryObject.missedChargersPerDay > 0 ? {'font-weight':'bold'} : "";
			}
		};
		
		this.dataObj.missedEQ = {
			value: this.summaryObject.totalMissedEQs,
			style: () =>{
				return this.summaryObject.totalMissedEQs > 0 ? {'font-weight':'bold'} : "";
			}
		};
		
		this.dataObj.totalMissedFinishes = {
			value: this.summaryObject.totalMissedFinishes,
			style: () =>{
				return this.summaryObject.totalMissedFinishes > 0 ? {'font-weight':'bold'} : {};
			}
		};

		this.dataObj.reachedMaxedEBU = {
			value: this.summaryObject.reachedMaxedEBU ? "Yes" : "No",
			style: () =>{
				return this.summaryObject.reachedMaxedEBU ? "{'font-weight':'bold'}" : "";
			}
		};

		this.dataObj.countOfHighTemp = {
			value: this.summaryObject.countOfHighTemp,
			style: () =>{
				return this.summaryObject.countOfHighTemp > 0 ? {'font-weight':'bold'} : "";
			}
		};

		
		this.dataObj.reachedTheMissedDays = {
			value: this.summaryObject.reachedTheMissedDays ? "Yes" : "No",
			style: () =>{
				return this.summaryObject.reachedTheMissedDays ? {'font-weight':'bold'} : "";
			}
		};

		this.dataObj.currentSensingValue = {
			value: this.summaryObject.isCurrentSensingIssue ? "Yes" : "No",
			style: () =>{
				return this.summaryObject.isCurrentSensingIssue ? {'font-weight':'bold'} : "";
			}
		};

		this.dataObj.deepDischarge = {
			value: this.summaryObject.deepDischarge ? "Yes" : "No",
			style: () =>{
				return this.summaryObject.deepDischarge ? {'font-weight':'bold'} : "";
			}
		};

		this.dataObj.deepUse = {
			value: this.summaryObject.deepUse ? "Yes" : "No",
			style: () =>{
				return this.summaryObject.deepUse ? {'font-weight':'bold'} : "";
			}
		};

		this.dataObj.AHRReturn = {
			value: this.summaryObject.AHRReturn > 0 ? "HIGH" : this.summaryObject.AHRReturn < 0 ? "LOW" : 'OK',
			style: () =>{
				return this.summaryObject.AHRReturn != 0 ? {'font-weight':'bold'} : "";
			}
		};
	}

	getCSVcontent() {
		return [
			["Client: ", this.customerName, " Site: ", this.siteName],
			["Model", this.device.batterymodel],
			["Battery S/N", this.device.batterysn],
			["Battery ID", this.device.batteryid],
			["Date Range", moment(this.date.fromDate).format('MM/DD/YYYY') + " - " + moment(this.date.toDate).format('MM/DD/YYYY')],
			["Battery Truck ID", this.device.truckid],
			[],
			['*'+this.device.serialnumber],
			["Nominal Voltage", this.device.nominalvoltage],
			["Capacity AHR", this.device.ahrcapacity],
			["Missed Min Charges", this.dataObj.missedChargersPerDay.value],
			["Weekly Missed EQ", this.dataObj.missedEQ.value],
			["Missed Finish", this.dataObj.totalMissedFinishes.value],
			["Inuse Limit Exceeded" , this.dataObj.reachedMaxedEBU.value],
			["Over Temperature", this.dataObj.countOfHighTemp.value],
			["Missed Min Connect", this.dataObj.reachedTheMissedDays.value],
			["Current Sensing Issue", this.dataObj.currentSensingValue.value],
			["Potential Weak Cells", this.dataObj.deepDischarge.value],
			["Deep Discharge", this.dataObj.deepUse.value],
			["AHR Return", this.dataObj.AHRReturn.value]
		];
	}
}
