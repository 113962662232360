<div class="panel panel-default margin-bottom-xs" *ngIf="(isSiteEnergy && currentSite.act_intelligent_type != 0) || (!isSiteEnergy && charger.actviewenable)">
	<div class="panel-body">

		<app-customized-widget 
			title="{{'charger.live_feed' | translate}}"
			widgetId="charger-energy-live-feed"
			[options]="{hasExpand: false, hasPrint: false}"
			[widgetClass]="'p-b-30'"
		>
			<div *appTransclude>
				<div class="col-md-12" *ngIf="LivePowerSnapshots.length > 0">
					<div class="toolss2 margin-right btn btn-default">
						<button type="button" class="fa fa-print" data-toggle="tooltip" title="{{'g.print' | translate}}" (click)="printChargerEnergyChart(true);"></button>
					</div>
				</div>
				<div class="col-md-12">
					<div *ngIf="noChart[1]" class="alert alert-warning" role="alert">
						{{'g.no_data_available_to_draw_this_chart' | translate}}
					</div>
					<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px" *ngIf="!noChart[1]">
						<line-chart
							(onChartReady)="generateImageURI($event)"
							[data]="snapshotsData[1]"
							[config]="snapshotsConfig[1]"
							elementId="liveChargerEnergy"
						>
						</line-chart>
					</div>
				</div>
			</div>
		</app-customized-widget>
		
		<app-customized-widget 
			title="{{'g.date_range' | translate}}"
			widgetId="charger-energy-date-range"
			[options]="{hasExpand: false, hasPrint: false}"
			[widgetClass]="'p-b-30'"
		>
			<div *appTransclude>
				<div class="red-text col-md-12 col-md-offset-2 margin-bottom-sm" *ngIf="errMessage">
					{{errMessage}}
				</div>

				<div class="col-md-12">
					<div class="col-md-2 text-right"><strong>{{'time.start_date' | translate}}:</strong></div>
					<div class="col-md-4">
						<div class="input-group">
							<input readonly class="form-control" id="fromDate" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="dateObj.from" bsDatepicker #fromDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
							<span class="input-group-btn">
								<button class="btn btn-default btn-date" (click)="fromDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
							</span>
						</div>
					</div>
				</div>

				<div class="col-md-12 margin-top-xs">
					<div class="col-md-2 text-right"><strong>{{'time.end_date' | translate}}:</strong></div>
					<div class="col-md-4">
						<div class="input-group">
							<input readonly class="form-control" id="toDate" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="dateObj.to" bsDatepicker #toDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
							<span class="input-group-btn">
								<button class="btn btn-default btn-date" (click)="toDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
							</span>
						</div>
					</div>
				</div>

				<div class="col-md-12 margin-top-xs">
					<div class="col-md-3 col-md-offset-2">
						<button class="form-control btn btn-success" (click)="getChargerPowerSnapshots()" [disabled]="!dateObj.to || !dateObj.from">{{'g.get_data' | translate}}</button>
					</div>
				</div>

				<div class="col-md-12 margin-top-lg">
					<app-grid-v2 *ngIf="currentSite.id"
						[id]="gridExportName"
						[fileName]="gridExportName+'_v1_'+currentSite.id"
						[modelId]="gridModelId"
						[columns]="chargerEnergyGridColumns"
						[data]="chargerEnergyGridData"
						translateHeaders="true"
						[settings]="{
							pagination: true,
							paginationPageSize: 20
						}"
					></app-grid-v2>
				</div>
				<div class="col-md-12" *ngIf="chargerEnergyGridData.length > 0">
					<div class="toolss2 margin-right btn btn-default">
						<button type="button" class="fa fa-print" data-toggle="tooltip" title="{{'g.print' | translate}}" (click)="printChargerEnergyChart();"></button>
					</div>
				</div>
				<div class="col-md-12">
					<div *ngIf="noChart[0]" class="alert alert-warning" role="alert">
						{{'g.no_data_available_to_draw_this_chart' | translate}}
					</div>
					<div style="width:100%; height:400px; vertical-align: top;  display: inline-block; padding: 10px" *ngIf="!noChart[0]">
						<line-chart
							(onChartReady)="generateImageURI($event)"
							[data]="snapshotsData[0]"
							[config]="snapshotsConfig[0]"
							elementId="chargerEnergy"
						>
						</line-chart>
					</div>
				</div>
			</div>
		</app-customized-widget>
	</div>
</div>