<div class="panel panel-default margin-bottom-xs">
	<div class="panel-body">
		<div class="col-md-6">
			<div class="form-field">
				<ng-select [items]="truckviews" bindLabel="appearanceName" placeholder="Select Truckview" name="device" [(ngModel)]="device" [clearable]="false" (ngModelChange)="truckviewChanged()">
				</ng-select>
			</div>
		</div>
		<div [ngClass]="{'col-md-6': !showElementsFlags.hasResetPLC, 'col-md-2': showElementsFlags.hasResetPLC}" *ngIf="showElementsFlags.pageHasManageBtn && (activePage != 'settings' || !multipleOptions)">
			<app-manage-device-btn [selectedDevices]="device?.id ? [device?.id] : []" *ngIf="!userService.hasAccessFunction('no_manage_devices')" [type]="'truckview'"></app-manage-device-btn>
		</div>
		<div [ngClass]="{'col-md-6': !showElementsFlags.hasResetPLC, 'col-md-2': showElementsFlags.hasResetPLC}" *ngIf="device && activePage == 'settings' && multipleOptions && truckviewID != 0">
			<div class="btn-group pointer" appDropdown [indirectOptionsList]="true">
				<i type="button" class="btn btn-default glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
				<app-truckview-actions [truckview]="device" [siteID]="siteId"></app-truckview-actions>
			</div>
		</div>
		<div class="col-md-4" *ngIf="showElementsFlags.hasResetPLC">
			<button class="btn btn-default mb20" ng-show="model.SelectedBFD && hasPLCFaults" (click)="resetPLC()">
				Reset PLC fault for a month
			</button>
		</div>
		<div class="col-md-6" *ngIf="!showElementsFlags.pageHasFilterByDate"></div>
		<div *ngIf="device?.id && showElementsFlags.pageHasFilterByDate" class="col-md-2">
			<span class="sdax">Select Date Range</span>
		</div>
		<div *ngIf="device?.id && showElementsFlags.pageHasFilterByDate" class="col-md-2">
			<div class="input-group">
				<input class="form-control" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="date.fromDate" bsDatepicker #fromDate="bsDatepicker" (ngModelChange)="datesChanged()" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
				<span class="input-group-btn">
					<button class="btn btn-default btn-date" (click)="fromDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
				</span>
			</div>
		</div>
		<div *ngIf="device?.id && showElementsFlags.pageHasFilterByDate" class="col-md-2">
			<div class="input-group" id="datetimepicker2">
				<input class="form-control" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="date.toDate" bsDatepicker #toDate="bsDatepicker" (ngModelChange)="datesChanged()" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
				<span class="input-group-btn">
					<button class="btn btn-default btn-date" (click)="toDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
				</span>
			</div>
		</div>
		<div *ngIf="device?.id && showElementsFlags.pageHasTimesInfo" class="col-md-6 padding-8">
			<span *ngIf="device.actviewenabled"><strong>Last Connect Time: </strong>{{device.lastconnecttime | date:'MM/dd/yyyy hh:mm:ss a'}}</span>
			<span *ngIf="userService.hasAccessFunction('view_rejected_device_connections') && device.actviewenabled"><br/><strong>IP Address: </strong>{{device.ip_address ? device.ip_address : 'No IP'}}</span>
			<span *ngIf="!device.actviewenabled && device.lastuploadtime > firstAcceptedDay"><strong>Last Upload Time: </strong>{{device.lastuploadtime | date:'MM/dd/yyyy hh:mm:ss a'}}</span>
		</div>
		<div *ngIf="device?.id && showElementsFlags.pageHasTruckviewTags" class="col-md-2 margin-top-xs">
			<span class="sdax">TRUCKView Tags</span>
		</div>
		<div *ngIf="device?.id && showElementsFlags.pageHasTruckviewTags" class="col-md-4">
			<tags
				[items]="siteTags | values"
				[deviceType]="'truckview'"
				[deviceId]="device.id"
				[tag]="deviceTags[device.id]"
				[customerId]="customerId"
				[siteId]="siteId"
				[isACTuser]="isACTuser"
				(onUpdateTags)="updateTags($event)"
			></tags>
		</div>
		<div *ngIf="device?.id" class="col-md-6 padding-8">
			<span [(ngModel)]="device.lasteventtime" *ngIf="!device?.actviewenabled && device?.lasteventtime > firstAcceptedDay"><strong>Last Record Date: </strong>{{device.lasteventtime | date:'MM/dd/yyyy hh:mm:ss a'}}</span>
		</div>

		<div *ngIf="device?.id && showElementsFlags.pageHasTruckviewTags && siteInfo.act_first_enabled" class="col-md-2 margin-top-xs">
			<span class="sdax">TRUCKView Groups</span>
		</div>
		<div *ngIf="device?.id && showElementsFlags.pageHasTruckviewTags && siteInfo.act_first_enabled" class="col-md-4">
			<ng-select
				[multiple]="false"
				[addTag]="false"
				[items]="siteGroups"
				(add)="addGroup($event)"
				[hideSelected]="false"
				placeholder="Add a Group"
				bindLabel="text"
				bindValue="id"
				[clearable]="false"
				[(ngModel)]="device.actfirstgroupid"
			></ng-select>
		</div>
		<div *ngIf="device?.id && showElementsFlags.pageHasTruckviewTags && siteInfo.act_first_enabled" class="col-md-6"></div>

		<div class="margin-top-xs col-md-12 no-gutter margin-left-sm" *ngIf="showElementsFlags.addNoteEnabled && device?.id">
		<a class="btn btn-xs btn-primary margin-left-xs" *ngIf="device?.id">
			<notes [siteId]="siteId" [modelType]="'truckview'" [modelId]="device?.id" [isAddNoteOnly]="true"></notes>
		</a>
		</div>
	</div>
</div>