import { Component, OnInit } from '@angular/core';
import { SiteDashboardService } from '../site-dashboard.service';

@Component({
	selector: 'app-battviews',
	templateUrl: './battviews.component.html',
	styleUrls: ['./battviews.component.css']
})
export class BattviewsComponent implements OnInit {

	constructor(
		private siteDashboardService: SiteDashboardService,
	) { }

	ngOnInit() {
		this.siteDashboardService.setActiveTab('batteries');
	}
}