import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UserService } from '../../../../auth/user.service';
import { UserSearchOptionsComponent } from '../user-search-options/user-search-options.component';
import { User } from '../../../../auth/user.model';
import { AdminService } from '../../../admin.service';
import { NotificationMessageService } from '../../../../shared/notification-message/notification-message.service';
import { BreadcrumbService } from '../../../../shared/breadcrumb/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-user-search',
	templateUrl: './user-search.component.html',
	styleUrls: ['./user-search.component.css']
})
export class UserSearchComponent implements OnInit, OnDestroy {

	@ViewChild('searchOptions') searchOptions:UserSearchOptionsComponent;
	users: [User];
	allUsers: any[];
	currentUser: any;

	// lists
	userTypes: {id: string, text: string}[] = [
		{id: 'oems', text: this.translateService.instant('site.oems')},
		{id: 'dealers', text: this.translateService.instant('site.dealers')},
		{id: 'truckdealer', text: this.translateService.instant('site.truck_dealers')},
		{id: 'clients', text: this.translateService.instant('site.clients')},
		{id: 'act', text: this.translateService.instant('act.act_users')},
		{id: 'specific', text: this.translateService.instant('site.specific_user')},
		{id: 'all', text: this.translateService.instant('g.all')}
	];

	constructor(
		public userService: UserService,
		private adminService: AdminService,
		private notificationMessage: NotificationMessageService,
		private breadcrumb: BreadcrumbService,
		private translateService: TranslateService
	) {
		this.currentUser = this.userService.getCurrentUser();
	}

	ngOnInit() {
		this.allUsers = [];
		this.breadcrumb.setBreadcrumb([
			{label: this.translateService.instant('g.users'), url: ""}
		]);
	}
	ngOnDestroy() {
		this.breadcrumb.hide();
	}

	delete(userId){
		if (confirm(this.translateService.instant('user.delete_user_prompt'))) {
			this.adminService.deleteUser(userId).subscribe(
				response => {
					this.notificationMessage.setMessage(response, 'success', 10000);
					this.searchOptions.resetCriteria();
					this.resetSearchResult();
				},
				err => {
					this.notificationMessage.setMessage(err);
				}
			);
		}
	}
	resetSearchResult() {
		this.users = [new User()];
		this.allUsers = [];
	}
	getBecomeUserToken(userId) {
		this.adminService.getBecomeUserToken(userId).subscribe(
			response => {
				var url = decodeURIComponent(window.location.origin+"/#/become-user/"+response);
				var msg = this.translateService.instant('user.become_user_link')+'<br/>';
				msg += "<input id='becomeUserURL' type='text' value='" + url + "' size='" + url.length + "'/>";
				this.notificationMessage.setMessage(msg, 'success', 30000);
			},
			err => {
				this.notificationMessage.setMessage(err);
			}
		);
	}
	ACTresetUserPassword(userId){
		if (confirm(this.translateService.instant('user.reset_gen_new_password'))) {
			this.adminService.ACTresetUserPassword(userId).subscribe(
				response => {
					var msg = this.translateService.instant('user.new_gen_password')+"<br/>";
					msg += "<input type='text' value='" + response + "' size='70'/>";
					this.notificationMessage.setMessage(msg, 'success', 30000);
				},
				err => {
					this.notificationMessage.setMessage(err);
				}
			);
		}
	}
	loadData(data) {
		this.allUsers = data['users'];
	}

	loadDataPagination(data) {
		this.users = data;
	}
}
