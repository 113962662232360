import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as moment from 'moment';
import { BattviewsDashboardService } from '../battviews-dashboard.service';
import { NotificationMessageService } from '../../../../../shared/notification-message/notification-message.service';

@Component({
	selector: 'app-fetch-rt-records',
	templateUrl: './fetch-rt-records.component.html',
	styleUrls: ['./fetch-rt-records.component.css']
})
export class FetchRtRecordsComponent implements OnInit {

	@Input() device: any = {};
	@Output() updateAppearanceElementsFlags = new EventEmitter<any>(true);

	fromDateFmt;
	toDateFmt;
	lastMonthTime: number = moment().utc().subtract(1, 'months').startOf('day').unix();

	constructor(
		private battviewsDashboardService: BattviewsDashboardService,
		private notificationMessage: NotificationMessageService,
	) { }

	ngOnInit() {
		this.updateAppearanceElementsFlags.emit({
			addNoteEnabled: false,
			pageHasFilterByDate: false,
			pageHasBattviewTags: true,
			hasResetPLC: false,
			pageHasManageBtn: true,
			pageHasTimesInfo: true,
		});
		this.fromDateFmt	= new Date(moment().utc().subtract(6, 'days').startOf('day').unix() * 1000);
		this.toDateFmt		= new Date(moment().utc().startOf('day').unix() * 1000);
	}

	onDeviceChanged(device) {
		this.device = device;
	}

	validateDates(fromDate, toDate) {

		var msg = '';

		if(toDate < fromDate)
			msg = "'End Date' should be later than 'Start Date'!";

		//Allow request to fetch 1 month at most
		if(!msg && (fromDate < this.lastMonthTime))
			msg = "You can request up to one month ago!";

		//Allow request to fetch 7 days at most
		if(!msg && ((toDate - fromDate) > (6*24*60*60)))
			msg = "You can request up to seven days!";

		var nowTime = new Date().getTime() / 1000;

		if(!msg && (fromDate > nowTime))
			msg = "'Start Date' should be earlier than today!";

		if(msg) {
			this.notificationMessage.setMessage(msg, 'danger');
			return true;
		}

		return false;
	};
	
	addFetchBattviewRTrecordsRequest() {

		let dates = this.getDates();
		var invalidDates = this.validateDates(dates[0], dates[1]);

		if(invalidDates)
			return;

		this.battviewsDashboardService.addFetchBattviewRTrecordsRequest(this.device.id, dates[0], dates[1]).subscribe((response) => {
			this.notificationMessage.setMessage(response, 'success');
		});
	};

	datesChanged() {
		let dates = this.getDates();
		this.validateDates(dates[0], dates[1]);
	}
	
	getDates() {
		var fromDate:any	= new Date(new Date(this.fromDateFmt).setHours(0, 0, 0, 0));
		fromDate			= Math.floor((-fromDate.getTimezoneOffset() * 60000 + fromDate.getTime()) / 1000);
		var toDate:any	    = new Date(new Date(this.toDateFmt).setHours(0, 0, 0, 0));
		toDate			    = Math.floor((-toDate.getTimezoneOffset() * 60000 + toDate.getTime()) / 1000);
		return [fromDate, toDate];
	}
}
