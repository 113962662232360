import { Component, OnInit, Input } from '@angular/core';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';

@Component({
	selector: 'app-site-trucks-report',
	templateUrl: './site-trucks-report.component.html',
	styleUrls: ['./site-trucks-report.component.css']
})
export class SiteTrucksReportComponent implements OnInit {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteTruckviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteTruckviewsTags: any = {};

	tableColumns: any[] = [];
	title: string = 'Site Trucks';
	elementId: string = 'site_trucks';
	widgetClass: string = 'auto-height-widget p-b-30';
	siteWidgetOptions: any = {
		hasOperation: false,
		hasDate: true,
		hideYesterdayDate: true,
		isReport: true,
		noprint: true,
		isTrucks: true,
	};

	constructor(private chartUtil: ChartUtil) { }

	ngOnInit() {
		this.tableColumns = [
			{header: "Truck ID", width:"150px", field: "truckid", colType: 'truckId', filterType: 'input', cellOpts: {customerID: this.customerId, siteID: this.siteId}},
			{header: "Truck SN", width:"150px", field: "truck_sn", filterType: 'input', cellOpts: {customerID: this.customerId, siteID: this.siteId}},
			{header: "Truck Type", width:"150px", field: "truck_type", filterType: 'input', cellOpts: {customerID: this.customerId, siteID: this.siteId}},
			{header: "Avg Daily EBU", width: "150px", field: "avg_ebu", colType:"number", filterType: 'greaterLessThan'},
			{header: "Max Daily EBU", field: "max_ebu", width: "120px", colType:"number", filterType: 'greaterLessThan'},
			{header: "Min daily idle time (min)", field: "min_idle", width: "160px", colType:"number", filterType: 'greaterLessThan'},
			{header: "Max daily idle time (min)", field: "max_idle", width: "160px", colType:"number", filterType: 'greaterLessThan'},
			{header: "Total In Use AHRs", field: "total_inuse_ahrs", width: "140px", colType:"number", filterType: 'greaterLessThan'},
		];
	}
}
