import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { HomeService } from '../home.service';

@Component({
	selector: 'app-group-admin',
	templateUrl: './group-admin.component.html',
	styleUrls: ['./group-admin.component.css']
})
export class GroupAdminComponent implements OnInit, OnDestroy {

	users: any = {'empty': true};

	constructor(
		private homeService: HomeService,
		private breadcrumb: BreadcrumbService,
		private chRef: ChangeDetectorRef,
	) { }

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([{label: 'Group Admin', url: ''}]);
		this.getGroupAdminUsers();
	}

	mapper = {
		"alerts": {'field': 'edit_alert_setting_disable', 'flag': 'alertsDisabled'},
		"login": {'field': 'actview_user_login_disable', 'flag': 'loginDisabled'},
		"reassignDevices": {'field': 'no_manage_devices', 'flag': 'reassignDevicesDisabled'},
		"remoteChargerControl": {'field': 'readonly_mcb_remote', 'flag': 'remoteChargerControlDisabled'},
		"settings": {'field': 'readonly_settings', 'flag': 'settingsDisabled'}
	};

	getGroupAdminUsers() {
		this.homeService.getGroupAdminUsers().subscribe(
			(response: any) => this.users = response
		);
	}

	sendGroupAdminRequest(mode, user, index) {

		var status = "";
		
		if(mode == 'delete') {

			var action = "delete " + user.firstname + " " + user.lastname;
		} else {
			
			status = "disable";
			if(!user[this.mapper[mode].flag])
				status = "enable";

			var type = "alerts settings";
			if(mode == 'login')
				type = "ACT-View.com login";
			
			var action = status + " " + type + " for " + user.firstname + " " + user.lastname;
		}
		
		var msg = "Are you sure you want to " + action + "?";

		if(confirm(msg)) {

			var tempUser = {'id': user.id};

			for(var func in this.mapper) {
				tempUser[this.mapper[func].flag] = user[this.mapper[func].flag];
			}

			this.homeService.sendGroupAdminRequest(tempUser, mode).subscribe(
				response => {}, 
				err => {
					if(mode != 'delete') {
						setTimeout(()=>{
							this.users[index][this.mapper[mode].flag] = !this.users[index][this.mapper[mode].flag];
						});
					}
				}
			);
		} else {
			if(mode != 'delete') {
				setTimeout(()=>{
					this.users[index][this.mapper[mode].flag] = !this.users[index][this.mapper[mode].flag];
				});
			}
		}
	};

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}