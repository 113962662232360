<ul class="dropdown-menu" role="menu">
	<li *ngIf="!userService.hasAccessFunction('no_manage_devices') && !currentUser.isTruckDealer">
		<a [routerLink]="['/devices', 'devices-management', 'truckview', truckview.id]">{{manageDeviceBtnText[0] + ' ' + manageDeviceBtnText[1]}}</a>
	</li>
	<li *ngIf="userService.hasAccessFunction('restart_devices')">
		<a (click)="restartTruckview(truckview.id)" class="has-action">Restart TRUCKView</a>
	</li>
	<li *ngIf="userService.hasAccessFunction('act_admin_device_management') && truckview.enableplc">
		<a (click)="addCommandToTruckviewQueue(truckview.id, 'loadPLCfirmware')" class="has-action">Load PLC Firmware</a>
	</li>
	<li *ngIf="!truckview.isCalibrated && truckview.actviewenabled && truckview.enableplc && truckview.firmwareversion >= 2.12 && userService.hasAccessFunction('remote_truckview_calibration')">
		<a (click)="addCommandToTruckviewQueue(truckview.id, 'remoteCalibration')" class="has-action">Calibrate TRUCKView with Quantum</a>
	</li>
	<li *ngIf="currentUser.isACTuser && truckview.time_gap > 0">
		<a (click)="addCommandToTruckviewQueue(truckview.id, 'setRealTimeClock')" class="has-action">Synch Time</a>
	</li>
	<li *ngIf="userService.hasAccessFunction('act_admin_device_management') && truckview.firmwareversion >= 2.2">
		<a (click)="refreshDeviceWiFiInfo(truckview.id)" class="has-action">Refresh WiFi Info</a>
	</li>
	<li *ngIf="false && truckview.actviewenabled && userService.hasAccessFunction('act_admin_device_management') && truckview.hwversion > 'AA'">
		<a (click)="addCommandToTruckviewQueue(truckview.id, 'loadDefaultCalibration')" class="has-action">Load Default Calibration Values</a>
	</li>
	<li *ngIf="pageName != 'listing'">
		<a>
			<notes
				[siteId]="siteID"
				modelType="truckview"
				[modelId]="truckview.id"
				[isDropDown]="true"
				[isAddNoteOnly]="true"
			></notes>
		</a>
	</li>
</ul>