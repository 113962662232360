import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../admin.service';
import { UserService } from '../../../auth/user.service';
import { NavigationService } from '../../../shared/services/navigation.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';

@Component({
	selector: 'app-manage-act-intelligent',
	templateUrl: './manage-act-intelligent.component.html',
	styleUrls: ['./manage-act-intelligent.component.css']
})
export class ManageActIntelligentComponent implements OnInit, OnDestroy, AfterViewInit {

	siteId: Number;
	invoices	= [];
	invoice: any = {};
	demoInvoice = null;
	engInvoice: any	= {};
	errMsgs		= [];
	modalTitle	= '';
	site		= {
		name: '',
		salesName: '',
		site_subscription_type: ''
	};
	originalType	= '';
	demoDefaults	= {};
	modalInvoice: any = {};
	
	isDefaultDemo: boolean		= false;
	demoDatesChanged: boolean	= false;

	haveDemoEditAccess		= false;
	haveInvoiceEditAccess	= false;

	@ViewChild('modal') modal;
	
	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute,
		public userService: UserService,
		private navigation: NavigationService,
		private notificationMessage: NotificationMessageService,
		private breadcrumb: BreadcrumbService,
	) {
	}

	ngAfterViewInit() {
		this.modal.onClose.subscribe(
			(agree) => {
				if(!agree)
					return;

				let validation = this.validateInvoice();
				if(validation.isValid) {
					this.modifySiteInvoices(this.modalInvoice.mode, this.modalInvoice);
					return this.modal.hide();
				}
				this.errMsgs = validation.data;
			}
		);
	}

	validateInvoice() {
		let invoice = this.modalInvoice;
		
		if(!invoice || typeof(invoice) != 'object' || Object.keys(invoice).length == 0)
			return {'isValid': false, 'data': ['Invalid invoice data']};

		let messages = [];
		
		if(typeof(invoice.invoice_id) != 'string' || invoice.invoice_id.length == 0)
			messages.push('"Invoice ID" is required');
		
		if(typeof(invoice.amount) != 'number' || invoice.amount < 0)
			messages.push('"Amount" should be a valid number');
		
		if(typeof(invoice.battview_count) != 'number' || invoice.battview_count < 0)
			messages.push('"Number of BATTViews" should be a valid number');

		if(typeof(invoice.chargers_count) != 'number' || invoice.chargers_count < 0)
			messages.push('"Number of Chargers" should be a valid number');

		if(!(invoice.start_date instanceof Date))
			messages.push('"Start Date" should be a valid date');
		
		if(!(invoice.end_date instanceof Date))
			messages.push('"End Date" should be a valid date');
		
		if(!(invoice.invoice_date instanceof Date))
			messages.push('"Invoice Date" should be a valid date');

		let fromDate	= Math.floor(new Date(invoice.start_date).getTime() / 1000);
		let toDate		= Math.floor(new Date(invoice.end_date).getTime() / 1000);

		if(toDate <= fromDate)
			messages.push('"End Date" should be later than "Start Date"');
		
		return {isValid: messages.length == 0, data: messages};
	}

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([
			{'label': 'Manage ACT Intelligent', 'url': ''},
		]);
		this.siteId		= this.route.snapshot.params.siteId;
		let todayBegin	= new Date(new Date().setHours(0, 0, 0, 0));
		todayBegin		= new Date(new Date(-todayBegin.getTimezoneOffset() * 60000 + todayBegin.getTime()));

		this.demoDefaults = {
			start_date:	todayBegin,
			end_date:	new Date(todayBegin.getTime() + 90*24*60*60*1000)
		};

		let currentUser	= this.userService.getCurrentUser();
		let access		= currentUser.accessFunctions;
		
		if(access.accounting == 'write' || access.act_intelligent_management == 'write' || access.admin_act_intelligent)
			this.haveDemoEditAccess = true;
		
		if(access.accounting == 'write' || access.admin_act_intelligent)
			this.haveInvoiceEditAccess = true;
		
		this.getSiteSubscriptionInvoices();
	}

	getSiteSubscriptionInvoices() {
		this.adminService.getSiteSubscriptionInvoices(this.siteId).subscribe(
			(response: any) => {
				this.site		= response.site;
				this.invoices	= [];
				this.invoice	= {};

				this.originalType = this.site.site_subscription_type;

				this.demoInvoice	= null;
				this.engInvoice		= {};

				for(let idx in response.invoices) {

					let invoice = response.invoices[idx];

					let todayIncluded = false;
					if(moment().utc().diff(moment(invoice.start_date)) > 0 && moment().utc().diff(moment(invoice.end_date)) < 0)
						todayIncluded = true;
					
					if(!invoice.is_deleted && !invoice.is_deactivated && todayIncluded) {
						invoice.is_active = true;

						if(invoice.type == 'Demo' && this.demoInvoice == null)
							this.demoInvoice = invoice;
						
						if(invoice.type == 'Engineering' && !this.engInvoice.sequence_id)
							this.engInvoice = invoice;
					}

					if(invoice.type == 'Paid')
						this.invoices.push(invoice);

					invoice.start_date		= this.useCorrectTimezone(invoice.start_date);
					invoice.end_date		= this.useCorrectTimezone(invoice.end_date);
					invoice.invoice_date	= this.useCorrectTimezone(invoice.invoice_date);
				}

				if(this.demoInvoice == null) {
					this.isDefaultDemo	= this.originalType == 'Demo';
					this.demoInvoice	= Object.assign({}, this.demoDefaults);
				}

				this.subscriptionChanged(this.originalType);
			}
		);
	}

	useCorrectTimezone(time) {
		let t = new Date(time);
		return new Date(new Date(t.getTimezoneOffset() * 60000 + t.getTime()));
	}

	changedDemoDate() {
		this.demoDatesChanged = true;
	}

	modifySiteInvoices(stage, invoice, invoiceIdx = -1) {
		let msg = 'Are you sure you want to ' + stage + ' invoice (' + invoice.invoice_id + ')?';

		if(!['delete', 'activate', 'deactivate'].includes(stage) || confirm(msg)) {

			let currInvoice = Object.assign({}, invoice);

			if(this.originalType == currInvoice.type && (currInvoice.type == 'Demo' || currInvoice.type == 'Engineering') && currInvoice.sequence_id > 0)
				stage = 'edit';

			currInvoice.site_id = this.siteId;

			if(currInvoice.type == 'Paid' && (stage == 'add' || stage == 'edit')) {
				
				let startDate	= new Date(new Date(currInvoice.start_date).setHours(0, 0, 0, 0));
				startDate		= new Date(new Date(-startDate.getTimezoneOffset() * 60000 + startDate.getTime()));

				let endDate	= new Date(new Date(currInvoice.end_date).setHours(0, 0, 0, 0));
				endDate		= new Date(new Date(-endDate.getTimezoneOffset() * 60000 + endDate.getTime()));
				
				let invoiceDate	= new Date(new Date(currInvoice.invoice_date).setHours(0, 0, 0, 0));
				invoiceDate		= new Date(new Date(-invoiceDate.getTimezoneOffset() * 60000 + invoiceDate.getTime()));

				currInvoice.start_date		= startDate;
				currInvoice.end_date		= endDate;
				currInvoice.invoice_date	= invoiceDate;
			}

			currInvoice.isDefaultDemo = this.isDefaultDemo;

			this.adminService.modifySiteInvoices(currInvoice, stage).subscribe(
				(data: any) => {
					if(data.httpStatus == 'error') {
						this.site.site_subscription_type = this.originalType;
						let msg = data.msg;
						return this.notificationMessage.setMessage(msg, 'danger', 60000);
					}
					this.notificationMessage.setMessage('globalSuccessMsg');
					
					this.demoDatesChanged	= false;
					this.isDefaultDemo		= false;
					
					if(stage == 'changeType') {

						this.originalType = invoice.type;
						if(invoice.type == 'Demo' || invoice.type == 'Engineering')
							this.invoice.sequence_id = data.sequence_id;

					} else if(invoice.type == 'Paid') {
					
						if(invoice.sequence_id) {

							if(stage == 'delete')
								this.invoices[invoiceIdx].is_deleted = true;
							else if(stage == 'deactivate' || stage == 'activate')
								this.invoices[invoiceIdx].is_deactivated = !this.invoices[invoiceIdx].is_deactivated;
							else
								this.invoices[invoice.idx] = invoice;
						} else {

							invoice.sequence_id		= data.sequence_id;
							invoice.is_deleted		= false;
							invoice.is_deactivated	= false;
							this.invoices.push(invoice);
						}
					}

					if(stage == 'edit' || stage == 'changeType') {

						if(invoice.type == 'Demo') {
							
							this.demoInvoice.invoice_note = invoice.demo_note || '';
							this.demoInvoice.sequence_id = data.sequence_id;
						} else if(invoice.type == 'Engineering') {

							this.engInvoice.invoice_note = invoice.eng_note || '';
							this.demoInvoice.sequence_id = data.sequence_id;
						}
					}
				}
			);
		}
	}

	subscriptionChanged(newVal) {
		this.invoice.type = newVal;

		if(newVal == 'Demo') {

			this.invoice.sequence_id	= this.demoInvoice.sequence_id || null;
			this.invoice.start_date		= new Date(this.demoInvoice.start_date);
			this.invoice.end_date		= new Date(this.demoInvoice.end_date);
			this.invoice.demo_note		= this.demoInvoice.invoice_note || '';
			this.changedDemoDate();
			
		} else if(newVal == 'Engineering') {

			this.invoice.sequence_id	= this.engInvoice.sequence_id || null;
			this.invoice.eng_note	= this.engInvoice.invoice_note || '';
		}
	}

	openInvoiceModal(passInvoice: any = {}, invoiceIdx = -1) {
		passInvoice.type = 'Paid';

		let mode = 'add';

		if(invoiceIdx >= 0) {
			mode = 'edit';
			passInvoice.idx = invoiceIdx;
		}
		
		this.modalInvoice = Object.assign({}, passInvoice);
		this.modalInvoice.mode = mode;

		if(mode == 'edit') {
			this.modalInvoice.start_date	= new Date(this.modalInvoice.start_date);
			this.modalInvoice.end_date		= new Date(this.modalInvoice.end_date);
			this.modalInvoice.invoice_date	= new Date(this.modalInvoice.invoice_date);
		}
		
		this.modalTitle = this.modalInvoice.sequence_id ? 'Edit Invoice' : 'Add Invoice';
		this.errMsgs = [];
		this.modal.show();
	}

	/**
	 * Use to set input "disabled" attribute
	 * @param enable 
	 * @returns {true|null}
	 */
	checkDisabledOption(enable: boolean) {
		return !enable || null;
	}

	goBack() {
		this.navigation.goBack();
	}

	ngOnDestroy() {
		this.modal.onClose.unsubscribe();
		this.breadcrumb.hide();
	}
}