<div *ngIf="sideMenu.visible"><a class="changebtn pointer" id="menu-toggle" (click)="toggleMenu()"><i class="fa fa-bars"></i> </a></div>
<nav *ngIf="header.visible" class="navbar navbar-actview navbar-fixed-top navbar-default navbar-fixed-top" role="navigation" #container>
	<div class="container-fluid">
		<div class="navbar-header">
			<button (click)="toggleHeader()" aria-controls="navbar" aria-expanded="false" data-target="#navbar" data-toggle="collapse" class="navbar-toggle collapsed" type="button">
				<span class="sr-only">{{'nav.toggle_navigation' | translate}}</span>
				<span class="icon-bar"></span>
				<span class="icon-bar"></span>
				<span class="icon-bar"></span>
			</button>
			<a
				routerLink="/sites-map"
				class="navbar-brand"
				ng-if="isAct">
					<div class="logo-actv"><span class="navbar-item"></span></div>
			</a>
		</div>
		<div class="navbar-collapse collapse" id="navbar" [ngClass]="{'in': showHeader}">
			<ul class="nav navbar-nav" (click)="closeMobileHeader()">
				<li [ngClass]="{ 'active': smp.isActive}">
					<a
						routerLink="/sites-map"
						routerLinkActive
						#smp="routerLinkActive"
						class="navbar-item"
					>{{'nav.main_menu' | translate}}</a>
				</li>
				<li [ngClass]="{ 'active': bvm.isActive}">
					<a
						routerLink="/battview-mobile"
						routerLinkActive
						#bvm="routerLinkActive"
						class="navbar-item"
					>{{'nav.battview_mobile_studies' | translate}}</a>
				</li>
				<li *ngIf="user.isOEM || user.isDealer" [ngClass]="{active: sdsn.isActive}">
					<a
						routerLink="/search-device-by-serial-number"
						routerLinkActive
						#sdsn="routerLinkActive"
						class="navbar-item"
					>{{'device.search_by_sn' | translate}}</a>
				</li>
				<li appDropdown *ngIf="user.isACTuser">
					<a class="navbar-item" dropdown-toggle>
						{{'device.device_management' | translate}}<i class="fa fa-caret-down margin-left-xs"></i>
					</a>
					<ul class="z-index-10k dropdown-menu" role="menu">
						<li class="dropdown-submenu batteryTopNav" appDropdown>
							<a tabindex="-1">
								<span>{{'device.unassigned_devices' | translate}}
									<i class="fa fa-caret-right margin-left-sm"></i>
								</span>
							</a>
							<ul class="z-index dropdown-menu" role="menu">
								<li><a routerLink="/unassigned-chargers">{{'g.unassigned_chargers' | translate}}</a></li>
								<li><a routerLink="/unassigned-battviews">{{'g.unassigned_battviews' | translate}}</a></li>
								<li><a routerLink="/unassigned-site-chargers">{{'g.unassigned_site_chargers' | translate}}</a></li>
								<li><a routerLink="/unassigned-site-battviews">{{'g.unassigned_site_battviews' | translate}}</a></li>
							</ul>
						</li>
						<li>
							<a routerLink="/battviews-mobile-listing" [queryParams]="{dealerId: 0}">
								{{'g.battviews_mobile_listing' | translate}}
							</a>
						</li>
						<li>
							<a routerLink="/replacement-devices-listing">
								{{'device.replacement_devices_listing' | translate}}
							</a>
						</li>
						<li class="dropdown-submenu batteryTopNav" appDropdown>
							<a tabindex="-1">
								<span>
									{{'g.connectivity' | translate}}<i class="fa fa-caret-right margin-left-sm"></i>
								</span>
							</a>
							<ul class="z-index dropdown-menu" role="menu">
								<li><a routerLink="/0/0/autoconnect-devices">{{'device.autoconnect_devices' | translate}}</a></li>
								<li><a routerLink="/sites-connectivity">{{'site.sites_connectivity_firmware_update' | translate}}</a></li>
								<li><a routerLink="/devices-connectivity">{{'site.devices_connectivity' | translate}}</a></li>
							</ul>
						</li>
						<li *ngIf="user.accessFunctions?.act_admin_device_management">
							<a routerLink="/pending-approval-requests/devices/charger">{{'site.pending_approval_requests' | translate}}</a>
						</li>
						<li class="dropdown-submenu batteryTopNav" appDropdown>
							<a tabindex="-1">
								<span>{{'g.search' | translate}} <i class="fa fa-caret-right margin-left-sm"></i>
								</span>
							</a>
							<ul class="z-index dropdown-menu" role="menu">
								<li><a routerLink="/search-device-by-sn">{{'device.search_by_sn' | translate}}</a></li>
								<li *ngIf="user.accessFunctions?.search_device_by_id">
									<a routerLink="/search-device-by-id">{{'device.search_device_by_id' | translate}}</a>
								</li>
								<li *ngIf="user.accessFunctions?.search_device_by_id">
									<a routerLink="/search-device-by-po">{{'device.search_device_by_po' | translate}}</a>
								</li>
								<li *ngIf="user.accessFunctions?.view_rejected_device_connections">
									<a routerLink="/search-devices-by-ip">{{'device.search_device_by_ip' | translate}}</a>
								</li>
								<li *ngIf="user.isACTuser">
									<a routerLink="/search-pms-by-serial-number">{{'device.search_pms_by_sn' | translate}}</a>
								</li>
								<li>
									<a routerLink="/battviews/daughter-cards-search">{{'device.battview_daughter_cards' | translate}}</a>
								</li>
							</ul>
						</li>
						<li class="dropdown-submenu batteryTopNav" appDropdown>
							<a tabindex="-1">
								<span>
									{{'site.sites_management' | translate}}<i class="fa fa-caret-right margin-left-sm"></i>
								</span>
							</a>
							<ul class="z-index dropdown-menu" role="menu">
								<li><a routerLink="/dealers-oems-hierarchy">{{'site.dealers_oems_hierarchy' | translate}}</a></li>
								<li><a routerLink="/sites-without-owners">{{'site.sites_without_owners' | translate}}</a></li>
							</ul>
						</li>
						<li *ngIf="user.accessFunctions?.service_page">
							<a routerLink="/service-page">{{'site.service_page' | translate}}</a>
						</li>
						<li *ngIf="user.accessFunctions?.service_page">
							<a routerLink="/battviews-functional-test">{{'site.battview_functional_test' | translate}}</a>
						</li>
						<li *ngIf="user.isACTuser">
							<a routerLink="/invoice-mismatch-for-site-battviews">
								{{'site.invoice_mismatch_for_site_battviews' | translate}}
							</a>
						</li>
						<li class="dropdown-submenu batteryTopNav" appDropdown *ngIf="user.accessFunctions?.reporting_module">
							<a tabindex="-1">
								<span>{{'site.reporting_module' | translate}}<i class="fa fa-caret-right margin-left-sm"></i>
								</span>
							</a>
							<ul class="z-index dropdown-menu" role="menu">
								<li><a routerLink="/reporting/user-search">{{'site.user_search' | translate}}</a></li>
								<li><a routerLink="/reporting/battview-search">{{'device.battview_search' | translate}}</a></li>
								<li><a routerLink="/reporting/charger-search">{{'device.charger_search' | translate}}</a></li>
							</ul>
						</li>
						<li class="dropdown-submenu batteryTopNav" appDropdown *ngIf="user.accessFunctions?.production_line_management">
							<a tabindex="-1">
								<span>{{'act.production_line' | translate}}<i class="fa fa-caret-right margin-left-sm"></i></span>
							</a>
							<ul class="z-index dropdown-menu" role="menu">
								<li><a routerLink="/allocate-production-line">{{'g.allocate' | translate}}</a></li>
								<li><a routerLink="/process-production-line">{{'g.process' | translate}}</a></li>
								<li><a routerLink="/production-line-ips">{{'act.act_production_ip_address' | translate}}</a></li>
								<li *ngIf="user.isACTuser"><a routerLink="/production-performance">{{'act.production_performance' | translate}}</a></li>
							</ul>
						</li>
						<li>
							<a routerLink="/support-logs/contact-us">{{'site.support_log' | translate}}</a>
						</li>
						<li *ngIf="user.accessFunctions?.accounting || user.accessFunctions?.admin_act_intelligent">
							<a routerLink="/sites/sites-invoices-reporting">{{'site.site_invoices_reporting' | translate}}</a>
						</li>
						<li *ngIf="user.accessFunctions?.accounting || user.accessFunctions?.admin_act_intelligent">
							<a routerLink="/sites/site-invoices-activation-reminder">{{'site.site_invoices_activation_reminder' | translate}}</a>
						</li>
						<li *ngIf="user.userAccess?.Batt_Commission_Daughter_Card == 'write'">
							<a routerLink="/battviews/daughter-cards/0">{{'device.commission_battview_daughter_card' | translate}}</a>
						</li>
						<li *ngIf="user.accessFunctions?.manage_equipment">
							<a routerLink="/equipments">{{'site.manage_equipments' | translate}}</a>
						</li>
						<li *ngIf="user.accessFunctions?.act_admin_device_management">
							<a routerLink="/blocked-fw-reports">{{'site.blocked_fw_reports' | translate}}</a>
						</li>
						<li *ngIf="user.accessFunctions?.voltus_dispatches_log">
							<a routerLink="/voltus-dispatches-log">{{'site.voltus_dispaches_log' | translate}}</a>
						</li>
						<li *ngIf="user.isACTuser">
							<a routerLink="/pm-report">{{'site.pm_report' | translate}}</a>
						</li>
					</ul>
				</li>
				<li appDropdown *ngIf="user.isACTuser && !user.accessFunctions?.act_user_management">
					<a class="navbar-item" dropdown-toggle>
						{{'site.customers_management' | translate}}<i class="fa fa-caret-down margin-left-xs"></i>
					</a>
					<ul class="z-index-10k dropdown-menu" role="menu">
						<li><a routerLink="/customers">{{'g.customers' | translate}}</a></li>
						<li><a routerLink="/users">{{'g.users' | translate}}</a></li>
					</ul>
				</li>
				<li appDropdown *ngIf="user.isACTuser && user.accessFunctions?.act_user_management">
					<a class="navbar-item" dropdown-toggle>
						{{'nav.admin_panel' | translate}}<i class="fa fa-caret-down margin-left-xs"></i>
					</a>
					<ul class="z-index-10k dropdown-menu" role="menu">
						<li><a routerLink="/oems">{{'device.battery_oem' | translate}}</a></li>
						<li><a routerLink="/users">{{'g.user' | translate}}</a></li>
						<li class="dropdown-submenu batteryTopNav" appDropdown>
							<a tabindex="-1">
								<span>
									{{'g.customers' | translate}} <i class="fa fa-caret-right margin-left-sm"></i>
								</span>
							</a>
							<ul class="z-index dropdown-menu" role="menu">
								<li><a routerLink="/customers">{{'g.customer' | translate}}</a></li>
								<li><a routerLink="/widget-management">{{'nav.widgets_management' | translate}}</a></li>
							</ul>
						</li>
						<li class="dropdown-submenu batteryTopNav" appDropdown>
							<a tabindex="-1">
								<span>
									{{'g.registration' | translate}} <i class="fa fa-caret-right margin-left-sm"></i>
								</span>
							</a>
							<ul class="z-index dropdown-menu" role="menu">
								<li><a routerLink="/dealers">{{'g.dealer' | translate}}</a></li>
								<li><a routerLink="/truck-dealers">{{'g.truck_dealer' | translate}}</a></li>
							</ul>
						</li>
						<li class="dropdown-submenu batteryTopNav" appDropdown>
							<a tabindex="-1">
							<span>
								{{'nav.logs' | translate}} <i class="fa fa-caret-right margin-left-sm"></i>
							</span>
							</a>
							<ul class="z-index dropdown-menu" role="menu">
								<li *ngIf="user.accessFunctions?.view_action_logs">
									<a routerLink="/action-logs">{{'nav.action_logs' | translate}}</a>
								</li>
								<li *ngIf="user.accessFunctions?.view_rejected_device_connections">
									<a routerLink="/rejected-device-connections">{{'nav.rejected_device_connections' | translate}}</a>
								</li>
								<li *ngIf="user.accessFunctions?.view_rejected_device_connections">
									<a routerLink="/rejected-quantum-uploads">{{'nav.rejected_quantum_uploads' | translate}}</a>
								</li>
								<li *ngIf="user.accessFunctions?.view_error_log">
									<a routerLink="/errors-log">{{'nav.errors_log' | translate}}</a>
								</li>
								<li *ngIf="user.accessFunctions?.view_rejected_device_connections">
									<a routerLink="/faulted-queue-log">{{'nav.faulted_queue_log' | translate}}</a>
								</li>
								<li *ngIf="user.accessFunctions?.actlink_failed_db">
									<a routerLink="/actlink-failed-db">{{'nav.actlink_failed_db' | translate}}</a>
								</li>
								<li *ngIf="user.accessFunctions?.act_admin_device_management">
									<a routerLink="/replacement-detection">{{'nav.replacement_detection' | translate}}</a>
								</li>
								<li *ngIf="user.accessFunctions?.apis_logs">
									<a routerLink="/apis-logs">{{'apis_logs.title' | translate}}</a>
								</li>
							</ul>
						</li>
						<li *ngIf="user.accessFunctions?.send_customized_emails">
							<a routerLink="/customized-emails">{{'nav.customized_emails' | translate}}</a>
						</li>
						<li *ngIf="user.accessFunctions?.sched_functions_control" class="dropdown-submenu batteryTopNav" appDropdown>
							<a tabindex="-1">
							<span>
								{{'nav.sched' | translate}} <i class="fa fa-caret-right margin-left-sm"></i>
							</span>
							</a>
							<ul class="z-index dropdown-menu" role="menu">
								<li><a routerLink="/sched-control">{{'nav.sched_control' | translate}}</a></li>
								<li><a routerLink="/sched-monitor">{{'nav.sched_monitor' | translate}}</a></li>
								<li><a routerLink="/sched-history">{{'nav.sched_history' | translate}}</a></li>
								<li><a routerLink="/sched-run">{{'nav.sched_run' | translate}}</a></li>
							</ul>
						</li>
						<li *ngIf="user.accessFunctions?.registrations_request=='write'">
							<a routerLink="/registrations-request">{{'nav.registrations_request' | translate}}</a>
						</li>
						<li *ngIf="user.accessFunctions?.registrations_reports=='write'">
							<a routerLink="/registrations-purposes">{{'nav.registrations_purposes' | translate}}</a>
						</li>
						<!--! we have to check the access function -->
						<li *ngIf="user.accessFunctions?.registrations_reports=='write'">
							<a routerLink="/smart-rebates-sites">{{'nav.smart_rebates_sites' | translate}}</a>
						</li>
						<li *ngIf="user.accessFunctions?.sched_functions_control">
							<a routerLink="/admin-actions">{{'nav.admin_actions' | translate}}</a>
						</li>
						<li *ngIf="!isProduction && !isStaging">
							<a routerLink="/data-generate">{{'Generate Fake Data' | translate}}</a>
						</li>
					</ul>
				</li>
			</ul>
			<div class="r">
				<div class="btn-group nms">
					<span class="padding-8 btn btn-default">
						<a
							href="/quantumSoftware/downloadManager?type=software"
							target="_self"
							*ngIf="!user.isACTuser && !user.accessFunctions?.access_apps_download_page"
						>
							{{'nav.download_actlink' | translate}}
						</a>
						<a routerLink="/app-download" *ngIf="user.isACTuser || user.accessFunctions?.access_apps_download_page">
							{{'nav.download_actlink' | translate}}
						</a>
					</span>
				</div>
				<span class="name-gear-actions margin-right-10" dropdown>
					<button
						type="button"
						id="basic-link"
						dropdownToggle
						(click)="false"
						aria-controls="basic-link-dropdown"
						class="name btn btn-link"
					>
						{{user.name}}<span class="glyphicon glyphicon-cog margin-left-sm"></span>
					</button>
					<ul
						id="basic-link-dropdown"
						*dropdownMenu
						class="dropdown-menu dropdown-menu-right"
						role="menu"
						aria-labelledby="basic-link"
						(click)="closeMobileHeader()"
					>
						<li (click)="onLogout()"><a>{{'user.logout' | translate}}</a></li>
						<li><a routerLink="/user-settings">{{'user.user_settings' | translate}}</a></li>
						<li *ngIf="userService.hasAccessFunction('group_admin')">
							<a routerLink="/group-admin">{{'nav.group_admin' | translate}}</a>
						</li>
						<li><a routerLink="/change-user-password">{{'g.change_password' | translate}}</a></li>
						<li *ngIf="!isProduction && userService.hasAccessFunction('enable_full_access_mode')">
							<a (click)="enableFullAccessMode()">{{'nav.enable_full_access_mode' | translate}}</a>
						</li>
					</ul>
				</span>
			</div>
		</div>
	</div>
</nav>
