<div class="panel panel-default margin-bottom-lg boxs3">
	<div class="panel-body" >
		<div class="form-group row margin-top-lg">
			<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">Date Range Filter</label>
			<div class="col-md-9">
				<div class="col-md-4">
					<div class="input-group">
						<input [disabled]="isReportGenerated" class="form-control" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="date.fromDate" id="bfdFromDate" bsDatepicker #fromDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
						<span class="input-group-btn">
							<button class="btn btn-default btn-date" (click)="fromDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
						</span>
					</div>
				</div>
				<div class="col-md-4">
					<div class="input-group" id="datetimepicker2">
						<input [disabled]="isReportGenerated" class="form-control" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="date.toDate" id="bfdtoDate" bsDatepicker #toDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }">
						<span class="input-group-btn">
							<button class="btn btn-default btn-date" (click)="toDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
						</span>
					</div>
				</div>
			</div>
		</div>
		<fieldset class="margin-top-xlg" [disabled]="isReportGenerated">
			<legend class="text-primary">Devices Filters</legend>
			<div class="margin-left">
				<div class="form-group row margin-top-lg">
					<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">Tags</label>
					<div class="col-md-8">
						<ng-select
							[multiple]="true"
							[addTag]="false"
							[items]="tags"
							[hideSelected]="true"
							placeholder="Filter By Tag"
							bindLabel="tagname"
							[clearable]="false"
							[(ngModel)]="tags"
						></ng-select>
					</div>
				</div>
				<div class="form-group row margin-top-lg">
					<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">Last Connection Time</label>
					<div class="col-md-9">
						<div class="col-md-11 col-sm-5 col-xs-10 no-gutter-left">
							<select class="form-control" [(ngModel)]="analyticsFilter.lastConnectionTime">
								<option value="">- Select -</option>
								<option value="1">Last Day</option>
								<option value="2">Last 2 Days</option>
								<option value="3">Last 3 Days</option>
								<option value="4">Last 4 Days</option>
								<option value="5">Last 5 Days</option>
								<option value="6">Last 6 Days</option>
								<option value="7">Last Week</option>
							</select>
						</div>
					</div>
				</div>
				<div class="form-group row margin-top-lg">
					<label class="col-md-3 col-form-label col-form-label-lg margin-top-xs">Battery Operation Type</label>
					<div>
						<span class="col-md-2"><input type="checkbox" name="conventional" [(ngModel)]="analyticsFilter.operation.conventional" value="1">Conventional</span>
						<span class="col-md-2"><input type="checkbox" name="opportunity" [(ngModel)]="analyticsFilter.operation.opportunity" value="2">Opportunity</span>
						<span class="col-md-2"><input type="checkbox" name="fast" [(ngModel)]="analyticsFilter.operation.fast" value="0">Fast</span>
					</div>
				</div>
				<div class="form-group row margin-top-lg">
					<label class="col-md-12 col-form-label col-form-label-lg margin-top-sm">
						<input type="checkbox" name="date-filter" [(ngModel)]="analyticsFilter.onlyDaysThatHaveEvents" class="sdax"> Only Days that have Events
					</label>
				</div>
			</div>
		</fieldset>
		<fieldset class="margin-top-lg" [disabled]="isReportGenerated">
			<legend class="text-primary">Data Cluster</legend>
			<div class="margin-left radio">
				<label class="col-md-2"><input type="radio" [(ngModel)]="analyticsFilter.dataCluster" name="data-cluster" value="date-cluster">Date</label>
				<label class="col-md-2"><input type="radio" [(ngModel)]="analyticsFilter.dataCluster" name="data-cluster" value="battview-cluster">Battview</label>
			</div>
		</fieldset>
		<fieldset class="margin-top-lg" [disabled]="isReportGenerated">
			<legend class="text-primary">Data Filters</legend>
			<div class="margin-left">
				<div class="form-group row">
					<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">
						<input type="checkbox" name="average-ebu" [(ngModel)]="analyticsFilter.data.averageEBU.checked"> Average EBU
					</label>
					<div class="col-md-4 col-sm-5 col-xs-10">
						<input type="number" name="greater-average-ebu" class="form-control" [(ngModel)]="analyticsFilter.data.averageEBU.greaterThan">
						<span class="x-small text-muted">Greater than</span>
					</div>
					<div class="col-md-4 col-sm-5 col-xs-10">
						<input type="number" name="less-average-ebu" class="form-control" [(ngModel)]="analyticsFilter.data.averageEBU.lessThan"><span class="x-small text-muted">Less than</span>
					</div>
					<div class="col-md-12 radio">
						<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.averageEBU.type" name="average-ebu-range-type" value="inrange">In Range</label>
						<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.averageEBU.type" name="average-ebu-range-type" value="reverse">Not In Range</label>
					</div>
				</div>

				<fieldset class="margin-top-lg">
					<legend><p>Charge AHRs</p></legend>
					<div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">
								<input type="checkbox" name="total-charge-ahrs" [(ngModel)]="analyticsFilter.data.totalChargeAHRs.checked"> Total Charge AHRs
							</label>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="greater-total-charge-ahrs" class="form-control" [(ngModel)]="analyticsFilter.data.totalChargeAHRs.greaterThan">
								<span class="x-small text-muted">Greater than</span>
							</div>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="less-total-charge-ahrs" class="form-control" [(ngModel)]="analyticsFilter.data.totalChargeAHRs.lessThan"><span class="x-small text-muted">Less than</span>
							</div>
							<div class="col-md-12 radio">
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.totalChargeAHRs.type" name="total-charge-ahrs-range-type" value="inrange">In Range</label>
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.totalChargeAHRs.type" name="total-charge-ahrs-range-type" value="reverse">Not In Range</label>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">
								<input type="checkbox" name="average-charge-ahrs" [(ngModel)]="analyticsFilter.data.averageChargeAHRs.checked"> Average Charge AHRs
							</label>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="greater-average-charge-ahrs" class="form-control" [(ngModel)]="analyticsFilter.data.averageChargeAHRs.greaterThan">
								<span class="x-small text-muted">Greater than</span>
							</div>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="less-average-charge-ahrs" class="form-control" [(ngModel)]="analyticsFilter.data.averageChargeAHRs.lessThan">
								<span class="x-small text-muted">Less than</span>
							</div>
							<div class="col-md-12 radio">
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.averageChargeAHRs.type" name="average-charge-ahrs-range-type" value="inrange">In Range</label>
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.averageChargeAHRs.type" name="average-charge-ahrs-range-type" value="reverse">Not In Range</label>
							</div>
						</div>
					</div>
				</fieldset>


				<fieldset class="margin-top-lg">
					<legend><p>Charge KWHR</p></legend>
					<div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">
								<input type="checkbox" name="total-charger-KWHR" [(ngModel)]="analyticsFilter.data.totalChargerKWHR.checked"> Total Charge KWHR
							</label>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="greater-total-charger-KWHR" class="form-control" [(ngModel)]="analyticsFilter.data.totalChargerKWHR.greaterThan">
								<span class="x-small text-muted">Greater than</span>
							</div>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="less-total-charger-KWHR" class="form-control" [(ngModel)]="analyticsFilter.data.totalChargerKWHR.lessThan"><span class="x-small text-muted">Less than</span>
							</div>
							<div class="col-md-12 radio">
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.totalChargerKWHR.type" name="total-charger-KWHR-range-type" value="inrange">In Range</label>
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.totalChargerKWHR.type" name="total-charger-KWHR-range-type" value="reverse">Not In Range</label>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">
								<input type="checkbox" name="average-charger-KWHR" [(ngModel)]="analyticsFilter.data.averageChargerKWHR.checked"> Average Charge KWHR
							</label>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="greater-average-charger-KWHR" class="form-control" [(ngModel)]="analyticsFilter.data.averageChargerKWHR.greaterThan">
								<span class="x-small text-muted">Greater than</span>
							</div>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="less-average-charger-KWHR" class="form-control" [(ngModel)]="analyticsFilter.data.averageChargerKWHR.lessThan">
								<span class="x-small text-muted">Less than</span>
							</div>
							<div class="col-md-12 radio">
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.averageChargerKWHR.type" name="average-charger-KWHR-range-type" value="inrange">In Range</label>
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.averageChargerKWHR.type" name="average-charger-KWHR-range-type" value="reverse">Not In Range</label>
							</div>
						</div>
					</div>
				</fieldset>



				<fieldset class="margin-top-lg">
					<legend><p>In Use AHR</p></legend>
					<div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">
								<input type="checkbox" name="total-inuse-ahrs" [(ngModel)]="analyticsFilter.data.totalInuseAHRs.checked"> Total In Use AHRs
							</label>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="greater-total-inuse-ahrs" class="form-control" [(ngModel)]="analyticsFilter.data.totalInuseAHRs.greaterThan">
								<span class="x-small text-muted">Greater than</span>
							</div>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="less-total-inuse-ahrs" class="form-control" [(ngModel)]="analyticsFilter.data.totalInuseAHRs.lessThan">
								<span class="x-small text-muted">Less than</span>
							</div>
							<div class="col-md-12 radio">
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.totalInuseAHRs.type" name="total-inuse-ahrs-range-type" value="inrange">In Range</label>
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.totalInuseAHRs.type" name="total-inuse-ahrs-range-type" value="reverse">Not In Range</label>
							</div>
						</div>

						<div class="form-group row">
							<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">
								<input type="checkbox" name="average-inuse-ahrs" [(ngModel)]="analyticsFilter.data.averageInuseAHRs.checked"> Average In Use AHRs
							</label>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="greater-average-inuse-ahrs" class="form-control" [(ngModel)]="analyticsFilter.data.averageInuseAHRs.greaterThan">
								<span class="x-small text-muted">Greater than</span>
							</div>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="less-average-inuse-ahrs" class="form-control" [(ngModel)]="analyticsFilter.data.averageInuseAHRs.lessThan">
								<span class="x-small text-muted">Less than</span>
							</div>
							<div class="col-md-12 radio">
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.averageInuseAHRs.type" name="average-inuse-ahrs-range-type" value="inrange">In Range</label>
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.averageInuseAHRs.type" name="average-inuse-ahrs-range-type" value="reverse">Not In Range</label>
							</div>
						</div>
					</div>
				</fieldset>
				

				<fieldset class="margin-top-lg">
					<legend><p>In Use KWHR</p></legend>
					<div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">
								<input type="checkbox" name="total-inuse-kwhr" [(ngModel)]="analyticsFilter.data.totalInuseKWHR.checked"> Total In Use KWHR
							</label>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="greater-total-inuse-kwhr" class="form-control" [(ngModel)]="analyticsFilter.data.totalInuseKWHR.greaterThan">
								<span class="x-small text-muted">Greater than</span>
							</div>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="less-total-inuse-kwhr" class="form-control" [(ngModel)]="analyticsFilter.data.totalInuseKWHR.lessThan">
								<span class="x-small text-muted">Less than</span>
							</div>
							<div class="col-md-12 radio">
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.totalInuseKWHR.type" name="total-inuse-kwhr-range-type" value="inrange">In Range</label>
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.totalInuseKWHR.type" name="total-inuse-kwhr-range-type" value="reverse">Not In Range</label>
							</div>
						</div>

						<div class="form-group row">
							<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">
								<input type="checkbox" name="average-inuse-kwhr" [(ngModel)]="analyticsFilter.data.averageInuseKWHR.checked"> Average In Use KWHR
							</label>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="greater-average-inuse-kwhr" class="form-control" [(ngModel)]="analyticsFilter.data.averageInuseKWHR.greaterThan">
								<span class="x-small text-muted">Greater than</span>
							</div>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="less-average-inuse-kwhr" class="form-control" [(ngModel)]="analyticsFilter.data.averageInuseKWHR.lessThan">
								<span class="x-small text-muted">Less than</span>
							</div>
							<div class="col-md-12 radio">
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.averageInuseKWHR.type" name="average-inuse-kwhr-range-type" value="inrange">In Range</label>
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.averageInuseKWHR.type" name="average-inuse-kwhr-range-type" value="reverse">Not In Range</label>
							</div>
						</div>
					</div>
				</fieldset>
				
				<fieldset class="margin-top-lg">
					<legend><p>In Use time</p></legend>
					<div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">
								<input type="checkbox" name="total-inuse-time" [(ngModel)]="analyticsFilter.data.totalInUseTime.checked"> Total In Use time
							</label>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="greater-total-inuse-time" class="form-control" [(ngModel)]="analyticsFilter.data.totalInUseTime.greaterThan">
								<span class="x-small text-muted">Greater than (hours)</span>
							</div>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="less-total-inuse-time" class="form-control" [(ngModel)]="analyticsFilter.data.totalInUseTime.lessThan">
								<span class="x-small text-muted">Less than (hours)</span>
							</div>
							<div class="col-md-12 radio">
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.totalInUseTime.type" name="total-inuse-time-range-type" value="inrange">In Range</label>
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.totalInUseTime.type" name="total-inuse-time-range-type" value="reverse">Not In Range</label>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">
								<input type="checkbox" name="average-inuse-time" class="form-control" [(ngModel)]="analyticsFilter.data.averageInUseTime.checked"> Average In Use time
							</label>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="greater-average-inuse-time" class="form-control" [(ngModel)]="analyticsFilter.data.averageInUseTime.greaterThan">
								<span class="x-small text-muted">Greater than (hours)</span>
							</div>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="less-average-inuse-time" class="form-control" [(ngModel)]="analyticsFilter.data.averageInUseTime.lessThan">
								<span class="x-small text-muted">Less than (hours)</span>
							</div>
							<div class="col-md-12 radio">
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.averageInUseTime.type" name="average-inuse-time-range-type" value="inrange">In Range</label>
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.averageInUseTime.type" name="average-inuse-time-range-type" value="reverse">Not In Range</label>
							</div>
						</div>
					</div>
				</fieldset>
				
				
				<fieldset class="margin-top-lg">
					<legend><p>Idle Time</p></legend>
					<div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">
								<input type="checkbox" class="form-control" name="total-idle-time" [(ngModel)]="analyticsFilter.data.totalIdleTime.checked"> Total Idle time
							</label>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="greater-total-idle-time" class="form-control" [(ngModel)]="analyticsFilter.data.totalIdleTime.greaterThan">
								<span class="x-small text-muted">Greater than (hours)</span>
							</div>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="less-total-idle-time" class="form-control" [(ngModel)]="analyticsFilter.data.totalIdleTime.lessThan">
								<span class="x-small text-muted">Less than (hours)</span>
							</div>
							<div class="col-md-12 radio">
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.totalIdleTime.type" name="total-idle-time-range-type" value="inrange">In Range</label>
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.totalIdleTime.type" name="total-idle-time-range-type" value="reverse">Not In Range</label>
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">
							<input type="checkbox" name="average-idle-time" [(ngModel)]="analyticsFilter.data.averageIdleTime.checked"> Average Idle time
						</label>
						<div class="col-md-4 col-sm-5 col-xs-10">
							<input type="number" name="greater-average-idle-time" class="form-control" [(ngModel)]="analyticsFilter.data.averageIdleTime.greaterThan">
							<span class="x-small text-muted">Greater than (hours)</span>
						</div>
						<div class="col-md-4 col-sm-5 col-xs-10">
							<input type="number" name="less-average-idle-time" class="form-control" [(ngModel)]="analyticsFilter.data.averageIdleTime.lessThan">
							<span class="x-small text-muted">Less than (hours)</span>
						</div>
						<div class="col-md-12 radio">
							<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.averageIdleTime.type" name="average-idle-time-range-type" value="inrange">In Range</label>
							<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.averageIdleTime.type" name="average-idle-time-range-type" value="reverse">Not In Range</label>
						</div>
					</div>
				</fieldset>
				
				<fieldset class="margin-top-lg">
					<legend><p>Charge Time</p></legend>
					<div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">
								<input type="checkbox" name="total-charge-time" [(ngModel)]="analyticsFilter.data.totalChargeTime.checked"> Total Charge time
							</label>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="greater-total-charge-time" class="form-control" [(ngModel)]="analyticsFilter.data.totalChargeTime.greaterThan">
								<span class="x-small text-muted">Greater than (hours)</span>
							</div>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="less-total-charge-time" class="form-control" [(ngModel)]="analyticsFilter.data.totalChargeTime.lessThan">
								<span class="x-small text-muted">Less than (hours)</span>
							</div>
							<div class="col-md-12 radio">
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.totalChargeTime.type" name="total-charge-time-range-type" value="inrange">In Range</label>
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.totalChargeTime.type" name="total-charge-time-range-type" value="reverse">Not In Range</label>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-md-3 col-form-label col-form-label-lg margin-top-sm">
								<input type="checkbox" name="average-charge-time" [(ngModel)]="analyticsFilter.data.averageChargeTime.checked"> Average Charge time
							</label>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="average-charge-time" class="form-control" [(ngModel)]="analyticsFilter.data.averageChargeTime.greaterThan">
								<span class="x-small text-muted">Greater than (hours)</span>
							</div>
							<div class="col-md-4 col-sm-5 col-xs-10">
								<input type="number" name="average-charge-time" class="form-control" [(ngModel)]="analyticsFilter.data.averageChargeTime.lessThan">
								<span class="x-small text-muted">Less than (hours)</span>
							</div>
							<div class="col-md-12 radio">
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.averageChargeTime.type" name="average-charge-time-type" value="inrange">In Range</label>
								<label class="col-md-3"><input type="radio" [(ngModel)]="analyticsFilter.data.averageChargeTime.type" name="average-charge-time-type" value="reverse">Not In Range</label>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</fieldset>
		

		
		<div *ngIf="!isReportGenerated" class="text-center margin-top-xxlg margin-bottom-xlg">
			<button class="btn btn-primary" (click)="generateData()" >GENERATE REPORT
			</button>
		</div>
		<div *ngIf="isReportGenerated" class="text-center margin-top-xxlg margin-bottom">
			<button class="btn btn-success" (click)="restartReport()">START AGAIN
				<i class="margin-left-sm fa fa-undo" aria-hidden="true"></i>
			</button>
		</div>

		<div *ngIf="isReportGenerated">
			<div *ngIf="analyticsFilter.data.averageEBU.checked">
				<label class="margin-left">Average EBU Report</label>
				<app-grid-v2
					[id]="gridIds.averageEBU"
					[columns]="gridColumns.averageEBU"
					[data]="gridData.averageEBU"
					class="margin-top margin-bottom margin-left margin-right"
				></app-grid-v2>
			</div>
			<div class="margin-bottom">
				<column-chart
					[data]="chartData.averageEBU"
					[config]="chartConfig.averageEBU"
					elementId="average-ebu-chart"
				></column-chart>
			</div>
			<hr *ngIf="analyticsFilter.data.averageEBU.checked" class="margin-top-xxlg">

			<div *ngIf="analyticsFilter.data.totalChargeAHRs.checked || analyticsFilter.data.averageChargeAHRs.checked">
				<label class="margin-left">Charge AHRs Report</label>
				<app-grid-v2
					[id]="gridIds.chargeAHRs"
					[columns]="gridColumns.chargeAHRs"
					[data]="gridData.chargeAHRs"
					class="margin-top margin-bottom margin-left margin-right"
				></app-grid-v2>
			</div>
			<div class="margin-bottom">
				<column-chart
					[data]="chartData.chargeAHRs"
					[config]="chartConfig.chargeAHRs"
					elementId="charge-ahrs-chart"
				></column-chart>
			</div>
			<hr *ngIf="analyticsFilter.data.totalChargeAHRs.checked || analyticsFilter.data.averageChargeAHRs.checked" class="margin-top-xxlg">

			<div *ngIf="analyticsFilter.data.totalChargerKWHR.checked || analyticsFilter.data.averageChargerKWHR.checked">
				<label class="margin-left">Charge KWHR Report</label>
				<app-grid-v2
					[id]="gridIds.chargerKWHR"
					[columns]="gridColumns.chargerKWHR"
					[data]="gridData.chargerKWHR"
					class="margin-top margin-bottom margin-left margin-right"
				></app-grid-v2>
			</div>
			<div class="margin-bottom">
				<column-chart
					[data]="chartData.chargerKWHR"
					[config]="chartConfig.chargerKWHR"
					elementId="charger-kwhr-chart"
				></column-chart>
			</div>
			<hr *ngIf="analyticsFilter.data.totalChargerKWHR.checked || analyticsFilter.data.averageChargerKWHR.checked" class="margin-top-xxlg">
	
			<div *ngIf="analyticsFilter.data.totalInuseAHRs.checked || analyticsFilter.data.averageInuseAHRs.checked">
				<label class="margin-left">In Use AHRs Report</label>
				<app-grid-v2
					[id]="gridIds.inuseAHRs"
					[columns]="gridColumns.inuseAHRs"
					[data]="gridData.inuseAHRs"
					class="margin-top margin-bottom margin-left margin-right"
				></app-grid-v2>
			</div>
			<div class="margin-bottom">
				<column-chart
					[data]="chartData.inuseAHRs"
					[config]="chartConfig.inuseAHRs"
					elementId="inuse-ahrs-chart"
				></column-chart>
			</div>
			<hr *ngIf="analyticsFilter.data.totalInuseAHRs.checked || analyticsFilter.data.averageInuseAHRs.checked" class="margin-top-xxlg">
	
			<div *ngIf="analyticsFilter.data.totalInuseKWHR.checked || analyticsFilter.data.averageInuseKWHR.checked">
				<label class="margin-left">In Use KWHR Report</label>
				<app-grid-v2
					[id]="gridIds.inuseKWHR"
					[columns]="gridColumns.inuseKWHR"
					[data]="gridData.inuseKWHR"
					class="margin-top margin-bottom margin-left margin-right"
				></app-grid-v2>
			</div>
			<div class="margin-bottom">
				<column-chart
					[data]="chartData.inuseKWHR"
					[config]="chartConfig.inuseKWHR"
					elementId="inuse-kwhr-chart"
				></column-chart>
			</div>
			<hr *ngIf="analyticsFilter.data.totalInuseKWHR.checked || analyticsFilter.data.averageInuseKWHR.checked" class="margin-top-xxlg">
			
			<div *ngIf="analyticsFilter.data.totalInUseTime.checked || analyticsFilter.data.averageInUseTime.checked">
				<label class="margin-left">In Use Time Report</label>
				<app-grid-v2
					[id]="gridIds.inuseTime"
					[columns]="gridColumns.inuseTime"
					[data]="gridData.inuseTime"
					class="margin-top margin-bottom margin-left margin-right"
				></app-grid-v2>
			</div>
			<div class="margin-bottom">
				<column-chart
					[data]="chartData.inuseTime"
					[config]="chartConfig.inuseTime"
					elementId="inuse-time-chart"
				></column-chart>
			</div>
			<hr *ngIf="analyticsFilter.data.totalInUseTime.checked || analyticsFilter.data.averageInUseTime.checked" class="margin-top-xxlg">
	
			<div *ngIf="analyticsFilter.data.totalIdleTime.checked || analyticsFilter.data.averageIdleTime.checked">
				<label class="margin-left">Idle Time Report</label>
				<app-grid-v2
					[id]="gridIds.idleTime"
					[columns]="gridColumns.idleTime"
					[data]="gridData.idleTime"
					class="margin-top margin-bottom margin-left margin-right"
				></app-grid-v2>
			</div>
			<div class="margin-bottom">
				<column-chart
					[data]="chartData.idleTime"
					[config]="chartConfig.idleTime"
					elementId="idle-time-chart"
				></column-chart>
			</div>
			<hr class="margin-top-xxlg">
			
			<div *ngIf="analyticsFilter.data.totalChargeTime.checked || analyticsFilter.data.averageChargeTime.checked">
				<label class="margin-left">Charge Time Report</label>
				<app-grid-v2
					[id]="gridIds.chargeTime"
					[columns]="gridColumns.chargeTime"
					[data]="gridData.chargeTime"
					class="margin-top margin-bottom margin-left margin-right"
				></app-grid-v2>
			</div>
			<div class="margin-bottom">
				<column-chart
					[data]="chartData.chargeTime"
					[config]="chartConfig.chargeTime"
					elementId="charge-time-chart"
				></column-chart>
			</div>
		</div>
	</div>
</div>