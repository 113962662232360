<div class="row">
	<div class="panel panel-default">
		<div class="panel-body">
			<div class="col-md-12">
				<simple-widget title="Site Invoices">
					<app-grid-v2 id="sitesInvoicesGrid" [columns]="sitesInvoicesGridColumns" [data]="sitesInvoicesGridData"></app-grid-v2>
				</simple-widget>
			</div>
		</div>
	</div>
</div>