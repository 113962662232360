<div class="boxs3">
	<div>
		<div class="row margin-top-lg margin-bottom-lg">
			<div class="row margin-bottom-lg">
				<div class="margin-left-sm col-md-3">
					<label>{{'time.from_date' | translate}}:</label>
					<div class="input-group">
						<input class="form-control" placeholder="mm/dd/yyyy" name="startDB" [(ngModel)]="fromDate" bsDatepicker #startDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
						<span class="input-group-btn">
							<button class="btn btn-default btn-date" (click)="startDB.toggle()" type="button"><i class="fa fa-calendar"></i></button>
						</span>
					</div>
				</div>
				<div class="col-md-3">
					<label>{{'time.to_date' | translate}}:</label>
					<div class="input-group">
						<input class="form-control" placeholder="mm/dd/yyyy" name="endDB" [(ngModel)]="toDate" bsDatepicker #endDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
						<span class="input-group-btn">
							<button class="btn btn-default btn-date" (click)="endDB.toggle()" type="button"><i class="fa fa-calendar"></i></button>
						</span>
					</div>
				</div>
				<div class="col-md-4 text-center margin-top-lg"><button class="btn btn-md btn-default" (click)="fetchData()">{{'g.fetch_data' | translate}}</button></div>
			</div>
		</div>

		<simple-widget
			[collapsedByDefault]="true"
			*ngFor="let profile of profiles"
			[title]="profile.type+(profile.name ? ': '+profile.name : '')">
			<div class="margin-bottom-lg">
				<ng-container *ngIf="profile.expanded">
					<table class="table table-striped">
						<thead>
							<tr>
								<th>ID</th>
								<th>{{'act.create_time' | translate}}</th>
								<th>{{'act.scheduled_start_time' | translate}}</th>
								<th>{{'act.scheduled_end_time' | translate}}</th>
								<th>{{'act.is_canceled' | translate}}</th>
								<th>{{'act.event_generated_by' | translate}}</th>
								<th>{{'act.exit_notes' | translate}}</th>
								<th>{{'act.exit_status' | translate}}</th>
								<th>{{'act.is_running' | translate}}</th>
								<th>{{'act.start_time' | translate}}</th>
								<th>{{'act.event_end_time' | translate}}</th>
								<th>{{'g.action' | translate}}</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let event of profile['events']">
								<td>
									<a
										*ngIf="userService.hasAccessFunction('act_energy_event_log')"
										class="pointer"
										[routerLink]="['/'+this.customerId, this.siteId, 'dynamic-response', 'event-logs']"
										[queryParams]="{'eventId': event.id, 'startDate': event.start_execution_time | date:'MM/dd/yyyy', 'endDate': event.end_execution_time | date:'MM/dd/yyyy'}"
									>
										{{event.id}}
									</a>
									<span *ngIf="!userService.hasAccessFunction('act_energy_event_log')">{{event.id}}</span>
								</td>
								<td>{{event.create_time | date:'MM/dd/yyyy hh:mm:ss aa'}}</td>
								<td>{{event.start_execution_time | date:'MM/dd/yyyy hh:mm:ss aa'}}</td>
								<td>{{event.end_execution_time | date:'MM/dd/yyyy hh:mm:ss aa'}}</td>
								<td>{{event.is_cancel}}</td>
								<td>{{event.source_of_event}}</td>
								<td>{{event.end_notes}}</td>
								<td>{{event.source_of_end}}</td>
								<td>{{event.is_running}}</td>
								<td>{{event.sched_start_time | date:'MM/dd/yyyy hh:mm:ss aa'}}</td>
								<td>{{event.sched_end_time | date:'MM/dd/yyyy hh:mm:ss aa'}}</td>
								<td>
									<a class="btn btn-warning pointer" *ngIf="event.source_of_end == null && !event.is_cancel && event.is_running" (click)="DeleteOrCancel(event.id)">{{'g.cancel' | translate}}</a>
									<a class="btn btn-danger pointer" *ngIf="event.source_of_end == null && !event.is_cancel && !event.is_running" (click)="DeleteOrCancel(event.id)">{{'g.delete' | translate}}</a>
								</td>
							</tr>
						</tbody>
					</table>
					<div *ngFor="let event of profile['events']" class="margin-top-lg margin-left">
						<div class="row margin-left">
							
						</div>
					</div>
				</ng-container>
			</div>
		</simple-widget>
	</div>
</div>
<div class="col-md-12 margin-top-lg" *ngIf="noData">
	<div class="alert alert-warning" role="alert">
		{{'act.no_events_available_in_this_time_period' | translate}}
	</div>
</div>