export class User {
	name: string;
	lang: string;
	roleId: number;
	id: number;
	dealerid: number;
	customerid: number;
	allowToChangeDeviceAttribute: boolean;
	accessFunctions: any;
	canGrantAccessFunctions: boolean;
	isACTuser : boolean;
	isDealer : boolean;
	isOEM : boolean ;
	isEndClient : boolean;
	isTruckDealer: boolean;
	userAccess: Object;
	firstname?: string;
	lastname?: string;
	// code: number;
	// confirm_email_tries: number;
	// email_confirmed: boolean;
	// isACTuser: boolean;
	// oemid: number;
	// shouldresetpass: boolean;
	// user_agreement_confirmed: boolean;
}