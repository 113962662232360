<app-site-widget
	[title]="title"
	[elementId]="elementId"
	chartType="combo"
	[widgetClass]="widgetClass"
	[siteWidgetOptions]="siteWidgetOptions"
	[widgetOptions]="{}"
	[data]="data"
	[tableColumns]="tableColumns"
	[config]="config"
	[siteId]="siteId"
	[customerId]="customerId"
	[customerName]="customerName"
	[siteName]="siteName"
></app-site-widget>