<div class="row">
	<div class="panel panel-default">
		<div class="panel-body">
			<input [(ngModel)]="customerNameFilter" placeholder="{{'site.search_filter_customers' | translate}}" class="form-control"/>
			<br/>
			<ul class="insm sidebar-nav">
				<li *ngFor="let customer of connectivity | filter: customerNameFilter: 'name'">
					<a href="javascript:" (click)="toggleExpanded(customer)">
						<span class="glyphicon" [ngClass]="{'glyphicon-plus-sign': !customer.expanded, 'glyphicon-minus-sign': customer.expanded}"></span>
						{{customer.name}}
					</a>
					<ul *ngIf="customer.expanded" class="sidebar-nav">
						<li *ngFor="let site of customer.sites | values">
							<a href="javascript:" (click)="toggleExpanded(site)">
								<span class="glyphicon" [ngClass]="{'glyphicon-plus-sign': !site.expanded, 'glyphicon-minus-sign': site.expanded}"></span>
								{{site.name}}
							</a>
							<ul class="col-md-12 sidebar-nav" *ngIf="site.expanded">
								<div class="col-md-5">
									<h4>{{'g.chargers' | translate}}</h4>
									<input [(ngModel)]="site.serialnumberFilterCH" placeholder="{{'device.search_filter_by_serial_number' | translate}}" class="form-control"/>
									<table class="table">
										<thead>
											<tr>
												<th>{{'device.serial_number' | translate}} ({{'device.charger_id' | translate}})</th>
												<th>{{'site.last_connect_time' | translate}}</th>
												<th>{{'device.firmware_version' | translate}}</th>
												<th>{{'device.wifi_firmware_version' | translate}}</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let charger of site.chargers | filter: site.serialnumberFilterCH: 'serialnumber'" [ngClass]="charger.bgClass">
												<td>{{charger.serialnumber}} ({{charger.chargerusername}})</td>
												<td>
													{{charger.lastconnecttime | date:'MM/dd/yyyy hh:mm:ss a'}} - <b>{{charger.timeDiff.diff}}</b> {{charger.timeDiff.unit}}s ago
												</td>
												<td>{{charger.firmwareversion}}</td>
												<td>{{charger.esp32_wifi_version}}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="col-md-5 col-md-offset-1">
									<h4>{{'g.battviews' | translate}}</h4>
									<input [(ngModel)]="site.serialnumberFilterBV" placeholder="{{'device.search_filter_by_serial_number' | translate}}" class="form-control"/>
									<table class="table">
										<thead>
											<tr>
												<th>{{'device.serial_number' | translate}} ({{'device.battery_id' | translate}})</th>
												<th>{{'site.last_connect_time' | translate}}</th>
												<th>{{'device.firmware_version' | translate}}</th>
												<th>{{'device.wifi_firmware_version' | translate}}</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let battview of site.battviews | filter: site.serialnumberFilterBV: 'serialnumber'" [ngClass]="battview.bgClass">
												<td>{{battview.serialnumber}} ({{battview.batteryid}})</td>
												<td>
													{{battview.lastconnecttime | date:'MM/dd/yyyy hh:mm:ss a'}} - <b>{{battview.timeDiff.diff}}</b> {{battview.timeDiff.unit}}s ago
												</td>
												<td>{{battview.firmwareversion}}</td>
												<td>{{battview.esp32_wifi_version}}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</ul>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</div>