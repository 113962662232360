<div class="panel panel-default margin-bottom-xs label-text" *ngIf="siteChargers.length > 0">
	<div class="panel-body">
		<div>
			<div class="row padding-right-10 margin-bottom-sm">
				<div class="col-md-2 text-right-md margin-top"><strong>{{'act.select_charger'|translate}}:</strong></div>
				<div class="col-md-3 margin-top">
					<div class="form-field">
						<ng-select [items]="siteChargers" bindLabel="prettyName" bindValue="id" placeholder="{{'act.select_charger'|translate}}" name="chargerID" [(ngModel)]="chargerID" [clearable]="false" (ngModelChange)="chargerChanged()">
						</ng-select>
					</div>
				</div>
				<div class="col-md-2 col-xs-6 text-right-md margin-top">
					<strong *ngIf="charger.actviewenable">{{'site.last_connect_time'|translate}}:<br/></strong>
					<strong *ngIf="userService.hasAccessFunction('view_rejected_device_connections') && charger.actviewenable">{{'g.ip_address'|translate}}:<br/></strong>
					<strong *ngIf="!charger.actviewenable && charger.lastcycleuploadtime">{{'charger.last_upload_time'|translate}}:<br/></strong>
					<strong *ngIf="!charger.actviewenable && charger.lastcycletime">{{'charger.last_record_date'|translate}}:<br/></strong>
					<strong *ngIf="currentUser.isACTuser && charger.firmwareversion >= 2.14">{{'charger.restarted_since'|translate}}:</strong>
				</div>
				<div class="col-md-3 col-xs-6 margin-top">
					<span *ngIf="charger.actviewenable">{{charger.lastconnecttime | date:'MM/dd/yyyy hh:mm:ss a'}}<br/></span>
					<span *ngIf="userService.hasAccessFunction('view_rejected_device_connections') && charger.actviewenable">{{charger.ip_address ? charger.ip_address : 'No IP'}}<br/></span>
					<span *ngIf="!charger.actviewenable && charger.lastcycleuploadtime">{{charger.lastcycleuploadtime | date:'MM/dd/yyyy hh:mm:ss a'}}<br /></span>
					<span *ngIf="!charger.actviewenable && charger.lastcycletime">{{charger.lastcycletime | date:'MM/dd/yyyy hh:mm:ss a'}}<br/></span>
					<span *ngIf="currentUser.isACTuser && charger.firmwareversion >= 2.14">{{charger.restart_time_fmt}}</span>
				</div>
				<div class="col-md-2" *ngIf="activePage == 'analytics' || (!multipleOptions && chargerID != 0)">
					<app-manage-device-btn [selectedDevices]="chargerID ? [chargerID] : []" *ngIf="!userService.hasAccessFunction('no_manage_devices')"></app-manage-device-btn>
				</div>
				<div class="col-md-2" *ngIf="activePage == 'settings' && multipleOptions && chargerID != 0">
					<div class="btn-group pointer" appDropdown [indirectOptionsList]="true">
						<i type="button" class="btn btn-default glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
						<app-charger-actions [charger]="charger" [siteID]="siteID"></app-charger-actions>
					</div>
				</div>
			</div>
			<div *ngIf="showDateRange" class="row margin-bottom-sm">
				<div class="col-md-2 text-right-md "><strong>{{'time.from_date'|translate}}:</strong></div>
				<div class="col-md-3">
					<div class="input-group">
						<input class="form-control" placeholder="mm/dd/yyyy" name="startDB" [(ngModel)]="fromDateFmt" (ngModelChange)="datesChanged()" bsDatepicker #startDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
						<span class="input-group-btn">
							<button class="btn btn-default btn-date" (click)="startDB.toggle()" type="button"><i class="fa fa-calendar"></i></button>
						</span>
					</div>
				</div>
				<div class="col-md-2 text-right-md "><strong>{{'time.to_date'|translate}}:</strong></div>
				<div class="col-md-3 ">
					<div class="input-group">
						<input class="form-control" placeholder="mm/dd/yyyy" name="endDB" [(ngModel)]="toDateFmt" (ngModelChange)="datesChanged()" bsDatepicker #endDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
						<span class="input-group-btn">
							<button class="btn btn-default btn-date" (click)="endDB.toggle()" type="button"><i class="fa fa-calendar"></i></button>
						</span>
					</div>
				</div>
				<div class="col-md-2">
					<button class="btn btn-default" *ngIf="chargerID && hasPLCFaults" (click)="resetPLC()">
						{{'charger.reset_plc_fault_for_month'|translate}}
					</button>
				</div>
			</div>
			<div *ngIf="chargerID > 0" class="row margin-bottom-sm">
				<div class="col-md-2 text-right-md ">
					<strong>{{'charger.charger_tags'|translate}}</strong>
				</div>
				<div class="col-md-3 ">
					<tags
						[items]="siteTags | values"
						[deviceType]="'charger'"
						[deviceId]="chargerID"
						[tag]="deviceTags[chargerID]"
						[customerId]="customerID"
						[siteId]="siteID"
						[isACTuser]="currentUser.isACTuser"
						(onUpdateTags)="updateTags($event)"
						>
					</tags>
				</div>
				<div class="col-md-2 text-right-md ">
					<strong *ngIf="currentSite.act_first_enabled">{{'charger.charger_truck_types'|translate}}</strong>
				</div>
				<div class="col-md-3 ">
					<ng-select
						[multiple]="false"
						[addTag]="false"
						[items]="siteGroups"
						(change)="addGroup($event)"
						[hideSelected]="false"
						placeholder="{{'charger.attach_to_truck_type'|translate}}"
						bindLabel="text"
						bindValue="id"
						[clearable]="false"
						[(ngModel)]="charger.actfirstgroupid"
						*ngIf="currentSite.act_first_enabled"
					></ng-select>
				</div>
				<div class="col-md-2"></div>
			</div>
			<div *ngIf="!multipleOptions && activePage != 'notes'" class="row">
				<div class="text-right-md col-md-2">
					<a class="btn btn-xs btn-primary margin-left-xs" *ngIf="chargerID">
						<notes [siteId]="siteID" [modelType]="'charger'" [modelId]="chargerID" [isAddNoteOnly]="true"></notes>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>