import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import *  as moment from 'moment';
import * as _ from 'lodash';

import { SiteDashboardService } from '../../site-dashboard.service';
import { DynamicResponseService } from '../dynamic-response.service';
import { ChartUtil } from '../../../../shared/services/utility/chart.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';
import { ComboChartConfig } from '../../../../shared/google-chart/Models/ComboChartConfig';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-events-chart',
	templateUrl: './events-chart.component.html',
	styleUrls: ['./events-chart.component.css']
})
export class EventsChartComponent implements OnInit {

	siteId: number = 0;
	customerId: number = 0;
	fromDateFmt: Date = new Date();
	fromDate = new Date(moment().subtract(1, 'days').startOf('day').unix() * 1000);
	toDate = new Date(moment().startOf('day').unix() * 1000);
	profiles: any = {};
	noChart: boolean = true;
	dataIsFetched: boolean = false;
	images: any[] = [];
	chargerEnergyData: any[] = [];
	currentSite: any = {};
	snapshotsData: any[] = [];
	snapshotsConfig: any = {};
	showNumberOfChargers: boolean = false;
	maxKWValue: number = 0;
	eventsByProfiles: any = {};
	shaved_energy: any = 0;

	constructor(
		private siteDashboardService: SiteDashboardService,
		private route: ActivatedRoute,
		private dynamicResponseService: DynamicResponseService,
		private chartUtil: ChartUtil,
		private commonUtil: CommonUtil,
		private sideMenuService: SideMenuService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.siteDashboardService.setActiveSubTab('events-chart');
		this.siteId = +this.route.parent.parent.snapshot.params.siteid || 0;
		this.customerId = +this.route.parent.parent.snapshot.params.customerid || 0;

		this.sideMenuService.currentSite.subscribe(data => {
			if(data && data.id) {
				this.currentSite = data;
			}
		});
	}

	showNumberOfChargersChanged() {
		this.drawChart();
	}

	fetchData() {
		this.dataIsFetched	= false;
		var fromDate:any	= new Date(new Date(this.fromDateFmt).setHours(0, 0, 0, 0));
		fromDate			= Math.floor((-fromDate.getTimezoneOffset() * 60000 + fromDate.getTime()) / 1000);
		fromDate			= this.commonUtil.getUTCTimestampFromZone(this.currentSite.zoneid, fromDate, true);

		var toDate	= fromDate + (24*60*60);
		if(new Date().setHours(0, 0, 0, 0) / 1000 == new Date(this.fromDateFmt).setHours(0, 0, 0, 0) / 1000) {
			// if the selected day is today, show up to now() - 5 minutes
			toDate	= Math.floor(new Date().getTime() / 1000) - (5 * 60);
		}

		this.chargerEnergyData = [];
		this.shaved_energy = 0;
		this.showNumberOfChargers = false;
		this.dynamicResponseService.getEventsChart(this.siteId, fromDate, toDate).subscribe((response: any) => {
			this.profiles = this.commonUtil.arrayToAssociativeArray(response.profiles);
			let snapshots = response.snapshots;
			this.noChart = (snapshots.length < 2);

			let eventsByProfiles = {};

			response.events.forEach((event) => {
				// hide any event with end sched is null, except when isRunning = true Or have source of end as timeissue/old
				if((event.sched_end_time != null || event.is_running) && ['timeissue', 'old'].indexOf(event.source_of_end) == -1) {
					var sched_start_time	= this.commonUtil.getZoneTimestampFromUTC(this.currentSite.zoneid, moment(event.sched_start_time).utc().unix(), true);
					event.startRunTime		= this.commonUtil.getDateFormattedFromUnixTimeStamp(sched_start_time, '12h');
					event.starttime			= new Date(event.startRunTime);

					var sched_end_time;
					if(event.sched_end_time == null) {
						sched_end_time = toDate;
						event.hideEndExecTime = true;
					} else {
						sched_end_time = moment(event.sched_end_time).utc().unix();
						event.hideEndExecTime = false;
					}
					sched_end_time		= this.commonUtil.getZoneTimestampFromUTC(this.currentSite.zoneid, sched_end_time, true);
					event.schedEndTime	= this.commonUtil.getDateFormattedFromUnixTimeStamp(sched_end_time, '12h');
					event.endtime		= new Date(event.schedEndTime);

					this.shaved_energy += (event.shaved_power * (sched_end_time - sched_start_time) / 3600);
	
					if(!eventsByProfiles[event.profile_id]) {
						eventsByProfiles[event.profile_id] = [];
					}
					eventsByProfiles[event.profile_id].push(event);
				}
			});
			
			var maxKWValue = 0;
			snapshots.forEach((record, idx) => {
				record.kw	= parseFloat(((record.output_power * 1.07) / 1000).toFixed(1));
				if(record.kw > maxKWValue) maxKWValue = record.kw; 
				
				var snapshotTime		= this.commonUtil.getZoneTimestampFromUTC(this.currentSite.zoneid, moment(record.snapshottime).utc().unix(), true);
				record.snapshotsitetime	= this.commonUtil.getDateFormattedFromUnixTimeStamp(snapshotTime);
				record.time				= new Date(record.snapshotsitetime);
			});
			
			this.chargerEnergyData	= snapshots;
			
			this.maxKWValue = maxKWValue * 1.2;
			this.eventsByProfiles = eventsByProfiles;
			this.dataIsFetched = true;

			this.drawChart();
		});
	}

	generateImageURI(event) {
		let chart = event['chart'];
		let elementId = event['elementId'];
		let image = this.chartUtil.getChartImageUri(chart);
		this.images[elementId] = image;
	}

	drawChart() {
		let events = this.eventsByProfiles;
		let maxKWValue = Math.round(this.maxKWValue/10) * 10;
		if(maxKWValue == 0) {
			maxKWValue = 1;
		}
		let snapshotsData: any = [
			[
				{label: this.translateService.instant('g.date'), type: "datetime"},
				{label: this.translateService.instant('g.KW'), type: "number"},
			]
		];

		if(this.showNumberOfChargers) {
			snapshotsData[0].push({label: this.translateService.instant('device.number_of_chargers'), type: "number"});
		}

		var i = 0;
		let profiles = Object.keys(events);
		profiles.forEach((profile) => {
			let eventName = "";
			eventName += this.profiles[events[profile][0].profile_id].name;
			snapshotsData[0].push({label: eventName, type: "number"});
			snapshotsData[0].push({role:'tooltip'});
		});

		var snapshotsArr = this.chargerEnergyData;
		var hAxisTitle = this.translateService.instant('time.day_n_time');
		
		var data = [];
		var maxChargersCount = 0;
		var maxEnergy = 0;

		for (var i = 0; i < snapshotsArr.length; i++) {
			data[i] = [
				snapshotsArr[i].time,
				snapshotsArr[i].kw,
			];
			if(this.showNumberOfChargers) {
				data[i].push(snapshotsArr[i].chargers_count);
			}
			for(let profile in events) {
				let hasValue = false;
				for (let eventIdx in events[profile]) {
					let event: any = events[profile][eventIdx];
					if(snapshotsArr[i].time >= event.starttime && snapshotsArr[i].time <= event.endtime) {
						data[i].push(maxKWValue);
						data[i].push(this.profiles[event.profile_id].name+"\n\r"+ this.translateService.instant('act.scheduled_end_time') +": "+event.startRunTime+(event.hideEndExecTime ? "" : "\n\r"+ this.translateService.instant('act.end_execution_time') +": "+event.schedEndTime));
						hasValue = true;
						break;
					}
				}
				if(snapshotsArr[i].kw > maxEnergy) {
					maxEnergy = snapshotsArr[i].kw;
				}
				if(!hasValue) {
					data[i].push(0);
					data[i].push("");
				}
			}

			if(snapshotsArr[i].chargers_count > maxChargersCount)
				maxChargersCount = snapshotsArr[i].chargers_count;
		}
		
		let ticks = [];
		for (let i = 0; i <= maxChargersCount+1; i++) {
			ticks.push(i);
		}

		let step = (0.25*maxEnergy);
		if(step == 0) {
			step = 1;
		}
		let energyTicks = [];
		for (var i = 0; i <= maxEnergy+step; i+=step) {
			energyTicks.push(i);
		}

		this.snapshotsData = snapshotsData.concat(data);

		var vAxes = {
			"0" : {
				textPosition: "out",
				textStyle: {color: '#328332'},
				title: this.translateService.instant('g.KW'),
				titleTextStyle: {color: '#328332'},
				viewWindow: { max: maxKWValue },
				ticks: energyTicks
			}
		};

		var series = {
			"0" : {
				"targetAxisIndex": 0,
				"color": "#328332" ,
				type: 'line'
			}
		};

		let counter = 1;
		if(this.showNumberOfChargers) {
			vAxes["1"] = {
				textPosition: "out", 
				textStyle:{color: '#b20e66'}, 
				title: this.translateService.instant('device.number_of_chargers'), 
				titleTextStyle: {color: '#b20e66'},
				ticks: ticks
			};
			series["1"] = {
				"targetAxisIndex": 1, 
				"color": "#b20e66", 
				type: 'line'
			};
			counter++;
		}

		let colors = ["#3c00ff", "#FFFF00", "#41ca6a", "#C70039", "#c78700", "998eba"];
		profiles.forEach((profile, index) => {
			let i = index % colors.length;
			series[''+(counter++)] = {
				color: colors[i]
			};
		});

		if(snapshotsArr.length === 0) {

			vAxes["0"].title = "";
			if(this.showNumberOfChargers) vAxes["1"].title = "";
		}

		this.snapshotsConfig = new ComboChartConfig(null, series, {
			LegendPosition: 'top', 
			hAxis: {
				title: hAxisTitle
			},
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
			pointSize: 3,
			vAxes: vAxes,
			explorer: {
				"actions": [
					"dragToZoom",
					"rightClickToReset"
				],
				maxZoomIn: 50.0,
				keepInBounds: true
			},
			useDateFormat: true,
			height: 400,
			tooltip: {},
			seriesType: 'steppedArea'
		});
	}

	printChargerEnergyChart() {

		var fromDate	= (<HTMLInputElement>document.getElementById('fromDate')).value;
		var title		= this.translateService.instant('act.charger_energy');
		var headerText	= "";
		var elementId	= 'chargerEnergy';
		
		title = this.translateService.instant('act.events_chart');
		
		var companyFullName	= this.currentSite.customer_name + '/' + this.currentSite.name;
		var image = this.images[elementId];
		var printContent = '<div>' + companyFullName + "<h3>" + title + "</h3><h4>For: " + fromDate + "</h4>"/*+"<h4>To: " + toDate + "</h4>"*/ + headerText + '<img src="'+image+'" style="width:100%;"" border="0" alt="Null"></div>';

		this.commonUtil.print({
			appendPrintContent: printContent
		});
	}
}