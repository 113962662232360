import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-device-notes',
	templateUrl: './device-notes.component.html',
	styleUrls: ['./device-notes.component.css']
})
export class DeviceNotesComponent implements OnInit {

	@Input() device: any = {};
	@Input() deviceType: string = '';
	@Input() sourcePage: string = '';
	@Input() siteId: number = 0;

	@Output() updateAppearanceElementsFlags = new EventEmitter<any>(true);

	constructor() { }

	ngOnInit() {
		if(this.deviceType == 'battview' || this.deviceType == 'battview mobile') {
			this.updateAppearanceElementsFlags.emit({
				addNoteEnabled: false,
				pageHasFilterByDate: false,
				pageHasBattviewTags: false,
				hasResetPLC: false,
				pageHasManageBtn: false,
				pageHasTimesInfo: false,
			});
		}
	}

}
