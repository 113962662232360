import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-battviews-mobiles',
	templateUrl: './battviews-mobiles.component.html',
	styleUrls: ['./battviews-mobiles.component.css']
})
export class BattviewsMobilesComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}
}
