<form #changePasswordForm="ngForm" class="form-auth" (ngSubmit)="changeUserPassword()">
  <div class="container-fluid" id="main">
    <div class="row">
      <div class="col-md-6 col-md-offset-3" id="left">
        <div class="boxs3">
          <h2>{{pageName == 'g.reset_password' ? 'g.reset_password' : 'g.change_password' | translate}}</h2>
          <div class="form-field" *ngIf="pageName != 'reset_password'">
            <label for="oldPassword">{{'g.old_password' | translate}}</label>
            <input type="password" class="form-control" [(ngModel)]="user.oldPassword" name="oldPassword" placeholder="{{'g.old_password' | translate}}" required="true">
          </div>
          <br/>
          <div class="form-field">
            <label for="newPassword">{{'g.new_password' | translate}}</label>
            <input type="password" class="form-control" [(ngModel)]="user.newPassword" name="newPassword" placeholder="{{'g.new_password' | translate}}" required="true">
          </div>
          <br/>
          <div class="form-field">
            <label for="confirmPassword">{{'g.confirm_password' | translate}}</label>
            <input type="password" class="form-control" [(ngModel)]="user.confirmPassword" name="confirmPassword" placeholder="{{'g.confirm_password' | translate}}" required="true">
          </div>
          <br/>
          <span class="text-danger" *ngIf="(user.confirmPassword && user.newPassword) && user.confirmPassword != user.newPassword">* {{'g.passwords_dont_match' | translate}}<br/><br/></span>
          <span class="text-danger" *ngIf="(user.confirmPassword && user.oldPassword && user.newPassword) && (user.oldPassword == user.newPassword && user.newPassword == user.confirmPassword)">* {{'g.please_choose_a_new_password' | translate}}<br/><br/></span>
          <button type="submit" class="btn btn-primary" [disabled]="changePasswordForm.invalid || (user.confirmPassword != user.newPassword) || (user.oldPassword == user.newPassword && user.newPassword == user.confirmPassword)">{{'g.submit' | translate}}</button>
          <button *ngIf="pageName != 'reset_password'" type="button" class="btn btn-default" (click)="navigation.goBack(['sites-map'])">{{'g.cancel' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</form>