<app-modal #detectionModal [title]="'charger.battery_events_detection' | translate" [hasConfirmButton]="false" [hasCloseButton]="false" [size]="'lg'">
	<div role="alert" class="alert alert-danger alert-dismissible" *ngIf="detectionConfig.invalidFields.length > 0">
		<div>{{'g.invalid_input' | translate}}!</div>
	</div>
	<div class="form-inline row padding-8">
		<div class="col-md-4">{{'device.start_time_detection' | translate}}</div>
		<div class="col-md-8 form-group">
			<span style="margin-right: 10px;">{{'g.from' | translate}} </span>
			<span class="col-md-3 input-group">
				<input type="number" min="-60" max="0" step="1" class="form-control" [(ngModel)]="detectionConfig.minStartTimeRange" [ngClass]="{'invalid-input': isInvalidDetectionConfigField('minStartTimeRange')}" />
				<span class="input-group-addon">{{'g.minute' | translate}}</span>
			</span>
			<span style="margin-left: 10px;margin-right: 10px;">{{'g.to' | translate}} </span>
			<span class="col-md-3 input-group">
				<input type="number" min="0" max="60" step="1" class="form-control" [(ngModel)]="detectionConfig.maxStartTimeRange" [ngClass]="{'invalid-input': isInvalidDetectionConfigField('maxStartTimeRange')}" />
				<span class="input-group-addon">{{'g.minute' | translate}}</span>
			</span>
		</div>
	</div>
	<div class="row padding-8">
		<div class="col-md-4">{{'device.dynamic_cycle_duration' | translate}}</div>
		<div class="col-md-8">
			<label><input type="radio" name="durationEnabled" [(ngModel)]="detectionConfig.durationEnabled" class="form-control" [value]="true"/> {{'g.enabled' | translate}}</label>
			<label><input type="radio" name="durationEnabled" [(ngModel)]="detectionConfig.durationEnabled" class="form-control" [value]="false"/> {{'g.disabled' | translate}}</label>
		</div>
	</div>
	<div class="form-inline row padding-8">
		<div class="col-md-4">{{'device.duration' | translate}}</div>
		<div class="col-md-8 form-group">
			<span class="col-md-5 input-group">
				<input type="number" min="-60" max="60" step="1" class="form-control" [(ngModel)]="detectionConfig.duration" [disabled]="!detectionConfig.durationEnabled" [ngClass]="{'invalid-input': isInvalidDetectionConfigField('duration')}" />
				<span class="input-group-addon">{{'g.minute' | translate}}</span>
			</span>
		</div>
	</div>
	<div class="modal-footer">
		<div class="col-md-5"><span class="save-btn" (click)="runDetection('OK')">{{'device.run_detection' | translate}}</span></div>
		<div class="col-md-5"><span class="back-btn" (click)="runDetection('cancel')">{{'g.cancel' | translate}}</span></div>
	</div>
</app-modal>
<app-modal #detectionResultModal [title]="'Battery Events Detection'" [hasConfirmButton]="false">
	<table class="table table-striped table-condensed table-bordered" *ngIf="eventsDetectionObj?.data?.length > 0">
		<tr>
			<td>{{'g.battview' | translate}}</td>
			<td>{{'act.event_id' | translate}}</td>
		</tr>
		<tr *ngFor="let item of eventsDetectionObj.data">
			<td>
				<a (click)="detectionResultModal.hide()" [routerLink]="['/', customerID, siteID, 'battviews', 'analytics', 'events-log', item.bfdid]">
					{{eventsDetectionObj.devices[item.bfdid].batteryid}} ({{eventsDetectionObj.devices[item.bfdid].serialnumber}})
				</a>
			</td>
			<td>
				{{item.sequenceid}}
			</td>
		</tr>
	</table>
	<div class="alert alert-warning" *ngIf="eventsDetectionObj?.data?.length == 0">
		{{'charger.detection_no_result' | translate}}
	</div>
</app-modal>

<div *ngIf="isSet('cycles') && ChargerCyclesGridData.length > 0 && hasChargers">
	<div class="panel panel-default">
		<div class="panel-body">
			<div>
				<label><input type="radio" [(ngModel)]="isVPC" class="form-control" [value]="true" (click)="clickChangeVPC(true)" /> {{'device.vpc' | translate}}</label>
				<label><input type="radio" [(ngModel)]="isVPC" class="form-control" [value]="false" (click)="clickChangeVPC(false)" /> {{'charger.battery_voltage' | translate}}</label>
			</div>
			<app-grid-v2 #cyclesGrid
				translateHeaders="true"
				id="ChargerCyclesGrid"
				[modelId]="charger.id"
				[columns]="ChargerCyclesGridColumns"
				[data]="ChargerCyclesGridData"
			></app-grid-v2>
		</div>
	</div>
</div>
<div *ngIf="isSet('faults') && PMFaultsGridData.length > 0 && hasChargers">
	<div class="panel panel-default">
		<div class="panel-body">
		<app-grid-v2
			translateHeaders="true"
			id="PMFaultsGrid"
			[modelId]="charger.id"
			[columns]="PMFaultsGridColumns"
			[data]="PMFaultsGridData"
		></app-grid-v2>
		</div>
	</div>
</div>
<div *ngIf="isSet('pm-warnings') && PMWarningsGridData.length > 0 && hasChargers">
	<div class="panel panel-default">
		<div class="panel-body">
		<app-grid-v2
			translateHeaders="true"
			id="PMWarningsGrid"
			[modelId]="charger.id"
			[columns]="PMWarningsGridColumns"
			[data]="PMWarningsGridData"
		></app-grid-v2>
		</div>
	</div>
</div>
<div *ngIf="((isSet('cycles') && ChargerCyclesGridData.length == 0) || (isSet('faults') && PMFaultsGridData.length == 0) || (isSet('pm-warnings') && PMWarningsGridData.length == 0)) && hasChargers">
	<div class="panel panel-default">
		<div class="panel-body">
			<strong>{{'g.no' | translate}}
				<span *ngIf="isSet('faults')"> {{'device.pm_faults' | translate}} </span>
				<span *ngIf="isSet('pm-warnings')"> {{'device.pm_warnings' | translate}} </span>
				<span *ngIf="isSet('cycles')"> {{'charger.cycles_record' | translate}} </span>{{'g.found' | translate}}!
			</strong>
		</div>
	</div>
</div>
<div *ngIf="!hasChargers">
	<div class="panel panel-default">
		<div class="panel-body">
			<strong>{{'charger.no_charger_registered' | translate}}</strong>
		</div>
	</div>
</div>
<div *ngIf="isSet('GR') && hasChargers">
	<div class="panel panel-default">
		<div class="panel-body">
			<div class="row">
				<table class="cellpadding-10">
					<tr>
						<td class="col-sm-6">{{'charger.total_charging_ahrs' | translate}}</td>
						<td>{{charger.totalahr | number:0}}</td>
					</tr>
					<tr>
						<td class="col-sm-6">{{'charger.total_charging_kwhrs' | translate}}</td>
						<td>{{charger.totalkwhr | number:0}}</td>
					</tr>
					<tr>
						<td class="col-sm-6">{{'charger.total_in_kwhrs' | translate}}</td>
						<td>{{charger.totalInKWHRs | number:0}}</td>
					</tr>
					<tr>
						<td class="col-sm-6">{{'charger.total_charging_duration' | translate}}</td>
						<td>{{charger.totalchargingduration}}</td>
					</tr>
					<tr>
						<td class="col-sm-6">{{'charger.total_charging_cycles' | translate}}</td>
						<td>{{charger.chargecycles | number:0}}</td>
					</tr>
					<tr *ngIf="userService.hasAccessFunction('extended_global_records')">
						<td class="col-sm-6">{{'charger.pm_faults_count' | translate}}</td>
						<td>{{charger.pmfaults | number:0}}</td>
					</tr>
					<tr *ngIf="userService.hasAccessFunction('extended_global_records')">
						<td class="col-sm-6">{{'charger.pm_warnings_count' | translate}}</td>
						<td>{{charger.pm_warning_count | number:0}}</td>
					</tr>
					<tr *ngIf="userService.hasAccessFunction('extended_global_records')">
						<td class="col-sm-6">{{'charger.manual_pm_faults' | translate}}</td>
						<td>{{charger.manualpmfaults | number:0}}</td>
					</tr>
					<tr *ngIf="userService.hasAccessFunction('extended_global_records')">
						<td class="col-sm-6">{{'charger.manual_charge_cycles' | translate}}</td>
						<td>{{charger.manualchargecycles | number:0}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</div>