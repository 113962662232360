import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { NotificationMessageService } from '../notification-message/notification-message.service';
import { TagsService } from './tags.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'tags',
	templateUrl: './tags.component.html',
	styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit, OnChanges {
	@Input() addTag: boolean = true;
	@Input() isMultiple: boolean = true;
	@Input() addToSite: boolean = false;
	@Input() hideSelected: boolean = false;
	@Input() isACTuser: boolean = false;
	@Input() items: any[] = [];
	@Input() deviceType: string = null;
	@Input() deviceId: any = 0;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() tag: any = {};
	@ViewChild("modal") modal;

	@Output() onUpdateTags = new EventEmitter<Object>(true);
	@Output() onAddTagFault = new EventEmitter<Object>(true);

	tagSharingOptions: {id: string, text: string}[];
	tempTag: any = {};
	deviceTagsInfo: any = {};
	title: String;
	showSharingOption: boolean = true;
  	keepInReplacement: boolean = true;

	constructor(
		private notificationMessage: NotificationMessageService,
		private tagsService: TagsService,
		private translateService: TranslateService
	) {
		this.title = translateService.instant('g.settings');
	}

	ngOnInit() {
		if(!this.isMultiple)
			this.tag = null;

		this.getTagSharingOptions();

		if (this.addToSite) {
			this.title = this.translateService.instant('tag.sharing_options');
		}
	}

	ngAfterViewInit() {
		this.modal.onClose.subscribe(
			(ok) => {
				if(ok && (this.tempTag.shareOption || this.tempTag.sharingoption)) {
					this.addTagFunction();
				} else {
					if(this.isMultiple)
						this.tag = this.tag.slice(0, this.tag.length-1);
					else
						this.tag = null;
				}
			}
		);
	}

	ngOnChanges(changes) {
		if(changes.tag && changes.tag.currentValue && Array.isArray(changes.tag.currentValue)) {
			let newTags = changes.tag.currentValue;
			let deviceTagsInfo = {};
			newTags.forEach((item) => {
				deviceTagsInfo[item.id] = item;
			});
			this.deviceTagsInfo = deviceTagsInfo;
		}
	}

	change(tag) {
		if(this.isMultiple)
			return;

		this.add(tag);
	}

	add(tag) {
		if(typeof tag == 'string') {
			this.tempTag = {'tagname': tag}
			for(var item of this.items) {
				if(item.tagname == tag) {
					this.tempTag = item;
					break;
				}
			}
		} else
			this.tempTag = tag;

		this.showSharingOption = this.addTag && (!this.tempTag || !(this.tempTag.sharingoption || this.tempTag.shareOption));

		this.tempTag.tagname = this.tempTag.tagname.trim();
		if(this.tempTag.tagname.length < 3) {
			this.tag = this.tag.slice(0, this.tag.length-1);
			this.notificationMessage.setMessage('translate|tag.invalid_tag_name');
			this.onAddTagFault.emit({tag: tag});
			return;
		}
		var showKeepInReplacement = this.isACTuser && !this.addToSite;
		if(this.showSharingOption || showKeepInReplacement) {
      		this.keepInReplacement= false;
			this.modal.show();
		} else {
			this.addTagFunction();
    	}
	}

	addTagFunction() {
	  this.tempTag.keep_in_replacement = this.keepInReplacement;
		if(this.addToSite) {
			this.tagsService.addSiteTags(this.customerId, this.siteId, this.tempTag).subscribe( (data: any) => {

				this.tag = null;
				if(data.httpStatus == 'error') {
					this.notificationMessage.setMessage(data.msg);
				} else {
					this.onUpdateTags.emit({type: 'add', tag: data});
					this.notificationMessage.setMessage('globalSuccessMsg', 'success');
				}
			});
		} else {
			this.tagsService.addDeviceTag(this.customerId, this.siteId, this.deviceType, this.deviceId, this.tempTag).subscribe(
				(res: any) => {
					if(res.httpStatus == 'error') {
						this.notificationMessage.setMessage(res.msg);
						this.tag = this.tag.slice(0, this.tag.length-1);
						this.items = this.items.slice(0, this.items.length-1);
					} else {
						this.tag[this.tag.length - 1] = this.tempTag;
						this.tag[this.tag.length - 1].id = res[1].id;
						if(res[1].isNewTag)
							this.items.push(this.tag[this.tag.length - 1]);
						this.notificationMessage.setMessage(res[0], 'success', 10000, true);
						this.onUpdateTags.emit({type: 'add', tag: res[1]});
					}
				},
				() => {
					this.onAddTagFault.emit({tag: this.tempTag});
				}
			);
		}
	}

	checkReplacementTag(tag) {
		if(!this.isACTuser)
			return false;
		
		if(tag.keep_in_replacement || (this.deviceTagsInfo[tag.id] && this.deviceTagsInfo[tag.id].keep_in_replacement))
			return true;
		
		return false;
	}

	removeTag(event) {
		this.tagsService.removeDeviceTag(this.customerId, this.siteId, this.deviceType, this.deviceId, this.items[event.index]).subscribe(
			() => {
				this.onUpdateTags.emit({type: 'remove', tag: this.items[event.index]});
			}
		);
	}
	private getTagSharingOptions() {
		this.tagSharingOptions = [
			{id:'public', text: this.translateService.instant('note.public')},
			{id:'private', text: this.translateService.instant('note.private')}
		];
		if (this.isACTuser) {
			this.tagSharingOptions.push({id:'group', text: this.translateService.instant('tag.within_act_group')});
		}
	};
}
