import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AdminService } from '../../../admin.service';
import { BreadcrumbService } from '../../../../shared/breadcrumb/breadcrumb.service';

@Component({
	selector: 'app-oem-edit',
	templateUrl: './oem-edit.component.html',
	styleUrls: ['./oem-edit.component.css']
})
export class OemEditComponent implements OnInit, OnDestroy {

	oem: any = {
		id: 0,
		name: ''
	};
	
	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute,
		private router: Router,
		private breadcrumb: BreadcrumbService
	) {
	}

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([
			{'label': 'OEMs', 'url': '/oems'},
			{'label': 'Edit', 'url': ''},
		]);
		this.route.params.subscribe(
			params => {
				this.oem['id'] = Number(params['oemId']);

				if(this.oem['id'])
					this.getOEMinfo(this.oem['id']);
			}
		);
	}

	getOEMinfo(id) {
		this.adminService.getOEMs(id).subscribe(
			data => {
				this.oem = data;
			}
		);
	}

	updateOEM() {
		this.adminService.updateOEM(this.oem).subscribe(
			data => {
				this.router.navigate(['/oems']);
			}
		);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}