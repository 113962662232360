import { Component, OnDestroy, OnInit } from '@angular/core';

import * as _ from 'underscore';

import { DeviceManagementService } from '../device-management.service';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-voltus-dispatches-log',
	templateUrl: './voltus-dispatches-log.component.html',
	styleUrls: ['./voltus-dispatches-log.component.css']
})
export class VoltusDispatchesLogComponent implements OnInit, OnDestroy {

	data: any;

	constructor(
		private deviceManagementService: DeviceManagementService,
		private breadcrumbService: BreadcrumbService,
		private translateService: TranslateService
	) { }

	ngOnInit(): void {
		this.breadcrumbService.setBreadcrumb([{'label': this.translateService.instant('site.voltus_dispaches_log'), 'url': ''}]);
		this.deviceManagementService.getDispatchedVoltusLogs().subscribe(
			(data: any) => {
				this.data = data;
			}
		);
	}

	ngOnDestroy() {
		this.breadcrumbService.hide();
	}
}
