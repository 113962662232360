import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import { DeviceManagementService } from '../../device-management.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { AdminService } from '../../../admin/admin.service';
import { UserService } from '../../../auth/user.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-device-by-sn',
	templateUrl: './device-by-sn.component.html',
	styleUrls: ['./device-by-sn.component.css']
})
export class DeviceBySnComponent implements OnInit, OnDestroy {

	type = 'charger';
	devicesInfo  = [];
	isPartial: boolean = false;
	serialnumber: string = '';
	noResult: boolean = null;
	currentUser: any = {};
	manageDatesAccess: boolean = false;

	constructor(
		private deviceManagemntService: DeviceManagementService,
		private breadcrumb: BreadcrumbService,
		private router: Router,
		private commonUtil: CommonUtil,
		private adminService: AdminService,
		public userService: UserService,
		private notificationMessage: NotificationMessageService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([
			{'label': this.translateService.instant('device.search_by_sn'), 'url': ''}
		]);
		this.currentUser = this.userService.getCurrentUser();
		this.manageDatesAccess = this.userService.hasAccessFunction('accounting') ||
			this.userService.hasAccessFunction('act_intelligent_management') ||
			this.userService.hasAccessFunction('admin_act_intelligent') ||
			this.userService.hasAccessFunction('users_function_management');
	}

	getDeviceBySN(type) {
		this.devicesInfo = [];
		this.type = type;
		this.noResult = false;

		let flags = {'partial': this.isPartial};
		this.serialnumber = this.serialnumber.trim();

		this.deviceManagemntService.getDeviceBySN(this.serialnumber, this.type, flags).subscribe(
			(devicesInfo: any) => {
				if(devicesInfo.length === 0) {
					this.noResult = true;
				} else {
					this.noResult = false;

					this.devicesInfo = devicesInfo;
					this.devicesInfo.forEach((deviceInfo) => {
						
						if(this.type == 'battview' && this.commonUtil.arrayCompare([+deviceInfo.oemid, +deviceInfo.dealerid, +deviceInfo.servicedealerid, +deviceInfo.customerid, +deviceInfo.siteid], [0, 0, 0, 0, 0]))
							deviceInfo.isProductionLine = true;
						
						deviceInfo.serialnumber = this.commonUtil.formatSerialNumber(deviceInfo);
					});
				}
			}
		);
	};

	deleteDevice(id) {
		let deviceText = this.type;
		if(this.type == 'battview')
			deviceText = this.translateService.instant('g.battview');
		if(this.type == 'charger')
			deviceText = this.translateService.instant('device.charger');

		if(confirm(this.translateService.instant('device.delete_device_prompt', {deviceType: deviceText}))) {
			this.adminService.deleteDevice(id, this.type).subscribe(
				data => this.getDeviceBySN(this.type)
			);
		}
	}

	goToEditDevice(deviceInfo) {
		let link = this.deviceManagemntService.getEditDeviceLink(this.type, deviceInfo);
		this.router.navigate(link.url, {queryParams: link.params});
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}

	regenerateDailyDetails(deviceInfo) {
		this.deviceManagemntService.regenerateDailyDetails(deviceInfo['id'], deviceInfo['studyid']).subscribe(res=> {
			this.notificationMessage.setMessage('globalSuccessMsg');
		})
	}
}