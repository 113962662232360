import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import { UserService } from '../../auth/user.service';

@Component({
	selector: 'app-manage-device-btn',
	templateUrl: './manage-device-btn.component.html',
	styleUrls: ['./manage-device-btn.component.css']
})
export class ManageDeviceBtnComponent implements OnInit {

	currentUser: any = {};
	@Input() selectedDevices = [];
	@Input() type: String = 'charger';
	@Input() twoLines: Boolean = false;
	@Input() hasBottomMargin: Boolean = true;

	chargerEditBtnTxt	= ["g.manage", "device.charger", "time.dates"];
	chargerReqBtnTxt	= ["charger.request_charger", "charger.management_change"];
	battviewEditBtnTxt	= ["g.manage", "BATTView", "time.dates"];
	battviewReqBtnTxt	= ["device.request_battview", "charger.management_change"];
	truckviewEditBtnTxt	= ["g.manage", "TRUCKView", "time.dates"];
	truckviewReqBtnTxt	= ["g.request_truckview", "charger.management_change"];
	btnText = [];
	btnDatesText = [];
	manageDatesAccess: boolean = false;

	constructor(private userService: UserService, private router: Router) { }

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();
		if(this.type == 'charger') {

			if(this.currentUser['allowToChangeDeviceAttribute']) {
				this.btnText = [this.chargerEditBtnTxt[0], this.chargerEditBtnTxt[1]];
				this.btnDatesText = [this.chargerEditBtnTxt[0], this.chargerEditBtnTxt[1], this.chargerEditBtnTxt[2]];
			} else {
				this.btnText = [this.chargerReqBtnTxt[0], this.chargerReqBtnTxt[1]];
				this.twoLines = true;
			}
		} else if (this.type == 'battview') {
			//battview
			if(this.currentUser['allowToChangeDeviceAttribute']) {
				this.btnText = [this.battviewEditBtnTxt[0], this.battviewEditBtnTxt[1]];
				this.btnDatesText = [this.battviewEditBtnTxt[0], this.battviewEditBtnTxt[1], this.battviewEditBtnTxt[2]];
			} else
				this.btnText = [this.battviewReqBtnTxt[0], this.battviewReqBtnTxt[1]];
		} else if (this.type == 'truckview') {
			//truckview
			if(this.currentUser['allowToChangeDeviceAttribute']) {
				this.btnText = [this.truckviewEditBtnTxt[0], this.truckviewEditBtnTxt[1]];
				this.btnDatesText = [this.truckviewEditBtnTxt[0], this.truckviewEditBtnTxt[1], this.truckviewEditBtnTxt[2]];
			} else
				this.btnText = [this.truckviewReqBtnTxt[0], this.truckviewReqBtnTxt[1]];
		}
		this.manageDatesAccess = this.userService.hasAccessFunction('accounting') ||
			this.userService.hasAccessFunction('act_intelligent_management') ||
			this.userService.hasAccessFunction('admin_act_intelligent') ||
			this.userService.hasAccessFunction('users_function_management');
	}

	goToManageDevicePage() {
		this.router.navigate(['/devices', 'devices-management', this.type, this.selectedDevices.join(',')]);
	}
	goToManageDatesDevicePage() {
		this.router.navigate(['/devices', 'devices-dates-management', this.type, this.selectedDevices.join(',')]);
	}
}
