import { Component, OnInit } from '@angular/core';
import { SiteDashboardService } from '../../site-dashboard.service';
import { UserService } from '../../../../auth/user.service';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';

@Component({
	selector: 'app-site-battery-aging',
	templateUrl: './site-battery-aging.component.html',
	styleUrls: ['./site-battery-aging.component.css']
})
export class SiteBatteryAgingComponent implements OnInit {

	user;
	customerId;
	siteId;
	data;
	customerName: string = '';
	siteName: string = '';

	constructor(
		private siteDashboardService: SiteDashboardService,
		private userService: UserService,
		private sideMenu: SideMenuService
	) {
		this.siteDashboardService.setActiveSubTab('aging');
	}

	ngOnInit() {
		this.user = this.userService.getCurrentUser();

		this.sideMenu.currentSite.subscribe(siteInfo => {
			this.customerId		= siteInfo.customerid;
			this.siteId			= siteInfo.id;
			this.customerName	= siteInfo.customer_name;
			this.siteName		= siteInfo.name;

			if(this.customerId && this.siteId) {
			
				this.siteDashboardService.activeDashboardData.subscribe(data => this.data = Object.assign({}, data));
				this.siteDashboardService.getDashboardData(this.customerId, this.siteId);
			}
		});
	}
}