<div class="boxs3 margin-top-lg">
	<div class="row">
		<div class="row margin-bottom">
			<div class="col-md-2 text-right-md "><strong>{{'g.date' | translate}}:</strong></div>
			<div class="col-md-3">
				<div class="input-group">
					<input class="form-control" placeholder="mm/dd/yyyy" name="startDP1" [(ngModel)]="startDate" bsDatepicker #startDP1="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
					<span class="input-group-btn">
						<button class="btn btn-default btn-date" (click)="startDP1.toggle()" type="button"><i class="fa fa-calendar"></i></button>
					</span>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<button class="btn btn-primary" (click)="fetchData()">{{'g.get_records' | translate}}</button>
	</div>
	<div class="row" *ngIf="reportNotReadyYet">
		<h4>{{'g.report_is_not_ready_yet' | translate}}</h4>
	</div>
	<div *ngIf="dataFetched && !reportNotReadyYet">
		<div class="margin-top-lg">
			<app-grid-v2
				id="act_first_parked_batteries_report"
				[columns]="gridColumns"
				[data]="gridRows"
			></app-grid-v2>
		</div>
		<div class="margin-top-xlg">
			<simple-widget title="{{'g.counts' | translate}}">
				<div class="margin-bottom toolss2">
					<button class="fa fa-print" data-toggle="tooltip" title="{{'g.print' | translate}}" (click)="printChart();"></button>
				</div>
				<div class="margin-top-xlg">
					<column-chart 
						(onChartReady)="generateImageURI($event)"
						[data]="chartDataTable"
						[config]="chartConfig"
						[elementId]="'parked_batteries_counts'">
					</column-chart>
				</div>
			</simple-widget>
		</div>
	</div>
</div>