import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ElementRef } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import * as moment from 'moment';

import { UserService } from '../../../../../auth/user.service';
import { DevicesDashboardService } from '../../../devices/devices-dashboard.service';
import { NotificationMessageService } from '../../../../../shared/notification-message/notification-message.service';
import { BattviewsDashboardService } from '../../../battviews/battviews-dashboard/battviews-dashboard.service';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { AdminService } from '../../../../../admin/admin.service';
import { BattviewService } from '../../../../../admin/devices/battviews/battview.service';

@Component({
	selector: 'app-battviews-mobiles-select-criteria',
	templateUrl: './battviews-mobiles-select-criteria.component.html',
	styleUrls: ['./battviews-mobiles-select-criteria.component.css']
})
export class BattviewsMobilesSelectCriteriaComponent implements OnInit {
	
	@Input() battviewID: number;
	@Input() studyID: number;
	@Input() currentUser: any;
	@Input() activePage: string = null;
	@Input() activeTab: string = null;
	@Input() activeSubTab: string = null;
	@Input() isShared: boolean = false;
	@Input() showElementsFlags: {
		addNoteEnabled: boolean,
		pageHasFilterByDate: boolean,
		pageHasBattviewTags: boolean,
		pageHasManageBtn: boolean,
		pageHasTimesInfo: boolean,
	} = {
		addNoteEnabled: false,
		pageHasFilterByDate: false,
		pageHasBattviewTags: false,
		pageHasManageBtn: true,
		pageHasTimesInfo: true,
	};
	@Input() showSettingsTab: boolean = false;

	date: {
		fromDate: any,
		toDate: any
	} = {
		fromDate: new Date(),
		toDate: new Date()
	};
	battviews: any[] = [{}];
	device: any = {};
	siteTags: any[] = [];
	deviceTags: any = {};
	hasBattviews: boolean = true;

	firstAcceptedDay	 = new Date(1*24*60*60*1000);
	getAllBattviews: boolean = false;
	getDevicesPeriod: string = '1';
	clientsAlphabets: any = {};
	userHasAccessToShareStudy: boolean = false;
	availableTrucks: any[];
	allStudies: any[];
	selectedBattview: any;
	newStudy: any;
	shareEnabled: boolean = false;
	reverseEnabled: boolean = false;
	newStudyEnabled: boolean = false;
	noSharedStudy: boolean = false;
	noOwnedStudy: boolean = false;
	reverseIds: any = {};
	battviewsMobile: any = [];
	selectedBattviewMobile: any = {};
	selectedDealerID: number = null;
	allDealers: any[] = [];
	dealersList: any[] = [];
	allOEMs: any[] = [];
	allBattviewsMobile: any[] = [];
	allStudiesData: any[] = [];
	allGroupedStudies;
	studies;
	passedStudy;
	pageHasRTrecord: boolean = false;
	hideStudyDateFilter: boolean = false;
	selectedStudy: any = {};
	OEMs: any[] = [];
	dealers: any[] = [];
	clientNameIndex: any = null;
	clients: any[] = [];

	@Output() onDeviceChanged = new EventEmitter<any[]>(true);
	@Output() onDateChanged = new EventEmitter<{fromDate: any, toDate: any}>(true);
	@Output() updateAlertSettings = new EventEmitter<any>(true);
	@Output() updateShowSettingsTab = new EventEmitter<any>(true);
	@Output() updatePageHasRTrecord = new EventEmitter<any>(true);
	@Output() updateHasBvMobileListing = new EventEmitter<any>(true);
	@Output() onDealerChanged = new EventEmitter<any>(true);

	constructor(
		private renderer: Renderer2,
		public elementRef: ElementRef,
		private battviewService: BattviewService,
		public userService: UserService,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
		private battviewsDashboardService: BattviewsDashboardService,
		private devicesDashboardService: DevicesDashboardService,
		private notificationMessage: NotificationMessageService,
		private commonUtil: CommonUtil,
		private adminService: AdminService,
		private datePipe: DatePipe
	) { }

	ngOnInit() {
		// get battviews
		if(this.activePage == 'settings') {
			this.getBattviews();
		} else {

			this.route.queryParams.subscribe(params => {
				this.battviewID = +(params['battviewId']) || 0;
				this.studyID = +(params['studyId']) || 0;
				this.selectedDealerID = +(params['dealerId']) || null;
				this.getBattviews();
			});
		}

		if(this.activePage == 'listing') {
			this.hideStudyDateFilter = true;
		}
		
		this.date.fromDate.setDate(this.date.fromDate.getDate() - 30);
		this.datesChanged();
	}

	getBattviews(forceNotChangeDevice = false) {
		if(this.device.bfdid && !forceNotChangeDevice) {
			this.device = {};
			this.deviceChanged();
		}
		this.availableTrucks = [];
		this.allStudies = [];
		this.selectedBattview = {};
		this.newStudy = {};

		this.shareEnabled = false;
		this.reverseEnabled = false;
		this.newStudyEnabled = false;
		
		this.reverseIds.start = null;
		this.reverseIds.end = null;
		this.userHasAccessToShareStudy = false;
		if (!this.isShared) {
			if (this.currentUser.isACTuser || this.currentUser.isDealer || this.currentUser.isOEM) {
				this.userHasAccessToShareStudy = true;
			}
		}

		var dataObj = {
			isShared: this.isShared,
			getBy: this.getAllBattviews ? 'getAll' : false,
			devicesPeriod: this.getDevicesPeriod
		};
		this.battviewsDashboardService.getListOfStudies(dataObj).subscribe((result: any) => {
			var battviewsMobile = [];
			var selectAllIds = [];
			var passedBFDIdx:any = -1;

			if (result.battviewMobile.length === 0) {
				if (this.isShared) {
					this.noSharedStudy = true;
				} else {
					this.noOwnedStudy = true;
				}
			} else {
				for (var i in result.battviewMobile) {
					var item = result.battviewMobile[i];
					battviewsMobile.push({
						name: item.serialnumber,
						id: item.id,
						serialnumber: item.serialnumber,
						dealerid: item.dealerid,
						oemid: item.oemid,
						customerid: item.customerid,
						actviewenabled: item.actviewenabled,
						lastconnecttime: item.lastconnecttime,
						studyid: item.studyid,
						lasteventtime: (item.lasteventtime ? new Date(item.lasteventtime):new Date()),
						ip_address: item.ip_address
					});
					
					if(item.id == this.battviewID)
						passedBFDIdx = i;

					selectAllIds.push(item.id);
				}

				if (result.battviewMobile.length > 1) {
					battviewsMobile.push({
						name: 'All BattView Mobiles',
						id: selectAllIds
					});
				}
			}

			this.allBattviewsMobile = [];
			this.allStudiesData = [];
			this.allBattviewsMobile = _.extend(this.allBattviewsMobile, battviewsMobile);
			this.allStudiesData = _.extend(this.allStudiesData, result.studies);

			this.battviewsMobile = battviewsMobile;
			if(passedBFDIdx > -1)
				this.selectedBattviewMobile = battviewsMobile[passedBFDIdx];
			else if(this.selectedDealerID == null)
				this.selectedBattviewMobile = battviewsMobile[battviewsMobile.length -1];

			this.groupStudies(result.studies, selectAllIds);
			
			if(passedBFDIdx > -1) {
				this.dealerSelectionChanged(this.selectedBattviewMobile.dealerid, true);
				this.battviewMobileSelectionChanged();
			} else if(this.selectedDealerID != null) {
				
				this.dealerSelectionChanged(this.selectedDealerID, true);
				this.updateHasBvMobileListing.emit(true);	
			}

			if(this.passedStudy)
				this.studyNameSelectionChanged(this.passedStudy);
		});

		if(this.userHasAccessToShareStudy) {
			if (this.currentUser.isACTuser) {
				this.getOEMs();
			}
			if (this.currentUser.isACTuser || this.currentUser.isOEM) {
				this.getDealers();
			}
			this.getClientsIndex();
		} else {
			if(this.currentUser.isACTuser)
				this.getDealers();
		}
	}

	getDealers() {
		this.adminService.getDealers(0).subscribe((response: any) => {
			this.allDealers	= response.dealers;
			this.dealersList = _.extend([], this.allDealers);
			this.dealersList.unshift({'id':0, 'name':'- No Dealer -'});
		});
	}

	getOEMs() {
		this.adminService.getOEMs(0).subscribe((response: any) => {
			this.allOEMs = response;
		});
	}

	getClientsIndex() {
		this.clientsAlphabets = this.commonUtil.makeAssociativeArray(this.commonUtil.alphabetCharacters, this.commonUtil.alphabetCharacters);
	}

	deviceChanged(){
		let url: any[] = [this.activePage];
		let queryParams: any = {};

		switch(this.activePage) {
			case 'analytics':
				url.push(this.activeTab);
				if(this.activeSubTab) {
					url.push(this.activeSubTab);
				}
				queryParams = {};
				if(this.device.bfdid)
					queryParams.battviewId = this.device.bfdid;
				if(this.device.studyid)
					queryParams.studyId = this.device.studyid;

				if(this.currentUser.isACTuser) {
					queryParams.dealerId = this.selectedBattview.dealerid || '';
				}
			break;
			case 'settings':
				let battviewsIDs = '';
				if(this.device.bfdid)
					battviewsIDs += this.device.bfdid;
				if(this.device.studyid)
					battviewsIDs += '-'+this.device.studyid;
				queryParams = {'battviewsIDs': battviewsIDs};
			break;
			default:
				// url.push(this.device.id || 0);
			break;
		}
		
		this.location.go(
			this.router.createUrlTree(
				url,
				{'relativeTo': this.route.parent, 'queryParams': queryParams}
			).toString()
		);

		if(this.device.bfdid) {
			this.device.id = this.device.bfdid;
		}

		this.onDeviceChanged.emit([this.device, this.date]);
	}
	datesChanged() {
		this.onDateChanged.emit(this.date);
	}

	groupStudies(studies, selectAllIds) {
		this.studies = [];
		this.allStudies = [];
		this.passedStudy = null;

		var names = [];
		for(var studyIndex in studies){
			var study = studies[studyIndex];
			if(selectAllIds.indexOf(+(study.bfdid)) === -1)
				continue;

			if (study.studyname == null) {
				study.studyname = "Study #"+study.studyid;
			}

			study.truckName = (study.truckid != null ? study.truckid : "No Truck ID") + " ";

			if (study.studystartdate != null && new Date(study.studystartdate) > new Date(0)) {
				study.truckName += this.datePipe.transform(study.studystartdate, 'MM/dd/yyyy');
			} else {
				var installation_date = new Date(study.installationdate);
				if (installation_date > this.date.fromDate) {
					study.truckName += this.datePipe.transform(installation_date, 'MM/dd/yyyy');
				} else {
					study.truckName += this.datePipe.transform(this.date.fromDate, 'MM/dd/yyyy');
				}
			}

			if(names.indexOf(study.studyname) === -1) {
				this.studies.push({studyname:study.studyname, bfdid: study.bfdid});
				names.push(study.studyname);
			}

			this.allStudies.push(study);

			if(this.battviewID == study.bfdid && study.studyid == this.studyID)
				this.passedStudy = study;
		}

		this.allGroupedStudies = this.studies;
	}

	highlightInvalidFields(invalidFields){
		this.notificationMessage.setMessage('Invalid input!', 'danger');
		for (let i = 0; i < invalidFields.length; i++) {
			let field = invalidFields[i];
			var ele = this.elementRef.nativeElement.querySelector('[name=' + field + ']');
			if(ele)
				ele.classList.add('invalid-input');
		}
	}
	startNewStudy() {
		let invalidFields = this.battviewService.validateStudy(this.newStudy);
		if(invalidFields.length){
			this.highlightInvalidFields(invalidFields);
			return;
		}
		this.notificationMessage.closeNotification();
		let inputs = this.elementRef.nativeElement.querySelectorAll('input');
		inputs.forEach((select) => {
			this.renderer.removeClass(select, 'invalid-input');
		});

		this.battviewsDashboardService.startNewStudy(this.selectedBattviewMobile.id, this.newStudy).subscribe((response:any) => {
			if(response.httpStatus == 'error') {
				if(response.msg == 'invalidFields') {
					this.highlightInvalidFields(response.invalidFields);
				}
			}
		});
	}

	dealerSelectionChanged(selectedDealerID, internal) {

		if(!this.currentUser.isACTuser)
			return;
		
		if(this.showSettingsTab) {
			this.showSettingsTab = false;
			this.updateShowSettingsTab.emit(this.showSettingsTab);
		}

		if(this.device.bfdid) {
			this.device = {};
			this.deviceChanged();
		}
		this.studies = [];
		this.availableTrucks = [];
		this.closeShareBox();
		this.closeReverseBox();
		this.closeNewStudyBox();
		this.selectedStudy = {};

		this.selectedDealerID = selectedDealerID;
		this.updateHasBvMobileListing.emit(true);

		var dealerBattviews = [];
		var selectAllIds = [];

		this.allBattviewsMobile.forEach((item) => {
			if(this.selectedDealerID == item.dealerid) {
				selectAllIds.push(item.id);
				dealerBattviews.push(item);
			}
		});

		if(selectAllIds.length > 1) {
			dealerBattviews.push({
				name: 'All BattView Mobiles',
				id: selectAllIds
			});
		}

		this.battviewsMobile = dealerBattviews;

		if(!internal) {
			this.selectedBattviewMobile = dealerBattviews[dealerBattviews.length -1];
		}
		this.groupStudies(this.allStudiesData, selectAllIds);

		this.onDealerChanged.emit(this.selectedDealerID);

		let queryParams: any = {};
		if(this.currentUser.isACTuser) {
			queryParams.dealerId = this.selectedDealerID || 0;
		}
		let url: any[] = [this.activePage];
		if(this.activeTab)
			url.push(this.activeTab);
		
		this.location.go(
			this.router.createUrlTree(
				url,
				{'relativeTo': this.route.parent, 'queryParams': queryParams}
			).toString()
		);
	}
	battviewMobileSelectionChanged() {
		if(this.showSettingsTab) {
			this.showSettingsTab = false;
			this.updateShowSettingsTab.emit(this.showSettingsTab);
		}

		if(this.device.bfdid) {
			this.device = {};
			this.deviceChanged();
		}
		this.studies = [];
		this.availableTrucks = [];
		this.closeShareBox();
		this.closeReverseBox();
		this.closeNewStudyBox();
		this.selectedStudy = {};

		if(this.selectedBattviewMobile && this.selectedBattviewMobile.id) {
			var studiesNames = [];
			if (Array.isArray(this.selectedBattviewMobile.id)) {
				this.selectedBattview = {};
				var studiesList = this.allGroupedStudies;
				if(this.selectedDealerID != null) {
					
					studiesList = [];
					this.allStudies.forEach((obj) => {
						if(this.selectedBattviewMobile.id.indexOf(+obj.bfdid) != -1 && studiesNames.indexOf(obj.studyname) < 0) {
							studiesNames.push(obj.studyname);
							studiesList.push(obj);
						}
					});
					if(this.currentUser.isACTuser) {
						this.updateHasBvMobileListing.emit(true);
					}
				}
				this.studies = studiesList;
			} else {
				this.selectedBattview = this.selectedBattviewMobile;
				this.allStudies.forEach((obj) => {
					if(obj.bfdid == this.selectedBattview.id && studiesNames.indexOf(obj.studyname) < 0) {
						studiesNames.push(obj.studyname);
						this.studies.push({studyname:obj.studyname, bfdid: obj.bfdid});
					}
				});
			}
		}
	}

	studyNameSelectionChanged(selectedStudy) {
		if(this.showSettingsTab) {
			this.showSettingsTab = false;
			this.updateShowSettingsTab.emit(this.showSettingsTab);
		}

		if(this.device.bfdid) {
			this.device = {};
			this.deviceChanged();
		}
		this.closeShareBox();
		this.closeReverseBox();
		this.closeNewStudyBox();

		this.selectedStudy = selectedStudy;
		if(selectedStudy) {
			this.availableTrucks = this.allStudies.filter((obj) => {
				return obj.studyname == this.selectedStudy.studyname && (Array.isArray(this.selectedBattviewMobile.id) || obj.bfdid == this.selectedStudy.bfdid);
			});

			if(this.availableTrucks.length > 0) {
				if(['config-track', 'connectivity-track', 'settings'].includes(this.activePage) || this.availableTrucks.length == 1) {
					this.device = this.availableTrucks[0];
					this.device.tosharedoemid = this.device.sharedoemid;
					this.device.toshareddealerid = this.device.shareddealerid;
					this.device.tosharedcustomerid = this.device.sharedcustomerid;
					this.truckSelectionChanged();
				}
			}
		}
	}
	closeShareBox() {
		this.shareEnabled = false;
		this.clientNameIndex = null;
	}
	closeReverseBox() {
		this.reverseEnabled = false;
		this.reverseIds.start = null;
		this.reverseIds.end = null;
	}
	closeNewStudyBox(open= false) {
		if(!open) {
			this.newStudyEnabled = false;
			return;
		}

		this.newStudy = {};
		this.newStudyEnabled = true;
	}
	truckSelectionChanged() {
		this.closeShareBox();
		this.closeReverseBox();
		this.closeNewStudyBox();

		if (this.device) {
			let battviewMobileSN = this.selectedBattview.serialnumber;
			if(!this.selectedBattview || Object.keys(this.selectedBattview).length === 0 || this.selectedBattview.id != this.device.bfdid) {
				if (Array.isArray(this.selectedBattviewMobile.id)) {
					var currentSelectedStudyBFDId = this.device.bfdid;
					for (var i = 0; i < this.battviewsMobile.length; i++) {
						if (this.battviewsMobile[i].id == currentSelectedStudyBFDId) {
							battviewMobileSN = this.battviewsMobile[i].serialnumber;
							this.selectedBattview = this.battviewsMobile[i];
							break;
						}
					}
				} else {
					battviewMobileSN = this.battviewsMobile[0].serialnumber;
					this.selectedBattview = this.battviewsMobile[0];
				}
			}

			this.device.serialnumber = battviewMobileSN;

			this.showSettingsTab = (this.activePage == 'settings' || (this.selectedBattview.actviewenabled && this.device.studyid && this.selectedBattview.studyid == this.device.studyid && !this.isShared));
			this.updateShowSettingsTab.emit(this.showSettingsTab);
			this.pageHasRTrecord = this.selectedBattview.actviewenabled && this.activePage == 'analytics' && this.activeTab == 'battery-summary';
			if (this.pageHasRTrecord) {
				this.devicesDashboardService.checkIfRTrecordsExist(this.device.bfdid, this.device.studyid).subscribe(data => {
					this.pageHasRTrecord = data && data[0] && data[0].records_exist ? !!data[0].records_exist : false;
					this.updatePageHasRTrecord.emit(this.pageHasRTrecord);
				});
			} else {
				this.updatePageHasRTrecord.emit(this.pageHasRTrecord);
			}
			
			this.setDates(this.activePage);

			// handle different data type
			this.device.shareddealerid		= +this.device.shareddealerid;
			this.device.sharedcustomerid	= +this.device.sharedcustomerid;
			this.device.toshareddealerid	= +this.device.toshareddealerid;
			this.device.tosharedcustomerid	= +this.device.tosharedcustomerid;
			this.device.studyStartDate		= this.selectedStudy.studystartdate;
			this.device.studyEndDate		= this.selectedBattview.lasteventtime;

			if(this.activePage != 'settings') {
				// execlude owners from this.allOEMs and this.alldealers
				if ((this.currentUser.isACTuser || this.currentUser.isOEM) && this.userHasAccessToShareStudy) {
					if (this.currentUser.isACTuser) {
						let oems = [];
						this.allOEMs.forEach((item) => {
							if (item.id != this.selectedBattview.oemid) {
								oems.push(item);
							}
						});
						this.OEMs = oems;
					}
					
					let dealers = []
					this.allDealers.forEach((item) => {
						if (item.id != this.selectedBattview.dealerid) {
							dealers.push(item);
						}
					});
					this.dealers = dealers;
				}
			}
		} else {
			this.device = {};
		}
		this.deviceChanged();
	}

	setDates(tab) {
		if(tab == 'connectivity-track') {
			this.date.fromDate	= new Date(moment().utc().subtract(1, 'days').startOf('day').unix() * 1000);
			this.date.toDate	= new Date(moment().utc().startOf('day').unix() * 1000);
		} else {
			if (this.device.studystartdate != null && new Date(this.device.studystartdate) > new Date(0)) {
				this.date.fromDate = new Date(moment(this.device.studystartdate).startOf('day').unix()*1000);
			} else {
				var installation_date = new Date(this.device.installationdate);
				if (installation_date > this.date.fromDate) {
					this.date.fromDate = new Date(moment(installation_date).startOf('day').unix()*1000);
				}
			}
			this.date.toDate	= new Date(moment(this.selectedBattview.lasteventtime).endOf('day').unix()*1000);
		}
	}

	enableShare() {
		this.selectedStudy.tosharedoemid		= this.device.sharedoemid || null;
		this.selectedStudy.toshareddealerid		= this.device.shareddealerid || null;
		this.selectedStudy.tosharedcustomerid	= this.device.sharedcustomerid || null;
		this.getCustomers(this.device.sharedcustomerid).then(() => {
			this.shareEnabled = true;
		});
	}

	getCustomers(customerId) {
		return new Promise<void>((resolve, reject) => {
			if (!customerId) {
				return resolve();
			}
			this.devicesDashboardService.getClientsByCustomerId(customerId).subscribe((response: any) => {
				this.clients = [];
				// execlude study customer owner
				response.clientsList.forEach((item, index) => {
					if (item.id != this.selectedBattview.customerid) {
						this.clients.push(item);
					}
				});
				this.clientNameIndex = response.clientNameIndex;
				return resolve();
			}, (err) => {
				return reject(err);
			});
		});
	}

	getClientsByNameIndex(clientNameIndex) {
		if(clientNameIndex) {
			this.adminService.getClientsByNameIndex(clientNameIndex).subscribe((response: any) => {
				this.selectedStudy.tosharedcustomerid = null;
				this.clients = [];
				// execlude study customer owner
				response.list.forEach((item, index) => {
					if (item.id != this.selectedBattview.customerid) {
						this.clients.push(item);
					}
				});
			});
		} else {
			this.selectedStudy.sharedcustomerid = 0;
			this.clients = [];
		}
	}
	shareWithUsers() {
		if(!this.selectedStudy.tosharedcustomerid && this.currentUser.isDealer) {
			return this.notificationMessage.setMessage('You need to select a client to share study!');
		}

		var sharedObject: any = {customerid: this.selectedStudy.tosharedcustomerid};
		if (this.currentUser.isACTuser) {
			sharedObject.oemid = this.selectedStudy.tosharedoemid;
		}
		if (this.currentUser.isACTuser || this.currentUser.isOEM) {
			sharedObject.dealerid = this.selectedStudy.toshareddealerid;
		}

		this.battviewsDashboardService.shareStudy(this.device.bfdid, this.device.studyid, sharedObject).subscribe((result:any) => {
			this.closeShareBox();
			this.device.sharedoemid = this.device.tosharedoemid;
			this.device.shareddealerid = this.device.toshareddealerid;
			this.device.sharedcustomerid = this.device.tosharedcustomerid;
			this.notificationMessage.setMessage('Study has been shared successfully!', 'success', 10000, true);
		});
	}

	deleteStudy() {
		if(confirm('Are you sure you want to delete selected study?')) {
			this.battviewsDashboardService.deleteStudy(this.device.bfdid, this.device.studyid).subscribe(msg => {
				this.notificationMessage.setMessage(msg, 'success', 10000, true);
				this.getBattviews();
			});
		}
	}

	reverseStudy() {
		this.reverseIds.start	= this.reverseIds.start ? this.reverseIds.start.trim() : this.reverseIds.start;
		this.reverseIds.end		= this.reverseIds.end ? this.reverseIds.end.trim() : this.reverseIds.end;
		this.battviewsDashboardService.reverseStudy(this.device.bfdid, this.device.studyid, this.reverseIds.start, this.reverseIds.end).subscribe(msg  => {
			this.closeReverseBox();
			this.notificationMessage.setMessage(msg, 'success', 10000, true);
		});
	}

	restartBattview() {
		this.devicesDashboardService.restartDevice('battview', this.device.bfdid, {}).subscribe();
	}
}