import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { DeviceManagementService } from '../../device-management.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-replacement-device',
	templateUrl: './replacement-device.component.html',
	styleUrls: ['./replacement-device.component.css']
})
export class ReplacementDeviceComponent implements OnInit, AfterViewInit, OnDestroy {

	serialnumber: string = null;
	devices: any[] = [];
	mode: string = null;
	deviceID: number = null;
	deviceType: string = null;
	replaceMsg: string = null;
	originalId: number = null;

	@ViewChild("modal") modal;
	@ViewChild("modal2") modal2;

	constructor(
		private deviceManagemntService: DeviceManagementService,
		private route: ActivatedRoute,
		private breadcrumb: BreadcrumbService,
		private router: Router,
	) {
		this.breadcrumb.setBreadcrumb([
			{label: 'Replacement Device', url: ''}
		]);
	}

	ngOnInit() {
		this.deviceType = this.route.snapshot.params.deviceType;
		this.deviceID   = +(this.route.snapshot.params.deviceID);
		this.mode       = this.route.snapshot.params.mode;
	}
	ngAfterViewInit() {
		this.modal.onClose.subscribe(
			(ok) => {
				if(ok) {
					if(this.deviceType == 'battview')
						this.replaceBattview();
					else
						this.replaceCharger();
				}
			}
		);
		this.modal2.onClose.subscribe(
			(ok) => {
				if(ok) {
					this.replaceBattview(true);
				}
			}
		);
	}
	getReplacementDevices(){
		var options = {
			serialnumber: this.serialnumber.trim()
		};
		switch (this.deviceType) {
			case 'battview':
				this.deviceManagemntService.getBattviews(options).subscribe(
					(battviews: any[]) => {
						this.devices = battviews;
					}
				);
				break;
				case 'charger':
					if(this.mode == 'doReplacement')
						break;
					this.deviceManagemntService.getChargers(options).subscribe(
						(chargers: any[]) => {
							this.devices = chargers;
						}
					);
			break;
			default:
				this.router.navigate(['/replacement-devices-listing']);
			break;
		}
	}
	replaceDevice(id){
		this.originalId = id;  
		this.replaceMsg = 'Are you sure to do replace process? This operation can\'t be undone';
		if(this.mode == 'onTheFly' || this.mode == 'doReplacement')
			this.replaceMsg = 'BATTView ' + this.serialnumber + ', will be deleted from the system and get replaced by ' + this.deviceID + '. Click OK to confirm.';
		this.modal.show();
	}
	replaceBattview(proceed: boolean = false) {
		if(this.mode == 'onTheFly' && !proceed) {  
			this.modal2.show();
		} else {
			this.deviceManagemntService.replaceBattview(this.originalId, this.deviceID, this.mode).subscribe(
				data => {
					if(this.mode == 'onTheFly')
						this.router.navigate(['/process-production-line']);
					else
						this.router.navigate(['/sites-map']);
				}
			)
		}
	}

	replaceCharger() {
		this.deviceManagemntService.replaceCharger(this.originalId, this.deviceID).subscribe(
			result => {
				this.router.navigate(['/sites-map']);
			}
		);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}