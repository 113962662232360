<div class="boxs3">
	<div class="margin-top-lg margin-bottom-lg">
		<div class="row margin-bottom-lg">
			<div class="col-md-2">
				<label>{{'act.event_id' | translate}}:</label>
				<input class="form-control" type="number" name="eventId" [(ngModel)]="eventId"/>
			</div>
			<div class="col-md-2">
				<label>{{'time.from_date' | translate}}:</label>
				<div class="input-group">
					<input class="form-control" placeholder="mm/dd/yyyy" name="startDate" [(ngModel)]="fromDateFmt" bsDatepicker #startDate="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
					<span class="input-group-btn">
						<button class="btn btn-default btn-date" (click)="startDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
					</span>
				</div>
			</div>
			<div class="col-md-2">
				<label>{{'time.to_date' | translate}}:</label>
				<div class="input-group">
					<input class="form-control" placeholder="mm/dd/yyyy" name="endDate" [(ngModel)]="toDateFmt" bsDatepicker #endDate="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
					<span class="input-group-btn">
						<button class="btn btn-default btn-date" (click)="endDate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
					</span>
				</div>
			</div>
			<div class="col-md-2 margin-top-lg">
				<button class="btn btn-md btn-success" (click)="getEventLogs()" [disabled]="!eventId">{{'g.fetch_data' | translate}}</button>
			</div>
			<div class="col-md-4 margin-top-lg">
				<input type="checkbox" name="chargers_record_only" class="form-control" [(ngModel)]="chargers_record_only" (change)="filterData()" />
				<label>{{'act.display_process' | translate}}</label>
			</div>
		</div>
	</div>
</div>


<simple-widget
	[collapsedByDefault]="true"
	*ngFor="let eventObj of eventLogs"
	[title]="eventObj.timestamp | date:'MMM dd, yyyy, HH:mm:ss':'UTC' ">
	<div *ngFor="let innerGroup of getKeys(eventObj.data)" class="margin-bottom-lg">
		<i 
			class="fa fa-lg margin-right-sm text-info"
			[ngClass]="eventObj.data[innerGroup].expanded ? 'fa-minus-square' : 'fa-plus-square'" 
			aria-hidden="true"
			(click)="eventObj.data[innerGroup].expanded = !eventObj.data[innerGroup].expanded"></i>
		<strong>{{innerGroup}}</strong>

		<ng-container *ngIf="eventObj.data[innerGroup].expanded">
			<div *ngIf="innerGroup == 'Generic'" class="row margin-top-sm margin-left">
				<div *ngFor="let key of getKeys(eventObj.data[innerGroup])" class="col-md-3">
					{{key}}: {{eventObj.data[innerGroup][key]}}
				</div>
			</div>
			<div *ngIf="innerGroup != 'Generic'"> 
				<div *ngFor="let item of eventObj.data[innerGroup]" class="margin-top-lg margin-left">
					<i
						class="fa fa-lg margin-right-sm text-info" 
						[ngClass]="item.expanded ? 'fa-minus-square' : 'fa-plus-square'" aria-hidden="true" 
						(click)="item.expanded = !item.expanded"></i>
					<a [routerLink]="['/', customerId, siteId, 'chargers', 'analytics', 'cycles', item.id]">
						{{ item.name }}</a>
						
					<ng-container *ngIf="item.expanded">
						<div class="row margin-top-sm margin-left">
							<div *ngFor="let key of getKeys(item)" class="col-md-3">
								<span *ngIf="key == 'LCD'">
									{{key}}: {{item[key] | json}}
								</span>
								<span *ngIf="key != 'LCD'">
									{{key}}: {{item[key]}}
								</span>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</ng-container>
	</div>
</simple-widget>