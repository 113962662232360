import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as _ from 'underscore';
import { NoUIBlockHttpParams } from '../../../shared/loader/noUIBlock-httpParam';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ActFirstService {
	
	constructor(
		private httpClient: HttpClient,
		private translateService: TranslateService) { 
	}

	getData(token: string = '', siteId: number = 0, hideLoader: boolean= false, publicPageReq: boolean = false) {
		return this.httpClient.post('/actFirst/getData', {token, siteId, publicPageReq}, {
			observe: "body",
			params: new NoUIBlockHttpParams(hideLoader)
		});
	}

	getChargerChangesQueue(id) {
		return this.httpClient.post('/chargers/getChargerChangesQueue', {id}, {
			observe: "body",
			params: new NoUIBlockHttpParams(true)
		});
	}

	getHistoricalLog(siteId, fromDate, toDate, chargerId= 0) {
		return this.httpClient.post('/actFirst/getData', {
			siteId: siteId,
			isHistory: true,
			fromDate: fromDate,
			toDate: toDate,
			chargerId: chargerId
	 });
	}

	getMisPicksHistory(siteId, fromDate, toDate) {
		return this.httpClient.post('/actFirst/getPicks', {
			siteId: siteId,
			fromDate: fromDate,
			toDate: toDate,
			isMissed: true
	 });
	}

	getPicksHistory(siteId, fromDate, toDate) {
		return this.httpClient.post('/actFirst/getPicks', {
			siteId: siteId,
			fromDate: fromDate,
			toDate: toDate
	 });
	}

	getConnectVoltageReport(siteId, date) {
		return this.httpClient.post('/actFirst/getConnectVoltageReport', {siteId, date}, {
			observe: "body"
		});
	}

	getParkedBatteriesReport(siteId, date) {
		return this.httpClient.post('/actFirst/getParkedBatteriesReport', {siteId, date}, {
			observe: "body"
		});
	}

	getReport(siteId, fromDate = null, toDate = null) {
		return this.httpClient.post('/actFirst/getReport', {
			siteId: siteId,
			isMissed: true,
			fromDate,
			toDate
		});
	}

	actFirstManagement(siteId) {
		return this.httpClient.post('/actFirst/management', {siteId});
	}

	updateChargerActfirstRanking(params) {
		return this.httpClient.post('/actFirst/updateChargerActfirstRanking', {
			chargerId: params.chargerId, 
			siteId: params.siteId,
			newValue: params.newValue
		});
	}

	getHourlyReport(siteId, fromDate = null, toDate = null) {
		return this.httpClient.post('/actFirst/getHourlyReport', {
			siteId: siteId,
			isMissed: true,
			fromDate,
			toDate
		});
	}

	getGroupName(key) {
		let text = key;
		switch(key) {
			case "not_connected":
				text = this.translateService.instant('act.battery_not_connected');
			break;
			case "charging":
				text = this.translateService.instant('act.charging');
			break;
			case "in_maintenance":
				text = this.translateService.instant('act.in_maintenance');
			break;
			case "battery_faulted":
				text = this.translateService.instant('act.battery_faulted');
			break;
			case "charger_faulted":
				text = this.translateService.instant('act.charger_faulted');
			break;
			case "user_faulted":
				text = this.translateService.instant('act.user_faulted');
			break;
			case "relaxing":
				text = this.translateService.instant('act.cooling_down');
			break;
			case "relaxed":
				text = this.translateService.instant('act.cooled_down');
			break;
			case "ready":
				text = this.translateService.instant('act.ready_to_pick');
			break;
			case "parked":
				text = this.translateService.instant('act.parked');
			break;
		}
		return text;
	}

  addManualPick(params) {
		return this.httpClient.post('/actFirst/addManualPick', {
			chargerid: params.chargerid, 
			actfirstgroupid: params.actfirstgroupid,
			siteid: params.siteid,
			token: params.token
		});
  }

  preventAlertUnplug(params) {
		return this.httpClient.post('/actFirst/preventAlertUnplug', {
			chargerid: params.chargerid, 
			siteid: params.siteid,
			options: {}
		});
  }
  
  ignorePMMispick(token, siteId, password) {
		return this.httpClient.post('/actFirst/ignorePMMispick', {
			token: token,
			siteId: siteId,
			password: password
		});
  }
  
}