import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'underscore';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';
import { ChartDataService } from '../../../../shared/services/chart-data.service';
import { BarChartConfig } from '../../../../shared/google-chart/Models/BarChartConfig';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { ChartUtil } from '../../../../shared/services/utility/chart.service';
import { SiteDashboardService } from '../../../site-dashboard/site-dashboard.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-intelligent-sites-reports',
	templateUrl: './intelligent-sites-reports.component.html',
	styleUrls: ['./intelligent-sites-reports.component.css']
})
export class IntelligentSitesReportsComponent implements OnInit, OnDestroy {

	options;
	widgetsDropDownLists = {};
	widgetsRadioButtons = {};
	widgetsCheckboxList = {};
	dateOptionsArray;
	customerId;
	alertsData;
	siteAlerts;
	alertsSettings;
	inactiveWidgets;
	customerSites;
	customerSitesIds;

	customersSubscription: Subscription = new Subscription();
	activeDashboardDataSubscription: Subscription = new Subscription();

	results = {
		missed_eqs: {},
		high_temp: {},
		over_under_charge: {},
		potential_weak_cells: {},
		ebu_over_usage: {},
		water_levels: {},
		missed_finish: {},
		total_daily_idle_times: {},
		deep_discharge: {},
		total_daily_missed_connection: {},
		potentially_sulfated: {},
		weekly_missed_eqs: {}
	};

	percentage = {
		missed_eqs              : true,
		high_temp               : true,
		over_under_charge       : true,
		potential_weak_cells    : true,
		ebu_over_usage          : true,
		water_levels            : true,
		missed_finish           : true,
		total_daily_idle_times  : true,
		deep_discharge          : true,
		total_daily_missed_connection: true,
		potentially_sulfated    : true,
		weekly_missed_eqs       : true
	}

	ready = {};
	gridColumns = {};
	gridRows = {};
	chartsDataTables = {};
	chartConfig = {};
	images= {};

	data_view = {
		weekly_missed_eqs: "batt_counts",
		missed_eqs: "batt_counts"
	}
	
	constructor(
		private route: ActivatedRoute,
		private chartDataService: ChartDataService,
		private sideMenuService: SideMenuService,
		private siteDashboardService: SiteDashboardService,
		private commonUtil: CommonUtil,
		private chartUtil: ChartUtil
	) { }

	ngOnInit() {
		this.options =  {
			hasPrint: true,
			hasExpand: false
		};

		this.dateOptionsArray = [
			{
				value: "W",
				label: "Last Week"
			},
			{
				value: 30,
				label: "Last 30 Days"
			},
			{
				value: 90,
				label: "Last 90 Days"
			}
		]
		
		this.customerId = parseInt(this.route.snapshot.params['id']);

		// customer sites IDs 
		this.customersSubscription = this.sideMenuService.customers.subscribe(value => {
			if (!_.isEmpty(value)) {
				let sites = this.sideMenuService.getSitesCustomerMap(this.customerId);
				if (!_.isEmpty(sites)) {
					this.customerSites  = {};
					for (let idx in sites) {
						if (sites[idx]['act_intelligent_type'] != "0") {
							this.customerSites[sites[idx].id] = sites[idx];
						}
					}
					this.customerSitesIds = _.keys(this.customerSites);
					this.getReportsData();
				}
			}
		});
	}


	getReportsData() {

		this.activeDashboardDataSubscription = this.siteDashboardService.activeDashboardData.subscribe(res=> {
			this.alertsData = res;
			this.siteAlerts = res.data;
			this.inactiveWidgets = res.inactiveWidgets;
			this.alertsSettings = res.siteSettings;
			this.generateAllSitesReport();
			this.generateWeeklyMissedEQsReport();
			this.generateHighTemperatureReport();
			this.generateOverUnderChargeReport();
			this.generatePotentialWeakCellsReport();
			this.generateEBUOverUsageReport();
			this.generateWaterLevelsReport();
			this.generateMissedFinishReport();
			this.generateTotalDailyIdleTimesReport();
			this.generateDeepDischargeReport();
			this.generateTotalDailyMissedConnectionReport();
			this.generatePotentiallySulfatedReport();
			this.generateMissedEQsReport();
		 });

		this.siteDashboardService.getDashboardData(this.route.snapshot.params['id']);

	}

	dateChange(name, value) {
		switch (name) {
			case 'all_sites_alerts':
				this.generateAllSitesReport(value);
				break;
			case 'weekly_missed_eqs':
				 this.generateWeeklyMissedEQsReport(value);
				 break;
			case 'high_temp' :
				this.generateHighTemperatureReport(value);
				break;
			case 'over_under_charge':
				this.generateOverUnderChargeReport(value);
				break;
			case 'potential_weak_cells':
				this.generatePotentialWeakCellsReport(value);
				break;
			case 'ebu_over_usage':
				 this.generateEBUOverUsageReport(value);
				 break;
			case 'water_levels':
				this.generateWaterLevelsReport(value);
				break;
			case 'missed_finish':
				this.generateMissedFinishReport(value);
				break;
			case 'total_daily_idle_times':
				this.generateTotalDailyIdleTimesReport(value);
				break;
			case 'deep_discharge':
				this.generateDeepDischargeReport(value);
				break;
			case 'total_daily_missed_connection':
				this.generateTotalDailyMissedConnectionReport(value);
				break;
			case 'missed_eqs':
				this.generateMissedEQsReport(value);
				break;
		}
	}

	radioButtonChange(name, value) {
		this.data_view[name] = value;
		switch (name) {
			case "weekly_missed_eqs":
				this.generateWeeklyMissedEQsReport();
				break;
			case 'missed_eqs':
				this.generateMissedEQsReport();
				break;
		}
	}

	checkboxChange(name, value) {

		this.percentage[name] = value;

		 switch (name) {
			case 'all_sites_alerts':
				this.generateAllSitesReport(value);
				break;
			case 'weekly_missed_eqs':
				 this.generateWeeklyMissedEQsReport(value);
				 break;
			case 'high_temp' :
				this.generateHighTemperatureReport(value);
				break;
			case 'over_under_charge':
				this.generateOverUnderChargeReport(value);
				break;
			case 'potential_weak_cells':
				this.generatePotentialWeakCellsReport(value);
				break;
			case 'ebu_over_usage':
				 this.generateEBUOverUsageReport(value);
				 break;
			case 'water_levels':
				this.generateWaterLevelsReport(value);
				break;
			case 'missed_finish':
				this.generateMissedFinishReport(value);
				break;
			case 'total_daily_idle_times':
				this.generateTotalDailyIdleTimesReport(value);
				break;
			case 'deep_discharge':
				this.generateDeepDischargeReport(value);
				break;
			case 'total_daily_missed_connection':
				this.generateTotalDailyMissedConnectionReport(value);
				break;
			case 'missed_eqs':
				this.generateMissedEQsReport(value);
				break;
		}
	}

	generateAllSitesReport(passedDate?) {
		this.widgetsDropDownLists['all_sites_alerts'] = [{
			name: 'all_sites_alerts',
			options: this.dateOptionsArray,
			selectedValue: "W"
		}];
		this.gridColumns['all_sites_alerts'] = [
			{headerName: "Site", width: 150, field: "site", filter: true},
			{headerName: "Weekly Missed EQs", width: 150, field: "weekly_missed_eqs", filter: false},
			{headerName: "High Temperature", width: 150, field: "high_temp", filter: false},
			{headerName: "Over Under Charge", type: "number", width: 170, field: "over_under_charge", filter: false},
			{headerName: "Ebu Over Usage", type: "number", width: 170, field:"ebu_over_usage", filter: false},
			{headerName: "Potential Weak Cells", type: "number", width: 170, field:"potential_weak_cells", filter: false},
			{headerName: "Water Levels", type: "number", width: 150, field: "water_levels", filter: false},
			{headerName: "Missed Finish", width: 150, field: "missed_finish", filter: false},
			{headerName: "Daily IdleTime", width: 150, field: "total_daily_idle_times", filter: false},
			{headerName: "Deep Discharge", width: 120, field: "deep_discharge", filter: false},
			{headerName: "Daily Missed Connection", width: 170, field: "total_daily_missed_connection", filter: false},
			{headerName: "Potentially Sulfated", width: 150, field: "potentially_sulfated", filter: false},
			{headerName: "Missed Scheduled EQs", width: 180, field: "missed_eqs", filter: false}
		];

		let alertCounts,
			missedEQsAlertCounts = 0,
			date = passedDate? passedDate : 'W';

		let options: any = {
			dateRange: date,
			inactiveWidgets: this.inactiveWidgets
		};
		for (let id in this.siteAlerts) {
			let userSettings = this.alertsSettings[id].userSettings;
			options.charge_events = !userSettings || userSettings.bv_charge_events_only_for_charge_ahr;
			options.inuse_events = !userSettings || userSettings.bv_inuse_events_only_for_charge_ahr;

			let value = this.siteAlerts[id] || [];
			// weekly Missed EQs
			let res1 = this.chartDataService.generateWidgetData(value, "batt_alert_missed_eq_weekly_list", options);
			alertCounts = res1 && res1[1]['list'] ? _.reduce(_.pluck(res1[1]['list'], "value"), function(memo, num) { return memo + num}, 0) : 0; 
			this.results.weekly_missed_eqs[id] = {
				data: res1,
				ok_batt_counts: res1 && res1[0]['list'] ? res1[0]['list'].length : 0,
				not_ok_batt_counts: res1 && res1[1]['list'] ? res1[1]['list'].length : 0,
				alert_counts: alertCounts
			};
			// All sites missed EQs
			let res2 = this.chartDataService.generateWidgetData(value, "batt_alert_missed_eq_list", options);
			missedEQsAlertCounts = res2 && res2[1]['list'] ? _.reduce(_.pluck(res2[1]['list'], "value"), function(memo, num) { return memo + num}, 0) : 0; 
			this.results.missed_eqs[id] = {
				data: res2,
				ok_batt_counts: res2 && res2[0]['list'] ? res2[0]['list'].length : 0,
				not_ok_batt_counts: res2 && res2[1]['list'] ? res2[1]['list'].length : 0,
				alert_counts: missedEQsAlertCounts
			};
			// High Temperature
			let res3 = this.chartDataService.generateWidgetData(value, "batt_alert_high_temp", options);
			this.results.high_temp[id] = {
				data: res3,
				ok_batt_counts: res3 && res3['Pie'] && res3['Pie'][0] ? res3['Pie'][0].ok  : 0,
				not_ok_batt_counts: res3 && res3['Pie'] && res3['Pie'][1] ? res3['Pie'][1].one_or_more : 0,
			};
			// Over Under Charge
			let res4 = this.chartDataService.generateWidgetData(value, "batt_alert_over_under_charge", options);
			this.results.over_under_charge[id] = {
				data: res4,
				under: res4 && res4['Pie'] && res4['Pie'][0] ? res4['Pie'][0].under  : 0,
				over: res4 && res4['Pie'] && res4['Pie'][1] ? res4['Pie'][1].over : 0,
				ok: res4 && res4['Pie'] && res4['Pie'][2] ? res4['Pie'][2].ok : 0
			};
			// Potential Weak Cells
			let res5 = this.chartDataService.generateWidgetData(value, "batt_alert_deep_use", options);
			this.results.potential_weak_cells[id] = {
				data: res5,
				ok_batt_counts: res5 && res5['Pie'] && res5['Pie'][0] ? res5['Pie'][0].ok  : 0,
				not_ok_batt_counts: res5 && res5['Pie'] && res5['Pie'][1] ? res5['Pie'][1].one_or_more : 0,
			};
			// EBU Over Usage
			let res6 = this.chartDataService.generateWidgetData(value, "batt_alert_over_usage", options);
			this.results.ebu_over_usage[id] = {
				data: res6,
				ok_batt_counts: res6 && res6['Pie'] && res6['Pie'][0] ? res6['Pie'][0].ok_count  : 0,
				not_ok_batt_counts: res6 && res6['Pie'] && res6['Pie'][1] ? res6['Pie'][1]['bad_count'] : 0,
			};
			// Water Level
			let res7 = this.chartDataService.generateWidgetData(value, "batt_alert_missed_watering", options);
			this.results.water_levels[id] = {
				data: res7,
				ok_batt_counts: res7 ? res7['good'] : 0,
				not_ok_batt_counts: res7 ? res7['bad'] : 0,
			};
			// Missed Finish
			let res8 = this.chartDataService.generateWidgetData(value, "batt_alert_missed_fi", options);
			this.results.missed_finish[id] = {
				data: res8,
				not_ok_batt_counts: res8 && res8['Pie'] && res8['Pie'][1] ? res8['Pie'][1].one_or_more : 0,
				ok_batt_counts: res8 && res8['Pie'] && res8['Pie'][0] ? res8['Pie'][0].ok : 0,
			};
			// Total Daily Idle Times
			let res9 = this.chartDataService.generateWidgetData(value, "missed_daily_connection", options);
			this.results.total_daily_idle_times[id] = {
				data: res9,
				ok_batt_counts: res9 && res9['list'] ? res9['list'][0].value : 0,
				group1: res9 && res9['list'] ? res9['list'][1].value : 0,
				group2: res9 && res9['list'] ? res9['list'][2].value : 0,
				group3: res9 && res9['list'] ? res9['list'][3].value : 0,
				group4: res9 && res9['list'] ? res9['list'][4].value : 0,
				group5: res9 && res9['list'] ? res9['list'][5].value : 0
			};
			// Deep Discharge
			let res10 = this.chartDataService.generateWidgetData(value, "batt_alert_deep_discharge", options);
			this.results.deep_discharge[id] = {
				data: res10,
				not_ok_batt_counts: res10 && res10['Pie'] && res10['Pie'][1] ? res10['Pie'][1].one_or_more : 0,
				ok_batt_counts: res10 && res10['Pie'] && res10['Pie'][0] ? res10['Pie'][0].ok : 0
			};
			// Total Daily Missed Connection
			let res11 = this.chartDataService.generateWidgetData(value, "missed_connection_plugged_in_alert", options);
			this.results.total_daily_missed_connection[id] = {
				data: res11,
				ok_batt_counts: res11 && res11['list'] ? res11['list'][0].value : 0,
				group1: res11 && res11['list'] ? res11['list'][1].value : 0,
				group2: res11 && res11['list'] ? res11['list'][2].value : 0,
				group3: res11 && res11['list'] ? res11['list'][3].value : 0,
				group4: res11 && res11['list'] ? res11['list'][4].value : 0,
				group5: res11 && res11['list'] ? res11['list'][5].value : 0
			};
			// potentially Sulfated
			let res12 = this.chartDataService.generateWidgetData(value, "sulfated_battery_alerts", options);
			this.results.potentially_sulfated[id] = {
				data: res12,
				not_ok_batt_counts: res12 && res12['Pie'] && res12['Pie'][1] ? res12['Pie'][1].one_or_more : 0,
				ok_batt_counts: res12 && res12['Pie'] && res12['Pie'][0] ? res12['Pie'][0].ok : 0
			};
		}
		let gridItems = [], gridItem = {};
		for (let id of this.customerSitesIds) {
			gridItem = {};
			gridItem["site"] = this.customerSites[id].name;
			gridItem["weekly_missed_eqs"] = this.results.weekly_missed_eqs[id].not_ok_batt_counts;
			gridItem["missed_eqs"] = this.results.missed_eqs[id].not_ok_batt_counts;
			gridItem["high_temp"] = this.results.high_temp[id].not_ok_batt_counts;
			gridItem["over_under_charge"] = this.results.over_under_charge[id].over + this.results.over_under_charge[id].under;
			gridItem["ebu_over_usage"] = this.results.ebu_over_usage[id].not_ok_batt_counts;
			gridItem["potential_weak_cells"] = this.results.potential_weak_cells[id].not_ok_batt_counts;
			gridItem["water_levels"] = this.results.water_levels[id].not_ok_batt_counts;
			gridItem["missed_finish"] = this.results.missed_finish[id].not_ok_batt_counts;
			gridItem["total_daily_idle_times"] = this.results.total_daily_idle_times[id].group1 + this.results.total_daily_idle_times[id].group2 + this.results.total_daily_idle_times[id].group3 + this.results.total_daily_idle_times[id].group4 + this.results.total_daily_idle_times[id].group5;
			gridItem["deep_discharge"] = this.results.deep_discharge[id].not_ok_batt_counts;
			gridItem["total_daily_missed_connection"] = this.results.total_daily_missed_connection[id].group1 + this.results.total_daily_missed_connection[id].group2 + this.results.total_daily_missed_connection[id].group3 + this.results.total_daily_missed_connection[id].group4 + this.results.total_daily_missed_connection[id].group5;
			gridItem["potentially_sulfated"] = this.results.potentially_sulfated[id].not_ok_batt_counts;
			gridItems.push(gridItem);
		}
		this.gridRows['all_sites_alerts'] = gridItems;
		this.ready['all_sites_alerts'] = true;
	}

	generateWeeklyMissedEQsReport(optionalDate?) {
 
		this.widgetsDropDownLists['weekly_missed_eqs'] = [{
			name: 'weekly_missed_eqs',
			options: this.dateOptionsArray,
			selectedValue: 'W'
		}];

		this.widgetsRadioButtons['weekly_missed_eqs'] = [{
			name: 'weekly_missed_eqs',
			options: [ 
				{label: 'Battery counts', value: 'batt_counts'},
				{label: 'Total alerts counts', value: 'alert_counts'}
			],
			selectedValue: 'batt_counts'
		}];

		this.widgetsCheckboxList['weekly_missed_eqs'] = [{
			name: 'weekly_missed_eqs',
			label: 'Show Percentage',
			selectedValue: 1
		}];

		let res,
			alertCounts,
			date = optionalDate ? optionalDate : 'W';
		
		let options: any = {
			dateRange: date,
			inactiveWidgets: this.inactiveWidgets
		};

		for (let id in this.siteAlerts) {
			let value = this.siteAlerts[id] || [];

			let userSettings = this.alertsSettings[id].userSettings;
			options.charge_events = !userSettings || userSettings.bv_charge_events_only_for_charge_ahr;
			options.inuse_events = !userSettings || userSettings.bv_inuse_events_only_for_charge_ahr;
		 
			// weekly Missed EQs
			res = this.chartDataService.generateWidgetData(value, "batt_alert_missed_eq_weekly_list", options);
			alertCounts = res && res[1].list ? _.reduce(_.pluck(res[1].list, "value"), function(memo, num) { return memo + num}, 0) : 0; 
			this.results.weekly_missed_eqs[id] = {
					data                : res,
					ok_batt_counts      : res && res[0].list ? res[0].list.length : 0,
					not_ok_batt_counts  : res && res[1].list ? res[1].list.length : 0,
					alert_counts        : alertCounts
			};
		}

		let weeklyMissedEQsRows = [], weeklyMissedEQsGridItems = [];
		if (this.data_view['weekly_missed_eqs'] == "batt_counts") {
			let weeklyMissedEQsChartColumns = [
					{ type: "string", label: "Site" },
					{ type: "number", label: "Weekly Missed EQs Batteries" },
					{ type: "number", label: "Equalized Batteries" }
			];

			this.gridColumns['weekly_missed_eqs'] =  [
				{headerName: "Site", field: "site", width: 430, filter: true},
				{headerName: "weekly Missed EQs Batteries", type: "number", width: 300, field: "not_ok_batt_counts", filter: false},
				{headerName: "Equalized Batteries", type: "number", width: 300, field: "ok_batt_counts", filter: false}
			];
		 
			for (let key in this.results.weekly_missed_eqs) {
				let obj = this.results.weekly_missed_eqs[key];
				if (this.customerSites[key]) {
					weeklyMissedEQsRows.push([ this.customerSites[key].name, this.results.weekly_missed_eqs[key].not_ok_batt_counts, this.results.weekly_missed_eqs[key].ok_batt_counts ]);
					weeklyMissedEQsGridItems.push({ site: this.customerSites[key].name, not_ok_batt_counts: this.results.weekly_missed_eqs[key].not_ok_batt_counts, ok_batt_counts: this.results.weekly_missed_eqs[key].ok_batt_counts });
				}
			}

			let options = { colors : [ "#941e51", "#1ebb2f"] };
			if (this.percentage.weekly_missed_eqs) {
				options['isStacked'] = 'percent';
			}
			this.ready['weekly_missed_eqs'] = true;
			this.setChart("weekly_missed_eqs", weeklyMissedEQsChartColumns, weeklyMissedEQsRows, options);

		} else if (this.data_view['weekly_missed_eqs'] == "alert_counts") {
			
			let weeklyMissedEQsChartColumns = [
				{type: "string", label: "Site"},
				{type: "number", label: "Weekly Missed EQs Alerts Count"}
			];

			this.gridColumns['weekly_missed_eqs'] = [ 
				{headerName: "Site", field: "site", filter: true, width:500},
				{headerName: "Weekly Missed EQs Alerts", type:"number", field: "alert_counts", filter: false, width:500},
			];

			for (let key in this.results.weekly_missed_eqs) {
				let obj = this.results.weekly_missed_eqs[key];
				if (this.customerSites[key]) {
					weeklyMissedEQsRows.push([this.customerSites[key].name, this.results.weekly_missed_eqs[key].alert_counts]);
					weeklyMissedEQsGridItems.push({ site: this.customerSites[key].name, alert_counts: this.results.weekly_missed_eqs[key].alert_counts });
				}
			}
			let options = { colors : ["#941e51"]};   
			this.ready['weekly_missed_eqs'] = true;
			this.setChart("weekly_missed_eqs", weeklyMissedEQsChartColumns, weeklyMissedEQsRows, options);
		}
	 
		this.gridRows['weekly_missed_eqs'] = weeklyMissedEQsGridItems;
	}

	generateHighTemperatureReport(optionalDate?) {
		this.ready['high_temp'] = false;

		this.widgetsDropDownLists['high_temp'] = [{
			name: 'high_temp',
			options: this.dateOptionsArray,
			selectedValue: 'W'
		}];

		this.widgetsCheckboxList['high_temp'] = [{
			name: 'high_temp',
			label: 'Show Percentage',
			selectedValue: 1
		}];

		let res,
			date = optionalDate ? optionalDate : 'W';

		let options: any = {
			dateRange: date,
			inactiveWidgets: this.inactiveWidgets
		};

		for (let id in this.siteAlerts) {
			let userSettings = this.alertsSettings[id].userSettings;
			options.charge_events = !userSettings || userSettings.bv_charge_events_only_for_charge_ahr;
			options.inuse_events = !userSettings || userSettings.bv_inuse_events_only_for_charge_ahr;
			let value = this.siteAlerts[id] || [];
			// High Temperature
			res = this.chartDataService.generateWidgetData(value, "batt_alert_high_temp", options);
			this.results.high_temp[id] = {
				data                    : res,
				ok_batt_counts          : res && res.Pie && res.Pie[0] ? res.Pie[0].ok  : 0,
				not_ok_batt_counts      : res && res.Pie && res.Pie[1] ? res.Pie[1].one_or_more : 0,
			};
		}

		this.gridColumns['high_temp'] =  [
			{headerName: "Site", field: "site", filter: true, width:430},
			{headerName: "Batteries With High Temp", type:"number", field: "not_ok_batt_counts", filter: false, width:300},
			{headerName: "Batteries Within Temp Limit", type:"number", field: "ok_batt_counts", filter: false, width:300}
		];
			
		let highTempChartColumns = [
			{
				type: "string",
				label: "Site"
			},
			{
				type: "number",
				label: "High Temperature Batteries Count"
			},
			{
				type: "number",
				label: "Batteries count within Temperature Limit"
			}
		];
			
		let highTempRows = [];
		let highTempGridItems = [];
		for (let key in this.results['high_temp']) {
			let obj = this.results['high_temp'][key];
		 
			if (this.customerSites[key]) {

				highTempRows.push([
					this.customerSites[key].name,// site name
					this.results.high_temp[key].not_ok_batt_counts,
					this.results.high_temp[key].ok_batt_counts
				]);
				highTempGridItems.push({
					site: this.customerSites[key].name,
					not_ok_batt_counts: this.results.high_temp[key].not_ok_batt_counts,
					ok_batt_counts: this.results.high_temp[key].ok_batt_counts
				});

			}
		}

		let highTemOptions = {
			colors : ["#C70039","#41ca6a"]
		};

		if (this.percentage.high_temp) {
			highTemOptions['isStacked'] = 'percent';
		}

		this.gridRows['high_temp'] = highTempGridItems;
		this.ready['high_temp'] = true;
		this.setChart("high_temp", highTempChartColumns, highTempRows, highTemOptions);

	}

	generateOverUnderChargeReport(optionalDate?) {
		this.ready['over_under_charge'] = false;
		 
		this.widgetsDropDownLists['over_under_charge'] = [{
			name: 'over_under_charge',
			options: this.dateOptionsArray,
			selectedValue: 'W'
		}];

		this.widgetsCheckboxList['over_under_charge'] = [{
			name: 'over_under_charge',
			label: 'Show Percentage',
			selectedValue: 1
		}];

		let res,
			date = optionalDate ? optionalDate : 'W';

		let options: any = {
			dateRange: date,
			inactiveWidgets: this.inactiveWidgets
		};
			
		for (let id in this.siteAlerts) {
			let userSettings = this.alertsSettings[id].userSettings;
			options.charge_events = !userSettings || userSettings.bv_charge_events_only_for_charge_ahr;
			options.inuse_events = !userSettings || userSettings.bv_inuse_events_only_for_charge_ahr;
			let value = this.siteAlerts[id] || [];
		
			// Over Under Charge
			res = this.chartDataService.generateWidgetData(value, "batt_alert_over_under_charge", options);
			this.results.over_under_charge[id] = {
					data    : res,
					under   : res && res.Pie && res.Pie[0] ? res.Pie[0].under  : 0,
					over    : res && res.Pie && res.Pie[1] ? res.Pie[1].over : 0,
					ok      : res && res.Pie && res.Pie[2] ? res.Pie[2].ok : 0
			};
		}

		this.gridColumns['over_under_charge'] =  [
			{headerName: "Site", field: "site", filter: true, width:300},
			{headerName: "Batteries Over Charged", type:"number", field: "over", filter: false, width:220},
			{headerName: "Batteries Under Charged", type:"number", field: "under", filter: false, width:220},
			{headerName: "Batteries Okay Charged", type:"number", field: "ok", filter: false, width:220}
		];

		let overUnderChargeChartColumns = [
			{
				type: "string",
				label: "Site"
			},
			{
				type: "number",
				label: "Over Charge Batteries Count"
			},
			{
				type: "number",
				label: "Under Charge Batteries Count"
			},
			{
				type: "number",
				label: "Okay Batteries Count"
			}
		];
		let overUnderChargeRows = [];
		let overUnderChargeGridItems = [];
 
		for (let key in this.results.over_under_charge) {

			let obj = this.results.over_under_charge[key];
				 
			if (this.customerSites[key]) {
				overUnderChargeRows.push([
					this.customerSites[key].name,// site name
					this.results.over_under_charge[key].over,
					this.results.over_under_charge[key].under,
					this.results.over_under_charge[key].ok
				]);

				overUnderChargeGridItems.push({
					site    : this.customerSites[key].name,
					over    : this.results.over_under_charge[key].over,
					under   : this.results.over_under_charge[key].under,
					ok      : this.results.over_under_charge[key].ok
				});
			}
		}

		let overUnderChargeOptions = {
			colors : ["#C70039", "#c78700", "#41ca6a"]
		};
		
		if (this.percentage.over_under_charge) {
			overUnderChargeOptions['isStacked'] = 'percent';
		}

		this.gridRows['over_under_charge'] = overUnderChargeGridItems;
		this.ready['over_under_charge'] = true;

		this.setChart("over_under_charge", overUnderChargeChartColumns, overUnderChargeRows, overUnderChargeOptions);
	}

	generatePotentialWeakCellsReport(optionalDate?) {
		this.ready['potential_weak_cells'] = false;
		
		this.widgetsDropDownLists['potential_weak_cells'] = [{
			name: 'potential_weak_cells',
			options: this.dateOptionsArray,
			selectedValue: 'W'
		}];

		this.widgetsCheckboxList['potential_weak_cells'] = [{
			name: 'potential_weak_cells',
			label: 'Show Percentage',
			selectedValue: 1
		}];

		let res,
			date = optionalDate ? optionalDate : 'W';

		let options: any = {
			dateRange: date,
			inactiveWidgets: this.inactiveWidgets
		};

		for (let id in this.siteAlerts) {
			let userSettings = this.alertsSettings[id].userSettings;
			options.charge_events = !userSettings || userSettings.bv_charge_events_only_for_charge_ahr;
			options.inuse_events = !userSettings || userSettings.bv_inuse_events_only_for_charge_ahr;

			let value = this.siteAlerts[id] || [];
			// Potential Weak Cells
			res = this.chartDataService.generateWidgetData(value, "batt_alert_deep_use", options);
			this.results.potential_weak_cells[id] = {
				data                : res,
				ok_batt_counts      : res && res.Pie && res.Pie[0] ? res.Pie[0].ok  : 0,
				not_ok_batt_counts  : res && res.Pie && res.Pie[1] ? res.Pie[1].one_or_more : 0,
			};
		}

		this.gridColumns['potential_weak_cells'] =  [
			{headerName: "Site", field: "site", filter: true, width:430},
			{headerName: "PotentialWeakCellsBatteries", type:"number", field: "not_ok_batt_counts", filter: false, width:300},
			{headerName: "OkayBatteriesCells", type:"number", field: "ok_batt_counts", filter: false, width:300},
		];

		let potentialWeakCellsChartColumns = [
			{
				type: "string",
				label: "Site"
			},
			{
				type: "number",
				label: "Potential Weak Cells Batteries Count"
			},
			{
				type: "number",
				label: "Okay Cells Batteries Count"
			}
		];
		let potentialWeakCellsRows = [];
		let potentialWeakCellsGridItems = [];
 
		for (let key in this.results.potential_weak_cells) {
			let obj = this.results.potential_weak_cells[key];

			if (this.customerSites[key]) {
				potentialWeakCellsRows.push([
					this.customerSites[key].name,// site name
					obj.not_ok_batt_counts,
					obj.ok_batt_counts,
				]);
				potentialWeakCellsGridItems.push({
					site                : this.customerSites[key].name,
					not_ok_batt_counts  : obj.not_ok_batt_counts,
					ok_batt_counts      : obj.ok_batt_counts
				});
			}
		
		}

		this.gridRows['potential_weak_cells'] = potentialWeakCellsGridItems;

		let pwcOptions = {
			colors : ["#C70039", "#41ca6a"]
		};
		
		if (this.percentage.potential_weak_cells) {
			pwcOptions['isStacked'] = 'percent';
		}

		this.ready['potential_weak_cells'] = true;
		this.setChart("potential_weak_cells", potentialWeakCellsChartColumns, potentialWeakCellsRows, pwcOptions);
	}

	generateEBUOverUsageReport(optionalDate?) {
		let widgetKey = 'ebu_over_usage';
		this.ready[widgetKey] = false;

		this.widgetsDropDownLists[widgetKey] = [{
			name: widgetKey,
			options: this.dateOptionsArray,
			selectedValue: 'W'
		}];

		this.widgetsCheckboxList[widgetKey] = [{
			name: widgetKey,
			label: 'Show Percentage',
			selectedValue: 1
		}];
		 
		let res,
			date = optionalDate ? optionalDate : 'W';

		let options: any = {
			dateRange: date,
			inactiveWidgets: this.inactiveWidgets
		};

		for (let id in this.siteAlerts) {
			let userSettings = this.alertsSettings[id].userSettings;
			options.charge_events = !userSettings || userSettings.bv_charge_events_only_for_charge_ahr;
			options.inuse_events = !userSettings || userSettings.bv_inuse_events_only_for_charge_ahr;
			let value = this.siteAlerts[id] || [];
			// EBU Over Usage
			res = this.chartDataService.generateWidgetData(value, "batt_alert_over_usage",  options);

			this.results[widgetKey][id] = {
				data                : res,
				ok_batt_counts      : res && res.Pie && res.Pie[0] ? res.Pie[0].ok_count  : 0,
				not_ok_batt_counts  : res && res.Pie && res.Pie[1] ? res.Pie[1].bad_count : 0,
			};
		}

		let EBUOverUsageChartColumns = [
			{
				type: "string",
				label: "Site"
			},
			{
				type: "number",
				label: "EBU Over Usage Batteries Count"
			},
			{
				type: "number",
				label: "Okay EBU Usage Batteries Count"
			}
		];

		let EBUOverUsageRows = [];
		let EBUOverUsageGridItems = [];
 
		for (let key in this.results[widgetKey]) {
				 
			if (this.customerSites[key]) {
				EBUOverUsageRows.push([
					this.customerSites[key].name,// site name
					this.results.ebu_over_usage[key].not_ok_batt_counts,
					this.results.ebu_over_usage[key].ok_batt_counts,
				]);
				EBUOverUsageGridItems.push({
					site                : this.customerSites[key].name,
					not_ok_batt_counts  : this.results.ebu_over_usage[key].not_ok_batt_counts,
					ok_batt_counts      : this.results.ebu_over_usage[key].ok_batt_counts
				});
			}
		}
		
		this.gridColumns[widgetKey] =  [
			{ headerName: "Site", field: "site", filter: true, width:430 },
			{ headerName: "EBU Over Usage Batteries", type:"number", field: "not_ok_batt_counts", filter: false, width:300},
			{ headerName: "EBU Okay Usage Batteries", type:"number", field: "ok_batt_counts", filter: false, width:300},
		];

		this.gridRows[widgetKey] = EBUOverUsageGridItems;
		
		let EBUoptions = {
			colors : ["#C70039", "#41ca6a"]
		};

		if (this.percentage[widgetKey]) {
			EBUoptions['isStacked'] = 'percent';
		}

		this.ready[widgetKey] = true;
	 
		this.setChart(widgetKey, EBUOverUsageChartColumns, EBUOverUsageRows, EBUoptions);
	}

	generateWaterLevelsReport(optionalDate?) {
		let widgetKey = 'water_levels';
		this.ready[widgetKey] = false;

		this.widgetsDropDownLists[widgetKey] = [{
			name: widgetKey,
			options: this.dateOptionsArray,
			selectedValue: 'W'
		}];

		this.widgetsCheckboxList[widgetKey] = [{
			name: widgetKey,
			label: 'Show Percentage',
			selectedValue: 1
		}];

		let res,
			date = optionalDate ? optionalDate : 'W';
		
		let options: any = {
			dateRange: date,
			inactiveWidgets: this.inactiveWidgets
		};
		
		for (let id in this.siteAlerts) {
			let userSettings = this.alertsSettings[id].userSettings;
			options.charge_events = !userSettings || userSettings.bv_charge_events_only_for_charge_ahr;
			options.inuse_events = !userSettings || userSettings.bv_inuse_events_only_for_charge_ahr;

			let value = this.siteAlerts[id] || [];
			// Water Level
			res = this.chartDataService.generateWidgetData(value, "batt_alert_missed_watering", options);
			this.results[widgetKey][id] = {
				data                : res,
				ok_batt_counts      : res ? res.good : 0,
				not_ok_batt_counts  : res ? res.bad : 0,
			};
		}

		let waterLevelChartColumns = [
			{
				type: "string",
				label: "Site"
			},
			{
				type: "number",
				label: "Low Water Levels Batteries Count"
			},
			{
				type: "number",
				label: "Okay Water Levels Batteries Count"
			}
		];

		let waterLevelRows = [];
		let waterLevelGridItems = [];
 
		for (let key in this.results[widgetKey]) {
			if (this.customerSites[key]) {
				waterLevelRows.push([
					this.customerSites[key].name,// site name
					this.results.water_levels[key].not_ok_batt_counts,
					this.results.water_levels[key].ok_batt_counts,
				]);
				waterLevelGridItems.push({
					site                :  this.customerSites[key].name,
					not_ok_batt_counts  :  this.results.water_levels[key].not_ok_batt_counts,
					ok_batt_counts      :  this.results.water_levels[key].ok_batt_counts
				});
			}
		}

		this.gridColumns[widgetKey] =  [
			{headerName: "Site", field: "site", filter: true, width:430},
			{headerName: "Low Water Levels Batteries", type:"number", field: "not_ok_batt_counts", filter: false, width:300},
			{headerName: "Okay Water Levels Batteries", type:"number", field: "ok_batt_counts", filter: false, width:300},
		];

		this.gridRows[widgetKey] = waterLevelGridItems;

		let waterOptions = {
			colors : ["#C70039", "#41ca6a"]
		};

		if (this.percentage[widgetKey]) {
			waterOptions['isStacked'] = 'percent';
		}

		this.ready[widgetKey] = true;

		this.setChart(widgetKey, waterLevelChartColumns, waterLevelRows, waterOptions);
	}

	generateMissedFinishReport(optionalDate?) {
		let widgetKey = 'missed_finish';

		this.ready[widgetKey] = false;

		this.widgetsDropDownLists[widgetKey] = [{
			name: widgetKey,
			options: this.dateOptionsArray,
			selectedValue: 'W'
		}];

		this.widgetsCheckboxList[widgetKey] = [{
			name: widgetKey,
			label: 'Show Percentage',
			selectedValue: 1
		}];

		let res,
			date = optionalDate ? optionalDate : 'W';
		
		let options: any = {
			dateRange: date,
			inactiveWidgets: this.inactiveWidgets
		};
		
		for (let id in this.siteAlerts) {

			let userSettings = this.alertsSettings[id].userSettings;
			options.charge_events = !userSettings || userSettings.bv_charge_events_only_for_charge_ahr;
			options.inuse_events = !userSettings || userSettings.bv_inuse_events_only_for_charge_ahr;

			let value = this.siteAlerts[id] || [];
			// Missed Finish
			res = this.chartDataService.generateWidgetData(value, "batt_alert_missed_fi", options);
			
			this.results[widgetKey][id] = {
				data                : res,
				not_ok_batt_counts  : res && res.Pie && res.Pie[1] ? res.Pie[1].one_or_more : 0,
				ok_batt_counts      : res && res.Pie && res.Pie[0] ? res.Pie[0].ok : 0,
			};
		}

		let missedFinishChartColumns = [
			{
				type: "string",
				label: "Site"
			},
			{
				type: "number",
				label: "Missed Finish Batteries Count"
			},
			{
				type: "number",
				label: "Okay Batteries Count"
			}
		];
		let missedFinishRows = [];
		let missedFinishGridItems = [];
 
		for (let key in this.results[widgetKey]) {
			 if (this.customerSites[key]) {
				missedFinishRows.push([
					this.customerSites[key].name,// site name
					this.results.missed_finish[key].not_ok_batt_counts,
					this.results.missed_finish[key].ok_batt_counts,
				]);
				missedFinishGridItems.push({
					site                : this.customerSites[key].name,
					not_ok_batt_counts  : this.results.missed_finish[key].not_ok_batt_counts,
					ok_batt_counts      : this.results.missed_finish[key].ok_batt_counts
				});
			}
		}

		this.gridColumns[widgetKey] =  [
			{headerName: "Site", field: "site", filter: true, width:430},
			{headerName: "Missed Finish Batteries", type:"number", field: "not_ok_batt_counts", filter: false, width:300},
			{headerName: "Okay Batteries", type:"number", field: "ok_batt_counts", filter: false, width:300},
		];

		this.gridRows[widgetKey] = missedFinishGridItems;

		let MFoptions = {
			colors : ["#C70039", "#41ca6a"]
		};
		if (this.percentage[widgetKey]) {
			MFoptions['isStacked'] = 'percent';
		}
		this.ready[widgetKey] = true;
	 
		this.setChart(widgetKey, missedFinishChartColumns, missedFinishRows, MFoptions);
	}

	generateTotalDailyIdleTimesReport(optionalDate?) {
		let widgetKey = 'total_daily_idle_times';
		
		this.ready[widgetKey] = false;

		this.widgetsDropDownLists[widgetKey] = [{
			name: widgetKey,
			options: this.dateOptionsArray,
			selectedValue: 'W'
		}];

		this.widgetsCheckboxList[widgetKey] = [{
			name: widgetKey,
			label: 'Show Percentage',
			selectedValue: 1
		}];

		let res,
			date = optionalDate ? optionalDate : 'W';
	
		let options: any = {
			dateRange: date,
			inactiveWidgets: this.inactiveWidgets};

		for (let id in this.siteAlerts) {

			let userSettings = this.alertsSettings[id].userSettings;
			options.charge_events = !userSettings || userSettings.bv_charge_events_only_for_charge_ahr;
			options.inuse_events = !userSettings || userSettings.bv_inuse_events_only_for_charge_ahr;

			let value = this.siteAlerts[id] || [];
			// Total Daily Idle Times
			res = this.chartDataService.generateWidgetData(value, "missed_daily_connection", options);
			
			this.results[widgetKey][id] = {
				data            : res,
				ok_batt_counts  : res && res.list ? res.list[0].value : 0,
				group1          : res && res.list ? res.list[1].value : 0,
				group2          : res && res.list ? res.list[2].value : 0,
				group3          : res && res.list ? res.list[3].value : 0,
				group4          : res && res.list ? res.list[4].value : 0,
				group5          : res && res.list ? res.list[5].value : 0
			}
		}

		let totalDailyIdleTimesChartColumns = [
			{
				type: "string",
				label: "Site"
			},
			{
				type: "number",
				label: "No Daily Idle Times"
			},
			{
				type: "number",
				label: "At Least 30 mins"
			},
			{
				type: "number",
				label: "At Least 1:00 hour"
			},
			{
				type: "number",
				label: "At Least 1:30 hours"
			},
			{
				type: "number",
				label: "At Least 2:00 hours"
			},
			{
				type: "number",
				label: "> 2:00 hours"
			}
		];

		let totalDailyIdleTimesRows = [];
		let totalDailyIdleTimesGridItems = [];
 
		for (let key in this.results[widgetKey]) {

			let obj = this.results[widgetKey][key];
				 
			if (this.customerSites[key]) {
				totalDailyIdleTimesRows.push([
					this.customerSites[key].name,// site name
					this.results.total_daily_idle_times[key].ok_batt_counts,
					this.results.total_daily_idle_times[key].group1,
					this.results.total_daily_idle_times[key].group2,
					this.results.total_daily_idle_times[key].group3,
					this.results.total_daily_idle_times[key].group4,
					this.results.total_daily_idle_times[key].group5
				]);
				totalDailyIdleTimesGridItems.push({
					site                :   this.customerSites[key].name,
					ok_batt_counts      :   this.results.total_daily_idle_times[key].ok_batt_counts,
					group_1_batt_counts :   this.results.total_daily_idle_times[key].group1,
					group_2_batt_counts :   this.results.total_daily_idle_times[key].group2,
					group_3_batt_counts :   this.results.total_daily_idle_times[key].group3,
					group_4_batt_counts :   this.results.total_daily_idle_times[key].group4,
					group_5_batt_counts :   this.results.total_daily_idle_times[key].group5
				});
			}
		}

		this.gridColumns[widgetKey] =  [
			{headerName: "Site", field: "site", filter: true},
			{headerName: "No Daily Idle Times Batteries", type:"number", field: "ok_batt_counts", filter: false},
			{headerName: "At Least 30 mins", type:"number", field: "group_1_batt_counts", filter: false},
			{headerName: "At Least 1:00 hour", type:"number", field: "group_2_batt_counts", filter: false},
			{headerName: "At Least 1:30 hours", type:"number", field: "group_3_batt_counts", filter: false},
			{headerName: "At Least 2:00 hours", type:"number", field: "group_4_batt_counts", filter: false},
			{headerName: "> 2:00 hours", type:"number", field: "group_5_batt_counts", filter: false}
		];

		this.gridRows[widgetKey] = totalDailyIdleTimesGridItems;

		let options2 = {
			colors : ["#41ca6a", "#C70039", "#c78700","#FFFF00", "#3c00ff" , "998eba"]
		};
		if (this.percentage[widgetKey]) {
			options2['isStacked'] = 'percent';
		}
		this.ready[widgetKey] = true;
		this.setChart(widgetKey, totalDailyIdleTimesChartColumns, totalDailyIdleTimesRows, options2);
	}
		
	generateDeepDischargeReport(optionalDate?) {
		let widgetKey = 'deep_discharge';
		this.ready[widgetKey] = false;

		this.widgetsDropDownLists[widgetKey] = [{
			name: widgetKey,
			options: this.dateOptionsArray,
			selectedValue: 'W'
		}];

		this.widgetsCheckboxList[widgetKey] = [{
			name: widgetKey,
			label: 'Show Percentage',
			selectedValue: 1
		}];

		let res,
			date = optionalDate ? optionalDate : 'W';
		
		let options: any = {
			dateRange: date,
			inactiveWidgets: this.inactiveWidgets
		};

		for (let id in this.siteAlerts) {
			let userSettings = this.alertsSettings[id].userSettings;
			options.charge_events = !userSettings || userSettings.bv_charge_events_only_for_charge_ahr;
			options.inuse_events = !userSettings || userSettings.bv_inuse_events_only_for_charge_ahr;

			let value = this.siteAlerts[id] || [];

			// Deep Discharge
			res = this.chartDataService.generateWidgetData(value, "batt_alert_deep_discharge", options);
			this.results[widgetKey][id] = {
				data                : res,
				not_ok_batt_counts  : res && res.Pie && res.Pie[1] ? res.Pie[1].one_or_more : 0,
				ok_batt_counts      : res && res.Pie && res.Pie[0] ? res.Pie[0].ok : 0
			};
		}

		let deepDischargeChartColumns = [
			{
				type: "string",
				label: "Site"
			},
			{
				type: "number",
				label: "Deep Discharge Batteries"
			},
			{
				type: "number",
				label: "Okay Batteries"
			}
		];
		
		let deepDischargeRows = [];
		let deepDischargeGridItems = [];
 
		for (let key in this.results[widgetKey]) {
			let obj = this.results[widgetKey][key];
				
			if (this.customerSites[key]) {
				deepDischargeRows.push([
					this.customerSites[key].name,// site name
					obj.not_ok_batt_counts,
					obj.ok_batt_counts
				]);
				deepDischargeGridItems.push({
					site                :   this.customerSites[key].name,
					not_ok_batt_counts  :   obj.not_ok_batt_counts,
					ok_batt_counts      :   obj.ok_batt_counts
				});
			}
		}

		this.gridColumns[widgetKey] =  [
			{headerName: "Site", field: "site", filter: true, width: 430},
			{headerName: "Deep Discharge Batteries", type:"number", field: "not_ok_batt_counts", filter: false, width:300},
			{headerName: "Okay Batteries", type:"number", field: "ok_batt_counts", filter: false, width:300}
		];

		this.gridRows[widgetKey] = deepDischargeGridItems;
		
		let options2 = {
			colors : ["#C70039", "#41ca6a"]
		};
		
		if (this.percentage[widgetKey]) {
			options2['isStacked'] = 'percent';
		}

		this.ready[widgetKey] = true;

		this.setChart(widgetKey, deepDischargeChartColumns, deepDischargeRows, options2);
	}

	generateTotalDailyMissedConnectionReport(optionalDate?) {
		let widgetKey = 'total_daily_missed_connection';
		this.ready[widgetKey] = false;

		this.widgetsDropDownLists[widgetKey] = [{
			name: widgetKey,
			options: this.dateOptionsArray,
			selectedValue: 'W'
		}];

		this.widgetsCheckboxList[widgetKey] = [{
			name: widgetKey,
			label: 'Show Percentage',
			selectedValue: 1
		}];

		let res,
			date = optionalDate ? optionalDate : 'W';

		let options: any = {
			dateRange: date,
			inactiveWidgets: this.inactiveWidgets
		};

		for (let id in this.siteAlerts) {
			let userSettings = this.alertsSettings[id].userSettings;
			options.charge_events = !userSettings || userSettings.bv_charge_events_only_for_charge_ahr;
			options.inuse_events = !userSettings || userSettings.bv_inuse_events_only_for_charge_ahr;

			let value = this.siteAlerts[id] || [];
			// Total Daily Missed Connection
			res = this.chartDataService.generateWidgetData(value, "missed_connection_plugged_in_alert",options);
			this.results[widgetKey][id] = {
				data                : res,
				ok_batt_counts  : res && res.list ? res.list[0].value : 0,
				group1          : res && res.list ? res.list[1].value : 0,
				group2          : res && res.list ? res.list[2].value : 0,
				group3          : res && res.list ? res.list[3].value : 0,
				group4          : res && res.list ? res.list[4].value : 0,
				group5          : res && res.list ? res.list[5].value : 0
			};
		}

		let totalDailyMissedConnectionChartColumns = [
			{
				type: "string",
				label: "Site"
			},
			{
				type: "number",
				label: "No Missed Connection"
			},
			{
				type: "number",
				label: "At Least 30 mins"
			},
			{
				type: "number",
				label: "At Least 1:00 hour"
			},
			{
				type: "number",
				label: "At Least 1:30 hours"
			},
			{
				type: "number",
				label: "At Least 2:00 hours"
			},
			{
				type: "number",
				label: "> 2:00 hours"
			}
		];

		let totalDailyMissedConnectionRows = [];
		let totalDailyMissedConnectionGridItems = [];

		for (let key in this.results[widgetKey]) {
			
			let obj = this.results[widgetKey][key];
			
			if (this.customerSites[key]) {

				totalDailyMissedConnectionRows.push([
					this.customerSites[key].name,// site name
					obj.ok_batt_counts,
					obj.group1,
					obj.group2,
					obj.group3,
					obj.group4,
					obj.group5
				]);
				totalDailyMissedConnectionGridItems.push({
					site                :   this.customerSites[key].name,
					ok_batt_counts      :   obj.ok_batt_counts,
					group_1_batt_counts :   obj.group1,
					group_2_batt_counts :   obj.group2,
					group_3_batt_counts :   obj.group3,
					group_4_batt_counts :   obj.group4,
					group_5_batt_counts :   obj.group5
				});
			}
		}

		this.gridColumns[widgetKey] =  [
			{headerName: "Site", field: "site", filter: true},
			{headerName: "No Daily Missed Connection Batteries", type:"number", field: "ok_batt_counts", filter: false},
			{headerName: "At Least 30 mins", type:"number", field: "group_1_batt_counts", filter: false},
			{headerName: "At Least 1:00 hour", type:"number", field: "group_2_batt_counts", filter: false},
			{headerName: "At Least 1:30 hours", type:"number", field: "group_3_batt_counts", filter: false},
			{headerName: "At Least 2:00 hours", type:"number", field: "group_4_batt_counts", filter: false},
			{headerName: "> 2:00 hours", type:"number", field: "group_5_batt_counts", filter: false}
		];
		this.gridRows[widgetKey] = totalDailyMissedConnectionGridItems;

		let options2 = {
			colors : ["#41ca6a", "#C70039", "#c78700","#FFFF00", "#3c00ff" , "998eba"]
		};
		if (this.percentage[widgetKey]) {
			options2['isStacked'] = 'percent';
		}
		this.ready[widgetKey] = true;
		this.setChart(widgetKey, totalDailyMissedConnectionChartColumns, totalDailyMissedConnectionRows, options2);
	}

	generatePotentiallySulfatedReport(optionalDate?) {
			
		let widgetKey = 'potentially_sulfated';

		this.ready[widgetKey] = false;

		this.widgetsDropDownLists[widgetKey] = [{
			name: widgetKey,
			options: this.dateOptionsArray,
			selectedValue: 'W'
		}];

		this.widgetsCheckboxList[widgetKey] = [{
			name: widgetKey,
			label: 'Show Percentage',
			selectedValue: 1
		}];

		let res,
			date = optionalDate ? optionalDate : 'W';
			
		let options: any = {
			dateRange: date,
			inactiveWidgets: this.inactiveWidgets
		};

		for (let id in this.siteAlerts) {
			let userSettings = this.alertsSettings[id].userSettings;
			options.charge_events = !userSettings || userSettings.bv_charge_events_only_for_charge_ahr;
			options.inuse_events = !userSettings || userSettings.bv_inuse_events_only_for_charge_ahr;

			let value = this.siteAlerts[id] || [];
			 
			// potentially Sulfated
			res = this.chartDataService.generateWidgetData(value, "sulfated_battery_alerts", options);
			this.results[widgetKey][id] = {
				data                : res,
				not_ok_batt_counts  : res && res.Pie && res.Pie[1] ? res.Pie[1].one_or_more : 0,
				ok_batt_counts      : res && res.Pie && res.Pie[0] ? res.Pie[0].ok : 0
			};
		}

		let potentiallySulfatedChartColumns = [
			{
				type: "string",
				label: "Site"
			},
			{
				type: "number",
				label: "Potentially Sulfated Batteries"
			},
			{
				type: "number",
				label: "Okay Batteries"
			}
		];
		
		let potentiallySulfatedRows = [];
		let potentiallySulfatedGridItems = [];
 
		for (let key in this.results[widgetKey]) {
			let obj = this.results[widgetKey][key];
			
			if (this. customerSites[key]) {    
				potentiallySulfatedRows.push([
					this.customerSites[key].name,// site name
					obj.not_ok_batt_counts,
					obj.ok_batt_counts,
				]);
				potentiallySulfatedGridItems.push({
					site                :   this.customerSites[key].name,
					not_ok_batt_counts  :   obj.not_ok_batt_counts,
					ok_batt_counts      :   obj.ok_batt_counts
				});
			}
		}

		this.gridColumns[widgetKey] =  [
			{headerName: "Site", field: "site", filter: true, width:430},
			{headerName: "Potentially Sulfated Batteries", type:"number", field: "not_ok_batt_counts", filter: false, width:300},
			{headerName: "Okay Batteries", type:"number", field: "ok_batt_counts", filter: false, width:300}
		];

		this.gridRows[widgetKey] = potentiallySulfatedGridItems;

		let options2 = {
			colors : ["#C70039", "#41ca6a"]
		};

		if (this.percentage[widgetKey]) {
			options2['isStacked'] = 'percent';
		}

		this.ready[widgetKey] = true;
		this.setChart(widgetKey, potentiallySulfatedChartColumns, potentiallySulfatedRows, options2);
	}

	generateMissedEQsReport(optionalDate?) {

		let widgetKey = 'missed_eqs';
		this.widgetsDropDownLists[widgetKey] = [{
			name: widgetKey,
			options: this.dateOptionsArray,
			selectedValue: 'W'
		}];

		this.widgetsRadioButtons[widgetKey] = [{
			name: widgetKey,
			options: [ 
				{label: 'Battery counts', value: 'batt_counts'},
				{label: 'Total alerts counts', value: 'alert_counts'}
			],
			selectedValue: 'batt_counts'
		}];

		this.widgetsCheckboxList[widgetKey] = [{
			name: widgetKey,
			label: 'Show Percentage',
			selectedValue: 1
		}];

		let res,
			missedEQsAlertCounts = 0,
			date = optionalDate ? optionalDate : 'W';

		let options: any = {
			dateRange: date,
			inactiveWidgets: this.inactiveWidgets
		};
		 
		for (let id in this.siteAlerts) {
			let userSettings = this.alertsSettings[id].userSettings;
			options.charge_events = !userSettings || userSettings.bv_charge_events_only_for_charge_ahr;
			options.inuse_events = !userSettings || userSettings.bv_inuse_events_only_for_charge_ahr;

			let value = this.siteAlerts[id] || [];
			// All sites missed EQs
			res = this.chartDataService.generateWidgetData(value, "batt_alert_missed_eq_list", options);
			missedEQsAlertCounts = res && res[1].list ? _.reduce(_.pluck(res[1].list, "value"), function(memo, num) { return memo + num}, 0) : 0; 
			this.results[widgetKey][id] = {
				data                    : res,
				ok_batt_counts          : res && res[0].list ? res[0].list.length : 0,
				not_ok_batt_counts      : res && res[1].list ? res[1].list.length : 0,
				alert_counts            : missedEQsAlertCounts
			};
		}
		let missedEQsChartColumns = [{
			type: "string",
			label: "Site"
		}];
 
		if (this.data_view['missed_eqs'] == "batt_counts") {
					 
			let missedEqsRows = [];
			let missedEQsGridItems = [];
			let notOkBattCounts, okBattCounts;
			missedEQsChartColumns.push({type: "number", label: "Missed EQs Batteries Count"});
			missedEQsChartColumns.push({type: "number", label: "Equalized batteries"});
			 
			for (let key in this.results[widgetKey]) {
				let obj = this.results[widgetKey][key];
					 
				notOkBattCounts = obj.not_ok_batt_counts;
				okBattCounts = obj.ok_batt_counts;
				if (this.customerSites[key]) {
					missedEqsRows.push([
						this.customerSites[key].name,// site name
						notOkBattCounts,
						okBattCounts
											
					]);
					missedEQsGridItems.push({
						site: this.customerSites[key].name,
						not_ok_batt_counts: notOkBattCounts,
						ok_batt_counts: okBattCounts
					});
				}
			}

			this.gridColumns[widgetKey] = [
				{headerName: "Site", field: "site", filter: true,width:430},
				{headerName: "Batteries With Missed Eqs", type:"number", field: "not_ok_batt_counts", filter: false,width:300},
				{headerName: "Batteries Without Missed Eqs", type:"number", field: "ok_batt_counts", filter: false, width:300}
			];

			this.gridRows[widgetKey] = missedEQsGridItems;

			let options = {
				colors : ["#C70039","#41ca6a"],
			};

			if (this.percentage[widgetKey]) {
				options['isStacked'] = 'percent';
			}
			this.ready[widgetKey] = true;
			this.setChart(widgetKey, missedEQsChartColumns, missedEqsRows, options);

		} else if (this.data_view['missed_eqs'] == "alert_counts") {
			let missedEqsRows = [];
			let missedEQsGridItems = [];
			missedEQsChartColumns.push({type: "number", label: "Missed EQs Alerts"});
		
			for (let key in this.results[widgetKey]) {

				let obj = this.results[widgetKey][key];					 
		
				if (this.customerSites[key]) {
					missedEqsRows.push([
						this.customerSites[key].name,// site name
						obj.alert_counts
					]);

					missedEQsGridItems.push({
						site: this.customerSites[key].name,
						alert_counts: obj.alert_counts
					});
				}
			}
			let missedEQsColumnDefs =  [
				{label: "Site", field: "site"},
				{label: "Number Of Missed Eqs", type:"number", field: "alert_counts"}
			];
			let options = {
				colors : ["#C70039"]
			};
			this.ready[widgetKey] = true;
			this.setChart(widgetKey, missedEQsChartColumns, missedEqsRows, options);
		}
	}

	generateImageURI(event) {
		let chart = event['chart'];
		let elementId = event['elementId'];
		let image = this.chartUtil.getChartImageUri(chart);
		this.images[elementId] = image;
	}

	setChart(report, columns, data, passedOptions) {

		let count = columns.length > 4 ? 4 : columns.length > 2 ? columns.length - 1 : columns.length;
		let sitescount = this.customerSitesIds.length == 1 ? 2 : this.customerSitesIds.length; 
		let height = sitescount * count * 23;
		let options = {
			bar: {groupWidth: '75%'},
			legend: { 'position': 'top', maxLines:5},
			bars: 'horizontal',
			chartArea: {
				 width: '70%' ,
			},
			height: height
		};

		options = {...options, ...passedOptions};
		this.chartConfig[report] = new BarChartConfig(options);

		let chartDataTable = [columns];
		chartDataTable = chartDataTable.concat(data);
		// chartDataTable.sort([{column: 0}]);
		this.chartsDataTables[report] = chartDataTable;
	}

	onPrintClick(event) {

		let widgetId = event['widgetId'];
		let selectedRadioButton = event['selectedRadioButton'];
		let selectedDropDown = event['selectedDropDown'];
		let customer = this.sideMenuService.getCurrentCustomer();
		let table = '<div class="row"><div class="col-md-10"><table class="table table-striped table-condensed table-bordered"><thead>';
		let fields = [];
		for (let col of this.gridColumns[widgetId]) {
			table+= '<th>' + col['headerName'] + '</th>';
			fields.push(col['field']);
		}
		table+='</thead><tbody>';
		for (let row of this.gridRows[widgetId]) {
			table+= '<tr>';
			for (let field of fields) {
				table+= '<td>' + row[field] + '</td>';
			}
			table+= '</tr>';
		}
		table+='</tbody></table></div></div>';
		let printContent = '<div class="boxs3 margin-bottom-lg element-contains-table"><h5>' + this.getWidgetTitle(widgetId) + '</h5>';
		
		if (widgetId != 'all_sites_alerts') {
			let imageHtml = '<img src="' + this.images[widgetId] + '" style="width:100%;" border="0" alt="Null">'; 
			printContent+= imageHtml
		}
		printContent+= table;
		printContent+= '</div>';
	
		this.commonUtil.print({
			customerName: customer.name,
			appendPrintContent: printContent,
			selectedRadioButton: selectedRadioButton,
			selectedDropDown: selectedDropDown,
			radioButtonGroupList: this.widgetsRadioButtons[widgetId],
			dropDownGroupList: this.widgetsDropDownLists[widgetId]

		});
	}

	getWidgetTitle(widgetId) {
		let titles = {
			missed_eqs: {},
			high_temp: "High Temperature Alerts",
			over_under_charge: "Over Under Charge Alerts",
			potential_weak_cells: "Potential Weak Cells Alerts",
			ebu_over_usage: "EBU Over Usage Alerts",
			water_levels: "Water Level Alerts",
			missed_finish: "Missed Finish Alerts",
			total_daily_idle_times: "Total Daily Idle Times Alerts",
			deep_discharge: "Deep Discharge Alerts",
			total_daily_missed_connection: "Total Daily Missed Connection Alerts",
			potentially_sulfated: "Potentially Sulfated Alerts",
			weekly_missed_eqs: "Weekly Missed EQs Alerts",
			all_sites_alerts: "All Sites Alerts (# of Batteries)"
		}
		return titles[widgetId];
	}

	ngOnDestroy() {
		this.customersSubscription.unsubscribe();
		this.activeDashboardDataSubscription.unsubscribe();

	}
}