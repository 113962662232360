<div>
	<div>
		<button *ngIf="pageName != 'battviewMobile' && userService.hasAccessFunction('act_admin_device_management')" class="btn btn-primary mb20" type="button" (click)="editBattviewBtn(0, owners.customerid, owners.siteid)">
			Add New <span class="glyphicon glyphicon-plus"></span>
		</button>

		<app-manage-device-btn [selectedDevices]="selectedDevices" type='battview' *ngIf="!userService.hasAccessFunction('no_manage_devices')"></app-manage-device-btn>

		<button *ngIf="owners.siteid == 554" class="btn btn-warning mb20" (click)="assignBattviewsToEastPenn()" [disabled]="selectedDevices.length == 0">
			Assign to "East Penn"
		</button>

		<button *ngIf="pageName != 'battviewMobile' && userService.hasAccessFunction('act_admin_device_management')" 
		class="btn btn-default mb20" type="button"
		[disabled]="selectedDevices.length == 0"
		(click)="autoCorrectBattview(selectedDevices)">
			{{'device.auto_correct' | translate}}
		</button>
	</div>

	<div class="boxs">
		<div *ngIf="pageName != 'listing'">
			<label for="dealer">Select Dealer <select class="form-control inline-form-control" [(ngModel)]="dealerId" (ngModelChange)="changeDealer()" style="display: inline;margin-left: 10px;">
					<option *ngFor="let dealer of dealers" [value]="dealer.id">{{dealer.name}}</option>
				</select>
			</label>
		</div>
		<table class="table table-stripped">
			<thead>
				<tr>
					<th *ngIf="owners.siteid == 554 || !userService.hasAccessFunction('no_manage_devices')"><input type="checkbox" [ngModel]="selectAllDevices" (ngModelChange)="selectAllToggle($event)"/> Select All</th>
					<th class="red-text">ActView ID</th>
					<th>Serial Number</th>
					<th *ngIf="pageName == 'unassignedSite'">Customer Name</th>
					<th>Battery ID</th>
					<th *ngIf="pageName != 'listing'">Model</th>
					<th *ngIf="pageName == 'listing'">Modification Date</th>
					<th *ngIf="pageName != 'listing'">Installation Date</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let battview of devices">
					<td *ngIf="owners.siteid == 554 || !userService.hasAccessFunction('no_manage_devices')"><input (ngModelChange)="toggleDeviceSelection($event, battview)" type="checkbox" class="form-control" [checked]="battview.checked" ngModel/></td>
					<td class="red-text">{{battview.id}}</td>
					<td>{{battview.serialnumber}}</td>
					<td *ngIf="pageName == 'unassignedSite'">{{battview.customername}}</td>
					<td>{{battview.batteryid}}</td>
					<td *ngIf="pageName != 'listing'">{{battview.model}}</td>
					<td *ngIf="pageName == 'listing'">{{battview.modificationdate | date:'MM/dd/yyyy'}}</td>
					<td *ngIf="pageName != 'listing'">{{battview.installationdate | date:'MM/dd/yyyy'}}</td>
					<td>
						<div class="btn-group" appDropdown>
							<i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
							<ul class="dropdown-menu" role="menu">
								<li><a (click)="editBattviewBtn(battview.id, battview.customerid, battview.siteid)">Edit</a></li>
								<li *ngIf="pageName == 'listing' && userService.hasAccessFunction('act_admin_device_management')"><a (click)="deleteBattview(battview.id)">Delete</a></li>
								<li *ngIf="pageName != 'listing' && pageName != 'battviewMobile' && userService.hasAccessFunction('enable_full_access_mode')"><a (click)="autoCorrectBattview(battview.id)">{{'device.auto_correct' | translate}}</a></li>
							</ul>
						</div>
					</td>
				</tr>	
			</tbody>
		</table>
	</div>
</div>