import { Component, OnInit, Input, Output, ViewChild, OnChanges, OnDestroy, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { UserService } from '../../../../../auth/user.service';
import { ChargersDashboardService } from '../chargers-dashboard.service';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { NotificationMessageService } from '../../../../../shared/notification-message/notification-message.service';
import { DevicesDashboardService } from '../../../devices/devices-dashboard.service';
import { SiteDashboardService } from '../../../site-dashboard.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-charger-analytics',
	templateUrl: './charger-analytics.component.html',
	styleUrls: ['./charger-analytics.component.css']
})
export class ChargerAnalyticsComponent implements OnInit, OnChanges, OnDestroy {

	@Input() charger: any = {};
	@Input() customerID: number = 0;
	@Input() siteID: number = 0;
	@Input() hasChargers = false;
	@Input() dateRange: {
		'fromDateFmt': Date,
		'toDateFmt': Date
	};
	@Input() tab = 'cycles';
	chargerID: number = 0;
	ChargerData: any = {};
	currentUser: any = {};
	numberOfCellsColumn: any = {};
	exitStatusColumn: any = {};
	isVPC = true;
	prevToDate: any;
	prevFromDate: any;
	ChargerCyclesGridColumns = [];
	ChargerCyclesGridData = [];
	PMFaultsGridColumns = [];
	PMFaultsGridData = [];
	PMWarningsGridColumns = [];
	PMWarningsGridData = [];
	hasPLCFaults = false;
	eventsDetectionObj: any = {};
	intervalID: any;
	batteryTypes: {};
	detectionConfig: any = {
		minStartTimeRange: -30,
		maxStartTimeRange: 30,
		durationEnabled: true,
		duration: 11,
		event: {},
		invalidFields: []
	};

	@ViewChild("detectionModal") detectionModal;
	@ViewChild("detectionResultModal") detectionResultModal;

	@ViewChild("cyclesGrid") cyclesGrid;
	@Output() onPLCFaultsEvent = new EventEmitter<boolean>();

	constructor(
		private userService: UserService,
		private datePipe: DatePipe,
		private commonUtil: CommonUtil,
		private devicesDashboard: DevicesDashboardService,
		private chargersDashboard: ChargersDashboardService,
		private notificationMessage: NotificationMessageService,
		private siteDashboardService: SiteDashboardService,
		private router: Router,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();

		this.siteDashboardService.setActiveSubTab('charger-analytics');
		this.batteryTypes = this.devicesDashboard.batteryTypesObj;
		this.batteryTypes[3] = {id: 3, text: "Lithium-ion: BMS"}; // add type only in this page

		this.exitStatusColumn = {
			headerName: 'act.exit_status',
			width: 300,
			field: "exitStatus",
			filterParams: {newRowsAction: 'keep'},
			floatingFilterComponent: "dropDownFilterComponent",
			floatingFilterComponentParams: {dropDownOptions: []},
		};
		this.numberOfCellsColumn = {
			headerName: "charger.number_of_cells",
			width: 200,
			field: "numberofcells",
			type: 'number',
			filterParams: {newRowsAction: 'keep'},
			floatingFilterComponent: "dropDownFilterComponent",
			floatingFilterComponentParams: {dropDownOptions: []},
		};

		this.ChargerCyclesGridColumns = [
			{headerName: "charger.sequence", field: "cycleid", type:"number", width: 150, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.cycle_time", field: "cycletime_formatted", filterParams: {newRowsAction: 'keep', defaultOption: 'startsWith'}, width: 170},
			{headerName: "device.duration", field: "duration", filterParams: {newRowsAction: 'keep', defaultOption: 'startsWith'}, width: 150},
			{headerName: "g.AHRs", field: "totalas", type:"number", width: 150, filterParams: {newRowsAction: 'keep', format: '1.1-1'}},
			{headerName: "g.KWHrs", field: "totalws", type:"number", width: 150, filterParams: {newRowsAction: 'keep', format: '1.2-2'}},
			{headerName: "charger.max_input_watts", field: "maxws", type:"number", width: 200, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.max_temperature", field: "maxtemperature", type:"number", width: 200, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.start_voltage", field: "startvoltageformatted", type:"number", filterParams: {newRowsAction: 'keep', format: '1.2-2'}, width: 200},
			{headerName: "charger.end_voltage", field: "lastvoltageformatted", type:"number", filterParams: {newRowsAction: 'keep', format: '1.2-2'}, width: 200},
			this.exitStatusColumn,
			{headerName: "charger.profiles", field: "profiles", width: 150, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.pms_faulted", field: "PMsFaulted", width: 300, filterParams: {newRowsAction: 'keep'}},
			this.numberOfCellsColumn,
			{
				headerName: "device.battery_id", width: 380, field: "batteryid", filter: "nullFilter",
				onCellClicked: ($e) => {
					this.onChargerCellClicked($e);
				},
				cellRendererSelector: (params) => {
					
					if(params.data.haveBatteryModel)
						return;
					
					if(params.data.batteryid) {

						params['label'] = params.data.batteryid;

						return {
							component: 'linkCellRenderer',
							params: params
						};
					}
					if(params.colDef.cellRendererParams.isACTuser) {
						params['label'] = params.colDef.cellRendererParams.translateService.instant('charger.battery_events_detection');
						return {
							component: 'linkCellRenderer',
							params: params,
						};
					}
					return null;
				},
				cellRendererParams: {isACTuser: this.currentUser.isACTuser, translateService: this.translateService},
				floatingFilterComponent: "dropDownFilterComponent",
				floatingFilterComponentParams: {
					dropDownOptions: [
						{label: '', value: 'null'},
						{label: this.translateService.instant('charger.no_battview'), value: 'none'},
						{label: this.translateService.instant('charger.with_battview'), value: 'not_null'}
					],
					type: "nullFilter"
				},
				filterParams: {newRowsAction: 'keep'}
			},
			{headerName: "device.battery_type", field: "batteryTypeName", width: 300, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.start_soc", field: "startsoc", type:"number", width: 150, filterParams: {newRowsAction: 'keep'}},
			{headerName: "charger.end_soc", field: "endsoc", type:"number", width: 150, filterParams: {newRowsAction: 'keep'}}

		];

		if(this.userService.hasAccessFunction('act_admin_device_management')) {
			this.ChargerCyclesGridColumns.push({
				headerName: 'charger.battery_events_detection',
				width: 380,
				field: "battery_id",
				hide: 1,
				filter: false,
				execludeOnExport: true,
				onCellClicked: ($e) => {
					this.showDetectionModal($e.node.data);
				},
				cellRendererParams: {translateService: this.translateService},
				cellRendererSelector: (params) => {
					params['label'] = params.colDef.cellRendererParams.translateService.instant('charger.battery_events_detection');
					return {
						component: 'linkCellRenderer',
						params: params,
					};
				},
			});
		}

		if(this.userService.hasAccessFunction('lcd_history')) {
			this.ChargerCyclesGridColumns.push({
				headerName: "charger.cycle_animation",
				width: 200,
				cellRendererSelector: (params) => {
					params['label'] = this.translateService.instant('charger.show_video');
					let link = ["#", params.colDef.cellRendererParams.customerID, params.colDef.cellRendererParams.siteID, "chargers", "lcd-history"].join('/');
					let queryParams = "?chargersIDs=" + params.data.chargerid + "&" + "cycleId=" + params.data.cycleid;
					params['link'] = link + queryParams;
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				field: "cycle_animation",
				filter: false,
				execludeOnExport: true,
				cellRendererParams: {'customerID': this.customerID, 'siteID': this.siteID}
			});
		}

		if(this.currentUser.isACTuser) {
			this.ChargerCyclesGridColumns.push(
				{
					headerName: "charger.insertion_time",
					width: 200,
					field: "insertiontime_formatted",
					filterParams: {newRowsAction: 'keep', defaultOption: 'startsWith'}
				},
				{
					headerName: "charger.cycle_end_time",
					field: "cycleendtime_formatted",
					filterParams: {newRowsAction: 'keep', defaultOption: 'startsWith'},
					width: 170
				}
			);
		}

		this.PMFaultsGridColumns = [
			{headerName: "device.pm_id", field: "pmid", width: 170, filterParams: {newRowsAction: 'keep', defaultOption: 'startsWith'}},
			{headerName: "device.fault_time", field: "time", filterParams: {newRowsAction: 'keep', defaultOption: 'startsWith'}, width: 170},
			{headerName: "g.fault", field: "fault", width: 650, filterParams: {newRowsAction: 'keep'}}
		];

		this.PMWarningsGridColumns = [
			{headerName: "charger.sequence", field: "seq", type:"number", width: 150, filterParams: {newRowsAction: 'keep', defaultOption: 'startsWith'}},
			{headerName: "device.pm_id", field: "pmid", width: 170, filterParams: {newRowsAction: 'keep', defaultOption: 'startsWith'}},
			{headerName: "g.time", field: "time", filterParams: {newRowsAction: 'keep', defaultOption: 'startsWith'}, width: 170},
			{headerName: "g.voltage", field: "voltage", type:"number", filterParams: {newRowsAction: 'keep', format: '1.2-2'}, width: 200},
			{headerName: "g.current", field: "current", type:"number", filterParams: {newRowsAction: 'keep', format: '1.2-2'}, width: 200},
			{headerName: "g.faults", field: "faults", width: 650, filterParams: {newRowsAction: 'keep'}}
		];
		
		this.intervalID = setInterval(() => {

			if(this.chargerID && ['cycles', 'faults', 'pm-warnings'].indexOf(this.tab) != -1)
				this.fetchChargerData('MostRecent');

		}, 60000); //Send ajax call every 1 minute
	}

	ngOnChanges(changes) {
		if(changes.charger || changes.dateRange) {
			if(changes.charger) {
				this.resetChargerData();
			}
			
			if(changes.dateRange && this.tab != 'GR') {
				this.ChargerData.fromDateFmt	= this.dateRange.fromDateFmt;
				this.ChargerData.toDateFmt		= this.dateRange.toDateFmt;
			}
			
			this.filterChargerData();
		}
	}

	onChargerCellClicked(cycle) {
		let data = cycle.node.data;
		
		if(data.haveBatteryModel)
			return;

		if(data.batteryid)
			return this.router.navigate(["/", this.customerID, this.siteID, "battviews", "analytics", "events-log", data.battviewid], {queryParams: {chargerSerialNumber: data.chargerSerialNumber}});
		
		if(this.currentUser.isACTuser)
			return this.showDetectionModal(data);

		return null;
	}

	isSet(tabId) {
		return this.tab === tabId;
	};
	
	runDetection(stage) {
		if(stage != 'OK') {
			return this.detectionModal.hide();
		}
		this.detectionConfig.invalidFields = this.validateDetectionConfig();
		if(this.detectionConfig.invalidFields.length > 0) {
			return;
		}

		this.detectionModal.hide();
		this.setDefaultDetectionConfig();

		let config: any = {
			minStartTimeRange: this.detectionConfig.minStartTimeRange,
			maxStartTimeRange: this.detectionConfig.maxStartTimeRange,
		};
		if (this.detectionConfig.durationEnabled) {
			config.duration = this.detectionConfig.duration;
		}

		let customerId	= this.customerID;
		let siteId		= this.siteID;

		let params = {
			siteid: siteId,
			customerid: customerId,
			cycleTime: new Date(this.detectionConfig.cycle.cycletime).getTime()/1000,
			ampSec: this.detectionConfig.cycle.totalas,
			duration: this.detectionConfig.cycle.durationTime,
			config: config
		};
		
		this.chargersDashboard.eventsDetection(params).subscribe(
			(response: any) => {
				this.eventsDetectionObj = {
					data: response.events,
					devices: response.battviews,
					customerid: customerId,
					siteid: siteId,
					detectionType: 'charger'
				};

				this.detectionResultModal.show();
			}
		);
	}

	showDetectionModal(cycle) {
		if(!(this.currentUser.isACTuser || this.userService.hasAccessFunction('act_admin_device_management')) || !cycle)
			return;

		this.detectionConfig.invalidFields = [];

		this.detectionConfig.cycle = cycle;
		this.getDefaultDetectionConfig();

		return this.detectionModal.show();
	}

	getDefaultDetectionConfig() {
		try {
			let config = JSON.parse(localStorage.getItem('defaultEventDetectionConfig'));
			if(!config)
				return;
	
			if(config.minStartTimeRange != undefined)
				this.detectionConfig.minStartTimeRange = config.minStartTimeRange;
			if(config.maxStartTimeRange != undefined)
				this.detectionConfig.maxStartTimeRange = config.maxStartTimeRange;
			if(config.durationEnabled != undefined)
				this.detectionConfig.durationEnabled = config.durationEnabled;
			if(config.duration != undefined)
				this.detectionConfig.duration = config.duration;
		} catch(ex) {}
	}

	setDefaultDetectionConfig() {
		let config = {
			minStartTimeRange: this.detectionConfig.minStartTimeRange,
			maxStartTimeRange: this.detectionConfig.maxStartTimeRange,
			durationEnabled: this.detectionConfig.durationEnabled,
			duration: this.detectionConfig.duration
		};

		return localStorage.setItem('defaultEventDetectionConfig', JSON.stringify(config));
	}

	validateDetectionConfig() {
		let validationRules = {
			'minStartTimeRange'	: [{type: 'integer', min: -60, max: 0}],
			'maxStartTimeRange'	: [{type: 'integer', min: 0, max: 60}],
			'duration'			: [{type: 'integer', min: -60, max: 60}],
		};

		if(!this.detectionConfig.durationEnabled)
			delete validationRules.duration;

		let invalidFields = [];

		for(let field in this.detectionConfig) {

			let rules = validationRules[field] || [];
			for (let i = 0; i < rules.length; i++) {
				let rule = rules[i];
				let isValid = this.commonUtil.dataValidator(rule.type, rule, this.detectionConfig[field]);

				if(!isValid)
					invalidFields.push(field);
			}
		}

		return invalidFields;
	}

	isInvalidDetectionConfigField(field) {
		return this.detectionConfig.invalidFields.includes(field);
	}

	resetChargerData() {

		this.chargerID = this.charger.id || 0;

		this.ChargerData = {
			fromDate: Math.floor(new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0, 0, 0, 0) / 1000),
			fromDateFmt: new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0, 0, 0, 0)),
			toDate: Math.floor(new Date().setHours(23,59,59,999) / 1000),
			toDateFmt: new Date(new Date().setHours(23,59,59,999)),
			Cycles: [],
			lastCycleID: 0,
			lastCycleStatus: '',
			cyclesMinDate: Math.floor(new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0, 0, 0, 0) / 1000),
			cyclesMaxDate: Math.floor(new Date().setHours(23,59,59,999) / 1000),
			Faults: [],
			faultsMinDate: Math.floor(new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0, 0, 0, 0) / 1000),
			faultsMaxDate: Math.floor(new Date().setHours(23,59,59,999) / 1000),
			lastFaultID: 0,
			Warnings: [],
			warningsMinDate: Math.floor(new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0, 0, 0, 0) / 1000),
			warningsMaxDate: Math.floor(new Date().setHours(23,59,59,999) / 1000),
			lastWarningID: 0,
			lastConnectTime: this.charger.lastconnecttime
		};

		this.prevFromDate	= this.ChargerData.fromDateFmt;
		this.prevToDate		= this.ChargerData.toDateFmt;

		this.PMFaultsGridData		= [];
		this.PMWarningsGridData		= [];
		this.ChargerCyclesGridData	= [];
	};

	getCycleRecords() {
		if(!this.chargerID)
			return;

		let options = {
			fromDate: this.ChargerData.fromDate,
			toDate: this.ChargerData.toDate
		};

		this.chargersDashboard.getCycleRecords(this.chargerID, options).subscribe(
			(cycleRecords: any) => {

				let visibleVideoColumn = 0;

				if(this.userService.hasAccessFunction('lcd_history') && this.charger.actviewenable)
					visibleVideoColumn = 1;
				
				this.ChargerCyclesGridColumns.forEach((column, idx) => {
					if(column.field == 'cycle_animation')
						this.ChargerCyclesGridColumns[idx].hide = !visibleVideoColumn;
				});

				this.ChargerData.cyclesMinDate 		= this.ChargerData.fromDate;
				this.ChargerData.cyclesMaxDate 		= this.ChargerData.toDate;
				this.ChargerData.isCyclesFetched	= true;
				
				if(Object.keys(cycleRecords).length == 0) {
					this.ChargerCyclesGridData = [];
					return;
				}

				cycleRecords.forEach((record, i) => {
					if(record.corrupted) {
						
						cycleRecords.splice(i,1); //remove record
						i--;
					} else {

						this.formatCycleRecord(cycleRecords[i]);
					}
				});

				if(cycleRecords.length === 0) {
					this.ChargerCyclesGridData = [];
					return;
				}

				this.ChargerData.Cycles				= cycleRecords;
				this.ChargerData.lastCycleID		= cycleRecords[0].cycleid;
				this.ChargerData.lastCycleStatus	= cycleRecords[0].exitStatus;
				this.formatChargerData();
				this.ChargerCyclesGridData			= this.ChargerData.Cycles;
			}
		);
	};

	formatCycleRecord(record) {
		record.cycletime_formatted = this.commonUtil.getDateFormattedFromUnixTimeStamp(new Date(Date.parse(record.cycletime)).getTime()/1000);
		record.cycleendtime_formatted = this.commonUtil.getDateFormattedFromUnixTimeStamp((new Date(Date.parse(record.cycletime)).getTime()/1000) + (+record.durationTime));
		record.insertiontime_formatted = this.commonUtil.getDateFormattedFromUnixTimeStamp(new Date(Date.parse(record.insertiontime)).getTime()/1000);
		record.maxws = Math.round(record.maxws * 1.07);
		record.totalws = record.totalws_raw * 1.07;

		var batteryTypeName = this.batteryTypes[record.batterytype];
		record.batteryTypeName = batteryTypeName ? batteryTypeName.text : '';
		record.runWithBMS = record.batterytype == 3;
		record.batteryid = this.chargersDashboard.getBatteryModelFromBattviewId(record, this.charger);
	}

	formatPMfault(record) {
		let date = (new Date(record.time).getTime() + (new Date(record.time).getTimezoneOffset() * 60000));
		record.time = this.datePipe.transform(new Date(date), 'MM/dd/yyyy hh:mm a');

		if(this.tab == 'pm-warnings') {
			record.voltage /= 100;
			record.current /= 10;
			record.faults = record.warnings.join(', ');
		}
	}

	formatChargerData() {
		var exitStatusOptions		= [],
			numberOfCellsOptions	= [];

		for (var cycleIndex in this.ChargerData.Cycles) {
			var cycle = this.ChargerData.Cycles[cycleIndex];
			if(cycle.batterytype == 3){ // if battery is Lithium with BMS, don't divide voltage by no. of cells
				cycle.vpcstartvoltage	= Math.round((cycle.startvoltage) * 100) / 100;
				cycle.vpclastvoltage	= Math.round((cycle.lastvoltage) * 100) / 100;
			} else {
				cycle.vpcstartvoltage	= Math.round((cycle.startvoltage / cycle.numberofcells) * 100) / 100;
				cycle.vpclastvoltage	= Math.round((cycle.lastvoltage / cycle.numberofcells) * 100) / 100;
			}

			cycle.startvoltageformatted	= cycle.vpcstartvoltage;
			cycle.lastvoltageformatted	= cycle.vpclastvoltage;
			if (!this.isVPC) {
				cycle.startvoltageformatted	= Math.round(cycle.startvoltage * 100) / 100;
				cycle.lastvoltageformatted	= Math.round(cycle.lastvoltage * 100) / 100;
			}
			cycle.chargerSerialNumber = this.charger.serialnumber;

			if (exitStatusOptions.indexOf(cycle.exitStatus) == -1) {
				exitStatusOptions.push(cycle.exitStatus);
			}
			if (numberOfCellsOptions.indexOf(cycle.numberofcells) == -1) {
				numberOfCellsOptions.push(cycle.numberofcells);
			}
		}
		let exitStatusDropdownOptions = [{label: '', value: null}];
		for(var exitStatus in exitStatusOptions) {
			exitStatusDropdownOptions.push({value: exitStatusOptions[exitStatus].toLowerCase(), label: exitStatusOptions[exitStatus]});
		}
		this.exitStatusColumn.floatingFilterComponentParams.dropDownOptions = exitStatusDropdownOptions;
		
		let numberOfCellsDropdownOptions = [{label: '', value: null}];
		for(var numberOfCellsOption in numberOfCellsOptions) {
			numberOfCellsDropdownOptions.push({value: numberOfCellsOptions[numberOfCellsOption], label: numberOfCellsOptions[numberOfCellsOption]});
		}
		this.numberOfCellsColumn.floatingFilterComponentParams.dropDownOptions = numberOfCellsDropdownOptions;
	};

	fetchChargerData(from) {

		if(!this.chargerID)
			return;

		var lastConnectTime = this.ChargerData.lastConnectTime;
		var lastCycleStatus = this.ChargerData.lastCycleStatus;
		var fromDate		= this.ChargerData.fromDate;
		var chargerID		= this.chargerID;
		var toDate = 0;
		var lastID = 0;

		switch(this.tab) {
			case 'cycles':
				toDate = this.ChargerData.cyclesMinDate;
				lastID = this.ChargerData.lastCycleID;
			break;
			
			case 'faults':
				toDate = this.ChargerData.faultsMinDate;
				lastID = this.ChargerData.lastFaultID;
			break;

			case 'pm-warnings':
				toDate = this.ChargerData.warningsMinDate;
				lastID = this.ChargerData.lastWarningID;
			break;
		}

		var payLoadObject = {
			'chargerID'			:chargerID,
			'type'				:this.tab,
			'fromDate'			:fromDate,
			'toDate'			:toDate,
			'from'				:from,
			'lastID'			:lastID,
			'lastConnectTime'	:lastConnectTime,
			'lastCycleStatus'	:lastCycleStatus
		};

		this.chargersDashboard.fetchChargerData(payLoadObject).subscribe(
			(result: any) => {

				this.charger.lastconnecttime = result.lastConnectTime;

				var response = result.data;

				response.forEach((record, i) => {
					if(record.corrupted) {
						response.splice(i,1); //remove corrupted record
						i--;
					} else {
						if(this.tab == 'cycles')
							this.formatCycleRecord(response[i]);
						else if(this.tab == 'faults' || this.tab == 'pm-warnings')
							this.formatPMfault(response[i]);
					}
				});

				if(response.length > 0) {

					switch(this.tab) {
						case 'cycles':

							var lastCycleID 		= response[0].cycleid;
							var lastCycleNewStatus	= response[0].exitStatus;

							if(from == 'old') {
								this.ChargerData.Cycles = this.ChargerData.Cycles.concat(response);
								this.formatChargerData();
								this.ChargerCyclesGridData = this.ChargerData.Cycles;
								
								if(lastCycleID > this.ChargerData.lastCycleID) {
									this.ChargerData.lastCycleID		= lastCycleID;
									this.ChargerData.lastCycleStatus	= lastCycleNewStatus;
								}

							} else {
								//Most Recent
								if(lastCycleStatus == 'The Cycle Is Still Running')
									this.ChargerData.Cycles			= response.concat(this.ChargerData.Cycles.slice(1, this.ChargerData.Cycles.length));
								else
									this.ChargerData.Cycles			= response.concat(this.ChargerData.Cycles);

								this.formatChargerData();
								this.ChargerCyclesGridData	= this.ChargerData.Cycles;
								this.ChargerData.lastCycleID			= lastCycleID;
								this.ChargerData.lastCycleStatus		= lastCycleNewStatus;
							}
						break;
						
						case 'faults':
							var lastFaultID = response[0].seq;
							if(from == 'old') {
								this.ChargerData.Faults	= this.ChargerData.Faults.concat(response);
								this.PMFaultsGridData	= this.ChargerData.Faults;

								if(lastFaultID > this.ChargerData.lastFaultID)
									this.ChargerData.lastFaultID = lastFaultID;

							} else {
								//Most Recent
								this.ChargerData.Faults			= response.concat(this.ChargerData.Faults);
								this.PMFaultsGridData			= this.ChargerData.Faults;
								this.ChargerData.lastFaultID	= lastFaultID;
							}
						break;

						case 'pm-warnings':
							var lastWarningID = response[0].seq;
							if(from == 'old') {
								this.ChargerData.Warnings	= this.ChargerData.Warnings.concat(response);
								this.PMWarningsGridData		= this.ChargerData.Warnings;

								if(lastWarningID > this.ChargerData.lastWarningID)
									this.ChargerData.lastWarningID = lastWarningID;

							} else {
								//Most Recent
								this.ChargerData.Warnings		= response.concat(this.ChargerData.Warnings);
								this.PMWarningsGridData			= this.ChargerData.Warnings;
								this.ChargerData.lastWarningID	= lastWarningID;
							}
						break;
					}
				}

				if(from == 'old') {
					if(this.tab == 'cycles')
						this.ChargerData.cyclesMinDate = this.ChargerData.fromDate;
					else if(this.tab == 'faults')
						this.ChargerData.faultsMinDate = this.ChargerData.fromDate;
					else if(this.tab == 'pm-warnings')
						this.ChargerData.warningsMinDate = this.ChargerData.fromDate;
				} else {
					//Most Recent
					if(this.tab == 'cycles')
						this.ChargerData.cyclesMaxDate = this.ChargerData.toDate;
					else if(this.tab == 'faults')
						this.ChargerData.faultsMaxDate = this.ChargerData.toDate;
					else if(this.tab == 'pm-warnings')
						this.ChargerData.warningsMaxDate = this.ChargerData.toDate;
				}
			}
		);
	}

	getPmWarnings() {
		if(!this.chargerID)
			return;

		this.chargersDashboard.getPmWarnings(this.chargerID, this.ChargerData.fromDate, this.ChargerData.toDate).subscribe(
			(PMWarningsResult: any) => {

				var PMWarnings = [];
				this.ChargerData.warningsMinDate	= this.ChargerData.fromDate;
				this.ChargerData.warningsMaxDate	= this.ChargerData.toDate;
				this.ChargerData.isWarningsFetched	= true;

				PMWarningsResult.forEach((record, i) => {
					if(!record.corrupted) {
						this.formatPMfault(record);
						PMWarnings.push(record);
					}
				});

				var lastID = 0;
				if(PMWarningsResult.length > 0) {
					lastID = PMWarningsResult[0].seq;
				}

				if(PMWarnings.length == 0) {
					this.PMWarningsGridData = [];
					return;
				}

				this.ChargerData.Warnings		= PMWarnings;
				this.ChargerData.lastWarningID	= lastID;
				this.PMWarningsGridData			= PMWarnings;
			}
		);
	}

	getPMFaults() {
		if(!this.chargerID)
			return;

		this.chargersDashboard.getPMFaults(this.chargerID, this.ChargerData.fromDate, this.ChargerData.toDate).subscribe(
			(PMFaultsResult: any) => {
				var PMFaults = [];
				this.ChargerData.faultsMinDate		= this.ChargerData.fromDate;
				this.ChargerData.faultsMaxDate		= this.ChargerData.toDate;
				this.ChargerData.isFaultsFetched	= true;
				let is_site_plc_enabled				= PMFaultsResult.isSitePLCEnabled || false;

				this.hasPLCFaults = false;
				if(
					is_site_plc_enabled &&
					this.userService.hasAccessFunction('PLC_error_detection') &&
					this.charger.enableplc
				) {
					var todayTime		= +(moment().utc().startOf('day').format("X"));
					var plc_event_time	= +(moment(this.charger.plc_event_time).utc().startOf('day').format("X"));
					var hide_plc_fault_date	= +(moment(this.charger.hide_plc_fault_date).utc().startOf('day').format("X"));
					var last_charge_cycle_time = +(moment(this.charger.last_charge_cycle_time).utc().startOf('day').format("X"));
					if (todayTime > hide_plc_fault_date) {
						// if plc_event_time is 1-1-1970 and last_charge_cycle_time != 1-1-1970 save a fault called no_plc_actvity
						if (plc_event_time < 1*24*60*60 && last_charge_cycle_time > 1*24*60*60) {
							var plcFaultsObject: any = {};
							plcFaultsObject.fault = 'No PLC Activity';
							plcFaultsObject.time = last_charge_cycle_time * 1000;
							this.formatPMfault(plcFaultsObject);
							PMFaults.push(plcFaultsObject);
							this.hasPLCFaults = true;
						} else if (last_charge_cycle_time > todayTime - (1*7*24*60*60) && plc_event_time < todayTime - (1*8*24*60*60)) {
							// ELSE if last_charge_cycle_time is within last 1 week, and plc_event_time is older than 8 days..save a fault called plc_warning
							var plcFaultsObject: any = {};
							plcFaultsObject.fault = 'PLC Warning';
							plcFaultsObject.time = plc_event_time * 1000;
							this.formatPMfault(plcFaultsObject);
							PMFaults.push(plcFaultsObject);
							this.hasPLCFaults = true;
						}
					}
				}

				if(this.hasPLCFaults)
					this.onPLCFaultsEvent.emit(true);

				PMFaultsResult.forEach((record, i) => {
					if(!record.corrupted) {
						this.formatPMfault(record);
						PMFaults.push(record);
					}
				});


				if(PMFaults.length == 0) {
					this.PMFaultsGridData = [];
					return;
				}

				this.ChargerData.Faults			= PMFaults;
				this.ChargerData.lastFaultID	= PMFaults[0].seq;
				this.PMFaultsGridData			= PMFaults;
			}
		);
	}

	filterChargerData() {
		if(!this.chargerID || this.tab == 'GR')
			return;

		this.ChargerData.fromDate	= Math.floor(new Date(this.ChargerData.fromDateFmt).getTime() / 1000);
		this.ChargerData.toDate	= Math.floor(new Date(new Date(this.ChargerData.toDateFmt).setHours(23,59,59,999)).getTime() / 1000);

		var isRecordsFetched = true;
		if(this.tab == 'cycles')
			isRecordsFetched = this.ChargerData.isCyclesFetched;
		else if(this.tab == 'faults')
			isRecordsFetched = this.ChargerData.isFaultsFetched;
		else if(this.tab == 'pm-warnings')
			isRecordsFetched = this.ChargerData.isWarningsFetched;

		if(isRecordsFetched && this.ChargerData.fromDateFmt == this.prevFromDate && this.ChargerData.toDateFmt == this.prevToDate)
			return; //Do nothing if dates were not changed

		if(this.ChargerData.toDate < this.ChargerData.fromDate) {
			this.notificationMessage.setMessage('End date always comes after start date!');
			return;
		}
		
		this.prevFromDate	= this.ChargerData.fromDateFmt;
		this.prevToDate	= this.ChargerData.toDateFmt;

		switch(this.tab) {
			case 'cycles':
				if(!this.ChargerData.isCyclesFetched) {

					this.getCycleRecords();
				} else {

					if(this.ChargerData.fromDate == this.ChargerData.cyclesMinDate
						&& this.ChargerData.toDate == this.ChargerData.cyclesMaxDate
					) {
						this.formatChargerData();
						this.ChargerCyclesGridData = this.ChargerData.Cycles;

					} else if(this.ChargerData.fromDate >= this.ChargerData.cyclesMinDate) {
						this.formatChargerData();
						this.ChargerCyclesGridData = this.ChargerData.Cycles.filter((item) => {

							var itemTime = Math.floor(new Date(item.cycletime).getTime() / 1000);
							if(itemTime >= this.ChargerData.fromDate && itemTime <= this.ChargerData.toDate) {
								return item;
							}
						});

					} else if(this.ChargerData.fromDate < this.ChargerData.cyclesMinDate) {
						this.fetchChargerData('old');
					}
				}
			break;

			case 'faults':
				if(!this.ChargerData.isFaultsFetched) {

					this.getPMFaults();
				} else {

					if(this.ChargerData.fromDate == this.ChargerData.faultsMinDate
						&& this.ChargerData.toDate == this.ChargerData.faultsMaxDate
					) {

						this.PMFaultsGridData = this.ChargerData.Faults;

					} else if(this.ChargerData.fromDate >= this.ChargerData.faultsMinDate) {

						this.PMFaultsGridData = this.ChargerData.Faults.filter((item) => {

							var itemTime = Math.floor(new Date(item.time).getTime() / 1000);
							if(itemTime >= this.ChargerData.fromDate
								&& itemTime <= this.ChargerData.toDate
							) {
								return item;
							}
						});

					} else if(this.ChargerData.fromDate < this.ChargerData.faultsMinDate) {
						this.fetchChargerData('old');
					}
				}
			break;

			case 'pm-warnings':
				if(!this.ChargerData.isWarningsFetched) {

					this.getPmWarnings();
				} else {

					if(this.ChargerData.fromDate == this.ChargerData.warningsMinDate
						&& this.ChargerData.toDate == this.ChargerData.warningsMaxDate
					) {

						this.PMWarningsGridData = this.ChargerData.Warnings;

					} else if(this.ChargerData.fromDate >= this.ChargerData.warningsMinDate) {

						this.PMWarningsGridData = this.ChargerData.Warnings.filter((item) => {

							var itemTime = Math.floor(new Date(item.time).getTime() / 1000);
							if(itemTime >= this.ChargerData.fromDate
								&& itemTime <= this.ChargerData.toDate
							) {
								return item;
							}
						});

					} else if(this.ChargerData.fromDate < this.ChargerData.warningsMinDate) {
						this.fetchChargerData('old');
					}
				}
			break;
		}
	};

	clickChangeVPC(isVPC) {
		//TODO: clearAllFilters();
		this.changeVPC(isVPC);
	}

	changeVPC(isVPC) {
		for (var cycleIndex in this.ChargerData.Cycles) {
			var cycle = this.ChargerData.Cycles[cycleIndex];
			cycle.startvoltageformatted	= cycle.vpcstartvoltage;
			cycle.lastvoltageformatted	= cycle.vpclastvoltage;
			if (!isVPC) {
				cycle.startvoltageformatted	= Math.round(cycle.startvoltage * 100) /100;
				cycle.lastvoltageformatted	= Math.round(cycle.lastvoltage * 100) /100;
			}
		}
		this.cyclesGrid.redrawRows();
	};

	ngOnDestroy() {
		clearInterval(this.intervalID);
	}
}