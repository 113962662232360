
import {map} from 'rxjs/operators';
import { 
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router
 } from '@angular/router';

import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { CanActivateChild } from '@angular/router';
import { NavigationService } from '../shared/services/navigation.service';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
	constructor(private authService: AuthService, private router: Router, private navigation: NavigationService, private location: Location) {}

	canActivateChild(route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
		return this.authHandler(route);
	}

	canActivate(route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean {
			return this.authHandler(route);
	}

	private authHandler(route) {
		return this.authService.isAuthenticated(route).pipe(
			map(user => {
				if(user)
					return true;

				this.navigation.setPreviousUrl(this.location.path());
				this.router.navigate(['login']);
				return false;
			}));
	}

}