import { Component, OnInit, Input, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'underscore';
import { NotesService } from './notes.service';
import { UserService } from '../../auth/user.service';
import { NotificationMessageService } from '../notification-message/notification-message.service';
import { DevicesDashboardService } from '../../home/site-dashboard/devices/devices-dashboard.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() siteId:number      = 0;
	@Input() modelType:string   = null;
	@Input() modelId:number  	  = 0;
	@Input() studyId:number  	  = 0;
	@Input() sourcePage:string  = null;
	@Input() isDropDown:boolean = false;
	@Input() isAddNoteOnly:boolean = false;

	addButtonPositionRight: boolean = true;
	noNotesMessagePosition: string = "text-center";
	allNotes: {
		notes: any[],
	} = {
		notes: [],
	};
	notes: {
		notes: any[],
		allLoaded: boolean,
		notification_users: any[],
		reminder_freq: string
	} = {
		notes: [],
		allLoaded 			: false,
		notification_users 	: [],
		reminder_freq: "none"
	};
	thisNote: any;
	user: any;
	allNotificationUsers: any[] = [];
	reminderOptions: any[] = [];

	@ViewChild("editSharingTypeModal") editSharingTypeModal;
	@ViewChild("editReminderFreqModal") editReminderFreqModal;
	@ViewChild("deleteModal") deleteModal;
	@ViewChild("addNoteModal") addNoteModal;
	
	constructor(
		private notesService: NotesService,
		private userService: UserService,
		private notificationMessage: NotificationMessageService,
		private devicesDashboard: DevicesDashboardService,
		private translateService: TranslateService
	) {
		this.reminderOptions = [
			{id: "none", label: this.translateService.instant('g.none')},
			{id: "daily", label: this.translateService.instant('g.daily')},
			{id: "weekly", label: this.translateService.instant('g.weekly')}
		];
	}

	ngOnInit() {
		this.user = this.userService.getCurrentUser();
		let loadData	= false;
		switch (this.sourcePage) {
			case "siteDashboard":
				loadData = true;
				this.modelType  = "site";
				this.modelId    = this.siteId;
				break;
			case "battview_mobile":
				this.modelType = "battview mobile";
				break;
			case "charger_analytics":
				this.modelType 	= "charger";
				break;
			case "charger_notes":
				this.modelType 	= "charger";
				break;
			case "battview_notes":
				if (this.modelId) {
					loadData = true;
				}
				break;
			case "service_page":
				this.noNotesMessagePosition = "text-left";
				this.addButtonPositionRight = false;
				if (this.modelId) {
					loadData = true;
				}
				break;
		}

		if (loadData) {
			this.getSiteNotes({
				siteId 	 	: this.siteId,
				modelType	: this.modelType,
				modelId  	: this.modelId,
				studyId		: this.studyId
			});
		}
	}
	ngAfterViewInit() {
		this.editSharingTypeModal.onClose.subscribe(
			(ok) => {
				if(ok) {
					this.editSharingTypeAction();
				}
			}
		);
		this.editReminderFreqModal.onClose.subscribe(
			(ok) => {
				if(ok) {
					this.editReminderFreqAction();
				}
			}
		);
		this.deleteModal.onClose.subscribe(
			(ok) => {
				if(ok) {
					this.deleteNoteAction();
				}
			}
		);
		this.addNoteModal.onClose.subscribe(
			(ok) => {
				if(ok) {
					this.addNoteAction();
				}
			}
		);
	}
	
	ngOnChanges(changes) {
		if(
			(changes.siteId && changes.siteId.currentValue) ||
			(changes.modelType && changes.modelType.currentValue) ||
			(changes.modelId && changes.modelId.currentValue) ||
			(changes.studyId && changes.studyId.currentValue)
		) {
			this.getSiteNotes({
				siteId: this.siteId,
				modelType: this.modelType,
				modelId : this.modelId,
				studyId: this.studyId
			});
		}
	}
	// function is exposed to other controllers
	getSiteNotes(params) {
		if (!params.modelId || !params.modelType || this.isAddNoteOnly) {
			return;
		}

		this.notesService.getSiteNotes(params).subscribe(
			(data: any) => {
				this.allNotes = data;
				this.notes.notification_users = data.notification_users ? data.notification_users : [];
				this.notes.notification_users.forEach(item => {
					item.appearanceName = item.firstname + ' ' + item.lastname;
				});

				this.allNotificationUsers = _.extend(this.allNotificationUsers, this.notes.notification_users);

				for(var i = 0; i < this.allNotes.notes.length; i++ ) {
					this.allNotes.notes[i].originalNote = this.allNotes.notes[i].note;
					this.allNotes.notes[i].note = this.urlParser(this.allNotes.notes[i].note);
					if(this.allNotes.notes[i].type == "bms_fw_version") {
						this.allNotes.notes[i].note = this.getBMSVersionUpdateNoteText(this.allNotes.notes[i].note);
						this.allNotes.notes[i].originalNote = "BMS FW Version got Updated";
					}
					this.allNotes.notes[i].formattedDate = moment(this.allNotes.notes[i].date).format("LLLL");
				}
				this.loadFirstThreeNotes();
				this.allNotesLoadedCheck();
			}
		);
	}
	loadFirstThreeNotes() {
		this.notes.notes = this.allNotes.notes.slice(0, 3);
	}
	allNotesLoadedCheck() {
		this.notes.allLoaded = (this.notes.allLoaded && this.allNotes.notes.length <= 3);
	}
	isDisabled(){
		var result = false;
		switch (this.sourcePage) {
			case "siteDashboard":
				if (!this.siteId) {
					result = true;
				}
				break;
			case "battery_analytics":
			case "charger_analytics":
			case "charger_notes":
			case "battview_notes":
				if (!this.modelId || !this.siteId) {
					result = true;
				}
				break;
			case "battview_mobile":
			case "battview_mobile_notes":
				if (!this.modelId || !this.studyId) {
					result = true;
				}
				break;
		}
		return result ;
	}
	addNote() {
		if(this.isAddNoteOnly)
			this.getUserNotificationList();

		this.thisNote = {
			newSiteNote : "",
			sharingType: "public",
			notificationUsers: [],
			reminder_freq: "none"
		};
		this.addNoteModal.show();
	}
	addNoteAction(){
		if (!this.thisNote.newSiteNote || !this.thisNote.newSiteNote.trim()) {
			return;
		}
		if (this.thisNote.sharingType == "private") {
			this.thisNote.notificationUsers = [];
		}
		this.notesService.addSiteNote({
			siteId 					: this.siteId,
			modelType 				: this.modelType,
			modelId 				: this.modelId,
			note 					: this.thisNote.newSiteNote,
			noteShareOption 		: this.thisNote.sharingType,
			user_notification_list	: _.pluck(this.thisNote.notificationUsers, "id").join(","),
			studyId 				: this.studyId,
			reminder_freq			: this.thisNote.reminder_freq
		}).subscribe(
			(res: any) => {
				if(!this.isAddNoteOnly) {
					let notifiedList = [];
					for (var i = 0; i< this.thisNote.notificationUsers.length; i++) {
						notifiedList.push({
							id:  this.thisNote.notificationUsers[i].id,
							fname: this.thisNote.notificationUsers[i].firstname,
							lname: this.thisNote.notificationUsers[i].lastname
						});
					}
					this.allNotes.notes.unshift({
						noteid 			: res.id,
						type 			: "user_note",
						fname 			: this.user.name ,
						lname 			: "",
						siteId 			: this.siteId,
						note 			: this.urlParser(this.thisNote.newSiteNote),
						originalNote    : this.thisNote.newSiteNote,
						noteOwnerId  	: this.user.id,
						sharingType 	: this.thisNote.sharingType,
						notifiedList	: notifiedList,
						formattedDate	: moment().format("LLLL"),
						reminder_freq	: this.thisNote.reminder_freq,
						canDelete		: true,
						canEditReminder	: true,
						canUpdateSharingType: true
					});
					if (this.showLoadMoreCheck() && this.allNotes.notes.length > 3) {
						this.loadFirstThreeNotes();
					} else {
						this.notes.notes = this.allNotes.notes;
					}
				}
				this.notificationMessage.setMessage("translate|note.note_added_successfully", 'success');
			}
		);
	}

	deleteNote(note) {
		this.thisNote = note;
		this.deleteModal.show();
	}
	deleteNoteAction() {
		this.notesService.deleteSiteNote({
			id			: this.thisNote.noteid,
			siteId		: this.siteId,
			modelType	: this.modelType,
			modelId		: this.modelId,
			studyId		: this.studyId
		}).subscribe(
			() => {
				this.allNotes.notes = _.without(this.allNotes.notes, _.findWhere(this.allNotes.notes , {
					noteid: this.thisNote.noteid
				}));
				if (this.showLoadMoreCheck() && this.allNotes.notes.length > 3) {
					this.loadFirstThreeNotes();
				} else {
					this.notes.notes = this.allNotes.notes;
				}
				this.notificationMessage.setMessage("translate|note.note_deleted_successfully", 'success', 10000, true);
			}
		);
	}

	editSharingType(note) {
		this.thisNote = {};
		this.thisNote = _.extend(this.thisNote, note);
		this.thisNote.oldSharingType = this.thisNote.sharingType;
		this.editSharingTypeModal.show();
	}
	editReminderFreq(note) {
		this.thisNote = {};
		this.thisNote = _.extend(this.thisNote, note);
		this.thisNote.oldReminderFreq = this.thisNote.reminder_freq;
		this.editReminderFreqModal.show();
	}
	editSharingTypeAction(){
		// no need to send request for no change
		if(this.thisNote.sharingType == this.thisNote.oldSharingType) {
			return;
		}
		this.notesService.updateNoteSharingType({
			siteId			: this.siteId,
			modelType		: this.modelType,
			modelId			: this.modelId,
			id				: this.thisNote.noteid,
			newShareOption	: this.thisNote.sharingType,
			studyId			: this.studyId
		}).subscribe(
			() => {
				/*if sharingType is updated to private and the owner is not the user remove the note*/
				if (this.thisNote.sharingType == "private" && this.thisNote.noteOwnerId != this.user.id) {
					this.allNotes.notes = _.without(this.allNotes.notes, _.findWhere(this.allNotes.notes , {
						noteid: this.thisNote.noteid
					}));
				} else {
					_.map(this.allNotes.notes, (note) => {
							if (note.noteid == this.thisNote.noteid) {
								note.sharingType = this.thisNote.sharingType;
							}
							return note;
					});
				}
				if (this.showLoadMoreCheck() && this.allNotes.notes.length > 3) {
					this.loadFirstThreeNotes();
				} else {
					this.notes.notes = this.allNotes.notes;
				}
				this.notificationMessage.setMessage("translate|note.note_updated_successfully", 'success', 10000, true);
			}
		);
	}
	editReminderFreqAction(){
		// no need to send request for no change
		if(this.thisNote.reminder_freq == this.thisNote.oldReminderFreq) {
			return;
		}
		this.notesService.updateNoteReminderFreq({
			siteId			: this.siteId,
			modelType		: this.modelType,
			modelId			: this.modelId,
			id				: this.thisNote.noteid,
			newReminderFreq	: this.thisNote.reminder_freq,
			studyId			: this.studyId
		}).subscribe(
			() => {
				/*if sharingType is updated to private and the owner is not the user remove the note*/
				_.map(this.allNotes.notes, (note) => {
						if (note.noteid == this.thisNote.noteid) {
							note.reminder_freq = this.thisNote.reminder_freq;
						}
						return note;
				});
				if (this.showLoadMoreCheck() && this.allNotes.notes.length > 3) {
					this.loadFirstThreeNotes();
				} else {
					this.notes.notes = this.allNotes.notes;
				}
				this.notificationMessage.setMessage("translate|note.note_updated_successfully", 'success', 10000, true);
			}
		);
	}
	getSharingtypeIcon(type) {
		var icons = {
			private 	: "fa fa-user fa-lg",
			public 		: "fa fa-globe fa-lg",
			group 		: "fa fa-users fa-lg"
		};
		return icons[type];
	}
	showLoadMoreCheck(){
		return this.notes.allLoaded ? false : true;
	}
	loadMoreNotes(){
		this.notes.allLoaded  = true;
		this.notes.notes 	  	= this.allNotes.notes;
	}
	showLoadLessCheck(){
		return this.notes.allLoaded ? true : false;
	}
	loadLessNotes(){
		this.notes.allLoaded = false;
		this.loadFirstThreeNotes();
	}
	urlParser(noteTxt) {
		var replacePattern1 = /(\b(https?|ftp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim
		var replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
		if (noteTxt.match(replacePattern1) || noteTxt.match(replacePattern2)) {
			noteTxt = noteTxt.replace(replacePattern1, "<a href=\"$1\" target=\"_blank\">$1</a>");
			// this is to avoid the relative path in case of www.
			noteTxt = noteTxt.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');
			// return $sce.trustAsHtml(noteTxt);
		}
		return noteTxt;
	}
	onChangeSharingType(sharingType) {

		if(sharingType != 'group') {
			this.notes.notification_users = _.extend([], this.allNotificationUsers);
			return;
		}
		
		// Filter out non ACT users
		let notification_users = [];
		let systemUserId = this.getSystemNoteUserId();
		this.allNotificationUsers.forEach(user => {
			if(user.roleid == this.userService.userRoles.ACTuser || user.id == systemUserId)
				notification_users.push(user);
		});

		this.notes.notification_users = notification_users;
	}
	getSystemNoteUserId() {
		return 2147483647;
	}
	getUserNotificationList() {
		let params = {
			siteId		: this.siteId,
			modelType	: this.modelType,
			modelId		: this.modelId,
			studyId		: this.studyId || 0
		};
		this.notesService.getUserNotificationList(params).subscribe(
			(data: any) => {
				this.notes.notification_users = data;

				this.notes.notification_users.forEach(item => {
					item.appearanceName = item.firstname + ' ' + item.lastname;
				});

				this.allNotificationUsers = _.extend(this.allNotificationUsers, this.notes.notification_users);
			}
		);
	}
	getBMSVersionUpdateNoteText(msg) {
		let bmsTypesByKey = _.indexBy(this.devicesDashboard.bmsTypes, "id");
		let splitted = msg.split(" To ");
		
		let bmsTypeTo = splitted[1];
		let bmsTypeToTxt = bmsTypesByKey[bmsTypeTo] ? bmsTypesByKey[bmsTypeTo].text : "";
		msg = msg.replace(bmsTypeTo, bmsTypeToTxt);
		
		let bmsTypeFrom = splitted[0].split("From: ")[1];
		let bmsTypeFromTxt = bmsTypesByKey[bmsTypeFrom] ? bmsTypesByKey[bmsTypeFrom].text : "";
		
		msg = msg.replace(bmsTypeFrom, bmsTypeFromTxt);
		return msg;
	}
}