<div class="boxs3 margin-top-lg col-lg-12" *ngIf="pmInfo">
	<div class="col-lg-12 margin-top-sm margin-bottom-sm">
		<div class="col-lg-2">
			<strong>ID</strong>
		</div>
		<div class="col-lg-10">
			{{pmInfo.id}}
		</div>
	</div>
	<div class="col-lg-12 margin-bottom-sm">
		<div class="col-lg-2">
			<strong>Serial Number</strong>
		</div>
		<div class="col-lg-10">
			{{pmInfo.serialnumber}}
		</div>
	</div>
	<div class="col-lg-12 margin-bottom-sm">
		<div class="col-lg-2">
			<strong>Last Known Charger</strong>
		</div>
		<div class="col-lg-10">
			<a [routerLink]="['/',latestChargerInfo.customerid, latestChargerInfo.siteid, 'chargers', 'analytics', 'cycles', latestChargerInfo.id]">{{pmInfo.last_known_charger}}</a>
		</div>
	</div>
	<div class="col-lg-12 margin-bottom-sm">
		<div class="col-lg-2">
			<strong>CM Tests</strong>
		</div>
		<div class="col-lg-10">
			<div *ngFor="let test of pmInfo.cm_tests" class="margin-top">
				<i 
					class="fa fa-lg margin-right-sm text-info"
					[ngClass]="test.expanded ? 'fa-minus-square' : 'fa-plus-square'" 
					aria-hidden="true"
					(click)="test.expanded = !test.expanded"
				></i>
				Test Info
				<div *ngIf="test.expanded" class="row margin-left">
					<div *ngFor="let key of test | keys">
						<div class="col-lg-4" *ngIf="key != 'expanded'">
							<strong>{{key}}:</strong> {{test[key]}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-lg-12 margin-bottom-sm">
		<div class="col-lg-2">
			<strong>Chargers Info</strong>
		</div>
		<div class="col-lg-10">
			<div *ngFor="let charger of pmInfo.chargers_info" class="margin-top">
				<i 
					class="fa fa-lg margin-right-sm text-info"
					[ngClass]="charger.expanded ? 'fa-minus-square' : 'fa-plus-square'" 
					aria-hidden="true"
					(click)="charger.expanded = !charger.expanded"
				></i>
				{{charger.SN}}
				<div *ngIf="charger.expanded" class="row margin-left">
					<div class="col-lg-2">
						<strong>Revision:</strong> {{charger.revision}}
					</div>
					<div class="col-lg-3">
						<strong>Model:</strong> {{charger.model}}
					</div>
					<div class="col-lg-7" *ngIf="charger.time">
						<strong>Time:</strong> {{charger.time * 1000 | date:'MM/dd/yyyy hh:mm:ss a'}}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="showError">
	<div class="panel-body">
		<div class="alert alert-danger">
			<strong>No PM Found!</strong>
		</div>
	</div>
</div>