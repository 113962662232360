<app-modal #modal [title]="deleteTitle">{{deleteMsg}}</app-modal> 
<div>
	<div>
		<button type="button" [routerLink]="['edit', 'customerId', 0]" class="btn btn-primary mb20">Add New <span class="glyphicon glyphicon-plus"></span></button>
	</div>
	<div class="boxs">
		<div class="col-md-12">
			<div class="col-md-3 padding-8">
				<div class="col-md-4 text-right"><strong>Get by:</strong></div>
				<div class="col-md-8">
					<select [(ngModel)]="Options.getBy" class="form-control">
						<option *ngFor="let option of filterOptions" [value]="option">{{option}}</option>
					</select>
				</div>
			</div>

			<div class="col-md-8 padding-8">
				<div class="col-md-2 text-right"><strong>Name Index:</strong></div>
				<div class="col-md-6">
					<select [(ngModel)]="Options.nameIndex" class="form-control">
						<option *ngFor="let char of alphabets" [value]="char">{{char}}</option>
					</select>
				</div>
			</div>
			
			<div class="col-md-8 col-md-offset-3 padding-8">
				<div class="col-md-2 text-right"><strong>Dealer Name:</strong></div>
				<div class="col-md-6">
					<ng-select [items]="dealersList" bindLabel="name" bindValue="id" name="dealer" [(ngModel)]="Options.dealerid" required>
					</ng-select>
				</div>
				<div class="col-md-3 col-md-offset-1 padding-8">
					<button class="form-control btn btn-success" (click)="searchForCustomers()" [disabled]="!Options.getBy || (Options.getBy == 'Name index' && !Options.nameIndex) || (Options.getBy == 'Dealer' && !Options.dealerid) || ((Options.getBy == 'Match any' || Options.getBy == 'Match both') && (!Options.dealerid || !Options.nameIndex))">Get Customers</button>
				</div>
			</div>
		</div>

		<table class="table table-striped">
			<thead>
				<tr>
					<th>Name</th>
					<th>OEMs</th>
					<th>Dealers</th>
					<th>Service Dealers</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
			<tr *ngFor="let customer of clients">
				<td>{{customer.name}}</td>
				<td><div *ngFor="let oem of customer.oems">{{oem.name}}</div></td>
				<td><div *ngFor="let dealer of customer.dealers">{{dealer.name}}</div></td>
				<td><div *ngFor="let serviceDealer of customer.serviceDealers">{{serviceDealer.name}}</div></td>
				<td>			
					<div class="btn-group" appDropdown>
						<i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
						<ul class="dropdown-menu" role="menu">
							<li><a (click)="goToEditCustomer(customer.id)" href="javascript:;">Edit</a></li>
							<li><a (click)="showCustomerDeleteModal(customer.id)" href="javascript:;" *ngIf="currentUser.isACTuser">Delete</a></li>
							<li><a [routerLink]="['/sites', 'customerId', customer.id]">Sites</a></li>
							<li *ngIf="currentUser.isACTuser">
								<a [routerLink]="['/equipments-devices', 'customer', customer.id]">Equipments</a>
							</li>
						</ul>
					</div>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>