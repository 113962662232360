<app-site-widget
	title="Missed Connection Times"
	elementId="missed_connection_plugged_in"
	chartType="column"
	[tableColumns]="tableColumns"
	[siteWidgetOptions]="{hasDate: true, isMaterialChart: true}"
	[widgetOptions]="{}"
	[data]="data"
	[config]="config"
	[siteId]="siteId"
	[customerId]="customerId"
	[customerName]="customerName"
	[siteName]="siteName"
></app-site-widget>