<div class="row">
	<div class="col-md-12 panel panel-default">
		<div class="panel-body">
			<div class="col-md-12">
				<div class="col-md-6 padding-8">
					<div class="col-md-3 col-md-offset-2 text-right"><strong>Day:</strong></div>
					<div class="col-md-7">
						<select [(ngModel)]="filter.date" class="form-control">
							<option *ngFor="let option of daysList" [value]="option.value">{{option.text}}</option>
						</select>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="col-md-6 padding-8">
					<div class="col-md-3 col-md-offset-2 text-right"><strong>Hour:</strong></div>
					<div class="col-md-7">
						<select [(ngModel)]="filter.hour" class="form-control">
							<option *ngFor="let option of hoursList" [value]="option.value">{{option.text}}</option>
						</select>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="col-md-6 padding-8">
					<div class="col-md-3 col-md-offset-2 text-right"></div>
					<div class="col-md-3">
						<button class="form-control btn btn-success" (click)="getData()">Get Logs</button>
					</div>
				</div>
			</div>

			<table class="boxs3 table table-striped">
				<thead>
					<tr>
						<th class="col-md-1">Time</th>
						<th class="col-md-1">Sched Name</th>
						<th class="col-md-1">Status</th>
					</tr>
				</thead>
				<tbody>
				<tr *ngFor="let sched of logData">
					<td title="Time">{{sched.time}}</td>
					<td title="Sched Name">{{sched.name}}</td>
					<td title="Status">{{sched.action}}</td>
				</tr>
				</tbody>
			</table>
			<pagination [allItems]="schedData" (onPageChanged)="loadData($event)"></pagination>
		</div>
	</div>
</div>