import { Component, OnInit, OnDestroy } from '@angular/core';

import * as _ from 'underscore';

import { DeviceManagementService } from '../device-management.service';
import { CommonUtil } from '../../shared/services/utility/common.service';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-blocked-fw-reports',
	templateUrl: './blocked-fw-reports.component.html',
	styleUrls: ['./blocked-fw-reports.component.css']
})
export class BlockedFwReportsComponent implements OnInit, OnDestroy {

	reportData: any = {};

	constructor(
		private deviceManagementService: DeviceManagementService,
		private commonUtil: CommonUtil,
		private breadcrumbService: BreadcrumbService,
		private notificationMessageService: NotificationMessageService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.breadcrumbService.setBreadcrumb([{'label': this.translateService.instant('site.blocked_fw_reports'), 'url': ''}]);
		this.deviceManagementService.getBlockedFWReport().subscribe(
			(data: any) => {
				let filteredChargers = [];
				data.chargers.forEach((ch) => {
					let lastconnecttime = new Date(ch.lastconnecttime).getTime() / 1000;
					let last7DaysTime = (new Date().getTime() / 1000) - 60*60*24*7;
					if(lastconnecttime > last7DaysTime && !ch.isdeleted) {
						filteredChargers.push(ch);
					}
				});
				this.reportData.chargers = this.commonUtil.arrayToAssociativeArray(filteredChargers);
				let filteredBV = [];
				data.battviews.forEach((bv) => {
					let lastconnecttime = new Date(bv.lastconnecttime).getTime() / 1000;
					let last7DaysTime = (new Date().getTime() / 1000) - 60*60*24*7;
					if(lastconnecttime > last7DaysTime && !bv.isdeleted) {
						filteredBV.push(bv);
					}
				});
				this.reportData.battviews = this.commonUtil.arrayToAssociativeArray(filteredBV);

				let filteredData = this.filterData(data);
				this.reportData.charger = filteredData.charger;
				this.reportData.battview = filteredData.battview;
			}
		);
	}
	
	filterData(data) {
		let filteredData: any = {};
		filteredData.charger = {};
		filteredData.battview = {};

		let plcItems = [];
		data.charger.plc.forEach((chargerId) => {
			if(this.reportData.chargers[chargerId]) {
				plcItems.push(chargerId);
			}
		});
		filteredData.charger.plc = plcItems;
		let wifiItems = [];
		data.charger.wifi.forEach((chargerId) => {
			if(this.reportData.chargers[chargerId.id]) {
				wifiItems.push(chargerId);
			}
		});
		filteredData.charger.wifi = wifiItems;
		let deviceItems = [];
		data.charger.device.forEach((item) => {
			if(this.reportData.chargers[item.id]) {
				deviceItems.push(item);
			}
		});
		filteredData.charger.device = deviceItems;

		// bv
		let bvPlcItems = [];
		data.battview.plc.forEach((battviewId) => {
			if(this.reportData.battviews[battviewId]) {
				bvPlcItems.push(battviewId);
			}
		});
		filteredData.battview.plc = bvPlcItems;
		let bvWifiItems = [];
		data.battview.wifi.forEach((battviewId) => {
			if(this.reportData.battviews[battviewId.id]) {
				bvWifiItems.push(battviewId);
			}
		});
		filteredData.battview.wifi = bvWifiItems;
		let bvDeviceItems = [];
		data.battview.device.forEach((item) => {
			if(this.reportData.battviews[item.id]) {
				bvDeviceItems.push(item);
			}
		});
		filteredData.battview.device = bvDeviceItems;

		return filteredData;
	}

	ngOnDestroy() {
		this.breadcrumbService.hide();
	}

	unBlock(deviceType, section, id, idx, version) {
		this.deviceManagementService.unBlockFWReport(deviceType, section, id, version).subscribe(
			() => {
				// success message then reload
				this.notificationMessageService.setMessage('globalSuccessMsg');
				this.reportData[deviceType][section].splice(idx, 1);
			}
		);
	}
}