
import {filter} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { NavigationService } from './shared/services/navigation.service';
import { SideMenuService } from './shared/side-menu/side-menu.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent {

	currentUrl: string = '';
	versionKey: string = 'current-version';
	hideMenuView = false;
	showRefreshMessage: boolean = false;
	enableRefreshMessage: boolean = false;

	constructor(
		public translate: TranslateService,
		public sideMenu: SideMenuService,
		private router: Router,
		private navigation: NavigationService,
		private httpClient: HttpClient
	) {

		translate.addLangs(['en']);
		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang('en');

		/**
		 * Fetch default language from one of below:
		 * 1- URL
		 * 2- Cookie
		 * 3- Session
		 * 4- User pref.
		 */
		// translate.use('es');
		
		this.sideMenu.hideMenuView.subscribe(value => {
			this.hideMenuView = value;
		});

		router.events.pipe(
		filter(event => event instanceof NavigationEnd))
		.subscribe((e: NavigationEnd) => {

			navigation.updateNavigationUrl(e);
		});

		router.events.pipe(
		filter(event => event instanceof NavigationStart))
		.subscribe(eve => {
			const ver = localStorage.getItem(this.versionKey);
			const enableRefreshMessage = this.enableRefreshMessage;

			this.httpClient.post('/auth/updateCheck', {ver}, {
				observe: "body"
			}).subscribe((response: any) => {
				if(response.newVersion) {
					localStorage.setItem(this.versionKey, response.newVersion);
					if(ver)
						this.showRefreshMessage = enableRefreshMessage;
				}
				this.enableRefreshMessage = true;
			});
		});

		this.httpClient.get('/auth/getGitData', {
			observe: "body"
		}).subscribe((response: any) => {
			if(Object.keys(response).length > 0)
				console.log("%c"+JSON.stringify(response), "color: red; font-size: x-large");
		});
	}
}