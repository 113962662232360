import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '../../admin.service';
import * as moment from 'moment';
import { CommonUtil } from '../../../shared/services/utility/common.service';

@Component({
	selector: 'app-sched-history',
	templateUrl: './sched-history.component.html',
	styleUrls: ['./sched-history.component.css']
})
export class SchedHistoryComponent implements OnInit {

	daysList = [];
	hoursList = [];
	filter: any = {
		date: '',
		hour: '00'
	};
	schedData: any = [];
	logData: any = [];

	constructor(
		private breadcrumb: BreadcrumbService,
		private translateService: TranslateService,
		private adminService: AdminService,
		private commonUtil: CommonUtil
	) { }

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([{
			'label': this.translateService.instant('nav.sched_history'),
			'url': ''
		}]);
		this.prepareFilters();
	}

	prepareFilters() {
		let startDate = moment().utc().add(-6, 'days');
		let endDate = moment().utc();

		while(startDate <= endDate) {
			let value = startDate.format('YYYY_MM_DD');
			this.daysList.push({
				value,
				text: startDate.format('LL')
			});
			startDate.add(1, 'days');
			this.filter.date = value;
		}

		for(let i = 0; i < 24; i++) {
			this.hoursList.push({
				value:this.commonUtil.lpad((''+i),2, '0'),
				text: this.commonUtil.lpad((''+i),2, '0')
			});
		}
	}

	getData() {
		let schedData = [];
		this.adminService.getSchedFunctionsHistory({
			date: this.filter.date,
			hour: this.filter.hour
		}).subscribe((data: any) => {
			for (let row of data) {
				schedData.push(
					JSON.parse(row)
				);
			}
			this.schedData = schedData;
		});
	}

	loadData(data) {
		this.logData = data;
	}
}
