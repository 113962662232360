<form #editAccessFunctionsForm="ngForm" class="form-auth" (ngSubmit)="updateFunctionManagement()">
    <div class="container-fluid" id="main">
      <div class="row">
        <div class="col-md-6 col-md-offset-1" id="left">
          <div class="boxs3">
            <h2>Edit Access Functions</h2>
            <div class="form-field">
              <label for="customer">Access Functions</label>
              <div class="col-md-12" *ngFor="let accessObj of user.accessfunctions; let idx = index;">
                <div class="col-md-6">
                  {{accessFunctions[accessObj.id].title}}
                </div>
                <div class="col-md-4">
                  <select *ngIf="accessFunctions[accessObj.id].hasReadOption" [(ngModel)]="accessObj.access" class="form-control" name="access-{{accessFunctions[accessObj.id].title}}">
                    <option *ngFor="let opt of accessOptions" [value]="opt">{{opt}}</option>
                  </select>
                  <select *ngIf="!accessFunctions[accessObj.id].hasReadOption" [(ngModel)]="accessObj.access" class="form-control" name="bool-access-{{accessFunctions[accessObj.id].title}}">
                    <option *ngFor="let opt of boolAccessOptions" [value]="opt">{{opt}}</option>
                  </select>
                </div>
                <div class="col-md-2">
                  <a class="pointer" title="Remove" (click)="editAccessFunction('del', idx)">X</a>
                </div>
              </div>
            </div>
            <div *ngFor="let group of accessFunctionsGroups">
              <div class="form-field col-md-12 padding-8">
                <h2>{{group}}</h2>
                <div class="col-md-6">
                  <ng-select [items]="accessFunctionsArr[group]" bindLabel="title" placeholder="Select Function..." name="access-function-{{group}}" [(ngModel)]="newAccessFunction" [clearable]="false">
                  </ng-select>
                </div>
                <div class="col-md-4" *ngIf="newAccessFunction">
                  <select *ngIf="newAccessFunction.hasReadOption" [(ngModel)]="newAccessFunction.access" class="form-control" name="new-access">
                    <option *ngFor="let opt of accessOptions" [value]="opt">{{opt}}</option>
                  </select>
                  <select *ngIf="!newAccessFunction.hasReadOption" [(ngModel)]="newAccessFunction.access" class="form-control" name="new-bool-access">
                    <option *ngFor="let opt of boolAccessOptions" [value]="opt">{{opt}}</option>
                  </select>
                </div>
                <div class="col-md-2">
                  <button type="button" class="btn btn-success" (click)="editAccessFunction('add', newAccessFunction, group)" [disabled]="!newAccessFunction.access || !newAccessFunction.title || editAccessFunctionsForm.invalid">Add</button>
                </div>
              </div>
            </div>
            <br/>
            <button type="submit" class="btn btn-primary">Submit</button>
            <button type="button" class="btn btn-default" (click)="navigation.goBack()">Cancel</button>
          </div>
        </div>
        <div class="col-md-4" id="right">
          <div class="boxs3" style="padding-bottom: 200px;">
            <h2>Access Functions Documentation</h2>
            <div *ngFor="let group of accessFunctionsGroups">
              <ng-select [items]="accessFunctionsDesc[group]" bindLabel="title" placeholder="Select Function..." name="doc-access-function" [(ngModel)]="docAccessFunction" class="margin-bottom" [clearable]="false">
              </ng-select>
              <div class="body-bg-color padding-8" *ngIf="docAccessFunction && docAccessFunction.desc && docAccessFunction.desc.length > 0">
                <p *ngFor="let item of docAccessFunction.desc">
                  <span class="glyphicon glyphicon-check"></span>&nbsp;<span [innerHTML]="item | safe: 'html'"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>