<form #dealerForm="ngForm" (ngSubmit)="updateDealer()">
	<div class="row">
		<div class="col-md-6 col-md-offset-3">
			<div class="boxs3">
				<h2>Add/Update Dealer Info</h2>
				<div class="panel panel-default">
					<div class="panel-heading">Dealer Info</div>
				</div>
				<div class="form-field">
					<label for="name">Dealer Name</label>
					<input type="text" class="form-control" name="dealerName" [(ngModel)]="dealer.name" required placeholder="Dealer Name" />
				</div>
				<br/>
				<div class="form-field">
					<label for="name">ACT Employee</label>
					<ng-select [items]="ACTusers" bindLabel="name" bindValue="id" placeholder="Select ACT Contact Person" name="ACTuserID" [(ngModel)]="dealer.act_user_id" required [clearable]="false">
					</ng-select>
				</div>
				<br/>
				<button type="submit" class="btn btn-primary" [disabled]="dealerForm.invalid || dealer.name.trim() == ''">Submit</button>
				<button type="button" class="btn btn-default" [routerLink]="[isTruckDealer ? '/truck-dealers' : '/dealers']">Cancel</button>
			</div>
		</div>
	</div>
</form>