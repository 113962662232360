import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChargerService } from '../../../admin/devices/chargers/charger.service';
import { BattviewService } from '../../../admin/devices/battviews/battview.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { DeviceManagementService } from '../../device-management.service';
import { UserService } from '../../../auth/user.service';
import { SiteDashboardService } from '../../../home/site-dashboard/site-dashboard.service';
import { BattviewsDashboardService } from '../../../home/site-dashboard/battviews/battviews-dashboard/battviews-dashboard.service';

@Component({
	selector: 'app-dealer-device-by-sn',
	templateUrl: './dealer-device-by-sn.component.html',
	styleUrls: ['./dealer-device-by-sn.component.css']
})
export class DealerDeviceBySnComponent implements OnInit, OnDestroy {

	type = 'charger';
	devicesInfo = [];
	searchEnabled = false;
	serialnumber: string = '';
	noResult: boolean = null;

	constructor(
		private notificationMessage: NotificationMessageService,
		private deviceManagemntService: DeviceManagementService,
		private battviewService: BattviewService,
		private battviewsDashboardService: BattviewsDashboardService,
		private chargerService: ChargerService,
		private breadcrumb: BreadcrumbService,
		public userService: UserService,
		private siteDashboard: SiteDashboardService,
		public commonUtil: CommonUtil
	) { }

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([
			{'label': 'Search Device By SN', 'url': ''}
		]);
	}

	getDeviceType() {
		this.serialnumber = this.serialnumber.trim();
		let isBattview = false;
		this.battviewService.battviewSNRules.forEach((rule) => {
			isBattview = this.commonUtil.dataValidator(rule.type, rule, this.serialnumber);
		});

		if(isBattview)
			return 'battview';

		let isCharger = false;
		this.chargerService.chargerSNRules.forEach((rule) => {
			isCharger = this.commonUtil.dataValidator(rule.type, rule, this.serialnumber);
		});

		if(isCharger)
			return 'charger';

		return false;
	};

	getDeviceBySN() {
		this.devicesInfo = [];

		let type = this.getDeviceType();
		if(!type) {
			this.notificationMessage.setMessage('Invalid serialnumber');
			return;
		}

		this.type = type;
		this.noResult = false;

		let flags = {'isDealerSearch': true};

		this.deviceManagemntService.getDeviceBySN(this.serialnumber, this.type, flags).subscribe(
			(devicesInfo: any) => {
				let devices: any[] = devicesInfo.list;
				if(devices.length === 0) {
					this.noResult = true;
					return;
				}
				this.noResult = false;
				devices.forEach((deviceInfo) => {
					if(this.type == 'battview' && this.commonUtil.arrayCompare([+deviceInfo.oemid, +deviceInfo.dealerid, +deviceInfo.servicedealerid, +deviceInfo.customerid, +deviceInfo.siteid], [0, 0, 0, 0, 0]))
						deviceInfo.isProductionLine = true;
					
					deviceInfo.serialnumber = this.commonUtil.formatSerialNumber(deviceInfo);
					deviceInfo.imageclass = this.getImageClass(deviceInfo, devicesInfo.connectTimes[deviceInfo.id]);
				});
				this.devicesInfo = devices;
			}
		);
	}

	getImageClass(device, connectTimes) {
		let imageClass = '';
		var chargerImageClass;
		if(this.type == 'charger') {
			var modeltype = device.model.split('-')[0];
			if(['Q4', 'Q6', 'Q12'].indexOf(modeltype) == -1)
				modeltype = 'Q12';

			var chargerStatus = 'disconnected-disabled';
			var disconnectLimit	= 2.5 * parseInt(device.actviewconnectfrequency);

			if(connectTimes[device.id])
				device.lastconnecttime = new Date(parseInt(connectTimes[device.id].lastconnecttime) * 1000);
			var disconnectTime: any	= (new Date().getTime() / 1000) - (new Date(device.lastconnecttime).getTime() / 1000); //disconnectTime in seconds
			
			if(device.act_intelligent_type != 0) {

				if(disconnectTime <= disconnectLimit) {

					//Less than 2.5 times of normal connectFrequency
					chargerStatus = 'idle';
				} else if(disconnectTime <= 24*3600) {

					//Less than 24 hrs
					chargerStatus = 'disconnected';
				} else if(disconnectTime > 24*3600 && disconnectTime <= 7*24*3600) {

					//More than 24 hrs and less than a week
					chargerStatus = 'disconnected-day';
				} else if(device.actviewenable) {

					//More than a week disconnectTime > 7*24*3600
					chargerStatus = 'disconnected-week';
				}
			}
			if(this.commonUtil.checkChargerGseModel(device.serialnumber))
				chargerImageClass = 'ch-gse-' + modeltype + '-' + chargerStatus;
			else
				chargerImageClass = 'ch-' + modeltype + '-' + chargerStatus

			imageClass = chargerImageClass + ' ch-' + modeltype + '-thumbnail';
		} else {
			if(device.act_intelligent_type != 0) {

				var connectFrequency = +device.actviewconnectfrequency;

				if(connectFrequency == this.siteDashboard.autoconnectFreq)
					connectFrequency = 900;

				var lastconnecttime	= Math.round(new Date(device.lastconnecttime).getTime() / 1000);
				var nowDate			= Math.round(new Date().getTime() / 1000);
				disconnectTime		= nowDate - lastconnecttime; //disconnectTime in seconds

				let isConnected = (disconnectTime <= (2.5 * connectFrequency));
				imageClass = this.battviewsDashboardService.getBattviewDisconnectionImageClass(disconnectTime, device.actviewenabled, device.act_first_enabled, isConnected);
			} else {

				//More than a week disconnectTime > 7*24*3600
				if(!device.actviewenabled)
					imageClass = 'bv-mobile-not-connected-week';
				else
					imageClass = 'bv-not-connected-week';
			}
		}
		return imageClass;
	}

	SNchanged() {
		if(this.getDeviceType() !== false) {
			this.searchEnabled = true;
		} else {
			this.searchEnabled = false;
		}
	}
	
	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}