import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { SiteDashboardService } from '../../site-dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { DynamicResponseService } from '../dynamic-response.service';
import { NotificationMessageService } from '../../../../shared/notification-message/notification-message.service';
import { UserService } from '../../../../auth/user.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import * as lo from 'lodash';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '../../../../admin/admin.service';

@Component({
	selector: 'app-dr-profiles',
	templateUrl: './dr-profiles.component.html',
	styleUrls: ['./dr-profiles.component.css']
})
export class DrProfilesComponent implements OnInit, OnDestroy {
	hasENERNOCProvider = false;
	siteId: number = 0;
	drProfiles: {normal: any[], none: any[]} = {normal: [], none: []};
	operationTypes: any = {};
	profileTypes: any = {};
	errMsgs = [];
	executeProfileErrMsgs = [];
	currentSiteProvider = this.profileTypes.None;

	modalTitle	= '';
	modalProfile: any = {};
	modalExecuteProfile: any = {};
	modalExecuteWeeklyProfile: any = {};
	currentUser: any = {};
	oldModalProfile: any = {};
	daysOfTheWeek: any[];
	profilesWeeklySchedule: any = {};
	weeklyProfilesWithData:any = {};

	@ViewChild("profileModal") profileModal;
	@ViewChild("executeProfileModal") executeProfileModal;
	@ViewChild("executeWeeklyProfileModal") executeWeeklyProfileModal;

	constructor(
		private siteDashboardService: SiteDashboardService,
		private route: ActivatedRoute,
		private notificationMessage: NotificationMessageService,
		private dynamicResponseService: DynamicResponseService,
		private userService: UserService,
		private commonUtil: CommonUtil,
		private translateService: TranslateService,
		private adminService: AdminService
	) {
		this.operationTypes	= dynamicResponseService.drOperationTypes;
		this.profileTypes	= dynamicResponseService.drTypes;
		this.hasENERNOCProvider = this.adminService.hasENERNOCProvider;
	}

	ngOnInit() {
		this.siteDashboardService.setActiveSubTab('profiles');
		this.siteId = +this.route.parent.parent.snapshot.params.siteid || 0;
		this.getSiteProfiles();
		this.currentUser = this.userService.getCurrentUser();
		this.daysOfTheWeek = this.commonUtil.getDropDownListData('week');
	}

	operationTypeChanged() {
		if(this.modalProfile['operation'] != this.operationTypes["lockout"]
			&& this.oldModalProfile['operation'] == this.operationTypes["lockout"]
		) {
			this.modalProfile['operation_power'] = 1;
		}

		this.validateEditProfileForm();
	}

	allFormRequiredFilled(form) {

		for(var field in form.controls) {
			if(form.controls[field].errors && form.controls[field].errors.required)
				return false;
		}

		return true;
	}

	missedRequiredField(form, field) {
		
		if(form.controls[field] && form.controls[field].errors && form.controls[field].errors.required)
			return true;
		
		return false;
	}

	validateEditProfileForm() {

		let profile	= this.modalProfile;
		let errors	= [];

		//operation
		let isValid = Object.keys(this.operationTypes).includes(profile.operation);

		if(!isValid)
			errors.push(this.translateService.instant('g.invalid_operation'));

		if(profile.operation != this.operationTypes.lockout) {

			//operation_power
			isValid = typeof(+profile.operation_power) == 'number'
						&& profile.operation_power >= 1
						&& profile.operation_power <= 12000;

			if(!isValid)
				errors.push(this.translateService.instant('g.invalid_power_setting'));
		}

		//max_duration
		isValid = typeof(+profile.max_duration) == 'number'
					&& profile.max_duration >= 60
					&& profile.max_duration <= 32000000;

		if(!isValid)
			errors.push(this.translateService.instant('g.invalid_max_duration'));

		this.errMsgs = errors;
		return errors.length == 0;
	}

	getSiteProfiles() {
		this.dynamicResponseService.getSiteProfiles(this.siteId).subscribe((data: {profiles: any[], site: any}) => {
				this.currentSiteProvider = data.site.dr_provider;

				data.profiles.forEach((profile) => {
				if(profile.type == this.profileTypes.None)
					this.drProfiles.none.push(profile);
				else
					this.drProfiles.normal.push(profile);

				this.weeklyProfilesWithData[profile.id] = (Array.isArray(profile.weekly_sched_days) && profile.weekly_sched_days.length > 0); // boolean if this profile have a weekly sched
				this.profilesWeeklySchedule[profile.id] = {days: profile.weekly_sched_days, startTime: profile.weekly_sched_start_exec_time, endTime: profile.weekly_sched_end_exec_time, name: profile.name};
			});
		});
	}

	updateSiteProfile(profile, action, profileIdx = -1) {
		profile.dr_provider = this.hasENERNOCProvider ? this.currentSiteProvider : 'VOLTUS';

		this.errMsgs = [];

		if(action != 'delete' && action != 'disable' && action != 'enable') {

			let isValid = this.validateEditProfileForm();
			
			if(!isValid)
				return;
		}

		if(action == 'add' && this.modalProfile.dr_provider == 'VOLTUS' && !this.hasENERNOCProvider)
			this.modalProfile.type	= this.profileTypes.None;

		this.dynamicResponseService.updateSiteProfile(this.siteId, action, profile).subscribe((data: any) => {
			if(data['httpStatus'] == 'error') {
				this.errMsgs = data['msgs'];
				return;
			}
			
			this.modalProfile = {};
			this.oldModalProfile = {};
			this.notificationMessage.setMessage('globalSuccessMsg');

			if(profile.id) {

				if(action == 'delete') {
					
					if(this.drProfiles.none[profileIdx])
						this.drProfiles.none.splice(profileIdx, 1);
				} else if (action == 'disable') {
					this.drProfiles.normal[profileIdx].is_disabled = true;
				} else if (action == 'enable') {
					this.drProfiles.normal[profileIdx].is_disabled = false;
				} else {
					//edit
					let section = profile.section;
					this.drProfiles[section][profile.idx] = profile;
					this.profileModal.hide();
				}
			} else {
				//add
				profile.id = data.id;
				profile.provider = this.currentSiteProvider;

				if (!profile.type)
					profile.type = this.profileTypes.None;

				if(profile.type && profile.type != this.profileTypes.None)
					this.drProfiles.normal.push(profile);
				else
					 this.drProfiles.none.push(profile);

				this.profilesWeeklySchedule[profile.id] = {days: [], startTime: null, endTime: null, name: ''};
				this.weeklyProfilesWithData[profile.id] = false;
				this.profileModal.hide();
			}
		});
	}

	executeProfile() {
		var fromDate	= (new Date(this.modalExecuteProfile.startTime).getTime() - new Date(this.modalExecuteProfile.startTime).getTimezoneOffset() * 60000) / 1000;
		var toDate		= (new Date(this.modalExecuteProfile.endTime).getTime() - new Date(this.modalExecuteProfile.endTime).getTimezoneOffset() * 60000) / 1000;

		this.executeProfileErrMsgs = [];

		if(toDate <= fromDate) {
			this.executeProfileErrMsgs.push(this.translateService.instant('time.end_start_date'));
			return;
		}

		var event = {
			profile_id: this.modalExecuteProfile.profileId,
			site_id: this.siteId,
			start_execution_time: fromDate,
			end_execution_time: toDate,
			type: 'manual',
			provider: this.currentSiteProvider
		};

		this.dynamicResponseService.addProfileEvent(this.siteId, event).subscribe((data: any) => {
			
			if(data['httpStatus'] == 'error') {
				this.executeProfileErrMsgs = data['msgs'];
				return;
			}
			
			this.modalExecuteProfile = {};
			this.notificationMessage.setMessage('globalSuccessMsg');
			this.executeProfileModal.hide();
		});
	}

	validateWeeklyProfile(){
		// if all empty, then its valid
		if( (!Array.isArray(this.modalExecuteWeeklyProfile.days) || this.modalExecuteWeeklyProfile.days.length == 0) && !this.modalExecuteWeeklyProfile.startTime && !this.modalExecuteWeeklyProfile.endTime ) return true;

		// if one or more is empty, then its invalid
		if( (!Array.isArray(this.modalExecuteWeeklyProfile.days) || this.modalExecuteWeeklyProfile.days.length == 0) || !this.modalExecuteWeeklyProfile.startTime || !this.modalExecuteWeeklyProfile.endTime){
			this.executeProfileErrMsgs.push(this.translateService.instant('g.please_fill_all_fields'));
			return false;
		}

		var fromDate	= this.commonUtil.clockTimeToTimeSpan(this.modalExecuteWeeklyProfile.startTime);
		var toDate		= this.commonUtil.clockTimeToTimeSpan(this.modalExecuteWeeklyProfile.endTime);

		if(toDate <= fromDate){
			this.executeProfileErrMsgs.push(this.translateService.instant('time.end_start_time'));
			return false;
		}

		for(let loopProfileId in this.profilesWeeklySchedule){
			if(loopProfileId == this.modalExecuteWeeklyProfile.profileId) continue;
			let _profile = this.profilesWeeklySchedule[loopProfileId];
			if(lo.intersection(_profile.days, this.modalExecuteWeeklyProfile.days).length > 0){
				if ((_profile.startTime <= fromDate && fromDate <= _profile.endTime) || (_profile.startTime <= toDate && toDate <= _profile.endTime)){
					this.executeProfileErrMsgs.push(this.translateService.instant('act.there_is_an_overlap_with_profile') + ': '+_profile.name);
					return false;
				}
			}
		}
		return true;
	}

	descheduleWeeklyProfile(){
		let profileId = this.modalExecuteWeeklyProfile.profileId;
		this.modalExecuteWeeklyProfile = {
			profileId: profileId,
			days: [],
			startTime: null,
			endTime: null,
		};
		this.executeWeeklyProfile();
		this.weeklyProfilesWithData[profileId] = false;
	}

	executeWeeklyProfile() {
		var fromDate	= (this.modalExecuteWeeklyProfile.startTime ? this.commonUtil.clockTimeToTimeSpan(this.modalExecuteWeeklyProfile.startTime) : null);
		var toDate		= (this.modalExecuteWeeklyProfile.endTime ? this.commonUtil.clockTimeToTimeSpan(this.modalExecuteWeeklyProfile.endTime) : null);
		var days		= this.modalExecuteWeeklyProfile.days;

		this.executeProfileErrMsgs = [];

		if(!this.validateWeeklyProfile())
			return;

		var weeklyEvent = {
			profile_id: this.modalExecuteWeeklyProfile.profileId,
			site_id: this.siteId,
			weekly_sched_days: days,
			weekly_sched_start_exec_time: fromDate,
			weekly_sched_end_exec_time: toDate,
		};

		this.dynamicResponseService.addProfileWeeklyEvent(this.siteId, weeklyEvent).subscribe((data: any) => {
			if(data['httpStatus'] == 'error')
				return this.executeProfileErrMsgs = data['msgs'];

			this.modalExecuteWeeklyProfile = {};
			this.notificationMessage.setMessage('globalSuccessMsg');
			this.executeWeeklyProfileModal.hide();

			this.profilesWeeklySchedule[weeklyEvent.profile_id] = {
				days: weeklyEvent.weekly_sched_days,
				startTime: weeklyEvent.weekly_sched_start_exec_time,
				endTime: weeklyEvent.weekly_sched_end_exec_time,
				name: this.profilesWeeklySchedule[weeklyEvent.profile_id].name,
			};

			this.weeklyProfilesWithData[weeklyEvent.profile_id] = (Array.isArray(weeklyEvent.weekly_sched_days) && weeklyEvent.weekly_sched_days.length > 0);

			this.drProfiles.none.forEach(_profile => {
				if(_profile.id != weeklyEvent.profile_id) return;
				_profile.weekly_sched_days = days;
				_profile.weekly_sched_start_exec_time = fromDate;
				_profile.weekly_sched_end_exec_time = toDate;
			});
		});
	}

	openProfileModal(passProfile = {}, profileIdx = -1, section = '') {
		let action = "add";

		if(profileIdx >= 0) {
			action = "edit";
			passProfile['idx'] = profileIdx;
		}
		
		this.modalProfile = Object.assign({}, passProfile);
		this.modalProfile['action'] = action;
		this.modalProfile['section'] = section;

		if (!this.hasENERNOCProvider) {
			this.modalProfile['provider'] = 'VOLTUS';
			this.modalProfile['type'] = this.profileTypes.None;
		}

		this.modalTitle = this.modalProfile['id'] ? this.translateService.instant('act.edit_profile') : this.translateService.instant('act.add_profile');
		this.errMsgs	= [];
		this.oldModalProfile = Object.assign({}, this.modalProfile);
		
		this.profileModal.show();
	}

	openExecuteProfileModal(profileId = -1) {
		this.modalExecuteProfile = {};
		if(profileId >= 0) {
			this.modalExecuteProfile['profileId'] = profileId;
		}
		this.executeProfileErrMsgs	= [];
		this.executeProfileModal.show();
	}
	openExecuteWeeklyProfileModal(profileId) {
		this.executeProfileErrMsgs	= [];
		var midNight = moment().startOf('day').format();

		this.modalExecuteWeeklyProfile = {
			profileId: profileId,
			days: this.profilesWeeklySchedule[profileId].days,
			startTime: this.profilesWeeklySchedule[profileId].startTime ? moment(midNight).add(this.profilesWeeklySchedule[profileId].startTime, 'seconds').format('HH:mm'): null,
			endTime: this.profilesWeeklySchedule[profileId].endTime ? moment(midNight).add(this.profilesWeeklySchedule[profileId].endTime, 'seconds').format('HH:mm'): null,
		};
		this.executeWeeklyProfileModal.show();
	}

	ngOnDestroy() {
		this.executeProfileModal.onClose.unsubscribe();
		this.executeWeeklyProfileModal.onClose.unsubscribe();
	}

	convertWeeklySchedDaysToString(days){
		if(!days || days.length == 0) return '';
		var str  = [];
		var weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
		days.forEach(day => {
			str.push(this.translateService.instant('time.'+weekDays[day]));
		});
		return str.join('/');
	}
	convertWeeklySchedTimespanToHoursMinutes(timespan){
		if(timespan === null) return '';
		var midNight = moment().startOf('day').format();
		return moment(midNight).add(timespan, 'seconds').format('LT');
	}
}