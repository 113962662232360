<div class="production-performance h-100 w-100">
	<div class="w-100 select-year-container">
		<div>
			<button class="fa fa-upload btn btn-primary margin-top-sm ng-isolate-scope" title="{{'g.export_to_csv' | translate}}" (click)="onPrintClick()">
			</button>
		</div>
		<div>
			<label for="year">
				<span>
					{{'g.select_year' | translate}}
				</span>
				<select id="year" class="form-control inline-form-control" [(ngModel)]="selectedYear" (ngModelChange)="drawChart()">
					<option *ngFor="let year of yearList" [value]="year">{{year}}</option>
				</select>
			</label>
		</div>
	</div>
	<google-chart *ngIf="chart && selectedIps.length > 0" [data]="chart"></google-chart>
	<div *ngIf="!chart || selectedIps.length === 0" class="alert alert-warning" role="alert">
		{{'g.no_data_available_to_draw_this_chart' | translate}}
	</div>
	<div class="footer">
		<label *ngFor="let ip of allowedIPs" class="ip-item" [for]="ip.ip">
			<input  (ngModelChange)="ipChanged($event, ip.ip)" id="{{ip.ip}}" [value]="ip.ip" type="checkbox" [(ngModel)]="ip.checked" name="ip">
			{{mapIpsNames[ip.ip] || ip.ip}}
		</label>
	</div>
</div>

