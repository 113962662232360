<app-user-search-options [userTypes]="userTypes" pageName="customized_emails" (onGetResult)="onUserSearch($event)" #searchOptions></app-user-search-options>

<div class="panel panel-default" *ngIf="getUsersBy == 'search' && users.length > 0">
	<div class="panel-body">
		<div class="padding-8">
			<table class="boxs table table-striped">
				<thead>
					<tr>
						<th>First Name</th>
						<th>Last Name</th>
						<th>Email</th>
					</tr>
				</thead>
				<tbody>
				<tr *ngFor="let user of users">
					<td>{{user.firstname}}</td>
					<td>{{user.lastname}}</td>
					<td>{{user.email}}</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<div class="panel panel-default">
	<div class="panel-body">
		<div class="col-md-8">
			<div class="col-md-12 padding-8">
				<div class="col-md-2">
					<strong>To:</strong>
				</div>
				
				<div class="col-md-10">
					<ng-select [items]="users" bindLabel="name" bindValue="id" multiple="true" [addTag]="true" [hideSelected]="true" placeholder="Select user..." name="customerid" [(ngModel)]="email.to" [clearable]="false" addTagText='' (add)="checkUser($event)">
					</ng-select>
				</div>
			</div>
			
			<div class="col-md-12 padding-8">
				<div class="col-md-2">
					<strong>Subject:</strong>
				</div>
				<div class="col-md-10">
					<input type="text" class="form-control" [(ngModel)]="email.subject" placeholder="Subject" />
				</div>
			</div>
			
		</div>

		<div class="col-md-4 padding-8">
			<button class="btn btn-success tcenter" style="padding:10px 20px" type="button" (click)="sendCustomizedEmail()" [disabled]="email.to.length == 0 || !email.subject || !email.body"><span class="glyphicon glyphicon-send"></span><br/>Send<br/>Email</button>
		</div>
		<div class="col-md-12 padding-8">
			<editor [init]="editorConfig" [apiKey]="apiKey" [(ngModel)]="email.body"></editor>
		</div>
	</div>
</div>