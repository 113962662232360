import { Component, OnInit, OnDestroy } from '@angular/core';
import { DeviceManagementService } from '../device-management.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { CommonUtil } from '../../shared/services/utility/common.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { UserService } from '../../auth/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-pending-approval-requests',
	templateUrl: './pending-approval-requests.component.html',
	styleUrls: ['./pending-approval-requests.component.css']
})
export class PendingApprovalRequestsComponent implements OnInit, OnDestroy {

	tab: string;
	subTab: string;
	deviceID: number;
	chargersList: any[] = [];
	chargers: any = {};
	chargersInfo: any;
	userRoles: any; // globals.userRoles
	usersInfo: any;
	oemsInfo: any;
	dealersInfo: any = {};
	customersInfo: any = {};
	sitesInfo: any = {};
	massChargers: any[];
	disableChargerApprove: boolean;
	battviewsList: any[] = [];
	battviews: any = {};
	battviewsInfo: any;
	massBattviews: any[];
	disableBattviewApprove: boolean;
	customersList: any[];
	sitesList: any[];
	devicesIDs: string;
	chargersListObj: any;
	battviewsListObj: any;

	constructor(
		private deviceManagemntService: DeviceManagementService,
		private route: ActivatedRoute,
		private breadcrumb: BreadcrumbService,
		private notificationMessage: NotificationMessageService,
		private router: Router,
		private commonUtil: CommonUtil,
        public userService: UserService,
        private translateService: TranslateService
	) {
	}
	
	ngOnInit() {
		this.breadcrumb.setBreadcrumb([
			{label: 'Pending Approval Requests', url: ''}
		]);
		this.route.params.subscribe(
			params => {
				this.tab = params.tab;
				this.subTab = params.subTab;
				this.deviceID = +(params.deviceID) || 0;

				if(this.tab == 'devices') {
					this.getDeviceOwnerChangeRequests();
					this.resetSelections();
				} else if(this.tab == 'customer-site') {
					this.getPendingCustomerSite();
				}
			}
		);
	}

	isTabSet(tabId, isSubTab: boolean = false) {
		if(isSubTab)
			return this.subTab === tabId;
		return this.tab === tabId;
	}

	setTab(tabId, isSubTab: boolean = false) {
		if(isSubTab) {
			this.subTab = tabId;
		} else {
			this.tab = tabId;
			if(this.tab == 'devices' && ['charger', 'battview'].indexOf(this.subTab) == -1)
				this.subTab = 'charger';
			else if(this.tab == 'customer-site' && ['customers', 'sites'].indexOf(this.subTab) == -1)
				this.subTab = 'customers';
		}

		this.router.navigate(['/pending-approval-requests/', this.tab, this.subTab]);
	}

	resetSelections() {
		this.massBattviews	= [];
		this.massChargers		= [];
		this.devicesIDs		= "";
	};

	goToEditSite(customerId, siteId, proceed?) {
		if(!proceed && this.sitesInfo && this.sitesInfo[siteId]) {
			proceed = !this.sitesInfo[siteId].isverified;
		}
		if(proceed) {
			this.router.navigate(['/sites/edit/customerId/'+customerId+'/siteId/'+siteId]);
			// $state.go('site_edit', {
				//   'siteId': siteId,
				//   'customerId': customerId,
				//   'siteName': siteName,
				//   'returnTo': {
					//     'tab': $scope.tab,
					//     'subTab': $scope.subTab
					//   }
					// });
		}
	};
			
	goToEditCustomer(customerId, proceed?) {
		if(!proceed && this.customersInfo && this.customersInfo[customerId]) {
			proceed = !this.customersInfo[customerId].isverified;
		}
		if(proceed) {
			this.router.navigate(['/customers/edit/customerId/'+customerId]);
			// $state.go('customer_edit', {
			//   'customerId': customerId,
			//   'customerName': customerName,
			//   'returnTo': {
			//     'tab': $scope.tab,
			//     'subTab': $scope.subTab
			//   }
			// });
		}
	};

	getDeviceOwnerChangeRequests() {
		this.deviceManagemntService.getDeviceOwnerChangeRequests(this.subTab).subscribe(
			devices => {
				if(!devices) {
					return;
				}
				this.usersInfo		  = devices['usersInfo'];
				this.oemsInfo			  = devices['oemsInfo'];
				this.dealersInfo		= devices['dealersInfo'];
				this.customersInfo	= devices['customersInfo'];
				this.sitesInfo		  = devices['sitesInfo'];

				if(this.subTab == 'charger') {

					this.chargersList		= devices['chargersList'];
					this.chargersListObj	= this.commonUtil.arrayToAssociativeArray(this.chargersList, 'deviceid');

					this.chargersInfo		= devices['chargersInfo'];

				} else if(this.subTab == 'battview') {

					this.battviewsList	= devices['battviewsList'];
					this.battviewsListObj	= this.commonUtil.arrayToAssociativeArray(this.battviewsList, 'deviceid');
					
					this.battviewsInfo	= devices['battviewsInfo'];
				}
				if(this.deviceID) {
					this.updateMassValues(this.subTab, this.deviceID, true, true);
					// @todo: @olabi handle below old code
					// $anchorScroll.yOffset = 90;
					// $location.hash(this.subTab + '-' + this.deviceID);
					// $anchorScroll();
					// $timeout(function() {
					//   $('#' + this.subTab  + 'Check-' + this.deviceID).attr('checked', true);
					// });
				}
			}
		);
	};

	validateDeviceOwnerChangeRequests(decision) {
		
		var IDs = this.massChargers;
		if(this.subTab == 'battview')
			IDs = this.massBattviews;

		this.deviceManagemntService.validateDeviceOwnerChangeRequests(IDs, this.subTab, decision).subscribe(
			msg => {
				this.resetSelections();
				this.notificationMessage.setMessage(msg, 'success', 10000, true);
				this.getDeviceOwnerChangeRequests();
			}
		);
	};

	validatePendingCustomerSite(id, decision) {
		if(decision == 'reject') {
            let key = 'device.validate_pending_customer';
            if(this.subTab == 'sites')
                key = 'device.validate_pending_site';
			if(!confirm(this.translateService.instant(key)))
				return;
		}
		this.deviceManagemntService.validatePendingCustomerSite(id, this.subTab, decision).subscribe(
			msg => {
				this.getPendingCustomerSite();
				this.notificationMessage.setMessage(msg, 'success', 10000, true);
			}
		);
	};

	updateMassValues(device, id, add, checkOwners?) {
		if(device == 'charger') {

			var itemIdx = this.massChargers.indexOf(id);
			if(add && itemIdx == -1)
				this.massChargers.push(id); //Add element
			else if(!add && itemIdx != -1)
				this.massChargers.splice(itemIdx, 1); //Remove element

			this.devicesIDs = this.massChargers.join(',');

			if(checkOwners) {

				this.disableChargerApprove = false;
				
				for(var i in this.massChargers) {
					var chargerID	= this.massChargers[i];
					var chargerInfo	= this.chargersListObj[chargerID];
					
					if(!this.customersInfo[chargerInfo.customerid]) {
						this.disableChargerApprove = true;
						break;
					}

					if(!this.customersInfo[chargerInfo.customerid].isverified) {
						this.disableChargerApprove = true;
						break;
					}

					if(!this.sitesInfo[chargerInfo.siteid]) {
						this.disableChargerApprove = true;
						break;
					}

					if(!this.sitesInfo[chargerInfo.siteid].isverified) {
						this.disableChargerApprove = true;
						break;
					}
				}
			}
		} else if(device == 'battview') {

			var itemIdx = this.massBattviews.indexOf(id);
			if(add && itemIdx == -1)
				this.massBattviews.push(id); //Add element
			else if(!add && itemIdx != -1)
				this.massBattviews.splice(itemIdx, 1); //Remove element
			
			this.devicesIDs = this.massBattviews.join(',');

			if(checkOwners) {

				this.disableBattviewApprove = false;
				
				for(var i in this.massBattviews) {
					var battviewID	 = this.massBattviews[i];
					var battviewInfo = this.battviewsListObj[battviewID];
					
					if(!this.customersInfo[battviewInfo.customerid]) {
						this.disableBattviewApprove = true;
						break;
					}

					if(!this.customersInfo[battviewInfo.customerid].isverified) {
						this.disableBattviewApprove = true;
						break;
					}

					if(!this.sitesInfo[battviewInfo.siteid]) {
						this.disableBattviewApprove = true;
						break;
					}

					if(!this.sitesInfo[battviewInfo.siteid].isverified) {
						this.disableBattviewApprove = true;
						break;
					}
				}
			}
		}
	};

	getPendingCustomerSite() {
		this.deviceManagemntService.getPendingCustomerSite(this.subTab).subscribe(
			(requests: any[]) => {
				if(requests) {
					if(this.subTab == 'customers')
						this.customersList = requests;
					else if(this.subTab == 'sites')
						this.sitesList = requests;
				}
			}
		);
	};

	getDealerName(id) {
		let name = '-';
		if(this.dealersInfo[id] && this.dealersInfo[id].name)
			name = this.dealersInfo[id].name;
		return name;
	}
	getCustomerName(id) {
		let name = '-';
		if(this.customersInfo[id] && this.customersInfo[id].name)
			name = this.customersInfo[id].name;
		return name;
	}
	getSiteName(id) {
		let name = '-';
		if(this.sitesInfo[id] && this.sitesInfo[id].name)
			name = this.sitesInfo[id].name;
		return name;
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
