import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
	name: 'round'
})
export class RoundPipe implements PipeTransform {

	constructor(private decimalPipe: DecimalPipe) {
	}

	transform(input: number, digits: number = 0): any {
		if (!input){
			return input;
		}

		return this.decimalPipe.transform(Math.round(input * Math.pow(10,digits)) / Math.pow(10,digits),'1.'+digits+'-'+digits);
	}
}
