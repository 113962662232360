<div>
  <div class="panel panel-default">
    <div class="panel-body">
      <div class="row">
        <table class="cellpadding-10">
          <tr>
            <td class="col-sm-6">Events Count</td>
            <td>{{device.eventscount | number:0}}</td>
          </tr>
          <tr>
            <td class="col-sm-6">Manual Upload Events Count</td>
            <td>{{device.manualuploadeventscount | number:0}}</td>
          </tr>
          <tr>
            <td class="col-sm-6">RT Records Count</td>
            <td>{{device.rtrecordscount | number:0}}</td>
          </tr>
          <tr>
            <td class="col-sm-6">Total Inuse AHR</td>
            <td>{{(+device.inuseahr) + (+device.add_inuseahr) | number:0}}</td>
          </tr>
          <tr>
            <td class="col-sm-6">Total Inuse KWHR</td>
            <td>{{(+device.inusekwhr) + (+device.add_inusekwhr) | number:0}}</td>
          </tr>
          <tr>
            <td class="col-sm-6">Total Charge AHR</td>
            <td>{{(+device.chargeahr) + (+device.add_chargeahr) | number:0}}</td>
          </tr>
          <tr>
            <td class="col-sm-6">Total Charge KWHR</td>
            <td>{{(+device.chargekwhr)+ (+device.add_chargekwhr) | number:0}}</td>
          </tr>
          <tr>
            <td class="col-sm-6">Total Inuse Time</td>
            <td>{{commonUtil.secondsToElapsedTime((+device.inuseseconds) + (+device.add_inuseseconds))}}</td>
          </tr>
          <tr>
            <td class="col-sm-6">Total Charge Time</td>
            <td>{{commonUtil.secondsToElapsedTime((+device.chargeseconds) + (+device.add_chargeseconds))}}</td>
          </tr>
          <tr>
            <td class="col-sm-6">Total Idle Time</td>
            <td>{{commonUtil.secondsToElapsedTime((+device.idleseconds) + (+device.add_idleseconds))}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>