import { Component, OnInit, OnDestroy } from '@angular/core';
import { DeviceManagementService } from '../../device-management.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { UserService } from '../../../auth/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-replacement-devices-listing',
  templateUrl: './replacement-devices-listing.component.html',
  styleUrls: ['./replacement-devices-listing.component.css']
})
export class ReplacementDevicesListingComponent implements OnInit, OnDestroy {
  serialnumber: string = null;
  devices: any[] = [];
  chargers: any[] = [];
  battviews: any[] = [];
  tab: string = 'battviews';

  constructor(
    private deviceManagemntService: DeviceManagementService,
    private breadcrumb: BreadcrumbService,
	public userService: UserService,
	private translateService: TranslateService
  ) {
    this.breadcrumb.setBreadcrumb([
      {label: this.translateService.instant('device.replacement_devices_listing'), url: ''}
    ]);
  }

  ngOnInit() {
    this.getReplacementDevices();    
  }

  getReplacementDevices() {
    this.deviceManagemntService.getAvailableReplacementDevices().subscribe(
      (devices: any) => {
        this.chargers = devices.chargers;
        this.battviews = devices.battviews;
        this.devices = this.battviews.slice();
      }
    );
  }

  ngOnDestroy() {
		this.breadcrumb.hide();
	}

  setTab(tab) {
    this.tab = tab;
    if(tab == 'chargers')
      this.devices = this.chargers.slice();
    else
      this.devices = this.battviews.slice();
  }
}
