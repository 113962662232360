<div class="row">
	<div class="panel panel-default">
		<div class="panel-body">
			<div class="panel-body">
				<table>
					<tbody>
						<tr>
							<td>Widget Name</td>
							<td>Status</td>
						</tr>
						<tr *ngFor="let widget of widgetsData">
							<td>{{widget.widget_name}}</td>
							<td>
								<div class="onoffswitch">
									<input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch{{widget.widget_id}}" checked="" [(ngModel)]="widget.is_active">
									<label class="onoffswitch-label" for="myonoffswitch{{widget.widget_id}}">
										<span class="onoffswitch-inner"></span>
										<span class="onoffswitch-switch"></span>
									</label>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<button type="button" class="btn btn-primary" (click)="updateWidgets()">Submit</button>
			</div>
		</div>
	</div>
</div>