<div class="boxs3">
  <div id="dvExceptionSettings">
    <span class="showOnPrint">
      <b>Date Range {{date.fromDate | date:'MM/dd/yyyy'}} - {{date.toDate | date:'MM/dd/yyyy'}}</b>
    </span>
    <table>
      <tr>
        <td>Serial Number</td>
        <td>{{dataObj.SerialNumber}}</td>
      </tr>
      <tr>
        <td>ID</td>
        <td>{{dataObj.ID}}</td>
      </tr>
      <tr>
        <td>Nominal Voltage</td>
        <td>{{dataObj.NominalVoltage}}</td>
      </tr>
      <tr>
        <td>Capacity AHr</td>
        <td>{{dataObj.AhrCapacity}}</td>
      </tr>
      <tr>
        <td>Missed Min charges</td>
        <td [ngStyle]="dataObj.missedChargersPerDay?.style()">{{dataObj.missedChargersPerDay?.value}}</td>
      </tr>
      <tr>
        <td>Weekly Missed EQ</td>
        <td [ngStyle]="dataObj.missedEQ?.style()">{{dataObj.missedEQ?.value}}</td>
      </tr>
      <tr>
        <td>Missed Finish</td>
        <td [ngStyle]="dataObj.totalMissedFinishes?.style()">{{dataObj.totalMissedFinishes?.value}}</td>
      </tr>
      <tr>
        <td>Inuse Limit Exceeded</td>
        <td [ngStyle]="dataObj.reachedMaxedEBU?.style()">{{dataObj.reachedMaxedEBU?.value}}</td>
      </tr>
      <tr>
        <td>Over Temperature</td>
        <td [ngStyle]="dataObj.countOfHighTemp?.style()">{{dataObj.countOfHighTemp?.value}}</td>
      </tr>
      <tr>
        <td>Missed Min Connect</td>
        <td [ngStyle]="dataObj.reachedTheMissedDays?.style()">{{dataObj.reachedTheMissedDays?.value}}</td>
      </tr>
      <tr>
        <td>Current Sensing Issue</td>
        <td [ngStyle]="dataObj.currentSensingValue?.style()">{{dataObj.currentSensingValue?.value}}</td>
      </tr>
      <tr>
        <td>Potential Weak Cells</td>
        <td [ngStyle]="dataObj.deepDischarge?.style()">{{dataObj.deepDischarge?.value}}</td>
      </tr>
      <tr>
        <td>Deep Discharge</td>
        <td [ngStyle]="dataObj.deepUse?.style()">{{dataObj.deepUse?.value}}</td>
      </tr>
      <tr>
        <td>AHr return</td>
        <td [ngStyle]="dataObj.AHRReturn?.style()">{{dataObj.AHRReturn?.value}}</td>
      </tr>
    </table>
  </div>
</div>