import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UserSearchOptionsComponent } from '../users/user/user-search-options/user-search-options.component';
import { AdminService } from '../admin.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-customized-emails',
	templateUrl: './customized-emails.component.html',
	styleUrls: ['./customized-emails.component.css']
})
export class CustomizedEmailsComponent implements OnInit, OnDestroy {

	@ViewChild('searchOptions') searchOptions: UserSearchOptionsComponent;
	editorConfig = {};
	usersByType = {};
	getUsersBy = '';
	users = [];
	email: any = {
		to: []
	};

	apiKey = '7pul2qciw2f7eorugz6xt7xuveq8t7k0xsepvtwba56gfppa';

	userTypes: {id: string, text: string}[] = [
		{id: 'oems', text: 'OEMs'},
		{id: 'dealers', text: 'Dealers'},
		{id: 'clients', text: 'Clients'},
		{id: 'act', text: 'ACT users'},
		{id: 'specific', text: 'Specific User'}
	];

	constructor(
		private adminService: AdminService,
		private notificationMessage: NotificationMessageService,
		private breadcrumb: BreadcrumbService,
		private router: Router
	) {}

	ngOnInit() {

		this.breadcrumb.setBreadcrumb([{'label': 'Customized Emails', 'url': ''}]);

		this.editorConfig = {
			inline: false,
			theme: 'modern',
			skin: 'lightgray',
			plugins: 'table advlist autolink link image lists charmap print preview',
			toolbar: 'undo redo | cut copy paste | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | fontselect fontsizeselect | outdent indent | subscript superscript | bullist numlist table insert | print preview',
			insert_button_items: 'image link charmap',
			branding: false,
			height: 300,
			menubar: false,
			resize: false,
			statusbar: false
		};
		
		this.resetUsers();
	}

	resetUsers() {
		this.users		= [];
		this.email.to	= [];
	};

	sendCustomizedEmail() {
		this.adminService.sendCustomizedEmail(this.email).subscribe(
			data => {
				if(data['httpStatus'] == 'error') {
					var msg = data['msg'];
					
					if(Array.isArray(data['msg'])) {
						msg = "Email was not sent to the following users:<br/>" + data['msg'].join('<br/>');
					}

					this.notificationMessage.setMessage(msg, 'danger', 60000);
				}
			}
		);
	};

	onUserSearch(result) {
		this.getUsersBy = result['role'];
		let users = [];
		let userIDs = [];
		result['users'].forEach((user) => {
			user['name'] = user['firstname'] + ' ' + user['lastname'];
			if(user.email_confirmed && !user.test_user) {
				userIDs.push(user.id);
				users.push(user);
			}
		});
		this.users = users;
		this.email.to = userIDs;
	}

	checkUser(user) {
		if(!user.id)
			this.email.to = this.email.to.slice(0, -1);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
