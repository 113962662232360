<div class="row">
  <div id="page-wrapper" >
    <div id="tab-content" class="row">
      <div class="col-md-4">
        <a href="https://play.google.com/store/apps/details?id=com.actchargers.actlink" target="_blank">
          <img src="/images/Android.png">
        </a>
      </div>
      <div class="col-md-4">
        <a href="https://itunes.apple.com/us/app/actlink/id1262897121" target="_blank">
          <img src="/images/iOS.png">
        </a>
      </div>
      <div class="col-md-4">
        <a href="/quantumSoftware/downloadManager?type=software" target="_self">
          <img src="/images/Computer.png">
        </a>
      </div>
    </div>
  </div>
</div>