<simple-widget [title]="'Site Information'" widgetId="site_info_widget">
	<div id="site-information">
		<div class="col-md-5 col-sm-12 margin-right margin-top" id="site-info-static-data">
			<ul>
				<li class="iconic">
					<img src="/images/icon_site_name.png">
					<div ng-click="getSiteMap(siteInfo)">
						<h4>{{siteInfo.name}}</h4>
						<span *ngIf="siteInfo.managed_by_dealer && siteInfo.act_intelligent_type != 0" class="picmcaker managed-by">
                            Managed By Dealer
						</span>
						<p>{{siteInfo.address}}</p>
					</div>
				</li>
				<li class="iconic">
					<img src="/images/icon_selling_dealer.png">
					<div>
						<h4>Selling Dealer</h4>
						<p *ngIf="data">{{data.dealerName}}</p>
					</div>
				</li>
				<li class="iconic">
					<img src="/images/icon_service_dearler.png">
					<div>
						<h4>Service Dealer</h4>
						<p *ngIf="data">{{data.serviceDealerName || data.dealerName}}</p>
					</div>
				</li>
				<li class="iconic" *ngIf="data && data.truckDealerName">
					<img src="/images/icon_service_dearler.png">
					<div>
						<h4>Truck Dealer</h4>
						<p *ngIf="data">{{data.truckDealerName}}</p>
					</div>
				</li>
				<li *ngIf="siteInfo.contactname" class="iconic">
					<img src="/images/icon_site_contact.png">
					<div>
						<h4>Contact Info</h4>
						<p>{{siteInfo.contactname}}</p>
						<p *ngIf="siteInfo.email">{{siteInfo.email}}</p> 
						<p *ngIf="siteInfo.phoneno">{{siteInfo.phoneno}}</p>
					</div>
				</li>
				<li *ngIf="siteInfo.act_intelligent_type > 0 " class="iconic">
					<img src="/images/icon_act_intelligent_enabled.png">
					<div>
						<h4>Last Connect Time</h4>
						<p *ngIf="data">{{data.siteLastConnectTime * 1000 | date:'MM/dd/yyyy hh:mm:ss a'}}</p>
						<p *ngIf="user.accessFunctions?.admin_act_intelligent && data.siteInvoice?.activeInvoice">Active until: {{data.siteInvoice.activeInvoice.end_date | date:'MM/dd/yyyy'}}</p>
						<div *ngIf="siteInfo.act_first_enabled" style="line-height: 36px">
							<img src="/images/ACTfirst.png" style="width: 29px; height: 36px; vertical-align: text-bottom;"/> <b>ACT-First</b> 
						</div>
						<div *ngIf="siteInfo.dr_provider == 'ENERNOC'" style="line-height: 36px">
							<img src="/images/ACTenergy.png" style="width: 81px; height: 25px; vertical-align: text-bottom;"/> <b>ACT Energy</b>
						</div>
						<div *ngIf="siteInfo.dr_provider == 'VOLTUS'" style="line-height: 36px">
							<img src="/images/ACTenergy.png" style="width: 81px; height: 25px; vertical-align: text-bottom;"/> <b>ACT Energy</b> 
						</div>
					</div>
				</li>
				<li class="iconic">
					<img src="/images/icon_time_zone.png">
					<div>
						<h4>Time Zone</h4>
						<p *ngIf="data">{{liveSiteTime | date:'MM/dd/yyyy hh:mm:ss a'}} - {{data.timezone}}</p>
					</div>
				</li>
				<li class="iconic">
					<img src="/images/icon_time_zone.png">
					<div>
						<h4>Installation Date</h4>
						<p>{{siteInfo.installation_date | date:'MM/dd/yyyy'}}</p>
					</div>
				</li>
			</ul>
		</div>
		<div id="site-info-images-grid" class="col-md-6 col-sm-12">
			<div class="wrapper">
				<div class="container">
					<div class="grid-container">
						<ul class="rig columns-2">
							<li class="iconic left">
								<img src="/images/icon_chargers.png">
								<h4 *ngIf="data">{{data.chargersCount | number:'1.0-0'}}</h4>
								<p class="info-desc">Quantum Chargers installed</p>
							</li>
							<li class="iconic right">
								<img src="/images/icon_batteries.png">
								<h4 *ngIf="data">{{data.battviewCount | number:'1.0-0'}}</h4>
								<p class="info-desc">BattViews Installed</p>
							</li>
							<li class="iconic left">
								<img src="/images/icon_kwhr_used.png">
								<h4>{{whrsUsed}} 
									<span *ngIf="siteInfo.act_intelligent_type == 0">*</span>
								</h4>
								<p class="info-desc">{{whrsUnit}} Used</p>
							</li>
							<li class="iconic right">
								<img src="/images/icon_ahrs_used.png">
								<h4>{{ahrsUsed}}
									<span *ngIf="siteInfo.act_intelligent_type == 0">*</span>
									</h4>
								<p class="info-desc">{{ahrsUnit}} Used</p>
							</li>
							<li class="iconic left">
								<img src="/images/icon_charge_cycles.png">
								<h4>{{estimatedCounts.charge_cycles_delivered | number:'1.0-2'}} 
									<span>{{chargerCyclesUnit}}</span><span *ngIf="siteInfo.act_intelligent_type == 0">*</span>
								</h4>
								<p class="info-desc">Charge Cycles Delivered</p>
							</li>
							<li class="iconic right">
								<img src="/images/icon_data_records.png" />
								<h4>{{estimatedCounts.data_records_saved | number:'1.0-0'}}
									<span *ngIf="siteInfo.act_intelligent_type == 0">*</span>
								</h4>
								<p class="info-desc">Data Records Saved</p>
							</li>
							<li class="iconic left">
								<img src="/images/icon_kwhr_saved.png" />
								<h4>{{estimatedCounts.total_energy_kwhrs_saved | number:'1.0-0'}}
									<span *ngIf="siteInfo.act_intelligent_type == 0">*</span>
								</h4>
								<p class="info-desc">kWhrs Total Energy Saved</p>
							</li>
							<li class="iconic right">
								<img src="/images/icon_trees.png" />
								<h4>{{estimatedCounts.co2_saved_per_urban_tree_planted | number:'1.0-0'}}
									<span *ngIf="siteInfo.act_intelligent_type == 0">*</span>
								</h4>
								<p class="info-desc">Number of Trees Planted</p>
							</li>
							<li class="iconic left">
								<img src="/images/icon_co2_saving.png" />
								<h4>{{estimatedCounts.co2_saving | number:'1.0-0'}}
									<span *ngIf="siteInfo.act_intelligent_type == 0">*</span>
								</h4>
								<p class="info-desc">lbs CO2 Saved</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div *ngIf="siteInfo.act_intelligent_type == 0" style="font-size: 18px">
				* Estimated
			</div>
		</div>
	</div>
</simple-widget>
