import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { SiteDashboardService } from '../../site-dashboard.service';
import { UserService } from '../../../../auth/user.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { ChartDataService } from '../../../../shared/services/chart-data.service';
import { NotificationMessageService } from '../../../../shared/notification-message/notification-message.service';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';

@Component({
	selector: 'app-site-alerts',
	templateUrl: './site-alerts.component.html',
	styleUrls: ['./site-alerts.component.css']
})
export class SiteAlertsComponent implements OnInit, OnDestroy {

	user;
	data;
	printDateEnabled: boolean = false;
	customerId;
	currentSite: any;
	activeDataSub: any;
	printDate: string = '';
	siteSubscription;
	customerName: string = '';
	siteName: string = '';

	constructor(
		private siteDashboardService: SiteDashboardService,
		private userService: UserService,
		private commonUtil: CommonUtil,
		private chartDataService: ChartDataService,
		private notificationMessage: NotificationMessageService,
		private sideMenu: SideMenuService,
	) {
		this.siteDashboardService.setActiveSubTab('alerts');
	}

	ngOnInit() {
		this.user = this.userService.getCurrentUser();

		this.siteSubscription = this.sideMenu.currentSite.subscribe(siteInfo => {
			this.currentSite	= siteInfo;
			this.customerId		= siteInfo.customerid;
			this.customerName	= siteInfo.customer_name;
			this.siteName		= siteInfo.name;

			if(this.customerId && this.currentSite.id) {

				this.activeDataSub = this.siteDashboardService.activeDashboardData.subscribe(data => this.data = Object.assign({}, data));
				this.siteDashboardService.getDashboardData(this.customerId, this.currentSite.id);
			}
		});
	}

	printAlerts() {
		this.printDateEnabled=false;
		var customerId	= this.customerId,
			siteId		= this.currentSite.id,
			date:any	= this.printDate || "W";

		this.printDate='';

		var dateSentence = '';
		switch (date) {
			case "W":
				dateSentence += 'Alerts For Last Week (';
				var daysDifference = 0;
				var today = new Date();
				if (today.getDay() === 0) {
					daysDifference = 7;
				}
				var endDate			= moment().day(0-daysDifference).utc().startOf('day').format('MM/DD/YYYY');		// last Sunday
				var startDate		= moment().day(-6-daysDifference).utc().startOf('day').format('MM/DD/YYYY');	// last Monday before Sunday
				dateSentence += startDate + ' - ' + endDate;
				dateSentence += ')';
			break;
			case 'Y':
				dateSentence += 'Alerts For Yesterday (';
				var yesterday = new Date();
				yesterday.setDate(yesterday.getDate()-1);
				dateSentence += (yesterday.getMonth()+1)+'/'+yesterday.getDate()+'/'+yesterday.getFullYear() + ')';
            break;
            case 'siteInstallation':
                dateSentence += 'Alerts From Devices Installation Date';
            break;
			default:
				dateSentence += 'Alerts For Last '+date+' Days (';
				var yesterday = new Date();
				yesterday.setDate(yesterday.getDate()-1);
				var endDate = (yesterday.getMonth()+1)+'/'+yesterday.getDate()+'/'+yesterday.getFullYear();
				yesterday.setDate(yesterday.getDate()-(+date));
				var startDate = (yesterday.getMonth()+1)+'/'+yesterday.getDate()+'/'+yesterday.getFullYear();
				dateSentence += startDate + ' - ' + endDate;
				dateSentence += ')';
			break;
		}

		this.siteDashboardService.getDashboardData(this.customerId, this.currentSite.id).subscribe((data) => {
			if (!data) 
				return this.notificationMessage.setMessage('No data for this site.');

			var header = '<div class="boxs"><div><div id="dvCompanyFullName"><h3>'+this.customerName+' / '+this.currentSite.name+' Alerts</h3></div><div class="picmcaker"> <i class="fa fa-map-marker"></i>'+this.currentSite.address+'</div><div><h4>'+dateSentence+'</h4></div></div></div>';
			let printItems = this.chartDataService.getSiteWidgetPrintAlertsList(data, customerId, siteId, {datePeriod:date})
			var htmlContent = header+'<div>';
			for (var widgetName in printItems) {
				var appendHtml = false;
				var tempHtml = '';
				tempHtml += '<div class="boxs3"><h2>'+widgetName+'</h2>';
				for (var index in printItems[widgetName]) {
					var printItem = printItems[widgetName][index];
					tempHtml += '<div class="page-break-inside">';
					if (printItem.title) {
						tempHtml += '<h4>'+printItem.title+'</h4>';
					}
					tempHtml += '<table class="table table-striped table-condensed table-bordered"><thead>';
					for (var columnIndex in printItem.columns) {
						tempHtml += '<th>'+printItem.columns[columnIndex].title+'</th>';
					}
					tempHtml += '</thead><tbody>';
					for (var itemIndex in printItem.list) {
						var item = printItem.list[itemIndex];
						tempHtml += '<tr>';
						var columnIndexId = 1; // I assume only 2 columns
						for (columnIndex in printItem.columns) {
							appendHtml = true;
							var tdWidth: any = (80 / (printItem.columns.length - 1));
							if (columnIndexId == 1) {
								tdWidth = '20';
							}
							tempHtml += '<td style="width:'+tdWidth+'%">';
							tempHtml += '<span>'+item[printItem.columns[columnIndex].property]+'</span>';
							tempHtml += '</td>';
							columnIndexId++;
						}
						tempHtml += '</tr>';
					}
					tempHtml += '</tbody></table></div>';
				}
				tempHtml += '</div>';

				if (appendHtml) {
					htmlContent += tempHtml;
				}
			}
			htmlContent += '</div>';

			this.commonUtil.print({
				appendPrintContent: htmlContent
			});
		});
	}

	ngOnDestroy() {
		this.siteSubscription.unsubscribe();
		if(this.activeDataSub)
			this.activeDataSub.unsubscribe();
	}
}