<app-modal #modal [title]="modalTitle" [closeOnConfirm]='false' confirmBtnText="Save" closeBtnText="Cancel">
	<form name="editInvoice" novalidate>
		<div class="modal-body">
			<div class="col-md-12">
				<div class="row padding-8">
					<div class="col-md-5"><strong>Invoice ID</strong></div>
					<div class="col-md-7">
						<input type="text" required class="form-control" name="invoice_id" [(ngModel)]="modalInvoice.invoice_id"/>
					</div>
				</div>
			
				<div class="row padding-8">
					<div class="col-md-5"><strong>Amount</strong></div>
					<div class="col-md-7">
						<div class='input-group'>
							<span class="input-group-addon"><span>$</span></span>
							<input type="number" required min="0" name="amount" class="form-control currency" [(ngModel)]="modalInvoice.amount"/>
						</div>
					</div>
				</div>
				
				<div class="row padding-8">
					<div class="col-md-5"><strong># of BATTViews</strong></div>
					<div class="col-md-7">
						<input type="number" required min="0" name="battview_count" class="form-control" [(ngModel)]="modalInvoice.battview_count"/>
					</div>
				</div>

				<div class="row padding-8">
					<div class="col-md-5"><strong># of Chargers</strong></div>
					<div class="col-md-7">
						<input type="number" required min="0" name="chargers_count" class="form-control" [(ngModel)]="modalInvoice.chargers_count"/>
					</div>
				</div>

				<div class="row padding-8">
					<div class="col-md-5"><strong>ACT First</strong></div>
					<div class="col-md-7">
						<input type="checkbox" name="act_first_enabled" class="form-control" [(ngModel)]="modalInvoice.act_first_enabled"/>
					</div>
				</div>

				<div class="row padding-8">
					<div class="col-md-5"><strong>ACT Energy</strong></div>
					<div class="col-md-7">
						<input type="checkbox" name="act_energy_enabled" class="form-control" [(ngModel)]="modalInvoice.act_energy_enabled"/>
					</div>
				</div>
			
				<div class="row padding-8">
					<div class="col-md-5"><strong>Start Date</strong></div>
					<div class="col-md-7">
						<div class="input-group">
							<input class="form-control" placeholder="mm/dd/yyyy" name="modalStartDB" [(ngModel)]="modalInvoice.start_date" bsDatepicker #modalStartDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" [required]="site.site_subscription_type == 'Demo'"/>
							<span class="input-group-btn">
								<button class="btn btn-default btn-date" (click)="modalStartDB.toggle()" type="button"><i class="fa fa-calendar"></i></button>
							</span>
						</div>
					</div>
				</div>

				<div class="row padding-8">
					<div class="col-md-5"><strong>End Date</strong></div>
					<div class="col-md-7">
						<div class="input-group">
							<input class="form-control" placeholder="mm/dd/yyyy" name="modalEndDB" [(ngModel)]="modalInvoice.end_date" bsDatepicker #modalEndDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" [required]="site.site_subscription_type == 'Demo'"/>
							<span class="input-group-btn">
								<button class="btn btn-default btn-date" (click)="modalEndDB.toggle()" type="button"><i class="fa fa-calendar"></i></button>
							</span>
						</div>
					</div>
				</div>

				<div class="row padding-8">
					<div class="col-md-5"><strong>Invoice Date</strong></div>
					<div class="col-md-7">
						<div class="input-group">
							<input class="form-control" placeholder="mm/dd/yyyy" name="modalInvoiceDB" [(ngModel)]="modalInvoice.invoice_date" bsDatepicker #modalInvoiceDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" [required]="site.site_subscription_type == 'Demo'"/>
							<span class="input-group-btn">
								<button class="btn btn-default btn-date" (click)="modalInvoiceDB.toggle()" type="button"><i class="fa fa-calendar"></i></button>
							</span>
						</div>
					</div>
				</div>

				<div class="row padding-8">
					<div class="col-md-5"><strong>Note</strong></div>
					<div class="col-md-7">
						<input [(ngModel)]="modalInvoice.invoice_note" name="invoice_note" placeholder="Note..." class="form-control">
					</div>
				</div>
			</div>
		</div>
		<div class="red-text col-md-12">
			<p *ngFor="let msg of errMsgs">
				* {{msg}}.
			</p>
		</div>
	</form>
</app-modal> 
<div class="row col-md-10 col-md-offset-1">
	<div class="panel panel-default">
		<div class="panel-body">
			<h3 class="tcenter margin-bottom-xlg">
				<strong>Site name:</strong> {{site.name}}
			</h3>
			<h4 class="tcenter margin-bottom-xlg" *ngIf="site.salesName">
				<strong>Salesperson:</strong> {{site.salesName}}
			</h4>
			<form #manageACTintelligent="ngForm" novalidate>
				<div class="row padding-8">
					<div class="card-sections col-md-8 col-md-offset-2 margin-bottom">
						<div>
							<input class="card-option" type="radio" id="control_01" value="Paid" name="site_subscription_type" [(ngModel)]="site.site_subscription_type" [attr.disabled]="checkDisabledOption(haveInvoiceEditAccess)" (ngModelChange)="subscriptionChanged($event)"/>
							<label class="card-label" for="control_01">
								<h2>Paid</h2>
							</label>
						</div>
						<div>
							<input class="card-option yellow" type="radio" id="control_02" value="Demo" name="site_subscription_type" [(ngModel)]="site.site_subscription_type" [attr.disabled]="checkDisabledOption(haveDemoEditAccess)" (ngModelChange)="subscriptionChanged($event)"/>
							<label class="card-label" for="control_02">
								<h2>Demo</h2>
							</label>
						</div>
						<div>
							<input class="card-option red" type="radio" id="control_04" value="Engineering" name="site_subscription_type" [(ngModel)]="site.site_subscription_type" [attr.disabled]="checkDisabledOption(userService.hasAccessFunction('admin_act_intelligent'))" (ngModelChange)="subscriptionChanged($event)"/>
							<label class="card-label" for="control_04">
								<h2>Engineering</h2>
							</label>
						</div>
						<div>
							<input class="card-option grey" type="radio" id="control_05" value="None" name="site_subscription_type" [(ngModel)]="site.site_subscription_type" [attr.disabled]="checkDisabledOption(haveDemoEditAccess)" (ngModelChange)="subscriptionChanged($event)"/>
							<label class="card-label" for="control_05">
								<h2>None</h2>
							</label>
						</div>
					</div>
				</div>
				<div class="row" *ngIf="site.site_subscription_type == 'Paid'">
					<div class="col-md-12" *ngIf="haveInvoiceEditAccess">
						<button type="button" (click)="openInvoiceModal()" class="btn btn-primary">Add Invoice</button>
					</div>
					<div class="col-md-12">
						<table class="table margin-top">
							<tbody>
								<tr class="body-bg-color">
									<th><span class="green-text">Invoice ID</span></th>
									<th><span class="green-text">Start Date</span></th>
									<th><span class="green-text">End Date</span></th>
									<th><span class="green-text">Invoice Date</span></th>
									<th><span class="green-text">Amount</span></th>
									<th><span class="green-text"># of BATTViews</span></th>
									<th><span class="green-text"># of Chargers</span></th>
									<th><span class="green-text">ACT First</span></th>
									<th><span class="green-text">ACT Energy</span></th>
									<th><span class="green-text">Note</span></th>
									<th><span class="green-text">Deleted</span></th>
									<th><span class="green-text">Active</span></th>
									<th *ngIf="haveInvoiceEditAccess"><span class="green-text">Options</span></th>
								</tr>
								<tr *ngFor="let invoice of invoices; let idx = index" [ngClass]="{'invalid-input': invoice.is_deleted, 'dirtySettingsField': invoice.is_deactivated}">
									<td>{{invoice.invoice_id}}</td>
									<td>{{invoice.start_date | date:'MM/dd/yyyy'}}</td>
									<td>{{invoice.end_date | date:'MM/dd/yyyy'}}</td>
									<td>{{invoice.invoice_date | date:'MM/dd/yyyy'}}</td>
									<td>${{invoice.amount | number}}</td>
									<td>{{invoice.battview_count}}</td>
									<td>{{invoice.chargers_count}}</td>
									<td>{{invoice.act_first_enabled ? 'Yes' : 'No'}}</td>
									<td>{{invoice.act_energy_enabled ? 'Yes' : 'No'}}</td>
									<td>{{invoice.invoice_note}}</td>
									<td>{{invoice.is_deleted ? 'Yes' : 'No'}}</td>
									<td>{{invoice.is_deactivated ? 'No' : 'Yes'}}</td>
									<td *ngIf="haveInvoiceEditAccess && !invoice.is_deleted"><div class="btn-group pointer" appDropdown>
										<i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle>
										</i>
										<ul class="dropdown-menu pointer" role="menu">
											<li><a (click)="openInvoiceModal(invoice, idx)">Edit</a></li>
											<li *ngIf="!invoice.is_deactivated"><a (click)="modifySiteInvoices('deactivate', invoice, idx)">Deactivate</a></li>
											<li *ngIf="invoice.is_deactivated"><a (click)="modifySiteInvoices('activate', invoice, idx)">Activate</a></li>
											<li><a (click)="modifySiteInvoices('delete', invoice, idx)">Delete</a></li>
										</ul>
									</div></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div *ngIf="site.site_subscription_type == 'Demo'">
					<div class="row padding-8">
						<div class="col-md-4 text-right"><strong>Start Date</strong></div>
						<div class="col-md-4">
							<div class="input-group">
								<input class="form-control" placeholder="mm/dd/yyyy" name="startDB" [(ngModel)]="invoice.start_date" bsDatepicker #startDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" (ngModelChange)="changedDemoDate()" [required]="site.site_subscription_type == 'Demo'"/>
								<span class="input-group-btn">
									<button class="btn btn-default btn-date" (click)="startDB.toggle()" type="button"><i class="fa fa-calendar"></i></button>
								</span>
							</div>
						</div>
					</div>

					<div class="row padding-8">
						<div class="col-md-4 text-right"><strong>End Date</strong></div>
						<div class="col-md-4">
							<div class="input-group">
								<input class="form-control" placeholder="mm/dd/yyyy" name="endDB" [(ngModel)]="invoice.end_date" bsDatepicker #endDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" [required]="site.site_subscription_type == 'Demo'" (ngModelChange)="changedDemoDate()"/>
								<span class="input-group-btn">
									<button class="btn btn-default btn-date" (click)="endDB.toggle()" type="button"><i class="fa fa-calendar"></i></button>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="site.site_subscription_type == 'Demo' || site.site_subscription_type == 'Engineering'">
					<div class="row padding-8">
						<div class="col-md-4 text-right"><strong>Note</strong></div>
						<div class="col-md-4">
							<input *ngIf="site.site_subscription_type == 'Demo'" [(ngModel)]="invoice.demo_note" name="demo_note" placeholder="Note..." class="form-control">
							<input *ngIf="site.site_subscription_type == 'Engineering'" [(ngModel)]="invoice.eng_note" name="eng_note" placeholder="Note..." class="form-control">
						</div>
					</div>
				</div>
				<div class="row margin-top-lg">
					<div class="col-md-4 col-md-offset-4">
						<button type="button" (click)="modifySiteInvoices('changeType', invoice)" class="btn btn-primary col-md-8" [disabled]="(!demoDatesChanged && originalType == site.site_subscription_type && !isDefaultDemo && ((site.site_subscription_type == 'Demo' && demoInvoice.invoice_note == invoice.demo_note) || (site.site_subscription_type == 'Engineering' && engInvoice.invoice_note == invoice.eng_note) || site.site_subscription_type == 'Paid' || site.site_subscription_type == 'None')) || manageACTintelligent.invalid" *ngIf="haveInvoiceEditAccess || haveDemoEditAccess">Update Site ACT Intelligent</button>
						<button type="button" (click)="goBack()" class="btn btn-default col-md-3 col-md-offset-1">Back</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>