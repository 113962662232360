<app-site-widget
    title="Battery Alert Summary"
    elementId="batt_alert_summary"
    chartType="column"
    widgetClass="auto-height-widget"
    [siteWidgetOptions]="{hasDataTypeFilter: true, hasDate: true, isSitePerformanceWidget: true, dataTypeModel: columnsIds, dataTypes: columnsLabels}"
    [widgetOptions]="{}"
    [data]="data"
    [config]="config"
    [tableColumns]="tableColumns"
    [siteId]="siteId"
    [customerId]="customerId"
    [isACTuser]="isACTuser"
    [customerName]="customerName"
    [siteName]="siteName"
></app-site-widget>