<div class="row">
	<div class="col-md-12 panel panel-default">
		<div class="panel-body">
			<form>
				<div class="padding-8">
					<input [(ngModel)]="serialnumber" name="serialnumber" placeholder="{{'device.serial_number' | translate}}" class="form-control"/>
				</div>
				<div class="padding-8">
					<label><input type='checkbox' class="form-control" name="isPartial" [(ngModel)]="isPartial"/> {{'g.partial_search' | translate}}</label>
				</div>
				<div class="padding-8">
					<button class="btn btn-primary mb20" type="submit" (click)="getBattviewDaughterCardBySN()" [disabled]="!serialnumber"><span class="glyphicon glyphicon-search"></span> {{'g.search' | translate}}</button>
				</div>
			</form>
			<div *ngIf="noResult" class="padding-8">
				{{'g.no_results_found' | translate}}
			</div>
			<table class="table table-striped padding-8" *ngIf="cardsInfo.length > 0">
				<thead>
					<tr>
						<th>{{'device.serial_number' | translate}}</th>
						<th>{{'device.charger_name' | translate}}</th>
						<th>{{'device.commission_date' | translate}}</th>
						<th>{{'g.is_deleted' | translate}}</th>
						<th>{{'g.action' | translate}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let card of cardsInfo; let idx = index">
						<td>{{card.serialnumber}}</td>
						<td>{{card.pretty_name}}</td>
						<td>{{card.commission_date | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
						<td>{{(card.is_deleted ? 'g.yes' : 'g.no') | translate}}</td>
						<td>
							<div class="btn-group" appDropdown *ngIf="!card.is_deleted">
								<i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
								<ul class="dropdown-menu" role="menu">
									<li>
										<a [routerLink]="['/battviews/daughter-cards', card.serialnumber]">{{'g.edit' | translate}}</a>
									</li>
									<li class="pointer" *ngIf="userService.hasAccessFunction('act_admin_device_management')">
										<a (click)="deleteBattviewDaughterCard(card.serialnumber, idx)">{{'g.delete' | translate}}</a>
									</li>
								</ul>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>