<div *ngIf="device.id">
  <div class="text-center center-block margin-top-4-per" *ngIf="QVbattview?.isSupported && QV?.isConnecting">
    <div class="col-md-5 alert alert-success center-block float-none">
      <h4>
        <strong>CONNECTING IN ... {{countDown | secondsToDateTime | date:'HH:mm:ss'}}</strong>
      </h4>
    </div>
  </div>
  <div class="text-center center-block margin-top-4-per" *ngIf="QVbattview?.isSupported === false">
    <div class="col-md-5 alert alert-warning center-block float-none">
      <h4>
        <strong>NOT SUPPORTED</strong>
      </h4>
    </div>
  </div>
  <div class="text-center center-block margin-top-4-per" *ngIf="emptyQV && QVbattview?.isSupported && !QV?.isConnecting">
    <div class="col-md-5 alert alert-info center-block float-none">
      <h4>
        <strong>NOT CONNECTED</strong>
      </h4>
    </div>
  </div>
  <div class="panel panel-default margin-bottom-xs" *ngIf="!emptyQV && QVbattview?.isSupported && !QV?.isConnecting">
    <div class="panel-body" *ngIf="QV"> 
      <div class="col-md-12 LCD-body"> <!-- BODY -->
		<div class="col-md-4" *ngIf="QV.event_type == 1 && chargerInfo">
		  <strong>
			  <a *ngIf="chargerInfo.customerid > 0 && chargerInfo.siteid > 0" [routerLink]="['/', chargerInfo.customerid, chargerInfo.siteid, 'chargers', 'analytics', 'cycles', chargerInfo.id]" >{{chargerInfo.chargerusername}}</a>
		  </strong>
		</div>
        <div class="col-md-12">
          <div class="col-md-3 tcenter">
            <img *ngIf="QV.SOCfactor == 0" src="./images/Battview_QV/IMAGE_SOC_0.bmp"/>
            <img *ngIf="QV.SOCfactor == 1" src="./images/Battview_QV/IMAGE_SOC_1.bmp"/>
            <img *ngIf="QV.SOCfactor == 2" src="./images/Battview_QV/IMAGE_SOC_2.bmp"/>
            <img *ngIf="QV.SOCfactor == 3" src="./images/Battview_QV/IMAGE_SOC_3.bmp"/>
            <img *ngIf="QV.SOCfactor == 4" src="./images/Battview_QV/IMAGE_SOC_4.bmp"/>
            <img *ngIf="QV.SOCfactor == 5" src="./images/Battview_QV/IMAGE_SOC_5.bmp"/>
            <img *ngIf="QV.SOCfactor == 6" src="./images/Battview_QV/IMAGE_SOC_6.bmp"/>
            <img *ngIf="QV.SOCfactor == 7" src="./images/Battview_QV/IMAGE_SOC_7.bmp"/>
            <img *ngIf="QV.SOCfactor >= 8" src="./images/Battview_QV/IMAGE_SOC_8.bmp"/>
            <div style="width: 100%; position: absolute; top: 25px; left: 0px; text-align: center; font-size: x-large"><strong>{{QV.soc}}%</strong></div>
          </div>
          <div class="col-md-3 tcenter">
            <img *ngIf="QV.voltageFactor <= 0" src="./images/Battview_QV/IMAGE_GAUGE_0.png"/>
            <img *ngIf="QV.voltageFactor == 1" src="./images/Battview_QV/IMAGE_GAUGE_1.png"/>
            <img *ngIf="QV.voltageFactor == 2" src="./images/Battview_QV/IMAGE_GAUGE_2.png"/>
            <img *ngIf="QV.voltageFactor == 3" src="./images/Battview_QV/IMAGE_GAUGE_3.png"/>
            <img *ngIf="QV.voltageFactor == 4" src="./images/Battview_QV/IMAGE_GAUGE_4.png"/>
            <img *ngIf="QV.voltageFactor == 5" src="./images/Battview_QV/IMAGE_GAUGE_5.png"/>
            <img *ngIf="QV.voltageFactor == 6" src="./images/Battview_QV/IMAGE_GAUGE_6.png"/>
            <img *ngIf="QV.voltageFactor == 7" src="./images/Battview_QV/IMAGE_GAUGE_7.png"/>
            <img *ngIf="QV.voltageFactor >= 8" src="./images/Battview_QV/IMAGE_GAUGE_8.png"/>
            <div style="width: 100%; position: absolute; top: 105px; left: 0px; text-align: center; font-size: x-large"><strong>{{QV.voltage | number:'1.1-1'}} V</strong></div>
            <div style="width: 100%; position: absolute; top: 135px; left: 0px; text-align: center; font-size: large"><strong>{{QV.VPC | number:'1.2-2'}} VPC</strong></div>
          </div>
          <div class="col-md-3 tcenter">
            <img *ngIf="QV.currentFactor <= 0" src="./images/Battview_QV/IMAGE_GAUGE_0.png"/>
            <img *ngIf="QV.currentFactor == 1" src="./images/Battview_QV/IMAGE_GAUGE_1.png"/>
            <img *ngIf="QV.currentFactor == 2" src="./images/Battview_QV/IMAGE_GAUGE_2.png"/>
            <img *ngIf="QV.currentFactor == 3" src="./images/Battview_QV/IMAGE_GAUGE_3.png"/>
            <img *ngIf="QV.currentFactor == 4" src="./images/Battview_QV/IMAGE_GAUGE_4.png"/>
            <img *ngIf="QV.currentFactor == 5" src="./images/Battview_QV/IMAGE_GAUGE_5.png"/>
            <img *ngIf="QV.currentFactor == 6" src="./images/Battview_QV/IMAGE_GAUGE_6.png"/>
            <img *ngIf="QV.currentFactor == 7" src="./images/Battview_QV/IMAGE_GAUGE_7.png"/>
            <img *ngIf="QV.currentFactor >= 8" src="./images/Battview_QV/IMAGE_GAUGE_8.png"/>
            <div style="width: 100%; position: absolute; top: 105px; left: 0px; text-align: center; font-size: x-large"><strong>{{QV.current | number:'1.0-0'}} A</strong></div>
          </div>
          <div class="col-md-3 tcenter">
            <img *ngIf="QV.tempFactor <= 0" src="./images/Battview_QV/IMAGE_TEMPERATURE_0.bmp"/>
            <img *ngIf="QV.tempFactor == 1" src="./images/Battview_QV/IMAGE_TEMPERATURE_1.bmp"/>
            <img *ngIf="QV.tempFactor == 2" src="./images/Battview_QV/IMAGE_TEMPERATURE_2.bmp"/>
            <img *ngIf="QV.tempFactor == 3" src="./images/Battview_QV/IMAGE_TEMPERATURE_3.bmp"/>
            <img *ngIf="QV.tempFactor == 4" src="./images/Battview_QV/IMAGE_TEMPERATURE_4.bmp"/>
            <img *ngIf="QV.tempFactor == 5" src="./images/Battview_QV/IMAGE_TEMPERATURE_5.bmp"/>
            <img *ngIf="QV.tempFactor == 6" src="./images/Battview_QV/IMAGE_TEMPERATURE_6.bmp"/>
            <img *ngIf="QV.tempFactor == 7" src="./images/Battview_QV/IMAGE_TEMPERATURE_7.bmp"/>
            <img *ngIf="QV.tempFactor >= 8" src="./images/Battview_QV/IMAGE_TEMPERATURE_8.bmp"/>
            <div style="width: 100%; position: absolute; top: 30px; left: 0px; text-align: center; font-size: x-large"><strong>{{QV.temperatureText}}</strong></div>
          </div>
        </div>
        <div class="col-md-12 margin-top-xxlg">
          <div class="col-md-3 tcenter">
            <div class="col-md-12 tcenter bg-grey">
              <strong>AHr: {{QV.AHR | number:'1.1-1'}}</strong>
            </div>
            <div class="col-md-12 tcenter bg-grey margin-top-lg">
              <strong>KWHr: {{QV.KWhr | number:'1.1-1'}}</strong>
            </div>
          </div>
          <div class="col-md-6 tcenter">
            <img *ngIf="!QV.EL_enabled" src="./images/Battview_QV/EL_OFF.png"/>
            <img *ngIf="QV.EL_enabled && !QV.waterLevel" src="./images/Battview_QV/EL_LOW.gif"/>
            <img *ngIf="QV.EL_enabled && QV.waterLevel" src="./images/Battview_QV/EL_HIGH.gif"/>
            <span *ngIf="QV.event_type == 1" style="color:orange;font-size: xx-large;margin-left: 50px">Charge</span>
            <span *ngIf="QV.event_type == 2" style="color:cyan;font-size: xx-large;margin-left: 50px">Idle</span>
            <span *ngIf="QV.event_type == 3" style="color:hotpink;font-size: xx-large;margin-left: 50px">In use</span>
          </div>
          <div class="col-md-3 tcenter">
            <div class="col-md-12 tcenter" style="background-color: #424143">
              <div style="color: #3CB64B; font-size: large">{{QV.startTime * 1000 | date:'MM/dd/yy hh:mm:ss'}}</div>
              <img *ngIf="QV.durationImg == 0" src="./images/Battview_QV/IMAGE_DURATION_1.bmp"/>
              <img *ngIf="QV.durationImg == 1" src="./images/Battview_QV/IMAGE_DURATION_2.bmp"/>
              <img *ngIf="QV.durationImg == 2" src="./images/Battview_QV/IMAGE_DURATION_3.bmp"/>
              <img *ngIf="QV.durationImg == 3" src="./images/Battview_QV/IMAGE_DURATION_4.bmp"/>
              &nbsp;<span style="color: #3CB64B; font-size: large">{{QV.durationTime}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>