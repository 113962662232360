import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { DeviceManagementService } from '../device-management.service';
import { UserService } from '../../auth/user.service';
import { User } from '../../auth/user.model';
import { CommonUtil } from '../../shared/services/utility/common.service';

@Component({
	selector: 'app-report-download',
	templateUrl: './report-download.component.html',
	styleUrls: ['./report-download.component.css']
})
export class ReportDownloadComponent implements OnInit, OnDestroy {

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private translateService: TranslateService,
		private breadcrumbService: BreadcrumbService,
		private notificationMessage: NotificationMessageService,
		private userService: UserService,
		private deviceManagemntService: DeviceManagementService,
		private commonUtil: CommonUtil,
	) { }

	ngOnInit(): void {
		this.userService.currentUser.subscribe((user: User) => {
			let currentUser: User = user;
			if (!currentUser.isACTuser) {
				return this.router.navigate(['/unauthorized']);
			}
		});

		this.route.queryParams.subscribe(params => {
			let report,
				type = 'pm_report';
			switch(params.type) {
				case 'charger_warranty':
					report = 'site.charger_warranty_report';
				break;
				case 'battview_warranty':
					report = 'site.battview_warranty_report';
				break;
				case 'service_page':
					report = 'site.service_page';
					type = 'service_page';
				break;
				default:
					report = 'site.pm_report';
			}
			this.breadcrumbService.setBreadcrumb([{'label': this.translateService.instant(report), 'url': ''}]);
			if(params.isExpired)
				return this.notificationMessage.setMessage('translate|g.file_has_been_deleted', 'warning', 20000);
			if(params.enc) {
				this.deviceManagemntService.prepareFileDownload(type, params.enc).subscribe((data:any) => {
					this.commonUtil.downloadReport(data);
					this.router.navigate(['/sites-map']);
				});
			}
		});
	}

	ngOnDestroy(): void {
		this.breadcrumbService.hide();
	}
}
