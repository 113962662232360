import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ControlContainer, NgForm } from '@angular/forms';
import * as _ from 'underscore';
import { AdminService } from '../../admin.service';
import { ChargerService } from '../chargers/charger.service';
import { BattviewService } from '../battviews/battview.service';
import { NavigationService } from '../../../shared/services/navigation.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { UserService } from '../../../auth/user.service';
import { SiteEditComponent } from '../../sites/site-edit/site-edit.component';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';

@Component({
	selector: 'app-device-edit-page',
	templateUrl: './device-edit-page.component.html',
	styleUrls: ['./device-edit-page.component.css'],
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class DeviceEditPageComponent implements OnInit, OnDestroy {

	@ViewChild('appSiteEdit') appSiteEdit: SiteEditComponent;

	deviceObj = {
		id: 0,
		siteid: 0,
		customerid: 0,
		isNew: true,
		isFreeDevice: false
	};

	deviceType: String;

	currentUser: any = {};
	device: any = {};
	oldDevice: any = {};
	alphabets = [];
	ACTusers = <any>[];
	clients = <any>[];
	clientSites = <any>[];
	dealersInfo = <any>[];
	serviceDealersInfo = <any>[];
	truckDealersInfo = <any>[];
	OEMsInfo = <any>[];
	newSiteOption = {};

	constructor(
		private adminService: AdminService,
		private chargerService: ChargerService,
		private battviewService: BattviewService,
		private route: ActivatedRoute,
		private navigation: NavigationService,
		private notificationMessage: NotificationMessageService,
		private commonUtil: CommonUtil,
		private userService: UserService,
		private elementRef: ElementRef,
		private breadcrumb: BreadcrumbService
	) {
		this.alphabets = this.commonUtil.alphabetCharacters;
		this.device['site'] = {};
		this.device['client'] = {};
		this.device['isClientNeeded'] = false;
	}

	ngOnInit() {
		this.deviceType = this.route.snapshot.data['type'];
		this.newSiteOption = {'id':0, 'name':'- New Site -'};
		this.currentUser = this.userService.getCurrentUser();

		if(this.deviceType != 'battviewMobile') {

			this.route.params.subscribe( params => {
				this.deviceObj.siteid = Number(params['siteId']) || 0;
				this.deviceObj.customerid = Number(params['customerId']) || 0;
				this.deviceObj.isFreeDevice = this.deviceObj.customerid == 0;
			});
		}
		
		this.route.queryParams.subscribe( params => {
			let deviceID = params['battviewId'];
			if(this.deviceType == 'charger')
				deviceID = params['chargerId'];

			this.deviceObj.id = Number(deviceID);
			this.deviceObj.isNew = this.deviceObj.id == 0;

			let label = 'BattViews';
			let deviceLink = '/battviews/customerId/'+this.deviceObj.customerid+'/siteId/'+this.deviceObj.siteid;
			
			if(this.deviceType == 'charger') {
				label = 'Chargers';
				deviceLink = '/chargers/customerId/'+this.deviceObj.customerid+'/siteId/'+this.deviceObj.siteid;
			}
			
			this.breadcrumb.setBreadcrumb([
				{'label': 'Customers', 'url': '/customers'},
				{'label': 'Sites', 'url': '/sites/customerId/'+this.deviceObj.customerid},
				{'label': label, 'url': deviceLink},
				{'label': 'Edit', 'url': ''}
			]);

			if(this.deviceObj.id)
				this.getDevice();
			else
				this.getOwnersList();
		});
	}

	getDevice() {
		if(this.deviceType == 'charger') {
			this.adminService.getCharger(this.deviceObj.id, this.deviceObj.isFreeDevice).subscribe(
				data => this.initPageVariables(data)
			);
		} else {
			//battview
			this.adminService.getBattview(this.deviceObj.id, this.deviceObj.isFreeDevice).subscribe(
				data => this.initPageVariables(data)
			);
		}
	}

	getOwnersList() {
		this.adminService.getOwnersList(this.deviceObj.customerid).subscribe(
			data => this.initPageVariables(data)
		);
	}

	initPageVariables(data) {

		let noOEM			= {'id':0, 'name':'- No OEM -'};
		let noServiceDealer	= {'id':0, 'name':'- No Service Dealer -'};
		let noTruckDealer	= {'id':0, 'name':'- No Truck Dealer -'};

		if(this.deviceObj.id) {
			if(this.deviceType == 'charger')
				this.device = data['charger'];
			else
				this.device = data['battview'];
			this.oldDevice = Object.assign({}, this.device);
			this.device['clientNameIndex'] = this.device['nameindex'].toUpperCase() || 'new';
		} else {

			this.device['clientNameIndex'] = data['clientNameIndex'];
			this.device['customerid'] = this.deviceObj.customerid;
		}

		this.clients = _.sortBy(data['clients'], 'name');
		this.clientSites = [this.newSiteOption].concat(_.sortBy(data['clientSites'], 'name'));

		if(data['ACTusers'])
			this.ACTusers = data['ACTusers'];

		if(data['OEMsInfo']) {
			let OEMsInfo = data['OEMsInfo'];
			OEMsInfo.unshift(noOEM);
			this.OEMsInfo = OEMsInfo;
		}

		if(data['dealersInfo']) {
			this.dealersInfo = data['dealersInfo'];
			let serviceDealersInfo = this.dealersInfo.slice();
			serviceDealersInfo.unshift(noServiceDealer);
			this.serviceDealersInfo = serviceDealersInfo;
		}

		if(data['truckDealersInfo']) {
			let truckDealersInfo = data['truckDealersInfo'];
			truckDealersInfo.unshift(noTruckDealer);
			this.truckDealersInfo = truckDealersInfo;
		}

		if((this.deviceObj.customerid && this.deviceObj.isNew) || (this.deviceObj.id && this.device['customerid'])) {
			this.device['isClientNeeded'] = true;
			this.device['site'] = {
				'id': this.deviceObj.siteid
			};
			if(this.deviceObj.siteid) {
				this.showSelectedAddress(this.deviceObj.siteid);
			}
		} else if(this.deviceObj.id) {

			this.device['site'] = {};
			this.device['client'] = {};
			this.device['isClientNeeded'] = false;
		}
	}

	showSelectedAddress(siteId) {
		if(siteId) {
			this.device['site'].id = siteId;
			for (let i = 0; i < this.clientSites.length; i++) {
				if(this.clientSites[i]['id'] == siteId) {
					this.device['site']['address'] = this.clientSites[i]['address'];
					break;
				}
			}
		} else {

			this.device['site'] = {
				id: 0
			};
		}
	}

	getClientsByNameIndex(clientNameIndex) {
		clientNameIndex = clientNameIndex || 0;
		this.device['clientNameIndex'] = clientNameIndex;
		this.device['customerid'] = 0;
		this.device['site'] = {};
		this.device['client'] = {};
		this.clients = [];
		this.clientSites = [];

		if(clientNameIndex != 'new') {
			this.adminService.getClientsByNameIndex(clientNameIndex).subscribe(
				data => this.clients = _.sortBy(data['list'], 'name')
			);
		} else {
			this.clientSites = [this.newSiteOption];
		}
	}

	getClientSites(customerid) {
		this.device['customerid'] = customerid;
		this.device['site'] = {};
		this.clientSites = [];
		this.adminService.getClientSites(customerid).subscribe(
			data => {
				this.clientSites = [this.newSiteOption].concat(_.sortBy(data['list'], 'name'));
			}
		);
	}

	handleError(err = '') {
		if(!err)
			err = 'globalErrMsg';

		this.notificationMessage.setMessage(err);
	}

	saveDevice() {
		if (!this.device['isClientNeeded']) {
			this.device['customerid'] = 0;
			this.device['site'] = '';
		}
		
		this.device['isNew'] = this.deviceObj.isNew;

		let invalidInputs = this.elementRef.nativeElement.querySelectorAll('.invalid-input');
		invalidInputs.forEach((item) => {
			item.classList.remove('invalid-input');
		});

		let freshDevice = this.device;
		let dirtyFields = {};
		let oldDevice = this.oldDevice;

		for (let key in freshDevice) {
			
			if(!oldDevice.hasOwnProperty(key) || oldDevice[key] != freshDevice[key]) {
			
				dirtyFields[key] = freshDevice[key];
			}
		}

		let invalidFields = [];
		if(this.deviceType == 'charger')
			invalidFields = this.chargerService.validateChargerSettings(dirtyFields, false, {}, {});
		else
			invalidFields = this.battviewService.validateBattviewSettings(dirtyFields, false, {}, {});

		if(invalidFields.length > 0) {
			for (let i = 0; i < invalidFields.length; i++) {
				let field = invalidFields[i];
				var ele = this.elementRef.nativeElement.querySelector('[name=' + field + ']');
				if(ele)
					ele.classList.add('invalid-input');
			}
			this.handleError('Invalid input!');
		} else {
			if(this.deviceType == 'charger') {
				this.adminService.saveCharger(this.device).subscribe(
					(data: any) => {
						if(data.httpStatus == 'success') {
							if (data.duplicateSite)
								return this.notificationMessage.setMessage('translate|site.duplicate_site');
							this.goBack();
						} else {
							this.handleError(data.msg);
						}
					}
				);
			} else {
				//battview
				if(this.deviceType == 'battviewMobile')
					this.device.isBattviewMobile = true;

				this.adminService.saveBattview(this.device).subscribe(
					(data: any) => {
						if(data.httpStatus == 'error') {

							var errorMessage = this.battviewService.formatProductionLineErrors(data.msg);
							this.notificationMessage.setMessage(errorMessage, 'danger', 30000);
						} else if(data.httpStatus == 'errorSN') {
							this.handleError(data.msg);
						} else {
							if (data.duplicateSite)
								return this.notificationMessage.setMessage('translate|site.duplicate_site');
							this.goBack();
						}
					}
				);
			}
		}
	};

	goBack() {
		let pageURL = ['/' + this.deviceType + 's', 'customerId', this.deviceObj.customerid, 'siteId', this.deviceObj.siteid];
		if(this.deviceType == 'battviewMobile')
			pageURL = ['/battviews-mobile-listing'];
		this.navigation.goBack(pageURL);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}