import { Injectable } from '@angular/core';
import { UserService } from '../../../auth/user.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { CommonDataService } from '../../../shared/services/common-data.service';

@Injectable()
export class BattviewService {

	battviewSNRules = [
		{type: 'string', min: 12, max: 12},
		{type: 'regex', patt: /^3(10|11|20|21|30|22)(0[1-9]|1[0-2])(1[6-9]|[2-9][0-9])([0-9]{5})$/}
	];
	daughterCardSNRules	= {type: 'regex', patt: /^610([0-9]{4})(0)([0-9]{4})$/};

	constructor(private userService: UserService, private commonUtil: CommonUtil, private commonData: CommonDataService) { }

	validateBattviewSettings(battview, isBulk, oldBattview, originalBattviewsInfo) {

		let minInstallationDate = new Date(new Date().setFullYear(new Date().getFullYear()-1)); //'1 year ago'
		let maxInstallationDate = new Date(new Date().setFullYear(new Date().getFullYear()+1)); //'1 year from now'

		var maxSSIDpasswordLength = 13;
		if(oldBattview.battviewversion == 2)
			maxSSIDpasswordLength = 31;

		let validationRules = {
			'serialnumber'						: this.battviewSNRules,
			'hwversion'							: [{type: 'string', min: 1, max: 2}, {type: 'regex', patt: /^([a-zA-Z ]{1,2})$/}], //format: "A" -> "ZZ"
			'autologtime'						: [{type: 'integer', min: 1, max: 65535}],
			'ispa'								: [{type: 'boolean'}],
			'enableexttempsensing'				: [{type: 'boolean'}],
			'enableelectrolyesensing'			: [{type: 'boolean'}],
			'enablehalleffectsensing'			: [{type: 'boolean'}],
			'enableplc'							: [{type: 'boolean'}],
			'temperatureformat'					: [{type: 'inArray', subType: 'integer', values: [0, 1]}],
			'halleffectscale'					: [{type: 'inArray', subType: 'integer', values: [1, 2]}],
			'enabledaylightsaving'				: [{type: 'boolean'}],
			'temperaturecontrol'				: [{type: 'inArray', subType: 'integer', values: [0, 5, 8]}],
			'mobileaccessssid'					: [{type: 'string', min: 8, max: 31}, {type: 'regex', patt: /^[^'" ]*$/}],
			'mobileaccessssidpassword'			: [{type: 'string', min: 8, max: 13}, {type: 'regex', patt: /^[^'" ]*$/}],
			'mobileport'						: [{type: 'integer', min: 49152, max: 65535}],
			'actviewenabled'					: [{type: 'boolean'}],
			'disableintercell'					: [{type: 'boolean'}],
			'actviewip'							: [{type: 'string', min: 7, max: 31}, {type: 'regex', patt: /^[^'" ]*$/}],
			'actviewport'						: [{type: 'integer', min: 1024, max: 65535}],
			'actviewconnectfrequency'			: [{type: 'integer', min: 1, max: 65535}],
			'actaccessssid'						: [{type: 'string', min: 1, max: 31}, {type: 'regex', patt: /^[^'" ]*$/}],
			'actaccessssidpassword'				: [{type: 'string', min: 8, max: maxSSIDpasswordLength}, {type: 'regex', patt: /^[^'" ]*$/}],
			'batteryid'							: [{type: 'string', min: 1, max: 17}],
			'batterysn'							: [{type: 'string', min: 0, max: 17}],
			'batterymodel'						: [{type: 'string', min: 0, max: 17}],
			'truckid'							: [{type: 'string', min: 0, max: 17}],
			'installationdate'					: [{type: 'integer', min: Math.floor(minInstallationDate.getTime() / 1000), max: Math.floor(maxInstallationDate.getTime() / 1000)}],
			'manufacturingdateview'				: [{type: 'integer', min: 0, max: Math.floor(maxInstallationDate.getTime() / 1000)}],
			'zoneid'							: [{type: 'integer', min: 0, max: 107}],
			'ahrcapacity'						: [{type: 'integer', min: 100, max: 2000}],
			'nominalvoltage'					: [{type: 'inArray', subType: 'integer', values: [24,26,28,30,32,34,36,38,40,42,44,46,48,72,80]}],
			'blockedeqdays'						: [{type: 'inArray', subType: 'integer', values: [0, 5, 6, 7, 8, 9]}],
			'blockedfidays'						: [{type: 'inArray', subType: 'integer', values: [0, 5, 6, 7, 8, 9]}],
			'temperaturevoltagecompensation'	: [{type: 'float', min: 3, max: 8}],
			'batteryhightemperature'			: [{type: 'float', min: 25, max: 125}],
			'batterytype'						: [{type: 'inArray', subType: 'integer', values: [0, 1, 2, 4]}],
			'warrantedahr'						: [{type: 'integer', min: 125000, max: 6250000}],
			'trtemperature'						: [{type: 'float', min: -5, max: 25}],
			'foldtemperature'					: [{type: 'float', min: 25, max: 125}],
			'cooldowntemperature'				: [{type: 'float', min: 25, max: 125}],
			'chargertype'						: [{type: 'inArray', subType: 'integer', values: [0, 1, 2]}],
			'tricklecurrentrate' 				: [{type: 'integer', min: 3, max: 30}],
			'ccrate' 							: [{type: 'integer', min: 4, max: 100}],
			'ficurrentrate' 					: [{type: 'float', min: 1, max: 13, step: 0.5}],
			'eqcurrentrate' 					: [{type: 'integer', min: 1, max: 11}],
			'plc_gain' 							: [{type: 'integer', min: 1, max: 5}],
			'tricklevoltage'					: [{type: 'float', min: 1.95, max: 2.1}],
			'cvtargetvoltage'					: [{type: 'float', min: 2.1, max: 2.5}],
			'fitargetvoltage'					: [{type: 'float', min: 2.25, max: 2.65}],
			'eqvoltage'							: [{type: 'float', min: 2.3, max: 2.7}],
			'cvendcurrentrate'					: [{type: 'float', min: 4, max: 15, step: 0.5}],
			'cvcurrentstep'						: [{type: 'float', min: 0, max: 10, step: 0.5}, {type: 'notInArray', subType: 'float', values: [0.5, 1, 1.5]}],
			'cvmaxduration'						: [{type: 'time', min: '01:00', max: '05:00', step: 60}],
			'fiduration'						: [{type: 'time', min: '01:00', max: '06:00', step: 15}],
			'eqduration'						: [{type: 'time', min: '02:00', max: '06:00', step: 15}],
			'desulfation'						: [{type: 'time', min: '06:00', max: '18:00', step: 15}],
			'fidv'								: [{type: 'integer', min: 5, max: 100, step: 5}],
			'fidt'								: [{type: 'integer', min: 10, max: 59}],
			'eqstartwindow'						: [{type: 'time', min: '00:00', max: '23:59'}],
			'eqtimer'							: [{type: 'time', min: '02:30', max: '24:00', step: 15}],
			'eqdaysmask'						: [{type: 'daysMask'}],
			'fistartwindow'						: [{type: 'time', min: '00:00', max: '23:59'}],
			'finishwindow'						: [{type: 'time', min: '02:30', max: '24:00', step: 15}],
			'fidaysmask'						: [{type: 'daysMask'}]
		};

		let access = this.userService.getCurrentUser()['userAccess'];

		let invalidFields = [];

		for(let field in battview) {

			let accessField = this.commonData.accessFieldsMapper['battview'][field];
			if(accessField) {

				let permission = access[accessField];
				if(permission == 'write') {

					let rules = validationRules[field] || [];
					for (let i = 0; i < rules.length; i++) {
						let rule = rules[i];

						if(invalidFields.indexOf(field) == -1) {

							let isValid = this.commonUtil.dataValidator(rule.type, rule, battview[field]);

							if(!isValid)
								invalidFields.push(field);
						}
					}
				} else {

					let isValid = false;
					if(field == 'warrantedahr' && battview.ahrcapacity) {
						if(battview.warrantedahr == (battview.ahrcapacity*1250))
							isValid = true;
					}

					if(!isValid)
						invalidFields.push(field);
				}
			}
		}

		let temperatureFields = ['cooldowntemperature', 'foldtemperature', 'batteryhightemperature', 'trtemperature'];

		let validateTemperatures = function(_battview, _oldBattview) {

			let isValid = false;

			_battview['cooldowntemperature']	= _battview.hasOwnProperty('cooldowntemperature') ? _battview['cooldowntemperature'] : _oldBattview['cooldowntemperature'];
			_battview['foldtemperature']		= _battview.hasOwnProperty('foldtemperature') ? _battview['foldtemperature'] : _oldBattview['foldtemperature'];
			_battview['batteryhightemperature']	= _battview.hasOwnProperty('batteryhightemperature') ? _battview['batteryhightemperature'] : _oldBattview['batteryhightemperature'];
			_battview['trtemperature']			= _battview.hasOwnProperty('trtemperature') ? _battview['trtemperature'] : _oldBattview['trtemperature'];

			if(_battview['trtemperature'] < _battview['cooldowntemperature'] && _battview['cooldowntemperature'] < _battview['foldtemperature']
				&& _battview['foldtemperature'] < _battview['batteryhightemperature']
			) {

				isValid = true;
			}

			if(!isValid) {
				temperatureFields.forEach(function(temperField) {
					invalidFields.push(temperField);
				});
			}

			return;
		}

		if(battview.hasOwnProperty('cooldowntemperature') || battview.hasOwnProperty('foldtemperature')
			|| battview.hasOwnProperty('batteryhightemperature') || battview.hasOwnProperty('trtemperature')
		) {

			validateTemperatures(battview, oldBattview);
		}

		let battviewCurrents = ['ficurrentrate', 'tricklecurrentrate', 'eqcurrentrate', 'ccrate', 'cvendcurrentrate'];

		let validateCurrentRates = function(tempBATT, _oldBattview) {

			let isValid = false;

			let _battview = Object.assign({}, tempBATT);

			_battview['ficurrentrate']		= _battview.hasOwnProperty('ficurrentrate') ? _battview['ficurrentrate'] : _oldBattview['ficurrentrate'];
			_battview['tricklecurrentrate']	= _battview.hasOwnProperty('tricklecurrentrate') ? _battview['tricklecurrentrate'] : _oldBattview['tricklecurrentrate'];
			_battview['eqcurrentrate']		= _battview.hasOwnProperty('eqcurrentrate') ? _battview['eqcurrentrate'] : _oldBattview['eqcurrentrate'];
			_battview['ccrate']				= _battview.hasOwnProperty('ccrate') ? _battview['ccrate'] : _oldBattview['ccrate'];
			_battview['cvendcurrentrate']	= _battview.hasOwnProperty('cvendcurrentrate') ? _battview['cvendcurrentrate'] : _oldBattview['cvendcurrentrate'];

			if(_battview['ccrate'] >= _battview['tricklecurrentrate'] && _battview['ccrate'] >= _battview['ficurrentrate']
				&& _battview['ficurrentrate'] >= _battview['eqcurrentrate'] && _battview['ccrate'] >= _battview['cvendcurrentrate']
			) {

				isValid = true;
			}

			if(!isValid) {
				battviewCurrents.forEach(function(temperField) {
					invalidFields.push(temperField);
				});
			}

			return;
		}

		if(battview.hasOwnProperty('ficurrentrate') || battview.hasOwnProperty('tricklecurrentrate')
			|| battview.hasOwnProperty('eqcurrentrate') || battview.hasOwnProperty('ccrate') || battview.hasOwnProperty('cvendcurrentrate')
		) {

			if(isBulk) {

				originalBattviewsInfo.forEach(function(_oldBattview) {
					validateCurrentRates(battview, _oldBattview);
				});
			} else {

				validateCurrentRates(battview, oldBattview);
			}
		}

		let battviewVoltages = ['eqvoltage', 'fitargetvoltage', 'cvtargetvoltage', 'tricklevoltage'];

		let validateTargetVoltages = function(tempBATT, _oldBattview) {

			let isValid = false;

			let _battview = Object.assign({}, tempBATT);

			_battview['eqvoltage']			= _battview.hasOwnProperty('eqvoltage') ? _battview['eqvoltage'] : _oldBattview['eqvoltage'];
			_battview['fitargetvoltage']	= _battview.hasOwnProperty('fitargetvoltage') ? _battview['fitargetvoltage'] : _oldBattview['fitargetvoltage'];
			_battview['cvtargetvoltage']	= _battview.hasOwnProperty('cvtargetvoltage') ? _battview['cvtargetvoltage'] : _oldBattview['cvtargetvoltage'];
			_battview['tricklevoltage']		= _battview.hasOwnProperty('tricklevoltage') ? _battview['tricklevoltage'] : _oldBattview['tricklevoltage'];

			if(_battview['cvtargetvoltage'] >= _battview['tricklevoltage'] && _battview['cvtargetvoltage'] <= _battview['fitargetvoltage']
				&& _battview['fitargetvoltage'] <= _battview['eqvoltage']
			) {

				isValid = true;
			}

			if(!isValid) {
				battviewVoltages.forEach(function(voltField) {
					invalidFields.push(voltField);
				});
			}

			return;
		}

		if(battview.hasOwnProperty('eqvoltage') || battview.hasOwnProperty('fitargetvoltage')
			|| battview.hasOwnProperty('cvtargetvoltage') || battview.hasOwnProperty('tricklevoltage')
		) {

			if(isBulk) {

				originalBattviewsInfo.forEach(function(_oldBattview) {
					validateTargetVoltages(battview, _oldBattview);
				});
			} else {

				validateTargetVoltages(battview, oldBattview);
			}
		}

		return invalidFields;
	}

	validateStudy(study) {
		let validationRules = {
			'studyname'			: [{type: 'string', min: 1, max: 17}],
			'truckid'			: [{type: 'string', min: 1, max: 17}],
		};

		let invalidFields = [];

		for(let field in study) {
			let rules = validationRules[field] || [];
			for (const rule of rules) {

				if(invalidFields.indexOf(field) == -1) {

					let isValid = this.commonUtil.dataValidator(rule.type, rule, study[field]);

					if(!isValid)
						invalidFields.push(field);
				}
			}
		}

		return invalidFields;
	};

	formatProductionLineErrors(err) {
		var typeMsg = {
			'disconnected':	"* Device didn't connect recently:",
			'old_firmware':	"* Device didn't finish firmware update:",
			'has_errors':	"* Device errors:",
			'has_queue':	"* Commands not executed:",
			'no_reset':		"* Can not edit device:"
		};

		var errorMessage = "";

		for(var type in err) {
			if(err[type].length > 0) {
				errorMessage += typeMsg[type] + '<br/>';
				errorMessage += err[type].join(', ') + '<br/>';
			}
		}

		return errorMessage;
	}
}
