import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SiteDashboardService } from '../../site-dashboard.service';
import { UserService } from '../../../../auth/user.service';
import { BattviewsDashboardService } from '../battviews-dashboard/battviews-dashboard.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';

@Component({
	selector: 'app-site-battery-performance',
	templateUrl: './site-battery-performance.component.html',
	styleUrls: ['./site-battery-performance.component.css']
})
export class SiteBatteryPerformanceComponent implements OnInit, OnDestroy {

	user;
	customerId;
	siteId;
	data;
	siteBattviewsData;
	siteTags;
	siteBattviewsTags;
	customerName: string = '';
	siteName: string = '';
	currentSiteSub: any;
	activeDataSub: any;

	constructor(
		private siteDashboardService: SiteDashboardService,
		private userService: UserService,
		private route: ActivatedRoute,
		private sideMenu: SideMenuService,
		private battviewsDashboardService: BattviewsDashboardService,
		private commonUtil: CommonUtil,
	) {
		this.siteDashboardService.setActiveSubTab('battery-performance');
	}

	ngOnInit() {
		this.user = this.userService.getCurrentUser();

		this.currentSiteSub = this.sideMenu.currentSite.subscribe(siteInfo => {
			this.customerId		= siteInfo.customerid;
			this.siteId			= siteInfo.id;
			this.customerName	= siteInfo.customer_name;
			this.siteName		= siteInfo.name;

			if(this.customerId && this.siteId) {
				this.activeDataSub = this.siteDashboardService.activeDashboardData.subscribe(data => this.data = Object.assign({}, data));
				this.siteDashboardService.getDashboardData(this.customerId, this.siteId);
				this.battviewsDashboardService.getBattviews({customerid: this.customerId, siteid: this.siteId, getTags: true}).subscribe((response:any) => {

					this.siteBattviewsData	= this.commonUtil.arrayToAssociativeArray(response.list, 'id');
					let siteTags = [];
					let siteBattviewsTags	= {};

					for(var i in response.tags.siteTags) {
						siteTags.push(response.tags.siteTags[i]);
					}
					this.siteTags = siteTags;

					for(let battviewId in response.tags.deviceTags) {
						let battviewTagsIds = response.tags.deviceTags[battviewId];
						for (let i in battviewTagsIds) {
							let tag = battviewTagsIds[i];
							let tagId = tag.tagid;
							if (!siteBattviewsTags[tagId]) {
								siteBattviewsTags[tagId] = [];
							}
							siteBattviewsTags[tagId].push(battviewId);
						}
					}
					this.siteBattviewsTags = siteBattviewsTags;
				});
			}
		});
	}

	ngOnDestroy() {
		this.currentSiteSub.unsubscribe();
		if(this.activeDataSub)
			this.activeDataSub.unsubscribe();
	}
}