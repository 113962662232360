import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BreadcrumbService } from '../../../../shared/breadcrumb/breadcrumb.service';
import { AdminService } from '../../../admin.service';
import { UserService } from '../../../../auth/user.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { NotificationMessageService } from '../../../../shared/notification-message/notification-message.service';
import * as moment from 'moment';
 
@Component({
	selector: 'app-equipment-listing',
	templateUrl: './equipment-listing.component.html',
	styleUrls: ['./equipment-listing.component.css']
})
export class EquipmentListingComponent implements OnInit, OnDestroy {

	equipmentsDevices: any[];
	equipments: any[];
	linkId: string;
	linkType: string;
	equipmentsArr: any;
	equipmentsParents: any;

	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute,
		private breadcrumb: BreadcrumbService,
		public userService: UserService,
		private commonUtil: CommonUtil, 
		private router: Router,
		private notificationMessage: NotificationMessageService,
	) {
	}

	ngOnInit() {
		this.linkType = this.route.snapshot.params.linkType;
		this.linkId = this.route.snapshot.params.linkId;

		let breadCrumbLabel = '';
		let url = '';
		let breadcrumbs = [];
		switch(this.linkType) {
			case 'oem':
				breadCrumbLabel = 'OEMs';
				url = '/oems';
			break;
			case 'dealer':
				breadCrumbLabel = 'Dealers';
				url = '/dealers';
			break;
			case 'truck-dealer':
				breadCrumbLabel = 'Truck Dealers';
				url = '/truck-dealers';
			break;
			case 'customer':
				breadCrumbLabel = 'Customers';
				url = '/customers';
			break;
			case 'site':
				breadcrumbs.push({label: 'Customers', url: '/customers'});
				breadCrumbLabel = 'Sites';
				url = '/sites/customerId/'+this.linkId;
			break;
		}
		breadcrumbs.push({label: breadCrumbLabel, url: url});
		breadcrumbs.push({label: 'Equipment', url: ''});
		this.breadcrumb.setBreadcrumb(breadcrumbs);

		this.adminService.getEquipmentsDevices(0, this.linkId, this.linkType).subscribe(
			(equipments: {
				equipment: any,
				parents: any,
				equipmentDevices: any[]
			}) => {
				this.equipments = equipments.equipment;
				this.equipmentsParents = equipments.parents;
				this.equipmentsDevices = equipments.equipmentDevices;
				this.equipmentsArr = this.commonUtil.arrayToAssociativeArray(this.equipments, 'id');
				this.equipmentsDevices.forEach(function(device) {
					device.deleteable = !device.children || !Object.keys(device.children).length;
				});
			}
		);
	}
	getKeys(obj) {
		return Object.keys(obj);
	}

	deleteEquipment(id, childId = 0) {
		if (!confirm('Are you sure you want to delete this Equipment?'))
			return false;

		this.adminService.deleteEquipmentDevice(id, childId).subscribe(
			response => {
				this.notificationMessage.setMessage(response, 'success', 10000, true);
				this.ngOnInit();
			}
		);
	};

	getFormattedDate(date) {
		return moment(date).format('L');
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}