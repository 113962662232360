import { Component, OnInit, Input, ChangeDetectorRef, OnChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { AdminService } from '../../../admin/admin.service';
import { UserService } from '../../../auth/user.service';
import * as _ from 'underscore';
import { v4 as uuid } from 'uuid';

@Component({
	selector: 'app-site-edit',
	templateUrl: './site-edit.component.html',
	styleUrls: ['./site-edit.component.css'],
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class SiteEditComponent implements OnInit, OnChanges {
	hasENERNOCProvider = false;
	@Input() mode: String = 'devices';
	@Input() site: any;
	@Input() readyArrays: boolean = false;
	@Input() OEMsInfo: Array<any> = [];
	@Input() dealersInfo: Array<any> = [];
	@Input() serviceDealersInfo: Array<any> = [];
	@Input() truckDealersInfo: Array<any> = [];
	@Input() ACTusers: Array<any> = [];
	@Input() siteOwners: Array<any> = [];

	TimeZonesMenu = <any>[];
	minInstallationDate = new Date(2015, 1, 1);
	allDealerUsers	= {};
	dealerUsers		= [];
	serviceDealerUsers = [];
	truckDealerUsers = [];
	currentUser: any = {};
	token : string = "";
	siteWasActFirst: boolean = false;
	validToken: boolean = false;

	constructor(
		private commonData: CommonDataService,
		private chRef: ChangeDetectorRef,
		private adminService: AdminService,
		public userService: UserService
	) {
	}

	ngOnInit() {
		this.TimeZonesMenu = this.commonData['TimeZonesMenu'];
		this.currentUser = this.userService.getCurrentUser();
		this.hasENERNOCProvider = this.adminService.hasENERNOCProvider;
	}

	ngOnChanges(changes) {
		let noOEM			= {'id':0, 'name':'- No OEM -'};
		let noServiceDealer	= {'id':0, 'name':'- No Service Dealer -'};
		let noTruckDealer	= {'id':0, 'name':'- No Truck Dealer -'};

		if(!this.readyArrays && changes.OEMsInfo && changes.OEMsInfo.currentValue !== undefined && changes.OEMsInfo.currentValue.length > 0) {
			this.OEMsInfo = [noOEM].concat(changes.OEMsInfo.currentValue);
		}

		if(!this.readyArrays && changes.serviceDealersInfo && changes.serviceDealersInfo.currentValue !== undefined && changes.serviceDealersInfo.currentValue.length > 0) {
			this.serviceDealersInfo = [noServiceDealer].concat(changes.serviceDealersInfo.currentValue);
		}

		if(!this.readyArrays && changes.truckDealersInfo && changes.truckDealersInfo.currentValue !== undefined && changes.truckDealersInfo.currentValue.length > 0) {
			this.truckDealersInfo = [noTruckDealer].concat(changes.truckDealersInfo.currentValue);
		}

		if(changes.ACTusers && changes.ACTusers.currentValue !== undefined && changes.ACTusers.currentValue.length > 0) {
			changes.ACTusers.currentValue.forEach(function(user) {
				user['name'] = user['firstname'] + ' ' + user['lastname'];
			});
			this.ACTusers = _.sortBy(changes.ACTusers.currentValue, 'name');
		}

		if(changes.siteOwners && changes.siteOwners.currentValue !== undefined && changes.siteOwners.currentValue.length > 0) {
			changes.siteOwners.currentValue.forEach(function(user) {
				user['name'] = user['firstname'] + ' ' + user['lastname'];
			});
			this.siteOwners = _.sortBy(changes.siteOwners.currentValue, 'name');
		}

		if (changes.site && changes.site.currentValue !== undefined) {
			this.token = this.site['act_first_token'];
			this.siteWasActFirst = this.site.act_first_enabled;
			this.validToken = this.siteWasActFirst;
			if(!this.site.dr_provider)
				this.site.dr_provider = 'None';

			if (this.site.dr_phones_whitelist) {
				let phonesListView = [];
				for (let item of this.site.dr_phones_whitelist) {
					phonesListView.push({value: item});
				}
				this.site.dr_phones_whitelist = phonesListView;
			} else {
				this.site.dr_phones_whitelist = [{value: ""}];
			}
		}
	}

	updateSiteCoords(coords) {
		this.site['latitude'] = coords['lat'];
		this.site['longitude'] = coords['lng'];
		this.site['address'] = coords['address'];
		this.site['country'] = coords['country'];
		this.site['state'] = coords['state'];
		this.chRef.detectChanges();
	}

	getDealerUsers(id, type) {
		if(this.mode != 'manage' || !id)
			return;

		switch(type) {
			case 'dealer':
				this.site.dealer_users = [];
				break;
			case 'service':
				this.site.service_dealer_users = [];
				break;
			case 'truck':
				this.site.truck_dealer_users = [];
				break;
		}

		if(this.allDealerUsers[id]) {
			switch(type) {
				case 'dealer':
					this.dealerUsers = this.allDealerUsers[id];
					break;
				case 'service':
					this.serviceDealerUsers = this.allDealerUsers[id];
					break;
				case 'truck':
					this.truckDealerUsers = this.allDealerUsers[id];
					break;
			}
			return;
		}
		this.adminService.getDealerUsers(id).subscribe(
			(response:any) => {

				response.forEach(function(user) {
					user['name'] = user['firstname'] + ' ' + user['lastname'];
				});
				if(this.currentUser.isDealer && this.currentUser.dealerid == id) {
					response.forEach((user, i) => {
						if(user.id == this.currentUser.id) {
							response.splice(i, 1); //remove user
							i--;
						}
					});
				}

				this.allDealerUsers[id]	= response;

				switch(type) {
					case 'dealer':
						this.dealerUsers = response;
						break;
					case 'service':
						this.serviceDealerUsers = response;
						break;
					case 'truck':
						this.truckDealerUsers = response;
						break;
				}
			}
		);
	}

	checkUser(user, type) {
		if(!user.id) {

			switch(type) {
				case 'dealer':
					this.site.dealer_users.pop();
					break;
				case 'service':
					this.site.service_dealer_users.pop();
					break;
				case 'truck':
					this.site.truck_dealer_users.pop();
					break;
			}
		}
	}

	generateToken(regenerate) {
		if (this.site.act_first_enabled && (regenerate || !this.site['act_first_token'] || this.site['act_first_token'] != this.token )) {
			let token = this.site.id + uuid();
			this.site['act_first_token'] = token;
			this.token = token;
			this.validToken = false;
		}
	}

	addNewDRPhoneWhiteList() {
		this.site.dr_phones_whitelist.push({value:""});
	}

}
