<app-site-widget
	*ngIf="!hideWidget"
	[title]="widgetName"
	elementId="batt_eq_summary"
	chartType="column"
	widgetClass="height-600"
	[siteWidgetOptions]="{hasEqTypeFilter: isACTuser, hasDate: true, isSitePerformanceWidget: true, hideYesterdayDate: true}"
	[widgetOptions]="{hasExport: true}"
	(eventEmit)="eventEmit($event)"
	[data]="data"
	[config]="config"
	[tableColumns]="tableColumns"
	[siteId]="siteId"
	[customerId]="customerId"
	[customerName]="customerName"
	[siteName]="siteName"
></app-site-widget>