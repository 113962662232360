<div class="row">
	<div id="page-wrapper" >
		<div id="page-inner">
			<div class="panel panel-default">
				<div class="boxs3">
					<table class="table table-stripped boxs" *ngIf="log">
						<tbody>
							<tr></tr>
							<tr>
								<td>Time</td>
								<td>{{log.error_time}}</td>
							</tr>
							<tr>
								<td>Function Name</td>
								<td>{{log.function_name}}</td>
							</tr>
							<tr>
								<td>Main Caller</td>
								<td>{{log.main_caller}}</td>
							</tr>
							<tr>
								<td>User ID</td>
								<td>{{log.user_id}}</td>
							</tr>
							<tr>
								<td>Error</td>
								<td>{{log.error}}</td>
							</tr>
							<tr>
								<td>Server IP</td>
								<td>{{log.server_ip}}</td>
							</tr>
							<tr>
								<td>User IP</td>
								<td>{{log.user_ip}}</td>
							</tr>
							<tr>
								<td>Browser</td>
								<td>{{log.browser}}</td>
							</tr>
							<tr>
								<td>Extra info</td>
								<td>{{log.extra_field}}</td>
							</tr>
							<tr>
								<td>Trace</td>
								<td>{{log.trace}}</td>
							</tr>
							<tr>
								<td>Is Sched?</td>
								<td>{{log.is_sched}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>