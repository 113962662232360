import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';

@Component({
	selector: 'app-site-alert-summary',
	templateUrl: './site-alert-summary.component.html',
	styleUrls: ['./site-alert-summary.component.css']
})
export class SiteAlertSummaryComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() isACTuser: boolean = false;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: ColumnChartConfig;
	tableColumns: any[] = [];
	columnsIds;
	columnsLabels;
	columnsNames;
	cacheKey: string = 'alertSummaryColumns_v1';

	constructor(
		private chartUtil: ChartUtil
	) { }

	ngOnInit() {
		this.config = new ColumnChartConfig({
			yTitle: "Batteries Count",
			widgetHeight: 400,
			hAxis: { slantedText: true, slantedTextAngle: 15},
			chartArea: { top: 10, width:'70%',height:'80%'}
		});

		this.config.formatFunction = (data) => {
			data = data.list;
			let formattedData: any[] = [
				[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'},
				]
			];

			for(let i = 0; i < data.length; i++) {
				let item: any = data[i];
				formattedData.push(
					[
						{v: item.Text},
						{v: item.value},
						{v: item.value},
						{v: 'color: #EA212D'}
					]
				);
			}

			return formattedData;
		};
		this.config.updateColumns = (index) => {
			let columnsArray = [];
			columnsArray.push(this.tableColumns[0]);
			if (this.data.siteSettings.userSettings.view_truck_id) {
				columnsArray.push(this.tableColumns[1]);
			}
			if(!Array.isArray(this.columnsNames[index])) {
				columnsArray.push(this.columnsNames[index]);
			} else {
				for(let item of this.columnsNames[index]) {
					columnsArray.push(item);
				}
			}
			return columnsArray;
		};
		this.config.setLocalStorage = (data) => {
			this.setLocalStorage(data);
		};
		this.setColumnsData();
	}

	ngOnChanges(changes) {
		if(this.data) {
			this.tableColumns = [{title: "Battery ID", property: "battery_id"}];
			if (this.data.siteSettings.userSettings.view_truck_id) {
				this.tableColumns.push({title: "Truck ID", property: "truck_id"});
			}
			// this.tableColumns.push({title: "Value", property: "value"});
			this.data['battviewsData'] = this.siteBattviewsData;
			this.data['siteTags'] = this.siteTags;
			this.data['siteBattviewsTags'] = this.siteBattviewsTags;
		}
	}

	setColumnsData() {
		this.columnsNames = {};
		this.columnsNames["0"] = {title: "Number of weekly missed EQs", property: "value"};
		this.columnsNames["1"] = [
			{title: "Number of times high temperature recorded", property: "value"},
			{title: "Max temperature recorded", property: "value2"}
		];
		this.columnsNames["2"] = {title: "% over charge", property: "value"}        
		var hiddenIndexes = [];
		var columnsIds = [0,1,2,4,5,6,7,9],
			columnsLabels = [
				{id: 0, label: "Weekly Missed EQ"},
				{id: 1, label: "High Temperature"},
				{id: 2, label: "Over Charge"},
				{id: 4, label: "Potential Weak Cells"},
				{id: 5, label: "Over Usage"},
				{id: 6, label: "Missed Finish"},
				{id: 7, label: "Deep Discharge"},
				{id: 9, label: "Water Level Alert"}
			];
		if (this.isACTuser) {
			this.columnsNames["3"] = {title: "% under charge", property: "value"};
			columnsIds.push(3);
			columnsLabels.push({id: 3, label: "Under Charge"});
		} else {
			hiddenIndexes.push(3);
		}
		this.columnsNames["4"] = [
			{title: "Min Load Voltage", property: "value"},
			{title: "Days of Low Voltage", property: "value2"},
		];
		this.columnsNames["5"] = [
			{title: "Average EBUs", property: "value"},
			{title: "Expected EBUs", property: "value2"}
		];
		this.columnsNames["6"] = [
			{title: "Expected FI Cycles", property: "value"},
			{title: "Actual FI Cycles", property: "value2"}
		];
		this.columnsNames["7"] = [
			{title: "Days of Deep Discharge", property: "value"},
			{title: "Min OCV (VPC)", property: "value2"}
		];
		this.columnsNames["8"] = {title: "Number of Potentially Sulfated Battery (+4 EQ Missed)", property: "value"};
		columnsIds.push(8);
		columnsLabels.push({id: 8, label: "Potentially Sulfated Battery Alert"});

		this.columnsNames["9"] = [{title: "Days of Low Water", property: "value"}];

		if (this.isACTuser) {
			this.columnsNames["10"] = [
                {title: "Number of times cold temperature recorded", property: "value"},
                {title: "Min temperature recorded", property: "value2"}
            ];
			columnsIds.push(10);
			columnsLabels.push({id: 10, label: "Cold Temperature"});
		} else {
			hiddenIndexes.push(10);
		}

		let cachedColumnsIds = this.getFromLocalStorage();
		if (cachedColumnsIds) {
			try {
				columnsIds = cachedColumnsIds;
				if(hiddenIndexes.length > 0) {
					var tempColumnsIds = [];
					columnsIds.forEach(function(item) {
						if(hiddenIndexes.indexOf(item) === -1)
							tempColumnsIds.push(item);
					});
					columnsIds = tempColumnsIds;
				}
			} catch (ignore) {}
		}

		this.columnsIds = columnsIds;
		this.columnsLabels = columnsLabels;
	}

	getFromLocalStorage() {
		return JSON.parse(localStorage.getItem(this.cacheKey));
	}

	setLocalStorage(data) {
		return localStorage.setItem(this.cacheKey, JSON.stringify(data));
	}
}