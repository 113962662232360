import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import * as _ from 'underscore';
import { DeviceManagementService } from '../../device-management.service';
import { AdminService } from '../../../admin/admin.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';


@Component({
	selector: 'app-charger-report',
	templateUrl: './charger-report.component.html',
	styleUrls: ['./charger-report.component.css']
})
export class ChargerReportComponent implements OnInit, AfterViewInit, OnDestroy {

	isCollapsed: {
		site: boolean,
		warnings: boolean,
		version: boolean,
		config: boolean,
		pms: boolean,
		history: boolean,
		dates : boolean,
	} = {
		site: true,
		warnings: true,
		version: true,
		config: true,
		pms: true,
		history: true,
		dates : true,
	};
	model: {
		hasSite: boolean,
		selectedCustomersNegation: boolean,
		selectedCustomers: number[],
		selectedSites: number[],
		selectedDealersNegation: boolean,
		selectedDealers: number[],
		selectedServiceDealersNegation: boolean,
		selectedServiceDealers: number[],
		selectedOEMsNegation: boolean,
		selectedOEMs: number[],
		isTestSite: boolean,
		actIntelligentSiteType: string,
		selectedWarnings: number[],
		selectedPMWarnings: number,
		numberPMFaultsOperation: string,
		numberPMFaults: number,
		fwVersionOperation: string,
		fwVersion: number,
		isTestMCB: boolean,
		MCBHwVersionOperation: string,
		MCBHwVersion: string,
		lcdMemoryVersion: number[],
		wifiVersion: number,
		isActviewEnabled: boolean,
		chargerType: number[],
		zoneid: number[],
		connectionTimeNegation: boolean,
		connectionTime: number,
		enabledPLC: boolean,
		ledcontrol: number,
		pmVoltageOperation: string,
		pmVoltage: number[],
		PMACVoltageNegation: boolean,
		PMACVoltage: number[],
		numberOfPMNegation: boolean,
		numberOfPM: number[],
		totalAHROperation: string,
		totalAHR: number,
		totalKWHROperation: string,
		totalKWHR: number,
		totalChargeTimeOperation: string,
		totalChargeTime: number,
		totalChargeCyclesOperation: string,
		totalChargeCycles: number,
		commissionDateOperation: string,
		commissionDate: Date,
		installationDateOperation: string,
		installationDate: Date,
		chaseTypeNegation: boolean,
		chaseType: number[],
		chaseDateOperation: string,
		chaseDate: Date,
	} = {
		hasSite: true,
		selectedCustomersNegation: false,
		selectedCustomers: [],
		selectedSites: [],
		selectedDealersNegation: false,
		selectedDealers: [],
		selectedServiceDealersNegation: false,
		selectedServiceDealers: [],
		selectedOEMsNegation: false,
		selectedOEMs: [],
		isTestSite: false,
		actIntelligentSiteType: null,
		selectedWarnings: [],
		selectedPMWarnings: null,
		numberPMFaultsOperation: '',
		numberPMFaults: null,
		fwVersionOperation: '',
		fwVersion: null,
		isTestMCB: null,
		MCBHwVersionOperation: '',
		MCBHwVersion: null,
		lcdMemoryVersion: [],
		wifiVersion: null,
		isActviewEnabled: null,
		chargerType: [],
		zoneid: [],
		connectionTimeNegation: false,
		connectionTime: null,
		enabledPLC: null,
		ledcontrol: null,
		pmVoltageOperation: '',
		pmVoltage: [],
		PMACVoltageNegation: false,
		PMACVoltage: [],
		numberOfPMNegation: false,
		numberOfPM: [],
		totalAHROperation: '',
		totalAHR: null,
		totalKWHROperation: '',
		totalKWHR: null,
		totalChargeTimeOperation: '',
		totalChargeTime: null,
		totalChargeCyclesOperation: '',
		totalChargeCycles: null,
		commissionDateOperation: '',
		commissionDate: null,
		installationDateOperation: '',
		installationDate: null,
		chaseTypeNegation: false,
		chaseType: [],
		chaseDateOperation: '',
		chaseDate: null,
	};
	customers: any[];
	customersSites: Object;
	sites: any[];
	allSites: any[];
	dealers: any[];
	OEMs: any[];
	warnings: {id: string, name: string}[] = [
		{id:'chargerConnectivity',name:this.translateService.instant('site.charger_connectivity')},
		{id:'chargerPLC',name:this.translateService.instant('device.charger_plc')},
		{id:'chargersCalibration',name:this.translateService.instant('device.charger_calibration')},
		{id:'chargerFaults',name:this.translateService.instant('device.charger_faults')},
		{id:'PMFaults',name:this.translateService.instant('device.pm_faults')},
		{id:'chargersNotUsed',name:this.translateService.instant('device.charger_not_used')},
		{id:'chargersTimeZone',name:this.translateService.instant('device.charger_time_zone')},
		{id:'chargersLostTime',name:this.translateService.instant('device.chargers_have_not_synched_time')},
	];
	lcdMemoryVersions: {id: number, name: string}[] = [
		{id:1, name:"1"},
		{id:2, name:"2"},
		{id:3, name:"3"},
		{id:4, name:"4"},
		{id:5, name:"5"},
	];
	batteryTypes: {id: number, name:string}[] = [
		{id: 0, name: this.translateService.instant('charger.fast')},
		{id: 1, name: this.translateService.instant('charger.conventional')},
		{id: 2, name: this.translateService.instant('charger.opportunity')},
	];
	PMVoltages: {id:number, name: string}[] = [
		{id: 36, name: "36"},
		{id: 48, name: "48"},
		{id: 80, name: "80"}
	];
	PMACVoltages: {id:number, name: string}[] = [
		{id: 1, name: "208"},
		{id: 3, name: "380"},
		{id: 0, name: "480"},
		{id: 2, name: "600"}
	];
	numberOfPMs: {id: number, name: string}[] = [
		{id: 1, name: "1"},
		{id: 2, name: "2"},
		{id: 3, name: "3"},
		{id: 4, name: "4"},
		{id: 5, name: "5"},
		{id: 6, name: "6"},
		{id: 7, name: "7"},
		{id: 8, name: "8"},
		{id: 9, name: "9"},
		{id: 10, name: "10"},
		{id: 11, name: "11"},
		{id: 12, name: "12"}
	];
	chaseTypes: {id: number, name: string}[] = [
		{id:1, name:"4X"},
		{id:2, name:"6X"},
		{id:3, name:"12X"}
	];
	reportName: string = null;

	@ViewChild("modal") modal;

	constructor(
		private route: ActivatedRoute,
		private deviceManagemntService: DeviceManagementService,
		private adminService: AdminService,
		private breadcrumb: BreadcrumbService,
		private notificationMessage: NotificationMessageService,
		private commonUtil: CommonUtil,
		private commonDataService: CommonDataService,
		private translateService: TranslateService
	) {
		this.breadcrumb.setBreadcrumb([
			{label: this.translateService.instant('device.charger_search'), url: ''}
		]);
	}

	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			if(params.isExpired){
				this.notificationMessage.setMessage('translate|g.file_has_been_deleted', 'warning', 20000);
			}
			if(params.enc){
				this.deviceManagemntService.prepareFileDownload('too_big_report', params.enc).subscribe((data:any)=>{
					this.commonUtil.downloadReport(data);
				});
			}
		});
		this.getDealers(0);
		this.getOEMs(0);
		this.deviceManagemntService.getAllSitesForUser().subscribe(
			(data: any[]) => {
				this.customers = this.commonUtil.extractKeysFromObjects(['customerid', 'customer_name'], data, true);
				this.customersSites = this.commonUtil.arrayToAssociativeArray(data, 'customerid', true);
				this.sites = data;
				this.allSites = data;
			}
		);
	}
	ngAfterViewInit() {
		this.modal.onClose.subscribe(
			(ok) => {
				if(ok) {
					this.scheduleReport();
				}
			}
		);
	}

	updateSitesAvailable = function() {
		this.sites = [];
		if(this.model.selectedCustomersNegation) {
			for(let customerId in this.customersSites) {
				if (this.model.selectedCustomers.indexOf((+customerId)) === -1) {
					this.sites = this.sites.concat(this.customersSites[customerId]);
				}
			}
		} else {
			if (this.model.selectedCustomers.length === 0) {
				_.extend(this.sites, this.allSites);
			} else {
				for(let customerId of this.model.selectedCustomers) {
					this.sites = this.sites.concat(this.customersSites[customerId]);
				}
			}
		}

		if (this.sites.length === 0)
			return;

		// remove any selected site not exists in list of sites (this.sites)
		var siteIds = this.commonUtil.extractKeyFromObjects('id', this.sites, true, true);
		var selectedSites = [];
		for(let selectedSite of this.model.selectedSites) {
			if(siteIds.indexOf(selectedSite) > -1){
				selectedSites.push(selectedSite);
			}
		}
		this.model.selectedSites = selectedSites;
	}
	scheduleReport() {
		if (!this.reportName)
			return this.notificationMessage.setMessage(this.translateService.instant('user.please_enter_report_name'), 'danger', 10000, true);

		var model = this.model;
		model['reportName'] = this.reportName;
		model['state'] = 'charger';

		this.deviceManagemntService.scheduleReport(model).subscribe(
			res => {
				this.reportName = '';
				this.notificationMessage.setMessage(this.translateService.instant('user.the_report_will_be_processed_and_sent_to_your_email'), 'success', 20000, true);
			}
		);
	}
	getDealers(id = 0) {
		let noDealer = {'id':0, 'name':this.translateService.instant('g.no_dealer')};
		this.adminService.getDealers(id).subscribe(
			data => {
				let dealers = data['dealers'] || [];
				dealers.unshift(noDealer);
				this.dealers = dealers;
			}
		);
	}
	getOEMs(id=0) {
		let noOEM = {'id':0, 'name':this.translateService.instant('g.no_oem')};    
		this.adminService.getOEMs(id).subscribe(
			(data: any[]) => {
				let OEMs = data || [];
				OEMs.unshift(noOEM);
				this.OEMs = OEMs;
			}
		);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}