<ul class="dropdown-menu" role="menu">
	<li *ngIf="!userService.hasAccessFunction('no_manage_devices') && !currentUser.isTruckDealer">
		<a [routerLink]="['/devices', 'devices-management', 'charger', charger.id]">{{manageDeviceBtnText[0] |translate}} {{manageDeviceBtnText[1]|translate}}</a>
	</li>
	<li *ngIf="charger.actviewenable && userService.hasAccessFunction('restart_devices')">
		<a (click)="restartCharger(charger.id)" class="has-action">{{'charger.restart_mcb'|translate}}</a>
	</li>
	<li *ngIf="charger.actviewenable && userService.hasAccessFunction('restart_devices') && charger.firmwareversion >= 2.29">
		<a (click)="restartCharger(charger.id, 'hard')" class="has-action">{{'charger.hard_restart_mcb'|translate}}</a>
	</li>
	<li *ngIf="userService.hasAccessFunction('act_admin_device_management') && charger.enableplc">
		<a (click)="addCommandToChargerQueue(charger.id, 'loadPLCfirmware')" class="has-action">{{'charger.load_plc_firmware'|translate}}</a>
	</li>
	<li *ngIf="currentUser.isACTuser && charger.time_gap > 0">
		<a (click)="addCommandToChargerQueue(charger.id, 'setRealTimeClock')" class="has-action">{{'charger.synch_time'|translate}}</a>
	</li>
	<li *ngIf="userService.hasAccessFunction('act_admin_device_management') && charger.firmwareversion >= 2.28">
		<a (click)="refreshDeviceWiFiInfo(charger.id)" class="has-action">{{'charger.refresh_wifi_info'|translate}}</a>
	</li>
	<li *ngIf="pageName != 'listing'">
		<a>
			<notes
				[siteId]="siteID"
				modelType="charger"
				[modelId]="charger.id"
				[isDropDown]="true"
				[isAddNoteOnly]="true"
			></notes>
		</a>
	</li>
</ul>