import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2, OnChanges } from '@angular/core';
import { DevicesDashboardService } from '../../../devices/devices-dashboard.service';
import { UserService } from '../../../../../auth/user.service';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import { BattviewsDashboardService } from '../battviews-dashboard.service';
import { BattviewService } from '../../../../../admin/devices/battviews/battview.service';
import { NotificationMessageService } from '../../../../../shared/notification-message/notification-message.service';
import { CommonDataService } from '../../../../../shared/services/common-data.service';

@Component({
	selector: 'app-battview-settings',
	templateUrl: './battview-settings.component.html',
	styleUrls: ['./battview-settings.component.css']
})
export class BattviewSettingsComponent implements OnInit, OnChanges {

	@Input() customerID: number = 0;
	@Input() siteID: number = 0;
	@Input() battviewsIDs: any = [];
	@Input() hasBattviews = false;
	@Input() isBattviewMobile: boolean = false;
	battview: any = {};
	currentUser: any = {};
	changesStack: any = {};
	oldBattview: any = {};
	userAccess: any = {};
	isBulk: boolean = false;
	originalBattviewsInfo: any = [];
	TRCurrentRates: any[]			= [];
	CCCurrentRates: any[]			= [];
	FICurrentRates: any[]			= [];
	EQCurrentRates: any[]			= [];
	TemperatureFallbackOpts: any[]	= [];
	blockedDaysOpts: any[]			= [];
	daysOfTheWeek: any[]			= [];
	finishDt: any[]					= [];
	cableGaugeOptions: any[]		= [];
	PMinputVoltages: any[]			= [];
	pmVoltages: any[]				= [];
	batteryTypes: any[]				= [];
	chargerTypes: any[]				= [];
	cvCurrentSteps: any[]			= [];
	cvFinishCurrents: any[]			= [];
	finishDvVoltages: any[]			= [];
	batteryVoltages: any[]			= [];
	timeIntervals: any[]			= [];
	quartsIntervals: any[]			= [];
	quarts_2_18: any[]				= [];
	quarts_6_18: any[]				= [];
	quarts_2_6: any[]				= [];
	quarts_1_6: any[]				= [];
	hours_1_5: any[]				= [];
	quarts_2_24: any[]				= [];
	limitingFactors: any[]			= [];
	resistancePerFoot: any			= {};
	dirtyFields: any				= {};
	temperatureFields: any = {
		'cooldowntemperature': '',
		'foldtemperature': '',
		'trtemperature': '',
		'batteryhightemperature': ''
	};
	formatTemperature: string = '';
	formName: string = '';
	tab: string = 'CARDS';
	stage: string = '';

	@Output() onMultipleOptionsFlag = new EventEmitter<boolean>();
	@Output() onGetBattviewInfo = new EventEmitter<object>();
	@Output() updateAppearanceElementsFlags = new EventEmitter<any>(true);

	@ViewChild("saveOptionsModal") saveOptionsModal;
	@ViewChild("chargerProfileModal") chargerProfileModal;

	constructor(
		private devicesDashboard: DevicesDashboardService,
		private battviewsDashboard: BattviewsDashboardService,
		private notificationMessage: NotificationMessageService,
		public commonData: CommonDataService,
		public userService: UserService,
		private battviewService: BattviewService,
		private elementRef: ElementRef,
		private renderer: Renderer2,
		public commonUtil: CommonUtil,
	) {
		devicesDashboard.settingsDeviceType = "BATTVIEW";
	}

	ngOnInit() {
		
		this.updateAppearanceElementsFlags.emit({
			addNoteEnabled: false,
			pageHasFilterByDate: false,
			pageHasBattviewTags: true,
			hasResetPLC: false,
			pageHasManageBtn: true,
			pageHasTimesInfo: true,
		});

		this.currentUser = this.userService.getCurrentUser();
		this.userAccess = this.currentUser.userAccess;

		if(this.battviewsIDs.length > 1) {
			this.hasBattviews = true;

			if(!this.isBattviewMobile)
				this.isBulk = true;
		}

		this.devicesDashboard.isBulkSettings = this.isBulk;

		this.finishDt				= this.commonUtil.getDropDownListData('number', {start:10, end:59, step:1});
		this.batteryTypes			= this.devicesDashboard.batteryTypes;
		this.chargerTypes			= this.devicesDashboard.chargerTypes;
		this.daysOfTheWeek			= this.commonUtil.getDropDownListData('week');
		let blockedDaysOpts: any	= [5, 6, 7, 8, 9];
		blockedDaysOpts				= this.commonUtil.arrayToArrayOfObjects(blockedDaysOpts, blockedDaysOpts);
		blockedDaysOpts.unshift({'id': 0, 'name': 'Disable'});
		this.blockedDaysOpts = blockedDaysOpts;
		this.TemperatureFallbackOpts	= this.battviewsDashboard.TemperatureFallbackOptions;

		let cvCurrentSteps			= this.commonUtil.getDropDownListData('number', {start:2, end:10, step:0.5});
		cvCurrentSteps.unshift(0);
		let cvCurrentStepsValues	= cvCurrentSteps.slice();
		cvCurrentStepsValues[0]		= 'Default';
		this.cvCurrentSteps			= this.commonUtil.arrayToArrayOfObjects(cvCurrentSteps, cvCurrentStepsValues);

		this.TRCurrentRates		= this.devicesDashboard.TRCurrentRates;
		this.CCCurrentRates		= this.devicesDashboard.CCCurrentRates;
		this.FICurrentRates		= this.devicesDashboard.FICurrentRates;
		this.EQCurrentRates		= this.devicesDashboard.EQCurrentRates;
		this.cvFinishCurrents	= this.devicesDashboard.cvFinishCurrents;
		this.finishDvVoltages	= this.devicesDashboard.finishDvVoltages;
		let batteryVoltages		= this.commonUtil.getDropDownListData('number', {'start': 24, 'end': 48, 'step': 2});
		batteryVoltages.push(72, 80);
		this.batteryVoltages = batteryVoltages;

		this.timeIntervals		= this.devicesDashboard.timeIntervals;
		this.quartsIntervals	= this.devicesDashboard.quartsIntervals;
		this.quarts_2_18		= this.devicesDashboard.quarts_2_18;
		this.quarts_6_18		= this.devicesDashboard.quarts_6_18;
		this.quarts_2_6			= this.devicesDashboard.quarts_2_6;
		this.quarts_1_6			= this.devicesDashboard.quarts_1_6;
		this.limitingFactors	= this.devicesDashboard.limitingFactors;
		this.hours_1_5			= this.commonUtil.getDropDownListData('clock',  {start:1, end:5, step:60});
		this.quarts_2_24		= this.commonUtil.getDropDownListData('clock',  {start:2, end:24, step:15, ignoreFirst: 2});
	}

	ngOnChanges(changes: any) {
		if(changes.battviewsIDs && changes.battviewsIDs.currentValue && changes.battviewsIDs.currentValue.length > 0)
			this.getBattviewsSettingsInfo();
	}

	setTab(tabId){
		this.tab = tabId;
	};

	isSet(tabId){
		return this.tab === tabId;
	};

	resetForms(removeErrors = false) {
		if(this.battviewsIDs.length > 0 && !removeErrors) {
			this.battview = Object.assign({}, this.oldBattview);
		}
		
		if(this.battviewsIDs.length > 0 || removeErrors) {
			let invalidInputs = this.elementRef.nativeElement.querySelectorAll('.invalid-input');
			invalidInputs.forEach((item) => {
				item.classList.remove('invalid-input');
			});
		}

		this.dirtyFields = {};
	}

	getBattviewsSettingsInfo() {
		if(this.battviewsIDs.length === 0)
			return;

		this.battviewsDashboard.getBattviewsSettingsInfo(this.battviewsIDs).subscribe(
			(battviews: any) => {

				this.originalBattviewsInfo = battviews.settings.map(x => Object.assign({}, x));

				if(this.isBulk) {
					this.formatTemperature = 'F';
					battviews.settings.forEach((aBattview) => {
						for(var field in this.temperatureFields) { //In case of bulk: Always show temperature fields in F format
							aBattview[field] = this.commonUtil.fahToCel(aBattview[field], true);
						}
					});
				}

				if(this.isBulk) {
					battviews.settings = this.commonUtil.getArrayOfObjectsIntersection(battviews.settings);
				} else {
					battviews.settings = battviews.settings[0];
					battviews.settings.wifi_at_version = this.commonUtil.Format_WIFI_AT_Version(battviews.settings.wifi_at_version);
				}

				if(battviews.settings.currentclamp2cala > 0)
					battviews.settings.halleffectreverse = false;
				else
					battviews.settings.halleffectreverse = true;

				if(battviews.settings.installationdate !== null)
					battviews.settings.installationdate = new Date(battviews.settings.installationdate);

				if(battviews.settings.manufacturingdateview !== null)
					battviews.settings.manufacturingdateview = new Date(battviews.settings.manufacturingdateview);

				this.oldBattview = Object.assign({}, battviews.settings);

				this.battview = battviews.settings;

				this.changesStack = this.battviewsDashboard.formatBattviewQueuedChanges(this.battview, battviews.changesStack);

				for(var field in this.temperatureFields) { //save original temperature fields value
					this.temperatureFields[field] = this.battview[field];
				}
	
				this.battview.lastuploadtime	= new Date(this.battview.lastuploadtime);
				this.battview.lasteventtime		= new Date(this.battview.lasteventtime);

				if(!this.isBulk) {
					this.formatTemperature = 'C';
					if(Object.keys(this.battview).length > 0) {
						if(this.battview.temperatureformat) {

							this.formatTemperature = 'F';
							for(let field in this.temperatureFields) { //temperatureformat is F so; convert C -> F
								this.oldBattview[field]	= this.commonUtil.fahToCel(this.battview[field], true);
								this.battview[field]	= this.commonUtil.fahToCel(this.battview[field], true);
							}
						}
					}

					let multipleOptions: boolean = false;
						
					if((this.userService.hasAccessFunction('act_admin_device_management') && this.battview.enableplc)
						|| (this.currentUser.isACTuser && this.battview.time_gap > 0) || this.userService.hasAccessFunction('restart_devices')
						|| (this.userService.hasAccessFunction('remote_battview_calibration') && !this.battview.isCalibrated && this.battview.actviewenabled && this.battview.enableplc && this.battview.firmwareversion >= 2.12)
						|| (this.userService.hasAccessFunction('reset_battview_history') && !this.battview.ispa)
					) {
						multipleOptions = true;
					}

					this.onMultipleOptionsFlag.emit(multipleOptions);
					this.onGetBattviewInfo.emit(this.battview);
				}
			}
		);
	}

	saveBattviewSettings() {

		if(this.oldBattview.actviewenabled === null && this.stage == 'queue') {

			var errorMessage = "ACT intelligent option is not enabled for the following BATTViews:<br/>";
			var notActviewenabledBattviews = [];
			this.originalBattviewsInfo.forEach((battviewTemp) => {
				if(battviewTemp.actviewenabled == false)
					notActviewenabledBattviews.push(battviewTemp.batteryid + ' (' + battviewTemp.serialnumber + ')');
			});
			
			errorMessage += notActviewenabledBattviews.join('<br/>');
			this.notificationMessage.setMessage(errorMessage, 'danger', 30000);

		} else {

			let dirtyFields = this.battviewsDashboard.battviewUpdatedFieldsEffect(this.dirtyFields, this.oldBattview);

			dirtyFields.ids					= this.battviewsIDs;
            dirtyFields.isBattviewMobile	= this.isBattviewMobile;
			dirtyFields.studyid				= this.battview.studyid || 0;
			this.battviewsDashboard.saveBattviewSettings(this.stage, dirtyFields).subscribe((response: any) => {
				if(response.httpStatus == 'error') {
					if(response.type == 'invalidFields') {

						this.highlightInvalidFields(response.data);
					} else if(response.type == 'info') {

						var errorMessage = "An error occurred while trying to apply changes for the following BATTViews:<br/>";
						var faultedBattviews = response.data.join('<br/>');
						errorMessage += faultedBattviews;
						this.notificationMessage.setMessage(errorMessage, 'danger', 30000);
					}
				} else {
					
					this.getBattviewsSettingsInfo();
				}
			});
		}
	};

	getStackHTML(field) {
		var stack = this.changesStack;

		if(!stack || !stack[field])
			return "";

		var returnHTML = `
				<p class='stackHTML'>
					<strong class='tcenter'>Changes Stack</strong>
				</p>
				<table class='stackHTML-table'>
					<tr><th>Value</th><th>User Name</th></tr>`;

		stack[field].forEach((item) => {
			returnHTML += "<tr><td style='color: white'>" + item[0] + "</td><td>" + item[1] + "</td></tr>";
		});
		
		returnHTML += "</table>";

		return returnHTML;
	}

	deselectAllDays(field) {
		if(this.battview) {
			this.battview[field] = [];
		}
	}

	setManufacturingDate() {
		if(this.battview) {
			if(this.battview.manufacturingdatesameasinstallation)
				this.battview.manufacturingdateview = new Date(this.battview.installationdate);
		}
	};

	checkChanges(formName) {

		let freshBattview = this.battview;
		let dirtyFields = {};
		let oldBattview = this.oldBattview;
		this.formName = formName;

		this.resetForms(true);

		let arrayFields		= this.battviewsDashboard.arrayFields;
		let ignoredFields	= ['lastuploadtime', 'lasteventtime'];

		for (let key in oldBattview) {
			
			if(ignoredFields.indexOf(key) != -1)
				continue;

			if(arrayFields.indexOf(key) != -1) {

				if(oldBattview[key] && freshBattview[key] && !this.commonUtil.arrayCompare(oldBattview[key], freshBattview[key])) {
					dirtyFields[key] = freshBattview[key];
				}
			} else if(oldBattview[key] != freshBattview[key]) {
			
				dirtyFields[key] = freshBattview[key];
			}
		}

		if(Object.keys(dirtyFields).length > 0) {

			for(let fieldName in dirtyFields) {

				if(this.temperatureFields.hasOwnProperty(fieldName)) {
					if(this.battview.temperatureformat) {

						for(let field in this.temperatureFields) {
							dirtyFields[field] = this.commonUtil.fahToCel(this.battview[field]);
						}
					} else {

						dirtyFields[fieldName] = this.battview[fieldName];
					}
				} else {
					if(fieldName == 'installationdate' || fieldName == 'manufacturingdateview')
						dirtyFields[fieldName] = Math.floor(new Date(new Date(-this.battview[fieldName].getTimezoneOffset() * 60000 + this.battview[fieldName].getTime())).getTime() / 1000);
					else
						dirtyFields[fieldName] = this.battview[fieldName];
				}
			}

			let invalidFields = this.battviewService.validateBattviewSettings(dirtyFields, this.isBulk, oldBattview, this.originalBattviewsInfo);
			this.dirtyFields = dirtyFields;
			
			if(invalidFields.length > 0) {
				this.highlightInvalidFields(invalidFields);
			} else {

				if(this.userService.hasAccessFunction('act_admin_device_management')
					&& (oldBattview.actviewenabled || oldBattview.actviewenabled === null)) {
					this.saveOptionsModal.show();
				} else {
					this.userSaveOption();
				}
			}
		}
	}

	updateWarrantedAhr() {
		if(this.battview && this.battview.ahrcapacity) {
			this.battview.warrantedahr = this.battview.ahrcapacity * 1250;
		}
	}

	highlightInvalidFields(invalidFields) {
		let forms = this.elementRef.nativeElement.querySelectorAll('form');
				
		forms.forEach((form) => {
			
			if(form.getAttribute('name') == this.formName) {
				for(let idx in form) {
					let ele = form[idx];
					if(Number.isInteger(Number(idx))) {
						if(invalidFields.indexOf(ele.getAttribute('name')) != -1)
							this.renderer.addClass(ele, 'invalid-input');
					}
				}
			}
		});
		this.notificationMessage.setMessage('Invalid input!');
	}

	userSaveOption = function(stage = 'queue') {

		this.stage = stage;

		if(!this.dirtyFields.hasOwnProperty('chargertype') && !this.dirtyFields.hasOwnProperty('batterytype'))
			return this.saveBattviewSettings();

		this.chargerProfileModal.show();
	}
}