<div class="row">
	<div class="col-md-12 panel panel-default">
		<form #searchByIP="ngForm" novalidate>
			<div class="padding-8">
				<strong>{{'g.ip_address' | translate}}:</strong>
			</div>
			
			<div class="padding-8">
				<input type="text" class="form-control inline-form-control" [(ngModel)]="IPaddress" name="ipAddress" pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$" placeholder='0.0.0.0' #ipAddress="ngModel" />
				<span class="red-text" *ngIf="ipAddress.invalid && ipAddress.dirty && ipAddress.touched">* {{'g.invalid_ip_address' | translate}}</span>
			</div>
			<div class="padding-8">
				<button class="btn btn-success tcenter" (click)="getDevicesByIP()" [disabled]="!IPaddress || ipAddress.invalid || searchByIP.invalid"><span class="glyphicon glyphicon-search"></span> {{'g.search' | translate}}</button>
			</div>
		</form>

		<div *ngIf="noResult" class="padding-8">
			{{'g.no_results_found' | translate}}
		</div>
		<ul class="nav nav-tabs margin-left" *ngIf="!noResult && showResults">
			<li [ngClass]="{'active': activeTab == 'battviews'}">
				<a 
				data-toggle="tab"
				(click)="activeTab = 'battviews'">BATTviews
				</a>
			</li>
			<li [ngClass]="{'active': activeTab == 'chargers'}">
				<a 
				data-toggle="tab"
				(click)="activeTab = 'chargers'">Chargers
				</a>
			</li>
		</ul>
		<div id="tab-content" *ngIf="!noResult && showResults">
			<fieldset class="col-md-12" *ngIf="!noResult && showResults && activeTab == 'chargers'">
				<legend>{{'g.chargers' | translate}}</legend>

				<div *ngIf="devicesInfo['charger']['non-deleted'].length == 0 && devicesInfo['charger']['deleted'].length == 0" class="padding-8">
					{{'device.no_chargers_found' | translate}}
				</div>
				
				<fieldset *ngIf="devicesInfo['charger']['non-deleted'].length > 0">
					<legend class="green-text">{{'g.not_deleted' | translate}}</legend>
					<table class="table table-striped padding-8">
						<thead>
							<tr>
								<th>{{'g.actview_id' | translate}}</th>
								<th>{{'device.serial_number' | translate}}</th>
								<th>{{'device.charger_id' | translate}}</th>
								<th>{{'device.purchase_order' | translate}}</th>
								<th>{{'g.model' | translate}}</th>
								<th>{{'device.is_replacement_part' | translate}}</th>
								<th>{{'site.customer_site' | translate}}</th>
								<th *ngIf="currentUser.isACTuser">{{'device.test_confirmed' | translate}}</th>
								<th>{{'g.action' | translate}}</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let deviceInfo of devicesInfo['charger']['non-deleted']">
								<td><strong>{{deviceInfo.id}}</strong></td>
								<td>{{deviceInfo.serialnumber}}</td>
								<td>
									<a *ngIf="!deviceInfo.replacmentpart && deviceInfo.customerid > 0 && deviceInfo.siteid > 0; else noLink" [routerLink]="['/', deviceInfo.customerid, deviceInfo.siteid, 'chargers', 'analytics', 'cycles', deviceInfo.id]">{{deviceInfo.prettyname}}</a>
									<ng-template #noLink>
										<span>{{deviceInfo.prettyname}}</span>
									</ng-template>
								</td>
								<td>{{deviceInfo.purchase_order}}</td>
								<td>{{deviceInfo.model}}</td>
								<td>{{deviceInfo.replacmentpart}}</td>
								<td>
									<a *ngIf="deviceInfo.customerid > 0 && !deviceInfo.ispa && !deviceInfo.replacmentpart; else noId" [routerLink]="['/main', deviceInfo.customerid]">{{deviceInfo.customername}}</a> / <a *ngIf="deviceInfo.siteid > 0 && !deviceInfo.ispa && !deviceInfo.replacmentpart; else noId" [routerLink]="['/', deviceInfo.customerid, deviceInfo.siteid]">{{deviceInfo.sitename}}</a>
								</td>
								<td *ngIf="currentUser.isACTuser">{{deviceManagemntService.isTestConfirmed('charger', deviceInfo)}}</td>
								<td>
									<div class="btn-group" appDropdown *ngIf="!deviceInfo.replacmentpart">
										<i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
										<ul class="dropdown-menu" role="menu">
											<li><a (click)="goToEditDevice(deviceInfo, 'charger')">{{'g.edit' | translate}}</a></li>
											<li 
												*ngIf="userService.hasAccessFunction('edit_purchase_order')">
												<a 
													[routerLink]="['/devices','devices-purchase-order-management','charger',deviceInfo.id]">{{'device.edit_purchase_order' | translate}}</a></li>
										</ul>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</fieldset>
				
				<fieldset *ngIf="devicesInfo['charger']['deleted'].length > 0">
					<legend class="red-text">{{'g.deleted' | translate}}</legend>
					<table class="table table-striped padding-8">
						<thead>
							<tr>
								<th>{{'g.actview_id' | translate}}</th>
								<th>{{'device.serial_number' | translate}}</th>
								<th>{{'device.charger_id' | translate}}</th>
								<th>{{'g.model' | translate}}</th>
								<th>{{'device.is_replacement_part' | translate}}</th>
								<th *ngIf="currentUser.isACTuser">{{'device.test_confirmed' | translate}}</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let deviceInfo of devicesInfo['charger']['deleted']">
								<td><strong>{{deviceInfo.id}}</strong></td>
								<td>{{deviceInfo.serialnumber}}</td>
								<td>{{deviceInfo.prettyname}}</td>
								<td>{{deviceInfo.model}}</td>
								<td>{{deviceInfo.replacmentpart}}</td>
								<td *ngIf="currentUser.isACTuser">{{deviceManagemntService.isTestConfirmed('charger', deviceInfo)}}</td>
							</tr>
						</tbody>
					</table>
				</fieldset>
			</fieldset>

			<fieldset class="col-md-12" *ngIf="!noResult && showResults && activeTab == 'battviews'">
				<legend>{{'g.battviews' | translate}}</legend>

				<div *ngIf="devicesInfo['battview']['non-deleted'].length == 0 && devicesInfo['battview']['deleted'].length == 0" class="padding-8">
					{{'device.no_battviews_found' | translate}}
				</div>

				<fieldset *ngIf="devicesInfo['battview']['non-deleted'].length > 0">
					<legend class="green-text">{{'g.not_deleted' | translate}}</legend>
					<table class="table table-striped padding-8">
						<thead>
							<tr>
								<th>{{'g.actview_id' | translate}}</th>
								<th>{{'device.serial_number' | translate}}</th>
								<th>{{'device.battery_id' | translate}}</th>
								<th>{{'device.is_battview_2_0' | translate}}</th>
								<th>{{'device.is_replacement_part' | translate}}</th>
								<th>{{'device.is_pa' | translate}}</th>
								<th>{{'g.notes' | translate}}</th>
								<th>{{'site.customer_site' | translate}}</th>
								<th>{{'g.action' | translate}}</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let deviceInfo of devicesInfo['battview']['non-deleted']">
								<td><strong>{{deviceInfo.id}}</strong></td>
								<td>{{deviceInfo.serialnumber}}</td>
								<td>
									<a *ngIf="!deviceInfo.ispa && !deviceInfo.replacmentpart && deviceInfo.customerid > 0 && deviceInfo.siteid > 0; else noBVlink" [routerLink]="['/', deviceInfo.customerid, deviceInfo.siteid, 'battviews', 'analytics', 'battery-summary', deviceInfo.id]">{{deviceInfo.prettyname}}</a>
									<ng-template #noBVlink>
										<span *ngIf="!deviceInfo.ispa || deviceInfo.replacmentpart">{{deviceInfo.prettyname}}</span>
									</ng-template>
									<a *ngIf="deviceInfo.ispa && !deviceInfo.replacmentpart" [routerLink]="['/battview-mobile','analytics','battery-summary']" [queryParams]="{battviewId: deviceInfo.id}">{{deviceInfo.prettyname}}</a>
								</td>
								<td>{{deviceInfo.model.length == 2 ? 'true' : 'false'}}</td>
								<td>{{deviceInfo.replacmentpart}}</td>
								<td>{{deviceInfo.ispa}}</td>
								<td><span *ngIf="deviceInfo.isProductionLine">* {{'act.act_production_line' | translate}}</span></td>
								<td>
									<a *ngIf="deviceInfo.customerid > 0 && !deviceInfo.ispa && !deviceInfo.replacmentpart; else noId" [routerLink]="['/main', deviceInfo.customerid]">{{deviceInfo.customername}}</a> / <a *ngIf="deviceInfo.siteid > 0 && !deviceInfo.ispa && !deviceInfo.replacmentpart; else noId" [routerLink]="['/', deviceInfo.customerid, deviceInfo.siteid]">{{deviceInfo.sitename}}</a>
								</td>
								<td>
									<div class="btn-group" appDropdown *ngIf="!deviceInfo.replacmentpart && !deviceInfo.isdeleted">
										<i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
										<ul class="dropdown-menu" role="menu">
											<li *ngIf="!deviceInfo.isProductionLine || userService.hasAccessFunction('act_admin_device_management') || userService.hasAccessFunction('production_line_management')"><a (click)="goToEditDevice(deviceInfo, 'battview')">{{'g.edit' | translate}}</a></li>
										</ul>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</fieldset>

				<fieldset *ngIf="devicesInfo['battview']['deleted'].length > 0">
					<legend class="red-text">{{'g.deleted' | translate}}</legend>
					<table class="table table-striped padding-8">
						<thead>
							<tr>
								<th>{{'g.actview_id' | translate}}</th>
								<th>{{'device.serial_number' | translate}}</th>
								<th>{{'device.battery_id' | translate}}</th>
								<th>{{'device.is_battview_2_0' | translate}}</th>
								<th>{{'device.is_replacement_part' | translate}}</th>
								<th>{{'device.is_pa' | translate}}</th>
								<th>{{'g.notes' | translate}}</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let deviceInfo of devicesInfo['battview']['deleted']">
								<td><strong>{{deviceInfo.id}}</strong></td>
								<td>{{deviceInfo.serialnumber}}</td>
								<td>{{deviceInfo.prettyname}}</td>
								<td>{{deviceInfo.model.length == 2 ? 'true' : 'false'}}</td>
								<td>{{deviceInfo.replacmentpart}}</td>
								<td>{{deviceInfo.ispa}}</td>
								<td><span *ngIf="deviceInfo.isProductionLine">* {{'act.act_production_line' | translate}}</span></td>
							</tr>
						</tbody>
					</table>
				</fieldset>
			</fieldset>
		</div>
	</div>
</div>
<ng-template #noId>
	-
</ng-template>