<form #updateUserSettingsForm="ngForm" class="form-auth" (ngSubmit)="updateUserSettings()">
  <div class="container-fluid" id="main">
    <div class="row">
      <div class="col-md-6 col-md-offset-3" id="left">
        <div class="boxs3">
          <h2>{{'user.update_user_settings' | translate}}</h2>
          <div class="form-field">
            <label for="firstName">{{'user.first_name' | translate}}</label>
            <input type="text" class="form-control" [(ngModel)]="user.firstname" name="firstname" id="firstName" placeholder="{{'user.first_name' | translate}}" />
          </div>
          <br/>
          <div class="form-field">
            <label for="lastName">{{'user.last_name' | translate}}</label>
            <input type="text" class="form-control" [(ngModel)]="user.lastname" name="lastname" id="lastName" placeholder="{{'user.last_name' | translate}}" />
          </div>
          <br/>
          <div class="form-field" *ngIf="user.id == 10 || user.id == 18">
            <label for="lastName">{{'user.language' | translate}}</label>
            <select class="form-control input-sm" [(ngModel)]="user.lang" name="lang">
              <option value="en">English</option>
              <option value="fr">Français</option>
              <option value="es">Español</option>
            </select>
          </div>
          <br/>
          <div class="form-field">
            <label>{{'user.unsubscribe_from_weekend_email_alerts' | translate}}</label>
            <br/>
            <div *ngFor="let day of daysList" class="margin-left-lg">
              <label>
                <input type='checkbox' class="form-control" [(ngModel)]="unsubscribeDays[day.id]" name="unsubscribeDays_{{day.id}}" /> {{day.text}}
              </label>
            </div>
          </div>
          <br/>
          <button type="submit" class="btn btn-primary">{{'g.submit' | translate}}</button>
          <button type="button" class="btn btn-default" (click)="navigation.goBack(['sites-map'])">{{'g.cancel' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</form>