import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SitesEstimatedCounts } from './sites-estimated-counts.model';
import { CommonUtil } from '../../../shared/services/utility/common.service';

@Component({
	selector: 'app-sites-estimated-counts',
	templateUrl: './sites-estimated-counts.component.html',
	styleUrls: ['./sites-estimated-counts.component.css']
})
export class SitesEstimatedCountsComponent implements OnInit {

	sitesEstimatedCounts= {} as SitesEstimatedCounts;
	whrsUnit: string;
	whrsUsed: number;
	ahrsUnit: string;
	ahrsUsed: number;
	chargeCyclesDelivered: number;
	chargeCyclesUnit: string;


	constructor(
		private httpClient: HttpClient,
		private commonUtil: CommonUtil
	) {}

	ngOnInit() {
		this.httpClient.get<SitesEstimatedCounts>('/customers/getSitesEstimatedCounts').subscribe(data => {
			this.sitesEstimatedCounts = data;

			// whrs Used
			let whrsUsed = Math.round(this.sitesEstimatedCounts.kwhrs_used);
			if (whrsUsed < 1000) {
				this.whrsUnit = "kWhrs";
			} else {
				// convert to Mega
				this.whrsUnit = "MWhrs";
				whrsUsed /= 1000;
			}
			this.whrsUsed = whrsUsed;

			// Ahrs Used
			let ahrsUsed = Math.round(this.sitesEstimatedCounts.ahrs_used);
			if (ahrsUsed < 1000000) {
				this.ahrsUnit = "Ahrs";
			} else {
				ahrsUsed /= 1000000;
				this.ahrsUnit = "Million Ahrs";
			}
			this.ahrsUsed = ahrsUsed;
			const chargerCycles  = this.commonUtil.calculateValuesWithUnit(this.sitesEstimatedCounts.charge_cycles_delivered);
			this.chargeCyclesDelivered = chargerCycles.value;
			this.chargeCyclesUnit = chargerCycles.unit;
		});
	}

	addNumberCommas(number) {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
}
