import { Component, OnInit, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { ChartDataService } from '../services/chart-data.service';
import { CommonUtil } from '../services/utility/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-site-widget',
	templateUrl: './site-widget.component.html',
	styleUrls: ['./site-widget.component.css']
})
export class SiteWidgetComponent implements OnInit, OnChanges {
	@Input() chartType: string = 'pie';
	@Input() title: any;
	@Input() widgetOptions: any;
	@Input() widgetClass: any;
	@Input() data: any;
	@Input() config: any;
	@Input() elementId: any;
	@Input() tableColumns: any[] = [];
	@Input() siteId: number = 0;			// @todo: use site name instead
	@Input() customerId: number = 0;		// @todo: use customer name instead
	@Input() siteWidgetOptions: any = {};
	@Input() isACTuser: boolean = false;
	@Input() customerName: string = '';
	@Input() siteName: string = '';
	@Input() isSiteWidget: boolean = true;
	
	hideWidget: boolean = false;
	chartData: any;
	tableItems: any[] = [];
	selectedIndex: number = 0;
	subSelectedIndex: number = 0;
	printItems: any[] = [];
	dropDownGroupList: any[] = []; // [ {name: name, label: label, options: [{value: value, label: label}], selectedValue : value}]
	checkboxList: any[] = []; // [ {name: name, label: label, selectedValue : value}]
	operationListOptions: any[] = [{value:'', label:'All Types'}, {value:'C', label:'Conventional'}, {value:'O', label: 'Opportunity'}, {value:'F', label:'Fast'}];
	truckviewListOptions: any[] = [{value:'', label:'All Types'}, {value:'Truck1', label:'Truck1'}, {value:'Truck2', label: 'Truck2'}];
	chargerModels: any[] = [{value:'', label:'All charger models'}, {value:'Q4', label:'4X'}, {value:'Q6', label: '6X'}, {value:'Q12', label:'12X'}];
	eqTypeListOptions: any[] = [{value:'', label:'All'}, {value:'C', label:'EQ Complete'}, {value:'S', label: 'EQ Start'}];
	printOption: string;
	selectedDropDown: any;
	selectedCheckbox: any;
	barLabels: any;
	widgetSummary: any;
	selectedTags: any = [];
	generatedListData: any;

	@ViewChild("printAlertModal") printAlertModal;
	@ViewChild("customizedWidget") customizedWidget;

	@Output() eventEmit = new EventEmitter<{type: string, data: any}>(true);

	constructor(
		private chartDataService: ChartDataService,
		private commonUtil: CommonUtil,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.prepareOptions();
		this.prepareData();
	}

	ngOnChanges(changes) {
		if(changes.data && changes.data.currentValue) {
			this.prepareOptions();
		}
		this.prepareData();
	}

	ngAfterViewInit() {
		this.printAlertModal.onClose.subscribe(
			(ok) => {
				if(ok) {
					let printTableContent = this.getPrintItems();
					this.commonUtil.print({
						customerName: this.customerName,
						siteName: this.siteName,
						dropDownGroupList: this.dropDownGroupList,
						selectedDropDown: this.selectedDropDown,
						checkboxList: this.checkboxList,
						selectedCheckbox: this.selectedCheckbox,
						widgetId: this.elementId,
						appendPrintContent: printTableContent,
						// selectedTags: [{tagname: 'first tag'}, {tagname: '2nd tag'}]
					});
				}
			}
		);
	}
	
	prepareOptions() {
		this.dropDownGroupList = [];
		this.checkboxList = [];

		let dropDownGroupList = [];

		if(this.siteWidgetOptions.hasDate) {
			let dateSelectOptions = [];

			if (!this.siteWidgetOptions.hideYesterdayDate) {
				dateSelectOptions.push({
					value: "Y",
					label: "Yesterday"
				});
			}

			dateSelectOptions = dateSelectOptions.concat([
				{
					value: "W",
					label: "Last Week"
				}, {
					value: 7,
					label: "Last 7 Days"
				}, {
					value: 14,
					label: "Last 14 Days"
				}, {
					value: 30,
					label: "Last 30 Days"
				}, {
					value: 90,
					label: "Last 90 Days"
				}
			]);
			
			if (this.siteWidgetOptions.isReport) {
				dateSelectOptions.push({
					value: 365,
					label: "Last 365 Days"
				});
				dateSelectOptions.push({
					value: "installationDate",
					label: "Since Installation"
				});
			} else {
				if(this.data && this.data.siteSettings.siteSettings.reports_since_installation && !this.siteWidgetOptions.excludeSinceInstallationOption) {
					dateSelectOptions.push({
						value: 'siteInstallation',
						label: "Since Installation"
					});
				}
			}

			dropDownGroupList.push({
				name: 'date',
				label: '',
				options: dateSelectOptions,
				selectedValue: 'W'
			});
		}

		if(this.siteWidgetOptions.hasDateRange) {
			this.siteWidgetOptions.dateRangeStart	= 1;
			this.siteWidgetOptions.dateRangeEnd		= 1;
			this.buildDateRangeLists('start');
			this.buildDateRangeLists('end');
		}

		if(this.siteWidgetOptions.hasOperation) {
			let opList = this.operationListOptions;
			if(this.siteWidgetOptions.isChargers) {
				opList[0].label = 'All charge profiles';
			}
			if(this.siteWidgetOptions.isTrucks)
				opList = this.truckviewListOptions;

			if(this.siteWidgetOptions.hasLithium && !this.checkItemExistsByValue(opList, 'L'))
				opList.push({value:'L', label:'Li-Ion'});

			dropDownGroupList.push({
				name: 'operation',
				label: '',
				options: opList,
				selectedValue: ''
			});
		}
		if(this.siteWidgetOptions.hasEqTypeFilter) {
			dropDownGroupList.push({
				name: 'eqType',
				label: '',
				options: this.eqTypeListOptions,
				selectedValue: ''
			});
		}
		if(this.siteWidgetOptions.hasWorkingDaysCheckBox) {
			this.checkboxList.push({
				name: 'workingDaysOnly',
				label: 'Working Days Only',
				selectedValue: 0
			});
		}
		if(this.siteWidgetOptions.hasAverageTimesPerBattview) {
			this.checkboxList.push({
				name: 'averageTimePerBattview',
				label: 'Average Time Per Battview',
				selectedValue: 0
			});
		}
		if(this.siteWidgetOptions.hasSiteEbuCheckBox) {
			this.siteWidgetOptions.max_ebu = true;
			this.siteWidgetOptions.avg_ebu = true;
			this.checkboxList.push(
				{name: 'showReference', label: 'Show Reference', selectedValue: 0},
				{name: 'max_ebu', label: 'Show Max EBU', selectedValue: this.siteWidgetOptions.max_ebu},
				{name: 'avg_ebu', label: 'Show Avg EBU', selectedValue: this.siteWidgetOptions.avg_ebu}
			);
		}
		if(this.siteWidgetOptions.hasNormalizeCheckBox) {
			this.checkboxList.push(
				{name: 'normalized', label: 'Normalized', selectedValue: 0},
			);
		}
		if(this.siteWidgetOptions.hasChargerModels) {
			dropDownGroupList.push({
				name: 'model',
				label: '',
				options: this.chargerModels,
				selectedValue: ''
			});
		}

		this.dropDownGroupList = dropDownGroupList;
	}

	buildDateRangeLists(input, min = 1, max = 12) {
		let list	= [];
		for(let i = min; i <= max; i++) {
			if(i == 1) {
				list.push({
					value: 1,
					label: this.translateService.instant("time.last_week")
				});
			} else {
				list.push({
					value: i,
					label: this.translateService.instant("g.last")+" "+i+" "+this.translateService.instant("g.week")
				});
			}
		}
		
		if(input == 'start') {
			this.siteWidgetOptions.dateRangeStartOptions = list;
		} else if(input == 'end') {
			this.siteWidgetOptions.dateRangeEndOptions = list;
		}
	}
	prepareData() {
		let generatedListData:any;
		if(this.data) {
			let options: any = {
				inactiveWidgets: this.data.inactiveWidgets,
				dateRange: this.siteWidgetOptions.selectedDate,
				operationType: this.siteWidgetOptions.selectedOperation,
				eqType: this.siteWidgetOptions.selectedEqType,
				workingDaysOnly: this.siteWidgetOptions.workingDaysOnly,
				dataTypeModel: this.siteWidgetOptions.dataTypeModel,
				isACTuser: this.isACTuser,
				averageTime: this.siteWidgetOptions.averageTimePerBattview,
				isChargers: this.siteWidgetOptions.isChargers,
				chargersData: this.data.chargersData,
				model: this.siteWidgetOptions.model,
				normalizeKW: this.siteWidgetOptions.normalizeKW,
				dateRangeStart: this.siteWidgetOptions.dateRangeStart,
				dateRangeEnd: this.siteWidgetOptions.dateRangeEnd,
				tags: this.selectedTags,
				siteTags: this.data.siteBattviewsTags || this.data.siteChargersTags || [],
			};

			if(this.siteWidgetOptions.hasSiteEbuCheckBox) {
				options.showReference = this.siteWidgetOptions.showReference;
				options.max_ebu = this.siteWidgetOptions.max_ebu;
				options.avg_ebu = this.siteWidgetOptions.avg_ebu;
			}

			let charge_events	= !this.data.siteSettings.userSettings || this.data.siteSettings.userSettings.bv_charge_events_only_for_charge_ahr;
			let inuse_events	= !this.data.siteSettings.userSettings || this.data.siteSettings.userSettings.bv_inuse_events_only_for_charge_ahr;

			options.charge_events = charge_events;
			options.inuse_events = inuse_events;

			if(this.isSiteWidget) {
				if(this.siteWidgetOptions.isReport) {
					if(
						(!this.siteWidgetOptions.isTrucks && (!this.data.battviewsData || Object.keys(this.data.battviewsData).length <= 0)) ||
						(this.siteWidgetOptions.isTrucks && (!this.data.truckviewsData && Object.keys(this.data.truckviewsData).length <= 0))
					)
						return;
	
					let data = this.chartDataService.generateSiteReportData(this.data, this.elementId, options);
					if(!data) {
						this.hideWidget = true;
						return;
					}
					if(Array.isArray(data))
						this.chartData = data;
					else
						this.chartData = data.list;
	
					if(this.siteWidgetOptions.updateChartMaxValue && this.config && this.config.vAxis) {
						this.config.vAxis['maxValue'] = data.maxValueOnChart;
					}
	
					if(this.siteWidgetOptions.updateChartSeries && this.config) {
						this.config.series = data.series;
					}
					generatedListData = data.data;
				} else {
					if(this.siteWidgetOptions.isSitePerformanceWidget) {
						if(this.siteWidgetOptions.isChargers)
							if(!this.data.chargersData || Object.keys(this.data.chargersData).length <= 0)
								return;
						else if(this.siteWidgetOptions.isTrucks)
							if(!this.data.truckviewsData || Object.keys(this.data.truckviewsData).length <= 0)
								return;
						else if(!this.data.battviewsData || Object.keys(this.data.battviewsData).length <= 0)
							return;

						this.chartData = this.chartDataService.generateSitePerformanceWidgetData(this.data, this.elementId, options);
						if(!this.chartData) {
							this.hideWidget = true;
							return;
						}
						options['isList'] = true;
						generatedListData = this.chartDataService.generateSitePerformanceWidgetData(this.data, this.elementId, options);
					} else {
						this.chartData = this.chartDataService.generateWidgetData(this.data.data, this.elementId, options);
						if(!this.chartData) {
							this.hideWidget = true;
							return;
						}
						generatedListData = this.chartDataService.generateWidgetData(this.data.data, this.elementId+'_list', options);
					}
				}
			} else {
				this.chartData = this.data;
			}

			let summary = {};
			if (this.config && this.config.formatFunction) {
				if(this.chartData.summary) {
					summary = this.chartData.summary;
				}
				this.chartData = this.config.formatFunction(this.chartData);
			}
			if (this.config && this.config.updateConfig) {
				this.config = this.config.updateConfig(this.config, {normalizeKW: this.siteWidgetOptions.normalizeKW});
			}
			if(this.config && this.config.setLocalStorage) {
				this.config.setLocalStorage(this.siteWidgetOptions.dataTypeModel);
			}
			this.updateGeneratedListData(generatedListData);
			this.updateTableData();

			if(this.siteWidgetOptions.showBarLabels) {
				this.barLabels = {label: "", value:""};
				if(this.chartData[this.selectedIndex + 1]) {
					this.barLabels = {label: this.chartData[this.selectedIndex + 1][0].v, value: this.chartData[this.selectedIndex + 1][1].v};
				}
			}

			if(this.siteWidgetOptions.showWidgetSummary) {
				this.widgetSummary = summary;
			}
		}
	}
	updateGeneratedListData(data) {
		this.generatedListData = data;
		this.eventEmit.emit({type: 'filteredData', data});
	}
	updateTableData() {
		let selectedIndex = this.selectedIndex;
		if(this.siteWidgetOptions.dataTypeModel) {
			this.siteWidgetOptions.dataTypeModel = this.siteWidgetOptions.dataTypeModel.sort((a, b) => a - b);
			selectedIndex = this.siteWidgetOptions.dataTypeModel[selectedIndex];
		}
		if (this.config && this.config.updateColumns) {
			this.tableColumns = this.config.updateColumns(selectedIndex, this.siteWidgetOptions);
		}

		this.tableItems = [];
		if(this.generatedListData && this.generatedListData[selectedIndex]) {
			if(this.siteWidgetOptions.isMaterialChart) {
				if(this.generatedListData[selectedIndex][this.subSelectedIndex])
					this.tableItems = this.generatedListData[selectedIndex][this.subSelectedIndex].list || [];
			} else {
				this.tableItems = this.generatedListData[selectedIndex].list || this.generatedListData[selectedIndex] || [];
			}
		}
	}
	onSelectItem(item) {
		if(item && (this.selectedIndex != item.row || (this.siteWidgetOptions.isMaterialChart && this.subSelectedIndex != item.column))) {
			this.selectedIndex = item.row;
			if(this.siteWidgetOptions.isMaterialChart) {
				switch(item.column) {
					case 1:
						this.subSelectedIndex = 0;
					break;
					case 4:
						this.subSelectedIndex = 1;
					break;
					case 7:
						this.subSelectedIndex = 2;
					break;
					case 10:
						this.subSelectedIndex = 3;
					break;
				}
			}
			this.updateTableData();
		}
	}
	
	updateSelectedTags(event) {
		this.selectedTags = event.selectedTags;
		this.prepareData();
	}
	
	onPrintClick(data) {
		let printTableContent:any = '';
		this.selectedDropDown	= data.selectedDropDown;
		this.selectedCheckbox	= data.selectedCheckbox;

		if(this.siteWidgetOptions.isAlertWidget) {
			this.printOption = '';
			return this.printAlertModal.show();
		}

		if (this.config && this.config.getPrintItems) {
			printTableContent = this.config.getPrintItems();
		} else {
			printTableContent = this.getPrintItems();
		}
		this.commonUtil.print({
			customerName: this.customerName,
			siteName: this.siteName,
			dropDownGroupList: this.dropDownGroupList,
			selectedDropDown: this.selectedDropDown,
			checkboxList: this.checkboxList,
			// selectedCheckbox: this.selectedCheckbox,
			widgetId: this.elementId,
			appendPrintContent: printTableContent
		});
	}

	exportClick(data) {
		this.eventEmit.emit({type: 'export', data});
	}
	
	getPrintItems() {
		let options: any = {
			siteAlertsSettings: this.data.siteSettings.siteSettings,
			userAlertsSettings: this.data.siteSettings.userSettings,
			isSitePerformanceWidget: this.siteWidgetOptions.isSitePerformanceWidget,
			datePeriod: this.siteWidgetOptions.selectedDate,
			operationType: this.siteWidgetOptions.selectedOperation,
			eqType: this.siteWidgetOptions.selectedEqType,
			siteBattviewsData: this.data.battviewsData,
			isACTuser: this.isACTuser,
			dataTypeModel: this.siteWidgetOptions.dataTypeModel,
			printOption: this.printOption,
			isChargers: this.siteWidgetOptions.isChargers,
			siteChargersData: this.data.chargersData,
			modelType: this.siteWidgetOptions.model,
			normalizeKW: this.siteWidgetOptions.normalizeKW,
			dateRangeStart: this.siteWidgetOptions.dateRangeStart,
			dateRangeEnd: this.siteWidgetOptions.dateRangeEnd,
		};

		let charge_events	= !this.data.siteSettings.userSettings || this.data.siteSettings.userSettings.bv_charge_events_only_for_charge_ahr;
		let inuse_events	= !this.data.siteSettings.userSettings || this.data.siteSettings.userSettings.bv_inuse_events_only_for_charge_ahr;

		options.charge_events = charge_events;
		options.inuse_events = inuse_events;

		let printList = this.renderPrintList(options);

		var printContent = '<div class="showOnPrint">';
		for (var index in printList) {
			let printItem = printList[index];
			printContent += '<div class="page-break-inside">';
			printContent += '<span style="font-weight:bold;">'+printItem.title+'</span>';
			printContent += '<table class="table table-striped table-condensed table-bordered"><thead>';
			var columns = printItem.columns;

			if(!columns)
				columns = this.tableColumns;

			for (var columnIndex in columns) {
				printContent += '<th>'+columns[columnIndex].title+'</th>';
			}
			printContent += '</thead><tbody>';
			for (var itemIndex in printItem.list) {
				var item = printItem.list[itemIndex];
				printContent += '<tr>';
				for (columnIndex in columns) {
					printContent += '<td>';
					printContent += '<span>'+item[columns[columnIndex].property]+'</span>';
					printContent += '</td>';
				}
				printContent += '</tr>';
			}
			printContent += '</tbody></table></div>';
		}
		printContent += '</div>';
		return printContent;
	}
	renderPrintList(options) {
		if (options.noPrintData) {
			return [];
		}
		let printList;
		if(options.isChargers) {
			printList = this.chartDataService.generateChargerWidgetPrintData(this.data, this.elementId, options, this.data.inactiveWidgets);
		} else {
			let elementId = this.elementId;
			let data = this.data;
			if(!this.siteWidgetOptions.isSitePerformanceWidget) {
				elementId += '_list';
				data = data.data;
			}
			printList = this.chartDataService.generateWidgetPrintData(data, elementId, options, this.data.inactiveWidgets);
		}
		if(printList == false) return [];
		return printList;
	}

	onSelectionChanged(event) {
		switch(event[0]) {
			case 'date':
				this.siteWidgetOptions.selectedDate = event[1];
			break;
			case 'operation':
				this.siteWidgetOptions.selectedOperation = event[1];
			break;
			case 'eqType':
				this.siteWidgetOptions.selectedEqType = event[1];
			break;
			case 'workingDaysOnly':
				this.siteWidgetOptions.workingDaysOnly = event[1];
			break;
			case 'averageTimePerBattview':
				this.siteWidgetOptions.averageTimePerBattview = event[1];
			break;
			case 'showReference':
				this.siteWidgetOptions.showReference = event[1];
			break;
			case 'max_ebu':
				this.siteWidgetOptions.max_ebu = event[1];
			break;
			case 'avg_ebu':
				this.siteWidgetOptions.avg_ebu = event[1];
			break;
			case 'model':
				this.siteWidgetOptions.model = event[1];
			break;
			case 'normalized':
				this.siteWidgetOptions.normalizeKW = event[1];
			break;
			case 'dateRangeStart':
				this.siteWidgetOptions.dateRangeStart = event[1];
				this.buildDateRangeLists('end', event[1]);
				if(event[1] > this.siteWidgetOptions.dateRangeEnd)
					this.siteWidgetOptions.dateRangeEnd = event[1];
			break;
			case 'dateRangeEnd':
				this.siteWidgetOptions.dateRangeEnd = event[1];
				this.buildDateRangeLists('start', 1, event[1]);
			break;
		}
		this.prepareData();
	}

	private checkItemExistsByValue(items, value) {
		let exists = false;
		for(let item of items) {
			if(item.value != value)
				continue;
			exists = true;
			break;
		}
		return exists;
	}
}