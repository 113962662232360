import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'underscore';
import { DeviceManagementService } from '../../device-management.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { AdminService } from '../../../admin/admin.service';
import { UserService } from '../../../auth/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-pm-by-sn',
	templateUrl: './pm-by-sn.component.html',
	styleUrls: ['./pm-by-sn.component.css']
})
export class PmBySnComponent implements OnInit, OnDestroy {

	devicesInfo  = [];
	isPartial: boolean = false;
	serialnumber: string = '';
	noResult: boolean = null;

	constructor(
		private deviceManagemntService: DeviceManagementService,
		private breadcrumb: BreadcrumbService,
		private commonUtil: CommonUtil,
		private adminService: AdminService,
		public userService: UserService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([
			{'label': this.translateService.instant('device.search_pms_by_sn'), 'url': ''}
		]);
	}

	getPmBySN() {
		this.devicesInfo = [];
		this.noResult = false;

		this.serialnumber = this.serialnumber.trim();

		this.deviceManagemntService.getPmBySN(this.serialnumber, {'isPartial': this.isPartial}).subscribe(
			(devicesInfo: any) => {
				if(devicesInfo.length === 0) {
					this.noResult = true;
				} else {
					this.noResult = false;

					this.devicesInfo = devicesInfo;
				}
			}
		);
	};

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}