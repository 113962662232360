<app-modal #modal [title]="deleteTitle">{{deleteMsg}}</app-modal> 
<div>
	<div>
		<button class="btn btn-primary mb20" type="button" [routerLink]="['/sites', 'edit', 'customerId', siteObj.customerid, 'siteId', 0]">Add New <span class="glyphicon glyphicon-plus"></span>
		</button>
	</div>
	<div class="boxs">
		<table class="table table-stripped">
			<thead>
				<tr>
					<th>Name</th>
					<th>Country</th>
					<th>State</th>
					<th>Address</th>
					<th>Modification Date</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let site of sites">
					<td>{{site.name}}</td>
					<td>{{site.country}}</td>
					<td>{{site.state}}</td>
					<td>{{site.address}}</td>
					<td>{{site.modificationdate | date:'MM/dd/yyyy'}}</td>
					<td>
						<div class="btn-group" appDropdown>
							<i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
							<ul class="dropdown-menu" role="menu">
								<li><a [routerLink]="['/sites', 'edit', 'customerId', siteObj.customerid, 'siteId', site.id]">Edit</a></li>
								<li *ngIf="site.act_intelligent_type == 0 && currentUser.isACTuser && site.id != productionLineSiteID && site.id != battviewMobileSiteID"><a href="javascript:;" (click)="showSiteDeleteModal(siteObj.customerid, site.id)">Delete</a></li>
								<li><a [routerLink]="['/chargers', 'customerId', siteObj.customerid, 'siteId', site.id]">Chargers</a></li>
								<li><a [routerLink]="['/battviews', 'customerId', siteObj.customerid, 'siteId', site.id]">BattViews</a></li>
								<li *ngIf="canManageACTintelligent"><a [routerLink]="['/sites', 'manage-act-intelligent', siteObj.customerid, site.id]">Manage ACT Intelligent</a></li>
								<li *ngIf="currentUser.isACTuser"><a [routerLink]="['/equipments-devices', 'site', site.id]">Equipments</a></li>
								<li *ngIf="userService.hasAccessFunction('enable_full_access_mode')"><a (click)="autoCorrectDevices(site.id, 'site_chargers')">{{'g.chargers' | translate}} {{'device.auto_correct' | translate}}</a></li>
								<li *ngIf="userService.hasAccessFunction('enable_full_access_mode')"><a (click)="autoCorrectDevices(site.id, 'site_battviews')">{{'g.battviews' | translate}} {{'device.auto_correct' | translate}}</a></li>
							</ul>
						</div>
					</td>
				</tr>	
				<tr >
					<td>Unassigned Customer Site</td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td>
						<div class="btn-group" appDropdown>
							<i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
							<ul class="dropdown-menu" role="menu">
								<li><a [routerLink]="['/chargers', 'customerId', siteObj.customerid, 'siteId', 0]">Chargers</a></li>
								<li><a [routerLink]="['/battviews', 'customerId', siteObj.customerid, 'siteId', 0]">BattViews</a></li>
							</ul>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>