<ul class="dropdown-menu" role="menu">
	<li *ngIf="!userService.hasAccessFunction('no_manage_devices') && !currentUser.isTruckDealer">
		<a [routerLink]="['/devices', 'devices-management', 'battview', battview.id]">{{manageDeviceBtnText[0] + ' ' + manageDeviceBtnText[1]}}</a>
	</li>
	<li *ngIf="userService.hasAccessFunction('restart_devices')">
		<a (click)="restartBattview(battview.id)" class="has-action">Restart BATTView</a>
	</li>
	<li *ngIf="userService.hasAccessFunction('act_admin_device_management') && battview.enableplc">
		<a (click)="addCommandToBattviewQueue(battview.id, 'loadPLCfirmware')" class="has-action">Load PLC Firmware</a>
	</li>
	<li *ngIf="!battview.isCalibrated && battview.actviewenabled && battview.enableplc && battview.firmwareversion >= 2.12 && !battview.enablehalleffectsensing && userService.hasAccessFunction('remote_battview_calibration')">
		<a (click)="addCommandToBattviewQueue(battview.id, 'remoteCalibration')" class="has-action">Calibrate BATTView with Quantum</a>
	</li>
	<li *ngIf="currentUser.isACTuser && battview.time_gap > 0">
		<a (click)="addCommandToBattviewQueue(battview.id, 'setRealTimeClock')" class="has-action">Synch Time</a>
	</li>
	<li *ngIf="userService.hasAccessFunction('act_admin_device_management') && battview.firmwareversion >= 2.2">
		<a (click)="refreshDeviceWiFiInfo(battview.id)" class="has-action">Refresh WiFi Info</a>
	</li>
	<li *ngIf="battview.enablehalleffectsensing && battview.actviewenabled && userService.hasAccessFunction('remote_battview_calibration') && battview.hwversion >= 'AA'">
		<a (click)="addCommandToBattviewQueue(battview.id, 'loadDefaultCalibration')" class="has-action">Load Default Calibration Values</a>
	</li>
	<li *ngIf="userService.hasAccessFunction('reset_battview_history') && !battview.ispa">
		<a (click)="resetConfirmModal.show();" class="has-action">Reset ACT-View.com history</a>
	</li>
	<li *ngIf="pageName != 'listing'">
		<a>
			<notes
				[siteId]="siteID"
				modelType="battview"
				[modelId]="battview.id"
				[isDropDown]="true"
				[isAddNoteOnly]="true"
			></notes>
		</a>
	</li>
</ul>

<app-modal #resetConfirmModal title="Reset BATTView history" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="margin-bottom-sm">This will reset data history permanently, click OK to proceed</div>
	<div class="modal-footer padding-8">
		<button class="btn btn-primary" (click)="resetBattviewHistory(battview.id);resetConfirmModal.hide()">OK</button>
		<button class="btn btn-default" (click)="resetConfirmModal.hide()">Cancel</button>
	</div>
</app-modal>