import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { AdminService } from '../admin.service';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';

@Component({
	selector: 'app-widgets-management',
	templateUrl: './widgets-management.component.html',
	styleUrls: ['./widgets-management.component.css']
})
export class WidgetsManagementComponent implements OnInit, OnDestroy {

	widgetsData = <any>[];

	constructor(
		private adminService: AdminService,
		private notificationMessage: NotificationMessageService,
		private router: Router,
		private breadcrumb: BreadcrumbService
	) {
	}

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([{'label': 'Widget Management', 'url': ''}]);
		this.adminService.getWidgets().subscribe(
			data => {
				this.widgetsData = data;
			}
		);
	}

	updateWidgets() {
		this.adminService.updateWidgets(this.widgetsData).subscribe(
			data => {
				this.router.navigate(['/sites-map']);
			}
		);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}