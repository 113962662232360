<div ngModelGroup="site" class="col-sm-12">
	<div class="form-field margin-bottom-sm">
		<label for="name">Name</label>
		<input required pattern="[^\s].*" type="text" class="form-control" [(ngModel)]="site.name" placeholder="Name" name="name"/>
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="mode == 'admin'">
		<label for="country">Country</label>
		<input required type="text" class="form-control" [readonly]="true" [(ngModel)]="site.country" placeholder="Country" name="country"/>
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="mode == 'admin'">
		<label for="state">State</label>
		<input required type="text" class="form-control" [readonly]="true" [(ngModel)]="site.state" placeholder="State" name="state" />
	</div>

	<div class="form-field margin-bottom-sm">
		<label for="latitude">Address</label>
		<input required type="text" name="address" class="form-control" [readonly]="true" [(ngModel)]="site.address" placeholder="Address" />
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="mode == 'admin'">
		<label for="zoneId">Time Zone</label>
		<ng-select [items]="TimeZonesMenu" bindLabel="display_name" bindValue="id" placeholder="Select Time Zone" name="zoneid" [(ngModel)]="site.zoneid" [clearable]="false" required>
		</ng-select>
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="mode == 'admin'">
		<label for="installationDate">Installation Date</label>
		<div class="form-inline">
			<div class="form-group">
				<div class="input-group">
					<input class="form-control" placeholder="mm/dd/yyyy" name="installation_date" [minDate]="minInstallationDate" [(ngModel)]="site.installation_date" bsDatepicker #d="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" required>
					<span class="input-group-btn">
						<button class="btn btn-default btn-date" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
					</span>
				</div>
			</div>
		</div>
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="site.act_intelligent_type != 0 && mode == 'admin'">
		<label for="dr_provider">Dynamic Response:</label><br />
		<label *ngIf="hasENERNOCProvider"><input type="radio" [(ngModel)]="site.dr_provider" name="dr_provider" class="form-control" value="ENERNOC"/> ENERNOC</label>
		<label><input type="radio" [(ngModel)]="site.dr_provider" name="dr_provider" class="form-control" value="VOLTUS"/> Voltus</label>
		<label><input type="radio" [(ngModel)]="site.dr_provider" name="dr_provider" class="form-control" value="None"/> None</label>
	</div>
	<div class="form-field margin-bottom-sm" *ngIf="mode == 'admin' && site.dr_provider == 'ENERNOC' && hasENERNOCProvider">
		<label for='dr_phone_number'>SMS Phone No.</label>
		<input type='text' class='form-control' name="dr_phone_number" [(ngModel)]='site.dr_phone_number' placeholder='Phone No.' pattern="\+?[0-9]+" required />
	</div>
	<div class="form-field margin-bottom-sm" *ngIf="mode == 'admin' && site.dr_provider == 'ENERNOC' && hasENERNOCProvider">
		<label for='dr_phones_whitelist'>recieve SMS Phones Whitelist</label>
		<div *ngFor="let number of site.dr_phones_whitelist;let i = index;">
			<input type='text' class='form-control' name="dr_phones_whitelist-{{i}}" [(ngModel)]="site['dr_phones_whitelist'][i].value" placeholder="{{'Phone No. '+(i+1)}}" pattern="\+?[0-9]+"/>
		</div>
		<div>
			<button type="button" class="btn btn-default" (click)="addNewDRPhoneWhiteList()">+</button>
		</div>
	</div>
	<div *ngIf="mode == 'admin' && site.dr_provider == 'VOLTUS'">
		<div class="form-field margin-bottom-sm">
			<label for="dr_market">Market <span class="text-danger">*</span></label>
			<input type="text" class="form-control" name="dr_market" [(ngModel)]="site.dr_market" placeholder="Market" required />
		</div>
		<div class="form-field margin-bottom-sm">
			<label for="dr_name">Name <span class="text-danger">*</span></label>
			<input type="text" class="form-control" name="dr_name" [(ngModel)]="site.dr_name" placeholder="Name" required />
		</div>
		<div class="form-field margin-bottom-sm">
			<label for="dr_facility_name">Facility Name <span class="text-danger">*</span></label>
			<input type="text" class="form-control" name="dr_facility_name" [(ngModel)]="site.dr_facility_name" placeholder="Facility Name" required />
		</div>
		<div class="form-field margin-bottom-sm">
			<label for="dr_facility_id">Facility ID <span class="text-danger">*</span></label>
			<input type="text" class="form-control" name="dr_facility_id" [(ngModel)]="site.dr_facility_id" placeholder="Facility ID" required />
		</div>
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="OEMsInfo.length > 0">
		<label for="oem_id">Site OEM</label>
		<ng-select [items]="OEMsInfo" bindLabel="name" bindValue="id" placeholder="Select Site OEM" name="oem_id" [(ngModel)]="site.oem_id" [clearable]="false" required>
		</ng-select>
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="dealersInfo.length > 0">
		<label for="dealer_id">Site Dealer</label>
		<ng-select [items]="dealersInfo" bindLabel="name" bindValue="id" placeholder="Select Site Dealer" name="dealer_id" [(ngModel)]="site.dealer_id" [clearable]="false" required (ngModelChange)="getDealerUsers($event, 'dealer')">
		</ng-select>
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="dealersInfo.length > 0 && mode == 'manage' && site.dealer_id">
		<label for="dealer_users">Notify Dealers</label>
		<ng-select [items]="dealerUsers" bindLabel="name" bindValue="id" multiple="true" [addTag]="true" [hideSelected]="true" placeholder="Notify user..." name="dealer_users" [(ngModel)]="site.dealer_users" addTagText='' (add)="checkUser($event, 'dealer')">
		</ng-select>
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="serviceDealersInfo.length > 0">
		<label for="service_dealer_id">Site Service Dealer</label>
		<ng-select [items]="serviceDealersInfo" bindLabel="name" bindValue="id" placeholder="Select Site Service Dealer" name="service_dealer_id" [(ngModel)]="site.service_dealer_id" [clearable]="false" required (ngModelChange)="getDealerUsers($event ,'service')">
		</ng-select>
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="serviceDealersInfo.length > 0 && mode == 'manage' && site.service_dealer_id">
		<label for="service_dealer_users">Notify Service Dealers</label>
		<ng-select [items]="serviceDealerUsers" bindLabel="name" bindValue="id" multiple="true" [addTag]="true" [hideSelected]="true" placeholder="Notify user..." name="service_dealer_users" [(ngModel)]="site.service_dealer_users" addTagText='' (add)="checkUser($event, 'service')">
		</ng-select>
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="truckDealersInfo.length > 0">
		<label for="truck_dealer_id">Site Truck Dealer</label>
		<ng-select [items]="truckDealersInfo" bindLabel="name" bindValue="id" placeholder="Select Site Truck Dealer" name="truck_dealer_id" [(ngModel)]="site.truck_dealer_id" [clearable]="false" required (ngModelChange)="getDealerUsers($event, 'truck')">
		</ng-select>
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="truckDealersInfo.length > 0 && mode == 'manage' && site.truck_dealer_id">
		<label for="truck_dealer_users">Notify Truck Dealers</label>
		<ng-select [items]="truckDealerUsers" bindLabel="name" bindValue="id" multiple="true" [addTag]="true" [hideSelected]="true" placeholder="Notify user..." name="truck_dealer_users" [(ngModel)]="site.truck_dealer_users" addTagText='' (add)="checkUser($event, 'truck')">
		</ng-select>
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="ACTusers.length > 0">
		<label for="ACTEmployee">ACT Contact Person</label>
		<ng-select [items]="ACTusers" bindLabel="name" bindValue="id" placeholder="Select ACT Contact Person" name="act_user_id" [(ngModel)]="site.act_user_id" [clearable]="false" required>
		</ng-select>
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="!site.isNew && mode == 'admin'">
		<label for="act_intelligent_type">ACT services:</label><br />
		<label><input type="radio" [(ngModel)]="site.act_intelligent_type" name="act_intelligent_type" class="form-control" disabled value="1"/> ACT intelligent</label>
		<label><input type="radio" [(ngModel)]="site.act_intelligent_type" name="act_intelligent_type" class="form-control" disabled value="0"/> None</label>
	</div>


	<div class="form-field margin-bottom-sm" *ngIf="site.act_intelligent_type > 0">
		<label>
			<input
				type="checkbox"
				name="act_first_enabled"
				[(ngModel)]="site.act_first_enabled"
				(ngModelChange)="generateToken()"
				class="form-control"/> ACT First Enabled</label>
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="site.act_intelligent_type != 0 && site.act_first_enabled">	
		<a href="/#/act-first/public/{{site.act_first_token}}" *ngIf="validToken">
			<span name="act_first_token" class="margin-right-sm">https://www.act-view.com/#/act-first/public/{{site.act_first_token}}</span>
		</a>
		<span name="act_first_token" class="margin-right-sm" *ngIf="!validToken">https://www.act-view.com/#/act-first/public/{{site.act_first_token}}</span>

		<button type="button" class="btn btn-default sm" (click)="generateToken(true)" *ngIf="siteWasActFirst">Regenerate Token</button>
		<input type="hidden" class="form-control" name="act_first_token" [(ngModel)]="site.act_first_token" />
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="ACTusers.length > 0">
		<label><input type="checkbox" name="test_site" [(ngModel)]="site.test_site" class="form-control"/> Is Test Site</label>

	<div class="form-field margin-bottom-sm" *ngIf="site.act_intelligent_type != 0 && site.act_first_enabled && userService.hasAccessFunction('public_pm_password')">
		<div class="form-field margin-bottom-sm">
			<label>
				<input
					type="checkbox"
					name="pm_enabled"
					[(ngModel)]="site.pm_enabled"
					(ngModelChange)="generateToken()"
					class="form-control"/> {{'site.pm_enabled' | translate}}
			</label>
		</div>
		
		<div class="form-field margin-bottom-sm">
			<label><input type="checkbox" name="act_first_water_indicator" [(ngModel)]="site.act_first_water_icon" class="form-control"/> Water Level Indicator (water icon)</label>
		</div>
		<div class="form-field margin-bottom-sm" *ngIf="site.pm_enabled">
			<label for="name">{{'site.act_first_pm_password' | translate}}</label>
			<span name="pm_password" class="margin-right-sm">{{site.pm_password || 'g.na' | translate}}</span>
		</div>
		<div class="form-field margin-bottom-sm" *ngIf="site.pm_enabled">
			<label for="name">{{'site.pm_subscribed_list' | translate}}</label>
			<ng-select [items]="siteOwners" multiple="true" bindLabel="name" bindValue="id" name="subscribed_pms" [(ngModel)]="site.pm_subscribed_list" required placeholder="{{'site.pm_subscribed_list' | translate}}">
			</ng-select>
		</div>
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="site.act_intelligent_type > 0">
		<label><input type="checkbox" name="managed_by_dealer" [(ngModel)]="site.managed_by_dealer" class="form-control"/> Managed By Dealer</label>
	</div>

	<div class="form-field margin-bottom-sm" *ngIf="userService.hasAccessFunction('official_reporting')">
		<label><input type="checkbox" name="is_smart_rebates" [(ngModel)]="site.is_smart_rebates" class="form-control"/>Is Smart Rebates™ Site</label>
	</div>

	<fieldset>
		<legend>Site Contact Info</legend>
		<div class="form-field margin-bottom-sm">
			<label for="name">Name</label>
			<input type='text' class='form-control' name="contactname" [(ngModel)]='site.contactname' placeholder='Person Name' />
		</div>

		<div class="form-field margin-bottom-sm">
			<label for='phoneno'>Phone No.</label>
			<input type='text' class='form-control' name="phoneno" [(ngModel)]='site.phoneno' placeholder='Phone No.' />
		</div>

		<div class="form-field margin-bottom-sm">
			<label for='email'>Email</label>
			<input type='email' class='form-control' name="email" [(ngModel)]='site.email' placeholder='Email' />
		</div>
	</fieldset>
</div>
