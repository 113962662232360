import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { NoUIBlockHttpParams } from '../../../shared/loader/noUIBlock-httpParam';

@Injectable()
export class DevicesDashboardService {

	batteryTypes = [{id:0, text:"Lead Acid"}, {id:1, text:"Lithium-ion"}, {id:2, text:"GEL"}, {id:4, text: "Thin Plate Pure Lead"}];
	batteryTypesObj = {};
	plcGainVals = [1, 2, 3, 4, 5];
	chargerTypes = [{id:0, text:"Fast"}, {id:1, text:"Conventional"}, {id:2, text:"Opportunity"}];
	truckTypes = [{id:0, text:"Small"}, {id:1, text:"Medium"}, {id:2, text:"Large"}];
	bmsTypes = [{id:1, text:"GCT"}, {id:2, text:"NAVITAS"}, {id:3, text:"Electrovaya"}, {id:4, text: 'Southwest Airlines'}];

	daysOfTheWeek: any[]	= [];
	TRCurrentRates: any[]	= [];
	CCCurrentRates: any[]	= [];
	FICurrentRates: any[]	= [];
	EQCurrentRates: any[]	= [];
	cvFinishCurrents: any[]	= [];
	finishDvVoltages: any[]	= [];
	timeIntervals: any[]	= [];
	quartsIntervals: any[]	= [];
	quarts_2_18: any[]		= [];
	quarts_6_18: any[]		= [];
	quarts_2_6: any[]		= [];
	quarts_1_6: any[]		= [];
	limitingFactors: any[]	= [];

	isBulkSettings: boolean = false;
	settingsDeviceType: string = '';

	constructor(
		private httpClient: HttpClient,
		private commonUtil: CommonUtil
	) {
		this.daysOfTheWeek		= commonUtil.getDropDownListData('week');
		this.TRCurrentRates		= commonUtil.getDropDownListData('number', {start:3, end:30, step:1});
		this.CCCurrentRates		= commonUtil.getDropDownListData('number', {start:4, end:100, step:1});
		this.FICurrentRates		= commonUtil.getDropDownListData('number', {start:1, end:13, step:0.5});
		this.EQCurrentRates		= commonUtil.getDropDownListData('number', {start:1, end:11, step:1});
		this.cvFinishCurrents	= commonUtil.getDropDownListData('number', {start:4, end:15, step:0.5});
		this.finishDvVoltages	= commonUtil.getDropDownListData('number', {start:5, end:100, step:5});
		this.timeIntervals		= commonUtil.getDropDownListData('clock',  {start:0, end:18, step:15, ignoreFirst: 1});
		this.quartsIntervals	= commonUtil.getDropDownListData('clock',  {start:0, end:24, step:15, ignoreFirst: 1});
		this.quarts_2_18		= commonUtil.getDropDownListData('clock',  {start:2, end:18, step:15});
		this.quarts_6_18		= commonUtil.getDropDownListData('clock',  {start:6, end:18, step:15});
		this.quarts_2_6			= commonUtil.getDropDownListData('clock',  {start:2, end:6, step:15});
		this.quarts_1_6			= commonUtil.getDropDownListData('clock',  {start:1, end:6, step:15});
		this.limitingFactors	= commonUtil.getDropDownListData('number', {start:50, end:95, step:1});
		this.batteryTypesObj	= commonUtil.arrayToAssociativeArray(this.batteryTypes);
	}

	resetPLCFault(deviceType, deviceId) {
		return this.httpClient.post('/customers/resetPLCFault', {deviceType, deviceId}, {
			observe: "body",
			responseType:"text"
		});
	}

	restartDevice(type, id, options) {
		return this.httpClient.post('/management/restartDevice', {type, id, options}, {
			observe: "body"
		});
	}

	refreshDeviceWiFiInfo(id, type) {
		return this.httpClient.post('/management/refreshDeviceWiFiInfo', {id, type}, {
			observe: "body"
		});
	}

	getDeviceInfo(id, type, blockUI) {
		return this.httpClient.post('/management/getDeviceInfo', {id, type}, {
			observe: "body",
			params: new NoUIBlockHttpParams(!blockUI)
		});
	}

	enableAutoconnect(id, type) {
		return this.httpClient.post('/management/enableAutoconnect', {id, type}, {
			observe: "body"
		});
	}

	getBattviewRTrecords(id, studyId, startDate, endDate) {
		return this.httpClient.post('/bfd/getBattviewRTrecords', {id, studyId, startDate, endDate}, {
			observe: "body"
		});
	}

	getConnectivityTrack(type, id, startDate, endDate) {
		return this.httpClient.post('/management/getConnectivityTrack', {type, id, startDate, endDate}, {
			observe: "body"
		});
	}

	getClientsByCustomerId(customerid) {
		return this.httpClient.post('/customers/getClientsByCustomerId', {customerid}, {
			observe: "body"
		});
	}

	getDebugRecords(id, type, startDate, endDate, studyId = 0) {
		let url = '/bfd/getBattviewDebugRecords';
		if(type == 'charger') {
			url = 'chargers/getChargerDebugRecords';
		}
		return this.httpClient.post(url, {id, studyId, startDate, endDate}, {
			observe: "body"
		});
	}

	getDeviceConfigTrack(type, id, options) {
		return this.httpClient.post('/management/getDeviceConfigTrack', {type, id, options}, {
			observe: "body"
		});
	}

	assignDeviceToGroup(siteId, groupId, deviceType, deviceId) {
		return this.httpClient.post('/management/assignDeviceToGroup', {siteId, groupId, deviceType, deviceId}, {
			observe: "body",
			responseType:"text"
		});
	}

	checkIfRTrecordsExist(id, studyId) {
		var options = {checkIfExists: true};
		return this.httpClient.post('/bfd/checkIfRTrecordsExist', {id, studyId, options}, {
			observe: "body"
		});
	}
}
