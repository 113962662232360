<div class="panel panel-default margin-top-sm" >
	<div class="panel-body">
		<div class="padding-8">
			<h2 *ngIf="data !== null && !data.length">{{'g.no_results_found' | translate}}</h2>
			<div class="form-group row">
				<label for="tmp-limit" class="col-md-3 col-form-label col-form-label-lg">{{'site.invoice_mismatch_subscribers' | translate}}</label>
				<div class="col-md-7">
					<ng-select
						name="Subscribers"
						[multiple]="true"
						[addTag]="false"
						[items]="ACTusers"
						[hideSelected]="true"
						placeholder="Select user..."
						bindLabel="name"
						bindValue="id"
						[clearable]="false"
						[(ngModel)]="subscribers"
					></ng-select>
				</div>
				<div class="col-md-2">
					<button class="btn btn-primary margin-left-lg" type="button" (click)="updateInvoicesMismatchSubscribers()" >{{'g.update' | translate}}</button>
				</div>
				
			</div>
			<h2>{{'site.invoice_mismatch_for_site_battviews' | translate}}</h2>
			<table class="boxs table table-striped" *ngIf="data && data.length">
				<thead>
					<tr>
						<th>{{'site.site_name' | translate}}</th>
						<th>{{'site.site_battviews' | translate}}</th>
						<th>{{'site.active_invoices_battviews' | translate}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let row of data">
						<td>
							<a [routerLink]="['/main', row.customer_id]">{{row.customer_name}}</a> / <a [routerLink]="['/', row.customer_id, row.site_id]">{{row.site_name}}</a>
						</td>
						<td>{{row.data.site_battviews}}</td>
						<td>{{row.data.invoice_battviews}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>