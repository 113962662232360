<div class="mt-5">
	<h2>{{'apis_logs.title' | translate}}</h2>
</div>

<div class="mt-3 mb-2 ml-2 mr-2">
	<table  class="table table-striped">
		<thead>
			<tr>
				<th>{{'act.create_time' | translate}}</th>
				<th>{{'user.user_name' | translate}}</th>
				<th>{{'apis_logs.endpoint' | translate}}</th>
				<th>{{'g.success' | translate}}</th>
				<th>{{'g.view' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let log of logs">
				<td>{{log.created_at | date:'MM/dd/yyyy hh:mm:ss'}}</td>
				<td>{{log.user}}</td>
				<td>{{log.end_point}}</td>
				<td>{{ ('g.' + log.success ? 'yes' : 'no') | translate}}</td>
				<td><button class="btn btn-primary" (click)="viewLog(log)">{{'g.view' | translate}}</button></td>
			</tr>
		</tbody>
	</table>
</div>