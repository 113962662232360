<div class="row">
	<div class="panel panel-default">
		<div class="panel-body">
			
			<div class="col-md-12">
				<simple-widget title="{{'site.all_site_invoices' | translate}}">
					<label class="margin-bottom-lg">
						<input type='checkbox' class="form-control" [ngModel]="showLastSiteInvoice" (ngModelChange)="showLastSiteInvoiceOnly($event)"/> {{'site.show_last_site_invoice_only' | translate}}
					</label>
					<app-grid-v2 id="sitesInvoicesGrid" [columns]="sitesInvoicesGridColumns" [data]="sitesInvoicesGridData"></app-grid-v2>
				</simple-widget>
			</div>
			
			<div class="col-md-12">
				<simple-widget title="{{'site.active_demo_sites' | translate}}">
					<app-grid-v2 id="demoSitesGrid" [columns]="demoSitesGridColumns" [data]="demoSitesGridData"></app-grid-v2>
				</simple-widget>
			</div>
			
			<div class="col-md-12">
				<simple-widget title="{{'site.active_engineering_sites' | translate}}">
					<app-grid-v2 id="engineeringSitesGrid" [columns]="engineeringSitesGridColumns" [data]="engineeringSitesGridData"></app-grid-v2>
				</simple-widget>
			</div>
		</div>
	</div>
</div>