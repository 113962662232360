<div class="boxs3">
	<div *ngIf="allPMsSnapshots">
		<div class="row margin-top-lg margin-bottom-lg" *ngIf="allPMsSnapshots.length > 0">
			<simple-widget
				*ngFor="let snapshotObj of allPMsSnapshots"
				[title]="snapshotObj.time | date:'MM/dd/yyyy hh:mm:ss a'"
				[collapsedByDefault]="true"
			>
				<ng-container>
					<div *ngFor="let snapshot of snapshotObj.snapshots" class="margin-top margin-left">
						<div class="margin-top margin-left">
							<div class="row">
								<div class="col-lg-3">{{'charger.pm_sn' | translate}}: <a [routerLink]="['/pm-info',snapshot.SN]">{{ snapshot.SN }}</a></div>
								<div class="col-lg-3">{{'charger.version_and_revision' | translate}}: {{snapshot.VersionAndRevision}}</div>
								<div class="col-lg-3">{{'g.model' | translate}}: {{snapshot.model}}</div>
							</div>
						</div>
					</div>
				</ng-container>
			</simple-widget>
		</div>

		<div *ngIf="allPMsSnapshots.length === 0">
			<div class="panel-body">
				<strong>{{'charger.no_pms_data_found' | translate}}</strong>
			</div>
		</div>
	</div>
</div>