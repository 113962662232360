import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import { DeviceManagementService } from '../../device-management.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { UserService } from '../../../auth/user.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-device-by-id',
	templateUrl: './device-by-id.component.html',
	styleUrls: ['./device-by-id.component.css']
})
export class DeviceByIdComponent implements OnInit, OnDestroy {

	replacementHistory: any = {empty: true};
	deviceInfo: any = {empty: true};
	noResult: boolean = null;
	id: number;
	type = 'charger';
	currentUser: any = {};
	manageDatesAccess: boolean = false;

	constructor(
		private deviceManagemntService: DeviceManagementService,
		private breadcrumb: BreadcrumbService,
		private router: Router,
		public commonUtil: CommonUtil,
		public userService: UserService,
		private notificationMessage: NotificationMessageService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([
			{'label': this.translateService.instant('device.search_device_by_id'), 'url': ''}
		]);
		this.currentUser = this.userService.getCurrentUser();
		this.manageDatesAccess = this.userService.hasAccessFunction('accounting') ||
			this.userService.hasAccessFunction('act_intelligent_management') ||
			this.userService.hasAccessFunction('admin_act_intelligent') ||
			this.userService.hasAccessFunction('users_function_management');
	}

	getReplacementTimeline(SN, ID) {
		this.deviceManagemntService.getReplacementTimeline(SN, ID, this.type).subscribe(
			(history: any) => {

				var items = [];
				
				history.forEach((item) => {
					if(item.isdeleted) {
						item.serialnumber = this.commonUtil.formatSerialNumber(item, item.id);
						items.push(item);
					}
				});

				this.replacementHistory = _.sortBy(items, 'timeOfDeletion');
			}
		);
	}

	getDeviceByID(type) {
		
		this.replacementHistory = {empty: true};
		this.deviceInfo = {empty: true};
		this.type = type;
		this.noResult = false;

		this.deviceManagemntService.getDeviceByID(this.id, type).subscribe(

			deviceInfo => {
				if(Object.keys(deviceInfo).length == 0) {
					this.noResult = true;
				} else {
					this.noResult = false;
					
					if(type == 'battview' && this.commonUtil.arrayCompare([deviceInfo['oemid'], deviceInfo['dealerid'], deviceInfo['servicedealerid'], deviceInfo['customerid'], deviceInfo['siteid']], [0, 0, 0, 0, 0]))
						deviceInfo['isProductionLine'] = true;

					deviceInfo['serialnumber'] = this.commonUtil.formatSerialNumber(deviceInfo);
					
					this.deviceInfo = deviceInfo;
				}
			}
		);
	}

	goToEditDevice() {
		let link = this.deviceManagemntService.getEditDeviceLink(this.type, this.deviceInfo);
		this.router.navigate(link.url, {queryParams: link.params});
	}

	regenerateDailyDetails(deviceInfo) {
		this.deviceManagemntService.regenerateDailyDetails(deviceInfo['id'], deviceInfo['studyid']).subscribe(res=> {
			this.notificationMessage.setMessage('globalSuccessMsg');
		})
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}