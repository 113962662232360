<div class="row">
	<div id="page-wrapper" >
		<div id="page-inner">
			<div class="col-md-12 panel panel-default">
				<div class="panel-body">
					<div id="menu-nav">
						<div id="wrap" class="col-md-12">
							<div class="inner relative">
								<nav id="navigation">
									<ul id="main-menu">
										<li [ngClass]="{active: selectedTab == 'contact-us'}">
											<a [routerLink]="['/support-logs', 'contact-us']" (click)="getSupportLoggingInfo('contact-us')">
												{{'g.contact_us' | translate}}
											</a>
										</li>
										<li [ngClass]="{active: selectedTab == 'notes'}">
											<a [routerLink]="['/support-logs', 'notes']" (click)="getSupportLoggingInfo('notes')">
												{{'g.notes' | translate}}
											</a>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					<table class="boxs3 table table-striped">
						<thead>
							<tr>
								<th class="col-md-1">{{'g.id' | translate}}</th>
								<th class="col-md-3">{{'g.time' | translate}}</th>
								<th class="col-md-2">{{'user.user_id' | translate}}</th>
								<th [ngClass]="{'col-md-6': selectedTab == 'contact-us', 'col-md-4': selectedTab == 'notes'}">{{'g.text' | translate}}</th>
								<th *ngIf="selectedTab == 'notes'" class="col-md-2">{{'g.related_item' | translate}}</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let log of supportLog">
								<td title="ID">{{log.id}}</td>
								<td title="Time">{{log.create_time | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
								<td title="User ID">{{log.user_id}}</td>
								<td title="Text">{{log.text}}</td>
								<td *ngIf="selectedTab == 'notes'" title="Link" [ngSwitch]="log.modelType">
									<a *ngSwitchCase="'battview'" [routerLink]="['/',log.customerid, log.siteid, 'battviews', 'notes', log.modelId]">{{log.modelName}}</a>
									<a *ngSwitchCase="'site'" [routerLink]="['/',log.customerid, log.siteid]">{{log.modelName}}</a>
									<a *ngSwitchCase="'charger'" [routerLink]="['/',log.customerid, log.siteid, 'chargers', 'notes', log.modelId]">{{log.modelName}}</a>
									<a *ngSwitchCase="'battview mobile'" [routerLink]="['/battview-mobile','notes']" [queryParams]="{'battviewId': log.modelId, 'studyId': log.extraModelId}">{{log.modelName}}</a>
									<a *ngSwitchCase="'user'" [routerLink]="['/users']" [queryParams]="{'searchType': 'id', 'Val': log.modelId}">{{log.modelName}}</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>