<app-widgets-loader
	key="battery-aging"
	[data]="{
		data: data,
		siteId: siteId,
		customerId: customerId,
		isACTuser: user.isACTuser,
		siteName: siteName,
		customerName: customerName
	}"
	[passAllDataOnce]="true"
></app-widgets-loader>