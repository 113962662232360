<div *ngIf="device.id && date.fromDate && date.toDate" id="analytics-page">
	<div *ngIf="(hasData || pageHasRTrecord)">
		<app-truck-summary
			*ngIf="['truck-summary', 'chart'].indexOf(activeTab) > -1"
			[isTruckviewMobile]="isTruckviewMobile"
			[date]="date"
			[device]="device"
			[dailyDetails]="DailyDetails"
			[summaryObject]="summaryObject"
			[isACTuser]="currentUser.isACTuser"
			[hideTemperatureChart]="hideTemperatureChart"
			[alertSettings]="alertSettings"
			[pageHasRTrecord]="pageHasRTrecord"
			[pageIsActive]="activeTab == 'truck-summary'"
			[customerName]="customerInfo.name"
			[siteName]="siteInfo.name"
		></app-truck-summary>
		<div [ngSwitch]="activeTab">
			<!-- <app-exceptions #exceptions
				*ngSwitchCase="'exceptions'"
				[date]="date"
				[device]="device"
				[dailyDetails]="DailyDetails"
				[summaryObject]="summaryObject"
				[customerName]="customerInfo.name"
				[siteName]="siteInfo.name"
			></app-exceptions> -->
			<!-- <app-truck-usage-summary #truckUsageSummary
				*ngSwitchCase="'truck-usage-summary'"
				[date]="date"
				[device]="device"
				[dailyDetails]="DailyDetails"
				[summaryObject]="summaryObject"
				[isTruckviewMobile]="isTruckviewMobile"
				[customerName]="customerInfo.name"
				[siteName]="siteInfo.name"
			></app-truck-usage-summary> -->
			<!-- <app-truck-daily-usage #truckDailyUsage
				*ngSwitchCase="'truck-daily-usage'"
				[customerName]="customerInfo.name"
				[siteName]="siteInfo.name"
				[device]="device"
				[date]="date"
				[activeTab]="activeTab"
				[activeSubTab]="activeSubTab"
				[dailyDetails]="DailyDetails"
				[summaryObject]="summaryObject"
				[isTruckviewMobile]="isTruckviewMobile"
				[alertSettings]="alertSettings"
			></app-truck-daily-usage> -->
			<!-- <app-events-log #eventsLog
				*ngSwitchCase="'events-log'"
				[device]="device"
				[events]="events"
				[dailyDetails]="DailyDetails"
				[summaryObject]="summaryObject"
				[isTruckviewMobile]="isTruckviewMobile"
				[customerID]="customerID"
				[siteID]="siteID"
				[alertSettings]="alertSettings"
				[isACTuser]="currentUser.isACTuser"
				[customerName]="customerInfo.name"
				[siteName]="siteInfo.name"
				[date]="date"
				[activeTab]="activeTab"
				[activeSubTab]="activeSubTab"
			></app-events-log> -->
			<!-- <app-truckview-analytics-charts
				*ngSwitchCase="'chart'"
				[isTruckviewMobile]="isTruckviewMobile"
				[device]="device"
				[dailyDetails]="DailyDetails"
				[events]="events"
				[customerName]="customerInfo.name"
				[siteName]="siteInfo.name"
				[date]="date"
				[activeSubTab]="activeSubTab"
				[alertSettings]="alertSettings"
				[hideTemperatureChart]="hideTemperatureChart"
			></app-truckview-analytics-charts> -->
		</div>
	</div>
	<div *ngIf="!(hasData || pageHasRTrecord) && activeTab != 'global-record'">
		<div class="panel panel-default">
			<div class="panel-body">
				<strong>No Truckview Data Found in Selected Period!</strong>
			</div>
		</div>
	</div>
</div>