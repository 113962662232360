import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';

@Component({
	selector: 'app-plugged-in-missed-connection',
	templateUrl: './plugged-in-missed-connection.component.html',
	styleUrls: ['./plugged-in-missed-connection.component.css']
})
export class PluggedInMissedConnectionComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: ColumnChartConfig;
	tableColumns: any[] = [];

	constructor() { }

	ngOnInit() {
		this.config = new ColumnChartConfig({
			isPercent: true,
			yTitle: "Count of Batteries",
			LegendPosition: 'right',
			colors: ['#FDA428','#EA212D']
		});

		this.config.formatFunction = (data) => {
			var legendLabel = ["30 - 60 mins", "> 60 mins"];

			data = data.list;
			let formattedData: any[] = [[
				{"label": "Name", "type": "string"},
			]];
			for(let legendLabelItem of legendLabel) {
				formattedData[0].push({"label": legendLabelItem, "type": "number"});
				formattedData[0].push({"type": "string", role: 'annotation'});
				formattedData[0].push({"type": "string", role: 'style'});
			}

			for(let i = 0; i < data.length; i++) {
				let item: any = data[i];
				let dataItem = [];
				dataItem.push({v: item.x});
				for(let j=0; j < legendLabel.length; j++) {
					dataItem.push({v: item.y[j]});
					dataItem.push({v: item.y[j]});
					dataItem.push({v: "color: "+this.config.colors[j]});
				}
				formattedData.push(dataItem);
			}

			return formattedData;
		};
	}

	ngOnChanges() {
		if(this.data) {
			this.tableColumns = [{title: "Battery ID", property: "battery_id"}];
			if (this.data.siteSettings.userSettings.view_truck_id) {
				this.tableColumns.push({title: "Truck ID", property: "truck_id"});
			}
			this.tableColumns.push({title: "Value", property: "value"});
			this.data['battviewsData'] = this.siteBattviewsData;
			this.data['siteTags'] = this.siteTags;
			this.data['siteBattviewsTags'] = this.siteBattviewsTags;
		}
	}
}
