<div class="row">
	<div class="col-md-6 col-md-offset-3">
		<form #forgotPasswordFrm="ngForm" (ngSubmit)="forgotPassword()">
			<div class="boxs3">
				<h2>{{'user.forgot_your_password' | translate}}</h2>
				<div class="form-field">
					{{'user.enter_email_send_you_reset_password_link' | translate}}
				</div>
				<br/>
				<div class="form-field">
					<input type="email" class="form-control" name="email" [(ngModel)]="forgot.email" placeholder="{{'user.email_address' | translate}}" required>
				</div>
				<br/>
				<p-captcha siteKey="6LeVcCAUAAAAAEKZnR7tM3nEinNQE0JEzAbmQqXj" (onResponse)="forgot.recaptcha = $event.response" (onExpire)="forgot.recaptcha = ''"></p-captcha>
				<br/>
				<button type="submit" class="btn btn-default" [disabled]="forgotPasswordFrm.invalid || !forgot.recaptcha">{{'g.send' | translate}}</button>
				<br/>
			</div>
		</form>
	</div>
</div>