<div class="row">
  <div id="page-wrapper" >
    <div id="page-inner">
      <div class="col-md-12 boxs3">
        <div class="col-md-3">
          <input [(ngModel)]="searchSN" placeholder="{{'device.search_by_serial_number' | translate}}" class="form-control" (keyup)="searchSNFunction()" />
        </div>
        <div class="col-md-3">
          <label class="col-md-6"><input type="radio" [(ngModel)]="isPAbattviews" class="form-control" [value]="false" (click)="getNonAllocatedBattviews(false)"/> {{'g.battview' | translate}}</label>
          <label><input type="radio" [(ngModel)]="isPAbattviews" class="form-control" [value]="true" (click)="getNonAllocatedBattviews(true)"/> {{'g.battview_mobile' | translate}}</label>
        </div>
        <div class="col-md-4 col-md-offset-2">
          <button class="btn btn-info" (click)="allocateBattviews()" [disabled]="battviewsToAllocate.length == 0">
            {{'act.add_to_act_production_line' | translate}}		
          </button>
        </div>
      </div>
      <div class="col-md-6 panel panel-default">
        <div class="panel-body">
          <table class="table">
            <thead>
              <tr>
                <th>{{'device.serial_number' | translate}} ({{'device.battery_id' | translate}})</th>
                <th>{{'g.select' | translate}}</th>
              </tr>
            </thead>
            <tbody>
            <tr *ngFor="let battview of nonAllocatedBattviews">
              <td title="{{'device.commission_date' | translate}}: {{battview.installationdate | date:'MM/dd/yyyy hh:mm:ss a'}}">{{battview.serialnumber}} ({{battview.batteryid}})</td>
              <td><span class="pointer green-text" (click)="moveBattview('rightColumn', battview)">{{'g.select' | translate}}</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-6 panel panel-default">
        <div class="panel-body">	
          <table class="table">
            <thead>
              <tr>
                <th>{{'device.serial_number' | translate}} ({{'device.battery_id' | translate}})</th>
                <th>{{'g.select' | translate}}</th>
              </tr>
            </thead>
            <tbody>
            <tr *ngFor="let battview of battviewsToAllocate">
              <td>{{battview.serialnumber}} ({{battview.batteryid}})</td>
              <td><span class="pointer" (click)="moveBattview('leftColumn', battview)">{{'g.unselect' | translate}}</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>