import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../../auth/user.service';
import { AdminService } from '../../admin.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';

@Component({
	selector: 'app-dealer',
	templateUrl: './dealer.component.html',
	styleUrls: ['./dealer.component.css']
})
export class DealerComponent implements OnInit, OnDestroy {

	pageType: string = 'dealer';
	isTruckDealer: boolean = false;
	dealers = <any>[];

	currentUser = {};

	constructor(
		private adminService: AdminService,
		private commonUtil: CommonUtil,
		public userService: UserService,
		private breadcrumb: BreadcrumbService,
		private route: ActivatedRoute,
		private notificationMessage: NotificationMessageService
	) {
	}

	ngOnInit() {
		this.pageType = this.route.snapshot.data['pageType'] || 'dealer';
		this.isTruckDealer = this.pageType == 'truck_dealer';
		this.breadcrumb.setBreadcrumb([{'label': (this.isTruckDealer ? 'Truck ' : '')+'Dealers', 'url': ''}]);
		this.currentUser = this.userService.getCurrentUser();
		this.getDealers();
	}

	getDealers(id = 0) {
		this.adminService.getDealers(id, this.pageType).subscribe(
			data => {
				this.dealers = data['dealers'];
			}
		);
	}

	deleteDealer(id, confirmed) {

		let msg = "Are you sure you want to delete this"+(this.isTruckDealer ? ' truck ' : ' ')+"dealer?";
		
		if(confirmed || confirm(msg)) {
            let type = this.pageType;
            if(type == 'truck_dealer')
                type = 'truckdealer';
			this.adminService.deleteDealerOrOEM(id, type, confirmed).subscribe(
				response => {
					if(response['httpStatus'] == 'error') {
						let res = response['error'];
						if(res['msg'] == 'has devices') {

							let pronoun = res['data'] > 1 ? 'them' : 'it';

							let confMsg = "This"+(this.isTruckDealer ? ' truck ' : ' ')+"dealer have " + this.commonUtil.singularOrPlural(res['data'], "device") + ". This action will assign " + pronoun + " to ACT dealer!";

							if(confirm(confMsg)) {
								this.deleteDealer(id, true);
							}
						} else {
							let errMsg: string = '';
							if(res['msg'] == 'own sites') {
								let sites = this.commonUtil.extractKeyFromObjects('name', res['data']).join('<br/>');
								errMsg = "This"+(this.isTruckDealer ? ' truck ' : ' ')+"dealer owns the following " + this.commonUtil.singularOrPlural(res['data'].length, "site", false) + ":<br/>" + sites;
							} else if(res['msg'] == 'has users') {
								
								errMsg = "This"+(this.isTruckDealer ? ' truck ' : ' ')+"dealer have some users. Please delete them before doing this action!";
							}
							this.notificationMessage.setMessage(errMsg, 'danger', 30000);
						}
					} else {
						this.getDealers(0);
					}
				}
			);
		}
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}