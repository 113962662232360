import { Component, OnInit, OnDestroy } from '@angular/core';
import { SideMenuService } from '../../shared/side-menu/side-menu.service';
import * as _ from 'underscore';

import { Site } from '../../shared/models/site.model';
import { CustomerDashboardService } from '../customer-dashboard/customer-dashboard.service';
import { Customer } from '../../shared/models/customer.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sites-map',
  templateUrl: './sites-map.component.html',
  styleUrls: ['./sites-map.component.css']
})
export class SitesMapComponent implements OnInit, OnDestroy {

  intelligentSitesCheckbox: boolean = true;
  tags = [];
  expandedCustomersArr = [];
  sites : Site[] = [];
  siteInfoBox = {
    show: false,
    id: 0,
    site: {},
    customer: {},
    actIntelligent: false
  };
  expandedSitesSubscription: Subscription = new Subscription();
  expandedCustomersSubscription: Subscription = new Subscription();
  clickedSiteNotDistinctSubscription: Subscription = new Subscription();

  constructor(private sideMenu: SideMenuService, private cdService: CustomerDashboardService) { }

  ngOnInit() {
    // reset all values
    // this.sideMenu.setExpandedCustomers([]);
    this.sideMenu.setCurrentSite(new Site());
    this.sideMenu.setCurrentCustomer(new Customer());
    this.setInfoBoxVisibility(false);
    this.expandedSitesSubscription = this.sideMenu.expandedSites.subscribe((sites) => {
      sites = this.filterSites(sites);
      this.sites = this.cdService.addSitesConnectivityStatusIcons(sites);
    });
    this.expandedCustomersSubscription = this.sideMenu.expandedCustomers.subscribe(value => {
      this.tags = value;
      this.setInfoBoxVisibility(false);
    });
    this.clickedSiteNotDistinctSubscription = this.sideMenu.clickedSiteNotDistinct.subscribe(site => {
      if (site && site.id > 0) {
        this.siteMarkerClick(site);
      }
    });
  }

  filterSites(sites) {
      let dealerFilter, intelligentFilter;
       sites = sites.filter((site) => {
        intelligentFilter = this.intelligentSitesCheckbox ? site.act_intelligent_type != 0 : true ;
        // if (_.isNumber($scope.dealer.dealerId)) {
          // dealerFilter = site.dealer_id == $scope.dealer.dealerId || site.service_dealer_id == $scope.dealer.dealerId;
        // } else {
          dealerFilter = true;
        // }
        return intelligentFilter && dealerFilter && !site.test_site;
      });
      return sites;
  }

  toggleIntelligentFilter() {
    this.setInfoBoxVisibility(false);
    this.intelligentSitesCheckbox = !this.intelligentSitesCheckbox;
	let sites = this.sideMenu.getExpandedSites();
    sites = this.filterSites(sites);
    this.sites = this.cdService.addSitesConnectivityStatusIcons(sites);
  }

  closeExpandedCustomer(customerId) {
    this.sideMenu.removeExpandedCustomer(customerId);
  }

  siteMarkerClick(site) {
    this.setInfoBoxVisibility(true);
    this.siteInfoBox.site = site;
    this.siteInfoBox.customer = this.sideMenu.getCustomer(site.customerid);
    this.siteInfoBox.actIntelligent = site.act_intelligent_type != 0 ? true: false;
  }

  setInfoBoxVisibility(value: boolean) {
    this.siteInfoBox.show = value;
    this.sideMenu.setSiteInfoBoxVisibility(value);
  }

  ngOnDestroy() {
    this.expandedSitesSubscription.unsubscribe();
    this.expandedCustomersSubscription.unsubscribe();
    this.clickedSiteNotDistinctSubscription.unsubscribe();
    this.sideMenu.setClickedSite(new Site());
  }   

}