<div class="row">
	<div class="col-md-6 panel panel-default">
		<div class="panel-body">
			<h3>Chargers</h3>
			<input [(ngModel)]="filterChargerSN" placeholder="Search/Filter by Serial Number" class="form-control"/>
			<br/>
			<div class="col-md-9" *ngIf="chargersList.length > 0">
				<table class="table">
					<thead>
						<tr>
							<th>Serial Number (Charger ID)</th>
							<th>Select</th>
						</tr>
					</thead>
					<tbody>
					<tr *ngFor="let charger of chargersList | filter: filterChargerSN: 'serialnumber': true" [ngClass]="{'dirtySettingsField':chargersRequests.indexOf(charger.id) != -1}">
						<td>{{charger.serialnumber}} ({{charger.deviceprettyname}})</td>
						<td><input (ngModelChange)="toggleDeviceSelection($event, charger, 'charger')" type="checkbox" class="form-control" [checked]="charger.checked" ngModel/></td>
					</tr>
					</tbody>
				</table>
			</div>
			<div class="col-md-3" *ngIf="chargersList.length > 0">
				<app-manage-device-btn twoLines="true" [selectedDevices]="selectedDevices['charger']" class="desktop-fixed-pos tcenter"></app-manage-device-btn>
			</div>
		</div>
	</div>
	<div class="col-md-6 panel panel-default">
		<div class="panel-body">
			<h3>BattViews</h3>
			<input [(ngModel)]="filterBattviewSN" placeholder="Search/Filter by Serial Number" class="form-control"/>
			<br/>
			<div class="col-md-9" *ngIf="battviewsList.length > 0">
				<table class="table">
					<thead>
						<tr>
							<th>Serial Number (Battery ID)</th>
							<th>Select</th>
						</tr>
					</thead>
					<tbody>
					<tr *ngFor="let battview of battviewsList | filter: filterBattviewSN: 'serialnumber': true" [ngClass]="{'dirtySettingsField':battviewRequests.indexOf(battview.id) != -1}">
						<td>{{battview.serialnumber}} ({{battview.deviceprettyname}})</td>
						<td><input (ngModelChange)="toggleDeviceSelection($event, battview, 'battview')" type="checkbox" class="form-control" [checked]="battview.checked" ngModel/></td>
					</tr>
					</tbody>
				</table>
			</div>
			<div class="col-md-3" *ngIf="battviewsList.length > 0">
				<app-manage-device-btn twoLines="true" [selectedDevices]="selectedDevices['battview']" type='battview' class="desktop-fixed-pos tcenter"></app-manage-device-btn>
			</div>
		</div>
	</div>
</div>