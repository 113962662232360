<div class="row margin-top-lg m-5">
	<div class="row">
		<div class="col-md-2 text-right-md "><strong>{{'time.from_date' | translate}}:</strong></div>
		<div class="col-md-3">
			<div class="input-group">
				<input class="form-control" placeholder="mm/dd/yyyy" name="startDB" [(ngModel)]="startDate" bsDatepicker #startDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<span class="input-group-btn">
					<button class="btn btn-default btn-date" (click)="startDB.toggle()" type="button"><i class="fa fa-calendar"></i></button>
				</span>
			</div>
		</div>
	</div>
	<div class="text-danger" *ngIf="submitted && !validStartFromTime">{{'time.please_enter_a_valid_time_range' | translate}}</div>
	<div class="row">
		<div class="col-md-2 text-right-md margin-top-sm"><strong>{{'time.from_time' | translate}}:</strong></div>
		<div class="col-md-3 no-gutter"><timepicker (isValid)="validStartFromTime = $event" [(ngModel)]="startFromTime" [showSpinners]="false" [showMeridian]="false"></timepicker></div>
	</div>

	<div class="row margin-top-lg">
		<div class="col-md-2 text-right-md "><strong>{{'time.to_date' | translate}}:</strong></div>
		<div class="col-md-3">
			<div class="input-group">
				<input class="form-control" placeholder="mm/dd/yyyy" name="endDB" [(ngModel)]="endDate" bsDatepicker #endDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<span class="input-group-btn">
					<button class="btn btn-default btn-date" (click)="endDB.toggle()" type="button"><i class="fa fa-calendar"></i></button>
				</span>
			</div>
		</div>
	</div>
	<div class="text-danger" *ngIf="submitted && !validEndToTime">{{'time.please_enter_a_valid_time_range' | translate}}</div>
	<div class="row">
		<div class="col-md-2 text-right-md margin-top-sm"><strong>{{'time.to_time' | translate}}:</strong></div>
		<div class="col-md-3 no-gutter"><timepicker (isValid)="validEndToTime = $event" [(ngModel)]="endToTime" [showSpinners]="false" [showMeridian]="false"></timepicker></div>
	</div>
	<div class="text-danger" *ngIf="invalidDates">{{'time.end_start_time' | translate}}</div>
	<div class="margin-top-lg margin-bottom-lg"><button class="btn btn-md btn-info" (click)="fetchData()">{{'g.fetch_data' | translate}}</button></div>
</div>

<simple-widget
	[collapsedByDefault]="true"
	*ngFor="let timeObj of dataByDateArr"
	[title]="dateTitles[timeObj.date]">
	<!-- <div *ngIf="groupHasData(group); else noData"> -->
	<div *ngFor="let innerGroupKey of getDateInnerGroups([timeObj.date])" class="margin-bottom-lg">
		<i 
			class="fa fa-lg margin-right-sm text-info"
			[ngClass]="timeObj['innergroups'][innerGroupKey].expanded ? 'fa-minus-square' : 'fa-plus-square'" 
			aria-hidden="true"
			(click)="timeObj['innergroups'][innerGroupKey].expanded = !timeObj['innergroups'][innerGroupKey].expanded"></i>
		<strong>{{getGroupName(innerGroupKey)}}</strong>

		<ng-container *ngIf="timeObj['innergroups'][innerGroupKey].expanded">
			<div 
				*ngFor="let itemId of getKeys(timeObj['innergroups'][innerGroupKey])"
				class="margin-top-lg margin-left">
					<i
						class="fa fa-lg margin-right-sm text-info" 
						[ngClass]="timeObj['innergroups'][innerGroupKey][itemId][0].expanded ? 'fa-minus-square' : 'fa-plus-square'" aria-hidden="true" 
						(click)="timeObj['innergroups'][innerGroupKey][itemId][0].expanded = !timeObj['innergroups'][innerGroupKey][itemId][0].expanded"></i>
						<a [routerLink]="timeObj['innergroups'][innerGroupKey][itemId][0]['name_link']">
						{{ timeObj['innergroups'][innerGroupKey][itemId][0].name }}</a>

					
					<ng-container *ngIf="timeObj['innergroups'][innerGroupKey][itemId][0].expanded">

						<div *ngFor="let charger of timeObj['innergroups'][innerGroupKey][itemId]" class="margin-top margin-left">
								<div class="row margin-left">
									<div class="row">
										<div class="col-lg-3">ID: <a [routerLink]="charger['name_link']">{{charger.id}}</a></div>
										<div class="col-lg-3" *ngIf="charger.batteryid">{{'device.battery' | translate}}: <a [routerLink]="charger['battery_id_link']">{{charger.batteryname}}</a></div>
									</div>
									<div class="row">
										<div class="col-lg-3">{{'device.truck_type' | translate}}: {{charger.groupname}}</div>
										<div class="col-lg-3">{{'device.has_alert' | translate}}: {{charger.alertFlag}}</div>
										<div class="col-lg-3">{{'g.rank' | translate}}: {{charger.rank}}</div>
										<div class="col-lg-3">{{'g.AHRs' | translate}}: {{charger.Ahrs}}</div>
									</div>
									<div class="row">
										<div class="col-lg-3">{{'device.temperature' | translate}}: {{charger.temperature}}</div>
										<div class="col-lg-3">{{'device.score' | translate}}: {{charger.score}}</div>
										<div class="col-lg-3">{{'device.exit_status' | translate}}: {{charger.exit_status_text}}</div>
										<div class="col-lg-3">{{'device.cooled_down_timer' | translate}}: {{charger['relaxedBatteryTimer']}}</div>
									</div>
								</div>
						</div>

					</ng-container>

			</div>
		</ng-container>

	</div>
	<ng-template #noData>{{'act.no_items_found_for_this_truck_type' | translate}}</ng-template>
</simple-widget>
