<div class="boxs2">
	<table class="table table-stripped">
		<thead>
			<tr>
				<th class="red-text">{{'g.id' | translate}}</th>
				<th>{{'g.time' | translate}}</th>
				<th>{{'g.data' | translate}}</th>
			</tr>
		</thead>
		<tbody *ngIf="data">
			<tr *ngFor="let item of data; let idx = index">
				<td class="red-text">{{item.id}}</td>
				<td>{{item.time_of_dispatch}}</td>
				<td>
					<ngx-json-viewer [json]="item.data" [expanded]="false"></ngx-json-viewer>
				</td>
			</tr>
		</tbody>
	</table>
</div>