import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgmCoreModule } from '@agm/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AlertModule } from 'ngx-bootstrap/alert';
import { SortablejsModule } from 'ngx-sortablejs';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { CaptchaModule } from 'primeng/captcha';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule} from 'primeng/inputtext';
import { ButtonModule} from 'primeng/button';
import { Ng5SliderModule } from 'ng5-slider';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthService } from './auth/auth.service';
import { AdminService } from './admin/admin.service';
import { ChargerService } from './admin/devices/chargers/charger.service';
import { BattviewService } from './admin/devices/battviews/battview.service';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { AdminModule } from './admin/admin.module';
import { UserService } from './auth/user.service';
import { AuthGuard } from './auth/auth-guard.service';
import { NoAuthGuard } from './auth/no-auth-guard.service';
import { LoaderService } from './shared/loader/loader.service';
import { loaderInterceptor } from './shared/loader/loader-interceptor.service';
import { DeviceManagementModule } from './device-management/device-management.module';
import { HomeModule } from './home/home.module';
import { SideMenuService } from './shared/side-menu/side-menu.service';
import { HeaderService } from './shared/header/header.service';
import { PaginationComponent } from './shared/pagination/pagination.component';
import { CommonUtil } from './shared/services/utility/common.service';
import { NavigationService } from './shared/services/navigation.service';
import { CommonDataService } from './shared/services/common-data.service';
import { NotificationMessageService } from './shared/notification-message/notification-message.service';
import { FooterService } from './shared/footer/footer.service';
import { BreadcrumbService } from './shared/breadcrumb/breadcrumb.service';
import { CustomerDashboardService } from './home/customer-dashboard/customer-dashboard.service';
import { DeviceManagementService } from './device-management/device-management.service';
import { HomeService } from './home/home.service';
import { ChargersDashboardService } from './home/site-dashboard/chargers/chargers-dashboard/chargers-dashboard.service';
import { SimpleWidgetComponent } from './shared/simple-widget/simple-widget.component';
import { NotesComponent } from './shared/notes/notes.component';
import { NotesService } from './shared/notes/notes.service';
import { TagsComponent } from './shared/tags/tags.component';
import { TagsService } from './shared/tags/tags.service';
import { SiteDashboardService } from './home/site-dashboard/site-dashboard.service';
import { DevicesDashboardService } from './home/site-dashboard/devices/devices-dashboard.service';
import { SiteInfoService } from './home/site-dashboard/site-info/site-info.service';
import { BattviewsDashboardService } from './home/site-dashboard/battviews/battviews-dashboard/battviews-dashboard.service';
import { TruckviewsDashboardService } from './home/site-dashboard/truckviews/truckviews-dashboard/truckviews-dashboard.service';
import { ChartUtil } from './shared/services/utility/chart.service';
import { ChartDataService } from './shared/services/chart-data.service';
import { ActFirstService } from './home/site-dashboard/act-first/act-first.service';
import { ActFirstGuard } from './auth/actfirst-guard.service';
import { DynamicResponseService } from './home/site-dashboard/dynamic-response/dynamic-response.service';
import { NgxJsonViewerModule } from 'ngx-json-viewer';


@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		HttpClientXsrfModule.withOptions({
			cookieName: 'XSRF-TOKEN',
			headerName: 'X-XSRF-Token',
		}),
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		AppRoutingModule,
		AuthModule,
		AdminModule,
		DeviceManagementModule,
		HomeModule,
		SharedModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyB9LI3Dv6V5PMCKJJANR1IFtz55OW7_1cc',
			libraries: ["places"],
			// language: localStorage && localStorage.lang || 'en'
		}),
		ModalModule.forRoot(),
		AlertModule.forRoot(),
		BsDropdownModule.forRoot(),
		SortablejsModule.forRoot({ animation: 150, handle: '.drag-handle' }),
		CaptchaModule,
		TableModule,
		CalendarModule,
		MultiSelectModule,
		DropdownModule,
		ButtonModule,
		Ng5SliderModule,
		InputTextModule,
		Ng2GoogleChartsModule,
		NgxJsonViewerModule
	],
	providers: [
		CommonUtil,
		ChartUtil,
		NavigationService,
		CommonDataService,
		AuthService,
		AdminService,
		DeviceManagementService,
		HomeService,
		ChargersDashboardService,
		DynamicResponseService,
		ChargerService,
		BattviewService,
		BattviewsDashboardService,
		TruckviewsDashboardService,
		AuthGuard,
		NoAuthGuard,
		UserService,
		LoaderService,
		SideMenuService,
		HeaderService,
		PaginationComponent,
		NotificationMessageService,
		FooterService,
		BreadcrumbService,
		CustomerDashboardService,
		SimpleWidgetComponent,
		NotesComponent,
		NotesService,
		TagsComponent,
		TagsService,
		SiteDashboardService,
		DevicesDashboardService,
		SiteInfoService,
		ChartDataService,
		ActFirstService,
		ActFirstGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: loaderInterceptor,
			multi: true
		}],
	bootstrap: [AppComponent]
})
export class AppModule { }

