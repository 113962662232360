import { Component, OnInit } from '@angular/core';
import { ChargersDashboardService } from '../chargers-dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationMessageService } from '../../../../../shared/notification-message/notification-message.service';

@Component({
	selector: 'app-pm-info',
	templateUrl: './pm-info.component.html',
	styleUrls: ['./pm-info.component.css']
})
export class PmInfoComponent implements OnInit {

	pmID: string;
	pmInfo: any;
	latestChargerInfo: any;
	showError: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private chargersDashboard: ChargersDashboardService,
		private notificationMessage: NotificationMessageService
	) { }

	ngOnInit() {
		this.pmID = this.route.snapshot.params.pmID || "0";
		this.chargersDashboard.getPMInfo(this.pmID).subscribe(
			(response: any) => {
				this.pmInfo = response.PM;
				this.latestChargerInfo = response.charger;
			},
			() => {
				// Error Case
				this.showError = true;
				this.notificationMessage.closeNotification();
			} 
		);
	}
}
