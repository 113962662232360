import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth/auth-guard.service';
import { SitesMapComponent } from './home/sites-map/sites-map.component';

const appRoutes: Routes = [	
	// { path: '**', redirectTo: '/not-found' }
]

@NgModule({
	imports: [RouterModule.forRoot(appRoutes,{useHash: true, preloadingStrategy: PreloadAllModules})],
	exports: [RouterModule]
})

export class AppRoutingModule {

}