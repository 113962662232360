import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'filter'})
export class FilterPipe implements PipeTransform {

	transform(items: any, searchText: any, field: string, trim: boolean): any {
		if(searchText == null) return items;
		if(trim) searchText = searchText.trim();
		return items.filter((item) => {
			return item[field].toLowerCase().indexOf(searchText.toLowerCase()) > -1;
		});
	}
}