import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AdminService } from '../../../admin.service';
import { UserService } from '../../../../auth/user.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { NotificationMessageService } from '../../../../shared/notification-message/notification-message.service';
import { BreadcrumbService } from '../../../../shared/breadcrumb/breadcrumb.service';

@Component({
	selector: 'app-validate-request',
	templateUrl: './validate-request.component.html',
	styleUrls: ['./validate-request.component.css']
})
export class ValidateRequestComponent implements OnInit, OnDestroy {

	guestInfo: any = {};
	user: any = {};
	roles = <any>[];
	customers = <any>[];
	dealers = <any>[];
	truckDealers = <any>[];
	OEMs = <any>[];

	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute,
		public userService: UserService,
		private commonUtil: CommonUtil,
		private notificationMessage: NotificationMessageService,
		private router: Router,
		private breadcrumb: BreadcrumbService
	) {
	}

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([{'label': 'Validate Registration Request', 'url': ''}]);
		this.route.params.subscribe(
			params => {
				this.guestInfo['id'] = Number(params['guestID']);
				
				if(this.guestInfo['id'])
					this.getGuestUser(this.guestInfo['id']);
			}
		);
	}

	getGuestUser(id) {
		this.adminService.getGuestUser(id).subscribe(
			data => {
				this.guestInfo = data['guestInfo'];
				this.user = Object.assign({}, this.guestInfo);
				this.user['guestID'] = this.guestInfo['id'];
				this.user['isNewClient'] = false;
				this.roles = data['roles'];
				this.customers = data['customers'];
				this.getMatchedCustomerID();

				if(this.guestInfo['isdealer']) {
					this.user['roleid'] = this.userService.userRoles.dealer;
					this.dealers = data['dealers'] || [];
				} else {
					this.user['roleid'] = this.userService.userRoles.client;
				}
			}
		);
	}

	useText(field, text) {
		this.user[field] = text;
	}

	getUsersByType(roleid) {
		this.user['roleid'] = roleid;
		if(this.userService.userRoles.dealer == roleid) {
			if(this.dealers.length == 0)
				this.getDealers();
		} else if(this.userService.userRoles.oem == roleid) {
			if(this.OEMs.length == 0)
				this.getOEMs();
		} else if(this.userService.userRoles.truckdealer == roleid) {
			if(this.truckDealers.length == 0)
				this.getTruckDealers();
		}
	}

	getDealers() {
		this.adminService.getDealers(0).subscribe(
			data => {
				this.dealers = data['dealers'];
			}
		);
	}

	getTruckDealers() {
		this.adminService.getDealers(0, 'truck_dealer').subscribe(
			data => {
				this.truckDealers = data['dealers'];
			}
		);
	}

	getOEMs() {
		this.adminService.getOEMs(0).subscribe(
			data => {
				this.OEMs = data;
			}
		);
	}

	addGuestAsUser(isRejected = false) {
		var proceed;
		if(isRejected)
			proceed = confirm("Are you sure you want to reject the user?");
		else
			proceed = confirm("Are you sure you want to Add the user?");

		if (proceed) {
			
			this.user['isRejected'] = false;

			if(isRejected)
				this.user['isRejected'] = true;

			this.adminService.addGuestAsUser(this.user).subscribe(
				(data: any) => {
					if(data.httpStatus == 'error') {

						this.notificationMessage.setMessage(data.msg);
					} else {

						this.router.navigate(['/registrations-request']);
					}
				}
			);
		}
	}

	getMatchedCustomerID() {
		this.user['customerid'] = null;
		let company = this.commonUtil.findObjectInArray(this.guestInfo['companyname'], this.customers);
		if(company)
			this.user['customerid'] = company.id;
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}