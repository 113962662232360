<div *ngIf="hasData || pageHasRTrecord" id="batterySummaryPage">
	<div class="row">
		<div class="col-md-12">
			<div id ="battery-summary">
				<app-customized-widget
					id="batterry_battery_summary"
					[title]="'Battery Summary'"
					[widgetId]="'batterry_battery_summary'"
					class="height-auto {{pageIsActive ? '' : 'showOnPrint'}}"
					options="options"
				>
					<div *appTransclude>
						<table class="table table-striped table-condensed table-bordered">
							<tr *ngIf="!isBattviewMobile">
								<td>Battery ID</td>
								<td>{{batterySummary.battery_id}}</td>
								<td>Battery SN</td>
								<td>{{batterySummary.battery_sn}}</td>
							</tr>
							<tr>
								<td>Battery Nominal Voltage</td>
								<td>{{batterySummary.battery_voltage}}</td>
								<td>Battery Capacity - Ahrs</td>
								<td>{{batterySummary.battery_capacity}}</td>
							</tr>
							<tr>
								<td>Truck ID</td>
								<td>{{batterySummary.truckid}}</td>
								<td><span *ngIf="!isBattviewMobile">Model</span><span *ngIf="isBattviewMobile">Truck Type</span></td>
								<td>{{batterySummary.batterymodel}}</td>
							</tr>
							<tr *ngIf="!isBattviewMobile">
								<td>Manufacturing Date</td>
								<td>{{manfacturingDate | date: "shortDate"}} {{batterySummary.extraAgingMessage}}</td>
								<td>Battview SN</td>
								<td>{{batterySummary.serialnumber}}</td>
							</tr>
							<tr *ngIf="!isBattviewMobile">
								<td>Battview Warranty Date</td>
								<td>{{batterySummary.battviewwarrantydate | date: "shortDate"}}</td>
								<td>Installation Date</td>
								<td>{{batterySummary.install_date | date: "shortDate"}}</td>
							</tr>
							<tr *ngIf="isBattviewMobile">
								<td>Study Name</td>
								<td>
									<span *ngIf="!studyNameEditEnabled">
										{{device.studyname}}
										<a *ngIf="userService.hasAccessFunction('act_admin_device_management')" class="pointer" (click)="studyNameEditEnabled = !studyNameEditEnabled;" style="float: right;padding-right: 10px;font-weight: bold;" class="noprint">Edit</a>
									</span>
									<span *ngIf="studyNameEditEnabled">
										<input [(ngModel)]="device.studyname">
										<a (click)="editStudyName()" class="pointer" style="float: right;padding-right: 10px;font-weight: bold;">Done</a>
									</span>
								</td>
								<td>Study Start Date</td>
								<td>{{device.studystartdate | date: "shortDate"}}</td>
							</tr>
							<tr *ngIf="isBattviewMobile">
								<td>Battview Mobile SN</td>
								<td>{{device.serialnumber}}</td>
								<td>Study End Date</td>
								<td>{{device.studyEndDate | date: "shortDate"}}</td>
							</tr>
						</table>
					</div>
				</app-customized-widget>
			</div>
		</div>
		
		<div class="col-md-12" *ngIf="pageHasRTrecord && pageIsActive">
			<div class="panel panel-default">
				<app-customized-widget
					id="batterry_rtRecords"
					[title]="'Real Time Records'"
					[widgetId]="'batterry_rtRecords'"
					class="height-auto"
					[options]="{hasPrint: false}"
				>
					<app-rt-records [hasGrid]="false" [hasTemperature]="false" [device]="device" [isBattviewMobile]="isBattviewMobile" *appTransclude></app-rt-records>
				</app-customized-widget>
			</div>
		</div>
		<div class="col-md-6" *ngIf="!isBattviewMobile && pageIsActive">
			<app-customized-widget
				id="batterry_alerts"
				[title]="'Alerts'"
				[widgetId]="'batterry_alerts'"
				options="options"
			>
				<div *appTransclude>
					<div class="alerts-widget">
						<div class="chart-wrapper">
							<h4>Alerts Summary</h4>
							<div>
								<pie-chart
									[data]="alertsSummaryChart.data"
									[config]="alertsSummaryChart.config"
									elementId="alertsSummaryChart"
								></pie-chart>
							</div>
						</div>
						<div class="chart-wrapper" *ngIf="!hideTemperatureChart">
							<h4>Temperature Dist. (Durations)</h4>
							<pie-chart
								[data]="temperatureDistChart.data"
								[config]="temperatureDistChart.config"
								elementId="temperatureDistChart"
							></pie-chart>
						</div>
					</div>
				</div>
			</app-customized-widget>
		</div>
		<div class="col-md-6" *ngIf="!isBattviewMobile && pageIsActive">
			<app-customized-widget
				id="baterry_performance_metrics"
				[title]="'Battery Performance Metrics'"
				[widgetId]="'baterry_performance_metrics'"
				options="options"
			>
				<table class="table align-middle battery-performance-table" *appTransclude>
					<colgroup>
						<col style="width:200px;"/>
						<col style="width:30px;"/>
						<col  />
						<col style="width:30px;"/>
					</colgroup>
					<tr>
						<td>Battery Utilization</td>
						<td class="text-center">
							{{batterySummary.battery_utilization}}%
						</td>
						<td>
							<div class="bar-cell">
								<div class="pull-left bar-progress bar-progress--full">
									<div class="bar-progress-green" [ngStyle]="{'width': batterySummary.battery_utilization+'%'}" style="background-color: green !important;"></div>
									<div class="bar-progress-red" [ngStyle]="{'width': 100-batterySummary.battery_utilization+'%'}" style="background-color: gray !important;"></div>
								</div>
							</div>
						</td>
						<td class="text-center">
							{{100-batterySummary.battery_utilization}}%
						</td>
					</tr>
					<tr>
						<td>Max Daily EBU's</td>
						<td class="text-center">
							{{batterySummary.max_daily_ebu}}
						</td>
						<td>
							<div class="bar-cell">
								<div class="pull-left bar-progress bar-progress--full">
									<div class="bar-progress-green" [ngStyle]="{'width': batterySummary.max_daily_ebu/3*100+'%'}" style="background-color: red !important;"></div>
								</div>
							</div>
						</td>
						<td class="text-center">
						</td>
					</tr>
					<tr>
						<td>Average Daily EBU's</td>
						<td class="text-center">
							{{batterySummary.avg_daily_ebu}}
						</td>
						<td>
							<div class="bar-cell">
								<div class="pull-left bar-progress bar-progress--full">
									<div class="bar-progress-green" [ngStyle]="{'width': batterySummary.avg_daily_ebu/5*100+'%'}" style="background-color: blue !important;"></div>
								</div>
							</div>
						</td>
						<td class="text-center">
						</td>
					</tr>
					<tr>
						<td class="no-border">
							<label>
							<input type='checkbox' class="form-control" [(ngModel)]="batterySummary.workingDaysOnly" (change)="workingDaysChanged()" /> Working Days Only
							</label>
						</td>
					</tr>
					<tr *ngIf="!batterySummary.hideEBU">
						<td>Battery Management (%&nbsp;Ahrs&nbsp;Returned)</td>
						<td></td>
						<td>
							<div class="bar-cell bar-cell-3">
								<div class="pull-left bar-progress bar-progress--full">
									<div class="bar-progress-green" style="width: 60.6%; background-color: orange !important;">
									</div>
									<div class="bar-progress-mid" style="width: 18.2%; background-color: green !important;"></div>
									<div class="bar-progress-red" style="width: 21.2%; background-color: red !important;"></div>
									<div class="bar-text bar-text-top" style="right: 39.4%">100%</div>
									<div class="bar-text bar-text-top" style="left: 78.8%">130%</div>
									<div class="bar-text bar-text-bottom text-center" [ngStyle]="{'left': batterySummary.ahrs_return_width+'%' }" style="width: 0%">
										<div class="bar-square"></div>
										<span>{{batterySummary.ahrs_return_text}}%</span>
									</div>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>Min Daily Idle Times After Event</td>
						<td class="text-center">
							{{batterySummary.min_charge_oppurtinity_duration}}
						</td>
						<td>
							<div class="bar-cell">
								<div class="pull-left bar-progress bar-progress--full">
									<div class="bar-progress-green" [ngStyle]="{'width': batterySummary.min_charge_oppurtinity_width+'%'}" style="background-color: red !important;"></div>
								</div>
							</div>
						</td>
						<td class="text-center">
						</td>
					</tr>
					<tr ng-if="!isBattviewMobile">
						<td>Remaining Life - Ahrs <span *ngIf="batterySummary.remaining_life_months_per_ahrs > 0">(Estimated)</span></td>
						<td class="text-center">{{batterySummary.remaining_life_ahrs_percent}}%
							<span *ngIf="batterySummary.remaining_life_months_per_ahrs > 0">
								<br />
								{{batterySummary.remaining_life_months_per_ahrs | number:'1.0-0'}} months
							</span>
						</td>
						<td>
							<div class="bar-cell bar-cell-2line">
								<div class="pull-left bar-progress bar-progress--full">
									<div class="bar-progress-green" [ngStyle]="{'width': batterySummary.remaining_life_ahrs_percent+'%'}" style="background-color: green !important;"></div>
									<div class="bar-progress-red" [ngStyle]="{'width': 100-batterySummary.remaining_life_ahrs_percent+'%'}" style="background-color: gray !important;"></div>
								</div>
							</div>
						</td>
						<td class="text-center">{{100-batterySummary.remaining_life_ahrs_percent}}%</td>
					</tr>
					<tr ng-if="!isBattviewMobile">
						<td>Remaining Life - Yrs (Estimated)</td>
						<td class="text-center">
							{{batterySummary.remaining_life_yrs_percent}}%<br />
							{{batterySummary.remaining_life_yrs[1] * 12 | number:'1.0-0'}} months
						</td>
						<td>
							<div class="bar-cell bar-cell-2line">
								<div class="pull-left bar-progress bar-progress--full">
									<div class="bar-progress-green" [ngStyle]="{'width': batterySummary.remaining_life_yrs_percent+'%'}" style="background-color: green !important;"></div>
									<div class="bar-progress-red" [ngStyle]="{'width': 100-batterySummary.remaining_life_yrs_percent+'%'}" style="background-color: gray !important;"></div>
								</div>
							</div>
						</td>
						<td class="text-center">{{100-batterySummary.remaining_life_yrs_percent}}%</td>
					</tr>
				</table>
			</app-customized-widget>
		</div>
		<div class="col-md-6" *ngIf="hasMissedDailyChart && pageIsActive">
			<app-customized-widget
				id="batterry_missedDailyChartAlert"
				title="Total Daily Idle Times"
				[widgetId]="'batterry_missedDailyChartAlert'"
				[customerName]="customerName"
				[siteName]="siteName"
				options="options"
			>
				<div class="alerts-widget" *appTransclude>
					<div class="chart-wrapper">
						<ng-select [items]="idleTypes" name="filter" (ngModelChange)="idleTypeChanged($event, 1)" [(ngModel)]="idleType1" [clearable]="false" class="noprint">
						</ng-select>
						<h3><span class="col-md-4 showOnPrint">{{idleType1}}</span></h3>
						<column-chart
							[data]="avgMissedDailyChart.data"
							[config]="avgMissedDailyChart.config"
							elementId="avgMissedDailyChart"
						></column-chart>
					</div>
					<div class="chart-wrapper">
						<column-chart
							[data]="minMissedDailyChart.data"
							[config]="minMissedDailyChart.config"
							elementId="minMissedDailyChart"
						></column-chart>
					</div>
					<div class="chart-wrapper" *ngIf="isBattviewMobile">
						<column-chart
							[data]="maxMissedDailyChart.data"
							[config]="maxMissedDailyChart.config"
							elementId="maxMissedDailyChart"
						></column-chart>
					</div>
					<div class="chart-wrapper" *ngIf="!isBattviewMobile && minMissedDailyColumnsItems.length > 0">
						<app-grid-v2 #grid id="min-missed-daily" [columns]="minMissedDailyColumns" [data]="minMissedDailyColumnsItems" [settings]= "{
							hasExport: false,
							hasSelectColumn: false,
							hasReset: false,
							showSummary: false,
							height: 185,
							enableFloatingFilter: false
						}"></app-grid-v2>
					</div>
				</div>
			</app-customized-widget>
		</div>
		<div class="col-md-6" *ngIf="hasEBUusageChart && pageIsActive">
			<app-customized-widget
				id="batterry_ebu_daily_usage_summary"
				[title]="'EBU Daily Usage Summary'"
				[widgetId]="'batterry_ebu_daily_usage_summary'"
				options="options"
			>
				<div *appTransclude>
					<column-chart
						[data]="dailyUsageAverageChart.data"
						[config]="dailyUsageAverageChart.config"
						elementId="dailyUsageAverageChart"
					></column-chart>
					<column-chart
						[data]="maxDailyUsageChart.data"
						[config]="maxDailyUsageChart.config"
						elementId="maxDailyUsageChart"
					></column-chart>
				</div>
			</app-customized-widget>
		</div>
		<div class="col-md-6" *ngIf="hasMissedConnectionChart && pageIsActive">
			<app-customized-widget
				id="batterry_missedConnectionChartAlert"
				[title]="'Total Daily Missed Connection Times'"
				[widgetId]="'batterry_missedConnectionChartAlert'"
			>
				<div class="alerts-widget" *appTransclude>
					<div class="chart-wrapper">
						<column-chart
							[data]="avgMissedConnectionChart.data"
							[config]="avgMissedConnectionChart.config"
							elementId="avgMissedConnectionChart"
						></column-chart>
					</div>
					<div class="chart-wrapper">
						<column-chart
							[data]="minMissedConnectionChart.data"
							[config]="minMissedConnectionChart.config"
							elementId="minMissedConnectionChart"
						></column-chart>
						<div *ngIf="minMissedConnectionColumnsItems.length > 0">
							<app-grid-v2 #grid id="total-missed-daily" [columns]="minMissedConnectionColumns" [data]="minMissedConnectionColumnsItems" [settings]= "{
								hasExport: false,
								hasSelectColumn: false,
								hasReset: false,
								showSummary: false,
								height: 185,
								enableFloatingFilter: false
							}"></app-grid-v2>
						</div>
					</div>
					<div class="chart-wrapper" *ngIf="false">
						<column-chart
							[data]="maxMissedConnectionChart.data"
							[config]="maxMissedConnectionChart.config"
							elementId="maxMissedConnectionChart"
						></column-chart>
					</div>
				</div>
			</app-customized-widget>
		</div>
		<div class="col-md-12" *ngIf="hasIdleTimes && isACTuser && pageIsActive">
			<app-customized-widget
				id="battery_idle_times"
				[title]="'Idle Times'"
				[widgetId]="'battery_idle_times'"
				(onPrintClick)="printIdleTimes()"
			>
				<div class="alerts-widget" *appTransclude>
					<ng-select [items]="idleTypes" name="filter" (ngModelChange)="idleTypeChanged($event, 2)" [(ngModel)]="idleType2" [clearable]="false" class="noprint">
					</ng-select>
					<div class="chart-wrapper">
						<column-chart
							[data]="idleTimesChart.data"
							[config]="idleTimesChart.config"
							elementId="idleTimesChart"
						></column-chart>
					</div>
				</div>
			</app-customized-widget>
		</div>
	</div>
</div>