<div class="boxs3">
  <div id="dvChargeSummary">
    <span class="showOnPrint">
      <b>Date Range {{date.fromDate | date:'MM/dd/yyyy'}} - {{date.toDate | date:'MM/dd/yyyy'}}</b>
    </span>
    <table>
      <tr>
        <td>Serial Number</td>
        <td>{{summaryObj.SerialNumber}}</td>
      </tr>
      <tr>
        <td>ID</td>
        <td>{{summaryObj.ModelNumber}}</td>
      </tr>
      <tr>
        <td>Nominal Voltage</td>
        <td>{{summaryObj.NominalVoltage}}</td>
      </tr>
      <tr>
        <td>Capacity AHr</td>
        <td>{{summaryObj.AhrCapacity}}</td>
      </tr>
      <tr>
        <td>Days</td>
        <td>{{summaryObj.totalRecodedDays}}</td>
      </tr>
      <tr>
        <td>Charges</td>
        <td>{{summaryObj.totalCharges}}</td>
      </tr>
      <tr>
        <td>Missed Finish</td>
        <td>{{summaryObj.totalMissedFinishes}}</td>
      </tr>
      <tr>
        <td>Total Charge AHr</td>
        <td>{{summaryObj.totalchargeAHR}}</td>
      </tr>
      <tr>
        <td>Total Inuse AHr</td>
        <td>{{summaryObj.totalDischargeAHR}}</td>
      </tr>
      <tr>
        <td>Max Temperature</td>
        <td>{{summaryObj.maxTemp}}</td>
      </tr>
      <tr>
        <td>Min SOC</td>
        <td>{{summaryObj.minSoc}}</td>
      </tr>
      <tr>
        <td>EQs</td>
        <td>{{summaryObj.totalEQ}}</td>
      </tr>
      <tr>
        <td>EQs With Water Ok</td>
        <td>{{summaryObj.totalEQWaterOK}}</td>
      </tr>
      <tr>
        <td>Weekly Missed EQs</td>
        <td>{{summaryObj.totalMissedEQs}}</td>
      </tr>
      <tr>
        <td>Avg EBU Per Day</td>
        <td>{{summaryObj.AvgEBUPerDay}}</td>
      </tr>
    </table>
  </div>
</div>