import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'underscore';
import { UserService } from '../../../../../../auth/user.service';
import { DecimalPipe } from '@angular/common';
import { CommonUtil } from '../../../../../../shared/services/utility/common.service';
import { BattviewsDashboardService } from '../../battviews-dashboard.service';
import { ColumnChartConfig } from '../../../../../../shared/google-chart/Models/ColumnChartConfig';
import { PieChartConfig } from '../../../../../../shared/google-chart/Models/PieChartConfig';

@Component({
	selector: 'app-battery-summary',
	templateUrl: './battery-summary.component.html',
	styleUrls: ['./battery-summary.component.css']
})
export class BatterySummaryComponent implements OnInit, OnChanges {
	@Input() date: {
		fromDate: any,
		toDate: any
	} = {
		fromDate: new Date(),
		toDate: new Date()
	};
	@Input() dailyDetails: any = {};
	@Input() warrantyDate: any;
	@Input() siteAlertsSettings: any = {};
	@Input() userAlertsSettings: any = {};
	@Input() summaryObject: any = {};
	@Input() device: any = {};
	@Input() isBattviewMobile: boolean = false;
	@Input() isACTuser: boolean = false;
	@Input() hideTemperatureChart: boolean = true;
	@Input() pageHasRTrecord: boolean = false;
	@Input() pageIsActive: boolean = false;
	@Input() customerName: string;
	@Input() siteName: string;

	hasData: boolean = true;
	hasMissedDailyChart: boolean = false;
	hasEBUusageChart: boolean = false;
	hasMissedConnectionChart: boolean = false;
	hasIdleTimes: boolean = false;
	currentUser: any = {};
	batterySummary: any = {};
	manfacturingDate: any;
	batteryManagementAHrReturn: any;
	alertsSummaryChart: {
		config: PieChartConfig,
		data: any[]
	};
	temperatureDistChart: {
		config: PieChartConfig,
		data: any[]
	};
	minMissedDailyChart: {
		config: ColumnChartConfig,
		data: any[]
	};
	maxDailyUsageChart: {
		config: ColumnChartConfig,
		data: any[]
	};
	dailyUsageAverageChart: {
		config: ColumnChartConfig,
		data: any[]
	};
	maxMissedConnectionChart: {
		config: ColumnChartConfig,
		data: any[]
	};
	minMissedConnectionColumnsItems = [];
	minMissedConnectionColumns: any;
	avgMissedConnectionChart: {
		config: ColumnChartConfig,
		data: any[]
	};
	minMissedConnectionChart: {
		config: ColumnChartConfig,
		data: any[]
	};
	maxMissedDailyChart: {
		config: ColumnChartConfig,
		data: any[]
	};
	minMissedDailyColumns: any;
	avgMissedDailyChart: {
		config: ColumnChartConfig,
		data: any[]
	};
	minMissedDailyColumnsItems = [];
	studyNameEditEnabled: boolean = false;
	idleTimesChart: {
		config: ColumnChartConfig,
		data: any[]
	};
	
	idleType1: string = "Idle";
	idleType2: string = "Idle";
	idleTypes: string[] = ["Idle", "Idle After Event"];

	hours	= ["12AM","1AM","2AM","3AM","4AM","5AM","6AM","7AM","8AM","9AM","10AM","11AM","12PM","1PM","2PM","3PM","4PM","5PM","6PM","7PM","8PM","9PM","10PM","11PM"];
	@ViewChild('idleTimesCchart') idleTimesCchart;

	constructor(
		public userService: UserService,
		private decimalPipe: DecimalPipe,
		private commonUtil: CommonUtil,
		private battviewsDashboardService: BattviewsDashboardService
	) {
	}

	ngOnChanges(changes) {
		if(changes.dailyDetails && (!changes.dailyDetails.firstChange || (Object.keys(changes.dailyDetails.currentValue).length > 0 && changes.dailyDetails.currentValue))) {
			this.prepareData();
		}
	}

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();
		this.prepareData();
	}

	printIdleTimes() {
		// Add Battery ID, Truck ID and time stamp to Idle times report print
		var printTableContent = '<div class="showOnPrint">';
		printTableContent += '<h3><span class="col-md-4">Battery ID</span><span class="col-md-4">'+this.device.batteryid+'</span></h3>';
		printTableContent += '<h3><span class="col-md-4">Truck ID</span><span class="col-md-4">'+this.device.truckid+'</span></h3>';
		printTableContent += '<h3><span class="col-md-4">'+this.idleType2+'</span></h3>';
		printTableContent += '<table class="table table-striped table-condensed table-bordered"><thead>';
		printTableContent += '<th>Time</th><th>Value (Minutes)</th>';
		printTableContent += '</thead><tbody>';

		let printList = this.batterySummary.idle_times;
		for (var item of printList) {
			printTableContent += '<tr>';
			printTableContent += '<td>'+item[0]+'</td>';
			printTableContent += '<td>'+item[1]+'</td>';
			printTableContent += '</tr>';
		}
		printTableContent += '</tbody></table>';

		this.commonUtil.print({
			customerName: this.customerName,
			siteName: this.siteName,
			appendPrintContent: printTableContent,
			widgetId: 'battery_idle_times',
		});
	}
	
	idleTypeChanged(value, id) {
		if(id == 1) {
			this.idleType1 = value;
		}
		if(id == 2) {
			this.idleType2 = value;
		}

		this.prepareData();
	}
	
	prepareData() {
		var workingDaysOnly = (this.batterySummary && this.batterySummary.workingDaysOnly) || false;
		let calculatedDailyDetails = this.calculationOnDailyDetails(workingDaysOnly);
		this.batterySummary = this.getData(calculatedDailyDetails);

		this.batterySummary.hideEBU = this.summaryObject.totalchargeAHR < this.device.ahrcapacity || this.summaryObject.totalDischargeAHR < this.device.ahrcapacity;
		this.batterySummary.hideEBU = this.batterySummary.hideEBU || (this.summaryObject.totalDischargeAHR / (0.8 * this.device.ahrcapacity * this.summaryObject.countOfWorkingDays) < 0.25);

		this.batterySummary.truckid = this.device.truckid;

		this.manfacturingDate = this.batterySummary.batterymanfacturingdate;
		if (this.batterySummary.extraAgingMessage.length > 0) {
			this.manfacturingDate = this.batterySummary.install_date;
		}

		this.batterySummary.ahrs_return_text =  Math.round(this.batterySummary.ahrs_return);
		this.batterySummary.ahrs_return_width = this.batterySummary.ahrs_return;
		if(this.batterySummary.ahrs_return_width>165)
			this.batterySummary.ahrs_return_width = 165;
		this.batterySummary.ahrs_return_width /=1.65;
		
		this.batteryManagementAHrReturn = this.batterySummary.ahrs_return;

		var calcProportion = (a, b) => {
			return Math.round(a / (a + b) * 100);
		};
		
		this.batterySummary.remaining_life_ahrs_percent = calcProportion(this.batterySummary.remaining_life_ahrs_percentage[1], this.batterySummary.remaining_life_ahrs_percentage[0]);
		this.batterySummary.remaining_life_months_per_ahrs = this.batterySummary.remaining_life_ahrs_percentage[2];
		this.batterySummary.remaining_life_yrs_percent = calcProportion(this.batterySummary.remaining_life_yrs[1], this.batterySummary.remaining_life_yrs[0]);

		// Max value of minChargeOppurtinityDuration is 8 hours
		if (this.batterySummary.minChargeOppurtinityDuration > 8*60*60 ) {
			this.batterySummary.minChargeOppurtinityDuration = 8*60*60;
		}

		this.batterySummary.min_charge_oppurtinity_width = (this.batterySummary.minChargeOppurtinityDuration/(8*60*60))*100;
		this.batterySummary.min_charge_oppurtinity_duration = this.commonUtil.timeFormat(this.batterySummary.minChargeOppurtinityDuration);
		this.workingDaysChanged();
		this.drawCharts();
	}

	getIdleTimesColors(value) {
		if(value < 5)
			return 'green';
		if(value < 15)
			return 'yellow';
		if(value < 25)
			return 'orange';
		return 'red';
	}
	
	private drawCharts() {
		//alerts
		this.alertsSummaryChart = {
			config: new PieChartConfig({
				title: "Alerts Summary",
				tooltip: {
					textStyle: {
						fontSize: 12
					}
				},
				chartArea: {
					height: 140
				},
				legend: {
					alignment: "center"
				},
				height: 160,
				noColor: true
			}),
			data: [[
				{"label": "Name", "type": "string"},
				{"label": "Value", "type": "number"}
			]]
		};
		let alertsSumCount = 0
		for(let i = 0; i <= 9; i++) {
			alertsSumCount += this.batterySummary.alerts_summary[i];
		}
		if (alertsSumCount === 0) {
			this.alertsSummaryChart.data.push(["No Alerts", 1]);
			this.alertsSummaryChart.config.colors = ['green'];
		} else {
			this.alertsSummaryChart.data.push(["Low Water", this.batterySummary.alerts_summary[0]]);
			this.alertsSummaryChart.data.push(["HT Alerts", this.batterySummary.alerts_summary[1]]);
			this.alertsSummaryChart.data.push(["Weekly Missed EQs", this.batterySummary.alerts_summary[2]]);
			this.alertsSummaryChart.data.push(["EBU Over Usage Alert", this.batterySummary.alerts_summary[3]]);
			this.alertsSummaryChart.data.push(["Missed Finish", this.batterySummary.alerts_summary[4]]);
			this.alertsSummaryChart.data.push(["Potential Weak Cells", this.batterySummary.alerts_summary[5]]);
			this.alertsSummaryChart.data.push(["Deep Discharge", this.batterySummary.alerts_summary[6]]);
			this.alertsSummaryChart.data.push(["Potentially Sulfated Battery Alert", this.batterySummary.alerts_summary[7]]);
			this.alertsSummaryChart.data.push(["Over Charge Alert", this.batterySummary.alerts_summary[8]]);
			this.alertsSummaryChart.data.push(["Cold Temperature Alert", this.batterySummary.alerts_summary[9]]);
			this.alertsSummaryChart.config.pieSliceText = "value";
		}

		this.temperatureDistChart = {
			config: new PieChartConfig({
				title: "Temperature Dist. (Durations)",
				pieSliceText: "percentage",
				tooltip: {
					textStyle: {
						fontSize: 12
					}
				},
				chartArea: {
					height: 140
				},
				legend: {
					alignment: "center"
				},
				height: 160,
				noColor: true,
				userDefinedFormatter: {
					format: function(dt, column) {
						for (var i=0;i<dt.getNumberOfRows();i++) {
							var value = dt.getValue(i, column) / 60;
							var hh:any = Math.floor(value / 60);
							var mm:any = Math.floor(value % 60);
							if (hh < 10) {
								hh = "0" + hh;
							}
							if (mm < 10) {
								mm = "0" + mm;
							}
							var formattedValue = hh + ":" + mm;
							dt.setFormattedValue(i, column, formattedValue);
						}
					}
				}
			}),
			data: [[
				{"label": "Name", "type": "string"},
				{"label": "Value", "type": "number"}
			]]
		};

		this.temperatureDistChart.data = this.temperatureDistChart.data.concat(this.batterySummary.temp_distribation)

		var days	= ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
		var colors	= ['#bd2f26', '#3f69ec'];

		this.hasMissedDailyChart	= false;
		if (this.device.chargertype != 1 || this.isBattviewMobile) {
			this.hasMissedDailyChart	= true;
			// "displayed": false,
			this.minMissedDailyChart = {
				data: [[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'}
				]],
				config: new ColumnChartConfig({
					title: this.isBattviewMobile ? "Minimum Daily Charge Opportunities" : "Minimum Daily Idle Times",
					height: 160,
					yTitle: 'min'
				})
			};

			this.batterySummary.missed_daily_data_min.forEach((item, i)=> {
				this.minMissedDailyChart.data.push([
					{v: item[0]},
					{v: Math.round(item[1])},
					{v: Math.round(item[1])},
					{v: 'color: ' + colors[i % 2]}
				]);
			});

			this.minMissedDailyColumns = [
				{headerName: "Day", field: "day", filter: false},
				{headerName: "Date Of Occurance", field: "date", filter: false}
			];
			
			this.minMissedDailyColumnsItems = [];
			for(var i = 0; i< 7; i++) {
				if (this.batterySummary.missed_daily_data_min_date[i]) {
					this.minMissedDailyColumnsItems.push({
						day:days[i],
						date:this.commonUtil.getDateFormattedFromUnixTimeStamp(this.batterySummary.missed_daily_data_min_date[i], 'date')
					});
				}
			}
			
			this.avgMissedDailyChart = {
				config: new ColumnChartConfig({
					title: "Average Daily Idle Times",
					widgetHeight: 160,
					yTitle: 'min'
				}),
				data: [[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'}
				]]
			}

			this.batterySummary.missed_daily_data_avg.forEach((item, i)=> {
				this.avgMissedDailyChart.data.push([
					{v: item[0]},
					{v: Math.round(item[1])},
					{v: Math.round(item[1])},
					{v: 'color: ' + colors[i % 2]}
				]);
			});

			this.maxMissedDailyChart = {
				config: new ColumnChartConfig({
					title: this.isBattviewMobile ? "Max Daily Charge Opportunities" : "Max Daily Idle Times",
					widgetHeight: 160,
					yTitle: 'min'
				}),
				data: [[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'}
				]]
			};
			this.batterySummary.missed_daily_data_max.forEach((item, i)=> {
				this.maxMissedDailyChart.data.push([
					{v: item[0]},
					{v: Math.round(item[1])},
					{v: Math.round(item[1])},
					{v: 'color: ' + colors[i % 2]}
				]);
			});
			this.hasMissedDailyChart = this.batterySummary.hasMissedDailyChart;
		}

		this.hasMissedConnectionChart	= false;
		if (this.device.chargertype != 1) {
			this.hasMissedConnectionChart = true;
			this.minMissedConnectionChart = {
				config: new ColumnChartConfig({
					title: "Minimum Daily Missed Connection Times",
					widgetHeight: 160,
					yTitle: 'min'
				}),
				data: [[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'}
				]]
			};
			this.batterySummary.missed_connection_data_min.forEach((item, i)=> {
				this.minMissedConnectionChart.data.push([
					{v: item[0]},
					{v: Math.round(item[1])},
					{v: Math.round(item[1])},
					{v: 'color: ' + colors[i % 2]}
				]);
			});

			this.minMissedConnectionColumns = [
				{headerName: "Day", field: "day", filter: false},
				{headerName: "Date Of Occurance", field: "date", filter: false}
			];

			this.minMissedConnectionColumnsItems = [];
			for(var i = 0; i< 7; i++) {
				if (this.batterySummary.missed_connection_data_min_date[i]) {
					this.minMissedConnectionColumnsItems.push({
						day:days[i],
						date:this.commonUtil.getDateFormattedFromUnixTimeStamp(this.batterySummary.missed_connection_data_min_date[i], 'date')
					});
				}
			}

			this.avgMissedConnectionChart = {
				config: new ColumnChartConfig({
					title: "Average Daily Missed Connection Times",
					widgetHeight: 160,
					yTitle: 'min'
				}),
				data: [[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'}
				]]
			};
			this.batterySummary.missed_connection_data_avg.forEach((item, i)=> {
				this.avgMissedConnectionChart.data.push([
					{v: item[0]},
					{v: item[1]},
					{v: item[1]},
					{v: 'color: ' + colors[i % 2]}
				]);
			});

			this.maxMissedConnectionChart = {
				config: new ColumnChartConfig({
					title: "Max Daily Missed Connection Times",
					widgetHeight: 160,
					yTitle: 'min'
				}),
				data: [[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'}
				]]
			};
			this.batterySummary.missed_connection_data_max.forEach((item, i)=> {
				this.maxMissedConnectionChart.data.push([
					{v: item[0]},
					{v: Math.round(item[1])},
					{v: Math.round(item[1])},
					{v: 'color: ' + colors[i % 2]}
				]);
			});

			let missed_connection_data_values = 0;
			this.batterySummary.missed_connection_data_min.forEach(element => {
				missed_connection_data_values += element[1];
			});

			if (missed_connection_data_values == 0) {
				this.hasMissedConnectionChart	= false;
			}
		}
		
		//EBU Daily Usage Summary
		this.hasEBUusageChart = true;
		this.dailyUsageAverageChart = {
			config: new ColumnChartConfig({
				title: "Average Daily EBU Usage",
				widgetHeight: 160
			}),
			data: [[
				{"label": "Name", "type": "string"},
				{"label": "Value", "type": "number"},
				{"type": "string", role: 'annotation'},
				{"type": "string", role: 'style'}
			]]
		};
		this.batterySummary.ebu_daily_usage_summary.forEach((item, i)=> {
			this.dailyUsageAverageChart.data.push([
				{v: item[0]},
				{v: item[1]},
				{v: item[1]},
				{v: 'color: ' + colors[i % 2]}
			]);
		});
		this.maxDailyUsageChart = {
			config: new ColumnChartConfig({
				title: "Max Daily EBU Usage",
				widgetHeight: 160
			}),
			data: [[
				{"label": "Name", "type": "string"},
				{"label": "Value", "type": "number"},
				{"type": "string", role: 'annotation'},
				{"type": "string", role: 'style'}
			]]
		};
		this.batterySummary.max_daily_ebu_usage.forEach((item, i)=> {
			this.maxDailyUsageChart.data.push([
				{v: item[0]},
				{v: item[1]},
				{v: item[1]},
				{v: 'color: ' + colors[i % 2]}
			]);
		});
		this.hasEBUusageChart = this.batterySummary.hasEBUusageChart;

		// Battery analytics idle times
		this.hasIdleTimes = this.batterySummary.total_idle_times > 0;
		let formattedData: any[] = [
			[
				{"label": "Name", "type": "string"},
				{"label": "Value", "type": "number"},
				{"type": "string", role: 'annotation'},
				{"type": "string", role: 'style'},
			]
		];

		for(let i = 0; i < this.batterySummary.idle_times.length; i++) {
			let item: any = this.batterySummary.idle_times[i][1];
			formattedData.push(
				[
					{v: this.hours[i]},
					{v: item},
					{v: item},
					{v: 'color: ' + this.getIdleTimesColors(item / this.batterySummary.idle_times_days)}
				]
			);
		}

		this.idleTimesChart = {
			config: new ColumnChartConfig({
				isPercent: false,
				yTitle: "minutes",
				hAxis: {textStyle : {fontSize: 12}},
				title: "Hourly Idle Times"
			}),
			data: []
		};

		this.idleTimesChart.data = formattedData;
	}

	calculationOnDailyDetails(workingDaysOnly = false) {
		var totalInuseAs            = 0,
			totalInuseAs2           = 0,
			maxDailyInuse           = 0,
			totalChargeAs           = 0,
			totalChargeAs2          = 0,
			totalEBUPerDay          = [0,0,0,0,0,0,0],
			totalEBUCountPerDay     = [0,0,0,0,0,0,0],
			countOfWorkingDays      = 0,
			countOfWorkingDays2     = 0,
			randomMinValue          = 99999,
			randomMaxValue          = -11111,
			totalMissedChargeValues	= [0,0,0,0,0,0,0],
			countofMissedCharge		= [0,0,0,0,0,0,0],
			totalMissedConnectionValues	= [0,0,0,0,0,0,0],
			countOfMissedConnection		= [0,0,0,0,0,0,0],
			missedEqThisWeek        = true,
			thisWeekEqAfterIdleCount	= 0,
			missedFiThisWeek        = true,
			countOfValidDays        = 0,
			waterLevelLowDays		= 0,
			tempWaterLevelAlerts	= 0;
		var tempSulfatedBatteryAlertsCount	= 0,
			dataHasSulfatedBatteryAlerts	= true;

		var calculatedDailyDetails = {
			lowWaterAlert :0,
			highTempAlert :0,
			coldTempAlert :0,
			temperatureDistribation :{},
			batteryUtilization	:0,
			avgDailyEBU			:'0',
			maxDailyEBU			:'0',
			ahrReturn			:0,
			missedEQ			:0,
			missedFi			:0,
			potentialWeakCells	:0,
			deepDischarge		:0,
			overChargeAlerts	:0,
			minChargeOppurtinityDuration: randomMinValue,
			minMissedConnectionDuration: randomMinValue,
			maxEBUDays            :[null,null,null,null,null,null,null],
			avgEBUDays            :['0','0','0','0','0','0','0'],
			minMissedCharge       :[randomMinValue,randomMinValue,randomMinValue,randomMinValue,randomMinValue,randomMinValue,randomMinValue],
			minMissedChargeDate   :[0,0,0,0,0,0,0],
			maxMissedCharge       :[randomMaxValue,randomMaxValue,randomMaxValue,randomMaxValue,randomMaxValue,randomMaxValue,randomMaxValue],
			avgMissedCharge       :[0,0,0,0,0,0,0],
			minMissedConnection       :[randomMinValue,randomMinValue,randomMinValue,randomMinValue,randomMinValue,randomMinValue,randomMinValue],
			minMissedConnectionDate   :[0,0,0,0,0,0,0],
			maxMissedConnection       :[randomMaxValue,randomMaxValue,randomMaxValue,randomMaxValue,randomMaxValue,randomMaxValue,randomMaxValue],
			avgMissedConnection       :[0,0,0,0,0,0,0],
			ebuOverUsage			:0,
			sulfatedBatteryAlerts	:0,
			idleTimes				:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
			idleTimesDays			:0,
			totalIdleTimes			: 0,
            workingDaysOnly			: workingDaysOnly
		};

		var expectedEBU;
		switch (this.device.chargertype) {
			case 0:
				expectedEBU = this.siteAlertsSettings.fast_ebu_limit || 1.6;
				break;
			case 1:
				expectedEBU = this.siteAlertsSettings.conventional_ebu_limit || 1;
				break;
			case 2:
				expectedEBU = this.siteAlertsSettings.opportunity_ebu_limit || 1.25;
				break;
			// case 3:
			// 	expectedEBU = this.siteAlertsSettings.lithium_ebu_limit || 1.65;
			// 	break;
		}
				
		var oneDaySeconds		= 60*60*24,
			endOfCurrentWeek, endOfLastWeek;

		var sortedDailyDetails = [];
		if (this.dailyDetails.length > 0) {
			sortedDailyDetails = this.dailyDetails.sort((a,b) => {return (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0);});
			let endDate = +moment(this.date.toDate).utc().startOf('day').format("X");

			endOfCurrentWeek	= endDate - (7*oneDaySeconds);
			endOfLastWeek		= endOfCurrentWeek;
		}

		var finishRequiredAllDays = false;
		if (Array.isArray(this.device.fidaysmask) && this.device.fidaysmask.length === 7) {
			finishRequiredAllDays = true;
		}

		var temperatureGroupsInF	= ["<-10", "-10-10", "10-30", "30-50", "50-70", "70-90", "90-110", "110-130", "130-150", ">150"];
		var temperatureGroupsInC	= ["<-23", "-23--12", "-12--1", "-1-10", "10-21", "21-32", "32-43", "43-54", "54-66", ">66"];
		var lessThan50ArrayInF		= ['<-10','-10-10','10-30','30-50'];
		var moreThan130ArrayInF		= ['130-150','>150'];
		var lessThan50ArrayInC		= ['<-23','-23--12','-12--1','-1-10'];
		var moreThan130ArrayInC		= ['54-66','>66'];

		if(this.device.temperatureformat == 1) {
			// Fahrenheit
			for(var i in temperatureGroupsInF) {
				calculatedDailyDetails.temperatureDistribation[temperatureGroupsInF[i]] = 0;
			}
		} else {
			//  Celsius
			for(var j in temperatureGroupsInC) {
				calculatedDailyDetails.temperatureDistribation[temperatureGroupsInC[j]] = 0;
			}
		}

		for (var dayIndex in sortedDailyDetails) {
			var currentDay = this.dailyDetails[dayIndex];
			if(currentDay.date < (new Date(this.date.fromDate).getTime() / 1000)  || currentDay.date > (new Date(this.date.toDate).getTime() / 1000)) {
				continue;
			}
			var currentDayOfTheWeek = moment(currentDay.date*1000).utc().day();
			if (currentDay.date <= endOfCurrentWeek) {
				if (endOfLastWeek <= endOfCurrentWeek && countOfValidDays === 0) {
					dataHasSulfatedBatteryAlerts = false;
				}
				if (countOfValidDays > 3) {
					if (missedEqThisWeek === true && thisWeekEqAfterIdleCount != 7) {
						calculatedDailyDetails.missedEQ++;
						if (dataHasSulfatedBatteryAlerts) {
							tempSulfatedBatteryAlertsCount++;
						}
					} else {
						if (tempSulfatedBatteryAlertsCount >= 4) {
							// increase the alerts
							calculatedDailyDetails.sulfatedBatteryAlerts++;
						}
						tempSulfatedBatteryAlertsCount = 0;
					}

					if (missedFiThisWeek === true) {
						calculatedDailyDetails.missedFi++;
					}

					// over charge
					let chargePercentage = -1;
					let forcedOkCharge	= false;
					if (totalInuseAs2 > 0) {
						chargePercentage = (totalChargeAs2 / totalInuseAs2) * 100;
						forcedOkCharge =  (totalInuseAs2 / (3600 * 0.8 * this.device.ahrcapacity * countOfWorkingDays2) < 0.25);
					}

					forcedOkCharge	= forcedOkCharge || ((Math.abs(totalChargeAs2 - totalInuseAs2) / 3600) < this.device.ahrcapacity);

					if (chargePercentage > 130 && !forcedOkCharge) {
						calculatedDailyDetails.overChargeAlerts++;
					}

					totalChargeAs2		= 0;
					totalInuseAs2		= 0;
					countOfWorkingDays2	= 0;
				}

				missedEqThisWeek = true;
				thisWeekEqAfterIdleCount = 0;
				missedFiThisWeek = true;

				countOfValidDays = 0;
				endOfCurrentWeek -= 7 * oneDaySeconds;
			}

			if (typeof(currentDay) !== 'object' || Object.keys(currentDay).length === 0) {
				continue;
			}

			// ignore any day that doesn't have any event
			if (+currentDay.charge_duration + (+currentDay.inuse_duration) + (+currentDay.idle_duration) === 0) {
				continue;
			}

			countOfValidDays++;

			if (currentDay.water_level_low) {
				waterLevelLowDays++;
				tempWaterLevelAlerts++;
			}

			if ((currentDay.count_of_eqs > 0 || currentDay.has_eq_start) && waterLevelLowDays >= this.siteAlertsSettings.water_level_low_days) {
				calculatedDailyDetails.lowWaterAlert += tempWaterLevelAlerts;
				tempWaterLevelAlerts = 0;
			}
			if (!currentDay.water_level_low) {
				waterLevelLowDays = 0;
				tempWaterLevelAlerts = 0;
			}

			if (missedEqThisWeek === true && (currentDay.count_of_eqs > 0 || currentDay.has_eq_start)) {
				missedEqThisWeek = false;
			}

			if(currentDay.eq_after_idle_day) {
				thisWeekEqAfterIdleCount++;
			}

			// if the FI schedule is daily (all days) then the non-working days don't have missed Finish
			if (!finishRequiredAllDays || currentDay.is_working_day) {
				if (missedFiThisWeek === true && !currentDay.missed_fi) {
					missedFiThisWeek = false;
				}
			}
			
			if (currentDay.max_temperature_exceeded > 0) {
				calculatedDailyDetails.highTempAlert += 1;
            }
            
            if(this.isACTuser && currentDay.min_temperature_exceeded > 0) {
                calculatedDailyDetails.coldTempAlert += 1;
            }

			for (var tempGroup in currentDay.max_temperature_groups) {

				var maxTemperatureGroupValue = (+currentDay.max_temperature_groups[tempGroup]);
				if(this.device.temperatureformat != 1) {
					// Celsius
					var fahrenheitIndex = temperatureGroupsInF.indexOf(tempGroup);
					tempGroup = temperatureGroupsInC[fahrenheitIndex];
					if (lessThan50ArrayInC.indexOf(tempGroup) > -1) {
						tempGroup = '<10';
					}

					if (moreThan130ArrayInC.indexOf(tempGroup) > -1) {
						tempGroup = '>54';
					}
				} else {
					if (lessThan50ArrayInF.indexOf(tempGroup) > -1) {
						tempGroup = '<50';
					}

					if (moreThan130ArrayInF.indexOf(tempGroup) > -1) {
						tempGroup = '>130';
					}
				}

				calculatedDailyDetails.temperatureDistribation[tempGroup] += maxTemperatureGroupValue;
			}
			
			let inuseAsValue = currentDay.inuse_as;
			if(this.userAlertsSettings && this.userAlertsSettings.bv_inuse_events_only_for_charge_ahr) {
				inuseAsValue = currentDay.inuse_events_as;
			}

			let chargeAsValue = currentDay.charge_as;
			if(this.userAlertsSettings && this.userAlertsSettings.bv_charge_events_only_for_charge_ahr) {
				chargeAsValue = currentDay.charge_events_as;
			}

			totalChargeAs += chargeAsValue;
			totalChargeAs2 += chargeAsValue;
			totalInuseAs2 += inuseAsValue;

			totalEBUCountPerDay[currentDayOfTheWeek]++;
			totalEBUPerDay[currentDayOfTheWeek] += inuseAsValue;

			if (inuseAsValue > calculatedDailyDetails.maxEBUDays[currentDayOfTheWeek]) {
				calculatedDailyDetails.maxEBUDays[currentDayOfTheWeek] = inuseAsValue;
			}

			if (currentDay.is_working_day || this.isBattviewMobile) {
				totalInuseAs += inuseAsValue;
				var dayOfWeekNum = moment(currentDay.date*1000).utc().day();
				if (!calculatedDailyDetails.workingDaysOnly || this.isWorkingDay(dayOfWeekNum)) {
					countOfWorkingDays++;
					countOfWorkingDays2++;
				}

				if (currentDay.charge_oppurtinity > 0 || this.isBattviewMobile) {
					countofMissedCharge[currentDayOfTheWeek]++;

					var chargeOppurtinityDuration = currentDay.idle_duration;
					if(this.idleType1 != 'Idle') {
						chargeOppurtinityDuration = currentDay.charge_oppurtinity_duration;
					}
					if(this.isBattviewMobile) {
						chargeOppurtinityDuration = ((60*60*24) - currentDay.inuse_duration);
						if (chargeOppurtinityDuration < 0)
							chargeOppurtinityDuration = 0;
					}
					totalMissedChargeValues[currentDayOfTheWeek] += chargeOppurtinityDuration;

					if (chargeOppurtinityDuration < calculatedDailyDetails.minMissedCharge[currentDayOfTheWeek]) {
						calculatedDailyDetails.minMissedCharge[currentDayOfTheWeek] = chargeOppurtinityDuration;
						calculatedDailyDetails.minMissedChargeDate[currentDayOfTheWeek] = +moment(currentDay.date*1000).utc().startOf('day').format("X");
					}
					if (chargeOppurtinityDuration > calculatedDailyDetails.maxMissedCharge[currentDayOfTheWeek]) {
						calculatedDailyDetails.maxMissedCharge[currentDayOfTheWeek] = chargeOppurtinityDuration;
					}
					
					if (currentDay.charge_oppurtinity_duration < calculatedDailyDetails.minChargeOppurtinityDuration) {
						calculatedDailyDetails.minChargeOppurtinityDuration = currentDay.charge_oppurtinity_duration;
					}
				}

				if (currentDay.missed_connection_alert > 0) {
					countOfMissedConnection[currentDayOfTheWeek]++;
					totalMissedConnectionValues[currentDayOfTheWeek] += currentDay.missed_connection_alert_duration;

					if (currentDay.missed_connection_alert_duration < calculatedDailyDetails.minMissedConnectionDuration) {
						calculatedDailyDetails.minMissedConnectionDuration = currentDay.missed_connection_alert_duration;
					}

					if (currentDay.missed_connection_alert_duration < calculatedDailyDetails.minMissedConnection[currentDayOfTheWeek]) {
						calculatedDailyDetails.minMissedConnection[currentDayOfTheWeek] = currentDay.missed_connection_alert_duration;
						calculatedDailyDetails.minMissedConnectionDate[currentDayOfTheWeek] = +moment(currentDay.date*1000).utc().startOf('day').format("X");
					}
					if (currentDay.missed_connection_alert_duration > calculatedDailyDetails.maxMissedConnection[currentDayOfTheWeek]) {
						calculatedDailyDetails.maxMissedConnection[currentDayOfTheWeek] = currentDay.missed_connection_alert_duration;
					}
				}

				var EBU	= inuseAsValue / (3600 * 0.8 * this.device.ahrcapacity);
				if (EBU > expectedEBU && EBU <= 3) {
					calculatedDailyDetails.ebuOverUsage++;
				}

				// Battery analytics idle times
				var hourDistribution = currentDay.non_plugged_in_idle_times;
				if(this.idleType2 != 'Idle') hourDistribution = currentDay.idle_times_distribution
				if(hourDistribution) {
					for (var hour = 0; hour < 24; hour++) {
						calculatedDailyDetails.idleTimes[hour] += hourDistribution[hour];
						calculatedDailyDetails.totalIdleTimes += hourDistribution[hour];
					}
					calculatedDailyDetails.idleTimesDays++;
				}
			}
			
			if (inuseAsValue > maxDailyInuse) {
				maxDailyInuse = inuseAsValue;
			}

			if (currentDay.potential_week_cells_exceeded > 0) {
				calculatedDailyDetails.potentialWeakCells++;
			}

			if (currentDay.deep_discharge_exceeded > 0) {
				calculatedDailyDetails.deepDischarge++;
			}
		}

		if (calculatedDailyDetails.highTempAlert < this.siteAlertsSettings.high_temperature_limit_days) {
			calculatedDailyDetails.highTempAlert = 0;
		}
		if (calculatedDailyDetails.potentialWeakCells < this.siteAlertsSettings.potential_weak_cells_limit_days) {
			calculatedDailyDetails.potentialWeakCells = 0;
		}
		if (calculatedDailyDetails.deepDischarge < this.siteAlertsSettings.deep_discharge_limit_days) {
			calculatedDailyDetails.deepDischarge = 0;
		}

		if (countOfValidDays > 3) {
			if (missedEqThisWeek === true && thisWeekEqAfterIdleCount != 7) {
				calculatedDailyDetails.missedEQ++;
				if (dataHasSulfatedBatteryAlerts) {
					tempSulfatedBatteryAlertsCount++;
				}
			}

			if (missedFiThisWeek === true) {
				calculatedDailyDetails.missedFi++;
			}

			// over charge
			let chargePercentage = -1;
			let forcedOkCharge	= false;
			if (totalInuseAs2 > 0) {
				chargePercentage = (totalChargeAs2 / totalInuseAs2) * 100;
				forcedOkCharge =  (totalInuseAs2 / (3600 * 0.8 * this.device.ahrcapacity * countOfWorkingDays2) < 0.25);
			}

			forcedOkCharge	= forcedOkCharge || ((Math.abs(totalChargeAs2 - totalInuseAs2) / 3600) < this.device.ahrcapacity);

			if (chargePercentage > 130 && !forcedOkCharge) {
				calculatedDailyDetails.overChargeAlerts++;
			}
		}

		if (tempSulfatedBatteryAlertsCount >= 4) {
			// increase the alerts
			calculatedDailyDetails.sulfatedBatteryAlerts++;
		}

		if (countOfWorkingDays > 0) {
			if (totalInuseAs > 0) {
				
				var averageInuseAhr = (totalInuseAs / countOfWorkingDays) / 3600;
				var averageInuseAhrOverEBU = averageInuseAhr / (0.8 * this.device.ahrcapacity);

				calculatedDailyDetails.batteryUtilization = +((averageInuseAhrOverEBU / expectedEBU) * 100).toFixed(0);

				if (calculatedDailyDetails.batteryUtilization > 100) {
					calculatedDailyDetails.batteryUtilization = 100;
				}
				if (averageInuseAhrOverEBU > 3) {
					averageInuseAhrOverEBU = 3;
				}
				calculatedDailyDetails.avgDailyEBU = this.decimalPipe.transform((averageInuseAhrOverEBU).toFixed(2));
			}
		}

		if (totalInuseAs != 0) {
			calculatedDailyDetails.ahrReturn = totalChargeAs / totalInuseAs;
		}

		let max_daily_ebu = maxDailyInuse/ (3600 * 0.8 * this.device.ahrcapacity);
		if (max_daily_ebu > 3) {
			max_daily_ebu = 3;
		}
		calculatedDailyDetails.maxDailyEBU = this.decimalPipe.transform((max_daily_ebu).toFixed(2));

		for (var dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
			if (totalEBUCountPerDay[dayOfWeek] != 0) {
				calculatedDailyDetails.avgEBUDays[dayOfWeek] = this.decimalPipe.transform(totalEBUPerDay[dayOfWeek] / (totalEBUCountPerDay[dayOfWeek] * 3600 * 0.8 * this.device.ahrcapacity), '1.2-2');
			}

			calculatedDailyDetails.maxEBUDays[dayOfWeek] = (calculatedDailyDetails.maxEBUDays[dayOfWeek] / (3600 * 0.8 * this.device.ahrcapacity)).toFixed(2);

			if (calculatedDailyDetails.minMissedCharge[dayOfWeek] === randomMinValue) {
				calculatedDailyDetails.minMissedCharge[dayOfWeek] = 0;
				calculatedDailyDetails.minMissedChargeDate[dayOfWeek] = null;
			} else {
				calculatedDailyDetails.minMissedCharge[dayOfWeek] = calculatedDailyDetails.minMissedCharge[dayOfWeek] / 60;
			}
			if (calculatedDailyDetails.maxMissedCharge[dayOfWeek] === randomMaxValue) {
				calculatedDailyDetails.maxMissedCharge[dayOfWeek] = 0;
			} else {
				calculatedDailyDetails.maxMissedCharge[dayOfWeek] = calculatedDailyDetails.maxMissedCharge[dayOfWeek] / 60;
			}

			if (countofMissedCharge[dayOfWeek] > 0) {
				calculatedDailyDetails.avgMissedCharge[dayOfWeek] = (totalMissedChargeValues[dayOfWeek] / countofMissedCharge[dayOfWeek]) / 60;
			}

			if (calculatedDailyDetails.minMissedConnection[dayOfWeek] === randomMinValue) {
				calculatedDailyDetails.minMissedConnection[dayOfWeek] = 0;
				calculatedDailyDetails.minMissedConnectionDate[dayOfWeek] = null;
			} else {
				calculatedDailyDetails.minMissedConnection[dayOfWeek] = Math.round(calculatedDailyDetails.minMissedConnection[dayOfWeek] / 60);
			}
			if (calculatedDailyDetails.maxMissedConnection[dayOfWeek] === randomMaxValue) {
				calculatedDailyDetails.maxMissedConnection[dayOfWeek] = 0;
			} else {
				calculatedDailyDetails.maxMissedConnection[dayOfWeek] = Math.round(calculatedDailyDetails.maxMissedConnection[dayOfWeek] / 60);
			}

			if (countOfMissedConnection[dayOfWeek] > 0) {
				calculatedDailyDetails.avgMissedConnection[dayOfWeek] = Math.round((totalMissedConnectionValues[dayOfWeek] / countOfMissedConnection[dayOfWeek]) / 60);
			}
		}

		// Shift EBU arrays to start with Monday instead of Sunday
		var sundayEBUMax = calculatedDailyDetails.maxEBUDays.shift();
		calculatedDailyDetails.maxEBUDays[6] = sundayEBUMax;

		var sundayEBUAvg = calculatedDailyDetails.avgEBUDays.shift();
		calculatedDailyDetails.avgEBUDays[6] = sundayEBUAvg;

		var sundayMinMissedCharge = calculatedDailyDetails.minMissedCharge.shift();
		calculatedDailyDetails.minMissedCharge[6] = sundayMinMissedCharge;
		var sundayMinMissedChargeDate = calculatedDailyDetails.minMissedChargeDate.shift();
		calculatedDailyDetails.minMissedChargeDate[6] = sundayMinMissedChargeDate;

		var sundayAvgMissedCharge = calculatedDailyDetails.avgMissedCharge.shift();
		calculatedDailyDetails.avgMissedCharge[6] = sundayAvgMissedCharge;

		var sundayMaxMissedCharge = calculatedDailyDetails.maxMissedCharge.shift();
		calculatedDailyDetails.maxMissedCharge[6] = sundayMaxMissedCharge;

		if (calculatedDailyDetails.minChargeOppurtinityDuration == randomMinValue) {
			calculatedDailyDetails.minChargeOppurtinityDuration = 0;
		}

		var sundayMinMissedConnection = calculatedDailyDetails.minMissedConnection.shift();
		calculatedDailyDetails.minMissedConnection[6] = sundayMinMissedConnection;
		var sundayMinMissedConnectionDate = calculatedDailyDetails.minMissedConnectionDate.shift();
		calculatedDailyDetails.minMissedConnectionDate[6] = sundayMinMissedConnectionDate;

		var sundayAvgMissedConnection = calculatedDailyDetails.avgMissedConnection.shift();
		calculatedDailyDetails.avgMissedConnection[6] = sundayAvgMissedConnection;

		var sundayMaxMissedConnection = calculatedDailyDetails.maxMissedConnection.shift();
		calculatedDailyDetails.maxMissedConnection[6] = sundayMaxMissedConnection;

		if (calculatedDailyDetails.minMissedConnectionDuration == randomMinValue) {
			calculatedDailyDetails.minMissedConnectionDuration = 0;
		}

		return calculatedDailyDetails;
	}

	isWorkingDay(dayOfWeekNum) {
		return dayOfWeekNum > 0 && dayOfWeekNum < 6;
	}

	getData(batterySummaryObj) {

		var temperatureDistribation = [];
		for (var temperatureGroup in batterySummaryObj.temperatureDistribation) {
			temperatureDistribation.push([temperatureGroup, batterySummaryObj.temperatureDistribation[temperatureGroup]]);
		}

		var totalAhrs	= ((+this.device.warrantedahr) === 0) ? 1250 * (+this.device.ahrcapacity) : (+this.device.warrantedahr),
			usedAhrs	= +this.device.inuseahr + (+this.device.add_inuseahr);

		if(usedAhrs > totalAhrs)
			usedAhrs = totalAhrs;

		const warrantyDate = this.warrantyDate || this.device.battviewwarrantydate;

		var totalLifeYears          = 5,
			currentDay              = new Date(),
			installationDate        = new Date(this.device.installationdate),
			manfacturingDate        = new Date(this.device.batterymanfacturingdate),
			battviewWarrantyDate	= new Date(warrantyDate),
			extraAgingMessage		= '',
			usedLifeYears,
			monthsSinceInstallation,
			remaingMonthsPerUsage	= 0;

		// since it may affected by timezone I'll change 0 to 1 day
		if (manfacturingDate.getTime() > 1*24*60*60*1000) {
			monthsSinceInstallation = this.commonUtil.getElapsedMonths(manfacturingDate, currentDay);
		} else {
			monthsSinceInstallation = this.commonUtil.getElapsedMonths(installationDate, currentDay);
		}

		if ((+this.device.chargertype) === 0)
			totalLifeYears = 4;

		let maxRemainingMonths = totalLifeYears * 12;
		var batteryUsagePerMonth = 0;

		// since it may affected by timezone I'll change 0 to 1 day
		if (manfacturingDate.getTime() > 1*24*60*60*1000) {
			usedLifeYears = parseFloat((this.commonUtil.monthDiff(manfacturingDate, currentDay) / 12).toFixed(1));
			
			if (manfacturingDate < installationDate) {
				var duration = moment.duration(installationDate.getTime() - manfacturingDate.getTime());
				usedAhrs	+= duration.asYears() * batteryUsagePerMonth * 12;
				if (usedAhrs > totalAhrs) {
					usedAhrs = totalAhrs;
				}
			}
		} else {
			usedLifeYears = parseFloat((this.commonUtil.monthDiff(installationDate, currentDay) / 12).toFixed(1));
			extraAgingMessage = '(same as Installation Date)';
		}

		if (monthsSinceInstallation > 0) {
			batteryUsagePerMonth = usedAhrs / monthsSinceInstallation;
			remaingMonthsPerUsage = (totalAhrs - usedAhrs) / batteryUsagePerMonth;
		}
		if (remaingMonthsPerUsage < 0) 
			remaingMonthsPerUsage = 0;

		if(remaingMonthsPerUsage > maxRemainingMonths) {
			remaingMonthsPerUsage = maxRemainingMonths;
		}
		if (usedLifeYears > totalLifeYears) {
			usedLifeYears = totalLifeYears;
		}

		var days	= ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
		let missed_daily_data_min = [];
		for(let i in batterySummaryObj.minMissedCharge) {
			missed_daily_data_min.push([days[i], batterySummaryObj.minMissedCharge[i]]);
		}
		let missed_daily_data_avg = [];
		for(let i in batterySummaryObj.avgMissedCharge) {
			missed_daily_data_avg.push([days[i], batterySummaryObj.avgMissedCharge[i]]);
		}
		let missed_daily_data_max = [];
		let hasMissedDailyChart = false;
		for(let i in batterySummaryObj.maxMissedCharge) {
			if(batterySummaryObj.maxMissedCharge[i] > 0) {
				hasMissedDailyChart = true;
			}
			missed_daily_data_max.push([days[i], batterySummaryObj.maxMissedCharge[i]]);
		}
		let max_daily_ebu_usage = [];
		let hasEBUusageChart = false;
		for(let i in batterySummaryObj.maxEBUDays) {
			if(+batterySummaryObj.maxEBUDays[i] > 0) {
				hasEBUusageChart = true;
			}
			max_daily_ebu_usage.push([days[i], +batterySummaryObj.maxEBUDays[i]]);
		}
		let ebu_daily_usage_summary = [];
		for(let i in batterySummaryObj.avgEBUDays) {
			ebu_daily_usage_summary.push([days[i], batterySummaryObj.avgEBUDays[i]]);
		}
		let missed_connection_data_max = [];
		for(let i in batterySummaryObj.maxMissedConnection) {
			missed_connection_data_max.push([days[i], +batterySummaryObj.maxMissedConnection[i]]);
		}
		let missed_connection_data_min = [];
		for(let i in batterySummaryObj.minMissedConnection) {
			missed_connection_data_min.push([days[i], +batterySummaryObj.minMissedConnection[i]]);
		}
		let missed_connection_data_avg = [];
		for(let i in batterySummaryObj.avgMissedConnection) {
			missed_connection_data_avg.push([days[i], +batterySummaryObj.avgMissedConnection[i]]);
		}
		let idle_times = [];
		for(let i in batterySummaryObj.idleTimes) {
			idle_times.push([this.hours[i], +batterySummaryObj.idleTimes[i]]);
		}

		var data = {
			//main
			battery_id: this.device.batteryid,
			battery_voltage: this.device.nominalvoltage,
			battery_sn: this.device.batterysn,
			battery_capacity: this.device.ahrcapacity,
			install_date: this.device.installationdate,

			//battery performance metrics widget
			battery_utilization: batterySummaryObj.batteryUtilization,
			max_daily_ebu: batterySummaryObj.maxDailyEBU,
			avg_daily_ebu: batterySummaryObj.avgDailyEBU,
			ahrs_return: batterySummaryObj.ahrReturn*100,
			minChargeOppurtinityDuration:batterySummaryObj.minChargeOppurtinityDuration,
			remaining_life_ahrs_percentage: [usedAhrs, totalAhrs - usedAhrs, remaingMonthsPerUsage],
			remaining_life_yrs: [usedLifeYears, totalLifeYears - usedLifeYears],

			//Ebu widget
			ebu_daily_usage_summary: ebu_daily_usage_summary,
			max_daily_ebu_usage: max_daily_ebu_usage,
			hasEBUusageChart: hasEBUusageChart,

			// idle times widget
			idle_times: idle_times,
			idle_times_days: batterySummaryObj.idleTimesDays,
			total_idle_times: batterySummaryObj.totalIdleTimes,

			//alerts widget
			alerts_summary: [
				batterySummaryObj.lowWaterAlert,
				batterySummaryObj.highTempAlert,
				batterySummaryObj.missedEQ,
				batterySummaryObj.ebuOverUsage,
				batterySummaryObj.missedFi,
				batterySummaryObj.potentialWeakCells,
				batterySummaryObj.deepDischarge,
				batterySummaryObj.sulfatedBatteryAlerts,
				batterySummaryObj.overChargeAlerts,
				batterySummaryObj.coldTempAlert,
			],
			temp_distribation: temperatureDistribation,
			missed_daily_data_min: missed_daily_data_min,
			missed_daily_data_min_date: batterySummaryObj.minMissedChargeDate,
			missed_daily_data_avg: missed_daily_data_avg,
			missed_daily_data_max: missed_daily_data_max,
			hasMissedDailyChart: hasMissedDailyChart,
			missed_connection_data_min: missed_connection_data_min,
			missed_connection_data_min_date: batterySummaryObj.minMissedConnectionDate,
			missed_connection_data_avg: missed_connection_data_avg,
			missed_connection_data_max: missed_connection_data_max,
			batterymodel: this.device.batterymodel,
			serialnumber:this.device.serialnumber,
			batterymanfacturingdate: manfacturingDate,
			battviewwarrantydate: battviewWarrantyDate,
			extraAgingMessage: extraAgingMessage,
			workingDaysOnly: batterySummaryObj.workingDaysOnly,
			totalLifeYears: totalLifeYears
		};
		return data;
	}

	editStudyName() {
		var updateObject = {
			id:this.device.id,
			studyId:this.device.studyid,
			studyname:this.device.studyname
		};
	
		this.battviewsDashboardService.updateStudy(updateObject).subscribe((response) => {
			this.studyNameEditEnabled = false;
		});
	}

	workingDaysChanged() {
		var totalInuseAs            = 0,
			countOfWorkingDays      = 0,
			avgDailyEBU				= null;

		for (let currentDay of this.dailyDetails) {
			if (!this.batterySummary.workingDaysOnly || currentDay.is_working_day) {
				let inuseAsValue = currentDay.inuse_as;
				if(this.userAlertsSettings && this.userAlertsSettings.bv_inuse_events_only_for_charge_ahr) {
					inuseAsValue = currentDay.inuse_events_as;
				}
				totalInuseAs += inuseAsValue;
				countOfWorkingDays++;
			}
		}

		if (countOfWorkingDays > 0) {
			if (totalInuseAs > 0) {
				var averageInuseAhr = (totalInuseAs / countOfWorkingDays) / 3600;
				var averageInuseAhrOverEBU = averageInuseAhr / (0.8 * this.device.ahrcapacity);

				avgDailyEBU = averageInuseAhrOverEBU;
				if (avgDailyEBU > 3) {
					avgDailyEBU = 3;
				}
				avgDailyEBU = this.decimalPipe.transform((averageInuseAhrOverEBU).toFixed(2));
			}
		}

		this.batterySummary.avg_daily_ebu = avgDailyEBU;
	}
	onIdleTimesChartReady($event) {
		let googleChartWrapper = this.idleTimesCchart.wrapper;
	}
}