<button class="btn btn-default" [ngClass]="{'mb20': hasBottomMargin}" (click)="goToManageDevicePage()" [disabled]="selectedDevices.length == 0" *ngIf="!currentUser.isTruckDealer"> 
	<span *ngIf="twoLines; else oneLine">
		{{btnText[0] | translate}} <br/> {{btnText[1] | translate}}
	</span>
	<ng-template #oneLine>
		{{btnText[0] | translate}} {{btnText[1] | translate}}
	</ng-template>
</button>

<button class="btn btn-default mb20" (click)="goToManageDatesDevicePage()" [disabled]="selectedDevices.length == 0" *ngIf="manageDatesAccess"> 
	<span *ngIf="twoLines; else oneLine">
		{{btnDatesText[0] | translate}} <br/> {{btnDatesText[1] | translate}}<br/> {{btnDatesText[2] | translate}}
	</span>
	<ng-template #oneLine>
		{{btnDatesText[0]| translate}} {{btnDatesText[1]| translate}} {{btnDatesText[2] | translate}}
	</ng-template>
</button>
