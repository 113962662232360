import { Component, OnInit, OnDestroy } from '@angular/core';
import { DeviceManagementService } from '../../device-management.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sites-without-owners',
  templateUrl: './sites-without-owners.component.html',
  styleUrls: ['./sites-without-owners.component.css']
})
export class SitesWithoutOwnersComponent implements OnInit, OnDestroy {

  sitesInfo: {time: number, sites: any[]};
  constructor(
    private deviceManagemntService: DeviceManagementService,
	private breadcrumb: BreadcrumbService,
	private translateService: TranslateService
  ) {
    this.breadcrumb.setBreadcrumb([
      {label: this.translateService.instant('site.sites_without_owners'), url: ''}
    ]);
  }

  ngOnInit() {
    this.deviceManagemntService.getSitesWithoutOwners().subscribe(
      (response: {time: number, sites: any}) => {
        this.sitesInfo = response;
      }
    );
  }

  ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
