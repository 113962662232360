import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit, OnDestroy, OnChanges } from '@angular/core';
import {Subscription} from 'rxjs';

import { ComboChartConfig } from './../Models/ComboChartConfig';
import { GoogleComboChartService } from './../Services/google-combo-chart.service';

declare var google: any;

@Component({
	selector: 'combo-chart',
	template: '<div id="{{elementId}}"></div>'
})
export class ComboChartComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

	@Input() data: any;
	@Input() config: ComboChartConfig;
	@Input() elementId: String;
	@Output() onSelectItem = new EventEmitter<any>(true);
	@Output() onChartReady = new EventEmitter<any>(true);

	subscription:Subscription;
	readySubscription: Subscription;

	private _comboChartService =  new GoogleComboChartService();

	constructor() {}

	ngOnInit(): void {
		this.buildChart();
	}

	ngAfterViewInit() {
		this.subscription = this._comboChartService.selectItem.subscribe(
			item => {
				this.onSelectItem.emit(item);
			}
		);

		this.readySubscription = this._comboChartService.chartReady.subscribe(
			chart => {
				this.onChartReady.emit({elementId: this.elementId, chart: chart});
			}
		);
	}

	buildChart() {
		if(this.data) {
			this._comboChartService.BuildComboChart(this.elementId, this.data, this.config || new ComboChartConfig());
		}
	}
	ngOnChanges(changes) {
		this.buildChart();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.readySubscription.unsubscribe();
	}
}