import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdminService } from '../../admin.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-sched-monitor',
	templateUrl: './sched-monitor.component.html',
	styleUrls: ['./sched-monitor.component.css']
})
export class SchedMonitorComponent implements OnInit, OnDestroy {

	schedFunctions = <any>[];

	constructor(
		private adminService: AdminService,
		private breadcrumb: BreadcrumbService,
		private router: Router
	) {
	}

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([{'label': 'Sched Monitor', 'url': ''}]);
		this.adminService.getCronJobsStatus().subscribe(
			data => {
				this.schedFunctions = data;
			}
		);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
