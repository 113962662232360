import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../../../../shared/breadcrumb/breadcrumb.service';
import { DeviceManagementService } from '../../../device-management.service';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { AdminService } from '../../../../admin/admin.service';
import { NotificationMessageService } from '../../../../shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-equipment-edit',
	templateUrl: './equipment-edit.component.html',
	styleUrls: ['./equipment-edit.component.css']
})
export class EquipmentEditComponent implements OnInit, OnDestroy {

	equipment = {
		name: null,
		description: null,
		parent_device_id: null,
		link_to: null
	};
	equipmentsParents: any[] = [];
	linkedToTypes: {id: string, name: string}[];
	equipmentId: number;
	constructor(
		private route: ActivatedRoute,
		private breadcrumb: BreadcrumbService,
		private deviceManagemntService: DeviceManagementService,
		private adminService: AdminService,
		public navigation: NavigationService,
		private router: Router,
		private notificationMessage: NotificationMessageService,
		private translateService: TranslateService
	) {
		let currentUrl = this.router.url;
		if (currentUrl.substr(-1) != '/') currentUrl += '/';
		this.breadcrumb.setBreadcrumb([
			{label: this.translateService.instant('site.equipments'), url: '/equipments'},
			{label: this.translateService.instant('g.edit'), url: ''}
		]);
	}

	ngOnInit() {
		this.linkedToTypes = [
			{id: 'site', name: this.translateService.instant('g.site')},
			{id: 'dealer', name: this.translateService.instant('g.dealer')},
			{id: 'truck-dealer', name: this.translateService.instant('g.truck_dealer')},
			{id: 'oem', name: this.translateService.instant('site.oem')},
			{id: 'customer', name: this.translateService.instant('g.customer')}
		];
		this.equipmentId = +(this.route.snapshot.params.id);
		this.equipmentsParents = [];
		this.getEquipments();
	}
	getEquipments() {
		this.adminService.getEquipments(this.equipmentId).subscribe(
			response => {
				if(this.equipmentId > 0) {
					this.equipment = response['equipment'][this.equipmentId];
				}
				this.equipmentsParents = response['parents'];
			}
		);
	}
	
	updateEquipment() {
		this.equipment.name = this.equipment.name.trim();
		this.equipment.description = this.equipment.description.trim();
		if(!this.equipment.name || !this.equipment.description) {
			this.notificationMessage.setMessage(this.translateService.instant('g.please_fill_in_all_required_fields'));
			return;
		}

		this.deviceManagemntService.updateEquipment(this.equipment).subscribe(
			result => {
				this.notificationMessage.setMessage('globalSuccessMsg');
				this.router.navigate(['/equipments']);
			}
		);
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
