import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import { DeviceManagementService } from '../../device-management.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { AdminService } from '../../../admin/admin.service';
import { UserService } from '../../../auth/user.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { User } from '../../../auth/user.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-device-by-po',
  templateUrl: './device-by-po.component.html',
  styleUrls: ['./device-by-po.component.css']
})
export class DeviceByPoComponent implements OnInit, OnDestroy {

	user: User = new User();
	devicesInfo  = [];
	isPartial: boolean = false;
	purchaseOrderNumber: string = "";
	noResult: boolean = null;
	type = 'charger';
  
  constructor(
  	private deviceManagemntService: DeviceManagementService,
		private breadcrumb: BreadcrumbService,
		private router: Router,
		public commonUtil: CommonUtil,
		private adminService: AdminService,
		public userService: UserService,
		private notificationMessage: NotificationMessageService,
		private translateService: TranslateService
	) { }

  ngOnInit() {
  	this.breadcrumb.setBreadcrumb([
			{'label': this.translateService.instant('device.search_device_by_purchase_order'), 'url': ''}
		]);
	this.userService.currentUser.subscribe((user: User) => {
		this.user = user;
		if (!this.user.isACTuser) {
			this.router.navigate(['/unauthorized']);
		}
	});
  }


  getDeviceByPO() {
  	this.devicesInfo = [];
		this.noResult = false;
		this.purchaseOrderNumber = this.purchaseOrderNumber.trim();
		if (!this.purchaseOrderNumber) {
			this.notificationMessage.setMessage(this.translateService.instant('device.please_insert_purchase_order'), 'danger', 60000);
			return
		} 

		let options = {partial: this.isPartial};
		this.deviceManagemntService.getDeviceByPO(this.purchaseOrderNumber, options).subscribe(
			(devicesInfo: any) => {
				if(devicesInfo.length === 0) {
					this.noResult = true;
				} else {
					this.noResult = false;
					this.devicesInfo = devicesInfo;
					this.devicesInfo.forEach((deviceInfo) => {
						deviceInfo.serialnumber = this.commonUtil.formatSerialNumber(deviceInfo);
					});
				}
			}
		);
  }

  	goToEditDevice(deviceInfo) {
		let link = this.deviceManagemntService.getEditDeviceLink(this.type, deviceInfo);
		this.router.navigate(link.url, {queryParams: link.params});
	}

	deleteDevice(id) {
		let deviceText = this.translateService.instant('device.charger');

		if(confirm(this.translateService.instant('device.delete_device_prompt', {deviceType: deviceText}))) {
			this.adminService.deleteDevice(id, this.type).subscribe(
				data => this.getDeviceByPO()
			);
		}
	}


  ngOnDestroy() {
		this.breadcrumb.hide();
	}

}
