import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth-guard.service';
import { EquipmentsComponent } from './devices/equipments/equipments/equipments.component';
import { EquipmentEditComponent } from './devices/equipments/equipment-edit/equipment-edit.component';
import { ChargersComponent } from '../admin/devices/chargers/chargers.component';
import { BattviewsComponent } from '../admin/devices/battviews/battviews.component';
import { SupportLogsComponent } from './support-logs/support-logs.component';
import { DaughterCardComponent } from './search/daughter-card/daughter-card.component';
import { CommissionDaughterCardComponent } from './commission-daughter-card/commission-daughter-card.component';
import { DeviceByIpComponent } from './search/device-by-ip/device-by-ip.component';
import { DeviceByIdComponent } from './search/device-by-id/device-by-id.component';
import { AutoconnectDevicesComponent } from './connectivity/autoconnect-devices/autoconnect-devices.component';
import { DevicesConnectivityComponent } from './connectivity/devices-connectivity/devices-connectivity.component';
import { SitesConnectivityComponent } from './connectivity/sites-connectivity/sites-connectivity.component';
import { PendingApprovalRequestsComponent } from './pending-approval-requests/pending-approval-requests.component';
import { DeviceBySnComponent } from './search/device-by-sn/device-by-sn.component';
import { DealersOemsHierarchyComponent } from './site-management/dealers-oems-hierarchy/dealers-oems-hierarchy.component';
import { SitesWithoutOwnersComponent } from './site-management/sites-without-owners/sites-without-owners.component';
import { UserReportComponent } from './reporting/user-report/user-report.component';
import { BattviewReportComponent } from './reporting/battview-report/battview-report.component';
import { ChargerReportComponent } from './reporting/charger-report/charger-report.component';
import { AllocateProductionLineComponent } from './production-line/allocate-production-line/allocate-production-line.component';
import { ProcessProductionLineComponent } from './production-line/process-production-line/process-production-line.component';
import { ProductionLineIpsComponent } from './production-line/production-line-ips/production-line-ips.component';
import { ReplacementDevicesListingComponent } from './devices/replacement-devices-listing/replacement-devices-listing.component';
import { ReplacementDeviceComponent } from './devices/replacement-device/replacement-device.component';
import { ServicePageComponent } from './service-page/service-page.component';
import { BattviewsFunctionalTestComponent } from './battviews-functional-test/battviews-functional-test.component';
import { SitesInvoicesReportingComponent } from './sites-invoices-reporting/sites-invoices-reporting.component';
import { SiteInvoicesActivationReminderComponent } from './site-invoices-activation-reminder/site-invoices-activation-reminder.component';
import { DealerDeviceBySnComponent } from './search/dealer-device-by-sn/dealer-device-by-sn.component';
import { PmBySnComponent } from './search/pm-by-sn/pm-by-sn.component';
import { BlockedFwReportsComponent } from './blocked-fw-reports/blocked-fw-reports.component';
import { DeviceByPoComponent } from './search/device-by-po/device-by-po.component';
import { VoltusDispatchesLogComponent } from './voltus-dispatches-log/voltus-dispatches-log.component';
import { PMReportComponent } from './pm-report/pm-report.component';
import { ReportDownloadComponent } from './report-download/report-download.component';
import { ProductionPerformanceComponent } from './production-line/production-performance/production-performance.component';


const deviceManagementRoutes: Routes = [
	{ path: 'equipments', component: EquipmentsComponent, canActivate: [AuthGuard] },
	{ path: 'equipments/edit/equipmentId/:id', component: EquipmentEditComponent, canActivate: [AuthGuard] },
	{ path: 'support-logs/:type', component: SupportLogsComponent, canActivate: [AuthGuard] },
	{ path: 'pending-approval-requests/:tab/:subTab/:deviceID', component: PendingApprovalRequestsComponent, canActivate: [AuthGuard] },
	{ path: 'pending-approval-requests/:tab/:subTab', component: PendingApprovalRequestsComponent, canActivate: [AuthGuard] },
	{ path: 'unassigned-chargers', component: ChargersComponent, canActivate: [AuthGuard], data: {pageName: 'unassignedDevice'}},
	{ path: 'unassigned-site-chargers', component: ChargersComponent, canActivate: [AuthGuard], data: {pageName: 'unassignedSite'}},
	{ path: 'unassigned-battviews', component: BattviewsComponent, canActivate: [AuthGuard], data: {pageName: 'unassignedDevice'}},
	{ path: 'unassigned-site-battviews', component: BattviewsComponent, canActivate: [AuthGuard], data: {pageName: 'unassignedSite'}},
	{ path: 'battviews-mobile-listing', component: BattviewsComponent, canActivate: [AuthGuard], data: {pageName: 'battviewMobile'}},
	{ path: 'battviews/daughter-cards/:daughterSN', component: CommissionDaughterCardComponent, canActivate: [AuthGuard]},
	{ path: 'battviews/daughter-cards-search', component: DaughterCardComponent, canActivate: [AuthGuard]},
	{ path: 'search-devices-by-ip', component: DeviceByIpComponent, canActivate: [AuthGuard]},
	{ path: 'search-device-by-id', component: DeviceByIdComponent, canActivate: [AuthGuard]},
	{ path: ':clientId/:site/autoconnect-devices', component: AutoconnectDevicesComponent, canActivate: [AuthGuard]},
	{ path: 'devices-connectivity', component: DevicesConnectivityComponent, canActivate: [AuthGuard]},
	{ path: 'sites-connectivity', component: SitesConnectivityComponent, canActivate: [AuthGuard]},
	{ path: 'search-device-by-sn', component: DeviceBySnComponent, canActivate: [AuthGuard]},
	{ path: 'dealers-oems-hierarchy', component: DealersOemsHierarchyComponent, canActivate: [AuthGuard]},
	{ path: 'sites-without-owners', component: SitesWithoutOwnersComponent, canActivate: [AuthGuard]},
	{ path: 'reporting/user-search', component: UserReportComponent, canActivate: [AuthGuard]},
	{ path: 'reporting/battview-search', component: BattviewReportComponent, canActivate: [AuthGuard]},
	{ path: 'reporting/charger-search', component: ChargerReportComponent, canActivate: [AuthGuard]},
	{ path: 'allocate-production-line', component: AllocateProductionLineComponent, canActivate: [AuthGuard]},
	{ path: 'process-production-line', component: ProcessProductionLineComponent, canActivate: [AuthGuard]},
	{ path: 'production-line-ips', component: ProductionLineIpsComponent, canActivate: [AuthGuard]},
	{ path: 'production-performance', component: ProductionPerformanceComponent, canActivate: [AuthGuard]},
	{ path: 'replacement-devices-listing', component: ReplacementDevicesListingComponent, canActivate: [AuthGuard]},
	{ path: 'replacement-device/:deviceType/:deviceID/:mode', component: ReplacementDeviceComponent, canActivate: [AuthGuard]},
	{ path: 'service-page/:customerId/:siteId', component: ServicePageComponent, canActivate: [AuthGuard]},
	{ path: 'service-page', component: ServicePageComponent, canActivate: [AuthGuard]},
	{ path: 'battviews-functional-test', component: BattviewsFunctionalTestComponent, canActivate: [AuthGuard]},
	{ path: 'sites/sites-invoices-reporting', component: SitesInvoicesReportingComponent, canActivate: [AuthGuard]},
	{ path: 'sites/site-invoices-activation-reminder', component: SiteInvoicesActivationReminderComponent, canActivate: [AuthGuard]},
	{ path: 'search-device-by-serial-number', component: DealerDeviceBySnComponent, canActivate: [AuthGuard]},
	{ path: 'search-pms-by-serial-number', component: PmBySnComponent, canActivate: [AuthGuard]},
	{ path: 'blocked-fw-reports', component: BlockedFwReportsComponent, canActivate: [AuthGuard]},
	{ path: 'voltus-dispatches-log', component: VoltusDispatchesLogComponent, canActivate: [AuthGuard]},
	{ path: 'search-device-by-po', component: DeviceByPoComponent, canActivate: [AuthGuard]},
	{ path: 'pm-report', component: PMReportComponent, canActivate: [AuthGuard]},
	{ path: 'download/pm-report', component: ReportDownloadComponent, canActivate: [AuthGuard]},
];

@NgModule({
	imports :[RouterModule.forChild(deviceManagementRoutes)],
	exports: [RouterModule],
})
export class DeviceManagementRoutingModule {

}