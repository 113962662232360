import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { User } from '../../../../auth/user.model';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../auth/user.service';
import { SiteInfoService } from '../site-info.service';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { DevicesDashboardService } from '../../devices/devices-dashboard.service';
import { Site } from '../../../../shared/models/site.model';

@Component({
	selector: 'app-charger-list-widget',
	templateUrl: './charger-list-widget.component.html',
	styleUrls: ['./charger-list-widget.component.css']
})
export class ChargerListWidgetComponent implements OnInit, OnChanges {

	@Input() data;
	user: User;
	siteId;
	customerId;
	widgetId :string = "chargers_list";
	hideWidget: boolean = false;
	showBmsColumns: boolean = false;
	options = {
		hasPrint: false,
		hasExpand: false
	};
	columns : Array<object> = [];
	currentSite: Site = new Site();
	bmsTypes: any = {};

	constructor(private userService: UserService,
		private sideMenu: SideMenuService,
		private commonUtil: CommonUtil,
		private devicesDashboardService: DevicesDashboardService) { }

	ngOnInit() {

		this.user = this.userService.getCurrentUser();
		this.bmsTypes = this.commonUtil.arrayToAssociativeArray(this.devicesDashboardService.bmsTypes, 'id');

		this.sideMenu.currentSite.subscribe(site => {
			if (site && site.id) {
				this.currentSite = site;
				this.setExtraData();
				this.columns = [
					{
						headerName: "Charger ID",
						field: "chargerusername",
						cellRendererSelector:function (params) {
							params['label'] = params.data.chargerusername;
							params['link'] = ["/#", params.colDef.cellRendererParams.customerId, params.colDef.cellRendererParams.siteId, "chargers", "analytics", "cycles", params.data.id].join('/');
							return {
								component: 'linkCellRenderer',
								params: params
							};
						},
						cellRendererParams: {customerId: this.currentSite.customerid, siteId: this.currentSite.id},
						filter: 'agTextColumnFilter'
					},
					{headerName: "Warranty", width: 180, field: "warrantyperiod", type: 'number'},
					{headerName: "Charger S/N", width: 180, field: "serialnumber", filter: 'agTextColumnFilter'},
					{headerName: "Model", width: 150, field: "model", filter: 'agTextColumnFilter'},
					{headerName: "Firmware Version", field: "firmwareversion", width: 210, hide: true, filter: 'agTextColumnFilter'},
					{headerName: "ACT Intelligent", field: "actviewenable", width: 200, hide: true, filter: 'agTextColumnFilter'},
					{headerName: "Time Zone", field: "zonename", width: 300, hide: true, filter: 'agTextColumnFilter'},
					{headerName: "Charger Type", field: "chargerType", width: 180, hide: true, filter: 'agTextColumnFilter'},
					{headerName: "Battery Type", field: "batteryType", width: 180, hide: true, filter: 'agTextColumnFilter'},
					{headerName: "Cell Voltage", field: "cellVoltage", width: 180, hide: true, filter: 'agTextColumnFilter'},
					{headerName: "Multi Voltage", width: 180, field: "enableautodetectmultivoltage", hide: true, filter: 'agTextColumnFilter'}
				];
		
				if (this.user.accessFunctions["view_rejected_device_connections"]) {
					this.columns.push({headerName: "IP Address", field: "ip_address", width: 170, filter: 'agTextColumnFilter'});
				}

				if(this.showBmsColumns) {
					this.columns.push(
						{headerName: "BMS ID", field: "bmsType", width: 180, filter: 'agTextColumnFilter', hide: true},
						{headerName: "BMS FW Version", field: "bms_fw_version", width: 210, filter: 'agTextColumnFilter', hide: true}
					);
				}
			}
		});
	}

	ngOnChanges() {
		if (this.data && this.data.length > 0) {
			this.hideWidget = false;
			const sortKey = 'chargerusername';
			this.data = this.data.sort(function(a,b) {
				return (a[sortKey] > b[sortKey]) ? 1 : ((b[sortKey] > a[sortKey]) ? -1 : 0);
			});
			this.setExtraData();
		} else {
			this.hideWidget = true;
		}
	}

	setExtraData() {
		let showBmsColumns = false;
		if(this.currentSite && this.currentSite.id && this.data && this.data.length > 0) {
			this.data.forEach((item) => {
				item.zonename	= this.commonUtil.getZoneName(item.zoneid);
				item.chargerType = this.devicesDashboardService.chargerTypes[item.chargertype] ? this.devicesDashboardService.chargerTypes[item.chargertype].text : ""; 
				item.batteryType = this.devicesDashboardService.batteryTypes[item.batterytype] ? this.devicesDashboardService.batteryTypes[item.batterytype].text : ""; 
				item.cellVoltage = item.batterytype == 1 && item.firmwareversion > 2.5 ? item.liion_cellvoltage : 2; 
				let tempBmsFwVersion = item.bms_fw_version;
				item.bmsType = 'N/A';
				item.bms_fw_version = 0;
				if(item.daughtercardenabled != 0) {
					item.bms_fw_version = tempBmsFwVersion;
					let bmsName = this.bmsTypes[item.bms_id];
					if(bmsName)
						item.bmsType = bmsName.text; 
					showBmsColumns = true;
				}
			});
		}
		this.showBmsColumns = showBmsColumns;
	}
}
