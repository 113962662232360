import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { ActFirstService } from '../../act-first.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-private-page-chargers-listing',
  templateUrl: './private-page-chargers-listing.component.html',
  styleUrls: ['./private-page-chargers-listing.component.css']
})
export class PrivatePageChargersListingComponent implements OnInit {

  images = {};
  @Input() chargersList = [];
  @Input() groupActiveTab = {};
  @Input() itemId = 0;

  constructor(
	private actFirstService : ActFirstService,
	private translateService: TranslateService
	) {
  }

  ngOnInit() {

    this.images = {
      ready: '/images/B1.png',
      bifo: '/images/B2.png',
      charging: '/images/B3.png',
      parked: '/images/B4.png',
    }
  
  }

  getRankedToltip(obj) {
    let batteryText = obj.batteryid ? "Battery: <a [routerLink]='" + obj['batteryid_link'] + "'" + obj.batteryname +  "</a>" : "";
  	return "<p class='stackHTML tcenter'><strong>" + obj['name'] + "</strong><br>"+ this.getFormattedText(obj['innergroup']) +"<br>" + batteryText + "Avg. Daily AHrs: " + obj['Ahrs'] + "<br>Battery Temp: " + obj['temperature'] + "</p>";
  }

  getFormattedText(text) {
  	if (text == 'ready') {
  		return this.translateService.instant('act.ready_to_pick');
  	}
  	if (text == 'ranked') {
  		return this.translateService.instant('act.bofo_ranking');
  	}
  	if (text == 'parked') {
  		return this.translateService.instant('act.parked_batteries');
  	}
  	return _.capitalize(text);
  }

  unplugReadyCharger(charger) {
    // update to is manual pick and log as pick
    this.actFirstService.addManualPick({chargerid: charger.id, siteid:charger.siteid, actfirstgroupid: charger.actfirstgroupid}).subscribe();
     
  }

}
