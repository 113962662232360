import { Component, OnInit, OnDestroy } from '@angular/core';
import { SideMenuService } from '../../shared/side-menu/side-menu.service';
import { UserService } from '../../auth/user.service';
import { User } from '../../auth/user.model';
import { Customer } from '../../shared/models/customer.model';
import { ActivatedRoute } from '@angular/router';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import * as _ from 'lodash';
import * as underscore from 'underscore';
import { SiteDashboardService } from '../site-dashboard/site-dashboard.service';
import { Subscription } from 'rxjs';
import { CustomerDashboardService } from './customer-dashboard.service';

@Component({
	selector: 'app-customer-dashboard',
	templateUrl: './customer-dashboard.component.html',
	styleUrls: ['./customer-dashboard.component.css']
})
export class CustomerDashboardComponent implements OnInit, OnDestroy {
	
	user: User;
	customer: Customer = new Customer();
	intelligentSitesCheckbox: boolean = true;
	showToggleIntelligentFilter: boolean = false;
	hasCustomerSitesReportsPage: boolean = false;
	
	data = {
		estimatedCounts: null,
		siteSettings: null,
		data: null
	};

	activeDashboardDataSubscription: Subscription = new Subscription();
	customersSubscription: Subscription = new Subscription(); 

	constructor(
		private route: ActivatedRoute,
		private sideMenu: SideMenuService,
		private userService: UserService,
		private siteDashboardService: SiteDashboardService,
		private notificationMessage: NotificationMessageService,
		private cdService: CustomerDashboardService
	) {
	}

	ngOnInit() {
		this.user = this.userService.getCurrentUser();

		// when accessing the page url directly/ refresh there is no need to get customer info from backend because we already have it in sideMenu.
		this.customersSubscription = this.sideMenu.customers.subscribe(value => {
			if (!_.isEmpty(value)) {
				let customer = this.sideMenu.getCustomer(this.route.snapshot.params['id']);
				if (customer) {
					this.customer = customer;
					this.sideMenu.setCurrentCustomer(customer);
					this.sideMenu.setCurrentSites(customer.sites);
					this.notificationMessage.closeNotification();

					this.showToggleIntelligentFilter = this.checkAppearanceOfToggleIntelligentFilter(customer.sites);
					if(this.user.isACTuser) {
						this.hasCustomerSitesReportsPage = this.areSitesContainAnACTintelligentSite(customer.sites);
					}

					this.activeDashboardDataSubscription = this.siteDashboardService.activeDashboardData.subscribe(data => {
						this.data.estimatedCounts = data.estimatedCounts;
						this.data.data = data.data;
					});
					this.siteDashboardService.getDashboardData(this.route.snapshot.params['id'], 0, {noAlertsSettings: true});
					this.toggleIntelligentFilter();

				} else {
					this.notificationMessage.setMessage('Unauthorized Access!', 'danger', 30000);
				}
			}
		});
	}

	areSitesContainAnACTintelligentSite(sites) {
		let result = false;
		sites.forEach((site) => {
			if(site.act_intelligent_type != 0) {
				result = true;
			}
		});

		return result;
	}
	
	checkAppearanceOfToggleIntelligentFilter(sites) {

		let sites_act_intelligent_types = [];
		sites.forEach((site) => {
			if(!site.test_site) {
				sites_act_intelligent_types.push(site.act_intelligent_type);
			}
		});

		sites_act_intelligent_types = underscore.sortBy(underscore.uniq(sites_act_intelligent_types));
		return (sites_act_intelligent_types.length > 1 && sites_act_intelligent_types[0] == 0);
	}

	toggleIntelligentFilter() {
		this.intelligentSitesCheckbox = !this.intelligentSitesCheckbox;
		this.cdService.setIntelligentSitesCheckbox(this.intelligentSitesCheckbox);
	}

	ngOnDestroy() {
		this.notificationMessage.closeNotification();
		this.customersSubscription.unsubscribe();
		this.activeDashboardDataSubscription.unsubscribe();
	}
}
