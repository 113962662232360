<div 
	class="col-md-12 boxs3">
	<div>
		<a 
			href="javascript:;" 
			class="btn btn-default margin-right-sm" 
			(click)="printSiteInfo()">
			<i class="glyphicon glyphicon-print"></i>
			Print Site Info
		</a>
		<a 
			*ngIf="user.isACTuser"
			class="btn btn-default margin-right-sm"
			[routerLink]="['/sites', 'edit', 'customerId', customerId, 'siteId', siteId]"
			[queryParams]="{'source': 'site-info'}">
			Edit Site
		</a>
		<a 
			*ngIf="canManageACTintelligent"
			class="btn btn-default margin-right-sm"
			[routerLink]="['/sites', 'manage-act-intelligent', customerId, siteId]">
			Manage ACT Intelligent
		</a>
	</div>
	<div 
		class="row col-md-12 margin-top-sm" 
		*ngIf="canViewRejectedDevices">
		<label 
			class="pointer" 
			title="Show IP addresses of connected devices only">
			<input 
				type="checkbox" 
				[(ngModel)]="onlyConnectedIPs" 
				(ngModelChange)="showOnlyConnectedIPs(onlyConnectedIPs)"/> Only connected</label>
	</div>
	<div 
		class="row col-md-12" 
		*ngIf="canViewRejectedDevices">
		<strong>Unique IP Addresses:</strong> 
		{{uniqueIPaddresses.length > 0 ? uniqueIPaddresses.join(', ') : 'No IP'}}
	</div>
</div>


<div 
	*ngIf="siteCommodity && user.accessFunctions?.site_devices_commodity"
	class="col-md-12 boxs3">
	<div 
		class="row col-md-4">
		<label>
			<input 
				type="checkbox" 
				[(ngModel)]="siteCommodity.firmware" 
				(ngModelChange)="updateSiteCommodity('firmware')" /> 
			Commodity Firmware
		</label>
	</div>
	<div 
		class="col-md-4">
		<label>
			<input 
				type="checkbox" 
				[(ngModel)]="siteCommodity.download" 
				(ngModelChange)="updateSiteCommodity('download')" /> 
			Commodity Download
		</label>
	</div>
	<div 
		class="col-md-4">
		<label>
			<input 
				type="checkbox"
				[(ngModel)]="siteCommodity.settings" 
				(ngModelChange)="updateSiteCommodity('settings')" />
			Commodity Settings
		</label>
	</div>
</div>
<app-widgets-loader key="siteInfo" [data]="widgetData" *ngIf="siteInfoData"></app-widgets-loader>