import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { UserService } from '../../../../auth/user.service';
import { TruckviewsDashboardService } from '../truckviews-dashboard/truckviews-dashboard.service';
import { SiteDashboardService } from '../../../site-dashboard/site-dashboard.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import * as moment from 'moment';
import * as _ from 'underscore';

@Component({
	selector: 'app-truckviews-listing',
	templateUrl: './truckviews-listing.component.html',
	styleUrls: ['./truckviews-listing.component.css']
})
export class TruckviewsListingComponent implements OnInit {

	@Input() currentSite: any = {};
	@Input() siteId: number;
	@Input() customerId: number;
	@Input() currentUser: any = {};
	
	truckviewsListing: any = {};
	timeoutId;
	hasTruckviews: boolean = true;

	@Output() truckviewsListingReady = new EventEmitter<object>();
	@Output() siteTagsChanged = new EventEmitter<object>();
	@Output() deviceTagsChanged = new EventEmitter<object>();
	@Output() refreshTagsFilter = new EventEmitter();
	@Output() deviceIsChecked = new EventEmitter<boolean>();

	constructor(
		private commonUtil: CommonUtil,
		public userService: UserService,
		private siteDashboard: SiteDashboardService,
		private truckviewsDashboard: TruckviewsDashboardService,
	) { }

	ngOnInit() {
		this.siteDashboard.setActiveSubTab('trucks-listing');
	}

	ngOnChanges() {
		if(Object.keys(this.currentSite).length > 0 && this.customerId && this.customerId)
			this.getTrucksListing();
	}

	getTrucksListing(isRefresh = false) {

		if(Object.keys(this.truckviewsListing).length == 0 || isRefresh) {

			var siteid		= this.siteId;
			var customerid	= this.customerId;

			if(siteid && customerid) {
				let options = {
					customerid: this.customerId,
					siteid: this.siteId,
					getTags: true,
					getGroups: this.currentSite.act_first_enabled
				};
				this.truckviewsDashboard.getTruckviews(options).subscribe( (response: any) => {
					
					// response['groups'] = [
					// 	{id: 10, text: 'Truck1'},
					// 	{id: 20, text: 'Truck2'},
					// ];
					response['tags']['deviceTags'] = {
						100: [],
						200: [],
						300: [],
						400: [],
						500: [],
						600: [],
						700: [],
						800: [],
						900: [],
						910: [],
					};
					response['list'] = [
						{id: 100, actfirstgroupid: 70, isConnected: true, bvStatus: 'tk-connected', timespanTextArr: ['Connected'], tags: [], isShow: true, gp: 'Truck1'},
						{id: 200, actfirstgroupid: 70, isConnected: true, bvStatus: 'tk-connected', timespanTextArr: ['Connected'], tags: [], isShow: true, gp: 'Truck1'},
						{id: 300, actfirstgroupid: 70, isConnected: true, bvStatus: 'tk-connected', timespanTextArr: ['Connected'], tags: [], isShow: true, gp: 'Truck2'},
						{id: 400, actfirstgroupid: 80, isConnected: true, bvStatus: 'tk-connected', timespanTextArr: ['Connected'], tags: [], isShow: true, gp: 'Truck2'},
						{id: 500, actfirstgroupid: 80, isConnected: false, bvStatus: 'tk-not-connected', timespanTextArr: ['Not Connected'], tags: [], isShow: true, gp: 'Truck1'},
						{id: 600, actfirstgroupid: 80, isConnected: false, bvStatus: 'tk-not-connected-day', timespanTextArr: ['Last connect: 1 day'], tags: [], isShow: true, gp: 'Truck1'},
						{id: 700, actfirstgroupid: 90, isConnected: false, bvStatus: 'tk-not-connected-week', timespanTextArr: ['Last connect: 1 week'], tags: [], isShow: true, gp: 'Truck2'},
						{id: 800, actfirstgroupid: 90, isConnected: false, bvStatus: 'tk-not-connected-no-act', timespanTextArr: ['Not Connected'], tags: [], isShow: true, gp: 'Truck2'},
						{id: 900, actfirstgroupid: 90, isConnected: true, bvStatus: 'tk-connected', timespanTextArr: ['Connected'], tags: [], isShow: true, gp: 'Truck1'},
						{id: 910, actfirstgroupid: 90, isConnected: true, bvStatus: 'tk-connected', timespanTextArr: ['Connected'], tags: [], isShow: true, gp: 'Truck2'},
					];
					this.hasTruckviews = (Object.keys(response).length > 0);

					if(Object.keys(response).length === 0) {
						this.truckviewsListingReady.emit({
							'list': {},
							'hasDevices': false
						});
						return;
					}

					var result	= _.sortBy(response.list, 'id');

					var truckviewsListing = {};
					var deviceGroups = {};

					result.forEach((item) => {

						var labelName = item.gp;

						if(!truckviewsListing[labelName])
							truckviewsListing[labelName] = [];

						deviceGroups[item.id] = item.actfirstgroupid;
						truckviewsListing[labelName].push(item);
					});

					var trucksListingOld = Object.assign({}, this.truckviewsListing);
					
					this.truckviewsListing	= truckviewsListing;

					let siteTagsById	= {};
					let deviceTags		= {};

					if(response.tags) {
						siteTagsById	= response.tags.siteTags;
						deviceTags		= response.tags.deviceTags;
						this.deviceTagsChanged.emit([deviceTags, deviceGroups]);
					}

					if (Object.keys(trucksListingOld).length > 0) {
						for (var deviceGroup in this.truckviewsListing) {
							this.truckviewsListing[deviceGroup].forEach((item, index) => {
								var oldItem = trucksListingOld[deviceGroup][index] || {};
								item.selected = oldItem.selected;
							});
						}
					}

					this.refreshTrucksList(2 * 60 * 1000); //every 2 mins

					for (var deviceGroup in this.truckviewsListing) {

						this.truckviewsListing[deviceGroup].forEach((item) => {

							item.bvStatus += " tk-thumbnail";

							var tags = deviceTags[item.id];
							item.tags = [];
							for (var i = 0; i < tags.length; i++) {
								if (siteTagsById[tags[i]])
									item.tags.push(siteTagsById[tags[i]]);
							}
						});
					}

					// site tags
					if (!isRefresh) {
						let siteGroups = response.groups;
						var siteTags = [];
						for (var tagIndex in siteTagsById) {
							var item = response.tags.siteTags[tagIndex];
							item.isActive = false;
							siteTags.push(item);
						}
						this.siteTagsChanged.emit([siteTags, siteGroups]);
					} else {
						this.refreshTagsFilter.emit();
					}
					
					this.truckviewsListingReady.emit({
						'list': this.truckviewsListing,
						'hasDevices': this.hasTruckviews
					});
				});
			}
		}
	}

	deviceChecked(isChecked) {
		this.deviceIsChecked.emit(isChecked);
	}

	refreshTrucksList(checkFreq) {	
		this.timeoutId = setTimeout(() => {
			if(Object.keys(this.truckviewsListing).length > 0)
				this.getTrucksListing(true);

		}, checkFreq);
	}

	ngOnDestroy() {
		clearInterval(this.timeoutId);
	}
}