<app-grid-v2
	[columns]="columns"
	[data]="items"
	[sizeColumnsToFit]="sizeColumnsToFit"
	[settings]="{
		hasExport: false,
		hasSelectColumn: false,
		hasReset: false,
		showSummary: false,
		height: '',
		enableFloatingFilter: false,
		pagination: true,
		paginationPageSize: 4,
		domLayout: 'autoHeight'
	}"
></app-grid-v2>

<a *ngIf="columns && items.length > itemsPerPage" (click)="modal.show()" class="text-center pie-view-more noprint" style="margin-top: 10px" href="javascript:">View More</a>

<app-modal #modal [title]="title" [hasConfirmButton]="false" [hasCloseButton]="true">
	<app-grid-v2
		[columns]="modalColumns"
		[data]="items"
		[sizeColumnsToFit]="sizeColumnsToFit"
		[settings]="{
			hasExport: false,
			hasSelectColumn: false,
			hasReset: false,
			showSummary: false,
			height: 'auto',
			enableFloatingFilter: false,
			pagination: false,
			domLayout: 'autoHeight'
		}"
		*ngIf="modal.render"
	></app-grid-v2>
</app-modal>