<div class="row">
  <div id="page-wrapper" >
    <div id="page-inner">
      <div class="panel panel-default">
        <div class="boxs3">
          <h2>ACTLink Faulted DB</h2>
          <div *ngIf="faultQueueLog.length > 0">
            <table class="table table-stripped boxs">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>User ID</th>
                  <th>IP Address</th>
                  <th>User Agent</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let log of logData">
                  <td>{{log.inserttime | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
                  <td>{{log.user_id}}</td>
                  <td>{{log.ip_address}}</td>
                  <td>{{log.user_agent}}</td>
                  <td>
                    <a (click)="delete('actlink_failed_db', log.id)"><span class="glyphicon glyphicon-trash large-icons" aria-hidden="true"></span>&nbsp;</a>
                    <a (click)="download(log.id, log.data, $event)" id="downloadAnchorElem_{{log.id}}"><span class="glyphicon glyphicon-download large-icons" aria-hidden="true"></span>&nbsp;</a>
                    <a href="/admins/getUploadFaildDBRecord/{{log.id}}" id="viewAnchorElem_{{log.id}}" target="_blank"><span class="glyphicon glyphicon-info-sign large-icons" aria-hidden="true"></span>&nbsp;</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination [allItems]="faultQueueLog" (onPageChanged)="loadData($event)" [count]="numLogsPerPage"></pagination>
          </div>
          <div *ngIf="faultQueueLog.length === 0">
            <h3>No DB Fault!</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>