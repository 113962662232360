<div class="row">
	<div id="page-wrapper">
		<div id="content-header">
			<div class="margin-left">
				<!-- <header-Title></header-Title> -->
				<div id="dvCompanyFullName">
					<h3>
						<a
							*ngIf="currentSite.name"
							[routerLink]="[ '/main' , currentCustomer.id]">
							{{currentCustomer.name}}
						</a>
						<span 
							*ngIf="currentSite.name">
							/ {{currentSite.name}}
						</span>
					</h3>
				</div>
				<div 
					*ngIf="currentSite.name" 
					class="picmcaker">
					<span ng-click="getSiteMap(currentSite)">
						<i class="fa fa-map-marker"></i>
						{{currentSite.address}}
					</span>
				</div>
			</div>
			<div 
				*ngIf="user.isACTuser" 
				class="margin-left">
				<a 
					[routerLink]="['/equipments-devices', 'site', currentSite.id]">View Equipments
				</a>
			</div>
			<div id="menu-nav" class="margin-top-lg">
				<!-- <site-menu></site-menu> -->
				<div id="wrap">
					<a 
						id="main-menu-toggle"
						*ngIf="currentSite.id"
						(click)="toggleSiteMenu()"
						class="btn btn-default">
							<i class="fa fa-bars"></i>
					</a>
					<div
						class="inner relative">
						<nav
							id="navigation">
							<ul
								*ngIf="currentSite.id && siteMenuOpen"
								id="main-menu">
								<li
									[ngClass]="{active: activeTab === 'site-info'}">
									<a
										[routerLink]="[ '/', currentCustomer.id , currentSite.id]">
										<i class="fa fa-info-circle margin-right-sm"></i>
											Site Info
									</a>
								</li>
								<li
									[ngClass]="{active: activeTab === 'batteries'}"
									class="parent">
									<a
										[routerLink]="['/', currentCustomer.id, currentSite.id, 'battviews', 'performance']">
										<i class="fa fa-battery-full margin-right-sm"></i>
										Batteries
										<i class="fa fa-chevron-down"></i>
									</a>
									<ul
										class="sub-menu">
										<li 
											[ngClass]="{active: activeSubTab === 'battery-performance' && activeTab === 'batteries'}">
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'battviews', 'performance']">
												<i class="fa fa-line-chart margin-right-sm"></i>
												Site Performance
											</a>
										</li>
										<li 
											[ngClass]="{active: activeSubTab === 'reports' && activeTab === 'batteries'}"
											*ngIf="currentSite.act_intelligent_type != 0">
												<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'battviews', 'reports']">
													<i class="fa fa-book margin-right-sm"></i>
													Site Reports
												</a>
										</li>
										<li 
											[ngClass]="{active: activeSubTab === 'alerts' && activeTab === 'batteries'}" 
											*ngIf="userService.hasAccessFunction('view_site_alerts_page') && (user.isACTuser || currentSite.act_intelligent_type != 0)">
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'battviews', 'alerts']">
												<i class="fa fa-warning margin-right-sm"></i>
												Site Alerts
											</a>
										</li>
										<li [ngClass]="{active: activeSubTab == 'aging' && activeTab === 'batteries'}" *ngIf="user.isACTuser || currentSite.act_intelligent_type != 0">
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'battviews', 'aging']">Site Battery Aging / Replacement</a>
										</li>
										<li
											[ngClass]="{active: activeSubTab === 'batteries-listing' && activeTab === 'batteries'}">
											<a 
												[routerLink]="['/', currentCustomer.id, currentSite.id, 'battviews', 'listing']" 
												href="javascript:"><i class="fa fa-list-alt margin-right-sm"></i>List</a>
										</li>
										<li 
											[ngClass]="{active: activeSubTab === 'analytics' && activeTab === 'batteries'}">
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'battviews', 'analytics', 'battery-summary', 0, '']">
												<i class="fa fa-area-chart margin-right-sm"></i>
												Analytics
												<span 
													*ngIf="currentSite.act_intelligent_type != 0"> / Settings</span>
											</a>
										</li>
										<li 
											[ngClass]="{active: activeSubTab == 'site-analytics' && activeTab === 'batteries'}">
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'battviews', 'site-analytics']">
												Site Analytics
											</a>
										</li>
									</ul>
								</li>
								<li 
									[ngClass]="{active: activeTab === 'chargers'}" 
									class="parent">
									<a 
										[routerLink]="['/', currentCustomer.id, currentSite.id, 'chargers', 'listing']">
										<i class="fa fa-plug margin-right-sm"></i>
										Chargers
										<i class="fa fa-chevron-down"></i>
									</a>
									<ul class="sub-menu">
										<li 
											[ngClass]="{active: activeSubTab === 'listing' && activeTab === 'chargers'}">
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'chargers', 'listing']">
												<i class="fa fa-list-alt margin-right-sm"></i>
												List
											</a>
										</li>
										<li 
											[ngClass]="{active: activeSubTab === 'chargers-performance' && activeTab === 'chargers'}" >
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'chargers', 'performance']">
												<i class="fa fa-line-chart margin-right-sm"></i>
												Site Performance
											</a>
										</li>
										<li 
											[ngClass]="{active: activeSubTab === 'charger-analytics' && activeTab === 'chargers'}">
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'chargers', 'analytics', 'cycles', 0]">
												<i class="fa fa-area-chart margin-right-sm"></i>
												Analytics
												<span *ngIf="currentSite.act_intelligent_type != 0"> / Settings</span>
											</a>
										</li>
										<li 
											[ngClass]="{active: activeSubTab === 'site-energy' && activeTab === 'chargers'}" 
											*ngIf="currentSite.act_intelligent_type != 0 && userService.hasAccessFunction('access_charger_site_energy')">
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'chargers', 'site-energy']">
												<i class="fa fa-bolt margin-right-sm"></i>
												Site Energy
											</a>
										</li>
									</ul>
								</li>
								<li
									*ngIf="userService.hasAccessFunction('service_page') && !currentSite.test_site && currentSite.act_intelligent_type != 0"
									[ngClass]="{active: activeTab === 'service-page'}" 
									class="parent">
									<a 
										[routerLink]="['/', currentCustomer.id, currentSite.id, 'service-page']">
										<i class="fa fa-wrench margin-right-sm"></i>
										Service page
									</a>
								</li>
								<li [ngClass]="{active: activeTab === 'settings'}" class="parent">
									<a [routerLink]="['/', currentCustomer.id , currentSite.id, 'manage-tags']">
										<i class="fa fa-cog margin-right-sm"></i>
										{{user.isTruckDealer ? 'Tags/Truck Types Management' : 'Settings'}}
										<i class="fa fa-chevron-down"></i>
									</a>
									<ul class="sub-menu">
										<li [ngClass]="{active: activeSubTab === 'manage-tags' && activeTab === 'settings'}">
											<a [routerLink]="[ '/', currentCustomer.id , currentSite.id, 'manage-tags']">
												{{currentSite.act_first_enabled ? 'Tags/Truck Types Management' : 'Manage Tags'}}
											</a>
										</li>
										<li [ngClass]="{active: activeSubTab === 'alerts-settings' && activeTab === 'settings'}">
											<a [routerLink]="[ '/', currentCustomer.id , currentSite.id, 'alerts-settings']">
												<i class="fa fa-cog margin-right-sm"></i>
												Alerts & Settings
											</a>
										</li>
										<li [ngClass]="{active: activeSubTab === 'users-settings' && activeTab === 'settings'}" *ngIf="!user.isEndClient">
											<a [routerLink]="[ '/', currentCustomer.id , currentSite.id, 'users-settings']">
												<i class="fa fa-cog margin-right-sm"></i>
												Users Settings
											</a>
										</li>
										<li [ngClass]="{active: activeSubTab === 'temp-disabled-alerts' && activeTab === 'settings'}" *ngIf="userService.hasAccessFunction('disable_alert_temporary')">
											<a [routerLink]="[ '/', currentCustomer.id , currentSite.id, 'temp-disabled-alerts']">
												Disable Alert Temporary
											</a>
										</li>
									</ul>
								</li>
								<!-- <i class="fa fa-columns margin-right-sm"></i> -->
								<li
									*ngIf="currentSite.act_intelligent_type != 0 && currentSite.act_first_enabled"
									[ngClass]="{active: activeTab === 'actfirst'}"
									class="parent">
									<a  style="padding: 2px 0; line-height: 31px;"
										[routerLink]="['/act-first/public' , currentSite.act_first_token]"
										[queryParams]="{'siteId': currentSite.id}">
										<img src="/images/actfirst-logo.png" style="width: 100px;height: 36px;vertical-align: bottom;" />
										<i class="fa fa-chevron-down margin-left-sm"></i>
									</a>
									<ul
										class="sub-menu">
										<li 
											[ngClass]="{active: activeSubTab === 'public' && activeTab === 'actfirst'}">
											<a 
												[routerLink]="['/act-first', 'public' , currentSite.act_first_token]"
												[queryParams]="{'siteId': currentSite.id}">
												<i class="margin-right-sm"></i>
												Public Rank Page
											</a>
										</li>
										<li 
											[ngClass]="{active: activeSubTab === 'private' && activeTab === 'actfirst'}">
												<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'act-first', 'private']">
													<i class="margin-right-sm"></i>
													Private Rank Page
												</a>
										</li>
										<li 
											[ngClass]="{active: activeSubTab === 'reports-charts' && activeTab === 'actfirst'}">
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'act-first', 'reports-charts', 'availability']">
												<i class="margin-right-sm"></i>
												Reports & Charts
											</a>
										</li>
										<li 
											[ngClass]="{active: activeSubTab == 'chargers-control' && activeTab === 'actfirst'}"
											*ngIf="userService.hasAccessFunction('act_first_chargers_control')">
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'act-first', 'chargers-control']">Chargers Control</a>
										</li>
										<li 
											*ngIf="userService.hasAccessFunction('act_first_pages', 'write')"
											[ngClass]="{active: activeSubTab == 'historical-charger-log' && activeTab === 'actfirst'}">
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'act-first', 'historical-charger-log']">Historical Charger Log</a>
										</li>
										<li
											*ngIf="userService.hasAccessFunction('act_first_pages', 'write')"
											[ngClass]="{active: activeSubTab === 'site-history' && activeTab === 'actfirst'}">
											<a 
												[routerLink]="['/', currentCustomer.id, currentSite.id, 'act-first', 'site-history']" 
												href="javascript:"><i class="margin-right-sm"></i>Site History</a>
										</li>
										<li 
											[ngClass]="{active: activeSubTab === 'management' && activeTab === 'actfirst'}"
											*ngIf="userService.hasAccessFunction('act_first_chargers_control')">
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'act-first', 'management']">
												<i class="margin-right-sm"></i>
												Management
											</a>
										</li>
									</ul>
								</li>
								<li
									*ngIf="currentSite.dr_provider != 'None' && user.isACTuser && userService.hasAccessFunction('act_energy_access')"
									[ngClass]="{active: activeTab === 'dynamic-response'}"
									class="parent">
									<a style="padding: 2px 0; line-height: 31px;"
										[routerLink]="['/', currentCustomer.id, currentSite.id, 'dynamic-response', 'profiles']">
										<img src="/images/ACTenergy.png" alt="ACT Energy" style="width: 100px;height: 36px;vertical-align: bottom;" />
										<i class="fa fa-chevron-down"></i>
									</a>
									<ul
										class="sub-menu">
										<li 
											[ngClass]="{active: activeSubTab === 'profiles' && activeTab === 'dynamic-response'}">
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'dynamic-response', 'profiles']">
												<i class="margin-right-sm"></i>
												DR Profiles
											</a>
										</li>
										<li 
											[ngClass]="{active: activeSubTab === 'events-history' && activeTab === 'dynamic-response'}">
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'dynamic-response', 'events-history']">
												<i class="margin-right-sm"></i>
												Events History
											</a>
										</li>
										<li 
											[ngClass]="{active: activeSubTab === 'events-chart' && activeTab === 'dynamic-response'}">
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'dynamic-response', 'events-chart']">
												<i class="margin-right-sm"></i>
												Events Charts
											</a>
										</li>
										<li 
											[ngClass]="{active: activeSubTab === 'event-logs' && activeTab === 'dynamic-response'}"
											*ngIf="userService.hasAccessFunction('act_energy_event_log')"
										>
											<a [routerLink]="['/', currentCustomer.id, currentSite.id, 'dynamic-response', 'event-logs']">
												<i class="margin-right-sm"></i>
												Event Logs
											</a>
										</li>
									</ul>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<div id="tab-content" class="row"><router-outlet></router-outlet></div>
	</div>
</div>
