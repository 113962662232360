import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'simple-widget',
	templateUrl: './simple-widget.component.html',
	styleUrls: ['./simple-widget.component.css']
})
export class SimpleWidgetComponent implements OnInit {

	@Input() title;
	@Input() collapsedByDefault: boolean = false;
	@Input() widgetId: string = '';
	@Input() isCollapsed: boolean = false;
	@Input() headerClass: string;

	@Output() onToggleCollapse = new EventEmitter<any>(true);
	constructor() { }

	ngOnInit() {
		if(this.collapsedByDefault)
			this.isCollapsed = true;
	}

	toggleCollapse() {
		this.isCollapsed = !this.isCollapsed;
		this.onToggleCollapse.emit(this.isCollapsed);
	}
}