<app-widgets-loader
	*ngIf="!siteDevicesHaveNoData && siteHasDevices"
	key="chargers-performance"
	[data]="{
		data: data,
		siteId: siteId,
		customerId: customerId,
		siteChargersData: siteChargersData,
		siteTags: siteTags,
		siteChargersTags: siteChargersTags,
		isACTuser: user.isACTuser,
		isEndClient: user.isEndClient,
		customerName: siteInfo.customer_name,
		siteName: siteInfo.name
	}"
	[passAllDataOnce]="true"
></app-widgets-loader>
<div *ngIf="siteHasDevices && siteDevicesHaveNoData">
	<div class="panel panel-default">
		<div class="panel-body">
			<strong>No data is available in the past 90 days</strong>
		</div>
	</div>
</div>
<div *ngIf="!siteHasDevices">
	<div class="panel panel-default">
		<div class="panel-body">
			<strong>No Charger Registered!</strong>
		</div>
	</div>
</div>