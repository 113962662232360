<div class="col-sm-12">
	<div class="form-field margin-bottom-lg">
		<label for="name">Customer Name</label>
		<input required type='text' class='form-control' [(ngModel)]='customer.name' placeholder='Customer Name' name="name" />
	</div>

	<fieldset>
		<legend>Contact Info</legend>
		<div class="form-field margin-bottom-sm">
			<label for="name">Name</label>
			<input type='text' class='form-control' name="contactname" [(ngModel)]='customer.contactname' placeholder='Person Name' />
		</div>

		<div class="form-field margin-bottom-sm">
			<label for='phoneno'>Phone No.</label>
			<input type='text' class='form-control' name="phoneno" [(ngModel)]='customer.phoneno' placeholder='Phone No.' />
		</div>
	</fieldset>
</div>