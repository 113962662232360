import { Component, OnInit, TemplateRef, Input, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
/* This is a component which we pass in modal*/

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html'
})

export class ModalComponent implements OnInit {
	
	@Input('closeOnConfirm') closeOnConfirm = true;
	@Input('hasConfirmButton') hasConfirmButton = true;
	@Input('hasCloseButton') hasCloseButton = true;
	@Input('title') title;
	@Input('closeBtnText') closeBtnText = '';
	@Input('confirmBtnText') confirmBtnText = '';
	@Input('confirmBtnClass') confirmBtnClass = 'btn-primary';
	@Input('size') size = 'md';
	@Input('modalComponent') modalComponent;
	@ViewChild("modalTemplate")
	modalTemplate: TemplateRef<any>;
	modalRef: BsModalRef;
	onClose: Subject<boolean>;
	render: boolean = false;
	settings = {
		animated: true,
		preventEscape: false,
		staticModal: false,
		backdrop: true
	};

	constructor(
		private modalService: BsModalService,
		private translate: TranslateService
	) {
		if(!this.closeBtnText)
			this.closeBtnText = this.translate.instant('g.close');

		if(!this.confirmBtnText)
			this.confirmBtnText = this.translate.instant('g.confirm');
	}

	ngOnInit() {
		this.onClose = new Subject();
		this.modalService.onHide.subscribe(
			event => {
				if((event == 'esc' || event == 'backdrop-click') && this.render)
					this.onHide();
			}
		);
	}

	show() {
		this.render = true;
		this.modalRef = this.modalService.show(this.modalTemplate, this.getConfig());
	}

	hide() {
		if(this.modalRef) this.modalRef.hide();
		this.render = false;
	}

	onHide(): void {
		this.onClose.next(false);
		this.hide();
	}

	onConfirm(): void {
		this.onClose.next(true);
		if(this.closeOnConfirm)
			this.hide();
	}

	getConfig() {
		const configObject: any = {};
		if (this.settings.staticModal) {
			configObject.backdrop = "static";
		}
		if (this.settings.preventEscape) {
			configObject.keyboard = false;
		}
		configObject.animated = this.settings.animated;
		configObject.backdrop = this.settings.backdrop;
		configObject.class = 'modal-' + this.size;
		return configObject;
	}
}