import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { BattviewsDashboardService } from '../battviews-dashboard.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { SiteDashboardService } from '../../../site-dashboard.service';

@Component({
	selector: 'app-battview-analytics',
	templateUrl: './battview-analytics.component.html',
	styleUrls: ['./battview-analytics.component.css']
})
export class BattviewAnalyticsComponent implements OnInit, OnChanges {

	@Input() isBattviewMobile: boolean = false;
	@Input() hideTemperatureChart: boolean =  true;
	@Input() pageHasRTrecord: boolean =  false;
	@Input() device: any;
	@Input() customerID: number = 0;
	@Input() siteID: number = 0;
	@Input() currentUser: any = {};
	@Input() date: {
		fromDate: any,
		toDate: any
	} = {
		fromDate: new Date(),
		toDate: new Date()
	};
	@Input() activeTab: string = null;
	@Input() activeSubTab: string = null;
	@Input() customerInfo: any = {};
	@Input() siteInfo: any = {};
	@Input() siteAlertsSettings: any = {};
	@Input() userAlertsSettings: any = {};

	@Output() updateAppearanceElementsFlags = new EventEmitter<any>(true);
	@Output() updateHasData = new EventEmitter<boolean>(true);

	@ViewChild("batteryUsageSummary") batteryUsageSummary;
	@ViewChild("batteryDailyUsage") batteryDailyUsage;
	@ViewChild("eventsLog") eventsLog;
	@ViewChild("exceptions") exceptions;
	@ViewChild("chargeSummary") chargeSummary;

	hasData: boolean = true;
	fullData: any = {
		DailyDetails: [],
		events: [],
		fromDate: null,
		toDate: null
	};
	DailyDetails: any[] = [];
	events: any[] = [];
	summaryObject: any = {};
	summarySequentialObject: any = {};
	warrantyDate: any;

	constructor(
		private battviewsDashboard: BattviewsDashboardService,
		private siteDashboardService: SiteDashboardService,
	) { }

	ngOnInit() {
		this.updateAppearanceElementsFlags.emit({
				addNoteEnabled: true,
				pageHasFilterByDate: true,
				pageHasBattviewTags: true,
				hasResetPLC: false,
				pageHasManageBtn: true,
				pageHasTimesInfo: true,
		});
		this.updateHasData.emit(this.hasData);
		this.siteDashboardService.setActiveSubTab('analytics');
	}

	ngOnChanges(changes) {
		if((changes.activeTab && !changes.activeTab.firstChange) || (changes.activeSubTab && !changes.activeSubTab.firstChange))
			this.onPageUpdate(true);
	}

	onPageUpdate(forceGetData) {
		if(this.device.id && this.date.fromDate && this.date.toDate) {
			this.filterData(forceGetData);
		}
	}
	onDatesUpdated(date) {
		this.date = date;
		this.onPageUpdate(false);
	}
	onDeviceChanged(device) {
		this.device = device;
		if(this.device.id && this.date.fromDate && this.date.toDate) {
			this.resetHaveData();
			this.filterData(true);
		}
	}

	getTabSourceData() {
		let sourceData = null;
		switch(this.activeTab) {
			case 'charge-summary':
			case 'exceptions':
			case 'battery-usage-summary':
			case 'battery-daily-usage':
			case 'battery-summary':
				sourceData = 'DailyDetails';
			break;
			case 'events-log':
			case 'soc-prediction':
				sourceData = 'both';
			break;
			case 'chart':
				switch(this.activeSubTab) {
					case 'daily-usage':
					case 'daily-ebus':
					case 'daily-ahrs':
						sourceData = 'DailyDetails';
					break;
					case 'soc':
					case 'max-temperature':
					case 'end-temperature':
					case 'end-voltage':
					case 'customized':
						sourceData = 'both';
					break;
				}
			break;
		}
		return sourceData;
	}

	filterData(forceGetData) {
		if(this.fullData.fromDate > this.date.fromDate || this.fullData.toDate < this.date.toDate) {
			this.resetHaveData();
		} else {
			this.flushDailyDetails();
			this.flushSequential();
		}

		this.getData((forceGetData || this.fullData.fromDate > this.date.fromDate || this.fullData.toDate < this.date.toDate), () => {

			this.date.fromDate = new Date(moment(this.date.fromDate).startOf('day').unix() * 1000);
			this.date.toDate = new Date(moment(this.date.toDate).startOf('day').unix() * 1000);
			var fromDate = new Date(-this.date.fromDate.getTimezoneOffset() * 60000 + this.date.fromDate.getTime()).getTime() / 1000;
			var endDate = new Date(-this.date.toDate.getTimezoneOffset() * 60000 + this.date.toDate.getTime()).getTime() / 1000;

			// daily details
			var filteredDays = this.fullData.DailyDetails.filter((item) => {
				return (item.date >= fromDate && item.date <= endDate);
			});
	
			this.postGetDailyDetailsData(filteredDays);
			
			// events
			if(this.getTabSourceData() == 'both') {
				var filteredEvents = this.fullData.events.filter((item) => {
					return (item.data.start_time >= fromDate && item.data.endTime <= endDate);
				});
				this.postGetEventsData(filteredEvents);
			}
		});
		
	};

	getData(getData, callback) {
		if(!getData) {
			return callback();
		}
		let sourceData = this.getTabSourceData();
		let dataObj = {
			minTime:		moment(this.date.fromDate).utc().startOf('day').format("X"),
			maxTime:		moment(this.date.toDate).utc().startOf('day').format("X"),
			deviceId:		this.device.id,
			studyId:		+this.device.studyid,
			getWarranty:	true // For replacement battviews
		};

		// Make sure that daily details are exists when getting sequential, since it used in events log filters
		if(sourceData == 'both') {
			if(
				this.fullData.events.length === 0 ||
				this.fullData.fromDate > this.date.fromDate ||
				this.fullData.toDate < this.date.toDate
			) {
				if(!this.fullData.fromDate || this.date.fromDate > this.fullData.fromDate)
					this.fullData.fromDate	= this.date.fromDate;
				if(!this.fullData.toDate || this.date.toDate < this.fullData.toDate)
					this.fullData.toDate	= this.date.toDate;

				this.battviewsDashboard.getSequential(dataObj).subscribe(
					(data: any) => {
						this.fullData.events = data.list;
						this.summarySequentialObject = this.battviewsDashboard.generateSequentialSummaryData(this.fullData.events);
						this.battviewsDashboard.generateEndTemperature(this.fullData.events);
						if(this.fullData.DailyDetails.length === 0) {
							this.battviewsDashboard.getDailyDetails(dataObj).subscribe(
								(data: any)=> {
									this.fullData.DailyDetails	= data.list;
									return callback();
							});
						} else {
							return callback();
						}
				});
			} else {
				return callback();
			}
		} else if(
			this.fullData.DailyDetails.length === 0 ||
			this.fullData.fromDate > this.date.fromDate ||
			this.fullData.toDate < this.date.toDate
		) {
			if(!this.fullData.fromDate || this.date.fromDate > this.fullData.fromDate)
				this.fullData.fromDate	= this.date.fromDate;
			if(!this.fullData.toDate || this.date.toDate < this.fullData.toDate)
				this.fullData.toDate	= this.date.toDate;
			
			this.battviewsDashboard.getDailyDetails(dataObj).subscribe(
				(data: any)=> {
					this.fullData.DailyDetails	= data.list;
					this.warrantyDate = data.wdate;
					return callback();
			});
		} else {
			return callback();
		}
	}

	resetHaveData() {
		this.flushDailyDetails();
		this.flushSequential();
		this.hasData = false;
		this.updateHasData.emit(this.hasData);
		this.fullData = {
			DailyDetails: [],
			events: [],
			fromDate: null,
			toDate: null
		};
	}

	postGetDailyDetailsData(data) {
		this.DailyDetails = data;
		this.hasData = (data.length > 0);
		this.updateHasData.emit(this.hasData);    
		this.generateDailyDetailsSummaryData();
	}
	postGetEventsData(data) {
		this.events = data;
	}
	flushDailyDetails() {
		this.DailyDetails = [];
	}
	flushSequential(){
		this.events = [];
	}
	generateDailyDetailsSummaryData() {
		let maxValue = 100000000;
		let minValue = -100000000;

		var maxTemp = minValue;
		var minSOC  = maxValue;

		let DailyDetails: any = {};

		this.DailyDetails.forEach((obj) => {
			if (obj.temperatureSensorEnabled && obj.max_temperature_value > maxTemp)
				maxTemp = obj.max_temperature_value;

			if (obj.min_soc < minSOC)
				minSOC = obj.min_soc;
		});

		this.device.minSoc = minSOC;
		if (maxTemp === minValue)
			this.device.maxTemp = 'N/A';
		else
			this.device.maxTemp = maxTemp;

		if (maxTemp === minValue)
			DailyDetails.maxTemp = 'N/A';
		else
			DailyDetails.maxTemp = maxTemp;

		DailyDetails.minSoc = minSOC;
		DailyDetails.totalTime = [0, 0, 0, 0];
		DailyDetails.totalMissedEQs = 0;
		DailyDetails.totalEQ = 0;
		DailyDetails.totalEQWaterOK = 0;
		DailyDetails.totalDischargeAHR = 0;
		DailyDetails.totalchargeAHR = 0;
		DailyDetails.totalDischargeDays = 0;
		DailyDetails.missedChargersPerDay = 0;
		DailyDetails.totalMissedFinishes = 0;
		DailyDetails.reachedTheMissedDays = false;
		DailyDetails.totalRecodedDays = 0;
		DailyDetails.totalCharges = 0;
		DailyDetails.maxHoursInInUse = (-1*60*60*1000);
		DailyDetails.minHoursInInUse = (24*60*60*1000);
		DailyDetails.minHoursOfIdle = (100000*60*60*1000);
		DailyDetails.minDailyDischarge = maxValue;
		DailyDetails.maxDailyDischarge = minValue;
		DailyDetails.minAHperHr = maxValue;
		DailyDetails.maxAHPerHr = minValue;
		DailyDetails.AHPerHrSum = 0;
		DailyDetails.countOfHighTemp = 0;
		DailyDetails.isCurrentSensingIssue = false;
		DailyDetails.deepDischarge = false;
		DailyDetails.deepUse = false;
		DailyDetails.AHRReturn = 0;
		DailyDetails.totalChargeOppurtinityDuration = 0;
		DailyDetails.totalWorkingDays = 0;
		DailyDetails.countOfWorkingDays = 0;

		var totalWeekDischargeAHR = 0,
			continousMissedCharges = 0,
			minChargesPerDay = this.getMinChargesPerDay(this.device.chargertype),
			maxEbusPerWeek = this.getMaxEbusPerWeek(this.device.chargertype),
			maxMissedChargingDays = 5,
			sumChargeAs = 0,
			hasEQPeriod	= false,
			isEQNextDayWithinPeriod	= false,
			eqPeriodValue	= true,
			hasFiPeriod	= false,
			isFiNextDayWithinPeriod	= false,
			fiPeriodValue	= true,
			daysHex			= [0x01,0x02,0x04,0x08,0x10,0x20,0x40];

		if (this.device.eqtimer >= 85500) {
			hasEQPeriod = true;
		}
		if (this.device.fitimer >= 85500) {
			hasFiPeriod = true;
		}

		var sortedDailyDetails = [];
		if (this.DailyDetails.length > 0) {
			sortedDailyDetails = this.DailyDetails.sort((a,b) => {return (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0);});

			var oneDaySeconds		= 60*60*24,
				endDate = moment(this.date.toDate).utc().endOf('day').unix(),
				endOfCurrentWeek	= endDate - (7*oneDaySeconds);
		}
		var missedEqThisWeek		= true,
			thisWeekEqAfterIdleCount	= 0,
			missedFiThisWeek		= true,
			countOfValidDays		= 0;

		for (var i in sortedDailyDetails) {
			var currentday = this.DailyDetails[i];

			let inuseAsValue = currentday.inuse_as;
			if(this.userAlertsSettings && this.userAlertsSettings.bv_inuse_events_only_for_charge_ahr) {
				inuseAsValue = currentday.inuse_events_as;
			}
			let chargeAsValue = currentday.charge_as;
			if(this.userAlertsSettings && this.userAlertsSettings.bv_charge_events_only_for_charge_ahr) {
				chargeAsValue = currentday.charge_events_as;
			}

			var inuse_duration = currentday.inuse_duration * 1000;
			var inuse_ah = (+inuseAsValue) / 3600;
			var charge_ah = +(chargeAsValue) / 3600;

			var dayOfWeekNum = new Date(currentday.date * 1000).getDay();

			if (currentday.date <= endOfCurrentWeek) {
				if (countOfValidDays >= 3) {
					if (missedEqThisWeek === true && thisWeekEqAfterIdleCount != 7) {
						DailyDetails.totalMissedEQs++;
					}

					if (missedFiThisWeek) {
						DailyDetails.totalMissedFinishes++;
					}
				}

				missedEqThisWeek = true;
				thisWeekEqAfterIdleCount = 0;
				missedFiThisWeek = true;

				countOfValidDays = 0;
				endOfCurrentWeek -= 7 * oneDaySeconds;
			}

			if (typeof(currentday) !== 'object' || Object.keys(currentday).length === 0) {
				continue;
			}

			// ignore any day that doesn't have any event
			if (+currentday.charge_duration + +currentday.inuse_duration + +currentday.idle_duration === 0) {
				continue;
			}

			countOfValidDays++;

			if(currentday.charge_duration != 0 || inuse_duration != 0 || currentday.idle_duration != 0){
				DailyDetails.totalRecodedDays++;
			}else{
				DailyDetails.continousMissedCharges = 0;
			}

			if (inuse_ah > 0 && inuse_ah < DailyDetails.minDailyDischarge) {
				DailyDetails.minDailyDischarge = inuse_ah;
				DailyDetails.minDailyInuseAhrsTime = currentday.date;
			}

			if (inuse_ah > DailyDetails.maxDailyDischarge) {
				DailyDetails.maxDailyDischarge = inuse_ah;
				DailyDetails.maxDailyInuseAhrsTime = currentday.date;
			}

			if (inuse_duration > DailyDetails.maxHoursInInUse) {
				DailyDetails.maxHoursInInUse = inuse_duration;
				DailyDetails.maxHoursInInUseTime = currentday.date;
			}

			if (currentday.is_working_day) {
				// this time in microsec - not in hours
				DailyDetails.totalChargeOppurtinityDuration += currentday.charge_oppurtinity_duration;
				if (DailyDetails.minHoursOfIdle > currentday.charge_oppurtinity_duration) {
					DailyDetails.minHoursOfIdle = currentday.charge_oppurtinity_duration;
					DailyDetails.minChargeOppurtinityTime = currentday.date;
				}

				DailyDetails.totalWorkingDays++;
				if ([6,0].indexOf(dayOfWeekNum) === -1) {
					DailyDetails.countOfWorkingDays++;
				}
			}

			if (inuse_ah != 0) {
				DailyDetails.totalDischargeAHR += inuse_ah;
				DailyDetails.totalDischargeDays++;

				if(inuse_duration > 0 && currentday.is_working_day){
					var AhrPerHr = currentday.inuse_events_as / currentday.inuse_duration;
					if(DailyDetails.minAHperHr > AhrPerHr) {
						DailyDetails.minAHperHr = AhrPerHr;
						DailyDetails.minAHperHrTime = currentday.date;
					}

					if(DailyDetails.maxAHPerHr < AhrPerHr) {
						DailyDetails.maxAHPerHr = AhrPerHr;
						DailyDetails.maxAHperHrTime = currentday.date;
					}

					DailyDetails.AHPerHrSum += AhrPerHr;
					
					if(DailyDetails.minHoursInInUse > inuse_duration) {
						DailyDetails.minHoursInInUse = inuse_duration;
						DailyDetails.minHoursInInUseTime = currentday.date;
					}
				}
			}

			if (missedEqThisWeek === true && (currentday.count_of_eqs > 0 || currentday.has_eq_start)) {
				missedEqThisWeek = false;
			}

			if(currentday.eq_after_idle_day) {
				thisWeekEqAfterIdleCount++;
			}

			if (Array.isArray(this.device.fidaysmask) && (this.device.fidaysmask.length !== 7 || currentday.is_working_day)) {

				if (hasFiPeriod) {
					if (this.device.fidaysmask.indexOf(dayOfWeekNum) > -1) {

						var tempDay = new Date(currentday.date * 1000);
						tempDay.setDate(tempDay.getDate()+1);
						var nextDay = tempDay.getDay();

						isFiNextDayWithinPeriod = (this.device.fidaysmask.indexOf(nextDay) > -1);
						fiPeriodValue = fiPeriodValue && currentday.missed_fi;
						if (!isFiNextDayWithinPeriod) {
							if (missedFiThisWeek && !fiPeriodValue) {
								missedFiThisWeek = false;
							}
							fiPeriodValue = true;
						}
					}
				} else {
					if (missedFiThisWeek && !currentday.missed_fi) {
						missedFiThisWeek = false;
					}
				}
			}

			DailyDetails.totalEQ += currentday.count_of_eqs;
			DailyDetails.totalEQWaterOK += currentday.count_of_eqs_waterok;
			DailyDetails.totalchargeAHR += charge_ah;

			if (currentday.is_working_day) {
				if(currentday.total_charge_events < minChargesPerDay){
					DailyDetails.missedChargersPerDay++;
				}
			}
			if(currentday.total_charge_events > 0){
				DailyDetails.totalCharges += currentday.total_charge_events;
				continousMissedCharges = 0;
			} else {
				continousMissedCharges++;
				if(continousMissedCharges >= maxMissedChargingDays){
					DailyDetails.reachedTheMissedDays = true;
				}
			}

			if(!DailyDetails.reachedMaxedEBU){
				totalWeekDischargeAHR += inuse_ah;
				if(totalWeekDischargeAHR > (maxEbusPerWeek * .8 * this.device.AhrCapacity))
					DailyDetails.reachedMaxedEBU = true;
				totalWeekDischargeAHR = 0;
			}

			DailyDetails.countOfHighTemp += currentday.max_temperature_exceeded;

			if (currentday.sensor_issue){
				DailyDetails.isCurrentSensingIssue = true;
			}

			if (currentday.potential_week_cells_exceeded > 0){
				DailyDetails.deepUse = true;
			}

			if (currentday.deep_discharge_exceeded > 0){
				DailyDetails.deepDischarge = true;
			}

			sumChargeAs += +(chargeAsValue);

			if (!DailyDetails.temperatureSensorEnabled && currentday.temperature_sensor_enabled) {
				DailyDetails.temperatureSensorEnabled = true;
			}

			DailyDetails.totalTime[0] += currentday.charge_duration == undefined ? 0 : currentday.charge_duration * 1000;
			DailyDetails.totalTime[1] += inuse_duration == undefined ? 0 : currentday.inuse_duration * 1000;
			DailyDetails.totalTime[2] += currentday.idle_duration == undefined ? 0 : currentday.idle_duration * 1000;
		}

		if (countOfValidDays >= 3) {
			if (missedEqThisWeek === true && thisWeekEqAfterIdleCount != 7) {
				DailyDetails.totalMissedEQs++;
			}
			if (missedFiThisWeek) {
				DailyDetails.totalMissedFinishes++;
			}
		}

		if ((sumChargeAs / 3600) + this.device.AhrCapacity < DailyDetails.totalchargeAHR){
			DailyDetails.AHRReturn = 1;
		}else if((sumChargeAs / 3600) - this.device.AhrCapacity > 1.3 * DailyDetails.totalchargeAHR){
			DailyDetails.AHRReturn = -1;
		}

		DailyDetails.totalTimeOfAll = DailyDetails.totalTime[0] + DailyDetails.totalTime[1] + DailyDetails.totalTime[2];
		this.summaryObject = DailyDetails;
	}
	getMinChargesPerDay(chargerType) {
		var minChargesPerDay = 2;
		if (chargerType) {
			minChargesPerDay = 1;
		}
		return minChargesPerDay;
	}
	getMaxEbusPerWeek(chargerType) {
		var maxEbusPerWeek;
		switch (chargerType) {
			case "0":
				maxEbusPerWeek = 10;
			break;
			case "1":
				maxEbusPerWeek = 6.25;
			break;
			case "2":
				maxEbusPerWeek = 8;
			break;
		}
		return maxEbusPerWeek;
	}
	onExport() {
		let CSVcontent: any[] = this.getExportFileName();
		new ngxCsv(CSVcontent[1], CSVcontent[0]);
	}
	getExportFileName() {
		let fileName = '';
		let CSVobj: any[] = [];

		switch(this.activeTab) {
			case 'battery-usage-summary':
				fileName = 'BatteryUsageSummary.csv';
				CSVobj = this.batteryUsageSummary.getCSVcontent();
			break;
			case 'battery-daily-usage':
				fileName = 'daily_usage_'+this.device.batteryid+'_'+this.device.serialnumber+'.csv';
				CSVobj = this.batteryDailyUsage.getCSVcontent();
			break;
			case 'events-log':
				fileName = 'events_log_'+this.device.batteryid+'_'+this.device.serialnumber+'.csv';
				CSVobj = this.eventsLog.getCSVcontent();
			break;
			case 'exceptions':
				fileName = 'Settings_'+this.device.batteryid+'_'+this.device.serialnumber+'.csv';
				CSVobj = this.exceptions.getCSVcontent();
			break;
			case 'charge-summary':
				fileName = 'ChargeSummary_'+this.device.batteryid+'_'+this.device.serialnumber+'.csv';
				CSVobj = this.chargeSummary.getCSVcontent();
			break;
		}
		return [fileName, CSVobj];
	}
}