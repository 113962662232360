import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { CommonDataService } from '../../shared/services/common-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fake-data-generator',
  templateUrl: './fake-data-generator.component.html',
  styleUrls: ['./fake-data-generator.component.css']
})
export class FakeDataGeneratorComponent implements OnInit {

	deviceType: number;
	deviceId: string;
	from: Date;
	to: Date;
	maxPerDay: number;
	type: string;
	withDailyDetails: boolean;
	withAnalytics: boolean;

	deviceTypes = [
		{ name: 'Battery', id: 1 },
		{ name: 'Charger', id: 2 }
	];
	commandsList = [];

	constructor(
		private adminService: AdminService,
		private commonData: CommonDataService,
		private router: Router
	) {}

	ngOnInit(): void {
		const isProduction = window.location.hostname == this.commonData.productionDomain;
		const isStaging = window.location.hostname == this.commonData.stagingDomain;
		if(isProduction || isStaging) {
			this.router.navigate(['/unauthorized']);
			return;
		}
	}

	onSubmit() {
		this.adminService.generateData(this.deviceId, this.deviceType, this.from, this.to, this.type, this.maxPerDay).subscribe();
	}

	onDeviceTypeChange() {
		switch(this.deviceType) {
			case 1: // Battery
				this.commandsList = [
					{ name: 'Event', id: 'event' },
					{ name: 'Rt', id: 'rt' },
					{ name: 'Quick view', id: 'quick_view' },
				];
				break;
			case 2: // Charger
				this.commandsList = [
					{ name: 'Cycle record', id: 'cycle_record' },
					{ name: 'Pm faults', id: 'pm_fault' },
					{ name: 'Pm warning', id: 'pm_warning' },
					{ name: 'LCD', id: 'lcd_status' },
					{ name: 'Power snapshot', id: 'power_snapshot' },
					// { name: 'Pm state', id: 6 },
				];
				break;
			default:
				this.commandsList = [];
				break;
		}
	}
}