import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'underscore';
import { SiteDashboardService } from '../../site-dashboard.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { BattviewsDashboardService } from '../battviews-dashboard/battviews-dashboard.service';
import { ColumnChartConfig } from '../../../../shared/google-chart/Models/ColumnChartConfig';

@Component({
	selector: 'app-site-analytics',
	templateUrl: './site-analytics.component.html',
	styleUrls: ['./site-analytics.component.css']
})
export class SiteAnalyticsComponent implements OnInit {

	customerId;
	siteId;

	deviceTags;
	siteTags;

	isBattviewMobile  = false;
	hasBattviews	= true;
	hasAddNote = false;

	isReportGenerated = false;
	tags = [];
	analyticsFilter = {
		lastConnectionTime: "",
		onlyDaysThatHaveEvents : true,
		operation: {
			conventional: true,
			opportunity: true,
			fast: true
		},
		data: {
			averageEBU: {
				checked: true,
				lessThan: null,
				greaterThan: null,
				type: "inrange"
			},
			averageChargeAHRs: {
				checked: true,
				lessThan: null,
				greaterThan: null,
				type: "inrange"
			},
			totalChargeAHRs: {
				checked: true,
				lessThan: null,
				greaterThan: null,
				type: "inrange"
			},
			totalChargerKWHR: {
				checked: true,
				lessThan: null,
				greaterThan: null,
				type: "inrange"
			},
			averageChargerKWHR: {
				checked: true,
				lessThan: null,
				greaterThan: null,
				type: "inrange"
			},
			totalInuseAHRs: {
				checked: true,
				lessThan: null,
				greaterThan: null,
				type: "inrange"
			},
			averageInuseAHRs: {
				checked: true,
				lessThan: null,
				greaterThan: null,
				type: "inrange"
			},
			totalInuseKWHR: {
				checked: true,
				lessThan: null,
				greaterThan: null,
				type: "inrange"
			},
			averageInuseKWHR: {
				checked: true,
				lessThan: null,
				greaterThan: null,
				type: "inrange"
			},
			totalInUseTime: {
				checked: true,
				lessThan: null,
				greaterThan: null,
				type: "inrange"
			},
			averageInUseTime: {
				checked: true,
				lessThan: null,
				greaterThan: null,
				type: "inrange"
			},
			totalIdleTime: {
				checked: true,
				lessThan: null,
				greaterThan: null,
				type: "inrange"
			},
			averageIdleTime: {
				checked: true,
				lessThan: null,
				greaterThan: null,
				type: "inrange"
			},
			averageChargeTime: {
				checked: true,
				lessThan: null,
				greaterThan: null,
				type: "inrange"
			},
			totalChargeTime: {
				checked: true,
				lessThan: null,
				greaterThan: null,
				type: "inrange"
			}
		},
		dataCluster: "date-cluster"
	};
	date = {
		fromDate: new Date(),
		toDate: new Date(),
	};

	bfds: any[] = [];
	gridData: any = {};
	gridColumns: any = {};
	gridIds: any = {};
	chartData: any = {};
	chartConfig: any = {};

	constructor(
		private route: ActivatedRoute,
		private siteDashboardService: SiteDashboardService,
		private commonUtil: CommonUtil,
		private battviewsDashboardService: BattviewsDashboardService,
	) { }

	ngOnInit() {
		this.customerId = this.route.parent.parent.snapshot.params.customerid || 0;
		this.siteId = this.route.parent.parent.snapshot.params.siteid || 0;
		this.siteDashboardService.setActiveSubTab('site-analytics');

		this.date.fromDate.setDate(this.date.fromDate.getDate() - 14);
		this.getSiteBattviews();
	}

	generateData() {
		if (this.isReportGenerated) {
			return;
		}

		var fromDate = this.commonUtil.getUTCstartOfDay(this.date.fromDate);
		var toDate = this.commonUtil.getUTCstartOfDay(this.date.toDate);

		let params = {
			customerId	: this.customerId,
			siteId		: this.siteId,
			minTime		: fromDate,
			maxTime		: toDate,
			bfdId		: this.bfds[0].id,
			studyId		: 0	

		};
		this.siteDashboardService.getSiteDailyDetails(params).subscribe((data) => {

			this.isReportGenerated = true;
			let dataList = data;
			/*Filter Battviews Tags */
			if (this.tags.length > 0) {
				var selectedTags = _.pluck(this.tags, "id");
				
				var devicesWithSelectedTags = [], deviceSelectedTags = [], itemTags = [];
				for (var key in this.deviceTags) {
					itemTags = _.map(this.deviceTags[key], function(item) {
						return parseInt(item, 10)
					});
					deviceSelectedTags = _.intersection(itemTags, selectedTags);
					if (deviceSelectedTags.length > 0) {
						devicesWithSelectedTags.push(key);
					}
				}
				var dataListNewKeys = _.intersection(_.keys(dataList), devicesWithSelectedTags);
				var filteredDataList = {};
				_.each(dataListNewKeys, function(key) {
					filteredDataList[key] = dataList[key];
				});
				dataList = filteredDataList;
			}
			/*Filter by tags Last Connect time*/
			if (this.analyticsFilter.lastConnectionTime) {
				var now = moment(new Date()); //today's date
				var end, duration, days; 
				dataList = _.filter(dataList, function(item) {
					end = moment(item.lastconnecttime);
					duration = moment.duration(now.diff(end));
					days = duration.asDays();
				   return this.analyticsFilter.lastConnectionTime >= days;
				});
			}
			/*Filter Battviews By Operation*/
			var filteredByOperation = {}, operation, operationName;
			var operationMap = {
				0: "fast",
				1: "conventional",
				2: "opportunity"
			};
			for (var key in dataList) {
				operation = dataList[key].chargertype;
				operationName = operationMap[operation];
				if (this.analyticsFilter.operation[operationName]) {
					filteredByOperation[key] = dataList[key];
				}
			}
			dataList = filteredByOperation;
			/*Filter Battviews Events*/
			if (this.analyticsFilter.onlyDaysThatHaveEvents) {
				for (key in dataList) {
					dataList[key].events.list = _.filter(dataList[key].events.list, function(event) {
						return event.is_complete;
					});
					dataList[key].events.count = dataList[key].events.list.length;
				}
			}
			/* Draw Report Chart and Grid */
			if (this.analyticsFilter.dataCluster == "date-cluster") {
				this.drawReportsByDateCluster(dataList);
			} else if (this.analyticsFilter.dataCluster == "battview-cluster") {
				this.drawReportsByBattviewCluster(dataList);
			}
		});
	}

	restartReport() {
		this.isReportGenerated = false;
		// $('html,body').animate({ scrollTop: 0 }, 'slow');
		// $("#average-ebu-chart").html("");
		// $("#charge-ahrs-chart").html("");
		// $("#inuse-ahrs-chart").html("");
		// $("#inuse-time-chart").html("");
		// $("#idle-time-chart").html("");
		// $("#charge-time-chart").html("");
		// $("#charger-kwhr-chart").html("");
		// $("#inuse-kwhr-chart").html("");
	}
	
	getdateFormatted(date) {
		var now = new Date();
		var diff = (now.getHours() - now.getUTCHours()) * 3600 * 1000;
		// shift by diff to make it GMT then shift 12 hours to appear date centeralized in chart
		var d = new Date(date - diff + (12*3600*1000));
		if (d !== undefined) {
			return d;
		} else {
			return new Date();
		}
	}

	getDataClusteredByDate(dataList) {
		var dataByDate = {},
		 item,
		 date,
		 isWorkingDayValue, 
		 chargeValue,
		 chargerKWHValue,
		 inuseValue,
		 inUseKWHValue,
		 inuseTimeValue,
		 idleTimeValue,
		 chargeTimeValue;

		for (var key in dataList) {

			for (var i = 0; i < dataList[key].events.list.length; i++) {
				
				item = dataList[key].events.list[i];
				
				date = (+moment(item.date * 1000).utc().startOf('day').format("X")) * 1000;
				if (!dataByDate[date]) {
					dataByDate[date] = {date: date};
				}

				/* Prepare Average EBU DATA */
				isWorkingDayValue = item.is_working_day;
				if (isWorkingDayValue) {
					if (dataByDate[date]['working_day_counts']) {
						dataByDate[date]['working_day_counts']++;
					} else {
						dataByDate[date]['working_day_counts'] = 1;
					}

					if (dataList[key].ahrcapacity > 0 ) {
						 if (dataByDate[date]['EBU_DAY']) {
							 dataByDate[date]['EBU_DAY'] +=  item.inuse_as/ (3600*dataList[key].ahrcapacity);
						 } else {
							dataByDate[date]['EBU_DAY'] = item.inuse_as/ (3600*dataList[key].ahrcapacity);
						}
					 }
				}
				
				/* Prepare Charge AH DATA */
				chargeValue = item.charge_as / 3600;
				if (dataByDate[date]['total_charge_ah']) {
					dataByDate[date]['total_charge_ah'] = dataByDate[date]['total_charge_ah'] + chargeValue;
					if (chargeValue > 0) {
						dataByDate[date]['charge_counts']++;
					}
					dataByDate[date]['charge_as'].push(item.charge_as);
				} else {
					dataByDate[date]['total_charge_ah'] = chargeValue;
					if (chargeValue > 0) {
						dataByDate[date]['charge_counts'] = 1
					} else {
						dataByDate[date]['charge_counts'] = 0;
					}
					dataByDate[date]['charge_as'] = [item.charge_as];
				}

				/* Prepare Charger AH DATA */
				chargerKWHValue = item.charge_ws / (3600 * 1000);
				if (dataByDate[date]['total_charger_wh']) {
					dataByDate[date]['total_charger_wh'] = dataByDate[date]['total_charger_wh'] + chargerKWHValue;
					if (chargeValue > 0) {
						dataByDate[date]['charger_wh_counts']++;
					}
				} else {
					dataByDate[date]['total_charger_wh'] = chargerKWHValue;
					if (chargerKWHValue > 0) {
						dataByDate[date]['charger_wh_counts'] = 1
					} else {
						dataByDate[date]['charger_wh_counts'] = 0;
					}
				}

				/* Prepare InUse AH DATA */
				inuseValue = item.inuse_as / 3600;
				if (dataByDate[date]['total_inuse_ah']) {
					dataByDate[date]['total_inuse_ah'] = dataByDate[date]['total_inuse_ah'] + inuseValue;
					if (inuseValue > 0) {
						dataByDate[date]['inuse_counts']++;
					}
					dataByDate[date]['inuse_as'].push(item.inuse_as);
				} else {
					dataByDate[date]['total_inuse_ah'] = inuseValue;
					if (inuseValue > 0) {
						dataByDate[date]['inuse_counts'] = 1;
					} else {
						dataByDate[date]['inuse_counts'] = 0;
					}
					dataByDate[date]['inuse_as'] = [item.inuse_as];
				}

				/* Prepare InUse KWHR DATA */
				inUseKWHValue = item.inuse_ws / (3600 * 1000);
				if (dataByDate[date]['total_inuse_kwhr']) {
					dataByDate[date]['total_inuse_kwhr'] = dataByDate[date]['total_inuse_kwhr'] + inUseKWHValue;
					if (chargeValue > 0) {
						dataByDate[date]['inuse_kwhr_counts']++;
					}
				} else {
					dataByDate[date]['total_inuse_kwhr'] = inUseKWHValue;
					if (inUseKWHValue > 0) {
						dataByDate[date]['inuse_kwhr_counts'] = 1
					} else {
						dataByDate[date]['inuse_kwhr_counts'] = 0;
					}
				}

				/* Prepare In Use Time DATA from seconds to hours*/
				inuseTimeValue = item.inuse_duration / 3600;
				if (dataByDate[date]['total_inuse_duration']) {
					dataByDate[date]['total_inuse_duration'] = dataByDate[date]['total_inuse_duration'] + inuseTimeValue;
					if (inuseTimeValue > 0) {
						dataByDate[date]['inuse_duration_counts']++;
					}
					dataByDate[date]['inuse_duration'].push(item.inuse_duration);
				} else {
					dataByDate[date]['total_inuse_duration'] = inuseTimeValue;
					if (inuseTimeValue > 0) {
						dataByDate[date]['inuse_duration_counts'] = 1
					} else {
						dataByDate[date]['inuse_duration_counts'] = 0;
					}
					dataByDate[date]['inuse_duration'] = [item.inuse_duration];
				}

				/* Prepare Idle Time DATA, fro seconds to hours*/
				idleTimeValue = item.idle_duration / 3600;
				if (dataByDate[date]['total_idle_duration']) {
					dataByDate[date]['total_idle_duration'] = dataByDate[date]['total_idle_duration'] + idleTimeValue;
					if (idleTimeValue > 0) {
						dataByDate[date]['idle_duration_counts']++;
					}
					dataByDate[date]['idle_duration'].push(idleTimeValue);
				} else {
					dataByDate[date]['total_idle_duration'] = idleTimeValue;
					if (idleTimeValue > 0) {
						dataByDate[date]['idle_duration_counts'] = 1
					} else {
						dataByDate[date]['idle_duration_counts'] = 0;
					}
					dataByDate[date]['idle_duration'] = [idleTimeValue];
				}

				/* Prepare Charge Time DATA convert from seconds to hours*/
				chargeTimeValue = item.charge_duration / 3600;
				if (dataByDate[date]['total_charge_duration']) {
					dataByDate[date]['total_charge_duration'] = dataByDate[date]['total_charge_duration'] + chargeTimeValue;
					if (chargeTimeValue > 0) {
						dataByDate[date]['charge_duration_counts']++;
					}
					dataByDate[date]['charge_duration'].push(chargeTimeValue);
				} else {
					dataByDate[date]['total_charge_duration'] = chargeTimeValue;
					if (chargeTimeValue > 0) {
						dataByDate[date]['charge_duration_counts'] = 1
					} else {
						dataByDate[date]['charge_duration_counts'] = 0;
					}
					dataByDate[date]['charge_duration'] = [chargeTimeValue];
				}
			}
		}
		// EBU
		var EBU, values;
		for (let date in dataByDate) {
			if (dataByDate[date].EBU_DAY) {
				  dataByDate[date]['average_ebu'] = dataByDate[date].EBU_DAY / (0.8 * dataByDate[date]['working_day_counts']);
			 } else {
				   dataByDate[date]['average_ebu'] = 0;
		  }
		}
		return dataByDate;
	}

	getDataClusteredByBattview(dataList) {
		
		for (var key in dataList) {
			var charge_as_counts = 0,
				 charge_ws_counts = 0,
				 inuse_as_counts = 0,
				 inuse_kwhr_counts = 0,
				 inuse_duration_counts = 0,
				 idle_duration_counts = 0,
				 charge_duration_counts = 0,
				 working_days_counts = 0;
			
			// counts of working days
			for (var i=0; i< dataList[key].events.list.length; i++) {
				if (dataList[key].events.list[i].is_working_day) {
					++working_days_counts;
				}
			}
			dataList[key]['working_days_counts'] = working_days_counts;
			
			// total & average Charge AHRs
			var totalChargeAS = 0;
			if(dataList[key].events.list.length > 0) {
				totalChargeAS = _.reduce(
					_.pluck(dataList[key].events.list, "charge_as"), 
					function (memoizer, number) {
						if (number > 0) {
							++charge_as_counts
						}
						return memoizer + number;
					});
			}
			var totalChargeAH = totalChargeAS / 3600;
			
			let averageChargeAH = 0;
			if (charge_as_counts > 0) {
				averageChargeAH = totalChargeAH / charge_as_counts;
			}
			dataList[key]['total_charge_ah'] = totalChargeAH ? totalChargeAH : 0;
			dataList[key]['average_charge_ah'] = averageChargeAH;

			// total & average Charge KWHR
			var totalChargeWS = 0;
			if(dataList[key].events.list.length > 0) {
				totalChargeWS = _.reduce(
					_.pluck(dataList[key].events.list, "charge_ws"), 
					function (memoizer, number) {
						if (number > 0) {
							++charge_ws_counts
						}
						return memoizer + number;
					});
			}
			var totalChargeKWHR = totalChargeWS / (3600 * 1000);
			
			let averageChargeKWHR = 0;
			if (charge_ws_counts > 0) {
				averageChargeKWHR = totalChargeKWHR / charge_ws_counts;
			}
			dataList[key]['total_charge_kwhr'] = totalChargeKWHR ? totalChargeKWHR : 0;
			dataList[key]['average_charge_kwhr'] = averageChargeKWHR;

			// total & average In Use KWHR
			var totalInuseAs = 0;
			if(dataList[key].events.list.length > 0) {
				totalInuseAs = _.reduce(
					_.pluck(dataList[key].events.list, "inuse_as"), 
					function (memoizer, number) {
						if (number > 0) {
							++inuse_as_counts;
						}
						return memoizer + number;
					});
			}
			var totalInuseAH = totalInuseAs / 3600;
			let averageInUseAH = 0;
			if (inuse_as_counts > 0) {
				averageInUseAH = totalInuseAH / inuse_as_counts;
			}
			dataList[key]['total_inuse_ah'] = totalInuseAH ? totalInuseAH : 0 ;
			dataList[key]['average_inuse_ah'] = averageInUseAH ? averageInUseAH : 0;
			
			// total & average In Use KWHR
			let totalInuseWs = 0;
			if(dataList[key].events.list.length > 0) {
				totalInuseWs = _.reduce(
					_.pluck(dataList[key].events.list, "inuse_ws"), 
					function (memoizer, number) {
						if (number > 0) {
							++inuse_kwhr_counts;
						}
						return memoizer + number;
					});
			}
			let totalInuseKWHR = totalInuseWs / (3600 * 1000);
			let averageInuseKWHR = 0;
			if (inuse_kwhr_counts > 0) {
				averageInuseKWHR = totalInuseKWHR / inuse_kwhr_counts;
			}
			dataList[key]['total_inuse_kwhr'] = totalInuseKWHR ? totalInuseKWHR : 0 ;
			dataList[key]['average_inuse_kwhr'] = averageInuseKWHR ? averageInuseKWHR : 0;
			
			// total & average inuse time
			let totalInUseTime = 0;
			if(dataList[key].events.list.length > 0) {
				totalInUseTime = _.reduce(
					_.pluck(dataList[key].events.list, "inuse_duration"),
					function (memoizer, number) {
						if (number > 0) {
							++inuse_duration_counts;
						}
						return memoizer + number;
					});
			}
			// from seconds to hours
			var totalInUseTimeDays = totalInUseTime / 3600;
			let averageInUseTime = 0;
			if (inuse_duration_counts > 0) {
				averageInUseTime = totalInUseTimeDays / inuse_duration_counts;
			}
			dataList[key]['total_inuse_time'] = totalInUseTimeDays;
			dataList[key]['average_inuse_time'] = averageInUseTime;
			// total & average Idle time
			let totalIdleTime = 0;
			if(dataList[key].events.list.length > 0) {
				totalIdleTime = _.reduce(
					_.pluck(dataList[key].events.list, "idle_duration"),
					function (memoizer, number) {
						if (number > 0) {
							++idle_duration_counts;
						}
						return memoizer + number;
					});
			}
			//from seconds to hours
			var totalIdleTimeDays = totalIdleTime / 3600;
			let averageIdleTime = 0;
			if (idle_duration_counts > 0) {
				averageIdleTime = totalIdleTimeDays / idle_duration_counts;
			}
			dataList[key]['total_idle_time'] = totalIdleTimeDays;
			dataList[key]['average_idle_time'] = averageIdleTime ;
			// total & average charge time
			let totalChargeTime = 0;
			if(dataList[key].events.list.length > 0) {
				totalChargeTime = _.reduce(
					_.pluck(dataList[key].events.list, "charge_duration"),
					function (memoizer, number) {
						if (number > 0) {
							++charge_duration_counts;
						}
						return memoizer + number;
					});
			}
			// from seconds to hours
			var totalChargeTimeDays = totalChargeTime / 3600;
			let averageChargeTime = 0;
			if (charge_duration_counts) {
				averageChargeTime = totalChargeTimeDays / charge_duration_counts;
			}
			dataList[key]['total_charge_time'] = totalChargeTimeDays;
			dataList[key]['average_charge_time'] = averageChargeTime;
		}

		//Average EBU 
		//var EBU = totalInuseAH / (3600 * 0.8 * dataList[key].ahrcapacity * #of working days);
		var EBU;
		for (var key in dataList) {
			if (dataList[key].working_days_counts == 0) {
				EBU = 0;
			} else {
				 EBU = dataList[key].total_inuse_ah / (0.8 * dataList[key].ahrcapacity * dataList[key].working_days_counts);
			 }
			 dataList[key]['average_ebu'] = EBU;
		}
		return dataList;
	}


	drawReportsByDateCluster(dataList) {
		
		/* STEP 1 : PREPARE DATA CLUSTERED BY DATE */
		var dataByDate = this.getDataClusteredByDate(dataList);
		/* STEP 2: FILTER OUT DATA BASED ON USER'S CONDITIONS */
		var avg, pass, passAvg, passTotal, passInverse1, passInverse2;

		for (var date in dataByDate) {
			/* Chart: Average EBU */
			if (this.analyticsFilter.data.averageEBU.checked) {
				var avg = dataByDate[date].average_ebu;
				pass = true, passInverse1 = true, passInverse2 = true;
				if (this.analyticsFilter.data.averageEBU.greaterThan) {
					if (this.analyticsFilter.data.averageEBU.type == "inrange") {
						pass = avg > this.analyticsFilter.data.averageEBU.greaterThan;
						if (!pass) {
							delete dataByDate[date];
							continue;
						}
					} else {
						passInverse1 = avg < this.analyticsFilter.data.averageEBU.greaterThan;
					}
					
				}
				if (this.analyticsFilter.data.averageEBU.lessThan) {
					if (this.analyticsFilter.data.averageEBU.type == "inrange") {
						pass = avg < this.analyticsFilter.data.averageEBU.lessThan;
						if (!pass) {
							delete dataByDate[date];
							continue;
						}
					} else {
						passInverse2 =  avg > this.analyticsFilter.data.averageEBU.lessThan;
					}
				}
				
				if (!passInverse1 && !passInverse2) {
					delete dataByDate[date];
					continue;
				}
			}
			/* Chart : Total and Average Charge AH */
			if (this.analyticsFilter.data.totalChargeAHRs.checked || this.analyticsFilter.data.averageChargeAHRs.checked) {
				// Average charge AH 
				if (this.analyticsFilter.data.averageChargeAHRs.checked) {
					passAvg = true, passInverse1 = true, passInverse2 = true;

					avg = dataByDate[date].charge_counts > 0 ? dataByDate[date].total_charge_ah / dataByDate[date].charge_counts : 0;
					if (this.analyticsFilter.data.averageChargeAHRs.lessThan) {
						if (this.analyticsFilter.data.averageChargeAHRs.type == "inrange") {
							passAvg = avg < this.analyticsFilter.data.averageChargeAHRs.lessThan;
							if (!passAvg) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse1 = avg > this.analyticsFilter.data.averageChargeAHRs.lessThan;
						}
					}
					if (this.analyticsFilter.data.averageChargeAHRs.greaterThan) {
						if (this.analyticsFilter.data.averageChargeAHRs.type == "inrange") {
							passAvg = avg > this.analyticsFilter.data.averageChargeAHRs.greaterThan;
							if (!passAvg) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse2 = avg < this.analyticsFilter.data.averageChargeAHRs.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataByDate[date];
						continue;
					}
				}
				// Total charge AH 
				if (this.analyticsFilter.data.totalChargeAHRs.checked) {
					passTotal = true, passInverse1 = true, passInverse2 = true;
					if (this.analyticsFilter.data.totalChargeAHRs.lessThan) {
						
						if (this.analyticsFilter.data.totalChargeAHRs.type == "inrange") {
							passTotal = dataByDate[date].total_charge_ah < this.analyticsFilter.data.totalChargeAHRs.lessThan;
							if (!passTotal) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse1 = dataByDate[date].total_charge_ah > this.analyticsFilter.data.totalChargeAHRs.lessThan;
						}
					}
					if (this.analyticsFilter.data.totalChargeAHRs.greaterThan) {
						
						if (this.analyticsFilter.data.totalChargeAHRs.type == "inrange") {
							passTotal = dataByDate[date].total_charge_ah > this.analyticsFilter.data.totalChargeAHRs.greaterThan;
							if (!passTotal) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse2 = dataByDate[date].total_charge_ah < this.analyticsFilter.data.totalChargeAHRs.greaterThan;
						}
					}

					if (!passInverse1 && !passInverse2) {
						delete dataByDate[date];
						continue;
					}
				}
			}


			/* Chart : Total and Average Charger KWHR */
			if (this.analyticsFilter.data.averageChargerKWHR.checked || this.analyticsFilter.data.totalChargerKWHR.checked) {
				// Average charge AH 
				if (this.analyticsFilter.data.averageChargerKWHR.checked) {
					passAvg = true, passInverse1 = true, passInverse2 = true;

					avg = dataByDate[date].charger_wh_counts > 0 ? dataByDate[date].total_charger_wh / dataByDate[date].charger_wh_counts : 0;
					if (this.analyticsFilter.data.averageChargerKWHR.lessThan) {
						if (this.analyticsFilter.data.averageChargerKWHR.type == "inrange") {
							passAvg = avg < this.analyticsFilter.data.averageChargerKWHR.lessThan;
							if (!passAvg) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse1 = avg > this.analyticsFilter.data.averageChargerKWHR.lessThan;
						}
					}
					if (this.analyticsFilter.data.averageChargerKWHR.greaterThan) {
						if (this.analyticsFilter.data.averageChargerKWHR.type == "inrange") {
							passAvg = avg > this.analyticsFilter.data.averageChargerKWHR.greaterThan;
							if (!passAvg) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse2 = avg < this.analyticsFilter.data.averageChargerKWHR.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataByDate[date];
						continue;
					}
				}
				// Total charger KWHR 
				if (this.analyticsFilter.data.totalChargerKWHR.checked) {
					passTotal = true, passInverse1 = true, passInverse2 = true;
					if (this.analyticsFilter.data.totalChargerKWHR.lessThan) {
						
						if (this.analyticsFilter.data.totalChargerKWHR.type == "inrange") {
							passTotal = dataByDate[date].total_charger_wh < this.analyticsFilter.data.totalChargerKWHR.lessThan;
							if (!passTotal) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse1 = dataByDate[date].total_charger_wh > this.analyticsFilter.data.totalChargerKWHR.lessThan;
						}
					}
					if (this.analyticsFilter.data.totalChargerKWHR.greaterThan) {
						
						if (this.analyticsFilter.data.totalChargerKWHR.type == "inrange") {
							passTotal = dataByDate[date].total_charger_wh > this.analyticsFilter.data.totalChargerKWHR.greaterThan;
							if (!passTotal) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse2 = dataByDate[date].total_charger_wh < this.analyticsFilter.data.totalChargerKWHR.greaterThan;
						}
					}

					if (!passInverse1 && !passInverse2) {
						delete dataByDate[date];
						continue;
					}
				}
			}

			/* Chart : Total and Average In Use AH */
			if (this.analyticsFilter.data.totalInuseAHRs.checked || this.analyticsFilter.data.averageInuseAHRs.checked) {
				if (this.analyticsFilter.data.averageInuseAHRs.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					avg = dataByDate[date].inuse_counts > 0 ? dataByDate[date].total_inuse_ah / dataByDate[date].inuse_counts : 0;
					
					if (this.analyticsFilter.data.averageInuseAHRs.lessThan) {
						
						if (this.analyticsFilter.data.averageInuseAHRs.type == "inrange") {
							pass = avg < this.analyticsFilter.data.averageInuseAHRs.lessThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse1 = avg > this.analyticsFilter.data.averageInuseAHRs.lessThan;
						}
					}
					if (this.analyticsFilter.data.averageInuseAHRs.greaterThan) {
						
						if (this.analyticsFilter.data.averageInuseAHRs.type == "inrange") {
							pass = avg > this.analyticsFilter.data.averageInuseAHRs.greaterThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse2 = avg < this.analyticsFilter.data.averageInuseAHRs.greaterThan;
						}
					}

					if (!passInverse1 && !passInverse2) {
						delete dataByDate[date];
						continue;
					}
				}
				// Total InUse AH 
				if (this.analyticsFilter.data.totalInuseAHRs.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;

					if (this.analyticsFilter.data.totalInuseAHRs.lessThan) {
						
						if (this.analyticsFilter.data.totalInuseAHRs.type == "inrange") {
							pass = dataByDate[date].total_inuse_ah < this.analyticsFilter.data.totalInuseAHRs.lessThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse1 = dataByDate[date].total_inuse_ah > this.analyticsFilter.data.totalInuseAHRs.lessThan;
						}
					}
					if (this.analyticsFilter.data.totalInuseAHRs.greaterThan) {
						
						if (this.analyticsFilter.data.totalInuseAHRs.type == "inrange") {
							pass = dataByDate[date].total_inuse_ah > this.analyticsFilter.data.totalInuseAHRs.greaterThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse2 = dataByDate[date].total_inuse_ah < this.analyticsFilter.data.totalInuseAHRs.greaterThan;
						}
					}

					if (!passInverse1 && !passInverse2) {
						delete dataByDate[date];
						continue;
					}
				}
			}


			/* Chart : Total and Average In Use KWHR */
			if (this.analyticsFilter.data.totalInuseKWHR.checked || this.analyticsFilter.data.averageInuseKWHR.checked) {
				if (this.analyticsFilter.data.averageInuseKWHR.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					avg = dataByDate[date].inuse_kwhr_counts > 0 ? dataByDate[date].total_inuse_kwhr / dataByDate[date].inuse_kwhr_counts : 0;
					
					if (this.analyticsFilter.data.averageInuseKWHR.lessThan) {
						if (this.analyticsFilter.data.averageInuseKWHR.type == "inrange") {
							pass = avg < this.analyticsFilter.data.averageInuseKWHR.lessThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse1 = avg > this.analyticsFilter.data.averageInuseKWHR.lessThan;
						}
					}
					if (this.analyticsFilter.data.averageInuseKWHR.greaterThan) {
						if (this.analyticsFilter.data.averageInuseKWHR.type == "inrange") {
							pass = avg > this.analyticsFilter.data.averageInuseKWHR.greaterThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse2 = avg < this.analyticsFilter.data.averageInuseKWHR.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataByDate[date];
						continue;
					}
				}
				// Total InUse KWHR 
				if (this.analyticsFilter.data.totalInuseKWHR.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					if (this.analyticsFilter.data.totalInuseKWHR.lessThan) {
						if (this.analyticsFilter.data.totalInuseKWHR.type == "inrange") {
							pass = dataByDate[date].total_inuse_kwhr < this.analyticsFilter.data.totalInuseKWHR.lessThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse1 = dataByDate[date].total_inuse_kwhr > this.analyticsFilter.data.totalInuseKWHR.lessThan;
						}
					}
					if (this.analyticsFilter.data.totalInuseKWHR.greaterThan) {
						if (this.analyticsFilter.data.totalInuseKWHR.type == "inrange") {
							pass = dataByDate[date].total_inuse_kwhr > this.analyticsFilter.data.totalInuseKWHR.greaterThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse2 = dataByDate[date].total_inuse_kwhr < this.analyticsFilter.data.totalInuseKWHR.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataByDate[date];
						continue;
					}
				}
			}

			/* Chart : Total and Average In Use Time */
			if (this.analyticsFilter.data.totalInUseTime.checked || this.analyticsFilter.data.averageInUseTime.checked) {
				// Average InUse AH 
				if (this.analyticsFilter.data.averageInUseTime.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					avg = dataByDate[date].inuse_duration_counts > 0 ? dataByDate[date].total_inuse_duration / dataByDate[date].inuse_duration_counts : 0;
					
					if (this.analyticsFilter.data.averageInUseTime.lessThan) {
						
						if (this.analyticsFilter.data.averageInUseTime.type == "inrange") {
							pass = avg < this.analyticsFilter.data.averageInUseTime.lessThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse1 = avg > this.analyticsFilter.data.averageInUseTime.lessThan;
						}
					}
					if (this.analyticsFilter.data.averageInUseTime.greaterThan) {
						pass = avg > this.analyticsFilter.data.averageInUseTime.greaterThan;
						
						if (this.analyticsFilter.data.averageInUseTime.type == "inrange") {
							pass = avg > this.analyticsFilter.data.averageInUseTime.greaterThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse2 = avg < this.analyticsFilter.data.averageInUseTime.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataByDate[date];
						continue;
					}
				}
				// Total InUse AH 
				if (this.analyticsFilter.data.totalInUseTime.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					if (this.analyticsFilter.data.totalInUseTime.lessThan) {
						
						if (this.analyticsFilter.data.totalInUseTime.type == "inrange") {
							pass = dataByDate[date].total_inuse_duration < this.analyticsFilter.data.totalInUseTime.lessThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse1 = dataByDate[date].total_inuse_duration > this.analyticsFilter.data.totalInUseTime.lessThan;
						}
					}
					if (this.analyticsFilter.data.totalInUseTime.greaterThan) {
						
						if (this.analyticsFilter.data.totalInUseTime.type == "inrange") {
							pass = dataByDate[date].total_inuse_duration > this.analyticsFilter.data.totalInUseTime.greaterThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse2 = dataByDate[date].total_inuse_duration < this.analyticsFilter.data.totalInUseTime.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataByDate[date];
						continue;
					}
				}
			}
			/* Chart : Total and Average Idle Time */
			if (this.analyticsFilter.data.totalIdleTime.checked || this.analyticsFilter.data.averageIdleTime.checked) {
				// Average Idle Time 
				if (this.analyticsFilter.data.averageIdleTime.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					avg = dataByDate[date].idle_duration_counts > 0 ? dataByDate[date].total_idle_duration / dataByDate[date].idle_duration_counts : 0;
					
					if (this.analyticsFilter.data.averageIdleTime.lessThan) {
						
						if (this.analyticsFilter.data.averageIdleTime.type == "inrange") {
							pass = avg < this.analyticsFilter.data.averageIdleTime.lessThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse1 = avg > this.analyticsFilter.data.averageIdleTime.lessThan;
						}
					}
					if (this.analyticsFilter.data.averageIdleTime.greaterThan) {
						
						if (this.analyticsFilter.data.averageIdleTime.type == "inrange") {
							pass = avg > this.analyticsFilter.data.averageIdleTime.greaterThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse2 = avg < this.analyticsFilter.data.averageIdleTime.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataByDate[date];
						continue;
					}
				}
				// Total Idle Time 
				if (this.analyticsFilter.data.totalIdleTime.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					if (this.analyticsFilter.data.totalIdleTime.lessThan) {
						
						if (this.analyticsFilter.data.totalIdleTime.type == "inrange") {
							pass = dataByDate[date].total_idle_duration < this.analyticsFilter.data.totalIdleTime.lessThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse1 = dataByDate[date].total_idle_duration > this.analyticsFilter.data.totalIdleTime.lessThan;
						}
					}
					if (this.analyticsFilter.data.totalIdleTime.greaterThan) {
						
						if (this.analyticsFilter.data.totalIdleTime.type == "inrange") {
							pass = dataByDate[date].total_idle_duration > this.analyticsFilter.data.totalIdleTime.greaterThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse2 = dataByDate[date].total_idle_duration < this.analyticsFilter.data.totalIdleTime.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataByDate[date];
						continue;
					}
				}
			}
			/*Charge Duration*/
			if (this.analyticsFilter.data.totalChargeTime.checked || this.analyticsFilter.data.averageChargeTime.checked) {
				// Average Charge Time 
				if (this.analyticsFilter.data.averageChargeTime.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					avg = dataByDate[date].charge_duration_counts > 0 ? dataByDate[date].total_charge_duration / dataByDate[date].charge_duration_counts : 0;
					
					if (this.analyticsFilter.data.averageChargeTime.lessThan) {
						
						if (this.analyticsFilter.data.averageChargeTime.type == "inrange") {
							pass = avg < this.analyticsFilter.data.averageChargeTime.lessThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse1 = avg > this.analyticsFilter.data.averageChargeTime.lessThan;
						}
					}
					if (this.analyticsFilter.data.averageChargeTime.greaterThan) {
						
						if (this.analyticsFilter.data.averageChargeTime.type == "inrange") {
							pass = avg > this.analyticsFilter.data.averageChargeTime.greaterThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse2 = avg < this.analyticsFilter.data.averageChargeTime.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataByDate[date];
						continue;
					}
				}
				// Total Charge Time 
				if (this.analyticsFilter.data.totalChargeTime.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;

					if (this.analyticsFilter.data.totalChargeTime.lessThan) {
						
						if (this.analyticsFilter.data.totalChargeTime.type == "inrange") {
							pass = dataByDate[date].total_charge_duration < this.analyticsFilter.data.totalChargeTime.lessThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse1 = dataByDate[date].total_charge_duration > this.analyticsFilter.data.totalChargeTime.lessThan;
						}
					}
					if (this.analyticsFilter.data.totalChargeTime.greaterThan) {
						if (this.analyticsFilter.data.totalChargeTime.type == "inrange") {
							pass = dataByDate[date].total_charge_duration > this.analyticsFilter.data.totalChargeTime.greaterThan;
							if (!pass) {
								delete dataByDate[date];
								continue;
							}
						} else {
							passInverse2 = dataByDate[date].total_charge_duration < this.analyticsFilter.data.totalChargeTime.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataByDate[date];
						continue;
					}
				}
			}
		}
		/* after deleting all data that does not apply on all conditions, we will draw the charts */
		/* STEP 3: PREPARE CHART ROWS AND GRID COLUMNS */
		var avg, 
		 avgEBURow,
		 avgEBURows = [],
		 avgEBUGridItem,
		 avgEBUGridItems = [],
		 avgChargeAHRow, 
		 avgChargeAHRows = [],
		 avgChargerKWHRow,
		 avgChargerKWHRows = [],
		 avgChargeAHGridItem, 
		 avgChargeAHGridItems = [],
		 avgChargerKWHGridItem,
		 avgChargerKWHGridItems = [],
		 inuseRow, 
		 inuseRows = [],
		 inuseGridItem,
		 inuseGridItems = [],
		 inuseKWHRRow,
		 inuseKWHRRows = [],
		 inuseKWHRGridItem,
		 inuseKWHRGridItems = [],
		 inuseTimeRow,
		 inuseTimeRows = [],
		 inuseTimeGridItem,
		 inuseTimeGridItems= [],
		 timeInWords,
		 idleTimeRow,
		 idleTimeRows = [],
		 idleTimeGridItem,
		 idleTimeGridItems = [],
		 chargeTimeRow,
		 chargeTimeRows = [],
		 chargeTimeGridItem,
		 chargeTimeGridItems = [];


		for (var date in dataByDate) {
			var formattedDate = this.getdateFormatted(dataByDate[date].date);
			/* Chart: Average EBU */
			if (this.analyticsFilter.data.averageEBU.checked) {
				avg = dataByDate[date].average_ebu;
				avgEBURow = [
					formattedDate,
					avg
				];
				avgEBUGridItem = {
					date: formattedDate,
					average_ebu: Math.round(avg*100)/100
				};
				avgEBURows.push(avgEBURow);
				avgEBUGridItems.push(avgEBUGridItem);
			}
			/* Chart : Total and Average Charge AH */
			if (this.analyticsFilter.data.totalChargeAHRs.checked || this.analyticsFilter.data.averageChargeAHRs.checked) {
				avgChargeAHRow = [
					formattedDate
				];
				avgChargeAHGridItem = {
					date: formattedDate
				};
				if (this.analyticsFilter.data.averageChargeAHRs.checked) {
					avg = dataByDate[date].charge_counts > 0 ? dataByDate[date].total_charge_ah / dataByDate[date].charge_counts : 0;
					avgChargeAHRow.push(avg);
					avgChargeAHGridItem.average_charge_ahrs = Math.round(avg);
				}
				if (this.analyticsFilter.data.totalChargeAHRs.checked) {
					avgChargeAHRow.push(dataByDate[date].total_charge_ah);
					avgChargeAHGridItem.total_charge_ahrs = Math.round(dataByDate[date].total_charge_ah);
				}
				avgChargeAHRows.push(avgChargeAHRow);
				avgChargeAHGridItems.push(avgChargeAHGridItem);
			}
			/* Chart : Total and Average Charger KWHR */
			if (this.analyticsFilter.data.totalChargerKWHR.checked || this.analyticsFilter.data.averageChargerKWHR.checked) {
				avgChargerKWHRow = [
					formattedDate
				];
				avgChargerKWHGridItem = {
					date: formattedDate
				};
				if (this.analyticsFilter.data.averageChargerKWHR.checked) {
					avg = dataByDate[date].charger_wh_counts > 0 ? dataByDate[date].total_charger_wh / dataByDate[date].charger_wh_counts : 0;
					avgChargerKWHRow.push(avg);
					avgChargerKWHGridItem.average_charger_wh = Math.round(avg);
				}
				if (this.analyticsFilter.data.totalChargerKWHR.checked) {
					avgChargerKWHRow.push(dataByDate[date].total_charger_wh);
					avgChargerKWHGridItem.total_charger_wh = Math.round(dataByDate[date].total_charger_wh);
				}
				avgChargerKWHRows.push(avgChargerKWHRow);
				avgChargerKWHGridItems.push(avgChargerKWHGridItem);
			}
			/* Chart : Total and Average In Use AH */
			if (this.analyticsFilter.data.totalInuseAHRs.checked || this.analyticsFilter.data.averageInuseAHRs.checked) {
				inuseRow = [
					formattedDate
				];
				inuseGridItem = {
					date: formattedDate
				};
				if (this.analyticsFilter.data.averageInuseAHRs.checked) {
					avg = dataByDate[date].inuse_counts > 0 ? dataByDate[date].total_inuse_ah / dataByDate[date].inuse_counts : 0;
					inuseRow.push(avg);
					inuseGridItem.average_inuse_ahrs = Math.round(avg);
				}
				if (this.analyticsFilter.data.totalInuseAHRs.checked) {
					inuseRow.push(dataByDate[date].total_inuse_ah);
					inuseGridItem.total_inuse_ahrs = Math.round(dataByDate[date].total_inuse_ah);
				}
				inuseRows.push(inuseRow);
				inuseGridItems.push(inuseGridItem);
			}
			/* Chart : Total and Average In Use AH */
			if (this.analyticsFilter.data.totalInuseKWHR.checked || this.analyticsFilter.data.averageInuseKWHR.checked) {
				inuseKWHRRow = [
					formattedDate
				];
				inuseKWHRGridItem = {
					date: formattedDate
				};
				if (this.analyticsFilter.data.averageInuseKWHR.checked) {
					avg = dataByDate[date].inuse_kwhr_counts > 0 ? dataByDate[date].total_inuse_kwhr / dataByDate[date].inuse_kwhr_counts : 0;
					inuseKWHRRow.push(avg);
					inuseKWHRGridItem.average_inuse_kwhr = Math.round(avg);
				}
				if (this.analyticsFilter.data.totalInuseKWHR.checked) {
					inuseKWHRRow.push(dataByDate[date].total_inuse_kwhr);
					inuseKWHRGridItem.total_inuse_kwhr = Math.round(dataByDate[date].total_inuse_kwhr);
				}
				inuseKWHRRows.push(inuseKWHRRow);
				inuseKWHRGridItems.push(inuseKWHRGridItem);
			}
			/* Chart : Total and Average In Use Time */
			if (this.analyticsFilter.data.totalInUseTime.checked || this.analyticsFilter.data.averageInUseTime.checked) {
				inuseTimeRow = [
					formattedDate
				];
				inuseTimeGridItem = {
					date: formattedDate
				};
				if (this.analyticsFilter.data.averageInUseTime.checked) {
					avg = dataByDate[date].inuse_duration_counts > 0 ? dataByDate[date].total_inuse_duration / dataByDate[date].inuse_duration_counts : 0;
					inuseTimeRow.push(avg);
					timeInWords = this.commonUtil.secondsToElapsedTime(avg * 3600);
					timeInWords = timeInWords ? timeInWords : "0 hour";
					inuseTimeGridItem.average_inuse_time = timeInWords;
				}
				if (this.analyticsFilter.data.totalInUseTime.checked) {
					inuseTimeRow.push(dataByDate[date].total_inuse_duration);
					timeInWords = this.commonUtil.secondsToElapsedTime(dataByDate[date].total_inuse_duration * 3600);
					timeInWords = timeInWords ? timeInWords : "0 hour";
					inuseTimeGridItem.total_inuse_time = timeInWords;
				}
				inuseTimeRows.push(inuseTimeRow);
				inuseTimeGridItems.push(inuseTimeGridItem);
			}
			/* Chart : Total and Average Idle Time */
			if (this.analyticsFilter.data.totalIdleTime.checked || this.analyticsFilter.data.averageIdleTime.checked) {
				idleTimeRow = [
					formattedDate
				];
				idleTimeGridItem = {
					date: formattedDate
				};
				// Average Idle Time
				if (this.analyticsFilter.data.averageIdleTime.checked) {
					avg = dataByDate[date].idle_duration_counts > 0 ? dataByDate[date].total_idle_duration / dataByDate[date].idle_duration_counts : 0;
					idleTimeRow.push(avg);
					timeInWords = this.commonUtil.secondsToElapsedTime(avg * 3600);
					timeInWords = timeInWords ? timeInWords : "0 hour";
					idleTimeGridItem.average_idle_time = timeInWords;
				}
				// Total Idle Time 
				if (this.analyticsFilter.data.totalIdleTime.checked) {
					idleTimeRow.push(dataByDate[date].total_idle_duration);
					timeInWords = this.commonUtil.secondsToElapsedTime(dataByDate[date].total_idle_duration * 3600);
					timeInWords = timeInWords ? timeInWords : "0 hour";
					idleTimeGridItem.total_idle_time = timeInWords;
				}
				idleTimeRows.push(idleTimeRow);
				idleTimeGridItems.push(idleTimeGridItem);
			}
			/* Chart : Charge Duration*/
			if (this.analyticsFilter.data.totalChargeTime.checked || this.analyticsFilter.data.averageChargeTime.checked) {
				chargeTimeRow = [
					formattedDate
				];
				chargeTimeGridItem = {
					date: formattedDate
				};
				// Average Charge Time 
				if (this.analyticsFilter.data.averageChargeTime.checked) {
					avg = dataByDate[date].charge_duration_counts > 0 ? dataByDate[date].total_charge_duration / dataByDate[date].charge_duration_counts : 0;
					chargeTimeRow.push(avg);
					timeInWords = this.commonUtil.secondsToElapsedTime(avg * 3600);
					timeInWords = timeInWords ? timeInWords : "0 hour";
					chargeTimeGridItem.average_charge_time = timeInWords;
				}
				// Total Charge Time 
				if (this.analyticsFilter.data.totalChargeTime.checked) {
					chargeTimeRow.push(dataByDate[date].total_charge_duration);
					timeInWords = this.commonUtil.secondsToElapsedTime(dataByDate[date].total_charge_duration * 3600);
					timeInWords = timeInWords ? timeInWords : "0 hour";
					chargeTimeGridItem.total_charge_time = timeInWords;
				}
				chargeTimeRows.push(chargeTimeRow);
				chargeTimeGridItems.push(chargeTimeGridItem);
			}
		}

		/* STEP 4: DRAW CHARTS AND DRAW GRIDS */
		if (this.analyticsFilter.data.averageEBU.checked) {
			var chartColumns = [
				{
					type: "date",
					label: "Date"
				},
				{
					type: "number",
					label: "Average EBU"
				}
			];
			this.chartData.averageEBU = [chartColumns].concat(avgEBURows);
			this.chartConfig.averageEBU = this.getChartConfig({hAxis: "Date", vAxis: "Average EBU"});
			this.gridColumns.averageEBU = [
				{headerName: "Date", field: "date", type:"date", width: 300},
				{headerName: "Average EBU", field: "average_ebu", type: "number", filterParams: '1.2-2', width: 300}
			];
			this.gridData.averageEBU = avgEBUGridItems;
			this.gridIds.averageEBU = 'averageEBU';
		}
		if (this.analyticsFilter.data.totalChargeAHRs.checked || this.analyticsFilter.data.averageChargeAHRs.checked) {
			var chartColumns = [
				{
					type: "date",
					label: "Date"
				}
			];
			if (this.analyticsFilter.data.averageChargeAHRs.checked) {
				chartColumns.push({
					type: 'number',
					label: 'Average Charge AHRs'
				});
			}
			if (this.analyticsFilter.data.totalChargeAHRs.checked) {
				chartColumns.push({
					type: 'number',
					label:'Total Charge AHRs'
				});
			}
			this.chartData.chargeAHRs = [chartColumns].concat(avgChargeAHRows);
			this.chartConfig.chargeAHRs = this.getChartConfig({hAxis: "Date", vAxis: "AH Charge"});
			this.gridColumns.chargeAHRs = [
				{headerName: "Date", field: "date", type: 'date'},
				{headerName: "Average Charge AHrs", field: "average_charge_ahrs", type: "number", filterParams: '1.0-0'},
				{headerName: "Total Charge AHRs", field: "total_charge_ahrs", type: "number", filterParams: '1.0-0'},
			];
			this.gridData.chargeAHRs = avgChargeAHGridItems;
			this.gridIds.chargeAHRs = 'chargeAHRs';
		}
		if (this.analyticsFilter.data.totalChargerKWHR.checked || this.analyticsFilter.data.averageChargerKWHR.checked) {
			var chartColumns = [
				{
					type: "date",
					label: "Date"
				}
			];
			if (this.analyticsFilter.data.averageChargerKWHR.checked) {
				chartColumns.push({
					type: 'number',
					label: 'Average Charger KWHR'
				});
			}
			if (this.analyticsFilter.data.totalChargerKWHR.checked) {
				chartColumns.push({
					type: 'number',
					label:'Total Charger KWHR'
				});
			}
			this.chartData.chargerKWHR = [chartColumns].concat(avgChargerKWHRows);
			this.chartConfig.chargerKWHR = this.getChartConfig({hAxis: "Date", vAxis: "KWHR"});
			this.gridColumns.chargerKWHR = [
				{headerName: "Date", field: "date", type:"date"},
				{headerName: "Average Charge KWHR", field: "average_charger_wh", type: "number", filterParams: '1.0-0'},
				{headerName: "Total Charge KWHR", field: "total_charger_wh", type: "number", filterParams: '1.0-0'},
			];
			this.gridData.chargerKWHR = avgChargerKWHGridItems;
			this.gridIds.chargerKWHR = 'chargerKWHR';
		}
		if (this.analyticsFilter.data.totalInuseAHRs.checked || this.analyticsFilter.data.averageInuseAHRs.checked) {
			var inuseChartColumns = [
				{
					type: "date",
					label: "Date"
				}
			];
			if (this.analyticsFilter.data.averageInuseAHRs.checked) {
				inuseChartColumns.push({
					type: 'number',
					label: 'Average In Use AHRs'
				});
			}
			if (this.analyticsFilter.data.totalInuseAHRs.checked) {
				inuseChartColumns.push({
					type: 'number',
					label: 'Total In Use AHRs'
				});
			}
			this.chartData.inuseAHRs = [chartColumns].concat(inuseRows);
			this.chartConfig.inuseAHRs = this.getChartConfig({hAxis: "Date", vAxis: "In Use AHRs"});
			this.gridColumns.inuseAHRs = [
				{headerName: "Date", field: "date", type:"date"},
				{headerName: "Average In Use AHrs", field: "average_inuse_ahrs", type: "number", filterParams: '1.0-0'},
				{headerName: "Total In Use AHrs", field: "total_inuse_ahrs", type: "number", filterParams: '1.0-0'},
			];
			this.gridData.inuseAHRs = inuseGridItems;
			this.gridIds.inuseAHRs = 'inuseAHRs';
		}

		if (this.analyticsFilter.data.totalInuseKWHR.checked || this.analyticsFilter.data.averageInuseKWHR.checked) {
			var inuseKWHRChartColumns = [
				{
					type: "date",
					label: "Date"
				}
			];
			if (this.analyticsFilter.data.averageInuseKWHR.checked) {
				inuseKWHRChartColumns.push({
					type: 'number',
					label: 'Average In Use KWHR'
				});
			}
			if (this.analyticsFilter.data.totalInuseKWHR.checked) {
				inuseKWHRChartColumns.push({
					type: 'number',
					label: 'Total In Use KWHR'
				});
			}
			this.chartData.inuseKWHR = [chartColumns].concat(inuseKWHRRows);
			this.chartConfig.inuseKWHR = this.getChartConfig({hAxis: "Date", vAxis: "In Use KWHR"});
			this.gridColumns.inuseKWHR = [
				{headerName: "Date", field: "date", type:"date"},
				{headerName: "Average In Use KWHR", field: "average_inuse_kwhr", type: "number", filterParams: '1.0-0'},
				{headerName: "Total In Use KWHR", field: "total_inuse_kwhr", type: "number", filterParams: '1.0-0'},
			];
			this.gridData.inuseKWHR = inuseKWHRGridItems;
			this.gridIds.inuseKWHR = 'inuseKWHR';
		}

		if (this.analyticsFilter.data.totalInUseTime.checked || this.analyticsFilter.data.averageInUseTime.checked) {
			var inuseTimeChartColumns = [
				{
					type: "date",
					label: "Date"
				}
			];
			if (this.analyticsFilter.data.averageInUseTime.checked) {
				inuseTimeChartColumns.push({
					type: 'number',
					label: 'Average In Use Time (Hours)'
				});
			}
			if (this.analyticsFilter.data.totalInUseTime.checked) {
				inuseTimeChartColumns.push({
					type: 'number',
					label: 'Total In Use Time (Hours)'
				});
			}
			this.chartData.inuseTime = [chartColumns].concat(inuseTimeRows);
			this.chartConfig.inuseTime = this.getChartConfig({hAxis: "Date", vAxis: "In Use Time (Hours)"});
			this.gridColumns.inuseTime = [
				{headerName: "Date", field: "date", type:"date"},
				{headerName: "Average In Use Time", field: "average_inuse_time", filter: 'agTextColumnFilter'},
				{headerName: "Total In Use Time", field: "total_inuse_time", filter: 'agTextColumnFilter'},
			];
			this.gridData.inuseTime = inuseTimeGridItems;
			this.gridIds.inuseTime = 'inuseTime';
		}
		if (this.analyticsFilter.data.totalIdleTime.checked || this.analyticsFilter.data.averageIdleTime.checked) {
			var idleTimeChartColumns = [
				{
					type: "date",
					label: "Date"
				}
			];
			if (this.analyticsFilter.data.averageIdleTime.checked) {
				idleTimeChartColumns.push({
					type: 'number',
					label: 'Average Idle Time (Hours)'
				});
			}
			if (this.analyticsFilter.data.totalIdleTime.checked) {
				idleTimeChartColumns.push({
					type: 'number',
					label: 'Total Idle Time (Hours)'
				});
			}
			this.chartData.idleTime = [chartColumns].concat(idleTimeRows);
			this.chartConfig.idleTime = this.getChartConfig({hAxis: "Date", vAxis: "Idle Time (Hours)"});
			this.gridColumns.idleTime = [
				{headerName: "Date", field: "date", type:"date"},
				{headerName: "Average Idle Time", field: "average_idle_time", filter: 'agTextColumnFilter'},
				{headerName: "Total Idle Time", field: "total_idle_time", filter: 'agTextColumnFilter'},
			];
			this.gridData.idleTime = idleTimeGridItems;
			this.gridIds.idleTime = 'idleTime';
		}
		if (this.analyticsFilter.data.totalChargeTime.checked || this.analyticsFilter.data.averageChargeTime.checked) {
			var chargeTimeChartColumns = [
				{
					type: "date",
					label: "Date"
				}
			];
			if (this.analyticsFilter.data.averageChargeTime.checked) {
				chargeTimeChartColumns.push({
					type: 'number',
					label: 'Average Charge Time (Hours)'
				});
			}
			if (this.analyticsFilter.data.totalChargeTime.checked) {
				chargeTimeChartColumns.push({
					type: 'number',
					label: 'Total Charge Time (Hours)'
				});
			}
			this.chartData.chargeTime = [chartColumns].concat(chargeTimeRows);
			this.chartConfig.chargeTime = this.getChartConfig({hAxis: "Date", vAxis: "Charge Time (Hours)"});
			this.gridColumns.chargeTime = [
				{headerName: "Date", field: "date", type:"date"},
				{headerName: "Average Charge Time", field: "average_charge_time", filter: 'agTextColumnFilter'},
				{headerName: "Total Charge Time", field: "total_charge_time", filter: 'agTextColumnFilter'},
			];
			this.gridData.chargeTime = chargeTimeGridItems;
			this.gridIds.chargeTime = 'chargeTime';
		}
	}

	drawReportsByBattviewCluster(dataList) {
		/* STEP 1 : PREPARE DATA CLUSTERED BY BATTVIEW */
		var dataList = this.getDataClusteredByBattview(dataList);
		var pass, avg, passInverse1, passInverse1, timeInWords;
		/* STEP 2: FILTER OUT DATA BASED ON USER'S CONDITIONS */
		for (var battview in dataList) {
			/* Chart: Average EBU */
			// @todo: check what passInverse2 means and if let here is crashing anything
			// this is new: let passInverse2 = false;
			let passInverse2 = false;
			if (this.analyticsFilter.data.averageEBU.checked) {
				pass = true, passInverse1 = true, passInverse2 = true;
				// Average EBU
				avg = dataList[battview].average_ebu;
				if (this.analyticsFilter.data.averageEBU.greaterThan) {
					if (this.analyticsFilter.data.averageEBU.type == "inrange") {
						pass = avg > this.analyticsFilter.data.averageEBU.greaterThan;
						if (!pass) {
							delete dataList[battview];
							continue;
						}
					} else {
						passInverse1 = avg < this.analyticsFilter.data.averageEBU.greaterThan;
					}
				}
				if (this.analyticsFilter.data.averageEBU.lessThan) {
					if (this.analyticsFilter.data.averageEBU.type == "inrange") {
						pass = avg < this.analyticsFilter.data.averageEBU.lessThan;
						if (!pass) {
							delete dataList[battview];
							continue;
						}
					} else {
						passInverse2 = avg > this.analyticsFilter.data.averageEBU.lessThan;
					}
				}

				if (!passInverse1 && !passInverse2) {
					delete dataList[battview];
					continue;
				}
			}
			/* Chart : Total and Average Charge AH */
			if (this.analyticsFilter.data.totalChargeAHRs.checked || this.analyticsFilter.data.averageChargeAHRs.checked) {
				// Average AHRs
				if (this.analyticsFilter.data.averageChargeAHRs.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					avg = dataList[battview].average_charge_ah;
					if (this.analyticsFilter.data.averageChargeAHRs.lessThan) {
						
						if (this.analyticsFilter.data.averageChargeAHRs.type == "inrange") {
							pass = avg < this.analyticsFilter.data.averageChargeAHRs.lessThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse1 = avg > this.analyticsFilter.data.averageChargeAHRs.lessThan;
						}
					}
					if (this.analyticsFilter.data.averageChargeAHRs.greaterThan) {
						
						if (this.analyticsFilter.data.averageChargeAHRs.type == "inrange") {
							pass = avg > this.analyticsFilter.data.averageChargeAHRs.greaterThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse2 = avg < this.analyticsFilter.data.averageChargeAHRs.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataList[battview];
						continue;
					}
				}
				// Total charge AH 
				if (this.analyticsFilter.data.totalChargeAHRs.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;

					if (this.analyticsFilter.data.totalChargeAHRs.lessThan) {
						if (this.analyticsFilter.data.totalChargeAHRs.type == "inrange") {
							pass = dataList[battview].total_charge_ah < this.analyticsFilter.data.totalChargeAHRs.lessThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse1 = dataList[battview].total_charge_ah > this.analyticsFilter.data.totalChargeAHRs.lessThan;
						}
					}
					if (this.analyticsFilter.data.totalChargeAHRs.greaterThan) {
						
						if (this.analyticsFilter.data.totalChargeAHRs.type == "inrange") {
							pass = dataList[battview].total_charge_ah > this.analyticsFilter.data.totalChargeAHRs.greaterThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse2 = dataList[battview].total_charge_ah < this.analyticsFilter.data.totalChargeAHRs.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataList[battview];
						continue;
					}
				}
			}


			/* Chart : Total and Average Charge KWHR */
			if (this.analyticsFilter.data.totalChargerKWHR.checked || this.analyticsFilter.data.averageChargerKWHR.checked) {
				// Average KWHR
				if (this.analyticsFilter.data.averageChargerKWHR.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					avg = dataList[battview].average_charge_kwhr;
					if (this.analyticsFilter.data.averageChargeAHRs.lessThan) {
						
						if (this.analyticsFilter.data.averageChargerKWHR.type == "inrange") {
							pass = avg < this.analyticsFilter.data.averageChargerKWHR.lessThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse1 = avg > this.analyticsFilter.data.averageChargerKWHR.lessThan;
						}
					}
					if (this.analyticsFilter.data.averageChargerKWHR.greaterThan) {
						
						if (this.analyticsFilter.data.averageChargerKWHR.type == "inrange") {
							pass = avg > this.analyticsFilter.data.averageChargerKWHR.greaterThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse2 = avg < this.analyticsFilter.data.averageChargerKWHR.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataList[battview];
						continue;
					}
				}
				// Total charge KWHR 
				if (this.analyticsFilter.data.totalChargerKWHR.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;

					if (this.analyticsFilter.data.totalChargerKWHR.lessThan) {
						if (this.analyticsFilter.data.totalChargerKWHR.type == "inrange") {
							pass = dataList[battview].total_charge_kwhr < this.analyticsFilter.data.totalChargerKWHR.lessThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse1 = dataList[battview].total_charge_kwhr > this.analyticsFilter.data.totalChargerKWHR.lessThan;
						}
					}
					if (this.analyticsFilter.data.totalChargerKWHR.greaterThan) {
						
						if (this.analyticsFilter.data.totalChargerKWHR.type == "inrange") {
							pass = dataList[battview].total_charge_kwhr > this.analyticsFilter.data.totalChargerKWHR.greaterThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse2 = dataList[battview].total_charge_kwhr < this.analyticsFilter.data.totalChargerKWHR.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataList[battview];
						continue;
					}
				}
			}

			/* Chart : Total and Average In Use AH */
			if (this.analyticsFilter.data.totalInuseAHRs.checked || this.analyticsFilter.data.averageInuseAHRs.checked) {
				// Average InUse AH 
				if (this.analyticsFilter.data.averageInuseAHRs.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					avg = dataList[battview].average_inuse_ah;
					
					if (this.analyticsFilter.data.averageInuseAHRs.lessThan) {
						
						if (this.analyticsFilter.data.averageInuseAHRs.type == "inrange") {
							pass = avg < this.analyticsFilter.data.averageInuseAHRs.lessThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse1 = avg > this.analyticsFilter.data.averageInuseAHRs.lessThan;
						}
					}
					if (this.analyticsFilter.data.averageInuseAHRs.greaterThan) {
						
						if (this.analyticsFilter.data.averageInuseAHRs.type == "inrange") {
							pass = avg > this.analyticsFilter.data.averageInuseAHRs.greaterThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse2 = avg < this.analyticsFilter.data.averageInuseAHRs.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataList[battview];
						continue;
					}
				}
				// Total InUse AH 
				if (this.analyticsFilter.data.totalInuseAHRs.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					if (this.analyticsFilter.data.totalInuseAHRs.lessThan) {
						
						if (this.analyticsFilter.data.totalInuseAHRs.type == "inrange") {
							pass = dataList[battview].total_inuse_ah < this.analyticsFilter.data.totalInuseAHRs.lessThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse1 = dataList[battview].total_inuse_ah > this.analyticsFilter.data.totalInuseAHRs.lessThan;
						}
					}
					if (this.analyticsFilter.data.totalInuseAHRs.greaterThan) {
						
						if (this.analyticsFilter.data.totalInuseAHRs.type == "inrange") {
							pass = dataList[battview].total_inuse_ah > this.analyticsFilter.data.totalInuseAHRs.greaterThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse2 = dataList[battview].total_inuse_ah < this.analyticsFilter.data.totalInuseAHRs.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataList[battview];
						continue;
					}
				}
			}

			/* Chart : Total and Average In Use KWHR */
			if (this.analyticsFilter.data.totalInuseKWHR.checked || this.analyticsFilter.data.averageInuseKWHR.checked) {
				// Average InUse AH 
				if (this.analyticsFilter.data.averageInuseKWHR.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					avg = dataList[battview].average_inuse_kwhr;
					
					if (this.analyticsFilter.data.averageInuseKWHR.lessThan) {
						
						if (this.analyticsFilter.data.averageInuseKWHR.type == "inrange") {
							pass = avg < this.analyticsFilter.data.averageInuseKWHR.lessThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse1 = avg > this.analyticsFilter.data.averageInuseKWHR.lessThan;
						}
					}
					if (this.analyticsFilter.data.averageInuseKWHR.greaterThan) {
						
						if (this.analyticsFilter.data.averageInuseAHRs.type == "inrange") {
							pass = avg > this.analyticsFilter.data.averageInuseKWHR.greaterThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse2 = avg < this.analyticsFilter.data.averageInuseKWHR.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataList[battview];
						continue;
					}
				}
				// Total InUse AH 
				if (this.analyticsFilter.data.totalInuseKWHR.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					if (this.analyticsFilter.data.totalInuseKWHR.lessThan) {
						
						if (this.analyticsFilter.data.totalInuseKWHR.type == "inrange") {
							pass = dataList[battview].total_inuse_kwhr < this.analyticsFilter.data.totalInuseKWHR.lessThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse1 = dataList[battview].total_inuse_kwhr > this.analyticsFilter.data.totalInuseKWHR.lessThan;
						}
					}
					if (this.analyticsFilter.data.totalInuseKWHR.greaterThan) {
						
						if (this.analyticsFilter.data.totalInuseKWHR.type == "inrange") {
							pass = dataList[battview].total_inuse_kwhr > this.analyticsFilter.data.totalInuseKWHR.greaterThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse2 = dataList[battview].total_inuse_kwhr < this.analyticsFilter.data.totalInuseKWHR.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataList[battview];
						continue;
					}
				}
			}

			// /* Chart : Total and Average In Use Time */
			if (this.analyticsFilter.data.totalInUseTime.checked || this.analyticsFilter.data.averageInUseTime.checked) {
				if (this.analyticsFilter.data.averageInUseTime.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					avg = dataList[battview].average_inuse_time;
					if (this.analyticsFilter.data.averageInUseTime.lessThan) {
						
						if (this.analyticsFilter.data.averageInUseTime.type == "inrange") {
							pass = avg < this.analyticsFilter.data.averageInUseTime.lessThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse1 = avg > this.analyticsFilter.data.averageInUseTime.lessThan;
						}
					}
					if (this.analyticsFilter.data.averageInUseTime.greaterThan) {
						
						if (this.analyticsFilter.data.averageInUseTime.type == "inrange") {
							pass = avg > this.analyticsFilter.data.averageInUseTime.greaterThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse2 = avg < this.analyticsFilter.data.averageInUseTime.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataList[battview];
						continue;
					}
				}
				// Total InUse time 
				if (this.analyticsFilter.data.totalInUseTime.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					if (this.analyticsFilter.data.totalInUseTime.lessThan) {
						
						if (this.analyticsFilter.data.totalInUseTime.type == "inrange") {
							pass = dataList[battview].total_inuse_time < this.analyticsFilter.data.totalInUseTime.lessThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse1 = dataList[battview].total_inuse_time > this.analyticsFilter.data.totalInUseTime.lessThan;
						}
					}
					if (this.analyticsFilter.data.totalInUseTime.greaterThan) {
						
						if (this.analyticsFilter.data.totalInUseTime.type == "inrange") {
							pass = dataList[battview].total_inuse_time > this.analyticsFilter.data.totalInUseTime.greaterThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse2 = dataList[battview].total_inuse_time < this.analyticsFilter.data.totalInUseTime.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataList[battview];
						continue;
					}
				}
			}
		
			if (this.analyticsFilter.data.totalIdleTime.checked || this.analyticsFilter.data.averageIdleTime.checked) {
				// Average Idle Time 
				if (this.analyticsFilter.data.averageIdleTime.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					avg = dataList[battview].average_idle_time;
					if (this.analyticsFilter.data.averageIdleTime.lessThan) {
						
						if (this.analyticsFilter.data.averageIdleTime.type == "inrange") {
							pass = avg < this.analyticsFilter.data.averageIdleTime.lessThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse1 = avg > this.analyticsFilter.data.averageIdleTime.lessThan;
						}
					}
					if (this.analyticsFilter.data.averageIdleTime.greaterThan) {
						
						if (this.analyticsFilter.data.averageIdleTime.type == "inrange") {
							pass = avg > this.analyticsFilter.data.averageIdleTime.greaterThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse2 = avg < this.analyticsFilter.data.averageIdleTime.greaterThan;
						}
					}

					if (!passInverse1 && !passInverse2) {
						delete dataList[battview];
						continue;
					}
				}
				// Total Idle Time 
				if (this.analyticsFilter.data.totalIdleTime.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;
					if (this.analyticsFilter.data.totalIdleTime.lessThan) {
						
						if (this.analyticsFilter.data.totalIdleTime.type == "inrange") {
							pass = dataList[battview].total_idle_time < this.analyticsFilter.data.totalIdleTime.lessThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse1 = dataList[battview].total_idle_time > this.analyticsFilter.data.totalIdleTime.lessThan;
						}
					}
					if (this.analyticsFilter.data.totalIdleTime.greaterThan) {
						
						if (this.analyticsFilter.data.totalIdleTime.type == "inrange") {
							pass = dataList[battview].total_idle_time > this.analyticsFilter.data.totalIdleTime.greaterThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse2 = dataList[battview].total_idle_time < this.analyticsFilter.data.totalIdleTime.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataList[battview];
						continue;
					}
				}
			}
			/*Charge Duration*/
			if (this.analyticsFilter.data.totalChargeTime.checked || this.analyticsFilter.data.averageChargeTime.checked) {
				// Average Charge Time 
				if (this.analyticsFilter.data.averageChargeTime.checked) {
					pass = true, passInverse1 = true , passInverse2 = true;

					avg = dataList[battview].average_charge_time ;
					
					if (this.analyticsFilter.data.averageChargeTime.lessThan) {
						
						if (this.analyticsFilter.data.averageChargeTime.type == "inrange") {
							pass = avg < this.analyticsFilter.data.averageChargeTime.lessThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse1 = avg > this.analyticsFilter.data.averageChargeTime.lessThan;
						}
					}
					if (this.analyticsFilter.data.averageChargeTime.greaterThan) {
						
						if (this.analyticsFilter.data.averageChargeTime.type == "inrange") {
							pass = avg > this.analyticsFilter.data.averageChargeTime.greaterThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse2 = avg < this.analyticsFilter.data.averageChargeTime.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse1) {
						delete dataList[battview];
						continue;
					}
				}
				// Total Charge Time 
				if (this.analyticsFilter.data.totalChargeTime.checked) {
					pass = true, passInverse1 = true, passInverse2 = true;

					if (this.analyticsFilter.data.totalChargeTime.lessThan) {
						
						if (this.analyticsFilter.data.totalChargeTime.type == "inrange") {
							pass = dataList[battview].total_charge_time < this.analyticsFilter.data.totalChargeTime.lessThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse1 = dataList[battview].total_charge_time > this.analyticsFilter.data.totalChargeTime.lessThan;
						}
					}
					if (this.analyticsFilter.data.totalChargeTime.greaterThan) {
						
						if (this.analyticsFilter.data.totalChargeTime.type == "inrange") {
							pass = dataList[battview].total_charge_time > this.analyticsFilter.data.totalChargeTime.greaterThan;
							if (!pass) {
								delete dataList[battview];
								continue;
							}
						} else {
							passInverse2 = dataList[battview].total_charge_time < this.analyticsFilter.data.totalChargeTime.greaterThan;
						}
					}
					if (!passInverse1 && !passInverse2) {
						delete dataList[battview];
						continue;
					}
				}
			}
		}
		
		/* STEP 3: PREPARE CHART ROWS AND GRID COLUMNS */
		var avg, 
			avgEBURow,
			avgEBURows = [],
			avgEBUGridItem,
			avgEBUGridItems = [],
			avgChargeAHRow, 
			avgChargeAHRows = [],
			avgChargeAHGridItem, 
			avgChargeAHGridItems = [],
			chargerKWHRRow,
			chargerKWHRRows = [],
			chargerKWHRGridItem,
			chargerKWHRGridItems = [],
			inuseRow, 
			inuseRows = [],
			inuseGridItem,
			inuseGridItems = [],
			inuseKWHRRow,
			inuseKWHRRows = [],
			inuseKWHRGridItem,
			inuseKWHRGridItems = [],
			inuseTimeRow,
			inuseTimeRows = [],
			inuseTimeGridItem,
			inuseTimeGridItems= [],
			timeInWords,
			idleTimeRow,
			idleTimeRows = [],
			idleTimeGridItem,
			idleTimeGridItems = [],
			chargeTimeRow,
			chargeTimeRows = [],
			chargeTimeGridItem,
			chargeTimeGridItems = [];

		for (battview in dataList) {
			if (this.analyticsFilter.data.averageEBU.checked) {
				avg = dataList[battview].average_ebu;
				avgEBURow = [
					dataList[battview].battery_id,
					avg
				];
				avgEBUGridItem = {
					id : dataList[battview].id,
					battery_id: dataList[battview].battery_id,
					battery_sn: dataList[battview].serialnumber,
					truckid: dataList[battview].truckid,
					average_ebu: Math.round(avg*100)/100
				};
				avgEBURows.push(avgEBURow);
				avgEBUGridItems.push(avgEBUGridItem);
			}
			if (this.analyticsFilter.data.totalChargeAHRs.checked || this.analyticsFilter.data.averageChargeAHRs.checked) {
				avgChargeAHRow = [
					dataList[battview].battery_id
				];
				avgChargeAHGridItem = {
					id: dataList[battview].id,
					battery_id: dataList[battview].battery_id,
					battery_sn: dataList[battview].serialnumber,
					truckid: dataList[battview].truckid
				};
				// Average AHRs
				if (this.analyticsFilter.data.averageChargeAHRs.checked) {
					avg = dataList[battview].average_charge_ah;
					avgChargeAHRow.push(avg);
					avgChargeAHGridItem.average_charge_ahrs = Math.round(avg);
				}
				// Total charge AH 
				if (this.analyticsFilter.data.totalChargeAHRs.checked) {
					avgChargeAHRow.push(dataList[battview].total_charge_ah);
					avgChargeAHGridItem.total_charge_ahrs = Math.round(dataList[battview].total_charge_ah);
				}
				avgChargeAHRows.push(avgChargeAHRow);
				avgChargeAHGridItems.push(avgChargeAHGridItem);
			}
			if (this.analyticsFilter.data.totalChargerKWHR.checked || this.analyticsFilter.data.averageChargerKWHR.checked) {
				chargerKWHRRow = [
					dataList[battview].battery_id
				];
				chargerKWHRGridItem = {
					id: dataList[battview].id,
					battery_id: dataList[battview].battery_id,
					battery_sn: dataList[battview].serialnumber,
					truckid: dataList[battview].truckid
				};
				// Average KWHR
				if (this.analyticsFilter.data.averageChargerKWHR.checked) {
					avg = dataList[battview].average_charge_kwhr;
					chargerKWHRRow.push(avg);
					chargerKWHRGridItem.average_charge_kwhr = Math.round(avg);
				}
				// Total charge KWHR 
				if (this.analyticsFilter.data.totalChargerKWHR.checked) {
					chargerKWHRRow.push(dataList[battview].total_charge_kwhr);
					chargerKWHRGridItem.total_charge_kwhr = Math.round(dataList[battview].total_charge_kwhr);
				}
				chargerKWHRRows.push(chargerKWHRRow);
				chargerKWHRGridItems.push(chargerKWHRGridItem);
			}
			if (this.analyticsFilter.data.totalInuseAHRs.checked || this.analyticsFilter.data.averageInuseAHRs.checked) {
				inuseRow = [
					dataList[battview].battery_id
				];
				inuseGridItem = {
					id: dataList[battview].id,
					battery_id: dataList[battview].battery_id,
					battery_sn: dataList[battview].serialnumber,
					truckid: dataList[battview].truckid
				};
				if (this.analyticsFilter.data.averageInuseAHRs.checked) {
					avg = dataList[battview].average_inuse_ah;
					inuseRow.push(avg);
					inuseGridItem.average_inuse_ahrs = Math.round(avg);
				}
				if (this.analyticsFilter.data.totalInuseAHRs.checked) {
					inuseRow.push(dataList[battview].total_inuse_ah);
					inuseGridItem.total_inuse_ahrs = Math.round(dataList[battview].total_inuse_ah);
				}
				inuseRows.push(inuseRow);
				inuseGridItems.push(inuseGridItem);

			}

			if (this.analyticsFilter.data.totalInuseKWHR.checked || this.analyticsFilter.data.averageInuseKWHR.checked) {
				inuseKWHRRow = [
					dataList[battview].battery_id
				];
				inuseKWHRGridItem = {
					id: dataList[battview].id,
					battery_id: dataList[battview].battery_id,
					battery_sn: dataList[battview].serialnumber,
					truckid: dataList[battview].truckid
				};
				// Average KWHR
				if (this.analyticsFilter.data.averageInuseKWHR.checked) {
					avg = dataList[battview].average_inuse_kwhr;
					inuseKWHRRow.push(avg);
					inuseKWHRGridItem.average_inuse_kwhr = Math.round(avg);
				}
				// Total charge KWHR 
				if (this.analyticsFilter.data.totalChargerKWHR.checked) {
					inuseKWHRRow.push(dataList[battview].total_inuse_kwhr);
					inuseKWHRGridItem.total_inuse_kwhr = Math.round(dataList[battview].total_inuse_kwhr);
				}
				inuseKWHRRows.push(inuseKWHRRow);
				inuseKWHRGridItems.push(inuseKWHRGridItem);
			}

			if (this.analyticsFilter.data.totalInUseTime.checked || this.analyticsFilter.data.averageInUseTime.checked) {
				inuseTimeRow = [
					dataList[battview].battery_id
				];
				inuseTimeGridItem = {
					id: dataList[battview].id,
					battery_id: dataList[battview].battery_id,
					battery_sn: dataList[battview].serialnumber,
					truckid: dataList[battview].truckid
				};
				// Average InUse AH 
				if (this.analyticsFilter.data.averageInUseTime.checked) {
					avg = dataList[battview].average_inuse_time;
					inuseTimeRow.push(avg);
					timeInWords = this.commonUtil.secondsToElapsedTime(avg * 3600);
					timeInWords = timeInWords ? timeInWords : "0 hour";
					inuseTimeGridItem.average_inuse_time = timeInWords;
				}
				// Total InUse AH 
				if (this.analyticsFilter.data.totalInUseTime.checked) {
					inuseTimeRow.push(dataList[battview].total_inuse_time);
					timeInWords = this.commonUtil.secondsToElapsedTime(dataList[battview].total_inuse_time * 3600);
					timeInWords = timeInWords ? timeInWords : "0 hour";
					inuseTimeGridItem.total_inuse_time = timeInWords;
				}
				inuseTimeRows.push(inuseTimeRow);
				inuseTimeGridItems.push(inuseTimeGridItem);
			}
			if (this.analyticsFilter.data.totalIdleTime.checked || this.analyticsFilter.data.averageIdleTime.checked) {
				idleTimeRow = [
					dataList[battview].battery_id
				];
				idleTimeGridItem = {
					id : dataList[battview].id,
					battery_id: dataList[battview].battery_id,
					battery_sn: dataList[battview].serialnumber,
					truckid: dataList[battview].truckid
				};
				// Average Idle Time 
				if (this.analyticsFilter.data.averageIdleTime.checked) {
					var avg = dataList[battview].average_idle_time;
					idleTimeRow.push(avg);
					timeInWords = this.commonUtil.secondsToElapsedTime(avg * 3600);
					timeInWords = timeInWords ? timeInWords : "0 hour";
					idleTimeGridItem.average_idle_time = timeInWords;
				}
				// Total Idle Time 
				if (this.analyticsFilter.data.totalIdleTime.checked) {
					idleTimeRow.push(dataList[battview].total_idle_time);
					timeInWords = this.commonUtil.secondsToElapsedTime(dataList[battview].total_idle_time * 3600);
					timeInWords = timeInWords ? timeInWords : "0 hour";
					idleTimeGridItem.total_idle_time = timeInWords;
				}
				idleTimeRows.push(idleTimeRow);
				idleTimeGridItems.push(idleTimeGridItem);						
			}
			if (this.analyticsFilter.data.totalChargeTime.checked || this.analyticsFilter.data.averageChargeTime.checked) {
				chargeTimeRow = [
					dataList[battview].battery_id
				];
				chargeTimeGridItem = {
					id : dataList[battview].id,
					battery_id: dataList[battview].battery_id,
					battery_sn: dataList[battview].serialnumber,
					truckid: dataList[battview].truckid
				};
				if (this.analyticsFilter.data.averageChargeTime.checked) {
					avg = dataList[battview].average_charge_time ;
					chargeTimeRow.push(avg);
					timeInWords = this.commonUtil.secondsToElapsedTime(avg * 3600);
					timeInWords = timeInWords ? timeInWords : "0 hour";
					chargeTimeGridItem.average_charge_time = timeInWords;
				}
				if (this.analyticsFilter.data.totalChargeTime.checked) {
					chargeTimeRow.push(dataList[battview].total_charge_time);
					timeInWords = this.commonUtil.secondsToElapsedTime(dataList[battview].total_charge_time * 3600);
					timeInWords = timeInWords ? timeInWords : "0 hour";
					chargeTimeGridItem.total_charge_time = timeInWords;
				}
				chargeTimeRows.push(chargeTimeRow);
				chargeTimeGridItems.push(chargeTimeGridItem);
			}
		}

		let batteryIdColumn = {
			headerName: "Battery ID",
			field: "battery_id",
			cellRendererSelector:function (params) {
				params['label'] = params.data.battery_id;
				params['link'] = ["/#", params.colDef.cellRendererParams.customerId, params.colDef.cellRendererParams.siteId, "battviews", "analytics", "battery-summary", params.data.id].join('/');
				return {
					component: 'linkCellRenderer',
					params: params
				};
			},
			cellRendererParams: {customerId: this.customerId, siteId: this.siteId},
			filter: 'agTextColumnFilter'
		};
		/* STEP 4: DRAW CHARTS AND DRAW GRIDS */
		if (this.analyticsFilter.data.averageEBU.checked) {
			var chartColumns = [
				{
					type: "string",
					label: "Battery ID"
				},
				{
					type: 'number',
					label: 'Average EBU'
				}
			];
			this.chartData.averageEBU = [chartColumns].concat(avgEBURows);
			this.chartConfig.averageEBU = this.getChartConfig({hAxis: "Battery ID", vAxis: "Average EBU"});
			this.gridColumns.averageEBU = [
				batteryIdColumn,
				{headerName: "Battery SN", field: "battery_sn", filter: 'agTextColumnFilter'},
				{headerName: "Truck ID", field: "truckid", filter: 'agTextColumnFilter'},
				{headerName: "Average EBU", field: "average_ebu", type: "number", filterParams: '1.2-2'}
			];
			this.gridData.averageEBU = avgEBUGridItems;
			this.gridIds.averageEBU = 'battery_averageEBU';
		}

		if (this.analyticsFilter.data.totalChargeAHRs.checked || this.analyticsFilter.data.averageChargeAHRs.checked) {
			var chartColumns = [
				{
					type: "string",
					label: "Battery ID"
				}
			];
			if (this.analyticsFilter.data.averageChargeAHRs.checked) {
				chartColumns.push({
					type: 'number',
					label: 'Average Charge AHRs'
				});
			}
			if (this.analyticsFilter.data.totalChargeAHRs.checked) {
				chartColumns.push({
					type: 'number',
					label:'Total Charge AHRs'
				});
			}
			this.chartData.chargeAHRs = [chartColumns].concat(avgChargeAHRows);
			this.chartConfig.chargeAHRs = this.getChartConfig({hAxis: "Battery ID", vAxis: "AH Charge"});
			this.gridColumns.chargeAHRs = [
				batteryIdColumn,
				{headerName: "Battery SN", field: "battery_sn", filter: 'agTextColumnFilter'},
				{headerName: "Truck ID", field: "truckid", filter: 'agTextColumnFilter'},
				{headerName: "Average Charge AHRs", field: "average_charge_ahrs", type: "number", filterParams: '1.0-0'},
				{headerName: "Total Charge AHRs", field: "total_charge_ahrs", type: "number", filterParams: '1.0-0'},
			];
			this.gridData.chargeAHRs = avgChargeAHGridItems;
			this.gridIds.chargeAHRs = 'battery_chargeAHRs';
		}

		if (this.analyticsFilter.data.totalChargerKWHR.checked || this.analyticsFilter.data.averageChargerKWHR.checked) {
			var KWHRchartColumns = [
				{
					type: "string",
					label: "Battery ID"
				}
			];
			if (this.analyticsFilter.data.averageChargerKWHR.checked) {
				KWHRchartColumns.push({
					type: 'number',
					label: 'Average Charge KWHR'
				});
			}
			if (this.analyticsFilter.data.totalChargerKWHR.checked) {
				KWHRchartColumns.push({
					type: 'number',
					label:'Total Charge KWHR'
				});
			}
			this.chartData.chargerKWHR = [chartColumns].concat(chargerKWHRRows);
			this.chartConfig.chargerKWHR = this.getChartConfig({hAxis: "Battery ID", vAxis: "KWHR Charge"});
			this.gridColumns.chargerKWHR = [
				batteryIdColumn,
				{headerName: "Battery SN", field: "battery_sn", filter: 'agTextColumnFilter'},
				{headerName: "Truck ID", field: "truckid", filter: 'agTextColumnFilter'},
				{headerName: "Average Charge KWHR", field: "average_charge_kwhr", type: "number", filterParams: '1.0-0'},
				{headerName: "Total Charge KWHR", field: "total_charge_kwhr", type: "number", filterParams: '1.0-0'},
			];
			this.gridData.chargerKWHR = chargerKWHRGridItems;
			this.gridIds.chargerKWHR = 'battery_chargerKWHR';
		}

		if (this.analyticsFilter.data.totalInuseAHRs.checked || this.analyticsFilter.data.averageInuseAHRs.checked) {
			var inuseChartColumns = [
				{
					type: "string",
					label: "Battery ID"
				}
			];
			if (this.analyticsFilter.data.averageInuseAHRs.checked) {
				inuseChartColumns.push({
					type: 'number',
					label: 'Average In Use AHRs'
				});
			}
			if (this.analyticsFilter.data.totalInuseAHRs.checked) {
				inuseChartColumns.push({
					type: 'number',
					label: 'Total In Use AHRs'
				});
			}
			this.chartData.inuseAHRs = [chartColumns].concat(inuseRows);
			this.chartConfig.inuseAHRs = this.getChartConfig({hAxis: "Battery ID", vAxis: "In Use AHRs"});
			this.gridColumns.inuseAHRs = [
				batteryIdColumn,
				{headerName: "Battery SN", field: "battery_sn", filter: 'agTextColumnFilter'},
				{headerName: "Truck ID", field: "truckid", filter: 'agTextColumnFilter'},
				{headerName: "Average In Use AHrs", field: "average_inuse_ahrs", type: "number", filterParams: '1.0-0'},
				{headerName: "Total In Use AHRs", field: "total_inuse_ahrs", type: "number", filterParams: '1.0-0'},
			];
			this.gridData.inuseAHRs = inuseGridItems;
			this.gridIds.inuseAHRs = 'battery_inuseAHRs';
		}

		if (this.analyticsFilter.data.totalInuseKWHR.checked || this.analyticsFilter.data.averageInuseKWHR.checked) {
			var inuseKWHRChartColumns = [
				{
					type: "string",
					label: "Battery ID"
				}
			];
			if (this.analyticsFilter.data.averageInuseAHRs.checked) {
				inuseKWHRChartColumns.push({
					type: 'number',
					label: 'Average In Use KWHR'
				});
			}
			if (this.analyticsFilter.data.totalInuseAHRs.checked) {
				inuseKWHRChartColumns.push({
					type: 'number',
					label: 'Total In Use KWHR'
				});
			}
			this.chartData.inuseKWHR = [chartColumns].concat(inuseKWHRRows);
			this.chartConfig.inuseKWHR = this.getChartConfig({hAxis: "Battery ID", vAxis: "In Use KWHR"});
			this.gridColumns.inuseKWHR = [
				batteryIdColumn,
				{headerName: "Battery SN", field: "battery_sn", filter: 'agTextColumnFilter'},
				{headerName: "Truck ID", field: "truckid", filter: 'agTextColumnFilter'},
				{headerName: "Average In Use KWHR", field: "average_inuse_kwhr", type: "number", filterParams: '1.0-0'},
				{headerName: "Total In Use KWHR", field: "total_inuse_kwhr", type: "number", filterParams: '1.0-0'},
			];
			this.gridData.inuseKWHR = inuseKWHRGridItems;
			this.gridIds.inuseKWHR = 'battery_inuseKWHR';
		}
	
		if (this.analyticsFilter.data.totalInUseTime.checked || this.analyticsFilter.data.averageInUseTime.checked) {
			var inuseTimeChartColumns = [
				{
					type: "string",
					label: "Battery ID"
				}
			];
			if (this.analyticsFilter.data.averageInUseTime.checked) {
				inuseTimeChartColumns.push({
					type: 'number',
					label: 'Average In Use Time (Hours)'
				});
			}
			if (this.analyticsFilter.data.totalInUseTime.checked) {
				inuseTimeChartColumns.push({
					type: 'number',
					label: 'Total In Use Time (Hours)'
				});
			}
			this.chartData.inuseTime = [chartColumns].concat(inuseTimeRows);
			this.chartConfig.inuseTime = this.getChartConfig({hAxis: "Battery ID", vAxis: "In Use Time (Hours)"});
			this.gridColumns.inuseTime = [
				batteryIdColumn,
				{headerName: "Battery SN", field: "battery_sn", filter: 'agTextColumnFilter'},
				{headerName: "Truck ID", field: "truckid", filter: 'agTextColumnFilter'},
				{headerName: "Average In Use Time (Days)", field: "average_inuse_time", filter: 'agTextColumnFilter'},
				{headerName: "Total In Use Time (Days)", field: "total_inuse_time", filter: 'agTextColumnFilter'},
			];
			this.gridData.inuseTime = inuseTimeGridItems;
			this.gridIds.inuseTime = 'battery_inuseTime';
		}

		if (this.analyticsFilter.data.totalIdleTime.checked || this.analyticsFilter.data.averageIdleTime.checked) {
			var idleTimeChartColumns = [
				{
					type: "string",
					label: "Battery ID"
				}
			];
			if (this.analyticsFilter.data.averageIdleTime.checked) {
				idleTimeChartColumns.push({
					type: 'number',
					label: 'Average Idle Time (Hours)'
				});
			}
			if (this.analyticsFilter.data.totalIdleTime.checked) {
				idleTimeChartColumns.push({
					type: 'number',
					label: 'Total Idle Time (Hours)'
				});
			}
			this.chartData.idleTime = [chartColumns].concat(idleTimeRows);
			this.chartConfig.idleTime = this.getChartConfig({hAxis: "Battery ID", vAxis: "Idle Time (Hours)"});
			this.gridColumns.idleTime = [
				batteryIdColumn,
				{headerName: "Battery SN", field: "battery_sn", filter: 'agTextColumnFilter'},
				{headerName: "Truck ID", field: "truckid", filter: 'agTextColumnFilter'},
				{headerName: "Average Idle Time", field: "average_idle_time", filter: 'agTextColumnFilter'},
				{headerName: "Total Idle Time", field: "total_idle_time", filter: 'agTextColumnFilter'},
			];
			this.gridData.idleTime = idleTimeGridItems;
			this.gridIds.idleTime = 'battery_idleTime';
		}


		if (this.analyticsFilter.data.totalChargeTime.checked || this.analyticsFilter.data.averageChargeTime.checked) {
			var chargeTimeChartColumns = [
				{
					type: "string",
					label: "Battery ID"
				}
			];
			if (this.analyticsFilter.data.averageChargeTime.checked) {
				chargeTimeChartColumns.push({
					type: 'number',
					label: 'Average Charge Time (Hours)'
				});
			}
			if (this.analyticsFilter.data.totalChargeTime.checked) {
				chargeTimeChartColumns.push({
					type: 'number',
					label: 'Total Charge Time (Hours)'
				});
			}
			this.chartData.chargeTime = [chartColumns].concat(chargeTimeRows);
			this.chartConfig.chargeTime = this.getChartConfig({hAxis: "Battery ID", vAxis: "Charge Time (Hours)"});
			this.gridColumns.chargeTime = [
				batteryIdColumn,
				{headerName: "Battery SN", field: "battery_sn", filter: 'agTextColumnFilter'},
				{headerName: "Truck ID", field: "truckid", filter: 'agTextColumnFilter'},
				{headerName: "Average Charge Time", field: "average_charge_time", filter: 'agTextColumnFilter'},
				{headerName: "Total Charge Time", field: "total_charge_time", filter: 'agTextColumnFilter'},
			];
			this.gridData.chargeTime = chargeTimeGridItems;
			this.gridIds.chargeTime = 'battery_chargeTime';
		}
	}

	getChartConfig(axisNames) {
		return new ColumnChartConfig({
			explorer: {
				actions: [
					"dragToZoom",
					"rightClickToReset"
				],
				keepInBounds: true
			},
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
			LegendPosition: "top",
			yTitle: axisNames.vAxis,
			xTitle: axisNames.hAxis,
			colors: ["#4ca64c","#428bca"],
			tooltip: ''
		});
	}
	drawChart(id, columns, rows, axisNames) {
					
		// var chartDataTable = new google.visualization.DataTable();
		
		// _.each(columns, function(column) {
		// 	chartDataTable.addColumn(column.type, column.name);
		// });
		
		// chartDataTable.addRows(rows);
		// chartDataTable.sort([{column: 0}]);
		var options = {
			
		};
		// var chart = new google.visualization.ColumnChart(document.getElementById(id));
		// // chartUtils.generateDownloadPNG(chart, "rtRecords", function(image) {});
		// chart.draw(chartDataTable, options);
	}


	drawGrid(optionsName, gridName, columnDefs, gridItems) {
		
		this[optionsName] = {
			name: gridName,
			columnDefs: columnDefs
		 };
		this[optionsName].data = gridItems;
	}

	toggleMainMenu() {
		// $('#main-menu').slideToggle(300);
	}

	getSiteBattviews() {
		
		var battviewInfo: any = {};
		battviewInfo.customerid	= this.customerId;
		battviewInfo.siteid		= this.siteId;
		battviewInfo.getTags	= true;

		this.battviewsDashboardService.getBattviews(battviewInfo).subscribe((battviews: any) => {

			this.hasBattviews = (battviews.list.length > 0);
				/* sort list by batterid then serialnumber*/
				var sortedList =  _.chain(battviews.list)
								.sortBy('serialnumber')
								.sortBy('batteryid')
								.value();

				this.bfds		= sortedList;
				this.siteTags	= battviews.tags.siteTags;
				this.deviceTags	= battviews.tags.deviceTags;
		});
	};
}