import { Component, OnInit, Input, OnDestroy, OnChanges, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { ActivatedRoute } from '@angular/router';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';
import { ChargersDashboardService } from '../chargers-dashboard/chargers-dashboard.service';
import { ChartUtil } from '../../../../shared/services/utility/chart.service';
import { LineChartConfig } from '../../../../shared/google-chart/Models/LineChartConfig';
import { SiteDashboardService } from '../../site-dashboard.service';
import { UserService } from '../../../../auth/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-chargers-energy',
	templateUrl: './chargers-energy.component.html',
	styleUrls: ['./chargers-energy.component.css']
})
export class ChargersEnergyComponent implements OnInit, OnDestroy, OnChanges {

	@Input() charger: any = {};
	@Input() dateObj: any = {from: null, to: null};
	@Output() updateActivePage = new EventEmitter<any>(true);

	gridExportName: string = '';
	gridModelId: number = 0;
	isSiteEnergy: boolean = true;
	LivePowerSnapshots: any[] = [];
	PowerSnapshots: any;
	chargersEnergyRecurringFunction: any;
	currentSite: any = {};
	startTime: number	= 0;
	endTime: number		= 0;
	errMessage: string = '';
	chargerEnergyGridColumns: any[] = [];
	chargerEnergyGridData: any[] = [];
	images: any[] = [];
	snapshotsData: any[] = [];
	snapshotsConfig: any[] = [];
	noChart: boolean[] = [false, false];
	currentUser: any = {};

	constructor(
		private commonUtil: CommonUtil,
		private route: ActivatedRoute,
		private sideMenuService: SideMenuService,
		private chargerDashboardService: ChargersDashboardService,
		private chartUtil: ChartUtil,
		private userService: UserService,
		private siteDashboardService: SiteDashboardService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		let viewName = this.route.snapshot.data['viewName'];
		if(viewName == "dashboard")
			this.isSiteEnergy = false;

		let gridExportName = 'chargerEnergyGrid';

		if(this.isSiteEnergy) {
			this.siteDashboardService.setActiveSubTab('site-energy');
			gridExportName = 'siteEnergyGrid';
		}

		this.gridExportName = gridExportName;

		this.sideMenuService.currentSite.subscribe(data => {
			if(data && data.id) {
				this.currentSite = data;
				this.gridModelId = this.currentSite.id;
				if(this.isSiteEnergy) {
	
					clearTimeout(this.chargersEnergyRecurringFunction);
					if(this.currentSite.act_intelligent_type != 0)
						this.getChargerPowerSnapshots(true);
				}
			}
		});
		
		this.chargerEnergyGridColumns = [
			{headerName: "g.time", field: "snapshotsitetime", filterParams: {defaultOption: 'startsWith'}, width: 300},
			{headerName: "g.KW", field: "kw", type:"number", filterParams: {format: '1.1-1'}, width: 300},
		];
		
		if(this.isSiteEnergy) {
			this.chargerEnergyGridColumns.push(
				{headerName: "device.number_of_chargers", field: "chargers_count", type:"number", width: 300}
			);
		}

		this.currentUser = this.userService.getCurrentUser();

		if(this.currentUser.isACTuser) {
			this.chargerEnergyGridColumns.push(
				{headerName: "charger.insertion_time", field: "insertiontime", hide: true, filterParams: {defaultOption: 'startsWith'}, width: 300}
			);
		}

		this.dateObj = {
			'from':	new Date(moment().utc().subtract(1, 'days').startOf('day').unix() * 1000),
			'to':	new Date(moment().utc().startOf('day').unix() * 1000)
		}
	}

	ngOnChanges(changes) {
		if(Object.keys(changes.charger).length > 0)
			if(!this.isSiteEnergy) {
				clearTimeout(this.chargersEnergyRecurringFunction);
				this.gridModelId = this.charger.id;
				if(this.charger.actviewenable)
					this.getChargerPowerSnapshots(true);
				else
					this.updateActivePage.emit(['analytics','cycles']);
			}
	}

	printChargerEnergyChart(isLive) {

		var fromDate	= (<HTMLInputElement>document.getElementById('fromDate')).value;
		var toDate		= (<HTMLInputElement>document.getElementById('toDate')).value;
		var title		= this.translateService.instant('act.charger_energy');
		var headerText	= "";
		var elementId	= 'chargerEnergy';
		
		if(this.isSiteEnergy)
			title = this.translateService.instant('act.site_energy');
		else
			headerText = "<h4> " + this.translateService.instant('device.charger_id') + this.charger.id + "</h4>";
		
		if(isLive) {
			elementId = 'liveChargerEnergy';
			
			if(!this.LivePowerSnapshots || this.LivePowerSnapshots.length == 0)
				return;

			var firstRecord	= this.LivePowerSnapshots[0];
			var lastRecord	= this.LivePowerSnapshots[this.LivePowerSnapshots.length - 1];

			var firstRecordTime	= moment(firstRecord.snapshottime).unix();
			var lastRecordTime	= moment(lastRecord.snapshottime).unix();

			if(this.isSiteEnergy) {
				fromDate	= moment(this.commonUtil.getZoneTimestampFromUTC(this.currentSite.zoneid, firstRecordTime) * 1000).utc().format('MM/DD/YYYY hh:mm A');
				toDate		= moment(this.commonUtil.getZoneTimestampFromUTC(this.currentSite.zoneid, lastRecordTime) * 1000).utc().format('MM/DD/YYYY hh:mm A');
			} else {
				fromDate	= moment(this.commonUtil.getZoneTimestampFromUTC(this.charger.zoneid, firstRecordTime, this.charger.daylightsaving) * 1000).utc().format('MM/DD/YYYY hh:mm A');
				toDate		= moment(this.commonUtil.getZoneTimestampFromUTC(this.charger.zoneid, lastRecordTime, this.charger.daylightsaving) * 1000).utc().format('MM/DD/YYYY hh:mm A');
			}
		}

		var companyFullName	= this.currentSite.customer_name + '/' + this.currentSite.name;
		var image = this.images[elementId];
		var printContent = '<div>' + companyFullName + "<h3>" + title + "</h3><h4>"+ this.translateService.instant('g.from') + ": " + fromDate + "</h4><h4>" + this.translateService.instant('g.to') + ": " +toDate + "</h4>" + headerText + '<img src="'+image+'" style="width:100%;"" border="0" alt="Null"></div>';

		this.commonUtil.print({
			appendPrintContent: printContent
		});
	}

	drawChargerEnergyChart(isLive = false) {
		let snapshotsData = [
			[
				{label: this.translateService.instant('g.date'), type: "datetime"},
				{label: this.translateService.instant('g.KW'), type: "number"},
			]
		];

		if(this.isSiteEnergy)
			snapshotsData[0].push({label: this.translateService.instant('device.number_of_chargers'), type: "number"},)

		var snapshotsArr = this.PowerSnapshots;
		var hAxisTitle = this.translateService.instant('time.day_n_time');
		
		if(isLive) {
			snapshotsArr = this.LivePowerSnapshots;
			hAxisTitle = this.translateService.instant('g.time');
		}

		var data = [];
		var ticks = [];
		var energyTicks = [];
		var maxChargersCount = 0;
		var maxEnergy = 0;

		for (var i = 0; i < snapshotsArr.length; i++) {
			data[i] = [
				snapshotsArr[i].time,
				snapshotsArr[i].kw
			];
			if(this.isSiteEnergy) {

				data[i].push(snapshotsArr[i].chargers_count);
				if(snapshotsArr[i].chargers_count > maxChargersCount)
					maxChargersCount = snapshotsArr[i].chargers_count;
			}
			if(snapshotsArr[i].kw > maxEnergy) {
				maxEnergy = snapshotsArr[i].kw;
			}
		}
		
		for (var i = 0; i <= maxChargersCount+1; i++) {
			ticks.push(i);
		}
		let step = (0.25*maxEnergy);
		if(step == 0) {
			step = 1;
		}
		for (var i = 0; i <= maxEnergy+step; i+=step) {
			energyTicks.push(i);
		}

		this.noChart[isLive ? 1 : 0] = (data.length < 2);
		this.snapshotsData[isLive ? 1 : 0] = snapshotsData.concat(data);

		// snapshotsDataTable.sort([{column: 0}]);

		var vAxes = {
			"0" : {
				textPosition: "out",
				textStyle: {color: '#328332'},
				title: this.translateService.instant('g.KW') ,
				titleTextStyle: {color: '#328332'},
				ticks: energyTicks
			}
		};

		var series = {
			"0" : {
				"targetAxisIndex": 0,
				"color": "#328332" ,
				type: 'line'
			}
		};

		if(this.isSiteEnergy) {
			vAxes["1"] = {
				textPosition: "out", 
				textStyle:{color: '#b20e66'}, 
				title: this.translateService.instant('device.number_of_chargers'), 
				titleTextStyle: {color: '#b20e66'},
				ticks: ticks
			};
			
			series["1"] = {
				"targetAxisIndex": 1, 
				"color": "#b20e66", 
				type: 'line'
			};
		}

		if(snapshotsArr.length === 0) {

			vAxes["0"].title = "";
			
			if(this.isSiteEnergy)
				vAxes["1"].title = "";
		}

		this.snapshotsConfig[isLive ? 1 : 0] = new LineChartConfig({
			LegendPosition: 'top', 
			hAxis: {
				title: hAxisTitle
			},
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
			pointSize: 3,
			vAxes: vAxes,
			series: series,
			explorer: {
				"actions": [
					"dragToZoom",
					"rightClickToReset"
				],
				maxZoomIn: 50.0,
				keepInBounds: true
			},
			useDateFormat: true,
			widgetHeight: 400,
			tooltip: {},
		});

		// if(isLive) {

		// 	var ele = document.getElementById(chartId);
		// 	if(!ele) {
		// 		return this.drawChargerEnergyChart(isLive);
		// 	}
		// }
	}

	getChargerPowerSnapshots(isLive, isRefresh = false) {

		this.PowerSnapshots = [];

		var fromDate	= moment(this.dateObj.from).utc().startOf('day').unix();
		var toDate		= moment(this.dateObj.to).utc().endOf('day').unix();

		if(this.isSiteEnergy) {

			fromDate	= this.commonUtil.getUTCTimestampFromZone(this.currentSite.zoneid, fromDate);
			toDate		= this.commonUtil.getUTCTimestampFromZone(this.currentSite.zoneid, toDate);
		} else {

			fromDate	= this.commonUtil.getUTCTimestampFromZone(this.charger.zoneid, fromDate, this.charger.daylightsaving);
			toDate		= this.commonUtil.getUTCTimestampFromZone(this.charger.zoneid, toDate, this.charger.daylightsaving);
		}

		if(isLive) {

			var minsAgo = 10;
			if(this.currentSite.dr_provider != 'None')
				minsAgo = 5;

			var oneHourAgo	= moment().utc().subtract(1, 'hours').unix();
			var xMinsAgo	= moment().utc().subtract(minsAgo, 'minutes').unix();

			if(isRefresh && !this.isSiteEnergy) {

				var lastRecord				= this.LivePowerSnapshots[this.LivePowerSnapshots.length - 1];
				var lastRecordTime			= lastRecord ? moment(lastRecord.snapshottime).unix() : 0;
				var startTime				= oneHourAgo;
				var endTime					= xMinsAgo;
				var lastConnectTime 		= moment(this.charger.lastconnecttime).unix();
				var minsBeforeLastConnect	= lastConnectTime - (7 * 60);
				
				if(lastRecord) {
					
					if(minsBeforeLastConnect > lastRecordTime) {

						startTime = minsBeforeLastConnect;
					} else {

						startTime = lastRecordTime + 1;

						if(this.startTime == startTime)
							startTime += 60;
					}
					
					endTime = startTime + 60;

				} else if(minsBeforeLastConnect > oneHourAgo) {

					startTime = minsBeforeLastConnect;
				}

				this.startTime	= startTime;
				this.endTime	= endTime;
			} else {

				this.startTime	= oneHourAgo;
				this.endTime	= xMinsAgo;
			}

			fromDate	= this.startTime;
			toDate		= this.endTime;
		} else {

			this.errMessage = '';
			if(toDate < fromDate) {

				this.errMessage = '* End date should be later than start date!';
				this.chargerEnergyGridData = [];
				this.PowerSnapshots = [];
				return this.drawChargerEnergyChart();
			}
		}

		var theID = this.currentSite.id;
		if(!this.isSiteEnergy)
			theID = this.charger.id;

		this.chargerDashboardService.getChargerPowerSnapshots(theID, fromDate, toDate, this.isSiteEnergy, isRefresh).subscribe((response: any) => {

			if(isLive && !this.chargersEnergyRecurringFunction) {
				this.chargersEnergyRecurringFunction = setInterval(() => {
					this.getChargerPowerSnapshots(true, true);
				}, 60000); //call every 1 minute
			}

			var snapshots = response.snapshots;
			
			if(!this.isSiteEnergy)
				this.charger.lastconnecttime = response.lastconnecttime;

			snapshots.forEach((record, idx) => {
				
				if(this.isSiteEnergy) {

					var snapshotTime	= this.commonUtil.getZoneTimestampFromUTC(this.currentSite.zoneid, moment(record.snapshottime).utc().unix());
					var insertionTime	= this.commonUtil.getZoneTimestampFromUTC(this.currentSite.zoneid, moment(record.insertiontime).utc().unix());
					snapshots[idx].kw	= Math.round((record.output_power * 1.07 * 10) / 1000) / 10;
				} else {

					var snapshotTime 	= this.commonUtil.getZoneTimestampFromUTC(this.charger.zoneid, moment(record.snapshottime).utc().unix(), this.charger.daylightsaving);
					var insertionTime 	= this.commonUtil.getZoneTimestampFromUTC(this.charger.zoneid, moment(record.insertiontime).utc().unix(), this.charger.daylightsaving);
					snapshots[idx].kw   = 0;

					if(record && record.snapshot) {
						snapshots[idx].snapshot = this.commonUtil.decompress(record.snapshot, 'snapshot');
						snapshots[idx].kw	= Math.round(((record.snapshot.voltage * record.snapshot.current) * 1.07 * 10) / 1000) / 10;
					}
				}
				
				snapshots[idx].snapshotsitetime	= this.commonUtil.getDateFormattedFromUnixTimeStamp(snapshotTime);
				snapshots[idx].insertiontime	= this.commonUtil.getDateFormattedFromUnixTimeStamp(insertionTime);
				snapshots[idx].time				= new Date(record.snapshotsitetime);
			});
			
			if(isLive) {

				if(isRefresh && !this.isSiteEnergy)
					this.LivePowerSnapshots = this.LivePowerSnapshots.concat(snapshots);
				else
					this.LivePowerSnapshots = snapshots;
			} else {
				
				this.chargerEnergyGridData	= snapshots;
				this.PowerSnapshots			= snapshots;
			}
			
			this.drawChargerEnergyChart(isLive);
		});
	}

	ngOnDestroy() {
		clearInterval(this.chargersEnergyRecurringFunction);
	}
	generateImageURI(event) {
		let chart = event['chart'];
		let elementId = event['elementId'];
		let image = this.chartUtil.getChartImageUri(chart);
		this.images[elementId] = image;
	}
}