import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AdminService } from '../../../admin.service';
import { BreadcrumbService } from '../../../../shared/breadcrumb/breadcrumb.service';
import { NavigationService } from '../../../../shared/services/navigation.service';
import { NotificationMessageService } from '../../../../shared/notification-message/notification-message.service';

@Component({
	selector: 'app-manage-equipment',
	templateUrl: './manage-equipment.component.html',
	styleUrls: ['./manage-equipment.component.css']
})
export class ManageEquipmentComponent implements OnInit, OnDestroy {

	operation: string;
	equipment: {
		equipment_id: number,
		serialnumber: string,
		description: string,
		shipping_date: Date,
		purchase_date: Date,
		isParent: boolean,
		childId: number
		id?: number
	};
	equipments: any[];
	linkType: string;
	linkId: string;
	childId: number;
	equipmentId: number;
	isParent: boolean;
	isNew: boolean;
	blockSaveButton: boolean;

	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute,
		private breadcrumb: BreadcrumbService,
		public navigation: NavigationService,
		private router: Router,
		private notificationMessage: NotificationMessageService,
	) {
	}

	ngOnInit() {
		this.linkType = this.route.snapshot.params.linkType;
		this.linkId = this.route.snapshot.params.linkId;
		this.equipmentId = +(this.route.snapshot.params.equipmentId);
		this.childId = +(this.route.snapshot.params.childId);
		this.isParent = this.childId == 0 && (this.route.snapshot.params.action != 'create' || this.equipmentId == 0);
		this.isNew = this.route.snapshot.params.action == 'create';

		let breadCrumbLabel = '';
		let url = '';
		let breadcrumbs = [];
		switch(this.linkType) {
			case 'oem':
				breadCrumbLabel = 'OEMs';
				url = '/oems';
			break;
			case 'dealer':
				breadCrumbLabel = 'Dealers';
				url = '/dealers';
			break;
			case 'truck-dealer':
				breadCrumbLabel = 'Truck Dealers';
				url = '/truck-dealers';
			break;
			case 'customer':
				breadCrumbLabel = 'Customers';
				url = '/customers';
			break;
			case 'site':
				breadcrumbs.push({label: 'Customers', url: '/customers'});
				breadCrumbLabel = 'Sites';
				url = '/sites/customerId/'+this.linkId;
			break;
		}
		let currentUrl = this.router.url;
		if (currentUrl.substr(-1) != '/') currentUrl += '/';

		breadcrumbs.push({label: breadCrumbLabel, url: url});
		breadcrumbs.push({label: 'Equipment', url: '/equipments-devices/'+this.linkType+'/'+this.linkId});
		breadcrumbs.push({label: 'Edit', url: ''});
		
		this.breadcrumb.setBreadcrumb(breadcrumbs);

		this.equipment = {
			equipment_id: null,
			serialnumber: null,
			description: null,
			shipping_date: null,
			purchase_date: null,
			isParent: this.isParent,
			childId: this.childId
		};
		this.operation		= (this.isNew ? 'Add' : 'Update');
		this.equipment.id = +(this.equipmentId);
		this.adminService.getEquipmentsDevices(this.equipmentId, this.linkId, this.linkType).subscribe(
			(response:{equipment: any, equipmentDevices: any[]}) => { 
			this.equipments = response.equipment;
			if(!this.isNew || !this.isParent) {
				var parentEquipmentDevice;
				var parentEquipmentId;
				var equipments = response.equipmentDevices;
				if(!this.isParent) {
					parentEquipmentDevice = equipments[0];
					parentEquipmentId = parentEquipmentDevice.equipment_id;
				}
				if(!this.isNew) {
					// edit mode
					if(this.isParent)
						for(let key in equipments[0]) {
							if(['shipping_date', 'purchase_date'].indexOf(key) > -1 && equipments[0][key] != null)
								this.equipment[key] = new Date(equipments[0][key]);
							else
								this.equipment[key] = equipments[0][key];
						}
					else
						for(let key in equipments[0].children[this.childId]){
							if(['shipping_date', 'purchase_date'].indexOf(key) > -1 && equipments[0].children[this.childId][key] != null)
								this.equipment[key] = new Date(equipments[0].children[this.childId][key]);
							else
								this.equipment[key] = equipments[0].children[this.childId][key];
						}
				}

				this.filterEquipmentTypes({parent_equipment_type: parentEquipmentId});
			} else
				this.filterEquipmentTypes();
			}
		);
	}

	filterEquipmentTypes(flags = {parent_equipment_type : 0}) {
		var parentEquipmentType = flags.parent_equipment_type || 0;
		var tempEquipments = [];
		for(var item of this.equipments) {
			var filteredOut = false;
			if(this.isParent && item.parent_device_id && item.parent_device_id.length > 0)
				filteredOut = true;

			if(!item.link_to || item.link_to.indexOf(this.linkType) === -1)
				filteredOut = true;

			if(!this.isParent && !(item.parent_device_id && item.parent_device_id.indexOf(parentEquipmentType) > -1))
				filteredOut = true;

			if(!filteredOut)
				tempEquipments.push(item);
		};

		this.equipments = tempEquipments;
	};
			
	saveEquipment() {
		try {	
			this.blockSaveButton = true;
			this.adminService.updateEquipmentDevice(this.equipment, this.linkId, this.linkType).subscribe(
				response => {
					this.notificationMessage.setMessage('globalSuccessMsg');
					this.router.navigate(['/equipments-devices', this.linkType, this.linkId]);
				},
				err => {
					this.blockSaveButton = false;
				}
			);
		} catch(err) {
			this.blockSaveButton = false;
		}
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}