import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../shared/breadcrumb/breadcrumb.service';

@Component({
	selector: 'app-unauthorized',
	templateUrl: './unauthorized.component.html',
	styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

	constructor(
		private breadcrumb: BreadcrumbService
	) {
		this.breadcrumb.hide();
	}

	ngOnInit() {
	}
}
