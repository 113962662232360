<div class="row sitemap">
  <div class="boxs2" ng-class="{'customer-map' : !draw}">
  	<agm-map
      [zoom]="4"
      [latitude]="40.513799"
      [longitude]="-101.953125"
      [disableDefaultUI]="true"
      [panControl]="false"
      [streetViewControl]="false"
      [styles]="[{stylers:[{gamma:0.9}]},{featureType:'road',stylers:[{visibility:'off'}]},{featureType:'poi',stylers:[{visibility:'off'}]}]"
      [panControl]="false"
      [zoomControl]="true"
      class="customer-map">
      	<agm-marker *ngFor="let site of sites"
	      	title="{{site.name}}"
	      	[latitude]="site.latitude"
	        [longitude]="site.longitude"
	        [iconUrl]="site.icon"
	        (markerClick)="siteMarkerClick(site)">
      	</agm-marker>
    </agm-map>
    <div id="over_map" 
      class="customer-site-box"
      *ngIf="siteInfoBox.show">
      <a 
        (click)="setInfoBoxVisibility(false)"
        class="icon-close pointer">&#10006;</a>
      <div class="site-info">
        <a 
          class="link"
          [routerLink]="['/', siteInfoBox.customer['id'], siteInfoBox.site['id']]">{{siteInfoBox.customer.name}}/ {{siteInfoBox.site.name}}</a>
        <div class="address" >{{siteInfoBox.site.address}}</div>
        <div class="margin-top margin-bottom">
          <div class="charger">
           	<img src="images/Charger_3.png" class="margin-right">
	          <span>{{siteInfoBox.site.total_chargers}}</span>
	          : Quantum Chargers
	        </div>
          <div class="battery">
            <img src="images/Battery_icon.png" class="margin-right">
            <span>{{siteInfoBox.site.total_battviews}}</span>
            : BATTViews
          </div>
          <div class="intelligent" *ngIf="siteInfoBox.actIntelligent">
            <img src="images/ACTintelligent.png" class="margin-right">
            ACT Intelligent
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
