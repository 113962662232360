<app-site-widget
	title="EBU Daily Usage Summary"
	elementId="truck_ebu_usage_summary"
	chartType="column"
	widgetClass="height-600"
	[siteWidgetOptions]="{hasOperation: true, hasWorkingDaysCheckBox: true, hasDate: true, isSitePerformanceWidget: true, isTrucks: true}"
	[widgetOptions]="{}"
	[data]="data"
	[config]="config"
	[tableColumns]="tableColumns"
	[siteId]="siteId"
	[customerId]="customerId"
	[customerName]="customerName"
	[siteName]="siteName"
></app-site-widget>