<form #oemForm="ngForm" (ngSubmit)="updateOEM()">
	<div class="row">
		<div class="col-md-6 col-md-offset-3">
			<div class="boxs3">
				<h2>Add/Update Battery OEM Info</h2>
				<div class="panel panel-default">
					<div class="panel-heading">OEM Info</div>
				</div>
				<div class="form-field">
					<label for="name">Battery OEM Name</label>
					<input type="text" required class="form-control" name="oemName" [(ngModel)]="oem.name" placeholder="Battery OEM Name" />
				</div>
				<br/>
				<button type="submit" class="btn btn-primary" [disabled]="oemForm.invalid">Submit</button>
				<button type="button" class="btn btn-default" routerLink="/oems">Cancel</button>
			</div>
		</div>
	</div>
</form>