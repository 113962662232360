<div class="row">
  <div id="page-wrapper" >
    <div id="page-inner">
      <div class="col-md-12 panel panel-default">
        <div class="panel-body">
          <div class="col-md-12">
            <div class="col-md-5 padding-8">
              <div class="col-md-4 col-md-offset-2 text-right"><strong>Device Type:</strong></div>
              <div class="col-md-6">
                <select [(ngModel)]="options.type" class="form-control">
                  <option value=""></option>
                  <option value="Charger">Charger</option>
                  <option value="BATTView">BATTView</option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="col-md-5 padding-8">
              <div class="col-md-4 col-md-offset-2 text-right"><strong>Original ID:</strong></div>
              <div class="col-md-6">
                <input type="number" class="form-control" [(ngModel)]="options.originalid"/>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="col-md-5 padding-8">
              <div class="col-md-4 col-md-offset-2 text-right"><strong>Original SN:</strong></div>
              <div class="col-md-6">
                <input type="text" class="form-control" [(ngModel)]="options.originalsn"/>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="col-md-5 padding-8">
              <div class="col-md-4 col-md-offset-2 text-right"><strong>New ID:</strong></div>
              <div class="col-md-6">
                <input type="number" class="form-control" [(ngModel)]="options.newid"/>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="col-md-5 padding-8">
              <div class="col-md-4 col-md-offset-2 text-right"><strong>New SN:</strong></div>
              <div class="col-md-6">
                <input type="text" class="form-control" [(ngModel)]="options.newsn"/>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="col-md-5 padding-8">
              <div class="col-md-4 col-md-offset-2 text-right"><strong>User ID:</strong></div>
              <div class="col-md-6">
                <input type="text" class="form-control" [(ngModel)]="options.userid"/>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="col-md-5 padding-8">
              <div class="col-md-4 col-md-offset-2 text-right"></div>
              <div class="col-md-5">
                <button class="form-control btn btn-success" (click)="getReplacementHistory()">Detect Replacement</button>
              </div>
            </div>
          </div>
          
          <div class="col-md-12">
            <div class="col-md-1">
              <button [disabled]="selectedLogs.length == 0" class="btn btn-default" (click)="deleteReplacementLogs()">Delete<br/>Selected</button>
            </div>

            <div class="col-md-11">
              <table class="boxs3 table table-striped">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Device Type</th>
                    <th>Original ID</th>
                    <th>Original SN</th>
                    <th>New ID</th>
                    <th>New SN</th>
                    <th>User ID</th>
                    <th>Upload Time</th>
                    <th>Deleted?</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let log of replacementHistory">
                    <td><input type="checkbox" class="form-control" [(ngModel)]="selectedlogs[log.id]" (change)="toggleLogsSelection()" [disabled]="log.isdeleted"/></td>
                    <td>{{log.type}}</td>
                    <td>{{log.originalid}}</td>
                    <td>{{log.originalsn}}</td>
                    <td>{{log.newid}}</td>
                    <td>{{log.newsn}}</td>
                    <td>{{log.userid}}</td>
                    <td>{{log.uploadtime | date:'MM/dd/yyyy hh:mm:ss'}}</td>
                    <td>{{log.isdeleted}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>