import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { DevicesDashboardService } from '../devices-dashboard.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import * as _ from 'lodash';

@Component({
	selector: 'app-connectivity-track',
	templateUrl: './connectivity-track.component.html',
	styleUrls: ['./connectivity-track.component.css']
})
export class ConnectivityTrackComponent implements OnInit, OnChanges {

	@Input() hasDevices: boolean = null;
	@Input() device: any = {};
	@Input() deviceType: string = null;
	@Input() dateRange: {
		'fromDateFmt': Date,
		'toDateFmt': Date
	};
	
	@Output() updateAppearanceElementsFlags = new EventEmitter<any>(true);

	gridColumns: any;
	gridColumnsClone: any;
	gridData: any[] = [];

	constructor(
		private devicesDashboardService: DevicesDashboardService,
		private commonUtil: CommonUtil,
	) { }

	ngOnInit() {
		if(this.deviceType =='battview') {
			this.updateAppearanceElementsFlags.emit({
				addNoteEnabled: false,
				pageHasFilterByDate: true,
				pageHasBattviewTags: true,
				hasResetPLC: false,
				pageHasManageBtn: true,
				pageHasTimesInfo: true,
			});
		}
		this.gridColumns = [
			{headerName: "site.last_connect_time", field: "last_connect_time", filterParams: {defaultOption: 'startsWith'}, width: 300},
			{headerName: "charger.log_time", field: "log_time", filterParams: {defaultOption: 'startsWith'}, width: 300},
		];
	}

	ngOnChanges(changes) {
		this.getConnectivityTrack();
	}

	getConnectivityTrack() {
		
		if(!this.device.id || !this.dateRange.fromDateFmt || !this.dateRange.toDateFmt || this.hasDevices === null)
			return;

		var zoneDiff		= new Date().getTimezoneOffset() * -1;
		var fromDate:any	= new Date(new Date(this.dateRange.fromDateFmt).getTime() + (zoneDiff * 60 * 1000));
		var toDate:any		= new Date(new Date(this.dateRange.toDateFmt).getTime() + (zoneDiff * 60 * 1000));
		fromDate			= moment(fromDate).utc().startOf('day').unix();
		toDate				= moment(toDate).utc().endOf('day').unix();

		this.devicesDashboardService.getConnectivityTrack(this.deviceType, this.device.id, fromDate, toDate).subscribe(
			(response: any) => {

				response.forEach((record, idx) => {
					response[idx].last_connect_time = this.commonUtil.getDateFormattedFromUnixTimeStamp(new Date(Date.parse(record.last_connect_time)).getTime()/1000);
					response[idx].log_time = this.commonUtil.getDateFormattedFromUnixTimeStamp(new Date(Date.parse(record.log_time)).getTime()/1000);
				});

				this.gridData = response;
				this.gridColumnsClone = _.cloneDeep(this.gridColumns);
			}
		);
	};

}