<div id="page-wrapper">
  <form class="col-md-4">
    <div class="input-group" id="adv-search">
      <input type="text" class="form-control" placeholder="Search for device" [(ngModel)]="serialnumber" name="serialnumber">
      <div class="input-group-btn">
          <div class="btn-group" role="group">
              <button type="button" class="btn btn-primary" (click)="getReplacementDevices()">
                <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
              </button>
          </div>
      </div>
    </div>
  </form>
  <div class="boxs">
    <table class="table table-stripped">
      <thead>
        <tr>
          <th class="red-text">ActView ID</th>
          <th>Serial Number</th>
          <th>Model</th>
          <th>Installation Date</th>
          <th>Device Name</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let device of devices">
          <td class="red-text">{{device.id}}</td>
          <td>{{device.serialnumber}}</td>
          <td>{{device.model}}</td>
          <td>{{device.installationdate | date:'MM/dd/yyyy'}}</td>
          <td>{{device.prettyname}}</td>
          <td>
            <div class="btn-group">
              <button type="button" class="btn btn-primary" (click)="replaceDevice(device.id)" *ngIf="mode != 'doReplacement' || device.actviewenabled">Replace Device</button>
              <strong *ngIf="mode == 'doReplacement' && !device.actviewenabled">Not ACT intelligent!</strong>
            </div>
          </td>
        </tr>	
      </tbody>
    </table>
  </div>
</div>
<app-modal #modal [title]="'Replace Device'" [confirmBtnText]="'OK'" [closeBtnText]="'Cancel'">{{replaceMsg}}</app-modal> 
<app-modal #modal2 [title]="'Replace Device'" [confirmBtnText]="'OK'" [closeBtnText]="'Cancel'">This Operation can't be undone. Click OK to proceed.</app-modal> 