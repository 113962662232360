import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from '../../../auth/user.service';
import { AdminService } from '../../admin.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { BattviewService } from './battview.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-battviews',
	templateUrl: './battviews.component.html',
	styleUrls: ['./battviews.component.css']
})
export class BattviewsComponent implements OnInit, OnDestroy {

	owners = {
		customerid: 0,
		siteid: 0
	}

	dealerId: 0;
	pageName: string;
	dealers = <any>[];

	devices = <any>[];
	selectedDevices = [];
	selectAllDevices: boolean;

	constructor(
		private adminService: AdminService,
		public userService: UserService,
		private route: ActivatedRoute,
		private router: Router,
		private battviewService: BattviewService,
		private notificationMessage: NotificationMessageService,
		private breadcrumb: BreadcrumbService,
		private translateService: TranslateService
	) {

	}

	ngOnInit() {
		this.selectAllDevices = false;
		this.pageName = this.route.snapshot.data['pageName'];
		
		let breadcrumbArr = [];

		if(this.pageName == 'listing') {
			this.route.params.subscribe( params => {

				this.owners.customerid = params['customerId'];
				this.owners.siteid = params['siteId'];

				breadcrumbArr = [
					{'label': this.translateService.instant('g.customers'), 'url': '/customers'},
					{'label': this.translateService.instant('g.sites'), 'url': '/sites/customerId/'+this.owners.customerid},
					{'label': this.translateService.instant('g.battviews'), 'url': ''}
				];

				this.getBattviews();
			});
		} else {

			let label = this.translateService.instant('g.unassigned_battviews');
			if(this.pageName == 'unassignedSite')
				label = this.translateService.instant('g.unassigned_site_battviews');
			else if(this.pageName == 'battviewMobile')
				label = this.translateService.instant('g.battviews_mobile_listing');
			
			breadcrumbArr = [{'label': label, 'url': ''}];

			this.route.queryParams.subscribe( params => {

				this.dealerId = params['dealerId'] || 0;
				this.getUnassignedBattViews(this.dealerId);
			});
			
			this.getDealers();
		}

		this.breadcrumb.setBreadcrumb(breadcrumbArr);
	}

	getDealers(id = 0) {
		let noDealer = {'id':0, 'name':this.translateService.instant('g.no_dealer_dash')};
		this.adminService.getDealers(id).subscribe(
			data => {
				let dealers = data['dealers'] || [];
				dealers.unshift(noDealer);
				this.dealers = dealers;
			}
		);
	}

	changeDealer() {
		this.router.navigate(
			['.'],
			{relativeTo: this.route, queryParams: {dealerId: this.dealerId}}
		);
	}

	getUnassignedBattViews(dealerId) {
		let data = {
			'dealerid': this.dealerId,
			'isUnassignedSite': this.pageName == 'unassignedSite',
			'isBattviewMobile': this.pageName == 'battviewMobile'
		};
		
		this.adminService.getUnassignedBattViews(data).subscribe(
			data => {
				this.devices = data;
				this.selectedDevices = [];
			}
		);
	}

	getBattviews() {
		this.adminService.getBattviews(this.owners).subscribe(
			data => {
				this.devices = data['list'];
				this.selectedDevices = [];
			}
		);
	}

	selectAllToggle(selectAllDevices) {
		let devices = [];
		let deviceIDs = [];
		let checked = selectAllDevices == true;
		
		for(let item of this.devices) {
			item.checked = checked;
			devices.push(item);
			if(checked)
				deviceIDs.push(Number(item.id));
		}
		
		this.devices = devices;
		this.selectedDevices = deviceIDs;
	};

	deleteBattview(id) {
		if(confirm(this.translateService.instant('g.del_battview_prompt'))) {
			this.adminService.deleteDevice(id, 'battview').subscribe(
				data => {
					this.getBattviews();
				}
			);
		}
	};

	editBattviewBtn(id, customerid, siteid) {
		if(this.pageName == 'battviewMobile') {

			this.router.navigate(
				['/battviews-mobile/edit'],
				{queryParams: {'battviewId': id}}
			);
		} else {

			this.router.navigate(
				['/battviews', 'edit', 'customerId', customerid, 'siteId', siteid],
				{queryParams: {'battviewId': id}}
			);
		}
	}

	toggleDeviceSelection(checked, device) {
		
		device.checked = checked;
		
		if(checked) {
			this.selectedDevices.push(device.id);
			return
		}

		let idx = this.selectedDevices.indexOf(device.id);
		if(idx != -1)
			this.selectedDevices.splice(idx, 1);
	}

	assignBattviewsToEastPenn() {
		if (confirm(this.translateService.instant('g.assign_to_east_penn'))) {
			this.adminService.assignBattviewsToEastPenn(this.selectedDevices).subscribe(
				(data: any) => {
					if(data.httpStatus == 'error') {

						var errorMessage = this.battviewService.formatProductionLineErrors(data.msg);
						this.notificationMessage.setMessage(errorMessage, 'danger', 30000);
					} else {

						this.selectedDevices = [];
						this.getBattviews();
					}
				}
			);
		}
	};

	autoCorrectBattview(id) {
		this.adminService.autoCorrentDevices(this.owners.siteid, 'battviews', id).subscribe(res=> {
			this.notificationMessage.setMessage(this.translateService.instant('device.auto_correct_msg') , 'success', 10000, true);
		});
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
