import { Component, Input, OnChanges } from '@angular/core';
import { CommonUtil } from '../../../shared/services/utility/common.service';

@Component({
	selector: 'app-customer-estimated-counts',
	templateUrl: './customer-estimated-counts.component.html',
	styleUrls: ['./customer-estimated-counts.component.css']
})
export class CustomerEstimatedCountsComponent implements OnChanges {

	@Input() estimatedCounts;

	chargers: number ;
	chargerAhrs: number;
	battviews: number;
	chargeCycles: number;
	dataRecords: string;
	dataRecordsUnit: string;
	days: number;
	energySavings: number;
	dollarSavings: number;
	co2Savings: number;
	co2SavedPerTree: number;
	whrsUnit: string;
	whrsUsed: number;
	ahrsUnit: string;
	ahrsUsed: number;
	chargerCyclesUnits: string;

	constructor(
		private commonUtil: CommonUtil
	) { }

	ngOnChanges() {

		if (this.estimatedCounts && ( this.estimatedCounts['battviews'] || this.estimatedCounts['chargers'])) {
			let counts				= this.calculateEstimations(this.estimatedCounts);
			this.chargers			= counts.total_chargers;
			this.chargerAhrs		= counts.charger_ahrs;
			this.battviews			= counts.total_battviews;
			this.days				= counts.days_of_operations;
			this.energySavings		= counts.total_energy_kwhrs_saved;
			this.dollarSavings		= counts.total_dollar_saving;
			this.co2Savings			= counts.co2_saving;
			this.co2SavedPerTree	= counts.co2_saved_per_urban_tree_planted;
			const chargerCycles	= this.commonUtil.calculateValuesWithUnit(counts.charge_cycles_delivered);
			this.chargeCycles       = chargerCycles.value;
			this.chargerCyclesUnits  = chargerCycles.unit;

			/* whrs Used */
			let whrsUsed = Math.round(counts.kwhrs_used);
			if (whrsUsed < 1000) {
				this.whrsUnit = "kWhrs";
			} else {
				/*convert to Mega and keep two decimals*/
				this.whrsUnit = "MWhrs";
				whrsUsed /= 1000;
			}
			this.whrsUsed = whrsUsed;

			/* Ahrs Used */
			var ahrsUsed = Math.round(counts.ahrs_used);
			if (ahrsUsed < 1000000) {
				this.ahrsUnit = "Ahrs";
			} else {
				ahrsUsed /= 1000000;
				this.ahrsUnit = "Million Ahrs";
			}
			this.ahrsUsed = ahrsUsed;

			/** Events Recorded */
			var dataRecordsSaved = counts.data_records_saved;
			if (dataRecordsSaved < 1000000) {
				dataRecordsSaved /= 1000;
				this.dataRecordsUnit = 'K';
			} else {
				dataRecordsSaved /= 1000000;
				this.dataRecordsUnit = 'M';
			}
			this.dataRecords = dataRecordsSaved;
		}

	}
 
	calculateEstimations(site) {
		// copied as is from angular 1 app
		let calculations = {
			total_chargers:						site.chargers.total_chargers,
			total_battviews:					site.battviews.total_battviews,
			kwhrs_used:							1.07 * site.chargers.total_kwhrs,
			ahrs_used:							site.battviews.total_inuse_ahrs,
			charger_ahrs:						site.battviews.total_charger_ahrs,
			charge_cycles_delivered:			site.chargers.total_records,
			data_records_saved:					site.battviews.total_events,
			days_of_operations:					0,
			total_energy_kwhrs_saved:			0,
			total_dollar_saving:				0,
			co2_saving:							0,
			co2_emitted_per_gallon_of_gasoline:	0,
			passenger_vehicles_per_year:		0,
			barrels_of_oil_emissions:			0,
			co2_saved_per_urban_tree_planted:	0
		};
				
		let DOO = site.battviews.total_days;

		if (site.chargers.total_days > DOO) {
			DOO = site.chargers.total_days;
		}	
		calculations.days_of_operations					= DOO;
		calculations.total_energy_kwhrs_saved			= 0.05 * calculations.kwhrs_used;
		calculations.total_dollar_saving				= 0.05 * calculations.total_energy_kwhrs_saved;
		calculations.co2_saving							= Math.pow(10, -4) * 7.03 * calculations.total_energy_kwhrs_saved;
		calculations.co2_emitted_per_gallon_of_gasoline	= calculations.co2_saving / (Math.pow(10, -3) * 8.887);
		calculations.passenger_vehicles_per_year		= calculations.co2_saving / 4.73;
		calculations.barrels_of_oil_emissions			= calculations.co2_saving / 0.43;
		calculations.co2_saved_per_urban_tree_planted	= calculations.co2_saving / 0.039;
		calculations.co2_saving *= 2204.62;

		return calculations;
	}
}
