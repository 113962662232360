<div>
	<button *ngIf="userService.hasAccessFunction('act_admin_device_management')" class="btn btn-primary mb20" type="button" (click)="editChargerBtn(0, owners.customerid, owners.siteid)">{{'g.add_new' | translate}} <span class="glyphicon glyphicon-plus"></span></button>
	<app-manage-device-btn [selectedDevices]="selectedDevices" *ngIf="!userService.hasAccessFunction('no_manage_devices')"></app-manage-device-btn>
	<button 
		*ngIf="userService.hasAccessFunction('edit_purchase_order')"
		[disabled]="selectedDevices <= 0" 
		class="btn btn-default mb20" 
		(click)="goToEditDevicePurchaseOrder()">
		{{'device.edit_purchase_order' | translate}}
	</button>
	<button *ngIf="userService.hasAccessFunction('enable_full_access_mode')" class="btn btn-default mb20" type="button" (click)="autoCorrectCharger(selectedDevices)" [disabled]="selectedDevices.length==0">{{'device.auto_correct' | translate}}</button>
	<div class="boxs">
		<div *ngIf="pageName != 'listing'">
			<label for="dealer">{{'g.select_dealer' | translate}} <select class="form-control inline-form-control" [(ngModel)]="dealerId" (ngModelChange)="changeDealer()" style="width: 70%;display: inline;margin-left: 10px;">
					<option *ngFor="let dealer of dealers" [value]="dealer.id">{{dealer.name}}</option>
				</select>
			</label>
		</div>
		<table class="table table-stripped">
			<thead>
				<tr>
					<th *ngIf="!userService.hasAccessFunction('no_manage_devices')"><input type="checkbox" [ngModel]="selectAllDevices" (ngModelChange)="selectAllToggle($event)"/> {{'g.select_all' | translate}}</th>
					<th class="red-text">{{'g.actview_id' | translate}}</th>
					<th>{{'device.serial_number' | translate}}</th>
					<th>{{'device.purchase_order' | translate}}</th>
					<th *ngIf="pageName == 'unassignedSite'">{{'device.customer_name' | translate}}</th>
					<th>{{'g.model' | translate}}</th>
					<th>{{'device.installation_date' | translate}}</th>
					<th>{{'device.charger_id' | translate}}</th>
					<th>{{'g.action' | translate}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let charger of devices">
					<td *ngIf="!userService.hasAccessFunction('no_manage_devices')"><input (ngModelChange)="toggleDeviceSelection($event, charger)" type="checkbox" class="form-control" [checked]="charger.checked" ngModel/></td>
					<td class="red-text">{{charger.id}}</td>
					<td>{{charger.serialnumber}}</td>
					<td>{{charger.purchase_order}}</td>
					<td *ngIf="pageName == 'unassignedSite'">{{charger.customername}}</td>
					<td>{{charger.model}}</td>
					<td>{{charger.installationdate | date:'MM/dd/yyyy'}}</td>
					<td>{{charger.chargerusername}}</td>
					<td>
						<div class="btn-group" appDropdown>
							<i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
							<ul class="dropdown-menu" role="menu">
								<li><a (click)="editChargerBtn(charger.id, charger.customerid, charger.siteid)">{{'g.edit' | translate}}</a></li>
								<li *ngIf="pageName == 'listing' && userService.hasAccessFunction('act_admin_device_management')"><a (click)="deleteCharger(charger.id)">{{'g.delete' | translate}}</a></li>
								<li *ngIf="pageName == 'listing' && userService.hasAccessFunction('enable_full_access_mode')"><a (click)="autoCorrectCharger(charger.id)">{{'device.auto_correct' | translate}}</a></li>
							</ul>
						</div>
					</td>
				</tr>	
			</tbody>
		</table>
    </div>
</div>