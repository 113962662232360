import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';

@Component({
	selector: 'app-hourly-idle-times',
	templateUrl: './hourly-idle-times.component.html',
	styleUrls: ['./hourly-idle-times.component.css']
})
export class HourlyIdleTimesComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: ColumnChartConfig;
	tableColumns: any[] = [];

	constructor() { }

	ngOnInit() {
		this.config = new ColumnChartConfig({
			yTitle: "Minutes",
			widgetHeight: 400,
			hAxis: { slantedText: true, slantedTextAngle: 15},
			chartArea: { top: 10, width:'70%',height:'80%'}
		});

		this.config.formatFunction = (data) => {
			var getIdleTimesColors = (value) => {
				if(value < 5)
					return 'green';
				if(value < 15)
					return 'yellow';
				if(value < 25)
					return 'orange';
				return 'red';
			};
			let dataList = data.list;
			let formattedData: any[] = [
				[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'},
				]
			];

			for(let i = 0; i < dataList.length; i++) {
				let item: any = dataList[i];
				formattedData.push(
					[
						{v: item.Text},
						{v: item.value},
						{v: item.value},
						{v: 'color: ' + getIdleTimesColors(item.originalValue / (data.countOfBatteries * data.countOfDays))}
					]
				);
			}

			return formattedData;
		};
	}

	ngOnChanges(changes) {
		if(this.data) {
			this.tableColumns = [];
			this.data['battviewsData'] = this.siteBattviewsData;
			this.data['siteTags'] = this.siteTags;
			this.data['siteBattviewsTags'] = this.siteBattviewsTags;
		}
	}
}