import { Component, OnInit, OnDestroy } from '@angular/core';
import { DeviceManagementService } from '../../device-management.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dealers-oems-hierarchy',
  templateUrl: './dealers-oems-hierarchy.component.html',
  styleUrls: ['./dealers-oems-hierarchy.component.css']
})
export class DealersOemsHierarchyComponent implements OnInit, OnDestroy {

  customers: any;
  dealersOEMs: any;
  client: any;
  clientID: any;
  clientRoleName: string;

  constructor(
    private deviceManagemntService: DeviceManagementService,
    private breadcrumb: BreadcrumbService,
	private commonUtil: CommonUtil,
	private translateService: TranslateService
  ) {
    this.breadcrumb.setBreadcrumb([
      {label: this.translateService.instant('site.dealers_oems_hierarchy'), url: ''}
    ]);
  }

  ngOnInit() {
    this.customers	= {};
    this.dealersOEMs	= {};

    this.deviceManagemntService.getDealersOEMsDropDown().subscribe(
      dealersOEMs => {
        this.dealersOEMs = dealersOEMs;
      }
    );
  }

  getCustomersForDealersOEMs(){
    this.clientID			= this.client.id;
    this.clientRoleName	= this.client.roleName;
    this.deviceManagemntService.getCustomersForDealersOEMs(this.client.id, this.client.roleName).subscribe(
      customers => {
        this.customers = this.commonUtil.arrayToAssociativeArray(customers, 'id');
        this.customers[0] = {
          'id': 0,
          'name': this.translateService.instant('site.unassigned_customer')
        };
      }
    );
  }
  getClientSitesByRoleName(customerid){
    if(this.customers[customerid]['sites']) {
      this.toggleExpanded(this.customers[customerid]);
      return;
    }

    this.customers[customerid]['sites'] = {};
    var clientObj = {
      'clientID':		this.clientID,
      'customerId':	customerid
    };
    this.deviceManagemntService.getClientSitesByRoleName(clientObj, this.clientRoleName).subscribe(
      (sites: {list: any}) => {
        for(let site of sites.list) {
          this.customers[customerid]['sites'][site.id] = site;
        }
        this.customers[customerid]['sites'][0] = {
          'id': 0,
          'name': this.translateService.instant('site.unassigned_site')
        };
        this.toggleExpanded(this.customers[customerid]);
      }
    );
  }
  toggleExpanded(item){
    if(item.expanded)
      item.expanded = false;
    else
      item.expanded = true;
  }
  getSiteDevices(customerid, siteid, deviceType){
    if(this.customers[customerid]['sites'][siteid][deviceType]) {
      this.toggleExpanded(this.customers[customerid]['sites'][siteid][deviceType]);
      return;
    }

    this.customers[customerid]['sites'][siteid][deviceType] = [];

    var clientObj = {
      'clientID':		this.clientID,
      'customerid':	customerid,
      'siteid':		  siteid
    };

    if(deviceType == 'charger') {
      this.deviceManagemntService.getChargersDropDownByRoleName(clientObj, this.clientRoleName).subscribe(
        (chargers: {list: any}) => {
          this.customers[customerid]['sites'][siteid][deviceType] = chargers.list;
          this.toggleExpanded(this.customers[customerid]['sites'][siteid][deviceType]);
        }
      );
    } else if(deviceType == 'battview') {
      this.deviceManagemntService.getBATTViewsDropDownByRoleName(clientObj, this.clientRoleName).subscribe(
        (battviews: {list: any}) => {
          this.customers[customerid]['sites'][siteid][deviceType] = battviews.list;
          this.toggleExpanded(this.customers[customerid]['sites'][siteid][deviceType]);
        }
      );
    }
  }

  ngOnDestroy() {
		this.breadcrumb.hide();
	}
}
