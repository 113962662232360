<div class="row">
  <div id="page-wrapper" >
    <div id="addNewPanel">
      <button class="btn btn-primary mb20" type="button" [routerLink]="['/equipment-device',linkType, linkId, 'create', 0, 0]" *ngIf="userService.hasAccessFunction('manage_equipment')">
        Add New <span class="glyphicon glyphicon-plus"></span>
      </button>
    </div>

    <div id="page-inner">
      <div class="panel panel-default">
        <div class="panel-body">
          <ul class="insm sidebar-nav">
            <li *ngFor="let equipment of equipmentsDevices">
              <div class="col-md-12">
                <a href="javascript:" (click)="equipment.expanded = !equipment.expanded" class="col-md-9">
                  <span class="glyphicon" [ngClass]="{'glyphicon-plus-sign': !equipment.expanded, 'glyphicon-minus-sign': equipment.expanded}"></span>
                    {{equipmentsArr[equipment.equipment_id]?.name}} ({{equipment.serialnumber}})
                    <small>{{equipment.description}}</small>
                    <small *ngIf="equipment.shipping_date" class="small"> -Shipping: {{getFormattedDate(equipment.shipping_date)}}</small> 
                    <small *ngIf="equipment.purchase_date"> -Purchase: {{getFormattedDate(equipment.purchase_date)}}</small>
                </a>
                <div class="btn-group" appDropdown *ngIf="userService.hasAccessFunction('manage_equipment')">
                  <i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle></i>
                  <ul class="dropdown-menu" role="menu">
                    <li>
                      <a [routerLink]="['/equipment-device',linkType, linkId, 'edit', equipment.id, 0]">Edit</a>
                    </li>
                    <li>
                      <a [routerLink]="['/equipment-device',linkType, linkId, 'create', equipment.id, 0]">Add New Equipment</a>
                    </li>
                    <li><a *ngIf="equipment.deleteable" (click)="deleteEquipment(equipment.id)">Delete</a></li>
                  </ul>
                </div>
              </div>
              <ul *ngIf="equipment.expanded" class="sidebar-nav">
                <li *ngFor="let childId of equipment.children | keys">
                  {{equipmentsArr[equipment.children[childId].equipment_id]?.name}} ({{equipment.children[childId].serialnumber}})
                  <small>{{equipment.children[childId].description}}</small>
                  <small *ngIf="equipment.children[childId].shipping_date" class="small"> -Shipping: {{getFormattedDate(equipment.children[childId].shipping_date)}}</small> 
                  <small *ngIf="equipment.children[childId].purchase_date"> -Purchase: {{getFormattedDate(equipment.children[childId].purchase_date)}}</small>
                  <div class="btn-group" appDropdown *ngIf="userService.hasAccessFunction('manage_equipment')">
                    <i type="button" class="glyphicon glyphicon-cog dropdown-toggle" dropdown-toggle>
                    </i>
                    <ul class="dropdown-menu" role="menu">
                      <li>
                        <a [routerLink]="['/equipment-device',linkType, linkId, 'edit', equipment.id, childId]">Edit</a>
                      </li>
                      <li><a (click)="deleteEquipment(equipment.id, equipment.children[childId].childId)">Delete</a></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>