<div class="panel panel-default">
  <div class="panel-body boxs">
    <div class="l" style="padding:12px">
      <strong>{{'site.select_dealer_oem' | translate}}</strong>
    </div>
    <div class="padding-8 l col-md-3">
      <ng-select [items]="dealersOEMs | values" bindLabel="name" placeholder="{{'site.select_dealer_oem' | translate}}" name="oem_id" [(ngModel)]="client" (change)="getCustomersForDealersOEMs()" [clearable]="false">
      </ng-select>
    </div>
    <ul class="insm sidebar-nav">
      <li *ngFor="let customerid of customers | keys">
        <a href="javascript:" (click)="getClientSitesByRoleName(customerid)">
          <span class="glyphicon" [ngClass]="{'glyphicon-plus-sign': !customers[customerid].expanded, 'glyphicon-minus-sign': customers[customerid].expanded}"></span>
          {{customers[customerid].name}}
        </a>
        <ul *ngIf="customers[customerid].expanded" class="sidebar-nav">
          <li *ngFor="let siteid of customers[customerid].sites | keys">
            <a href="javascript:" (click)="toggleExpanded(customers[customerid].sites[siteid])">
              <span class="glyphicon" [ngClass]="{'glyphicon-plus-sign': !customers[customerid].sites[siteid].expanded, 'glyphicon-minus-sign': customers[customerid].sites[siteid].expanded}"></span>
              {{customers[customerid].sites[siteid].name}}
            </a>
            <ul class="col-md-12 sidebar-nav" *ngIf="customers[customerid].sites[siteid] && customers[customerid].sites[siteid].expanded">
              <li>
                <a href="javascript:" (click)="getSiteDevices(customerid, siteid, 'charger')">
                  <span class="glyphicon" [ngClass]="{'glyphicon-plus-sign': !customers[customerid].sites[siteid].charger || !customers[customerid].sites[siteid].charger.expanded, 'glyphicon-minus-sign': customers[customerid].sites[siteid].charger && customers[customerid].sites[siteid].charger.expanded}"></span>
                  {{'g.chargers' | translate}}
                </a>
              </li>
              <div *ngIf="customers[customerid].sites[siteid].charger && customers[customerid].sites[siteid].charger.expanded" class="col-md-12">
                <div class="col-md-6">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>{{'device.serial_number' | translate}} ({{'device.charger_id' | translate}})</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let charger of customers[customerid].sites[siteid].charger" [ngClass]="charger.bgClass">
                        <td>{{charger.serialnumber}} ({{charger.chargerusername}})</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <li>
                <a href="javascript:" (click)="getSiteDevices(customerid, siteid, 'battview')">
                  <span class="glyphicon" [ngClass]="{'glyphicon-plus-sign': !customers[customerid].sites[siteid].battview || !customers[customerid].sites[siteid].battview.expanded, 'glyphicon-minus-sign': customers[customerid].sites[siteid].battview && customers[customerid].sites[siteid].battview.expanded}"></span>
                  {{'g.battviews' | translate}}
                </a>
              </li>
              <div *ngIf="customers[customerid].sites[siteid].battview && customers[customerid].sites[siteid].battview.expanded" class="col-md-12">
                <div class="col-md-6">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>{{'device.serial_number' | translate}} ({{'device.battery_id' | translate}})</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let battview of customers[customerid].sites[siteid].battview" [ngClass]="battview.bgClass">
                        <td>{{battview.serialnumber}} ({{battview.batteryid}})</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>