import { Component, OnInit, Input } from '@angular/core';
import { ComboChartConfig } from '../../../../../shared/google-chart/Models/ComboChartConfig';

@Component({
	selector: 'app-site-temperature-report',
	templateUrl: './site-temperature-report.component.html',
	styleUrls: ['./site-temperature-report.component.css']
})
export class SiteTemperatureReportComponent implements OnInit {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: ComboChartConfig;
	tableColumns: any[] = [
		{title: "Battery ID", property: "battery_id"},
		{title: "Max Temperature", property: "value"}
	];
	title: string = 'Site Temperature';
	elementId: string = 'site_temperature';
	widgetClass: string = 'auto-height-widget';
	siteWidgetOptions: any = {
		hasOperation: true,
		hasDate: true,
		hideYesterdayDate: true,
		isReport: true,
		updateChartMaxValue: true
	};

	constructor() { }

	ngOnInit() {
		let series: any = {
			2: {type: 'line', color: 'green'},
			3: {type: 'line', color: 'yellow'},
			4: {type: 'line', color: 'red'}
		};
		let vAxis: any = {
			title: 'Temperature Value',
			titleTextStyle: {color: 'black', fontSize: 18},
			maxValue: 100,
			minValue: 0,
			gridlines: {count: 10}
		};
		this.config = new ComboChartConfig(vAxis, series, {height: '60%'});
	}
}
