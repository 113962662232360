<form #contactUs="ngForm" class="form-auth" (ngSubmit)="sendContactUsEmail()">
  <div class="container-fluid" id="main">
    <div class="row">
      <div class="col-md-6 col-md-offset-3" id="left">
        <div class="boxs3">
          <h2>{{'g.contact_us' | translate}}</h2>
          <div class="form-field">
            <label for="subject">{{'g.subject' | translate}}</label>
            <input required type="text" class="form-control" [(ngModel)]="subject" id="subject" name="subject" placeholder="{{'g.subject' | translate}}" />
          </div>
          <br/>
          <div class="form-field">
            <label for="enquiry">{{'g.your_enquiry' | translate}}</label>
            <textarea required rows="10" class="form-control" [(ngModel)]="enquiry" id="enquiry" name="enquiry" placeholder="{{'g.your_enquiry' | translate}}"></textarea>
          </div>
          <br/>
          <button [disabled]="!subject || !enquiry" type="submit" class="btn btn-primary">{{'g.send' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</form>