<div class="row">
	<div class="panel panel-default">
		<div class="panel-body">
			<div class="col-md-12 margin-top-lg">
				<div class="row col-md-2 lg-font">
					<strong>{{'g.select_type' | translate}}</strong>
				</div>
				<div class="row col-md-3">
					<label class="col-md-6"><input type="radio" [(ngModel)]="type" class="form-control" value="normal" (ngModelChange)="getProductionLineBattviews()"/> {{'g.battview' | translate}}</label>
					<label><input type="radio" [(ngModel)]="type" class="form-control" value="mobile" (ngModelChange)="getProductionLineBattviews()"/> {{'g.battview_mobile' | translate}}</label>
				</div>
			</div>
			<div class="col-md-12 margin-top-lg">
				<div class="row col-md-2 lg-font">
					<strong>{{'g.select_connectivity' | translate}}</strong>
				</div>
				<div class="row col-md-6">
					<label class="col-md-3"><input name="conn" type="radio" [(ngModel)]="connectivity" class="form-control" value="now" (ngModelChange)="getProductionLineBattviews()"/> {{'g.connected_now' | translate}}</label>
					<label>
						<input name="conn" type="radio" [(ngModel)]="connectivity" class="form-control" value="in"/>
						{{'g.connected_in_the_past_days' | translate}}
						<input *ngIf="connectivity == 'in'" type="number" [(ngModel)]="connectivityDays" class="form-control" (ngModelChange)="getProductionLineBattviews()" min="1" max="365"/>
					</label>
				</div>
			</div>
			<div class="col-md-12 margin-top-lg">
				<fieldset *ngFor="let cat of battviewsCat | keys">
					<legend *ngIf="cat == ('device.pending_setup_as_replacement' | translate)" [ngStyle]="{'color': 'red', 'font-weight': 'bold'}">{{cat}}</legend>
					<legend *ngIf="cat != ('device.pending_setup_as_replacement' | translate)">{{cat}}</legend>
					<table class="table">
						<thead>
							<tr>
								<th>{{'device.serial_number' | translate}}</th>
								<th>{{'g.connectivity' | translate}}</th>
								<th>{{'device.fw_version' | translate}}</th>
								<th>{{'device.fw_status' | translate}}</th>
								<th>{{'device.wifi_fw_version' | translate}}</th>
								<th>{{'device.wifi_fw_status' | translate}}</th>
								<th>{{'g.notes' | translate}}</th>
								<th>{{'g.actions' | translate}}</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let battview of battviewsCat[cat]" [ngClass]="{'invalid-input': messages[battview.id]}" [pTooltip]="messages[battview.id]?.join('\n')" tooltipStyleClass="device-changes-log">
								<td><a [routerLink]="['/', 42, 554, 'battviews', 'settings']" [queryParams]="{'battviewsIDs': battview.id}">{{battview.serialnumber}}</a></td>
								<td>{{battview.connectivity}}</td>
								<td>{{battview.firmwareversion}}</td>
								<td *ngIf="!battview.fwUpdateProgress">{{'g.up_to_date' | translate}}</td>
								<td *ngIf="battview.fwUpdateProgress">{{'site.site_fw_version' | translate}}: {{siteInfo.battviewsfirmwareversion}}<br/>{{'g.progress' | translate}}: <span>{{battview.fwUpdateProgress}}%</span></td>
								<td>{{battview.esp32_wifi_version}}</td>
								<td *ngIf="!battview.wifiFwUpdateProgress || ( battview.esp32_wifi_version==0 && battview.connectivity == 'Connected') ">
								<span *ngIf="battview.esp32_wifi_version!=0"> {{'g.up_to_date' | translate}}</span>
								<span *ngIf="battview.esp32_wifi_version==0"> {{'g.no_update_available' | translate}}</span>

								</td>
								<td *ngIf="!(!battview.wifiFwUpdateProgress || ( battview.esp32_wifi_version==0 && battview.connectivity == 'Connected'))">{{'site.site_fw_version' | translate}}: {{siteInfo.wififirmwareversion}}<br/>{{'g.progress' | translate}}: <span>{{battview.wifiFwUpdateProgress}}%</span></td>
								<td><span *ngIf="battview.hasQueue"><span *ngIf="!battview.hasReplacement">{{'battery.this_battview_has_queued_commands_not_processed_yet' | translate}}</span><span *ngIf="battview.hasReplacement">{{'battery.this_battview_has_setup_as_replacement_command_and_not_processed_yet' | translate}}</span></span></td>
								<td>
									<button class="btn btn-info" (click)="processBattviews(battview.id)" *ngIf="!messages[battview.id] && !battview.hasQueue && siteInfo.battviewsfirmwareversion <= battview.firmwareversion && (battview.esp32_wifi_version==0 || siteInfo.wififirmwareversion <= battview.esp32_wifi_version) && !battview.replacmentpart && !battview.ispa">
										{{'device.setup_as_replacement' | translate}}
									</button>
									<button *ngIf="!messages[battview.id] && battview.connectivity == 'Connected' && !battview.hasQueue && !battview.ispa && siteInfo.battviewsfirmwareversion <= battview.firmwareversion && (battview.esp32_wifi_version==0 || siteInfo.wififirmwareversion <= battview.esp32_wifi_version)" class="btn btn-primary" [routerLink]="['/replacement-device', 'battview', battview.id, 'onTheFly']">
										{{'device.replace_on_the_fly' | translate}}
									</button>
								</td>
							</tr>
						</tbody>
					</table>
					<br/>
				</fieldset>
			</div>
		</div>
	</div>
</div>