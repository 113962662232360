import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'intRange'
})
export class IntRangePipe implements PipeTransform {

	//intRange: range of integers 0,1,2,...
	transform(element, min, max): number[] {
        let input = [];
		min = parseInt(min); //Make string input int
		max = parseInt(max);
		for (var i=min; i<=max; i++)
			input.push(i);
		return input;
	}
}