<div class="p404">
	<div class="text-center center-block margin-top-4-per">
		<a class="act-logo" href="http://act-view.com/"></a>
		<div class="row">
			<div class="col-md-12">
				<h1><strong>404</strong></h1>
				<h1>{{'g.not_found' | translate}}</h1>
			</div>
		</div>
	</div>
</div>