<div class="panel panel-default">
	<div class="panel-body">
		<div class="row">
			<div *ngFor="let server of schedFunctions | keys" class="col-md-{{12/numberOfServers}}">
				<h2>{{server}}</h2>
				<table>
					<tbody>
						<tr>
							<td>Function Name</td>
							<td>Status</td>
						</tr>
						<tr *ngFor="let function of schedFunctions[server]">
							<td>{{function.prettyName}}</td>
							<td>
								<div class="onoffswitch">
									<input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch{{function.server}}{{function.name}}" checked="" [(ngModel)]="function.is_active">
									<label class="onoffswitch-label" for="myonoffswitch{{function.server}}{{function.name}}">
										<span class="onoffswitch-inner"></span>
										<span class="onoffswitch-switch"></span>
									</label>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<button type="button" class="btn btn-lg btn-primary" (click)="updateSchedFunctionsStatus()">Submit</button>
			</div>
		</div>
	</div>
</div>