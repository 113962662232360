import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormsModule } from '@angular/forms';
import { AuthRoutingModule } from './auth-routing.module';
import { CaptchaModule } from 'primeng/captcha';
import { BecomeUserComponent } from './become-user/become-user.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { TranslateFileLoader } from '../TranslateFileLoader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

// AoT requires an exported function for factories
export function TranslateFileLoaderFactory() {
	return new TranslateFileLoader();
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CaptchaModule,
	AuthRoutingModule,
	TranslateModule.forRoot({
		loader: {
			provide: TranslateLoader,
			useFactory: TranslateFileLoaderFactory,
		}
	}),
  ],
  declarations: [
  	LoginComponent,
  	RegisterComponent,
  	ForgotPasswordComponent,
  	BecomeUserComponent,
  	UnauthorizedComponent
  ]
})
export class AuthModule { }
