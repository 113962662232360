import { Component, OnInit, Input } from '@angular/core';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';

@Component({
	selector: 'app-charger-site-alerts',
	templateUrl: './charger-site-alerts.component.html',
	styleUrls: ['./charger-site-alerts.component.css']
})
export class ChargerSiteAlertsComponent implements OnInit {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';
	@Input() isEndClient: boolean = false;

	config: ColumnChartConfig;
	tableColumns: any[] = [
		{title: "Charger ID", property: "chargerusername"},
		{title: "Charger SN", property: "serialnumber"},
		{title: "Cycle ID", property: "value"}
	];
	title: string = 'Site Alerts';
	elementId: string = 'site_alerts_wdgt';
	widgetClass: string = 'auto-height-widget';
	siteWidgetOptions: any = {
		hasOperation: true,
		hasChargerModels: true,
		isSitePerformanceWidget: true,
		isChargers: true
	};

	constructor(private chartUtil: ChartUtil) { }

	ngOnInit() {
		this.config = new ColumnChartConfig({
			isPercent: false,
			yTitle: "Count",
			widgetHeight: 400,
			chartArea: {top: 10, width:'70%', height:'80%'},
			vAxisMinValue: 0,
			tooltip: {trigger: 'hover'},
		});

		this.config.formatFunction = (data) => {
			data = data.list;
			let formattedData: any[] = [
				[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'},
				]
			];

			let colors = ['#bd2f26', '#3f69ec'];
			for(let i = 0; i < data.length; i++) {
				let item: any = data[i];
				formattedData.push(
					[
						{v: item.x},
						{v: item.value},
						{v: ''},
						{v: 'color: ' + (this.chartUtil.getColor(item.value, true) || colors[i % 2])}
					]
				);
			}

			return formattedData;
		};

		this.config.updateColumns = (index) => {
			let columnsArray = [
				{title: "Charger ID", property: "chargerusername"},
				{title: "Charger SN", property: "serialnumber"},
			];
			if(index == 4) {
				columnsArray.push({title: "PM ID", property: "value"});
			} else {
				columnsArray.push({title: "Cycle ID", property: "value"});
			}
			return columnsArray;
		}
	}
}