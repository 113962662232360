export class ComboChartConfig {
	vAxis: any;
	series: any;
	formatFunction: any;
	updateColumns: any;
	height:string = '50%';
	LegendPosition;
	hAxis;
	chartArea;
	pointSize;
	vAxes;
	explorer;
	useDateFormat;
	tooltip;
	seriesType;

	constructor(vAxis: any = null, series = null, options: any = {}) {
		this.vAxis = vAxis;
		this.series = series;
		if(options.height) this.height = options.height;
		if(options.LegendPosition) this.LegendPosition = options.LegendPosition;
		if(options.hAxis) this.hAxis = options.hAxis;
		if(options.chartArea) this.chartArea = options.chartArea;
		if(options.pointSize) this.pointSize = options.pointSize;
		if(options.vAxes) this.vAxes = options.vAxes;
		if(options.explorer) this.explorer = options.explorer;
		if(options.useDateFormat) this.useDateFormat = options.useDateFormat;
		if(options.tooltip) this.tooltip = options.tooltip;
		if(options.seriesType) this.seriesType = options.seriesType;
	}
}