/// <reference types="@types/googlemaps" />

import { Component, OnInit, Input, Output, OnChanges, EventEmitter, ElementRef, NgZone, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MouseEvent, MapsAPILoader } from '@agm/core';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-google-map',
	templateUrl: './google-map.component.html',
	styleUrls: ['./google-map.component.css']
})
export class GoogleMapComponent implements OnInit, OnChanges {

	@Input() streetViewControl: boolean = false;
	@Input() zoomControl: boolean = true;
	@Input() panControl: boolean = false;
	@Input() latitude: number = 40.513799;
	@Input() longitude: number = -101.953125;
	@Input() zoom: number = 4;

	map: Object = {};
	@ViewChild("search") searchElementRef: ElementRef;
	searchControl: UntypedFormControl;
	markers: marker[] = [];
	mapCenter = {
		lat: this.latitude,
		lng: this.longitude
	};

	@Output() onAddressChanged = new EventEmitter<Object>();

	constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) { }

	ngOnInit() {
		this.searchControl = new UntypedFormControl();
		this.mapsAPILoader.load().then(() => {
			let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {types: []});
			autocomplete.addListener("place_changed", () => {
				this.ngZone.run(() => {

					let place: google.maps.places.PlaceResult = autocomplete.getPlace();

					if(place.geometry === undefined || place.geometry === null)
						return;

					this.setCurrentPosition({
						lat: place.geometry.location.lat(),
						lng: place.geometry.location.lng()
					}, true, true, true);
				});
			});
		});
	}

	ngOnChanges(changes) {
		if(changes.latitude && changes.longitude && changes.latitude.currentValue !== undefined && changes.longitude.currentValue !== undefined) {
			this.setCurrentPosition({
				lat: changes.latitude.currentValue,
				lng: changes.longitude.currentValue
			}, false, true, true);
		}
	}

	setCurrentPosition(coords, getAddress = true, zoomIn = false, centerMap = false) {
		let marker: marker = {
			lat: coords.lat,
			lng: coords.lng
		}
		
		this.markers = [marker];
		this.map['lat'] = marker.lat;
		this.map['lng'] = marker.lng;

		if(centerMap)
			this.mapCenter = marker;

		if(!getAddress) {
			if(zoomIn)
				this.zoom = 15;
			return;
		}

		this.getAddressName(marker).subscribe(
			data => {
				this.map['address'] = data['address'];
				this.map['country'] = data['country'];
				this.map['state'] = data['state'];
				if(zoomIn)
					this.zoom = 15;
				this.onAddressChanged.emit(this.map);
			},
			err => {}
		);
	}

	private parseAddressComponentObject(addressComponentArr) {
		let addressObj = {};

		addressComponentArr.forEach(function (address_component) {

			switch(address_component.types[0]) {
				case 'country':
					addressObj['country'] = address_component['long_name'];
				break;
				case 'administrative_area_level_1':
					addressObj['state'] = address_component['long_name'];
				break;
			}
		});

		return addressObj;
	}

	private getAddressName(coords: marker) {
		let latlng = new google.maps.LatLng(coords.lat, coords.lng);
		let geocoder = new google.maps.Geocoder();
		return Observable.create(observer => {
			geocoder.geocode({'location': latlng}, (results, status) => {

				if (status === google.maps.GeocoderStatus.OK && results[1]) {

					let addressObj = this.parseAddressComponentObject(results[1].address_components);
					let siteInfo = {};
					if(addressObj['country'])
						siteInfo['country'] = addressObj['country'];

					if(addressObj['state'])
						siteInfo['state'] = addressObj['state'];

					siteInfo['address'] = results[0].formatted_address;
					observer.next(siteInfo);
					observer.complete();
				} else {

					observer.error();
					observer.complete();
				}
			});
		});
	}
}

interface marker {
	lat: number;
	lng: number;
}