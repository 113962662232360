import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorLogComponent } from './logs/error-log/error-log.component';
import { ViewErrorComponent } from './logs/error-log/view-error/view-error.component';
import { SchedControlComponent } from './sched/sched-control/sched-control.component';
import { SchedMonitorComponent } from './sched/sched-monitor/sched-monitor.component';
import { WidgetsManagementComponent } from './widgets-management/widgets-management.component';
import { OemComponent } from './users/oem/oem.component';
import { OemEditComponent } from './users/oem/oem-edit/oem-edit.component';
import { DealerComponent } from './users/dealer/dealer.component';
import { DealerEditComponent } from './users/dealer/dealer-edit/dealer-edit.component';
import { CustomerComponent } from './users/customer/customer.component';
import { CustomerEditPageComponent } from './users/customer/customer-edit-page/customer-edit-page.component';
import { SitesComponent } from './sites/sites.component';
import { SiteEditPageComponent } from './sites/site-edit-page/site-edit-page.component';
import { ManageActIntelligentComponent } from './sites/manage-act-intelligent/manage-act-intelligent.component';
import { DeviceEditPageComponent } from './devices/device-edit-page/device-edit-page.component';
import { AuthGuard } from '../auth/auth-guard.service';
import { ActlinkFailedDbComponent } from './logs/actlink-failed-db/actlink-failed-db.component';
import { FaultedQueueComponent } from './logs/faulted-queue/faulted-queue.component';
import { ChargersComponent } from './devices/chargers/chargers.component';
import { BattviewsComponent } from './devices/battviews/battviews.component';
import { ShortUrlComponent } from './devices/short-url/short-url.component';
import { ManageDevicePageComponent } from './devices/manage-device-page/manage-device-page.component';
import { ManageDeviceDatesPageComponent } from './devices/manage-device-dates-page/manage-device-dates-page.component';
import { ManageDevicePurchaseOrderComponent } from './devices/manage-device-purchase-order/manage-device-purchase-order.component';
import { ActionLogComponent } from './logs/action-log/action-log.component';
import { RegistrationRequestsComponent } from './users/registration-requests/registration-requests.component';
import { ValidateRequestComponent } from './users/registration-requests/validate-request/validate-request.component';
import { CustomizedEmailsComponent } from './customized-emails/customized-emails.component';
import { UserSearchComponent } from './users/user/user-search/user-search.component';
import { UserEditComponent } from './users/user/user-edit/user-edit.component';
import { AccessPermissionsComponent } from './users/user/access-permissions/access-permissions.component';
import { EditAccessFunctionsComponent } from './users/user/edit-access-functions/edit-access-functions.component';
import { EquipmentListingComponent } from './devices/equipment/equipment-listing/equipment-listing.component';
import { ManageEquipmentComponent } from './devices/equipment/manage-equipment/manage-equipment.component';
import { RejectedDeviceConnectionsComponent } from './logs/rejected-device-connections/rejected-device-connections.component';
import { ReplacementDetectionComponent } from './logs/replacement-detection/replacement-detection.component';
import { ViewFaultedQueueComponent } from './logs/faulted-queue/view-faulted-queue/view-faulted-queue.component';
import { SchedHistoryComponent } from './sched/sched-history/sched-history.component';
import { InvoiceMismatchForSiteBattviewsComponent } from './sites/invoice-mismatch-for-site-battviews/invoice-mismatch-for-site-battviews.component';
import { AdminActionsComponent } from './sched/admin-actions/admin-actions.component';
import { ApisLogsComponent } from './apis-logs/apis-logs.component';
import { RegistrationPurposesComponent } from './users/registration-purposes/registration-purposes.component';
import { SmartRebatesSitesComponent } from './users/smart_rebates_sites/smart_rebates_sites.component';
import { SchedRunComponent } from './sched/sched-run/sched-run.component';
import { FakeDataGeneratorComponent } from './fake-data-generator/fake-data-generator.component';


const adminRoutes: Routes = [
	{ path: 'errors-log', component: ErrorLogComponent, data: { pageName: 'errors_log' }, canActivate: [AuthGuard] },
	{ path: 'actlink-failed-db', component: ActlinkFailedDbComponent, canActivate: [AuthGuard] },
	{ path: 'faulted-queue-log', component: FaultedQueueComponent, canActivate: [AuthGuard] },
	{ path: 'faulted-queue-log/:deviceType/:id', component: ViewFaultedQueueComponent, canActivate: [AuthGuard] },
	{ path: 'errors-log/:id', component: ViewErrorComponent, data: { pageName: 'errors_log' }, canActivate: [AuthGuard] },
	{ path: 'rejected-quantum-uploads', component: ErrorLogComponent, data: { pageName: 'rejected_quantum_upload' }, canActivate: [AuthGuard] },
	{ path: 'rejected-quantum-uploads/:id', component: ViewErrorComponent, data: { pageName: 'quantum' }, canActivate: [AuthGuard] },
	{ path: 'invoice-mismatch-for-site-battviews', component: InvoiceMismatchForSiteBattviewsComponent, canActivate: [AuthGuard] },
	{ path: 'sched-control', component: SchedControlComponent, canActivate: [AuthGuard] },
	{ path: 'sched-monitor', component: SchedMonitorComponent, canActivate: [AuthGuard] },
	{ path: 'sched-history', component: SchedHistoryComponent, canActivate: [AuthGuard] },
	{ path: 'sched-run', component: SchedRunComponent, canActivate: [AuthGuard] },
	{ path: 'admin-actions', component: AdminActionsComponent, canActivate: [AuthGuard] },
	{ path: 'widget-management', component: WidgetsManagementComponent, canActivate: [AuthGuard] },
	{ path: 'oems', component: OemComponent, canActivate: [AuthGuard] },
	{ path: 'oems/edit/oemId/:oemId', component: OemEditComponent, canActivate: [AuthGuard] },
	{ path: 'dealers', component: DealerComponent, canActivate: [AuthGuard], data: { pageType: 'dealer' } },
	{ path: 'dealers/edit/dealerId/:dealerId', component: DealerEditComponent, canActivate: [AuthGuard], data: { pageType: 'dealer' } },
	{ path: 'truck-dealers', component: DealerComponent, canActivate: [AuthGuard], data: { pageType: 'truck_dealer' } },
	{ path: 'truck-dealers/edit/dealerId/:dealerId', component: DealerEditComponent, canActivate: [AuthGuard], data: { pageType: 'truck_dealer' } },
	{ path: 'customers', component: CustomerComponent, canActivate: [AuthGuard] },
	{ path: 'customers/edit/customerId/:customerId', component: CustomerEditPageComponent, canActivate: [AuthGuard] },
	{ path: 'sites/customerId/:customerId', component: SitesComponent, canActivate: [AuthGuard] },
	{ path: 'sites/edit/customerId/:customerId/siteId/:siteId', component: SiteEditPageComponent, canActivate: [AuthGuard] },
	{ path: 'sites/manage-act-intelligent/:customerId/:siteId', component: ManageActIntelligentComponent, canActivate: [AuthGuard] },
	{ path: 'chargers/edit/customerId/:customerId/siteId/:siteId', component: DeviceEditPageComponent, data: {type: 'charger'}, canActivate: [AuthGuard] },
	{ path: 'battviews/edit/customerId/:customerId/siteId/:siteId', component: DeviceEditPageComponent, data: {type: 'battview'}, canActivate: [AuthGuard] },
	{ path: 'battviews-mobile/edit', component: DeviceEditPageComponent, data: {type: 'battviewMobile'}, canActivate: [AuthGuard] },
	{ path: 'devices/devices-management/:type/:deviceIDs', component: ManageDevicePageComponent, canActivate: [AuthGuard] },
	{ path: 'devices/devices-dates-management/:type/:deviceIDs', component: ManageDeviceDatesPageComponent, canActivate: [AuthGuard] },
	{ path: 'devices/devices-purchase-order-management/:type/:deviceIDs', component: ManageDevicePurchaseOrderComponent, canActivate: [AuthGuard] },
	{ path: 'users', component: UserSearchComponent, canActivate: [AuthGuard] },
	{ path: 'users/edit/userId/:userId', component: UserEditComponent, canActivate: [AuthGuard] },
	{ path: 'users/access-permissions/:userId', component: AccessPermissionsComponent, canActivate: [AuthGuard] },
	{ path: 'users/edit-function-management/:userId', component: EditAccessFunctionsComponent, canActivate: [AuthGuard] },
	{ path: 'registrations-request', component: RegistrationRequestsComponent, canActivate: [AuthGuard] },
	{ path: 'registrations-purposes', component: RegistrationPurposesComponent, canActivate: [AuthGuard] },
	{ path: 'smart-rebates-sites', component: SmartRebatesSitesComponent, canActivate:[AuthGuard]},
	{ path: 'validate-registration-request/:guestID', component: ValidateRequestComponent, canActivate: [AuthGuard] },
	{ path: 'customized-emails', component: CustomizedEmailsComponent, canActivate: [AuthGuard] },
	{ path: 'chargers/customerId/:customerId/siteId/:siteId', component: ChargersComponent, canActivate: [AuthGuard], data: {pageName: 'listing'} },
	{ path: 'battviews/customerId/:customerId/siteId/:siteId', component: BattviewsComponent, canActivate: [AuthGuard], data: {pageName: 'listing'} },
	{ path: 'action-logs', component: ActionLogComponent, canActivate: [AuthGuard] },
	{ path: 'rejected-device-connections', component: RejectedDeviceConnectionsComponent, canActivate: [AuthGuard] },
	{ path: 'equipments-devices/:linkType/:linkId', component: EquipmentListingComponent, canActivate: [AuthGuard] },
	{ path: 'equipment-device/:linkType/:linkId/:action/:equipmentId/:childId', component: ManageEquipmentComponent, canActivate: [AuthGuard] },
	{ path: 'replacement-detection', component: ReplacementDetectionComponent, canActivate: [AuthGuard] },
	{ path: 'apis-logs', component: ApisLogsComponent, canActivate: [AuthGuard] },
	{ path: 'short/:type/:id', component: ShortUrlComponent, canActivate: [AuthGuard] },
	{ path: 'data-generate', component: FakeDataGeneratorComponent, canActivate: [AuthGuard] },
];

@NgModule({
	imports :[RouterModule.forChild(adminRoutes)],
	exports: [RouterModule],
})
export class AdminRoutingModule {

}
