import { ComboChartConfig } from './../Models/ComboChartConfig';
import { Injectable } from '@angular/core';
import { GoogleChartsBaseService } from './google-charts.base.service';

declare var google: any;

@Injectable()
export class GoogleComboChartService extends GoogleChartsBaseService {

	constructor() { super(); }

	public BuildComboChart(elementId: String, data: any[], config: ComboChartConfig) : void {
		var chartFunc = () => { return new google.visualization.ComboChart(document.getElementById(<string>elementId)); };
		var clearFunc = () => { document.getElementById(<string>elementId).innerHTML = ""; }

		let vAxis:any = {};
		var options: any = {
			vAxis: config.vAxis,
			hAxis: {textPosition: 'none'},
			seriesType: config.seriesType || 'bars',
			legend: { position: 'top'},
			colors: ["#428bca", "#EF980C", "#AAAEB0"],
			'animation.duration': 0,
			chartArea: {
				width: '80%',
				height: config.height,
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
			series: config.series,
			tooltip: config.tooltip,
		};

		if (vAxis && Object.keys(vAxis).length > 0) options.vAxis = vAxis;
		if (config.hAxis)           options.hAxis               = config.hAxis;
		if (config.vAxis)           options.vAxis               = config.vAxis;
		if (config.vAxes)           options.vAxes               = config.vAxes;
		if (config.chartArea)       options['chartArea']        = config.chartArea;
		if (config.height)          options['height']           = config.height;
		if (config.explorer)        options['explorer']         = config.explorer;
		if (config.pointSize)       options['pointSize']        = config.pointSize;
		if (config.series)          options['series']           = config.series;
		if (config.useDateFormat)   options['useDateFormat']    = config.useDateFormat;

		this.buildChart(data, chartFunc, options, clearFunc);
	}
}