
import {map, shareReplay} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SiteInfoService {
	
	private data: Object = {};
	private observable: Observable<any>;

	constructor(private httpClient: HttpClient) {}

	getSiteInformationData(customerId, siteId) {
		// data formatting will be handled in each widget
		// if (this.data[siteId] !== undefined) {
		// 	return Observable.of(this.data[siteId]); 
		// } else {
			return this.httpClient.post('/customers/getSiteInformationData', {
				customerId:customerId,
				siteId:siteId
			}).pipe(shareReplay(1),map(response =>  {
				this.data[siteId] = response;
				return this.data[siteId];
			}),);
		// }
	} 


	updateSiteCommodity(siteId, type, value) {
		return this.httpClient.post('/customers/updateSiteCommodity', {
			siteID: siteId,
			type: type,
			value: value
		});
	}

	getRmaDevices(siteId) {
		return this.httpClient.post('/customers/siteRma', {siteId})
	}

}