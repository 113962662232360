<form #editDevicePurchaseOrder="ngForm" (submit)="saveDevicePurchaseOrder()">
	<div class="col-md-10 col-md-offset-1">
		<div class="boxs3">
			<h2>Edit Charger Purchase Order</h2>
			<div class="row">
				<div *ngFor="let item of devices; let i=index">
					<div class="padding-8 col-sm-12 boxs3 body-bg-color">
						<div class="col-sm-3">Serial Number</div>
						<div class="col-sm-5">{{item.SN}}</div>
						<div class="col-sm-4">ActView ID: {{item.id}}</div>
					</div>
					<div class="padding-8 col-sm-12">
						<div class="col-sm-3">Purchase Order</div>
						<div class="col-sm-5">
							<input class="form-control" [name]="'purchase_order'+i" [(ngModel)]="item.purchase_order">
						</div>
					</div>
				</div>
				<div>
					<button type="submit" class="btn btn-primary" [disabled]="editDevicePurchaseOrder.invalid">Submit</button>
					<button type="button" class="btn btn-default" (click)="goBack()">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</form>