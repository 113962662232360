import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceManagementService } from '../../device-management.service';
import { BreadcrumbService } from '../../../shared/breadcrumb/breadcrumb.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { UserService } from '../../../auth/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-device-by-ip',
	templateUrl: './device-by-ip.component.html',
	styleUrls: ['./device-by-ip.component.css']
})
export class DeviceByIpComponent implements OnInit, OnDestroy {

	IPaddress: string = '';
	devicesInfo: any = [];
	showResults: boolean = false;
	noResult: boolean = false;
	activeTab: string = 'battviews';
	currentUser: any = {};

	constructor(
		private deviceManagemntService: DeviceManagementService,
		private breadcrumb: BreadcrumbService,
		private commonUtil: CommonUtil,
		private router: Router,
		public userService: UserService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.breadcrumb.setBreadcrumb([
			{'label': this.translateService.instant('device.search_device_by_ip'), 'url': ''}
		]);
		this.currentUser = this.userService.getCurrentUser();
	}

	ngOnDestroy() {
		this.breadcrumb.hide();
	}

	getDevicesByIP() {
						
		this.deviceManagemntService.getDevicesByIP(this.IPaddress).subscribe(
			(devicesInfo: any) => {
				this.showResults = true;

				this.devicesInfo = {
					'charger': {'deleted': [], 'non-deleted': []},
					'battview': {'deleted': [], 'non-deleted': []}
				};

				if(devicesInfo.length == 0)
					this.noResult = true;
				else
					this.noResult = false;

				devicesInfo.forEach((deviceInfo) => {
					
					if(deviceInfo.type == 'battview' && this.commonUtil.arrayCompare([+deviceInfo.oemid, +deviceInfo.dealerid, +deviceInfo.servicedealerid, +deviceInfo.customerid, +deviceInfo.siteid], [0, 0, 0, 0, 0]))
						deviceInfo.isProductionLine = true;
					
					deviceInfo.serialnumber = this.commonUtil.formatSerialNumber(deviceInfo);

					var group = 'non-deleted';
					if(deviceInfo.isdeleted)
						group = 'deleted';

					this.devicesInfo[deviceInfo.type][group].push(deviceInfo);
				});
			}
		);
	}

	goToEditDevice(deviceInfo, type) {
		let link = this.deviceManagemntService.getEditDeviceLink(type, deviceInfo);
		this.router.navigate(link.url, {queryParams: link.params});
	};
}