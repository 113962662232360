import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class HomeService {
	constructor(private httpClient: HttpClient) {}

	sendContactUsEmail(subject, inquery) {
		return this.httpClient.post('/users/sendContactUsEmail', {subject, inquery}, {
			observe: "body",
			responseType:"text"
		});
	}
	confirmUserAgreement() {
		return this.httpClient.post('/users/confirmUserAgreement', {}, {
			observe: "body",
			responseType:"text"
		});
	}
	getUserNotAssignedDevices() {
		return this.httpClient.post('/customers/getUserNotAssignedDevices', {}, {
			observe: "body"
		});
	}
	updateUserSettings(user) {
		return this.httpClient.post('/users/updateUserSettings', {user}, {
			observe: "body"
		});
	}
	getUsers(id, isEdit) {
		return this.httpClient.post('/users/get/', {id, isEdit}, {
			observe: "body"
		});
	}
	getGroupAdminUsers() {
		return this.httpClient.post('/users/getGroupAdminUsers', {}, {
			observe: "body"
		});
	}
	sendGroupAdminRequest(user, mode) {
		return this.httpClient.post('/users/sendGroupAdminRequest', {user, mode}, {
			observe: "body"
		});
	}
	confirmUserEmail(uid, enc) {
		return this.httpClient.post('/users/confirmUserEmail', {uid, enc}, {
			observe: "body"
		});
	}
	sendConfirmEmail() {
		return this.httpClient.post('/users/sendConfirmEmail', {}, {
			observe: "body"
		});
	}
	changeUserPassword(user, mode, enc) {
		return this.httpClient.post('/users/changeUserPassword', {user, mode, enc}, {
			observe: "body"
		});
	}
}