<simple-widget [title]="'device.chargers_plc_blocked' | translate">
	<table class="table table-stripped">
		<thead>
			<tr>
				<!-- <th><input type="checkbox" [ngModel]="selectAllDevices" (ngModelChange)="selectAllToggle($event)"/> Select All</th> -->
				<th class="red-text">{{'g.actview_id' | translate}}</th>
				<th>{{'device.serial_number' | translate}}</th>
				<th>{{'device.charger_id' | translate}}</th>
				<th>{{'g.action' | translate}}</th>
			</tr>
		</thead>
		<tbody *ngIf="reportData.charger?.plc">
			<tr *ngFor="let chargerId of reportData.charger.plc; let idx = index">
				<!-- <td><input (ngModelChange)="toggleDeviceSelection($event, charger)" type="checkbox" class="form-control" [checked]="reportData.chargers[chargerId].checked" ngModel/></td> -->
				<td class="red-text">{{chargerId}}</td>
				<td>{{reportData.chargers[chargerId].serialnumber}}</td>
				<td>{{reportData.chargers[chargerId].chargerusername}}</td>
				<td>
					<a (click)="unBlock('charger', 'plc', chargerId, idx)" class="btn btn-primary">{{'g.unblock' | translate}}</a>
				</td>
			</tr>	
		</tbody>
	</table>
</simple-widget>
<simple-widget [title]="'device.chargers_wifi_blocked' | translate">
	<table class="table table-stripped">
		<thead>
			<tr>
				<!-- <th><input type="checkbox" [ngModel]="selectAllDevices" (ngModelChange)="selectAllToggle($event)"/> Select All</th> -->
				<th class="red-text">{{'g.actview_id' | translate}}</th>
				<th>{{'device.serial_number' | translate}}</th>
				<th>{{'device.charger_id' | translate}}</th>
				<th>{{'device.firmware_version' | translate}}</th>
				<th>{{'g.action' | translate}}</th>
			</tr>
		</thead>
		<tbody *ngIf="reportData.charger?.wifi">
			<tr *ngFor="let chargerId of reportData.charger.wifi; let idx = index">
				<!-- <td><input (ngModelChange)="toggleDeviceSelection($event, charger)" type="checkbox" class="form-control" [checked]="reportData.chargers[chargerId].checked" ngModel/></td> -->
				<td class="red-text">{{chargerId.id}}</td>
				<td>{{reportData.chargers[chargerId.id].serialnumber}}</td>
				<td>{{reportData.chargers[chargerId.id].chargerusername}}</td>
				<td>{{chargerId.version}}</td>
				<td>
					<a (click)="unBlock('charger', 'wifi', chargerId.id, idx, chargerId.version)" class="btn btn-primary">{{'g.unblock' | translate}}</a>
				</td>
			</tr>	
		</tbody>
	</table>
</simple-widget>
<simple-widget [title]="'device.chargers_blocked' | translate">
	<table class="table table-stripped">
		<thead>
			<tr>
				<!-- <th><input type="checkbox" [ngModel]="selectAllDevices" (ngModelChange)="selectAllToggle($event)"/> Select All</th> -->
				<th class="red-text">{{'g.actview_id' | translate}}</th>
				<th>{{'device.serial_number' | translate}}</th>
				<th>{{'device.charger_id' | translate}}</th>
				<th>{{'device.firmware_version' | translate}}</th>
				<th>{{'g.action' | translate}}</th>
			</tr>
		</thead>
		<tbody *ngIf="reportData.charger?.device">
			<tr *ngFor="let chargerId of reportData.charger.device; let idx = index">
				<!-- <td><input (ngModelChange)="toggleDeviceSelection($event, charger)" type="checkbox" class="form-control" [checked]="reportData.chargers[chargerId].checked" ngModel/></td> -->
				<td class="red-text">{{chargerId.id}}</td>
				<td>{{reportData.chargers[chargerId.id].serialnumber}}</td>
				<td>{{reportData.chargers[chargerId.id].chargerusername}}</td>
				<td>{{chargerId.version}}</td>
				<td>
					<a (click)="unBlock('charger', 'device', chargerId.id, idx, chargerId.version)" class="btn btn-primary">{{'g.unblock' | translate}}</a>
				</td>
			</tr>	
		</tbody>
	</table>
</simple-widget>


<simple-widget [title]="'device.battviews_plc_blocked' | translate">
	<table class="table table-stripped">
		<thead>
			<tr>
				<!-- <th><input type="checkbox" [ngModel]="selectAllDevices" (ngModelChange)="selectAllToggle($event)"/> Select All</th> -->
				<th class="red-text">{{'g.actview_id' | translate}}</th>
				<th>{{'device.serial_number' | translate}}</th>
				<th>{{'device.battery_id' | translate}}</th>
				<th>{{'g.action' | translate}}</th>
			</tr>
		</thead>
		<tbody *ngIf="reportData.battview?.plc">
			<tr *ngFor="let battviewId of reportData.battview.plc; let idx = index">
				<!-- <td><input (ngModelChange)="toggleDeviceSelection($event, reportData.battviews[battviewId])" type="checkbox" class="form-control" [checked]="reportData.battviews[battviewId].checked" ngModel/></td> -->
				<td class="red-text">{{battviewId}}</td>
				<td>{{reportData.battviews[battviewId].serialnumber}}</td>
				<td>{{reportData.battviews[battviewId].batteryid}}</td>
				<td>
					<a (click)="unBlock('battview', 'plc', battviewId, idx)" class="btn btn-primary">{{'g.unblock' | translate}}</a>
				</td>
			</tr>	
		</tbody>
	</table>
</simple-widget>
<simple-widget [title]="'device.battviews_wifi_blocked' | translate">
	<table class="table table-stripped">
		<thead>
			<tr>
				<!-- <th><input type="checkbox" [ngModel]="selectAllDevices" (ngModelChange)="selectAllToggle($event)"/> Select All</th> -->
				<th class="red-text">{{'g.actview_id' | translate}}</th>
				<th>{{'device.serial_number' | translate}}</th>
				<th>{{'device.battery_id' | translate}}</th>
				<th>{{'device.firmware_version' | translate}}</th>
				<th>{{'g.action' | translate}}</th>
			</tr>
		</thead>
		<tbody *ngIf="reportData.battview?.wifi">
			<tr *ngFor="let battviewId of reportData.battview.wifi; let idx = index">
				<!-- <td><input (ngModelChange)="toggleDeviceSelection($event, charger)" type="checkbox" class="form-control" [checked]="reportData.battviews[battviewId].checked" ngModel/></td> -->
				<td class="red-text">{{battviewId.id}}</td>
				<td>{{reportData.battviews[battviewId.id].serialnumber}}</td>
				<td>{{reportData.battviews[battviewId.id].batteryid}}</td>
				<td>{{battviewId.version}}</td>
				<td>
					<a (click)="unBlock('battview', 'wifi', battviewId.id, idx, battviewId.version)" class="btn btn-primary">{{'g.unblock' | translate}}</a>
				</td>
			</tr>	
		</tbody>
	</table>
</simple-widget>
<simple-widget [title]="'device.battviews_blocked' | translate">
	<table class="table table-stripped">
		<thead>
			<tr>
				<!-- <th><input type="checkbox" [ngModel]="selectAllDevices" (ngModelChange)="selectAllToggle($event)"/> Select All</th> -->
				<th class="red-text">{{'g.actview_id' | translate}}</th>
				<th>{{'device.serial_number' | translate}}</th>
				<th>{{'device.battery_id' | translate}}</th>
				<th>{{'device.firmware_version' | translate}}</th>
				<th>{{'g.action' | translate}}</th>
			</tr>
		</thead>
		<tbody *ngIf="reportData.battview?.device">
			<tr *ngFor="let battviewId of reportData.battview.device; let idx = index">
				<!-- <td><input (ngModelChange)="toggleDeviceSelection($event, charger)" type="checkbox" class="form-control" [checked]="reportData.battviews[battviewId].checked" ngModel/></td> -->
				<td class="red-text">{{battviewId.id}}</td>
				<td>{{reportData.battviews[battviewId.id].serialnumber}}</td>
				<td>{{reportData.battviews[battviewId.id].batteryid}}</td>
				<td>{{battviewId.version}}</td>
				<td>
					<a (click)="unBlock('battview', 'device', battviewId.id, idx, battviewId.version)" class="btn btn-primary">{{'g.unblock' | translate}}</a>
				</td>
			</tr>	
		</tbody>
	</table>
</simple-widget>