import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SiteDashboardService } from '../site-dashboard.service';
import { UserService } from '../../../auth/user.service';
import { SideMenuService } from '../../../shared/side-menu/side-menu.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';

@Component({
	selector: 'app-manage-tags',
	templateUrl: './manage-tags.component.html',
	styleUrls: ['./manage-tags.component.css']
})
export class ManageTagsComponent implements OnInit, OnDestroy {

	currentUser: any = {};
	currentSite: any = {};
	siteId: number;
	customerId: number;
	siteGroups		= [];
	sitePublicTags	= [];
	sitePrivateTags	= [];
	siteGroupTags	= [];
	siteSubscription: any;
	newGroup: any;

	constructor(
		private siteDashboardService: SiteDashboardService,
		private route: ActivatedRoute,
		private userService: UserService,
		private sideMenu: SideMenuService,
		private notificationMessage: NotificationMessageService
	) { }

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();
		this.siteDashboardService.setActiveTab('settings');
		this.siteDashboardService.setActiveSubTab('manage-tags');

		this.siteId = Number(this.route.parent.snapshot.params['siteid']);
		this.customerId = Number(this.route.parent.snapshot.params['customerid']);

		this.siteSubscription = this.sideMenu.currentSite.subscribe(siteInfo => {
			this.currentSite	= siteInfo;
		});

		this.getSiteTags();
		this.getSiteGroups();
	}
	
	ngOnDestroy() {
		if(this.siteSubscription)
			this.siteSubscription.unsubscribe();
	}

	getSiteTags() {
		if(!this.customerId || !this.siteId)
			return;
		
		this.siteDashboardService.getSiteTags(this.customerId, this.siteId).subscribe( (response: any) => {
			this.sitePublicTags		= response.public;
			this.sitePrivateTags	= response.private;
			this.siteGroupTags		= response.group;
		});
	}
	
	getSiteGroups() {
		if(!this.siteId)
			return;
		
		this.siteDashboardService.getSiteGroups(this.siteId).subscribe((response: any) => {
			this.siteGroups	= response;
		});
	}

	editTagSharingOption = function(tag, newShareOption) {
		
		if(!this.customerId || !this.siteId)
			return;

		if(newShareOption == 'private') {
			if(tag.ownerid != this.currentUser.id) {
				this.notificationMessage.setMessage('translate|g.change_tag_not_allowed');
				return;
			}
		}
		this.siteDashboardService.updateTagShareOption(this.customerId, this.siteId, tag, newShareOption).subscribe( (data) => {
			this.updateTagsList(tag, 'delete');
			tag.sharingoption = newShareOption;
			this.updateTagsList(tag, 'insert');
		});
	}

	updateTagsList(tag, operation) {
		var shareOption = tag.sharingoption;
		switch (operation) {
			case 'insert':
				switch(shareOption) {
					case 'private':
						this.sitePrivateTags.push(tag);
					break;
					case 'group':
						this.siteGroupTags.push(tag);
					break;
					default:
						this.sitePublicTags.push(tag);
					break;
				}
			break;
			case 'delete':
				var group;
				switch(shareOption) {
					case 'private':
						group = this.sitePrivateTags;
					break;
					case 'group':
						group = this.siteGroupTags;
					break;
					default:
						group = this.sitePublicTags;
					break;
				}

				var newList = [];
				group.forEach((currentTag) => {
					if (currentTag.id != tag.id) {
						newList.push(currentTag);
					}
				});

				switch(shareOption) {
					case 'private':
						this.sitePrivateTags = newList;
					break;
					case 'group':
						this.siteGroupTags = newList;
					break;
					default:
						this.sitePublicTags = newList;
					break;
				}
			break;
		}
	}

	siteTagAdded(info) {
		this.updateTagsList(info.tag, 'insert');
	}

	tagRemoved(tag) {
		
		if(!this.customerId || !this.siteId)
			return;

		if (!confirm('Are you sure that you want to remove the tag from any device?'))
			return false;

		this.siteDashboardService.removeTagFromSite(this.customerId, this.siteId, tag).subscribe( (data) => {
			this.updateTagsList(tag, 'delete');
		});
	}

	removeGroup(groupId) {
		if(!this.siteId)
			return;

		if (!confirm('Are you sure that you want to remove the group?'))
			return false;

		this.siteDashboardService.removeGroupFromSite(this.siteId, groupId).subscribe(() => {
			let newList = [];
			this.siteGroups.forEach((group) => {
				if(group.id != groupId) {
					newList.push(group);
				}
			});

			this.siteGroups = newList;
		});
	}

	addGroup(groupText) {
		this.siteDashboardService.addSiteGroup(this.siteId, groupText).subscribe((data: any) => {

			this.newGroup = null;
			if(data.httpStatus == 'error') {
				this.notificationMessage.setMessage(data.msg);
			} else {
				this.siteGroups.push(data);
				this.notificationMessage.setMessage('globalSuccessMsg', 'success');
			}
		});
	}
}