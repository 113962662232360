import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DownloadAppComponent } from './download-app/download-app.component';
import { AuthGuard } from '../auth/auth-guard.service';
import { HomeComponent } from './home.component';
import { SitesMapComponent } from './sites-map/sites-map.component';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CustomerReportsComponent } from './customer-dashboard/customer-reports/customer-reports.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { UserAgreementComponent } from './user-agreement/user-agreement.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { GroupAdminComponent } from './group-admin/group-admin.component';
import { ConfirmUserEmailComponent } from './confirm-user-email/confirm-user-email.component';
import { DevicesManagementComponent } from './devices-management/devices-management.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SiteDashboardComponent } from './site-dashboard/site-dashboard.component';
import { SiteInfoComponent } from './site-dashboard/site-info/site-info.component';
import { AlertsSettingsComponent } from './site-dashboard/alerts-settings/alerts-settings.component';
import { TempDisabledAlertsComponent } from './site-dashboard/temp-disabled-alerts/temp-disabled-alerts.component';
import { ManageTagsComponent } from './site-dashboard/manage-tags/manage-tags.component';
import { ChargersComponent } from './site-dashboard/chargers/chargers.component';
import { ChargersDashboardComponent } from './site-dashboard/chargers/chargers-dashboard/chargers-dashboard.component';
import { TruckviewsComponent } from './site-dashboard/truckviews/truckviews.component';
import { TruckviewsDashboardComponent } from './site-dashboard/truckviews/truckviews-dashboard/truckviews-dashboard.component';
import { BattviewsComponent } from './site-dashboard/battviews/battviews.component';
import { BattviewsDashboardComponent } from './site-dashboard/battviews/battviews-dashboard/battviews-dashboard.component';
import { SiteBatteryAgingComponent } from './site-dashboard/battviews/site-battery-aging/site-battery-aging.component';
import { SiteBatteryPerformanceComponent } from './site-dashboard/battviews/site-battery-performance/site-battery-performance.component';
import { SiteTruckPerformanceComponent } from './site-dashboard/truckviews/site-truck-performance/site-truck-performance.component';
import { SiteAlertsComponent } from './site-dashboard/battviews/site-alerts/site-alerts.component';
import { SiteReportsComponent } from './site-dashboard/battviews/site-reports/site-reports.component';
import { SiteTruckReportsComponent } from './site-dashboard/truckviews/site-truck-reports/site-truck-reports.component';
import { ChargersPerformanceComponent } from './site-dashboard/chargers/chargers-performance/chargers-performance.component';
import { SiteAnalyticsComponent } from './site-dashboard/battviews/site-analytics/site-analytics.component';
import { BattviewsMobilesDashboardComponent } from './site-dashboard/battviews-mobiles/battviews-mobiles-dashboard/battviews-mobiles-dashboard.component';
import { BattviewsMobilesComponent } from './site-dashboard/battviews-mobiles/battviews-mobiles.component';
import { PrivatePageComponent } from './site-dashboard/act-first/private-page/private-page.component';
import { ChargersControlComponent } from './site-dashboard/act-first/chargers-control/chargers-control.component';
import { SiteHistoryComponent } from './site-dashboard/act-first/site-history/site-history.component';
import { HistoricalChargerLogComponent } from './site-dashboard/act-first/historical-charger-log/historical-charger-log.component';
import { PublicPageComponent } from './site-dashboard/act-first/public-page/public-page.component';
import { ActFirstGuard } from '../auth/actfirst-guard.service';
import { ReportsChartsComponent } from './site-dashboard/act-first/reports-charts/reports-charts.component';
import { ManagementComponent } from './site-dashboard/act-first/management/management.component';
import { DynamicResponseComponent } from './site-dashboard/dynamic-response/dynamic-response.component';
import { DrProfilesComponent } from './site-dashboard/dynamic-response/dr-profiles/dr-profiles.component';
import { DrEventLogComponent } from './site-dashboard/dynamic-response/dr-event-log/dr-event-log.component';
import { EventsHistoryComponent } from './site-dashboard/dynamic-response/events-history/events-history.component';
import { EventsChartComponent } from './site-dashboard/dynamic-response/events-chart/events-chart.component';
import { PmInfoComponent } from './site-dashboard/chargers/chargers-dashboard/pm-info/pm-info.component';
import { ServicePageComponent } from '../device-management/service-page/service-page.component';
import { UsersSettingsComponent } from './site-dashboard/users-settings/users-settings.component';

const homeRoutes: Routes = [
	{ path: 'app-download', component: DownloadAppComponent },
	{ path: 'contact-us', component: ContactUsComponent, canActivate: [AuthGuard]},
	{ path: 'user-agreement', component: UserAgreementComponent, canActivate: [AuthGuard], data: {'stateName': 'user_agreement'}},
	{ path: 'user-settings', component: UserSettingsComponent, canActivate: [AuthGuard]},
	{ path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard], data: {'pageName': 'change_password'}},
	{ path: 'change-user-password', component: ChangePasswordComponent, canActivate: [AuthGuard], data: {'pageName': 'change_user_password'}},
	{ path: 'reset-password/:userId', component: ChangePasswordComponent, data: {'pageName': 'reset_password'}},
	{ path: 'group-admin', component: GroupAdminComponent, canActivate: [AuthGuard]},
	{ path: 'devices/devices-management', component: DevicesManagementComponent, canActivate: [AuthGuard]},
	{ path: 'confirm-user-email/:userId', component: ConfirmUserEmailComponent},
	{ path: 'battview-mobile', component: BattviewsMobilesComponent, data: {isShared: false}, children: [
		{ path: '', pathMatch: 'full', redirectTo: 'analytics/battery-summary'},
		{ path: 'analytics/:tab/:subTab', component: BattviewsMobilesDashboardComponent, canActivate: [AuthGuard], data: {'pageName': 'analytics', 'viewName': 'dashboard'}},
		{ path: 'analytics/:tab', component: BattviewsMobilesDashboardComponent, canActivate: [AuthGuard], data: {'pageName': 'analytics', 'viewName': 'dashboard'}},
		{ path: 'settings', component: BattviewsMobilesDashboardComponent, canActivate: [AuthGuard], data: {'pageName': 'settings', 'viewName': 'dashboard'}},
		{ path: 'connectivity-track', component: BattviewsMobilesDashboardComponent, canActivate: [AuthGuard], data: {'pageName': 'connectivity-track', 'viewName': 'dashboard'}},
		{ path: 'config-track', component: BattviewsMobilesDashboardComponent, canActivate: [AuthGuard], data: {'pageName': 'config-track', 'viewName': 'dashboard'}},
		{ path: 'listing', component: BattviewsMobilesDashboardComponent, canActivate: [AuthGuard], data: {'pageName': 'listing', 'viewName': 'dashboard'}},
		{ path: 'notes', component: BattviewsMobilesDashboardComponent, canActivate: [AuthGuard], data: {'pageName': 'notes', 'viewName': 'dashboard'}},
	]},
	{ path: 'shared-battview-mobile', component: BattviewsMobilesComponent, data: {isShared: true}, children: [
		{ path: '', pathMatch: 'full', redirectTo: 'analytics/battery-summary'},
		{ path: 'analytics/:tab/:subTab', component: BattviewsMobilesDashboardComponent, canActivate: [AuthGuard], data: {'pageName': 'analytics', 'viewName': 'dashboard'}},
		{ path: 'analytics/:tab', component: BattviewsMobilesDashboardComponent, canActivate: [AuthGuard], data: {'pageName': 'analytics', 'viewName': 'dashboard'}},
		{ path: 'settings', component: BattviewsMobilesDashboardComponent, canActivate: [AuthGuard], data: {'pageName': 'settings', 'viewName': 'dashboard'}},
		{ path: 'connectivity-track', component: BattviewsMobilesDashboardComponent, canActivate: [AuthGuard], data: {'pageName': 'connectivity-track', 'viewName': 'dashboard'}},
		{ path: 'config-track', component: BattviewsMobilesDashboardComponent, canActivate: [AuthGuard], data: {'pageName': 'config-track', 'viewName': 'dashboard'}},
		{ path: 'listing', component: BattviewsMobilesDashboardComponent, canActivate: [AuthGuard], data: {'pageName': 'listing', 'viewName': 'dashboard'}},
		{ path: 'notes', component: BattviewsMobilesDashboardComponent, canActivate: [AuthGuard], data: {'pageName': 'notes', 'viewName': 'dashboard'}},
	]},
	{ path: '', component: HomeComponent ,children: [
		{ path: '', pathMatch: 'full', redirectTo: 'sites-map' },
		{ path: 'sites-map', canActivate: [AuthGuard], component: SitesMapComponent },
		{ path: 'main/:id', canActivate: [AuthGuard], component: CustomerDashboardComponent },
		{ path: ':id/customer-sites-reports/:report', canActivate: [AuthGuard], component: CustomerReportsComponent},
		{ path: 'pm-info/:pmID', canActivate: [AuthGuard], component: PmInfoComponent},    
		{ path: ':customerid/:siteid', component: SiteDashboardComponent, children: [
			{ path: '', canActivate: [AuthGuard], component: SiteInfoComponent},
			{ path: 'service-page', component: ServicePageComponent, canActivate: [AuthGuard], data: {'insideSiteDashboard': true}},
			{ path: 'alerts-settings',canActivate: [AuthGuard], component: AlertsSettingsComponent}, // site settings
			{ path: 'users-settings',canActivate: [AuthGuard], component: UsersSettingsComponent},
			{ path: 'temp-disabled-alerts',canActivate: [AuthGuard], component: TempDisabledAlertsComponent},
			{ path: 'manage-tags', canActivate: [AuthGuard], component: ManageTagsComponent},
			{ path: 'chargers', component: ChargersComponent, canActivateChild: [AuthGuard], children: [
				{ path: '', pathMatch: 'full', redirectTo: 'listing'},
				{ path: 'listing', component: ChargersDashboardComponent, data: {'pageName': 'listing'}},
				{ path: 'performance', component: ChargersPerformanceComponent, data: {'pageName': 'performance'}},
				{ path: 'analytics/:tab/:chargerID', component: ChargersDashboardComponent, data: {'pageName': 'analytics', 'viewName': 'dashboard'}},
				{ path: 'settings', component: ChargersDashboardComponent, data: {'pageName': 'settings', 'viewName': 'dashboard'}},
				{ path: 'lcd-projection', component: ChargersDashboardComponent, data: {'pageName': 'lcd-projection', 'viewName': 'dashboard'}},
				{ path: 'connectivity-track', component: ChargersDashboardComponent, data: {'pageName': 'connectivity-track', 'viewName': 'dashboard'}},
				{ path: 'debug-records', component: ChargersDashboardComponent, data: {'pageName': 'debug-records', 'viewName': 'dashboard'}},
				{ path: 'config-track', component: ChargersDashboardComponent, data: {'pageName': 'config-track', 'viewName': 'dashboard'}},
				{ path: 'charger-energy', component: ChargersDashboardComponent, data: {'pageName': 'charger-energy', 'viewName': 'dashboard'}},
				{ path: 'notes/:chargerID', component: ChargersDashboardComponent, data: {'pageName': 'notes', 'viewName': 'dashboard'}},
				{ path: 'lcd-history', component: ChargersDashboardComponent, data: {'pageName': 'lcd-history', 'viewName': 'dashboard'}},
				{ path: 'pms-live-view', component: ChargersDashboardComponent, data: {'pageName': 'pms-live-view', 'viewName': 'dashboard'}},
				{ path: 'pms-list', component: ChargersDashboardComponent, data: {'pageName': 'pms-list', 'viewName': 'dashboard'}},
				{ path: 'site-energy', component: ChargersDashboardComponent, data: {'pageName': 'site-energy'}},
			]},
			{ path: 'charger-analytics/:tab/:chargerID', redirectTo: 'chargers/analytics/:tab/:chargerID'},
			{ path: 'battviews', component: BattviewsComponent, data: {isBattviewMobile: false}, canActivateChild: [AuthGuard], children: [
				{ path: '', pathMatch: 'full', redirectTo: 'listing'},
				{ path: 'listing', component: BattviewsDashboardComponent, data: {'pageName': 'listing'}},
				{ path: 'analytics/:tab/:battviewID/:subTab', component: BattviewsDashboardComponent, data: {'pageName': 'analytics', 'viewName': 'dashboard'}},
				{ path: 'settings', component: BattviewsDashboardComponent, data: {'pageName': 'settings', 'viewName': 'dashboard'}},
				{ path: 'analytics/:tab/:battviewID', component: BattviewsDashboardComponent, data: {'pageName': 'analytics', 'viewName': 'dashboard'}},
				{ path: 'quick-view/:battviewID', component: BattviewsDashboardComponent, data: {'pageName': 'quick-view', 'viewName': 'dashboard'}},
				{ path: 'faults/:battviewID', component: BattviewsDashboardComponent, data: {'pageName': 'faults', 'viewName': 'dashboard'}},
				{ path: 'notes/:battviewID', component: BattviewsDashboardComponent, data: {'pageName': 'notes', 'viewName': 'dashboard'}},
				{ path: 'debug-records/:battviewID', component: BattviewsDashboardComponent, data: {'pageName': 'debug-records', 'viewName': 'dashboard'}},
				{ path: 'config-track/:battviewID', component: BattviewsDashboardComponent, data: {'pageName': 'config-track', 'viewName': 'dashboard'}},
				{ path: 'fetch-rt-records/:battviewID', component: BattviewsDashboardComponent, data: {'pageName': 'fetch-rt-records', 'viewName': 'dashboard'}},
				{ path: 'list-rt-records/:battviewID', component: BattviewsDashboardComponent, data: {'pageName': 'list-rt-records', 'viewName': 'dashboard'}},
				{ path: 'connectivity-track/:battviewID', component: BattviewsDashboardComponent, data: {'pageName': 'connectivity-track', 'viewName': 'dashboard'}},
				{ path: 'aging', component: SiteBatteryAgingComponent},
				{ path: 'performance', component: SiteBatteryPerformanceComponent},
				{ path: 'alerts', component: SiteAlertsComponent},
				{ path: 'reports', component: SiteReportsComponent},
				{ path: 'site-analytics', component: SiteAnalyticsComponent},
			]},
			{ path: 'truckviews', component: TruckviewsComponent, canActivateChild: [AuthGuard], children: [
				{ path: '', pathMatch: 'full', redirectTo: 'listing'},
				{ path: 'listing', component: TruckviewsDashboardComponent, data: {'pageName': 'listing'}},
				{ path: 'analytics/:tab/:battviewID/:subTab', component: TruckviewsDashboardComponent, data: {'pageName': 'analytics', 'viewName': 'dashboard'}},
				{ path: 'settings', component: TruckviewsDashboardComponent, data: {'pageName': 'settings', 'viewName': 'dashboard'}},
				{ path: 'analytics/:tab/:truckviewID', component: TruckviewsDashboardComponent, data: {'pageName': 'analytics', 'viewName': 'dashboard'}},
				{ path: 'quick-view/:truckviewID', component: TruckviewsDashboardComponent, data: {'pageName': 'quick-view', 'viewName': 'dashboard'}},
				{ path: 'faults/:truckviewID', component: TruckviewsDashboardComponent, data: {'pageName': 'faults', 'viewName': 'dashboard'}},
				{ path: 'notes/:truckviewID', component: TruckviewsDashboardComponent, data: {'pageName': 'notes', 'viewName': 'dashboard'}},
				{ path: 'debug-records/:truckviewID', component: TruckviewsDashboardComponent, data: {'pageName': 'debug-records', 'viewName': 'dashboard'}},
				{ path: 'connectivity-track/:truckviewID', component: TruckviewsDashboardComponent, data: {'pageName': 'connectivity-track', 'viewName': 'dashboard'}},
				{ path: 'performance', component: SiteTruckPerformanceComponent},
				{ path: 'alerts', component: SiteAlertsComponent},
				{ path: 'reports', component: SiteTruckReportsComponent},
				{ path: 'site-analytics', component: SiteAnalyticsComponent},
			]},
			{ path: 'dynamic-response', component: DynamicResponseComponent, canActivateChild: [AuthGuard], children: [
				{ path: '', pathMatch: 'full', redirectTo: 'profiles'},
				{ path: 'profiles', component: DrProfilesComponent},
				{ path: 'event-logs', component: DrEventLogComponent},
				{ path: 'events-history', component: EventsHistoryComponent},
				{ path: 'events-chart', component: EventsChartComponent},
			]},
			{ path: 'battery-analytics/:battviewID/:tab', redirectTo: 'battviews/analytics/:tab/:battviewID'},
			{ path: 'notes/battview/:battviewID', redirectTo: 'battviews/notes/:battviewID'},
			{ path: 'notes/charger/:chargerID', redirectTo: 'chargers/notes/:chargerID'},
			{ path: 'charger-programming', redirectTo: 'chargers/settings'},
			{ path: 'battview-programming', redirectTo: 'battviews/settings'},
			{ path: 'batteries/batteries-listing', redirectTo: 'battviews/listing'},
			{ path: 'act-first/private', canActivate: [AuthGuard], component: PrivatePageComponent},
			{ path: 'act-first/chargers-control', canActivate: [AuthGuard], component: ChargersControlComponent},
			{ path: 'act-first/site-history', canActivate: [ActFirstGuard], component: SiteHistoryComponent},
			{ path: 'act-first/historical-charger-log', canActivate: [ActFirstGuard], component: HistoricalChargerLogComponent},
			{ path: 'act-first/reports-charts/:report', canActivate: [AuthGuard], component: ReportsChartsComponent},
			{ path: 'act-first/management', canActivate: [AuthGuard], component: ManagementComponent},
		]},
	]},
	{ path: 'act-first/public/:token', component: PublicPageComponent},
	{ path: '404', component: PageNotFoundComponent },
	{ path: '**', redirectTo: '404' },
	
];

@NgModule({
	imports :[
		RouterModule.forChild(homeRoutes)
	],
	exports: [RouterModule]
})
export class HomeRoutingModule {

}