<div class="boxs3 margin-top-lg col-lg-12">
	<div class="col-lg-12 margin-top-sm margin-bottom-sm">
		<div class="col-lg-2">
			<strong>{{'g.request_pm_report' | translate}}</strong>
		</div>
		<div class="col-lg-10">
			<button class="btn btn-primary" type="button" [disabled]="status" (click)="requestPMReport()">
				{{'g.request' | translate}}
			</button>
		</div>
	</div>
</div>