<div id="sidebar-wrapper" [@enterAnimation] *ngIf="!hideMenuView">
    <ul class="insm sidebar-nav"> 
        <div *ngIf="ACTUser; else falsyTemplate">
            <input id="search-filter" [ngModel]="searchText" (ngModelChange)="applyFilter('text', $event)" offset="15" placeholder="Search/Filter" class="form-control"/>
            <select 
            class="form-control"
			[ngModel]="actIntelligentFilter.dealer"
			(ngModelChange)="applyFilter('dealer', $event)">
              <option value="">- Select By Dealer -</option>
              <option
                *ngFor="let obj of dealersList"
                [value]="obj.id"> {{obj.name}}</option>
			</select>
            <select 
            class="form-control"
            [ngModel]="actIntelligentFilter.salesPerson"
            (ngModelChange)="applyFilter('salesPerson', $event)">
              <option value="">{{'g.sales_person_filter' | translate}}</option>
              <option
                *ngFor="let obj of salesPersonsList"
                [value]="obj.id"><span *ngIf="obj.id">{{obj.fn + ' ' + obj.ln}}</span></option>
            </select>
			<select
				class="form-control"
				[ngModel]="actIntelligentFilter.demo"
				(ngModelChange)="applyFilter('demo', $event)">
					<option value="">- Select -</option>
					<option value="include">Demo</option>
					<option value="dontInclude">Not Demo</option>
			</select>
			<select
				class="form-control"
				[ngModel]="actIntelligentFilter.managedByDealer"
				(ngModelChange)="applyFilter('managedByDealer', $event)">
					<option value="">- Select -</option>
					<option value="include">Managed by dealer</option>
					<option value="dontInclude">Not Managed by dealer</option>
			</select>
			<select
				class="form-control"
				[ngModel]="actIntelligentFilter.act"
				(ngModelChange)="applyFilter('act', $event)">
					<option value="">- Select -</option>
					<option value="include">ACT intelligent</option>
					<option value="dontInclude">Not ACT intelligent</option>
			</select>
			<select
				class="form-control"
				[ngModel]="actIntelligentFilter.country"
				(ngModelChange)="applyFilter('country', $event)"
			>
				<option value="">- Select By Country -</option>
				<option value="{{countryOptions.usa.value}}">{{countryOptions.usa.text}}</option>
				<option value="{{countryOptions.canada.value}}">{{countryOptions.canada.text}}</option>
			</select>
			<select
				class="form-control"
				[ngModel]="actIntelligentFilter.state"
				(ngModelChange)="applyFilter('state', $event)"
				*ngIf="actIntelligentFilter.country == countryOptions.usa.value"
			>
				<option value="">- Select By State -</option>
				<option *ngFor="let state of stateOptions" value="{{state}}">{{state}}</option>
			</select>
			<hr style="margin: 10px 0">
        </div>
        <ng-template #falsyTemplate>
             <div>
                <li *ngIf="!userService.hasAccessFunction('no_manage_devices') && !currentUser.isEndClient && !currentUser.isTruckDealer">
                    <a routerLink="/devices/devices-management" class="padding-8">Devices Management</a>
                 </li>
                <input id="search-filter" [ngModel]="searchText" (ngModelChange)="applyFilter('text', $event)" placeholder="Search/Filter" class="form-control"/>
            </div>
        </ng-template>
        <li
         *ngFor="let customer of customers">
        	<a
             class="customer pointer" 
             [ngClass]="{'active': customer.id == currentCustomer.id, 'intelligence': customer.had_act_intelligence}" 
             (click)="onSelectCustomer($event, customer)">
                <span 
                 (click)="$event.stopPropagation();toggleCustomerExpand(customer);" 
                 class="glyphicon margin-right-xs" 
                 [ngClass]="{'glyphicon-plus-sign': !customer.expanded, 'glyphicon-minus-sign': customer.expanded}"
                ></span>
                <span>{{customer.name}}</span>
            </a>
            <ul 
               *ngIf="customer.expanded"
               class="sidebar-nav">
                <li
                 *ngFor="let site of sitesCustomerMap[customer.id]">
                    <a href="javascript:"
                       [ngClass]="{'active': site.id === currentSite.id, 'router': site.act_intelligent_type != 0}"
                       (click)="$event.preventDefault();selectSite(site)">{{site.name}}</a>
                </li>
            </ul>
        </li>
    </ul>
</div>