import { Component, OnInit, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as _ from 'underscore';

import { SiteDashboardService } from '../site-dashboard.service';
import { UserService } from '../../../auth/user.service';
import { CommonUtil } from '../../../shared/services/utility/common.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { SideMenuService } from '../../../shared/side-menu/side-menu.service';
import { NavigationService } from '../../../shared/services/navigation.service';

@Component({
	selector: 'app-users-settings',
	templateUrl: './users-settings.component.html',
	styleUrls: ['./users-settings.component.css']
})
export class UsersSettingsComponent implements OnInit, OnDestroy {

	currentUser: any;
	userIsSubscribed: boolean = false;
	customerID: number = 0;
	siteID: number = 0;
	currentSite: any = {};

	alert: any = {
		tempCycleAbnormalFaults: {},
		missed_connection_alert: [],
		missedConnectionAlert: []
	};
	oldAlertsSettings: any = {
		missed_connection_alert: [],
		missedConnectionAlert: []
	};
	usersAlertsSettings: any;
	subscribedUsers: any = [];
	frequencies: any = {};
	weeklyDispatchDays: any = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
	runSLider: boolean;
	model: any = {};
	selectedUser: any = 0;
	weeklySlider: any = {
		value: 'none',
		options: {
			showTicksValues: true,
			stepsArray: [
				{value: "none"},
				{value: "weekly"},
			]
		}
	};
	dailySlider: any = {
		value: 'none',
		options: {
			showTicksValues: true,
			stepsArray: [
				{value: "none"},
				{value: "daily"},
			]
		}
	};

	slider: any = {
		value: 'none',
		options: {
			showTicksValues: true,
			stepsArray: [
				{value: "none"},
				{value: "weekly"},
				{value: "daily"},
			]
		}
	};

	connectivity: any = {
		charger: {
			isWeekly: true
		},
		battview: {
			isWeekly: true
		}
	};

	siteSubscription: any;

	constructor(
		public userService: UserService,
		private siteDashboardService: SiteDashboardService,
		private commonUtil: CommonUtil,
		private route: ActivatedRoute,
		private notificationMessage: NotificationMessageService,
		private sideMenuService: SideMenuService,
		private router: Router,
		public navigation: NavigationService,
		private elementRef: ElementRef,
		private renderer: Renderer2,
	) { }

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();
		this.siteDashboardService.setActiveTab('settings');
		this.siteDashboardService.setActiveSubTab('users-settings');

		if (this.currentUser.accessFunctions['edit_alert_setting_disable'] == 'read') {
			this.router.navigate(['/sites-map']);
		}

		var sliderDisabled = false;
		if (this.userService.hasAccessFunction('edit_alert_setting_disable') || this.currentUser.isTruckDealer) {
			sliderDisabled = true;
		}

		this.weeklySlider.options.disabled	= sliderDisabled;
		this.dailySlider.options.disabled	= sliderDisabled;
		this.slider.options.disabled		= sliderDisabled;

		this.frequencies.enable_no_alert = false;
		this.frequencies.view_truck_id = false;
		// this.frequencies.enable_receiving_noc_emails = false;
		this.frequencies.bv_charge_events_only_for_charge_ahr = true;
		this.frequencies.bv_inuse_events_only_for_charge_ahr = true;
		this.frequencies.weekly_dispatch_email_day = 1;

		this.route.parent.params.subscribe( params => {
			this.customerID = params['customerid'];
			this.siteID = params['siteid'];
			this.siteSubscription = this.sideMenuService.currentSite.subscribe(siteInfo => {
				this.currentSite = siteInfo;
				this.alertsHandler();
			});
		});
	}

	ngOnDestroy() {
		this.siteSubscription.unsubscribe();
	}

	setDirtyFields() {
		let dirtyFields = {};
		let oldAlertsSettings = this.oldAlertsSettings;

		let arrayFields = ['cycle_abnormal_faults'];
		for (let key in oldAlertsSettings) {

			if(arrayFields.indexOf(key) != -1) {

				if(oldAlertsSettings[key] && this.frequencies[key] && !this.commonUtil.arrayCompare(oldAlertsSettings[key], this.frequencies[key])) {
					dirtyFields[key] = this.frequencies[key];
				}
			} else if(oldAlertsSettings[key] != this.frequencies[key]) {

				dirtyFields[key] = this.frequencies[key];
			}
		}

		return dirtyFields;
	}

	alertsHandler(isAfterSave = false) {
		this.siteDashboardService.getAlertsSettingsWithSubscribers(this.siteID, {}).subscribe((response: any) => {
			this.alert					= response.alertsSettings;
			this.usersAlertsSettings	= response.usersAlertsSettings;
			let subscribedUsers		= [];

			response.usersHaveAccessToSite.forEach((user) => {
				if(this.alert.subscribed_users.indexOf(''+user.id) > -1)
					subscribedUsers.push(user);
			});
			response.ACTusersHaveAccessToSite.forEach((user) => {
				if(this.alert.subscribed_act_users.indexOf(''+user.id) > -1)
					subscribedUsers.push(user);
			});
			this.subscribedUsers = subscribedUsers;

			if(this.currentUser.isACTuser) {
				this.updateUserAlertsSettings(this.selectedUser);
			} else {
				this.updateUserAlertsSettings(this.currentUser.id);
			}

			this.runSLider = true;
			this.setConnectivitySliders();
		});
	}

	setConnectivitySliders() {
		let chargerDisconnectionIsDaily		= this.commonUtil.dataValidator('integer', {'min': 2, 'max': 24},	this.alert.charger_disconnection_timespan),
			battviewDisconnectionIsDaily	= this.commonUtil.dataValidator('integer', {'min': 2, 'max': 24},	this.alert.battview_disconnection_timespan);

		if(chargerDisconnectionIsDaily) {
			this.connectivity.charger.isWeekly = false;
		}
		if(battviewDisconnectionIsDaily) {
			this.connectivity.battview.isWeekly = false;
		}
		return;
	}

	updateAlertsSettings() {
		let inputs = this.elementRef.nativeElement.querySelectorAll('input');
		inputs.forEach((input) => {

			this.renderer.removeClass(input, 'invalid-input');
		});
		let selects = this.elementRef.nativeElement.querySelectorAll('input');
		selects.forEach((select) => {

			this.renderer.removeClass(select, 'invalid-input');
		});

		let cycle_abnormal_faults = [];

		for(var key in this.frequencies.tempCycleAbnormalFaults) {
			if(this.frequencies.tempCycleAbnormalFaults[key])
				cycle_abnormal_faults.push(key);
		}

		this.frequencies.cycle_abnormal_faults = cycle_abnormal_faults;

		if(!this.frequencies.enable_no_alert) this.frequencies.enable_no_alert = false;
		if(!this.frequencies.view_truck_id) this.frequencies.view_truck_id = false;
		if(this.frequencies.bv_charge_events_only_for_charge_ahr !== false) this.frequencies.bv_charge_events_only_for_charge_ahr = true;
		if(this.frequencies.bv_inuse_events_only_for_charge_ahr !== false) this.frequencies.bv_inuse_events_only_for_charge_ahr = true;
		if(!this.frequencies.weekly_dispatch_email_day) this.frequencies.weekly_dispatch_email_day = 1;

		var invalidFields = this.validateAlertsSettings(this.frequencies);
		if(invalidFields.length > 0) {
			this.notificationMessage.setMessage('Invalid input!', 'danger');
			let form = this.elementRef.nativeElement.querySelectorAll('form')[0];
			for(let idx in form) {
				let ele = form[idx];
				if(ele && typeof ele.getAttribute === 'function' && invalidFields.indexOf(ele.getAttribute('name')) > -1) {
					this.renderer.addClass(ele, 'invalid-input');
				}
			}
		} else {

			if(!this.currentUser.isACTuser) {
				this.selectedUser = this.currentUser.id;
			}

			let dirtyFields = this.setDirtyFields();
			if(Object.keys(dirtyFields).length === 0) {
				return;
			}

			this.siteDashboardService.updateUsersAlertsSettings(dirtyFields, this.siteID, this.selectedUser).subscribe((response:any) => {
				if(response.httpStatus == 'error') {
					if(response.msg == 'invalidFields') {
						this.notificationMessage.setMessage('Invalid input!', 'danger');
						let form = this.elementRef.nativeElement.querySelectorAll('form')[0];
						for(let idx in form) {
							let ele = form[idx];
							if(ele && typeof ele.getAttribute === 'function' && invalidFields.indexOf(ele.getAttribute('name')) > -1) {
								this.renderer.addClass(ele, 'invalid-input');
							}
						}
					}
				} else {
					this.siteDashboardService.deleteDashboardData(this.customerID, this.siteID);
					this.notificationMessage.setMessage('globalSuccessMsg', 'success');
					this.alertsHandler(true);
				}
			});
		}
	}

	updateUserAlertsSettings(userId = 0) {
		let userIsSubscribed = true;
		if(userId == 0 || !this.currentUser.isACTuser) {
			userIsSubscribed = false;
			if(this.alert.subscribed_users)
				userIsSubscribed = this.alert.subscribed_users.includes(''+this.currentUser.id);
			if(this.alert.subscribed_act_users)
				userIsSubscribed = userIsSubscribed || this.alert.subscribed_act_users.includes(''+this.currentUser.id);
		}
		this.userIsSubscribed = userIsSubscribed;

		let selectedUser = userId;
		if(!userIsSubscribed && userId == 0) {
			selectedUser = this.currentUser.id;
		}
		this.selectedUser = userId;

		let frequencies = Object.assign({}, this.usersAlertsSettings[selectedUser]);
		if (!frequencies || Object.keys(frequencies).length === 0) {
			frequencies = Object.assign(frequencies, this.usersAlertsSettings[0]);
		}

		if(frequencies) {
			frequencies.tempCycleAbnormalFaults = {};
			frequencies.cycle_abnormal_faults.forEach((fault) => {
				frequencies.tempCycleAbnormalFaults[fault] = true;
			});
		}

		if(frequencies && frequencies.subscribed_users) {
			frequencies.subscribed_users.forEach((user, i) => {
				frequencies.subscribed_users[i] = parseInt(user);
			});
		}

		if(frequencies && frequencies.subscribed_act_users) {
			frequencies.subscribed_act_users.forEach((user, i) => {
				frequencies.subscribed_act_users[i] = parseInt(user);
			});
		}

		this.frequencies = frequencies;
		this.oldAlertsSettings = Object.assign({}, frequencies);
	}

	validateAlertsSettings(alert: any) {

		var alertFreq = ['none', 'daily', 'weekly'];

		var validationRules = {
			'override_temperature_limit'			: [{type: 'boolean'}],
			'enable_no_alert'						: [{type: 'boolean'}],
			'temperature_limit'						: [{type: 'float', min: 90, max: 160}],
			'high_temperature_limit_days'			: [{type: 'integer', min: 1, max: 4}],
			'site_disconnection_timespan'			: [{type: 'integer', min: 4, max: 48}],
			'cold_temperature_limit'				: [{type: 'float', min: 0, max: 80}],
			'conventional_ebu_limit'				: [{type: 'float', min: 0.25, max: 2}],
			'opportunity_ebu_limit'					: [{type: 'float', min: 0.25, max: 2}],
			'fast_ebu_limit'						: [{type: 'float', min: 0.25, max: 2}],
			'lithium_ebu_limit'						: [{type: 'float', min: 0.25, max: 2}],
			'water_level_low_days'					: [{type: 'integer', min: 1, max: 7}],
			'weekly_dispatch_email_day'				: [{type: 'integer', min: 1, max: 5}],
			'missed_plugged_in_timespan'			: [{type: 'integer', min: 1, max: 168}],
			'idle_times_limit'						: [{type: 'integer', min: 1, max: 1440}],
			'deep_use_limit'						: [{type: 'float', min: 1, max: 10}],
			'deep_discharge_limit'					: [{type: 'float', min: 1, max: 10}],
			'potential_weak_cells_limit_days'		: [{type: 'integer', min: 1, max: 4}],
			'deep_discharge_limit_days'				: [{type: 'integer', min: 1, max: 4}],
			'expected_fast_chargers_cycles'			: [{type: 'integer', min: 1, max: 10}],
			'expected_conventional_chargers_cycles'	: [{type: 'integer', min: 1, max: 10}],
			'expected_opportunity_chargers_cycles'	: [{type: 'integer', min: 1, max: 10}],
			'site_weekly_working_days'				: [{type: 'integer', min: 1, max: 7}],
			'deep_use_frequency'					: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'deep_discharge_frequency'				: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'over_charge_frequency'					: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'missed_plugged_in_frequency'			: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'idle_times_frequency'					: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'temperature_send_frequency'			: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'cold_temperature_frequency'			: [{type: 'inArray', subType: 'string', values: ['none', 'weekly']}],
			'ebu_alert_send_frequency'				: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'water_level_send_frequency'			: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'missed_eq_send_frequency'				: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'missed_weekly_eq_send_frequency'		: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'sulfated_battery_frequency'			: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'missed_fi_send_frequency'				: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'site_disconnection_frequency'			: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'charger_alert_frequency'				: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'pm_faults_frequency'					: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'cycle_abnormal_stop_frequency'			: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'charger_alert_type'					: [{type: 'inArray', subType: 'string', values: ['latching', 'both']}],
			'cycle_abnormal_faults'					: [{type: 'arraySubset', values: ['charger-restart', 'aborted-operation', 'timeout', 'pm-fauts', 'battery-faults']}],
			'battview_disconnection_frequency'		: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'charger_disconnection_frequency'		: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'charger_disconnection_timespan'		: [{type: 'integer', min: 2, max: 120}],
			'battview_disconnection_timespan'		: [{type: 'integer', min: 2, max: 120}],
			'charger_utilization_alert_frequency'	: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'missed_plugin_cycles_alert_frequency'	: [{type: 'inArray', subType: 'string', values: alertFreq}],
			'expected_plugin_charge_cycles'			: [{type: 'integer', min: 1, max: 10}],
			'weekly_plugin_working_days'			: [{type: 'integer', min: 1, max: 7}],
			'view_truck_id'							: [{type: 'boolean'}],
			'bv_charge_events_only_for_charge_ahr'	: [{type: 'boolean'}],
			'bv_inuse_events_only_for_charge_ahr'	: [{type: 'boolean'}],
			'reports_since_installation'			: [{type: 'boolean'}],
			'kwhrs_alert_frequency'                 : [{type: 'inArray', subType: 'string', values: alertFreq}],
			// 'enable_receiving_noc_emails'			: [{type: 'boolean'}]
		};

		var invalidFields = [];

		for(var field in alert) {
			var rules = validationRules[field] || [];
			rules.forEach((rule) => {

				if(invalidFields.indexOf(field) == -1) {

					var isValid = this.commonUtil.dataValidator(rule.type, rule, alert[field]);

					if(!isValid)
						invalidFields.push(field);
				}
			});

			if(field == 'charger_disconnection_timespan') {

				var isValid = true;

				if(alert.charger_disconnection_frequency == 'daily')
					isValid = this.commonUtil.dataValidator('integer', {'min': 2, 'max': 24}, alert[field]);
				else if(alert.charger_disconnection_frequency == 'weekly')
					isValid = this.commonUtil.dataValidator('integer', {'min': 36, 'max': 120}, alert[field]);

				if(!isValid)
					invalidFields.push(field);
			} else if(field == 'battview_disconnection_timespan') {

				var isValid = true;

				if(alert.battview_disconnection_frequency == 'daily')
					isValid = this.commonUtil.dataValidator('integer', {'min': 2, 'max': 24}, alert[field]);
				else if(alert.battview_disconnection_frequency == 'weekly')
					isValid = this.commonUtil.dataValidator('integer', {'min': 36, 'max': 120}, alert[field]);

				if(!isValid)
					invalidFields.push(field);
			}
		}

		return invalidFields;
	}

	mergeObjects(obj1, obj2) {
		return {...obj1, ...obj2};
	}

	isACTUser(id) {
		return this.alert.subscribed_act_users.indexOf(''+id) > -1;
	}
}
