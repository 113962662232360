<div *ngIf="viewName == 'dashboard'">
	<app-truckview-tabs #tabs
		[isTruckviewMobile]="isTruckviewMobile"
		[date]="date"
		[device]="device"
		[showSettingsTab]="showSettingsTab"
		[hideTemperatureChart]="hideTemperatureChart"
		[activePage]="activePage"
		[activeTab]="activeTab"
		[activeSubTab]="activeSubTab"
		[currentUser]="currentUser"
		[customerId]="customerID"
		[siteId]="siteID"
		[hasData]="hasData"
		(onTabChanged)="updateTabs($event)"
		(onExport)="analyticsPageComponent.onExport()"
	></app-truckview-tabs>
	<app-truckview-select-criteria
		#truckviewSelectCriteriaComponent
		*ngIf="activePage != 'settings' || (activePage == 'settings' && truckviewsIDs.length <= 1)"
		[currentUser]="currentUser"
		[customerId]="customerID"
		[siteId]="siteID"
		[truckviewID]="truckviewID"
		[activePage]="activePage"
		[activeTab]="activeTab"
		[activeSubTab]="activeSubTab"
		[showElementsFlags]="showElementsFlags"
		[isACTuser]="isACTuser"
		[siteInfo]="siteInfo"
		(onDeviceChanged)="updateSelectedDevice($event)"
		(onDateChanged)="updateSelectedDate($event)"
		(updateHasTruckviews)="updateHasTruckviews($event)"
		(updateAlertSettings)="updateAlertSettings($event)"
		(onResetPLC)="onResetPLC($event)"
	></app-truckview-select-criteria>
</div>
<div [ngSwitch]="activePage" *ngIf="hasTruckviews">
	<app-truckview-analytics #analyticsPageComponent
		*ngSwitchCase="'analytics'"
		[customerID]="customerID"
		[siteID]="siteID"
		[device]="device"
		[date]="date"
		[activeTab]="activeTab"
		[activeSubTab]="activeSubTab"
		[isTruckviewMobile]="isTruckviewMobile"
		[siteInfo]="siteInfo"
		[customerInfo]="customerInfo"
		[currentUser]="currentUser"
		[alertSettings]="alertSettings"
		[hideTemperatureChart]="hideTemperatureChart"
		(updateHasData)="hasData = $event"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-truckview-analytics>
	<!-- <app-truckview-quick-view #quickViewComponent
		*ngSwitchCase="'quick-view'"
		[device]="device"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-truckview-quick-view> -->
	<!-- <app-truckviews-faults #faultsComponent
		*ngSwitchCase="'faults'"
		[device]="device"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-truckviews-faults> -->
	<app-device-notes
		*ngSwitchCase="'notes'"
		[device]="device"
		deviceType="truckview"
		sourcePage="truckview_notes"
		[siteId]="siteID"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-device-notes>
	<app-debug-records #debugRecordsComponent
		*ngSwitchCase="'debug-records'"
		[device]="device"
		[date]="date"
		[deviceType]="'truckview'"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-debug-records>
	<!-- <app-fetch-rt-records #fetchRtRecordsComponent
		*ngSwitchCase="'fetch-rt-records'"
		[device]="device"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-fetch-rt-records>
	<app-list-rt-records #listRtRecordsComponent
		*ngSwitchCase="'list-rt-records'"
		[device]="device"
		[siteName]="siteInfo.name"
		[companyName]="customerInfo.name"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-list-rt-records> -->
	<app-connectivity-track #connectivityTrackComponent
		*ngSwitchCase="'connectivity-track'"
		[hasDevices]="true"
		[device]="device"
		[deviceType]="'truckview'"
		[dateRange]="{
			'fromDateFmt': date?.fromDate,
			'toDateFmt': date?.toDate
		}"
		(updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)"
	></app-connectivity-track>
	<app-devices-listing *ngSwitchCase="'listing'" deviceType="truckview"></app-devices-listing>
	<app-truckview-settings *ngSwitchCase="'settings'" [truckviewsIDs]="truckviewsIDs" [customerID]="customerID" [siteID]="siteID" [hasTruckviews]="hasTruckviews" (onGetTruckviewInfo)="updateSelectedDevice([$event], 'onGetTruckviewInfo')" (onMultipleOptionsFlag)="truckviewSelectCriteriaComponent.multipleOptions = $event" (updateAppearanceElementsFlags)="updateAppearanceElementsFlags($event)" [isTruckviewMobile]="isTruckviewMobile"></app-truckview-settings>
</div>
<div *ngIf="!hasTruckviews">
	<div class="panel panel-default">
		<div class="panel-body">
			<strong>No Truckview Registered!</strong>
		</div>
	</div>
</div>