import { Component, OnInit, Input } from '@angular/core';
import { ComboChartConfig } from '../../../../../shared/google-chart/Models/ComboChartConfig';

@Component({
	selector: 'app-site-ebus-report',
	templateUrl: './site-ebus-report.component.html',
	styleUrls: ['./site-ebus-report.component.css']
})
export class SiteEbusReportComponent implements OnInit {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: ComboChartConfig;
	tableColumns: any[] = [
		{title: "Battery ID", property: "battery_id"},
		{title: "Max EBUs", property: "max_ebu"},
		{title: "Avg EBUs", property: "avg_ebu"},
	];
	title: string = 'Site EBUs';
	elementId: string = 'site_ebus';
	widgetClass: string = 'auto-height-widget';
	siteWidgetOptions: any = {
		hasOperation: true,
		hasDate: true,
		hideYesterdayDate: true,
		isReport: true,
		updateChartMaxValue: true,
		updateChartSeries: true,
		hasSiteEbuCheckBox: true
	};

	constructor() { }

	ngOnInit() {
		let series: any = {};
		let vAxis: any = {
			title: 'EBU Value',
			titleTextStyle: {color: 'black', fontSize: 18},
			maxValue: 100,
			minValue: 0,
			gridlines: {count: 10}
		};
		this.config = new ComboChartConfig(vAxis, series, {height: '60%'});
		this.config.updateColumns = (data, options) => {
			let columnsArray = [
				{title: "Battery ID", property: "battery_id"},
			];
			if(options.max_ebu) {
				columnsArray.push({title: "Max EBUs", property: "max_ebu"});
			}
			if(options.avg_ebu) {
				columnsArray.push({title: "Avg EBUs", property: "avg_ebu"});
			}
			return columnsArray;
		};
	}
}