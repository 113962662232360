<form class="form-auth">
  <div class="container-fluid" id="main">
    <div class="boxs3 col-md-6 col-md-offset-3" id="left">
      <div>
        <h2 class="tcenter">{{'site.charger_report' | translate}}</h2>
        <fieldset>
          <legend class="padding-8">{{'g.site' | translate}} <a class="pull-right noprint" href="javascript:">
            <i (click)="isCollapsed['site'] = !isCollapsed['site']" class="fa " [ngClass]="{'fa-minus': !isCollapsed['site'], 'fa-plus': isCollapsed['site']}"></i>
          </a></legend>
          
          <div *ngIf="!isCollapsed['site']">
            <div class="col-md-12 padding-8">
              <label>
                <input type="checkbox" [(ngModel)]="model.hasSite" name="hasSite" /> {{'site.has_a_site' | translate}}
              </label>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4"><strong>{{'g.customer' | translate}}</strong></div>
              <div class="col-md-3">
                <label>
                  <input type="checkbox" [(ngModel)]="model.selectedCustomersNegation" name="selectedCustomersNegation" (change)="updateSitesAvailable()" /> {{'g.exclude' | translate}}
                </label>
              </div>
              <div class="col-md-5">
                <ng-select [items]="customers" bindLabel="customer_name" bindValue="customerid" placeholder="{{'g.all_customers' | translate}}" name="customerid" [(ngModel)]="model.selectedCustomers" [disabled]="!model.hasSite" multiple="true" (change)="updateSitesAvailable()">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4"><strong>{{'g.sites' | translate}}</strong></div>
              <div class="col-md-8">
                <ng-select [items]="sites" bindLabel="name" bindValue="id" name="siteid" [(ngModel)]="model.selectedSites" [disabled]="!model.hasSite" multiple="true">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'g.dealer' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <label>
                  <input type="checkbox" [(ngModel)]="model.selectedDealersNegation" name="selectedDealersNegation" /> {{'g.exclude' | translate}}
                </label>
              </div>
              <div class="col-md-5">
                <ng-select [items]="dealers" bindLabel="name" bindValue="id" name="dealerid" [(ngModel)]="model.selectedDealers" [disabled]="!model.hasSite" multiple="true">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'g.service_dealer' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <label>
                  <input type="checkbox" [(ngModel)]="model.selectedServiceDealersNegation" name="selectedServiceDealersNegation" /> {{'g.exclude' | translate}}
                </label>
              </div>
              <div class="col-md-5">
                <ng-select [items]="dealers" bindLabel="name" bindValue="id" name="servicedealerid" [(ngModel)]="model.selectedServiceDealers" [disabled]="!model.hasSite" multiple="true">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'g.select_an_oem' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <label>
                    <input type="checkbox" [(ngModel)]="model.selectedOEMsNegation" name="selectedOEMsNegation" /> {{'g.exclude' | translate}}
                </label>
              </div>
              <div class="col-md-5">
                <ng-select [items]="OEMs" bindLabel="name" bindValue="id" name="oemid" [(ngModel)]="model.selectedOEMs" [disabled]="!model.hasSite" multiple="true">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'site.test_site' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <label class="radio-inline"><input type="radio" name="isTestSite" [(ngModel)]="model.isTestSite" [value]="true" [disabled]="!model.hasSite">{{'g.yes' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="isTestSite" [(ngModel)]="model.isTestSite" [value]="false" [disabled]="!model.hasSite">{{'g.no' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="isTestSite" [(ngModel)]="model.isTestSite" [value]="null" [disabled]="!model.hasSite">{{'g.not_selected' | translate}}</label>
              </div>
            </div>
              <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'act.act_services' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <label class="radio-inline"><input type="radio" name="actIntelligentSiteType" [(ngModel)]="model.actIntelligentSiteType" [value]="0" [disabled]="!model.hasSite">{{'g.none' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="actIntelligentSiteType" [(ngModel)]="model.actIntelligentSiteType" [value]="1" [disabled]="!model.hasSite">{{'act.act_intelligent' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="actIntelligentSiteType" [(ngModel)]="model.actIntelligentSiteType" [value]="null" [disabled]="!model.hasSite">{{'g.not_selected' | translate}}</label>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend class="padding-8">{{'g.warnings_and_faults' | translate}} <a class="pull-right noprint" href="javascript:">
            <i (click)="isCollapsed['warnings'] = !isCollapsed['warnings']" class="fa " [ngClass]="{'fa-minus': !isCollapsed['warnings'], 'fa-plus': isCollapsed['warnings']}"></i></a>
          </legend>
          <div *ngIf="!isCollapsed['warnings']">
            <div class="col-md-12 padding-8">
              <div class="col-md-4"><strong>{{'act.warnings_for_actview_enabled_devices_only' | translate}}</strong></div>
              <div class="col-md-8">
                <ng-select [items]="warnings" bindLabel="name" bindValue="id" name="warnings" [(ngModel)]="model.selectedWarnings" multiple="true">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4"><strong>{{'charger.has_pm_warning' | translate}}</strong></div>
              <div class="col-md-8">
                <label class="radio-inline"><input type="radio" name="selectedPMWarnings" [(ngModel)]="model.selectedPMWarnings" [value]="1">{{'charger.has_pm_faulted' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="selectedPMWarnings" [(ngModel)]="model.selectedPMWarnings" [value]="2">{{'charger.no_pm_faulted' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="selectedPMWarnings" [(ngModel)]="model.selectedPMWarnings" [value]="3">{{'charger.pms_not_detected' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="selectedPMWarnings" [(ngModel)]="model.selectedPMWarnings" [value]="null">{{'g.not_selected' | translate}}</label>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'charger.number_of_pm_faults' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.numberPMFaultsOperation" class="form-control" name="numberPMFaultsOperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="number" name="numberPMFaults" [(ngModel)]="model.numberPMFaults" [disabled]="!model.numberPMFaultsOperation">
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend class="padding-8">{{'device.mcb_version' | translate}} <a class="pull-right noprint" href="javascript:">
            <i (click)="isCollapsed['version'] = !isCollapsed['version']" class="fa " [ngClass]="{'fa-minus': !isCollapsed['version'], 'fa-plus': isCollapsed['version']}"></i></a></legend>
          <div *ngIf="!isCollapsed['version']">
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.firmware_version' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.fwVersionOperation" class="form-control" name="fwVersionOperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value="=" label="=">=</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                  <option value="!=" label="!=">!=</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="number" step="0.01" name="fwVersion" [(ngModel)]="model.fwVersion" [disabled]="!model.fwVersionOperation">
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.test_mcb' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <label class="radio-inline"><input type="radio" name="isTestMCB" [(ngModel)]="model.isTestMCB" [value]="true">{{'g.yes' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="isTestMCB" [(ngModel)]="model.isTestMCB" [value]="false">{{'g.no' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="isTestMCB" [(ngModel)]="model.isTestMCB" [value]="null">{{'g.not_selected' | translate}}</label>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.mcb_hardware_revision' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.MCBHwVersionOperation" class="form-control" name="MCBHwVersionOperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value="=" label="=">=</option>
                  <option value="!=" label="!=">!=</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="text" name="MCBHwVersion" [(ngModel)]="model.MCBHwVersion" [disabled]="!model.MCBHwVersionOperation">
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.lcd_memory_version' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <ng-select [items]="lcdMemoryVersions" bindLabel="name" bindValue="id" name="lcdMemoryVersion" placeholder="Select a Version" [(ngModel)]="model.lcdMemoryVersion" multiple="true">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.wifi_firmware_version' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <label class="radio-inline"><input type="radio" name="wifiVersion" [(ngModel)]="model.wifiVersion" [value]="1">1</label>
                <label class="radio-inline"><input type="radio" name="wifiVersion" [(ngModel)]="model.wifiVersion" [value]="2">2</label>
                <label class="radio-inline"><input type="radio" name="wifiVersion" [(ngModel)]="model.wifiVersion" [value]="null">{{'g.not_selected' | translate}}</label>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend class="padding-8">{{'device.mcb_configurations' | translate}} <a class="pull-right noprint" href="javascript:">
            <i (click)="isCollapsed['config'] = !isCollapsed['config']" class="fa " [ngClass]="{'fa-minus': !isCollapsed['config'], 'fa-plus': isCollapsed['config']}"></i></a></legend>
          <div *ngIf="!isCollapsed['config']">
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'act.actview_enable' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <label class="radio-inline"><input type="radio" name="isActviewEnabled" [(ngModel)]="model.isActviewEnabled" [value]="true">{{'g.enabled' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="isActviewEnabled" [(ngModel)]="model.isActviewEnabled" [value]="false">{{'g.disabled' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="isActviewEnabled" [(ngModel)]="model.isActviewEnabled" [value]="null">{{'g.not_selected' | translate}}</label>
              </div>
            </div>
              <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.charger_type' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <ng-select [items]="batteryTypes" bindLabel="name" bindValue="id" name="chargerType" placeholder="{{'device.select_a_charger_type' | translate}}" [(ngModel)]="model.chargerType" multiple="true">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.charger_time_zone' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <ng-select [items]="commonDataService.TimeZonesMenu" bindLabel="display_name" bindValue="id" name="timeZonesMenu" placeholder="{{'time.select_a_time_zone' | translate}}" [(ngModel)]="model.zoneid" multiple="true">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'act.connected_within_in_minutes_for_actview_enabled_devices_only' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <label>
                  <input type="checkbox" [(ngModel)]="model.connectionTimeNegation" name="connectionTimeNegation" /> {{'g.exclude' | translate}}
                </label>
              </div>
              <div class="col-md-5">
                <input type="number" min="0" class="form-control" [(ngModel)]="model.connectionTime" name="connectionTime" />
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.enabled_plc' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <label class="radio-inline"><input type="radio" name="enabledPLC" [(ngModel)]="model.enabledPLC" [value]="true">{{'g.enabled' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="enabledPLC" [(ngModel)]="model.enabledPLC" [value]="false">{{'g.disabled' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="enabledPLC" [(ngModel)]="model.enabledPLC" [value]="null">{{'g.not_selected' | translate}}</label>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'charger.led_options' | translate}}</strong>
              </div>
              <div class="col-md-8">
                <label class="radio-inline"><input type="radio" name="ledcontrol" [(ngModel)]="model.ledcontrol" [value]="0">{{'charger.led_strip' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="ledcontrol" [(ngModel)]="model.ledcontrol" [value]="1">{{'g.disabled' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="ledcontrol" [(ngModel)]="model.ledcontrol" [value]="2">{{'charger.stack_light' | translate}}</label>
                <label class="radio-inline"><input type="radio" name="ledcontrol" [(ngModel)]="model.ledcontrol" [value]="null">{{'g.not_selected' | translate}}</label>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend class="padding-8">{{'g.pms' | translate}} <a class="pull-right noprint" href="javascript:">
            <i (click)="isCollapsed['pms'] = !isCollapsed['pms']" class="fa " [ngClass]="{'fa-minus': !isCollapsed['pms'], 'fa-plus': isCollapsed['pms']}"></i></a></legend>
          <div *ngIf="!isCollapsed['pms']">
              <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.pm_voltage' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.pmVoltageOperation" class="form-control" name="pmVoltageOperation">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value="=" label="=">=</option>
                  <option value="!=" label="!=">!=</option>
                </select>
              </div>
              <div class="col-md-5">
                <ng-select [items]="PMVoltages" bindLabel="name" bindValue="id" name="pmVoltage" [(ngModel)]="model.pmVoltage" multiple="true" [disabled]="model.pmVoltageOperation == ''">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.pm_ac_voltage' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <label>
                  <input type="checkbox" [(ngModel)]="model.PMACVoltageNegation" name="PMACVoltageNegation" /> {{'g.exclude' | translate}}
                </label>
              </div>
              <div class="col-md-5">
                <ng-select [items]="PMACVoltages" bindLabel="name" bindValue="id" name="PMACVoltage" [(ngModel)]="model.PMACVoltage" multiple="true">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.number_of_pms' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <label>
                  <input type="checkbox" [(ngModel)]="model.numberOfPMNegation" name="numberOfPMNegation" /> {{'g.exclude' | translate}}
                </label>
              </div>
              <div class="col-md-5">
                <ng-select [items]="numberOfPMs" bindLabel="name" bindValue="id" name="numberOfPM" [(ngModel)]="model.numberOfPM" multiple="true">
                </ng-select>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend class="padding-8">{{'g.data_history' | translate}}
            <a class="pull-right noprint" href="javascript:">
              <i (click)="isCollapsed['history'] = !isCollapsed['history']" class="fa " [ngClass]="{'fa-minus': !isCollapsed['history'], 'fa-plus': isCollapsed['history']}"></i>
            </a>
          </legend>
          <div *ngIf="!isCollapsed['history']">
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.total_ahr' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.totalAHROperation" name="totalAHROperation" class="form-control">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="number" name="totalAHR" [(ngModel)]="model.totalAHR" [disabled]="!model.totalAHROperation" >
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.total_output_kwhr' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.totalKWHROperation" name="totalKWHROperation" class="form-control">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="number" name="totalKWHR" [(ngModel)]="model.totalKWHR" [disabled]="!model.totalKWHROperation" >
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.total_charge_time_in_hours' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.totalChargeTimeOperation" name="totalChargeTimeOperation" class="form-control">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="number" name="totalChargeTime" [(ngModel)]="model.totalChargeTime" [disabled]="!model.totalChargeTimeOperation">
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.total_charge_cycles' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.totalChargeCyclesOperation" name="totalChargeCyclesOperation" class="form-control">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input type="number" name="totalChargeCycles" [(ngModel)]="model.totalChargeCycles" [disabled]="!model.totalChargeCyclesOperation" >
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend class="padding-8">{{'device.chassis' | translate}} <a class="pull-right noprint" href="javascript:">
            <i (click)="isCollapsed['dates'] = !isCollapsed['dates']" class="fa " [ngClass]="{'fa-minus': !isCollapsed['dates'], 'fa-plus': isCollapsed['dates']}"></i></a></legend>
          <div *ngIf="!isCollapsed['dates']">
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.commission_date' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.commissionDateOperation" name="commissionDateOperation" class="form-control">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input class="form-control" name="commissionDate" [(ngModel)]="model.commissionDate" bsDatepicker #commissionDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" [disabled]="!model.commissionDateOperation">
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.installation_date' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.installationDateOperation" name="installationDateOperation" class="form-control">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value="=" label="=">=</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                  <option value="!=" label="!=">!=</option>
                </select>
              </div>
              <div class="col-md-5">
                <input class="form-control" name="installationDate" [(ngModel)]="model.installationDate" bsDatepicker #installationDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" [disabled]="!model.installationDateOperation">
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.chassis_type' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <label>
                  <input type="checkbox" [(ngModel)]="model.chaseTypeNegation" name="chaseTypeNegation" /> {{'g.exclude' | translate}}
                </label>
              </div>
              <div class="col-md-5">
                <ng-select [items]="chaseTypes" bindLabel="name" bindValue="id" name="chaseType" [(ngModel)]="model.chaseType" multiple="true">
                </ng-select>
              </div>
            </div>
            <div class="col-md-12 padding-8">
              <div class="col-md-4">
                <strong>{{'device.chassis_date' | translate}}</strong>
              </div>
              <div class="col-md-3">
                <select [(ngModel)]="model.chaseDateOperation" name="chaseDateOperation" class="form-control">
                  <option value="" label="{{'g.not_selected' | translate}}">{{'g.not_selected' | translate}}</option>
                  <option value="=" label="=">=</option>
                  <option value=">" label=">">></option>
                  <option value="<" label="<"><</option>
                </select>
              </div>
              <div class="col-md-5">
                <input class="form-control" name="chaseDate" [(ngModel)]="model.chaseDate" bsDatepicker #chaseDate="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" [disabled]="!model.chaseDateOperation">
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="form-buttons bvf">
        <button class="btn btn-primary mb20" type="button" (click)="modal.show()"><span class="glyphicon glyphicon-search"></span> {{'g.search' | translate}}</button>
      </div>
    </div>
  </div>
</form>
<app-modal #modal [title]="'g.report_name' | translate" [confirmBtnText]="'submit' | translate" [hasCloseButton]="false">
	<span>{{'g.enter_report_name' | translate}}</span>
	<input type="text" class="form-control" [(ngModel)]="reportName" placeholder="{{'g.report_name' | translate}}" />
</app-modal>