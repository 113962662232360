<div *ngIf="this.userService.hasAccessFunction('noc','read')" class="panel panel-default margin-bottom-xs">
	<div class="panel-heading">{{'note.reporting_noc_status' | translate}}</div>
  <table class="table">
		<tr>
			<td *ngIf="this.userService.hasAccessFunction('noc','write')">
				<button type="button" class="btn btn-primary edit" (click)="showModal()">
					<i [ngClass]="'fa fa-edit fa-lg'" class="fa-fw" data-toggle="tooltip" data-placement="top" title="{{'g.edit' | translate}}"></i>
				</button>
			</td>
			<td >{{formattedDate}}</td>
			<td class="w-50">{{formattedNote || ''}}</td>
		</tr>
	</table>
</div>
<app-modal #addNoteModal title="{{'g.notes' | translate}}" confirmBtnText="{{'g.save' | translate}}" [size]="'lg'">
  <div dialog-content class="w-100 form-group">
		<textarea class="form-control" name="note" id="note" cols="30" rows="10" [(ngModel)]="deviceNote"></textarea>
	</div>
</app-modal>
